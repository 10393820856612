import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';

import { AppService } from '../../app.service';
import { IConfigService } from '../../config/iconfigservice';
import { DocumentSigner, DocumentExternal } from '../../models';
import { BaseService } from '../base.service';
import { lastValueFrom } from 'rxjs';

@Injectable()
export class DocumentsService extends BaseService {

    publicApiBaseUrl: string;

    constructor(public appService: AppService, public configService: IConfigService, public httpClient: HttpClient) {
        super(appService, configService, httpClient);
        this.publicApiBaseUrl = this.configService.getConfiguration().publicApiUrl;
    }

    getDocumentSigners(documentId: any) {
        const clientId = this.appService.getClientId('');
        const url = `${this.publicApiBaseUrl}client/${clientId}/config/account/documentsigner/${documentId}`;

        return super.getData<any>(url, clientId);
    }

    saveDocumentSigners(documentId: any, signers: DocumentSigner[]) {
        const clientId = this.appService.getClientId('');
        const url = `${this.publicApiBaseUrl}client/${clientId}/config/account/documentsigners/${documentId}`;
        return super.postData(url, signers, clientId);
    }

    getPDFDocumentList(accountId: any) {
        const clientId = this.appService.getClientId('');
        const url = `${this.publicApiBaseUrl}client/${clientId}/config/account/${accountId}/documents/pdf`;

        return super.getData<any>(url, clientId);
    }

    getPDFDocumentFormFields(accountId: any, documentId: any) {
        const clientId = this.appService.getClientId('');
        const url = `${this.publicApiBaseUrl}client/${clientId}/config/account/${accountId}/envelopedocument/formfields/${documentId}`;

        return super.getData<any>(url, clientId);
    }

    getDocumentList(accountId: any, accountVersion: any) {
        const clientId = this.appService.getClientId('');
        const url = `${this.publicApiBaseUrl}client/${clientId}/account/${accountId}/${accountVersion}/document`;

        return lastValueFrom(super.getData<DocumentExternal[]>(url, clientId));
    }
    
    getDocumentTemplateUri(accountId: string, documentId: string) {
        const clientId = this.appService.getClientId('');
        const url = `${this.publicApiBaseUrl}client/${clientId}/account/${accountId}/document/${documentId}/templateUri`;

        return super.getData<string>(url, clientId);
    }
}
