import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { SignatureEnvelope, SignatureEnvelopeRecipient } from "@Models";
import { Observable, Subscription } from "rxjs";
import { CaseSignatureService, ConfirmationDialogService, SignalRService } from "@Services";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MonitoringService } from "@App";
import { CustomEventLogMode, CustomEventType, MrsColor } from "@Enums";
import { Utils } from "@Utils";
import { EmbeddedSignatureDialogV2Component } from "@Components/signature-envelope-dialog/v2/embedded-signature-dialog/embedded-signature-dialog-v2.component";

export interface SignatureEnvelopeDialogv2Params {
    accountId: string;
    caseId: string;
    caseIntegrationQueueId: string;
    envelope: SignatureEnvelope;
    onEnvelopeUpdate: Observable<any>;
    mapKeys: any;
    signalrConnection: any;
}
@Component({
    selector: 'signature-envelope-dialog-v2',
    templateUrl: 'signature-envelope-dialog-v2.component.html',
    styleUrls: ['signature-envelope-dialog-v2.component.scss']
})
export class SignatureEnvelopeDialogV2Component {
    envelope: SignatureEnvelope;
    envelopeStatus: { label: string; class: string; complete: boolean; signed: boolean; };
    loadingEnvelope = false;
    loadingEmbeddedUrl = false;
    subscriptions: Subscription[] = [];
    docuSignSignerStatusDelay: number;
    mapKeys: any;
    useDocusignV2 = false;
    currentSigner: SignatureEnvelopeRecipient;
    applyingSignature = false;
    applyingSignatureTakingTooLong = false;
    signalrConnection: any;

    UIGreen = MrsColor.UIGreen;
    UIBlue = MrsColor.UIBlue;
    UIRed = MrsColor.UIRed;
    MidGray = MrsColor.MidGray;
    DarkBlueGray = MrsColor.MidGray;

    constructor(
        private caseSignatureService: CaseSignatureService,
        private dialog: MatDialog,
        private monitoringService: MonitoringService,
        public dialogRef: MatDialogRef<SignatureEnvelopeDialogV2Component>,
        @Inject(MAT_DIALOG_DATA) public context: SignatureEnvelopeDialogv2Params,
        public changeDetector: ChangeDetectorRef,
        public signalRService: SignalRService) {
    }

    ngOnInit() {
        if (this.context.envelope != null) {
            this.setEnvelope(this.context.envelope);
        }

        if (this.context.mapKeys) {
            this.mapKeys = this.context.mapKeys;
        }

        if (this.context.signalrConnection) {
            this.signalrConnection = this.context.signalrConnection;
        }

        if (this.context.onEnvelopeUpdate) {
            this.subscriptions.push(this.context.onEnvelopeUpdate.subscribe((envelope: SignatureEnvelope) => {
                if (envelope.envelopeId === this.context.envelope.envelopeId) {
                    this.setEnvelope(envelope);
                }
            }));
        }

        this.docuSignSignerStatusDelay = 1000;
    }

    ngOnDestroy() {
        this.subscriptions.forEach(x => {
            if (typeof x?.unsubscribe === 'function') {
                x.unsubscribe();
            }
        });
    }

    ok() {
        this.dialogRef.close();
    }

    refreshEnvelope(): void {
        this.applyingSignature = true;
        setTimeout(() => {
            this.caseSignatureService.getSignatureEnvelope(this.context.accountId, this.context.caseId, this.context.envelope.envelopeId, this.context.caseIntegrationQueueId).then(envelope => {
                if (envelope && envelope != null) {
                    this.setEnvelope(envelope);
                }

                this.applyingSignature = false;
            });
        }, this.docuSignSignerStatusDelay);

        setTimeout(() => {
            this.applyingSignatureTakingTooLong = true;
        }, 10000);
    }

    setEnvelope(envelope: SignatureEnvelope) {
        if (!this.envelope)
            this.envelope = envelope;

        this.envelope.status = envelope.status;
        const failedIdCheck = envelope.signers.find(x => x.status === 'failed_id_check');
        this.envelopeStatus = this.getEnvelopeStatus(envelope.status, failedIdCheck);

        this.envelope.signers.forEach(s => {
            const signer = envelope.signers.find(x => x.clientUserId === s.clientUserId);
            s.status = signer.status;
            
            switch (signer.status) {
                case 'completed': {
                    s.statusDescription = `Signed`;
                    s.signerStatus = 'Signed';
                    break;
                }
                case 'declined': {
                    s.statusDescription = `Declined, with reason: ${signer.declinedReason}`;
                    s.signerStatus = 'Declined';
                    break;
                }
                case 'failed_id_check': {
                    s.statusDescription = `Failed ID check`;
                    s.signerStatus = 'Failed';
                    break;
                }
                case 'sent': {
                    s.signerStatus = 'Sent';
                    break;
                }
                case 'created':
                    s.signerStatus = 'Pending';    
                    break;
            }
        });

        this.loadingEnvelope = false;
        this.applyingSignature = false;
        this.currentSigner = null;
    }

    async sign(signer: SignatureEnvelopeRecipient, reloadAfterClose = true) {
        if(reloadAfterClose) {
            this.currentSigner = signer; //We are signing, not just viewing the doc
            this.applyingSignature = signer.status === 'completed';
        }
        this.loadingEmbeddedUrl = true;

        this.monitoringService.logCaseEvent(`Client - Signature button clicked. Signer: ${JSON.stringify(signer)}`, this.context.caseId, this.context.caseIntegrationQueueId, CustomEventType.IntegrationMetric, CustomEventLogMode.Standalone);

        const loaderPayload = {
            caseId: this.context.caseId,
            envelopeId: this.context.envelope.envelopeId,
            signer: signer
        };
        try {
            this.loadingEmbeddedUrl = false;

            const embeddedSignatureDialog = this.dialog.open(EmbeddedSignatureDialogV2Component, {
                height: '100%',
                minWidth: Utils.isDesktop() ? '55vw' : '90%',
                maxWidth: null,
                maxHeight: '90vh',
                disableClose: true,
                panelClass: 'mat-dialog--no-padding',
                data: loaderPayload
            });

            if (reloadAfterClose) {
                const afterClosedSubscription = embeddedSignatureDialog.afterClosed().subscribe((signEvent: any) => {
                    this.loadingEnvelope = true;
                    let isLastSigner = true;

                    switch (signEvent.event) {
                        case 'decline':
                            signer.status = 'declined';
                            signer.signerStatus = 'Processing';
                            break;
                        case 'cancel':                            
                            signer.signerStatus = 'Sent';
                            this.loadingEnvelope = false;
                            isLastSigner = false;
                            break;
                        case 'signing_complete':
                            signer.status = 'completed';
                            signer.signerStatus = 'Processing';
                            break;
                        default:
                            signer.status = signEvent.event;
                            break;
                    }

                    
                    let nextSignerSent = false;
                    this.envelope.signers.forEach(s => {
                        if (s.clientUserId !== signer.clientUserId && s.status === 'created' && !nextSignerSent) {
                            if (signer.status === 'completed') {
                                s.status = 'sent';
                                nextSignerSent = true;
                            }
                            isLastSigner = false;
                        }
                    });

                    this.caseSignatureService.putSignatureEnvelope(this.context.accountId, this.context.caseId, this.context.envelope.envelopeId, this.envelope);
                    if (isLastSigner) {
                        signer.signerStatus = "Processing";
                        this.refreshEnvelope();
                    } else {
                        setTimeout(()=>{
                            this.setEnvelope(this.envelope);
                        }, this.docuSignSignerStatusDelay);

                    }
                    afterClosedSubscription.unsubscribe();
                });
            }
        } catch (error) {
            this.loadingEmbeddedUrl = false;
        }
    }

    async viewSignedDocuments() {
        const firstSigner = this.envelope.signers[0];
        await this.sign(firstSigner, false);
    }

    getEnvelopeStatus(envelopeStatus: string, failedIdCheckSigners: SignatureEnvelopeRecipient): { label: string; class: string; complete: boolean; signed: boolean; } {
        switch (envelopeStatus) {
            case 'sent':
                return failedIdCheckSigners ?
                    {label: 'Failed ID check', class: 'text-danger', complete: true, signed: false}
                    : {label: 'Pending', class: 'text-info', complete: false, signed: false};
            case 'completed':
                return {label: 'Completed', class: 'text-success', complete: true, signed: true};
            case 'declined':
                return {label: 'Declined', class: 'text-danger', complete: true, signed: false};
            default:
                return null;
        }
    }

    signatureDeclinedOrFailed(signer:SignatureEnvelopeRecipient){
        return signer.status === 'declined' || signer.status === 'failed_id_check';
    }
}
