<button *ngIf="isSubmitButton" type="submit" mat-button color="primary" (click)="onClick.emit($event)"
  [disabled]="disabled" id="{{buttonId}}">
  <span *ngIf="iconPosition === 'before'">
    <span class="material-icons" [ngStyle]="{ 'color': textColor }">{{iconName}}</span>
    <span class="inner-text-margin">{{buttonLabel}}</span>
  </span>
  <span *ngIf="iconPosition === 'after'">
    <span class="inner-after-text-margin">{{buttonLabel}}</span>
    <span class="material-icons" [ngStyle]="{ 'color': textColor }">{{iconName}}</span>
  </span>
</button>
<button *ngIf="!isSubmitButton" mat-button color="primary" (click)="onClick.emit($event)" [disabled]="disabled"
  id="{{buttonId}}">
  <span *ngIf="iconPosition === 'before'">
    <span class="material-icons" [ngStyle]="{ 'color': textColor }">{{iconName}}</span>
    <span class="inner-text-margin">{{buttonLabel}}</span>
  </span>
  <span *ngIf="iconPosition === 'after'">
    <span class="inner-after-text-margin">{{buttonLabel}}</span>
    <span class="material-icons" [ngStyle]="{ 'color': textColor }">{{iconName}}</span>
  </span>
</button>