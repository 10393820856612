import { Component, Input, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { AppService } from '@App';
import { Account, AccountApiMapping, ClientAccountSetting, CsvApiMappingSettings } from '@Models';
import { AccountSettingsService, ApiMappingsService, ConfirmationDialogService, MapKeyService, NotificationService, NotificationSeverity } from '@Services';
import { MapKeyType } from 'app/enums';
import { FeatureManagerService } from 'app/services/global/feature-manager/feature-manager.service';
import * as _ from 'lodash';

@Component({
    selector: 'api-mappings',
    host: {'class': 'd-flex flex-row flex-fill'},
    templateUrl: './api-mappings.component.html',
    styleUrls: ['./api-mappings.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ApiMappingsComponent implements OnInit {
    @ViewChild('orderPaginator') orderPaginator: MatPaginator;
    @ViewChild('exportPaginator') exportPaginator: MatPaginator;
    @ViewChild('applicantPaginator') applicantPaginator: MatPaginator;
    @ViewChild('stpPaginator') stpPaginator: MatPaginator;
    @ViewChild('csvPaginator') csvPaginator: MatPaginator;

    @ViewChild(MatSort) sort: MatSort;
    @ViewChildren(MatTable) table !: QueryList<MatTable<string>>;
    @ViewChild('validationDialog') validationDialog: TemplateRef<any>;
    @ViewChild('expressionHelpDialog') expressionHelpDialog: TemplateRef<any>;
    @ViewChild('standardDataEntry') standardDataEntry;

    @Input() accountInfo: Account;
    @Input() nonVirtualMapKeys: any;
    @Input() secrets: any;
    csvAccountSettingName = 'csvApiMappingSettings';

    selectedCategory: any;
    mappingForm: UntypedFormGroup;
    validationForm: UntypedFormGroup;
    csvForm: UntypedFormGroup;
    mappings: AccountApiMapping[];
    dataSource: MatTableDataSource<any>;
    validationDataSource: MatTableDataSource<any>;
    displayedMapkeys: any;
    displayedNonQuestionMapkeys: any;
    formSubmitted = false;
    subscriptions: any = [];
    allowedMapKeyTypes: MapKeyType[];
    csvApiMappingSettings: ClientAccountSetting;

    displayedColumns = ['elementName', 'mapKeyId', 'validations', 'actions'];
    validationColumns = ['expression', 'errorMessage', 'actions'];
    expressionHelpColumns = ['name', 'expression', 'description'];

    expressionHelpData = [
        {
            name: 'Required',
            expression: '.',
            description: 'Must contain at least one non-whitespace character'
        },
        {
            name: 'Min Length',
            expression: '^.{<mark>5</mark>,}$',
            description: 'Must be at least <mark>5</mark> characters'
        },
        {
            name: 'Max Length',
            expression: '^.{0,<mark>5</mark>}$',
            description: 'Must be 0-<mark>5</mark> characters'
        },
        {
            name: 'Exact Length',
            expression: '^.{<mark>5</mark>}$',
            description: 'Must be exactly <mark>5</mark> characters'
        },
        {
            name: 'Range',
            expression: '^.{<mark>5</mark>,<mark>10</mark>}$',
            description: 'Must be between <mark>5</mark> and <mark>10</mark> characters'
        },
        {
            name: 'Number/Decimal',
            expression: '^[0-9]\d*(\\.\\d+)?$',
            description: 'Must be a valid integer or decimal'
        },
        {
            name: 'Phone Number',
            expression: '^\\d{3}-?\\d{3}-?\\d{4}$',
            description: 'Must be a valid phone number with or without dashes'
        },
        {
            name: 'SSN',
            expression: '^\\d{3}-?\\d{2}-?\\d{4}$',
            description: 'Must be a valid SSN with or without dashes'
        },
        {
            name: 'Email',
            expression: '^\\S+@\\S+.\\S+$',
            description: 'Must be a valid email address'
        },
    ];

    expressionHelpDataSource: MatTableDataSource<any> = new MatTableDataSource(this.expressionHelpData);

    constructor(
        private appService: AppService,
        private _fb: UntypedFormBuilder,
        private confirmationService: ConfirmationDialogService,
        private apiMappingService: ApiMappingsService,
        public dialog: MatDialog,
        public expressionDialog: MatDialog,
        public mapKeyService: MapKeyService,
        private featureManagerService: FeatureManagerService,
        private accountSettingsService: AccountSettingsService,
        private notificationService: NotificationService
    ) {
    }

    ngOnInit() {
        this.mappingForm = this._fb.group(
            {
                selectedCategory: [],
                mappings: this._fb.array([])
            });

        this.validationForm = this._fb.group(
            {
                validations: this._fb.array([])
            });

        this.csvForm = this._fb.group(
            {
                emailRecipients: this._fb.array([]),
                protocol: ['', Validators.required],
                serverName: ['', Validators.required],
                portNumber: ['', Validators.required],
                remotePath: ['', Validators.required],
                delimiter: ['', Validators.compose([Validators.required, Validators.pattern(/^\S+/)])],
                authenticationType: ['', Validators.required],
                userName: ['', Validators.required],
                password: ['', Validators.required],
                passphrase: [''],
                pgpKey: ['']
            }
        );


        this.apiMappingService.getNonQuestionMapkeys(this.accountInfo.id).subscribe(result => {
            this.displayedNonQuestionMapkeys = _.filter(result, function (o) {
                return !o.isConstant && (!o.integrationId || o.integrationId === null) && !o.isVirtual;
            });
            this.filterMapKeys();
        });

        this.selectedCategory = 'Order';
        this.getMappings();
    }

    get emailRecipients() {
        return this.csvForm.controls["emailRecipients"] as UntypedFormArray;
    }

    selectCategory(event) {
        switch (event.index) {
            case 0:
                this.selectedCategory = 'Order';
                break;
            case 1:
                this.selectedCategory = 'Export';
                break;
            case 2:
                this.selectedCategory = 'Applicant';
                break;
            case 3:
                this.selectedCategory = 'STP';
                break;
            case 4:
                this.selectedCategory = 'CSV';
                break;
        }

        this.getMappings();
    }

    setAllowedMapkeyTypes() {
        if (this.selectedCategory === 'Export') {
            this.allowedMapKeyTypes = [MapKeyType.NonVirtual, MapKeyType.ExpressionBuilder, MapKeyType.NonPersistent, MapKeyType.Virtual, MapKeyType.VirtualDocument];
        } else {
            this.allowedMapKeyTypes = [MapKeyType.NonVirtual, MapKeyType.ExpressionBuilder];
        }
    }

    getMappings() {
        this.apiMappingService.getAllAsFormArray(this.accountInfo.id, this.selectedCategory).subscribe(response => {
            this.filterMapKeys();
            this.mappingForm.setControl('mappings', response);

            if (this.selectedCategory === 'STP') {

                this.displayedColumns = ['elementName', 'mapKeyId', 'validations', 'actions'];
                this.mappingForm.enable();
                this.mappingForm.get('mappings')['controls'].forEach(control => {
                    const readOnly = control.get('readOnly').value;
                    if (readOnly) {
                        control.disable();
                    }
                });
            } else {
                this.displayedColumns = ['elementName', 'mapKeyId', 'validations', 'actions'];
                this.mappingForm.enable();
            }

            if (this.selectedCategory === 'CSV') {
                this.getCsvSettings();
            }

            this.renderMappingsDatasource();
        });
    }

    getCsvSettings() {
        this.appService.getData(`AccountSettings/${this.accountInfo.id}`).subscribe(
            response => {
                const setting = _.find(response.data, {'name': this.csvAccountSettingName});
                if (setting) {
                    this.csvApiMappingSettings = setting;
                    const csvSettings = JSON.parse(setting.value) as CsvApiMappingSettings;
                    this.csvForm.patchValue({
                        protocol: csvSettings.protocol,
                        serverName: csvSettings.serverName,
                        portNumber: csvSettings.portNumber,
                        remotePath: csvSettings.remotePath,
                        delimiter: csvSettings.delimiter,
                        authenticationType: csvSettings.authenticationType,
                        userName: csvSettings.userName,
                        password: csvSettings.password,
                        passphrase: csvSettings.passphrase,
                        pgpKey: csvSettings.pgpKey
                    });

                    const emailRecipientArray = new UntypedFormArray([]);
                    csvSettings.emailRecipients.forEach(emailRecipient => {
                        emailRecipientArray.push(this.createEmailRecipient(emailRecipient.emailAddress));
                    });
                    this.csvForm.setControl('emailRecipients', emailRecipientArray);
                } else {
                    this.csvApiMappingSettings = {
                        clientId: this.accountInfo.clientId,
                        accountId: this.accountInfo.id,
                        name: this.csvAccountSettingName,
                        value: ''
                    };
                    this.addEmailRecipient();
                }
            });
    }

    saveMappings() {
        this.formSubmitted = true;
        this.mappingForm.markAllAsTouched();
        this.csvForm.markAllAsTouched();

        if (this.mappingForm.valid) {
            const apiMapping = this.mappingForm.value.mappings as AccountApiMapping[];

            const uniqueMappings = _.uniqBy(apiMapping, 'mapKeyId');
            if (uniqueMappings.length !== apiMapping.length) {
                this.notificationService.showNotification({severity: NotificationSeverity.Error, message: 'Not saved. Cannot assign a mapkey more than once'});
                return;
            }

            if (this.selectedCategory === 'CSV') {

                if (this.csvForm.invalid) {
                    this.notificationService.showNotification({severity: NotificationSeverity.Error, message: 'Required fields missing'});
                    return;
                }

                const csvSettings = this.csvForm.value;
                this.csvApiMappingSettings.value = JSON.stringify(csvSettings);

                if (csvSettings.emailRecipients.length <= 0) {
                    this.notificationService.showNotification({severity: NotificationSeverity.Error, message: 'One email recipient required'});
                    return;
                }

                this.csvApiMappingSettings.name = this.csvAccountSettingName;
                this.accountSettingsService.saveAccountSetting(this.accountInfo, this.csvApiMappingSettings).subscribe(() => {
                    this.saveApiMapping(apiMapping);
                });
            } else {
                this.saveApiMapping(apiMapping);
            }
        } else {
            this.notificationService.showNotification({severity: NotificationSeverity.Error, message: 'Invalid mappings'});
        }
    }

    private saveApiMapping(apiMapping: AccountApiMapping[]) {
        this.apiMappingService.saveApiMapping(this.accountInfo.id, apiMapping)
            .subscribe(() => {
                this.notificationService.showNotification({severity: NotificationSeverity.Success, message: 'Settings saved'});
                this.getMappings();
                this.formSubmitted = false;
            });
    }

    closeDialog() {
        this.dialog.closeAll();
    }

    editValidations(element) {
        this.validationForm = this._fb.group(
            {
                validations: this._fb.array([])
            });

        const validations = element.value.mapKeyValidations;
        const validationFormArray = new UntypedFormArray([]);
        validations.forEach(v => {
            const validation = ApiMappingsService.validationsAsFormGroup(v);
            validationFormArray.push(validation);
        });

        this.validationForm.setControl('validations', validationFormArray);
        this.validationDataSource = new MatTableDataSource((this.validationForm.get('validations') as UntypedFormArray).controls);

        const dialogRef = this.dialog.open(this.validationDialog,
            {
                width: '800px',
                maxHeight: '90%'
            });

        dialogRef.afterClosed().subscribe(() => {
            const editedValidations = this.validationForm.get('validations') as UntypedFormArray;
            element.setControl('mapKeyValidations', editedValidations);
            this.renderMappingsDatasource();
        });
    }

    deleteApiMapping(element) {
        this.confirmationService.open({
            message: `Are you sure that you want to delete this mapping?`,
            showCancel: true,
            onOk: () => {
                if (element.value.id == null) {
                    const index = this.dataSource.data.indexOf(element, 0);
                    if (index > -1) {
                        this.dataSource.data.splice(index, 1);
                    }
                    this.renderMappingsDatasource();
                } else {
                    this.apiMappingService.deleteApiMapping(this.accountInfo.id, element.value.id).subscribe(() => {
                        this.notificationService.showNotification({severity: NotificationSeverity.Success, message: 'Deleted successfully'});
                        this.getMappings();
                    });
                }
            }
        });
    }

    deleteValidation(element) {
        const index = this.validationDataSource.data.indexOf(element, 0);
        if (index > -1) {
            this.validationDataSource.data.splice(index, 1);
        }
        this.table.last.dataSource = this.validationDataSource;
        this.table.last.renderRows();
    }

    addApiMapping() {
        const formArray = this.mappingForm.get('mappings') as UntypedFormArray;

        formArray.insert(0, new UntypedFormGroup({
            id: new UntypedFormControl(null),
            category: new UntypedFormControl(this.selectedCategory),
            elementName: new UntypedFormControl('', Validators.required),
            mapKeyId: new UntypedFormControl('', Validators.required),
            mapKeyValidations: new UntypedFormArray([])
        }));
        this.filterMapKeys();
        this.renderMappingsDatasource();
    }

    addValidation() {
        const formArray = this.validationForm.get('validations') as UntypedFormArray;

        formArray.push(new UntypedFormGroup({
            id: new UntypedFormControl(),
            expression: new UntypedFormControl(''),
            errorMessage: new UntypedFormControl('')
        }));

        this.validationDataSource = new MatTableDataSource((this.validationForm.get('validations') as UntypedFormArray).controls);
        this.table.last.dataSource = this.validationDataSource;
        this.table.last.renderRows();
    }

    renderMappingsDatasource() {
        this.dataSource = new MatTableDataSource((this.mappingForm.get('mappings') as UntypedFormArray).controls);
        switch (this.selectedCategory) {
            case 'Order':
                this.dataSource.paginator = this.orderPaginator;
                break;
            case 'Export':
                this.dataSource.paginator = this.exportPaginator;
                break;
            case 'Applicant':
                this.dataSource.paginator = this.applicantPaginator;
                break;
            case 'STP':
                this.dataSource.paginator = this.stpPaginator;
                break;
            case 'CSV':
                this.dataSource.paginator = this.csvPaginator;
                break;
        }
        this.table.first.dataSource = this.dataSource;
        this.table.first.renderRows();
        
        this.dataSource.sort = this.sort;
    }

    showExpressionHelp() {
        this.expressionDialog.open(this.expressionHelpDialog,
            {
                width: '600px',
                maxHeight: '90%'
            });
    }

    addEmailRecipient() {
        const control = <UntypedFormArray>this.csvForm.get('emailRecipients');
        control.push(this.createEmailRecipient(null));
    }

    deleteEmailRecipient(index) {
        const controls = <UntypedFormArray>this.csvForm.get('emailRecipients');
        controls.removeAt(index);
    }

    createEmailRecipient(email) {
        return this._fb.group({
            emailAddress: [email, [Validators.required, Validators.email]]
        });
    }

    private filterMapKeys() {
        this.setAllowedMapkeyTypes();
        if (this.selectedCategory !== 'Export') {
            this.displayedMapkeys = _.filter(this.mapKeyService.mapKeys, function (o) {
                return !o.isConstant && (!o.integrationId || o.integrationId === null) && (!o.isVirtual || (o.isVirtual && o.mapKeyTypeId === MapKeyType.ExpressionBuilder));
            });
        } else {
            this.displayedMapkeys = _.filter(this.mapKeyService.mapKeys, function (o) {
                return (!o.integrationId || o.integrationId === null);
            });
        }
    }
}
