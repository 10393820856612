import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { AppService } from '../../../../app.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
// import { EventEmitter } from 'events';

import { Account } from '../../../../models';
import { AccountSettingsService, MapKeyService } from '../../../../../app/services';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'case-transfer-settings',
    host: { 'class': 'd-flex flex-row flex-fill' },
    templateUrl: './case-transfer.component.html',
    styleUrls: ['../account-settings-v2.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CaseTransferSettingsComponent implements OnInit {
    @Input() accountInfo: Account;
    @Input() secretKeys;
    @Input() rules;

    labels: any[] = [];
    params: any = {};
    ftpSettings: any;
    showForm: boolean = false;

    ftpSettingsForm: UntypedFormGroup;

    authenticationModes: any = [
        { id: 1, name: 'Password' },
        { id: 2, name: 'Private Key' },
    ];

    toolTipText = `The filename convention is used to generate the name of the generated document.
  It should include the extension and the current date parameter. The date parameter is necessary for regeneration.
  Mapkey replacement in the filename uses '%MAPKEY%'.
  Replaceable parameters wrapped with curly braces: {{'{'}}Parameter{{'}'}}.
  The filename convention supports the following replaceable parameters:
  - Account (Account entity)
  - Case (Case entity)
  - Now (current date/time), can be formatted using .NET format specifiers

  Examples:
  1.
  {{'{'}}Account.Code{{'}'}}-{{'{'}}Case.CaseNumber{{'}'}}-SIWL_GDP_App-{{'{'}}Now:yyyyMMddHHmmss{{'}'}}.pdf
  = 123-001-1-SIWL_GDP_App-20181109083440.pdf

  2. {{'{'}}Case.PolicyNumber{{'}'}}-SIWL_GDP_App-{{'{'}}Now:yyyyMMddHHmmss{{'}'}}.tiff
  = 000005123456-SIWL_GDP_App-20181109083440.tiff`;

    recordingToolTipText = `The filename convention is used to generate the name of the recording if included in the case package.
  It should include the extension and the current date parameter.
  Mapkey replacement in the filename uses '%MAPKEY%'.
  Replaceable parameters wrapped with curly braces: {{'{'}}Parameter{{'}'}}.
  The filename convention supports the following replaceable parameters:
  - Account (Account entity)
  - Case (Case entity)
  - Now (current date/time), can be formatted using .NET format specifiers

  Examples:
  1. {{'{'}}Account.Code{{'}'}}-{{'{'}}Case.CaseNumber{{'}'}}-SIWL_GDP_App-{{'{'}}Now:yyyyMMddHHmmss{{'}'}}.wav
  = 123-001-1-SIWL_GDP_App-20181109083440.wav

  2. {{'{'}}Case.PolicyNumber{{'}'}}-SIWL_GDP_App-{{'{'}}Now:yyyyMMddHHmmss{{'}'}}.wav
  =   000005123456-SIWL_GDP_App-20181109083440.wav `;

    constructor(
        private appService: AppService,
        private _fb: UntypedFormBuilder,
        private accountSettingsService: AccountSettingsService
    ) {
    }

    async ngOnInit() {
        this.ftpSettingsForm = this._fb.group({
            id: '',
            accountId: [this.accountInfo.id, Validators.required],
            name: 'Case Transmit',
            protocol: ['', Validators.required],
            serverName: ['', Validators.required],
            portNumber: ['', Validators.required],
            authenticationMode: ['', Validators.required],
            userName: ['', Validators.required],
            secretUri: ['', Validators.required],
            passphraseUri: [''],
            waitTime: ['', Validators.required],
            remoteFilePath: '',
            pgpEncryption: '',
            caseTransmitTimeout: ['60', Validators.compose([Validators.required, Validators.min(5), Validators.max(1440)])],
            callRecordingsRequired: '',
            associatedCallsRequired: '',
            excludeCallsFromCaseTransfer: '',
            casePackageFilename: ['{Case.CaseNumber}-{Now:yyyyMMddhhmmss}.zip', Validators.compose([Validators.required])],
            recordingFilename: ['{Case.CaseNumber}-{Now:yyyyMMddhhmmss}.wav', Validators.compose([Validators.required])],
            transmissionSchedule: '',
            transmissionEnabled: '',
            transmissionRule: '',
            transmissionRuleName: '',
            zipCasePackage: ''
        });

        // this.inputMapKeys = this.mapKeyService.filt(this.mapKeys);
        this.getSettings();
    }

    getSettings() {
        const generalSettingsObservable = this.appService.getData(`AccountSettings/${this.accountInfo.id}`);
        const ftpSettingsObservable = this.appService.getData(`FtpSettings/${this.accountInfo.id}`);

        combineLatest([generalSettingsObservable, ftpSettingsObservable]).pipe(
            map(([generalSettings, ftpSettings]) => ({ generalSettings, ftpSettings })))
            .subscribe(pair => {
                const generalSettings = pair.generalSettings;

                if (generalSettings.status === 'success') {

                    if (generalSettings.data && generalSettings.data.length) {
                        this.params = {};
                        generalSettings.data.forEach(item => {
                            // bools - default to false
                            if (item.name === 'callRecordingsRequired'
                                || item.name === 'associatedCallsRequired'
                                || item.name === 'excludeCallsFromCaseTransfer'
                                || item.name === 'zipCasePackage'
                                || item.name === 'transmissionEnabled') {
                                this.params[item.name] = (item.value === 'true' ? true : false);
                            } else {
                                this.params[item.name] = item.value;
                                if (item.name === 'transmissionRule') {
                                    var transmissionRule = this.rules.find(x => x.id === item.value);
                                    if (transmissionRule) {
                                        this.params["transmissionRuleName"] = transmissionRule.name;
                                    }
                                }
                            }
                        });
                    }

                    // default to true if doesn't exist
                    this.params.zipCasePackage = !this.params || this.params.zipCasePackage == null ? true : this.params.zipCasePackage;

                    this.zipCasePackageChanged(this.params.zipCasePackage);

                    this.ftpSettings = pair.ftpSettings.data[0];

                    this.params = { ...this.params, ...this.ftpSettings };
                }

            });
    }

    checkACL(permissionType, redirect?) {
        return this.appService.checkACL('Accounts', permissionType, redirect);
    }

    zipCasePackageChanged(isChecked) {
        const zipFileNameControl = this.ftpSettingsForm.get('casePackageFilename');

        if (isChecked === true) {
            zipFileNameControl.setValidators(Validators.required);
            zipFileNameControl.enable();
        } else {
            zipFileNameControl.clearValidators();
            zipFileNameControl.setValidators(null);
            zipFileNameControl.disable();
        }

        zipFileNameControl.updateValueAndValidity();
    }

    changeAuthenticationMode(option) {
        if (option.value === '1') {
            this.ftpSettingsForm.controls['passphraseUri'].clearValidators();
        } else {
            this.ftpSettingsForm.controls['passphraseUri'].setValidators(Validators.required);
        }
    }

    editSetting() {
        this.ftpSettingsForm.reset();
        this.ftpSettingsForm.patchValue({
            accountId: this.accountInfo.id
        });

        this.ftpSettingsForm.patchValue(this.params);
        this.onExcludeCallsToggle(this.ftpSettingsForm.get('excludeCallsFromCaseTransfer'));
        this.showForm = true;
    }

    cancel() {
        this.showForm = false;
    }

    getAuthenticationName(mode) {
        if (mode === '1') {
            return 'Password';
        } else if (mode === '2') {
            return 'Private Key';
        }
    }

    getSecretKeyLabel(id) {
        if (id && this.secretKeys && this.secretKeys.length) {
            const secretKey = _.find(this.secretKeys, ['id', id]);
            if (secretKey && secretKey.name) {
                return secretKey.name;
            } else {
                return '';
            }
        } else {
            return '';
        }
    }

    onExcludeCallsToggle(event) {
        if (event.checked || event.value) {
            this.ftpSettingsForm.get('recordingFilename').clearValidators();
            this.ftpSettingsForm.get('recordingFilename').setValidators(null);
            this.ftpSettingsForm.get('recordingFilename').updateValueAndValidity();
        } else {
            this.ftpSettingsForm.get('recordingFilename').setValidators([Validators.required]);
            this.ftpSettingsForm.get('recordingFilename').updateValueAndValidity();
        }

    }

    saveSettings() {
        if (!this.ftpSettingsForm.invalid) {
            const formValues = {
                accountSettings: [],
                ftpSettings: this.ftpSettingsForm.value
            };

            const accountSettings = [];
            accountSettings.push(this.addAccountSetting('caseTransmitTimeout'));
            accountSettings.push(this.addAccountSetting('callRecordingsRequired'));
            accountSettings.push(this.addAccountSetting('associatedCallsRequired'));
            accountSettings.push(this.addAccountSetting('excludeCallsFromCaseTransfer'));
            accountSettings.push(this.addAccountSetting('casePackageFilename'));
            accountSettings.push(this.addAccountSetting('transmissionSchedule'));
            accountSettings.push(this.addAccountSetting('zipCasePackage'));
            accountSettings.push(this.addAccountSetting('recordingFilename'));
            accountSettings.push(this.addAccountSetting('transmissionEnabled'));
            accountSettings.push(this.addAccountSetting('transmissionRule'));

            formValues.accountSettings = accountSettings;
            formValues.ftpSettings.name = 'Case Transmit';

            if (this.ftpSettings) {
                formValues.ftpSettings.id = this.ftpSettings.id;
                this.accountSettingsService.editAccountFtpSettings(this.accountInfo, this.ftpSettings.id, formValues)
                    .subscribe(
                        () => {
                            this.showForm = false;
                            this.getSettings();
                            this.appService.showMsg('success', 'Saved successfully ...');
                        }
                    );
            } else {
                this.accountSettingsService.addAccountFtpSettings(this.accountInfo, formValues)
                    .subscribe(
                        () => {
                            this.showForm = false;
                            this.getSettings();
                            this.appService.showMsg('success', 'Saved successfully ...');
                        }
                    );
            }
        }
    }

    addAccountSetting(settingName) {
        {
            const newValue = this.ftpSettingsForm.get(settingName).value;

            return {
                accountId: this.ftpSettingsForm.get('accountId').value,
                name: settingName,
                value: newValue != null ? newValue.toString() : null
            };
        }
    }
}
