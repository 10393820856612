import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TableColumnDefinition } from 'app/shared/components/mrs-table/models/table-models';
import { AppService } from '../../app.service';
import { ReportDownloadService } from '../../services/reportdownload.service';
import moment = require('moment');

@Component({
    selector: 'user-access-report',
    host: { 'class': 'd-flex flex-row flex-fill custom-scrollbar' },
    templateUrl: './user-access-report.component.html',
    styleUrls: ['./user-access-report.component.scss']
})
export class UserAccessReportComponent implements OnInit {

    public clients: any = [];
    public accounts: any = [];
    public orderForm: UntypedFormGroup;
    public filteredData: any = [];
    public noDataPlaceholder = false;
    public selectedRecord: any;
    timeZone: string;
    tableConfig: any = [];
    @Input() scrollWidth;
    @Input() scrollHeight;

    displayedColumns: TableColumnDefinition[];
    reportData: any;
    searchableFields: [];

    constructor(
        private appService: AppService,
        private reportDownloadService: ReportDownloadService,
        private _fb: UntypedFormBuilder
    ) {
        this.timeZone = this.appService.getTimeZone();
    }

    ngOnInit() {
        this.getClients();
        this.orderForm = this._fb.group({
            clientId: ['', Validators.required],
            accountId: ['', Validators.required]
        });

        this.tableConfig = this.getTableConfig();

    }

    getClients() {
        this.appService.getData('Clients').subscribe(
            data => {
                this.clients = data.data;
            }
        );
    }

    getSelectedClientId(): string {
        const accountId = this.orderForm.get('accountId').value;
        const account = this.accounts.find(a => a.id === accountId);
        return account.clientId;
    }

    getAccounts() {
        const clientId = this.orderForm.get('clientId').value;
        if (clientId != null) {
            this.appService.getData(`Accounts/${clientId}/Client`, clientId)
                .subscribe(
                    data => {
                        this.accounts = data.data;
                    }
                );
        }
    }

    getReport() {
        if (this.orderForm.valid) {
            this.filteredData = [];
            const url = `Reports/GetAccountRolesReport`;

            this.appService.postData(url, this.orderForm.value, false, false, this.getSelectedClientId())
                .subscribe(
                    data => {
                        if (data.status === 'success') {
                            this.filteredData = data.data;

                            this.reportData = data.data.map((data) => ({
                                ...data,
                                lastLoginDate: moment(data.lastLoginDate).utc().format('MM/DD/yyyy HH:mm:ss')
                            }));
                        }

                        this.noDataPlaceholder = !this.filteredData.length;
                    }
                );
        }
    }

    downloadReport() {
        this.reportDownloadService.downloadReport('application/csv', 'Reports/DownloadCSV/GetAccountRolesReport', this.orderForm.value, this.getSelectedClientId());
    }

    getTableConfig() {

        this.displayedColumns = [
            {
                def: 'user',
                header: 'User',
                sortFields: ['user'],
                columnStyles:
                {
                    'width': '250px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'user',
                    }
                ]
            },
            {
                def: 'userRole',
                header: 'User Role',
                sortFields: ['userRole'],
                columnStyles:
                {
                    'width': '150px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'userRole',
                    }
                ]
            },
            {
                def: 'client',
                header: 'Client',
                sortFields: ['client'],
                columnStyles:
                {
                    'width': '100px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'client',
                    }
                ]
            },
            {
                def: 'account',
                header: 'Account',
                sortFields: ['account'],
                columnStyles:
                {
                    'width': '150px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'account',
                    }
                ]
            },
            {
                def: 'lastLoginDate',
                header: 'Last Access Date',
                sortFields: ['lastLoginDate'],
                columnStyles:
                {
                    'width': '100px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'lastLoginDate',
                    }
                ]
            },
            {
                def: 'actions',
                header: '',
                columnStyles:
                {
                    'width': '10px'
                },
                isAction: true,
                actions: []
            }
        ];

        return [{
            title: 'User',
            dataCol: 'user',
            width: '100px',
            format: 'none'
        }, {
            title: 'Role',
            dataCol: 'userRole',
            width: '100px',
            format: 'none'
        }, {
            title: 'Client',
            dataCol: 'client',
            width: '150px',
            format: 'none'
        }, {
            title: 'Account',
            dataCol: 'account',
            width: '150px',
            format: 'none'
        }, {
            title: 'Last Access Date',
            dataCol: 'lastLoginDate',
            width: '200px',
            format: 'date'
        }];
    }
}
