<form (ngSubmit)="saveRule()" *ngIf="this.config" [formGroup]="ruleForm" [ngClass]="{'sub-rule': isSubRule}"
      class="rule-container mat-card" spellcheck="false">

    <form (ngSubmit)="saveRule()" *ngIf="this.config" [formGroup]="ruleForm" [ngClass]="{'sub-rule': isSubRule}"
          class="rule-container mat-card" spellcheck="false">

        <input formControlName="AccountId" type="hidden">
        <input formControlName="clientId" type="hidden">
        <input formControlName="id" type="hidden">

    <mat-form-field class="w-100 p-2" *ngIf="isEditView" hintLabel="Max 120 characters">
        <mat-label>Rule Name</mat-label>
        <input matInput #input type="text" formControlName="name" autocomplete="off" maxlength="120">
        <mat-hint align="end">{{input.value.length}}/120</mat-hint>
        <mat-error *ngIf="ruleForm.get('name').hasError('required')">
            Rule Name is
            <strong>required</strong>
        </mat-error>
    </mat-form-field>    

        <div [ngClass]="{'rule-success': testResult?.ruleResult && isTestExecuted, 'rule-fail': !testResult?.ruleResult && isTestExecuted}" class="rule-header">
            <button (click)="expanded = !expanded" *ngIf="isSubRule" class="btn btn-link btn-sm custom" type="button">
                <i [ngClass]="{'fa-angle-right': !expanded, 'fa-angle-down': expanded}" aria-hidden="true" class="fa"></i>
            </button>
            <button (click)="collapseChildren = !collapseChildren" class="btn btn-link btn-sm custom" type="button">
                <i [ngClass]="{'fa-angle-double-down': collapseChildren, 'fa-angle-double-right': !collapseChildren}" aria-hidden="true" class="fas"></i>
            </button>

            <span class="d-inline-block">
            <mat-radio-group [disabled]="!isEditView" formControlName="filterId">
                <mat-radio-button value="00000000-0000-0000-0000-000000000001" [disabled]="!isEditView">All</mat-radio-button>
                <mat-radio-button value="00000000-0000-0000-0000-000000000002" [disabled]="!isEditView">Any</mat-radio-button>
                <mat-radio-button value="00000000-0000-0000-0000-000000000003" [disabled]="!isEditView">None</mat-radio-button>
            </mat-radio-group>
            <i [ngClass]="{'fa-check-circle text-success': testResult?.ruleResult && isTestExecuted, 'fa-times-circle text-danger': !testResult?.ruleResult && isTestExecuted}" aria-hidden="true" class="fa"></i>
        </span>

            <span class="d-inline-block">
            <mat-error *ngIf="formSubmitted && ruleForm.get('filterId').hasError('required')">
                required
            </mat-error>
        </span>

            <span *ngIf="!isSubRule" class="float-right">
            <button (click)="addConditionSet()" *ngIf="isEditView && checkACL('W')" class="btn btn-link btn-sm custom" type="button">
                <i aria-hidden="true" class="fa fa-plus"></i> Add Condition Set
            </button>
            <button (click)="showSubRuleForm()" *ngIf="isEditView && checkACL('W')" class="btn btn-link btn-sm custom" type="button">
                <i aria-hidden="true" class="fa fa-arrow-down"></i> Insert Sub Rule
            </button>
            <button (click)="editRule()" *ngIf="!isEditView && checkACL('W')" class="btn btn-link btn-sm custom" type="button">
                <i aria-hidden="true" class="fa fa-edit"></i> Edit
            </button>
            <button (click)="testRule()" *ngIf="!isEditView && checkACL('W')" class="btn btn-link btn-sm custom" type="button">
                <i aria-hidden="true" class="fa fa-flask"></i> Test
            </button>
            <button (click)="clearTestResults.emit()" *ngIf="!isEditView && isTestExecuted && checkACL('W')" class="btn btn-link btn-sm custom" type="button">
                <i aria-hidden="true" class="fa fa-times"></i> Clear Test Results
            </button>
            <button *ngIf="isEditView && checkACL('W')" class="btn btn-link btn-sm custom" type="submit">
                <i aria-hidden="true" class="fa fa-check"></i> Save
            </button>
            <button (click)="cancelRule()" *ngIf="isEditView && !ruleForm.get('id').value && checkACL('W')" class="btn btn-link btn-sm custom"
                    type="button">
                <i aria-hidden="true" class="fa fa-times"></i> Cancel
            </button>
            <button (click)="viewRule()" *ngIf="isEditView && ruleForm.get('id').value && checkACL('W')" class="btn btn-link btn-sm custom"
                    type="button">
                <i aria-hidden="true" class="fa fa-times"></i> Close
            </button>
                <!-- [disabled]="!ruleForm.valid"> -->
                <!-- <button type="button" class="btn btn-link btn-sm custom" (click)="deleteRule()">
                    <i aria-hidden="true" class="far fa-trash-alt"></i>
                </button> -->
        </span>
            <span *ngIf="isSubRule" class="float-right">
            <button (click)="deleteSubRule()" *ngIf="checkACL('W')" class="btn btn-link btn-sm custom" type="button">
                <i aria-hidden="true" class="far fa-trash-alt"></i> Delete
            </button>
        </span>
        </div>


        <div [hidden]="!expanded" class="rule-body">
            <ng-container *ngFor="let cs of ruleForm.get('conditionSets')['controls']; let i = index" formArrayName="conditionSets">
                <!-- {{cs | json}} -->
                <conditionset (deleteConditionSet)="deleteConditionSet(i)" *ngIf="cs.get('type').value == 'ConditionSet'" [collapseMe]="collapseChildren" [comboData]="comboData" [conditionSet]="getConditionSet(i)"
                              [config]="cs" [formGroupName]="i" [formSubmitted]="formSubmitted" [isEditView]="isEditView"
                              [isSubRule]="isSubRule" [isTestExecuted]="isTestExecuted" [mapKeys]="mapKeys" [nonListNonVirtualMapKeys]="nonListNonVirtualMapKeys"
                              [nonVirtualMapKeys]="nonVirtualMapKeys" [rules]="rules" [testResult]="getConditionSetTestResult(i)" [variableMapKeys]="variableMapKeys"></conditionset>

                <subrule (deleteSubRuleEvent)="deleteConditionSet(i)" *ngIf="cs.get('type').value == 'Rule'" [accountInfo]="accountInfo" [collapseMe]="collapseChildren" [comboData]="comboData" [config]="cs"
                         [formGroupName]="i" [isParentEditView]="isEditView" [isTestExecuted]="isTestExecuted" [mapKeys]="mapKeys" [nonListNonVirtualMapKeys]="nonListNonVirtualMapKeys"
                         [nonVirtualMapKeys]="nonVirtualMapKeys" [rules]="rules" [testResult]="getConditionSetTestResult(i)" [variableMapKeys]="variableMapKeys"></subrule>

            </ng-container>
            <ng-container *ngIf="!ruleForm.get('conditionSets')['controls'].length">
                <div [ngClass]="{'text-danger': formSubmitted, 'text-muted': !formSubmitted}" class="text-center">Add a Condition Set or Insert a Sub Rule</div>
            </ng-container>
        </div>
    </form>

    <div *ngIf="!this.config" class="p-4 text-center text-muted">
        <img alt="" class="pb-3" src="./assets/images/no-data-placeholder.png"/>
        <h6>Select a Rule</h6>
    </div>

    <p-dialog [(visible)]="showInsertSubRule" appendTo="body" header="Insert Sub Rule" modal="true" width="500">
        <form (ngSubmit)="insertSubRule()" [formGroup]="subRuleSelectForm">
            <auto-suggest [list]="subRuleList" [showRequiredError]="subRuleSelectForm.get('targetRuleId').hasError('required')" dataKey="id" field="name" formControlName="targetRuleId" placeholder="Select Sub Rule"></auto-suggest>

            <div class="pt-4 text-right">
                <button class="btn btn-outline-primary btn-sm" type="submit">
                    <i aria-hidden="true" class="fa fa-arrow-down"></i> Insert Sub Rule
                </button>
                <button (click)="showInsertSubRule = false" class="btn btn-outline-secondary btn-sm" type="submit">
                    <i aria-hidden="true" class="fa fa-time"></i> Close
                </button>
            </div>
        </form>
    </p-dialog>
</form>
