import * as dropin from 'braintree-web-drop-in';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { BraintreeService, NotificationSeverity } from '../../services';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CaseBraintreePayload } from '../../models';
import { InterviewService } from '../../interview.service';
import { AppService } from '../../app.service';

@Component({
    selector: 'braintree-mrs',
    host: { 'class': 'content' },
    templateUrl: './braintree.component.html'
})
export class BrainTreeComponent implements OnInit {

    @ViewChild('payButton') button;

    authToken: string;
    clientId: string;
    caseId: string;
    accountId: string;
    caseIntegrationQueueId: string;

    isSuccessfulVault: boolean = false;
    verifyingPayment: boolean = false;
    errors: string = null;

    constructor(
      public appService: AppService,
      public brainTreeService: BraintreeService,
      public interviewService: InterviewService,
      public dialog: MatDialog,
      @Inject(MAT_DIALOG_DATA) public data
    ) {
        this.authToken = data.brainTreeAuth;
        this.clientId = data.clientId;
        this.caseId = data.caseId;
        this.accountId = data.accountId;
        this.caseIntegrationQueueId = data.caseIntegrationQueueId;
    }
  
    ngOnInit() {
        dropin.create({ 
            authorization: this.authToken,
            container: '#dropin-container',
            card: {
                cardholderName: {
                    required: true
                }
            }
        }, function (err, instance) {
            if (err) {
                this.appService.showMsg(NotificationSeverity.Error, 'Unable to create the payment container.');
                console.error(err);
                return;
            }

            document.getElementById('payButton').addEventListener('click', function () {
                instance.requestPaymentMethod(async function (requestPaymentMethodErr, payload) {
                    if (requestPaymentMethodErr) {
                        this.appService.showMsg(NotificationSeverity.Error, 'Unable to verify the payment information.');
                        console.error(requestPaymentMethodErr);
                        return;
                    }

                    this.verifyingPayment = true;
                    this.errors = null;
                    const braintreePayload: CaseBraintreePayload = {
                        accountId: this.accountId,
                        clientId: this.clientId,
                        caseId: this.caseId,
                        caseIntegrationQueueId: this.caseIntegrationQueueId,
                        nonce: payload.nonce,
                        cardHolderName: payload.details.cardholderName,
                        cardType: payload.details.cardType,
                        expirationMonth: payload.details.expirationMonth,
                        expirationYear: payload.details.expirationYear,
                        lastFour: payload.details.lastFour,
                        binData: JSON.stringify(payload.binData)
                    };

                    const response = await this.brainTreeService.submitPayment(braintreePayload);
                    this.verifyingPayment = false;
                    this.isSuccessfulVault = response.isSuccessful;
                    
                    if (!this.isSuccessfulVault) {
                        instance.clearSelectedPaymentMethod();
                        this.errors = response.errors;
                    } 
                    
                    this.interviewService.mergeMapkeys(response.caseMapKeys);
                    this.interviewService.updateQuestionPreActionsForSection(this.interviewService.getInterviewData().activeSectionId);
                  
                }.bind(this));
            }.bind(this));
        }.bind(this));

    }
}