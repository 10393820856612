import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AppService } from "app/app.service";
import { CaseAssignee } from "app/models";
import { CaseAssigneeService, RoutingService } from "app/services";

@Component({
    selector: 'case-assignment-and-restart',
    template: ''
})
export class CaseAssignmentAndRestartComponent implements OnInit {

    clients: any[];

    constructor(public caseAssigneeService: CaseAssigneeService,
        public activatedRoute: ActivatedRoute,
        public appService: AppService,
        public routingService: RoutingService)
    {}
    
    
    ngOnInit(): void {
        this.getClients();
    }

    getClients() {
        this.appService.getData('Clients').subscribe(
            data => {
                this.clients = data.data;
                this.assignCase();
            }
        );
    }

    assignCase(){
        const caseId = this.activatedRoute.snapshot.queryParams['case'];
        const clientCode = this.activatedRoute.snapshot.queryParams['client'];
        const client = this.clients.find(x => x.code == clientCode);

        const caseAssignee : CaseAssignee = 
            {
                caseId : caseId,
                clientCode: client.id,
                userId: this.appService.getUserId()
            };
        this.caseAssigneeService.setAssigneeToCase(caseAssignee).subscribe(() => { 
            this.restartCase();
        },
        error => {
            this.restartCase();
        });
    }

    restartCase(){
        const caseId = this.activatedRoute.snapshot.queryParams['case'];
        const client = this.activatedRoute.snapshot.queryParams['client'];
        const account = this.activatedRoute.snapshot.queryParams['account'];
        const version = this.activatedRoute.snapshot.queryParams['version'];

        this.routingService.navigateToUrl(`client/${client}/account/${client}-${account}/${version}/interview/${caseId}`);
    }
}