<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content>
  <ng-template [ngIf]="data.messageMode === messageMode.Html">
    <div [innerHtml]="data.message"></div>
  </ng-template>
  <p *ngIf="data.messageMode === messageMode.Text">{{data.message}}</p>
</div>
<div mat-dialog-actions>
  <div class="flex-1"></div>

    <mrs-btn-tertiary
      *ngIf="data.showCancel"
      [textColor]="data.cancelTextLabel"
      [buttonLabel]="data.cancelLabel"
      buttonId="confirmationCancelBtn"
      (onClick)="onCancel()">
    </mrs-btn-tertiary>

    <mrs-btn-tertiary
      [buttonLabel]="data.okLabel"
      [textColor]="data.okTextColor"
      buttonId="confirmationOkBtn"
      (onClick)="onOk()">
  </mrs-btn-tertiary>

</div>
