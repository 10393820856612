import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppService } from '../../../../../app.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AccountEvent, AccountMessage } from '../../../../../models';
import { AccountEventsService } from '../../../../../services';

@Component({
    selector: 'account-event-action',
    templateUrl: './event-action-sms.component.html'
})
export class EventSMSActionComponent implements OnInit {

    public messageForm: UntypedFormGroup;
    template: any;
    messageId: any;
    formSubmitted: boolean = false;
    event: AccountEvent;
    mapKeys: any;

    constructor(
        private appService: AppService,
        private accountEventsService: AccountEventsService,
        private _fb: UntypedFormBuilder,
        public dialogRef: MatDialogRef<EventSMSActionComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit() {
        this.messageForm = this._fb.group({
            to: ['', Validators.required],
            content: ['', Validators.required]
        });

        this.event = this.data.event;
        this.mapKeys = this.data.mapKeys;

        if (this.event.configuration) {
            const configuration = JSON.parse(this.event.configuration);
            this.messageId = configuration['messageId'];

            this.accountEventsService.getAccountEventMessage(this.event.accountId, this.messageId).subscribe(res => {
                this.template = JSON.parse(res.template);

                this.messageForm.patchValue({
                    to: this.template['to'],
                    content: this.template['content']
                });
            });
        } else if (this.event.accountMessage) {
            this.template = JSON.parse(this.data.accountMessage.template);
            this.messageForm.patchValue({
                to: this.template['to'],
                content: this.template['content']
            });
        }
    }

    saveAccountMessage() {
        const message = this.messageForm.value;
        this.formSubmitted = true;

        if (this.messageForm.valid) {
            if (this.event.accountMessage) {
                this.event.accountMessage.template = JSON.stringify(message);
            } else {
                const accountMessage: AccountMessage = {
                    id: this.messageId,
                    template: JSON.stringify(message)
                };
                this.event.accountMessage = accountMessage;
            }

            this.dialogRef.close();
        } else {
            this.messageForm.markAsTouched();
            this.messageForm.controls.content.markAsTouched();
        }
    }

}
