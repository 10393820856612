<div class="signer flex align-items-center" style="padding-bottom: 15px;">
  <div class="flex-1">
    <div class="bold-label-18">
      {{ signer.name }}
      <i class="bold-label-18-description" *ngIf="signer.status === 'sent'">
        Agent-Directed Signing, choose a method to direct {{ signer.name }} to the portal:
      </i>
    </div>
    <div><small>{{ signer.statusDescription }}</small></div>
  </div>
  <div class="signer--actions">
    <button class="material-default" mat-button disabled
      *ngIf="!envelopeStatus?.complete && signer.status !== 'completed'">Pending</button>
    <button class="material-default" mat-button disabled *ngIf="signer.status === 'completed'">
      <i class="fas fa-check text-success"></i>
    </button>
    <button class="material-default" mat-button disabled
      *ngIf="signer.status === 'declined' || signer.status === 'failed_id_check'">
      <i class="fas fa-times text-danger"></i>
    </button>
  </div>
</div>
<div style="margin-left: 10px; margin-right: 10px; font-size: 13px"
  *ngIf="!envelopeStatus?.complete && (signer.status === 'sent' || signer.status === 'delivered') ">
  <div class="flex flex-column">
    <div class="flex flex-row">
      <div [innerHTML]="instructions"></div>
      <div style="text-align: right; margin-left: auto;">
        <div class="access-code">{{ signer.signerInfo?.accessCode }}</div>
      </div>
    </div>
  </div>
  <div class="flex flex-column" style="padding-top: 10px;">
    <div class="bold-label-16">Contact Info for {{ signer.name }}</div>
    <div>
      <ul>
        <li *ngIf="email !== null && email !== ''">{{ email }}</li>
        <li *ngIf="phoneNumber !== null && phoneNumber !== ''">{{ phoneNumber }}</li>
      </ul>
    </div>
    <div class="flex flex-row">
      <button class="material-default" mat-raised-button (click)="sendCode('email')"
        [disabled]="signer.status === 'completed' || email === ''">Send Link via Email</button>
      <button class="material-default ml-5" mat-raised-button (click)="sendCode('sms')"
        [disabled]="signer.status === 'completed' || phoneNumber === ''">Send Link via SMS Text Message</button>
    </div>
  </div>
</div>