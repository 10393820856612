import { Injectable } from '@angular/core';

@Injectable()
export class LocalDataService {

    getUserRoleName() {
        return this.getUserDataItems('userRoleName');
    }

    getUserDataItems(key) {
        const data = JSON.parse(localStorage.getItem('data'));
        const keyItem = data[key] ? data[key] : '';
        return keyItem;
    }
}
