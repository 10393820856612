<div [formGroup]="group">
    <div formGroupName="actionParams">
        <mat-form-field *ngIf="config?.actionParamsType?.fieldType == 'Lookup'" class="w-100">
            <mat-label>{{config?.displayName}}</mat-label>
            <mat-select formControlName="{{config.id}}">
                <mat-option></mat-option>
                <mat-option *ngFor="let listValue of config?.LookupValues" [value]="listValue.id">
                    {{listValue.name}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="group.get(config.id)?.hasError('required')">required</mat-error>
        </mat-form-field>

        <mat-form-field *ngIf="config?.actionParamsType?.fieldType == 'Multiselect' && config?.params !== 'virtualMapKeys'" class="w-100">
            <mat-label>{{config?.displayName}}</mat-label>
            <mat-select formControlName="{{config.id}}" multiple>
                <mat-option *ngFor="let listValue of config?.LookupValues" [value]="listValue.id">
                    {{listValue.name}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="group.get(config.id)?.hasError('required')">required</mat-error>
        </mat-form-field>

        <mat-form-field *ngIf="config?.actionParamsType?.fieldType == 'Multiselect' && config?.params === 'virtualMapKeys'" class="w-100">
            <mat-label>{{config?.displayName}}</mat-label>
            <mat-select formControlName="{{config.id}}" multiple>
                <mat-option *ngFor="let listValue of virtualMapKeys" [value]="listValue.id" [matTooltip]="listValue.entityHierarchy" matTooltipPosition="left">
                    {{listValue.entityHierarchy}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="w-100 mb-1" *ngIf="config?.actionParamsType?.fieldType == 'Text'">
            <mat-label>{{config?.displayName}}</mat-label>
            <input matInput formControlName="{{config.id}}" autocomplete="off">
            <mat-error *ngIf="group.get(config.id)?.hasError('required')">required</mat-error>
        </mat-form-field>

        <auto-suggest *ngIf="config?.actionParamsType?.fieldType == 'AutoSuggest'" [list]="config?.LookupValues" [placeholder]="config?.displayName" field="name" dataKey="id" formControlName="{{config.id}}" [showRequiredError]="group.get(config.id)?.hasError('required')"></auto-suggest>

        <map-keys-dropdown
            *ngIf="config?.actionParamsType?.fieldType == 'MapKey'"
            [displayList]="mapKeys"
            [placeholder]="config?.displayName"
            formControlName="{{config.id}}"
            [formControl]="group.get('actionParams').get(config.id)"
            [showRequiredError]="group.get('actionParams').get(config.id)?.hasError('required')"
            [allowedTypes]="[mapKeyTypeEnum.NonVirtual]"></map-keys-dropdown>

        <app-followup-date-config *ngIf="config?.actionParamsType?.fieldType == 'FollowUpDate'" [config]="config" [formGroupRef]="group.get('actionParams')"></app-followup-date-config>
    </div>
</div>