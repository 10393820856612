import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FeatureToggle } from '@Enums';
import { FileUploadService, NotificationService, NotificationSeverity } from '@Services';
import { FeatureManagerService } from '@Services/global';
import { SubSink } from 'subsink';

import { AppService } from '@App';

@Component({
    selector: 'attach-document-dialog',
    templateUrl: 'attach-document-dialog.component.html',
})
export default class AttachDocumentDialogComponent implements OnInit, OnDestroy {

    constructor(
        public dialogRef: MatDialogRef<AttachDocumentDialogComponent>,
        private httpClient: HttpClient,
        private appService: AppService,
        private _fb: UntypedFormBuilder,
        public fileUploadService: FileUploadService,
        public featureManagerService: FeatureManagerService,
        private notificationService: NotificationService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }

    fileUploadProgress = 0;
    fileUploadComplete = true;
    fileData: any = {};
    fileName: any;
    file: any;
    attachmentForm: any;
    configData: any = [];
    validFile: any;
    isEdit = false;
    attachmentTags: string[] = [];
    useV2 = false;
    private subs = new SubSink();


    //#region Lifecycle

    ngOnInit() {
        this.useV2 = this.featureManagerService.getByName(FeatureToggle.CaseDocumentsAttachmentsUploadInChunks).enabled;

        // Form group
        this.attachmentForm = this._fb.group({
            id: this.data.document?.id || undefined,
            caseId: this.data.caseId,
            includeInCasePackage: this.data.document?.includeInCasePackage || false,
        });

        // init props
        this.isEdit = Boolean(this.data.document) || this.isEdit;
        this.attachmentTags = this.data.document?.tags || [];

        this.appService.getAllConfigData().then((data) => {
            this.configData = data;
        });
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    //#endregion

    selectFile(event) {
        const files = event.target.files;
        if (!files || files.length <= 0) {
            return;
        } else {
            const extension = files[0].name.match(/\.([^\.]+)$/)[1];
            if (extension.toLowerCase() === 'pdf') {
                const formData: FormData = new FormData();

                for (let i = 0; i < files.length; i++) {
                    formData.append(i.toString(), files[i], files[i].name);
                }
                this.fileData = formData;
                this.validFile = true;
            } else {
                this.notificationService.showNotification({severity: NotificationSeverity.Error, message: 'Only PDF files allowed'});
                this.validFile = false;
            }
        }
    }

    addAttachment(): void {
        const attachmentData = {
            ...this.attachmentForm.value,
            tags: this.attachmentTags
        };

        if (this.isEdit) {
            this.subs.add(this.appService.postData('CaseDocumentAttachment', attachmentData, true, true).subscribe(
                data => {
                    if (data.status === 'success') {
                        this.dialogRef.close(true);
                    } else {
                        this.notificationService.showNotification({severity: NotificationSeverity.Error, message: data.message});
                    }
                }));
        } else if (this.validFile) {
            const url = this.appService.getAPIBaseURL() + 'CaseDocumentAttachment';
            const options = {headers: this.appService.getUploadHttpHeaders()};

            this.fileData.append('data', JSON.stringify(attachmentData));
            this.subs.add(this.httpClient.post(url, this.fileData, options).subscribe((response: any) => {
                if (response.status === 'success') {
                    this.dialogRef.close(true);
                } else {
                    this.notificationService.showNotification({severity: NotificationSeverity.Error, message: response.message});
                }
            }));
        }
    }

    cancel(): void {
        this.dialogRef.close(false);
    }

    selectFileV2(event) {
        const files = event.target.files;
        if (!files || files.length <= 0) {
            return;
        } else {
            this.file = files[0];
            const extension = files[0].name.match(/\.([^\.]+)$/)[1];
            if (extension.toLowerCase() === 'pdf' || extension.toLowerCase() === 'tiff') {
                const formData: FormData = new FormData();

                for (let i = 0; i < files.length; i++) {
                    formData.append(i.toString(), files[i], files[i].name);
                    this.fileName = files[i].name;
                }
                this.fileData = formData;
                this.validFile = true;
            } else {
                this.notificationService.showNotification({severity: NotificationSeverity.Error, message: 'Only PDF files allowed'});
                this.validFile = false;
            }
        }
    }

    async addAttachmentV2() {
        const attachmentData = {
            ...this.attachmentForm.value,
            tags: this.attachmentTags
        };

        // Update the metadata on the file DB record
        if (this.isEdit) {
            this.subs.add(this.appService.postData('CaseDocumentAttachment', attachmentData, true, true).subscribe({
                next: data => {
                    if (data.status === 'success') {
                        this.dialogRef.close(true);
                        return;
                    }
                    this.notificationService.showNotification({severity: NotificationSeverity.Error, message: data.message});
                }
            }));
        }
        // Upload the file
        else if (this.validFile) {
            this.fileUploadComplete = false;
            await this.fileUploadService.sendCaseAttachmentInChunks(this.data.account.clientId, this.data.account.id, this.data.caseId, attachmentData, this.file)
                .then(commitResult => {
                    this.subs.add(commitResult.subscribe({
                        next: _result => {
                            this.fileUploadComplete = true;
                            this.dialogRef.close(true);
                        },
                        error: error => {
                            this.fileUploadComplete = true;
                            this.appService.showResponseErrorMsg(error);
                        }
                    }));
                }).catch(err => {
                    this.fileUploadComplete = true;
                    this.appService.showResponseErrorMsg(err);
                });
        }
    }
}
