<div class="action-card">
    <div class="drag-drop-box ">
        <div>
            <button type="button" class="btn btn-link btn-sm" (click)="expanded = !expanded">
                <i aria-hidden="true" class="fa" [ngClass]="{'fa-angle-right': !expanded, 'fa-angle-down': expanded}"></i>
            </button>
            <span data-testid="actionHeader" class="d-inline-block ml-1" *ngIf="!expanded">
                {{actionLabel}}
            </span>
        </div>
        <div class="pr-1 actions">
            <span [hidden]="expanded || action.id === ''" class="ml-1 drag-handle" data-testid="drag-handle">
                <ng-content select="[slot=drag-handle]">
                </ng-content>
            </span>
            <button id="editButton" type="button" *ngIf="checkACL('W')" class="btn btn-link btn-sm custom" (click)="editAction()">
                <i aria-hidden="true" class="fa fa-edit"></i>
            </button>
            <button id="deleteButton" *ngIf="checkACL('W')" type="button" class="btn btn-link btn-sm custom" (click)="onDeleteAction.emit()">
                <i aria-hidden="true" class="far fa-trash-alt"></i>
            </button>
        </div>
    </div>

    <div data-testid="actionDetails" class="action-card-body" [ngStyle]="{'display':expanded?'':'none'}">
        <div class="border-bottom row pb-1">
            <span class="col-4">Type:</span>
            <span class="col-8">{{this.actionTypeLabel}}</span>
        </div>
        <div class="border-bottom row py-1">
            <span class="col-4">Action:</span>
            <span *ngIf="accountSettingsLoaded && !disableNamedActions" class="col-8">{{this.actionSelectionLabel}}</span>
            <span *ngIf="accountSettingsLoaded && disableNamedActions" class="col-8">{{this.actionLabel}}</span>
        </div>
        <div *ngIf="accountSettingsLoaded && !disableNamedActions" class="border-bottom row py-1">
            <span class="col-4">Name:</span>
            <span class="col-8">{{this.actionName}}</span>
        </div>
        <div class="border-bottom row py-1" *ngFor="let param of actionParams;">
            <span class="col-4">{{param?.displayName}}:</span>
            <span class="col-8">{{formatParamValue(param)}}</span>
        </div>
        <div class="border-bottom row py-1">
            <span class="col-4">Rule:</span>
            <span class="col-8">{{this.ruleLabel}}</span>
        </div>
        <div class="border-bottom row py-1">
            <span class="col-4">Billing Event:</span>
            <span class="col-8">{{billingEventName}}</span>
        </div>
    </div>
</div>
