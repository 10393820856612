<div class="page-heading" id="subheading">
    <i aria-hidden="true" class="page-heading-icon fa fa-users"></i>
    <span class="page-heading-title">Client Details</span>
    <button class="float-right" mat-button color="basic" (click)="back()">
        <i aria-hidden="true" class="fa fa-chevron-left"></i> Back
    </button>
</div>

<div id="innercontent" class="custom-scrollbar" *ngIf="showClientDetails">
    <div class="container-fluid container-background">
        <div class="container pt-3 pb-5">
            <div class="new-client-container" *ngIf="!clientDetails?.id || (isClientEditable && clientDetails?.id)">
                <div class="client-name-image">
                    <div class="col-2 text-center">
                        <div class="client-image-container">
                            <img mat-card-sm-image alt="" class="company-logo" src="{{companyLogo}}">
                            <div class="camera-icon-layer">
                                <span class="camera-icon">
                                    <i aria-hidden="true" class="fa fa-camera" aria-hidden="true"></i>
                                    <input type="file" id=file class="camera-input" accept="image/*"
                                        (change)="uploadImage()" />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-10 position-relative">
                        <form class="client-name-input" [formGroup]="newClientForm" (ngSubmit)="saveClient()"
                            novalidate>
                            <div class="row">
                                <div class="col-6">
                                    <input type="hidden" formControlName='id'>
                                    <mat-form-field class="full-width">
                                        <mat-label>Client Name</mat-label>
                                        <input matInput formControlName="name"
                                            autocomplete="off">
                                        <mat-error *ngIf="newClientForm.get('name').hasError('required')">
                                            Client name
                                            <strong>required</strong>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-6 text-right">
                                    <button type="submit" mat-raised-button color="primary" class="mr-1"
                                        *ngIf="checkACL('Clients', 'W')">
                                        <i aria-hidden="true" class="fa fa-cloud"></i> Save
                                    </button>
                                    <button mat-button color="basic" (click)="cancel()">
                                        <i aria-hidden="true" class="fa fa-times"></i> Cancel
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div class="new-client-container" *ngIf="clientDetails && !isClientEditable">
                <div class="client-name-image">
                    <div class="col-2 text-center">
                        <div class="client-image-container">
                            <img mat-card-sm-image alt="" class="company-logo" src="{{clientDetails.logo}}">
                        </div>
                    </div>
                    <div class="col-10 position-relative">
                        <div class="row">
                            <div class="col-6">
                                <div class="label-title">Client Name</div>
                                <h5>{{clientDetails.name}}</h5>
                            </div>
                            <div class="col-6 text-right pr-4">
                                <button mat-button (click)="editClient()" *ngIf="checkACL('Clients', 'W')">
                                    <i aria-hidden="true" class="edit-icon fa fa-edit"></i> Edit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <mat-tab-group>
                        <mat-tab label="Accounts">

                            <div class="col-12 p-3 accounts-container">
                                <div class="row mb-3">
                                    <div class="col-12 text-right pr-4">
                                        <button type="submit" mat-raised-button color="primary" (click)="addAccount()"
                                            *ngIf="checkACL('Accounts', 'W')">
                                            <i aria-hidden="true" class="fa fa-plus-circle"></i> Add Account
                                        </button>
                                    </div>
                                </div>
                                <div class="p-4 text-center text-muted" *ngIf="accounts?.length ==0">
                                    <img src="./assets/images/no-data-placeholder.png" alt="" class="pb-3" />
                                    <h6>No Accounts Found</h6>
                                </div>

                                <div class="p-2 mb-2" *ngIf="checkACL('Accounts', 'R')">
                                    <div *ngIf="accounts?.length">
                                        <mat-card appearance="outlined" class="py-3 mb-3 px-4 item" *ngFor="let account of accounts"
                                            (click)="viewAccount(account)">
                                            <mat-card-content>
                                                <div class="row">
                                                    <div class="col-8 my-auto">
                                                        <div class="title">{{account.name}}</div>
                                                        <div class="content-details">
                                                            <button mat-icon-button>
                                                                <span
                                                                    *ngIf="account.statusId == '00000000-0000-0000-0000-000000000001'">
                                                                    <i aria-hidden="true"
                                                                        class="fa fa-check-circle text-success pr-1"></i>
                                                                    Active</span>
                                                                <span
                                                                    *ngIf="account.statusId == '00000000-0000-0000-0000-000000000002'">
                                                                    <i aria-hidden="true"
                                                                        class="fa fa-times-circle text-danger pr-1"></i>Inactive</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div class="col-4 text-right my-auto">
                                                    </div>
                                                </div>
                                            </mat-card-content>
                                        </mat-card>
                                    </div>
                                </div>
                            </div>
                        </mat-tab>
                        <mat-tab label="Contacts">
                            <div class="col-12 p-3 accounts-container">
                                <div class="col-12 mb-3 text-right">
                                    <button mat-raised-button color="primary" (click)="addContact()"
                                        *ngIf="checkACL('Clients', 'W')">
                                        <i aria-hidden="true" class="fa fa-plus-circle"></i> Add Contact
                                    </button>
                                </div>
                                <div class="p-4 text-center text-muted" *ngIf="contacts?.length ==0 && !newContact">
                                    <img src="./assets/images/no-data-placeholder.png" alt="" class="pb-3" />
                                    <h6>No Contacts Found</h6>
                                </div>
                                <div *ngIf="newContact">
                                    <contacts [data]='newContact' [type]="'contacts'"
                                        (onContactSaved)="getContacts(newContact.clientId)"
                                        (onCancel)="this.newContact=undefined"></contacts>
                                </div>
                                <div *ngIf="contacts && checkACL('Clients', 'R')">
                                    <contacts [data]='record' [type]="'contacts'"
                                        (onContactSaved)="getContacts(record.clientId)"
                                        *ngFor="let record of contacts;"></contacts>
                                </div>
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </div>
        </div>
    </div>
</div>