import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { BaseInterviewFormFieldComponent, BaseFormFieldServices } from '../base-interview-form-field/base-interview-form-field.component';

@Component({
    selector: 'health-answer',
    templateUrl: './health-answer.component.html',
    styleUrls: ['./health-answer.component.scss']
})
export class HealthAnswerComponent extends BaseInterviewFormFieldComponent implements OnInit, OnDestroy {
    healthQuestions = [];

    constructor(public services: BaseFormFieldServices) {

        super(services);
    }

    ngOnInit() {
        this.showHealthQuestions();

        this.subscriptions.push(this.group.get(this.config.id).valueChanges.subscribe(
            data => {
                // on value change, if the field has reflexive questions, show the corresponding questions for the selected option.
                this.onBlur();
            }
        ));

        super.initialize();
    }

    onBlur() {
        if (!this.services.interviewService.isReadOnly) {
            const fieldControl = this.group.get(this.config.id);
            if (fieldControl.value && fieldControl.value.toLowerCase() === 'yes' && this.config.mibDetails && this.config.mibDetails.length) {
                this.healthQuestions.forEach(question => {
                    question.display = false;
                    const healthForm = this.services.interviewService.healthForms[this.config.id];
                    if (question.mapKeyName === this.config.mapKeyName + 'Corrected') {
                        let answerValue = 'Yes';
                        if (question.listValues && question.listValues.length > 0) {
                            const listValue = question.listValues.find(l => l.value.toLowerCase() === 'yes');
                            if (listValue) {
                                answerValue = listValue.value;
                            }
                        }
                        this.services.interviewService.setQuestionAnswer(question, answerValue);
                        if (healthForm) {
                            const correctedFieldControl = healthForm.get(question.id);
                            if (correctedFieldControl) {
                                correctedFieldControl.setValue(question.answerValue);
                            }
                        }
                    } else {
                        if (this.config.mibDetails && this.config.mibDetails[0].relationRoleCode !== '') {
                            if (question.mapKeyName.toLowerCase().endsWith(this.config.mibDetails[0].relationRoleCode.toLowerCase())) {
                                if (healthForm) {
                                    const correctedFieldControl = healthForm.get(question.id);
                                    if (correctedFieldControl) {
                                        correctedFieldControl.setValue('');
                                    }
                                }
                            }
                        }
                    }
                });
            }

            if (fieldControl.value && fieldControl.value.toLowerCase() === 'no' && this.config.mibDetails && this.config.mibDetails.length) {
                const healthForm = this.services.interviewService.healthForms[this.config.id];
                this.healthQuestions.forEach(question => {
                    if (this.config.mibDetails && this.config.mibDetails[0].relationRoleCode !== '') {
                        if (question.mapKeyName.toLowerCase().endsWith(this.config.mibDetails[0].relationRoleCode.toLowerCase())) {
                            question.display = true;
                        } else if (question.mapKeyName === this.config.mapKeyName + 'Corrected') {
                            this.services.interviewService.setQuestionAnswer(question, '');
                            question.display = true;
                        }
                        if (healthForm) {
                            const correctedFieldControl = healthForm.get(question.id);
                            if (correctedFieldControl) {
                                correctedFieldControl.setValue('');
                            }
                        }
                    }
                });
            }
        }

        super.onBlur();
    }

    showHealthQuestions() {
        this.healthQuestions = [];
        const activeSection = this.services.interviewService.getActiveSection();
        if (activeSection) {
            for (const eachQuestion of activeSection.questions) {
                if (eachQuestion.parentQuestionId === this.config.id) {
                    this.healthQuestions.push(eachQuestion);
                }
            }
        }
    }

    ngOnDestroy() {
        delete this.services.interviewService.healthForms[this.config.id];
        super.ngOnDestroy();
    }
}
