import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { debounceTime, distinctUntilChanged, Subject } from "rxjs";

@Component({
    selector: 'mrs-search-field',
    templateUrl: './mrs-search-field.component.html',
    styleUrls: ['./mrs-search-field.component.scss']
})
export class MrsSearchFieldComponent implements OnInit {
    @Input() searchText: string;
    @Input() placeholder: string;
    @Input() hint: string;
    @Input() debounce = 400;
    @Output() searchTextChange: EventEmitter<string> = new EventEmitter<string>();

    userSearchUpdate = new Subject<string>();

    ngOnInit() {
        this.userSearchUpdate.pipe(
            debounceTime(this.debounce),
            distinctUntilChanged()
        ).subscribe(() => {
            this.searchTextChange.emit(this.searchText);
        });
    }

    onSearchTextChange() {
        this.userSearchUpdate.next(this.searchText);
    }

    clearSearchText() {
        this.searchText = '';
        this.userSearchUpdate.next(this.searchText);
        this.searchTextChange.emit(this.searchText);
    }
}
