import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import * as _ from 'lodash';

import { AppService } from '../../app.service';
import { LookupItem } from '../../models';

@Injectable()
export class LookupService {
    private _cache: { [key: string]: Promise<any> } = {};

    constructor(private appService: AppService) {}

    public getMapKeyTypes(): Promise<LookupItem[]> {
        return this.getCachedItem('lookup.mapKeyTypes', () => {
            return this.appService.getData('lookup/MapKeyTypes');
        });
    }

    private getCachedItem(cacheKey: string, fillCache: () => Observable<any>) {
        if (this._cache[cacheKey] == null) {
            this._cache[cacheKey] = lastValueFrom(fillCache())
                .catch(ex => {
                    this._cache[cacheKey] = null;
                    throw ex;
                });
        }

        return this._cache[cacheKey];
    }
}
