<app-navigation *ngIf="!isLoading"></app-navigation>

<ng-template #waitDialog>

  <div mat-dialog-content style="padding: 24px">
    <div *ngIf="!showForm">
      <div class="content flex flex-column mt-5 mb-5" style="align-items: center; justify-content: center;">
        <div class="center-bold-dialog-title m-2">
          Please wait while we load your application.
        </div>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #verificationDialog>
  <div id="main">
    <div id="header">
      <i aria-hidden="true" style="margin-left: 24px"></i>
      <div class="logo-container mr-2"></div>
    </div>
  </div>
  <form [formGroup]="purlVerificationForm" (ngSubmit)="verify()" novalidate autocomplete="off">
    <div mat-dialog-content *ngIf="!showForm">
      <div class="flex flex-column">
        <div class="page-sub-header mt-4 mb-4">{{purlData.accountPurlSettings.welcomeHeaderText}}</div>
        <div class="mb-4">
          {{purlData.accountPurlSettings.welcomeBodyText}}
        </div>
      </div>
      <div class="pt-4 flex pb-5" style="justify-content: center;" *ngIf="!showForm">
        <button type="button" mat-flat-button color="primary" (click)="showForm = true;" style="width: 100%">
          Next
        </button>
      </div>
    </div>
    <div mat-dialog-content *ngIf="showForm">
      <div class="content flex flex-column mt-4" style="align-items: center; justify-content: center;">
        <div *ngIf="inError" class="error-display-message">
          Sorry, the information provided is incorrect. Please try again.
        </div>
        <div formArrayName="verificationQuestions" class="w-100">
          <ng-container *ngFor="let control of verificationQuestions.controls; let i = index;">
            <div [formGroupName]="i" id="verificationQuestion" class="flex flex-column mt-3">
              <div [innerHtml]="control.get('questionText').value | safeHtml" class="bold-label-16"></div>
              <mat-form-field style="margin-top: -1rem">
                <input matInput formControlName="verificationAnswer">
                <mat-error *ngIf="control.get('verificationAnswer').hasError('required')">required</mat-error>
              </mat-form-field>
            </div>
          </ng-container>
        </div>
        <div class="pt-4 flex pb-5 w-100" style="justify-content: center;">
          <button type="submit" mat-flat-button color="primary" style="width: 100%">
            Verify
          </button>
        </div>
      </div>
    </div>
  </form>

</ng-template>

<ng-template #failedDialog>
  <div class="modal-dark-background">
    <h1 mat-dialog-title>
      <div class="flex flex-column center-bold-dialog-title">
        <mat-icon svgIcon="alert-circle" style="height: 100px; width: 100px;" class="mt-4"></mat-icon>
      </div>
    </h1>
    <div mat-dialog-content>
      <div class="content flex flex-column mb-4" style="align-items: center; justify-content: center;">
        <div style="text-align: center;font-size: 27px;  font-weight:700" class="m-4">The maximum login attempts has been exceeded.</div>
        <div style="text-align: center;font-size: 16px; " class="m-4">
          <div [innerHtml]="purlData.accountPurlSettings.verificationText | safeHtml" class="bold-label-16"></div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #linkExpiredDialog>
  <div class="modal-dark-background">
    <h1 mat-dialog-title>
      <div class="flex flex-column center-bold-dialog-title ">
        <mat-icon svgIcon="close-circle-outline" style="height: 100px; width: 100px;" class="mt-4"></mat-icon>
      </div>
    </h1>
    <div mat-dialog-content>
      <div class="content flex flex-column mb-4" style="align-items: center; justify-content: center;">
        <div style="text-align: center;font-size: 27px;  font-weight:700" class="m-4">Sorry, we can't find that page!</div>
        <div style="text-align: center;font-size: 16px; " class="m-4">
          <div [innerHtml]="purlData.accountPurlSettings.expirationMessageText | safeHtml" class="bold-label-16"></div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #errorDialog>
  <div class="modal-dark-background">
    <h1 mat-dialog-title>
      <div class="flex flex-column center-bold-dialog-title">
        <mat-icon svgIcon="close-circle-outline" style="height: 100px; width: 100px;" class="mt-4"></mat-icon>
      </div>
    </h1>
    <div mat-dialog-content>
      <div class="content flex flex-column mb-4" style="align-items: center; justify-content: center;">
        <div style="text-align: center;font-size: 27px;  font-weight:700" class="m-4">
          <div class="flex mb-3 bold-label-16" style="flex-direction: column;" *ngFor="let userError of userErrors">
            <div [innerHtml]="userError | safeHtml" class="bold-label-16"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
