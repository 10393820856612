import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppService } from '@App';
import { HubConnectionState } from '@microsoft/signalr';
import { Account, ManagementQueue, ManagementQueueDeleted, ManagementQueueUpdated, Rule } from '@Models';
import { BaseService, ManagementQueueService, ObservableService, SignalRService } from '@Services';
import { MultiSelectModel } from 'app/components/multi-select-dropdown-list/models/MultiSelectModel.interface';
import { groupBy } from 'app/shared/functions';
import { Subject } from 'rxjs';

import { IConfigService } from '../../config/iconfigservice';

@Injectable()
export class NewBusinessWorkbenchService extends BaseService {
    baseUrl: string;
    publicApiBaseUrl: string;
    signalRConnection: signalR.HubConnection;

    private queuesUpdatedSub = new Subject<string[]>(); // managementQueueIds[] (guid) updated
    queuesUpdated$ = this.queuesUpdatedSub.asObservable();

    private queuesDeletedSub = new Subject<string[]>(); // managementQueueIds[] (guid) deleted
    queuesDeleted$ = this.queuesDeletedSub.asObservable();

    constructor(
        public appService: AppService,
        public httpClient: HttpClient,
        public configService: IConfigService,
        public observableService: ObservableService,
        public activatedRoute: ActivatedRoute,
        public signalRService: SignalRService,
        public managementQueueService: ManagementQueueService
    ) {
        super(appService, configService, httpClient);

        this.baseUrl = this.appService.getAPIBaseURL();
        this.publicApiBaseUrl = this.configService.getConfiguration().publicApiUrl;
    }

    //#region Signalr

    startSignalRConnection(clientId: string) {
        if (!this.signalRConnection) {
            this.signalRConnection = this.signalRService.getClientSignalRConnection(clientId);

            this.signalRConnection.serverTimeoutInMilliseconds = 1000 * 60 * 15; // 15 minutes
            this.signalRConnection.on('connected', function (event) {
                console.log('SignalR Connected for client : ' + event);
            });

            this.signalRConnection.on('managementQueueUpdated', (payload: ManagementQueueUpdated) => {
                this.queuesUpdatedSub.next(payload.updatedQueues);
            });

            this.signalRConnection.on('managementQueueDeleted', (payload: ManagementQueueDeleted) => {
                this.queuesDeletedSub.next(payload.deletedQueues);
            });

            if (this.signalRConnection.state === HubConnectionState.Disconnected) {
                this.signalRConnection.start();
            }

            return this.signalRConnection;
        }
    }

    stopSignalRConnection() {
        if (this.signalRConnection) {
            this.signalRConnection.stop();
        }
    }

    //#endregion
    //#region Lifecycle

    ngOnDestroy() {
        this.stopSignalRConnection();
    }

    //#endregion

    getRules(clientId: string) {
        // ClientController.cs - Dictionary<Guid, List<Rule>>
        return super.getData<{ [guid: string]: Rule[] }>(`${this.publicApiBaseUrl}client/${clientId}/rules`);
    }

    getFilteredWorkbenchQueues(clientId: string) {
        return super.getData<ManagementQueue[]>(`${this.publicApiBaseUrl}newBusinessWorkbench/client/${clientId}/queues/filtered`, undefined, true);
    }

    deleteWorkbenchQueue(clientId: string, queueId: string) {
        return super.deleteData(`${this.publicApiBaseUrl}newBusinessWorkbench/client/${clientId}/queue/${queueId}`);
    }

    updateWorkbenchQueue(updatedQueues: ManagementQueue[], newQueues: ManagementQueue[], clientId) {
        const param = {
            clientId: clientId,
            updateQueues: updatedQueues,
            addQueues: newQueues
        };

        return super.putData(`${this.publicApiBaseUrl}newBusinessWorkbench/client/${clientId}/queues/modify`, param);
    }

    createWorkbenchQueue(queue: ManagementQueue) {
        return super.postData(`${this.publicApiBaseUrl}newBusinessWorkbench/queue`, queue);
    }

    getUniqueClients(accounts: Account[]): MultiSelectModel[] {
        const selectModels = new Array<MultiSelectModel>();
        const uniqueClients = groupBy<Account, string>(accounts, account => account.clientId);

        const hasMultipleClients = uniqueClients && uniqueClients.size > 1;
        uniqueClients.forEach(accounts => {
            selectModels.push({
                id: accounts[0].clientId,
                name: accounts[0].clientName ?? accounts[0].clientId,
                isSelected: !hasMultipleClients
            });
        });

        return selectModels;
    }
}
