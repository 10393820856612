import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MapKeyType } from 'app/enums';
import { AppService } from '../../../../../app.service';
import { AccountEvent, AccountMessage } from '../../../../../models';

@Component({
    selector: 'app-event-action-webhook',
    templateUrl: './event-action-webhook.component.html',
    styleUrls: ['./event-action-webhook.component.scss']
})
export class EventActionWebhookComponent implements OnInit {

    public webhookForm: UntypedFormGroup;
    formSubmitted = false;
    event: AccountEvent;
    template: any;
    messageId: any;
    secretKeys: any;
    headersForm: UntypedFormArray;
    outputForm: UntypedFormArray;
    mapkeys: any;
    mapKeyTypeEnum: any = MapKeyType;

    constructor(private _fb: UntypedFormBuilder,
              public appService: AppService,
              public dialogRef: MatDialogRef<EventActionWebhookComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    get headers() {
        return this.webhookForm.get('headers') as UntypedFormArray;
    }

    set headers(val: UntypedFormArray) {
        this.headers = val;
    }

    get outputs() {
        return this.webhookForm.get('outputs') as UntypedFormArray;
    }

    set outputs(val: UntypedFormArray) {
        this.webhookForm.setControl('outputs', val);
    }

    ngOnInit() {
        this.event = this.data.event;
        this.mapkeys = this.data.mapkeys;
        this.webhookForm = this._fb.group({
            url: ['', Validators.required],
            userName: [''],
            secret: [''],
            hmacRequest: this._fb.group(
                {
                    userName: [''],
                    secret: [''],
                }),
            oAuth2Request: this._fb.group(
                {
                    grantType: [''],
                    authContentType: [''],
                    tokenEndpoint: [''],
                    clientId: [''],
                    clientSecret: [''],
                    scope: ['']
                }),
            authType: [''],
            contentType: [''],
            methodType: [''],
            regenerateDocuments: [''],
            content: ['', Validators.required],
            retryAttempts: ['5'],
            expirationHours: ['1'],
            errorCaseOnFailure: [false],
            headers: this._fb.array([]),
            outputs: this._fb.array([])
        });
        this.setupForm();
        this.getSecretKeys();

    }

    setupForm() {
        if (this.event.accountMessage && this.event.accountMessage.template) {
            this.template = JSON.parse(this.event.accountMessage.template);

            const userName = this.template?.hmacRequest?.userName || this.template?.userName;
            const secret = this.template?.hmacRequest?.secret || this.template?.secret;

            this.webhookForm.patchValue({
                url: this.template?.url,
                userName,
                secret,
                hmacRequest: {
                    userName,
                    secret,
                },
                oAuth2Request: {
                    grantType: this.template.oAuth2Request?.grantType,
                    authContentType: this.template.oAuth2Request?.authContentType,
                    tokenEndpoint: this.template.oAuth2Request?.tokenEndpoint,
                    clientId: this.template.oAuth2Request?.clientId,
                    clientSecret: this.template.oAuth2Request?.clientSecret,
                    scope: this.template.oAuth2Request?.scope
                },
                authType: this.template?.authType,
                contentType: this.template?.contentType,
                methodType: this.template?.methodType,
                regenerateDocuments: this.template?.regenerateDocuments,
                content: this.template?.content,
                retryAttempts: this.template?.retryAttempts,
                expirationHours: this.template?.expirationHours,
                errorCaseOnFailure: this.template?.errorCaseOnFailure,
                headers: this.template.headers || [],
                outputs: this.template.outputs || [],
            });

            this.template.headers?.forEach((header: { key: string, value: string }) => this.addHeader(header.key, header.value));
            this.template.outputs?.forEach((output: { outputProperty: string, mapkeyId: string }) => this.addOutput(output.outputProperty, output.mapkeyId));
        }
    }

    addHeader(key?: string, value?: string) {
        this.headers.push(this._fb.group({
            key: key ?? '',
            value: value ?? ''
        }));
    }

    addOutput(outputProperty?: string, mapkeyId?: string) {
        this.outputs.push(this._fb.group({
            outputProperty: [outputProperty ?? '', Validators.required],
            mapkeyId: [mapkeyId ?? '', Validators.required]
        }));
    }

    saveAccountMessage() {
        const message = this.webhookForm.value;
        this.formSubmitted = true;

        this.webhookForm.markAllAsTouched();
        if (this.webhookForm.valid) {
            if (this.event.accountMessage) {
                this.event.accountMessage.template = JSON.stringify(message);
            } else {
                const accountMessage: AccountMessage = {
                    id: this.messageId,
                    template: JSON.stringify(message)
                };
                this.event.accountMessage = accountMessage;
            }

            this.dialogRef.close();
        } else {
            this.webhookForm.markAsTouched();
            this.webhookForm.controls.content.markAsTouched();
        }
    }

    getSecretKeys() {
        this.appService.getData(`ConfigData/GetSecretKeys`).subscribe(result => {
            if (result.status === 'success') {
                this.secretKeys = result.data.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0));
            } else {
                this.secretKeys = [];
            }
        });
    }

    removeHeader(index) {
        this.headers.removeAt(index);
    }

    removeOutput(index) {
        this.outputs.removeAt(index);
    }

}
