export enum CaseDocumentStatus {
    PendingSignature = '00000000-0000-0000-0000-000000000001',
    Completed = '00000000-0000-0000-0000-000000000002',
    Closed = '00000000-0000-0000-0000-000000000003',
    Cancelled = '00000000-0000-0000-0000-000000000004',
    Expired = '00000000-0000-0000-0000-000000000005',
    Voided = '00000000-0000-0000-0000-000000000006',
    Failed = '00000000-0000-0000-0000-000000000007',
    RequestSignature = '00000000-0000-0000-0000-000000000008',
    Processing = '00000000-0000-0000-0000-000000000009',
    Summarizing = '00000000-0000-0000-0000-000000000010',
    Summarized = '00000000-0000-0000-0000-000000000011',
    SummaryFailed = '00000000-0000-0000-0000-000000000012',
}
