import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MagnumComponent } from "@Components/third-party-section/magnum/magnum.component";
import { MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { MrsButtonsModule } from "@Shared";
import { MatCardModule } from "@angular/material/card";


@NgModule({
    declarations: [MagnumComponent],
    imports: [
        CommonModule,
        MatCardModule,
        MatButtonModule,
        MatDialogModule,
        MrsButtonsModule
    ],
    providers: [],
    exports: [MagnumComponent],
    entryComponents: [MagnumComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MagnumModule {
}
