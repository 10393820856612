<h1 mat-dialog-title>Configure SMS</h1>
  <div mat-dialog-content>
    <div class="flex-fill d-flex flex-column overflow-y-auto">
      <form [formGroup]="messageForm" novalidate autocomplete="off" >
        <mat-form-field class="w-100">
          <mat-label>To</mat-label>
          <input matInput formControlName="to">
          <mat-error *ngIf="formSubmitted && messageForm.get('to').hasError('required')">
            To
            <strong>required</strong>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100 textarea-formfield">
          <mat-label>Body</mat-label>
          <textarea matInput formControlName='content' rows="10" maxlength="300"></textarea>
          <mat-error *ngIf="formSubmitted && messageForm.get('content').hasError('required')">
            Body
            <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </form>
    </div>
  </div>
  <mat-dialog-actions align="end">
    <button id="closeButton" mat-button id="cancelButton" color="basic" class="mr-1 material-default" mat-dialog-close >
      CANCEL
    </button>
    <button id="okButton" mat-button color="primary" class="mr-1 material-default" (click)="saveAccountMessage()">
      OK
    </button>
  </mat-dialog-actions>
