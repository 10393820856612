<h1 mat-dialog-title>{{accountBillingEvent.name}}</h1>
<div mat-dialog-content>
    <p>This billing event cannot be removed because it is being used in the following locations:</p>
    <table class="w-100 mb-4" mat-table [dataSource]="accountBillingEvent.usageList">
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let event"> {{event.name}} </td>
        </ng-container>
        <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef> Type </th>
            <td mat-cell *matCellDef="let event"> {{event.type}} </td>
        </ng-container>
        <ng-container matColumnDef="actionName">
            <th mat-header-cell *matHeaderCellDef> Action </th>
            <td mat-cell *matCellDef="let event"> {{event.actionName}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let events; columns: columnsToDisplay"></tr>
    </table>
</div>
<div mat-dialog-actions align="end">
    <button mat-button class="material-default" color="primary" [mat-dialog-close] cdkFocusInitial>OK</button>
</div>