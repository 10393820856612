enum DocType {
    // UWPipeline DocTypes
    Unknown = '00000000-0000-0000-0000-000000000000',
    PDF = '00000000-0000-0000-0000-000000000001',
    TIFF = '00000000-0000-0000-0000-000000000002',
    XSLT = '00000000-0000-0000-0000-000000000003',
    HTML = '00000000-0000-0000-0000-000000000004',
    XML = '00000000-0000-0000-0000-000000000005',
    TEXT = '00000000-0000-0000-0000-000000000006',
    JSON = '00000000-0000-0000-0000-000000000007',
    XSD = '00000000-0000-0000-0000-000000000008',
    Integration = '00000000-0000-0000-0000-000000000010',
    CombinedDocument = '00000000-0000-0000-0000-000000000011',
    Transcript = '00000000-0000-0000-0000-000000000012',
    IntegrationResponse = '00000000-0000-0000-0000-000000000014',
    // Custom FE DocTypes (starts with 9)
    Image = '90000000-0000-0000-0000-000000000001',
    Audio = '90000000-0000-0000-0000-000000000002'
}

export { DocType };

