<div class="stats">

    <span class="stat goal" *ngIf="showGoal"><b>Goal:</b> {{ auditGoalPercent | number: '1.0-1'}}%</span>
    <span class="spacer" *ngIf="showGoal">|</span>
    <span>
        <b>Selected:</b>
        <audit-percent-label
            [totalCases]="totalCases"
            [allocatedCases]="selectedAuditCasesTotal"
            [auditGoal]="auditGoalPercent"
        />
    </span>
    <span class="spacer">|</span>
    <span>
        <b>Completed:</b>
        <audit-percent-label
            [totalCases]="totalCases"
            [allocatedCases]="completedAuditCasesTotal"
            [auditGoal]="auditGoalPercent"
        />
    </span>
</div>