import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';

@Component({
    selector: 'audio-player',
    templateUrl: './audio-player.component.html',
    styleUrls: ['./audio-player.component.scss']
})
export default class AudioPlayer implements OnChanges {
    @ViewChild('audioControl') audioControlRef: ElementRef;
    @Input() src: string;

    setSource() {
        this.audioControlRef.nativeElement.src = this.src;
        this.audioControlRef.nativeElement.load();
        this.audioControlRef.nativeElement.play();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.src.currentValue)
            this.setSource();
    }
}
