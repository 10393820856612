import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { FeatureManagerService } from 'app/services';

export interface MaskValueOptions {
    enabled?: boolean;
    visibleCharacters?: number;
    character?: string;
    clearOnFocus?: boolean;
}

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[maskValue]'
})
export class MaskValueDirective implements OnInit {

    @Input('maskValue')
    public options: MaskValueOptions;

    private _value: any;
    private _input: HTMLInputElement;
    private _inputType: string;

    constructor(private el: ElementRef, private form: ControlContainer,
        public featureService: FeatureManagerService) {
        this._input = el.nativeElement;
        this._inputType = `${this._input.type}`;
    }

    ngOnInit() {
        this.options = this.options || {};
        this.options.visibleCharacters = this.options.visibleCharacters || 4;
        this.options.character = this.options.character || '*';

        if (!this.options.enabled) {
            return;
        }

        this._value = this._input.value;
        this.formatValue();
    }

    private isDisabled() {
        return !this.options.enabled
            || this._input.readOnly
            || this._input.disabled;
    }

    @HostListener('input') onChange() {
        if (this.isDisabled()) {
            return;
        }

        this._value = this._input.value;
    }

    @HostListener('blur') onBlur() {
        if (this.isDisabled()) {
            return;
        }

        this.formatValue();
    }

    @HostListener('focus') onFocus() {
        if (this.isDisabled()) {
            return;
        }

        this._input.type = this._inputType;

        if (this.options.clearOnFocus) {
            this._input.value = '';
        } else {
            this._input.value = this._value;
        }
    }

    formatPrefillValue() {
        if (this.isDisabled() || !this.featureService.getByName('Case.LexisNexis.Prefill.MaskValues').enabled) {
            return;
        }

        this._value = this._input.value;
        this.formatValue();
    }

    formatValue() {
        this._input.type = 'text';

        const length = this._input.value.length;
        const visibleCharacters = +this.options.visibleCharacters;
        this._input.value = (length <= visibleCharacters) ? this._input.value :
            `${this.options.character.repeat(length - visibleCharacters)}${this._input.value.slice(length - visibleCharacters)}`;
    }
}
