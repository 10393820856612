<button mat-button
    *ngIf="isSubmitButton"
    id="{{buttonId}}"
    type="submit"
    class="mrs-tertiary no-hover-effect"
    [attr.data-testid]="buttonId"
    [disableRipple]="true"
    [disabled]="disabled || showSpinner"
    [ngClass]="{ 'spinner': showSpinner }"
    (click)="onClick.emit($event)">
    <span class="material-icons" [ngStyle]="{ 'color': textColor }">{{iconName}}</span>
    <span [ngStyle]="{ 'color': textColor }" class="inner-text-margin">{{buttonLabel}}</span>
</button>
<button mat-button
    *ngIf="!isSubmitButton && !isSvg"
    id="{{buttonId}}"
    type="button"
    class="mrs-tertiary no-hover-effect"
    [attr.data-testid]="buttonId"
    [disableRipple]="true"
    [disabled]="disabled || showSpinner"
    [ngClass]="{ 'spinner': showSpinner }"
    (click)="onClick.emit($event)">
    <span class="material-icons" [ngStyle]="{ 'color': textColor }">{{iconName}}</span>
    <span [ngStyle]="{ 'color': textColor }" class="inner-text-margin">{{buttonLabel}}</span>
</button>
<button mat-button
    *ngIf="!isSubmitButton && isSvg"
    id="{{buttonId}}"
    type="button"
    class="mrs-tertiary no-hover-effect"
    [attr.data-testid]="buttonId"
    [disableRipple]="true"
    [disabled]="disabled || showSpinner"
    [ngClass]="{ 'spinner': showSpinner }"
    (click)="onClick.emit($event)">
    <div [ngStyle]="{ 'color': textColor }" class="svg-inner-text-margin">
        <mat-icon [ngStyle]="{ 'color': textColor }" [svgIcon]="svgIconName"/>
        <span [ngStyle]="{ 'color': textColor }" class="inner-text-margin">{{buttonLabel}}</span>    
    </div>
</button>