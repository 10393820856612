import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IConfigService } from '@Config';
import { RulesDTO } from '@DTOs';
import { UWResponse } from '@Models';
import { BaseService } from '@Services';
import { AppService } from 'app/index';
import { catchError, map, of, take, tap } from 'rxjs';

/**
 * The RulesDataService (rules.data.service) is an Angular service that will fetch rules for an Account. These rules will be cached by account id for
 * a period of time and for a limited rolling number of accounts.
 * 
 * There is also a `rules.service.ts` as well as direct API calls using the `app.service.ts` such as:
 * ```
 * // (see: account-config.component.ts)
 * this.appService.getData<UWResponse<RulesDTO[]>>(`Rules/${this.account.id}`)
 * ```
 *  
 * The `rules.service.ts` only calls "simple rules" which is a limited list of rules. Also, since this service is already being used we did not not to modify it.
 * This service also follows the `data.service.ts` naming convention for services who's purpose is loading data with some front-end caching to limit repeat API
 * calls made by different components.
 * 
 * Use this service for loading sharable "read" data and replace direct API calls like `appService.getData('Rules/...')` with this service.
 */

@Injectable({
    providedIn: 'root'
})
export default class RulesDataService extends BaseService {
    private _cache = new Map<string, RulesDTO[]>(); // <AccountId, RulesDTO[]>
    private cacheKeyLimit = 4;

    constructor(
        public configService: IConfigService,
        public httpClient: HttpClient,
        public appService: AppService
    ) {
        super(appService, configService, httpClient);
    }


    /**
     * Clear the cache if we've over our cache key limit. This is a low-brow attempt to prevent rules from growing too large.
     * The threshold number was an educated guess on how many accounts a user interacts with vs how much data we should cache.
     */
    memorySaver() {
        if (this._cache.keys.length > this.cacheKeyLimit) this.clearCache();
    }

    clearCache() {
        this._cache.clear();
    }

    /**
     * Load the Rules for an account. This observable will complete after a value is returned and unsubscribe should not be necessary.
     * @param accountId 
     * @param clientId 
     * @param hideWaiting 
     * @returns 
     */
    load(accountId: string, clientId?: string, hideWaiting = false) {
        if (this._cache.has(accountId)) {
            return of(this._cache.get(accountId));
        }

        const url = `${this.baseApiUrl}Rules/${accountId}`; // [UWPipeline.Application] RulesController.cs (GetRules())
        return super.getData<UWResponse<RulesDTO[]>>(url, clientId, hideWaiting).pipe(
            take(1), // Close the observable after one value so we don't have to unsubscribe.
            map(response => {
                return response.data;
            }),
            tap(rules => {
                this.memorySaver();
                rules.sort((a, b) => a.name.localeCompare(b.name));
                this._cache.set(accountId, rules);
            }),
            catchError(err => {
                console.error(`Error loading Rules for account ${accountId}.`, err);
                return of([] as RulesDTO[]);
            }),
        );
    }

}
