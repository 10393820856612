import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from '@App';
import { FeatureToggle } from '@Enums';
import { AccountsByClient, Client } from '@Models';
import { FeatureManagerService } from '@Services';
import * as _ from 'lodash';

import { IConfigService } from '../../config/iconfigservice';
import { BaseService } from '../base.service';

@Injectable()
export class ClientService extends BaseService {

    publicApiBaseUrl: string;
    _accounts: any = null;

    constructor(public appService: AppService, public configService: IConfigService,
        public httpClient: HttpClient, public featureService: FeatureManagerService) {
        super(appService, configService, httpClient);
        this.publicApiBaseUrl = this.configService.getConfiguration().publicApiUrl;
    }

    // Consider front-end caching this result for a period of time. We'd want to clear this cache if clines or accounts where changed.
    // See: https://dev.azure.com/mrsreps/MRS/_wiki/wikis/UW%20Pipeline.wiki/1471/Angular-front-end-cache-API-results-for-period-of-time
    getClientsAndAccounts(hideWaiting?: boolean, useCache = false) {
        let endPoint = 'account';

        if (this.featureService.getByName(FeatureToggle.GlobalUseAuthenticationV2).enabled && useCache) {
            endPoint = 'accountv2/useCache';
        }


        if (this.featureService.getByName(FeatureToggle.GlobalUseAuthenticationV2).enabled && !useCache) {
            endPoint = 'accountv2';
        }

        const url = `${this.publicApiBaseUrl}global/client/${endPoint}`; // GlobalController.Client.Partial.cs
        return super.getDataForCache<Client[]>(url, null, hideWaiting);
    }

    clearCache() {
        this._accounts = null;
    }

    getClientUsers(clientId: string) {
        const url = `${this.publicApiBaseUrl}client/${clientId}/users`;
        return super.getData<any>(url, null);
    }

    getClientAndParentAccounts() {
        const url = `${this.publicApiBaseUrl}global/client/accountv2/parent`;
        return super.getData<any>(url, null);
    }


    formatClientsAndAccounts(clientsAndAccounts): AccountsByClient {
        const response: AccountsByClient = {
            clients: [],
            accountsByClient: {}
        };

        clientsAndAccounts = _.sortBy(clientsAndAccounts, [function (o) { return o.clientName; }]);

        const key = 'clientName';
        response.clients = this.getUniqueBy(key, clientsAndAccounts);
        response.accountsByClient = _.groupBy(clientsAndAccounts, 'clientId');

        return response;
    }

    private getUniqueBy = (prop, list) => {
        const objUniq = list.reduce((res, item) => ({ ...res, [item[prop]]: item }), {});
        return Object.keys(objUniq).map(item => objUniq[item]);
    };
}
