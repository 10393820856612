<section id="audit-view-page" class="ag-page" [formGroup]="auditViewForm">
    <div class="page-header">
        <span class="title">Audit > {{client?.name }}: {{ audit?.name }}</span>

        <div class="title-buttons">
            <mrs-btn-tertiary buttonLabel="Go Back" iconName="chevron_left" (onClick)="goBack()" />
            <mrs-btn-tertiary *ngIf="canViewAuditEdit" buttonLabel="Edit" svgIconName="square-edit-outline" (onClick)="goToEditPage()"/>
            <mrs-btn-tertiary *ngIf="canViewAuditRefresh" buttonLabel="Refresh" iconName="refresh" [disabled]="auditCasesRefreshing" (click)="onRefresh()"/>
            <mrs-btn-primary
                *ngIf="!isGroupByOn"
                [disabled]="customDateRange"
                buttonLabel="Next Available Case"
                matTooltip="Reset date range to use"
                [matTooltipDisabled]="!customDateRange"
                (onClick)="onSelectNextCase()"></mrs-btn-primary>
        </div>

    </div>

    <!-- Page Sub Header -->
    <div class="page-subheader">
        <div>
            {{ startDateDisplay }} - {{ endDateDisplay }}
        </div>
        <div *ngIf="canViewAuditRefresh && auditLoaded" class="last">
            <span>Last Refreshed:</span>            
            <span>{{ audit.lastRefreshedOnDay }} at {{ audit.lastRefreshedOnTime }}</span>
        </div>
    </div>

    <ng-container *ngIf="!auditLoaded">
        <mat-spinner mode="indeterminate" diameter="30"></mat-spinner>
    </ng-container>

    <div class="page-content" *ngIf="auditLoaded">
        <!-- Details -->
        <ul class="audit-details">
            <li class="detail">
                <span class="value">
                    <mat-form-field class="form-field status-filter-form-field">
                        <mat-label>Audit Status</mat-label>
                        <mat-select panelClass="auto-width-panel" formControlName="statusFilter" class="status-filter">
                            <mat-option
                                *ngFor="let statusFilter of statusFilters" [value]="statusFilter">
                                {{ statusFilter }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </span>
            </li>
            <li class="detail">
                <span class="value">
                    <mat-form-field class="form-field">
                        <mat-label>Date Range</mat-label>
                        <mat-date-range-input [rangePicker]="rangePicker" [min]="audit.startDate" [max]="audit.endDate">
                            <input matStartDate placeholder="Start date" formControlName="startDate">
                            <input matEndDate placeholder="End date" formControlName="endDate">
                        </mat-date-range-input>
                        <mat-hint *ngIf="customDateRange">Custom range selected</mat-hint>

                        <mat-error *ngIf="auditViewForm.controls.startDate.hasError('required') && !auditViewForm.controls.endDate.hasError('required')">start date is required</mat-error>
                        <mat-error *ngIf="!auditViewForm.controls.startDate.hasError('required') && auditViewForm.controls.endDate.hasError('required')">end date is required</mat-error>
                        <mat-error *ngIf="auditViewForm.controls.startDate.hasError('required') && auditViewForm.controls.endDate.hasError('required')">start and end date are required</mat-error>
                        <mat-error *ngIf="auditViewForm.controls.startDate.hasError('dateRange') || auditViewForm.controls.endDate.hasError('dateRange')">Dates outside of valid range. Cases not updated.</mat-error>

                        <mat-datepicker-toggle matIconSuffix [for]="rangePicker"></mat-datepicker-toggle>
                        <mat-date-range-picker #rangePicker></mat-date-range-picker>
                    </mat-form-field>
                </span>
            </li>
            <li class="detail">
                <audit-cases-table-stats
                    [auditGoalPercent]="audit.goal"
                    [totalCases]="audit.totalCases" 
                    [auditCases]="auditCases_filtered" />
            </li>  
        </ul>

        <!-- Cases -->
        <div *ngIf="isGroupByOn">
            <audit-group-table
                [auditCasesGroups]="auditCasesGroups"
                [noCasesMessage]="noCasesMessage"
                [disableActions]="customDateRange"
                [auditGroupByType]="audit.groupBy"
                [auditGoalPercent]="audit.goal"
                (onCompleteAuditCases)="onAuditCaseComplete($event)"
                (onSelectNextCase)="onSelectNextCaseForGroup($event)" />
        </div>
        <div *ngIf="!isGroupByOn">
            <audit-cases-table
                [auditCases]="auditCases_filtered"
                [noCasesMessage]="noCasesMessage"
                (onCompleteAuditCases)="onAuditCaseComplete($event)" />
        </div>
    </div>
</section>