import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';


@Component({
    selector: 'json-viewer',
    templateUrl: './json-viewer.component.html',
    styleUrls: ['./json-viewer.component.scss']
})
export default class JsonViewer implements OnChanges {
    @Input() src: string;

    contents = '';

    loadContents() {
        fetch(this.src).then(value => value.json()).then(txt => this.contents = txt);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.src.currentValue)
            this.loadContents();
    }
}
