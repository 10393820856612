<div [formGroup]="subRuleForm" class="rule-container" [ngClass]="{'sub-rule': isSubRule}">

    <input type="hidden" formControlName="targetRuleId">

    <div class="rule-header"  [ngClass]="{'rule-success': testResult?.ruleResult && isTestExecuted, 'rule-fail': !testResult?.ruleResult && isTestExecuted}">
        <ng-template [ngIf]="showSubRuleDetails">
            <button type="button" class="btn btn-link" *ngIf="isSubRule" (click)="expanded = !expanded">
                <i aria-hidden="true" class="fa" [ngClass]="{'fa-angle-right': !expanded, 'fa-angle-down': expanded}"></i>
            </button>
            <button type="button" class="btn btn-link" *ngIf="expanded" (click)="collapseChildren = !collapseChildren">
                <i aria-hidden="true" class="fas" [ngClass]="{'fa-angle-double-down': collapseChildren, 'fa-angle-double-right': !collapseChildren}"></i>
            </button>
        </ng-template>

        <span class="d-inline-block">
                <span class="muted">RULE: </span><span class="font-weight-bold">{{getRuleName(subRuleForm.get('targetRuleId').value)}}</span>
        </span>

        <span class="d-inline-block ml-5" *ngIf="showSubRuleDetails && expanded">
            <mat-radio-group [disabled]="true">
                <mat-radio-button value="00000000-0000-0000-0000-000000000001" [checked]="subRuleForm.get('filterId')?.value == '00000000-0000-0000-0000-000000000001'">ALL</mat-radio-button>
                <mat-radio-button value="00000000-0000-0000-0000-000000000002" [checked]="subRuleForm.get('filterId')?.value == '00000000-0000-0000-0000-000000000002'">ANY</mat-radio-button>
            </mat-radio-group>
            <i aria-hidden="true" class="fa" [ngClass]="{'fa-check-circle text-success': testResult?.ruleResult && isTestExecuted, 'fa-times-circle text-danger': !testResult?.ruleResult && isTestExecuted}"></i>
        </span>

        <span *ngIf="!showSubRuleDetails">
            <button type="button" class="btn btn-link btn-sm custom" (click)="getSubRuleDetails()">
                <!-- <i aria-hidden="true" class="fa fa-arrows-alt"></i>  -->
                Show Rule Details
            </button>
        </span>

        <span *ngIf="isParentEditView" class="float-right">
            <button *ngIf="checkACL('F')" type="button" class="btn btn-link custom" (click)="deleteSubRule()">
                <i aria-hidden="true" class="far fa-trash-alt"></i> Delete
            </button>
        </span>
    </div>
    <div class="rule-body" *ngIf="showSubRuleDetails" [hidden]="!expanded">
        <ng-container formArrayName="conditionSets" *ngFor="let cs of subRuleForm.get('conditionSets')['controls']; let i = index">
            <conditionset
                *ngIf="cs.get('type').value == 'ConditionSet'"
                [formGroupName]="i"
                [config]="cs"
                [isSubRule]="isSubRule"
                [isEditView]="isEditView"
                [mapKeys]="mapKeys"
                [nonListNonVirtualMapKeys]="nonListNonVirtualMapKeys"
                [variableMapKeys]="variableMapKeys"
                [nonVirtualMapKeys]="nonVirtualMapKeys"
                [collapseMe]="collapseChildren"
                [formSubmitted]="formSubmitted"
                [comboData]="comboData"
                [rules]="rules"
                [conditionSet]="getConditionSet(i)"
                [testResult]="getConditionSetTestResult(i)"
                [isTestExecuted]="isTestExecuted"
                ></conditionset>
                <!-- (deleteConditionSet)="deleteConditionSet(i)"  -->

            <subrule
                *ngIf="cs.get('type').value == 'Rule'"
                [accountInfo]="accountInfo"
                [formGroupName]="i"
                [config]="cs"
                [mapKeys]="mapKeys"
                [nonListNonVirtualMapKeys]="nonListNonVirtualMapKeys"
                [variableMapKeys]="variableMapKeys"
                [nonVirtualMapKeys]="nonVirtualMapKeys"
                [collapseMe]="collapseChildren"
                [comboData]="comboData"
                [rules]="rules"
                [testResult]="getConditionSetTestResult(i)"
                [isTestExecuted]="isTestExecuted"
                ></subrule>

        </ng-container>
        <ng-container *ngIf="!subRuleForm.get('conditionSets')['controls'].length">
            <div class="text-center" [ngClass]="{'text-danger': formSubmitted, 'text-muted': !formSubmitted}">Invalid Rule / Condition Set</div>
        </ng-container>
    </div>
</div>
