<div>
    <div *ngIf="auditCasesGroupsEnhanced.length == 0" class="no-cases">{{ noCasesMessage }}</div>

    <div *ngIf="auditCasesGroupsEnhanced.length > 0">
        <table mat-table multiTemplateDataRows [dataSource]="auditCasesGroupsEnhanced" class="expand-table">
            <!-- Templates -->
            <!-- Header -->
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row class="table-row" *matRowDef="let group; let i = index; columns: displayedColumns;"></tr>
            <tr mat-row *matRowDef="let group; columns: ['expandedContent']" class="table-row-expanded"></tr>

            <!-- Expand Arrow Column -->
            <ng-container matColumnDef="expand">
                <th mat-header-cell *matHeaderCellDef aria-label="actions">&nbsp;</th>
                <td mat-cell *matCellDef="let group" class="expand">
                    <button mat-icon-button aria-label="expand row" (click)="(expandedGroup = (expandedGroup?.name === group.name) ? null : group); $event.stopPropagation()">
                        <mat-icon *ngIf="expandedGroup?.name !== group?.name">keyboard_arrow_down</mat-icon>
                        <mat-icon *ngIf="expandedGroup?.name === group?.name">keyboard_arrow_up</mat-icon>
                    </button>
                </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>{{ nameLabel }}</th>
                <td mat-cell *matCellDef="let group">{{ group.name || "(Unassigned)" }}</td>
            </ng-container>

            <!-- Selected Stats Column -->
            <ng-container matColumnDef="selected">
                <th class="selected" mat-header-cell *matHeaderCellDef>Selected</th>
                <td mat-cell *matCellDef="let group">
                    <audit-percent-label
                        [totalCases]="group.auditStatistics.totalCases"
                        [allocatedCases]="group.auditStatistics.selectedCases"
                        [auditGoal]="auditGoalPercent"
                    />
                </td>
            </ng-container>

            <!-- Completed Stats Column -->
            <ng-container matColumnDef="completed">
                <th class="completed" mat-header-cell *matHeaderCellDef>Completed</th>
                <td mat-cell *matCellDef="let group">
                    <audit-percent-label
                        [totalCases]="group.auditStatistics.totalCases"
                        [allocatedCases]="group.auditStatistics.completedCases"
                        [auditGoal]="auditGoalPercent"
                    />
                </td>
            </ng-container>

            <!-- Select Next Button Column -->
            <ng-container matColumnDef="actions">
                <th class="completed" mat-header-cell *matHeaderCellDef>&nbsp;</th>
                <td mat-cell *matCellDef="let group" class="actions">
                    <mrs-btn-tertiary *ngIf="!hideActions"
                        [disabled]="disableActions"
                        [textColor]="MrsColor.UIBlue"
                        buttonLabel="Next Available Case"
                        matTooltip="Reset date range to use"
                        [matTooltipDisabled]="!disableActions"
                        (onClick)="selectNextCase(group)"></mrs-btn-tertiary>
                </td>
            </ng-container>

            <!-- Expanded Content Row - The detail row is made up of this one column that spans across all columns -->
            <ng-container matColumnDef="expandedContent">
                <td mat-cell *matCellDef="let group" [attr.colspan]="displayedColumns.length">
                    <div class="table-row-expanded-content" [@detailExpand]="group.name == expandedGroup?.name ? 'expanded' : 'collapsed'">
                        <div class="table-row-expanded-content-inner">
                            <audit-cases-table
                                class="cases-table"
                                [auditCases]="group.filteredCases"
                                [noCasesMessage]="noCasesMessage"
                                [stickyHeaders]="true"
                                (onCompleteAuditCases)="onCompleteCaseAudit($event)"></audit-cases-table>
                        </div>
                    </div>
                </td>
            </ng-container>
        </table>

    </div>
</div>