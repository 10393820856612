import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from 'app/app.service';
import { IConfigService } from 'app/config/iconfigservice';
import { BaseService, NotificationService, NotificationSeverity, ObservableService } from 'app/services';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AuraService extends BaseService {

    private auraSectionComplete: boolean = true;
    private deuiAppConfig: any;

    private auraSectionSubmitted = new Subject<any>();
    auraSectionSubmittedObservable = this.auraSectionSubmitted.asObservable();

    private auraInterviewComplete = new Subject<any>();
    auraInterviewCompleteObservable = this.auraInterviewComplete.asObservable();
    publicApiBaseUrl: string;

    constructor(
    public appService: AppService,
    public httpClient: HttpClient,
    public configService: IConfigService,
    public observableService: ObservableService,
    public notificationService: NotificationService
    ) {
        super(appService, configService, httpClient);
        this.publicApiBaseUrl = this.configService.getConfiguration().publicApiUrl;
    }

    isAuraSectionComplete() {
        return this.auraSectionComplete;
    }

    setAuraSectionComplete(isComplete: boolean, saveInterview: boolean = true) {
        this.auraSectionComplete = isComplete;

        if (isComplete && saveInterview) {
            this.auraInterviewComplete.next({});
        }
    }

    setDeuiAppConfig(deuiAppConfig) {
        this.deuiAppConfig = deuiAppConfig;
    }

    buttonClicked() {
        if (this.deuiAppConfig?.navigation?.navSections?.some((navSection) => navSection.status !== 'complete')) {
            this.notificationService.showNotification({
                message: 'Some questions have invalid values...',
                severity: NotificationSeverity.Error
            });
        } else {
            this.auraSectionSubmitted.next({  });
        }
    }

    saveAuraResults(clientId, caseId, ruleResults, questionResults){
        return super.postData(`${this.publicApiBaseUrl}client/${clientId}/case/${caseId}/auraDecision`,
            {
                auraResults: ruleResults,
                auraQuestionResults: questionResults
            });
    }
}
