<form *ngIf="envelopeSignerForm" [formGroup]="envelopeSignerForm" (ngSubmit)="saveEnvelopeSigner()" novalidate autocomplete="off">
  <h1 mat-dialog-title>
    <div *ngIf="envelopeSignerForm.get('id')?.value == null">New Envelope Signer</div>
    <div *ngIf="envelopeSignerForm.get('id')?.value != null">{{ data.label }}</div>
  </h1>
  <div mat-dialog-content class="flex py-2">
      <auto-suggest *ngIf="envelopeSignerForm.get('id')?.value == null" [list]="accountSigners" placeholder="Account Signer" field="label" dataKey="id"
        formControlName="accountSignerId"
        [disabled]="envelopeSignerForm.get('id') && envelopeSignerForm.get('id').value != null"
        [showRequiredError]="formSubmitted && envelopeSignerForm.get('accountSignerId').hasError('required')">
      </auto-suggest>
      <mat-slide-toggle class="mr-2 mb-3" formControlName="idCheckRequired">ID Check Required</mat-slide-toggle>
      <mat-slide-toggle *ngIf="isEmbedded" class="mr-3 mb-3" formControlName="remoteSigner" (change)="runValidations($event)">Remote Signer</mat-slide-toggle>
      <mat-form-field style="width: 100%" *ngIf="envelopeSignerForm.get('idCheckRequired').value === true">
        <mat-label>ID Check Type</mat-label>
        <mat-select formControlName="idCheckType" >
          <mat-option>None</mat-option>
          <mat-option value="ID Check $">Knowledge Base</mat-option>
          <mat-option value="SMS Auth $">SMS</mat-option>
          <mat-option value="Phone Auth $">Phone</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-card appearance="outlined" >
        <mat-card-title>
          <h2>Documents</h2>
          <button type="button" mat-flat-button color="primary" class="float-right"
            (click)="addEnvelopeDocument()" *ngIf="selectedEnvelopeDocument == null">
            <i aria-hidden="true" class="fa fa-plus"></i> ADD
          </button>
        </mat-card-title>
        <div formArrayName="documents" cdkDropList (cdkDropListDropped)="drop($event)" class="drag-drop-list">
          <mat-card appearance="outlined" *ngFor="let envelopeDocument of getEnvelopeFormArray().controls; let i = index;"
            class="mt-3" [ngClass]="{'envelope-card': selectedEnvelopeDocument != envelopeDocument}" [formGroupName]="i" cdkDrag>
            <mat-card-content class="drag-drop-box" style="border-style: none !important; cursor: pointer;">
              <div class="flex flex-row" (click)="selectEnvelopeDocument(envelopeDocument)">
                <div class="drag-drop-custom-placeholder" *cdkDragPlaceholder></div>
                <span class="ml-1 cdk-drag-placeholder">
                  <i aria-hidden="true" class="fa fa-grip-horizontal mr-2 pt-1 " cdkDragHandle></i>
                </span>
                <span style="color: black">{{ getDocumentLabel(envelopeDocument) }}</span>
                <div class="flex-1"></div>
                <div style="justify-content: flex-end">
                  <i aria-hidden="true" class="fa fa-trash" (click)="deleteEnvelopeDocument(envelopeDocument, $event)"
                  *ngIf="envelopeDocument.get('id').value != null"></i>
                </div>
              </div>
              <div *ngIf="selectedEnvelopeDocument === envelopeDocument">
                <auto-suggest [list]="availableDocuments" placeholder="Document" field="name" dataKey="id"
                  formControlName="documentId" *ngIf="selectedEnvelopeDocument.get('id').value == null">
                </auto-suggest>
                <mat-slide-toggle class="mr-2 mt-3 mb-3" formControlName="reviewOnly" [disabled]="isXsltDoc">Review Only</mat-slide-toggle>
                <auto-suggest [list]="rules" placeholder="Signing Rule" field="name" dataKey="id"
                  formControlName="ruleId">
                </auto-suggest>
                <ng-template [ngIf]="envelopeDocument.get('reviewOnly').value == false">
                  <mat-form-field style="width: 100%">
                    <mat-label>Document Form Fields</mat-label>
                    <mat-select formControlName="selectedFormFields" multiple>
                      <mat-option *ngFor="let formField of documentFormFields" [value]="formField.formFieldName">
                        {{formField.formFieldName}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <div
                    *ngIf="envelopeDocument.get('selectedFormFields') && envelopeDocument.get('selectedFormFields').value">
                    <div class="flex flex-row">
                      <div style="width: 100px">Is Signature</div>
                      <div style="width: 100px">Is Required</div>
                      <div class="flex-1">Field Name</div>
                      <div class="flex-1">Field Type</div>
                    </div>
                    <div formArrayName="formFields">
                      <div *ngFor="let formField of envelopeDocument.get('formFields').controls; let j=index"
                        class="flex flex-row" [formGroupName]="j">
                        <input type="hidden" formControlName="id" />
                        <input type="hidden" formControlName="fieldType" />
                        <mat-checkbox style="width: 100px" formControlName="isSignature"></mat-checkbox>
                        <mat-checkbox style="width: 100px" formControlName="isRequired" [disabled]="envelopeDocument.get('formFields').controls[j].controls.fieldType.value === 'CheckboxField'"></mat-checkbox>
                        <div class="flex-1">
                          {{ envelopeDocument.get('formFields').controls[j].controls.formFieldName.value }}
                        </div>
                        <div class="flex-1">
                          {{ envelopeDocument.get('formFields').controls[j].controls.fieldType.value }}
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </mat-card>
  </div>
  <div mat-dialog-actions align="end">
    <mrs-btn-tertiary buttonLabel="Cancel" buttonId="cancelButton"
      (onClick)="dialogRef.close()">Cancel</mrs-btn-tertiary>
    <mrs-btn-primary buttonLabel="Save" buttonId="billingSave" isSubmitButton="true"></mrs-btn-primary>
  </div>
</form>
