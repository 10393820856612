import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MrsBtnIconComponent, MrsBtnOutlineComponent, MrsBtnPrimaryComponent, MrsBtnTertiaryComponent } from './index';



@NgModule({
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule
    ],
    exports: [
        MatIconModule, MrsBtnPrimaryComponent, MrsBtnIconComponent, MrsBtnOutlineComponent, MrsBtnTertiaryComponent],
    declarations: [MrsBtnPrimaryComponent, MrsBtnIconComponent, MrsBtnOutlineComponent, MrsBtnTertiaryComponent]
})
export class MrsButtonsModule {
}
