import { Component, OnInit, ViewChild, Input, OnDestroy } from '@angular/core';
import { AppService } from '../../../../app.service';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators, UntypedFormArray } from '@angular/forms';
import * as _ from 'lodash';
import { Account, AccountSigner, AccountSignerMappingProperty } from '../../../../models';
import { ConfirmationDialogService, SignerService, MapKeyService, NotificationService, NotificationSeverity } from '../../../../../app/services';
import { Subscription, lastValueFrom } from 'rxjs';
import { RichTextEditorComponent } from '../../../../components/rich-text-editor/rich-text-editor.component';

@Component({
    selector: 'account-signers',
    host: { 'class': 'd-flex flex-row flex-fill' },
    templateUrl: './account-signers.component.html',
    styleUrls: ['./account-signers.component.scss']
})
export class AccountSignersComponent implements OnInit, OnDestroy {
    @Input() accountInfo: Account;
    @Input() mapKeys;
    @ViewChild('richTextEditor') richTextEditor: RichTextEditorComponent;

    remoteEmbeddedInstructionsMap: { name: string, remoteEmbeddedInstructions: string };
    htmlEditorContent = new UntypedFormControl('');
    signerForm: UntypedFormGroup;
    selectedSigner: AccountSigner;
    formSubmitted: any;
    signers: any = [];
    mappingProperties: AccountSignerMappingProperty[];
    configEditorTitle = "Embedded Instructions";
    inputMapKeys: any[];
    outputMapKeys: any[];
    subscriptions: Subscription[] = [];
    richTextForm: UntypedFormGroup;
    remoteEmbeddedInstructions: string;
    maxLength = 1000;


    constructor(
        private appService: AppService,
        private signerService: SignerService,
        private _fb: UntypedFormBuilder,
        private confirmationService: ConfirmationDialogService,
        private mapKeyService: MapKeyService,
        private notificationService: NotificationService
    ) {
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(x => {
            x.unsubscribe();
        });
    }

    async ngOnInit() {
        const mapKeySubscription = this.mapKeyService.mapKeysObserver.subscribe(updated => {
            if (updated) {
                this.setMapKeys();
            }
        });

        this.subscriptions.push(mapKeySubscription);

        this.setMapKeys();

        this.signerForm = this._fb.group({
            id: '',
            accountId: [this.accountInfo.id, Validators.required],
            label: ['', Validators.required],
            emailMapKeyId: ['', Validators.required],
            nameMapKeyId: ['', Validators.required],
            phoneMapKeyId: [''],
            deliveryMethodMapKeyId: [''],
            urlMapKeyId: [''],
            ssnMapKeyId: [''],
            accessCodeMapKeyId: [''],
            sequence: ['', Validators.required],
            accountSignerMappings: this._fb.array([])
        });


        this.mappingProperties = await lastValueFrom(this.signerService.getAccountSignerMappingProperties(this.accountInfo.id));

        this.getAccountSigners();
    }

    setMapKeys() {
        this.mapKeys = this.mapKeyService.mapKeys;
        this.outputMapKeys = this.mapKeyService.filterOutputMapKeys(this.mapKeys);
        this.inputMapKeys = this.mapKeyService.filterInputMapKeys(this.mapKeys);
    }

    getAccountSigners() {
        this.signerService.getAccountSigners(this.accountInfo.id).subscribe(
            data => {
                this.signers = data;
                this.signers = _.sortBy(this.signers, ['sequence']);
            }
        );
    }

    selectSigner(event) {
        if (this.selectedSigner === event) {
            this.selectedSigner = null;
        } else {
            this.formSubmitted = false;
            this.selectedSigner = event;
            this.signerForm.reset();
            this.signerForm.patchValue(this.selectedSigner);
            const mappingPropertyFormArray = this.signerForm.get('accountSignerMappings') as UntypedFormArray;
            mappingPropertyFormArray.clear();

            this.mappingProperties.forEach(x => {
                const signerMapping = this.selectedSigner.accountSignerMappings.find(asm => asm.propertyId === x.id);
                const signerMappingMapKeyId = signerMapping == null || signerMapping.mapKeyId == null ? '' : signerMapping.mapKeyId;

                mappingPropertyFormArray.push(this._fb.group({
                    name: [x.name],
                    propertyId: [x.id],
                    mapKeyId: [signerMappingMapKeyId]
                }));
            });
        }
    }

    addAccountSigner() {
        this.formSubmitted = false;
        const accountSigner: AccountSigner = {
            accountId: this.accountInfo.id,
            label: '',
            emailMapKeyId: '',
            nameMapKeyId: '',
            sequence: this.signers.length,
            accountSignerMappings: [],
            remoteEmbeddedInstructions: 'Signing Experience Direct [Applicant Name Mapkey or "Signer"]to visit [URL Mapkey or "signing portal"] [Applicant Name Mapkey or "Signer"]will enter secure code and last four of SSN'
        };
        this.selectSigner(accountSigner);
        if (this.signers) {
            this.signers.push(accountSigner);
        } else {
            this.signers = [];
            this.signers.push(accountSigner);
        }
    }

    saveAccountSigner() {
        this.formSubmitted = true;
        if (!this.signerForm.valid) {
            this.signerForm.markAsTouched();
            this.signerForm.controls.label.markAsTouched();
            this.signerForm.controls.emailMapKeyId.markAsTouched();
            this.signerForm.controls.nameMapKeyId.markAsTouched();
            return false;
        }
        const editorContent = this.richTextEditor.textEditorFormGroup.get('htmlEditorControl').value;

        const accountSigner: AccountSigner = this.signerForm.value;

        accountSigner.accountId = this.accountInfo.id;
        accountSigner.remoteEmbeddedInstructions = editorContent;
        const mappings = this.signerForm.controls.accountSignerMappings as UntypedFormArray;
        const validMappings = mappings.value.filter(x => x.mapKeyId != null && x.mapKeyId !== '');
        accountSigner.accountSignerMappings = validMappings.map(x => ({
            accountSignerId: x.id,
            mapKeyId: x.mapKeyId,
            propertyId: x.propertyId
        }));

        if (this.signerForm.valid) {
            if (this.selectedSigner.id) {
                this.saveEditSigner(accountSigner);
                this.formSubmitted = false;
            } else {
                this.saveAddSigner(accountSigner);
                this.formSubmitted = false;
            }
        }
    }

    saveEditSigner(accountSigner: AccountSigner) {
        this.signerService.editAccountSigner(accountSigner).subscribe(res => {
            this.notificationService.showNotification({ message: 'Signer saved', severity: NotificationSeverity.Success });
            this.signers = res;
            this.selectedSigner = null;
            this.signers = _.sortBy(this.signers, ['sequence']);
        });
    }

    saveAddSigner(accountSigner: AccountSigner) {
        this.signerService.addAccountSigner(accountSigner).subscribe(res => {
            this.notificationService.showNotification({ message: 'Signer saved', severity: NotificationSeverity.Success });
            this.signers = res;
            this.selectedSigner = null;
            this.signers = _.sortBy(this.signers, ['sequence']);
        });
    }

    deleteAccountSigner(accountSigner: AccountSigner) {
        this.confirmationService.open({
            message: `Are you sure that you want to delete this account signer?`,
            showCancel: true,
            onOk: () => {
                this.signerService.deleteAccountSigner(accountSigner).subscribe(
                    res => {
                        this.notificationService.showNotification({ message: 'Signer deleted', severity: NotificationSeverity.Success });
                        this.signers = res;
                        this.selectedSigner = null;
                        this.signers = _.sortBy(this.signers, ['sequence']);
                    }
                );
            }
        });
    }

    cancelSigner(signer: AccountSigner) {
        if (signer.id == null) {
            this.signers = _.reject(this.signers, function (o) { return o === signer; });
        }
        this.selectedSigner = null;
    }

    get descriptionRichControl() {
        return this.richTextForm.controls.description as UntypedFormControl;
    }
}
