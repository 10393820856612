import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { BaseInterviewFormFieldComponent, BaseFormFieldServices } from '../base-interview-form-field/base-interview-form-field.component';

@Component({
    selector: 'phone-number-answer',
    templateUrl: './phone-number-answer.component.html',
    styleUrls: ['./phone-number-answer.component.scss']
})

export class PhoneNumberAnswerComponent extends BaseInterviewFormFieldComponent implements OnInit {
    extension: any = '';

    // used to display mask and auto fill dashes.
    public mask = [/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

    constructor(private fb: UntypedFormBuilder,
        public services: BaseFormFieldServices) {

        super(services);
    }

    ngOnInit() {
        const extensionFormField = `ext${this.config.id}`;
        this.group.addControl(extensionFormField, this.fb.control(''));

        const currentAnswerValue = this.group.get(this.config.id).value;
        let extensionValue = '';
        let finalAnswerValue = '';

        // if value>12, then phonenumber has extension, whose input value is filled by splitting the answer value.
        if (currentAnswerValue.length > 12) {
            const answerValueSplit = currentAnswerValue.split('-');

            answerValueSplit.forEach((eachSplit, index) => {
                if (index < 3) {
                    finalAnswerValue = finalAnswerValue ? finalAnswerValue + '-' + eachSplit : eachSplit;
                } else {
                    extensionValue = eachSplit;
                }
            });
        }

        if (finalAnswerValue) {
            this.group.get(this.config.id).setValue(finalAnswerValue);
        }

        if (extensionValue) {
            this.group.get('ext' + this.config.id).setValue(extensionValue);
        }

        super.initialize({
            inputType: 'text',
            additionalFormFieldNames: [extensionFormField],
            additionalValidators: [
                Validators.pattern(/^\d{3}-?\d{3}-?\d{4}$/)
            ]
        });
    }
}
