import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { RoleBuilderComponent } from '../roles/role-builder.component';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

export const routes: Routes = [
    { path: '', pathMatch: 'full', component: RoleBuilderComponent }
];

@NgModule({
    declarations: [
        RoleBuilderComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forChild(routes),
    ]
})
export class RolesModule { }
