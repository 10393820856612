import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { CaseStatus, FeatureToggle } from '@Enums';
import { UWBaseProps, UWDecisionNote } from '@Underwriting/models';
import { FeatureManagerService } from 'app/services';
import { SubSink } from 'subsink';

@Component({
    selector: 'underwriting-decision-view',
    templateUrl: './underwriting-decision-view.component.html',
    styleUrls: ['./underwriting-decision-view.component.scss']
})
export class UnderwritingView implements OnInit, OnChanges, OnDestroy {
    @Input() uwLabelName: string;
    @Input() uwDecisionNote: UWDecisionNote;
    @Input() baseProps: UWBaseProps;
    @Output() buttonClick = new EventEmitter();

    subs = new SubSink();
    isDecisionButtonVisible = false;
    hideUpdateDecisionButtonDisabled = false;

    constructor(
        protected featureManagerService: FeatureManagerService,
    ) { }

    //#region Helpers

    IsCaseStatusLocked = () => this.baseProps?.case?.statusId === CaseStatus.Cancelled || this.baseProps?.case?.statusId === CaseStatus.Done;

    updateDecisionButtonVisibility(): void {
        if (this.hideUpdateDecisionButtonDisabled) this.isDecisionButtonVisible = true;
        else if (this.IsCaseStatusLocked()) this.isDecisionButtonVisible = false;
        else if (this.baseProps?.readonly) this.isDecisionButtonVisible = false;
        else this.isDecisionButtonVisible = true;
    }

    //#endregion
    //#region Subscriptions

    subscribeToFeatureToggles() {
        this.subs.add(this.featureManagerService.isEnabled(FeatureToggle.UWWorkbenchUpdateDecisionMultiUserClient, this.baseProps.client.id).subscribe(isEnabled => {
            this.hideUpdateDecisionButtonDisabled = isEnabled;
            this.updateDecisionButtonVisibility();
        }));
    }

    //#endregion  
    //#region LifeCycle

    ngOnInit() {
        this.subscribeToFeatureToggles();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.baseProps) this.updateDecisionButtonVisibility();
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    //#endregion

}
