<section>
    <h2 mat-dialog-title>Delete Column?</h2>
    <div mat-dialog-content>
        <p>You will lose your configuration for this column.<br>
            This action cannot be undone.</p>
    </div>

    <mat-dialog-actions align="end">
        <mrs-btn-tertiary buttonLabel="Cancel" buttonId="cancelButton" (click)="handleCancel()"></mrs-btn-tertiary>
        <mrs-btn-tertiary buttonLabel="Delete Column" textColor="#900606" (onClick)="handleDelete()"></mrs-btn-tertiary>
    </mat-dialog-actions>
</section>