<div *ngIf="!showTestForm" class="flex-fill flex-column overflow-y-auto custom-scrollbar" cdkScrollable>
    <div [ngClass]="{'view-container': !_mapkeyOperation.isModal}">
        <form [formGroup]="mapkeyForm" autocomplete="off" novalidate>
            <div *ngIf="!_mapkeyOperation.isModal" class="flex flex-row" style="margin-bottom: 1.44rem">
                <div class="flex flex-column">
                    <div class="page-header">{{_mapkeyOperation.entityHierarchy ?? _mapkeyOperation.name}}</div>
                    <div class="autogen-subheader" *ngIf="autogeneratedMapkey">Generated by {{actionName}}</div>
                </div>
                <div class="flex-1"></div>
                <div *ngIf="checkACL('W')">
                    <div *ngIf="_mapkeyOperation.entityType !== entityTypes.Constant && _mapkeyOperation.entityType !== entityTypes.DynamicList"
                        id="detailsButtons">
                        <button (click)="editItem(_mapkeyOperation)"
                            *ngIf="showDetailContent && canBeEditted(_mapkeyOperation.selectedNode)" class="float-right"
                            color="primary" mat-flat-button>
                            <i aria-hidden="true" class="fa fa-edit pr-1"></i>EDIT
                        </button>
                        <button (click)="saveForm()" *ngIf="!showDetailContent" class="float-right mr-1" color="primary"
                            mat-flat-button>
                            SAVE CHANGES
                        </button>
                        <button (click)="cancel()" *ngIf="!showDetailContent" class="float-right mr-1 material-default"
                            color="primary" mat-button>
                            CANCEL
                        </button>
                    </div>
                    <div *ngIf="_mapkeyOperation.entityType === entityTypes.Constant" id="constantButtons">
                        <button (click)="constantComponent.editItem()"
                            *ngIf="showDetailContent && constant && !constantComponent?.isEditView" class="float-right"
                            color="primary" mat-flat-button>
                            <i aria-hidden="true" class="fa fa-edit pr-1"></i>EDIT
                        </button>
                        <button (click)="constantComponent.saveConstant()"
                            *ngIf="constant  && constantComponent?.isEditView" class="float-right mr-1" color="primary"
                            mat-flat-button>
                            SAVE CHANGES
                        </button>
                        <button (click)="constantComponent.cancel()" *ngIf="constant  && constantComponent?.isEditView"
                            class="float-right mr-1 material-default" color="primary" mat-button>
                            CANCEL
                        </button>
                    </div>
                    <div *ngIf="dynamicList" id="DynamicListButtons">
                        <button (click)="dynamicListComponent.editItem()"
                            *ngIf="showDetailContent &&  !dynamicListComponent?.isEditView" class="float-right"
                            color="primary" mat-flat-button>
                            <i aria-hidden="true" class="fa fa-edit pr-1"></i>EDIT
                        </button>
                        <button (click)="dynamicListComponent.saveDynamicList()"
                            *ngIf=" dynamicListComponent?.isEditView" class="float-right mr-1" color="primary"
                            mat-flat-button>
                            SAVE CHANGES
                        </button>
                        <button (click)="dynamicListComponent.cancel()" *ngIf=" dynamicListComponent?.isEditView"
                            class="float-right mr-1 material-default" color="primary" mat-button>
                            CANCEL
                        </button>
                    </div>
                </div>
            </div>
            <div *ngIf="!constant && _mapkeyOperation.entityType !== entityTypes.DynamicList" id="mapkeyDetails">
                <div class="mapkey-section-pane" id="detailsWrapper">
                    <span *ngIf="!_mapkeyOperation.isModal" class="d-inline-flex">
                        <mat-icon (click)="showDetails = !showDetails">{{showDetails ? 'expand_less' :
                        'expand_more'}}</mat-icon>
                        <h2>Details</h2>
                    </span>
                    <div [ngStyle]="{'display':showDetails?'':'none'}" id="details">
                        <div *ngIf="!showDetailContent" id="detailsEdit">
                            <mat-card appearance="outlined">
                                <mat-card-content>
                                    <div *ngIf="!isPredefinedMapKey">
                                        <mat-form-field *ngIf="_mapkeyOperation.entityType === entityTypes.Mapkey"
                                            class="full-width pb-3">
                                            <mat-label>Mapkey Type</mat-label>
                                            <mat-select (selectionChange)="mapkeyChange($event)"
                                                id="mapkeyTypeSelector"
                                                [disabled]="disableMapKeyTypeSelection" formControlName='mapKeyTypeId'>
                                                <mat-option *ngFor="let mapkeyType of mapKeyTypes"
                                                    [value]="mapkeyType.id">
                                                    {{ mapkeyType.name }}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error *ngIf="mapkeyForm.get('parentId').hasError('required')">
                                                Mapkey type
                                                <strong>required</strong>
                                            </mat-error>
                                        </mat-form-field>
                                        <div *ngIf="isExpressionMapkey">
                                            <expression-builder [accountInfo]="accountInfo" [caseMapKeys]="caseMapKeys"
                                                [mapKeyForm]="mapkeyForm"
                                                [rules]="mapKeyService.rules"></expression-builder>
                                        </div>
                                        <div *ngIf="!isExpressionMapkey">
                                            <div *ngIf="_mapkeyOperation.isModal">
                                                <button (click)="$event.preventDefault()"
                                                    *ngIf="mapkeyForm.get('id').value == '' "
                                                    [matMenuTriggerFor]="panel.menu" class="material-default"
                                                    color="primary" mat-stroked-button>Choose the Mapkey
                                                    Parent
                                                </button>
                                                <app-menu-item #panel (onItemSelect)="parentChange($event)"
                                                    [items]="parentItems"></app-menu-item>
                                            </div>
                                            <div class="label-title">Parent Name</div>
                                            <div class="label-content">{{selectedNode.parentPath}}</div>
                                            <mat-error
                                                *ngIf="mapkeyForm.dirty && mapkeyForm.get('parentId').hasError('required') && mapkeyForm.get('id').value == ''">
                                                <strong>Please choose a parent</strong>
                                            </mat-error>
                                            <mat-form-field class="full-width">
                                                <mat-label>Name</mat-label>
                                                <input autofocus formControlName='name' matInput>
                                                <mat-error *ngIf="mapkeyForm.get('name').hasError('required')">
                                                    Name
                                                    <strong>required</strong>
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div *ngIf="!isExpressionMapkey">
                                        <mat-form-field class="full-width">
                                            <mat-label>Sanitized Value</mat-label>
                                            <input autofocus formControlName='sanitizedValue' matInput>
                                        </mat-form-field>
                                        <div *ngIf="!isPredefinedMapKey">
                                            <div *ngIf="mapkeyForm.get('id').value != ''" class="form-label">Parent Name
                                            </div>
                                            <div *ngIf="mapkeyForm.get('id').value != '' && !selectedNode.parent"
                                                class="mb-3">
                                                {{selectedNode.label}}
                                            </div>
                                            <div *ngIf="mapkeyForm.get('id').value != '' && selectedNode.parent"
                                                class="mb-3">
                                                {{selectedNode.parent.label}}
                                            </div>
                                            <input formControlName='rootCategory' type="hidden">
                                            <input formControlName='id' type="hidden">

                                            <div *ngIf="isMapkey" class="form-label">
                                                <div
                                                    *ngIf="mapkeyForm.get('mapKeyTypeId').value === mapKeyTypeEnum.Integration">
                                                    <mat-form-field class="full-width">
                                                        <mat-label>Integration</mat-label>
                                                        <mat-select formControlName='integrationId'>
                                                            <mat-option *ngFor="let integration of accountIntegrations"
                                                                [value]="integration.id">
                                                                {{ integration.name }}
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>
                                                <div
                                                    *ngIf="mapkeyForm.get('mapKeyTypeId').value === mapKeyTypeEnum.Virtual || mapkeyForm.get('mapKeyTypeId').value === mapKeyTypeEnum.VirtualDocument">
                                                    <div>
                                                        <mat-checkbox formControlName="isVirtualExpression">Virtual
                                                            Expression
                                                        </mat-checkbox>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="isMapkey && (!isPredefinedMapKey || _mapkeyOperation.selectedNode.rootMapNode === 'Integration')">
                                            <mat-checkbox class="mr-3" formControlName="isPii">
                                                Contains PII
                                            </mat-checkbox>
                                            <div (click)="showPiiHelp()" class="text-link"
                                                style="margin-left: 24px">What does this do?
                                            </div>
                                        </div>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                            <div *ngIf="mapkeyForm.get('mapKeyTypeId').value === mapKeyTypeEnum.Virtual || mapkeyForm.get('mapKeyTypeId').value === mapKeyTypeEnum.VirtualDocument"
                                class="mt-5">
                                <div *ngIf="mapkeyForm.get('isVirtualExpression').value == true" class="flex flex-column">
                                    <div class="flex flex-column">
                                        <div class="page-sub-header">
                                            Virtual Expression
                                        </div>
                                        <div (click)="showVirtualMapKeyHelp()" class="text-link">
                                            What expressions can I use?
                                        </div>
                                    </div>
                                    <mat-card>
                                        <mat-card-content>
                                            <div class="w-100 textarea-formfield" style="position: relative;">
                                                <textarea (itemSelected)="onMention()" (searchTerm)="onSearch($event)"
                                                    [mentionConfig]="{triggerChar:'@', disableSearch: true}"
                                                    [mention]="filteredMentionItems" cols="50"
                                                    class="w-100" placeholder="Virtual Expression"
                                                    style="border: none"
                                                    formControlName='virtualExpression' rows="12"></textarea>
                                            </div>
                                            <div>
                                                <mrs-btn-outline buttonLabel="Test Expression" *ngIf="!_mapkeyOperation.isModal"
                                                    (onClick)="testVirtualMapKey(false)">
                                                </mrs-btn-outline>
                                            </div>
                                        </mat-card-content>
                                    </mat-card>
                                </div>
                                <div
                                    *ngIf="mapkeyForm.get('isVirtualExpression').value != true && _mapkeyOperation.selectedNode.mapKeyTypeId !== mapKeyTypeEnum.ExpressionBuilder">
                                    <div class="flex flex-row">
                                        <div class="page-sub-header">
                                            Mapkeys
                                        </div>
                                    </div>
                                    <mat-card appearance="outlined">
                                        <mat-card-content>
                                            <div>
                                                <mat-form-field class="full-width">
                                                    <mat-label>Separator</mat-label>
                                                    <input autofocus formControlName='delimiter' matInput>
                                                </mat-form-field>
                                            </div>
                                            <div *ngIf="mapkeyForm.get('id').value == '' || mapkeyForm.get('mapKeyTypeId').value === mapKeyTypeEnum.Virtual || mapkeyForm.get('mapKeyTypeId').value === mapKeyTypeEnum.VirtualDocument"
                                                class="mb-1">
                                                <div *ngIf="selectedMapKeys?.length" class="label-title">Mapkeys List
                                                </div>
                                                <div *ngFor="let virtulaKey of selectedMapKeys let index=index; let odd=odd; let even=even;"
                                                    [ngClass]="{ odd: odd, even: even }" class="label-content">
                                                    {{virtulaKey.listMapKeyName}}
                                                    <button (click)="removeAddedMapkeys(virtulaKey)"
                                                        class="float-right btn btn-link p-0" type="button">
                                                        <i aria-hidden="true" class="fa fa-minus-circle"></i>
                                                    </button>
                                                </div>
                                                <div class="d-flex border-full mt-2">
                                                    <div class="col-9 p-0 pl-1">
                                                        <mat-form-field class="full-width pb-3">
                                                            <mat-label>Mapkeys</mat-label>
                                                            <mat-select (selectionChange)="addMapKeys($event)"
                                                                formControlName='availableKeys'>
                                                                <mat-option *ngFor="let caseMapkey of caseMapKeys"
                                                                    [value]="caseMapkey">
                                                                    {{ caseMapkey.listMapKeyName }}
                                                                </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-3 p-0">
                                                        <span (click)="addToMapkeys()"
                                                            class="d-inline-block px-0 text-right col-12">
                                                            <button class="btn btn-link" type="button">
                                                                Add
                                                                <i aria-hidden="true" class="fa fa-plus-circle"></i>
                                                            </button>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </mat-card-content>
                                    </mat-card>
                                </div>
                            </div>
                            <mat-dialog-actions class="flex flex-row" style="justify-content: end;" *ngIf="_mapkeyOperation.isModal">
                                <div *ngIf="checkACL('W')">
                                    <mrs-btn-tertiary buttonLabel="Cancel" buttonId="cancelButton"
                                        *ngIf="!showDetailContent && (_mapkeyOperation.entityType !== entityTypes.ConstantList) && (_mapkeyOperation.entityType !== entityTypes.DynamicList)"
                                        (onClick)="cancel()"></mrs-btn-tertiary>
                                    <mrs-btn-tertiary buttonLabel="Test Expression" buttonId="testButton"
                                        *ngIf="mapkeyForm.get('isVirtualExpression').value == true"
                                        (onClick)="testVirtualMapKey(true)"></mrs-btn-tertiary>
                                    <mrs-btn-tertiary buttonLabel="Edit" buttonId="editButton"
                                        *ngIf="showDetailContent && (_mapkeyOperation.entityType !== entityTypes.ConstantList) && (_mapkeyOperation.entityType !== entityTypes.DynamicList)"
                                        (onClick)="editItem(_mapkeyOperation)"></mrs-btn-tertiary>
                                    <mrs-btn-primary buttonLabel="Save" buttonId="saveButton"
                                        *ngIf="!showDetailContent && (_mapkeyOperation.entityType !== entityTypes.ConstantList) && (_mapkeyOperation.entityType !== entityTypes.DynamicList)"
                                        (onClick)="saveForm()"></mrs-btn-primary>
                                </div>
                            </mat-dialog-actions>
                        </div>
                        <div *ngIf="showDetailContent" id="detailsView">
                            <mat-card appearance="outlined">
                                <mat-card-content>
                                    <div *ngIf="_mapkeyOperation.entityType === entityTypes.Mapkey"
                                        class="label-title-bold">Mapkey Name
                                    </div>
                                    <div *ngIf="_mapkeyOperation.entityType === entityTypes.Entity"
                                        class="label-title-bold">Folder Name
                                    </div>
                                    <div class="label-content">{{selectedNode?.label}}</div>
                                    <div *ngIf="_mapkeyOperation.entityType === entityTypes.Mapkey">
                                        <div class="label-title-bold">Type</div>
                                        <div class="label-content">{{_mapkeyOperation.mapkeyTypeDisplay}}</div>
                                    </div>
                                    <div *ngIf="_mapkeyOperation.selectedNode.mapKeyTypeId === mapKeyTypeEnum.Integration"
                                        id="integration">
                                        <div class="label-title-bold">Integration</div>
                                        <div class="label-content">{{_mapkeyOperation.integrationMapkeyIntegrationName}}
                                        </div>
                                    </div>
                                    <div class="label-title-bold">Folder Location</div>
                                    <div class="label-content">{{formatParentPath()}}</div>
                                    <div *ngIf="_mapkeyOperation.entityType === entityTypes.Mapkey">
                                        <div
                                            *ngIf="_mapkeyOperation.selectedNode.mapKeyTypeId === mapKeyTypeEnum.Virtual || _mapkeyOperation.selectedNode.mapKeyTypeId === mapKeyTypeEnum.VirtualDocument">
                                            <mat-checkbox [checked]="selectedNode.isVirtualExpression"
                                                disabled="true">Virtual
                                                Expression
                                            </mat-checkbox>
                                        </div>
                                        <div>
                                            <mat-checkbox [checked]="selectedNode.isPii" disabled="true">Contains
                                                PII
                                            </mat-checkbox>
                                        </div>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </div>
                    </div>
                </div>
                <div *ngIf="showDetailContent && _mapkeyOperation.selectedNode.isVirtualExpression"
                    [ngClass]="{'mapkey-section-pane':showDetailContent && _mapkeyOperation.selectedNode.isVirtualExpression}"
                    id="virtualExpression">
                    <span class="d-inline-flex"><mat-icon
                        (click)="showVirtualExpression = !showVirtualExpression">{{showVirtualExpression ?
                    'expand_less' : 'expand_more'}}</mat-icon>
                        <h2>Virtual Expression</h2>
                    </span>
                    <div [ngStyle]="{'display':showVirtualExpression?'':'none'}">
                        <mat-card appearance="outlined">
                            <mat-card-content>
                                <div
                                    *ngIf="_mapkeyOperation.selectedNode.isVirtualExpression && _mapkeyOperation.selectedNode.mapKeyTypeId !== mapKeyTypeEnum.ExpressionBuilder">
                                    <div class="label-content-no-underline">
                                        {{_mapkeyOperation.selectedNode.virtualExpression}}</div>
                                </div>
                                <div
                                    *ngIf="_mapkeyOperation.selectedNode.isVirtualExpression && _mapkeyOperation.selectedNode.mapKeyTypeId === mapKeyTypeEnum.ExpressionBuilder">

                                    <div class="label-content-no-underline">{{_mapkeyOperation.virtualExpressionParsed}}
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
                <div *ngIf="showDetailContent && _mapkeyOperation.selectedNode.mapKeyTypeId === mapKeyTypeEnum.VirtualDocument && !_mapkeyOperation.selectedNode.isVirtualExpression"
                    [ngClass]="{'mapkey-section-pane':showDetailContent && _mapkeyOperation.selectedNode.mapKeyTypeId === mapKeyTypeEnum.VirtualDocument && !_mapkeyOperation.selectedNode.isVirtualExpression}"
                    id="mapkeyValues">
                    <span class="d-inline-flex">
                        <h2>Mapkey Values</h2><mat-icon
                        (click)="showMapkeyValues = !showMapkeyValues">{{showMapkeyValues ? 'expand_less' :
                    'expand_more'}}</mat-icon>
                    </span>
                    <mat-card appearance="outlined" [ngStyle]="{'display': showMapkeyValues?'':'none'}">
                        <mat-card-content class="mapkey-section-pane">
                            <ng-container *ngIf="_mapkeyOperation.selectedNode.virtualMapKeysList.length > 1">
                                <div class="label-title-bold"> Separator</div>
                                <div class="label-content">{{selectedNode.delimiter}}</div>
                            </ng-container>
                            <div *ngIf="selectedNode?.virtualMapKeysList?.length > 0">
                                <ng-container *ngFor="let virtualKey of selectedNode?.virtualMapKeysList">

                                    <div class="label-title-bold"> Mapkey Name</div>
                                    <div class="label-content">{{virtualKey.listMapKeyName}}</div>
                                </ng-container>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
        </form>
        <constant (editConstant)="mapKeyService.editChildInNode($event)" (listEditChange)="editConstant()"
            (saveChanges)="constantSaved($event)" *ngIf="constant" [constant]="constant" [rules]="rules"
            [parentPathDisplay]="formatParentPath()" (undoCancel)="undoConstantCancelAction($event)">
        </constant>
        <dynamic-list (editDynamicList)="mapKeyService.editChildInNode($event)" (listEditChange)="editDynamic()"
            (saveChanges)="dynamicListSaved($event)" *ngIf="dynamicList" [dynamicList]="dynamicList"
            [parentPathDisplay]="formatParentPath()">
        </dynamic-list>
        <div *ngIf="usedInFeatureToggleEnabled && _mapkeyOperation.entityType !== entityTypes.Entity && !_mapkeyOperation.isNew"
            class="mapkey-section-pane" id="usedIn">
            <span class="d-inline-flex">
                <mat-icon (click)="showUsedIn = !showUsedIn">{{showUsedIn ? 'expand_less' :
                'expand_more'}}</mat-icon>
                <h2>Used In</h2>
            </span>
            <div [ngStyle]="{'display':showUsedIn?'':'none'}">
                <div class="mapkey-section-pane">
                    <div class="mat-card-outline-orange">
                        <mat-card>
                            <mat-card-content>
                                <h3>
                                    <mat-icon class="material-icons-outlined md-18"
                                        style="font-size: 20px">info
                                    </mat-icon>
                                    Some areas may be missing
                                </h3>
                                <ul>
                                    <li class="body-platform"><b>Included Now: </b> Application, Integrations, Mapkeys,
                                        Rules, and Settings
                                    </li>
                                    <li class="body-platform"><b>Coming Later: </b> Documents and Events</li>
                                </ul>
                                <a *ngIf="this.mapkeyHotjarSurveyFeatureToggle"
                                    href="https://surveys.hotjar.com/c54f6766-9267-4840-aa09-374a92f3e67d"
                                    rel="noopener noreferrer" target="_blank">What else should we include?</a>
                            </mat-card-content>
                        </mat-card>
                    </div>
                    <used-in [accountId]="accountInfo.id" [clientId]="accountInfo.clientId"
                        [mapkeyId]="_mapkeyOperation.selectedNode.id"></used-in>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="showTestForm" style="width: 100%;">
    <test-virtual (onCloseTestVirtual)="closeVirtualTest()" [virtualExpression]="mapkeyForm.value['virtualExpression']">
    </test-virtual>
</div>

<ng-template #piiHelpDialog>
    <h1 class="dialog-title" mat-dialog-title>What does this do?</h1>
    <div style="overflow-y: auto; height: 240px">
        <div>
            Personally identifiable information or PII is any information that when used alone or with other
            relevant data can identify an individual.<br><br>
            Selecting <b>Contains PII</b> ensures the data is de-identified when used in analytics and troubleshooting.
            To protect individuals’ privacy, we recommend indicating configured elements that contain PII as described
            by
            HIPAA standards.
        </div>
        <div class="text-link mt-3">
            <mat-icon style="height: 18px; width: 18px;" svgIcon="open-in-new"></mat-icon>
            <a href="https://www.dhs.gov/publication/handbook-safeguarding-sensitive-personally-identifiable-information"
                target="_blank">
                Learn more about PII (www.dhs.gov)</a>
        </div>
    </div>
    <div>
        <button (click)="closeHelp()" class="material-default" color="primary" mat-button style="float: right"
            type="button">Close
        </button>
    </div>
</ng-template>
