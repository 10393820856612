<div>
    <div>
        <mat-icon (click)="showtext = !showtext" id="expanderIcon">{{showtext ? 'expand_more' : 'expand_less'}}</mat-icon>
        <span>{{originalText}}</span>
    </div>
    <div [ngStyle]="{'margin-left':iconWidth + 'px'}" id="expandedText">
        <div *ngIf="showtext && expandedText.length > 1">
            <div *ngFor="let text of expandedText">{{text}}</div>
        </div>
        <div *ngIf="showtext && expandedText.length === 1">
            {{expandedText}}
        </div>
    </div>
</div>
