import {Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import * as _ from 'lodash';
import {AppService} from '../../app.service';
import {NoteType, NoteContactTime, FeatureToggle} from '../../enums';
import {NoteTypeReason} from '../../models';
import {ConfirmationDialogService, FeatureToggleService} from '../../../app/services';
import {AngularEditorConfig} from '@kolkov/angular-editor';
import { FeatureManagerService } from 'app/services/global/feature-manager/feature-manager.service';

@Component({
    selector: 'note',
    templateUrl: './note.component.html',
    styleUrls: ['./note.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NoteComponent implements OnInit {

    _note;
  @Input() noteTypes;
  @Input() noteTypeReasons: NoteTypeReason[];
  @Input() noteRelations;
  @Input() noteFollowupInDays;

  expanded;
  isEditView: boolean;
  noteForm: UntypedFormGroup;
  actionTypes: any = [];
  actions: any = [];
  actionParams: any = [];
  timeZone: string;
  noteBestTimeToContact: any[] = [
      {
          id: NoteContactTime.Morning,
          value: 'Morning'
      },
      {
          id: NoteContactTime.Afternoon,
          value: 'Afternoon'
      },
      {
          id: NoteContactTime.Evening,
          value: 'Evening'
      }
  ];
  activeNoteTypes: any;
  activeNoteTypeReasons: any[];
  noteTypeReasonDictionary: { [key: string]: NoteTypeReason };
  showTextArea = false;
  enableHtmlEditor = false;
  htmlEditorConfig: AngularEditorConfig = {
      editable: true,
      minHeight: '10',
      toolbarHiddenButtons: [['customClasses', 'backgroundColor', 'textColor']]
  };
  toggleHtmlEditorFeature: boolean;
  isFollowupDate: boolean;

  @Output() onDeleteNote = new EventEmitter<any>();
  @Output() onUpdateNotes = new EventEmitter<any>();

  constructor(
    private _fb: UntypedFormBuilder,
    private appService: AppService,
    private confirmationService: ConfirmationDialogService,
    private featureManagerService: FeatureManagerService
  ) {
      this.isEditView = false;
      this.timeZone = this.appService.getTimeZone();
  }

  @Input()
  set note(value) {

      if (value.id) {
          if( value.followUpDate ){
              value.followUpDateDisplay = this.appService.formatDate(value.followUpDate);
              value.followUpDate = new Date(value.followUpDate);
          }
          else {
              value.followUpDateDisplay = '';
              value.followUpDate = '';
          }
          value.creationDate = this.appService.formatDate(value.creationDate, true);
      } else {
          this.isEditView = true;
          this.expanded = true;
      }

      if (!value.relatedTo || value.relatedTo === '') {
          value.relatedTo = 'Case';
      }

      this._note = value;

      this.noteForm = this._fb.group({
          id: value.id,
          caseDetailsId: [value.caseDetailsId, Validators.required],
          relatedTo: [value.relatedTo, Validators.required],
          noteTypeId: [value.noteTypeId, Validators.required],
          noteTypeReasonId: [value.noteTypeReasonId],
          followUpDate: [value.followUpDate, Validators.required],
          // bestTimeToContact: [value.bestTimeToContact, Validators.required],
          noteContent: [value.noteContent, Validators.required],
          isClientViewable: value.isClientViewable
      });

      this.updateValidations();
      this.noteTypeChanged();

      this.toggleHtmlEditorFeature = this.featureManagerService.getByName(FeatureToggle.CaseNotesToggleHtmlEditor)?.enabled;
  }

  get note() {
      return this._note;
  }

  ngOnInit() {
      this.activeNoteTypes = _.filter(this.noteTypes, function (o) {
          return o.isActive && o.id !== NoteType.SystemLog && o.id !== NoteType.Error && o.id != NoteType.Underwriting;
      });

      this.noteTypeReasonDictionary = _.keyBy(this.noteTypeReasons, 'id');

  }

  dateFilter = (d: Date): boolean => {
      const now = new Date();
      const today = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()));
      if (d?.getTime() < today.getTime() || d?.getDay() === 0) {
          return false;
      } else {
          return true;
      }
  };

  hasFollowupDate(noteTypeId) {
      if (noteTypeId === NoteType.PendingInformation
      || noteTypeId === NoteType.UpdateStatus
      || noteTypeId === NoteType.Interpreter) {
          return true;
      } else {
          return false;
      }
  }

  noteTypeChanged() {
      this.updateValidations();

      const noteTypeIdField = this.noteForm.get('noteTypeId');
      const noteTypeReasonIdField = this.noteForm.get('noteTypeReasonId');
      this.activeNoteTypeReasons = _.filter(this.noteTypeReasons, {noteTypeId: noteTypeIdField.value});

      if (this.activeNoteTypeReasons == null || this.activeNoteTypeReasons.find(x => x.id === noteTypeReasonIdField.value) == null) {
          noteTypeReasonIdField.setValue(undefined);
      }

      this.isFollowupDate = this.hasFollowupDate(noteTypeIdField.value);
  }

  getNoteTypeReason(noteTypeReasonId: string) {
      return this.noteTypeReasonDictionary[noteTypeReasonId];
  }

  saveNote() {

      let isEdit = false;
      if (this.noteForm.get('id').value) {
          isEdit = true;
      }

      const params = this.noteForm.value;

      if (!this.hasFollowupDate(params.noteTypeId)) {
          delete params.followUpDate;
      // delete params.bestTimeToContact;
      } else {

          const currentDateTime = new Date();

          params.followUpDate.setHours(currentDateTime.getHours(), currentDateTime.getMinutes(), currentDateTime.getSeconds(), currentDateTime.getMilliseconds());
      }

      if (this.noteForm.valid) {
          this.appService.postData('Notes', this.noteForm.value, isEdit, true)
              .subscribe(
                  data => {
                      if (data.status === 'success') {
                          this.onUpdateNotes.emit();
                          this.appService.showMsg('success', 'Saved successfully ...');
                      } else {
                          this.appService.showMsg('error', data.message);
                      }
                  }
              );
      }
  }


  deleteNote() {
      this.confirmationService.confirm({
          message: 'Are you sure that you want to delete this note?',
          key: 'notesListConfirm',
          accept: () => {
              if (this.note.id) {
                  this.appService.deleteData(`Notes/${this.note.id}`).subscribe(
                      data => {
                          if (data.status === 'success') {
                              this.onUpdateNotes.emit();
                              this.appService.showMsg('success', data.message);
                          } else {
                              this.appService.showMsg('error', data.message);
                          }
                      }
                  );
              } else {
                  this.onUpdateNotes.emit();
              }
          },
          showCancel: true
      });
  }

  getNoteTypeLabel(id) {
      if (id && this.noteTypes && this.noteTypes.length) {
          const noteType = _.find(this.noteTypes, ['id', id]);
          if (noteType && noteType.description) {
              return noteType.description;
          } else {
              return '';
          }
      } else {
          return '';
      }
  }

  getRelationLabel(key) {
      if (key && this.noteRelations && this.noteRelations.length) {
          const noteRelation = _.find(this.noteRelations, ['key', key]);
          if (noteRelation && noteRelation.value) {
              return noteRelation.value;
          } else {
              return '';
          }
      } else {
          return '';
      }
  }

  editNote() {
      this.isEditView = true;
  }

  updateValidations() {
      if (this.hasFollowupDate(this.noteForm.get('noteTypeId').value)) {
          this.noteForm.get('followUpDate').setValidators([Validators.required]);
      // this.noteForm.get('bestTimeToContact').setValidators([Validators.required]);
      } else {
          this.noteForm.get('followUpDate').clearValidators();
      // this.noteForm.get('bestTimeToContact').clearValidators();
      }
      this.noteForm.get('followUpDate').updateValueAndValidity();
      // this.noteForm.get('bestTimeToContact').updateValueAndValidity();
  }

  checkACL(permissionType, redirect) {
      return this.appService.checkACL('Case', permissionType, redirect);
  }
}
