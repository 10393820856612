<div class="request-card d-flex flex-column" [ngClass]="{active: expanded}">

    <form novalidate [formGroup]="requestForm" (ngSubmit)="saveRequest()" class="d-flex flex-column">

        <div class="request-card-header d-flex flex-row">

            <button type="button" class="btn btn-link expand-trigger" (click)="expanded = !expanded">
                <i aria-hidden="true" class="fa" [ngClass]="{'fa-angle-right': !expanded, 'fa-angle-down': expanded}"></i>
            </button>

            <div *ngIf="!expanded" class="request-card-header-content d-flex flex-row flex-fill" (click)="expanded = !expanded">
                <span class="request-type">{{request.name}}</span>
                <!-- <span class="flex-fill request-content">{{request.requestUrl}}</span> -->
            </div>

            <div *ngIf="expanded" class="request-card-header-actions flex-fill pr-4">
                <!-- <button *ngIf="isEditView && requestForm?.get('id')?.value && checkACL('F')" type="button" class="btn btn-link btn-sm custom float-right" (click)="deleteRequest()">
                    <i aria-hidden="true" class="far fa-trash-alt"></i> Delete
                </button> -->
                <button type="button" *ngIf="!isEditView && checkACL('W')" class="btn btn-link btn-sm custom float-right" (click)="editRequest()">
                    <i aria-hidden="true" class="fa fa-edit"></i> Edit
                </button>
                <button *ngIf="isEditView && !requestForm?.get('id')?.value && checkACL('W')" type="button" class="btn btn-link btn-sm custom float-right" (click)="deleteRequest()">
                    <i aria-hidden="true" class="fa fa-times"></i> Cancel
                </button>
                <button type="button" *ngIf="isEditView && requestForm?.get('id')?.value" class="btn btn-link btn-sm custom float-right" (click)="isEditView = !isEditView">
                    <i aria-hidden="true" class="fa fa-times"></i> Close
                </button>
                <button type="submit" *ngIf="isEditView && checkACL('W')" class="btn btn-link btn-sm custom float-right">
                    <i aria-hidden="true" class="fa fa-check"></i> Save
                </button>
            </div>
        </div>

        <div class="request-card-body d-flex flex-column px-4 pt-3 pb-1" *ngIf="expanded">

            <div *ngIf="!isEditView" class="request-card-body-preview d-flex flex-column">
                <div class="preview-block">
                    <div class="label-title">Request Name</div>
                    <div class="label-content">{{request.name}}</div>
                </div>

                <div class="preview-block">
                    <div class="label-title">Action</div>
                    <div class="label-content">{{request.action}}</div>
                </div>

                <div class="preview-block">
                    <div class="label-title">Action To</div>
                    <div class="label-content">{{request.actionTo}}</div>
                </div>

                <div class="preview-block">
                    <div class="label-title">Namespace Prefix</div>
                    <div class="label-content">{{request.namespacePrefix}}</div>
                </div>

                <div class="preview-block">
                    <div class="label-title">Namespace</div>
                    <div class="label-content">{{request.namespace}}</div>
                </div>

                <div class="preview-block flex-fill">
                    <div class="label-title">Request Template</div>
                    <div class="label-content">{{request.requestTemplate}}</div>
                </div>

                <div class="preview-block">
                    <div class="label-title">Mock Mode</div>
                    <div class="label-content">{{request.isMockMode}}</div>
                </div>

                <div class="preview-block flex-fill">
                    <div class="label-title">Mock Response</div>
                    <div class="label-content">{{request.mockResponse}}</div>
                </div>
            </div>

            <ng-template [ngIf]="isEditView">
                <input type="hidden" formControlName="id" />
                <input type="hidden" formControlName="accountIntegrationID" />
                <mat-form-field class="w-100">
                    <input placeholder="Request Name" matInput formControlName='name' readonly="readonly">
                    <mat-error *ngIf="requestForm.get('name').hasError('required')">required</mat-error>
                </mat-form-field>
                <mat-form-field class="w-100">
                    <input placeholder="Action" matInput formControlName='action'>
                </mat-form-field>
                <mat-form-field class="w-100">
                    <input placeholder="Action To" matInput formControlName='actionTo'>
                </mat-form-field>
                <mat-form-field class="w-100">
                    <input placeholder="Namespace Prefix" matInput formControlName='namespacePrefix'>
                </mat-form-field>
                <mat-form-field class="w-100">
                    <input placeholder="Namespace" matInput formControlName='namespace'>
                </mat-form-field>
                <div class="position-relative">
                    <mat-form-field class="w-100 textarea-formfield">
                        <textarea matInput placeholder="Request Template" matTextareaAutosize matAutosizeMinRows="2" matAutosizeMaxRows="5" formControlName="requestTemplate"></textarea>
                        <mat-error *ngIf="requestForm.get('requestTemplate').hasError('required')">required</mat-error>
                    </mat-form-field>
                    <button type="button" class="float-right btn btn-link btn-sm custom position-absolute expand-button" (click)="expandRequestTemplate(requestTemplateDialog)" title="Expand Request Template">
                        <i aria-hidden="true" class="fas fa-expand-arrows-alt"></i>
                    </button>
                </div>
                <div class="position-relative">
                    <mat-checkbox formControlName="isMockMode">Enable Mock Mode</mat-checkbox>
                    <mat-form-field class="w-100 textarea-formfield">
                        <textarea matInput placeholder="Mock Response" matTextareaAutosize matAutosizeMinRows="2" matAutosizeMaxRows="5" formControlName="mockResponse"></textarea>
                        <mat-error *ngIf="requestForm.get('mockResponse').hasError('required')">required</mat-error>
                    </mat-form-field>
                    <button type="button" class="float-right btn btn-link btn-sm custom position-absolute expand-button" (click)="expandMockResponse(responseTemplateDialog)" title="Expand Mock Response">
                        <i aria-hidden="true" class="fas fa-expand-arrows-alt"></i>
                    </button>
                </div>
            </ng-template>

        </div>

    </form>
</div>

<ng-template #requestTemplateDialog let-data style="width: '800px', max-height='90%'">
    <h2 mat-dialog-title>Request Template</h2>
    <div mat-dialog-content>
        <textarea class="popup-editor-container" [(ngModel)]="data.requestTemplate"></textarea>
    </div>
    <div mat-dialog-actions>
        <button mat-button color="basic" class="mr-1" (click)="closeRequestTemplate()">
            <i aria-hidden="true" class="fa fa-times"></i> Close
        </button>
    </div>
</ng-template>

<ng-template #responseTemplateDialog let-data style="width: '800px', max-height='90%'">
    <h2 mat-dialog-title>Response Template</h2>
    <div mat-dialog-content>
        <textarea class="popup-editor-container" [(ngModel)]="data.mockResponse"></textarea>
    </div>
    <div mat-dialog-actions>
        <button mat-button color="basic" class="mr-1" (click)="closeMockResponse()">
            <i aria-hidden="true" class="fa fa-times"></i> Close
        </button>
    </div>
</ng-template>
