<form [formGroup]="addCaseRequirementForm" *ngIf="hasRequirements && !baseProps.readonly">
    <div class="flex-row">
        <mat-form-field [style.width.px]=324>
            <mat-label>Select Requirement</mat-label>
            <mat-select formControlName="requirement" (selectionChange)="requirementSelected()">
                <mat-option
                    *ngFor="let requirement of requirements"
                    [value]="requirement"
                    [disabled]="requirement.disabled"
                    [matTooltip]="requirement.tooltip"
                    [matTooltipDisabled]="!requirement.tooltip"
                    matTooltipPosition="left">
                    {{ requirement.name }}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="addCaseRequirementForm.get('requirement').hasError('required')">No requirement selected</mat-error>
            <mat-hint *ngIf="!hasRequirements" class="no-config-requirements">No requirements configured.</mat-hint>
        </mat-form-field>
        <mrs-btn-tertiary
            buttonLabel="Add Requirement"
            iconName="add"
            (onClick)="handleAddCaseRequirement()"
            [disabled]="disableAddButton"
            textColor="#2C6EED"></mrs-btn-tertiary>
    </div>

    <div *ngIf="showSubtitleField">
        <mat-form-field style="width: 324px;">
            <mat-label>Add an optional subtitle</mat-label>
            <input matInput formControlName="subtitle" [attr.maxlength]="characterLimit">
            <mat-hint *ngIf="addCaseRequirementForm?.get('subtitle').valid">
                {{(characterLimit - addCaseRequirementForm?.get('subtitle').value.length) < 0 ? 0 : characterLimit -
                    addCaseRequirementForm?.get('subtitle').value.length}}/{{characterLimit}} characters remaining </mat-hint>
        </mat-form-field>
    </div>
</form>