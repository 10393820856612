import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { InterviewService } from '@App';
import { InterviewDTO_extended } from '@DTOs';
import { InterviewManagerService } from '@Interview';
import { InterviewDataService } from '@Services';
import { SubSink } from 'subsink';

import { InterviewSectionDialogAction, InterviewSectionDialogData, InterviewSectionDialogResponse } from './models';


@Component({
    selector: 'interview-section-dialog',
    templateUrl: './interview-section-dialog.component.html',
    styleUrls: ['./interview-section-dialog.component.scss']
})
export default class InterviewSectionDialog implements OnInit, OnDestroy {
    @ViewChild('sectionContainer') sectionContainer: ElementRef;

    dialogTitle = 'Section Requirement';
    interviewLoaded = false;
    sectionContainerHtmlId = 'interview-section-content';
    saveButtonLabel = 'Save Decision';


    subs = new SubSink();

    constructor(
        public dialogRef: MatDialogRef<InterviewSectionDialog, InterviewSectionDialogResponse>,
        @Inject(MAT_DIALOG_DATA) public data: InterviewSectionDialogData,
        private interviewService: InterviewService,
        private interviewDataService: InterviewDataService,
        protected interviewManager: InterviewManagerService,
    ) {
        this.interviewService.setAccount(this.data.account);
        this.saveButtonLabel = data.saveButtonLabel;
    }


    //#region Subscriptions

    subscribeInterviewData() {
        this.subs.add(this.interviewDataService.reloadInterviewForSection(this.data.case.id, this.data.sectionId).subscribe(interview => {
            // override active section with the specific section we want to show
            const interviewWithActiveSection: InterviewDTO_extended = { ...interview, activeSectionId: this.data.sectionId };
            this.interviewService.setInterviewData(interviewWithActiveSection);
            this.interviewManager.formatSectionData(interviewWithActiveSection);
            this.interviewLoaded = true;


        }));
    }

    subscribeToSavedInterviewResponse() {
        this.subs.add(this.interviewService.updatedInterview.subscribe(interview => {
            const interviewWithActiveSection: InterviewDTO_extended = { ...interview, activeSectionId: this.data.sectionId };
            this.interviewService.setInterviewData(interviewWithActiveSection);
            this.interviewManager.formatSectionData(interviewWithActiveSection);
            this.interviewLoaded = true;

            const anyInterviewErrors = this.interviewService.checkForSectionErrors(this.data.sectionId, interviewWithActiveSection.sections);
            if ((!anyInterviewErrors) || (interviewWithActiveSection.workflowCompleted)) {
                this.dialogRef.close({
                    action: InterviewSectionDialogAction.Saved
                });
            }
        }));
    }

    subscribeToSavedInterviewError() {
        this.subs.add(this.interviewService.notifyUpdateInterviewError.subscribe(_ => {
            this.interviewLoaded = true;
        }));
    }
    //#endregion
    //#region Lifecycle

    async ngOnInit() {
        this.dialogTitle = this.data.title;

        await this.interviewManager.init(this.data.client, this.data.account, this.data.case, this.sectionContainerHtmlId);
        await this.interviewService.loadAccountRules(this.data.account.id);

        this.interviewManager.setupSignalRConnection();

        this.subscribeInterviewData();

        this.subscribeToSavedInterviewResponse();
        this.subscribeToSavedInterviewError();
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    //#endregion
    //#region Handlers

    handleClose() {
        this.dialogRef.close({
            action: InterviewSectionDialogAction.Canceled
        });
    }

    async handleSave() {
        this.interviewLoaded = false;
        const didInterviewManagerDelegateToInterviewService = await this.interviewManager.saveInterview();

        if (didInterviewManagerDelegateToInterviewService === false) {
            this.interviewLoaded = true;
        }
        // the dialog close will only happen when no errors are on the interview
        // See subscribeToSavedInterviewResponse function -> that code gets run when the interview.service.ts
        // updates an observable with the interview data after the save.
    }
    //#endregion
}
