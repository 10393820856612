<h1 mat-dialog-title>{{data.actionName}}</h1>
<div mat-dialog-content>
  <div *ngIf="!data.hideStatus">{{data.actionStatus}}</div>
  <div [innerHtml]="data.message | safeHtml"></div>
  <div class="m-2 text-center" *ngIf="data.alternateLoader"><img [src]="data.alternateLoader" /></div>
  <div class="flex-1"><span><br /></span></div>
  <div *ngIf="!data.alternateLoader">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
  <div class="flex-1"><span><br /></span></div>
  <div mat-dialog-actions style="padding: 0;">
    <div class="flex-1"></div>
    <mrs-btn-tertiary *ngIf="canCancel" [buttonLabel]="cancelLabel" [textColor]="cancelTextColor" buttonId="cancelOkBtn"
      mat-dialog-close>
    </mrs-btn-tertiary>
    <mrs-btn-tertiary [buttonLabel]="refreshLabel" [textColor]="refreshTextColor" buttonId="confirmationOkBtn"
      (onClick)="refresh()">
    </mrs-btn-tertiary>
  </div>
</div>