enum CaseRequirementStatuses {
    Unknown = "00000000-0000-0000-0000-000000000000",
    NotOrdered = "00000000-0000-0000-0000-000000000001",
    Ordered = "00000000-0000-0000-0000-000000000002",
    Received = "00000000-0000-0000-0000-000000000003",
    Waived = "00000000-0000-0000-0000-000000000004",
    Completed = "00000000-0000-0000-0000-000000000005",
    Failed = "00000000-0000-0000-0000-000000000006",
    Pending = "00000000-0000-0000-0000-000000000007",
    Cancelled = "00000000-0000-0000-0000-000000000008",
}

export default CaseRequirementStatuses;
