﻿<div>
  <h1 mat-dialog-title>{{matDialogRefData.titleText}}</h1>
  <div>{{matDialogRefData.descriptionText}}</div>
  <div><em>{{matDialogRefData.helperText}}</em></div>
  <div mat-dialog-content>
    <form [formGroup]="htmlContentForm">
      <angular-editor id="html-input" matInput [config]="editorConfig" style="padding-top:8px"
                      formControlName="htmlContent" placeholder="Body">
      </angular-editor>
    </form>
  </div>
  <mat-dialog-actions class="text-right">
    <button id="cancelButton" mat-button color="basic" class="mr-1 material-default" mat-dialog-close>
      CANCEL
    </button>
    <button id="saveMessage" mat-button color="primary" class="mr-1 material-default" (click)="saveText()">
      {{matDialogRefData.confirmationText}}
    </button>
  </mat-dialog-actions>
</div>
