<div class="body" *ngIf="useAuthV2 && showContent">
    <div class="login-container text-center" *ngIf="!useNewLoginFormat">
        <div class="logo mb-3"></div>
        <div>
            <mrs-btn-primary
                buttonLabel="Sign In"
                buttonId="signInButton"
                (onClick)="signIn()"
            >
            </mrs-btn-primary>
        </div>
    </div>
    <div class="login-container text-center" *ngIf="useNewLoginFormat">
        <div class="flex" style="justify-content: center">
            <mat-card
                style="width: 500px"
                class="login-container-background mat-mdc-card-outlined">
                <mat-card-content>
                    <div class="logo mb-3"></div>
                    <div style="padding-bottom: 1.75rem">
                        <mrs-btn-primary
                            buttonLabel="Sign In"
                            buttonId="signInButton"
                            (onClick)="signIn()">
                        </mrs-btn-primary>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>
