export enum AnswerTypes {
    DisplayMessage = '00000000-0000-0000-0000-000000000001',
    ShortAnswer = '00000000-0000-0000-0000-000000000002',
    MultiLineAnswer = '00000000-0000-0000-0000-000000000003',
    PhoneNumberAnswer = '00000000-0000-0000-0000-000000000004',
    RoutingNumberAnswer = '00000000-0000-0000-0000-000000000005',
    NumericAnswer = '00000000-0000-0000-0000-000000000006',
    EmailAnswer = '00000000-0000-0000-0000-000000000007',
    DateAnswer = '00000000-0000-0000-0000-000000000008',
    HeightAnswer = '00000000-0000-0000-0000-000000000009',
    SSNAnswer = '00000000-0000-0000-0000-000000000010',
    ZipCode = '00000000-0000-0000-0000-000000000011',
    ActionButton = '00000000-0000-0000-0000-000000000012',
    MultiChoiceSingleAnswer = '00000000-0000-0000-0000-000000000013',
    HealthAnswer = '00000000-0000-0000-0000-000000000014',
    MultiChoiceMultiAnswer = '00000000-0000-0000-0000-000000000015',
    RepeatBlock = '00000000-0000-0000-0000-000000000016',
    EFTDate = '00000000-0000-0000-0000-000000000017',
    MultiChoiceMultiAnswerV2 = '00000000-0000-0000-0000-000000000018',
    UploadFileActionButton = '00000000-0000-0000-0000-000000000019',
    Aura = '00000000-0000-0000-0000-000000000020',
}
