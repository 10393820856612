export default class CombinedDocumentDTO {
    id: string;
    accountId: string;
    parentDocumentId: string;
    documentId: string | null;
    sort: number;
    name: string;
    tagName?: string;

    constructor(accountId: string, parentDocumentId: string, documentId: string, sort: number, name: string, tagName?: string) {

        this.accountId = accountId;
        this.parentDocumentId = parentDocumentId;
        this.documentId = documentId;
        this.sort = sort;
        this.name = name;
        this.tagName = tagName;
    }
}
