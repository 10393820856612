<div mat-dialog-content>
    <div class="content flex-fill d-flex flex-column page-header">Edit Combined Document</div>
    <mat-horizontal-stepper linear #stepper labelPosition="bottom">
      <mat-step id="step1" [stepControl]="addDocsFormGroup" label="Add Documents" class="step-two-fax">
        <document-wizard-document-add [nextButtonText]="stepTwoNextText" [canCancel]="true"
          [documents]="filteredSelectDocuments" [tags]="tags" [selectedDocuments]="selectedDocuments"
          [selectedTags]="selectedTags" [isFaxEvent]="isFaxEvent"
          [selectedItems]="selectedItems"></document-wizard-document-add>

      </mat-step>
      <mat-step id="step2" [stepControl]="arrangeDocsFormGroup" label="Arrange Documents" class="step-three-fax">
        <document-wizard-document-arrange [nextButtonText]="stepThreeNextText" [canCancel]="true"
          [documents]="filteredSelectDocuments" [tags]="tags" [selectedDocuments]="selectedDocuments"
          [selectedTags]="selectedTags" [selectedItems]="selectedItems" [isFaxEvent]="isFaxEvent"
          (completeArrangeEvent)="updateSelectedDocumentCombinedDocument()"></document-wizard-document-arrange>
      </mat-step>
    </mat-horizontal-stepper>
  </div>