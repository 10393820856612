import { Component, OnInit, ViewChild, Input, SimpleChanges, Output, EventEmitter, HostListener } from '@angular/core';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { AppService } from '../../../../app.service';
import { UntypedFormBuilder, UntypedFormGroup, FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import * as _ from 'lodash';
// import { EventEmitter } from 'events';

import { Account, AccountSigner, Envelope, EnvelopeSigner } from '../../../../models';
import { ConfirmationDialogService, SignerService } from '../../../../../app/services';
import { AccountEnvelopeSignerComponent } from './account-envelope-signer.component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { lastValueFrom } from 'rxjs';

@Component({
    selector: 'account-envelopes',
    host: { 'class': 'd-flex flex-row flex-fill' },
    templateUrl: './account-envelopes.component.html',
    styleUrls: ['./account-envelopes.component.scss']
})
export class AccountEnvelopesComponent implements OnInit {
    @Input() accountInfo: Account;
    @Input() mapKeys;
    @Input() rules;

    accountSigners: AccountSigner[];
    envelopes: any = [];
    envelopeForm: UntypedFormGroup;
    formSubmitted: boolean = false;
    selectedEnvelope: Envelope;
    envelopeSigners: EnvelopeSigner[];
    documents: any = [];

    workflowTypes = [
        {'id': '00000000-0000-0000-0000-000000000001', 'name': 'Email'},
        {'id': '00000000-0000-0000-0000-000000000002', 'name': 'Embedded'}
    ];

    dialogRef: MatDialogRef<AccountEnvelopeSignerComponent>;

    constructor(
        public dialog: MatDialog,
        private appService: AppService,
        private signerService: SignerService,
        private _fb: UntypedFormBuilder,
        private confirmationService: ConfirmationDialogService
    ) {
    }

    ngOnInit() {
        this.envelopeForm = this._fb.group({
            id: '',
            accountId: [this.accountInfo.id, Validators.required],
            name: ['', Validators.required],
            workflowTypeId: ['', Validators.required],
            documentId: [''],
            isCaseSummary: ['', Validators.required]
        });

        this.getEnvelopes();
        this.getAccountSigners();
        this.getDocuments();
    }

    getAccountSigners() {
        this.signerService.getAccountSigners(this.accountInfo.id)
            .subscribe(data => {
                this.accountSigners = data;
            }
            );
    }

    getDocuments() {
        this.appService.getData(`Documents/${this.accountInfo.id}`).subscribe(
            response => {
                if (response.status === 'success') {
                    this.documents = response.data.filter(x => x.integrationId === '00000000-0000-0000-0000-000000000025');
                }
            }
        );
    }

    getEnvelopes() {
        this.signerService.getEnvelopes(this.accountInfo.id).subscribe(data => {
            this.envelopes = data;
        });
    }

    getAccountSignerLabel(envelopeSigner: EnvelopeSigner) {
        const accountSigner = this.accountSigners.find(x => x.id === envelopeSigner.accountSignerId);
        return accountSigner.label;
    }

    selectEnvelope(envelope: Envelope) {
        if (this.selectedEnvelope === envelope) {
            this.selectedEnvelope = null;
        } else {
            this.formSubmitted = false;
            this.selectedEnvelope = envelope;
            this.envelopeForm.reset();
            this.envelopeForm.patchValue(this.selectedEnvelope);
            this.getEnvelopeSigners();
        }
    }

    addEnvelope() {
        this.formSubmitted = false;
        const envelope: Envelope = {
            id: '00000000-0000-0000-0000-000000000000',
            accountId: this.accountInfo.id,
            name: '',
            workflowTypeId: '',
            documentId: '',
            isCaseSummary: false
        };
        this.selectEnvelope(envelope);
        if (this.envelopes) {
            this.envelopes.push(envelope);
        } else {
            this.envelopes = [];
            this.envelopes.push(envelope);
        }
    }

    saveEnvelope() {
        this.formSubmitted = true;
        if (!this.envelopeForm.valid) {
            this.envelopeForm.markAsTouched();
            this.envelopeForm.controls.label.markAsTouched();
            this.envelopeForm.controls.emailMapKeyId.markAsTouched();
            this.envelopeForm.controls.nameMapKeyId.markAsTouched();
            return false;
        }

        const envelope: Envelope = this.envelopeForm.value;
        envelope.accountId = this.accountInfo.id;

        if (this.envelopeForm.valid) {
            if (this.selectedEnvelope.id) {
                this.saveEditEnvelope(envelope);
                this.formSubmitted = false;
            } else {
                this.saveAddSigner(envelope);
                this.formSubmitted = false;
            }
        }
    }

    saveEditEnvelope(envelope: Envelope) {
        this.signerService.editEnvelope(envelope, this.accountInfo.id).subscribe(res => {
            this.appService.showMsg('success', 'Edited successfully ...');
            this.envelopes = res;
            this.selectedEnvelope = null;
        });
    }

    saveAddSigner(envelope: Envelope) {
        this.signerService.addEnvelope(envelope, this.accountInfo.id).subscribe(res => {
            this.appService.showMsg('success', 'Added successfully ...');
            this.envelopes = res;
            this.selectedEnvelope = null;
        });
    }

    addEnvelopeSigner() {
        this.dialogRef = this.dialog.open(AccountEnvelopeSignerComponent, {
            width: '50%',
            data: {
                rules: this.rules,
                mapKeys: this.mapKeys,
                envelope: this.selectedEnvelope,
                accountId: this.accountInfo.id,
                envelopeId: this.selectedEnvelope.id,
                sortOrder: this.envelopeSigners.length + 1,
                isEmbedded: this.envelopeForm.controls.workflowTypeId.value === this.workflowTypes.find(x => x.name =="Embedded").id
            }
        });

        this.dialogRef.afterClosed().subscribe(res => {
            this.getEnvelopeSigners();
        });
    }

    selectEnvelopeSigner(envelopeSigner) {
        this.dialogRef = this.dialog.open(AccountEnvelopeSignerComponent, {
            width: '50%',
            data: {
                rules: this.rules,
                mapKeys: this.mapKeys,
                envelope: this.selectedEnvelope,
                accountId: this.accountInfo.id,
                envelopeSigner: envelopeSigner,
                label: this.getAccountSignerLabel(envelopeSigner),
                isEmbedded: this.envelopeForm.controls.workflowTypeId.value === this.workflowTypes.find(x => x.name =="Embedded").id
            }
        });

        this.dialogRef.afterClosed().subscribe(res => {
            this.getEnvelopeSigners();
        });
    }

    async getEnvelopeSigners(): Promise<any> {
        const promise = await lastValueFrom(this.signerService.getEnvelopeSigners(this.accountInfo.id, this.selectedEnvelope.id));
        this.envelopeSigners = await promise;
        this.envelopeSigners =  _.sortBy(this.envelopeSigners, ['sortOrder']);

        return promise;
    }

    async deleteAccountEnvelopeSigner(envelopeSigner, event) {
        event.stopPropagation();
        this.confirmationService.open({
            message: `Are you sure that you want to delete this signer?`,
            showCancel: true,
            onOk: async () => {
                await lastValueFrom(this.signerService.deleteEnvelopeSigner(this.accountInfo.id, this.selectedEnvelope.id, envelopeSigner.id));
                this.appService.showMsg('success', 'Deleted successfully ...');

                await this.getEnvelopeSigners();
                await this.resequenceSigners(); // resequence from 1 so there are no gaps.
            }
        });
    }

    deleteAccountEnvelope(envelope) {
        this.confirmationService.open({
            message: `Are you sure that you want to delete this envelope?`,
            showCancel: true,
            onOk: () => {
                this.signerService.deleteEnvelope(this.accountInfo.id, envelope.id).subscribe(
                    res => {
                        this.appService.showMsg('success', 'Deleted successfully ...');
                        this.getEnvelopes();
                    }
                );
            }
        });
    }

    async drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.envelopeSigners, event.previousIndex, event.currentIndex);
        await this.resequenceSigners();
    }

    async saveSequence(): Promise<any> {
        const promise = this.signerService.editEnvelopeSigners(this.envelopeSigners, this.accountInfo.id).toPromise();
        this.envelopeSigners = await promise;
        this.envelopeSigners = _.sortBy(this.envelopeSigners, ['sortOrder']);

        return promise;
    }

    cancelEnvelope(envelope) {
        if (envelope.id == null) {
            this.envelopes = _.reject(this.envelopes, function(o) { return o === envelope; });
        }
        this.selectedEnvelope = null;
    }

    async resequenceSigners(): Promise<any> {
        if (this.envelopeSigners == null) { return; }

        this.envelopeSigners.forEach((signer, index) => {
            signer.sortOrder = (index + 1);
        });

        await this.saveSequence();
    }
}
