<section id="evidence-manager-dialog">
    <h2 mat-dialog-title>{{ dialogTitle }}</h2>
    <div mat-dialog-content>
        <p *ngIf="evidenceItems.length == 0">Add requirement documents.</p>
        <p *ngIf="evidenceItems.length > 0">Add or view documents.</p>
    </div>

    <!-- No Documents -->
    <mat-dialog-content *ngIf="evidenceItems.length == 0">
        <p class="no-documents">No documents are currently attached.</p>
    </mat-dialog-content>

    <!-- List Documents :: TIFF Previewer Disabled -->
    <mat-dialog-content class="file-list-content" *ngIf="downloadTiffEnabled && evidenceItems.length > 0">
        <ul class="file-list">
            <li *ngFor="let evidence of evidenceItems"
                (click)="handlePreview(evidence)"
                [ngClass]="{ 'disabled': evidence.isTiffFile && !directDownloadIsEnabled}"
                [matTooltipDisabled]="!evidence.isTiffFile || directDownloadIsEnabled"
                [matTooltip]="msg_TiffPreviewNotSupported">
                <div class="details">
                    <div class="filename">
                        <b *ngIf="evidence.isSummaryDocument">Summary: </b>{{ evidence.name }}
                    </div>
                    <div class="file-added"><mat-icon svgIcon="file-document-outline" />Added {{ evidence.uploadedOnDay }} at {{ evidence.uploadedOnTime }} by {{ evidence.uploadedBy }}</div>
                    <div class="file-reviewed" *ngFor="let review of evidence.reviews"><mat-icon class="reviewed-icon" aria-hidden="false" aria-label="reviewed icon" fontIcon="check_circle" />Reviewed {{ review.reviewedOnDay }} at {{ review.reviewedOnTime }} by {{ review.userName }}</div>
                    <div class="file-summary-failed" *ngIf="evidence.isSummaryFailed"><mat-icon svgIcon="close-circle-outline" />Summary failed. Try again or contact Helpdesk.</div>
                    <br />
                    <label class="summarization-wait-text" *ngIf="evidence.isSummaryInProgress">Summarization may take a while.</label>
                </div>
                <div *ngIf="(requirement) && (requirement.evidenceProcessor) && requirement.evidenceProcessor.processorType === 'summarize'">
                    <mrs-btn-tertiary *ngIf="!evidence.isSummaryInProgress && !evidence.isSummaryCompleted && !evidence.isSummaryDocument"
                        buttonLabel="Summarize"
                        (onClick)="handleSummarizeDocumentClick($event, evidence.caseDocumentId)"
                        [disabled]="disableSummarize"
                        textColor="#2C6EED">
                    </mrs-btn-tertiary>
                    <div class="row" *ngIf="evidence.isSummaryInProgress">
                        <mat-spinner class="spinner" diameter="16"></mat-spinner>
                        <label class="summarizing-text">SUMMARIZING</label>
                    </div>
                </div>
                <div class="download" *ngIf="evidence.isTiffFile && !directDownloadIsEnabled">
                    <button mat-icon-button color="primary" (click)="handleDownload(evidence)">
                        <mat-icon aria-hidden="false" aria-label="download icon">download</mat-icon>
                    </button>
                </div>
            </li>
        </ul>
    </mat-dialog-content>

    <!-- List Documents :: TIFF Previewer Enabled -->
    <mat-dialog-content class="file-list-content" *ngIf="!downloadTiffEnabled && evidenceItems.length > 0">
        <ul class="file-list">
            <li *ngFor="let evidence of evidenceItems" (click)="handlePreview(evidence)">
                <div class="details">
                    <div class="filename">
                        <b *ngIf="evidence.isSummaryDocument">Summary: </b>{{ evidence.name }}
                    </div>
                    <div class="file-added flex flex-row">
                        <mat-icon class="mat-document-icon" svgIcon="file-document-outline" />
                        <div>Added {{ evidence.uploadedOnDay }} at {{ evidence.uploadedOnTime }} by {{ evidence.uploadedBy }}</div>
                    </div>
                    <div class="file-reviewed" *ngFor="let review of evidence.reviews"><mat-icon class="reviewed-icon" aria-hidden="false" aria-label="reviewed icon" fontIcon="check_circle" />Reviewed {{ review.reviewedOnDay }} at {{ review.reviewedOnTime }} by {{ review.userName }}</div>
                    <div class="file-summary-failed flex flex-row" *ngIf="evidence.isSummaryFailed">
                        <mat-icon class="summary-failed-icon" svgIcon="close-circle-outline" />
                        <div>Summary failed. Try again or contact Helpdesk.</div>
                    </div>
                    <br />
                    <label class="summarization-wait-text" *ngIf="evidence.isSummaryInProgress">Summarization may take a while.</label>
                </div>
                <div *ngIf="(requirement) && (requirement.evidenceProcessor) && requirement.evidenceProcessor.processorType === 'summarize'">
                    <mrs-btn-tertiary *ngIf="!evidence.isSummaryInProgress && !evidence.isSummaryCompleted && !evidence.isSummaryDocument"
                        buttonLabel="Summarize"
                        (onClick)="handleSummarizeDocumentClick($event, evidence.caseDocumentId)"
                        [disabled]="disableSummarize"
                        textColor="#2C6EED">
                    </mrs-btn-tertiary>
                    <div class="row summary-in-progress-row" *ngIf="evidence.isSummaryInProgress">
                        <mat-spinner class="spinner" diameter="16"></mat-spinner>
                        <label class="summarizing-text">SUMMARIZING</label>
                    </div>
                </div>
            </li>
        </ul>
    </mat-dialog-content>

    <!-- Add Document Button & Progress Bar -->
    <mat-dialog-content class="add-button-row">
        <ng-container *ngIf="uploading">
            <div class="fileUploadProgress">
                <span class="percent">{{ fileUploadService.uploadProgress }}% uploaded</span>
                <mat-progress-bar mode="determinate" [value]="fileUploadService.uploadProgress"></mat-progress-bar>
            </div>
        </ng-container>
        <div class="button-row" [ngClass]="{ 'has-documents': uploading || evidenceItems?.length > 0 || false }">
            <button mat-flat-button color="primary" (click)="fileInput.click()" [disabled]="uploading || data.readonly"><i aria-hidden="true" class="fa fa-plus"></i>&nbsp;Add Document</button>
            <p class="uploading-message" *ngIf="uploading">Closing this page during the upload will cause the items to be lost.</p>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <mrs-btn-tertiary buttonLabel="Close" buttonId="cancelButton" (click)="handleCancel()">Close</mrs-btn-tertiary>
    </mat-dialog-actions>
    <input #fileInput hidden="true" type="file" id="fileInput" accept="image/tiff,.tif, .pdf, .png, .jpg" (change)="handleUploadEvidence($event)" />
</section>