
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MrsColor } from 'app/enums';
import moment = require('moment');


@Component({
    selector: 'follow-up-dialog',
    templateUrl: './follow-up-dialog.component.html',
    styleUrls: ['./follow-up-dialog.component.scss']
})
export default class FollowUpDialog {

    followUpformGroup: FormGroup;
    buttonColor = MrsColor.UIBlue;
    title: string;
    isEdit = false;
    msg: string;
    followUp: string;
    readOnly = false;
    minDate: Date;
    maxDate: Date;

    constructor(
        public dialogRef: MatDialogRef<FollowUpDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _fb: FormBuilder
    ) {
        this.title = data.title;
        this.msg = data.msg;
        this.followUp = data.followUp;
        this.followUpformGroup = _fb.group({ 'follow-up': [this.followUp] });
    }

    //#region Lifecycle

    ngOnInit() {
        this.minDate = moment().add(1, 'day').startOf('day').toDate();
        this.maxDate = moment().add(12, 'month').startOf('day').toDate();
    }

    //#endregion
    //#region Handlers

    updateFollowUp(): void {
        this.dialogRef.close({
            "followUp": this.followUpformGroup.get('follow-up').value
        });
    }

    cancel(): void {
        this.dialogRef.close();
    }

    //#endregion

}
