<div class="case-manager-container">
  <div class="main-page-heading">
    Case Manager
  </div>
  <div class="case-manager-inner-container">
    <div class="flex flex-align-center" style="justify-content: space-between">
      <div>
        <mat-form-field id="filterCasesFormField" class="filter-cases" floatLabel="never" appearance="fill">
          <input class="case-summary-search-input" matInput placeholder="Search for a Case" name="filterValue"
            (keyup)="updateFilteredCases()" [(ngModel)]="filterValue">
          <mat-icon class="case-summary-search-icon" aria-hidden="false" aria-label="Case search icon" matSuffix>
            search
          </mat-icon>
        </mat-form-field>
      </div>
      <div class="ml-2" style="width:22rem;" *ngIf="hasMultipleClients">

        <app-multi-select-dropdown-list class="multi-select-dropdown-list"
                                        [listTitle]="'Filter by Client'"
                                        [completeList]="clientSelectListItems"
                                        [selectedItemsList]="onLoadSelectedClientsList"
                                        (selectionChanged)="selectedClientsChanged($event)">
        </app-multi-select-dropdown-list>
      </div>
      <div class="ml-2" style="width:22rem;">

        <app-multi-select-dropdown-list class="multi-select-dropdown-list"
                                        [listTitle]="'Filter by Assignee(s)'"
                                        [completeList]="assigneeSelectListItems"
                                        [selectedItemsList]="onLoadSelectedAssigneesList"
                                        (selectionChanged)="selectedAssigneesChanged($event)">
        </app-multi-select-dropdown-list>
      </div>

      <div style="margin-left: auto">
        <button type="button" class="btn float-right standard mrs-primary text-uppercase"
          style="font-size: 14px; font-weight:500; line-height: 16px" (click)="getCases(selectedQueue)">
          <i aria-label="refresh" class="fa fa-sync-alt rotate90"></i> Refresh
        </button>
      </div>
    </div>

    <mat-tab-group (selectedTabChange)="selectQueue($event)" [selectedIndex]="selectedIndex">
      <mat-tab *ngFor="let queue of queues; let index = index" [label]="queue.name">
        <ng-template matTabContent>
          <table mat-table [dataSource]="caseDataSource" matSort style="width:100%;overflow:hidden;">

            <!-- Select Box -->
            <ng-container matColumnDef="selection">
              <th mat-header-cell *matHeaderCellDef style="width:55px" id="selectionHeader">
              </th>
              <td mat-cell *matCellDef="let element" (click)="stopPropagation($event)">
                <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(element) : null"
                  [checked]="selection.isSelected(element)"></mat-checkbox>
              </td>
            </ng-container>

            <!-- Account Name -->
            <ng-container matColumnDef="accountName">
              <th mat-header-cell *matHeaderCellDef style="width:175px;" (click)="sortColumn('accountName')"
                id="accountNameHeader">
                {{(queue.managementQueueColumnMapKeys|filter : ['columnDefinition'] : 'accountName')[0]?.displayName}}
              </th>
              <td mat-cell *matCellDef="let element"> {{element.accountName}} </td>
            </ng-container>

            <!-- Client Name -->
            <ng-container matColumnDef="clientName">
              <th mat-header-cell mat-sort-header *matHeaderCellDef style="width:125px;" (click)="sortColumn('clientName')"
                id="clientNameHeader">
                {{(queue.managementQueueColumnMapKeys|filter : ['columnDefinition'] : 'clientName')[0]?.displayName}}
              </th>
              <td mat-cell *matCellDef="let element"> {{element.clientName}} </td>
            </ng-container>

            <!-- Case Number -->
            <ng-container matColumnDef="caseNumber">
              <th mat-header-cell mat-sort-header *matHeaderCellDef style="width:140px;" (click)="sortColumn('caseNumber')"
                id="caseNumberHeader">
                {{(queue.managementQueueColumnMapKeys|filter : ['columnDefinition'] : 'caseNumber')[0]?.displayName}}
              </th>
              <td mat-cell *matCellDef="let element"> {{element.caseNumber}} </td>
            </ng-container>

            <!-- Policy Number -->
            <ng-container matColumnDef="policyNumber">
              <th mat-header-cell mat-sort-header *matHeaderCellDef style="width:140px;" (click)="sortColumn('policyNumber')"
                id="policyNumberHeader">
                {{(queue.managementQueueColumnMapKeys|filter : ['columnDefinition'] : 'policyNumber')[0]?.displayName}}
              </th>
              <td mat-cell *matCellDef="let element"> {{element.policyNumber}} </td>
            </ng-container>

            <!-- Applicant -->
            <ng-container matColumnDef="applicantFullName">
              <th mat-header-cell mat-sort-header *matHeaderCellDef style="width:140px;" (click)="sortColumn('applicantFullName')"
                id="applicantHeader">
                {{(queue.managementQueueColumnMapKeys|filter : ['columnDefinition'] :
                'applicantFullName')[0]?.displayName}}
              </th>
              <td mat-cell *matCellDef="let element"> {{element.Case_Applicant_FirstName}}
                {{element.Case_Applicant_LastName}} </td>
            </ng-container>

            <!-- Age -->
            <ng-container matColumnDef="caseCreated">
              <th mat-header-cell mat-sort-header *matHeaderCellDef style="width:90px;" (click)="sortColumn('caseCreated')"
                id="caseCreatedHeader">
                {{(queue.managementQueueColumnMapKeys|filter : ['columnDefinition'] : 'caseCreated')[0]?.displayName}}
              </th>
              <td mat-cell *matCellDef="let element"> {{element.age}} </td>
            </ng-container>

            <!-- Best Time To Contact -->
            <ng-container matColumnDef="Case_Applicant_BestTimeToContact">
              <th mat-header-cell mat-sort-header *matHeaderCellDef style="width:90px;"
                (click)="sortColumn('Case_Applicant_BestTimeToContact')" id="bttcHeader">
                {{(queue.managementQueueColumnMapKeys|filter : ['columnDefinition'] :
                'Case_Applicant_BestTimeToContact')[0]?.displayName}}
              </th>
              <td mat-cell *matCellDef="let element"> {{element.Case_Applicant_BestTimeToContact}} </td>
            </ng-container>

            <!-- Case_GradedPlanIndicated -->
            <ng-container matColumnDef="Case_GradedPlanIndicated">
              <th mat-header-cell mat-sort-header *matHeaderCellDef style="width:90px;"
                (click)="sortColumn('Case_GradedPlanIndicated')" id="typeHeader">
                {{(queue.managementQueueColumnMapKeys|filter : ['columnDefinition'] :
                'Case_GradedPlanIndicated')[0]?.displayName}}
              </th>
              <td mat-cell *matCellDef="let element"> {{element.Case_GradedPlanIndicated}} </td>
            </ng-container>

            <!-- Case_PreviousCoverage_IsPreviousCoverageAnyButDentalDisplay -->
            <ng-container matColumnDef="Case_PreviousCoverage_IsPreviousCoverageAnyButDentalDisplay">
              <th mat-header-cell mat-sort-header *matHeaderCellDef style="width:90px;"
                (click)="sortColumn('Case_PreviousCoverage_IsPreviousCoverageAnyButDentalDisplay')" id="previousCoverageHeader">
                {{(queue.managementQueueColumnMapKeys|filter : ['columnDefinition'] :
                'Case_PreviousCoverage_IsPreviousCoverageAnyButDentalDisplay')[0]?.displayName}}
              </th>
              <td mat-cell *matCellDef="let element"> {{element.Case_PreviousCoverage_IsPreviousCoverageAnyButDentalDisplay}} </td>
            </ng-container>

            <!-- Last Updated -->
            <ng-container matColumnDef="lastTouched">
              <th mat-header-cell mat-sort-header *matHeaderCellDef style="width:75px;" (click)="sortColumn('lastTouched')"
                id="lastTouchedHeader">
                {{(queue.managementQueueColumnMapKeys|filter : ['columnDefinition'] : 'lastTouched')[0]?.displayName}}
              </th>
              <td mat-cell *matCellDef="let element"> {{element.lastTouched}} </td>
            </ng-container>

            <!-- State -->
            <ng-container matColumnDef="Case_Applicant_State">
              <th mat-header-cell mat-sort-header *matHeaderCellDef style="width:75px;" (click)="sortColumn('Case_Applicant_State')"
                id="stateHeader">
                {{(queue.managementQueueColumnMapKeys|filter : ['columnDefinition'] :
                'Case_Applicant_State')[0]?.displayName}}
              </th>
              <td mat-cell *matCellDef="let element"> {{element.Case_Applicant_State}} </td>
            </ng-container>

            <!-- Status -->
            <ng-container matColumnDef="statusName">
              <th mat-header-cell mat-sort-header *matHeaderCellDef style="width:150px;" (click)="sortColumn('statusName')"
                id="statusHeader">
                {{(queue.managementQueueColumnMapKeys|filter : ['columnDefinition'] : 'statusName')[0]?.displayName}}
              </th>
              <td mat-cell *matCellDef="let element"> {{element.statusName}} </td>
            </ng-container>

            <!-- Note -->
            <ng-container matColumnDef="note">
              <th mat-header-cell *matHeaderCellDef style="width:175px;" (click)="sortColumn('note')" id="noteHeader">
                {{(queue.managementQueueColumnMapKeys|filter : ['columnDefinition'] : 'note')[0]?.displayName}}
              </th>
              <td style="max-width: 150px;" mat-cell *matCellDef="let element"
                [innerHTML]="element.note | removeHTMLTags"></td>
            </ng-container>

            <!-- Assignee -->
            <ng-container matColumnDef="assignee">
              <th mat-header-cell *matHeaderCellDef style="width:200px;" (click)="sortColumn('assignee')"
                id="assigneeHeader">
                {{(queue.managementQueueColumnMapKeys|filter : ['columnDefinition'] : 'assignee')[0]?.displayName}}
              </th>
              <td style="max-width: 200px; padding-right:5px;" mat-cell *matCellDef="let element">
                <span style="display: block; overflow:hidden;" *ngIf="canAssignCase && element.canAssignTo">
                  {{element.assignedTo && element.assignedTo !== null ? element.assignedToFullName :
                  'Unassigned'}}</span>
              </td>
            </ng-container>

            <!-- Actions -->
            <ng-container matColumnDef="actions">
              <mat-header-cell *matHeaderCellDef></mat-header-cell>
              <td mat-cell class="ml-2 align-content-center" *matCellDef="let element" (click)="stopPropagation($event)"
                class="cell-icon">
                <em aria-hidden="true" class="ml-1 fa fa-asterisk" style="color: red; margin-right: 10px;"
                  *ngIf="element.usersInCase && element.usersInCase != null" [matTooltip]="element.usersInCase"></em>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: columnsToDisplay;" (click)="viewCaseDetails(row, false)"
              (contextmenu)="openMenu($event, row)" class="row-height" style="cursor: pointer"></tr>
          </table>
          <div *ngIf="caseDataSource.filteredData == null || caseDataSource.filteredData.length == 0" class="bg-white">
            <div class="p-4 text-center text-muted ">
              <img src="./assets/images/no-data-placeholder.png " alt=" " class="pb-3 " />
              <h6>No Cases Found</h6>
            </div>
          </div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
    <div class="flex mat-paginator-sticky mat-mdc-paginator-container">
      <div id="actionButtons" *ngIf="selection.selected.length > 0" class="flex" [@flyRightToLeft]>
        <div id="selectedChip" style="overflow: visible; margin-bottom: auto; margin-top: auto">
          <mat-chip-listbox>
            <mat-chip-option>{{selection.selected.length}} selected</mat-chip-option>
          </mat-chip-listbox>
        </div>
        <div>
          <button type="button" class="btn btn-link text-uppercase" [matMenuTriggerFor]="assignToMenu"
            (menuOpened)="getClientUsers()" matTooltip="Assign To" aria-label="Assign To" aria-describedby="Assign To"
            *ngIf="canAssignCase && canSelectedCasesBeAssign()">
            <em class="material-icons">assignment_ind</em>
            Assign To
          </button>
          <button type="button" class="btn btn-link text-uppercase" *ngIf="selection.selected.length===1"
            (click)="viewNotes(selection.selected[0])" matTooltip="Open Notes" aria-label="Open Notes"
            aria-describedby="Open Notes">
            <em class="material-icons">note</em>
            View Notes
          </button>
          <button type="button" class="btn btn-link text-uppercase" matTooltip="Open In New"
            aria-label="Open In New Tab" aria-describedby="Open In New Tab" (click)="openInNewWindow()">
            <em class="material-icons">open_in_new</em>
            Open in new tab
          </button>
        </div>
      </div>
      <div style="margin-left: auto">
        <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]">
        </mat-paginator>
      </div>
    </div>
  </div>
  <div id="actionMenuOverlay" style="visibility: hidden; position: fixed" [style.left]="contextMenuPosition.x"
    [style.top]="contextMenuPosition.y" [matMenuTriggerFor]="actionMenu">
  </div>

  <mat-menu #actionMenu="matMenu">
    <ng-template matMenuContent>
      <div class="context-menu">
        <div class="menu-item text-uppercase" mat-menu-item [matMenuTriggerFor]="assignToMenu"
          (menuOpened)="getClientUsers()" *ngIf="canAssignCase && canSelectedCasesBeAssign()">
          <em class="material-icons mr-4">assignment_ind</em>Assign To
        </div>
        <div class="menu-item text-uppercase" mat-menu-item (click)="viewNotes(selection.selected[0])"
          *ngIf="selection.selected.length === 1">
          <em class="material-icons mr-4">note</em>View Notes
        </div>
        <div class="menu-item text-uppercase" mat-menu-item (click)="openInNewWindow()">
          <em class="material-icons mr-4">open_in_new</em>
          Open in new tab
        </div>
      </div>
    </ng-template>
  </mat-menu>


  <mat-menu #assignToMenu="matMenu" [aria-labelledby]="'Assign To Menu'" yPosition="above">
    <ng-template matMenuContent>
      <div class="menu-item" mat-menu-item *ngIf="clientUsers.length > 0">
        <form novalidate (click)="stopPropagation($event)">
          <mat-form-field class="w-100">
            <mat-label>Assign to</mat-label>
            <input type="text" #userInput [placeholder]="'Select User...'" matInput [formControl]="selectedUser"
              [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption
              (optionSelected)="selectedUser.value === this.appService.emptyGUID?claimCase():assignUser()"
              [displayWith]="getOptionText.bind(this)">
              <mat-option [value]="appService.emptyGUID">Unassign</mat-option>
              <mat-option *ngFor="let clientUser of filteredUserList" [value]="clientUser.id">
                {{clientUser.firstName}} {{clientUser.lastName}}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="selectedUser.hasError('required')">
              Please select a user to assign the case to
            </mat-error>
          </mat-form-field>
        </form>
      </div>
      <div mat-menu-item *ngIf="clientUsers.length==0">No users for selection.</div>
    </ng-template>
  </mat-menu>
</div>
