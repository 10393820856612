import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, Injectable, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { BehaviorSubject } from 'rxjs';


export class TreeItemNode {
    children: TreeItemNode[];
    name: string;
    level: number;
    expandable: boolean;
    parentId: string;
    id: string;
}

export class TreeItemFlatNode {
    name: string;
    level: number;
    expandable: boolean;
    parentId: string;
    id: string;
}


@Injectable()
export class ChecklistDatabase {
    dataChange = new BehaviorSubject<TreeItemNode[]>([]);
    treeData: any[];
    get data(): TreeItemNode[] { return this.dataChange.value; }

    initialize(data) {
        this.treeData = data;
        // Build the tree nodes from Json object. The result is a list of `TodoItemNode` with nested
        //     file node as children.
        const fileTreeData = this.treeData;
        // Notify the change.
        this.dataChange.next(fileTreeData);
    }

    public filter(filterText: string) {
        let filteredTreeData;
        if (filterText) {

            filteredTreeData = this.treeData.map((element) => {
                return {
                    ...element,
                    children: element.children.filter((child) => child.name.toLocaleLowerCase().indexOf(filterText.toLocaleLowerCase()) > -1
          || element.name.toLocaleLowerCase().indexOf(filterText.toLocaleLowerCase()) > -1)
                };
            });

            filteredTreeData  = filteredTreeData.filter((element) => element.children?.length > 0);

        } else {
            filteredTreeData = this.treeData;
        }

        this.dataChange.next(filteredTreeData);
    }
}

@Component({
    selector: 'mrs-treeview',
    templateUrl: './mrs-treeview.component.html',
    styleUrls: ['./mrs-treeview.component.scss'],
    providers: [ChecklistDatabase]
})
export class MrsTreeviewComponent implements OnChanges {
  @Input() data;
  @Input() selectedItems;
  @Input() treeViewHeight = "45vh";
  @Output() selectionModel = new EventEmitter<TreeItemFlatNode[]>();

  flatNodeMap = new Map<TreeItemFlatNode, TreeItemNode>();
  nestedNodeMap = new Map<TreeItemNode, TreeItemFlatNode>();
  selectedParent: TreeItemFlatNode | null = null;
  newItemName = '';
  treeControl: FlatTreeControl<TreeItemFlatNode>;
  treeFlattener: MatTreeFlattener<TreeItemNode, TreeItemFlatNode>;
  dataSource: MatTreeFlatDataSource<TreeItemNode, TreeItemFlatNode>;
  all = false;
  expanded = false;

  /** The selection for checklist */
  checklistSelection = new SelectionModel<TreeItemFlatNode>(true);

  constructor(private database: ChecklistDatabase) {}

  ngOnChanges(changes: SimpleChanges) {
      if ((changes?.data?.currentValue !== changes?.data?.previousValue) ||
         (changes?.selectedItems?.currentValue !== changes?.selectedItems?.previousValue )) {
          this.setupTree();
      }
  }

  setupTree(){
      this.database.initialize(this.data);
      this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel,
          this.isExpandable, this.getChildren);
      this.treeControl = new FlatTreeControl<TreeItemFlatNode>(this.getLevel, this.isExpandable);
      this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

      this.database.dataChange.subscribe(data => {
          if (data && this.dataSource){
              this.dataSource.data = data;
          }
      });

      this.setSelectedItems();

      this.selectionModel.emit(this.checklistSelection.selected);
  }

  setSelectedItems() {
      if (this.treeControl?.dataNodes){
          this.treeControl.dataNodes.forEach((node) => {
              if (this.selectedItems?.find(x => node.id == x.id)) {
                  this.checklistSelection.select(node);
                  this.treeControl.expand(node);
              }else{
                  if (node.parentId && node.parentId !== null) {
                      const parent = this.selectedItems?.find(x => x.id == node.parentId);

                      if (parent?.accounts.find(x => x.id === node.id)) {
                          this.checklistSelection.select(node);
                      }else{
                          this.checklistSelection.deselect(node);
                      }
                  }else{
                      this.checklistSelection.deselect(node);
                  }
              }
          });
      }
  }

  getLevel = (node: TreeItemFlatNode) => node.level;

  isExpandable = (node: TreeItemFlatNode) => node.expandable;

  getChildren = (node: TreeItemNode): TreeItemNode[] => node.children;

  hasChild = (_: number, _nodeData: TreeItemFlatNode) => _nodeData.expandable;

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: TreeItemNode, level: number) => {
      const existingNode = this.nestedNodeMap.get(node);
      const flatNode = existingNode && existingNode.id === node.id
          ? existingNode
          : new TreeItemFlatNode();
      flatNode.id = node.id;
      flatNode.level = level;
      flatNode.id = node.id;
      flatNode.parentId = node.parentId;
      flatNode.name = node.name;
      flatNode.expandable = node.children && node.children.length > 0;

      // if (this.selectedItems){
      //   if (flatNode.expandable){
      //     const selectedNode = (this.dataSource.data.find(x => x.id == node.id)) as TreeItemFlatNode;
      //     if (selectedNode){
      //       this.checklistSelection.select(selectedNode);
      //     }
      //   }
      // }

      this.flatNodeMap.set(flatNode, node);
      this.nestedNodeMap.set(node, flatNode);
      return flatNode;
  };

  /** Whether all the descendants of the node are selected */
  descendantsAllSelected(node: TreeItemFlatNode): boolean {
      const descendants = this.treeControl.getDescendants(node);
      return descendants.every(child => this.checklistSelection.isSelected(child));
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: TreeItemFlatNode): boolean {
      const descendants = this.treeControl.getDescendants(node);
      const result = descendants.some(child => this.checklistSelection.isSelected(child));
      return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: TreeItemFlatNode): void {
      this.checklistSelection.toggle(node);
      const descendants = this.treeControl.getDescendants(node);
      this.checklistSelection.isSelected(node)
          ? this.checklistSelection.select(...descendants)
          : this.checklistSelection.deselect(...descendants);

      this.selectionChange();
  }

  filterChanged(filterText: string) {
      this.database.filter(filterText);
      if(filterText)
      {
          this.treeControl.expandAll();
      } else {
          this.treeControl.collapseAll();
      }
  }

  selectionChange() {
      this.selectionModel.emit(this.checklistSelection.selected);
  }

  selectAll() {
      this.all = !this.all;

      this.treeControl.dataNodes.forEach(node => {
          if (this.all) {
              if(!this.checklistSelection.isSelected(node))
                  this.checklistSelection.toggle(node);
          }else{
              if(this.checklistSelection.isSelected(node))
                  this.checklistSelection.toggle(node);
          }
      });

      this.selectionChange();
  }

  toggleExpansion() {
      this.expanded = !this.expanded;

      if (this.expanded)
          this.treeControl.expandAll();
      else
          this.treeControl.collapseAll();
  }
}
