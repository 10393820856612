import { FeatureManagerService } from "@Services";
import { FilterableListService } from "@Services/config/filterable-list.service";
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FeatureToggle } from "app/enums";
import { Subscription } from "rxjs";
import { BaseFormFieldServices, BaseInterviewFormFieldComponent } from '../base-interview-form-field/base-interview-form-field.component';

@Component({
    selector: 'multi-choice-single-answer',
    templateUrl: './multi-choice-single-answer.component.html',
    styleUrls: ['./multi-choice-single-answer.component.scss']
})
export class MultiChoiceSingleAnswerComponent extends BaseInterviewFormFieldComponent implements OnInit, OnDestroy {
    indentReflexiveQuestions: boolean;
    reflexiveQuestions: any = [];
    reflexiveFormId: any = [];
    inputTypeDropdown = 'dropDown';
    inputTypeRadiobutton = 'radioButton';
    inputTypeButton = 'button';
    constantList: any = [];
    showList = true;
    
    private filterableListUpdatedSub: Subscription;

    constructor(public services: BaseFormFieldServices, public filterableListService: FilterableListService, private featureManagerService: FeatureManagerService) {
        super(services);
    }

    override constantListUpdatedLogic() {
        this.setupConfigConstantValues();
    }

    //#region Lifecycle

    ngOnInit() {
        this.filterableListUpdatedSub = this.filterableListService.filterableListUpdated$.subscribe((filteredList) => {
            if (this.filterableListService.filteredListOrConfigOrContantListHasRuleId(filteredList, this.constantList, this.config)) {
                if (Object.keys(filteredList).length != 0 && this.config['multiChoiceAnswers'][0].constantKeyNavigation?.id) {
                    this.constantList = filteredList[this.config['multiChoiceAnswers'][0].constantKeyNavigation.id];
                }

                const group = this.group.get(this.config.id);
                if (group.value && group.value.length > 0) {
                    if (this.constantList && !this.constantList.map(x => x.value).includes(group.value)) {
                        this.group.get(this.config.id).setValue('');
                    }

                    if (this.config.isReflexive) {
                        if (!this.config.listValues?.find(l => l.value === group.value)) {
                            this.reflexiveQuestions = [];
                            this.showReflexiveQuestions('');
                        }
                    }
                    this.onBlur();
                    this.updateDependentQuestions();
                }
                if (this.featureManagerService.getByName(FeatureToggle.GlobalVariantsResetJSONOnVariantFilter).enabled) {
                    this.services.interviewService.updateInterviewJson(null, null, true);
                }
            }
            this.showList= true;
        });

        this.config.removingDependentQuestions = [];
        this.config.newDependentQuestions = [];

        // if interviewmode is repeat, i.e, if reflexive is in repeat form, then the reflexive form group id is set to repeat form id, so as to differentiate it from normal reflexive form.
        if (this.interviewMode === 'repeat') {
            this.reflexiveFormId = this.config.repeatFormId;
        } else {
            this.reflexiveFormId = this.config.id;
        }

        if (this.interviewMode) {
            if (this.config['multiChoiceAnswers'][0]) {
                // get options from 'multichoiceanswers' property
                this.getListValuesToQuestion();
                this.subscriptions.push(this.group.get(this.config.id).valueChanges.subscribe(
                    data => {
                        // on value change, if the field has reflexive questions, show the corresponding questions for the selected option.
                        if (this.config.isReflexive) {
                            let answerId;
                            if (this.constantList.find(l => l.value === data)) {
                                answerId = this.constantList.find(l => l.value === data).id;
                            }

                            const constantLists = this.constantLists[this.config['multiChoiceAnswers'][0].constantKeyNavigation.id];

                            if (constantLists && constantLists.some(x => x.ruleId) && this.config.listValues.find(l => l.value === data)) {
                                answerId = this.config.listValues.find(l => l.value === data).id;
                            }
                            
                            if (answerId) {
                                this.showReflexiveQuestions(answerId);
                            }
                        }
                        this.onBlur();
                        // on value change , update dependent questions, i.e questions dependent on the value of this question and also on the values of the reflexive questions present.
                        this.updateDependentQuestions();
                    }
                ));
                this.indentReflexiveQuestions = this.config['multiChoiceAnswers'][0].indentReflexiveQuestions;
            }
        } else {
            if (this.config['multiChoiceAnswers'][0]) {
                this.setupConfigConstantValues();
            }
        }

        super.initialize();
    }

    onBlur() {
        if (this.isLiveMode()) {
            const fieldControl = this.group.get(this.config.id);
            if (fieldControl && fieldControl.dirty && fieldControl.valid) {
                // todo: do we need this?
                if (fieldControl.value) {
                    this.services.interviewService.setQuestionAnswer(this.config, fieldControl.value);
                }
                super.onBlur();
            }
        } else {
            super.onBlur();
        }
    }

    // remove reflexive form reference in interviewService on destroy
    ngOnDestroy() {
        delete this.services.interviewService.reflexiveForms[this.reflexiveFormId];
        this.filterableListUpdatedSub.unsubscribe();
        super.ngOnDestroy();
    }

    //#endregion

    setupConfigConstantValues() {
        if (this.config['multiChoiceAnswers'][0] &&
            this.config['multiChoiceAnswers'][0].constantKey) {

            if (this.constantLists) {

                this.constantList = this.constantLists[this.config['multiChoiceAnswers'][0].constantKey];
            }
        }
    }

    getListValuesToQuestion() {
        if (this.config['multiChoiceAnswers'][0] &&
            this.config['multiChoiceAnswers'][0].constantKeyNavigation) {
            if (this.constantLists) {
                this.constantList = this.constantLists[this.config['multiChoiceAnswers'][0].constantKeyNavigation.id];
                if (this.featureManagerService.getByName(FeatureToggle.FilterableStaticMapkeys).enabled){
                 
                    if (this.constantList && this.constantList.filter(x=> x.ruleId).length > 0){
                        this.showList= false;
                        this.filterableListService.updateFilterableListAsync(this.constantLists, this.interviewMode);
                    }
                }
            }

            if (this.config['multiChoiceAnswers'][0].constantKeyNavigation.constantMapKeyValues) {
                this.config['listValues'] = this.config['multiChoiceAnswers'][0].constantKeyNavigation.constantMapKeyValues;
            }
        }
    }

    showReflexiveQuestions(questionData) {
        this.config.removingDependentQuestions = [];
        this.config.newDependentQuestions = [];
        if (questionData) {
            const listMapKeyValue = this.config.listValues.find(l => l.id === questionData);
            const currentSection = this.services.interviewService.getActiveSection();

            // first remove all the existing dependent questions
            if (this.reflexiveQuestions && this.reflexiveQuestions.length) {
                this.reflexiveQuestions.forEach(reflexiveQuestion => {
                    if (reflexiveQuestion.answerType === 'RepeatBlock' && reflexiveQuestion.blocks && reflexiveQuestion.blocks.length) {
                        this.services.interviewService.removeRepeatBlocks(reflexiveQuestion);
                        reflexiveQuestion.blocks.forEach(block => {
                            if (this.services.interviewService.repeatForms[block.repeatId]) {
                                delete this.services.interviewService.repeatForms[block.repeatId];
                            }
                        });
                        reflexiveQuestion.blocks = null;
                    }
                });

                if (this.config.dependentQuestions && this.config.dependentQuestions.length > 0) {
                    for (const eachReflexiveQuestion of this.reflexiveQuestions) {
                        if (eachReflexiveQuestion.dependentQuestions && eachReflexiveQuestion.dependentQuestions.length > 0) {
                            for (const eachDepdentQuestion of eachReflexiveQuestion.dependentQuestions) {
                                if (this.config.dependentQuestions.includes(eachDepdentQuestion)) {
                                    this.config.removingDependentQuestions = this.config.removingDependentQuestions ? this.config.removingDependentQuestions : [];
                                    // let dependentQuestionIndex = this.config.dependentQuestions.lastIndexOf(eachDepdentQuestion);
                                    this.config.removingDependentQuestions.push(eachDepdentQuestion);
                                }
                            }
                        }
                    }
                }
            }

            // remove reflexive questions from repeat block form
            this.reflexiveQuestions.forEach(reflexiveQuestion => {
                if (reflexiveQuestion.repeatFormId) {
                    const repeatForm = this.services.interviewService.repeatForms[reflexiveQuestion.repeatFormId];
                    if (repeatForm) {
                        repeatForm.removeControl(reflexiveQuestion.id);
                    }
                }
            });

            // reassign reflexiveQuestions property to empty array, we will add new questions to this array based on the value changed.
            this.reflexiveQuestions = [];
            if (this.services.interviewService.reflexiveForms[this.reflexiveFormId]) {
                // delete the existing reflexive form reference from interviewservice. It shall be added if any new questions will get added on value changed.
                delete this.services.interviewService.reflexiveForms[this.reflexiveFormId];

            }
            const currentRefQuestions = [];
            if (listMapKeyValue) {
                const mapKeyReflexives = this.config.reflexiveQuestions.filter(x => x.answerValueId === listMapKeyValue.id);

                for (const eachCurrentRefQuestion of mapKeyReflexives) {
                    currentRefQuestions.push(eachCurrentRefQuestion.refQuestionId);
                }
            }
            // update display and value for the reflexive questions (display property for each question is the one used to show/hide it in form)
            for (const eachRefQuestion of this.config.reflexiveQuestions) {
                if (!currentRefQuestions.includes(eachRefQuestion.refQuestionId)) {
                    this.hideNestedReflexives(currentSection, eachRefQuestion);
                }
            }
            // time out is required as if there are no changes in reflexiveQuestions array, angular doesnt create a new form (so there is no form reference in interviewService as it got deleted in our previous step, hence form values don't update)
            setTimeout(() => {
                if (this.config.listValues && this.config.listValues.length > 0) {

                    if (listMapKeyValue) {
                        for (const eachQuestion of this.config.reflexiveQuestions) {
                            if (eachQuestion.answerValueId === listMapKeyValue.id) {
                                let newRefQuestion;
                                if (this.config.repeatFormId) {
                                    const repeatFormId = this.config.repeatFormId;
                                    newRefQuestion = currentSection.questions.find(q => q.id === eachQuestion.refQuestionId && q.repeatFormId === repeatFormId);
                                    if (!newRefQuestion) {
                                        const displayTypeQuestion = currentSection.questions.find(q => q.id === eachQuestion.refQuestionId && q.answerType === 'DisplayMessage');
                                        if (displayTypeQuestion) {
                                            newRefQuestion = displayTypeQuestion;
                                        }
                                    }
                                } else {
                                    newRefQuestion = currentSection.questions.find(q => q.id === eachQuestion.refQuestionId);
                                }

                                if (newRefQuestion) {
                                    // validate action results to see if the field in the current form should be hidden because of action results.
                                    newRefQuestion.display = this.validateActionResults(newRefQuestion);
                                    this.reflexiveQuestions.push(newRefQuestion);

                                    // add dependent questions for the reflexive inner questions to the main question..so when value of main question is changed their preactions will get called.
                                    if (newRefQuestion.dependentQuestions && newRefQuestion.dependentQuestions.length > 0) {
                                        this.config.dependentQuestions = this.config.dependentQuestions ? this.config.dependentQuestions : [];
                                        for (const eachDepdentQuestion of newRefQuestion.dependentQuestions) {

                                            if (!this.config.dependentQuestions.includes(eachDepdentQuestion)) {
                                                this.config.newDependentQuestions = this.config.newDependentQuestions ? this.config.newDependentQuestions : [];
                                                this.config.newDependentQuestions.push(eachDepdentQuestion);
                                            }
                                        }

                                    }
                                }
                            }
                        }
                    }
                    this.updateDependentQuestions();
                }
                if (this.reflexiveQuestions.length === 0) {
                    delete this.services.interviewService.reflexiveForms[this.reflexiveFormId];
                }
            }, 200);
        }
    }

    hideNestedReflexives(currentSection, reflexiveQuestion) {
        let newRefQuestion;
        if (this.config.repeatFormId) {
            const repeatFormId = this.config.repeatFormId;
            newRefQuestion = currentSection.questions.find(q => q.id === reflexiveQuestion.refQuestionId && q.repeatFormId === repeatFormId);
        } else {
            newRefQuestion = currentSection.questions.find(q => q.id === reflexiveQuestion.refQuestionId);
        }
        if (newRefQuestion) {
            newRefQuestion.display = false;
            this.services.interviewService.setQuestionAnswer(newRefQuestion, '');
            if (newRefQuestion.reflexiveQuestions && newRefQuestion.reflexiveQuestions.length > 0) {
                newRefQuestion.reflexiveQuestions.forEach(element => {
                    this.hideNestedReflexives(currentSection, element);
                });
            }
        }
    }

    validateActionResults(question) {
        let questionDisplay = true;

        if (question.actionResult && question.actionResult.length) {
            const allActionResults = question.actionResult;
            for (const eachActionResult of allActionResults) {
                if (eachActionResult.actionResults && eachActionResult.actionResults.data && (eachActionResult.actionResults.data.display === 'false' || eachActionResult.actionResults.data.display === false)) {
                    questionDisplay = false;
                }
            }

            return questionDisplay;
        }

        if (question.questionActions && question.questionActions.length) {
            const actions = question.questionActions;
            for (const action of actions) {
                if (action.actionResults && action.actionResults.data && (action.actionResults.data.display === 'false' || action.actionResults.data.display === false)) {
                    questionDisplay = false;
                }
            }
        }

        return questionDisplay;
    }

    updateValueOnButtonClick(value) {
        this.group.get(this.config.id).markAsDirty();
        this.group.get(this.config.id).setValue(value);
    }

    updateDependentQuestions() {
        for (const eachRemovingQuestion of this.config.removingDependentQuestions) {
            const dependentQuestionIndex = this.config.dependentQuestions.lastIndexOf(eachRemovingQuestion);
            if (dependentQuestionIndex > -1)
                this.config.dependentQuestions.splice(dependentQuestionIndex, 1);
        }
        for (const eachNewQuestion of this.config.newDependentQuestions) {
            this.config.dependentQuestions.push(eachNewQuestion);
        }
    }

    hideQuestion(question) {
        let hideInLeftPanel = false;
        if (question.propertyValues) {
            question.propertyValues.forEach(element => {
                if (element.ansTypePropertiesId === '00000000-0000-0000-0000-000000000038') {
                    hideInLeftPanel = true;
                }
            });
        }
        return hideInLeftPanel;
    }
}
