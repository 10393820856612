<div class="w-25 d-flex flex-column">
    <div class="list-heading">
        <span>Mapkeys</span>
    </div>
    <div style="background:white" class="flex-fill overflow-y-auto custom-scrollbar">
        <p-tree [value]="mapKeysService.treeData" selectionMode="single" [(selection)]="selectedNode"
                (onNodeSelect)="getSelectedNodeInfo($event)" [contextMenu]="mapMenu">
            <ng-template let-node pTemplate="default">
                <div class="tree-button" (contextmenu)="nodeSelect(node)">
                    {{node.label}}
                </div>
            </ng-template>
        </p-tree>
        <p-contextMenu #mapMenu appendTo="body" styleClass="map-menu" [model]="items"></p-contextMenu>
    </div>
</div>
<div *ngIf="!showContent" class="flex-fill d-flex flex-column align-items-center">
        <h2 style="padding-top:96px; margin-bottom:20px">No Mapkey Selected</h2>
        <div>To get started, select a Mapkey from the list.</div>
</div>
<div *ngIf="showContent" class="flex-fill d-flex flex-column">
    <account-mapkey [accountInfo]="accountInfo" [rules]="rules" [mapkeyOperation]="mapkeyOperation" (newMapKeyAdded)="onNewMapkeyAdded($event)" (undoConstantCancel)="selectNodeByMapkeyId($event.id);">
    </account-mapkey>
</div>
