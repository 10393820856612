import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {  UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PurlQuestion, Account, AccountPurlSettings } from '../../../../models';
import { AccountSettingsService, QuestionService } from '../../../../services';
import * as _ from 'lodash';
import { RichTextEditorComponent } from '../../../../components/rich-text-editor/rich-text-editor.component';
import { AppService } from '../../../../app.service';
import { RxwebValidators } from '@rxweb/reactive-form-validators';

@Component({
    selector: 'purl-settings',
    templateUrl: './purl-settings.component.html'
})
export class PurlSettingsComponent implements OnInit {
  @Input() accountInfo: Account;
  @ViewChild('expirationMessageEditor') expirationMessageEditor: RichTextEditorComponent;
  @ViewChild(' verificationAttemptsMessageEditor') verificationAttemptsMessageEditor: RichTextEditorComponent;

  showForm = false;
  purlSettingsForm: UntypedFormGroup;
  accountSetting: any = {};
  purlSettings: AccountPurlSettings;
  questions;
  expirationMessage = ' ';
  verificationAttemptsMessage = ' ';
  purlEnabled; boolean = false;
  charsRemaining = 0;

  constructor(
    public accountSettingService: AccountSettingsService,
    public appService: AppService,
    public questionService: QuestionService,
    private _fb: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
      this.purlSettingsForm = this._fb.group({
          isPurlEnabled: false,
          numberOfRetries: ['3', [Validators.required, Validators.max(10), Validators.min(1)]],
          expirationDays: ['30', [Validators.required, Validators.max(90), Validators.min(1)]],
          welcomeBodyText: ['To protect your information, we need to ask you a few questions before you can access your application.', [Validators.required]],
          welcomeHeaderText: ['Please verify your identity', [Validators.required]],
          verificationQuestions: this._fb.array([], Validators.required)
      });

      this.getQuestions();
      this.loadAccountSetting();
  }

  get verificationQuestions() {
      return this.purlSettingsForm.controls["verificationQuestions"] as UntypedFormArray;
  }

  getQuestions() {
      this.questionService.getQuestions(this.accountInfo).subscribe(result => {
          this.questions = result.filter(x => x.mapKey && x.isRequired === true);

          this.questions = _.sortBy(this.questions, 'mapKey.entityHierarchy');
      });
  }

  loadAccountSetting() {
      this.appService.getData(`AccountSettings/${this.accountInfo.id}`).subscribe(
          response => {
              if (response.status === 'success') {
                  const setting = _.find(response.data, { 'name': 'purlSettings' });
                  if (setting) {
                      this.accountSetting = setting;
                      this.purlSettings = JSON.parse(setting.value);
                      this.purlEnabled = this.purlSettings.isPurlEnabled;

                      this.purlSettingsForm.patchValue({
                          isPurlEnabled: this.purlSettings.isPurlEnabled,
                          numberOfRetries: this.purlSettings.numberOfRetries,
                          expirationDays: this.purlSettings.expirationDays,
                          welcomeHeaderText: this.purlSettings.welcomeHeaderText,
                          welcomeBodyText: this.purlSettings.welcomeBodyText
                      });

                      if (!this.purlSettings.expirationMessageText) {
                          this.purlSettings.expirationMessageText = 'If you think you’ve gotten this message in error, please reach out to your agent for help.';
                      }

                      if (!this.purlSettings.verificationText) {
                          this.purlSettings.verificationText = 'Please try again later';
                      }

                      const verificationQuestionArray = new UntypedFormArray([]);
                      for (const question of this.purlSettings.verificationQuestions) {
                          verificationQuestionArray.push(this.createQuestion(question));
                      }

                      this.purlSettingsForm.setControl('verificationQuestions', verificationQuestionArray);
                  } else {
                      this.purlSettings = {
                          isPurlEnabled: false,
                          verificationText: 'Please try again later',
                          expirationMessageText: 'If you think you’ve gotten this message in error, please reach out to your agent for help.',
                          expirationDays: 30,
                          numberOfRetries: 3,
                          welcomeBodyText: 'To protect your information, we need to ask you a few questions before you can access your application.',
                          welcomeHeaderText: 'Please verify your identity',
                          verificationQuestions: []
                      };
                  }
              }

              this.showForm = true;
          });
  }

  createQuestion(question: PurlQuestion) {
      return this._fb.group({
          id: [question?.id, [Validators.required,RxwebValidators.unique()]],
          verificationText: [question?.verificationText, Validators.required]
      });
  }

  addQuestion() {
      const control = <UntypedFormArray>this.purlSettingsForm.get('verificationQuestions');
      control.push(this.createQuestion(null));
  }

  deleteQuestion(index) {
      const controls = <UntypedFormArray>this.purlSettingsForm.get('verificationQuestions');
      controls.removeAt(index);
  }

  selectQuestion(index) {
      const controls = <UntypedFormArray>this.purlSettingsForm.get('verificationQuestions');
      const control = controls.value[index];
      const question = this.questions.find(x => x.id === control.id);

      (this.verificationQuestions.at(index) as UntypedFormGroup).get('verificationText').patchValue(question.questionText);
  }

  saveSettings() {
      if (this.purlSettingsForm.valid) {
          const purlSettings = this.purlSettingsForm.value as AccountPurlSettings;

          if (purlSettings.isPurlEnabled) {
              const expirationMessageText = this.expirationMessageEditor.textEditorFormGroup.get('htmlEditorControl').value;
              const verificationMessageText = this.verificationAttemptsMessageEditor.textEditorFormGroup.get('htmlEditorControl').value;
              purlSettings.verificationText = verificationMessageText;
              purlSettings.expirationMessageText = expirationMessageText;
          }

          if (purlSettings.isPurlEnabled && purlSettings.verificationQuestions.length <= 0) {
              this.appService.showMsg('error', 'Plese select at least one verification question...');
              return;
          }

          if (!purlSettings.isPurlEnabled) {
              purlSettings.verificationQuestions = [];
          }

          this.accountSetting.name = 'purlSettings';
          this.accountSetting.value = JSON.stringify(this.purlSettingsForm.value);
          this.accountSettingService.saveAccountSetting(this.accountInfo, this.accountSetting).subscribe(result => {
              this.loadAccountSetting();
              this.appService.showMsg('success', 'Saved successfully ...');
          });
      } else {
          this.appService.showMsg('error', 'Plese fill in all the required fields...');
      }
  }

  onEnabledToggle(event) {
      this.purlEnabled = event.checked;
  }
}
