<!-- UW DECISION LABELS -->
<div class="right-panel w-40 d-flex flex-column">
    <div class="left-panel-header">
        <span class="left-panel-title">UW Decision Labels</span>
        <button *ngIf="checkACL('W')" type="button" class="btn btn-link btn-sm custom float-right" (click)="addLabel()">
            <i aria-hidden="true" class="fa fa-plus-circle"></i> Add UW Decision Label
        </button>
    </div>
    <div class="right-panel-body flex-fill overflow-y-auto custom-scrollbar p-3">

        <uw-label *ngFor="let label of labels; let i = index;" [label]="label" [index]="i" [labelsLength]="labels.length" [accountInfo]="accountInfo" [rules]="rules"
            (onSave)="getUwLabels()" (onDelete)="deleteLabel(label, i)" (moveUp)="changeLabelOrder(i, i-1)" (moveDown)="changeLabelOrder(i, i+1)"></uw-label>

        <div *ngIf="!labels.length" class="p-4 text-center text-muted">
            <img src="./assets/images/no-data-placeholder.png" alt="" class="pb-3" />
            <h6>No Labels Found</h6>
        </div>

    </div>
</div>
<!-- UW DECISION LABELS -->

<!-- UW DECISION TAGS -->
<div class="left-panel d-flex w-60 flex-column">
    <div class="left-panel-header">
        <span class="left-panel-title">UW Decision Tags</span>
        <button *ngIf="checkACL('W')" type="button" class="btn btn-link btn-sm custom float-right" (click)="addTag()">
            <i aria-hidden="true" class="fa fa-plus-circle"></i> Add UW Decision Tag
        </button>
    </div>
    <div class="left-panel-body flex-fill overflow-y-auto custom-scrollbar p-3">
        <uw-tag *ngFor="let tag of tags; let i = index;" [tag]="tag" [index]="i" [tagsLength]="tags.length" [rules]="rules" (onSave)="getUwTags()"
            (onDelete)="deleteTag(tag, i)" (moveUp)="changeTagOrder(i, i-1)" (moveDown)="changeTagOrder(i, i+1)"></uw-tag>
        <div *ngIf="!tags.length" class="p-4 text-center text-muted">
            <img src="./assets/images/no-data-placeholder.png" alt="" class="pb-3" />
            <h6>No Tags Found</h6>
        </div>

    </div>
</div>
<!-- UW DECISION TAGS -->
