
    <h1 mat-dialog-title>Discard {{exitText}}?</h1>
    <div mat-dialog-content>
    <form [formGroup]="interviewTerminateForm" novalidate>
        <div>
            <mat-form-field class="w-100 textarea-formfield">
                <mat-label>Enter reason...</mat-label>
                <textarea matInput minRows="5" formControlName="terminateMessage"></textarea>
                <mat-error *ngIf="interviewTerminateForm.get('terminateMessage').hasError('required')">
                    Reason required
                </mat-error>
            </mat-form-field>
        </div>
    </form>
</div>
    <div mat-dialog-actions align="end">
        <mrs-btn-tertiary buttonLabel="Cancel" buttonId="cancelButton"
            (onClick)="cancelTerminate()">Cancel</mrs-btn-tertiary>
        <mrs-btn-tertiary textColor="#900606" [buttonLabel]="discardText"
            (onClick)="submitTerminateReason()"></mrs-btn-tertiary>
    </div>