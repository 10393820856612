import { AuditGoalStates } from '@Audit/audit.enums';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';


@Component({
    selector: 'audit-percent-label',
    templateUrl: './audit-percent-label.component.html',
    styleUrls: ['./audit-percent-label.component.scss']
})
export class AuditPercentLabelComponent implements OnChanges {
    @Input() totalCases = 0;
    @Input() allocatedCases = 0;
    @Input() auditGoal?: number = null;
    @Input() toolTipEnabled = false;

    isAllocatedGoalEnabled = false;
    allocatedPercent = 0;
    allocatedGoalFormatted = '';
    allocatedGoalState = AuditGoalStates.Disabled;

    get AuditGoalStates() { return AuditGoalStates; }


    calculateAllocatedPercentage() {
        return (this.totalCases <= 0) ? 0 : ((this.allocatedCases / this.totalCases) * 100);
    }

    ngOnChanges(_changes: SimpleChanges) {
        this.allocatedPercent = this.calculateAllocatedPercentage();
        this.allocatedGoalFormatted = 'Goal disabled in audit settings';

        this.isAllocatedGoalEnabled = (this.auditGoal !== null) && (this.auditGoal > 0);

        if (this.isAllocatedGoalEnabled)
        {
            this.allocatedGoalFormatted = `Goal: ${this.auditGoal}%`;

            if (this.allocatedPercent >= this.auditGoal) {
                this.allocatedGoalState = AuditGoalStates.Above;
            } else {
                this.allocatedGoalState = AuditGoalStates.Below;
            }
        }
    }
}