<h2 mat-dialog-title>{{ title }}</h2>  

<form [formGroup]="followUpformGroup">
  <mat-dialog-content>
      <mat-form-field class="follow-up-field">
        <input matInput class="follow-up-input" formControlName="follow-up" [min]="minDate" [max]="maxDate" [matDatepicker]="picker">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error *ngIf="followUpformGroup.get('follow-up').hasError('matDatepickerMin')">Date must be tomorrow or later.</mat-error>
        <mat-error *ngIf="followUpformGroup.get('follow-up').hasError('matDatepickerMax')">Date must be less than a year.</mat-error>
      </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <mrs-btn-tertiary buttonLabel="Cancel" buttonId="closeButton" (onClick)="cancel()">
    </mrs-btn-tertiary>
    <mrs-btn-tertiary buttonLabel="Save" buttonId="okButton" (onClick)="updateFollowUp()" 
      [disabled]="followUpformGroup.get('follow-up').errors" textColor="#2C6EED">
    </mrs-btn-tertiary>
  </mat-dialog-actions>
</form>