import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppService } from '@App';
import { CaseDocumentTypes, DocType, FeatureToggle } from '@Enums';
import { CaseDocumentsDTO } from '@Models/domain/dto';
import { CaseSummaryService, FeatureManagerService, NotificationService, NotificationSeverity } from '@Services';
import { CaseRequirementResponse, EvidenceResponse } from '@Underwriting/models';
import { UWRequirementsDataService, UWWorkbenchService } from '@Underwriting/services';
import { FileUtils } from '@Utils';
import { SubSink } from 'subsink';

import { TiffProps } from '.';
import { ZoomLevel } from './components/pdf-viewer/pdf-viewer.component';
import { ViewDocumentDialogData } from './models';

@Component({
    selector: 'view-document-dialog',
    templateUrl: './view-document-dialog.component.html',
    styleUrls: ['./view-document-dialog.component.scss']
})
export default class ViewDocumentDialog {
    @ViewChild('audioControl') audioControlRef: ElementRef;

    public readonly Error_UnexpectedUpload = 'Something unexpected went wrong trying to upload the document.';
    public readonly Error_NoDocuments = 'No documents selected.';
    public readonly Error_MultipleDocuments = 'Only one document can be uploaded.  Multiple documents where selected.';
    public readonly Error_DocumentExtension = 'Only pdf and Tiff files are allowed to be uploaded';
    public readonly Error_UploadFailed = 'Upload failed.';
    public readonly Error_UploadCanceled = 'Upload Canceled.';
    public readonly Msg_AttachmentUploaded = 'Attachment added successfully';

    title = '';
    caseRequirementName = '';
    subs = new SubSink();

    fullscreen = false;
    hideClose = false;
    hideActions = false;
    showMarkReviewed = false;
    disableMarkReviewed = false;
    reviewDocumentEnabled = false;

    renderAsTextTypes = [DocType.TEXT, DocType.HTML, DocType.XML, DocType.XSLT, DocType.XSD];
    isJsonFile = false;
    isTextFile = false;
    isTiffFile = false;
    isImageFile = false;
    isAudioFile = false;
    isPdfFile = false;
    isPdfLoaded = false;
    src = '';
    zoomLevel = ZoomLevel.Auto;

    evidence: EvidenceResponse;
    hasBeenReviewed = false;

    tiffProps: TiffProps;

    caseRequirement: CaseRequirementResponse;

    constructor(
        public dialogRef: MatDialogRef<ViewDocumentDialog>,
        @Inject(MAT_DIALOG_DATA) public data: ViewDocumentDialogData,
        private caseSummaryService: CaseSummaryService,
        protected notificationService: NotificationService,
        protected appService: AppService,
        protected uwWorkbenchService: UWWorkbenchService,
        private featureManagerService: FeatureManagerService,
        private uwRequirementsDataService: UWRequirementsDataService,
    ) {
    }

    //#region Helpers

    getFileType(caseDocument: CaseDocumentsDTO) {
        if (FileUtils.validFileType(caseDocument.generateTo)) return caseDocument.generateTo as DocType.Image;
        if (FileUtils.validFileType(caseDocument.doctypeId)) return caseDocument.doctypeId as DocType;

        return FileUtils.getFileTypeFromExtension(caseDocument.remoteName);
    }

    setFileType() {
        const fileType = this.getFileType(this.data.caseDocument);

        this.isJsonFile = fileType == DocType.JSON;
        this.isTiffFile = fileType == DocType.TIFF;
        this.isImageFile = fileType == DocType.Image;
        this.isAudioFile = fileType == DocType.Audio;
        this.isPdfFile = fileType == DocType.PDF;
        this.isTextFile = this.renderAsTextTypes.includes(fileType);
    }

    getDocumentUri() {
        const { client, account, caseId, caseDocument, caseDocumentType } = this.data;

        const loadDocument$ = caseDocumentType == CaseDocumentTypes.Attachment
            ? this.caseSummaryService.downloadAttachedDocument(client.id, account.id, caseId, caseDocument.id, true)
            : this.caseSummaryService.getCaseDocumentUri(client.id, account.id, caseId, caseDocument.id);

        loadDocument$.subscribe({
            next: async (objectUrl: string) => this.src = objectUrl,
            error: _error => {
                this.notificationService.showNotification({ severity: NotificationSeverity.Error, message: 'Error trying to download the documents.' });
            }
        });
    }

    formatCaseRequirementName() {
        if (!this.caseRequirement)
            return '';

        let requirementName = (this.caseRequirement.subtitle?.trim())
            ? `${this.caseRequirement.name}: ${this.caseRequirement.subtitle}`
            : this.caseRequirement.name;

        requirementName = requirementName.trim();
        return requirementName;
    }

    setDialogTitle() {
        this.title = `${this.data.caseDocument?.docTemplateName ?? this.data.caseDocument?.remoteName}`;
    }

    //#endregion
    //#region Subscriptions

    subsribeToFeatureToggles() {
        const { client } = this.data;

        this.subs.add(this.featureManagerService.isEnabled(FeatureToggle.UWWorkbenchFeatureReviewDocumentsClients, client.id).subscribe({
            next: isEnabled => {
                this.reviewDocumentEnabled = isEnabled;
                this.showMarkReviewed = this.caseRequirement && this.reviewDocumentEnabled;
            }
        }));
    }

    subscribeToCaseRequirements() {
        if (!this.data.caseRequirementId) return;

        this.subs.add(this.uwRequirementsDataService.loadCaseRequirements(this.data.client.code, this.data.caseId).subscribe(caseRequirementResponses => {
            this.caseRequirement = caseRequirementResponses.find(x => x.id == this.data.caseRequirementId);
            this.evidence = this.caseRequirement?.evidence.find(x => x.caseDocumentId == this.data.caseDocument.id);
            this.hasBeenReviewed = this.evidence?.reviews && this.evidence.reviews.length > 0;
            this.disableMarkReviewed = this.hasBeenReviewed;
            this.showMarkReviewed = this.caseRequirement && this.reviewDocumentEnabled;
        }));
    }

    //#endregion
    //#region Lifecycle

    ngOnInit() {
        this.setFileType();

        this.uwWorkbenchService.setClientInfo(this.data.client);
        if (this.data.caseRequirementId)
            this.subs.add(this.uwWorkbenchService.viewedEvidence(this.data.account.id, this.data.caseRequirementId, this.data.caseDocument).subscribe());

        this.zoomLevel = this.data.zoom || ZoomLevel.Auto;
        this.setDialogTitle();
        this.caseRequirementName = this.formatCaseRequirementName();
        this.fullscreen = this.data.fullscreen;
        this.hideClose = this.data.hideClose;
        this.hideActions = this.hideClose; // we only have "close", if we hide it, hide the whole bar.

        this.subsribeToFeatureToggles();
        this.subscribeToCaseRequirements();
        this.getDocumentUri();
    }


    ngOnDestory() {
        this.subs.unsubscribe();
    }

    //#endregion
    //#region Handlers

    handleCancel() {
        this.dialogRef.close({
            action: "cancel"
        });
    }

    handleMarkReviewed() {
        this.disableMarkReviewed = true;
        this.subs.add(this.uwWorkbenchService.reviewedEvidence(this.data.account.id, this.data.caseRequirementId, this.data.caseDocument).subscribe({
            error: _err => {
                this.disableMarkReviewed = false;
            }
        }));
    }

    //#endregion
}
