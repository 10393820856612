<div class="mx-auto page-borders" style="width: 100%">
  <mat-card appearance="outlined">
    <mat-card-header>
      <mat-card-title-group>
        <mat-card-title><h2>My Cases Settings</h2></mat-card-title>
        <div class="flex">
          <button type="button" mat-button (click)="addFilter()" class="mr-1 material-default">
            ADD FILTER
          </button>
          <button type="submit" (click)="save()" mat-flat-button color="primary">
            SAVE
          </button>
        </div>
      </mat-card-title-group>
    </mat-card-header>
    <mat-card-content>
      <form [formGroup]="myCaseSettingsForm" (ngSubmit)="save()">
        <table mat-table [dataSource]="dataSource" style="width:100%;">
          <ng-container matColumnDef="showColumn">
            <th mat-header-cell *matHeaderCellDef style="width: 25px">
            </th>
            <td mat-cell *matCellDef="let element">
              Show
            </td>
          </ng-container>
          <ng-container matColumnDef="selectedCaseStatus" class="col-2">
            <th mat-header-cell *matHeaderCellDef style="width: 250px;"> Show Cases in these Statuses </th>
            <td mat-cell *matCellDef="let element;" [formGroup]="element">
              <mat-form-field style="width: 250px; padding-right: 25px; padding-left: 25px;">
                <mat-select formControlName="selectedCaseStatus">
                  <mat-option *ngFor="let caseStatus of caseStatuses" [value]="caseStatus.id"
                    [disabled]="statusUsed(caseStatus.id)">
                    {{caseStatus.name}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="element.get('selectedCaseStatus').invalid && formSubmitted">
                  Case status is <strong>required</strong>
                </mat-error>
              </mat-form-field>
            </td>
          </ng-container>
          <ng-container matColumnDef="casesMsgColumn">
            <th mat-header-cell *matHeaderCellDef>
            </th>
            <td mat-cell *matCellDef="let element">
              cases for
            </td>
          </ng-container>
          <ng-container matColumnDef="selectedDaysBack">
            <th mat-header-cell *matHeaderCellDef style="width: 125px"> For this Long (days) </th>
            <td mat-cell *matCellDef="let element;" [formGroup]="element">
              <mat-form-field style="width: 125px; padding-right: 25px; padding-left: 25px;">
                <input matInput type="number" formControlName="selectedDaysBack" />
                <mat-error *ngIf="element.get('selectedDaysBack').invalid && formSubmitted">
                  Number of days back is <strong>required</strong>
                </mat-error>
              </mat-form-field>
            </td>
          </ng-container>
          <ng-container matColumnDef="messages">
            <th mat-header-cell *matHeaderCellDef ></th>
            <td mat-cell *matCellDef="let element">
              {{getCaseStatusMessage(element)}}
            </td>
          </ng-container>
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>
            </th>
            <td mat-cell class="text-center" *matCellDef="let element">
              <mat-icon svgIcon="delete" aria-label="Delete" style="margin-bottom: 10px"
                  (click)="deleteFilter(element)" class="mat-icon-gray"></mat-icon>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" style="cursor: default;"></tr>
        </table>
      </form>
    </mat-card-content>
  </mat-card>
</div>