<ul #notesList class="notes-list">
  <li *ngIf="notes.length == 0" class="no-note-message">No Notes Added</li>
  <li *ngFor="let note of notes" class="note">
    <ul class="details">
      <li class="detail note-name">
        {{ note.userDisplayName}}
        <button 
          mat-icon-button 
          class="mat-mdc-note-icon-button" 
          [matTooltip]="note.isInternalNote ? 'Hidden on case document' : 'Visible on case document'" 
          (click)="toggleNoteVisibility(note.id)"
          *ngIf="isHiddenNoteFeatureEnabled && isHiddenNoteAccountSettingEnabled">
          <mat-icon>
            <ng-container *ngIf="!note.isInternalNote">visibility</ng-container>
            <ng-container *ngIf="note.isInternalNote">visibility_off</ng-container>
          </mat-icon>
        </button>
      </li>
      <li class="detail note-info">{{ note.userRole }}</li>
      <li class="detail note-info">{{ note.createdOnDisplay }}</li>
    </ul>
    <div class="note-content">
      <div class="requirement-name" *ngIf="showRequirementHandle">
        {{ note.caseRequirementNote?.caseRequirementName }}<ng-container *ngIf="note.caseRequirementNote?.caseRequirementSubtitle">: {{  note.caseRequirementNote?.caseRequirementSubtitle }}</ng-container>
      </div>
      <div class="message">{{ note.message }}</div>
    </div>
  </li>
</ul>
