<div [formGroup]="group">
  <div class="row">
    <div class="col-md-12 action-integration-header">Response</div>
  </div>
  <mat-form-field class="w-100">
    <mat-select formControlName="response">
      <mat-option *ngFor="let listValue of responseMapKeys" [value]="listValue.id">{{listValue.entityHierarchy}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <div class="row">
    <div class="col-md-12 action-integration-header">Inputs</div>
  </div>
  <div formArrayName="inputParams" *ngIf="mapKeys && mapKeys.length > 0">
    <div class="row" *ngFor="let inputParam of inputParameters; let inputIndex = index; let odd = odd;"
      [class.odd]="odd" [formGroupName]="inputIndex">
      <div class="col-md-5" style="display: flex; align-items: center">
        <input type="hidden" formControlName='metadata_Id' value="inputParam.id">
        <span>{{inputParam.value}} <span *ngIf="inputParam.isRequired">*</span></span>
      </div>
      <div class="col-md-6">
        <map-keys-dropdown
                [displayList]="inputParameterMapKeys" placeholder="Mapkey"
          formControlName="mapKey_Id" [formControl]="getFormControl('inputParams', inputIndex)"></map-keys-dropdown>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 action-integration-header">Outputs</div>
  </div>
  <div formArrayName="outputParams" *ngIf="mapKeys && mapKeys.length > 0">
    <div class="row" *ngFor="let output of outputs; let outputIndex = index; let odd = odd;"
      [class.odd]="odd" [formGroupName]="outputIndex">
      <div class="col-md-5">
        <mat-form-field class="w-100">
          <mat-select formControlName="metadata_Id">
            <mat-option *ngFor="let outputParam of outputParameters" [value]="outputParam.id">
              {{outputParam.property}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-6">
          <map-keys-dropdown
                  [displayList]="outputParameterMapKeys" placeholder="Mapkey"
            formControlName="mapKey_Id" [formControl]="getFormControl('outputParams', outputIndex)"></map-keys-dropdown>
      </div>
      <div class="col-md-1" style="display: flex; align-items: center; justify-content: center">
        <button type="button" class="btn btn-link btn-sm float-right custom"
          (click)="removeOutputParameter(output, outputIndex)">
          <i aria-hidden="true" class="far fa-trash-alt"></i>
        </button>
      </div>
    </div>
    <div class="row col-md-12" *ngIf="outputs.length < outputParameters.length">
      <button type="button" class="btn btn-link btn-sm float-right custom" (click)="addOutputParameter()">
        <i aria-hidden="true" class="fa fa-plus"></i> Add Output
      </button>
    </div>
  </div>
</div>
