import { AppService } from './../../app.service';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { RoutingService, ClientDataService } from '../';
import { Account } from '../../models';
import { AccountService } from '../config';
import { lastValueFrom } from 'rxjs';

@Injectable()
export class ActiveAccountResolverService implements Resolve<any> {
    constructor(
      private clientDataService: ClientDataService,
        private accountService: AccountService,
        public appService: AppService,
        public routingService: RoutingService) {
    }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Account> {
        return new Promise(async (resolve, reject) => {
            const clientCode = route.queryParams.client;
            const accountCode = route.queryParams.account;

            const clients = await lastValueFrom(this.clientDataService.getClients(true));

            const targetClient = clients.find(client => client.code == clientCode);

            if (targetClient == null) {
                this.appService.showMsg('error', `Cannot find the requested client: ${clientCode}`);
                this.routingService.navigateToDefault();
                reject();
            }
            const targetAccount = await lastValueFrom(this.accountService.getActiveAccount(clientCode, accountCode));

            if (targetAccount == null) {
                this.appService.showMsg('error', `Cannot find the requested account: ${accountCode}`);
                this.routingService.navigateToDefault();
                reject();
            } else {
                this.routingService.navigateToUrl(`client/${clientCode}/account/${accountCode}/${targetAccount.versionNumber}/interview`);
            }
        });
    }
}
