import { Injectable } from '@angular/core';
import * as _ from 'lodash';

import { AppService } from 'app/app.service';
import { QueueStatusEnum } from 'app/enums';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { InterviewService } from 'app/interview.service';
import { PrimorisDialogComponent } from 'app/components/primoris-embedded/primoris-dialog.component';

@Injectable()
export class PrimorisIntegrationResponseHandler{
    dialogRef: MatDialogRef<PrimorisDialogComponent>;

    constructor(
        public appService: AppService,
        public interviewService: InterviewService,
        public dialog: MatDialog
    ) {}

    handleResponse(clientId: string, accountId: string,integrationResponse, integrationData){
        if (this.dialogRef && this.dialogRef != null) {
            this.dialogRef.close();
            return;
        }

        if (integrationResponse.status === QueueStatusEnum.Completed) {
            const paymentPrefs = _.get(integrationData, 'details.clientProperties.paymentPrefs');
            this.showPrimorisEmbeddedDialog(clientId, accountId, integrationData.caseDetailId, integrationData.caseIntegrationQueueId, paymentPrefs);
        }
    }

    showPrimorisEmbeddedDialog(clientId: string, accountId: string, caseId: string, caseIntegrationQueueId: string, paymentPrefs) {
        this.dialogRef = this.dialog.open(PrimorisDialogComponent, {
            width: '600px',
            height: '600px',
            data: {
                clientId: clientId,
                caseId: caseId,
                caseIntegrationQueueId: caseIntegrationQueueId,
                paymentPrefs,
                accountId: accountId
            }
        });

        this.dialogRef.afterClosed().subscribe(result => {
            this.dialogRef = null;
        });
    }
}
