import { HttpEventType } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { AppService } from '@App';
import { FeatureToggle } from '@Enums';
import { CaseAttachmentService, CaseDocumentsDataService, FeatureManagerService, FileUploadService } from '@Services';
import { last, map } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { InterviewService } from '../../../interview.service';
import {
    BaseFormFieldServices,
    BaseInterviewFormFieldComponent,
} from '../base-interview-form-field/base-interview-form-field.component';

@Component({
    selector: 'upload-file-action-button',
    templateUrl: './upload-file-button.component.html',
    encapsulation: ViewEncapsulation.None
})
export class UploadFileActionButtonComponent extends BaseInterviewFormFieldComponent implements OnInit, OnDestroy {
  @ViewChild('fileInput') fileInput;
  constructor(public services: BaseFormFieldServices,
    private appService: AppService,
    private caseAttachmentService: CaseAttachmentService,
    public featureManagerService: FeatureManagerService,
    private fileUploadService: FileUploadService,
    private interviewService: InterviewService,
    private caseDocumentsDataService: CaseDocumentsDataService,
  ) {

      super(services);
  }

  file: any;
  fileData: any = {};
  fileUploadProgress: number = 0;
  fileUploadComplete: boolean = true;
  useV2: boolean = false;
  private subs = new SubSink();

  ngOnInit() {
      super.initialize({
          inputType: 'uploadFileActionButton',
          skipValidators: true
      });

      this.useV2 = this.featureManagerService.getByName(FeatureToggle.CaseDocumentsAttachmentsUploadInChunks).enabled;

  }

  selectFile(event) {
      const files = event.target.files;
      if (!files || files.length <= 0) {
          return;
      } else {
          const formData: FormData = new FormData();

          for (let i = 0; i < files.length; i++) {
              formData.append(i.toString(), files[i], files[i].name);
          }
          this.fileData = formData;
          this.addAttachment();
      }
      this.fileInput.nativeElement.value = '';
  }

  addAttachment(): void {
      this.group.get(this.config.id).setErrors(null);
      this.fileUploadComplete = false;
      const { clientId, accountId, caseDetails } = this.interviewService.getInterviewData();

      this.caseAttachmentService.addCaseAttachment(clientId, accountId, caseDetails.id, this.config.id, this.fileData)
          .pipe(
              map(data => this.getEventMessage(data)),
              last()
          )
          .subscribe({
              next: () => {},
              error: response => {
                  if (response.error && response.error.userErrors.length > 0) {
                      const error: ValidationErrors = { 'validationError': response.error.userErrors[0] };
                      this.group.get(this.config.id).setErrors(error);
                  }
                  this.fileUploadProgress = 0;
              }
          });
  }

  getEventMessage(event) {
      switch (event.type) {
      case HttpEventType.Sent:
          this.fileUploadProgress = 0;
          break;

      case HttpEventType.UploadProgress:
          const percentDone = Math.round(100 * event.loaded / event.total);
          this.fileUploadProgress = percentDone;
          break;

      case HttpEventType.Response:
          this.fileUploadProgress = 100;
          if (event.body) {
              this.fileUploadComplete = true;
              this.appService.showMsg('success', 'Attachment added successfully');
              this.group.get(this.config.id).setErrors(null);
              this.config.answerTimeStamp = new Date();
              event.body.forEach(caseMapKey => {
                  if (caseMapKey.name === this.config.mapKeyName) {
                      this.config.answerValue = caseMapKey.value;
                  }
              });

              this.interviewService.mergeMapkeys(event.body);
          }
      }
  }


  selectFileV2(event) {
      const files = event.target.files;
      if (!files || files.length <= 0) {
          return;
      } 
      else {
          const { clientId, accountId, caseDetails } = this.interviewService.getInterviewData();

          this.file = files[0];
          const formData: FormData = new FormData();

          for (let i = 0; i < files.length; i++) {
              formData.append(i.toString(), files[i], files[i].name);
          }
      
          this.fileData = formData;
          this.fileUploadComplete = false;
          this.fileUploadProgress = 1;

          this.fileUploadService.sendInterviewAttachmentInChunks(clientId, accountId, caseDetails.id, this.file, this.config.id)
              .then(commitResult => {
                  this.subs.add(commitResult.subscribe({
                      next: result => {
                          this.fileUploadComplete = true;
                          this.fileUploadProgress = 100;
                          this.fileUploadService.uploadProgress = 100;

                          this.appService.showMsg('success', 'Attachment added successfully');
                          this.group.get(this.config.id).setErrors(null);
                          this.config.answerTimeStamp = new Date();
                          result.forEach(caseMapKey => {
                              if (caseMapKey.name === this.config.mapKeyName) {
                                  this.config.answerValue = caseMapKey.value;
                              }
                          });

                          this.interviewService.mergeMapkeys(result);
                          // Because attaching a document will affect the Case Summary page and if the user already visited the
                          // Case Summary, we need to "bust" the cache so it loads fresh docs.
                          this.caseDocumentsDataService.forceOnNextLoad();
                      },
                      error: (err) => {
                          if (err.error && err.error.userErrors.length > 0) {
                              const error: ValidationErrors = { 'validationError': err.error.userErrors[0] };
                              this.group.get(this.config.id).setErrors(error);
                          }

                          this.fileUploadProgress = 0;
                      }
                  }));
              })
              .catch(err => {
                  const error: ValidationErrors = { 'validationError': err.error.userErrors[0] };
                  this.group.get(this.config.id).setErrors(error);
                  this.appService.showResponseErrorMsg(err);
              });
      }
      this.fileInput.nativeElement.value = '';
  }

  ngOnDestroy(){
      this.subs.unsubscribe();
  }
}
