<mat-card>
    <mat-card-content *ngIf="dataLoaded && mapkeyId">
        <div *ngIf="!usedInSystem" class="body-platform">This Mapkey is not being used.</div>
        <div *ngIf="questions.length > 0 || actions.length > 0" class="used-in-section">
            <h3>Application</h3>
            <div *ngFor="let question of questions" class="body-platform">{{question}}</div>
            <div *ngFor="let action of actions" class="body-platform">{{action}}</div>
            <div *ngFor="let prefill of prefills" class="body-platform">{{prefill}} > Pre-Fill Answer > From Question</div>
        </div>
        <div *ngIf="rules.length > 0" class="used-in-section">
            <h3>Rules</h3>
            <div *ngFor="let rule of rules" class="body-platform">{{rule}}</div>
        </div>
        <div *ngIf="integrations.length > 0" class="used-in-section">
            <h3>Integrations</h3>
            <div *ngFor="let integration of integrations" class="body-platform">{{integration}}</div>
        </div>
        <div *ngIf="mibRiskMap.length > 0" class="used-in-section">
            <h3>MIB Risk Map</h3>
            <div *ngFor="let item of mibRiskMap" class="body-platform">{{item}}</div>
        </div>
        <div *ngIf="virtualMapkeys.length > 0" class="used-in-section">
            <h3>Mapkeys</h3>
            <div *ngFor="let virtualMapkey of virtualMapkeys" class="body-platform">{{virtualMapkey}}</div>
        </div>
        <div *ngIf="settings.length > 0" class="used-in-section">
            <h3>Settings</h3>
            <div *ngFor="let setting of settings" class="body-platform">{{setting}}</div>
        </div>
    </mat-card-content>
    <mat-card-content *ngIf="dataLoaded && ruleId" id="usedInRules">
        <div *ngIf="!usedInSystem" class="body-platform">This Rule is not being used.</div>
        <div *ngIf="usedInSystem">
            <div *ngIf="usedInAccountConfiguration" class="used-in-section">
                <h3>Account Configuration</h3>
                <div *ngIf="accountConfiguration.questions?.length > 0 ||
                        accountConfiguration.actions?.length > 0 ||
                        accountConfiguration.sections?.length > 0">
                    <h4>Application</h4>
                    <div *ngIf="accountConfiguration.questions && accountConfiguration.questions.length > 0">
                        <div *ngFor="let question of accountConfiguration.questions.sort()" class="body-platform">{{question}}</div>
                    </div>
                    <div *ngIf="accountConfiguration.actions && accountConfiguration.actions.length > 0">
                        <div *ngFor="let action of accountConfiguration.actions.sort()" class="body-platform">{{action}}</div>
                    </div>
                    <div *ngIf="accountConfiguration.sections && accountConfiguration.sections.length > 0">
                        <div *ngFor="let section of accountConfiguration.sections.sort()" class="body-platform">{{section}}</div>
                    </div>
                </div>
                <div *ngIf="accountConfiguration.rules && accountConfiguration.rules?.length > 0">
                    <h4>Rules</h4>
                    <div *ngFor="let rule of accountConfiguration.rules.sort()" class="body-platform">{{rule}}</div>
                </div>
                <div *ngIf="accountConfiguration.underwritings && accountConfiguration.underwritings.length > 0">
                    <h4>Underwriting</h4>
                    <div *ngFor="let underwriting of accountConfiguration.underwritings.sort()" class="body-platform">{{underwriting}}</div>
                </div>
                <div *ngIf="accountConfiguration.documents && accountConfiguration.documents.length > 0">
                    <h4>Documents</h4>
                    <div *ngFor="let document of accountConfiguration.documents.sort()" class="body-platform">{{document}}</div>
                </div>
                <div *ngIf="accountConfiguration.mapkeys && accountConfiguration.mapkeys.length > 0">
                    <h4>Mapkeys</h4>
                    <div *ngFor="let mapkey of accountConfiguration.mapkeys.sort()" class="body-platform">{{mapkey}}</div>
                </div>
                <div *ngIf="accountConfiguration.events && accountConfiguration.events.length > 0">
                    <h4>Events</h4>
                    <div *ngFor="let events of accountConfiguration.events.sort()" class="body-platform">{{events}}</div>
                </div>
                <div *ngIf="accountConfiguration.settings?.length > 0 || accountConfiguration.signerrules?.length > 0">
                    <h4>Settings</h4>
                    <div *ngIf="accountConfiguration.settings && accountConfiguration.settings.length > 0">
                        <div *ngFor="let setting of accountConfiguration.settings.sort()" class="body-platform">{{setting}}</div>
                    </div>
                    <div *ngIf="accountConfiguration.signerrules && accountConfiguration.signerrules.length > 0">
                        <div *ngFor="let signer of accountConfiguration.signerrules.sort()" class="body-platform" style="margin-left: -29px">
                            <expandable-text [expandedText]="signer.rules" [originalText]="signer.name"></expandable-text>
                        </div>
                    </div>
                </div>
                <div *ngIf="accountConfiguration.workbench?.length > 0">
                    <h4>Workbench Requirements</h4>
                    <div *ngIf="accountConfiguration.workbench && accountConfiguration.workbench?.length > 0">
                        <div *ngFor="let workbenchRequirement of accountConfiguration.workbench.sort()" class="body-platform">{{workbenchRequirement}}</div>
                    </div>
                </div>
            </div>
            <div *ngIf="caseManager.queues && caseManager.queues.length > 0" class="used-in-section">
                <h3>Case Manager</h3>
                <div *ngIf="caseManager.queues.length > 0">
                    <h4>Queues</h4>
                    <div *ngFor="let caseManagerRule of caseManager.queues.sort()" class="body-platform">{{caseManagerRule}}</div>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>
