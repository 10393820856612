import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NotificationService, NotificationSeverity } from '@Services';
import { CreateMyNoteRequest, UWBaseProps } from '@Underwriting/models';
import { UWWorkbenchService } from '@Underwriting/services';
import UWNotesFeatureToggleDataService from 'app/case-summary/underwriting/services/uw-notes-feature-toggle/uw-notes-feature-toggle.data.service';
import { NoteTypes } from 'app/enums';
import { SubSink } from 'subsink';

export interface UWNotesAddFormProps {
    dropShadow?: boolean;
    caseRequirementName?: string;
    caseRequirementSubtitle?: string;
    caseRequirementId?: string;
    integrationId?: string;
    integrationActionId?: string;
    noteType?: NoteTypes
}

@Component({
    selector: 'uw-notes-add-form',
    templateUrl: './uw-notes-add-form.component.html',
    styleUrls: ['./uw-notes-add-form.component.scss']
})
export default class UWNotesAddForm implements OnInit, OnDestroy {
    @Input() baseProps: UWBaseProps;
    @Input() props: UWNotesAddFormProps;

    noteForm: UntypedFormGroup;
    noteError = false;
    isHiddenNoteFeatureEnabled = false;
    isHiddenNoteAccountSettingEnabled = false;

    subs = new SubSink();

    constructor(
        public _fb: UntypedFormBuilder,
        private underWriterWorkbenchService: UWWorkbenchService,
        private notificationService: NotificationService,
        private uwNotesFeatureToggle: UWNotesFeatureToggleDataService       
    ) {
        this.noteForm = this._fb.group({
            isInternalNote: [false, Validators.required],
            note: ['', Validators.required]
        });
    }

    //#region Helpers
    
    enableNoteError = () => this.noteError = true;
    disableNoteError = () => this.noteError = false;

    createMyNoteRequest(note: string, isInternalNote: boolean): CreateMyNoteRequest {
    
        return {
            caseId: this.baseProps.case.id,
            message: note,
            isInternalNote: isInternalNote,
            caseRequirement: {
                caseRequirementName: this.props.caseRequirementName,
                caseRequirementSubtitle: this.props.caseRequirementSubtitle,
                caseRequirementId: this.props.caseRequirementId,
                integrationId: this.props.integrationId,
                integrationActionId: this.props.integrationActionId,
            },
            noteTypeId: this.props.noteType || NoteTypes.Standard
        };
    }

    //#endregion

    //#region Handlers

    clearNote() {
        this.noteError = false;
        this.noteForm.reset();
        this.noteForm.get('note').setValue('');
        this.noteForm.get('note').setErrors(null);
        this.noteForm.get('isInternalNote').setValue(false);
    }

    addNote() {
        this.noteForm.get('note').updateValueAndValidity();
        const note: string = this.noteForm.get('note').value;
        const isInternalNote = this.noteForm.get('isInternalNote').value;

        if (!note || note.length == 0)
            return this.enableNoteError();
        
        const request = this.createMyNoteRequest(note, isInternalNote);

        this.disableNoteError();
        this.subs.add(this.underWriterWorkbenchService.addMyNote(this.baseProps.account.id, request).subscribe(() => {
            this.notificationService.showNotification({ severity: NotificationSeverity.Success, message: "Added note successfully..." });
            this.clearNote();
        }));

    }

    toggleNoteVisibility() {
        const isInternalNoteValue: boolean = this.noteForm.get('isInternalNote').value;

        if (isInternalNoteValue)
            this.noteForm.patchValue({'isInternalNote': false}, {onlySelf: true, emitEvent: false});
        else
            this.noteForm.patchValue({'isInternalNote': true}, {onlySelf: true, emitEvent: false});
    }
    //#endregion

    //#region Lifecycle

    ngOnInit() {
        this.subs.add(this.uwNotesFeatureToggle.loadHiddenNotesFeatureSetting(this.baseProps.account).subscribe((hiddenNoteFeatureSetting) => {
            this.isHiddenNoteFeatureEnabled = hiddenNoteFeatureSetting.featureToggle;
            this.isHiddenNoteAccountSettingEnabled = hiddenNoteFeatureSetting.accountSetting;
        }));
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    //#endregion
}
