import { MapKeysDTO, RulesDTO } from '@DTOs';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { ConstantComponent } from 'app/account/account-config/account-mapkeys/constant/constant.component';
import { DynamicListComponent } from "app/account/account-config/account-mapkeys/dynamic-list/dynamic-list.component";
import { UsedInComponent } from 'app/account/account-config/account-mapkeys/used-in/used-in.component';
import { AppService } from 'app/app.service';
import { FeatureToggle, MapKeyType, MapkeyTypeName } from 'app/enums';
import { Account, LookupItem, MapkeyConfig } from 'app/models';
import { LookupService, MapkeyNode, MapKeyService, RoutesEnum } from 'app/services';
import { FeatureManagerService } from 'app/services/global/feature-manager/feature-manager.service';
import * as _ from 'lodash';
import { AccountMapkeyVmkHelpDialog } from './help/account-mapkey-vmk-help-dialog.component';
import { EntityType, MapkeyOperation } from './models/mapkey-operation';
import { TestVirtualComponent } from './test/test-virtual.component';


@Component({
    selector: 'account-mapkey',
    host: { 'class': 'd-flex flex-row flex-fill' },
    templateUrl: './account-mapkey-view.component.html',
    styleUrls: ['./account-mapkeys-list.component.scss']
})

export class AccountMapKeyComponent implements OnInit, OnChanges {

    @ViewChild(UsedInComponent) usedIn: UsedInComponent;
    @Input() accountInfo: Account;
    @Input() rules: RulesDTO[];
    @Output() newMapKeyAdded = new EventEmitter<any>();
    @Output() mapKeyClosed = new EventEmitter<any>();
    @Output() undoConstantCancel = new EventEmitter<any>();
    showDetails = true;
    mapkeyForm: UntypedFormGroup;
    accountIntegrations: any[] = [];
    mapKeyTypes: LookupItem[] = [];
    caseMapKeys: MapkeyConfig[] = [];
    mentionItems: Array<string> = [];
    filteredMentionItems: Array<string> = [];
    selectedMapKeys: any = [];
    selectedMapkey: any;
    testResult: any;
    isPredefinedMapKey: any = false;
    isExpressionMapkey: any = false;
    url: any;
    constant: any;
    dynamicList: any;
    showDetailContent = false;
    showTestForm = false;
    formTitle: any;
    isMapkey = false;
    mapKeyTypeEnum: any = MapKeyType;
    parentCombo: any = [];
    parentComboList: any = [];
    selectedNode: MapkeyNode;
    hideForm = true;
    parentItems: any;
    mapkeyParentPath: string;
    disableMapKeyTypeSelection = false;
    dialogRef: MatDialogRef<TestVirtualComponent>;
    helpDialogRef: MatDialogRef<any>;
    @ViewChild('piiHelpDialog', { static: true }) piiHelpDialog: TemplateRef<any>;
    @ViewChild(ConstantComponent) constantComponent: ConstantComponent;
    @ViewChild(DynamicListComponent) dynamicListComponent: DynamicListComponent;
    showUsedIn = true;
    showVirtualExpression = true;
    entityTypes: any = EntityType;
    showMapkeyValues = true;
    usedInFeatureToggleEnabled = false;
    mapkeyHotjarSurveyFeatureToggle: boolean;
    autogeneratedMapkey = false;
    actionName: string;
    integrationName: string;

    constructor(
        public dialog: MatDialog,
        private _fb: UntypedFormBuilder,
        private appService: AppService,
        private lookupService: LookupService,
        public mapKeyService: MapKeyService,
        private featureManagerService: FeatureManagerService
    ) {
        this.checkACL('R', RoutesEnum.dashboard);
    }

    _mapkeyOperation: MapkeyOperation;

    @Input() set mapkeyOperation(operation: MapkeyOperation) {
        this._mapkeyOperation = operation;

        //Clear possible previous data
        if(this.isExpressionMapkey) this.isExpressionMapkey = false;
        if(this.isPredefinedMapKey) this.isPredefinedMapKey = false;

        this.openAllSections();
        this._mapkeyOperation.mapkeyTypeDisplay = this.getMapkeyTypeDisplayName();
        if (operation.isVirtualExpression && operation.selectedNode.mapKeyTypeId === MapKeyType.ExpressionBuilder) {
            this._mapkeyOperation.virtualExpressionParsed = this.replaceVirtualExpresssionGuids(operation.virtualExpression);
        }
        if (operation.selectedNode.integrationId && this.accountIntegrations) {
            this.getIntegrationMapkeyIntegrationName();
        }

        this.determineAutogeneratedMapkey();
    }

    ngOnInit() {
        this.usedInFeatureToggleEnabled = this.featureManagerService.getByName(FeatureToggle.ConfigMapkeyUsedIn).enabled;
        this.getAccountIntegrations();
        this.lookupService.getMapKeyTypes().then(mapKeyTypes => {
            this.mapKeyTypes = mapKeyTypes.filter((i) => i.id !== MapKeyType.DynamicList);

            if (this._mapkeyOperation.allowedTypes) {
                this.mapKeyTypes = [];
                this._mapkeyOperation.allowedTypes.forEach(allowedType => {
                    const mapKeyType = mapKeyTypes.find((i) => i.id === allowedType);
                    this.mapKeyTypes.push(mapKeyType);
                });
            }

            if (this.mapKeyTypes.length === 1) {
                this.disableMapKeyTypeSelection = true;
                this.mapkeyForm.get('mapKeyTypeId').setValue(this.mapKeyTypes[0]['id']);
            }

            this._mapkeyOperation.mapkeyTypeDisplay = this.getMapkeyTypeDisplayName();
        });
        this.mapkeyHotjarSurveyFeatureToggle = this.featureManagerService.getByName(FeatureToggle.ConfigMapkeyHotjarSurvey).enabled;
        this.rules = this.mapKeyService.rules;
    }

    ngOnChanges() {
        this.parentItems = this.mapKeyService.treeData;
        this.selectedNode = this._mapkeyOperation.selectedNode;
        this.hideForm = false;

        this.mapkeyForm = this._fb.group({
            id: '',
            accountId: this.accountInfo.id,
            clientId: this.accountInfo.clientId,
            name: ['', Validators.required],
            parentId: ['', Validators.required],
            integrationId: [''],
            mapKeyEntityId: [''],
            rootCategory: '',
            isConstant: false,
            isVirtualExpression: false,
            delimiter: '',
            sanitizedValue: '',
            availableKeys: '',
            virtualExpression: '',
            virtualMapKeysList: [],
            mapKeyTypeId: MapKeyType.NonVirtual,
            isPii: ''
        });

        this.constant = this._mapkeyOperation.constant;
        this.dynamicList = this._mapkeyOperation.dynamicList;

        if (this._mapkeyOperation.isView) {
            this.showDetailContent = true;
        } else {
            this.showDetailContent = false;
            if (!this._mapkeyOperation.isNew) {
                this.editItem(this._mapkeyOperation);
            } else {
                if (this._mapkeyOperation.entityType === EntityType.DynamicList) {
                    this.addDynamic();
                } else if (this._mapkeyOperation.entityType === EntityType.Constant) {
                    this.addConstant();
                } else if (this._mapkeyOperation.entityType === EntityType.Entity) {
                    this.addEntity(false);
                } else {
                    this.addMapkey();
                }
            }
        }
    }

    setFormStatus(title, url) {
        this.constant = null;
        this.dynamicList = null;
        this.showDetailContent = false;
        this.formTitle = title;
        this.url = url;
    }

    getAccountIntegrations() {
        this.appService.getData(`Accounts/${this.accountInfo.id}/Integrations`, null, !this.constant).subscribe(
            data => {
                if (data.status === 'success') {
                    this.accountIntegrations = data.data;
                    if (this._mapkeyOperation.selectedNode.integrationId) {
                        this.getIntegrationMapkeyIntegrationName();
                    }
                }
            }
        );
    }

    getNonConstantMapKeys(virtualKeys) {
        if (this.accountInfo) {
            this.appService.getData(`MapKeys/${this.accountInfo.id}/NonConstantMapKeys`).subscribe(
                result => {
                    if (result.status === 'success') {
                        this.caseMapKeys = result.data;
                        const newCaseMapkeys = [];
                        const mentionMapKeys = [];

                        this.caseMapKeys.forEach(element => {
                            if (element.mapKeyTypeId !== MapKeyType.Virtual && element.mapKeyTypeId !== MapKeyType.VirtualDocument) {
                                newCaseMapkeys.push({
                                    listMapKeysId: element.id,
                                    name: element.name,
                                    listMapKeyName: element.entityHierarchy,
                                    integrationId: element.integrationId,
                                    mapKeyTypeId: element.mapKeyTypeId
                                });
                            }

                            mentionMapKeys.push(element.entityHierarchy);
                        });

                        this.caseMapKeys = newCaseMapkeys;
                        if (virtualKeys) {
                            this.getVirtualData();
                        }

                        this.mentionItems = mentionMapKeys;
                        this.filteredMentionItems = this.mentionItems;
                    }
                }
            );
        }
    }

    onSearch(event): void {
        this.filteredMentionItems = this.mentionItems.filter(member => member.toLowerCase().includes(event.toLowerCase()));
    }

    onMention(): void {
        this.filteredMentionItems = this.mentionItems;
    }

    getVirtualData() {
        const mapkeysOptions = [];
        if (this.caseMapKeys.length > 0) {
            this.caseMapKeys.forEach(element => {
                let found = false;
                for (let i = 0; i < this.selectedMapKeys.length; i++) {
                    if (this.selectedMapKeys[i].listMapKeysId === element.listMapKeysId) {
                        found = true;
                        break;
                    }
                }
                if (!found) {
                    mapkeysOptions.push(element);

                }
            });
            this.caseMapKeys = mapkeysOptions;
        }
    }

    testVirtualMapKey(isFromModal) {
        if (isFromModal) {
            this.showTestForm = true;
            this.hideForm = true;
        } else {
            this.dialogRef = this.dialog.open(TestVirtualComponent, {
                width: '50%',
                data: {
                    isModal: true,
                    virtualExpression: this.mapkeyForm.value.virtualExpression
                }
            });
        }
    }

    closeVirtualTest() {
        this.showTestForm = false;
        this.hideForm = false;
    }

    mapkeyChange(option: MatSelectChange) {
        const changedType = [];
        this.isExpressionMapkey = false;
        if (option.value === MapKeyType.ExpressionBuilder) {
            this.isExpressionMapkey = true;
        } else if (option.value !== MapKeyType.Virtual) {
            this.selectedMapKeys.forEach((element, index) => {
                if (index === 0) {
                    changedType.push(element);
                } else {
                    this.caseMapKeys.push(element);
                }
            });
            this.selectedMapKeys = changedType;
        }

        if (option.value === MapKeyType.Virtual || option.value === MapKeyType.VirtualDocument) {
            this.mapkeyForm.controls['isVirtualExpression'].setValue(true);
        }

        if (option.value !== MapKeyType.ExpressionBuilder) {
            this.mapkeyForm.removeControl('expressionId');
            this.mapkeyForm.removeControl('responseMapKeyId');
            this.mapkeyForm.removeControl('ruleId');
            this.mapkeyForm.removeControl('propertyId');
            this.mapkeyForm.removeControl('ruleResultsDelimiter');
        }

        this.mapkeyForm.patchValue({
            availableKeys: ''
        });
    }

    cancel() {
        this.showDetailContent = true;
        this.isPredefinedMapKey = false;
        if (this._mapkeyOperation.isNew) {
            this.hideForm = true;
        }

        this.mapKeyClosed.emit();
    }

    saveForm() {
        let isEditValue = false;
        const mapkey: MapkeyConfig = this.mapkeyForm.value;

        if (mapkey.id !== '') {
            isEditValue = true;
        }
        this.mapkeyForm.markAllAsTouched();
        if (this.mapkeyForm.valid) {
            this.applyTypeSpecificTransforms(mapkey);

            this.mapKeyService.saveMapKey(this.url, mapkey, isEditValue).subscribe((data) => {
                if (data) {
                    this.hideForm = true;
                    this.isPredefinedMapKey = false;

                    if (this._mapkeyOperation.isModal) {
                        this.mapKeyClosed.emit(data);
                    } else {
                        this.showDetailContent = true;
                    }
                    this.newMapKeyAdded.emit({ reloadData: this.url === 'mapKeyEntities', mapkeyId: data.id });
                }
            });
        }
    }

    editItem(mapkeyOperation: MapkeyOperation) {
        if (this.constantComponent) {
            this.constantComponent.editItem();
        }
        const writeAccess = this.checkACL('W');

        if (mapkeyOperation.entityType === EntityType.Entity) {
            this.editEntity(mapkeyOperation.selectedNode.isSystemGenerated);
        } else if (mapkeyOperation.entityType === EntityType.Mapkey && writeAccess) {
            if (mapkeyOperation.isSystemGenerated) {
                this.editPredefinedMapkey();
            } else {
                this.editMapkey();
            }
        } else if (mapkeyOperation.entityType === EntityType.Constant && writeAccess) {
            this.editConstant();
        } else if (mapkeyOperation.entityType === EntityType.DynamicList && writeAccess) {
            this.editDynamic();
        }
    }

    addEntity(isConstant) {
        this.isMapkey = false;
        this.mapkeyForm.patchValue({
            id: '',
            name: '',
            mapKeyEntityId: '',
            accountId: this.accountInfo.id,
            clientId: this.accountInfo.clientId,
            isConstant: isConstant,
            parentId: this.selectedNode.id,
            rootCategory: this.selectedNode.rootMapNode
        });
        this.setFormStatus('Add Entity', 'mapKeyEntities');
        this.parentCombo = [];
        this.getParentCombo(this.mapKeyService.treeData);
    }

    editEntity(isConstant) {
        this.isMapkey = false;
        this.mapkeyForm.patchValue({
            id: this.selectedNode.id,
            name: this.selectedNode.label,
            accountId: this.accountInfo.id,
            clientId: this.accountInfo.clientId,
            isConstant: isConstant,
            parentId: this.selectedNode.parent.id,
            rootCategory: this.selectedNode.rootMapNode
        });
        this.setFormStatus('Edit Entity', 'mapKeyEntities');
    }

    addMapkey() {
        this.isMapkey = true;
        this.getNonConstantMapKeys(false);
        this.selectedMapKeys = [];
        this.isExpressionMapkey = false;
        this.setFormStatus('Add Mapkey', 'Mapkeys');
        this.parentCombo = [];
        this.getParentCombo(this.mapKeyService.treeData);

        if (this.selectedNode && this.selectedNode.parentNames) {
            this.mapkeyParentPath = this.selectedNode.parentNames.join('/');
        }

        this.mapkeyForm.reset();
        this.mapkeyForm.patchValue({
            id: '',
            name: '',
            mapKeyEntityId: this.selectedNode.id,
            accountId: this.accountInfo.id,
            clientId: this.accountInfo.clientId,
            isConstant: false,
            delimiter: '',
            sanitizedValue: '',
            isVirtualExpression: false,
            virtualExpression: '',
            parentId: this.selectedNode.id,
            integrationId: this.selectedNode.integrationId,
            rootCategory: this.selectedNode.rootMapNode,
            mapKeyTypeId: MapKeyType.NonVirtual,
            isPii: this.selectedNode.isPii
        });
    }

    editMapkey() {
        this.getNonConstantMapKeys(true);
        this.isMapkey = true;
        this.mapkeyForm.patchValue({
            id: this.selectedNode.id,
            name: this.selectedNode.label,
            isConstant: false,
            accountId: this.accountInfo.id,
            clientId: this.accountInfo.clientId,
            mapKeyEntityId: this.selectedNode.parent.id,
            parentId: this.selectedNode.id,
            integrationId: this.selectedNode.integrationId,
            isVirtualExpression: this.selectedNode.isVirtualExpression,
            virtualExpression: this.selectedNode.virtualExpression,
            delimiter: this.selectedNode.delimiter,
            sanitizedValue: this.selectedNode.sanitizedValue,
            rootCategory: this.selectedNode.rootMapNode,
            mapKeyTypeId: this.selectedNode.mapKeyTypeId,
            expressionId: this.selectedNode.expressionId,
            isPii: this.selectedNode.isPii
        });

        this.setFormStatus('Edit Mapkey', 'MapKeys');
        this.isExpressionMapkey = false;
        if (this.selectedNode.mapKeyTypeId === MapKeyType.ExpressionBuilder) {
            this.isExpressionMapkey = true;
        } else if (this.selectedNode.mapKeyTypeId === MapKeyType.Virtual) {
            this.selectedMapKeys = this.selectedNode.virtualMapKeysList;
            this.mapkeyForm.patchValue({
                parentId: this.selectedNode.parent.id
            });
        } else {
            this.selectedMapKeys = [];
        }
    }

    editPredefinedMapkey() {
        this.isMapkey = true;
        this.isPredefinedMapKey = true;
        this.mapkeyForm.patchValue({
            id: this.selectedNode.id,
            name: this.selectedNode.label,
            isConstant: false,
            accountId: this.accountInfo.id,
            clientId: this.accountInfo.clientId,
            mapKeyEntityId: this.selectedNode.parent.id,
            parentId: this.selectedNode.id,
            integrationId: this.selectedNode.integrationId,
            delimiter: this.selectedNode.delimiter,
            sanitizedValue: this.selectedNode.sanitizedValue,
            rootCategory: this.selectedNode.rootMapNode,
            mapKeyTypeId: this.selectedNode.mapKeyTypeId,
            isPii: this.selectedNode.isPii
        });
        this.setFormStatus('Edit Predefined Mapkey', 'MapKeys');
    }

    checkACL(permissionType, redirect?) {
        return this.appService.checkACL('Accounts', permissionType, redirect);
    }

    addDynamic() {
        this.showDetailContent = false;
        this.dynamicList = {
            id: '',
            title: 'Add Constant',
            name: '',
            mapKeyEntityId: this.selectedNode.id,
            accountId: this.accountInfo.id,
            clientId: this.accountInfo.clientId,
            virtualExpression: '',
            isEdit: false
        };
    }

    addConstant() {
        this.showDetailContent = false;
        this.constant = {
            id: '',
            title: 'Add Constant',
            name: '',
            mapKeyEntityId: this.selectedNode.id,
            accountId: this.accountInfo.id,
            clientId: this.accountInfo.clientId,
            isConstant: true,
            isList: '',
            constantMapKeyValues: [],
            isEdit: false
        };
    }

    editDynamic() {
        this.showDetailContent = false;
        this.dynamicList = {
            id: this.selectedNode.id,
            title: 'Dynamic List Details',
            name: this.selectedNode.label,
            mapKeyEntityId: this.selectedNode.mapKeyEntityId,
            accountId: this.accountInfo.id,
            clientId: this.accountInfo.clientId,
            isConstant: this.selectedNode.isConstant,
            isList: this.selectedNode.isList,
            virtualExpression: this.selectedNode.virtualExpression,
            isEdit: true
        };
    }

    editConstant() {
        this.showDetailContent = false;
        if (this.selectedNode.constantMapKeyValues) {
            this.selectedNode.constantMapKeyValues = _.sortBy(this.selectedNode.constantMapKeyValues, [function (o) {
                return o.order;
            }]);
        }
        this.constant = {
            id: this.selectedNode.id,
            title: 'Constant Details',
            name: this.selectedNode.label,
            mapKeyEntityId: this.selectedNode.mapKeyEntityId,
            accountId: this.accountInfo.id,
            clientId: this.accountInfo.clientId,
            isConstant: this.selectedNode.isConstant,
            isList: this.selectedNode.isList,
            constantMapKeyValues: this.selectedNode.constantMapKeyValues,
            constantMapkeyVariants: this.selectedNode.constantMapkeyVariants,
            isEdit: true
        };
    }

    getParentCombo(treeData) {
        for (const eachNode of treeData) {
            if (!this.selectedNode.rootMapNodeId || eachNode.rootMapNodeId === this.selectedNode.rootMapNodeId) {
                if (eachNode.level === 'root' || eachNode.level === 'entity') {
                    this.parentCombo.push({ id: eachNode.id, label: eachNode.label });
                    if (eachNode.label === 'Case' || !eachNode.isSystemGenerated) {
                        this.parentComboList.push({ id: eachNode.id, label: eachNode.label });
                    }
                }
                if (eachNode.children && eachNode.children.length > 0) {
                    this.getParentCombo(eachNode.children);
                }
            }
        }
    }

    parentChange(selectedParent) {
        this.mapkeyForm.controls['mapKeyEntityId'].setValue(selectedParent.id);
        this.mapkeyForm.controls['parentId'].setValue(selectedParent.id);
        this.selectedNode = selectedParent;
    }

    addMapKeys(record) {
        if (record) {
            this.selectedMapkey = record.value;
        }
    }

    addToMapkeys() {
        if (this.selectedMapkey !== '' && this.selectedMapkey) {
            this.selectedMapKeys.push(this.selectedMapkey);
            this.caseMapKeys.splice(_.findIndex(this.caseMapKeys, ['listMapKeysId', this.selectedMapkey.listMapKeysId]), 1);
            this.selectedMapkey = '';
        }
    }

    removeAddedMapkeys(virtualKey) {
        this.caseMapKeys.push(virtualKey);
        this.selectedMapKeys.splice(_.findIndex(this.selectedMapKeys, ['listMapKeysId', virtualKey.listMapKeysId]), 1);
        this.mapkeyForm.patchValue({
            availableKeys: ''
        });
    }

    showVirtualMapKeyHelp() {
        this.helpDialogRef = this.dialog.open(AccountMapkeyVmkHelpDialog,
            {
                width: '70vw'
            });
    }

    showPiiHelp() {
        this.helpDialogRef = this.dialog.open(this.piiHelpDialog,
            {
                width: '38vw'
            });
    }

    closeHelp() {
        this.helpDialogRef.close();
    }

    replaceVirtualExpresssionGuids(virtualExpression: string) {
        let result = virtualExpression.slice();


        const props = virtualExpression.match(/('.*?'+)/g);

        if (props.length > 1) {
            const responseMapKey = props[0];
            const responseMapKeyId = responseMapKey.substring(1, responseMapKey.length - 1);
            const responseMapkeyEntityHierarcy = this.mapKeyService.mapKeys.find(x => x.id === responseMapKeyId)?.entityHierarchy;
            result = result.replace(responseMapKeyId, responseMapkeyEntityHierarcy);

            const rule = props[1];
            const ruleId = rule.substring(1, rule.length - 1);
            const ruleName = this.mapKeyService.rules.find(x => x.id === ruleId)?.name;
            result = result.replace(ruleId, ruleName);
        } else {

            const rule = props[0];
            const ruleId = rule.substring(1, rule.length - 1);
            const ruleName = this.mapKeyService.rules.find(x => x.id === ruleId)?.name;
            result = result.replace(ruleId, ruleName);

        }
        return result;
    }

    canBeEditted(selectedNode: MapkeyNode) {
        const isAccountRooted = selectedNode.rootMapNode === 'Account';
        const isSystemGeneratedEntity = ((selectedNode.rootMapNode === 'Case' || selectedNode.rootMapNode === 'Integration') && selectedNode.level === 'entity' && selectedNode.isSystemGenerated);
        return this.showDetailContent && selectedNode.level !== 'root' && (isAccountRooted || !isSystemGeneratedEntity);
    }

    constantSaved($event: any) {
        this._mapkeyOperation.name = $event.name;
        this._mapkeyOperation.entityHierarchy = $event.entityHierarchy;
        this._mapkeyOperation.entityType = EntityType.Constant;
        this.mapKeyService.addChildToNode($event);
        this.newMapKeyAdded.emit({ reloadData: false, mapkeyId: $event.id });
    }

    dynamicListSaved($event: MapKeysDTO) {
        this._mapkeyOperation.name = $event.name;
        this._mapkeyOperation.entityHierarchy = $event.entityHierarchy;
        this._mapkeyOperation.entityType = EntityType.DynamicList;
        this.mapKeyService.addChildToNode($event);
        this.newMapKeyAdded.emit({ reloadData: false, mapkeyId: $event.id });
    }

    reloadUsedInData() {
        this.usedIn.fetchData();
    }

    formatParentPath() {
        return this.selectedNode.parentPath.split('/').join(' > ');
    }

    // This gets the Integration name for an Integration Mapkey so that we can display it in the view
    private getIntegrationMapkeyIntegrationName() {
        this._mapkeyOperation.integrationMapkeyIntegrationName = this.accountIntegrations.find(x => x.id === this._mapkeyOperation.selectedNode.integrationId).name;
    }

    private applyTypeSpecificTransforms(mapkey: MapkeyConfig): void {
        // Backward compatible bit setting
        mapkey.isVirtual = (mapkey.mapKeyTypeId === MapKeyType.Virtual || mapkey.mapKeyTypeId === MapKeyType.VirtualDocument);
        mapkey.isJson = (mapkey.mapKeyTypeId === MapKeyType.Integration);

        // Virtual
        if (mapkey.mapKeyTypeId !== MapKeyType.Virtual
            && mapkey.mapKeyTypeId !== MapKeyType.VirtualDocument
            && mapkey.mapKeyTypeId !== MapKeyType.ExpressionBuilder) {
            mapkey.isVirtualExpression = false;
            mapkey.delimiter = '';
        } else {
            const listMapkeys = [];
            this.selectedMapKeys.forEach(element => {
                listMapkeys.push({ listMapKeysId: element.listMapKeysId });
            });

            mapkey.virtualMapKeysList = listMapkeys;
            mapkey.virtualExpression = mapkey.isVirtualExpression ? mapkey.virtualExpression : '';

            if (mapkey.mapKeyTypeId !== MapKeyType.ExpressionBuilder) {
                mapkey.delimiter = mapkey.isVirtualExpression ? '' : mapkey.delimiter;
            }

            if (mapkey.mapKeyTypeId === MapKeyType.ExpressionBuilder) {
                mapkey.delimiter = mapkey.ruleResultsDelimiter;
            }
        }

        // Integration
        if (mapkey.mapKeyTypeId !== MapKeyType.Integration) {
            mapkey.integrationId = null;
        }
    }

    private getMapkeyTypeDisplayName() {
        if (this._mapkeyOperation.selectedNode.isSystemGenerated) {
            return MapkeyTypeName.SystemGenerated;
        }

        switch (this._mapkeyOperation.selectedNode.mapKeyTypeId) {
            case MapKeyType.Virtual:
                return MapkeyTypeName.Virtual;
            case MapKeyType.VirtualDocument:
                return MapkeyTypeName.VirtualDocument;
            case MapKeyType.ExpressionBuilder:
                return MapkeyTypeName.ExpressionBuilder;
            case MapKeyType.Integration:
                return MapkeyTypeName.Integration;
            case MapKeyType.NonVirtual:
                return MapkeyTypeName.NonVirtual;
            case MapKeyType.NonPersistent:
                return MapkeyTypeName.NonPersistent;
            default:
                return 'Unknown';
        }
    }

    private openAllSections() {
        this.showDetails = true;
        this.showUsedIn = true;
        this.showVirtualExpression = true;
        if (this.constantComponent) {
            this.constantComponent.expandAllSections();
        }
    }

    undoConstantCancelAction(mapkey) {
        this.undoConstantCancel.emit(mapkey);
    }

    canDeactivate(routerSnapshot: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot) {
        if (this.constant) {
            return this.constantComponent.canDeactivate(routerSnapshot, currentState, nextState);
        } else {
            return true;
        }
    }

    private determineAutogeneratedMapkey() {
        const entityHierarchyParts = this._mapkeyOperation.selectedNode.parentPath.split('/');
        if(this._mapkeyOperation.selectedNode.isSystemGenerated && entityHierarchyParts[0] === 'Integration' && entityHierarchyParts.length > 1){
            this.integrationName = entityHierarchyParts[1]==='LexisNexisLDP'?'LexisNexis: Life Data Prefill':entityHierarchyParts[1];
            this.actionName = entityHierarchyParts[2] === 'LifeDataPrefillPostAction'? 'LexisNexis: Life Data Prefill':entityHierarchyParts[2];
            this.autogeneratedMapkey = true;
        }else{
            this.autogeneratedMapkey = false;
        }
    }
}
