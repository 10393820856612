import { Component, Input, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AccountSettings, FeatureToggle } from '@Enums';
import { Account, Client } from '@Models';
import { FeatureManagerService } from '@Services';

import {
    WorkbenchDecisionConfigComponent,
    WorkbenchNotesConfigComponent,
    WorkbenchRequirementsConfigComponent,
} from './components';

interface NoteAccountSettingWarning {
    title: string,
    message: string,
}
interface NotesAccountSettingItem {
    accountSettingName: string;
    formControlName: string;
    accountSettingTitle: string;
    accountSettingDescription: string;
    loadError?: boolean;
    loadErrorMessage?: string;
    warning?: NoteAccountSettingWarning;
    initialValue?: boolean;
    currentValue?: boolean;
    isDirty?: boolean;
    accountSettingId?: string;
}

@Component({
    selector: 'workbench-settings',
    templateUrl: './workbench-settings.component.html',
    styleUrls: ['workbench-settings.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export default class WorkbenchSettingsComponent implements OnInit {
    @ViewChild('workbenchNotesConfig', { static: false }) workbenchNotesConfig: WorkbenchNotesConfigComponent;
    @ViewChild('workbenchDecisionConfig', { static: false }) workbenchDecisionConfig: WorkbenchDecisionConfigComponent;
    @ViewChild('workbenchRequirementsConfig', { static: false }) workbenchRequirementsConfig: WorkbenchRequirementsConfigComponent;
    @ViewChild('disableWarningDialog', { static: true }) disableWarningDialog: TemplateRef<any>;
    @Input() client: Client;
    @Input() account: Account;

    lastUpdated = new Date();

    // Notes
    workbenchNotesForm: UntypedFormGroup;
    hasNoteChanged = false;
    notesAccountSettingsLoaded = false;
    allowHiddenNotesFormControlName = 'allowHiddenNotes';
    notesAccountSettings: NotesAccountSettingItem[];

    // Decision
    updateDecisionToggleEnabled = false;

    // Requirements
    hasRequirementsChanged = false;

    constructor(
        private _fb: UntypedFormBuilder,
        private featureManagerService: FeatureManagerService,
    ) {
        this.notesAccountSettings = [];
        this.notesAccountSettings.push({
            accountSettingName: AccountSettings.UWWorkbenchHiddenNotes,
            formControlName: this.allowHiddenNotesFormControlName,
            accountSettingTitle: 'Hidden Notes',
            accountSettingDescription: 'Allow internal underwriting notes to be hidden from the notes document.',
            warning: {
                title: 'Documents out of sync',
                message: 'Underwriting notes documents on all current cases may be out of sync until they are regenerated.'
            }
        });
    }

    //#region Subscriptions

    subscribeToUpdateDecisionToggle() {
        this.featureManagerService.isEnabled(FeatureToggle.CaseUnderwritersUpdateDecisionClient, this.client.id).subscribe(isEnabled => {
            this.updateDecisionToggleEnabled = isEnabled;
        });
    }

    //#endregion
    //#region Lifecycle

    ngOnInit() {
        this.workbenchNotesForm = this._fb.group({
            allowHiddenNotes: false
        });
        this.subscribeToUpdateDecisionToggle();
    }

    //#endregion
}