<h1 mat-dialog-title>
  <div class="flex center-bold-dialog-title">
    Exit Application?
  </div>
</h1>
<div mat-dialog-content>
  <div class="content flex flex-column" style="align-items: center; justify-content: center;">
    <div style="text-align: center;">
      Your progress will be saved.
    </div>
    <div class="mt-4 mb-3">
      <button type="button" mat-flat-button color="primary"
        (click)="interviewOnHold()">
        Save & Exit
      </button>
    </div>
    <div>
      <button mat-button class="btn btn-link standard material-default" color="primary" (click)="close()">
        <mat-icon svgIcon="chevron-left" style="vertical-align: 1px ;"></mat-icon>
        <span style="margin-right: 25px">Go Back</span>
      </button>
    </div>
  </div>
</div>
