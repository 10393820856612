import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from 'app/app.service';
import { IConfigService } from 'app/config/iconfigservice';
import { CaseDocumentTiffToJpgResponse, UWResponse } from '@Models';
import { BaseService } from '@Services';
import { SubSink } from 'subsink';
import { CaseDocumentsDTO } from '@Models/domain/dto';
import { first, ReplaySubject } from 'rxjs';

/// DEV NOTE: https://stackoverflow.com/a/33676723/253564
// The above SO as helpful in working towards what I'm trying to achieve with this service.

export type BaseCaseRequest = {
    clientCode: string;
    accountCode: string;
    caseId: string;
}

export type BaseCaseDocumentRequest = BaseCaseRequest & {
    caseDocumentId: string;
}

export type TiffToJpgRequest = BaseCaseDocumentRequest & {
    startIndex: number,
    numberOfPages: number,
    pageIndexesToLoad: number[]
}

@Injectable({
    providedIn: 'root'
})
export default class CaseDocumentsDataService extends BaseService {
    // Generated Documents State
    private forceReloadDocuments = false;
    private documentsLoaded = false;
    private caseIdForLoadedDocuments = '';
    private documentsSub = new ReplaySubject<CaseDocumentsDTO[]>(1);
    public documents$ = this.documentsSub.asObservable();
    // Attachment Documents State
    private forceReloadAttachments = false;
    private attachmentsLoaded = false;
    private caseIdForLoadedAttachments = '';
    private attachmentsSub = new ReplaySubject<CaseDocumentsDTO[]>(1);
    public attachments$ = this.attachmentsSub.asObservable();

    private subs = new SubSink();

    constructor(
        public configService: IConfigService,
        public httpClient: HttpClient,
        public appService: AppService
    ) {
        super(appService, configService, httpClient);
    }

    //#region Helpers

    private documentsAreLoaded = (caseId: string) => caseId == this.caseIdForLoadedDocuments && this.documentsLoaded;
    private attachmentsAreLoaded = (caseId: string) => caseId == this.caseIdForLoadedAttachments && this.attachmentsLoaded;

    //#endregion
    //#region Lifecycle

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    //#endregion
    //#region Documents (ie. generated)

    public reloadDocuments(caseId: string, hideWaiting?: boolean) {
        this.forceReloadDocuments = false;
        const url = `${this.baseApiUrl}CaseDocuments/${caseId}`;

        // auto unsubscribe after we get the first value   vvvvvvvvvvvvv
        super.getData<UWResponse<CaseDocumentsDTO[]>>(url, null, hideWaiting).pipe(first()).subscribe({
            next: response => {
                this.documentsSub.next(response.data);
                this.documentsLoaded = true;
                this.caseIdForLoadedDocuments = caseId;
            }
        });

        return this.documents$;
    }

    public loadDocuments(caseId: string) {
        if (!this.forceReloadDocuments && this.documentsAreLoaded(caseId))
            return this.documents$;

        return this.reloadDocuments(caseId);
    }

    public getTiffCaseDocumentAsJpg(request: TiffToJpgRequest) {
        const { clientCode, accountCode, caseId, caseDocumentId, numberOfPages, startIndex, pageIndexesToLoad } = request;
        const query = new URLSearchParams();
        query.set('startPageIndex', `${startIndex}`);
        query.set('numberOfPages', `${numberOfPages}`);
        query.set('pageIndexesToLoad', pageIndexesToLoad.join(','));

        const hideWaiting = true;
        const url = `${this.basePublicApiUrl}client/${clientCode}/account/${accountCode}/case/${caseId}/document/${caseDocumentId}/tifftojpg?${query}`;
        return super.getData<CaseDocumentTiffToJpgResponse>(url, null, hideWaiting).pipe(first());
    }

    //#endregion
    //#region Attachments

    public reloadAttachments(caseId: string, hideWaiting?: boolean) {
        this.forceReloadAttachments = false;
        const url = `${this.baseApiUrl}CaseDocumentAttachment/${caseId}`;

        // auto unsubscribe after we get the first value   vvvvvvvvvvvvv
        super.getData<UWResponse<CaseDocumentsDTO[]>>(url, null, hideWaiting).pipe(first()).subscribe({
            next: response => {
                this.attachmentsSub.next(response.data);
                this.attachmentsLoaded = true;
                this.caseIdForLoadedAttachments = caseId;
            }
        });

        return this.attachments$;
    }

    public loadAttachments(caseId: string) {
        if (!this.forceReloadAttachments && this.attachmentsAreLoaded(caseId))
            return this.attachments$;

        return this.reloadAttachments(caseId);
    }

    //#endregion

    public load(caseId: string) {
        this.loadDocuments(caseId);
        this.loadAttachments(caseId);
    }

    // Lazy Cache busting - Set this to force the next "load" attempt to reload the documents.
    // This way, you don't have to call the API _now_ to load documents but wait until the user hits a page
    // that uses this service. All load attemps should make sure they set the force back to false.
    public forceOnNextLoad() {
        this.forceReloadDocuments = true;
        this.forceReloadAttachments = true;
    }

}
