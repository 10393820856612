import { Audit, AuditCase, AuditCaseEnhanced, AuditCasesGroup, AuditDateTypes, AuditGroupByTypes, AuditNoCasesMessage, AuditUtils, AuditViewStatusFilters } from '@Audit';
import { AuditService } from '@Audit/services/audit.service';
import { Account, AccountMinimal, Client } from '@Models';
import { ClientContextService, NotificationService, NotificationSeverity } from '@Services';
import { FormUtils, TimeUtils } from '@Utils';
import { emptyGuid } from '@Utils/utils';
import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, finalize, forkJoin, of, switchMap, take } from 'rxjs';
import { SubSink } from 'subsink';

export type AuditViewForm = {
    statusFilter: FormControl<AuditViewStatusFilters>;
    startDate: FormControl<Date>;
    endDate: FormControl<Date>;
};

export type AuditEnhanced = Audit & {
    startDate: Date;
    endDate: Date;
    lastRefreshedOnDay: string;
    lastRefreshedOnTime: string;
}

@Component({
    selector: 'audit-view-page',
    templateUrl: './audit-view-page.component.html',
    styleUrls: ['./audit-view-page.component.scss'],
})
export default class AuditViewPageComponent implements OnInit {
    auditViewForm: FormGroup<AuditViewForm> = this._fb.group({
        statusFilter: [AuditViewStatusFilters.All],
        startDate: [null],
        endDate: [null],
    });

    statusFilters = [
        AuditViewStatusFilters.All,
        AuditViewStatusFilters.Selected,
        AuditViewStatusFilters.Completed,
    ];

    clients: Client[] = [];
    client: Client = null;
    clientsLoaded = false;

    audit: AuditEnhanced;
    auditCases: AuditCaseEnhanced[] = [];
    auditCases_filtered: AuditCaseEnhanced[] = [];
    auditCasesGroups: AuditCasesGroup[];
    auditLoaded = false;
    auditCasesRefreshing = false;

    canViewAuditRefresh = false;
    canViewAuditEdit = false;

    customDateRange = false;
    isGroupByOn = false;

    startDateDisplay = '';
    endDateDisplay = '';

    msg_noCasesAvailable = 'No cases available';
    msg_caseSelectedFor = 'Case selected for';
    msg_caseSelected = 'Case selected';
    msg_caseCompleted = 'Case completed';
    msg_caseRefreshed = '{total} case(s) added';
    msg_caseRefreshed_none = 'No new cases';

    noCasesMessage = AuditNoCasesMessage.Default;

    subs = new SubSink();

    get validFilterDates() {
        const startDate = this.auditViewForm.controls.startDate;
        const endDate = this.auditViewForm.controls.endDate;
        // Verify if the date control is valid (value required is also an error message, this
        // double checks the value isn't null in case the error message isn't set properly)
        return (startDate.valid && startDate.value) && (endDate.valid && endDate.value);
    }
    get invalidFilterDates() {
        return !this.validFilterDates;
    }

    constructor(
        private _fb: FormBuilder,
        private _auditService: AuditService,
        private _router: Router,
        private _route: ActivatedRoute,
        private _clientContextService: ClientContextService,
        private _notificationService: NotificationService,
        private _location: Location
    ) { }

    //#region Helpers

    getAuditCaseDateForType(auditCase: AuditCaseEnhanced): Date {
        if (this.audit.auditDateType == AuditDateTypes.CompletionDate)
            return TimeUtils.convertToDateCst(auditCase.caseCompletedDate);

        return TimeUtils.convertToDateCst(auditCase.caseCreatedDate);
    }

    getAuditCasesFilteredForDateRange(): AuditCaseEnhanced[] {
        if (this.invalidFilterDates)
            return this.auditCases;

        if (!this.customDateRange)
            return this.auditCases;

        const filterStartDate = TimeUtils.toStartOfDay(this.auditViewForm.controls.startDate.value);
        const filterEndDate = TimeUtils.toEndOfDay(this.auditViewForm.controls.endDate.value);

        const auditCases_filtered = this.auditCases.filter(auditCase => {
            const caseDate = this.getAuditCaseDateForType(auditCase);

            if (caseDate < filterStartDate || filterEndDate < caseDate) return false;
            return true;
        });

        return auditCases_filtered;
    }

    filterUngroupedAuditCases() {
        const statusFilter = this.auditViewForm.controls.statusFilter.value;
        const auditCases_filteredByDate = this.getAuditCasesFilteredForDateRange();

        if (statusFilter === AuditViewStatusFilters.All) {
            this.auditCases_filtered = auditCases_filteredByDate.slice();
            this.noCasesMessage = AuditNoCasesMessage.Default;
        }
        else if (statusFilter === AuditViewStatusFilters.Selected) {
            this.auditCases_filtered = auditCases_filteredByDate.filter(x => x.selectedOn);
            this.noCasesMessage = AuditNoCasesMessage.Selected;
        }
        else if (statusFilter === AuditViewStatusFilters.Completed) {
            this.auditCases_filtered = auditCases_filteredByDate.filter(x => x.completedOn);
            this.noCasesMessage = AuditNoCasesMessage.Completed;
        }

        if (this.audit.groupBy !== AuditGroupByTypes.None)
        {
            this.auditCasesGroups = AuditUtils.filterSortAndGroupAuditCases(auditCases_filteredByDate, this.audit.groupBy, statusFilter);
        }
    }

    updateAuditCaseInPlace(updatedAuditCase: AuditCase) {
        this.auditCases = this.auditCases.map(auditCase => {
            // replace this case with the updated case values
            if (auditCase.caseId === updatedAuditCase.caseId)
                return { ...auditCase, ...updatedAuditCase };

            return auditCase;
        });
    }

    validateDate(control: FormControl<Date>) {
        if (control.value == null) {
            control.setErrors({ 'required': true });
        }
    }

    validateDateRange() {
        FormUtils.removeError(this.auditViewForm.controls.startDate, 'dateRange');
        FormUtils.removeError(this.auditViewForm.controls.endDate, 'dateRange');

        // If start/end date need to be valid date objects (the picker could set them to null)
        if (this.invalidFilterDates) return;

        const filterStart = this.auditViewForm.controls.startDate.value;
        const filterEnd = this.auditViewForm.controls.endDate.value;

        const rangeIsSameOrLess = TimeUtils.isRangeSameOrWithin(filterStart, filterEnd, this.audit.startDateUtc, this.audit.endDateUtc);

        if (rangeIsSameOrLess) return;

        this.auditViewForm.controls.startDate.setErrors({ 'dateRange': true });
        this.auditViewForm.controls.endDate.setErrors({ 'dateRange': true });
    }

    setCustomDateRangeFlag() {
        if (this.validFilterDates && !TimeUtils.isSameDay(this.auditViewForm.controls.startDate.value, this.audit.startDate)) this.customDateRange = true;
        else if (this.validFilterDates && !TimeUtils.isSameDay(this.auditViewForm.controls.endDate.value, this.audit.endDate)) this.customDateRange = true;
        else this.customDateRange = false;
    }

    updateDateControl(_date: Date, control: FormControl<Date>) {
        control.setErrors(null); // clear errors so we can revalidate

        this.validateDate(control);
        this.validateDateRange();
        this.setCustomDateRangeFlag();
        if (this.validFilterDates) this.filterUngroupedAuditCases();
    }

    findAccount(accountId: string): Account {
        for (const parentAccount of this.client.accounts) {
            for (const accountVersion of parentAccount.versions) {
                if (accountVersion.id == accountId)
                    return accountVersion;
            }
        }

        return null;
    }

    enrichAndSetAudit(audit: Audit) {
        this.audit = {
            ...audit,
            startDate: TimeUtils.convertToDateCst(audit.startDateUtc),
            endDate: TimeUtils.convertToDateCst(audit.endDateUtc),
            lastRefreshedOnDay: TimeUtils.formatDay(audit.lastRefreshedOn, 'M/D/YYYY'),
            lastRefreshedOnTime: TimeUtils.formatTime(audit.lastRefreshedOn),
        };
    }

    enrichAndSetAuditCases(auditCases: AuditCase[]) {
        this.auditCases = auditCases.map((auditCase): AuditCaseEnhanced => {
            const account = this.findAccount(auditCase.accountId) as AccountMinimal;
            return { ...auditCase, client: this.client, account };
        });
    }

    //#endregion
    //#region Lifecycle

    async ngOnInit() {
        await this._clientContextService.ready;

        this.canViewAuditRefresh = this._auditService.canViewAuditRefresh();
        this.canViewAuditEdit = this._auditService.canViewAuditEdit();

        forkJoin({
            clients: this._clientContextService.clients$.pipe(take(1)),
            params: this._route.params.pipe(take(1)),
        }).pipe(
            switchMap(({ clients, params }) => {
                const clientCode = params['clientCode'];
                const auditId = params['auditId'];

                this.clients = clients;
                this.client = this.clients.find(x => x.code === clientCode);
                this.clientsLoaded = true;

                return forkJoin({
                    audit: this._auditService.getAudit(this.client.id, auditId),
                    auditCases: this._auditService.getAuditCases(this.client.id, auditId),
                });
            }),
        ).subscribe(({ audit, auditCases }) => {
            this.audit = {
                ...audit,
                startDate: TimeUtils.convertToDateCst(audit.startDateUtc),
                endDate: TimeUtils.convertToDateCst(audit.endDateUtc),
                lastRefreshedOnDay: TimeUtils.formatDay(audit.lastRefreshedOn, 'M/D/YYYY'),
                lastRefreshedOnTime: TimeUtils.formatTime(audit.lastRefreshedOn),
            };
            this.enrichAndSetAudit(audit);
            this.enrichAndSetAuditCases(auditCases);
            this.filterUngroupedAuditCases();

            this.startDateDisplay = TimeUtils.formatDay(this.audit.startDate);
            this.endDateDisplay = TimeUtils.formatDay(this.audit.endDate);
            this.auditViewForm.controls.startDate.setValue(this.audit.startDate);
            this.auditViewForm.controls.endDate.setValue(this.audit.endDate);
            this.isGroupByOn = this.audit.groupBy !== AuditGroupByTypes.None;

            this.auditLoaded = true;
        });

        this.subs.add(this.auditViewForm.controls.startDate.valueChanges.pipe(
            debounceTime(500),
        ).subscribe(startDate => this.updateDateControl(startDate, this.auditViewForm.controls.startDate)));

        this.subs.add(this.auditViewForm.controls.endDate.valueChanges.pipe(
            debounceTime(500),
        ).subscribe(endDate => this.updateDateControl(endDate, this.auditViewForm.controls.endDate)));


        this.subs.add(this.auditViewForm.controls.statusFilter.valueChanges.subscribe(_filter => {
            // filter cases
            this.filterUngroupedAuditCases();
        }));
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    //#endregion
    //#region Handlers

    goBack() {
        this._location.back();
    }

    goToAuditPage() {
        this._router.navigate(['/audit']);
    }

    goToEditPage() {
        const client = this.clients.find(x => x.id === this.audit.clientId);
        this._router.navigate([`/audit/client/${client.code}/${this.audit.id}/edit`]);
    }
    onSelectNextCase() {
        this._auditService.selectNextAuditCase(this.client.id, this.audit.id).subscribe(nextAuditCase => {
            if (!nextAuditCase) {
                this._notificationService.showNotification({ severity: NotificationSeverity.Info, message: this.msg_noCasesAvailable });
                return;
            }

            this.updateAuditCaseInPlace(nextAuditCase); // so we don't reload all cases
            this.filterUngroupedAuditCases();

            this._notificationService.showNotification({ severity: NotificationSeverity.Info, message: this.msg_caseSelected });
        });
    }

    onSelectNextCaseForGroup(auditCasesGroup: AuditCasesGroup) {
        const assigneeId = auditCasesGroup.lastAssignee?.userId ?? emptyGuid;

        this._auditService.selectNextAuditCaseForGroup(this.client.id, this.audit.id, assigneeId, this.audit.groupBy).subscribe(nextAuditCase => {
            if (!nextAuditCase) {
                this._notificationService.showNotification({ severity: NotificationSeverity.Info, message: this.msg_noCasesAvailable });
                return;
            }

            this.updateAuditCaseInPlace(nextAuditCase); // so we don't reload all cases
            this.filterUngroupedAuditCases();

            this._notificationService.showNotification({ severity: NotificationSeverity.Info, message: `${this.msg_caseSelectedFor} ${auditCasesGroup.name}` });
        });
    }

    onAuditCaseComplete(completeAuditForCase: AuditCaseEnhanced) {
        this._auditService.completeAuditCase(this.client.id, this.audit.id, completeAuditForCase.caseId, false).subscribe(completeAuditCase => {

            this.updateAuditCaseInPlace(completeAuditCase); // so we don't reload all cases
            this.filterUngroupedAuditCases();
            this._notificationService.showNotification({ severity: NotificationSeverity.Info, message: this.msg_caseCompleted });
        });
    }

    onRefresh() {
        let totalCasesAdded = 0;

        const hideWaiting = true;
        this.auditCasesRefreshing = true;
        this._auditService.refreshCases(this.client.id, this.audit.id, hideWaiting).pipe(
            switchMap(updatedAudit => {
                totalCasesAdded = Math.abs(updatedAudit.totalCases - this.audit.totalCases);
                this.enrichAndSetAudit(updatedAudit);

                if (totalCasesAdded)
                    return this._auditService.getAuditCases(this.client.id, this.audit.id, hideWaiting);
                else
                    return of(null);
            }),
            finalize(() => {
                this.auditCasesRefreshing = false;
            })
        ).subscribe(refreshedCases => {
            // If refreshedCases are null, that means the case totals haven't changed and no new cases were found. Avoid expensive reloading of cases.
            if (refreshedCases === null) {
                this._notificationService.showNotification({ severity: NotificationSeverity.Info, message: this.msg_caseRefreshed_none });
                return;
            }

            this._notificationService.showNotification({ severity: NotificationSeverity.Info, message: this.msg_caseRefreshed.replace('{total}', `${totalCasesAdded}`) });

            this.enrichAndSetAuditCases(refreshedCases);
            this.filterUngroupedAuditCases();
        });
    }
    //#endregion
}
