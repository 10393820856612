<div id="multiSelectDropdown">
  <mat-form-field appearance="{{appearance}}" class="multi-select-input-width mat-select-dropdown-label" [floatLabel]="selectedItems?.length>0?'always':'auto'">
    <mat-icon *ngIf="showSearchIcon" matPrefix class="case-summary-search-icon" aria-hidden="false" aria-label="Case search icon" matSuffix>
      search
    </mat-icon>
    <input matInput [matAutocomplete]="auto" [ngClass]="{'additional-selection':selectedItems?.length > 1}" [formControl]="inputControl">
    <mat-spinner matSuffix *ngIf="isLoading" class="spinner" diameter="20"></mat-spinner>
    <mat-icon svgIcon="menu-down" matSuffix *ngIf="!isLoading"></mat-icon>
    <mat-label class="multi-select-dropdown-label" [ngClass]="{'flex flex-row align-items-center':isLoading}">
      <span>{{listTitle}}</span>
    </mat-label>
    <mat-autocomplete #auto="matAutocomplete" (closed)="applyInputText()" (opened)="updateAutoComplete()">
      <cdk-virtual-scroll-viewport appendOnly #virtualScrollViewport style="height:240px; overflow-x:hidden; margin-right: 1px;" itemSize="48" minBufferPx="288" maxBufferPx="384">
        <mat-option *ngIf="!isLoading"
                    style="flex-flow:row-reverse; caret-color: transparent;height:48px;"
                    [ngClass]="{'mat-active' : selectAllItem.isSelected}"
                    [value]="selectAllItem">
          <div (click)="onSelectAll($event)">
            <mat-checkbox [checked]="selectAllItem.isSelected">All</mat-checkbox>
          </div>
        </mat-option>
        <mat-option role="option" class="mat-option multi-select-input-width"
                    style="flex-flow: row-reverse; caret-color: transparent;height:48px;"
                    [ngClass]="{'mat-active' : item.isSelected}"
                    *cdkVirtualFor="let item of filteredItems | async;" [value]="item">
          <div (click)="onSelect($event,item)" [matTooltipDisabled]="!auto.isOpen" matTooltip="{{item.name}}" matTooltipShowDelay="500">
            <mat-checkbox [checked]="item.isSelected">{{item.name}}</mat-checkbox>
          </div>
        </mat-option>
      </cdk-virtual-scroll-viewport>
    </mat-autocomplete>
  </mat-form-field>
</div>
