import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import * as _ from 'lodash';

import { AppService } from '../../app.service';
import { IConfigService } from '../../config/iconfigservice';
import { BaseService } from '../base.service';
import { SigningUrlDelivery } from '../../models';
import { ObservableService } from '../observable.service';

@Injectable()
export class SystemEventService extends BaseService {
    baseUrl: string;
    publicApiBaseUrl: string;

    constructor(
        public appService: AppService,
        public httpClient: HttpClient,
        public configService: IConfigService,
        public observableService: ObservableService
    ) {
        super(appService, configService, httpClient);

        this.baseUrl = this.appService.getAPIBaseURL();
        this.publicApiBaseUrl = this.configService.getConfiguration().publicApiUrl;
    }

    deliverSigningUrl(accountId: string, caseId: string, signingUrlDelivery: SigningUrlDelivery) {
        const clientId = this.appService.getClientId('');
        const url = `${this.publicApiBaseUrl}client/${clientId}/account/${accountId}/case/${caseId}/event/signingurl`;

        return super.postData(url, signingUrlDelivery);
    }

    evaluateRule(caseId: string, ruleId: string) {
        const clientId = this.appService.getClientId('');
        const url = `${this.publicApiBaseUrl}client/${clientId}/case/${caseId}/rule/${ruleId}/evaluaterule`;

        return super.getData<any>(url);
    }
}
