import {Component, OnInit, ViewChild, ElementRef, OnDestroy} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { COMMA, ENTER, TAB, MAC_ENTER, SEMICOLON, SPACE } from '@angular/cdk/keycodes';
import { AppService } from '../app.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import * as $ from 'jquery';
import { Subscription, of} from 'rxjs';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { debounceTime, switchMap } from 'rxjs/operators';

@Component({
    selector: 'endofcall',
    host: { 'class': 'content' },
    templateUrl: './endofcall.component.html',
    styleUrls: ['./endofcall.component.scss']
})

export class EndOfCallComponent implements OnInit, OnDestroy {
    @ViewChild('caseNumberInput') caseNumberInput: ElementRef<HTMLInputElement>;
    @ViewChild('caseNumberAutocomplete') matAutocomplete: MatAutocomplete;

    clientId: string;
    callDetails: any;
    callId: any;
    callEditForm: UntypedFormGroup;
    platforms: any[];
    isContactFound: boolean = true;
    dismissReasons: any = [];
    timeZone: string;

    separatorKeyCodes: number[] = [ENTER, COMMA, TAB, MAC_ENTER, SEMICOLON, SPACE];

    filter: string = null;
    caseNumbers: string[] = [];
    filteredCaseNumbers: string[];
    private postUpdateCallDetailInfoSub: Subscription;

    constructor(
        private appService: AppService,
        private activatedRoute: ActivatedRoute,
        private _fb: UntypedFormBuilder) {
        this.callEditForm = this._fb.group({
            caseNumbers: [[]],
            dismissReason: '',
            caseNumber: ['']
        });
    }

    ngOnInit() {
        $('#sidenav').removeClass('hidenav');
        this.timeZone = this.appService.getTimeZone();
        this.activatedRoute.params.subscribe(params => {
            this.callId = params.id;
            this.clientId = params.tenant;
            this.getCallDetails();
        });
        this.platforms = this.appService.getConfigData('platForm');
        this.dismissReasons = this.appService.getConfigData('dismissReason');

        this.callEditForm
            .get('caseNumber')
            .valueChanges
            .pipe(
                debounceTime(300),
                switchMap(value => {
                    if (this.filter === value) {
                        return of({ data: this.caseNumbers });
                    } else {
                        this.filter = value;
                        return this.appService.getData(`CallLog/CaseNumbers?filter=${value || ''}`, this.clientId);
                    }
                })
            )
            .subscribe(result => this.updateFilteredCaseNumbers(result.data));
    }

    ngOnDestroy() {
        if(this.postUpdateCallDetailInfoSub)
            this.postUpdateCallDetailInfoSub.unsubscribe();
    }

    updateFilteredCaseNumbers(caseNumbers?: string[]) {
        if (caseNumbers) {
            this.caseNumbers = caseNumbers.slice();
        }

        const selectedCaseNumbers = this.callEditForm.get('caseNumbers').value;
        this.filteredCaseNumbers = this.caseNumbers.filter(c => selectedCaseNumbers.indexOf(c) < 0);
    }

    getCallDetails() {
        this.appService.getData(`CallLog/${this.callId}`, this.clientId).subscribe(
            data => {
                if (data.status === 'success') {
                    this.callDetails = data.data;
                    this.callEditForm.patchValue({
                        caseNumbers: (this.callDetails.externalId !== '' ? this.callDetails.externalId.split(',') : []),
                        dismissReason: this.callDetails.dismissReason,
                    });
                    this.isContactFound = true;
                } else {
                    this.isContactFound = false;
                }

                this.appService.getData(`CallLog/CaseNumbers`, this.clientId).subscribe(result => this.updateFilteredCaseNumbers(result.data));
            }
        );
    }

    caseNumberSelected(event: MatAutocompleteSelectedEvent): void {
        this.addSelectedCaseNumber(event.option.value);
        this.caseNumberInput.nativeElement.value = '';
        this.callEditForm.get('caseNumber').setValue(null);

        this.updateFilteredCaseNumbers();
    }

    removeCaseNumber(caseNumber: string) {
        this.removeSelectedCaseNumber(caseNumber);

        this.updateFilteredCaseNumbers();
    }

    addCaseNumber(event: MatChipInputEvent): void {
        if (!this.matAutocomplete.isOpen) {
            const input = event.input;
            const value = event.value;

            this.addSelectedCaseNumber(value);

            if (input) {
                input.value = '';
            }

            this.callEditForm.get('caseNumber').setValue(null);

            this.updateFilteredCaseNumbers();
        }
    }

    dismissReasonChange(dismiss) {
        const newValue = this.callEditForm.get('dismissReason').value;
        if (newValue !== '') {
            this.callEditForm.get('caseNumbers').setValue([]);
            this.updateFilteredCaseNumbers();
        }
    }

    checkOrderId() {
        const newValue = this.callEditForm.get('caseNumbers').value;
        if (newValue && newValue.length >= 0) {
            this.callEditForm.get('dismissReason').setValue('');
        }
    }

    updateCallDetailInfo() {
        if ((!this.callEditForm.get('caseNumbers').value || this.callEditForm.get('caseNumbers').value.length <= 0) &&
            (!this.callEditForm.get('dismissReason').value || this.callEditForm.get('dismissReason').value === '')) {
            this.appService.showMsg('error', 'Please select an Order ID or Dismiss Reason.', false);
        } else {
            this.postUpdateCallDetailInfoSub = this.appService.postData(`CallLog/UpdateCallDetailInfo/${this.callId}`, this.callEditForm.value, false, false, this.clientId).subscribe(
                data => {
                    if (data.status === 'success') {
                        this.appService.showMsg('success', 'Call Details saved successfully ...');
                    }
                }
            );
        }
    }

    private addSelectedCaseNumber(caseNumber: string) {
        const selectedCaseNumbers = this.callEditForm.get('caseNumbers').value;
        const trimmedCaseNumber = caseNumber.trim();

        if (selectedCaseNumbers.indexOf(trimmedCaseNumber) < 0
            && this.filteredCaseNumbers.indexOf(trimmedCaseNumber) >= 0) {
            selectedCaseNumbers.push(trimmedCaseNumber);
            this.callEditForm.get('caseNumbers').setValue(selectedCaseNumbers);
        }
    }

    private removeSelectedCaseNumber(caseNumber: string) {
        const selectedCaseNumbers = this.callEditForm.get('caseNumbers').value;
        const trimmedCaseNumber = caseNumber.trim();

        const index = selectedCaseNumbers.indexOf(trimmedCaseNumber);
        if (index >= 0) {
            selectedCaseNumbers.splice(index, 1);
            this.callEditForm.get('caseNumbers').setValue(selectedCaseNumbers);
        }
    }

    checkACL(feature) {
        if (feature === '') { return true; }
        return this.appService.checkACL(feature, 'R');
    }
}
