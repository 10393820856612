import { Injectable } from '@angular/core';
import * as _ from 'lodash';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AppService } from 'app/app.service';
import { StripeComponent } from 'app/components/stripe/stripe.component';
import { QueueStatusEnum } from 'app/enums';
import { InterviewService } from 'app/interview.service';
import { NotificationSeverity } from 'app/services';

@Injectable()
export class StripeIntegrationResponseHandler {

    tcDialog: MatDialogRef<StripeComponent>;

    constructor(
        public appService: AppService,
        public interviewService: InterviewService,
        public dialog: MatDialog
    ) { }

    handleResponse(clientId: string, accountId: string, integrationResponse, integrationData) {
        if (integrationResponse.status === QueueStatusEnum.Completed) {
            const intent = _.get(integrationData, 'details.clientProperties.clientIntent');
            const publishableKey = _.get(integrationData, 'details.clientProperties.stripePublishableKey');

            if (publishableKey != null) {
                this.showStripeDialog(clientId, accountId, integrationData.caseDetailId, integrationData.caseIntegrationQueueId, intent, publishableKey);
            }
        } else if (integrationResponse.status === QueueStatusEnum.Failed) {
            this.appService.showMsg(NotificationSeverity.Error, integrationResponse.errorMessage);
        }
    }

    showStripeDialog(clientId: string, accountId: string, caseId: string, caseIntegrationQueueId: string, intent: any, publishableKey: string) {
        this.tcDialog = this.dialog.open(StripeComponent, {
            width: '600px',
            disableClose: true,
            data: {
                clientId: clientId,
                caseId: caseId,
                caseIntegrationQueueId: caseIntegrationQueueId,
                accountId: accountId,
                intent: intent,
                publishableKey: publishableKey
            }
        });
    }
}
