import { animate, style, transition, trigger } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort, MatSortable, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { AppService } from '@App';
import { Account, CaseAssignee, ClientUser, ManagementQueue, ManagementQueueResult, ManagementQueueSettings } from '@Models';
import {
    MultiSelectDropdownListComponent,
} from 'app/components/multi-select-dropdown-list/multi-select-dropdown-list.component';
import * as $ from 'jquery';
import * as _ from 'lodash';
import { forkJoin, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { MultiSelectModel } from '../components/multi-select-dropdown-list/models/MultiSelectModel.interface';
import { InterviewService } from '../interview.service';
import { MonitoringService } from '../monitor.service';
import {
    CaseAssigneeService,
    CaseSummaryService,
    ClientService,
    GlobalService,
    ManagementQueueService,
    NotificationService,
    NotificationSeverity,
    RoutesEnum,
    RoutingService,
    UtilityService,
} from '@Services';
import { groupBy } from '../shared/functions';
import { NotesDialogComponent } from './notes-dialog.component';

@Component({
    selector: 'management-queues',
    host: { 'class': 'content' },
    templateUrl: './management-queues.component.html',
    styleUrls: ['management-queues.component.scss'],
    animations: [
        trigger('flyRightToLeft', [
            transition(':enter', [
                style({ transform: 'translateX(-100%)' }),
                animate(250),
                animate('10ms', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                animate(200, style({ transform: 'translateX(-100%)' })),
                animate('10ms', style({ opacity: 0 }))
            ])
        ])
    ],
    encapsulation: ViewEncapsulation.None
})
export class ManagementQueuesComponent implements OnInit, OnDestroy, AfterViewInit {
    sort: MatSort = new MatSort();
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatTabGroup) tabGroup: MatTabGroup;
    @ViewChildren(MatMenuTrigger) matMenuTriggers: MatMenuTrigger[];
    @ViewChild('actionMenu') actionMenu: MatMenu;
    @ViewChild('userInput', { read: MatAutocompleteTrigger }) userInput: MatAutocompleteTrigger;
    @ViewChild('assigneeDropdown') assigneeDropdown: MultiSelectDropdownListComponent;
    @ViewChild('clientDropdown') clientDropdown: MultiSelectDropdownListComponent;

    public get selectedClientItems(): string[] {
        return this.hasMultipleClients ? this.selectedClients.value : this.clientSelectListItems.map(item => item.id);
    }

    filterValue: any;
    menu: any = [];
    activeMenu: any = '';

    clientUsers: ClientUser[] = [];
    queues: ManagementQueue[] = [];
    cases: ManagementQueueResult[] = [];
    dialogRef: MatDialogRef<NotesDialogComponent>;
    caseDataSource = new MatTableDataSource<ManagementQueueResult>();
    selectedQueue: ManagementQueue;
    selectedIndex: any = 0;
    timeZone: string;
    settings: ManagementQueueSettings = {};
    useSavedData = false;
    canAssignCase = false;
    accounts: Account[];
    clientSelectListItems: MultiSelectModel[];
    hasMultipleClients = false;
    selectedClients: UntypedFormControl;
    contextMenuPosition = { x: '0px', y: '0px' };
    selectedUser: UntypedFormControl;
    appServiceGetDataSub: any;
    columnsToDisplay = [];
    customColumns: string[] = [];
    selection: SelectionModel<ManagementQueueResult>;
    actionMenuTrigger: MatMenuTrigger;
    subscriptions: Subscription[] = [];
    filteredUserList: ClientUser[];
    assigneeSelectListItems: MultiSelectModel[];
    selectedAssignees: UntypedFormControl;
    onLoadSelectedAssigneesList: string[];  // Needed to keep OnLoad input separate from MultiSelect Control Input
    onLoadSelectedClientsList: string[];    // Needed to keep OnLoad input separate from MultiSelect Control Input
    subs = new SubSink();

    constructor(
        public appService: AppService,
        public datePipe: DatePipe,
        public interviewService: InterviewService,
        public routingService: RoutingService,
        public activatedRoute: ActivatedRoute,
        public dialog: MatDialog,
        public monitoringService: MonitoringService,
        public managementQueueService: ManagementQueueService,
        public clientService: ClientService,
        public caseAssigneeService: CaseAssigneeService,
        public notificationService: NotificationService,
        public snackBar: MatSnackBar,
        public utilityService: UtilityService,
        public globalService: GlobalService,
        public caseSummaryService: CaseSummaryService
    ) {

        this.appService.getAllConfigData();
        this.selection = new SelectionModel<ManagementQueueResult>(true, []);
        const activatedRouteSub = this.activatedRoute.data.pipe(
            tap(data => {
                const uniqueClients = groupBy(data.accounts, client => client.clientId);
                const selectModels = new Array<MultiSelectModel>();
                this.hasMultipleClients = uniqueClients && uniqueClients.size > 1;
                uniqueClients.forEach(client => {
                    selectModels.push({
                        id: client[0].clientId,
                        name: client[0].clientName ?? client[0].clientId,
                        isSelected: !this.hasMultipleClients
                    });
                });
                this.clientSelectListItems = selectModels;
            })
        ).subscribe(data => {
            this.accounts = data.accounts;
        });

        this.subs.sink = activatedRouteSub;
        this.globalService.getAllAssignees().subscribe(
            assignees => {
                const selectModels = new Array<MultiSelectModel>();
                assignees.sort(function (a, b) {
                    const nameA = `${a.firstName?.toUpperCase()} ${a.lastName?.toUpperCase()}`; // ignore upper and lowercase
                    const nameB = `${b.firstName?.toUpperCase()} ${b.lastName?.toUpperCase()}`; // ignore upper and lowercase
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }

                    // names must be equal
                    return 0;
                });
                assignees.forEach(assignee => {
                    selectModels.push({
                        id: assignee.id,
                        name: `${assignee.firstName} ${assignee.lastName}`,
                        isSelected: false
                    });
                });
                this.assigneeSelectListItems = selectModels;
            });
    }

    ngOnInit() {
        $('#sidenav').removeClass('hidenav');
        this.timeZone = this.appService.getTimeZone();
        this.settings = this.managementQueueService.getSavedSettings();
        if (this.settings && this.settings.caseData) {
            this.useSavedData = true;
        }

        this.selectedUser = new UntypedFormControl('');
        this.selectedAssignees = new UntypedFormControl([]);
        this.selectedClients = new UntypedFormControl([]);
        this.subs.sink = this.selectedUser.valueChanges.subscribe(val => {
            const filterValue = val ? val.toLowerCase() : '';

            if (this.clientUsers.length > 0) {
                this.filteredUserList = this.clientUsers.filter((item: ClientUser) => {
                    return (`${item.firstName} ${item.lastName}`.toLowerCase().includes(filterValue));
                });
                this.filteredUserList = _.sortBy(this.filteredUserList, ['firstName', 'lastName']);
            }
        });

        this.subs.sink = this.selectedAssignees.valueChanges.subscribe(val => {
            this.filterCases();
        });

        this.subs.sink = this.selectedClients.valueChanges.subscribe(val => {
            this.filterCases();
        });

        this.canAssignCase = this.checkACL('Allow Case Assignment', 'F');
    }

    ngAfterViewInit() {
        if (this.settings) {
            this.selectedQueue = this.settings.selectedQueue;
            this.caseDataSource.data = this.settings.caseData;
            this.cases = this.settings.caseData;
            this.queues = this.settings.queues;
            this.selectedClients.setValue(this.settings.selectedClients);
            this.onLoadSelectedClientsList = this.settings.selectedClients;
            this.selectedAssignees.setValue(this.settings.selectedAssignees);
            this.onLoadSelectedAssigneesList = this.settings.selectedAssignees;
            this.selectedIndex = this.settings.selectedQueue.order - 1;
            this.filterValue = this.settings.filter;
            this.updateFilteredCases();
        } else {
            this.getQueues();
        }

        this.actionMenuTrigger = this.matMenuTriggers.find(matMenuTrigger => matMenuTrigger.menu === this.actionMenu);
    }

    checkACL(feature, permissionType) {
        return this.appService.checkACL(feature, permissionType);
    }

    setAttributes() {
        if (this.settings) {

            if (this.paginator) {
                this.caseDataSource.paginator = this.paginator;
                this.paginator.pageIndex = this.settings.pageIndex;
                this.paginator.pageSize = this.settings.pageSize;
            }

            if (this.sort) {
                this.caseDataSource.sort = this.sort;
                this.sort.sort(({ id: this.settings.sortActiveColumn, start: this.settings.sortDirection }) as MatSortable);

                const sortState: Sort = { active: this.settings.sortActiveColumn, direction: this.settings.sortDirection };
                this.sort.direction = sortState.direction;
                this.sort.sortChange.emit(sortState);
            }

            this.filterCases();
        }
    }

    getQueues() {
        this.subs.sink = this.appService.getData('CaseManager').subscribe(
            results => {
                this.queues = results.data;
            }
        );
    }

    getCases(queue: ManagementQueue) {
        this.selectedQueue = queue;
        this.selection.clear();
        if (this.useSavedData) {
            this.useSavedData = false;
            this.setAttributes();
        } else {
            this.caseDataSource = new MatTableDataSource<ManagementQueueResult>();
            const metricEvent = this.monitoringService.beginMetric(`Search: Case Manager-${this.selectedQueue.name}`);
            this.subs.sink = this.appService.getData(`CaseManager/Search/${queue.id}`).subscribe(
                results => {
                    this.cases = (results.data || []).map(c => (Object.assign({}, c, {
                        caseCreatedDateFormatted: this.datePipe.transform(c.caseCreatedDate, 'MM/dd/yyyy', this.timeZone),
                        canResume: this.interviewService.canResumeCase(c.status),
                        note: c.displayNote,
                        usersInCase: this.getPresenceUsers(c),
                        clientId: c.clientId,
                        checked: false
                    }, c.mapkeys.reduce((dict, next) => {
                        dict[next.name] = next.value;
                        return dict;
                    }, {}))));
                    this.caseDataSource.sort = this.sort;
                    this.caseDataSource.paginator = this.paginator;
                    this.sort.direction = null;

                    if (!this.sort.active) {
                        this.sort.sort({ id: 'caseCreated', start: null, disableClear: false });
                    } else {
                        this.sort.sort({
                            id: this.settings.sortActiveColumn,
                            start: this.getSortDirection(this.settings.sortDirection),
                            disableClear: false
                        });
                    }

                    this.caseDataSource.data = this.cases;
                    this.updateFilteredCases();
                    this.saveSettings();

                    this.filterCases();

                    this.caseDataSource.sortingDataAccessor = (item, property) => {
                        if (property === 'applicantFullName') {
                            return `${item.Case_Applicant_FirstName} ${item.Case_Applicant_LastName}`.toLowerCase();
                        } else {
                            return item[property];
                        }
                    };
                    this.monitoringService.endMetric(metricEvent);
                    this.monitoringService.flushMetrics();
                });
        }
    }

    getSortDirection(currentDirection) {
        if (currentDirection === 'asc') {
            return null;
        }

        if (currentDirection === 'desc') {
            return 'asc';
        }

        if (currentDirection === null) {
            return 'desc';
        }
    }

    sortColumn(columnName) {
        this.sort.active = columnName;
        this.sort.sort({ id: columnName, start: this.sort.start, disableClear: false });
        this.saveSettings();
        this.filterCases();
    }

    getPresenceUsers(data) {
        let result = null;
        if (data.presenceUsers && data.presenceUsers.length > 0) {
            result = Object.keys(data.presenceUsers).map(function (k) {
                return data.presenceUsers[k].name;
            }).join(', ');
        }
        return result;
    }

    viewNotes(item: any) {
        this.dialogRef = this.dialog.open(NotesDialogComponent, {
            width: '75%',
            data: item
        });
    }

    selectQueue(tab: any) {
        if (this.selectedQueue !== tab) {
            this.selection.clear();
        }
        const queue = this.queues[tab.index];
        const sortedColumns = _.orderBy(queue.managementQueueColumnMapKeys, ['order']);
        const columns = sortedColumns.map(item => item.columnDefinition)
            .filter((value, index, self) => self.indexOf(value) === index);

        this.columnsToDisplay = columns;

        this.getCases(this.queues[tab.index]);
    }

    updateFilteredCases() {
        if (this.filterValue) {
            this.caseDataSource.filter = this.filterValue.trim().toLowerCase();
        } else {
            this.caseDataSource.filter = null;
        }
    }

    viewCaseDetails(record, newTab: boolean) {
        this.caseSummaryService.routeToCase(record, newTab);
    }

    stopPropagation($event) {
        $event.stopPropagation();
    }

    // TECH DEBT: This doesn't appear to be used anywhere and it's access non-existent properties (bug). I'm commenting it out to see if anything breaks.
    // restartInterview(record) {
    //     const account = this.accounts.find(a => a.id === record.accountId);
    //     this.routingService.navigateToRoute(RoutesEnum.interviewRestart, {
    //         clientCode: account.clientCode, // TECH DEBT: `clientCode` does not exist on this model.
    //         accountCode: account.code,
    //         versionNumber: account.versionNumber,
    //         caseId: record.caseId
    //     });
    // }

    saveSettings() {
        this.settings = {
            caseData: this.cases,
            filter: this.filterValue,
            selectedClients: this.selectedClients.value,
            selectedAssignees: this.selectedAssignees.value,
            queues: this.queues,
            selectedQueue: this.selectedQueue,
            pageIndex: this.paginator.pageIndex,
            pageSize: this.paginator.pageSize,
            sortActiveColumn: this.sort.active,
            sortDirection: this.sort.direction
        };

        this.managementQueueService.saveSettings(this.settings);
    }

    selectedClientsChanged(newlySelectedClients: string[]) {
        this.selectedClients.setValue(newlySelectedClients);
    }

    selectedAssigneesChanged(newlySelectedAssignees: string[]) {
        this.selectedAssignees.setValue(newlySelectedAssignees);
    }

    filterCases() {
        const clientIds = this.selectedClientItems;
        const assigneeIds = this.selectedAssignees.value;


        let dataSource = this.settings ? this.settings.caseData : this.caseDataSource.data;

        if ((clientIds?.includes('00000000-0000-0000-0000-0000000000000') || clientIds?.length === 0) &&
            (assigneeIds?.includes('00000000-0000-0000-0000-0000000000000') || assigneeIds?.length === 0)) {
            this.caseDataSource.data = dataSource;
        } else {
            if (clientIds.length !== 0) {
                dataSource = dataSource.filter(caseData => clientIds.includes(caseData.clientId.toLowerCase()));
            }

            if (assigneeIds.length !== 0) {
                dataSource = dataSource.filter(caseData => assigneeIds.includes(caseData.assignedTo?.toLowerCase()));
            }

            this.caseDataSource.data = dataSource;
        }

        this.caseDataSource.data = dataSource;
        this.caseDataSource.connect().next(dataSource);
        this.paginator._changePageSize(this.paginator.pageSize);
    }

    openMenu(event: MouseEvent, record) {
        event.preventDefault();
        if (this.selection.selected.length === 0 || !this.selection.isSelected(record)) {
            this.selection.clear();
            this.selection.select(record);
        }
        this.contextMenuPosition.x = event.clientX + 'px';
        this.contextMenuPosition.y = event.clientY + 'px';
        this.actionMenuTrigger.openMenu();
    }

    toggleAllSelection() {
        if (this.isAllSelected()) {
            this.selection.clear();
        } else {
            this.caseDataSource.filteredData.forEach(row => {
                if (!this.selection.isSelected(row)) {
                    this.selection.select(row);
                }
            });
        }
    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.caseDataSource.filteredData.length;
        return numSelected === numRows;
    }

    openInNewWindow() {
        const selectedItems = this.getSelectedCases();

        if (selectedItems.length === 0) {
            this.appService.showMsg(NotificationSeverity.Warning, 'Select an item...');
            return;
        }

        selectedItems.forEach(record => {
            this.viewCaseDetails(record, true);
        });
    }

    getSelectedCases(): ManagementQueueResult[] {
        return this.selection.selected;
    }

    getClientUsers() {
        const observableBatch = [];
        const selectedCases = this.getSelectedCases();

        const clientIds = selectedCases.map(item => item.clientId)
            .filter((value, index, self) => self.indexOf(value) === index);

        this.clientUsers = [];
        clientIds.forEach(clientId => {
            observableBatch.push(this.clientService.getClientUsers(clientId));
        });

        this.subs.sink = forkJoin(observableBatch).subscribe({
            next: (responses) => {
                responses.forEach((response: ClientUser[]) => {
                    if (this.clientUsers.length === 0) {
                        this.clientUsers = response;
                    } else {
                        this.clientUsers = this.utilityService.compareTwoArrays(this.clientUsers, response, true);
                    }
                });
            },
            complete: () => this.filteredUserList = _.sortBy(this.clientUsers, ['firstName', 'lastName'])
        });
    }

    assignUser() {
        const user = this.clientUsers.find(x => x.id === this.selectedUser.value);
        const caseAssignees = this.buildCaseAssigneeList(user.id);
        const completedCases = [];
        const errorCases = [];

        this.subs.sink = this.caseAssigneeService.setCaseUserAssignee(caseAssignees).subscribe(response => {
            response.forEach(res => {
                if (typeof res.userErrors !== 'undefined') {
                    errorCases.push(res);
                } else {
                    completedCases.push(res);
                }
            });

            if (completedCases.length > 0) {
                this.toastCompletedCases(completedCases);
            }

            if (errorCases.length > 0) {
                this.toastErrorCases(errorCases);
            }
            this.clearSelection();
        });
    }

    public toastErrorCases(errorCases: any[]) {
        if (errorCases.length > 0) {
            this.notificationService.showNotification({
                severity: NotificationSeverity.Error,
                message: `${errorCases.length} ${errorCases.length > 1 ? 'cases' : 'case'} errored.`
            });
        }
    }

    public toastCompletedCases(completedCases: any[]) {
        const user = this.clientUsers.find(x => x.id === this.selectedUser.value);
        if (completedCases.length > 0) {
            this.notificationService.showNotification({
                severity: NotificationSeverity.Success,
                message: `${completedCases.length} ${completedCases.length > 1 ? 'cases have' : 'case has'} been assigned to ${user.firstName} ${user.lastName}`
            });
            this.selection.selected.forEach(selectedCase => {
                selectedCase.assignedTo = user.id;
                selectedCase.assignedToFullName = `${user.firstName} ${user.lastName}`;
            });
        }
    }

    buildCaseAssigneeList(userId?) {
        const caseAssignees: CaseAssignee[] = [];

        this.getSelectedCases().forEach(selectedCase => {
            const caseAssignee: CaseAssignee = {
                caseId: selectedCase.caseId,
                clientCode: selectedCase.clientId,
                userId: userId ?? selectedCase.assignedTo
            };

            caseAssignees.push(caseAssignee);
        });

        return caseAssignees;
    }

    claimCase() {
        const completedCases = [];

        const caseAssignees = this.buildCaseAssigneeList();

        this.subs.sink = this.caseAssigneeService.removeCaseUserAssignee(caseAssignees).subscribe(response => {
            response.forEach(res => {
                completedCases.push(res);
            });

            this.notificationService.showNotification({
                severity: NotificationSeverity.Success,
                message: `${completedCases.length} ${completedCases.length > 1 ? 'cases' : 'case'} cleared!`
            });

            this.selection.selected.forEach(selectedCase => {
                selectedCase.assignedTo = null;
            });
            this.clearSelection();
        });
    }

    canSelectedCasesBeAssign() {
        let areAllAssignable = true;
        this.selection.selected.forEach(selectedRow => {
            if (!selectedRow.canAssignTo) {
                areAllAssignable = false;
            }
        });

        return areAllAssignable;
    }

    clearSelection() {
        this.selection.clear();
        this.matMenuTriggers.forEach((matMenuTrigger) => matMenuTrigger.closeMenu());
        this.selectedUser.reset();
        this.userInput.closePanel();
    }

    getOptionText(option) {
        const user = this.clientUsers.find((clientUser) => clientUser.id === option);
        if (user) {
            return `${user.firstName} ${user.lastName}`;
        }
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
        this.saveSettings();
    }
}
