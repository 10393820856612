import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DocumentWizardDocumentAdd } from '@Components/document-wizard/add-documents/document-add.component';
import { CombinedDocumentDTO } from '@DTOs';
import { DocType } from '@Enums';
import { Account, AccountEvent, DocumentExternal, FaxEvent, Tag } from '@Models';
import { DocumentsService, MapKeyService, NotificationService, NotificationSeverity, TagService } from '@Services';
import * as _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

@Component({
    selector: 'combined-documents',
    host: { 'class': '' },
    templateUrl: './combined-documents.component.html',
    styleUrls: ['./combined-documents.component.scss']
})
export class CombinedDocumentsComponent implements OnInit {
    @ViewChild('myData', { static: true }) myData: ElementRef;
    @Input() documentFormData?: any;

    stepOneFormGroup: UntypedFormGroup;
    addDocsFormGroup: UntypedFormGroup;
    arrangeDocsFormGroup: UntypedFormGroup;
    combinedDocumentsForm: UntypedFormGroup;
    combinedDocuments: CombinedDocumentDTO[] = [];
    selectedDocument: any;
    event: FaxEvent;
    accountInfo: Account;
    stepTwoNextText = "ARRANGE DOCUMENTS";
    stepThreeNextText = "DONE";

    readonly separatorKeysCodes: number[] = [ENTER, COMMA];

    accountEvent: AccountEvent;
    mapKeys: any;
    documents: DocumentExternal[];
    tags: Tag[];
    selectedDocuments: any[] = [];
    filteredSelectDocuments: any[] = [];
    selectedTags: Tag[] = [];
    selectedItems: any[] = [];
    template: FaxEvent;
    faxMapKeys = ["", ""];
    isFaxEvent = true;

    constructor(
        private _fb: UntypedFormBuilder,
        public notificationService: NotificationService,
        public mapKeyService: MapKeyService,
        public documentService: DocumentsService,
        public tagService: TagService,
        public dialogRef: MatDialogRef<CombinedDocumentsComponent>,
        public addDocsDialogRef: MatDialogRef<DocumentWizardDocumentAdd>,
        @Inject(MAT_DIALOG_DATA) public data: any) { }

    async ngOnInit() {
        this.event = this.data.event;
        this.accountInfo = this.data.accountInfo;
        this.mapKeys = this.mapKeyService.nonConstantMapKeys;
        this.documentFormData = this.data.documentFormData;

        await this.getTags();

        this.filteredSelectDocuments = this.data.documents.filter(x => x.doctypeId != DocType.CombinedDocument && (x.doctypeId == DocType.PDF || x.doctypeId == DocType.TIFF || x.generateTo == DocType.PDF || x.generateTo == DocType.TIFF));

        this.stepOneFormGroup = this._fb.group({
            mapKeyId: ['', Validators.required]
        });
        this.addDocsFormGroup = this._fb.group({});
        this.arrangeDocsFormGroup = this._fb.group({});

        this.selectedDocument = this.data.selectedDocument;

        this.combinedDocumentsForm = this._fb.group({
            selectedDocuments: [''],
            selectedTags: ['']
        });

        await this.setupForm();
    }

    async setupForm() {
        if (this.selectedDocument && this.selectedDocument.combinedDocuments && this.selectedDocument.combinedDocuments.length > 0) {

            for await (const combinedDocument of this.selectedDocument.combinedDocuments) {
                // is tag?
                const tagCheck = (p: any) => Object.prototype.hasOwnProperty.call(p, 'tagName');

                if (tagCheck(combinedDocument)) {
                    const tag = this.tags.find(x => x.name === combinedDocument.tagName);
                    this.selectedTags.push(tag);
                    this.selectedItems.push(tag);
                    tag.checked = true;
                }
                else {
                    const document = this.data.documents.find(x => x.id === combinedDocument.documentId);
                    this.selectedDocuments.push(document);
                    this.selectedItems.push(document);
                    document.checked = true;
                }
            }
        }
    }


    getDocumentName(id) {

        const d = this.documents.find(doc => {
            if (doc.id === id) {
                return doc;
            }
        });

        if (d) {
            return d.name;
        }
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.combinedDocuments, event.previousIndex, event.currentIndex);
    }

    showCasePackageWarning(combinedDocument) {
        if (!this.selectedDocument.isExcludeTransmit) {
            const d = _.find(this.data.documents, function (doc) {
                if (doc.id === combinedDocument.documentId) {
                    return doc;
                }
            });

            if (!d?.isExcludeTransmit) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    updateSelectedDocumentCombinedDocument() {
        this.selectedItems = this.dialogRef.componentInstance.selectedItems;
        const combinedItems: any[] = [];
        let idx = 0;

        if (this.selectedItems.length <= 0) {
            this.notificationService.showNotification({
                severity: NotificationSeverity.Error,
                message: 'Please select at least one document or tag'
            });
            return false;
        }
        const parentDocumentId = uuidv4();

        this.selectedItems.forEach(item => {
            const selectedDocumentsIndex = this.selectedDocuments.indexOf(item);
            const selectedTagsIndex = this.selectedTags.indexOf(item);

            if (selectedTagsIndex >= 0) {
                const combined = new CombinedDocumentDTO(item.accountId, parentDocumentId, item.id, idx, item.name, item.name);
                combinedItems.push(combined);
            }

            if (selectedDocumentsIndex >= 0) {
                const combined = new CombinedDocumentDTO(item.accountId, parentDocumentId, item.id, idx, item.name);
                combinedItems.push(combined);
            }

            idx++;
        });

        this.combinedDocumentsForm.get('selectedDocuments').setValue(combinedItems);
        this.dialogRef.close({ event: 'CombinedDocuments', form: this.combinedDocumentsForm, data: this.data });
    }


    async getTags() {
        this.tags = await this.tagService.getTagsByAccount(this.accountInfo);
    }
}
