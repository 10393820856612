import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { AppService } from '../app.service';
import { ActivatedRoute } from '@angular/router';
import { RoutingService, RoutesEnum, ClientDataService, DashboardService, AccountService, CaseSummaryService } from '../services';
import { MonitoringService } from '../monitor.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DatePipe } from '@angular/common';
import { Account, SearchParams } from '../models';
import * as $ from 'jquery';
import * as moment from 'moment';

export const DATE_FORMATS = [ 'M/D/YYYY', 'MM/DD/YYYY', 'YYYY-MM-D', 'YYYY-MM-DD', moment.ISO_8601];

@Component({
    selector: 'app-dashboard',
    host: { 'class': 'content' },
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;

    accounts: Account[];
    menu: any = [];
    activeMenu: any = '';
    searchValue: any;
    daysBack: any = '60';
    noDataPlaceholder: boolean = false;
    storedRecords: any = [];
    timeZone: string;
    cases: any = [];
    clients: any = [];
    clientId: any = '';
    caseDataSource = new MatTableDataSource<any>();
    columnsToDisplay = ['accountName', 'caseNumber', 'policyNumber', 'applicant', 'dateOfBirthFormatted', 'agent', 'Case_Agent_AgentNumber','statusName', 'caseCreatedDateFormatted', 'actions'];
    searchParams: SearchParams;

    constructor(
        private appService: AppService,
        private activatedRoute: ActivatedRoute,
        private monitoringService: MonitoringService,
        private clientDataService: ClientDataService,
        private dashboardService: DashboardService,
        private datePipe: DatePipe,
        private caseSummaryService: CaseSummaryService
    ) {
        this.appService.getAllConfigData();
        this.activatedRoute.data.subscribe(data => {
            this.accounts = data.accounts;
        });

    }

    ngOnInit() {
        $('#sidenav').removeClass('hidenav');
        this.timeZone = this.appService.getTimeZone();
        this.getClients();
    }

    ngAfterViewInit() {
        this.searchParams = this.dashboardService.getSearchParams();
        if (this.searchParams) {
            this.daysBack = this.searchParams.daysBack;
            this.clientId = this.searchParams.clientId;
            this.caseDataSource = this.searchParams.caseDataSource;
            this.searchValue = this.searchParams.searchValue;

            this.paginator.pageIndex = this.searchParams.pageIndex;
            this.paginator.pageSize = this.searchParams.pageSize;
            this.sort.sort(({ id: this.searchParams.sortActiveColumn, start: this.searchParams.sortDirection }) as MatSortable);

            const sortState: Sort = {active: this.searchParams.sortActiveColumn, direction: this.searchParams.sortDirection};
            this.sort.direction = sortState.direction;
            this.sort.sortChange.emit(sortState);

            this.caseDataSource.paginator = this.paginator;
            this.caseDataSource.sort = this.sort;
        }
    }

    checkACL(feature) {
        if (feature === '') { return true; }
        return this.appService.checkACL(feature, 'R');
    }

    isActive(selected) {
        return this.activeMenu === selected;
    }

    getDaysBackDateRange() {
        if (this.daysBack != '') {
            const today = moment().format('MMM D, YYYY');
            const startdate = moment().subtract(this.daysBack, "days").format("MMM D, YYYY");
            return `${startdate} - ${today}`;
        } else {
            return 'All';
        }
    }

    getClients() {
        this.clientDataService.getClients(true)
            .subscribe(clients => this.clients = clients);
    }

    getCases() {
        if (this.searchValue && this.searchValue.length > 0) {
            this.caseDataSource.data = [];
            const metricEvent = this.monitoringService.beginMetric('Search: Dashboard');

            const searchUrl = `Case/Search?searchText=${encodeURIComponent(this.searchValue)}` +
                `&includeMapKeys=Case_Applicant_FirstName&includeMapKeys=Case_Applicant_LastName` +
                `&includeMapKeys=Case_Applicant_DOB&includeMapKeys=Case_Agent_FirstName` +
                `&includeMapKeys=Case_Agent_LastName&includeMapKeys=Case_Agent_AgentNumber` +
                `&daysBack=${encodeURIComponent(this.daysBack)}` +
                `&clientId=${this.clientId}`;

            this.appService.getData(searchUrl).subscribe(
                data => {
                    this.cases = (data.data || []).map(c => (Object.assign({}, c, {
                        caseCreatedDateFormatted: this.datePipe.transform(c.caseCreated, 'MM/dd/yyyy', this.timeZone),
                    }, c.mapkeys.reduce((dict, next) => {
                        if (next.value) {
                            if (next.name === 'Case_Applicant_DOB') {
                                dict['dateOfBirthFormatted'] = this.formatDateString(next.value);
                            }
                            dict[next.name] = next.value;
                        }
                        return dict;
                    }, {}))));

                    this.caseDataSource.data = this.cases;
                    this.monitoringService.endMetric(metricEvent);
                    this.monitoringService.flushMetrics();
                    this.sort.sort(({ id: 'caseCreatedDateFormatted', start: 'desc' }) as MatSortable);

                    this.caseDataSource.sortingDataAccessor = (item, property) => {
                        switch (property) {
                        case 'caseCreatedDateFormatted': return item.caseCreated;
                        case 'dateOfBirthFormatted': return item.Case_Applicant_DOB;
                        case 'applicant': return `${item.Case_Applicant_FirstName} ${item.Case_Applicant_LastName}`.toLowerCase();
                        case 'agent': return `${item.Case_Agent_FirstName} ${item.Case_Agent_LastName}`.toLowerCase();
                        default: return item[property];
                        }
                    };
                    this.caseDataSource.sort = this.sort;
                    this.caseDataSource.paginator = this.paginator;
                }
            );
        }
    }

    formatDateString(dobInput: string) :string {
        const mmt = moment(dobInput, DATE_FORMATS);
        if (mmt.isValid()) {
            return mmt.format('MM/DD/YYYY');
        }
        // it's probably a js date string, handle accordingly
        const jsDate = new Date(dobInput);
        const jsMoment = moment(jsDate, false);
        return jsMoment.format('MM/DD/YYYY');
    }

    viewCaseDetails(record, newTab: boolean) {
        this.caseSummaryService.routeToCase(record, newTab);
    }

    stopPropagation($event) {
        $event.stopPropagation();
    }

    ngOnDestroy() {
        const searchParams: SearchParams = {
            daysBack: this.daysBack,
            clientId: this.clientId,
            caseDataSource: this.caseDataSource,
            searchValue: this.searchValue,
            pageIndex: this.paginator.pageIndex,
            pageSize: this.paginator.pageSize,
            sortActiveColumn: this.sort.active,
            sortDirection: this.sort.direction
        };

        this.dashboardService.saveSearchParams(searchParams);
    }
}
