import { AuditCaseEnhanced, AuditCasesGroup, AuditCasesGroupEnhanced, AuditGroupByDisplayTypes, AuditGroupByTypes, AuditProgressStatistics } from '@Audit';
import { MrsColor } from '@Enums';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
    selector: 'audit-group-table',
    templateUrl: './audit-group-table.component.html',
    styleUrls: ['./audit-group-table.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class AuditGroupTableComponent implements OnChanges {
    @Input() auditCasesGroups: AuditCasesGroup[] = [];
    @Input() noCasesMessage = 'No Cases';
    @Input() disableActions = false;
    @Input() hideActions = false;
    @Input() auditGroupByType: AuditGroupByTypes = AuditGroupByTypes.AssignedTo;
    @Input() auditGoalPercent = 0;
    @Output() onCompleteAuditCases = new EventEmitter<AuditCaseEnhanced>;
    @Output() onSelectNextCase = new EventEmitter<AuditCasesGroup>;

    // Table Data
    displayedColumns = [
        'expand',
        'name',
        'selected',
        'completed',
        'actions',    
    ];
    nameLabel = '';

    currentExpandedGroupName: string = null;

    auditCasesGroupsEnhanced: AuditCasesGroupEnhanced[] = [];

    public get MrsColor() { return MrsColor; }

    //#region Helpers

    updateAuditCasesGroup() {
        this.auditCasesGroupsEnhanced = this.auditCasesGroups.map(auditCasesGroup => {
            
            const groupStats: AuditProgressStatistics = {
                totalCases: auditCasesGroup.totalUnfilteredCases,
                selectedCases: auditCasesGroup.totalSelected,
                completedCases: auditCasesGroup.totalCompleted,
                goalPercent: this.auditGoalPercent,
            };
            const auditCasesGroupEnhanced: AuditCasesGroupEnhanced = {
                ...auditCasesGroup,
                auditStatistics: groupStats,
            };

            return auditCasesGroupEnhanced;
        });
    }

    updateAuditGroupTypeLabel() {
        const displayGroupByType = AuditGroupByDisplayTypes.find(x => x.apiValue === this.auditGroupByType);
        this.nameLabel = displayGroupByType ? displayGroupByType.displayName : "Name";
    }
    
    //#endregion
    //#region Lifecycle

    ngOnChanges(changes: SimpleChanges) {
        if (changes.auditCasesGroups) {
            this.updateAuditCasesGroup();
        }
        if (changes.auditGroupByType) {
            this.updateAuditGroupTypeLabel();
        }
    }

    //#endregion
    //#region Handlers

    onCompleteCaseAudit(auditCase: AuditCaseEnhanced) {
        this.onCompleteAuditCases.emit(auditCase);
    }

    selectNextCase(group: AuditCasesGroup) {
        this.onSelectNextCase.emit(group);
    }

    //#endregion
}
