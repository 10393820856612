enum SystemEventAction {
    Email = '00000000-0000-0000-0000-000000000001',
    DisplayMessage = '00000000-0000-0000-0000-000000000002',
    Integration = '00000000-0000-0000-0000-000000000003',
    MarkCaseDone = '00000000-0000-0000-0000-000000000004',
    SendSMS = '00000000-0000-0000-0000-000000000005',
    Webhook = '00000000-0000-0000-0000-000000000006',
    Fax = '00000000-0000-0000-0000-000000000007',
    ProcessInterview = '00000000-0000-0000-0000-000000000008',
    AcordEvent = '00000000-0000-0000-0000-000000000009',
    StatusChange = '00000000-0000-0000-0000-000000000010',
    ProcessInterviewAccumulateErrors = '00000000-0000-0000-0000-000000000011',
    GTLSubmission = '00000000-0000-0000-0000-000000000012',
    EvaluateCaseManager = '00000000-0000-0000-0000-000000000013',
    LongLivedIntegrationStatusUpdate = '00000000-0000-0000-0000-000000000014',
    LongLivedEvidenceReceived = '00000000-0000-0000-0000-000000000015',
    InitializeWorkbenchRequirements = '00000000-0000-0000-0000-000000000016',
    StandardIntegrationStatusChange = '00000000-0000-0000-0000-000000000017',
    EvaluateUwwRequirementRules = '00000000-0000-0000-0000-000000000018',
    ArcusSubmission = '00000000-0000-0000-0000-000000000019',
}

enum SystemEventActionType {
    Email = 0,
    SMS = 1,
    Push = 3,
    Fax = 4
}

export { SystemEventAction };
export { SystemEventActionType };
