import { KeyValueObj } from '@Models';
import moment = require('moment');

import { Utils } from './';
import _ = require('lodash');

export type DateStringOrMoment = string | Date | moment.Moment;

export function convertToMomentCst(timestamp: DateStringOrMoment): moment.Moment {
    if (moment.isMoment(timestamp)) return timestamp as moment.Moment;
    if (Utils.isNullOrWhitespace(timestamp as string)) return null;

    return moment.utc(timestamp).tz('America/Chicago');
}

export function convertToDateCst(timestamp: DateStringOrMoment): Date {
    return convertToMomentCst(timestamp)?.toDate();
}

export function isTomorrow(mDate: moment.Moment, unit: moment.unitOfTime.DurationConstructor = 'day'): boolean {
    if (!mDate) return false;

    return mDate.isSame(moment().add(1, unit), unit);
}

export function isToday(mDate: moment.Moment, unit: moment.unitOfTime.DurationConstructor = 'day'): boolean {
    if (!mDate) return false;

    return mDate.isSame(moment(), unit);
}

export function isYesterday(mDate: moment.Moment, unit: moment.unitOfTime.DurationConstructor = 'day'): boolean {
    if (!mDate) return false;

    return mDate.isSame(moment().subtract(1, unit), unit);
}

export function isPast(mDate: moment.Moment, unit: moment.unitOfTime.DurationConstructor = 'day'): boolean {
    if (!mDate) return false;

    return mDate.startOf(unit).diff(moment().startOf(unit), unit) < 0;
}

export function isFuture(mDate: moment.Moment, unit: moment.unitOfTime.DurationConstructor = 'day'): boolean {
    if (!mDate) return false;

    return mDate.startOf(unit).diff(moment().startOf(unit), unit) > 0;
}

export function formatDay(date: string | Date | moment.Moment, format = 'MMMM Do, YYYY'): string {
    if (!date) return '';

    const mDate = moment.isMoment(date) ? date : convertToMomentCst(date);

    if (!mDate) return '';
    if (isTomorrow(mDate)) return 'Tomorrow';
    if (isToday(mDate)) return 'Today';
    if (isYesterday(mDate)) return 'Yesterday';

    return mDate.format(format);
}

export function formatTime(date: string | moment.Moment, format = 'h:mm a z'): string {
    if (!date) return '';

    const mDate = moment.isMoment(date) ? date : convertToMomentCst(date);

    if (!mDate) return '';
    return mDate.format(format);
}

export function getNextNthDate(n: number): string {
    const currentDate = new Date();
    const nextDate = new Date(currentDate);

    nextDate.setDate(nextDate.getDate() + n);

    const dd = nextDate.getDate();
    const mm = nextDate.getMonth() + 1;
    const y = nextDate.getFullYear();

    if (nextDate.getDay() !== 0 && nextDate.getDay() !== 6) {
        return mm + '/' + dd + '/' + y;
    }

    return '';
}

export function getNextNDates(numberOfDays = 10): KeyValueObj[] {
    const nextNDates: KeyValueObj[] = [];
    let noOfDays = 0;
    let index = 1;

    while (noOfDays < numberOfDays) {
        const nextNthDate = this.getNextNthDate(index);

        if (nextNthDate) {
            nextNDates.push({
                key: nextNthDate,
                value: `${nextNthDate} (${index} day${(index === 1 ? '' : 's')})`
            });
            noOfDays += 1;
        }

        index += 1;
    }

    return nextNDates;
}

export function toStartOfDay(date: DateStringOrMoment): Date {
    const mDate = convertToMomentCst(date);
    const startOfDay = mDate?.startOf('day');
    return startOfDay?.toDate();
}

export function toEndOfDay(date: DateStringOrMoment): Date {
    const mDate = convertToMomentCst(date);
    const endOfDay = mDate?.endOf('day');
    return endOfDay?.toDate();
}

export function minutesToMilliseconds(minutes: number): number {
    return minutes * 60 * 1000;
}

export function hoursToMilliseconds(hours: number): number {
    return hours * 60 * 60 * 1000;
}

export function areDatesLessThanAYear(date1: DateStringOrMoment, date2: DateStringOrMoment): boolean {
    const mDate1 = convertToMomentCst(date1);
    const mDate2 = convertToMomentCst(date2);

    // Calculate the difference in years
    const differenceInYears = Math.abs(mDate1.diff(mDate2, 'years', true));

    // Check if the difference is less than 1 year
    return differenceInYears < 1;
}

/**
 * Verifies if the inner start/end date range is within the outer start/end date range.
 * @param rangeAStart the start date that should be inside of the outer date
 * @param rangeAEnd the end date that should be inside the outer date
 * @param rangeBStart the start date constraint that the inner date should be within
 * @param rangeBEnd the end date constraint that the inner date should be within
 * @returns true if the inner date range is within or equal to the outer date range.
 */
export function isRangeSameOrWithin(rangeAStart: DateStringOrMoment, rangeAEnd: DateStringOrMoment, rangeBStart: DateStringOrMoment, rangeBEnd: DateStringOrMoment): boolean {
    const mRangeAStart = convertToMomentCst(rangeAStart);
    const mRangeAEnd = convertToMomentCst(rangeAEnd);
    const mRangeBStart = convertToMomentCst(rangeBStart);
    const mRangeBEnd = convertToMomentCst(rangeBEnd);

    const isRangeSameOrAfter = mRangeAStart.isSameOrAfter(mRangeBStart) && mRangeAEnd.isSameOrBefore(mRangeBEnd);

    return isRangeSameOrAfter;
}

export function isSameDay(date1: Date, date2: Date) {
    const sameYear = date1?.getFullYear() === date2?.getFullYear();
    const sameMonth = date1?.getMonth() === date2?.getMonth();
    const sameDay = date1?.getDate() === date2?.getDate();
    return sameYear && sameMonth && sameDay;
}

export function getStartOfMonth(date: DateStringOrMoment = null): Date {
    const safeDate = date ?? new Date();
    const mDate = convertToMomentCst(safeDate);
    const startOfMonth = mDate?.startOf('month');
    return startOfMonth?.toDate();
}

export function getEndOfMonth(date: DateStringOrMoment = null): Date {
    const safeDate = date ?? new Date();
    const mDate = convertToMomentCst(safeDate);
    const startOfMonth = mDate?.endOf('month');
    return startOfMonth?.toDate();
}
