import { Component, OnInit, Input, ViewChildren, QueryList } from '@angular/core';
import { AppService } from '@App';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { ClientAccountSetting, MyCaseSetting } from '@Models';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { AccountSettingsService } from '@Services';
import { CaseStatus } from '@Enums';
import { Account } from '@Models';
import { CaseStatusDTO } from '@DTOs';

@Component({
    selector: 'my-cases-settings',
    host: { 'class': 'd-flex flex-row flex-fill' },
    templateUrl: './my-cases-settings.component.html'
})
export class MyCasesSettingsComponent implements OnInit {
    @Input() accountInfo: Account;
    @ViewChildren(MatTable) table !: QueryList<MatTable<string>>;

    myCaseSettings: any;
    configData: any;
    showForm = false;
    myCaseSettingsForm: UntypedFormGroup;
    settings: any;
    caseStatuses: CaseStatusDTO[];
    selectedDaysBack: any;
    selectedCaseStatuses: [];
    selectedCaseStatusNames: any;
    displayedColumns = ['showColumn', 'selectedCaseStatus', 'casesMsgColumn', 'selectedDaysBack', 'messages', 'actions'];
    dataSource: MatTableDataSource<any>;
    formSubmitted = false;

    constructor(
        private appService: AppService,
        private accountSettingService: AccountSettingsService,
        private _fb: UntypedFormBuilder
    ) {
    }

    ngOnInit() {
        this.myCaseSettingsForm = this._fb.group(
            {
                myCaseSettings: this._fb.array([])
            });

        this.appService.getAllConfigData().then((data) => {
            this.caseStatuses = data.caseStatus;
            this.getMyCaseSettings();
        });
    }

    getMyCaseSettings() {
        this.appService.getData(`AccountSettings/${this.accountInfo.id}`).subscribe(
            response => {
                if (response.status === 'success') {
                    this.settings = response.data;
                    this.setFormValues();
                }
            });
    }

    setFormValues() {
        const setting = _.find(this.settings, { 'name': 'myCaseSettings' });

        if (setting) {
            const myCaseSettings: MyCaseSetting[] = JSON.parse(setting.value.toString());
            const myCaseSettingsFormArray = new UntypedFormArray([]);
            myCaseSettings.forEach(v => {
                const myCaseSetting = this.casesStatusesAsFormGroup(v);
                myCaseSettingsFormArray.push(myCaseSetting);
            });

            this.myCaseSettingsForm.setControl('myCaseSettings', myCaseSettingsFormArray);
            this.setDataSource();
        } else {
            this.addFilter();
        }
    }

    setDataSource() {
        this.dataSource = new MatTableDataSource((this.myCaseSettingsForm.get('myCaseSettings') as UntypedFormArray).controls);

        this.table.first.dataSource = this.dataSource;
        this.table.first.renderRows();
    }

    casesStatusesAsFormGroup(myCaseSetting: MyCaseSetting): UntypedFormGroup {
        const fg = new UntypedFormGroup({
            selectedCaseStatus: new UntypedFormControl(myCaseSetting.selectedCaseStatus, Validators.required),
            selectedDaysBack: new UntypedFormControl(myCaseSetting.selectedDaysBack, Validators.required)
        });
        return fg;
    }

    save() {
        this.formSubmitted = true;
        if (this.myCaseSettingsForm.valid) {
            const filters = this.myCaseSettingsForm.get('myCaseSettings').value;

            const item: ClientAccountSetting = {
                clientId: this.accountInfo.clientId,
                accountId: this.accountInfo.id,
                name: 'myCaseSettings',
                value: JSON.stringify(filters)
            };

            const setting = this.settings.find(s => s.name === 'myCaseSettings');

            if (setting) { item['id'] = setting.id; }

            this.accountSettingService.saveAccountSetting(this.accountInfo, item)
                .subscribe(
                    () => {
                        this.getMyCaseSettings();
                        this.appService.showMsg('success', 'Saved successfully ...');
                        this.formSubmitted = false;
                    });
        } else {
            this.appService.showMsg('error', 'Plese fill in all the required fields...');
        }
    }

    addFilter() {
        const formArray = this.myCaseSettingsForm.get('myCaseSettings') as UntypedFormArray;

        formArray.push(new UntypedFormGroup({
            selectedCaseStatus: new UntypedFormControl('', Validators.required),
            selectedDaysBack: new UntypedFormControl('', Validators.required)
        }));

        this.setDataSource();
    }

    deleteFilter(element) {
        const index = this.dataSource.data.indexOf(element, 0);
        if (index > -1) {
            const formArray = this.myCaseSettingsForm.get('myCaseSettings') as UntypedFormArray;
            formArray.removeAt(index);
        }

        this.setDataSource();
    }

    statusUsed(statusId) {
        let result = false;
        const formArray = this.myCaseSettingsForm.get('myCaseSettings') as UntypedFormArray;

        formArray.controls.forEach(x => {
            const statusControl = x.get('selectedCaseStatus');
            if (statusControl.value === statusId) {
                result = true;
            }
        });

        return result;
    }

    errorHandling = (control: string, error: string, element: any) => {
        const index = this.dataSource.data.indexOf(element, 0);
        const formArray = this.myCaseSettingsForm.get('myCaseSettings') as UntypedFormArray;
        const formControl = formArray.controls[index];
        return formControl.get(control).hasError(error);
    };

    getCaseStatusMessage(element: UntypedFormGroup) {
        const caseStatusControl = element.controls['selectedCaseStatus'];

        if (caseStatusControl && caseStatusControl.value !== '') {
            if (caseStatusControl.value === CaseStatus.Done) {
                return 'days since the case was completed';
            } else {
                return 'days since the case was created';
            }
        } else {
            return '';
        }
    }
}
