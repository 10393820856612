import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { IConfigService } from "app/config/iconfigservice";
import { AccountSettingsService, BaseService, FeatureManagerService } from "app/services";
import { AppService } from 'app/app.service';
import { ReplaySubject, forkJoin } from "rxjs";
import { FeatureToggle } from "app/enums";
import AccountSettings from "app/enums/account-settings.enum";
import { Account } from "app/models";
import { SubSink } from "subsink";

export type UWWorkbenchHiddenNoteFeatureSetting = {
    featureToggle: boolean,
    accountSetting: boolean
}

@Injectable()
export default class UWNotesFeatureToggleDataService extends BaseService {

    hiddenNotesFeatureSettingSub = new ReplaySubject<UWWorkbenchHiddenNoteFeatureSetting>(1);
    hiddenNotesFeatureSetting$$ = this.hiddenNotesFeatureSettingSub.asObservable();
    hiddenNotesFeatureSettingLoaded = false;
    forceReloadHiddenNotesFeatureSettings = false;

    private subs = new SubSink();

    constructor(
        public configService: IConfigService,
        public httpClient: HttpClient,
        public appService: AppService,
        private featureManagerService: FeatureManagerService,
        private accountSettingService: AccountSettingsService
    ) {
        super(appService, configService, httpClient);
    }

    //#region Helpers

    reloadFeatureSettings(account: Account) {
        this.forceReloadHiddenNotesFeatureSettings = false;
        this.subs.add(forkJoin([
            this.featureManagerService.isEnabled(FeatureToggle.UWWorkbenchHiddenNotesAccountSetting),
            this.accountSettingService.getAccountSetting(account, AccountSettings.UWWorkbenchHiddenNotes)
        ]).subscribe(([hiddenNoteFeatureToggle, hiddenNoteAccountSetting]) => {
            this.hiddenNotesFeatureSettingLoaded = true;
            const featureToggleSetting = hiddenNoteFeatureToggle ?? false;
            const accountSetting = (hiddenNoteAccountSetting) ? (hiddenNoteAccountSetting.value.toLocaleLowerCase() === 'true') : false;
            this.hiddenNotesFeatureSettingSub.next({
                featureToggle: featureToggleSetting,
                accountSetting: accountSetting
            });
        }));

        return this.hiddenNotesFeatureSetting$$;
    }

    //#endregion

    //#region LifeCycle

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    //#endregion
    loadHiddenNotesFeatureSetting(account: Account) {
        if (!this.forceReloadHiddenNotesFeatureSettings && this.hiddenNotesFeatureSettingLoaded)
            return this.hiddenNotesFeatureSetting$$;

        return this.reloadFeatureSettings(account);
    }

    forceOnNextLoadHiddenNotesFeatureSetting() {
        this.forceReloadHiddenNotesFeatureSettings = true;
    }
}