import { Component, OnInit } from '@angular/core';
import { TableColumnDefinition } from 'app/shared/components/mrs-table/models/table-models';


@Component({
    templateUrl: 'account-mapkey-vmk-help-dialog.component.html',
})
export class AccountMapkeyVmkHelpDialog implements OnInit {
    testMapkeys = [];
    showResult = false;
    testResult: any;
    isModal = false;
    closeText = 'CLOSE';
    basicFunctionsDisplayColumns: TableColumnDefinition[];
    functionsDisplayColumns: TableColumnDefinition[];
    valueFunctionsDisplayColumns: TableColumnDefinition[];
    mathFunctionsDisplayColumns: TableColumnDefinition[];
    functionDataSource: any = [];
    basicFunctionDataSource: any = [];
    mathFunctionDataSource: any = [];
    valueFunctionDataSource: any = [];
    initialSort = "name";
    displayTable = false;

    constructor() { }
    ngOnInit(): void {
        this.setupData();
    }

    setupData() {
        this.basicFunctionsDisplayColumns = [
            {
                def: 'name',
                header: 'Name',
                sortFields: ['name'],
                columnStyles: {
                    width: '200px'
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'name',
                    }
                ]
            },
            {
                def: 'usage',
                header: 'Syntax',
                sortFields: ['usage'],
                columnStyles: {},
                rowDefs: [
                    {
                        className: '',
                        dataName: 'usage',
                    }
                ]
            },
            {
                def: 'description',
                header: 'Description',
                sortFields: ['description'],
                columnStyles: {},
                rowDefs: [
                    {
                        className: '',
                        dataName: 'description',
                    }
                ]
            },
            {
                def: 'action',
                header: '',
                sortFields: [''],
                columnStyles: {},
                rowDefs: [
                    {
                        className: '',
                        dataName: 'action',
                    }
                ]
            }
        ];

        this.valueFunctionsDisplayColumns = [
            {
                def: 'name',
                header: 'Name',
                sortFields: ['name'],
                columnStyles: {
                    width: '200px'
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'name',
                    }
                ]
            },
            {
                def: 'usage',
                header: 'Syntax',
                sortFields: ['usage'],
                columnStyles: {},
                rowDefs: [
                    {
                        className: '',
                        dataName: 'usage',
                    }
                ]
            },
            {
                def: 'description',
                header: 'Description',
                sortFields: ['description'],
                columnStyles: {},
                rowDefs: [
                    {
                        className: '',
                        dataName: 'description',
                    }
                ]
            },
            {
                def: 'action',
                header: '',
                sortFields: [''],
                columnStyles: {},
                rowDefs: [
                    {
                        className: '',
                        dataName: 'action',
                    }
                ]
            }
        ];

        this.basicFunctionDataSource = [
            { name: 'Equals', usage: '==, =', description: 'Returns true if provided values are equal, and false if provided values are not equal.' },
            { name: 'Not Equals', usage: '!=, <>', description: 'Returns true if provided values are not equal, and false if provided values are equal.' },
            { name: 'And', usage: '&&, AND', description: 'Returns true if all of the provided arguments are logically true, and false if any of the provided arguments are logically false.' },
            { name: 'Or', usage: '||, OR', description: 'Returns true if any of the provided arguments are logically true, and false if all of the provided arguments are logically false.' },
            { name: 'If', usage: "If(1 == 1, 'true', 'false')", description: 'Returns one value if a logical expression is `TRUE` and another if it is `FALSE`.' }
        ];

        this.basicFunctionDataSource.sort((a, b) => a.name > b.name ? 1 : -1);

        this.mathFunctionsDisplayColumns = [
            {
                def: 'name',
                header: 'Name',
                sortFields: ['name'],
                columnStyles: {
                    width: '200px'
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'name',
                    }
                ]
            },
            {
                def: 'usage',
                header: 'Syntax',
                sortFields: ['usage'],
                columnStyles: {},
                rowDefs: [
                    {
                        className: '',
                        dataName: 'usage',
                    }
                ]
            },
            {
                def: 'description',
                header: 'Description',
                sortFields: ['description'],
                columnStyles: {},
                rowDefs: [
                    {
                        className: '',
                        dataName: 'description',
                    }
                ]
            },
            {
                def: 'action',
                header: '',
                sortFields: [''],
                columnStyles: {},
                rowDefs: [
                    {
                        className: '',
                        dataName: 'action',
                    }
                ]
            }
        ];

        this.mathFunctionDataSource = [
            { name: 'Sum', usage: 'Sum(0, 2, 3, 4)', description: 'Returns sum of provided values.' },
            { name: 'Average', usage: 'Average(0, 2, 3, 4)', description: 'Returns average of provided values.' }
        ];

        this.mathFunctionDataSource.sort((a, b) => a.name > b.name ? 1 : -1);

        this.valueFunctionDataSource = [
            { name: 'DateTime', usage: '#Today#, #Now#', description: '#Today# will return the current date in yyyy-MM-dd format.<br>#Now# will return the current date and time to the millisecond.' },
            { name: 'Current User Role', usage: '#UserRole#', description: 'Returns the role of the currently logged in user.' }
        ];

        this.valueFunctionDataSource.sort((a, b) => a.name > b.name ? 1 : -1);

        this.functionsDisplayColumns = [
            {
                def: 'name',
                header: 'Name',
                sortFields: ['name'],
                columnStyles: {
                    width: '200px'
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'name',
                    }
                ]
            },
            {
                def: 'usage',
                header: 'Syntax',
                sortFields: ['usage'],
                columnStyles: {},
                rowDefs: [
                    {
                        className: '',
                        dataName: 'usage',
                    }
                ]
            },
            {
                def: 'description',
                header: 'Description',
                sortFields: ['description'],
                columnStyles: {},
                rowDefs: [
                    {
                        className: '',
                        dataName: 'description',
                    }
                ]
            },
            {
                def: 'action',
                header: '',
                sortFields: [''],
                columnStyles: {},
                rowDefs: [
                    {
                        className: '',
                        dataName: 'action',
                    }
                ]
            }
        ];

        this.functionDataSource = [
            { name: 'Replace', usage: "Replace(@Case_mapkey, '-', '')", description: 'Replace all types of one character with another.' },
            { name: 'Trim', usage: "Trim(@Case_mapkey)", description: 'Removes any leading or trailing spaces.' },
            { name: 'IsNullOrEmpty', usage: "IsNullOrEmpty(@Case_mapkey)", description: 'Returns true if the supplied mapkey is null or blank else returns false.' },
            {
                name: 'Timezone and Date Format', usage: 'DateFormat(#Now#, "MM/dd/yyyy hh:mm tt","true", "Central Standard Time")', description: `This function takes 4 parameters. The first is the date/time you want to use (mapkey or function like
            #Now#).<br><br> Next is the format
            you want the output in(See this link for example formats <a href="https://blog.nicholasrogoff.com/2012/05/05/c-datetime-tostring-formats-quick-reference/" target="_blank">Date Time formats</a>).
            The third is whether you want the timezone to be printed, default is false if an empty string is passed in.
            The fourth is the timezone you want the date converted from(Central Standard Time, Eastern Standard Time,
            etc).<br>
            If you do not want the timezone you can use an empty string, ""`},
            {
                name: 'CaseDetail', usage: "CaseDetail('[element]')", description: `Supported elements: <ul><li>AccountName, CaseNonBillable, CaseNumber, ClientName, CompletedByName, CreatedByName,
        CreationDate, LastModifiedBy, Id, InterviewEndDate, InterviewStartDate, LastModifiedDate, PolicyNumber,
        UWLabelName, StatusName</li></ul>`},
            {
                name: 'UserDetail', usage: "UserDetail('[userType]', '[element]', [useCreatedBy: optional])", description:
                    `<div>Supported user types: <ul><li>CreatedBy, CompletedBy, LastModifiedBy, Current, AssignedTo</li><ul></div>
        <div>Supported elements: <ul><li>FirstName, LastName, Email, Role, UserName</li><ul></div>
        <div>The third parameter is optional and defaults to true.  If you do not want to use the created by user as the default AssignedTo user provide a false value for this parameter.</div>`},
            {
                name: 'CurrentUserProfile', usage: "CurrentUserProfile('[parameterName]', '[true/false]')", description: `If the url for a user contains query parameters (?agentId=12345) those can be accessed using a VMK.
        Ensure to keep the query parameters lowercase or it might not work as expected.

        Value will use previously set value if true and it will always replace with current if false or left null`},
            {
                name: 'NoteExists', usage: "NoteExists('[noteType]', '[true/false]')", description: `Supported note types: <ul><li>Journal Entry, Case Update, Case Referral, Pending Info, Contact, Audit, Status
        Change, Interpreter</li></ul>
        Value will return as true if follow up date is the same as or prior to today’s date`},
            { name: 'LastModifiedDateTime', usage: 'GetLastModifiedDateTime("[MapKey]")', description: 'Gets the last modifed date time of a MapKey(Do not include the "@" symbol).' },
            {
                name: 'CaseElementUpdate', usage: "CaseElementUpdate('[element]', [Mapkey])", description: `Supported elements: <ul><li>PolicyNumber, TransmissionMessage, TransmitDateTime, ReceivedDateTime,
        ProcessedDateTime, EnvironmentMode, CreatedBy, LastModifiedBy, LastModifiedDate, CreationDate,
        InterviewStartDate, InterviewEndDate, DoneDate, CompletedBy, TransmitStartDateTime</li></ul>`},
            { name: 'AddDays', usage: "AddDays(#2017-01-30#, 2)", description: 'Returns the supplied date with the specified number of days added.' },
            { name: 'DaysBetween', usage: "DaysBetween(#2017-01-01#, #2017-01-30#)", description: 'Returns the number of days between the specified 2 dates.' },
            { name: 'DayOf', usage: "DayOf(#2017-01-30#)", description: 'Returns the day component of the supplied date.' },
            { name: 'AddMonths', usage: "AddMonths(#2017-01-30#, 2)", description: 'Returns the supplied date with the specified number of months added.' },
            { name: 'AddYears', usage: "AddYears(#2017-01-30#, 2)", description: 'Returns the supplied date with the specified number of years added.' },
            { name: 'Substring', usage: 'Substring("Testing", 0, 4)', description: 'Returns a subset of the string from the start index (0) to the given length (4)' },
            { name: 'ToUpper', usage: 'ToUpper("testing")', description: 'Returns the input string in all uppercase' },
            { name: 'ToLower', usage: 'ToLower("TESTING")', description: 'Returns the input string in all lowercase' },
            { name: 'SumList', usage: 'SumList("1|2|3")', description: 'Returns the sum of a list of delimited values.  The delimiter <b>must</b> be a |.' },
            { name: 'DecimalFormat', usage: 'DecimalFormat("4.1", "{0:0.00}")', description: 'Returns the input string in the specified decimal format' }
        ];

        this.functionDataSource.sort((a, b) => a.name > b.name ? 1 : -1);

        this.displayTable = true;
    }
}
