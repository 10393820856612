<div class="w-100" [formGroup]="tagFormGroup">
    <mat-form-field class="w-100 chip-input" appearance="fill">        
        <mat-label>Tags</mat-label>
        <mat-chip-grid #chipList>
            <mat-chip-row
                *ngFor="let tag of tags"
                [selectable]="true"
                [removable]="true"
                (removed)="tagRemove(tag)">
                {{ tag }}
                <button matChipRemove [attr.aria-label]="'remove ' + tag">
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-chip-row>
            <input
                #tagInput
                formControlName="tagInputCtrl"
                [matAutocomplete]="auto"
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="true"
                (matChipInputTokenEnd)="tagAdd($event)">
        </mat-chip-grid>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="tagSelected($event)">
            <mat-option *ngFor="let tag of filteredTagSuggestions | async" [value]="tag">{{ tag }}</mat-option>
        </mat-autocomplete>
    </mat-form-field>
</div>
