<h1 mat-dialog-title style="margin-bottom: 0 !important;">Preparing Documents
    <div class="dialog-title-subtext">This may take a minute.  Please do not refresh your browser.</div>
</h1>
<div mat-dialog-content class="flex">
    <div class="flex flex-column">
        <div class="flex flex-column">
            <div class="flex flex-row status-row-height flex-align-center" style="margin-bottom: -.75rem">
                <div *ngIf="generatingDocuments" class="mr-2 flex flex-center">
                    <mat-spinner style="margin-left: 1px; margin-right: 3px" diameter="16"></mat-spinner>
                </div>
                <div *ngIf="!generatingDocuments" class="mr-2 flex flex-center">
                    <mat-icon svgIcon="check-circle" style="width: 20px" class="status-check-mark"></mat-icon>
                </div>
                <div class="flex flex-column" >
                    <div>Generating documents</div>
                </div>
            </div>
            <div class="flex">
                <div style="margin-right: 1.8rem"></div>
                <div class="small-text" *ngIf="totalNumberOfDocuments === 0" style="min-height: 24px;"></div>
                <div class="small-text" *ngIf="totalNumberOfDocuments > 0" >{{numberOfDocumentsComplete}} of {{totalNumberOfDocuments}} complete</div>
            </div>
        </div>
        <div class="flex flex-row mt-2 status-row-height flex-align-center" style="margin-bottom: 12px">
            <div *ngIf="!generatingDocumentsDone"class="mr-2 flex flex-center">
                <mat-icon style="color: #C4C4C4; width: 20px" svgIcon="circle-outline"></mat-icon>
            </div>
            <div *ngIf="generatingDocumentsDone && packagingDocuments" class="mr-2 flex flex-center">
                <mat-spinner style="margin-left: 1px; margin-right: 3px" diameter="16"></mat-spinner>
            </div>
            <div *ngIf="!packagingDocuments"class="mr-2 flex flex-center">
                <mat-icon svgIcon="check-circle" style="width: 20px" class="status-check-mark"></mat-icon>
            </div>
            <div>Packaging documents</div>
        </div>
        <div class="flex flex-row mt-2 status-row-height flex-align-center">
            <div *ngIf="!packagingDocumentsDone"class="mr-2 flex flex-center">
                <mat-icon style="color: #C4C4C4; width: 20px" svgIcon="circle-outline"></mat-icon>
            </div>
            <div *ngIf="packagingDocumentsDone"class="mr-2 flex flex-center">
                <mat-spinner style="margin-left: 1px; margin-right: 3px" diameter="16"></mat-spinner>
            </div>
            <div>Preparing for signatures</div>
        </div>
    </div>
</div>
<div mat-dialog-actions align="end">
    <mrs-btn-tertiary buttonLabel="Close" [textColor]="'#2C6EED'" buttonId="cancelButton" (onClick)="cancel()"></mrs-btn-tertiary>
</div>
