import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AppService } from '../../../../../app.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AccountEvent, AccountMessage } from '../../../../../models';
import { AccountEventsService, FeatureManagerService } from '../../../../../services';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { AttachDocumentsComponent } from './attach-documents/attach-documents.component';
import { DocumentsDTO } from '@DTOs';
import { FeatureToggle } from '@Enums';

@Component({
    selector: 'account-event-action',
    templateUrl: './event-action-email.component.html',
    styleUrls: ['./event-action-email.component.scss']
})
export class EventEmailActionComponent implements OnInit {

    public messageForm: UntypedFormGroup;
    template: any;
    messageId: any;
    emailBodyRowCount = 10;
    showAttachDocumentsControls: boolean = false;
    documentOptions: DocumentsDTO[];
    attachedDocuments: DocumentsDTO[] = [];
    attachDocumentsRef: MatDialogRef<AttachDocumentsComponent>;
    formSubmitted = false;
    enableHTMLMode = false;
    editorConfig: AngularEditorConfig = {
        editable: true,
        spellcheck: true,
        height: '225px',
        minHeight: '0',
        maxHeight: 'auto',
        width: 'auto',
        minWidth: '0',
        translate: 'yes',
        enableToolbar: true,
        showToolbar: true,
        sanitize: false,
        toolbarHiddenButtons:[
            ['insertImage',
                'insertVideo',
                'textColor',
                'backgroundColor',
                'customClasses',
                'link',
                'unlink',
                'insertHorizontalRule',
                'removeFormat',]
        ]
    };
    public bodyText:string;

    constructor(
        private appService: AppService,
        private accountEventsService: AccountEventsService,
        private _fb: UntypedFormBuilder,
        private featureManagerService: FeatureManagerService,
        public dialogRef: MatDialogRef<EventEmailActionComponent>,
        public dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: {
            accountId: string,
            accountEvent: AccountEvent,
            clientId: string
            }
        ) {}

    ngOnInit() {
        this.featureManagerService.isEnabled(FeatureToggle.CaseWorkflowAttachDocumentsToConfigureEmailEvent, this.data.clientId).subscribe(showAttachDocumentControls => {
            this.showAttachDocumentsControls = showAttachDocumentControls;
            if (showAttachDocumentControls) {
                this.emailBodyRowCount = 7;
                this.getAttachmentOptions();
            }
        });

        const defaultFromEmailAddress = this.appService.getConfigData('defaultFromEmailAddress');

        this.messageForm = this._fb.group({
            from:[defaultFromEmailAddress, Validators.email],
            to: ['', Validators.required],
            subject: ['', Validators.required],
            content: [''],
            htmlContent: ['', Validators.required],
            textContent:['', Validators.required],
            isHtmlMessage: [false]
        });

        if (this.data.accountEvent.configuration) {
            const configuration = JSON.parse(this.data.accountEvent.configuration);
            this.messageId = configuration['messageId'];

            this.accountEventsService.getAccountEventMessage(this.data.accountEvent.accountId, this.messageId).subscribe(res => {
                this.template = JSON.parse(res.template);

                this.messageForm.patchValue({
                    from:this.template['from'],
                    to: this.template['to'],
                    subject: this.template['subject'],
                    htmlContent: this.template['content'],
                    textContent: this.template['content'],
                    isHtmlMessage:this.template['isHtmlMessage']
                });
            });
        } else if (this.data.accountEvent.accountMessage) {
            this.template = JSON.parse(this.data.accountEvent.accountMessage.template);
            this.messageForm.patchValue({
                from:this.template['from'],
                to: this.template['to'],
                subject: this.template['subject'],
                htmlContent: this.template['content'],
                textContent: this.template['content'],
                isHtmlMessage:this.template['isHtmlMessage']
            });
        }
        this.appendDomainToFrom();
    }

    toggleHtmlMessage(event){
        if(event.checked){
            this.messageForm.get('htmlContent').setValue(this.messageForm.get('textContent').value);
        }else{
            this.messageForm.get('textContent').setValue(this.messageForm.get('htmlContent').value);
        }
    }

    appendDomainToFrom(){
        const currentFrom = this.messageForm.get('from').value;
        if(currentFrom.indexOf('@agenium.ai') < 0)
            this.messageForm.controls['from'].setValue(currentFrom + '@agenium.ai');
    }

    removeDocument(document: DocumentsDTO) {
        const indexToRemove = this.attachedDocuments.indexOf(document);
        this.attachedDocuments.splice(indexToRemove, 1);
    }

    openAttachDocumentsModal(){
        this.attachDocumentsRef = this.dialog.open(AttachDocumentsComponent, {
            width: '40%',
            data: {
                accountId: this.data.accountId,
                documentOptions: this.documentOptions,
                attachedDocuments: this.attachedDocuments
            },
        });
    }

    getAttachmentOptions() {
        this.appService.getData(`Documents/${this.data.accountId}`).subscribe(
            result => {
                if (result.status === 'success') {
                    this.documentOptions = result.data?.filter(x => x.generateTo === "00000000-0000-0000-0000-000000000001"); // Guid for PDF
                    if ((this.template) && (this.template['documentIds'])) {
                        const documentIds = this.template['documentIds'];
                        this.attachedDocuments = this.documentOptions.filter(x => documentIds.includes(x.id));
                    }
                }
            }
        );
    }

    saveAccountMessage() {
        this.appendDomainToFrom();
        const message = this.messageForm.value;
        this.formSubmitted = true;
        let content = '';
        if(this.messageForm.get('isHtmlMessage').value){
            content = this.messageForm.get('htmlContent').value;
            this.messageForm.get('textContent').setValue(this.messageForm.get('htmlContent').value);
        } else {
            content = this.messageForm.get('textContent').value;
            this.messageForm.get('htmlContent').setValue(this.messageForm.get('textContent').value);
        }

        const newMessage = {
            from:message.from,
            to: message.to,
            subject: message.subject,
            content: content,
            isHtmlMessage: message.isHtmlMessage,
            documentIds: this.attachedDocuments.map(d => d.id)
        };
        if (this.messageForm.valid) {
            if (this.data.accountEvent.accountMessage) {
                this.data.accountEvent.accountMessage.template = JSON.stringify(newMessage);
            } else {
                const accountMessage: AccountMessage = {
                    id: this.messageId,
                    template: JSON.stringify(newMessage)
                };
                this.data.accountEvent.accountMessage = accountMessage;
            }

            this.dialogRef.close();
        } else {
            this.messageForm.markAsTouched();
            this.messageForm.controls.from.markAllAsTouched();
            this.messageForm.controls.to.markAsTouched();
            this.messageForm.controls.subject.markAsTouched();
            this.messageForm.controls.content.markAsTouched();
        }
    }

}
