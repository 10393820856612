import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import * as _ from 'lodash';
import { saveAs } from 'file-saver';

import { AppService } from '../../app.service';
import { IConfigService } from '../../config/iconfigservice';
import { ReportDownloadService } from '../reportdownload.service';

@Injectable()
export class CaseImportExportService {

    publicApiBaseUrl: string;

    constructor(private appService: AppService, private configService: IConfigService, private httpClient: HttpClient, private reportDownloadService: ReportDownloadService) {
        this.publicApiBaseUrl = this.configService.getConfiguration().publicApiUrl;
    }

    exportCase(clientId, caseId) {
        this.appService.display(true);
        const publicApiBaseUrl = this.configService.getConfiguration().publicApiUrl;
        const url = publicApiBaseUrl + `client/${clientId}/ExportCase?caseNumberDisplay=${caseId}`;

        const httpPost = this.httpClient.get(url, { headers: this.appService.getHeaders(), responseType: 'blob', observe: 'response' });

        httpPost.subscribe(
            {
                next: (response: HttpResponse<Blob>) => {
                    this.appService.display(false);

                    saveAs(response.body, `${caseId}.json`);

                    this.appService.showMsg('success', 'Case exported successfully... ');
                },
                error: (error) => {
                    this.appService.display(false);
                    this.appService.showResponseErrorMsg(error);
                }
            });
    }

    importCase(clientId, fileData) {
        this.appService.display(true);

        const url = `${this.configService.getConfiguration().publicApiUrl}client/${clientId}/ImportCase`;
        this.httpClient.post(url, fileData, {
            headers: this.appService.getHeaders()
        }).subscribe(
            {
                next: (response) => {
                    let data: any;
                    if (response['_body']) {
                        data = JSON.parse(response['_body']);
                    } else {
                        data = response;
                    }
                    if (data === true) {
                        fileData = undefined;
                        this.appService.showMsg('success', 'Case imported successfully... ');
                    } else {
                        this.appService.showMsg('error', data.message);
                    }
                },
                error: (error) => {
                    this.appService.showResponseErrorMsg(error);
                    this.appService.display(false);
                },
                complete: () => {
                    this.appService.display(false);
                }
            });
    }

    getCaseNumbersForExport(clientId, accountId) {
        this.appService.display(true);
        const publicApiBaseUrl = this.configService.getConfiguration().publicApiUrl;
        const url = publicApiBaseUrl + `client/${clientId}/GetCaseNumbers/${accountId}`;

        return this.httpClient.get(url, {
            headers: this.appService.getHeaders(clientId)
        }).pipe(map((resData) => {
            this.appService.display(false);
            const res: any = resData;
            if (res) {
                return res;
            }
        }),
        catchError((error: any) => {
            this.appService.display(false);
            this.appService.showResponseErrorMsg(error);
            return throwError(() => new Error(error.message));
        }));
    }
}
