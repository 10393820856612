<div>
    <div class="page-heading case-summary-header flex flex-row">
        <div><b>{{ case?.clientName }} - {{ case?.accountName }}</b> | # {{ case?.caseNumber }}</div>
        <div style="flex: 1"></div>
        <div style="text-align: right">
            <span class="fa-stack fa-sm" style="cursor: pointer;"
                *ngIf="displayMessages && displayMessages.messages.length > 0" (click)="viewMessages(true)">
                <em class="fas fa-bell fa-stack-2x" style="color: red"></em>
                <span class="fa-stack-1x fa-inverse fa-stack-text">{{displayMessages.messages.length}}</span>
            </span>
        </div>
    </div>

    <div *ngIf="!sidebarFlagLoaded">
        <mat-spinner mode="indeterminate" diameter="30"></mat-spinner>
    </div>

    <!-- "new" Case Summary -->
    <div *ngIf="sidebarFlagLoaded && sidebarCaseSummaryEnabled" class="larger-container">
        <!-- Title and Warnings -->
        <div>
            <div style="display:flex; flex-direction: row">
                <!--Sidebar Case Summary-->
                <div id="caseSummarySidebar" *ngIf="case?.statusId && sidebarCaseSummaryEnabled" class="case-summary-sidebar">
                    <h1 class="sidebar-case-summary-header" *ngIf="sidebarCaseSummaryEnabled">Case Details</h1>
                    <button type="button" class="btn btn-link-cancel" *ngIf="canResumeCase" (click)="restartInterview()" [disabled]="disableRestartInterview">
                        <mat-icon svgIcon="file-document-edit-outline" style="margin-bottom: -6px;"></mat-icon>
                        {{restartText}}
                    </button>
                    <div *ngIf="!viewStatusEdit">
                        <button *ngIf="canChangeCaseStatus && !isCaseProcessing && canViewDetails && checkACL('W')" type="button" class="btn btn-link-cancel" style="padding-left:0px;" (click)="viewStatusEdit = true">
                            <mat-icon svgIcon="square-edit-outline" style="margin-bottom: -6px;"></mat-icon>
                            Change Case Status
                        </button>
                    </div>
                    <div *ngIf="viewStatusEdit">
                        <mat-form-field class="w-80 case-status-select" style="padding-left:6px">
                            <mat-select (selectionChange)="changeStatus($event)" [ngModel]="case?.statusId">
                                <mat-option *ngFor="let status of availableCaseStatuses" [value]="status.id">
                                    {{status.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div>
                            <button type="button" class="btn btn-link btn-sm custom" (click)="changeCaseStatus()">
                                <em class="fa fa-check"></em> Save
                            </button>
                            <button type="button" class="btn btn-link btn-sm custom" (click)="viewStatusEdit = false">
                                <em class="fa fa-close"></em> Cancel
                            </button>
                        </div>
                    </div>
                    <button *ngIf="canViewDetails && hasViewPermission('DownloadCaseXML')" type="button"
                        class="btn btn-link-cancel " (click)="downloadCaseXml()">
                        <mat-icon>download</mat-icon>
                        Download Case XML
                    </button>
                    <button *ngIf="canViewDetails && hasViewPermission('DownloadCaseXML')" type="button"
                        class="btn btn-link-cancel" (click)="downloadCaseJson()">
                        <mat-icon>download</mat-icon>
                        Download Case JSON
                    </button>
                    <button *ngIf="hasViewPermission('View Application Documents')" type="button" class="btn btn-link-cancel"
                        (click)="summaryDocumentGenerate(pdfViewerDialog)">
                        <mat-icon svgIcon="note-outline" style="margin-bottom: -6px;"></mat-icon>
                        View Application Documents
                    </button>
                    <div>
                        <h2 class="sidebar-title-ui-red">Summary</h2>
                        <div><b>Status: </b>{{ getStatus(case?.statusId) }}</div>
                        <div><b>Case Number: </b>{{ case?.caseNumber }}</div>
                    </div>
                    <div *ngFor="let item of caseSummarySidebarDisplayItems">
                        <div *ngIf="item.entityHierarchy.toLowerCase() === 'header'" class="sidebar-header-ui-red">{{item.name}}
                        </div>
                        <div *ngIf="item.entityHierarchy.toLowerCase() !== 'header'"><b>{{item.name}}: </b>{{item.mapkeyValue}}
                        </div>
                    </div>
                </div>

                <!-- Case Interview -->
                <div id="caseSummaryDetails" class="p-0 flex-column">
                    <div class="mb-3 p-3 mat-card mat-card--danger" *ngIf="showAgentMessage">{{agentWarningMessage}}</div>
                    <div class="mb-3 p-3 mat-card mat-card--danger" *ngIf="activeCaseUsersFormatted">Current users:
                        {{activeCaseUsersFormatted}}</div>
                    <div class="mb-3 p-3 mat-card mat-card--danger" *ngIf="isCaseProcessing">Case is View-Only until it has
                        Processed</div>
                    <mat-tab-group #tabGroup [selectedIndex]="currentTabIndex" style="padding:2px">
                        <!-- Case Details Tab -->
                        <mat-tab [label]="applicationText" *ngIf="hasViewPermission('Case Summary: Case Details')">
                            <ng-template matTabContent>
                                <div class="outline-shadow">
                                    <app-interview *ngIf="tabGroup.selectedIndex === 0 && interviewLoaded" [readonly]="true" [interviewDTO]="interviewData"></app-interview>
                                </div>
                            </ng-template>
                        </mat-tab>
                        <!-- Notes Tab -->
                        <mat-tab label="Notes" *ngIf="canViewDetails && hasViewPermission('Case Summary: Notes')">
                            <ng-template matTabContent>
                                <notes-list *ngIf="case" [case]="case"></notes-list>
                            </ng-template>
                        </mat-tab>
                        <!-- Documents Tab -->
                        <mat-tab label="Documents" *ngIf="canViewDetails && hasViewPermission('Case Summary: Documents')">
                            <ng-template matTabContent>
                                <case-summary-document [case]="case" [selectedCaseId]="selectedCaseId"
                                    [events]="onTriggerRefreshDocuments"></case-summary-document>
                                <case-summary-document-attachments [case]="case"
                                    [selectedCaseId]="selectedCaseId"></case-summary-document-attachments>
                            </ng-template>
                        </mat-tab>
                        <!-- Underwriting Tab -->
                        <mat-tab label="Underwriting" *ngIf="canViewDetails && uwWorkBenchEnabled && uwWorkBenchPermission">
                            <ng-template matTabContent>
                                <underwriting-tab [baseProps]="baseProps" [myCaseActivity]="myCaseActivity" (onInterviewUpdated)="handleUpdateInterview()"></underwriting-tab>
                            </ng-template>
                        </mat-tab>
                        <!-- UW Decisions Tab -->
                        <mat-tab label="UW Decisions"
                            *ngIf="canViewDetails && hasViewPermission('Case Summary: UW Decisions') && !(uwWorkBenchEnabled && uwWorkBenchPermission)">
                            <ng-template matTabContent>
                                <div *ngIf="!uwDecision?.decisionLabel && case.uwLabelName">
                                    <div class="my-4 mx-5">
                                        <div class="row">
                                            <div class="offset-4 col-4 text-center">
                                                <div class="label-title">UW Decision</div>
                                                <div class="label-contentx h1 text-primary">{{ case.uwLabelName }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="p-4 text-center text-muted" *ngIf="!uwDecision?.decisionLabel && !case.uwLabelName">
                                    <img src="./assets/images/no-data-placeholder.png" alt="" class="pb-3" />
                                    <h6>UW Decision Details Not Found</h6>
                                </div>

                                <div class="my-4 mx-5" *ngIf="uwDecision?.decisionLabel">
                                    <div class="row">
                                        <div class="offset-4 col-4 text-center">
                                            <div class="label-title">UW Decision</div>
                                            <div class="label-contentx h1 text-primary">{{uwDecision?.decisionLabel.uwDecisionLabel}}</div>
                                        </div>
                                    </div>

                                    <div class="row mt-4">
                                        <div class="col-4 text-center" *ngFor="let category of uwDecision?.tags;">
                                            <div class="label-title">{{category?.mapKeyName}} Result</div>
                                            <div class="label-contentx h2 text-info">{{category?.value}}</div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-12 text-center h6 text-uppercase my-3 p-1 bg-light border-top">Matched Keys</div>
                                        <div class="col-4 border-right border-left" *ngFor="let category of uwDecision?.tags;">
                                            <div class="h5 mb-3 text-uppercase text-center text-white bg-success p-1">{{category?.mapKeyName}}
                                            </div>
                                            <div class="result-item" *ngFor="let item of category.matchedMapKeys;">
                                                <div class="label-title" title="{{item?.mapKeyName}}">{{item?.name}}</div>
                                                <div class="label-content">{{item?.value}}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-12 text-center h6 text-uppercase my-3 p-1 bg-light border-top">Other Keys</div>
                                        <div class="col-4 border-right border-left" *ngFor="let category of uwDecision?.tags;">
                                            <div class="h5 mb-3 text-uppercase text-center text-white bg-danger p-1">{{category?.mapKeyName}}
                                            </div>
                                            <div class="result-item" *ngFor="let item of category.relatedMapKeys;">
                                                <div class="label-title" title="{{item?.mapKeyName}}">{{item?.name}}</div>
                                                <div class="label-content">{{item?.value}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </mat-tab>
                        <!-- Call List Tab -->
                        <mat-tab label="CALL LIST" *ngIf="canViewDetails && hasViewPermission('Case Summary: Call List')">
                            <ng-template matTabContent>
                                <case-summary-call-logs [case]="case" [selectedCaseId]="selectedCaseId">
                                </case-summary-call-logs>
                            </ng-template>
                        </mat-tab>
                        <!-- Billing Tab -->
                        <mat-tab label="Billing" *ngIf="canViewDetails && hasViewPermission('Case Summary: Billing')">
                            <ng-template matTabContent>
                                <case-summary-billing [(case)]="case" [selectedCaseId]="selectedCaseId"></case-summary-billing>
                            </ng-template>
                        </mat-tab>
                        <mat-tab label="Metrics" *ngIf="canViewDetails && hasViewPermission('Case Summary: Metrics')">
                            <ng-template matTabContent>
                                <case-metrics-v2 [caseId]="selectedCaseId"></case-metrics-v2>
                            </ng-template>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </div>
        </div>
    </div>

    <!-- "old" Case Summary -->
    <div id="innercontent" *ngIf="sidebarFlagLoaded && !sidebarCaseSummaryEnabled" class="custom-scrollbar">
        <div class="container py-4">
            <div class="mb-3 p-3 mat-card mat-card--danger" *ngIf="showAgentMessage">{{agentWarningMessage}}</div>
            <div class="mb-3 p-3 mat-card mat-card--danger" *ngIf="activeCaseUsersFormatted">Current users: {{activeCaseUsersFormatted}}</div>
            <div class="mb-3 p-3 mat-card mat-card--danger" *ngIf="isCaseProcessing">Case is View-Only until it has Processed</div>
            <div class="bg-white p-0 mat-card">
                <div class="mb-3 border-bottom">
                    <div class="d-flex" *ngIf="case?.statusId">
                        <div class="w-33 border-right pt-3 px-3 pb-0">
                            <display-lv [label]="'Client/Account'" [value]="case?.clientName + ' - ' + case?.accountName" [isUpperCase]="'true'"></display-lv>
                            <display-lv [label]="'Case Number'" [value]="case?.caseNumber" [isUpperCase]="'true'" [attr.data-test-casenumber]="case?.caseNumber"></display-lv>
                            <display-lv [label]="account?.preferredName" [value]="case?.policyNumber" [isUpperCase]="'true'"></display-lv>
                            <display-lv [label]="'Case Created'" [value]="case?.creationDate | date: 'MM/dd/yyyy HH:mm:ss': timeZone" [isUpperCase]="'true'"></display-lv>
                            <display-lv [label]="'Decision'" [value]="case?.uwLabelName" [isUpperCase]="'true'"></display-lv>
                        </div>

                        <div class="w-33 border-right pt-3 px-3 pb-0">
                            <display-lv
                                [label]="'Applicant Information'"
                                [value]="getMapKeysValue('applicant_name') +' ('+ getMapKeysValue('applicant_dob') +' – '+ getMapKeysValue('applicant_gender') +') – '+ getMapKeysValue('applicant_state')"
                                [isUpperCase]="'true'"></display-lv>
                            <display-lv [label]="'Applicant Phone Number'" [value]="getMapKeysValue('applicant_phonenumber')" [isUpperCase]="'true'"></display-lv>
                            <display-lv
                                [label]="'Agent Information'"
                                [value]="getMapKeysValue('agent_name')  +' ('+ getMapKeysValue('agent_code')  +') – '+ getMapKeysValue('agent_phonenumber')"
                                [isUpperCase]="'true'"></display-lv>
                            <display-lv [label]="'Submitted by'" [value]="case?.createdByName" [isUpperCase]="'true'"></display-lv>
                            <display-lv [label]="'Completed by'" [value]="case?.completedByName" [isUpperCase]="'true'" *ngIf="canViewDetails"></display-lv>
                        </div>

                        <div class="w-33 text-center">
                            <div class="label-title text-center text-uppercase pt-3">Case Status</div>
                            <div class="status-icon mt-2" [ngClass]="getStatusColor(case?.statusId)">
                                <em [ngClass]="getStatusIcon(case?.statusId)"></em>
                            </div>
                            <div class="status-text mb-3" [ngClass]="getStatusColor(case?.statusId)">{{ getStatus(case?.statusId) }}
                            </div>
                            <div>
                                <div *ngIf="!viewStatusEdit" class="my-2">
                                    <button *ngIf="canChangeCaseStatus && !isCaseProcessing && canViewDetails && checkACL('W')"
                                        type="button" class="btn btn-link btn-sm custom" (click)="viewStatusEdit = true">
                                        <em class="fa fa-edit"></em> Change Case Status
                                    </button>
                                </div>
                                <div class="my-2" *ngIf="viewStatusEdit">
                                    <mat-form-field class="w-80 case-status-select">
                                        <mat-select (selectionChange)="changeStatus($event)" [ngModel]="case?.statusId">
                                            <mat-option *ngFor="let status of availableCaseStatuses" [value]="status.id">{{ status.name }}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <button type="button" class="btn btn-link btn-sm custom" (click)="changeCaseStatus()"><em class="fa fa-check"></em> Save</button>
                                    <button type="button" class="btn btn-link btn-sm custom" (click)="viewStatusEdit = false"><em class="fa fa-close"></em> Cancel</button>
                                </div>
                            </div>
                            <div class="mb-2">
                                <button type="button" class="btn btn-link btn-sm custom" *ngIf="canResumeCase" (click)="restartInterview()" [disabled]="disableRestartInterview">
                                    <em class="fa-solid fa-file-signature"></em> {{restartText}}
                                </button>
                            </div>
                            <div class="mb-2">
                                <button *ngIf="canViewDetails && hasViewPermission('DownloadCaseXML')" type="button" class="btn btn-link btn-sm custom" (click)="downloadCaseXml()">
                                    <em class="fa fa-download"></em> Download Case XML
                                </button>
                            </div>
                            <div>
                                <button *ngIf="canViewDetails && hasViewPermission('DownloadCaseXML')" type="button" class="btn btn-link btn-sm custom" (click)="downloadCaseJson()">
                                    <em class="fa fa-download"></em> Download Case JSON
                                </button>
                            </div>
                            <div class="mt-2" *ngIf="hasViewPermission('View Application Documents')">
                                <button type="button" class="btn btn-link btn-sm custom"
                                    (click)="summaryDocumentGenerate(pdfViewerDialog)">
                                    <em class="fa fa-file"></em> View Application Documents
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Tabs -->
            <div class="bg-white mt-4 p-0 mat-card">
                <mat-tab-group #tabGroup [selectedIndex]="currentTabIndex">
                    <!-- Case Details Tab -->
                    <mat-tab [label]="applicationText" *ngIf="hasViewPermission('Case Summary: Case Details')">
                        <ng-template matTabContent>
                            <app-interview *ngIf="tabGroup.selectedIndex === 0 && interviewLoaded" [readonly]="true" [interviewDTO]="interviewData"></app-interview>
                        </ng-template>
                    </mat-tab>
                    <!-- Notes Tab -->
                    <mat-tab label="Notes" *ngIf="canViewDetails && hasViewPermission('Case Summary: Notes')">
                        <ng-template matTabContent>
                            <notes-list *ngIf="case" [case]="case"></notes-list>
                        </ng-template>
                    </mat-tab>
                    <!-- Documents Tab -->
                    <mat-tab label="Documents" *ngIf="canViewDetails && hasViewPermission('Case Summary: Documents')">
                        <ng-template matTabContent>
                            <case-summary-document [case]="case" [selectedCaseId]="selectedCaseId"
                                [events]="onTriggerRefreshDocuments"></case-summary-document>
                            <case-summary-document-attachments [case]="case" [selectedCaseId]="selectedCaseId">
                            </case-summary-document-attachments>
                        </ng-template>
                    </mat-tab>
                    <!-- Underwriting Tab -->
                    <mat-tab label="Underwriting" *ngIf="canViewDetails && uwWorkBenchEnabled">
                        <ng-template matTabContent>
                            <underwriting-tab [baseProps]="baseProps" [myCaseActivity]="myCaseActivity" (onInterviewUpdated)="handleUpdateInterview()"></underwriting-tab>
                        </ng-template>
                    </mat-tab>
                    <!-- UW Decissions Tab -->
                    <mat-tab label="UW Decisions"
                        *ngIf="canViewDetails && hasViewPermission('Case Summary: UW Decisions') && !(uwWorkBenchEnabled && uwWorkBenchPermission)">
                        <ng-template matTabContent>
                            <div *ngIf="!uwDecision?.decisionLabel && case.uwLabelName">
                                <div class="my-4 mx-5">
                                    <div class="row">
                                        <div class="offset-4 col-4 text-center">
                                            <div class="label-title">UW Decision</div>
                                            <div class="label-contentx h1 text-primary">{{ case.uwLabelName }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="p-4 text-center text-muted" *ngIf="!uwDecision?.decisionLabel && !case.uwLabelName">
                                <img src="./assets/images/no-data-placeholder.png" alt="" class="pb-3" />
                                <h6>UW Decision Details Not Found</h6>
                            </div>

                            <div class="my-4 mx-5" *ngIf="uwDecision?.decisionLabel">
                                <div class="row">
                                    <div class="offset-4 col-4 text-center">
                                        <div class="label-title">UW Decision</div>
                                        <div class="label-contentx h1 text-primary">{{uwDecision?.decisionLabel.uwDecisionLabel}}</div>
                                    </div>
                                </div>

                                <div class="row mt-4">
                                    <div class="col-4 text-center" *ngFor="let category of uwDecision?.tags;">
                                        <div class="label-title">{{category?.mapKeyName}} Result</div>
                                        <div class="label-contentx h2 text-info">{{category?.value}}</div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12 text-center h6 text-uppercase my-3 p-1 bg-light border-top">Matched Keys</div>
                                    <div class="col-4 border-right border-left" *ngFor="let category of uwDecision?.tags;">
                                        <div class="h5 mb-3 text-uppercase text-center text-white bg-success p-1">{{category?.mapKeyName}}
                                        </div>
                                        <div class="result-item" *ngFor="let item of category.matchedMapKeys;">
                                            <div class="label-title" title="{{item?.mapKeyName}}">{{item?.name}}</div>
                                            <div class="label-content">{{item?.value}}</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12 text-center h6 text-uppercase my-3 p-1 bg-light border-top">Other Keys</div>
                                    <div class="col-4 border-right border-left" *ngFor="let category of uwDecision?.tags;">
                                        <div class="h5 mb-3 text-uppercase text-center text-white bg-danger p-1">{{category?.mapKeyName}}
                                        </div>
                                        <div class="result-item" *ngFor="let item of category.relatedMapKeys;">
                                            <div class="label-title" title="{{item?.mapKeyName}}">{{item?.name}}</div>
                                            <div class="label-content">{{item?.value}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </mat-tab>
                    <!-- Call List Tab -->
                    <mat-tab label="CALL LIST" *ngIf="canViewDetails && hasViewPermission('Case Summary: Call List')">
                        <ng-template matTabContent>
                            <case-summary-call-logs [case]="case" [selectedCaseId]="selectedCaseId"></case-summary-call-logs>
                        </ng-template>
                    </mat-tab>
                    <!-- Billing Tab -->
                    <mat-tab label="Billing" *ngIf="canViewDetails && hasViewPermission('Case Summary: Billing')">
                        <ng-template matTabContent>
                            <case-summary-billing [(case)]="case" [selectedCaseId]="selectedCaseId"></case-summary-billing>
                        </ng-template>
                    </mat-tab>
                    <!-- Metrics Tab -->
                    <mat-tab label="Metrics" *ngIf="canViewDetails && hasViewPermission('Case Summary: Metrics')">
                        <ng-template matTabContent>
                            <case-metrics-v2 [caseId]="selectedCaseId"></case-metrics-v2>
                        </ng-template>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </div>
</div>

<!-- Modal Template -->
<ng-template #pdfViewerDialog let-data>
    <h1 mat-dialog-title class="wrap">{{documentHeader}}</h1>
    <div class="content">
        <div class="loading-indicator" *ngIf="!isLoaded">
            <mat-progress-spinner mode="indeterminate" color="primary"></mat-progress-spinner>
        </div>
        <div class="fillSpace">
            <ngx-extended-pdf-viewer [src]="pdfResult" [showPrintButton]="hasViewPermission" [showDownloadButton]="false"
                [useBrowserLocale]="true" [height]="'100%'" [zoom]="getZoom()" [customToolbar]="multiToolbar">>
            </ngx-extended-pdf-viewer>
            <ng-template #multiToolbar>
                <div id="toolbarViewerLeft">
                    <div class="toolbarButtonSpacer"></div>
                    <pdf-paging-area></pdf-paging-area>
                </div>
                <pdf-zoom-toolbar>
                    <pdf-zoom-out id="zoomOut" />
                    <pdf-zoom-dropdown id="scaleSelect" value="getZoom()" />
                    <pdf-zoom-in id="zoomIn" />
                </pdf-zoom-toolbar>
                <div id="toolbarViewerRight">
                    <pdf-print *ngIf="hasViewPermission('Download Documents and Attachments')"></pdf-print>
                    <!-- <pdf-download *ngIf="hasViewPermission('Download Documents and Attachments')"></pdf-download> -->
                    <div class="verticalToolbarSeparator hiddenSmallView"></div>
                </div>
            </ng-template>
        </div>
    </div>
    <p-footer>
        <mat-dialog-actions align="end">

            <mrs-btn-tertiary textColor="#2C6EED" buttonLabel="CLOSE" buttonId="cancelButton" [mat-dialog-close]
                cdkFocusInitial>CLOSE
            </mrs-btn-tertiary>
        </mat-dialog-actions>
    </p-footer>
</ng-template>