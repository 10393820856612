import {Injectable} from '@angular/core';
import * as moment from 'moment';
import { AuthService } from '@auth0/auth0-angular';
import { AuthLegacyService } from '../../auth/auth-legacy.service';

@Injectable()
export class UtilityService {
    constructor(
        private auth0Service: AuthService,
        private authLegacyService: AuthLegacyService
    ) {}

    // Generic helper function that can be used for the three operations:
    compareTwoArrays = (list1, list2, isUnion = false) =>
        list1.filter(
            (set => a => isUnion === set.has(a.id))(new Set(list2.map(b => b.id)))
        );

    isFloat(val) {
        const floatRegex = /^-?\d+(?:[.,]\d*?)?$/;
        if (!floatRegex.test(val)) {
            return false;
        }

        val = parseFloat(val);
        if (isNaN(val)) {
            return false;
        }

        return true;
    }

    isInt(val) {
        const intRegex = /^-?\d+$/;
        if (!intRegex.test(val)) {
            return false;
        }
        const intVal = parseInt(val, 10);
        return parseFloat(val) === intVal && !isNaN(intVal);
    }

    isSSN(val) {
        const ssnRegex = /^\d{3}-?\d{2}-?\d{4}$/;
        return ssnRegex.test(val);
    }

    isDate(val) {
        return moment(val).isValid();
    }

    isGuid(stringToTest) {
        var regexGuid = /^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/gi;
        return regexGuid.test(stringToTest);
    }

    formatDate(date: string, format: string): string {
        const daysDiff = moment().diff(date, 'days');

        if (daysDiff > 1 && daysDiff < 8) return moment(date).format('dddd h:mm a');
        if (daysDiff === 1) return 'Yesterday';
        if (daysDiff === 0) return 'Today';

        return moment(date).format(format);
    }
  
    removeHtmlFormatting(value: string): string {
        return value.replace(/<[^>]*>/g, '');
    }

    handleLogout(redirectUrl: string = '/authorize?action=logout'): void {
        this.authLegacyService.isAuthenticatedV2 = false;
        this.authLegacyService.logout(true, redirectUrl);
        if (JSON.parse(localStorage.getItem('legacy')) === false) {
            this.auth0Service.logout({
                async openUrl() {
                    window.location.replace(window.location.origin + redirectUrl);
                }
            });
        }
    }

    // See https://animate.style/ for more information.
    // Typical usage is animateCSS('#elementid', 'bounceIn')
    // Then names of animation come from animate.css which is described in link above.
    animateCSS(element:string, animation:string, prefix='animate__') {
        return new Promise((resolve, reject) => {
            const animationName = `${prefix}${animation}`;
            const node = document.querySelector(element);

            node.classList.add(`${prefix}animated`, animationName);

            function handleAnimationEnd(event) {
                event.stopPropagation();
                node.classList.remove(`${prefix}animated`, animationName);
                resolve('Animation ended');
            }

            node.addEventListener('animationend', handleAnimationEnd, {once: true});
        });
    }

}
