import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AppService } from '@App';

export enum ZoomLevel {
    Auto = 'auto',
    PageActual = 'page-actual',
    PageFit = 'page-fit',
    PageWidth = 'page-width',
    Percent50 = .5,
    Percent100 = 1,
    Percent125 = 1.25,
    Percent150 = 1.5,
    Percent200 = 2,
    Percent300 = 3,
    Percent400 = 4,
}

@Component({
    selector: 'pdf-viewer',
    templateUrl: './pdf-viewer.component.html',
    styleUrls: ['./pdf-viewer.component.scss']
})
export default class PdfViewer implements OnChanges {
    @Input() src: string;
    @Input() zoomLevel = ZoomLevel.Auto;

    contents: Blob = null;
    isPdfLoaded = false;
    hasViewPrintButtonPermission = false;

    constructor(protected appService: AppService) {
    }

    loadContents() {
        fetch(this.src).then(value => value.blob()).then(pdfBlob => {
            this.contents = pdfBlob;
            this.isPdfLoaded = true;
        });
    }

    ngOnInit() {
        this.hasViewPrintButtonPermission = this.appService.hasViewPermission('Download Documents and Attachments');
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.src.currentValue)
            this.loadContents();
    }
}
