import { Injectable } from '@angular/core';
import { AppService } from '@App';
import { AccountStatusEnum } from '@Enums';
import { Account, FeatureToggle } from '@Models';
import { Environment, EnvironmentService, FeatureToggleService, RoutesEnum } from '@Services';

@Injectable()
export class InterviewClientDefaultsService {
    public defaultAccount: Account;
    private _defaultBack: RoutesEnum = RoutesEnum.accounts;
    private _alternateBack: RoutesEnum = RoutesEnum.myCases;
    private _environment: Environment;
    private _features: FeatureToggle[] = [];
    private roleDefaultAccounts: any = {
        'Agent': '103-0002'
    };

    constructor(private appService: AppService,
        private environmentService: EnvironmentService,
        private featureToggleService: FeatureToggleService
    ) {
        this.environmentService.getEnvironment().subscribe(env => this._environment = env);
    }

    getUserBackDestination(account: Account): RoutesEnum {
        let target = this._defaultBack;
        const userRole = this.appService.getUserRoleName();

        if (account != null && this.roleDefaultAccounts[userRole] === account.code) {
            target = this._alternateBack;
        }

        return target;
    }

    async getUserDefaultAccount(availableAccounts: Account[]) {
        const userRole = this.appService.getUserRoleName();
        let defaultAccountCode = this.roleDefaultAccounts[userRole];

        if (!defaultAccountCode && availableAccounts.length === 1) {
            defaultAccountCode = availableAccounts[0].code;
        }

        this.defaultAccount = (availableAccounts || []).find((account) => account.code === defaultAccountCode && account.statusId === AccountStatusEnum.Active);
        return this.defaultAccount;
    }
}
