<div id="container" class="mapkey-section-pane">
    <form (ngSubmit)="saveConstant()" [formGroup]="constantForm" autocomplete="off">
        <div id="details" style="padding-bottom: 16px">
            <span class="d-inline-flex"><mat-icon (click)="showDetails = !showDetails">{{showDetails ? 'expand_less' :
                    'expand_more'}}</mat-icon>
                <h2>Details</h2>
            </span>
            <mat-card appearance="outlined" id="view" *ngIf="!isEditView" [ngStyle]="{'display':showDetails?'':'none'}">
                <mat-card-content>
                    <div class="label-title-bold">Constant Name</div>
                    <div class="label-content">{{constantForm?.get('name')?.value}}</div>
                    <div class="label-title-bold">Constant Type</div>
                    <div *ngIf="constantForm?.get('isList')?.value == false" class="label-content">Value</div>
                    <div *ngIf="constantForm?.get('isList')?.value == true" class="label-content">Static List</div>
                    <div *ngIf="constantForm?.get('isList')?.value == false" class="label-title-bold">Value</div>
                    <div *ngIf="constantForm?.get('isList')?.value == false">
                        <div *ngFor="let listItem of constantForm.value['constantMapKeyValues']"
                            class="label-content overflow-y-auto">{{listItem.value}}</div>
                    </div>
                    <div class="label-title-bold">Folder Location</div>
                    <div class="label-content">{{parentPathDisplay}}</div>
                </mat-card-content>
            </mat-card>
            <mat-card appearance="outlined" id="edit" *ngIf="isEditView" [ngStyle]="{'display':showDetails?'':'none'}">
                <mat-card-content>
                    <mat-form-field class="full-width pb-3">
                        <mat-label>Constant Name</mat-label>
                        <input id="constantNameInput" matInput formControlName='name'>
                        <mat-error *ngIf="constantForm.get('name').hasError('required')">
                            <strong>Required</strong>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="full-width pb-3">
                        <mat-label>Constant Type</mat-label>
                        <mat-select id="constantTypeSelect" formControlName="isList"
                            (selectionChange)="constantTypeChange($event)">
                            <mat-option *ngFor="let constantType of constantTypes" [value]="constantType.isList">
                                {{constantType.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="constantForm.get('isList').hasError('required')">
                            <strong>Required</strong>
                        </mat-error>
                    </mat-form-field>

                    <div *ngIf="!constantForm.get('isList').value" class="overflow-y-auto custom-scrollbar" id="values">
                        <div *ngFor="let list of constantForm.get('constantMapKeyValues')['controls']; let i = index"
                            class="row mx-0" formArrayName="constantMapKeyValues">
                            <div [formGroupName]="i" [ngClass]="{'col-8': constantForm?.get('isList')?.value == true}"
                                class="px-0 w-100">
                                <div class="d-flex flex-row align-items-center">
                                    <mat-form-field class="w-100 pr-2">
                                        <mat-label>Constant Value</mat-label>
                                        <input matInput formControlName='value' required
                                            maxlength="{{this.ValueMaxLength}}">
                                        <mat-error
                                            *ngIf="constantForm.get('constantMapKeyValues.' + i + '.value').hasError('required')">
                                            <strong>Required</strong>
                                        </mat-error>
                                        <mat-error
                                            *ngIf="constantForm.get('constantMapKeyValues.' + i + '.value').hasError('pattern')">
                                            <strong>Commas are not allowed in the value</strong>
                                        </mat-error>
                                    </mat-form-field>
                                    <mat-form-field *ngIf="constantForm?.get('isList')?.value == true"
                                        class="w-100 pr-2">
                                        <mat-label>Display Name</mat-label>
                                        <input matInput formControlName='displayName' required
                                            maxlength="{{this.DisplayMaxLength}}">
                                        <mat-error
                                            *ngIf="constantForm.get('constantMapKeyValues.' + i + '.displayName').hasError('required')">
                                            <strong>Required</strong>
                                        </mat-error>
                                    </mat-form-field>
                                    <button (click)='removeInputField(i)' *ngIf="checkACL('W')" mat-icon-button
                                        type="button">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </mat-card-content>
            </mat-card>
        </div>
        <div *ngIf="constantForm?.get('isList')?.value == true" id="constantList">
            <span class="d-inline-flex align-items-center"><mat-icon id="listSectionCollapser"
                    (click)="showList = !showList">{{showList ?
                    'expand_less' :
                    'expand_more'}}</mat-icon>
                <h2 style="margin-bottom:0">List</h2>
                <span *ngIf="constantForm?.get('isList')?.value == true && isEditView"
                    style="margin-left:12px"><mrs-btn-tertiary iconName="add" buttonLabel="Add Variant"
                        buttonId="addVariant" textColor="#2C6EED" (onClick)="addNewVariant()"></mrs-btn-tertiary>
                </span>
            </span>
            <div id="listCard" [ngStyle]="{'display':showList?'':'none'}">
                <mat-tab-group class="inner-mat-tab">
                    <mat-tab id="defaultTab">
                        <ng-template mat-tab-label>
                            <mat-icon *ngIf="isEditView && defaultHasErrors()"
                                class="small-error-icon mr-2">error_outline</mat-icon>
                            Default
                        </ng-template>
                        <mat-card appearance="outlined" [ngStyle]="{'display':showList && !isEditView?'':'none'}">
                            <mat-card-content>
                                <div id="toolbar" class="flex-md-row" style="margin-bottom: 16px">
                                    <span class="d-inline"
                                        *ngIf="checkACL('W') && constantForm?.get('isList')?.value == true">
                                        <input (change)="selectFile($event)" (click)="onClick($event)" accept=".xlsx"
                                            class="d-none" id="fileInput" type="file" #fileInput />
                                        <mrs-btn-outline iconName='upload' buttonLabel="Import" buttonId="import"
                                            [disabled]="constantForm.value.constantMapkeyVariants.length > 0"
                                            (onClick)="fileInput.click()" *ngIf="checkACL('W')"
                                            matTooltip="Import Mapkeys"
                                            [matTooltipDisabled]="constantForm.value.constantMapkeyVariants.length > 0">
                                        </mrs-btn-outline>
                                    </span>
                                    <span *ngIf="checkACL('W') && constantForm?.get('isList')?.value == true"
                                        class="d-inline">
                                        <mrs-btn-outline iconName='download' buttonLabel="Export" buttonId="export"
                                            (click)="exportItem()" matTooltip="Export Mapkeys">
                                        </mrs-btn-outline>
                                    </span>
                                </div>
                                <div class="scrollable-list">
                                    <div class="d-flex sticky-header" id="heading">
                                        <div class="col-6">Value</div>
                                        <div class="col-6">Display Name</div>
                                    </div>
                                    <div id="list">
                                        <div *ngFor="let listItem of constantForm.value['constantMapKeyValues']"
                                            class="display-list d-flex constant-list-item">
                                            <div class="col-6 label-content">{{listItem.value}}</div>
                                            <div class="col-6  label-content">{{listItem.displayName}}</div>
                                        </div>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                        <mat-card appearance="outlined" [ngStyle]="{'display':showList && isEditView?'':'none'}">
                            <mat-card-content>
                                <div *ngIf="filterableStaticMapkeysEnabled && constantForm.get('constantMapkeyVariants')['controls'].length > 0"
                                    style="margin-bottom: 12px">Changes to default list will affect all variants.</div>
                                <div class="d-flex sticky-header" id="editable-list-heading" style="margin-left: 16px">
                                    <div class="col-4">Value</div>
                                    <div class="col-4" style="margin-left:3px">Display Name</div>
                                </div>
                                <div class="scrollable-list constant-list-container" cdkDropList
                                    (cdkDropListDropped)="changeConstantOrder($event.previousIndex, $event.currentIndex)"
                                    cdkDropListAutoScrollDisabled="false" cdkDropListAutoScrollStep="5">
                                    <div *ngFor="let list of constantForm.get('constantMapKeyValues')['controls']; let i = index"
                                        class="row mx-0" formArrayName="constantMapKeyValues" cdkDrag
                                        cdkDragPreviewContainer="parent">
                                        <div *cdkDragPlaceholder class="drag-drop-custom-placeholder"></div>
                                        <div [formGroupName]="i" class="px-0 w-100 col-8 constant-list-input">
                                            <div class="d-flex flex-row align-items-center">
                                                <div class="mr-2">
                                                    <mat-icon id="drag" cdkDragHandle>drag_indicator
                                                    </mat-icon>
                                                </div>
                                                <mat-form-field class="col-6 pr-4">
                                                    <input matInput [id]="'valueInput'+i.toString()"
                                                        formControlName='value' required
                                                        (focusout)="ValidateValueDuplicate(list)"
                                                        maxlength="{{this.ValueMaxLength}}">
                                                    <mat-error *ngIf="list.get('value').hasError('required')">
                                                        <strong>Required</strong>
                                                    </mat-error>
                                                    <mat-error *ngIf="list.get('value').hasError('pattern')">
                                                        <strong>Commas are not allowed in the value</strong>
                                                    </mat-error>
                                                    <mat-error *ngIf="list.get('value').hasError('duplicate')">
                                                        <strong>Value already in use
                                                        </strong>
                                                    </mat-error>
                                                </mat-form-field>
                                                <mat-form-field *ngIf="constantForm?.get('isList')?.value == true"
                                                    class="col-6 pr-2">
                                                    <input matInput formControlName='displayName' required
                                                        maxlength="{{this.DisplayMaxLength}}">
                                                    <mat-error
                                                        *ngIf="constantForm.get('constantMapKeyValues.' + i + '.displayName').hasError('required')">
                                                        <strong>Required</strong>
                                                    </mat-error>
                                                </mat-form-field>
                                                <button (click)='removeInputField(i);ValidateValueDuplicate(list);'
                                                    *ngIf="checkACL('W') && constantForm.get('constantMapKeyValues')['controls'].length > 1"
                                                    mat-icon-button type="button">
                                                    <mat-icon>delete</mat-icon>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <mrs-btn-tertiary textColor="#2C6EED" (click)="addNewInputField()" iconName="add"
                                    buttonId="addValue" buttonLabel="Add Value"></mrs-btn-tertiary>
                            </mat-card-content>
                        </mat-card>
                    </mat-tab>
                    <mat-tab *ngFor="let variant of constantForm.get('constantMapkeyVariants').controls; let i = index"
                        formArrayName="constantMapkeyVariants">

                        <ng-template mat-tab-label>
                            <mat-icon *ngIf="isEditView && variant.invalid && filterableStaticMapkeysEnabled"
                                class="small-error-icon mr-2">error_outline</mat-icon>
                            <mat-icon class="warning-icon-tab mr-2"
                                *ngIf="isEditView && !variant.invalid && variant.dirty">circle</mat-icon>
                            {{variant.get('name').value}}
                        </ng-template>

                        <ng-template matTabContent>
                            <mat-card>
                                <mat-card-content [formGroupName]="i">
                                    <div class="d-flex flex-row justify-content-between">
                                        <div *ngIf="!isEditView">
                                            <div class="label-title-bold">Variant Name</div>
                                            <div class="label-content">{{variant.get('name').value}} </div>
                                        </div>
                                        <mat-form-field class="col-6 pr-4" *ngIf="isEditView">
                                            <input (focusout)="markVariantAsDirty(variant)"
                                                [id]="'variantNameInput'+i.toString()" class="variant-name-input"
                                                matInput formControlName='name' required>
                                            <mat-label>Variant Name</mat-label>
                                            <mat-error *ngIf="variant.get('name').hasError('required')">
                                                <strong>Required</strong>
                                            </mat-error>
                                            <mat-error *ngIf="variant.get('name').hasError('uniqueName')">
                                                <strong>Must be unique</strong>
                                            </mat-error>
                                        </mat-form-field>
                                        <mrs-btn-tertiary *ngIf="isEditView" [buttonId]="'deleteVariant'+i.toString()"
                                            iconName="delete" textColor="#26333B" buttonLabel="delete variant"
                                            (onClick)="deleteVariant(i)"></mrs-btn-tertiary>
                                    </div>

                                    <div *ngIf="!isEditView">
                                        <div class="label-title-bold">Rule</div>
                                        <div class="label-content" style="margin-bottom: 0" [id]="'ruleNameVariant'+i">
                                            {{variant.get('ruleName').value}}</div>
                                        <div class="hint">Variant will display in Application when true</div>
                                    </div>
                                    <mat-form-field class="col-6 pr-4" *ngIf="isEditView">
                                        <mat-label>Rule</mat-label>
                                        <mat-select [id]="'variantRuleSelect'+i.toString()" required
                                            formControlName="ruleId" (selectionChange)="markVariantAsDirty(variant)">
                                            <mat-option *ngFor="let rule of rules"
                                                [value]="rule.id">{{rule.name}}</mat-option>
                                        </mat-select>
                                        <mat-hint>Variant will display in Application when true</mat-hint>
                                        <mat-error *ngIf="variant.get('ruleId').hasError('required')">
                                            <strong>Required</strong>
                                        </mat-error>
                                        <mat-error *ngIf="variant.get('ruleId').hasError('uniqueRuleId')">
                                            <strong>Must use a rule that is not in any other variant</strong>
                                        </mat-error>
                                    </mat-form-field>

                                    <div
                                        [ngStyle]="{'margin-top':variantListValueSelectionHasErrors(variant)?'4px':'28px'}">
                                        <div class="d-flex flex-row align-items-center">
                                            <mat-icon style="color:#900606;  margin-left: 0px"
                                                *ngIf="variantListValueSelectionHasErrors(variant)">error_outline</mat-icon>
                                            <div style="color:#900606; margin-left:4px"
                                                *ngIf="variantListValueSelectionHasErrors(variant)">
                                                <strong>At least one value
                                                    must
                                                    be selected</strong>
                                            </div>
                                        </div>
                                        <div *ngIf="isEditView">
                                            <span>
                                                <mat-checkbox [id]="'showAllVariant'+i.toString()"
                                                    [checked]="allVariantValuesChecked(variant.get('constantMapkeyVariantValues'))"
                                                    [indeterminate]="someVariantValuesChecked(variant.get('constantMapkeyVariantValues'))"
                                                    (change)="toggleAllVariantValuesSelection(variant)">
                                                    <div style="display:flex; flex-direction:row; align-items:center">
                                                        <div>Show All</div>
                                                    </div>
                                                </mat-checkbox>

                                            </span>
                                        </div>
                                        <div class="d-flex sticky-header flex-row">
                                            <div *ngIf="isEditView" style="flex:0 0 60px">Show</div>
                                            <div style="flex:1 1 33%">Value</div>
                                            <div style="flex:1 1 33%">Display Name</div>
                                        </div>

                                        <div *ngIf="!isEditView">
                                            <div *ngFor="let listItem of variant.value['constantMapkeyVariantValues']"
                                                class="display-list d-flex constant-variant-value-list-item">
                                                <div class="label-content" style="flex:1 1 33%">{{listItem.value}}</div>
                                                <div class="label-content" style="flex:1 1 33%">{{listItem.displayName}}
                                                </div>
                                            </div>
                                        </div>

                                        <div *ngIf="isEditView">
                                            <div *ngFor="let constantMapkeyValue of constantForm.get('constantMapKeyValues')['controls']; let j = index;"
                                                formArrayName="constantMapkeyVariantValues"
                                                class="display-list d-flex flex-row align-items-center">
                                                <mat-checkbox style="flex:0 0 60px" class="variant-value-checkbox"
                                                    [checked]="variantIsSelected(constantMapkeyValue, variant.get('constantMapkeyVariantValues'))"
                                                    (change)="toggleVariantValueSelection(constantMapkeyValue, variant.get('constantMapkeyVariantValues'))" />
                                                <div class=" label-content" style="margin-bottom:0;flex:1 1 33%">
                                                    {{constantMapkeyValue.get('value').value}}</div>
                                                <div class=" label-content" style="margin-bottom:0;flex:1 1 33%">
                                                    {{constantMapkeyValue.get('displayName').value}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </ng-template>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </form>
</div>
