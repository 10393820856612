import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FeatureToggle } from 'app/enums';
import { FeatureManagerService } from 'app/services/global/feature-manager/feature-manager.service';
import { RoutingService } from '../../services';
import { AuthLegacyService } from '../auth-legacy.service';

@Component({
    selector: 'auth0-login',
    templateUrl: './auth0-login.component.html',
    styleUrls: ['./auth0-login.component.scss']
})
export class Auth0LoginComponent implements OnInit {

    showContent = false;

    constructor(
        public authService: AuthLegacyService,
        private routingService: RoutingService,
        private snackBar: MatSnackBar,
        private featureService: FeatureManagerService) { }

    ngOnInit() {
        if (this.authService.isAuthenticated()) {
            this.routingService.navigateToDefault();
        } else {
            this.showContent = true;
            this.openSnackBar();
        }
    }

    signIn() {
        localStorage.setItem('legacy', 'true');
        if (this.authService.isAuthenticated()) {
            this.routingService.navigateToDefault();
        } else {
            this.authService.login();
        }
    }

    openSnackBar() {
        if (this.featureService.getByName(FeatureToggle.GlobalLoginNewProcessMessage).enabled)
            this.snackBar.openFromComponent(SignInUpgradeComponent, {
                horizontalPosition: 'center',
                verticalPosition: 'top',
                panelClass: 'snack-bar-panel'
            });
    }
}

@Component({
    selector: 'sign-in-upgrade',
    templateUrl: './sign-in-upgrade.component.html'
})
export class SignInUpgradeComponent {

}
