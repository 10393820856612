import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { AppService } from '../../../app.service';

import { MatDialog } from '@angular/material/dialog';
import { IntegrationMappingType } from '@Enums';
import { Account, AccountIntegrationMapping } from '../../../models';
import { ConfirmationDialogService } from '@Services';

@Component({
    selector: 'account-integrations',
    host: {'class': 'bg-white d-flex flex-row flex-fill'},
    templateUrl: './account-integrations-list.component.html',
    styleUrls: ['./account-integrations.component.scss']
})
export class AccountIntegrationsComponent implements OnInit {
    @Input() accountInfo: Account;
    @Input() rules;
    @Input() hiddenIntegrations;
    accountIntegrations: any[] = [];
    integrationForm: UntypedFormGroup;
    showForm = false;
    showContent = false;
    selectedIntegration: any = {};
    expandAllRequests: boolean;
    secretKeys: any = [];
    metadata: any;
    addMapping = false;
    selectedAccountIntegration: any;

    filterValue: string;
    filteredIntegrations: any[] = [];

    @ViewChild('autoFocus') autoFocusElement: ElementRef;

    constructor(
        private appService: AppService,
        private _fb: UntypedFormBuilder,
        private confirmationService: ConfirmationDialogService,
        public dialog: MatDialog
    ) {

    }

    ngOnInit() {
        this.getSecretKeys();
        this.getAccountIntegrations();

        this.integrationForm = this._fb.group({
            id: '',
            accountId: ['', Validators.required],
            clientId: ['', Validators.required],
            integrationId: ['', Validators.required],
            environment: ['', Validators.required],
            requestUrl: ['', Validators.required],
            username: [''],
            password: [''],
            timeoutSeconds: [''],
            expirationHours: ['', Validators.required],
            maxAttempts: ['', Validators.required],
            isActive: false
        });

        this.integrationForm.get('environment').valueChanges.subscribe(val => {
            if (val != null) {
                const env = this.metadata.environments.find(x => {
                    return x.name == val;
                });

                if (!this.selectedIntegration || !this.selectedIntegration.accountIntegrations || (!this.selectedIntegration.accountIntegrations.username || this.selectedIntegration.accountIntegrations.username === '')) {
                    this.integrationForm.patchValue({
                        username: env.username == null || env.username === '' ? this.integrationForm.get('username').value : env.username
                    });
                }

                this.integrationForm.patchValue({
                    requestUrl: env.url
                });
            }
        });

    }

    checkACL(permissionType, redirect?) {
        return this.appService.checkACL('Accounts', permissionType, redirect);
    }

    getAccountIntegrations() {
        this.appService.getData(`Accounts/${this.accountInfo.id}/Integrations`).subscribe(
            data => {
                if (data.status === 'success') {
                    this.accountIntegrations = data.data;
                    if (this.hiddenIntegrations) {
                        this.accountIntegrations = this.accountIntegrations.filter(x => !this.hiddenIntegrations.includes(x.id));
                    }
                    if (this.selectedIntegration && this.selectedIntegration.id) {
                        // this.selectedIntegration.accountIntegrations = Object.assign({}, data.data)
                        const currentIntegration = _.find(this.accountIntegrations, ['id', this.selectedIntegration.id]);

                        this.selectedIntegration = currentIntegration;
                        if (this.selectedIntegration.version && this.selectedIntegration.version == 2) {
                            this.setEnvironment();
                        } else {
                            this.selectedAccountIntegration = (this.selectedIntegration.accountIntegrations && this.selectedIntegration.accountIntegrations.length > 0) ? this.selectedIntegration.accountIntegrations[0] : null;
                        }
                    }

                    this.filteredIntegrations = this.accountIntegrations;
                }
            }
        );
    }

    getIntegrationRequests() {
        if (this.selectedIntegration.accountIntegrations.id) {
            this.appService.getAllDynamicConfigData(this.accountInfo.id);
            this.appService.getData(`IntegrationRequest/${this.selectedIntegration.accountIntegrations.id}`).subscribe(
                data => {
                    if (data.status === 'success') {
                        this.selectedIntegration.accountIntegrations.integrationRequests = data.data;
                        this.getIntegrationMetadata();
                    }
                }
            );
        }
    }

    getIntegrationMetadata() {
        if (this.selectedIntegration.version == 2 && this.selectedIntegration.id) {
            this.appService.getData(`IntegrationRequest/GetMetadata/${this.selectedIntegration.id}`).subscribe(
                data => {
                    if (data.status === 'success') {
                        this.metadata = data.data;
                        if (this.selectedIntegration.accountIntegrations && this.selectedIntegration.accountIntegrations.requestUrl && this.selectedIntegration.accountIntegrations.requestUrl != '') {
                            this.setEnvironment();
                        }
                    }
                }
            );
        }
    }

    setEnvironment() {
        if (this.metadata.environments) {
            const environment = this.metadata.environments.find(x => {
                return x.url == this.selectedIntegration.accountIntegrations.requestUrl;
            });

            if (environment) {
                this.selectedIntegration.environment = environment.name;
                this.integrationForm.patchValue({
                    environment: this.selectedIntegration.environment
                });
            }
        }
    }

    editAccountIntegration() {
        this.getIntegrationMetadata();
        this.integrationForm.patchValue(this.selectedIntegration.accountIntegrations[0]);
        this.setIntegrationFormFields();
        this.showForm = true;
        this.appService.focusElement(this.autoFocusElement);
    }


    getAccountIntegrationDetail(integration) {
        this.integrationForm.reset();
        this.selectedIntegration = integration;
        this.selectedAccountIntegration = integration.accountIntegrations && integration.accountIntegrations.length > 0 ? integration.accountIntegrations[0] : null;
        if (integration.version == 2) {
            this.getIntegrationMetadata();
        }

        if (integration.accountIntegrations && integration.accountIntegrations.length > 0) { // edit
            this.showForm = false;
        } else { // add
            this.integrationForm.patchValue({
                accountId: this.accountInfo.id,
                clientId: this.accountInfo.clientId,
                integrationId: this.selectedIntegration.id,
                isActive: true
            });
            this.showForm = true;
            this.setIntegrationFormFields();
            this.appService.focusElement(this.autoFocusElement);
        }

    }

    setIntegrationFormFields() {
        if (this.selectedIntegration.version == 2) {
            this.integrationForm.controls['requestUrl'].disable();
            this.integrationForm.controls['environment'].setValidators([Validators.required]);
            this.integrationForm.controls['expirationHours'].setValidators([Validators.required]);
        } else {
            this.integrationForm.controls['requestUrl'].enable();
            this.integrationForm.controls['environment'].setValidators([]);
            this.integrationForm.controls['expirationHours'].setValidators([]);
        }
    }

    cancel() {
        this.showForm = false;
        if (!this.selectedIntegration.accountIntegrations) {
            this.selectedIntegration = undefined;
        }
    }

    addIntegrationMapping(templateRef: any) {
        this.dialog.open(templateRef,
            {
                width: '800px',
                maxHeight: '90%',
                data: {
                    integrationForm: this.integrationForm,
                    selectedIntegration: this.selectedIntegration
                }
            });
        this.addMapping = true;
    }

    closeMapping() {
        this.dialog.closeAll();
    }

    buildIntegrationMapping(formVal) {
        const mappings: AccountIntegrationMapping[] = [];
        if (formVal.response) {
            const responseMapping: AccountIntegrationMapping = {
                accountIntegrationid: formVal.id,
                mapKeyId: formVal.response,
                mappingtypeId: IntegrationMappingType.Response
            };
            mappings.push(responseMapping);
        }

        if (formVal.inputParams) {
            formVal.inputParams.forEach(element => {
                const inputMapping: AccountIntegrationMapping = {
                    accountIntegrationid: formVal.id,
                    mapKeyId: element.mapKey_Id,
                    mappingtypeId: IntegrationMappingType.Input,
                    metadataId: element.metadata_Id
                };

                mappings.push(inputMapping);
            });
        }

        if (formVal.outputParams) {
            formVal.outputParams.forEach(element => {
                const inputMapping: AccountIntegrationMapping = {
                    accountIntegrationid: formVal.id,
                    mapKeyId: element.mapKey_Id,
                    mappingtypeId: IntegrationMappingType.Output,
                    metadataId: element.metadata_Id
                };

                mappings.push(inputMapping);
            });
        }

        formVal.accountIntegrationMapping = mappings;
    }

    saveAccountIntegration() {
        if (this.integrationForm.valid) {

            let isEdit = false;
            let url = '';

            if (this.integrationForm.get('id').value) {
                isEdit = true;
                url = `Accounts/${this.accountInfo.id}/Integration/${this.integrationForm.get('id').value}`;
            } else {
                isEdit = false;
                url = `Accounts/${this.accountInfo.id}/Integration`;
            }

            const formRaw = this.integrationForm.getRawValue();
            if (this.addMapping) {
                this.buildIntegrationMapping(formRaw);
            } else if (this.selectedIntegration.accountIntegrations && this.selectedIntegration.accountIntegrations.accountIntegrationMapping) {
                formRaw.accountIntegrationMapping = this.selectedIntegration.accountIntegrations.accountIntegrationMapping;
            }

            this.appService.postData(url, formRaw, isEdit, false)
                .subscribe(
                    data => {
                        if (data.status === 'success') {
                            // this.accountIntegrations = [...this.accountIntegrations, data.data];
                            this.showForm = false;
                            // this.selectedIntegration.accountIntegrations = Object.assign({}, data.data)
                            this.getAccountIntegrations();
                            this.appService.getAllDynamicConfigData(this.accountInfo.id);
                            this.appService.showMsg('success', 'Saved successfully ...');
                        } else {
                            this.appService.showMsg('error', data.message);
                        }
                    }
                );
        } else {
            this.appService.showMsg('error', 'Invalid Form. Please fill all fields', false);
        }
    }


    deleteAccountIntegration(id, integration) {
        this.confirmationService.open({
            title: 'Delete Integration?',
            message: `Deleting ${integration.name} may impact the account configuration.  This cannot be undone.`,
            okLabel: 'Delete',
            onOk: () => {
                // this.accountIntegrations.splice(id, 1);
                this.appService.deleteData(`Accounts/${this.accountInfo.id}/Integration/${id}`).subscribe(
                    data => {
                        if (data.status === 'success') {
                            this.showForm = false;
                            this.showContent = false;
                            this.getAccountIntegrations();
                            this.appService.getAllDynamicConfigData(this.accountInfo.id);
                            this.appService.showMsg('success', data.message);
                        }
                    }
                );
            },
            showCancel: true
        });

    }

    getLabelName(ruleId) {
        if (ruleId && this.rules && this.rules.length) {
            return _.find(this.rules, ['id', ruleId]).name;
        } else {
            return '';
        }
    }

    addRequest() {

        const newRequest = {
            id: '',
            accountIntegrationID: this.selectedIntegration.accountIntegrations.id,
            name: '',
            requestUrl: '',
            requestTemplate: ''
        };

        this.selectedIntegration.accountIntegrations.integrationRequests = [...this.selectedIntegration.accountIntegrations.integrationRequests, newRequest];
    }

    getSecretKeys() {

        this.appService.getData(`ConfigData/GetSecretKeys`).subscribe(
            data => {
                if (data.status === 'success') {
                    this.secretKeys = data.data.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0));
                } else {
                    this.secretKeys = [];
                }
            }
        );
    }

    getSecretKeyLabel(id) {
        if (id && this.secretKeys && this.secretKeys.length) {
            const secretKey = _.find(this.secretKeys, ['id', id]);
            if (secretKey && secretKey.name) {
                return secretKey.name;
            } else {
                return '';
            }
        } else {
            return '';
        }
    }

    getIntegrationIconClass(integration) {
        if (!integration.accountIntegrations || integration.accountIntegrations.length === 0) {
            return 'empty-icon';
        }

        const activeIntegration = integration.accountIntegrations.find(x => x.isActive);

        if (!activeIntegration) {
            return 'empty-icon';
        }

        return 'fa fa-check-circle text-success';
    }

    searchIntegrations() {
        if (this.filterValue && this.filterValue !== '') {
            this.filteredIntegrations = this.accountIntegrations.filter(p => p.name.toLowerCase().indexOf(this.filterValue.toLowerCase()) !== -1);
        } else {
            this.filteredIntegrations = this.accountIntegrations;
        }
    }
}
