import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IConfigService } from '@Config';
import { InterviewSectionDTO, JsonRule, RulesResultDTO } from '@DTOs';
import { UWResponse } from '@Models';
import { AppService } from 'app';
import * as _ from 'lodash';
import { lastValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base.service';
import { RuleFact } from '../rules-engine/models';
import { RulesEngineService } from '../rules-engine/rules-engine.service';

export interface RuleDictionary { [ruleId: string]: JsonRule; }

@Injectable()
export class RulesService extends BaseService {
    private publicApiBaseUrl: string;
    private _cachedRules: { [accountId: string]: Promise<RuleDictionary> } = {};

    constructor(public appService: AppService,
        public httpClient: HttpClient,
        public configService: IConfigService,
        public rulesEngineService: RulesEngineService) {
        super(appService, configService, httpClient);

        this.publicApiBaseUrl = this.configService.getConfiguration().publicApiUrl;
    }

    //#region Helpers

    private toRuleDictionary(rules: JsonRule[]): RuleDictionary {
        return _.keyBy(rules, 'ruleId');
    }

    //#endregion

    getSimpleRulesByAccount(accountId: string, cache = true) {
        if (this._cachedRules[accountId] == null || !cache) {
            this._cachedRules[accountId] = lastValueFrom(this.appService.getData<UWResponse<JsonRule[]>>(`Rules/${accountId}/simple`).pipe(
                map(result => {
                    const rules = result.data;
                    return this.toRuleDictionary(rules);
                })
            )).catch(() => this._cachedRules[accountId] = null);
        }

        return this._cachedRules[accountId];
    }

    execute(caseId: string, rule: JsonRule, facts: RuleFact[], clientId?: string) {
        return this.rulesEngineService.execute(caseId, rule, facts, clientId);
    }

    executeApi(caseId: string, ruleId: string, hideWaiting = true) {
        const clientId = this.appService.getClientId('');
        return lastValueFrom(super.getData<boolean>(`${this.publicApiBaseUrl}client/${clientId}/case/${caseId}/rule/${ruleId}/evaluaterule`, undefined, hideWaiting));
    }

    executeApiWithSection(caseId: string, ruleId: string, sectionDto: InterviewSectionDTO) {
        const clientId = this.appService.getClientId('');
        return lastValueFrom(super.putData<RulesResultDTO>(`${this.publicApiBaseUrl}client/${clientId}/case/${caseId}/rule/${ruleId}/evaluaterule/section`, sectionDto));
    }

    executeMultipleRulesWithSection(caseId: string, ruleIds: string[], sectionDto: InterviewSectionDTO) {
        const clientId = this.appService.getClientId('');
        return lastValueFrom(super.putData<RulesResultDTO[]>(`${this.publicApiBaseUrl}client/${clientId}/case/${caseId}/rules/evaluaterule/section`, { section: sectionDto, ruleIds }));
    }
}
