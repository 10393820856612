<div class="flex flex-column">
  <div class="flex flex-row">
    <form [formGroup]="callsForm" novalidate >
      <div class="d-flex mt-2">
        <div class="mr-5">
          <client-account-dropdown formField="account" [formGroup]="callsForm" [clientAccountSplitOverride]="clientAccountOverride"></client-account-dropdown>
        </div>
          <div class="report-date-input mr-5">
            <mat-form-field class="w-100">
              <mat-label>Choose a start date</mat-label>
              <input matInput [matDatepicker]="startDate" formControlName="startDate"
                readonly>
              <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
              <mat-datepicker #startDate disabled="false"></mat-datepicker>
              <mat-error *ngIf="callsForm.get('startDate').hasError('required')">required</mat-error>
            </mat-form-field>
          </div>
        <div class="report-date-input mr-5">
          <mat-form-field class="w-100">
            <mat-label>Choose an end date</mat-label>
            <input matInput [matDatepicker]="endDate" formControlName="endDate"
              readonly>
            <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
            <mat-datepicker #endDate disabled="false"></mat-datepicker>
            <mat-error *ngIf="callsForm.get('endDate').hasError('required')">required</mat-error>
          </mat-form-field>
        </div>
        <div class="ml-5" style="margin-left: auto;justify-content: end;">
          <mrs-btn-icon class="float-right" buttonLabel="Show Report" buttonId="showReportButton" (onClick)="getCalls()"
            iconName="search"></mrs-btn-icon>
        </div>
      </div>
    </form>
  </div>

  <div *ngIf="reportData.length" class="d-flex flex-row flex-nowrap overflow-auto">
    <mrs-table [data]="reportData" [displayedColumns]="displayedColumns" [searchableFields]="searchableFields"
      (tableEvent)="tableAction($event)">
    </mrs-table>
  </div>

  <div class="pt-3" *ngIf="noDataPlaceholder">
    <div >
      <div class="p-4 text-center text-muted ">
        <img src="./assets/images/no-data-placeholder.png " alt=" " class="pb-3 " />
        <h6>No Data Found</h6>
      </div>
    </div>
  </div>
</div>
