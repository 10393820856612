import { Component, ElementRef, OnDestroy, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { AppService } from '@App';
import { AccountImportRequestAction, AccountStatusEnum, CopyAccountSetting } from '@Enums';
import { Account, Client, FeatureToggle } from '@Models';
import {
    AccountDataService,
    AccountService,
    ClientService,
    ConfirmationDialogService,
    Environment,
    EnvironmentService,
    FeatureToggleService,
    InterviewClientDefaultsService,
    RoutesEnum,
    RoutingService,
} from '@Services';
import { saveAs } from 'file-saver';
import * as $ from 'jquery';
import * as _ from 'lodash';
import { interval, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { InterviewService } from '../interview.service';

@Component({
    selector: 'app-start-interview',
    host: { 'class': 'content' },
    templateUrl: './active-accounts.component.html',
    styleUrls: ['./active-accounts.component.scss']
})
export class ActiveAccountsComponent implements OnInit, OnDestroy {
    @ViewChildren('matExpansionPanel') matExpansionPanel: QueryList<MatExpansionPanel>;
    @ViewChild('copyVersionDialog') copyVersionDialog: TemplateRef<any>;
    @ViewChild('versionStatusDialog') versionStatusDialog: TemplateRef<any>;
    @ViewChild('editClientDialog') editClientDialog: TemplateRef<any>;
    @ViewChild('activateVersionDialog') activateVersionDialog: TemplateRef<any>;
    @ViewChild('fileInput') fileInput: ElementRef;

    clients: Client[];
    defaultAccount: any = null;
    filterValue: any = '';
    filteredData: any;
    noDraftAccess = true;
    isConfigView = false;
    accounts: Account[] = [];
    accountActiveStatus = AccountStatusEnum.Active;
    accountDraftStatus = AccountStatusEnum.Draft;
    accountInactiveStatus = AccountStatusEnum.Inactive;
    public showInterview = false;
    import: any;
    statusInterval: any;
    copyInProgress = false;
    accountCopyingInterval: any;
    accountCopyInProgress = false;
    hasDraftAccess = false;
    selectedAccount: Account;
    selectedClient: any;
    activeAccount: Account;
    versionForm: UntypedFormGroup;
    versionDialog: MatDialogRef<ActiveAccountsComponent>;
    editClientDialogRef: MatDialogRef<ActiveAccountsComponent>;
    activateVersionClientDialogRef: MatDialogRef<ActiveAccountsComponent>;
    dataSource = new MatTableDataSource<any>();
    newClientForm: UntypedFormGroup;
    columnsToDisplay = ['taskType', 'task', 'message', 'actions'];
    selectedOptions: string[] = [];
    copySettings = CopyAccountSetting.Settings;
    integrationSettings = CopyAccountSetting.Integrations;
    eventSettings = CopyAccountSetting.Events;
    fileData: any;
    canImport = false;
    canExport = false;
    environment: Environment;
    copyTimerRunning;
    featureToggles: FeatureToggle[] = [];
    clientWriteAccess = false;
    accountWriteAccess = false;

    private subject: Subject<string> = new Subject();
    private _destroy$ = new Subject();

    constructor(
        public dialog: MatDialog,
        private _fb: UntypedFormBuilder,
        private appService: AppService,
        private clientService: ClientService,
        public interviewService: InterviewService,
        private interviewClientDefaultsService: InterviewClientDefaultsService,
        private routingService: RoutingService,
        private activatedRoute: ActivatedRoute,
        private accountService: AccountService,
        private environmentService: EnvironmentService,
        private confirmationService: ConfirmationDialogService,
        private featureToggleService: FeatureToggleService,
        private accountDataService: AccountDataService
    ) { }

    ngOnInit() {

        this.activatedRoute.data.subscribe(data => {
            this.isConfigView = data.isConfigView;
            this.canImport = this.checkACL('Import Account');
            this.canExport = this.checkACL('Export Account');
            this.accountWriteAccess = this.checkACL('Accounts');
            this.clientWriteAccess = this.checkACL('Clients');
            this.environmentService.getEnvironment().subscribe(env => this.environment = env);
            if (!this.isConfigView) {
                $('#sidenav').removeClass('hidenav');
            }

            this.getAccounts(false, true);

            this.noDraftAccess = !(this.checkACL('View Draft Accounts'));

            this.subject.pipe(
                debounceTime(250)
            ).subscribe(() => {
                this.updateFilteredData();
            });
        });
    }

    ngOnDestroy() {
        this._destroy$.next(1);
        this._destroy$.unsubscribe();

        if (this.copyTimerRunning) {
            this.copyTimerRunning.unsubscribe();
        }
    }

    checkACL(feature) {
        if (feature === '') {
            return true;
        }
        return this.appService.checkACL(feature, 'R');
    }

    viewInterview(client, account: Account) {
        if (this.isConfigView) {
            this.viewAccount(client, account);
        } else {
            this.routingService.navigateToRoute(RoutesEnum.interview, {
                clientCode: client.code,
                accountCode: account.code,
                versionNumber: account.versionNumber
            });
        }
    }

    getAccounts(hideWaiting = false, useCache = true) {
        this.accountCopyInProgress = false;
        this.clientService.getClientsAndAccounts(hideWaiting, useCache)
            .pipe(takeUntil(this._destroy$))
            .subscribe(async result => {
                this.clients = result;
                const allAccounts = [];

                if (!this.isConfigView) {
                    this.clients.forEach(client => {
                        client.accounts.forEach(account => {
                            account.versions = _.filter(account.versions, function (o) {
                                return o.statusId !== AccountStatusEnum.Inactive && o.statusId !== AccountStatusEnum.Failed;
                            });

                            allAccounts.push(...account.versions);
                        });
                    });

                    this.defaultAccount = await this.interviewClientDefaultsService.getUserDefaultAccount(allAccounts);
                    if (this.defaultAccount != null) {
                        const defaultClient = this.clients.find(element => element.id === this.defaultAccount.clientId);
                        this.viewInterview(defaultClient, this.defaultAccount);
                    }
                } else {
                    this.clients.forEach(client => {
                        client.accounts.forEach(account => {
                            if (this.selectedAccount) {
                                if (account.id === this.selectedAccount.id) {
                                    account.open = true; // TECH DEBT: See: `\models\config\account.d.ts`
                                } else {
                                    account.open = false; // TECH DEBT: See: `\models\config\account.d.ts`
                                }
                            }

                            account.versions.forEach(version => {
                                if (version.status === 'Copying') {
                                    this.accountCopyInProgress = true;
                                }
                            });
                        });
                    });

                    if (!this.accountCopyInProgress && this.copyTimerRunning) {
                        this.copyTimerRunning.unsubscribe();
                        this.copyTimerRunning = undefined;
                    }

                    if (this.accountCopyInProgress && !this.copyTimerRunning) {
                        this.copyTimerRunning = interval(10000).subscribe(
                            {
                                next: () => { this.getAccounts(true, false); }
                            }
                        );
                    }
                }

                this.updateFilteredData();
            });
    }

    onKeyUp(searchTextValue: string) {
        this.subject.next(searchTextValue);
    }

    updateFilteredData() {
        const filterValue = this.filterValue.toLowerCase();
        if (filterValue && filterValue.length > 0) {
            this.filteredData = this.clients
                .filter(element => element.accounts
                    .some(subElement =>
                        element.name.toLowerCase().indexOf(filterValue) !== -1 ||
                        subElement.name.toLowerCase().indexOf(filterValue) !== -1 ||
                        element.code.indexOf(filterValue) !== -1 ||
                        subElement.code.indexOf(filterValue) !== -1)
                )
                .map(element => {
                    const n = Object.assign({}, element, {
                        'accounts': element.accounts.filter(
                            subElement =>
                                element.name.toLowerCase().indexOf(filterValue) !== -1 ||
                                subElement.name.toLowerCase().indexOf(filterValue) !== -1 ||
                                element.code.indexOf(filterValue) !== -1 ||
                                subElement.code.indexOf(filterValue) !== -1
                        )
                    });
                    return n;
                });
        } else {
            this.filteredData = this.clients;
        }
    }

    expandPanel(client, account: any, panel: MatExpansionPanel, event: Event) {
        event.stopPropagation();
        const isExpansionIndicator = this._isExpansionIndicator(event.target);
        const currentPanel = this.matExpansionPanel.find(x => x.id === panel.id);

        if (isExpansionIndicator) {
            if (!currentPanel.expanded) {
                currentPanel.close();
                this.selectedClient = null;
                this.selectedAccount = null;
            } else {
                this.selectedClient = client;
                this.selectedAccount = account;
                currentPanel.open();
            }
        } else {
            if (currentPanel.expanded) {
                currentPanel.close();
            }
            this.viewInterview(client, account);
        }
    }

    private _isExpansionIndicator(eventTarget: EventTarget): boolean {
        const target = eventTarget as HTMLSpanElement;
        const matExpansionIndicatorClass = 'mat-expansion-indicator';
        const expansionIndicatorClass = 'expansion-indicator';
        return (target.classList && (target.classList.contains(expansionIndicatorClass) || target.classList.contains(matExpansionIndicatorClass)));
    }

    addAccount(client) {
        this.routingService.navigateToRoute(RoutesEnum.accountCreation, {
            clientCode: client.code
        });
    }

    viewAccount(client, account: Account) {
        if (account.status === 'Copying' || account.status === 'Failed') {
            this.viewStatus(account);
            return false;
        }

        this.routingService.navigateToRoute(RoutesEnum.accountDetails, {
            clientCode: client.code,
            accountCode: account.code,
            versionNumber: account.versionNumber
        });
    }

    stopPropagation($event) {
        $event.stopPropagation();
    }

    copyAccount(account: Account) {
        this.versionForm = this._fb.group(
            {
                versionLabel: []
            });

        this.selectedAccount = account;
        this.versionDialog = this.dialog.open(this.copyVersionDialog,
            {
                width: '600px',
                maxHeight: '90%'
            });
    }

    createVersion() {
        const versionLabel = this.versionForm.get('versionLabel').value;
        this.accountService.createAccountVersion(this.selectedAccount.clientId, this.selectedAccount.id, this.selectedAccount.versionNumber, versionLabel)
            .subscribe(() => {
                this.appService.showMsg('success', 'Version creation started');
                this.getAccounts(false, false);
                this.versionDialog.close();
                this.copyInProgress = true;
                this.accountDataService.clearCache();
            });
    }

    getVersionStatus(version: Account) {
        this.accountService.getVersionStatus(version.clientId, version.id, version.versionNumber)
            .subscribe(result => {
                this.dataSource.data = result.tasks;
                if (result.status === 'Complete' || result.status === 'Failed') {
                    this.getAccounts(false, false);
                    this.copyInProgress = false;

                    if (this.statusInterval) {
                        clearInterval(this.statusInterval);
                    }

                    if (this._destroy$) {
                        this._destroy$.next(1);
                    }
                }
            });
    }

    viewStatus(version: Account) {
        this.selectedAccount = version;
        const statusDialog = this.dialog.open(this.versionStatusDialog,
            {
                width: '800px',
                maxHeight: '90%'
            });

        statusDialog.afterOpened().subscribe(() => {
            this.getVersionStatus(this.selectedAccount);

            this.statusInterval = setInterval(() => {
                this.getVersionStatus(this.selectedAccount);
            }, 2000);
        });

        statusDialog.afterClosed().subscribe(() => {
            clearInterval(this.statusInterval);
            if (!this.copyInProgress) {
                this.getAccounts(false, false);
            }
        });
    }

    restartAccountImport(accountImportTaskId) {
        this.accountService.restartAccountImport(this.selectedClient.id, this.selectedAccount, accountImportTaskId).subscribe({
            next: result => {
                this.dataSource.data = result.tasks;
                this.copyInProgress = true;

                this.statusInterval = setInterval(() => {
                    this.getVersionStatus(this.selectedAccount);
                }, 2000);
            }
        });
    }

    activateVersion(client, version: Account, activeVersion: Account) {
        this.selectedAccount = version;
        this.activeAccount = activeVersion;
        this.selectedClient = client;
        this.selectedOptions = [];

        this.activateVersionClientDialogRef = this.dialog.open(this.activateVersionDialog,
            {
                width: '800px',
                maxHeight: '90%'
            });
    }

    saveActivateVersion() {
        this.accountService.setAccountActive(this.selectedClient.id, this.selectedAccount, this.selectedOptions).subscribe(
            data => {
                if (data.success) {
                    this.appService.showMsg('success', data.message);
                    this.getAccounts(false, false);
                    this.activateVersionClientDialogRef.close();
                    this.accountDataService.clearCache();
                } else {
                    this.appService.showMsg('error', data.message);
                }
            }
        );
    }

    archiveVersion(client, version: Account) {
        this.confirmationService.open({
            message: `Are you sure that you want to remove "v${version.versionNumber} - ${version.versionLabel}"?`,
            showCancel: true,
            onOk: () => {
                this.accountService.setAccountRemoved(client.id, version).subscribe(data => {
                    this.appService.showMsg('success', data.message);
                    this.getAccounts(false, false);
                    this.accountDataService.clearCache();
                });
            }
        });
    }

    editClient(client) {
        this.selectedClient = client;

        this.newClientForm = new UntypedFormGroup({
            name: new UntypedFormControl(this.selectedClient.name, [Validators.required])
        });

        this.editClientDialogRef = this.dialog.open(this.editClientDialog,
            {
                width: '800px',
                maxHeight: '90%'
            });
    }

    saveClient() {
        if (this.newClientForm.valid) {
            const clientForm = this.newClientForm.getRawValue();
            clientForm.id = this.selectedClient.id;

            this.appService.postData('Clients', clientForm, true).subscribe(
                data => {
                    if (data.status === 'success') {
                        this.appService.showMsg('success', 'Saved successfully ...');
                        this.getAccounts(false, false);
                        this.editClientDialogRef.close();
                    } else {
                        this.appService.showMsg('error', data.message);
                    }
                }
            );
        }
    }

    exportVersion(client, version: Account) {
        this.accountService.exportVersion(client.id, version.id, version.versionNumber).subscribe(data => {
            const blob = new Blob([JSON.stringify(data)], { type: 'application/json' });
            saveAs(blob, `${version.code}-${version.versionNumber}-${version.versionLabel}-${this.environment.mode}.json`);

            this.appService.showMsg('success', 'Version exported successfully... ');
        });
    }

    selectFile(event, client, version) {
        const selectedFile = event.target.files[0];

        if (!selectedFile) {
            return;
        } else {
            const extension = selectedFile.name.match(/\.([^.]+)$/)[1];

            if (extension.toLowerCase() === 'json') {
                const fileReader = new FileReader();
                fileReader.readAsText(selectedFile, "UTF-8");
                fileReader.onload = () => {
                    const fileImportData = JSON.parse(fileReader.result.toString());
                    fileImportData.createdFromAccount = version;

                    const importVersion = {
                        versionLabel: fileImportData.exportedFromAccount.versionLabel,
                        clientId: client.id,
                        action: null,
                        export: fileImportData
                    };

                    if (version === null) {
                        importVersion.action = AccountImportRequestAction.Create;
                        this.accountService.importNewAccount(client.id, importVersion).subscribe(data => {
                            this.appService.showMsg('success', `Version ${data.versionNumber} import started... `);
                            this.getAccounts(false, false);
                            this.accountDataService.clearCache();
                            this.copyInProgress = true;
                        });
                    } else {
                        importVersion.action = AccountImportRequestAction.Import;
                        this.accountService.importVersion(client.id, version.id, importVersion).subscribe(data => {
                            this.appService.showMsg('success', `Version ${data.versionNumber} import started... `);
                            this.getAccounts(false, false);
                            this.accountDataService.clearCache();
                            this.copyInProgress = true;
                        });
                    }

                };
            } else {
                this.appService.showMsg('error', 'Please choose a json file type', false);
            }
        }
    }

    trackByClientFunction(index, item) {
        if (!item) return null;
        return item.clientId;
    }

    trackByAccountFunction(index, item) {
        if (!item) return null;
        return item.Id;
    }


    onClick(event) {
        event.target.value = null;
    }
}
