﻿<div *ngIf="accountSettingsLoaded && notesAccountSettingItems.length !== 0">
    <!-- <div class="page-sub-header"> -->
    <div class="sub-section-header">
        <h1 class="title">Notes</h1>
        <span class="actions">
            Last Updated: <span *ngIf="lastUpdated; else never">{{ lastUpdated | date: 'M/d/yy, h:mm a' }}</span>
            <ng-template #never>Never</ng-template>
        </span>
    </div>

    <form [formGroup]="workbenchNotesForm" novalidate autocomplete="off">
        <mat-card *ngFor="let noteAccountSetting of notesAccountSettingItems" class="account-setting" appearance="outlined">
            <mat-card-header>{{ noteAccountSetting.accountSettingTitle }}</mat-card-header>

            <mat-card-content *ngIf="!accountSettingsLoaded && !noteAccountSetting.loadError">
                <mat-spinner mode="indeterminate" diameter="30"></mat-spinner>
            </mat-card-content>

            <mat-card-content *ngIf="accountSettingsLoaded && !noteAccountSetting.loadError">
                <mat-slide-toggle
                    [formControlName]="noteAccountSetting.formControlName"
                    [name]="noteAccountSetting.formControlName"
                    (change)="onToggleFeature($event)">
                    {{ noteAccountSetting.accountSettingDescription }}
                </mat-slide-toggle>
            </mat-card-content>

            <mat-card-content *ngIf="noteAccountSetting.loadError">
                {{ noteAccountSetting.loadErrorMessage }}
            </mat-card-content>
        </mat-card>
    </form>
</div>


<!-- Templates -->
<ng-template let-data #disableWarningDialog>
    <div mat-dialog-content>
        <div>
            <div class="dialog-title" style="margin-bottom: 10px;">{{ data.title }}</div>
            <div class="content flex flex-column">{{ data.content }}</div>
        </div>
    </div>
    <mat-dialog-actions align="end">
        <mrs-btn-tertiary (click)="handleClose()" buttonId="closeDialog" textColor="#2C6EED" buttonLabel="OKAY"> </mrs-btn-tertiary>
    </mat-dialog-actions>
</ng-template>