﻿<div [formGroup]="queueRulesFormGroup">
  <h2>Case Routing Rules</h2>
  <div class="flex flex-row" formArrayName="accounts" >
    <mat-list>
      <mat-list-item style="margin-left:-16px; height:70px;" *ngFor="let accountRule of accountRules.controls; index as i">
        <div class="flex flex-row">
          <div style="flex:1 1 auto;">
            <div class="title">Account</div>
            <div>{{accountRule.get('accountName').value}}</div>
          </div>
        </div>
      </mat-list-item>
    </mat-list>

    <mat-list style="margin-left:70px">
      <mat-list-item style="margin-left:-16px; height:70px;" *ngFor="let accountRule of accountRules.controls; index as i">
        <div class="flex flex-row">
          <app-multi-select-dropdown-list
            [id]="accountRule.get('accountId')"
            style="min-width:450px"
            [completeList]="accountRule.get('accountRules').value"
            (selectionChanged)="ruleSelectionChanged()"
            [listTitle]="'Rule(s)'"
            [selectedItemsList]="accountRule.get('selectedAccountRules').value"
          ></app-multi-select-dropdown-list>
        </div>
      </mat-list-item>
    </mat-list>
  </div>
</div>
