import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from 'app/app.service';
import { IConfigService } from 'app/config/iconfigservice';
import { ObservableService } from 'app/services';

import { BaseService } from '../../services/base.service';

@Injectable({
    providedIn: 'root'
})
export class TableDataService extends BaseService {
    constructor(
        public appService: AppService,
        public httpClient: HttpClient,
        public configService: IConfigService,
        public observableService: ObservableService
    ) {
        super(appService, configService, httpClient);
    }

    get<T = any>(resource: string) {
        return super.getData<T>(`${this.basePublicApiUrl}${resource}`);
    }
}
