import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UWResponse } from '@Models';
import { InterviewDTO, InterviewDTO_extended } from '@Models/domain/dto';
import { BaseService } from '@Services';
import { AppService } from 'app/app.service';
import { IConfigService } from 'app/config/iconfigservice';
import { first, map } from 'rxjs';


@Injectable({ providedIn: 'root' })
export default class InterviewDataService extends BaseService {
    constructor(
        public configService: IConfigService,
        public httpClient: HttpClient,
        public appService: AppService
    ) {
        super(appService, configService, httpClient);
    }

    //#region Helpers

    convertToExtended(interview: InterviewDTO) {
        const interview_ext: InterviewDTO_extended = { ...interview };

        // extend section questions to add `displayType`
        interview_ext.sections.forEach(section => section.questions.forEach(question => {
            question.DisplayType = question.displayType = question.propertyValues.find(x => x.name.toLowerCase() == 'DisplayType'.toLowerCase())?.value;
        }));

        return interview_ext;
    }

    //#endregion

    /**
     * DEV NOTE: This Data Service is not doing a ReplaySubject (ie. caching) because "Services" in Angular are singleton-like
     * (ie. once the service is created, that instance is reused on any component injecting that service).
     * 
     * Instead, we want to always hit the API for the latest data when we load the interview. This will make sure we don't accidently 
     * use the "previously" loaded interview when user is clicking between cases (eg. Case Manager -> Case Summary -> Case Manager -> etc)
     * 
     * We've also updated `interview-manager.service.ts` because it's an "Angular Service" and it's `formattedSection` was being cached/retained
     * between cases. When a new interview data was loaded, we'd pass it to Interview Manager to be formatted and there was a period of time
     * between the new data being formatted and the old data being available, that the wrong data could "flash" and appear to the user.
     * 
     * There's some idea of using a "Resettable" Replay Subject (see SO Link below) that might be an improvement we can make in the future.
     * See: https://stackoverflow.com/a/51147023/253564
     */
    public reloadInterviewForSection(caseId: string, sectionId: string, hideWaiting?: boolean) {
        const url = `${this.baseApiUrl}Interview/${caseId}/section/${sectionId}`;

        return super.getData<UWResponse<InterviewDTO_extended>>(url, null, hideWaiting).pipe(first(), map(response => {
            const interviewExtended = this.convertToExtended(response.data);
            return interviewExtended;
        }));
    }
}
