import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';

import { AppService } from '../../app.service';
import { IConfigService } from '../../config/iconfigservice';
import { Tag } from '../../models';
import { BaseService } from '../base.service';
import { lastValueFrom } from 'rxjs';

@Injectable()
export class TagService extends BaseService {

    publicApiBaseUrl: string;

    constructor(public appService: AppService, public configService: IConfigService, public httpClient: HttpClient) {
        super(appService, configService, httpClient);
        this.publicApiBaseUrl = this.configService.getConfiguration().publicApiUrl;
    }

    getTagsByAccount(account: any) {
        const clientId = this.appService.getClientId('');
        const url = `${this.publicApiBaseUrl}client/${clientId}/account/${account.id}/${account.versionNumber}/tag`;

        return lastValueFrom(super.getData<Tag[]>(url, clientId));
    }

    getTagsByAccountAndType(account: any, tagTypes: string[]) {
        const clientId = this.appService.getClientId('');
        let url = `${this.publicApiBaseUrl}client/${clientId}/account/${account.id}/${account.versionNumber}/tag`;
        tagTypes.forEach((tagType, index) => {
            if (index === 0) {
                url += `?objectType=${tagType}`;
            } else {
                url += `&objectType=${tagType}`;
            }
        });

        return lastValueFrom(super.getData<Tag[]>(url, clientId));
    }
}
