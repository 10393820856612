import { AfterViewChecked, AfterViewInit, Component, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { InterviewService } from '@App';
import { InterviewQuestionDTO_extended } from '@DTOs';
import { ActionEnum, FeatureToggle } from '@Enums';
import { Account } from '@Models';
import { ScrollToConfigOptions, ScrollToService } from '@nicky-lenaers/ngx-scroll-to';
import { FeatureManagerService } from 'app/services';
import { Subscription } from 'rxjs';

@Component({
    selector: 'dynamic-form',
    templateUrl: './dynamic-form.component.html',
    styleUrls: ['./dynamic-form.component.scss']
})
export class DynamicFormComponent implements OnInit, OnDestroy, AfterViewChecked, AfterViewInit {
    @Input() expandAllQuestions;
    @Input() selectedQuestion;
    @Input() interviewMode;
    @Input() removeIndent;
    @Input() parentIndex;
    @Input() sectionLocked: boolean;
    @Input() parentId;
    @Input() formattedSection;

    _constantLists: any;
    viewLoaded = false;
    form: UntypedFormGroup;
    account: Account;
    subscriptions: Subscription[] = [];
    prefillReflexiveInRepeatsToggle = false;

    @Input()
    set constantLists(value) {
        this._constantLists = value;
    }

    get constantLists() {
        return this._constantLists;
    }

    _config: InterviewQuestionDTO_extended[] = [];
    @Input()
    set config(value: InterviewQuestionDTO_extended[]) {
        if (this.interviewMode) {
            const me = this;
            if (this.interviewMode === 'reflexive') {
                value = value.filter(question => {
                    me.checkQuestionDisplay(question);
                    return true;
                    // return me.checkQuestionDisplay(question);
                });
            } else if (this.interviewMode === 'repeat') {
                value = value.filter(question => {
                    me.checkQuestionDisplay(question);
                    return true;
                    // return me.checkQuestionDisplay(question);
                });
            } else if (this.interviewMode === 'health') {
                value = value.filter(question => {
                    me.checkQuestionDisplay(question);
                    return true;
                    // return me.checkQuestionDisplay(question);
                });
            } else {
                value = value.filter((question, _index, _questionsArray) => {
                    me.checkQuestionDisplay(question);
                    return question.parentQuestionId === '00000000-0000-0000-0000-000000000000';
                    // return me.checkQuestionDisplay(question) && question.parentQuestionId == "00000000-0000-0000-0000-000000000000";
                });
            }
        }

        this._config = value;
        this.form = this.createGroup();
                // on change of component's "value" property, new form is created, update the same to the reference in interview service.
        if (!this.parentId) {
            this.interviewService.setDynamicForm(this.form);
        } else if (this.interviewMode === 'reflexive') {
            value.forEach(element => {
                const configValue = element;
                const repeatFormId = configValue.repeatFormId;
                const formId = element.id;
                if (repeatFormId && configValue.display) {
                    const repeatForm = this.interviewService.repeatForms[repeatFormId];
                    const formControl = this.form.get(formId);
                    repeatForm.addControl(formId, formControl);
                    if(this.prefillReflexiveInRepeatsToggle) {
                        this.interviewService.repeatReflexiveForms[this.parentId] = this.form;
                    }
                } else {
                    this.interviewService.reflexiveForms[this.parentId] = this.form;
                }
            });
        } else if (this.interviewMode === 'repeat') {
            this.interviewService.repeatForms[this.parentId] = this.form;
        } else if (this.interviewMode === 'health') {
            this.interviewService.healthForms[this.parentId] = this.form;
        }

        if (this.interviewMode) {
            for (const eachQuestion of value) {
                if (eachQuestion.questionActions != null && eachQuestion.questionActions.length > 0) {
                    const actionIds = eachQuestion.questionActions.map(x => x.actionsId);
                    eachQuestion.actionIds = JSON.stringify(actionIds);
                }

                if (this.form.get(eachQuestion.id)) {
                    if (eachQuestion.errorData) {
                        this.form.get(eachQuestion.id).setErrors({ 'validationError': eachQuestion.errorData.message ? eachQuestion.errorData.message : eachQuestion.errorData });
                    } else {
                        this.form.get(eachQuestion.id).setErrors(null);
                    }
                }

            }
        }
        this.viewLoaded = true;
    }
    get config() {
        return this._config;
    }

    /**
     * This component is used for displaying all type of forms in the interview. All the forms are differentiated using "interviewMode" property.
     * @param  {FormBuilder} fb
     * @param  {InterviewService} interviewService
     */
    constructor(
        private fb: UntypedFormBuilder,
        public interviewService: InterviewService,
        private route: ActivatedRoute,
        private rd: Renderer2,
        private _scrollToService: ScrollToService,
        private featureManagerService: FeatureManagerService
    ) {
        this.subscriptions.push(route.data.subscribe(data => {
            this.account = data.account;
        }));
        this.prefillReflexiveInRepeatsToggle = this.featureManagerService.getByName(FeatureToggle.CaseRuntimePrefillReflexiveInRepeats).enabled;
    }

    //#region Helpers

    /**
     * Create an Angular FormGroup that represents the `config` (ie. list of InterviewQuestionDTOs).
     * 
     * @returns 
     */
    createGroup() {
        const group = this.fb.group({});
        let firstVisibleInput = false;

        if (this.interviewMode === 'new') {
            this.config.forEach((control, _index) => {

                const controlValue = control.answerValue ? control.answerValue : '';
                if (!firstVisibleInput && control.answerType !== 'DisplayMessage') {
                    if (control.display) {
                        firstVisibleInput = true;
                        let newControl: any;
                        if (control.answerType === 'MultiChoiceSingleAnswer' && control.DisplayType === 'radio') {
                            newControl = new UntypedFormControl(controlValue, { updateOn: 'change' });
                        } else {
                            newControl = new UntypedFormControl(controlValue, { updateOn: 'blur' });
                        }

                        const formValues = newControl.valueChanges;
                        // adding save case event to first question answer value update, this event is unsubscribed once after case is saved.
                        const newFormValueSubscripton = formValues.subscribe(_data => {
                            if (!newControl.pristine && newControl.valid) {
                                this.interviewService.saveCase(this.account.id);
                                newFormValueSubscripton.unsubscribe();
                            }
                        });
                        group.addControl(control.id, newControl);
                    }
                } else {
                    // if "display" property is false, field is not shown and not added to formgroup.
                    if (control.display) {
                        group.addControl(control.id, this.fb.control(controlValue));
                    }
                }
            });
        } else {
            this.config.forEach(control => {
                // if field already has answervalue, create a control with value as answervalue.
                const controlValue = control.answerValue ? control.answerValue : '';
                if (control.display) {
                    group.addControl(control.id, this.fb.control(controlValue));
                }
            });
        }
        return group;
    }

    //#endregion
    //#region Lifecycle

    ngOnInit() {
        if (this.parentIndex != null) {
            this.parentIndex += ".";
        } else {
            this.parentIndex = "";
        }
    }

    ngAfterViewInit() {
        this.setFocus(this.interviewService.lastAnsweredQuestionId);
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    // check for field validations after form loaded (this is for back and forth field validation on repeat blocks)
    ngAfterViewChecked() {
        this.updateValidityForFields();
    }

    //#endregion


    setFocus(questionId) {
        setTimeout(() => {
            const el = document.getElementById(questionId);
            if (el) {
                const config: ScrollToConfigOptions = {
                    target: questionId,
                    container: "interviewForm"
                };
                this._scrollToService.scrollTo(config);
            }
        }, 0);
    }

    getFieldStatus(field: InterviewQuestionDTO_extended) {
        /**
         * TECH DEBT: The only place this method is called is in the view.  This is bad for Angular because this will be rapid fired from any DOM events like browser movements.
         * The purpose of this method is to control an `*ngIf`.  It's also confusing because besides the `*ngIf` for visability, it's also mutating the `this.form` value to 
         * remove the form.  So, it's not just a UI display control but also doing some data manipulation.
         * 
         * This method should be move to some kind of pre-processor that can set a flag for `*ngIf` and manipulate the `this.form` control.
         * 
         * Making this change might have a nice positive impact on the UI performance as well.
         * 
         * However, I think the App might be relying on this firing millions of times because it's mutating values and all these handled events
         * might be "helping" making the questions show/hide. Ie. if we move this logic into a pre-processor there might not be any event that notifies
         * the component to update.  Right now, because this thing machine gun fires, it's going to simply pick up any changes. If we pre-process it,
         * that pre-process will only fire once and any further changes will no longer be picked up and applied (because we're not brute-force rendering)
         */
        if (field.answerType === 'DisplayMessage' && field.DisplayType === 'Popup') {
            if (field.display) {
                field.display = false;
                return true;
            } else {
                return false;
            }
        }
        if (field.display) {

            // add more conditions to this such as display message, actionbuttons
            if (!this.form.get(field.id)) {
                const controValue = field.answerValue ? field.answerValue : '';
                this.form.addControl(field.id, this.fb.control(controValue));
            }

            const formField = this.form.get(field.id);
            if (formField) {
                if (field.isLocked && formField.enabled)
                    formField.disable();
                else if (!field.isLocked && formField.disabled)
                    formField.enable();
            }

            return true;
        } else { // Hide Field
            if (this.form.get(field.id)) {
                this.form.removeControl(field.id);
            }

            if (this.form.get(`${field.id}_picker`)) {
                this.form.removeControl(`${field.id}_picker`);
            }
            return false;
        }
    }

    // This metod is used to change question's display property which in turn is used to remove/add the field to formgroup.
    checkQuestionDisplay(question) {
        if (question.answerType === 'ActionButton') {
            if (question.questionActions && question.questionActions.length) {
                for (const eachQuestionAction of question.questionActions) {
                    eachQuestionAction.isTriggerAutomatic = false;
                }
            }
        } else {
            if (question.questionActions && question.questionActions.length) {
                for (const eachQuestionAction of question.questionActions) {
                    eachQuestionAction.isTriggerAutomatic = true;
                }
            }
        }

        let returnValue = true;
        returnValue = question.display;
        if (question.questionActions) {
            const allActionResults = question.questionActions;
            for (const eachActionResult of allActionResults) {
                if (eachActionResult.actionResults && eachActionResult.actionResults.data) {
                    if (eachActionResult.actionResults.data.display != null) {
                        returnValue = eachActionResult.actionResults.data.display;
                    }
                }

                if (eachActionResult.actions.id === ActionEnum.Disabled && eachActionResult.actionResults) {
                    question.isLocked = eachActionResult.actionResults.data.disabled;
                }
            }
        }

        question.display = returnValue;

        if (!question.isLocked) {
            question.isLocked = this.sectionLocked;
        }
        // return returnValue;
    }

    getInnerText(config) {

        if (config.integrationMessages === 'SSN') {
            if (config.intergrationData && config.intergrationData.hasDuplicateSSN) {
                this.form.get(config.id).setErrors({
                    'ssn': true
                });
                return config.intergrationData.message;
            } else {
                this.form.get(config.id).setErrors(null);
                config.integrationMessages = false;
                return '';
            }
        } else if (config.integrationMessages === 'GIACT') {
            return 'GIACT Response: ' + config.intergrationData;
        }

    }

    updateValidityForFields() {
        if (this.interviewMode && this.viewLoaded) {
            for (const eachField of this.config) {
                if (eachField.errorData) {
                    const field = this.form.get(eachField.id);
                    if (field) {
                        field.setErrors({ validationError: eachField.errorData });
                    }
                }
            }
        }
        this.viewLoaded = false;
    }
}
