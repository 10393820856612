import { ModifiedSectionActionsDTO } from "@AccountConfig/account-interview/account-interview.component";
import { ActionDialogComponentParams, EditActionDialogComponent } from "@Components/action-new/dialog/edit-action-dialog.component";
import { AccountSettingsDTO, ActionParamsDTO, ActionTypesDTO, ActionsDTO, RulesDTO } from "@DTOs";
import { AccountSettings, NoteType } from "@Enums";
import { AccountSettingsDataService } from "@Services";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { AppService } from "app/app.service";
import * as _ from "lodash";
import { lastValueFrom } from "rxjs";
import { SubSink } from "subsink";

export interface ActionV2ActionModel extends ModifiedSectionActionsDTO {
    actionParameters?: any[];
}
@Component({
    selector: 'action-v2',
    templateUrl: 'action-v2.component.html',
    styleUrls: ['action-v2.component.scss']
})
export class ActionV2Component implements OnInit, OnDestroy {
    @Input() action: ActionV2ActionModel;
    @Input() rules: RulesDTO[];
    @Input() actions: ActionsDTO[];
    @Input() actionParent: string;

    expanded = false;
    actionLabel: string;
    actionSelectionLabel: string;
    actionTypeLabel: string;
    actionName: string;
    actionTypes: ActionTypesDTO[];
    actionParams: ActionParamsDTO[];
    ruleLabel: string;
    billingEvents: any[];
    billingEventName:string;

    subs = new SubSink();
    accountSettings: AccountSettingsDTO[] = [];
    accountSettingsLoaded = false;
    disableNamedActions = false;
    
    private actionTypeBoth = '00000000-0000-0000-0000-000000000003';

    @Output() onDeleteAction = new EventEmitter<any>();
    @Output() onActionUpdated = new EventEmitter<any>();


    constructor(
        private appService: AppService,
        public dialog: MatDialog,
        private accountSettingsDataService: AccountSettingsDataService,
    ) {
    }

    ngOnInit() {
        this.subscribeToAccountSettings(this.action.accountId);
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
        this.accountSettings = [];
        this.accountSettingsLoaded = false;
    }

    checkACL(permissionType, redirect?) {
        return this.appService.checkACL('Accounts', permissionType, redirect);
    }

    editAction(isAddAction:boolean) {
        const dialogRef = this.dialog.open(EditActionDialogComponent,
            {
                width: '800px',
                maxHeight: '95%',
                data: {
                    action: this.action,
                    actions: this.actions,
                    actionParent: this.actionParent,
                    rules: this.rules,
                    isAddAction: isAddAction ?? false
                } as ActionDialogComponentParams
            });

        dialogRef.componentInstance.onDeleteAction.subscribe(() => {
            this.onDeleteAction.emit();
            dialogRef.componentInstance.onDeleteAction.unsubscribe();
        });

        dialogRef.componentInstance.onActionUpdated.subscribe((action) => {
            this.onActionUpdated.emit();
            dialogRef.componentInstance.onActionUpdated.unsubscribe();
        });
    }

    getActionTypeLabel(id) {
        if (id && this.actionTypes && this.actionTypes.length) {
            const actionType = _.find(this.actionTypes, ['id', id]);
            if (actionType && actionType.name) {
                return actionType.name;
            } else {
                return '';
            }
        } else {
            return '';
        }
    }

    formatParamValue(param:ActionParamsDTO) {
        const value = this.action.actionParameters.find(x=>x.actionParamsId === param.id)?.paramValue;
        if (param) {
            if (param.actionParamsType.fieldType === 'Lookup' || param.actionParamsType.fieldType === 'AutoSuggest') {
                const lookupValue = _.find(param['LookupValues'], ['id', value]);
                return (lookupValue ? lookupValue.name : '');
            } else if (param.actionParamsType.fieldType === 'Text') {
                return value;
            } else if (param.actionParamsType.fieldType === 'Multiselect') {
                let returnVal = '';
                if (value && value !== '') {
                    const valueArray = value.split('|');
                    valueArray.forEach(x => {
                        const lookupValue = _.find(param['LookupValues'], ['id', x]);
                        returnVal += (lookupValue ? lookupValue.name + ', ' : '');
                    });
                }
                return returnVal;
            } else if (param.actionParamsType.fieldType === 'FollowUpDate') {
                return `Follow up after ${value} days`;
            }
        } else {
            return value;
        }
    }

    getRuleLabel(id) {
        if (id && this.rules && this.rules.length) {
            const rule = _.find(this.rules, ['id', id]);
            if (rule && rule.name) {
                return rule.name;
            } else {
                return '';
            }
        } else {
            return '';
        }
    }

    setBillingEventLabel(id) {
        if (id && this.billingEvents && this.billingEvents.length) {
            const billingEvent = _.find(this.billingEvents, ['billingEventID', id]);
            if (billingEvent && billingEvent.billingEventName) {
                this.billingEventName = billingEvent.billingEventName;
            } else {
                return '';
            }
        } else {
            return '';
        }
    }


    setCollapsedLabel(action) {
        const labels = [];
        this.actionTypeLabel = this.getActionTypeLabel(action.actionTypeId);
        this.actionLabel = this.getActionLabel2(action.actionName, action.actionsId);
        this.ruleLabel = this.getRuleLabel(action.rulesId);

        if (this.actionTypeLabel.length > 0) {
            labels.push(this.actionTypeLabel);
        }

        if (this.actionLabel.length > 0) {
            labels.push(this.actionLabel);
        }

        if (this.ruleLabel.length > 0) {
            labels.push(this.ruleLabel);
        }
        this.actionLabel = labels.join(' - ');
        this.actionLabel = (this.actionLabel.length > 45 ? `${this.actionLabel.substring(0, 45)}...` : this.actionLabel);
    }

    getActionLabel2(actionName: string, id: string) {
        if (!this.disableNamedActions && this.accountSettingsLoaded) {
            if (actionName && actionName !== '') {
                return actionName;
            } else {
                return this.getActionLabel(id);
            }
        } else {
            return this.getActionLabel(id)
        }
    }

    getActionLabel(id) {
        if (id && this.actions && this.actions.length) {
            const action = _.find(this.actions, ['id', id]);
            if (action && action.name) {
                return action.name;
            } else {
                return '';
            }
        } else {
            return '';
        }
    }

    getActionParams(selectedActionId) {
        const configActions = this.appService.getConfigData('actions');
        let currentAction: any;

        configActions.forEach(configAction => {
            if (configAction.id === selectedActionId.value) {
                currentAction = configAction;
            }
        });

        if (_.find(this.actions, ['id', selectedActionId.value])) {
            this.actionParams = _.find(this.actions, ['id', selectedActionId.value]).actionParams;
            if (this.actionParent === 'Section') {
                this.actionParams = this.actionParams.filter(o => o.params !== 'waitForResponse');
                this.actionParams = this.actionParams.filter(o => o.params !== 'statusMessage');
                this.actionParams = this.actionParams.filter(o => o.params !== 'statusDisplayType');
            }
        }

        if (this.actionParams.length) {
            this.actionParams.forEach(async param => {
                let filteredParamValues;
                if (param.displayName === "Integration Report") {
                    const integrations = this.appService.getConfigData('integrations');

                    if (integrations && currentAction) {
                        const documents = await this.getDocuments(`Documents/${this.action.accountId}`);
                        const linkedIntegrationId = integrations.find(x => x.id === currentAction.integration_Id).linkedIntegrationId;

                        filteredParamValues = documents.data.filter(x => x.integrationId === linkedIntegrationId);
                    }
                }
                else {
                    filteredParamValues = this.appService.getConfigFromStaticAndDynamicConfigData(param.params);
                    if (param.id === "00000000-0000-0000-0000-000000000053") {
                        filteredParamValues = filteredParamValues.filter(x => x.id !== NoteType.Error && x.id !== NoteType.SystemLog);
                    }
                }

                if (param.displayName != "Integration Report" || (param.displayName === "Integration Report" && filteredParamValues)) {

                    if (param.actionParamsType.fieldType === 'Lookup' || param.actionParamsType.fieldType === 'AutoSuggest'
                        || param.actionParamsType.fieldType === 'Multiselect') {
                        param['LookupValues'] = filteredParamValues;

                    }
                }
            });
        }
    }

    getDocuments(url, clientId?: string): Promise<any> {

        return lastValueFrom(this.appService.getData(url, clientId));
    }

    subscribeToAccountSettings(accountId: string) {
        this.subs.add(this.accountSettingsDataService.loadAccountSettings(accountId).subscribe(accountSettings => {
            this.accountSettings = accountSettings;
            this.accountSettingsLoaded = true;

            const disabledNameActionAccountSetting = this.accountSettings.find((setting) => setting.name.toLowerCase() === AccountSettings.disabledNamedActions.toLocaleLowerCase());
            if (disabledNameActionAccountSetting) {
                this.disableNamedActions = disabledNameActionAccountSetting.value?.toLowerCase() === 'true';
            } else {
                this.disableNamedActions = false;
            }

            this.actionTypes = this.appService.combos['ActionTypes'].filter(at => at.id !== this.actionTypeBoth);
            this.actionTypeLabel = this.getActionTypeLabel(this.action.actionTypeId);
            this.actionSelectionLabel = this.getActionLabel(this.action.actionsId);
            this.actionName = this.getActionLabel2(this.action.actionName, this.action.actionsId);
            this.ruleLabel = this.getRuleLabel(this.action.rulesId);
            this.billingEvents = this.appService.getDynamicConfigData('accountBillingEvents');
            this.setBillingEventLabel(this.action?.billingEventId);
            this.setCollapsedLabel(this.action);
            this.actionParams = this.actions.find(x=>x.id === this.action.actionsId)?.actionParams;
            if(this.action.id) this.getActionParams(this.action.id);           
        }));
    }
}