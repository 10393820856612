<div class="mrs-table-container" [style.width.px]="tableWidth">
  <div class="mrs-table-inner-container">
    <mat-form-field id="filterCasesFormField" class="filter-cases" floatLabel="never" appearance="fill"
      *ngIf="searchableFields && searchableFields.length > 0">
      <input matInput placeholder="Search" name="filterValue" (keyup)="updateFilteredCases()" [(ngModel)]="filterValue">
      <mat-icon class="search-icon" aria-hidden="false" aria-label="Case search icon" matSuffix>
        search
      </mat-icon>
    </mat-form-field>
    <div style="max-height: 50vh" [style.overflowY]="'scroll'">
      <table mat-table class="mrs-table" aria-label="Elements" [dataSource]="mrsDataSource" matSort
        [matSortActive]="initialSort">
        <div *ngFor="let column of displayedColumns">
          <div *ngIf="!column.isAction ||  (column.isAction && showActionsColumn)">
            <ng-container [matColumnDef]="column.def" [sticky]="true">
              <th mat-header-cell *matHeaderCellDef mat-sort-header [ngStyle]="column.columnStyles">{{column.header}}
              </th>
              <div *ngIf="!column.isAction">
                <td mat-cell *matCellDef="let row" style="cursor: default">
                  <div *ngFor="let rowDef of column.rowDefs">
                    <div [ngClass]="rowDef.className" *ngIf="!rowDef.isHTML && !rowDef.isLink && !rowDef.isIcon">
                      {{row[rowDef.dataName]}}
                    </div>
                    <div [ngClass]="rowDef.className" *ngIf="rowDef.isHTML"
                      [innerHTML]="sanitizer.bypassSecurityTrustHtml(row[rowDef.dataName])"></div>
                    <div [ngClass]="rowDef.className" *ngIf="rowDef.isLink"><a
                        (click)="linkClick(row[rowDef.urlField], $event)" class="cell-link">{{row[rowDef.dataName]}}</a>
                    </div>
                    <div [ngClass]="rowDef.className" *ngIf="rowDef.isIcon">
                      <mat-icon style="padding-left: 0 !important; padding-right: 0 !important"
                        *ngIf="row[rowDef.dataName] === true">{{rowDef.iconNameConditionTrue}}</mat-icon>
                      <mat-icon style="padding-left: 0 !important; padding-right: 0 !important"
                        *ngIf="row[rowDef.dataName] === false">{{rowDef.iconNameConditionFalse}}</mat-icon>
                    </div>
                  </div>
                </td>
              </div>
              <div *ngIf="column.isAction && showActionsColumn">
                <td mat-cell *matCellDef="let row" style="cursor: pointer;">
                  <mat-icon [matMenuTriggerFor]="menu" *ngIf="row.numberOfActions > 1" class="ml-3 mr-3">
                    <mat-menu #menu="matMenu">
                      <div *ngFor="let action of column.actions">
                        <button mat-menu-item (click)="tableAction(action, row)"
                          *ngIf="action.permission && row[action.actionType]">
                          <mat-icon *ngIf="action.icon">{{action.icon}}</mat-icon>
                          {{action.name}}</button>
                      </div>
                    </mat-menu>
                    more_vert
                  </mat-icon>
                  <div *ngIf="row.numberOfActions == 1">
                    <div *ngFor="let action of column.actions">
                      <mrs-btn-tertiary *ngIf="action.permission && row[action.actionType]" [buttonLabel]="action.name"
                        buttonId="actionButton" [iconName]="action.icon" [textColor]="action.textColor"
                        (onClick)="tableAction(action, row)">
                      </mrs-btn-tertiary>
                    </div>
                  </div>
                </td>
              </div>
            </ng-container>
          </div>
        </div>
        <tr mat-header-row *matHeaderRowDef="displayedColumnsDef; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsDef;"></tr>
      </table>
    </div>

    <div class="flex mat-paginator-sticky mat-paginator-container" style="margin-left: auto">
      <div style="flex: 1; background-color: #EFEFEF;"></div>
      <mat-paginator #paginator [length]="mrsDataSource?.data?.length" [pageIndex]="0" [pageSize]="10"
        (page)="setStyles()" [pageSizeOptions]="[5, 10, 25, 50, 100]">
      </mat-paginator>
    </div>
  </div>