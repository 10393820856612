import { Component, Inject, NgZone, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RoutesEnum, RoutingService } from '../../services';
import { InterviewCloseEnum } from '../../enums';
import { InterviewService } from '../../interview.service';
import { PurlService } from '../../services/purl.service';

@Component({
    selector: 'applicant-interview-close',
    templateUrl: 'applicant-interview-close.component.html',
    styleUrls: ['../interview.component.scss']
})
export class ApplicantInterviewCloseComponent implements OnInit {

    accountInfo: any;
    clickSource: any;

    constructor(
    public dialogRef: MatDialogRef<ApplicantInterviewCloseComponent>,
    public interviewService: InterviewService,
    private zone: NgZone,
    private routingService: RoutingService,
    public purlService: PurlService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit() {
        const isCaseCreated = this.interviewService.getInterviewData().caseDetails;
        if (!isCaseCreated) {
            this.dialogRef.close(this.data.clickSource);
        }

        this.accountInfo = this.data.accountInfo;
        if (!this.accountInfo) {
            this.accountInfo = this.interviewService.account;
        }

        this.clickSource = this.data.clickSource;
        this.interviewService.clickSource = this.clickSource;
    }

    close() {
        this.dialogRef.close();
    }

    async interviewOnHold() {
        await this.interviewService.autoSaveInterview(this.accountInfo.id, 'InterviewOnHold', '', this.clickSource);
        this.interviewService.flushInterviewMetrics();
        this.dialogRef.close(this.clickSource);
        this.purlService.isPurlCaseCanceled = true;

        this.zone.run(() => this.routingService.navigateToRoute(RoutesEnum.endOfInterview));
    }

    async discardInterview() {
        this.interviewService.clickSource = InterviewCloseEnum.ApplicantTerminate;
        await this.interviewService.autoSaveInterview(this.accountInfo.id, 'TerminateInterview', 'Applicant Terminated',InterviewCloseEnum.ApplicantTerminate);
        this.interviewService.flushInterviewMetrics();
        this.dialogRef.close(this.clickSource);
        this.purlService.isPurlCaseCanceled = true;

        this.zone.run(() => this.routingService.navigateToRoute(RoutesEnum.endOfInterview));
    }

}
