import {Component, OnInit, Input} from '@angular/core';
import {AppService} from "../../../app.service";
import * as _ from "lodash";

import { Account } from '../../../models';
import { ConfirmationDialogService } from '@Services';

@Component({
    selector: 'account-underwriting',
    host: {'class': 'd-flex flex-row flex-fill'},
    templateUrl: './account-underwriting.component.html',
    styleUrls: ['./account-underwriting.component.scss']
})
export class AccountUnderwritingComponent implements OnInit {
    @Input() accountInfo: Account;
    @Input() rules;
    labels: UWLabel[] = [];
    tags: any = [];

    constructor(
        private appService: AppService,
        private confirmationService: ConfirmationDialogService
    ) {
    }

    ngOnInit() {
        this.getUwTags();
        this.getUwLabels();
        this.rules.unshift({
            id: this.appService.emptyGUID,
            name: ' None'
        });
    }

    checkACL(permissionType, redirect?) {
        return this.appService.checkACL("Accounts", permissionType, redirect);
    }

    getLabels() {
        this.appService.getData(`UwdecisionLabel/${this.accountInfo.workflowId}`).subscribe(
            data => {
                if (data.status == "success") {
                    this.labels = data.data;
                }
            }
        );
    }

  addLabel() {
      const newLabels = this.labels.filter(note => !note.id);

        if (!newLabels.length) {

          const newLabel:UWLabel = {
              id: "",
              name: "",
              ruleId: "",
              workFlowId: this.accountInfo.workflowId,
              summaryMessage: ""
          };

            this.labels = [newLabel, ...this.labels];
        } else {
            this.appService.showMsg("warning", "Please save new UW Decision Label, then add another ...");
        }
    }

    deleteLabel(label, labelIndex) {
        this.confirmationService.confirm({
            message: "Are you sure that you want to delete this label?",
            key: "uwLabelDelete",
            accept: () => {
                if (label.id) {
                    this.appService.deleteData(`UwdecisionLabel/${label.id}/WorkFlow/${label.workFlowId}`).subscribe(
                        data => {
                            if (data.status == "success") {
                                this.getLabels();
                                this.appService.showMsg("success", "UW Decision Label Removed Successfully ...");
                            }
                        }
                    );
                } else {
                    this.labels.splice(labelIndex, 1);
                    this.appService.showMsg("success", "UW Decision Label Removed Successfully ...");
                }
            },
            showCancel: true
        });
    }

    changeTagOrder(from, to) {
        this.tags.splice(to, 0, this.tags.splice(from, 1)[0]);
        this.reOrderTags();
    }

  reOrderTags() {
      const tagsWithOrder = [];
      this.tags.forEach((tag, index) => {
          tagsWithOrder.push({
              id: tag.id,
              tagName: tag.tagName,
              uwdecisionTagDetails: tag.uwdecisionTagDetails,
              workFlowId: this.accountInfo.workflowId,
              order: index + 1
          });
      });
      console.log(tagsWithOrder);
      this.appService.postData(`UwdecisionLabel/ReorderDecisionTags/${this.accountInfo.workflowId}`, tagsWithOrder, true, false).subscribe(
          data => {
              if (data.status == "success") {
                  this.appService.showMsg("success", "Order changed...");
              } else {
                  this.appService.showMsg("error", data.message);
              }
          }
      );
  }

    changeLabelOrder(from, to) {
        this.labels.splice(to, 0, this.labels.splice(from, 1)[0]);
        this.reOrderLabels();
    }

  reOrderLabels() {
      const labelsWithOrder = [];
      this.labels.forEach((label, index) => {
          labelsWithOrder.push({
              id: label.id,
              name: label.name,
              ruleId: label.ruleId,
              workFlowId: this.accountInfo.workflowId,
              order: index + 1
          });
      });
      console.log(labelsWithOrder);
      this.appService.postData("UwdecisionLabel/Reorder", labelsWithOrder, true, false).subscribe(
          data => {
              if (data.status == "success") {
                  this.appService.showMsg("success", "Order changed...");
              } else {
                  this.appService.showMsg("error", data.message);
              }
          }
      );
  }

    getUwLabels() {
        this.appService.getData(`UwdecisionLabel/${this.accountInfo.workflowId}`).subscribe(
            data => {
                if (data.status == "success") {
                    this.labels = data.data;
                }
            }
        );
    }


    getUwTags() {
        this.appService.getData(`UwdecisionLabel/DecisionTags/${this.accountInfo.workflowId}`).subscribe(
            data => {
                if (data.status == "success") {
                    this.tags = data.data;
                }
            }
        );
    }

  addTag() {
      const newTags = this.tags.filter(note => !note.id);

        if (!newTags.length) {

          const newTag = {
              id: "",
              name: "",
              ruleId: "",
              workFlowId: this.accountInfo.workflowId
          };

            this.tags = [newTag, ...this.tags];
        } else {
            this.appService.showMsg("warning", "Please save new UW Decision Tag, then add another ...");
        }
    }

    deleteTag(tag, tagIndex) {
        this.confirmationService.confirm({
            message: "Are you sure that you want to delete this tag?",
            key: "uwTagDelete",
            accept: () => {
                if (tag.id) {
                    this.appService.deleteData(`UwdecisionLabel/DecisionTag/${tag.id}`).subscribe(
                        data => {
                            if (data.status == "success") {
                                this.getUwTags();
                                this.appService.showMsg("success", "UW Decision Tag Removed Successfully ...");
                            }
                        }
                    );
                } else {
                    this.tags.splice(tagIndex, 1);
                    this.appService.showMsg("success", "UW Decision Tag Removed Successfully ...");
                }
            },
            showCancel: true
        });
    }


}

export class UWLabel {
    id: string;
    name: string;
    ruleId: string;
    workFlowId: string;
    summaryMessage: string;
}
