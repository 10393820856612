import { Injectable } from '@angular/core';
import * as _ from 'lodash';

import { AppService } from '../../app.service';
import { MatDialog } from '@angular/material/dialog';
import { RolesEnum } from '../../enums';
import { ApplicantInterviewCloseComponent } from './applicant-interview-close.component';
import { InterviewCloseComponent } from './interview-close.component';

@Injectable()
export class EndOfInterviewHandler{

    constructor(
        public appService: AppService,
        public dialog: MatDialog
    ) {}

    handleClose(accountInfo, clickSource, isTerminate){
        const userRole = this.appService.getUserRoleName();

        const isApplicant = userRole === RolesEnum.Applicant;
        if (isApplicant){
            this.dialog.open(ApplicantInterviewCloseComponent, {
                width: '300px',
                data: {accountInfo: accountInfo, clickSource: clickSource, isTerminate: isTerminate},
                disableClose: false
            });
        }else{
            this.dialog.open(InterviewCloseComponent, {
                width: '300px',
                data: {accountInfo: accountInfo, clickSource: clickSource, isTerminate: isTerminate},
                disableClose: false
            });
        }
    }
}
