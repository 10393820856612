<div class="background baseBox">
    <uw-decision
        [baseProps]="uwBaseProps"
        [showViewDecision]="showViewDecision"
        [uwDecisionNote]="uwDecisionNote"
        [uwLabelName]="cachedCase.uwLabelName"
        [labels]="labels"
        (onShowDecision)="handleShowEditDecision()"
        (onHideDecision)="handleHideUpdateDecision()"
        (onSaveDecision)="handleSaveDecision($event)"
        (onSectionSaved)="handleSectionSaved()">
    </uw-decision>

    <uw-requirements
        [baseProps]="uwBaseProps"
        (onIntegrationRequirementRun)="handleIntegrationRequirementRun($event)"></uw-requirements>

    <uw-notes [baseProps]="uwBaseProps"></uw-notes>
</div>