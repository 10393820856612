import { Route } from '@angular/router';
import { EditQueuesComponent } from 'app/new-business-workbench/edit-queues/edit-queues.component';
import { CanActivateCnbw } from 'app/new-business-workbench/guards/canActivateCnbw.guard';

import { ManagementQueuesSwitcherComponent } from '../../management-queues/management-queues-switcher.component';
import { CanNavigateAwayWithConfirmGuard } from '../guards/canNavigateAway.guard';
import * as Guards from '../../services/guards';
import * as Resolvers from '../../services/resolvers';

export default {
    path: 'casemanager',
    canActivate: [Guards.AclGuardService],
    data: {
        permission: 'Case',
        permissionType: 'R',
        rolesNotAllowed: ['Applicant']
    },
    resolve: {
        accounts: Resolvers.AccountsResolverService
    },
    children: [
        {
            path: '',
            redirectTo: 'view',
            pathMatch: 'full'
        },
        {
            path: 'view',
            // Switch between "ManagementQueues-v1" and new "CNBW" depending on feature flags
            component: ManagementQueuesSwitcherComponent,
            canActivate: [Guards.AclGuardService],
            data: {
                permission: 'Case',
                permissionType: 'R',
                rolesNotAllowed: ['Applicant']
            },
            resolve: {
                accounts: Resolvers.AccountsResolverService
            }
        },
        {
            path: 'edit',
            // Switch Redirect to "/view" if no access via feature flags
            component: EditQueuesComponent,
            resolve: {
                accounts: Resolvers.AccountsResolverService
            },
            canActivate: [Guards.AclGuardService, CanActivateCnbw],
            canDeactivate: [CanNavigateAwayWithConfirmGuard],
            data: {
                permission: 'Case Manager: Configure',
                permissionType: 'W',
                rolesNotAllowed: ['Applicant']
            }
        },
    ]
} as Route;
