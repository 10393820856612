import { Component, OnInit, Input } from '@angular/core';
import { AppService } from '../../../../app.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Account } from '../../../../models';

@Component({
    selector: 'document-signing',
    host: { 'class': 'd-flex flex-row flex-fill' },
    templateUrl: './document-signing.component.html'
})
export class DocumentSigningComponent implements OnInit {
    @Input() accountInfo: Account;
    @Input() rules;
    @Input() mapKeys;

    signingProviderForm: UntypedFormGroup;
    params = {};
    settings = [];

    constructor(
        private appService: AppService,
        private _fb: UntypedFormBuilder
    ) {
    }

    async ngOnInit() {
        this.signingProviderForm = this._fb.group({
            documentSigningProvider: '',
        });

        this.getSettings();
    }

    checkACL(permissionType, redirect?) {
        return this.appService.checkACL('Accounts', permissionType, redirect);
    }

    getSettings() {
        this.appService.getData(`AccountSettings/${this.accountInfo.id}`).subscribe(
            data => {
                if (data.status === 'success') {
                    if (data.data && data.data.length) {
                        this.params = {};
                        const signingProvider = data.data.find(s => s.name === 'documentSigningProvider');

                        if (signingProvider) {
                            this.settings.push(signingProvider);
                            this.params[signingProvider.name] = signingProvider.value;
                        }

                        this.signingProviderForm.patchValue(this.params);
                    }
                }
            }
        );
    }

    save() {
        const params = [];

        Object.keys(this.signingProviderForm.value).forEach(key => {
            if (key !== 'accountId') {
                const newValue = this.signingProviderForm.get(key).value;
                const item = {
                    accountId: this.accountInfo.id,
                    name: key,
                    value: newValue != null ? newValue.toString() : null
                };

                if (this.settings && this.settings !== null) {
                    const setting = this.settings.find(s => s.name === key);

                    if (setting) { item['id'] = setting.id; }
                }

                params.push(item);
            }
        }, this);

        this.appService.postData('AccountSettings', params)
            .subscribe(
                data => {
                    if (data.status === 'success') {

                        this.appService.showMsg('success', 'Saved successfully ...');
                    } else {
                        this.appService.showMsg('error', data.message);
                    }
                });
    }
}
