import { Injectable } from '@angular/core';
import { ApiVersionEnum } from '../enums/global/api-versions.enum';
import { Configuration } from './config-model';

@Injectable()
export abstract class IConfigService {
    abstract load(url?: string, appConfig?: Configuration);
    abstract getConfiguration(): Configuration;
    abstract getPublicApiUrl(apiVersion: ApiVersionEnum): string;
    abstract getWhiteLabelConfiguration(): any;
    abstract getFeatureFlags(): any;
    abstract getUnderwriterApiUrl():string;
    abstract getAllowedLogoutRoutes():string;
}
