import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppService } from '../../../../../app.service';
import { AccountEvent, AccountMessage } from '../../../../../models';

@Component({
    selector: 'app-event-action-gtl-submission',
    templateUrl: './event-action-gtl-submission.component.html',
    styleUrls: ['./event-action-gtl-submission.component.scss']
})
export class EventActionGTLSubmissionComponent implements OnInit {

    public webhookForm: FormGroup;
    formSubmitted: boolean = false;
    event: AccountEvent;
    template: any;
    messageId: any;
    secretKeys: any;
    headersForm: FormArray;
    mapKeys: any;
    nonListMapKeys: any;

    constructor(private _fb: FormBuilder,
    public appService: AppService,
    public dialogRef: MatDialogRef<EventActionGTLSubmissionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit() {
        this.event = this.data.event;
        this.mapKeys = this.data.mapKeys;
        this.nonListMapKeys = this.data.nonListMapKeys;
        this.webhookForm = this._fb.group({
            url: ['', Validators.required],
            experationMapKeyId: ["", Validators.required],
            agentMapKeyId: ["", Validators.required],
            apiKey: ["", Validators.required],
            authKeyMapKeyId: ["", Validators.required],
            agentCodeMapKeyId: ["", Validators.required],
            agentKeyMapKeyId: ["", Validators.required],
            assessmentResponseMapKeyId: ["", Validators.required],
            stateMapKeyId: ["", Validators.required],
            productCategoryMapKeyId: ["", Validators.required],
            productCodeMapKeyId: ["", Validators.required],
            content: ['', Validators.required],
            retryAttempts: ['5'],
            expirationHours: ['1'],
            errorCaseOnFailure: [false]
        });
        this.setupForm();

    }

    setupForm() {
        if (this.event.accountMessage && this.event.accountMessage.template) {
            this.template = JSON.parse(this.event.accountMessage.template);

            this.webhookForm.patchValue({
                url: this.template?.url,
                experationMapKeyId: this.template?.experationMapKeyId,
                agentMapKeyId: this.template?.agentMapKeyId,
                apiKey: this.template?.apiKey,
                authKeyMapKeyId: this.template?.authKeyMapKeyId,
                agentKeyMapKeyId: this.template?.agentKeyMapKeyId,
                agentCodeMapKeyId: this.template?.agentCodeMapKeyId,
                assessmentResponseMapKeyId: this.template?.assessmentResponseMapKeyId,
                stateMapKeyId: this.template?.stateMapKeyId,
                productCategoryMapKeyId: this.template?.productCategoryMapKeyId,
                productCodeMapKeyId: this.template?.productCodeMapKeyId,
                content: this.template?.content,
                retryAttempts: this.template?.retryAttempts,
                expirationHours: this.template?.expirationHours,
                errorCaseOnFailure: this.template?.errorCaseOnFailure
            });
        }
    }

    get headers() {
        return this.webhookForm.get('headers') as FormArray;
    }
    set headers(val: FormArray) {
        this.headers = val;
    }

    addHeader(key?: string, value?: string) {
        this.headers.push(this._fb.group({
            key: key ?? '',
            value: value ?? ''
        }));
    }

    saveAccountMessage() {
        const message = this.webhookForm.value;
        this.formSubmitted = true;

        if (this.webhookForm.valid) {
            if (this.event.accountMessage) {
                this.event.accountMessage.template = JSON.stringify(message);
            } else {
                const accountMessage: AccountMessage = {
                    id: this.messageId,
                    template: JSON.stringify(message)
                };
                this.event.accountMessage = accountMessage;
            }

            this.dialogRef.close();
        } else {
            this.webhookForm.markAsTouched();
            this.webhookForm.controls.content.markAsTouched();
        }
    }
}
