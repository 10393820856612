export enum FeatureToggle {
    AutoSelectInterview = 'Config.Interview.AutoSelectInterview',
    CaseDocumentsAttachmentsUploadInChunks = 'Case.Documents.Attachments.UploadInChunks',
    CaseNotesToggleHtmlEditor = 'Case.Notes.ToggleHtmlEditor',
    CaseExecutionLog = 'Case.ExecutionLog',
    CaseRuntime20 = 'Case.Runtime2.0',
    CaseRuntimePrefillReflexiveInRepeats = "Case.Runtime.Prefill.PopulateReflexivesInRepeatblock",
    CaseRuntimeProcessPreActions = 'Case.Runtime.ProcessPreActions',
    CaseHannoverReHideGTLTermCTLRiderEmptyElement = 'Case.HannoverRe.HideGTLTermCTLRiderEmptyElement',
    CaseUnderwritersUpdateDecisionClient = 'Case.Underwriters.UpdateDecision.Client',
    CaseFilterableListsMultipleVariants = 'Case.FilterableLists.MultipleVariants',
    CaseUseDocusignV2 = 'Case.UseDocusign.V2',
    ConfigAccountSettingsUWWorkBench = 'Config.AccountSettings.UWWorkBench',
    ConfigAccountSettingsWorkbenchConfiguration = 'Config.AccountSettings.WorkbenchConfiguration',
    ConfigAccountSettingsWorkbenchRequirementsClient = 'Config.AccountSettings.WorkbenchRequirements.Client',
    ConfigInterviewAura = 'Config.Interview.Aura',
    ConfigInterviewMoveQuestionBetweenSections = 'Config.Interview.MoveQuestionBetweenSections',
    ConfigInterviewPrefillQuestions = 'Config.Interview.PrefillQuestions',
    ConfigMapkeyUsedIn = 'Config.Mapkeys.UsedIn',
    ConfigMapkeyHotjarSurvey = 'Config.Mapkeys.UsedIn.HotjarSurvey',
    ConfigUseFilteredVmks = 'Config.Interview.UseFilteredVmks',
    ConfigMapkeysAccountDynamicListClient = 'Config.Mapkeys.Account.DynamicList.Client',
    ConfigRulesUsedIn = 'Config.Rules.UsedIn',
    ConfigRulesUsedInIncludeWorkbench = 'Config.Rules.UsedIn.IncludeWorkbench',
    ConfigSettingsWorkbenchIncludeInCasePackageClient ='Config.Settings.Workbench.IncludeInCasePackage.Client',
    CheckInterviewStatusV2 = 'Case.Summary.InterviewStatus',
    DevelopmentFeatureAuditUser = 'Development.Feature.Audit.User',
    FilterableStaticMapkeys = 'Config.Mapkeys.FilterableStaticMapkeys',
    FilterableWorkflow = 'Config.Interview.FilterableWorkflow',
    GlobalCaseManagerV2AssignNextCaseClient = 'Global.CaseManager.V2.AssignNextCase.Client',
    GlobalConfigSetDroppedSectionAsActive = 'Global.Config.SetDroppedSectionAsActive',
    GlobalDisplayEnvironment = 'Global.DisplayEnvironmentName',
    GlobalDisplayQuestionLastUpdated = 'Global.DisplayQuestionLastUpdated',
    GlobalCaseManagerV2ConfigColumns = 'Global.CaseManager.V2.ConfigColumns',
    GlobalFeaturePreviewDocumentOpenInNewTabClient = 'Global.Feature.PreviewDocument.OpenInNewTab.Client',
    GlobalFeaturePreviewDocumentTiffDownloadClient = 'Global.Feature.PreviewDocument.TiffDownload.Client',
    GlobalFilterableStaticListMCMAOnBlur = 'Global.FilterableStaticList.MCMA.OnBlur',
    GlobalInterviewWaitForSaveAsPromise = 'Global.Interview.WaitForSaveAsPromise',
    GlobalLoginNewProcessMessage = 'Global.Login.NewProcessMessage',
    GlobalMCMAReflexives = 'Global.MCMA.Reflexives',
    GlobalUseAuthenticationV2 = 'Global.UseAuthenticationV2',
    GlobalUseLoginNewFormat = 'Global.Use.LoginNewFormat',
    GlobalSettingsAutoCancelByRule = 'Global.Settings.AutoCancelByRule',
    GlobalTextInterviewToApplication = 'Global.Text.InterviewToApplication',
    GlobalUseDocGenV2 = 'Global.DocGen.V2',
    GlobalVariantsResetJSONOnVariantFilter = 'Global.Variants.ResetJSON.OnVariantFilter',
    HandleHttpErrorResponseForSSNDupeCheck = 'Global.HandleHttpErrorResponseForSSNDupeCheck',
    SystemEventEnableCaseStatusV2 = "SystemEvent.EnableCaseStatusV2",
    UWWorkbenchDirectDownloadAttachmentClient = 'UWWorkbench.DirectDownloadAttachment.Client',
    UWWorkbenchFeatureReviewDocumentsClients = 'UWWorkbench.Feature.ReviewDocuments.Clients',
    UWWorkbenchHideUWNotesInCaseNotesClient = 'UWWorkbench.HideUWNotesInCaseNotes.Client',
    UWWorkbenchHiddenNotesAccountSetting = 'UWWorkbench.HiddenNotes.AccountSetting',
    UWWorkbenchEnableConfigurationOfIntegrationsAsRequirements = 'UWWorkbench.Requirements.EnableConfigurationOfIntegrationsAsRequirements.Client',
    UWWorkbenchRequirementFollowUpDatePreservedInEndState = 'UWWorkbench.Requirements.FollowupDatePreservedInEndState.Client',
    UWWorkbenchRequirementsHideCancelledClient = 'UWWorkbench.Requirements.HideCancelled.Client',
    UWWorkbenchRequirementsHideDeleteClient = 'UWWorkbench.Requirements.HideDelete.Client',
    UWWorkbenchRequirementsHideEndStateToEndStateTransitionClient = 'UWWorkbench.Requirements.HideEndStateToEndStateTransition.Client',
    UWWorkbenchRequirementsHidePendingClient = 'UWWorkbench.Requirements.HidePending.Client',
    UWWorkbenchRequirementsHideSaaRConfigClient = "UWWorkbench.Requirements.HideSaaRConfig.Client",
    UWWorkbenchRequirementsHideRuleConfigClient = "UWWorkbench.Requirements.HideRuleConfig.Client",
    UWWorkbenchRequirementsEnableSummarizeDocumentsClient = "UWWorkbench.Requirements.EnableSummarizeDocuments.Client",
    UWWorkbenchRequirementsReviewedClient = 'UWWorkbench.Requirements.Reviewed.Client',
    UWWorkbenchUpdateDecisionMultiUserClient = 'UWWorkbench.UpdateDecision.MultiUser.Client',
    GlobalOrderDetailReportUpdates = "Global.Reports.OrderDetailReportUpdates",
    GlobalReportsBillingReportClientAccountSplit = "Global.Reports.BillingReportClientAccountSplit",
    GlobalReportsCallDetailReportClientAccountSplit = "Global.Reports.CallDetailReportClientAccountSplit",
    GlobalReportsCaseDetailReportClientAccountSplit = "Global.Reports.CaseDetailReportClientAccountSplit",
    GlobalReportsTransmissionReportClientAccountSplit = "Global.Reports.TransmissionReportClientAccountSplit",
    ConfigUseDistributedCache = 'Config.UseDistributedCache',
    ConfigCacheAccountStrategySetting = 'Config.CacheAccount.StrategySetting',
    ConfigInterviewAddNoteForVariants = 'Config.Interview.AddNoteForVariants',
    CaseRuntimeHeightDefaultToBlank = "Case.Runtime.Height.DefaultToBlank",
    CaseRuntimeOptimizeRuleAndVmkProcessingSetting = "Case.Runtime.OptimizeRuleAndVmkProcessingSetting",
    CaseWorkflowAttachDocumentsToConfigureEmailEvent = 'Case.Workflow.AttachDocumentsToConfigureEmailEvent',
    GlobalPreFillSequenceContainsNoElementsBugFix = "Global.PreFill.SequenceContainsNoElementsBugFix",
    GlobalMagnumListenToCaseResumedEvent = "Global.Magnum.ListenToCaseResumedEvent",
    GlobalMagnumLogFrontEndDetails = "Global.Magnum.LogFrontEndDetails",
    GlobalInterviewWaitForIntegrationPrePopup = "Global.Interview.WaitForIntegration.PrePopup",
    CaseQuestionOnlyFilterListsWhenLoading = "Case.Question.OnlyFilterListsWhenLoading",
    GlobalAppLoader = "Global.App.Loader",
    CaseRuntimeProcessVmksPerformanceUpdates = "Case.Runtime.ProcessVmks.PerformanceUpdates"
}

