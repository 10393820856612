import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppService } from '../../../../app.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormArray } from '@angular/forms';
import { AccountEvent } from '../../../../models';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { EmbeddedDocument } from '../../../../models/config/embedded-document.interface';
import * as _ from 'lodash';

@Component({
    selector: 'account-event-action',
    host: { 'class': 'd-flex flex-row flex-fill custom-scrollbar bg-white' },
    templateUrl: './embedded-documents-settings.component.html'
})
export class EmbeddedDocumentsSettingsComponent implements OnInit {

    documents: any;
    document: any;

    public embeddedDocumentForm: UntypedFormGroup;
    template: any;
    messageId: any;
    formSubmitted: boolean = false;
    event: AccountEvent;
    mapKeys: any;
    embeddedDocuments: EmbeddedDocument[] = [];
    selectedControl: any = null;
    caretPos: number =0;

    constructor(
    private appService: AppService,
    private _fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<EmbeddedDocumentsSettingsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit() {
        this.documents = this.data.documents;
        this.document = this.data.selectedDocument;

        this.embeddedDocumentForm = this._fb.group({
            embeddedDocuments: this._fb.array([])
        });

        const objectProperties = _.keyBy(this.document.objectProperties, 'key');
        const embeddedDocumentProperty = objectProperties['embeddedDocuments'];
        this.embeddedDocuments = embeddedDocumentProperty != null ? JSON.parse(embeddedDocumentProperty.value) : [];

        const control = <UntypedFormArray>this.embeddedDocumentForm.get('embeddedDocuments');

        for (const embeddedDocument of this.embeddedDocuments) {
            control.push(this.createItem(embeddedDocument));
        }
    }

    add() {
        const control = <UntypedFormArray>this.embeddedDocumentForm.get('embeddedDocuments');
        const newItem: EmbeddedDocument = { documentId: '', order: control.length, markup: '' };
        const formGroup = this.createItem(newItem);
        control.push(formGroup);

        this.selectedControl = formGroup;
    }

    delete(index) {
        const control = <UntypedFormArray>this.embeddedDocumentForm.get('embeddedDocuments');
        control.removeAt(index);
    }

    resetMarkup(control: UntypedFormGroup) {
        const documentId = control.controls['documentId'].value;
        const embeddedDocument = this.embeddedDocuments.find(x => x.documentId === documentId);

        if (embeddedDocument) {
            control.controls['markup'].setValue(embeddedDocument.markup);
        } else {
            control.controls['markup'].setValue('');
        }
    }

    createItem(item: EmbeddedDocument) {
        return this._fb.group({
            documentId: [item.documentId, Validators.required],
            markup: [item.markup, Validators.required],
            order: [item.order]
        });
    }

    dropDocument(event: CdkDragDrop<string[]>) {
        const formArray = <UntypedFormArray>this.embeddedDocumentForm.get('embeddedDocuments');
        moveItemInArray(formArray.controls, event.previousIndex, event.currentIndex);

        const formGroup = formArray.controls as UntypedFormGroup[];
        formGroup.forEach((control, index) => {
            control.controls['order'].setValue(index + 1);
        });
    }

    save() {
        if (this.embeddedDocumentForm.valid){
            const formArray = <UntypedFormArray>this.embeddedDocumentForm.get('embeddedDocuments');
            const objectProperties: EmbeddedDocument[] = [];

            for (const formGroup of formArray.controls as UntypedFormGroup[]) {
                const embeddedDocument: EmbeddedDocument = {
                    documentId: formGroup.controls['documentId'].value,
                    markup: formGroup.controls['markup'].value,
                    order: formGroup.controls['order'].value
                };
                objectProperties.push(embeddedDocument);
            }

            const result = [{
                key: 'embeddedDocuments',
                value: JSON.stringify(objectProperties)
            }];

            this.dialogRef.close({ event: 'EmbeddedDocuments', data: result });
        } else {
            this.appService.showMsg("error", "Please fill in all required fields", false);
        }

    }

    getDocumentLabel(control: UntypedFormGroup) {
        if (control) {
            const documentId = control.get('documentId').value;
            if (documentId != null && this.documents) {
                const document = this.documents.find(x => x.id === documentId);
                if (document) {
                    return document.name;
                }
            }
        }
    }

    selectDocument(control) {
        if (this.selectedControl === control) {
            this.selectedControl = null;
        } else {
            this.selectedControl = control;
        }
    }

    getCaretPos(oField) {
        if (oField.selectionStart || oField.selectionStart == '0') {
            this.caretPos = oField.selectionStart;
        }
    }

    addText(text, control) {
        const markupText = control.get('markup').value;
        var output = [markupText.slice(0, this.caretPos), `{{${text}}}`, markupText.slice(this.caretPos)].join('');
        control.get('markup').setValue(output);
    }
}
