import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppService } from "../../../app.service";
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import * as _ from "lodash";

import { AccountDataService, ConfirmationDialogService, RoutesEnum } from '../../../services';
import { Account } from '../../../models';
import { lastValueFrom } from 'rxjs';

@Component({
    selector: 'account-summary',
    host: { 'class': 'd-flex flex-row flex-fill' },
    templateUrl: './account-summary.component.html',
    styleUrls: ['./account-summary.component.scss']
})
export class AccountSummaryComponent implements OnInit {
    accountForm: UntypedFormGroup;
    addContactForm: UntypedFormGroup;

    accountTypes: any;
    status: any;
    showContactForm = false;
    editForm = false;
    contactsCombo: any[];
    contacts: any;
    accountContacts: any;
    preferredName: any = [
        { id: '0', value: 'Policy Number', code: 'P' },
        { id: '1', value: 'Certificate', code: 'C' }
    ];
    contactTypeCombo: any;
    showAlreadyAddedMsg = false;

    @Input() accountInfo: Account;
    @Input() isNewAccount: boolean;
    @Input() clientId: string;

    @Output() accountSaved: EventEmitter<Account> = new EventEmitter<Account>();

    constructor(
        private accountDataService: AccountDataService,
        private appService: AppService,
        private confirmationService: ConfirmationDialogService) {

        this.accountForm = new UntypedFormGroup({
            code: new UntypedFormControl({ value: '', disabled: true }),
            name: new UntypedFormControl('', [Validators.required]),
            clientId: new UntypedFormControl(''),
            accountTypeId: new UntypedFormControl(''),
            statusId: new UntypedFormControl('00000000-0000-0000-0000-000000000001'),
            preferredName: new UntypedFormControl(''),
            seedPolicyNumber: new UntypedFormControl(''),
            policyNumberPrefix: new UntypedFormControl('')
        });

        this.addContactForm = new UntypedFormGroup({
            contactId: new UntypedFormControl('', [Validators.required]),
            clientId: new UntypedFormControl(''),
            primaryContact: new UntypedFormControl(''),
            contactTypeId: new UntypedFormControl('', [Validators.required]),
        });
    }

    ngOnInit() {
        this.checkACL("R", RoutesEnum.dashboard);
        this.accountTypes = this.appService.combos['AccountTypes'];
        this.status = this.appService.combos['Status'];
        this.contactTypeCombo = this.appService.combos['ContactTypes'];

        if (!this.isNewAccount) {
            this.getContactsByAccId();
        } else {
            this.accountForm.controls["preferredName"].setValue('Policy Number');
            this.editForm = true;
        }


    }

    editAccount() {
        // latest account information.
        this.accountForm.patchValue(this.accountInfo);
        if (this.accountInfo.seedPolicyNumber) {
            this.accountForm.get('seedPolicyNumber').disable();
        }
        this.editForm = true;
    }

    getContacts() {

        this.appService.getData('Contacts/' + this.accountInfo.clientId + '/Client').subscribe(
            data => {
                this.contactsCombo = [];
                this.contacts = data.data;
                if (this.contacts) {
                    this.contacts.forEach(element => {
                        let found = false;
                        for (let i = 0; i < this.accountContacts.length; i++) {
                            if (this.accountContacts[i].contactsId == element.id) {
                                found = true;
                                break;
                            }
                        }
                        if (!found) {
                            this.contactsCombo.push({ id: element.id, name: element.firstName + ' ' + element.lastName });
                        }
                        // this.contactsCombo.push({ id: element.id, name: element.firstName + ' ' + element.lastName })
                    });
                    // console.log(this.contactsCombo);
                }
            }
        );
    }
    getContactsByAccId() {
        this.appService.getData('Accounts/' + this.accountInfo.id + '/Contacts').subscribe(
            data => {
                this.accountContacts = data.data;
                if (this.accountContacts) {
                    this.accountContacts.forEach(element => {
                        const contactTypeInfo = _.find(this.contactTypeCombo, ['id', element['contactTypeId']]);
                        element.contacts['contactTypeId'] = element['contactTypeId'];
                        element.contacts['contactTypeValue'] = contactTypeInfo.name;
                        element.contacts['accountId'] = element['accountId'];
                        element.contacts['accountContactId'] = element['id'];
                    });
                }
                this.getContacts();
            }
        );
    }
    checkACL(permissionType, redirect?) {
        return this.appService.checkACL("Accounts", permissionType, redirect);
    }
    saveAccount() {
        // Required for adding new account
        this.accountForm.value.clientId = this.clientId;
        if (!this.isNewAccount) {
            this.accountForm.value.code = this.accountInfo.code;
            this.accountForm.value.id = this.accountInfo.id;
        }

        if (this.accountForm.valid) {
            this.accountDataService.saveAccount(this.accountForm.value, this.isNewAccount)
                .subscribe(async (account) => {
                    if (this.isNewAccount) {
                        // clear account cache, pull new ones.
                        await lastValueFrom(this.accountDataService.getAccounts(false));
                    }

                    this.accountSaved.emit(account);

                    this.editForm = false;
                    this.accountForm.controls['code'].setValue(account.code);

                    this.appService.showMsg('success', 'Saved successfully...');
                });
        }
    }

    addAccountContact() {
        if (this.contactsCombo.length > 0) {
            this.showContactForm = true;
        } else {
            this.appService.showMsg("info", "All the contacts are added already. No more contacts to add");
        }
    }
    addContact() {
        const contact = _.find(this.contactsCombo, ['name', this.addContactForm.value.contactId]);
        const params = {
            accountId: this.accountInfo.id,
            contactsId: contact.id,
            contactTypeId: this.addContactForm.value.contactTypeId,
        };

        this.appService.postData('Accounts/AccountContacts', params).subscribe({
            next: (data) => {
                if (data.status == "success") {
                    this.showContactForm = false;
                    this.addContactForm.reset();
                    this.appService.showMsg("success", "Saved successfully ...");
                    this.getContactsByAccId();
                } else {
                    this.appService.showMsg("error", data.message);
                }
            },
            error: () => {
                this.addContactForm.reset();
            }
        });
    }
    deleteContact(contact) {
        this.confirmationService.confirm({
            message: `Are you sure that you want to delete this contact?`,
            key: 'summaryConfirm',
            accept: () => {
                this.appService.deleteData(`Accounts/${contact.accountContactId}/AccountContacts`).subscribe(
                    data => {
                        if (data.status == "success") {
                            this.appService.showMsg("success", data.message);
                            this.getContactsByAccId();
                        }
                    }
                );
            },
            showCancel: true
        });
    }
}
