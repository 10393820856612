import { FeatureToggle } from "@Enums";
import { FeatureManagerService } from "@Services";
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from "@angular/material/dialog";
import { TableColumnDefinition } from "app/shared/components/mrs-table/models/table-models";
import * as $ from 'jquery';
import { AppService } from '../../app.service';
import { CallDialogComponent } from "../../components/call-dialog/call-dialog.component";
import { Account, AccountsByClient } from '../../models';
import moment = require('moment');

@Component({
    selector: 'call-detail-report',
    host: { 'class': 'd-flex flex-row flex-fill custom-scrollbar' },
    templateUrl: './call-detail-report.component.html',
    styleUrls: ['./call-detail-report.component.scss']
})
export class CallDetailReportComponent implements OnInit {

    public accounts: any = [];
    public callsForm: UntypedFormGroup;
    public clients: AccountsByClient;
    noDataPlaceholder = false;
    calls: any = [];
    storedRecords: any = [];
    tableConfig: any = [];
    timeZone: string;
    selectedAccount: Account;
    @Input() scrollWidth;
    @Input() scrollHeight;

    searchValue: any;
    displayedColumns: TableColumnDefinition[];
    reportData: any = [];
    searchableFields: [];
    clientAccountOverride = false;

    constructor(
        private appService: AppService,
        private _fb: UntypedFormBuilder,
        private dialog: MatDialog,
        private featureManagerService: FeatureManagerService
    ) {
        this.timeZone = this.appService.getTimeZone();
        this.clientAccountOverride = this.featureManagerService.getByName(FeatureToggle.GlobalReportsCallDetailReportClientAccountSplit).enabled;
    }

    ngOnInit() {
        $('#sidenav').removeClass('hidenav');
        this.callsForm = this._fb.group({
            startDate: ['', Validators.required],
            endDate: ['', Validators.required],
            account: ['', Validators.required]
        });

        this.tableConfig = this.getTableConfig();
    }

    getSelectedClientId(): string {
        this.selectedAccount = this.callsForm.get('account').value;
        return this.selectedAccount.clientId;
    }

    getParams() {
        const params = JSON.parse(JSON.stringify(this.callsForm.value));
        params.startDate = new Date(params.startDate);
        const end = new Date(params.endDate);
        end.setHours(23, 59, 59, 999);
        params.endDate = end;
        params.accountId = params.account.id;
        return params;
    }

    getCalls() {
        if (this.callsForm.valid) {
            const params = this.getParams();
            this.appService.postData(`Reports/GetCallDetailReport`, params, false, false, this.getSelectedClientId())
                .subscribe(
                    data => {
                        if (data.status === 'success') {
                            this.calls = data.data;

                            this.getTableConfig();
                            this.reportData = data.data.map((data) => ({
                                ...data,
                                startTime: moment(data.startTime).utc().format('MM/DD/yyyy HH:mm:ss'),
                                abandoned: data.abandoned === true ? 'true' : 'false'
                            }));

                        } else {
                            this.calls = [];
                            this.reportData = [];
                        }
                        this.noDataPlaceholder = !this.calls.length;
                    },
                    error => this.appService.showMsg('error', error),
                );
        }
    }

    showPlaybackModal(callCaseId, modalTitle) {
        const dialogRef = this.dialog.open(CallDialogComponent, {
            width: "50%",
            data: {
                caseCallId: callCaseId,
                clientId: this.getSelectedClientId(),
                title: modalTitle
            }
        });
    }

    filterData() {
        if (this.storedRecords.length) {
            const records = this.storedRecords;
            if (records.length > 0 && this.searchValue.length > 0) {
                const filteredRecords = records.filter(o =>
                    Object.keys(o).some((k) => {
                        if (o[k]) {
                            return o[k].toLowerCase().includes(this.searchValue.toLowerCase());
                        }
                    }
                    ));
                this.calls = filteredRecords;
                if (this.calls.length > 0) {
                    this.noDataPlaceholder = false;
                } else {
                    this.noDataPlaceholder = true;
                }
            }
        }
    }

    getTableConfig() {
        this.displayedColumns = [
            {
                def: 'callID',
                header: 'Contact ID',
                sortFields: ['callID'],
                columnStyles:
                {
                    'width': '100px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'callID',
                    }
                ]
            },
            {
                def: 'startTime',
                header: 'Date',
                sortFields: ['startTime'],
                columnStyles:
                {
                    'width': '100px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'startTime',
                    }
                ]
            },
            {
                def: 'ani',
                header: 'ANI',
                sortFields: ['ani'],
                columnStyles:
                {
                    'width': '100px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'ani',
                    }
                ]
            },
            {
                def: 'dnis',
                header: 'DNIS',
                sortFields: ['dnis'],
                columnStyles:
                {
                    'width': '100px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'dnis',
                    }
                ]
            },
            {
                def: 'skill',
                header: 'Skill',
                sortFields: ['skill'],
                columnStyles:
                {
                    'width': '100px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'skill',
                    }
                ]
            },
            {
                def: 'agentName',
                header: 'Agent',
                sortFields: ['agentName'],
                columnStyles:
                {
                    'width': '100px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'agentName',
                    }
                ]
            },
            {
                def: 'preQ',
                header: 'PreQ (secs)',
                sortFields: ['preQ'],
                columnStyles:
                {
                    'width': '100px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'preQ',
                    }
                ]
            },
            {
                def: 'routing',
                header: 'Routing (secs)',
                sortFields: ['routing'],
                columnStyles:
                {
                    'width': '100px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'routing',
                    }
                ]
            },
            {
                def: 'inQ',
                header: 'InQ (secs)',
                sortFields: ['inQ'],
                columnStyles:
                {
                    'width': '100px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'inQ',
                    }
                ]
            },
            {
                def: 'agentTime',
                header: 'Agent Time (mins)',
                sortFields: ['agentTime'],
                columnStyles:
                {
                    'width': '100px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'agentTime',
                    }
                ]
            },
            {
                def: 'abandoned',
                header: 'Abandoned',
                sortFields: ['abandoned'],
                columnStyles:
                {
                    'width': '100px',
                },
                rowDefs: [
                    {
                        isIcon: true,
                        className: '',
                        iconNameConditionTrue: 'check_circle',
                        iconNameConditionFalse: '',
                        dataName: 'abandoned'
                    }
                ]
            },
            {
                def: 'abandonTime',
                header: 'Abandon Time (secs)',
                sortFields: ['abandonTime'],
                columnStyles:
                {
                    'width': '100px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'abandonTime',
                    }
                ]
            },
            {
                def: 'externalId',
                header: 'Order ID',
                sortFields: ['externalId'],
                columnStyles:
                {
                    'width': '175px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'externalId',
                    }
                ]
            },
            {
                def: 'actions',
                header: 'Actions',
                columnStyles:
                {
                    'width': '75px'
                },
                isAction: true,
                actions: [{
                    name: 'Playback Recording',
                    actionType: 'playback',
                    textColor: "#2C6EED",
                    permission: true,
                    conditions: []
                },]
            }
        ];

        return [{
            title: 'Account Version',
            dataCol: 'accountVersion',
            width: '100px',
            format: 'none'
        }, {
            title: 'Contact ID',
            dataCol: 'callID',
            width: '100px',
            format: 'none'
        }, {
            title: 'Date',
            dataCol: 'startTime',
            width: '160px',
            format: 'date'
        }, {
            title: 'ANI',
            dataCol: 'ani',
            width: '100px',
            format: 'none'
        }, {
            title: 'DNIS',
            dataCol: 'dnis',
            width: '100px',
            format: 'none'
        }, {
            title: 'Skill',
            dataCol: 'skill',
            width: '100px',
            format: 'none'
        }, {
            title: 'Agent',
            dataCol: 'agentName',
            width: '140px',
            format: 'none'
        }, {
            title: 'PreQ (secs)',
            dataCol: 'preQ',
            width: '75px',
            format: 'right'
        }, {
            title: 'Routing (secs)',
            dataCol: 'routing',
            width: '75px',
            format: 'right'
        }, {
            title: 'InQ (secs)',
            dataCol: 'inQ',
            width: '80px',
            format: 'right'
        }, {
            title: 'Agent Time (mins)',
            dataCol: 'agentTime',
            width: '100px',
            format: 'right'
        }, {
            title: 'Abandoned',
            dataCol: 'abandoned',
            width: '140px',
            format: 'boolean'
        }, {
            title: 'Abandon Time (secs)',
            dataCol: 'abandonTime',
            width: '100px',
            format: 'right'
        }, {
            title: 'Order ID',
            dataCol: 'externalId',
            width: '100px',
            format: 'order'
        }, {
            title: 'Playback Recording',
            dataCol: 'playback',
            width: '100px',
            format: 'center'
        }
        ];
    }

    tableAction(event) {
        if (event.action?.actionType === 'playback') {
            this.showPlaybackModal(event.record?.id, event.record?.callID);
        }
    }
}
