export class Auth0Config {
    constructor(
        public clientID: any,
        public domain: any,
        public responseType: any,
        public audience: any,
        public redirectUri: any,
        public scope: any
    ) {
    }
}

export class AppInsightsConfig {
    constructor(
        public instrumentationKey: any
    ) {
    }
}

export class AppConfiguration {
    constructor(public readOnlyConnection: string, public configLabel: string) {
    }
}

export class Configuration {
    constructor(
        public apiUrl: string,
        public publicApiUrl: string,
        public signalRBaseUrl: string,
        public sdkApiUrl: string,
        public webUrl: string,
        public interviewTimeOut: string,
        public sessionTimeOut: string,
        public timeZone: any,
        public logoutTimeOutOnSessionTimeOut: string,
        public heartbeatInterval: string,
        public buildNumber: any,
        public auth0Config: Auth0Config,
        public appInsights: AppInsightsConfig,
        public whiteLabelResourceUrl: string,
        public appConfiguration: AppConfiguration,
        public auth0V2Config?: any,
        public underwritingApiUrl?: string,
        public allowedLogoutRoutes?: string,
    ) {
    }
}
