import { Route } from '@angular/router';
import AuditPageComponent from '@Audit/home/audit-page.component';

import * as Guards from '@Services/guards';
import { AuditCreatePageComponent } from './create';
import { AuditViewPageComponent } from './view';
import { AuditEditPageComponent } from './edit';
import { AuditPermissions } from './audit.enums';
import { PermissionTypes } from '@Enums';

const AuditRoutes: Route = {
    path: 'audit',
    canActivate: [Guards.AclGuardService],
    // Tech Debt: Can I use Resolver to add data that's shared by all children routes?
    // This could improve the need to load lots of services in all children.
    // resolve: { 
    //     accounts: Resolvers.AccountsResolverService
    // }
    data: {
        // Set values for `AclGuardService` (this will apply to all children)
        permission: AuditPermissions.Audit,
        permissionType: PermissionTypes.Read,
        rolesNotAllowed: ['Applicant']
    },
    children: [
        {
            path: '',
            pathMatch: 'full',
            component: AuditPageComponent,
        },
        {
            path: 'create',
            component: AuditCreatePageComponent,
        },
        {
            path: 'client/:clientCode/:auditId',
            component: AuditViewPageComponent,
        },
        {
            path: 'client/:clientCode/:auditId/edit',
            component: AuditEditPageComponent,
        }
    ]
};

export default AuditRoutes;