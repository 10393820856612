import { Component, Inject } from "@angular/core";
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from "@angular/material/snack-bar";

@Component({
    selector: 'mrs-snackbar',
    templateUrl: 'mrs-snackbar.component.html',
    styleUrls: ['mrs-snackbar.component.scss']
})
export class MrsSnackbarComponent {
    messages: string[] = [];

    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any, public snackBarRef: MatSnackBarRef<MrsSnackbarComponent>) {
        this.snackBarRef._dismissAfter(4000);
        if (data.messages) {
            this.messages = data.messages;
        } else {
            this.messages.push(data.message);
        }
    }
}