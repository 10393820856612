import { Injectable } from '@angular/core';
import {HttpClient, HttpEvent} from '@angular/common/http';
import {throwError ,  Observable } from 'rxjs';
import * as _ from 'lodash';

import { AppService } from '../../app.service';
import { IConfigService } from '../../config/iconfigservice';
import { BaseService } from '../base.service';
import { ObservableService } from '../observable.service';
import { map, catchError } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class CaseAttachmentService extends BaseService {
    baseUrl: string;
    publicApiBaseUrl: string;

    constructor(
        public appService: AppService,
        public httpClient: HttpClient,
        public configService: IConfigService,
        public observableService: ObservableService,
        public dialog: MatDialog
    ) {
        super(appService, configService, httpClient);

        this.baseUrl = this.appService.getAPIBaseURL();
        this.publicApiBaseUrl = this.configService.getConfiguration().publicApiUrl;
    }

    getAttachmentUri(clientId: string, accountId: string, caseId: string, caseDocumentId: string) {
        const url = `${this.publicApiBaseUrl}client/${clientId}/account/${accountId}/case/${caseId}/attachment/${caseDocumentId}/uri`;
        return super.getData<any>(url);
    }

    addCaseAttachment(clientId: string, accountId: string, caseId: string,  questionId: string, fileData: FormData) : Observable<HttpEvent<any>> {
        const url = `${this.publicApiBaseUrl}client/${clientId}/account/${accountId}/case/${caseId}/upload`;
        fileData.append('data', JSON.stringify({ 'questionId': questionId, 'caseId': caseId }));
        return this.sendAttachment(fileData, url);
    }

    private sendAttachment(fileData: FormData, url: string) {
        return this.httpClient.post(url, fileData, {
            headers: this.appService.getUploadHttpHeaders(),
            reportProgress: true,
            observe: 'events'
        }).pipe(map((resData) => {
            const res: any = resData;
            if (res) {
                return res;
            }
        }),
        catchError((error: any) => {
            return throwError(() => new Error(error.message));
        }));
    }
}
