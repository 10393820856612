import { Component, OnInit, ViewChild, Input, SimpleChanges, Output, EventEmitter, HostListener, Inject, OnDestroy } from '@angular/core';
import { AppService } from '../../../../app.service';
import { UntypedFormBuilder, UntypedFormGroup, FormControl, FormGroupDirective, NgForm, Validators, UntypedFormArray } from '@angular/forms';
import * as _ from 'lodash';
// import { EventEmitter } from 'events';

import { Account, AccountSigner, EnvelopeSigner, EnvelopeDocument } from '../../../../models';
import { ConfirmationDialogService, SignerService, DocumentsService } from '../../../../../app/services';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DocType } from '../../../../enums';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
    selector: 'account-envelope-signer',
    templateUrl: './account-envelope-signer.component.html',
    styleUrls: ['./account-envelopes.component.scss']
})
export class AccountEnvelopeSignerComponent implements OnInit, OnDestroy {
    isEdit: boolean = false;
    isDataLoaded: boolean = false;
    isEmbedded: boolean = false;
    accountSigners: AccountSigner[];
    documents: any[];
    availableDocuments: any[];
    documentFormFields: any[];
    mapKeys: any;
    rules: any;
    documentId: any;
    accountId: any;
    envelope: any;
    formSubmitted: boolean = false;
    envelopeSignerForm: UntypedFormGroup;
    envelopeDocumentForm: UntypedFormGroup;
    selectedEnvelopeDocument: any;
    envelopeSigner: EnvelopeSigner;
    envelopeDocument: EnvelopeDocument;
    envelopeDocuments: EnvelopeDocument[];
    envelopeSigners: EnvelopeSigner[];
    envelopeDocumentSigners: any;
    subscriptions: any = [];
    formFields: any = [];
    isXsltDoc: boolean = false;

    constructor(
        private appService: AppService,
        private signerService: SignerService,
        private documentsService: DocumentsService,
        private _fb: UntypedFormBuilder,
        private confirmationService: ConfirmationDialogService,
        public dialogRef: MatDialogRef<AccountEnvelopeSignerComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit() {
        this.rules = this.data.rules;
        this.documentId = this.data.documentId;
        this.accountId = this.data.accountId;
        this.envelopeSigner = this.data.envelopeSigner;
        this.envelope = this.data.envelope;
        this.isEmbedded = this.data.isEmbedded;
        const sortOrder = this.data.sortOrder;

        if (this.envelopeSigner) {
            this.getEnvelopeDocumentSigners();
        } else {
            this.envelopeSignerForm = this._fb.group({
                id: null,
                accountSignerId: ['', Validators.required],
                idCheckRequired: [''],
                idCheckType: [''],
                remoteSigner: [''],
                sortOrder: [sortOrder],
                documents: this._fb.array([ ])
            });

            this.getAccountSigners();
            this.getPDFDocuments();
            this.isEdit = true;
        }
    }

    createDocumentForm(documentSigner: any) {
        return this._fb.group({
            id: [documentSigner.id],
            documentId: [documentSigner.documentId, Validators.required],
            ruleId: [documentSigner.ruleId],
            selectedFormFields: [documentSigner.selectedFormFields],
            formFields: documentSigner.formFields,
            reviewOnly: documentSigner.reviewOnly,
            sortOrder: documentSigner.sortOrder
        });
    }

    createFormFieldForm(formField: any) {
        return this._fb.group({
            id: formField.id,
            formFieldName: formField.formFieldName,
            isSignature: formField.isSignature,
            isRequired: formField.isRequired,
            fieldType: this.getFormFieldType(formField.formFieldName)
        });
    }

    isNullOrEmptyGuid(mapKey: any){
        return !mapKey || mapKey === '00000000-0000-0000-0000-000000000000';
    }

    runValidations(isRemote: any){
        if(isRemote.checked === true){
            var currentSigner = this.accountSigners.find(x=> x.id === this.envelopeSignerForm.controls.accountSignerId.value);
            if(this.isNullOrEmptyGuid(currentSigner.ssnMapKeyId) || this.isNullOrEmptyGuid(currentSigner.accessCodeMapKeyId) )
                this.confirmationService.open({
                    message: `SSN and Access Code must be populated for Remote Signer to work correctly`,
                    showCancel: false
                });
        }
    }
    getEnvelopeDocumentSigners() {
        this.signerService.getEnvelopeDocumentSigners(this.accountId, this.envelope.id, this.envelopeSigner.id).subscribe(res => {
            if (res.documents) {
                res.documents = _.orderBy(res.documents, 'sortOrder', 'asc');
            }

            this.envelopeDocumentSigners = res;
            this.setupEnvelopeSignerForm();

            this.getAccountSigners();
            this.getPDFDocuments();

            this.resequenceDocuments();
        });
    }

    filterAvailableDocuments() {
        if (this.envelopeDocumentSigners && this.envelopeDocumentSigners.documents && this.envelopeDocumentSigners.documents.length > 0) {
            this.availableDocuments = [];
            if (this.documents) {
                this.documents.forEach(doc => {
                    const docUsed = _.find(this.envelopeDocumentSigners.documents, function(x) { return x.documentId === doc.id; });
                    if (!docUsed || docUsed === null) {
                        this.availableDocuments.push(doc);
                    }
                });
            }
        }
    }

    setupEnvelopeSignerForm() {
        this.envelopeSignerForm = this._fb.group({
            id: [this.envelopeDocumentSigners.id],
            accountSignerId: [this.envelopeDocumentSigners.accountSignerId, Validators.required],
            idCheckRequired: [this.envelopeDocumentSigners.idCheckRequired],
            idCheckType: [this.envelopeDocumentSigners.idCheckType],
            remoteSigner: [this.envelopeDocumentSigners.remoteSigner],
            documents: this._fb.array([ ])
        });

        this.envelopeDocumentSigners.documents = this.envelopeDocumentSigners.documents.sort(x => x.sortOrder);

        this.envelopeDocumentSigners.documents.forEach(document => {
            const documentGroup = this.createDocumentForm({
                id: document.id,
                documentId: document.documentId,
                ruleId: document.ruleId,
                selectedFormFields: document.selectedFormFields,
                formFields: this._fb.array([ ]),
                reviewOnly: document.reviewOnly,
                sortOrder: document.sortOrder
            });

            const formArray = <UntypedFormArray>this.envelopeSignerForm.get('documents');
            formArray.push(documentGroup);

            document.formFields.forEach(formField => {
                const formFieldGroup = this.createFormFieldForm(formField);

                const formFieldArray = <UntypedFormArray> documentGroup.get('formFields');
                formFieldArray.push(formFieldGroup);
            });
        });

        this.isEdit = true;
    }

    getAccountSigners() {
        this.signerService.getAccountSigners(this.accountId, true)
            .subscribe(data => {
                this.accountSigners = data;
                this.isDataLoaded = true;
            }
            );
    }

    getFormFields(documentId) {
        if (documentId && documentId != null) {
            const document = _.find(this.documents, function(x) { return x.id === documentId; });

            if (document.doctypeId === DocType.PDF || document.doctypeId === DocType.CombinedDocument) {
                this.isXsltDoc = false;
                this.documentsService.getPDFDocumentFormFields(this.accountId, documentId)
                    .subscribe(data => {
                        this.documentFormFields = data;
                        this.setFormFieldType();
                    });
            } else {
                this.isXsltDoc = true;
                const reviewField = this.selectedEnvelopeDocument.get('reviewOnly');
                reviewField.patchValue(true);
            }
        }
    }

    setFormFieldType() {
        const formFields = this.selectedEnvelopeDocument.get('formFields').controls;
        formFields.forEach(element => {
            const fieldTypeControl = (element as UntypedFormGroup).get('fieldType');
            const fieldType = this.getFormFieldType((element as UntypedFormGroup).get('formFieldName').value);
            fieldTypeControl.patchValue(fieldType);
        });
    }


    getFormFieldType(formFieldName) {
        const formField = _.find(this.documentFormFields, function(x) { return x.formFieldName === formFieldName; });
        if (formField) {
            return formField.formFieldType;
        }
    }

    getPDFDocuments() {
        this.documentsService.getPDFDocumentList(this.accountId)
            .subscribe(data => {
                this.documents = data;
                this.availableDocuments = data;
                this.filterAvailableDocuments();
            });
    }

    getDocumentLabel(envelopeDocument: UntypedFormGroup) {
        if (envelopeDocument) {
            const documentId = envelopeDocument.get('documentId').value;
            if (documentId != null && this.documents) {
                const document =  this.documents.find(x => x.id === documentId);
                if (document) {
                    return document.name;
                }
            }
        }
    }

    saveEnvelopeSigner() {
        this.formSubmitted = true;
        if (!this.envelopeSignerForm.valid) {
            this.envelopeSignerForm.markAsTouched();
            this.envelopeSignerForm.controls.accountSignerId.markAsTouched();
            this.envelopeSignerForm.controls.idCheckRequired.markAsTouched();
            this.envelopeSignerForm.controls.idCheckType.markAsTouched();
            return false;
        }

        const formVal = this.envelopeSignerForm.value;

        if (this.envelopeSignerForm.valid) {
            this.signerService.saveEnvelopeDocumentSigner(this.accountId, this.envelope.id, formVal, (this.envelopeSigner && this.envelopeSigner.id != null))
                .subscribe({
                    next: _res => {
                        this.appService.showMsg('success', 'Saved successfully ...');
                        this.dialogRef.close();
                    }
                });
        }
    }

    selectEnvelopeDocument(envelopeDocument: any) {
        if (this.selectedEnvelopeDocument === envelopeDocument) {
            this.selectedEnvelopeDocument = null;
        } else {
            this.formSubmitted = false;
            this.selectedEnvelopeDocument = envelopeDocument;
            const documentId = envelopeDocument.get('documentId').value;
            this.getFormFields(documentId);
            this.documentSelected(envelopeDocument);
        }
    }

    addEnvelopeDocument() {
        const documentGroup = this.createDocumentForm({
            id: null,
            documentId: undefined,
            ruleId: '',
            selectedFormFields: undefined,
            reviewOnly: false,
            sortOrder: '',
            formFields: this._fb.array([])
        });

        const formArray = <UntypedFormArray>this.envelopeSignerForm.get('documents');
        formArray.push(documentGroup);
        const sub = documentGroup.get('documentId').valueChanges.subscribe(value => {
            this.getFormFields(value);
        });
        this.documentSelected(documentGroup);
        this.subscriptions.push(sub);
        this.selectedEnvelopeDocument = documentGroup;
        this.resequenceDocuments();
    }

    documentSelected(documentGroup: UntypedFormGroup) {
        const sub1 = documentGroup.get('selectedFormFields').valueChanges.subscribe(value => {
            const formFieldArray = <UntypedFormArray>this.selectedEnvelopeDocument.get('formFields');

            const currentFields = [];
            for (let i = 0; i < formFieldArray.length; i++) {
                const formField = formFieldArray.controls[i];
                currentFields.push({
                    formFieldName: formField.get('formFieldName').value,
                    isSignature: formField.get('isSignature').value,
                    isRequired: formField.get('isRequired').value,
                    fieldType: formField.get('fieldType').value
                });
            }
            formFieldArray.clear();

            value.forEach(element => {
                let isSignature = false;
                let isRequired = true;
                const fieldType = this.getFormFieldType(element);
                if (fieldType === 'CheckboxField') {
                    isRequired = false;
                }
                const currentField = _.find(currentFields, { 'formFieldName': element});
                if (currentField) {
                    isSignature = currentField.isSignature;
                    isRequired = currentField.isRequired;
                }
                const formGroup = this._fb.group({
                    id: undefined,
                    formFieldName: element,
                    isSignature: isSignature,
                    isRequired: isRequired,
                    fieldType: fieldType
                });

                formFieldArray.push(formGroup);
            });
        });

        this.subscriptions.push(sub1);
    }

    getEnvelopeFormArray(): UntypedFormArray {
        return this.envelopeSignerForm.get('documents') as UntypedFormArray;
    }

    cancelEnvelopeDocument() {
        this.selectedEnvelopeDocument = null;
    }

    deleteEnvelopeDocument(envelopeDocument: UntypedFormGroup, event: any) {
        event.stopPropagation();
        const id = envelopeDocument.get('id').value;
        this.confirmationService.open({
            message: `Are you sure that you want to delete this document?`,
            showCancel: true,
            onOk: () => {
                this.signerService.deleteEnvelopeDocument(this.accountId, this.envelope.id, this.envelopeSigner.id, id).subscribe(
                    res => {
                        this.appService.showMsg('success', 'Deleted successfully ...');
                        this.getEnvelopeDocumentSigners();
                        this.selectedEnvelopeDocument = null;
                    }
                );
            }
        });
    }


    drop(event: CdkDragDrop<string[]>) {
        const documentArray = this.getEnvelopeFormArray();

        const from = event.previousIndex;
        const to = event.currentIndex;
        this.moveItemInFormArray(documentArray, from, to);

        this.resequenceDocuments();
    }

    resequenceDocuments() {
        const documentArray = this.getEnvelopeFormArray();
        if (documentArray == null) { return; }

        documentArray.controls.forEach((formGroup: UntypedFormGroup, index) => {
            formGroup.controls['sortOrder'].setValue((index + 1));
        });
    }


    moveItemInFormArray(formArray: UntypedFormArray, fromIndex: number, toIndex: number): void {
        const dir = toIndex > fromIndex ? 1 : -1;

        const from = fromIndex;
        const to = toIndex;

        const temp = formArray.at(from);
        for (let i = from; i * dir < to * dir; i = i + dir) {
            const current = formArray.at(i + dir);
            formArray.setControl(i, current);
        }
        formArray.setControl(to, temp);
    }

    /** Clamps a number between zero and a maximum. */
    clamp(value: number, max: number): number {
        return Math.max(0, Math.min(max, value));
    }


    ngOnDestroy() {
        for (const eachSubscription of this.subscriptions) {
            eachSubscription.unsubscribe();
        }
    }
}
