import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UWLabel, UWDecisionPatch } from 'app/case-summary/underwriting/models';

@Component({
    selector: 'underwriting-decision-update',
    templateUrl: './underwriting-decision-update.component.html',
    styleUrls: ['./underwriting-decision-update.component.scss']
})
export class UnderwritingUpdate {
    @Input() uwLabelName: string;
    @Input() labels: UWLabel[];
    @Output() onSave = new EventEmitter<UWDecisionPatch>();
    @Output() onCancel = new EventEmitter<void>();

    patchDecision: UWDecisionPatch = {
        uwdecisionId: '00000000-0000-0000-0000-000000000000',
        underWritingNote: {
            noteContent: "",
            lastModifiedDate: ""
        }
    };
    submitted = false;

    protected handleOnSave() {
        this.submitted = true;
        if (this.patchDecision.uwdecisionId !== '00000000-0000-0000-0000-000000000000' && this.patchDecision.underWritingNote.noteContent != "") {
            this.onSave.emit(this.patchDecision);
        }
    }

    protected handleOnCancel() {
        this.onCancel.emit();
    }
}
