<section class="notes-section sub-section">
    <h2 class="title">Notes</h2>
    <div class="content-wrapper">
        <div class="content">
            <ng-container *ngIf="!notesLoaded && !caseRequirementsLoaded">
                <mat-spinner diameter="20"></mat-spinner>
            </ng-container>
            <ng-container *ngIf="notesLoaded && caseRequirementsLoaded">
                <uw-notes-add-form class="add-note" [baseProps]="baseProps" [props]="uwNotesAddForm"></uw-notes-add-form>
                <uw-notes-list class="list-notes" [baseProps]="baseProps" [notes]="notes | removeExcludedNotesFromMaster" [showRequirementHandle]=true></uw-notes-list>
            </ng-container>
        </div>
    </div>
</section>