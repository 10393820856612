import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { HttpCacheService } from 'app/shared/services/http-cache.service';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {

    constructor(private cacehService: HttpCacheService) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    // pass along requests that modify data and invalidate cache
        if (request.method !== 'GET' && !request.url.toLowerCase().includes('checkstatus') && !request.url.toLowerCase().includes('/addusertocase')) {
            console.log(`Invalidating cache: ${request.method} ${request.url}`);
            this.cacehService.invalidateCache();
            return next.handle(request);
        }

        // attempt to retrieve a cached response
        const cachedResponse: HttpResponse<any> = this.cacehService.get(request.url);

        // return cached response
        if (cachedResponse) {
            console.log(`Returning cached response: ${cachedResponse.url}`);
            console.log(cachedResponse);
            return of(cachedResponse);
        }

        // send request to server and add response to cache
        return next.handle(request)
            .pipe(
                tap(event => {
                    if (event instanceof HttpResponse) {
                        console.log(`Adding item to cache: ${request.url}`);
                        this.cacehService.put(request.url, event);
                    }
                })
            );

    }

}
