import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'expandable-text',
    templateUrl: './expandable-text.component.html',
    styleUrls: ['./expandable-text.component.scss']
})
export class ExpandableTextComponent implements OnInit {
    @Input() originalText: string;
    @Input() expandedText: any;
    showtext = false;
    iconWidth: number;

    ngOnInit(): void {
        const expanderIconElement = document.querySelector('#expanderIcon');
        const uxRequestedIndentationExtraOffset = 15;
        this.iconWidth = expanderIconElement.clientWidth + uxRequestedIndentationExtraOffset;
    }

}
