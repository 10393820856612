import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { AppService } from "app/app.service";
import { AuraComponent } from "app/components/aura/aura.component";
import * as _ from 'lodash';

@Injectable()
export class AuraIntegrationResponseHandler{

    constructor(
        public appService: AppService,
        public dialog: MatDialog
    ) {}

    handleResponse(clientId: string, accountId: string,integrationResponse, integrationData){
        this.dialog.open(AuraComponent, {
            width: '60%',
            disableClose: false,
            data: {
                clientId: clientId,
                caseId: integrationData.caseId,
                caseIntegrationQueueId: integrationData.caseIntegrationQueueId,
                authToken: _.get(integrationData, 'details.clientProperties.authToken'),
                accountId: accountId
            }
        });
    }
}
