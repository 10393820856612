import { AccountSettingsDTO, ActionParamsDTO, ActionTypesDTO, ActionsDTO, DocumentsDTO, RulesDTO, SectionActionsDTO } from "@DTOs";
import { AccountSettings, FeatureToggle, IntegrationMappingType, MapKeyType, MrsColor, NoteType } from "@Enums";
import { AccountSettingsDataService, ConfirmationDialogService, FeatureManagerService, MapKeyService, UtilityService } from '@Services';
import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from "@angular/material/select";
import { AppService } from "app/app.service";
import * as _ from 'lodash';
import { Observable, map, startWith } from 'rxjs';
import { SubSink } from "subsink";


export interface ActionDialogComponentParams {
    rules: any;
    actionParent: string;
    actions: ActionsDTO[];
    action: SectionActionsDTO;
    isAddAction: boolean;
}

@Component({
    templateUrl: './edit-action-dialog.component.html',
    styleUrls: ['./edit-action-dialog.component.scss']
})
export class EditActionDialogComponent implements OnInit, OnDestroy {
    filteredRules: any;
    _actions: any[];
    autoGeneratedMapkeyIntegration = false;
    isEditView: boolean;
    isIntegrationV2Action = false;
    actionForm: UntypedFormGroup;
    accountId: string;
    actionTypes: ActionTypesDTO[] = [];
    actionParams: ActionParamsDTO[] = [];
    inputParameters: any = [];
    outputParameters: any = [];
    actionOutputs: any = [];
    responseMapKeys: any = [];
    inputParameterMapKeys: any = [];
    outputParameterMapKeys: any = [];
    actionTypeBoth = '00000000-0000-0000-0000-000000000003';
    showActionDialog = false;
    mapKeys: any = [];
    actionTypeLabel: any;
    actionLabel: any;
    ruleLabel: any;
    billingEventName: any;
    filteredActions: any = [];
    mapKeyTypeEnum: any = MapKeyType;
    rules: RulesDTO[];
    paramControlsLoaded = false;
    accountDocuments: DocumentsDTO[] = [];
    integrationReportLabel = "Integration Report";

    subs = new SubSink();
    accountSettings: AccountSettingsDTO[] = [];
    accountSettingsLoaded = false;
    disableNamedActions = false;

    @Output() onDeleteAction = new EventEmitter();
    @Output() onActionUpdated = new EventEmitter();

    @ViewChild('actionDialog') actionDialog: TemplateRef<any>;
    _formSubmitted: boolean;
    billingEvents: any = [];
    prefillQuestionsFeatureEnabled = false;
    actionTypeHeader: string;
    actionDialogTitle: string;

    constructor(private _fb: UntypedFormBuilder,
        private appService: AppService,
        public dialog: MatDialog,
        private mapKeyService: MapKeyService,
        private confirmationService: ConfirmationDialogService,
        private featureManagerService: FeatureManagerService,
        private utilityService: UtilityService,
        private accountSettingsDataService: AccountSettingsDataService,
        @Inject(MAT_DIALOG_DATA) public dialogData: ActionDialogComponentParams
    ) {
        this.isEditView = false;
    }

    //#region Helpers
    _filterRules(name: string) {
        const filterValue = name.toLowerCase();

        return this.dialogData.rules.filter(option => option.name.toLowerCase().includes(filterValue));
    }

    get action() {
        return this.dialogData.action;
    }

    addOutputParameter() {
        this.actionOutputs.push({
            metadata_Id: '',
            mapKey_Id: '',
            mappingType_Id: '00000000-0000-0000-0000-000000000002'
        });
        const outputFormArray = this.actionForm.get('outputParams') as UntypedFormArray;
        outputFormArray.push(this.createParam(null, null, false, IntegrationMappingType.Output));
    }

    checkACL(permissionType, redirect?) {
        return this.appService.checkACL('Accounts', permissionType, redirect);
    }

    createParam(metadata_id, mapKey_Id, isRequired, mappingTypeId): UntypedFormGroup {
        if (isRequired) {
            return this._fb.group({
                mapKey_Id: [mapKey_Id, Validators.required],
                metadata_Id: [metadata_id, Validators.required],
                mappingType_Id: mappingTypeId
            });
        } else {
            return this._fb.group({
                mapKey_Id: [mapKey_Id],
                metadata_Id: [metadata_id],
                mappingType_Id: mappingTypeId
            });
        }
    }

    getActionLabel(id) {
        if (id && this.dialogData.actions && this.dialogData.actions.length) {
            const action = _.find(this.dialogData.actions, ['id', id]);
            if (action && action.name) {
                return action.name;
            } else {
                return '';
            }
        } else {
            return '';
        }
    }

    getActions(selectedActionType, isInit = false) {
        if (!isInit) {
            this.actionForm.patchValue({
                actionsId: ''
            });
        }

        const selectedActions = [];
        this.dialogData.actions?.forEach(action => {
            if (action.actionTypesID === selectedActionType.value ||
                action.actionTypesID === this.actionTypeBoth) {

                selectedActions.push(action);
            }
        });
        this.filteredActions = selectedActions;
    }

    getActionTypeLabel(id) {
        if (id && this.actionTypes && this.actionTypes.length) {
            const actionType = _.find(this.actionTypes, ['id', id]);
            if (actionType && actionType.name) {
                return actionType.name;
            } else {
                return '';
            }
        } else {
            return '';
        }
    }

    getConfigAction(actionId: string): ActionsDTO {
        const configActions = this.appService.getConfigData('actions');
        const currentActions = configActions.filter((configAction) => {return configAction.id === actionId;});
        return (currentActions.length >= 1) ? currentActions[0] : null;
    }

    getDocuments(accountId: string): Observable<any> {
        const url = `Documents/${accountId}`;
        return this.appService.getData<any>(url);
    }

    getFilteredParamValuesForActionParam(currentAction: ActionsDTO, actionParameter: ActionParamsDTO) {
        const activeNoteTypeActionParamId = "00000000-0000-0000-0000-000000000053";
        let filteredParamValues = undefined;
        if (actionParameter.displayName === this.integrationReportLabel) {

            const integrations = this.appService.getConfigData('integrations');

            if (integrations) {
                const linkedIntegrationId = integrations.find(x => x.id === currentAction.integration_Id).linkedIntegrationId;
                const actionIntegrationId = currentAction.integration_Id;

                filteredParamValues = this.accountDocuments.filter(x => (x.integrationId === linkedIntegrationId || x.integrationId === actionIntegrationId)).map(x => { return {id: x.id, name: x.name}; });
            }

        }
        else {
            filteredParamValues = this.appService.getConfigFromStaticAndDynamicConfigData(actionParameter.params);
            if (actionParameter.id === activeNoteTypeActionParamId) {
                // when parameter is active note type remove Error and Systemlog (Configurators should not be able to send errors or systemlog notes)
                filteredParamValues = filteredParamValues.filter(x => x.id !== NoteType.Error && x.id !== NoteType.SystemLog);
            }
        }
        return filteredParamValues;
    }

    getParameterValue(action: SectionActionsDTO, actionParam: ActionParamsDTO) {
        const actionParamsKey = 'actionParameters';
        let paramValue = '';
                
        const val = action[actionParamsKey];
        const findVal = ['actionParamsId', actionParam.id];
        const actionParamCfg = _.find(val, findVal);
        let pVal;
        if (actionParamCfg?.paramValue) {
            pVal = actionParamCfg.paramValue;
        }

        if (actionParam.actionParamsType.fieldType === 'Multiselect' && pVal) {
            paramValue = pVal.split('|');
        } else if (actionParam.actionParamsType.fieldType === 'FollowUpDate' && pVal) {
            paramValue = pVal;
        } else {
            paramValue = pVal;
        }

        return paramValue;
    }

    getRuleLabel(id) {
        if (id && this.dialogData.rules && this.dialogData.rules.length) {
            const rule = _.find(this.dialogData.rules, ['id', id]);
            if (rule && rule.name) {
                return rule.name;
            } else {
                return '';
            }
        } else {
            return '';
        }
    }

    initializeActionForm(actionId: string) {
        const currentAction = this.getConfigAction(actionId);

        // PER-Defect:40140 When the dialog is in edit mode, it is possible
        // the actionName has not been set. So display the default value
        // to be the current action Name.
        if (!this.dialogData.isAddAction) {
            if (!this.actionForm.get('actionName').value) {
                this.actionForm.get('actionName').setValue(currentAction?.name);
            }
        }
        if (currentAction !== null) {
            // update rule validation based on isRuleRequired
            if (currentAction && currentAction.isRuleRequired) {
                this.actionForm.get('rulesId').setValidators([Validators.required]);
            } else {
                this.actionForm.get('rulesId').clearValidators();
            }
            this.actionForm.get('rulesId').updateValueAndValidity();

            this.setV2IntegrationsInputsAndOutputs(currentAction);
            this.setActionParamsForm(currentAction);
        }
    }
    
    private isAutoGenMapkeyIntegration(integration_Id: string) {
        return integration_Id === '00000000-0000-0000-0000-000000000089';
    }    

    setActionForm(value: SectionActionsDTO) {

        if (value.id) {
            this.getActions({
                value: value.actionTypeId
            }, true);

        }

        this.initializeActionForm(value.actionsId);

        this.togglebillingEventIDValidations(value.actionsId);

        this.filteredRules = this.actionForm.get('rulesId').valueChanges.pipe(
            startWith(''),
            map(value => {
                const name = typeof value === 'string' ? value : value?.name;
                return name ? this._filterRules(name as string) : this.rules.slice();
            }),
        );
    }

    setActionParamsForm(currentAction: ActionsDTO) {
        const caseNoteFollowUpParameterId = '00000000-0000-0000-0000-000000000078';

        // Get the action params for the configured action
        if (_.find(this.dialogData.actions, ['id', currentAction.id])) {
            this.actionParams = _.find(this.dialogData.actions, ['id', currentAction.id]).actionParams;

            if (this.dialogData.actionParent === 'Section') {
                this.actionParams = this.actionParams.filter(o => o.params !== 'waitForResponse');
                this.actionParams = this.actionParams.filter(o => o.params !== 'statusMessage');
                this.actionParams = this.actionParams.filter(o => o.params !== 'statusDisplayType');
            }
        }

        // Get the form control for the action group
        const actionParamsGroup = <UntypedFormGroup>this.actionForm.get('actionParams');

        // If the action has defined action Parameters
        if (this.actionParams.length) {

            // Set the control for each type of action param
            this.actionParams.forEach(async param => {

                // Get filtered parameter values
                const filteredParamValues = this.getFilteredParamValuesForActionParam(currentAction, param);
                const initialValue = this.getParameterValue(this.action, param);
                if (param.id === caseNoteFollowUpParameterId) {
                    // Add caseNoteFollowUp with special validator
                    actionParamsGroup.addControl(`${param.id}`, this._fb.control(initialValue, [Validators.min(0)]));
                } else if (param.isRequired) {
                    actionParamsGroup.addControl(`${param.id}`, this._fb.control(initialValue, [Validators.required]));
                } else {
                    // Default add control w/o validation
                    actionParamsGroup.addControl(`${param.id}`, this._fb.control(initialValue, []));
                }

                if (param.actionParamsType.fieldType === 'Lookup' || param.actionParamsType.fieldType === 'AutoSuggest'
                    || param.actionParamsType.fieldType === 'Multiselect') {
                    param['LookupValues'] = filteredParamValues;

                }
            });

        }

    }

    setBillingEventLabel(id) {
        if (id && this.billingEvents && this.billingEvents.length) {
            const billingEvent = _.find(this.billingEvents, ['billingEventID', id]);
            if (billingEvent && billingEvent.billingEventName) {
                this.billingEventName = billingEvent.billingEventName;
            } else {
                return '';
            }
        } else {
            return '';
        }
    }

    setCollapsedLabel(action) {
        const labels = [];
        this.actionTypeLabel = this.getActionTypeLabel(action.actionTypeId);
        this.actionLabel = this.getActionLabel(action.actionsId);
        this.ruleLabel = this.getRuleLabel(action.rulesId);

        if (this.actionTypeLabel.length > 0) {
            labels.push(this.actionTypeLabel);
        }

        if (this.actionLabel.length > 0) {
            labels.push(this.actionLabel);
        }

        if (this.ruleLabel.length > 0) {
            labels.push(this.ruleLabel);
        }
        this.actionLabel = labels.join(' - ');
        this.actionLabel = (this.actionLabel.length > 45 ? `${this.actionLabel.substring(0, 45)}...` : this.actionLabel);
    }

    setupMapKeys() {
        this.mapKeys = this.mapKeyService.mapKeys;

        this.responseMapKeys = this.mapKeys.filter(x => x.isJson && x.integrationId != null);

        this.inputParameterMapKeys = this.mapKeys.filter(mapKey => (
            (mapKey.isConstant && !mapKey.isList) ||
            (mapKey.isVirtual && mapKey.isVirtualExpression) ||
            (!mapKey.isVirtual && !mapKey.isConstant)));
        this.inputParameterMapKeys = _.sortBy(this.inputParameterMapKeys, 'entityHierarchy');

        this.outputParameterMapKeys = this.mapKeys.filter(mapKey =>
            (!mapKey.isVirtual && !mapKey.isConstant));
        this.outputParameterMapKeys = _.sortBy(this.outputParameterMapKeys, 'entityHierarchy');
    }

    setV2IntegrationsInputsAndOutputs(currentAction: ActionsDTO) {
        this.isIntegrationV2Action = false;

        if (currentAction && currentAction.integration_Id) {
            this.isIntegrationV2Action = true;
            const action = _.find(this.dialogData.actions, ['id', currentAction.id]);

            // Get InputParams
            const inputFormArray = this.actionForm.get('inputParams') as UntypedFormArray;
            this.inputParameters = action.integrationMapping?.filter(x => x.mappingType_Id === '00000000-0000-0000-0000-000000000001');

            const mappedInputParams = _.keyBy(this.action.inputParams || [], (param) => param.metadata_Id);
            this.inputParameters?.forEach(x => {
                const inputParamWithMapping = this.createParam(
                    x.metadata_Id,
                    _.get(mappedInputParams, `[${x.metadata_Id}].mapKey_Id`),
                    x.isRequired,
                    IntegrationMappingType.Input
                );

                inputFormArray.push(inputParamWithMapping);
            });

            // Get output params
            const outputFormArray = this.actionForm.get('outputParams') as UntypedFormArray;
            this.outputParameters = action.integrationMapping.filter(x => x.mappingType_Id === '00000000-0000-0000-0000-000000000002' && !x.isArray);

            // If auto gen mapkey is on then create those parameters
            this.autoGeneratedMapkeyIntegration = this.isAutoGenMapkeyIntegration(currentAction.integration_Id);
            if (this.action.outputParams && !this.autoGeneratedMapkeyIntegration) {
                this.actionOutputs = this.action.outputParams.filter(x => x.mapKey_Id);
                this.action.outputParams.forEach(x => {
                    outputFormArray.push(this.createParam(x.metadata_Id, x.mapKey_Id, false, IntegrationMappingType.Output));
                });
            }
        }
    }

    togglebillingEventIDValidations(actionId) {
        if (actionId === '00000000-0000-0000-0000-000000000014') {
            this.actionForm.get('billingEventID').setValidators(Validators.required);
        } else {
            this.actionForm.get('billingEventID').clearValidators();
        }
        this.actionForm.get('billingEventID').updateValueAndValidity();
    }

    subscribeToAccountSettings(accountId: string) {
        this.subs.add(this.accountSettingsDataService.loadAccountSettings(accountId).subscribe(accountSettings => {
            this.accountSettings = accountSettings;
            this.accountSettingsLoaded = true;

            const disabledNameActionAccountSetting = this.accountSettings.find((setting) => setting.name.toLowerCase() === AccountSettings.disabledNamedActions.toLocaleLowerCase());
            if (disabledNameActionAccountSetting) {
                this.disableNamedActions = disabledNameActionAccountSetting.value?.toLowerCase() === 'true';
            } else {
                this.disableNamedActions = false;
            }

        }));
    }    
    //#endregion

    //#region Lifecycle
    
    ngOnInit() {
        if (!this.action.id) {
            this.isEditView = true;
        }

        this.rules = this.dialogData.rules;
        this.actionTypeHeader = this.dialogData.actionParent === 'Questions' ? 'Question' : this.dialogData.actionParent;


        if (this.dialogData.isAddAction) {
            this.actionDialogTitle = `Add ${this.actionTypeHeader} Action`;
        } else {
            this.actionDialogTitle = `Edit ${this.actionTypeHeader} Action`;            
        }

        this.setupMapKeys();

        this.actionTypes = this.appService.combos['ActionTypes'];
        this.actionTypes = this.actionTypes?.filter(at => at.id !== this.actionTypeBoth);

        this.billingEvents = this.appService.getDynamicConfigData('accountBillingEvents');
        this.setBillingEventLabel(this.action?.billingEventId);
        this.setCollapsedLabel(this.action);
        this.isEditView = true;



        this.actionForm = this._fb.group({
            id: this.action.id,
            actionTypeId: [this.action.actionTypeId, Validators.required],
            actionsId: [this.action.actionsId, Validators.required],
            actionName: [this.action.actionName, Validators.required],
            rulesId: this.action.rulesId,
            parentId: [(this.dialogData.actionParent === 'Section' ? this.action.sectionId : this.action.questionsId), Validators.required],
            actionParams: this._fb.group({}),
            response: [this.action.response],
            inputParams: this._fb.array([
            ]),
            outputParams: this._fb.array([
            ]),
            billingEventID: this.action.billingEventId,
            accountId: this.action.accountId
        });

        this.getDocuments(this.action.accountId).subscribe(documents => {
            this.accountDocuments = documents.data;
            this.setActionForm(this.action);
            this.paramControlsLoaded = true;
        });

        this.prefillQuestionsFeatureEnabled = this.featureManagerService.getByName(FeatureToggle.ConfigInterviewPrefillQuestions).enabled;
        this.subscribeToAccountSettings(this.action.accountId);
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
        this.accountSettings = [];
        this.accountSettingsLoaded = false;
    }
    //#endregion


    //#region Handlers

    cancelAction() {
        if (this.actionForm.dirty) {
            this.confirmationService.confirm({
                title: 'Cancel?',
                message: `Your changes will not be saved.`,
                key: 'cancelAction',
                showCancel: true,
                cancelLabel: 'Continue Editing',
                okLabel: 'Yes, Cancel',
                okTextColor: MrsColor.UIBlue,
                accept: () => {

                    this.onDeleteAction.emit();
                    this.isEditView = !this.isEditView;
                    this.dialog.closeAll();
                }
            });
        } else {
            this.onDeleteAction.emit();
            this.isEditView = !this.isEditView;
            this.dialog.closeAll();
        }
    }

    onActionParamChange(onChangeEvent: MatSelectChange) {
        // Get the form control for the action group
        const actionParamsGroup = <UntypedFormGroup>this.actionForm.get('actionParams');

        // Remove all controls because they get added back in
        Object.keys(actionParamsGroup.controls).forEach(control => {
            actionParamsGroup.removeControl(control);
        });


        this.initializeActionForm(onChangeEvent.value);

        this.togglebillingEventIDValidations(onChangeEvent.value);

        const currentAction = this.getConfigAction(onChangeEvent.value);
        if (currentAction !== null) {
            this.actionForm.get('actionName').setValue(currentAction.name);
            this.utilityService.animateCSS('#actionNameFormField', 'headShake');
        }
    }

    saveAction() {
        this._formSubmitted = true;

        let isEdit = false;
        // let url = 'Section/Actions';
        let url = `${this.dialogData.actionParent}/Actions`;
        if (this.actionForm.get('id').value) {
            isEdit = true;
            // url = 'Section/' + this.action.id + '/Actions';
            url = `${this.dialogData.actionParent}/${this.actionForm.get('id').value}/Actions`;
        }

        if (this.actionForm.valid) {

            const formData = this.actionForm.value;
            delete formData.actions;


            const actionParamsFormatted = [];
            Object.keys(formData.actionParams).forEach(paramKey => {
                const actionparams = formData.actionParams[paramKey];
                if (Array.isArray(actionparams)) {
                    let pipedVal = '';

                    actionparams.forEach(x => {
                        pipedVal += x + '|';
                    });

                    actionParamsFormatted.push({
                        actionParamsId: paramKey,
                        accountId: formData.accountId,
                        paramValue: pipedVal
                    });
                } else {
                    actionParamsFormatted.push({
                        actionParamsId: paramKey,
                        accountId: formData.accountId,
                        paramValue: formData.actionParams[paramKey]
                    });
                }
            });
            const configActions = this.appService.getConfigData('actions');
            let currentAction: any;

            configActions.forEach(configAction => {
                if (configAction.id === formData.actionsId) {
                    currentAction = configAction;
                }
            });

            const destId = this.dialogData.actionParent === 'Section' ? 'sectionId' : 'questionsId';
            formData[destId] = formData.parentId;
            formData['actionParameters'] = actionParamsFormatted;
            formData['integrationId'] = currentAction.integration_Id;

            if (this.action.order /*&& this.action.order !== ''*/) {    //TODO: Do we still need this code?
                formData['order'] = this.action.order;
            }

            this.appService.postData(url, formData, isEdit, false)
                .subscribe(
                    data => {
                        if (data.status === 'success') {
                            this.onActionUpdated.emit();
                            this.isEditView = false;
                            this.dialog.closeAll();
                            if (!isEdit) {
                                this.actionForm.patchValue({
                                    id: data.data.id
                                });

                                this.action.id = data.data.id;
                            }
                            if (this.autoGeneratedMapkeyIntegration && this.prefillQuestionsFeatureEnabled) {
                                const actionName = 'LexisNexisLDP';
                                this.appService.showMsg('success', `Generating mapkeys.`);
                                this.mapKeyService.getMapKeys(this.dialogData.action.accountId, true).subscribe((data) => {
                                    this.appService.showMsg('success', `${this.action.sectionId ? 'Section' : 'Question'} action saved. Output Mapkeys generated in Mapkeys > Integrations_${actionName}`);
                                });

                            } else {
                                this.appService.showMsg('success', `${this.action.sectionId ? 'Section' : 'Question'} action saved.`);
                            }

                            this.setCollapsedLabel(data.data);
                        } else {
                            this.appService.showMsg('error', data.message);
                        }
                    }
                );
        } else {
            this.appService.showMsg('error', 'Please fill required fields');

            Object.keys(this.actionForm.controls).forEach(control => {
                this.actionForm.get(control).markAsTouched();
                this.actionForm.get(control).markAsDirty();
                this.actionForm.get(control).updateValueAndValidity();
            });

            if (this.actionForm.controls.actions) {
                Object.keys(this.actionForm.controls.actions['controls']).forEach(control => {
                    this.actionForm.controls.actions.get(control).markAsTouched();
                    this.actionForm.controls.actions.get(control).markAsDirty();
                    this.actionForm.controls.actions.get(control).updateValueAndValidity();
                });
            }

            if (this.actionForm.controls.actionParams) {
                Object.keys(this.actionForm.controls.actionParams['controls']).forEach(control => {
                    this.actionForm.controls.actionParams.get(control).markAsTouched();
                    this.actionForm.controls.actionParams.get(control).markAsDirty();
                    this.actionForm.controls.actionParams.get(control).updateValueAndValidity();
                });
            }

        }
    }

    onRemoveOutputParameter(idx: number) {
        this.actionOutputs.splice(idx, 1);
        const outputFormArray = this.actionForm.get('outputParams') as UntypedFormArray;
        outputFormArray.removeAt(idx);
    }

    //#endregion
}
