import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { TrustCommerceService } from '../../services';

@Component({
    selector: 'trustcommerce-component',
    host: { 'class': 'content' },
    templateUrl: './trustcommerce.component.html'
})
export class TrustCommerceComponent implements OnInit {
    url: SafeResourceUrl;
    isTrustCommerceInterviewComplete: boolean = false;
    browserMessageHandler: any = this.handleBrowserMessage.bind(this);

    constructor(
        private trustCommerceService: TrustCommerceService,
        public dialog: MatDialog,
        private sanitizer: DomSanitizer,
        @Inject(MAT_DIALOG_DATA) public data
    ) { }


    ngOnInit() {
        if (this.data.trustCommerceUrl.includes("&action=store")) {
            this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.trustCommerceUrl);
            window.addEventListener('message', this.browserMessageHandler);
        } else { this.dialog.closeAll(); }
    }

    ngOnDestroy() {
        window.removeEventListener('message', this.browserMessageHandler);
    }

    handleBrowserMessage(event: any) {
        if (event == null || event.data == null) { return; }

        try {
            const messageData = event.data;
            if (messageData.messageType !== 'trustCommerceCallback') { return; }

            this.isTrustCommerceInterviewComplete = true;
            this.handleCallBack(messageData.queryParams);
        } catch (error) {
            // non-json payload, someone else's message
        }
    }

    handleCallBack(response: any) {
        this.trustCommerceService.submitCallback(this.data.caseIntegrationQueueId, response).subscribe(r => {
            this.dialog.closeAll();
        });
    }
}
