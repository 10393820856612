import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { SafeResourceUrl } from "@angular/platform-browser";
import { loadStripe, Stripe, StripeElements } from '@stripe/stripe-js';
import { StripeService } from 'app/services/case/stripe.service';

@Component({
    selector: 'stripe-component',
    host: { 'class': 'content' },
    templateUrl: './stripe.component.html',
    styleUrls: ['./stripe.component.scss']
})
export class StripeComponent implements OnInit {
    url: SafeResourceUrl;
    isStripeInterviewComplete: boolean = false;
    stripe: Stripe;
    elements: StripeElements;
    hasError: boolean = true;

    constructor(
    private stripeService: StripeService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data
    ) { }

    strikeCheckout: any = null;
    async ngOnInit() {
        await this.SetupStripe();
    }

    ProcessSubmitResponse(result) {
        if (result.error) {
            this.hasError = false;
            const messageContainer = document.querySelector('#error-message');
            messageContainer.textContent = result.error.message;
            // Inform the customer that there was an error.
        } else {

            const requestPayload = { intent: result.setupIntent, customer: this.data.intent.customer };
            this.stripeService.submitCallback(this.data.caseIntegrationQueueId, requestPayload).subscribe(r => {
                this.dialog.closeAll();
            });
        }
    }

    async ProcessSubmit(event) {
        {
            event.preventDefault();
            await this.stripe.confirmSetup({
                elements: this.elements,
                confirmParams: {
                    return_url: location.href
                },
                redirect: 'if_required'
            }).then(this.ProcessSubmitResponse.bind(this));
        }
    }

    async SetupStripe() {
        this.stripe = await loadStripe(this.data.publishableKey.toString());
        const stripeoptions = {
            clientSecret: this.data.intent.client_secret,
            // fully customizable with appearance api.
            appearance: {/*...*/ },
        };
        this.elements = this.stripe.elements(stripeoptions);
        // Create and mount the Payment Element
        const paymentElement = this.elements.create('payment');
        paymentElement.mount('#payment-element');
        const form = document.getElementById('payment-form');

        form.addEventListener('submit', this.ProcessSubmit.bind(this));

    }
    ngAfterViewInit() {
        const numberInput = document.getElementById('payment-element');
        numberInput.focus();
    }
}
