import { Component, Input } from '@angular/core';

export enum MessageBoxType {
    Plain = 'plain',
    Warning = 'warning',
}

@Component({
    selector: 'message-box',
    templateUrl: './message-box.component.html',
    styleUrls: ['message-box.component.scss']
})
export default class MessageBoxComponent {
    @Input() title: string;
    @Input() message: string;
    @Input() type = MessageBoxType.Warning;

    protected hasIcon = false;

    protected get MessageBoxType() { return MessageBoxType; }

    //#region Helpers

    protected isIconType() {
        return this.type == MessageBoxType.Warning;
    }

    //#endregion
    //#region Lifecycle

    ngOnChanges() {
        this.hasIcon = this.isIconType();
    }

    //#endregion
}
