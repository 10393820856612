import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CaseSummaryService, RoutingService } from '../';


@Injectable()
export class ValidCaseGuard implements CanActivate {
    constructor(
        private caseSummaryService: CaseSummaryService,
        private routingService: RoutingService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return new Promise((resolve) => {
            const clientCode = route.params.clientCode;
            const caseId = route.params.caseId;

            this.caseSummaryService.validateCaseNavigation(clientCode, caseId).subscribe({
                next: (result) => {
                    if (result)
                        resolve(true);
                    else
                        this.routingService.navigateToNotFound();
                },
                error: () => {
                    this.routingService.navigateToNotFound();
                }});
        });
    }
}
