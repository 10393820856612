import { InterviewSectionSummaryDTO } from '@DTOs';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Subject } from 'rxjs/internal/Subject';
import { AppService } from '../../../app/app.service';
import { MonitoringService } from '../../monitor.service';
import { RulesService } from '../config';
import { CaseMapKeyService } from './case-mapkey.service';

@Injectable()
export class InterviewSectionActionService {
    isActive = false;
    sectionSummaries: any = [];
    displayMessage: string;

    processingTimeout: any;
    startTimeout: any;
    ruleInterval: any;
    activeMetric: any;

    private sectionWaitingSource = new Subject<any>();
    sectionWaitingObservable = this.sectionWaitingSource.asObservable();

    constructor(
        private appService: AppService,
        private rulesService: RulesService,
        private caseMapKeyService: CaseMapKeyService,
        private monitorService: MonitoringService) {
    }

    getSectionSettings(section) {
        if (section == null) { return {}; }

        // ultimately i'm not sure this function belongs in here, but I didn't want to duplicate this "legacy check"
        // logic so i'm stuffing it here for re-use.
        const objectProperties = _.keyBy(section.objectProperties, 'key');

        let sectionSettings;
        if (objectProperties['isProcessingPopupEnabled'] != null) {
            // LEGACY - This code is here until we convert to the new format
            sectionSettings = {
                isProcessingPopupEnabled: objectProperties['isProcessingPopupEnabled'].value === 'true' || false,
                displayMessage: _.get(objectProperties['displayMessage'], 'value'),
                timeout: _.get(objectProperties['timeout'], 'value'),
                sectionProcessingRuleId: _.get(objectProperties['sectionProcessingRuleId'], 'value')
            };
        } else {
            const sectionSettingsProperty = objectProperties['sectionSettings'];
            sectionSettings = sectionSettingsProperty != null ? JSON.parse(sectionSettingsProperty.value) : {};
        }
        sectionSettings.sectionName = section.name;

        return sectionSettings;
    }

    getSectionRoleHiddenSettings(section) {
        if (section == null) { return {}; }
        return this.getSectionSettingByKey(section, 'sectionRoleSetting');
    }

    getSectionApiSettings(section) {
        if (section == null) { return {}; }
        return this.getSectionSettingByKey(section, 'sectionApiSettings');
    }

    private getSectionSettingByKey(section, key) {
        if (section == null) { return {}; }

        const objectProperties = _.keyBy(section.objectProperties, 'key');
        const sectionProperties = objectProperties[key];
        return sectionProperties && sectionProperties != null && sectionProperties.value ? JSON.parse(sectionProperties.value) : {};
    }

    sectionConfiguredToWait(sectionId: string, visibleSections: InterviewSectionSummaryDTO[]) {
        const sectionSettings = this.getSectionSettingsBySectionId(sectionId, visibleSections);
        return sectionSettings.isProcessingPopupEnabled && sectionSettings.sectionProcessingRuleId;
    }

    async sectionShouldWait(accountId: string, caseId: string, sectionId: string, visibleSections: InterviewSectionSummaryDTO[]) {
        const sectionSettings = this.getSectionSettingsBySectionId(sectionId, visibleSections);

        let ruleResult = false;
        if (this.sectionConfiguredToWait(sectionId, visibleSections)) {
            
            ruleResult = await this.rulesService.executeApi(caseId, sectionSettings.sectionProcessingRuleId, false);

            if (ruleResult) {   
                this.appService.display(true);             
                this.displayMessage = sectionSettings.displayMessage;                

                this.isActive = true;
                this.activeMetric = {
                    caseId,
                    startTime: moment.utc().format('YYYY-MM-DDTHH:mm:ss:SSZ'),
                    endTime: null,
                    accountId,
                    sectionId,
                    sectionName: sectionSettings.sectionName
                };

                // hide and proceed after the overall timeout is reached
                this.processingTimeout = setTimeout(() => {
                    this.closePopup();
                }, sectionSettings.timeout * 1000);

                // don't pop this loader until at least 2 seconds have passed
                this.startTimeout = setTimeout(() => {
                    this.appService.showSecondaryLoadingMask(true);
                    this.appService.display(false);
                }, 2000);

                // re-run the rule every few seconds, since it may not be tied to an integration response
                this.ruleInterval = setInterval(() => {
                    this.rerunPopupRule(accountId, caseId, sectionId, visibleSections);
                }, 3000);
            }else{
                this.appService.display(false);
            }
        }

        return ruleResult;
    }

    async rerunPopupRule(accountId, caseId, sectionId, visibleSections: InterviewSectionSummaryDTO[]) {
        let ruleResult = false;

        if (this.sectionConfiguredToWait(sectionId, visibleSections) && this.isActive) {
            const sectionSettings = this.getSectionSettingsBySectionId(sectionId, visibleSections);
            if (sectionSettings && sectionSettings.sectionProcessingRuleId) {
                ruleResult = await this.rulesService.executeApi(caseId, sectionSettings.sectionProcessingRuleId);

                if (!ruleResult) {
                    clearInterval(this.ruleInterval); // stop the interval so we don't re-run in the middle of update VMKs.

                    await this.caseMapKeyService.updateAndMergeVirtualMapKeys(accountId, caseId);

                    this.closePopup();
                }
            }
        }

        return ruleResult;
    }

    closePopup() {
        clearTimeout(this.startTimeout);
        clearTimeout(this.processingTimeout);
        clearInterval(this.ruleInterval); // also stop the ruleInterval here in case we closed outside of the rerunPopupRule flow.

        // hide loaders
        this.appService.showSecondaryLoadingMask(false);
        this.appService.display(false);

        // allow section processing to continue
        this.sectionWaitingSource.next(false);

        // complete and clear the metric in context
        if (this.activeMetric) {
            this.activeMetric.endTime = moment.utc().format('YYYY-MM-DDTHH:mm:ss:SSZ');
            this.monitorService.logEvent(`Section Display Message`, this.activeMetric);
            this.activeMetric = null;
        }
        // indicate that we are no longer actively doing anything
        this.isActive = false;
    }

    private getSectionSettingsBySectionId(sectionId: string, visibleSections: InterviewSectionSummaryDTO[]): any {
        let section = _.find(this.sectionSummaries, function (o) {
            return o.id === sectionId;
        });
        if (!section) {
            section = _.find(visibleSections, function (o) {
                return o.id === sectionId;
            });
        }

        return this.getSectionSettings(section);
    }
}
