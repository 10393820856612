<!-- Settings Menu Nav -->
<div class="left-panel w-240px bg-light d-flex flex-column">
    <div class="left-panel-header">
        <span class="left-panel-title">Settings</span>
    </div>
    <div class="left-panel-body flex-fill overflow-y-auto custom-scrollbar">
        <div class="list-container">
            <ng-container *ngFor="let setting of settings">
                <div
                    class="list-item"
                    matRipple
                    *ngIf="!setting.hide"
                    [ngClass]="{'active': setting.code == this.selectedSetting?.code}"
                    [attr.data-test-name]="setting.name"
                    [id]="setting.name"
                    (click)="setActiveSetting(setting)">
                    <div class="list-item-title">{{ setting.name }}</div>
                </div>
            </ng-container>
        </div>
        <div class="p-4 text-center text-muted" *ngIf="!hasSettings">
            <img src="./assets/images/no-data-placeholder.png" alt="" class="pb-3" />
            <h6>No Settings Found</h6>
        </div>
    </div>
</div>
<!-- Sub-page -->
<div class="right-panel flex-fill d-flex flex-column">
    <div *ngIf="!selectedSetting?.code" class="right-panel-body flex-fill overflow-y-auto custom-scrollbar p-3">
        <div class="p-4 text-center text-muted">
            <img src="./assets/images/no-data-placeholder.png" alt="" class="pb-3" />
            <h6>Please select a setting ...</h6>
        </div>
    </div>
    <div *ngIf="selectedSetting?.code == SettingsMenuItemCode.GeneralSettings" class="right-panel-body flex-fill overflow-y-auto custom-scrollbar">
        <general-settings [accountInfo]="account"></general-settings>
    </div>
    <div *ngIf="selectedSetting?.code == SettingsMenuItemCode.CaseTransferSettings" class="right-panel-body flex-fill overflow-y-auto custom-scrollbar p-3">
        <case-transfer-settings [accountInfo]="account" [rules]="rules" [secretKeys]="secretKeys"></case-transfer-settings>
    </div>
    <div *ngIf="selectedSetting?.code == SettingsMenuItemCode.DocumentSigning" class="right-panel-body flex-fill overflow-y-auto custom-scrollbar p-3">
        <document-signing [accountInfo]="account" [mapKeys]="mapKeys" [rules]="rules"></document-signing>
    </div>
    <div *ngIf="selectedSetting?.code == SettingsMenuItemCode.MyCases" class="right-panel-body flex-fill overflow-y-auto custom-scrollbar p-3">
        <my-cases-settings [accountInfo]="account"></my-cases-settings>
    </div>
    <div *ngIf="selectedSetting?.code == SettingsMenuItemCode.ApiMappings" class="right-panel-body flex-fill overflow-y-auto custom-scrollbar">
        <api-mappings [accountInfo]="account" [nonVirtualMapKeys]="mapKeys" [secrets]="secretKeys"></api-mappings>
    </div>
    <div *ngIf="selectedSetting?.code == SettingsMenuItemCode.InterviewSettings" class="right-panel-body flex-fill overflow-y-auto custom-scrollbar">
        <interview-settings [accountInfo]="account" [rules]=rules></interview-settings>
    </div>
    <div *ngIf="selectedSetting?.code == SettingsMenuItemCode.PurlSettings" class="right-panel-body flex-fill overflow-y-auto custom-scrollbar">
        <purl-settings [accountInfo]="account"></purl-settings>
    </div>
    <div *ngIf="selectedSetting?.code == SettingsMenuItemCode.CaseSummarySidebar" class="right-panel-body flex-fill overflow-y-auto custom-scrollbar">
        <case-summary-sidebar [account]="account"></case-summary-sidebar>
    </div>
    <div *ngIf="selectedSetting?.code == SettingsMenuItemCode.WorkbenchSettings" class="right-panel-body flex-fill overflow-y-auto custom-scrollbar">
        <workbench-settings [client]="client" [account]="account"></workbench-settings>
    </div>
</div>