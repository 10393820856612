import { FeatureToggle, MapKeyType } from "@Enums";
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { AccountMapKeyComponent } from 'app/account/account-config/account-mapkeys/account-mapkey-view.component';
import { AppService } from 'app/app.service';
import { Account, MapkeyConfig } from 'app/models';
import { ConfirmationDialogService, FeatureToggleService, MapkeyNode, MapKeyService, RoutesEnum } from 'app/services';
import * as _ from 'lodash';
import { ConfirmationService } from 'primeng/api';
import { EditableConstantMapkey } from './models/constant-mapkey';
import { EntityType, MapkeyOperation } from './models/mapkey-operation';

@Component({
    selector: 'account-mapkeys',
    host: { 'class': 'd-flex flex-row flex-fill', 'style': 'height:100%' },
    templateUrl: './account-mapkeys-list.component.html',
    styleUrls: ['./account-mapkeys-list.component.scss'],
    providers: [ConfirmationService]
})

export class AccountMapKeysComponent implements OnInit {
    @Input() accountInfo: Account;
    @Input() rules: any;
    @ViewChild(AccountMapKeyComponent) accountMapKeyComponent: AccountMapKeyComponent;
    selectedNode: MapkeyNode;
    items: any[];
    showForm = false;
    isEdit = false;
    showContent = false;
    rootName: string;
    constant: EditableConstantMapkey;
    parentNode: any = {};
    caseMapKeys: MapkeyConfig[] = [];
    testResult: any;
    accountIntegrations: any[] = [];
    mapkeyOperation: MapkeyOperation;

    @Output() newMapKeyAdded = new EventEmitter<boolean>();

    rootMapNodeId: any;
    rootMapNode: any;
    url: string;
    addDynamicListsFeatureFlag: boolean;

    constructor(
        private _fb: UntypedFormBuilder,
        private appService: AppService,
        private mapkeysConfirmationService: ConfirmationDialogService,
        public mapKeysService: MapKeyService,
        private featureToggleService: FeatureToggleService
    ) {
        this.checkACL('R', RoutesEnum.dashboard);
    }

    ngOnInit() {
        this.featureToggleService.getFeatureFlag(FeatureToggle.ConfigMapkeysAccountDynamicListClient, this.accountInfo.clientId).subscribe(data => {
            this.addDynamicListsFeatureFlag = data;
        });
    }

    checkACL(permissionType, redirect?) {
        return this.appService.checkACL('Accounts', permissionType, redirect);
    }

    deleteChildFromNode(childData) {
        const id = childData.id;

        for (const childIndex in this.mapKeysService.treeData) {
            const child = this.mapKeysService.treeData[childIndex];
            this.deleteChildNode(child, id, this.mapKeysService.treeData, childIndex);
        }
    }

    deleteChildNode(child, id, previousChildren, childIndex) {
        if (child.id === id) {
            previousChildren.splice(childIndex, 1);
            return;
        } else {
            if (child.children && child.children.length) {
                for (const index in child.children) {
                    const newChild = child.children[index];
                    this.deleteChildNode(newChild, id, child.children, index);
                }
            }
        }
    }

    nodeSelect(event) {
        this.items = [];
        const writeAccess = this.checkACL('W');
        if (event.rootMapNode === 'Account' && writeAccess) {
            switch (event.level) {
                case 'root':
                    this.items = [
                        { label: 'Add Folder', command: () => this.addItem(EntityType.Entity) },
                        { label: 'Add Constant', command: () => this.addItem(EntityType.Constant) }
                    ];
                    if (this.addDynamicListsFeatureFlag) {
                        this.items = [...this.items, { label: 'Add Dynamic List', command: () => this.addItem(EntityType.DynamicList) }];
                    }
                    break;
                case 'entity':
                    this.items = [
                        { label: 'Add Folder', command: () => this.addItem(EntityType.Entity) },
                        { label: 'Edit Folder', command: () => this.editItem(EntityType.Entity) },
                        { label: 'Add Constant', command: () => this.addItem(EntityType.Constant) },
                    ];

                    if (this.addDynamicListsFeatureFlag) {
                        this.items = [...this.items, { label: 'Add Dynamic List', command: () => this.addItem(EntityType.DynamicList) }];
                    }
                    if (writeAccess) {
                        this.items.push({ label: 'Delete Folder', command: () => this.delete('folder', 'mapKeyEntities', event.entityHierarchy ?? event.name) });
                    }
                    break;
                case 'mapkey':
                    if (event.mapKeyTypeId == MapKeyType.DynamicList) {
                        this.items = [
                            { label: 'Edit Dynamic List', command: () => this.editItem(EntityType.DynamicList) }
                        ];
                        if (writeAccess) {
                            this.items.push({ label: 'Delete Dynamic List', command: () => this.delete('dynamic list', 'MapKeys', event.entityHierarchy ?? event.name) });
                        }

                    } else {
                        this.items = [
                            { label: 'Edit Constant', command: () => this.editItem(EntityType.Constant) }
                        ];
                        if (writeAccess) {
                            this.items.push({ label: 'Delete Constant', command: () => this.delete('constant', 'MapKeys', event.entityHierarchy ?? event.name) });
                        }
                    }
                    break;
            }
        } else if (event.rootMapNode === 'Case' && writeAccess && !event.isSystemGenerated) {
            switch (event.level) {
                case 'root':
                    this.items = [
                        { label: 'Add Folder', command: () => this.addItem(EntityType.Entity) },
                        { label: 'Add Mapkey', command: () => this.addItem(EntityType.Mapkey) }
                    ];
                    break;
                case 'entity':
                    this.items = [
                        { label: 'Add Folder', command: () => this.addItem(EntityType.Entity) },
                        { label: 'Edit Folder', command: () => this.editItem(EntityType.Entity) },
                        { label: 'Add Mapkey', command: () => this.addItem(EntityType.Mapkey) }
                    ];
                    if (writeAccess) {
                        this.items.push({ label: 'Delete Folder', command: () => this.delete('folder', 'mapKeyEntities', event.entityHierarchy ?? event.name) });
                    }
                    break;
                case 'mapkey':
                    this.items = [
                        { label: 'Edit Mapkey', command: () => this.editItem(EntityType.Mapkey) }
                    ];
                    if (writeAccess) {
                        this.items.push({ label: 'Delete Mapkey', command: () => this.delete('mapkey', 'MapKeys', event.entityHierarchy ?? event.name) });
                    }
                    break;
            }
        } else if (event.rootMapNode === 'Case' && writeAccess && event.isSystemGenerated) {
            switch (event.level) {
                case 'root':
                    this.items = [
                        { label: 'Add Folder', command: () => this.addItem(EntityType.Entity) },
                        { label: 'Add Mapkey', command: () => this.addItem(EntityType.Mapkey) },
                    ];
                    break;
                case 'entity':
                    this.items = [
                        { label: 'Add Folder', command: () => this.addItem(EntityType.Entity) },
                        { label: 'Add Mapkey', command: () => this.addItem(EntityType.Mapkey) },
                    ];
                    break;
                case 'mapkey':
                    this.items = [
                        { label: 'Edit Mapkey', command: () => this.editItem(EntityType.Mapkey) }
                    ];
                    break;
            }
        }
    }

    addItem(itemType: EntityType) {
        this.mapkeyOperation = {
            accountId: this.accountInfo.id,
            clientId: this.accountInfo.clientId,
            isView: false,
            entityType: itemType,
            isNew: true,
            selectedNode: this.selectedNode,
            mapkeyTypeDisplay: itemType,
            isSystemGenerated: false,
            entityHierarchy: '',
            name: ''
        };

        this.showContent = true;
    }

    editItem(itemType: EntityType) {
        this.mapkeyOperation = {
            isView: false,
            isNew: false,
            entityType: itemType,
            selectedNode: this.selectedNode,
            mapkeyTypeDisplay: itemType,
            isSystemGenerated: this.selectedNode.isSystemGenerated,
            entityHierarchy: this.selectedNode.entityHierarchy,
            name: this.selectedNode.name,
            virtualExpression: this.selectedNode.virtualExpression,
            clientId: this.selectedNode.clientId,
            accountId: this.selectedNode.accountId
        };

        this.showContent = true;
    }

    delete(type, url, entityName) {
        const title = type.charAt(0).toUpperCase() + type.slice(1);
        this.mapkeysConfirmationService.confirm({
            message: `Are you sure that you want to delete the ${type} "${entityName}" ?`,
            key: 'mapkeyConfirm',
            title: `Delete ${title}`,
            showCancel: true,
            okLabel: 'delete',
            accept: () => {
                this.appService.deleteData(`${url}/${this.selectedNode.id}`).subscribe(
                    data => {
                        if (data.status === 'success') {
                            this.deleteChildFromNode(data.data);
                            this.constant = null;
                            this.showForm = false;
                            this.showContent = false;
                            this.appService.showMsg('success', data.message);
                            this.newMapKeyAdded.emit(this.url === 'mapKeyEntities');
                        } else {
                            this.appService.showMsg('error', data.message);
                        }
                    }
                );
            }
        });
    }

    // Node data will contain
    getSelectedNodeInfo(item, reloading = false) {
        if (!reloading) {
            this.showContent = false;
            this.constant = null;
            this.mapkeyOperation = null;
        }
        const itemNode = item.node;   //This is either a MapkeyEntitiesDTO or MapkeysDTO

        if (!itemNode.isConstant) {
            this.showForm = false;
            this.showContent = true;
            this.mapkeyOperation = {
                id: itemNode.id,
                accountId: this.accountInfo.id,
                clientId: this.accountInfo.clientId,
                isView: true,
                entityType: itemNode.level === 'mapkey' ? EntityType.Mapkey : EntityType.Entity,
                selectedNode: itemNode,
                mapKeyEntityId: itemNode.mapKeyEntityId,
                mapkeyTypeDisplay: '',
                isSystemGenerated: itemNode.isSystemGenerated,
                entityHierarchy: itemNode.entityHierarchy,
                name: itemNode.name,
                isVirtualExpression: itemNode.isVirtualExpression,
                virtualExpression: itemNode.virtualExpression,
                isDynamicList: itemNode.mapKeyTypeId == MapKeyType.DynamicList
            };

            if (this.mapkeyOperation.isDynamicList) {
                this.mapkeyOperation.entityType = EntityType.DynamicList;

                this.mapkeyOperation.dynamicList = {
                    id: itemNode.id,
                    title: 'Constant Details',
                    name: itemNode.label,
                    mapKeyEntityId: itemNode.mapKeyEntityId,
                    accountId: this.accountInfo.id,
                    virtualExpression: itemNode.virtualExpression
                };
            }

            this.showContent = true;
        } else if (itemNode.isConstant) {
            this.showContent = true;
            if (itemNode.constantMapKeyValues) {
                itemNode.constantMapKeyValues = _.sortBy(itemNode.constantMapKeyValues, [function (o) {
                    return o.order;
                }]);
            }

            this.constant = {
                id: itemNode.id,
                title: 'Constant Details',
                name: itemNode.label,
                mapKeyEntityId: itemNode.mapKeyEntityId,
                accountId: this.accountInfo.id,
                isConstant: itemNode.isConstant,
                isList: itemNode.isList,
                constantMapKeyValues: itemNode.constantMapKeyValues,
                constantMapkeyVariants: itemNode.constantMapkeyVariants,
                isEdit: false,
                entityHierarchy: itemNode.entityHierarchy,
                isSystemGenerated: itemNode.isSystemGenerated,
                isVirtualExpression: itemNode.isVirtualExpression,
                virtualExpression: itemNode.virtualExpression,
                isVirtual: itemNode.isVirtual,
                isRepeatable: itemNode.isRepeatable,
                isJson: itemNode.isJson,
                delimiter: itemNode.delimiter,
                allValues: itemNode.allValues,
                sanitizedValue: itemNode.sanitizedValue,
                mapKeyTypeId: itemNode.mapKeyTypeId,
                isPii: itemNode.isPii,
                isSearchable: itemNode.isSearchable,
                repBlockMapKeys: itemNode.repBlockMapKeys,
                virtualMapKeysList: itemNode.virtualMapKeysList,
            };

            this.mapkeyOperation = {
                isView: true,
                entityType: EntityType.Constant,
                selectedNode: itemNode,
                constant: this.constant,
                mapkeyTypeDisplay: 'Constant',
                isSystemGenerated: itemNode.isSystemGenerated,
                entityHierarchy: itemNode.entityHierarchy,
                name: itemNode.name
            };
        }
    }

    onNewMapkeyAdded($event: any) {
        this.newMapKeyAdded.emit($event.reloadData);
        this.selectNodeByMapkeyId($event.mapkeyId);
        this.getSelectedNodeInfo({ node: this.selectedNode }, true);
    }

    refreshView() {
        if (this.accountMapKeyComponent) {
            this.accountMapKeyComponent.reloadUsedInData();
        }
    }

    selectNodeByMapkeyId(mapkeyId): any {
        for (const child of this.mapKeysService.treeData) {
            this.selectNodeByMapkeyIdInTree(child, mapkeyId);
        }
    }

    selectNodeByMapkeyIdInTree(child, mapkeyId) {
        if (child.id === mapkeyId) {
            this.selectedNode = child;
            return;
        } else if (child.children && child.children.length) {
            for (const newChild of child.children) {
                this.selectNodeByMapkeyIdInTree(newChild, mapkeyId);
            }
        }
    }

    canDeactivate(routerSnapshot: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState: RouterStateSnapshot) {
        if (this.accountMapKeyComponent) {
            return this.accountMapKeyComponent.canDeactivate(routerSnapshot, currentState, nextState);
        } else {
            return true;
        }
    }
}
