<h1 mat-dialog-title>Configure GTL Submission</h1>
<div mat-dialog-content>
  <div class="subtext">Used to send case information to GTL API. Contact GTL for required information.
  </div>
  <div class="flex-fill d-flex flex-column overflow-y-auto">
    <form [formGroup]="webhookForm" novalidate autocomplete="off" class="addpadding">
      <mat-form-field class="w-100">
        <input id="url" placeholder="URL" matInput formControlName='url'>
        <mat-error *ngIf="webhookForm.get('url').hasError('required')">required</mat-error>
      </mat-form-field>
      <mat-form-field class="w-100">
        <input id="apiKey" placeholder="API Key" matInput formControlName='apiKey'>
        <mat-error *ngIf="webhookForm.get('apiKey').hasError('required')">required</mat-error>
      </mat-form-field>
      <map-keys-dropdown [displayList]="nonListMapKeys" placeholder="Agent Code Mapkey"
        formControlName="agentCodeMapKeyId" [formControl]="webhookForm.get('agentCodeMapKeyId')">
      </map-keys-dropdown>
      <map-keys-dropdown [displayList]="mapKeys" placeholder="Agent ID Mapkey" formControlName="agentMapKeyId"
        [formControl]="webhookForm.get('agentMapKeyId')">
      </map-keys-dropdown>
      <map-keys-dropdown [displayList]="mapKeys" placeholder="Agent Key Mapkey" formControlName="agentKeyMapKeyId"
        [formControl]="webhookForm.get('agentKeyMapKeyId')">
      </map-keys-dropdown>
      <map-keys-dropdown [displayList]="mapKeys" placeholder="Auth Key Mapkey" formControlName="authKeyMapKeyId"
        [formControl]="webhookForm.get('authKeyMapKeyId')">
      </map-keys-dropdown>
      <map-keys-dropdown [displayList]="mapKeys" placeholder="Expire Time Mapkey" formControlName="experationMapKeyId"
        [formControl]="webhookForm.get('experationMapKeyId')">
      </map-keys-dropdown>
      <map-keys-dropdown [displayList]="nonListMapKeys" placeholder="State Mapkey" formControlName="stateMapKeyId"
        [formControl]="webhookForm.get('stateMapKeyId')">
      </map-keys-dropdown>
      <map-keys-dropdown [displayList]="nonListMapKeys" placeholder="Product Category Mapkey"
        formControlName="productCategoryMapKeyId" [formControl]="webhookForm.get('productCategoryMapKeyId')">
      </map-keys-dropdown>
      <map-keys-dropdown [displayList]="nonListMapKeys" placeholder="Product Code Mapkey"
        formControlName="productCodeMapKeyId" [formControl]="webhookForm.get('productCodeMapKeyId')">
      </map-keys-dropdown>
      <map-keys-dropdown [displayList]="mapKeys" placeholder="Assessment Response Mapkey"
        formControlName="assessmentResponseMapKeyId" [formControl]="webhookForm.get('assessmentResponseMapKeyId')">
      </map-keys-dropdown>
      <mat-form-field class="w-100 textarea-formfield">
        <textarea id="content" placeholder="Content" matInput formControlName='content' rows="10"></textarea>
        <mat-error *ngIf="formSubmitted && webhookForm.get('content').hasError('required')">
          Content
          <strong>required</strong>
        </mat-error>
      </mat-form-field>
      <div class="flex flex-row">
        <mat-form-field class="mr-5">
          <input id="retryAttempts" type="number" matInput formControlName="retryAttempts"
            placeholder="Max # of Attempts" />
        </mat-form-field>
        <mat-form-field class="mr-5">
          <input id="expiration" type="number" matInput formControlName="expirationHours"
            placeholder="Expiration (Hours)" />
        </mat-form-field>
        <div style="display: flex; align-items:center;">
          <mat-slide-toggle id="errorCase" formControlName="errorCaseOnFailure">Failures Result in Case Error
          </mat-slide-toggle>
        </div>
      </div>
    </form>
  </div>
</div>
<div style="padding-right:20px">
  <mat-dialog-actions align="end">
    <mrs-btn-tertiary buttonLabel="Cancel" buttonId="cancelButton" mat-dialog-close>Cancel</mrs-btn-tertiary>
    <mrs-btn-primary buttonId="continueButton" buttonLabel="Continue" (onClick)="saveAccountMessage()">Continue
    </mrs-btn-primary>
  </mat-dialog-actions>
</div>