import { Component, OnInit } from '@angular/core';
import { BaseInterviewFormFieldComponent, BaseFormFieldServices } from '../base-interview-form-field/base-interview-form-field.component';

@Component({
    selector: 'action-button',
    templateUrl: './action-button.component.html',
    styleUrls: ['./action-button.component.scss']
})
export class ActionButtonComponent extends BaseInterviewFormFieldComponent implements OnInit {
    constructor(public services: BaseFormFieldServices) {

        super(services);
    }

    ngOnInit() {
        super.initialize({
            inputType: 'actionButton',
            skipValidators: true
        });
    }

    /**
   * Calling post Actions on click of action button
   */
    onClickActionButton() {
        if (this.isLiveMode()) {
            this.group.get(this.config.id).setErrors(null);
            this.config.answerTimeStamp = new Date();
            const caseDetails = this.services.interviewService.getInterviewData().caseDetails;
            if (caseDetails) {
                this.services.monitorService.logEvent(`Answered Question`, 
                    {
                        "sectionName": this.services.interviewService.getActiveSection().name, 
                        "caseNumber": caseDetails.caseNumber, 
                        "accountCode": this.services.interviewService.account.code, 
                        "role": this.userRole,
                        "sessionId": this.services.signalRService.getSessionId(),
                        "questionReference": this.config.questionTitle,
                        "caseCreatedDate": caseDetails.creationDate,
                        "eventType": "Client Runtime"
                    }
                );
            }
        }

        this.onBlur(true);
    }
}
