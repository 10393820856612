import { NgModule, Component, Injectable, Input, Output, EventEmitter } from '@angular/core';
import {HttpClient, HttpParams, HttpHeaders, HttpResponse} from '@angular/common/http';
import { finalize } from 'rxjs/operators';
import {Observable} from 'rxjs';
import { AppService } from '../app.service';
import { saveAs } from 'file-saver/dist/FileSaver';

@Injectable()
export class ReportDownloadService {

    constructor(
        private appService: AppService,
        private httpClient: HttpClient
    ) { }

    downloadReport(fileType, url, params?, clientId?: string) {
        let headers = new HttpHeaders();
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Authorization', 'Bearer ' + this.appService.getAuthToken());

        clientId = this.appService.getClientId(clientId);
        if (clientId != null) {
            headers = headers.append('X-Tenant-Guid', clientId);
        }

        let baseUrl = this.appService.getAPIBaseURL();
        url = baseUrl + url;
        if (params && params != null) {
            const httpPost = this.httpClient.post(url, params, { headers: headers, responseType: 'blob', observe: 'response' });

            httpPost.subscribe(
                (response: HttpResponse<Blob>) => {
                    this.saveToFileSystem(response, fileType);
                }
                ,
                error => this.appService.showResponseErrorMsg(error)
            );
        } else {
            const httpGet = this.httpClient.get(url, { headers: headers, responseType: 'blob', observe: 'response' });

            httpGet.subscribe(
                (response: HttpResponse<Blob>) => {
                    this.saveToFileSystem(response, fileType);
                }
                ,
                error => this.appService.showResponseErrorMsg(error)
            );
        }
    }

    saveToFileSystem(response: HttpResponse<Blob>, fileType: string) {
        this.appService.display(false);
        const contentDispositionHeader: string = response.headers.get('content-disposition');
        const parts: string[] = contentDispositionHeader.split(';');
        //const filename = [1].split('"')[1];
        const fn = parts[1].split('=');
        let filename = fn[1];
        if (filename.indexOf('"') > -1){
            filename = filename.split('"')[1];
        }
        const blob = new Blob([response.body], { type:  fileType });
        saveAs(blob, filename);
    }
}