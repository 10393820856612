<section #sectionContainer>
    <h2 mat-dialog-title class="dialog-title">{{ dialogTitle }}</h2>

    <mat-dialog-content *ngIf="!interviewLoaded || !interviewManager.constantMapKeysLoaded">
        <mat-spinner diameter="20"></mat-spinner>
    </mat-dialog-content>

    <mat-dialog-content [id]="sectionContainerHtmlId" *ngIf="interviewLoaded && interviewManager.constantMapKeysLoaded">
        <dynamic-form
            [expandAllQuestions]="true"
            [selectedQuestion]="interviewManager.selectedQuestion"
            [interviewMode]="interviewManager.interviewMode"
            [parentIndex]=""
            [sectionLocked]="interviewManager.formattedSection.isLocked"
            [(constantLists)]="interviewManager.constantLists"
            [config]="interviewManager.formattedSection.questions"
            [formattedSection]="interviewManager.formattedSection">
        </dynamic-form>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <mrs-btn-tertiary class="close-btn" buttonId="close" buttonLabel="Close" (onClick)="handleClose()"></mrs-btn-tertiary>
        <mrs-btn-primary [buttonLabel]="saveButtonLabel" buttonId="saveDecision" (click)="handleSave()"></mrs-btn-primary>
    </mat-dialog-actions>
</section>