<div class="d-flex flex-column">
    <div (click)="$event.stopPropagation(); selectReflexiveChoice(reflexiveChoice)"
        [matTooltip]="reflexiveChoice.displayName" class="node-container" matTooltipPosition="right">
        <div
            [ngClass]="{'active-selected': selectedReflexiveChoice?.id === reflexiveChoice?.id && !selectedQuestion, 'parent-selected': selectedSection?.id === section.id || selectedReflexiveChoice?.questionId === reflexiveChoice.questionId}">
            <div [ngStyle]="{'margin-left':nestedLevel * 2 + 2 + 'rem'}" class="node"
                style="height: 40px; flex: 1 1 40px">
                <button (click)="$event.stopPropagation(); reflexiveChoice.expanded = !reflexiveChoice.expanded"
                    mat-icon-button style="margin-left:-12px;">
                    <mat-icon>{{reflexiveChoice.expanded ? 'expand_less' : 'expand_more'}}</mat-icon>
                </button>
                <div class="node-name">
                    {{reflexiveChoice.displayName}}
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="reflexiveChoice.reflexiveQuestions?.length && reflexiveChoice.expanded" class="q-l-container">
        <interview-navigation-question [isReflexiveChildren]="true" [nestedLevel]="nestedLevel + 1"
            [parentQuestions]="parentQuestions" [questions]="reflexiveChoice.reflexiveQuestions"
            [section]="reflexiveChoice" [selectedQuestion]="selectedQuestion"
            [selectedReflexiveChoice]="selectedReflexiveChoice" [selectedSection]="selectedSection"
            [filterReflexiveQuestionsToQuesionIds]="filterReflexiveQuestionsToQuesions"
            [sections]="sections"></interview-navigation-question>
    </div>
</div>