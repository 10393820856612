import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { AppService } from '@App';
import { IConfigService } from 'app/config/iconfigservice';
import { BaseService } from './base.service';
import { UpdateUserStatusDto } from '@Models';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UserAdminService extends BaseService {

    publicApiBaseUrl: string;
    private sidenav: MatSidenav;

    constructor(public appService: AppService, public configService: IConfigService,
        public httpClient: HttpClient) {
        super(appService, configService, httpClient);
        this.publicApiBaseUrl = this.configService.getConfiguration().publicApiUrl;
    }

    getAllUsers() {
        return super.getData<any>(`${this.publicApiBaseUrl}user/all`);
    }
    getUser(id) {
        return super.getData<any>(`${this.publicApiBaseUrl}user/${id}`);
    }

    createUser(user) {
        return super.postData(`${this.publicApiBaseUrl}user`, [user]);
    }

    editUser(user) {
        return super.putData(`${this.publicApiBaseUrl}user`, [user]);
    }

    updateUserStatus(request: UpdateUserStatusDto): Observable<UpdateUserStatusDto[]> {
        return super.patchData(`${this.publicApiBaseUrl}user/status`, [request]);
    }

    public setSidenav(sidenav: MatSidenav) {
        this.sidenav = sidenav;
    }

    public open() {
        return this.sidenav.open();
    }

    public close() {
        return this.sidenav.close();
    }

    public toggle(): void {
        this.sidenav.toggle();
    }
}
