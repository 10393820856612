<div>
    <form [formGroup]="prefillFormGroup">
        <div [ngClass]="{'header-disabled':filteredQuestions?.length == 0}">
            <h4 [ngStyle]="{'margin-bottom':filteredQuestions?.length == 0?'0':'.5rem'}">Pre-fill Answer</h4>
            <sub class="mat-hint-lookalike" *ngIf="filteredQuestions?.length == 0">No multiple choice single answer question using dynamic list available</sub>
        </div>
        <mat-form-field class="mat-form-field-stretch" style="padding-bottom: .75rem">
            <mat-label>From Question</mat-label>
            <mat-select
                data-testid="from-question-dropdown"
                formControlName="fromQuestion"
                (selectionChange)="fromQuestionChanged($event)">
                <mat-option data-testid="NoneOption"></mat-option>
                <mat-option *ngFor="let question of filteredQuestions" [value]="question.id">
                    {{question.mapKeyName}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="mat-form-field-stretch">
            <mat-label>Pre-fill with</mat-label>
            <mat-select data-testid="prefill-dropdown"  formControlName="populateWith" (selectionChange)="prefillSelected.emit($event.value)">
                <mat-option *ngFor="let prefill of prefillOptions" [value]="prefill.value" >
                    {{prefill.display}}
                </mat-option>
            </mat-select>
            <mat-hint *ngIf="prefillFormGroup.get('fromQuestion').value && prefillOptions.length === 0">No Pre-fill data configured; please use a different question or contact Support.</mat-hint>
            <mat-error *ngIf="prefillFormGroup.get('populateWith').getError('required')">Required when "From Question" is selected.</mat-error>
        </mat-form-field>
    </form>
</div>
