import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatChipListbox,  MatChipSelectionChange } from '@angular/material/chips';
import { MrsChip } from './models/mrs-chip-model';

@Component({
    selector: 'mrs-chip-list',
    templateUrl: './mrs-chip-list.component.html',
    styleUrls: ['./mrs-chip-list.component.scss']
})

export class MrsChipListComponent{

  @ViewChild(MatChipListbox) chipList : MatChipListbox;

  @Input() chips: MrsChip[];
  @Input() chipListId: string;
  @Input() ariaLabel: string;
  @Input() multiple: boolean;
  @Input() selectable: boolean;
  @Output() onChipSelectionChange = new EventEmitter<MrsChip[]>();

  selectedChips : MrsChip[] = [];

  toggleChipClick(chip:MrsChip){
      this.toggleChip(chip);
  }

  toggleChip(chip:MrsChip){

      if(!chip.selectable)
          return;
      
      chip.selected = !chip.selected;

      if(!this.multiple)
          this.deselectOtherChips(chip);

      this.selectedChips = this.chips.filter(chip=>chip.selected);
      this.onChipSelectionChange.emit(this.selectedChips);
  }

  deselectOtherChips(ignoreChip: MrsChip){
      this.chips.filter(chip => chip!=ignoreChip).forEach(chip => chip.selected = false);
  }

  getSelectedChips(){
      return this.selectedChips;
  }
}