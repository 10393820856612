import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AppService } from "../../../../app.service";
import { UntypedFormBuilder, UntypedFormGroup, FormControl, NgForm, Validators, UntypedFormArray } from '@angular/forms';
import * as _ from "lodash";

@Component({
    selector: 'uw-tag',
    templateUrl: './uw-tag.component.html',
    styleUrls: ['./uw-tag.component.scss']
})
export class UWTagComponent implements OnInit {

    _tag;
    tagForm: UntypedFormGroup;
    showForm: boolean = false;
    decisionCategories: any;
    // decisionCategory: any[] = [];

    @Input() rules;
    @Input() index;
    @Input() tagsLength;

    @Input()
    set tag(tagValue) {
        this._tag = tagValue;
        this.tagForm = this._fb.group({
            id: tagValue.id,
            tagName: [tagValue.tagName, Validators.required],
            workFlowId: [tagValue.workFlowId, Validators.required],
            uwdecisionTagDetails: this._fb.array([])
        });

        this.decisionCategories = this.appService.getConfigData('uwDecisionCategories');
        if (tagValue.id) {
            const control = <UntypedFormArray>this.tagForm.get('uwdecisionTagDetails');
            tagValue.uwdecisionTagDetails.forEach(item => {
                control.push(this._fb.group({
                    ruleId: [item.ruleId],
                    decisionCategoryId: [item.decisionCategoryId, Validators.required]
                }));
            });
            this.showForm = false;
            // if (!this.tagValue.isEdit)
            //     this.showForm = false;

        } else {
            let formArray = this.tagForm.get('uwdecisionTagDetails') as UntypedFormArray;
            for (let category of this.decisionCategories) {
                formArray.push(this.createItem(category));
            }
            this.showForm = true;
            // const control = <FormArray>this.tagForm.get('constantMapKeyValues');
            // control.push(this.createItem());
        }
    }

    createItem(category): UntypedFormGroup {
        return this._fb.group({
            ruleId: [this.appService.emptyGUID],
            decisionCategoryId: [category.id, Validators.required]
        });
    }

    get tag() {
        return this._tag;
    }

    @Output() onDelete = new EventEmitter<any>();
    @Output() moveDown = new EventEmitter<any>();
    @Output() moveUp = new EventEmitter<any>();
    @Output() onSave = new EventEmitter<any>();

    constructor(
        private _fb: UntypedFormBuilder,
        private appService: AppService
    ) { }

    ngOnInit() {

    }

    checkACL(permissionType, redirect = undefined) {
        return this.appService.checkACL("Accounts", permissionType, redirect);
    }

    editTag() {

        this.tagForm = this._fb.group({
            id: this._tag.id,
            tagName: [this._tag.tagName, Validators.required],
            workFlowId: [this._tag.workFlowId, Validators.required],
            uwdecisionTagDetails: this._fb.array([])
        });
        const control = <UntypedFormArray>this.tagForm.get('uwdecisionTagDetails');
        this._tag.uwdecisionTagDetails.forEach(item => {
            control.push(this._fb.group({
                ruleId: [item.ruleId || this.appService.emptyGUID],
                decisionCategoryId: [item.decisionCategoryId, Validators.required]
            }));
        });
        this.showForm = true;

    }

    cancel() {
        this.showForm = false;
        this.tagForm.reset();
    }

    saveTag() {
        if (this.tagForm.valid) {

            let isEdit = false;
            let url = "UwdecisionLabel/DecisionTag";

            if (this.tagForm.get("id").value) {
                isEdit = true;
                url = `UwdecisionLabel/DecisionTag/${this.tagForm.get("id").value}`;
            }

            this.tagForm.value.uwdecisionTagDetails.forEach(item => {
                item.ruleId = item.ruleId === this.appService.emptyGUID ? '' : item.ruleId;
            });

            this.appService.postData(url, this.tagForm.value, isEdit, false)
                .subscribe(
                    data => {
                        if (data.status == "success") {
                            this.showForm = false;
                            if (!isEdit) this.onSave.emit();
                            this._tag = Object.assign({}, data.data);
                            this.appService.showMsg("success", "Saved successfully ...");
                        } else {
                            this.appService.showMsg("error", data.message);
                        }
                    }
                );
        } else {
            this.appService.showMsg("error", "Please fill all fields");
        }
    }

    getRuleName(ruleId) {
        if (ruleId && this.rules && this.rules.length) {
            return _.find(this.rules, ['id', ruleId]).name;
        } else {
            return "None";
        }
    }

    getDecisionCategoryName(categoryId) {
        if (categoryId && this.decisionCategories && this.decisionCategories.length) {
            return _.find(this.decisionCategories, ['id', categoryId]).name;
        } else {
            return "None";
        }
    }
}