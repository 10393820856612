import { Injectable } from "@angular/core";
import { Observable, Observer } from "rxjs";


export enum InjectableScripts {
  Zendesk = 'zendesk',
  Hotjar = 'hotjar'
}

export const ScriptStore: Record<InjectableScripts, Script> = {
    [InjectableScripts.Zendesk]: { src: 'https://static.zdassets.com/ekr/snippet.js?key=425a2256-582e-4b4c-9a80-3002abaec622', id: 'ze-snippet', async: true },
    [InjectableScripts.Hotjar]:  { src: './assets/js/hotjar.js' }
};

@Injectable({
    providedIn: 'root',
})
export class ScriptLoaderService {
    public loadDynamicScript(scriptName, script: Script):Observable<Script>{
        InjectableScripts[scriptName] = scriptName;
        ScriptStore[scriptName] = script;
        return this.load(scriptName);
    }
    public load(scriptName: InjectableScripts): Observable<Script> {
        return new Observable<Script>((observer: Observer<Script>) => {
            const theScript = ScriptStore[scriptName];

            // Complete if already loaded
            if (theScript && theScript.loaded) {
                observer.next(theScript);
                observer.complete();
            }
            else {
                // Load the script
                let scriptElement = document.createElement("script");

                if (theScript.id) scriptElement.id = theScript.id;
                if (theScript.async) scriptElement.async = theScript.async;
                if (theScript.defer) scriptElement.defer = theScript.defer;
                scriptElement.type = "text/javascript";
                scriptElement.src = theScript.src;

                scriptElement.onload = () => {
                    theScript.loaded = true;
                    observer.next(theScript);
                    observer.complete();
                };

                scriptElement.onerror = (_error: any) => {
                    observer.error("Couldn't load script " + theScript.src);
                };

                document.getElementsByTagName('head')[0].appendChild(scriptElement);
            }
        });
    }
}

export interface Script {
    src: string,
    id?: string,
    async?: boolean,
    defer?: boolean,
    loaded?: boolean
}
