import { Injectable, ErrorHandler } from '@angular/core';
import { MonitoringService } from './monitor.service';

@Injectable()
export class MonitoringErrorHandler extends ErrorHandler {

    ms: MonitoringService;

    constructor(monitoringService: MonitoringService) {        
        super();
        this.ms = monitoringService;
    }

    handleError(error: any): void {
        this.ms.logError(error);
        super.handleError(error);
    }
}