import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AppService } from 'app/app.service';
import { FeatureManagerService } from 'app/services/global/feature-manager/feature-manager.service';
import { FeatureToggle } from 'app/enums';
import { MonitoringService } from 'app/monitor.service';
import { RuleLocationsAccountConfiguration, RuleLocationsCaseManager, RuleLocationsSignerRules } from "./models/rule-locations";

@Component({
    selector: 'used-in',
    templateUrl: './used-in.component.html',
    styleUrls: ['./used-in.component.scss']
})
export class UsedInComponent implements OnChanges {
    @Input() mapkeyId: string;
    @Input() ruleId: string;
    @Input() clientId: string;
    @Input() accountId: string;
    dataLoaded = false;
    questions: string;
    prefills: string;
    actions: string;
    integrations: string;
    rules: string;
    virtualMapkeys: string;
    settings: string;
    workbenchRequirements: string;
    mibRiskMap: string;
    usedInSystem = false;
    usedInAccountConfiguration = false;
    accountConfiguration: RuleLocationsAccountConfiguration;
    caseManager: RuleLocationsCaseManager;
    startTime: number;
    timerRef: any;
    counter;



    constructor(
        private appService: AppService,
        private featureManagerService: FeatureManagerService,
        private monitoringService: MonitoringService
    ) {
    }

    // This will capture initial setting of inputs and all changes thereafter
    ngOnChanges(changes: SimpleChanges) {

        if (changes.mapkeyId && changes.accountId && changes.mapkeyId.previousValue === changes.mapkeyId.currentValue && changes.accountId.previousValue === changes.accountId.currentValue) {
            return; // We only care if the accountid or mapkeyid are changing
        }

        if (this.mapkeyId && this.accountId) {
            this.fetchData();
        }

        if (this.ruleId && this.accountId) {
            this.fetchData();
        }
    }

    fetchData() {
        if (this.featureManagerService.getByName(FeatureToggle.ConfigMapkeyUsedIn).enabled) {
            this.startTimer();
            if (this.mapkeyId) {
                this.fetchMapkeyData();
            }
            this.endtimer();
        }
        if (this.featureManagerService.getByName(FeatureToggle.ConfigRulesUsedIn).enabled) {
            if (this.featureManagerService.getByName(FeatureToggle.ConfigRulesUsedInIncludeWorkbench).enabled) {
                this.startTimer();
                if (this.ruleId) {
                    this.fetchRuleDataV2();
                }
                this.endtimer();
            } else {
                this.startTimer();
                if (this.ruleId) {
                    this.fetchRuleData();
                }
                this.endtimer();
            }
        }
    }

    private fetchRuleDataV2() {
        this.appService.getData(`Rules/${this.accountId}/RuleLocationsV2/${this.ruleId}`).subscribe((response) => {
            if (response.status == 'success') {
                //const results = JSON.parse(data.data.replaceAll('""', ''))[0];
                const results = JSON.parse(response.data.coreRuleJson.replaceAll('""', ''))[0];
                console.log(results);

                this.accountConfiguration = results;
                this.caseManager = results;
                if (results.SignerRules) {
                    this.accountConfiguration.signerrules = results.SignerRules.map(obj => {
                        const propertyNames = Object.keys(obj);
                        const result: RuleLocationsSignerRules = {
                            name: propertyNames[0],
                            rules: obj[propertyNames[0]]
                        };
                        return result;
                    });
                }

                if (response.data.workbenchRuleUsage) {
                    this.accountConfiguration.workbench = response.data.workbenchRuleUsage;
                }
                
                this.usedInAccountConfiguration = this.accountConfiguration.settings?.length > 0 ||
                    this.accountConfiguration.mapkeys?.length > 0 ||
                    this.accountConfiguration.documents?.length > 0 ||
                    this.accountConfiguration.rules?.length > 0 ||
                    this.accountConfiguration.actions?.length > 0 ||
                    this.accountConfiguration.sections?.length > 0 ||
                    this.accountConfiguration.events?.length > 0 ||
                    this.accountConfiguration.questions?.length > 0 ||
                    this.accountConfiguration.underwritings?.length > 0 ||
                    this.accountConfiguration.signerrules?.length > 0 ||
                    this.accountConfiguration.workbench?.length > 0;

                this.usedInSystem = this.usedInAccountConfiguration ||
                    this.caseManager.queues?.length > 0;

                this.dataLoaded = true;
            }
        });
    }


    private fetchRuleData() {
        this.appService.getData(`Rules/${this.accountId}/RuleLocations/${this.ruleId}`).subscribe((data) => {
            if (data.status == 'success') {
                const results = JSON.parse(data.data.replaceAll('""', ''))[0];
                this.accountConfiguration = results;
                this.caseManager = results;
                if (results.SignerRules) {
                    this.accountConfiguration.signerrules = results.SignerRules.map(obj => {
                        const propertyNames = Object.keys(obj);
                        const result: RuleLocationsSignerRules = {
                            name: propertyNames[0],
                            rules: obj[propertyNames[0]]
                        };
                        return result;
                    });
                }
                console.log(results);
                this.usedInAccountConfiguration = this.accountConfiguration.settings?.length > 0 ||
                    this.accountConfiguration.mapkeys?.length > 0 ||
                    this.accountConfiguration.documents?.length > 0 ||
                    this.accountConfiguration.rules?.length > 0 ||
                    this.accountConfiguration.actions?.length > 0 ||
                    this.accountConfiguration.sections?.length > 0 ||
                    this.accountConfiguration.events?.length > 0 ||
                    this.accountConfiguration.questions?.length > 0 ||
                    this.accountConfiguration.underwritings?.length > 0 ||
                    this.accountConfiguration.signerrules?.length > 0;

                this.usedInSystem = this.usedInAccountConfiguration ||
                    this.caseManager.queues?.length > 0;

                this.dataLoaded = true;
            }
        });
    }

    private fetchMapkeyData() {
        this.appService.getData(`MapKeys/${this.accountId}/MapKeyLocations/${this.mapkeyId}`).subscribe((data) => {
            this.questions = data.data.questions;
            this.prefills = data.data.prefills;
            this.actions = data.data.actions;
            this.integrations = data.data.integrations;
            this.rules = data.data.rules;
            this.virtualMapkeys = data.data.virtualMapkeys;
            this.settings = data.data.settings;
            this.mibRiskMap = data.data.mibRiskMap;

            this.usedInSystem = this.questions.length > 0 ||
                this.actions.length > 0 ||
                this.integrations.length > 0 ||
                this.rules.length > 0 ||
                this.virtualMapkeys.length > 0 ||
                this.settings.length > 0 ||
                this.mibRiskMap.length > 0;
            this.dataLoaded = true;
        });
    }

    private startTimer() {
        this.startTime = Date.now() - (this.counter || 0);
        this.timerRef = setInterval(() => this.counter = Date.now() - this.startTime);
    }

    private endtimer() {
        clearInterval(this.timerRef);

        const logMeasurements = {
            elapsedMilliseconds: this.counter,
            elapsedSeconds: this.counter / 1000,
            elapsedMinutes: this.counter / 1000 / 60
        };
        if (this.mapkeyId) {
            const logData = {
                accountId: this.accountId,
                clientid: this.clientId,
                mapkeyId: this.mapkeyId
            };
            this.monitoringService.logEvent(`Mapkey.UsedIn Fetch Time: ${this.counter / 1000} seconds`, logData, logMeasurements);
        } else if (this.ruleId) {
            const logData = {
                accountId: this.accountId,
                clientid: this.clientId,
                ruleId: this.ruleId
            };
            this.monitoringService.logEvent(`Rule.UsedIn Fetch Time: ${this.counter / 1000} seconds`, logData, logMeasurements);
        }

        this.counter = 0;
    }
}
