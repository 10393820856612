import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AppService } from '@App';

@Component({
    selector: 'conditionset',
    templateUrl: './conditionset.component.html',
    styleUrls: ['./conditionset.component.scss']
})
export class ConditionSetComponent implements OnInit {

    @Input('config') conditionSetForm: UntypedFormGroup;
    @Input() isSubRule;
    @Input() isEditView;
    @Input() mapKeys;
    @Input() nonListNonVirtualMapKeys;
    @Input() variableMapKeys;
    @Input() nonVirtualMapKeys;
    @Output() deleteConditionSet = new EventEmitter<any>();
    @Input() comboData;
    @Input() rules;
    @Input() conditionSet;
    @Input() testResult;
    @Input() isTestExecuted;

    expanded: boolean;
    _formSubmitted: boolean;

    constructor(private _fb: UntypedFormBuilder, private appService: AppService) {
    }

    @Input()
    set collapseMe(value) {
        this.expanded = value;
    }

    @Input()
    set formSubmitted(value) {
        this._formSubmitted = value;

        if (value) {
            this.conditionSetForm.markAsTouched();
        } else {
            this.conditionSetForm.markAsUntouched();
        }
    }

    get formSubmitted() {
        return this._formSubmitted;
    }

    ngOnInit() {
        this.expanded = (this.isSubRule ? false : true);
    }

    delete() {
        this.deleteConditionSet.emit();
    }

    addCondition() {
        // this.config.conditions = [...this.config.conditions, {
        //     "id": "8",
        //     "operand": "mapkey GUID",
        //     "operatorId": "GUID / String = <= >=",
        //     "valueoperand": "Stringvalue",
        //     "mapkeyoperand": "Guid",
        //     "funcoperand": "Guid"
        // }];

        // console.log(this.config.conditions);


        const items = this.conditionSetForm.get('conditions') as UntypedFormArray;

        items.push(this._fb.group({
            order: items.length,
            operand: ['', Validators.required],
            operatorId: ['', Validators.required],
            operandTypeId: ['', Validators.required],
            valueoperand: '',
            mapkeyOperand: '',
            jsonPropertyId: ''
        }));

        // console.log(this.conditionSetForm.value);
        // console.log(this.conditionSetForm.get('conditions')['controls']);

    }

    deleteCondition(conditionIndex) {
        const conditions = this.conditionSetForm.get('conditions') as UntypedFormArray;
        conditions.removeAt(conditionIndex);
    }

    checkACL(permissionType, redirect?) {
        return this.appService.checkACL('Accounts', permissionType, redirect);
    }

    getCondition(i) {
        if (!this.isSubRule && this.conditionSet && this.conditionSet.conditionSet && this.conditionSet.conditionSet.conditions) {
            return this.conditionSet.conditionSet.conditions[i];
        }

        if (this.isSubRule && this.conditionSet && this.conditionSet.conditionSet && this.conditionSet.conditionSet.conditionSet && this.conditionSet.conditionSet.conditionSet.conditions) {
            return this.conditionSet.conditionSet.conditionSet.conditions[i];
        }
        return null;
    }

    getConditionTestResult(i) {
        if (this.testResult && this.testResult.conditionResults) {
            const condition = this.getCondition(i);
            if (condition) {
                return this.testResult.conditionResults.find(tr => tr.conditionId === condition.id);
            }
        }
        return null;
    }
}
