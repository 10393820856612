import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FeatureToggle as FeatureToggleType } from 'app/enums';
import { Observable, of } from 'rxjs';

import { AppService } from '../../app.service';
import { AuthLegacyService } from '../../auth/auth-legacy.service';
import { IConfigService } from '../../config/iconfigservice';
import { BaseService } from '../base.service';
import { FeatureManagerService } from './feature-manager/feature-manager.service';

@Injectable()
export class FeatureToggleService extends BaseService {
    publicApiBaseUrl: string;

    constructor(
        public appService: AppService,
        public configService: IConfigService,
        public httpClient: HttpClient,
        private authService: AuthLegacyService,
        private featureManagerService: FeatureManagerService
    ) {
        super(appService, configService, httpClient);

        const config = this.configService.getConfiguration();
        this.publicApiBaseUrl = config.publicApiUrl;
    }

    getFeatureFlags() {
        setTimeout(() => {
            const isAuthenticated = this.authService.isAuthenticated();

            if (isAuthenticated) {
                const clientId = this.appService.getClientId('');
                const url = `${this.publicApiBaseUrl}global/FeatureFlags`;

                return super.getDataForCache(url, clientId, true);
            }
        }, 1000);

    }

    getFeatureFlag(flagName: string, clientId: string = null): Observable<boolean> {
        const isAuthenticated = this.authService.isAuthenticated();

        if (isAuthenticated || this.featureManagerService.getByName(FeatureToggleType.GlobalUseAuthenticationV2)?.enabled) {
            const clientIdValue = clientId ? clientId : this.appService.getClientId('');

            const url = `${this.publicApiBaseUrl}global/FeatureFlags/${flagName}`;
            return super.getDataForCache(url, clientIdValue, true);
        }

        return of(false);
    }
}
