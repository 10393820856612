import { Audit, AuditUser, AuditCase, AuditExtended, AuditPermissions, AuditPreview, CreateAuditRequest, AuditGroupByTypes, SelectNextCaseRequest, EditAuditRequest } from '@Audit';
import { IConfigService } from '@Config';
import { PermissionTypes } from '@Enums';
import { AccountMinimal, CacheContent } from '@Models';
import { BaseService } from '@Services';
import { TimeUtils } from '@Utils';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from 'app';
import { Observable, map, of, shareReplay, take, tap } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AuditService extends BaseService {

    private readonly auditCacheTimeoutMins = 15;
    private readonly auditCacheTimeout = TimeUtils.minutesToMilliseconds(this.auditCacheTimeoutMins);
    auditCacheTimeoutId?: any; // string | number | NodeJS.Timeout


    // Audits for Client: <client.id, Audit.id[]>.  The actual audit object will be stored in the `auditCache`.
    // This cache is used to know when we've loaded all audits for a client.
    clientAuditsCache = new Map<string, CacheContent<string[]>>();
    // Flattend list of Audits: <Audit.Id, Audit>.
    // This cache is used to cache single audits so we can load single audits without having to load all client audits.
    // (thought, there might not be a lot of use case for only loading a single audit.)
    auditsCache = new Map<string, CacheContent<AuditExtended>>();
    private readonly cacheDuration = TimeUtils.minutesToMilliseconds(10);

    constructor(
        public configService: IConfigService,
        public httpClient: HttpClient,
        public appService: AppService
    ) {
        super(appService, configService, httpClient);
    }

    //#region Helpers: Timeouts

    /**
     * Clear the cache if it hasn't been access for the given timeout. This is to try and limit browser memory usage.
     * @returns 
     */
    setAuditCacheTimer(): any { // string | number | NodeJS.Timeout 
        return setTimeout(() => {
            console.info(`Audit Cache has Expired (${this.auditCacheTimeoutMins} mins) and has been cleared.`);
            this.clientAuditsCache.clear();
            this.auditsCache.clear();
        }, this.auditCacheTimeout);
    }

    resetAuditExpiration(): void {
        // Clear the previous timer and set a new one
        clearTimeout(this.auditCacheTimeoutId ?? 0);
        this.auditCacheTimeoutId = this.setAuditCacheTimer();
    }

    //#endregion
    //#region Helpers

    /**
     * Enrich Audit into AuditExtended.
     * @param audit 
     * @returns 
     */
    enrichAudit(audit: Audit): AuditExtended {
        const audit_enriched = {
            ...audit,
            startDateFormatted: TimeUtils.formatDay(audit.startDateUtc),
            endDateFormatted: TimeUtils.formatDay(audit.endDateUtc),
        };
        return audit_enriched;
    }

    cacheAudit(audit: AuditExtended): void {
        const expiry = Date.now() + this.cacheDuration;
        this.auditsCache.set(audit.id, { value: audit, expiry });
        this.resetAuditExpiration();
    }

    /**
     * Fetch all audits for a client. This will cache the audits associated to a client and cache the
     * individual audits into the audit cache.
     * @param clientId 
     * @param hideWaiting 
     * @returns 
     */
    fetchAndCacheAuditsForClient(clientId: string, hideWaiting = false): Observable<AuditExtended[]> {
        // [UWPipeline.Public.Api] Audit/AuditController.cs -> GetByClient()
        const url = `${this.basePublicApiUrl}client/${clientId}/Audit`;

        const audits$ = super.getData<Audit[]>(url, clientId, hideWaiting).pipe(
            // Order by creation date
            map(audits => {
                audits.sort((a, b) => new Date(a.creationDate).getTime() - new Date(b.creationDate).getTime());
                return audits;
            }),
            // Enrich with time formats
            map((audits): AuditExtended[] => audits.map((audit): AuditExtended => ({
                ...audit,
                startDateFormatted: TimeUtils.formatDay(audit.startDateUtc),
                endDateFormatted: TimeUtils.formatDay(audit.endDateUtc),
            }))),
            // Cache Results
            tap(auditsExtended => {
                const expiry = Date.now() + this.cacheDuration;

                // Add the individual audits to the cache
                const auditIds = new Set<string>();
                auditsExtended.forEach(audit => {
                    this.auditsCache.set(audit.id, { value: audit, expiry });
                    auditIds.add(audit.id);
                });

                // Save the audit ids as the client grouping
                this.clientAuditsCache.set(clientId, { value: Array.from(auditIds), expiry });

                this.resetAuditExpiration();
            }),
            shareReplay(1) // This ensures that multiple subscribers to this observable get the cached response
        );

        return audits$;
    }

    createDisplayCaseAssignee(auditAssignee: AuditUser): string {
        if (auditAssignee) {
            return `${auditAssignee.firstName} ${auditAssignee.lastName}`.trim();
        }

        return '';
    }

    enrichAuditCase(auditCase: AuditCase): AuditCase {
        if (!auditCase) return null;

        const caseCreatedDate_mdate = TimeUtils.convertToMomentCst(auditCase.caseCreatedDate);
        const caseCreatedDate_date = caseCreatedDate_mdate?.toDate();
        const caseCreatedDayFormatted = TimeUtils.formatDay(caseCreatedDate_mdate);
        const caseCreatedTimeFormatted = TimeUtils.formatTime(caseCreatedDate_mdate);

        const doneDate_mdate = TimeUtils.convertToMomentCst(auditCase.caseCompletedDate);
        const doneDate_date = doneDate_mdate?.toDate();
        const caseCompletedDateDayFormatted = TimeUtils.formatDay(doneDate_mdate);
        const caseCompletedDateTimeFormatted = TimeUtils.formatTime(doneDate_mdate);

        const selectedOn_mdate = TimeUtils.convertToMomentCst(auditCase.selectedOn);
        const selectedOn_date = selectedOn_mdate?.toDate();
        const selectedOnDayFormatted = TimeUtils.formatDay(selectedOn_mdate);
        const selectedOnTimeFormatted = TimeUtils.formatTime(selectedOn_mdate);

        const completedOn_mdate = TimeUtils.convertToMomentCst(auditCase.completedOn);
        const completedOn_date = completedOn_mdate?.toDate();
        const completedOnDayFormatted = TimeUtils.formatDay(completedOn_mdate);
        const completedOnTimeFormatted = TimeUtils.formatTime(completedOn_mdate);

        const enrichedAuditCase: AuditCase = {
            ...auditCase,

            caseCreatedDate_date,
            caseCreatedDayFormatted,
            caseCreatedTimeFormatted,

            caseCompletedDate_date: doneDate_date,
            caseCompletedDateDayFormatted,
            caseCompletedDateTimeFormatted,

            selectedOn_date,
            selectedOnDayFormatted,
            selectedOnTimeFormatted,

            completedOn_date,
            completedOnDayFormatted,
            completedOnTimeFormatted,
        };

        return enrichedAuditCase;
    }

    enrichAuditCases(auditCases: AuditCase[]): AuditCase[] {
        const enrichedAuditCases = auditCases.map((auditCase): AuditCase => this.enrichAuditCase(auditCase));
        return enrichedAuditCases;
    }


    //#endregion
    //#region Permissions

    canViewAuditRefresh() {
        const canViewAuditRefresh = this.appService.checkACL(AuditPermissions.AuditRefresh, PermissionTypes.View);
        return canViewAuditRefresh;
    }

    canViewAuditEdit() {
        const canViewAuditEdit = this.appService.checkACL(AuditPermissions.AuditEdit, PermissionTypes.View);
        return canViewAuditEdit;
    }

    //#endregion

    createAudit(request: CreateAuditRequest, clientId: string, hideWaiting = false): Observable<AuditExtended> {
        // [UWPipeline.Public.Api] Audit/AuditController.cs -> CreateAudit()
        const url = `${this.basePublicApiUrl}client/${clientId}/Audit`;

        const result$ = super.postData<Audit>(url, request, clientId, hideWaiting).pipe(
            // Enrich with time formats
            map((audit): AuditExtended => {
                return {
                    ...audit,
                    startDateFormatted: TimeUtils.formatDay(audit.startDateUtc),
                    endDateFormatted: TimeUtils.formatDay(audit.endDateUtc),
                };
            }),
        );
        return result$;
    }

    editAudit(request: EditAuditRequest, clientId: string, hideWaiting = false): Observable<AuditExtended> {
        const url = `${this.basePublicApiUrl}client/${clientId}/Audit/${request.id}`;

        const result$ = super.postData<Audit>(url, request, clientId, hideWaiting).pipe(
            // Enrich with time formats
            map((audit): AuditExtended => {
                return {
                    ...audit,
                    startDateFormatted: TimeUtils.formatDay(audit.startDateUtc),
                    endDateFormatted: TimeUtils.formatDay(audit.endDateUtc),
                };
            }),
        );

        return result$;
    }

    getAudit(clientId: string, auditId: string, hideWaiting = true, forceRefresh = false): Observable<Audit> {
        const now = Date.now();
        const cached = this.auditsCache.get(auditId);

        if (!forceRefresh && cached && cached.expiry > now) {
            this.resetAuditExpiration();
            return of(cached.value);
        }

        const url = `${this.basePublicApiUrl}client/${clientId}/Audit/${auditId}`;
        return super.getData<Audit>(url, clientId, hideWaiting).pipe(
            map((audit): AuditExtended => this.enrichAudit(audit)),
            tap(audit => this.cacheAudit(audit)),
            shareReplay(1) // This ensures that multiple subscribers to this observable get the cached response
        );
    }

    getAudits(clientId: string, hideWaiting = true, forceRefresh = false): Observable<AuditExtended[]> {
        const now = Date.now();
        const cached = this.clientAuditsCache.get(clientId);

        if (!forceRefresh && cached && cached.expiry > now) {
            this.resetAuditExpiration();
            const audits = cached.value.map(auditId => this.auditsCache.get(auditId).value);
            return of(audits);
        }

        return this.fetchAndCacheAuditsForClient(clientId, hideWaiting);
    }

    getAuditCases(clientId: string, auditId: string, hideWaiting = false): Observable<AuditCase[]> {
        // [UWPipeline.Public.Api] Audit/AuditController.cs -> GetAuditCases
        const url = `${this.basePublicApiUrl}client/${clientId}/Audit/${auditId}/cases`;

        const result$ = super.getData<AuditCase[]>(url, clientId, hideWaiting).pipe(
            map((auditCases): AuditCase[] => this.enrichAuditCases(auditCases)),
        );
        return result$;
    }

    getAuditPreview(request: CreateAuditRequest, clientId: string, hideWaiting = false): Observable<AuditPreview> {
        // [UWPipeline.Public.Api] Audit/AuditController.cs -> GetAuditPreview()
        const url = `${this.basePublicApiUrl}client/${clientId}/Audit/preview`;

        const result$ = super.postData<AuditPreview>(url, request, clientId, hideWaiting).pipe(
            take(1),
            map((auditPreview): AuditPreview => ({
                ...auditPreview,
                auditCases: this.enrichAuditCases(auditPreview.auditCases),
            })),
        );
        return result$;
    }

    getAuditEditPreview(request: EditAuditRequest, clientId: string, hideWaiting = false): Observable<AuditPreview> {
        // [UWPipeline.Public.Api] Audit/AuditController.cs -> GetAuditPreview()
        const url = `${this.basePublicApiUrl}client/${clientId}/Audit/${request.id}/preview`;

        const result$ = super.postData<AuditPreview>(url, request, clientId, hideWaiting).pipe(
            take(1),
            map((auditPreview): AuditPreview => ({
                ...auditPreview,
                auditCases: this.enrichAuditCases(auditPreview.auditCases),
            })),
        );
        return result$;

    }

    selectNextAuditCase(clientId: string, auditId: string, hideWaiting = false): Observable<AuditCase> {
        // [UWPipeline.Public.Api] Audit/AuditController.cs -> SelectNextAuditCase()
        const url = `${this.basePublicApiUrl}client/${clientId}/Audit/${auditId}/select-next-case`;

        const result$ = super.getData<AuditCase>(url, clientId, hideWaiting).pipe(
            map((auditCase): AuditCase => this.enrichAuditCase(auditCase)),
        );
        return result$;
    }

    selectNextAuditCaseForGroup(clientId: string, auditId: string, assigneeId: string, groupByType: AuditGroupByTypes, hideWaiting = false): Observable<AuditCase> {
        // [UWPipeline.Public.Api] Audit/AuditController.cs -> SelectNextAuditCase()
        const url = `${this.basePublicApiUrl}client/${clientId}/Audit/${auditId}/select-next-case`;
        const body: SelectNextCaseRequest = {
            userIdToGroupBy: assigneeId,
            groupByType
        };

        const result$ = super.postData<AuditCase>(url, body, clientId, hideWaiting).pipe(
            map((auditCase): AuditCase => this.enrichAuditCase(auditCase)),
        );
        return result$;
    }

    completeAuditCase(clientId: string, auditId: string, caseId: string, hideWaiting = false): Observable<AuditCase> {
        // [UWPipeline.Public.Api] Audit/AuditController.cs -> CompleteCaseAudit()
        const url = `${this.basePublicApiUrl}client/${clientId}/Audit/${auditId}/caseId/${caseId}/complete`;

        const result$ = super.patchData<AuditCase>(url, {}, clientId, hideWaiting).pipe(
            map((auditCase): AuditCase => this.enrichAuditCase(auditCase)),
        );
        return result$;
    }

    /**
     * Ask the API to refresh cases for the provided AuditId.
     * 
     * @param clientId 
     * @param auditId 
     * @param hideWaiting 
     * @returns 
     * @remarks
     * This will return the Audit object with the new total cases/refresh data but does not return the list of cases.
     * Returning all the cases is a heavy operation and it's not certain that all callers want this data. Instead, the call
     * is expected to reload cases when this call finishes.
     */
    refreshCases(clientId: string, auditId: string, hideWaiting = false): Observable<AuditExtended> {
        // [UWPipeline.Public.Api] Audit/AuditController.cs -> RefreshCases
        const url = `${this.basePublicApiUrl}client/${clientId}/Audit/${auditId}/refresh-cases`;
        const data: object = null;

        return super.postData<Audit>(url, data, clientId, hideWaiting).pipe(
            map((audit): AuditExtended => this.enrichAudit(audit)),
            tap(audit => this.cacheAudit(audit)),
            shareReplay(1) // This ensures that multiple subscribers to this observable get the cached response
        );
    }

    getActiveAccountsFromLastAuditEdit(clientId: string, auditId: string, hideWaiting = false): Observable<AccountMinimal[]> {
        const url = `${this.basePublicApiUrl}client/${clientId}/Audit/${auditId}/active-since-last-edit`;

        return super.getData<AccountMinimal[]>(url, clientId, hideWaiting);
    }
}
