<div style="overflow-y: hidden !important">
  <form [formGroup]="addDocsFormGroup" style="min-height: 200px" id="add-pages-form">
    <mat-tab-group mat-align-tabs="start" class="flex-fill">
      <mat-tab label="Documents" attr.data-test-attribute="documents-tab">
        <div class="flex-fill overflow-y-auto custom-scrollbar">
          <div class="list-container">
            <mat-checkbox *ngFor="let document of documents" [selectable]="true" class="document-add-checkbox"
              style="display: flex; flex-direction: row;" [checked]="document.checked"
              (change)="toggleItem(document, selectedDocuments)" id="{{document.name}}">
              {{document.name}}
            </mat-checkbox>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Tags" attr.data-test-attribute="tags-tab">
        <div class="flex-fill overflow-y-auto custom-scrollbar">
          <div class="list-container">
              <mat-chip-listbox #tagChipList aria-label="Tag selection" multiple class="modal-chip-list"
                data-test-attribute="tag-chiplist">
                <mat-chip-option *ngFor="let tag of tags" selectable="true"
                  [selected]="tag.checked" (click)="toggleItem(tag, selectedTags)"
                  id="{{tag.name}}">
                  {{tag.name}}
                </mat-chip-option>
                <input id="matChipInput" [matChipInputFor]="tagChipList"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
                  (matChipInputTokenEnd)="addTag($event)">
              </mat-chip-listbox>
            <div class="w-100" style="font-size: 12px">
              Type to add a new tag
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </form>
  <div class="flex flex-align-center" style="justify-content: space-between; padding-top: 10px">
    <div id="actionButtons" *ngIf="selectedItems.length > 0" class="flex" [@flyRightToLeft]>
      <div id="selectedChip" style="overflow: visible; margin-bottom: auto; margin-top: auto">
        <mat-chip-listbox>
          <mat-chip-option>{{selectedItems.length}} documents selected</mat-chip-option>
        </mat-chip-listbox>
      </div>
    </div>
  </div>
</div>
<mat-dialog-actions align="end">  
    <div class="float-right">
      <button id="document-wizard-document-add-closeButton" mat-button id="document-wizard-document-add-cancelButton"
        color="basic" class="mr-1 material-default" mat-dialog-close>
        CANCEL
      </button>
      <button id="document-wizard-document-add-backButton" mat-button color="primary" class="mr-1 material-default"
        matStepperPrevious>
        BACK
      </button>
      <button id="document-wizard-document-add-next" mat-button color="primary" class="mr-1 material-default"
        matStepperNext>
        {{nextButtonText}}
      </button>
    </div>
</mat-dialog-actions>