import {Injectable} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {PortalOneComponent} from 'app/components/portal-one/portal-one.component';
import {InterviewService} from 'app/interview.service';
import {NotificationService} from 'app/services';
import {SbliOneIncPaymentService} from "app/services/case/sbli-one-inc-payment.service";
import {ScriptLoaderService} from "app/services/script.service";

@Injectable()
export class SbliOneIncPaymentIntegrationHandler {

    constructor(
    public notificationService: NotificationService,
    public interviewService: InterviewService,
    public dialog: MatDialog,
    public portalOneService: SbliOneIncPaymentService,
    public scriptService: ScriptLoaderService
    ) {
    }

    handleResponse(clientId: string, accountId: string, sbliOneIncPaymentResponse, integrationData) {
    // integrationData.sessID
        if (sbliOneIncPaymentResponse?.successful) {
            const portalOneSess = sbliOneIncPaymentResponse.sessionId;
            if (portalOneSess != null) {
                this.scriptService.loadDynamicScript('PortalOne', {src: sbliOneIncPaymentResponse.portalOneUrl, async: true, defer:true})
                    .subscribe(() => {
                        this.showPortalOneDialog(clientId, accountId, integrationData.caseDetailId, integrationData.caseIntegrationQueueId, sbliOneIncPaymentResponse);
                    });
            }
        }
        else if (sbliOneIncPaymentResponse?.errors) {
            var errorsPayload = {
                clientId: clientId,
                caseId: integrationData.caseDetailId,
                accountId: accountId,
                caseIntegrationQueueId: integrationData.caseIntegrationQueueId,
                errors: sbliOneIncPaymentResponse.errors,
                tokenId:''
            };
            this.portalOneService.saveMapkeyData(errorsPayload);
        }

    }

    dialogRef: MatDialogRef<PortalOneComponent>;
    showPortalOneDialog(clientId: string, accountId: string, caseId: string, caseIntegrationQueueId: string, sbliOneIncPaymentResponse) {

        this.dialogRef = this.dialog.open(PortalOneComponent, {
            height: '-100px',
            width: '-100px',
            disableClose: true,
            data: {
                clientId: clientId,
                caseId: caseId,
                caseIntegrationQueueId: caseIntegrationQueueId,
                accountId: accountId,
                sbliResp: sbliOneIncPaymentResponse
            }
        });

        if(this.dialogRef) {
            this.dialogRef.addPanelClass('hide-dialog');

            this.dialogRef.afterClosed().subscribe(
                (value) => {
                    if (value !== undefined) {
                        this.notificationService.showNotification({severity: value.state, message: value.msg});
                    }
                });
        }
    }
}
