import { AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TableColumnDefinition } from 'app/shared/components/mrs-table/models/table-models';
import * as $ from 'jquery';
import { AppService } from '../app.service';
import { CallManagerService } from '../services';
import moment = require('moment');

@Component({
    selector: 'app-call-manager',
    host: { 'class': 'content' },
    templateUrl: './call-manager.component.html',
    styleUrls: ['./call-manager.component.scss']
})

export class CallManagerComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('importCallDialog') importCallDialog: TemplateRef<any>;

    public clients: any = [];
    noDataPlaceholder = false;
    calls: any = [];
    storedRecords: any = [];
    callsForm: UntypedFormGroup;
    callEditForm: UntypedFormGroup;
    importForm: UntypedFormGroup;
    menuObservable: any;
    scrollHeight: any;
    scrollWidth: any;
    tableConfig: any = [];
    minDateValue: Date;
    searchValue: any;
    caseIds: any = [];
    showEditCall: boolean;
    selectedCall: any;
    filteredCases: any[];
    platFormData: any;
    timeZone: string;
    canImport: boolean;
    showImport: boolean;
    importDialogRef: MatDialogRef<CallManagerComponent>;

    displayedColumns: TableColumnDefinition[];
    reportData: any = [];
    searchableFields: any = [];

    constructor(
        private appService: AppService,
        private callManagerService: CallManagerService,
        private _fb: UntypedFormBuilder,
        public dialog: MatDialog
    ) {
        this.timeZone = this.appService.getTimeZone();
        this.callsForm = this._fb.group({
            startDate: ['', Validators.required],
            endDate: ['', Validators.required],
            clientId: ['', Validators.required]
        });

        this.callEditForm = this._fb.group({
            platform: '',
            caseNumbers: [[]],
            dismissReason: ''
        });

        this.platFormData = appService.getConfigData('platForm');
    }

    ngOnInit() {
        $('#sidenav').removeClass('hidenav');
        this.getClients();
        this.menuObservable = this.appService.sideMenuObservable?.subscribe(
            data => {
                this.scrollWidth = ($(window).width() - (data ? 30 : 270));
            }
        );

        this.tableConfig = this.getTableConfig();
        this.canImport = this.appService.checkACL('Import Call', 'W');
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.scrollWidth = ($(window).width() - 270);
            this.scrollHeight = ($(window).height() - 340);
        }, 10);
    }

    getClientId(): string {
        return this.callsForm.get('clientId').value;
    }

    getClients() {
        this.appService.getData('Clients').subscribe(
            data => {
                this.clients = data.data;
            }
        );
    }

    checkACL(permissionType, redirect?) {
        return this.appService.checkACL('CallManager', permissionType, redirect);
    }

    ngOnDestroy() {
        this.menuObservable.unsubscribe();
    }

    getStartRange() {
        this.minDateValue = this.callsForm.value.startDate;
        // this.callsForm.patchValue({
        //     endDate: this.callsForm.value.startDate
        // })
    }

    filterCases(event) {
        const caseId = event.query;
        this.filteredCases = this.filterCaseIds(caseId, this.caseIds);
    }

    filterCaseIds(inputValue, caseIds: any[]): any[] {
        const filtered: any[] = [];
        for (let i = 0; i < caseIds.length; i++) {
            // let caseId = caseIds[i];
            if (caseIds[i].indexOf(inputValue) === 0) {
                filtered.push(caseIds[i]);
            }
        }
        return filtered;
    }

    getParams() {
        const params = JSON.parse(JSON.stringify(this.callsForm.value));
        params.startDate = this.callsForm.value.startDate;
        params.endDate = this.callsForm.value.endDate;
        return params;
    }

    refreshCall(record) {
        this.appService.postData(`CallLog/RefreshCall`, record, false, false, this.getClientId())
            .subscribe(
                data => {
                    if (data.status === 'success') {
                        this.appService.showMsg('success', data.message);
                    }
                }
            );

    }

    getCalls() {
        if (this.callsForm.valid) {
            const params = this.getParams();
            this.appService.postData(`CallLog/ManageCallLogs`, params, false, false, this.getClientId())
                .subscribe(
                    data => {
                        this.calls = data.data;
                        if (this.calls.length > 0) {
                            this.noDataPlaceholder = false;
                            this.getCaseIds();

                            this.getTableConfig();

                            this.reportData = data.data.map((data) => ({
                                ...data,
                                startTime: moment(data.startTime).utc().local().format('MM/DD/yyyy HH:mm:ss'),
                                endTime: data.endTime ? moment(data.endTime).utc().local().format('MM/DD/yyyy HH:mm:ss') : null,
                                externalId: data.externalId != '' ? data.externalId : 'Not Specified'
                            }));


                        } else {
                            this.noDataPlaceholder = true;
                        }
                    }
                );
        }
    }

    getCaseIds() {
        const params = this.getParams();
        this.appService.postData(`CallLog/GetOrdersBasedOnRange`, params, false, false, this.getClientId())
            .subscribe(
                data => {
                    this.caseIds = [];
                    data.data.forEach(id => {
                        this.caseIds.push({ 'id': id });
                    });
                }
            );
    }

    filterData() {
        if (this.storedRecords.length) {
            const records = this.storedRecords;
            if (records.length > 0 && this.searchValue.length > 0) {
                const filteredRecords = records.filter(o =>
                    Object.keys(o).some((k) => {
                        if (o[k]) {
                            return o[k].toLowerCase().includes(this.searchValue.toLowerCase());
                        }
                    }
                    ));
                this.calls = filteredRecords;
                if (this.calls.length > 0) {
                    this.noDataPlaceholder = false;
                } else {
                    this.noDataPlaceholder = true;
                }
            }
        }
    }

    editRecord(record) {
        this.showEditCall = true;
        this.selectedCall = record;
        this.selectedCall.externalId = this.selectedCall.externalId.replace('Not Specified', '');
        this.callEditForm.patchValue({
            caseNumbers: this.selectedCall.externalId !== '' ? this.selectedCall.externalId.split(',') : [],
            dismissReason: this.selectedCall.dismissReason,
            platform: this.platFormData[0].name
        });
    }

    saveCall() {
        this.appService.postData(`CallLog/UpdateCallDetailInfo/${this.selectedCall.id}`, this.callEditForm.value, false, false, this.getClientId())
            .subscribe(
                data => {
                    if (data.status === 'success') {
                        this.getCalls();
                        this.showEditCall = false;
                    }
                }
            );
    }

    enableImport(client) {
        if (this.canImport && client !== '') {
            this.showImport = true;
        } else {
            this.showImport = false;
        }

    }

    showImportDialog() {
        this.importForm = this._fb.group({
            contactId: ['', Validators.required]
        });

        this.importDialogRef = this.dialog.open(this.importCallDialog,
            {
                width: '800px',
                maxHeight: '90%',
                minHeight: '250px',
            });
    }

    importContact() {
        if (this.importForm.valid) {
            this.callManagerService.importContact(this.getClientId(), this.importForm.controls['contactId'].value).subscribe(result => {
                if (result.importSuccess) {
                    this.appService.showMsg('success', result.importStatusMessage);
                    this.importDialogRef.close();
                } else {
                    this.appService.showMsg('error', result.importStatusMessage);
                }
            });
        }
    }

    cancelImport() {
        this.importDialogRef.close();
    }

    getTableConfig() {
        this.searchableFields = ['callId', 'startTime', 'endTime', 'dnis', 'ani', 'skill', 'agentName', 'recordFileName', 'transferState', 'dismissReason'];

        this.displayedColumns = [

            {
                def: 'callId',
                header: 'Contact ID',
                sortFields: ['callId'],
                columnStyles:
                {
                    'width': '120px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'callId',
                    }
                ]
            },
            {
                def: 'startTime',
                header: 'Started On',
                sortFields: ['startTime'],
                columnStyles:
                {
                    'width': '150px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'startTime',
                    }
                ]
            },
            {
                def: 'endTime',
                header: 'Ended On',
                sortFields: ['endTime'],
                columnStyles:
                {
                    'width': '150px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'endTime',
                    }
                ]
            },
            {
                def: 'dnis',
                header: 'DNIS',
                sortFields: ['dnis'],
                columnStyles:
                {
                    'width': '100px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'dnis',
                    }
                ]
            },
            {
                def: 'ani',
                header: 'ANI',
                sortFields: ['ani'],
                columnStyles:
                {
                    'width': '100px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'ani',
                    }
                ]
            },
            {
                def: 'skill',
                header: 'Skill',
                sortFields: ['skill'],
                columnStyles:
                {
                    'width': '100px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'skill',
                    }
                ]
            },
            {
                def: 'agentName',
                header: 'Agent',
                sortFields: ['agentName'],
                columnStyles:
                {
                    'width': '140px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'agentName',
                    }
                ]
            },
            {
                def: 'recordFileName',
                header: 'Location',
                sortFields: ['recordFileName'],
                columnStyles:
                {
                    'width': '100px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'recordFileName',
                    }
                ]
            },
            {
                def: 'transferState',
                header: 'Transfer State',
                sortFields: ['transferState'],
                columnStyles:
                {
                    'width': '100px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'transferState',
                    }
                ]
            },
            {
                def: 'externalId',
                header: 'Order',
                sortFields: ['externalId'],
                columnStyles:
                {
                    'width': '150px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'externalId',
                    }
                ]
            },
            {
                def: 'dismissReason',
                header: 'Dismiss Reason',
                sortFields: ['dismissReason'],
                columnStyles:
                {
                    'width': '140px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'dismissReason',
                    }
                ]
            },
            {
                def: 'actions',
                header: 'Actions',
                columnStyles:
                {
                    'width': '50px'
                },
                isAction: true,
                actions: [{
                    name: 'Refresh',
                    actionType: 'refresh',
                    permission: this.checkACL('W'),
                    conditions: []
                },
                {
                    name: 'Edit',
                    actionType: 'edit',
                    permission: this.checkACL('W'),
                    conditions: []
                }]
            }
        ];
    }

    tableAction(event) {
        if (event.action?.actionType === 'refresh') {
            this.refreshCall(event.record);
        }

        if (event.action?.actionType === 'edit') {
            this.editRecord(event.record);
        }
    }
}
