import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { PipesModule } from "app/pipes/pipes.module";
import { SharedModule } from "app/shared/shared.module";
import { PrimorisService } from "./primoris.service";

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        SharedModule,
        PipesModule
    ],
    exports: [],
    providers: []
})
export class PrimorisModule {
    static forRoot(): ModuleWithProviders<PrimorisModule> {
        return {
            ngModule: PrimorisModule,
            providers: [PrimorisService],
        };
    }
}
