<h1 mat-dialog-title>Notes</h1>
<div mat-dialog-content>
  <div class="content">
    <div class="flex-fill d-flex flex-column overflow-y-auto" *ngIf="displayNotes">
      <note *ngFor="let note of notes; let i = index" [note]="note" [noteTypes]="noteTypes"
        [noteRelations]="noteRelations" [noteFollowupInDays]="noteFollowupInDays"></note>
    </div>
  </div>
</div>
<mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>Close</button>
      </mat-dialog-actions>
