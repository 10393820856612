import { RulesDTO } from "@DTOs";
import { FeatureToggle } from "@Enums";
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatTabGroup } from "@angular/material/tabs";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { AppService } from 'app/app.service';
import { ConfirmationDialogService, FeatureManagerService, NotificationService, NotificationSeverity, ReportDownloadService, RoutingService } from 'app/services';
import _ = require('lodash');

@Component({
    selector: 'constant',
    templateUrl: './constant.component.html',
    styleUrls: ['./constant.component.scss']
})
export class ConstantComponent implements OnInit {
    readonly ValueMaxLength = 1000;
    readonly DisplayMaxLength = 200;

    private _constant: any;
    isEditView: boolean;
    constantForm: UntypedFormGroup;
    listId: any = 1;
    title: any;
    fileData: any;
    showDetails = true;
    showList = true;

    constantTypes: any = [
        { id: '00000000-0000-0000-0000-000000000001', name: 'Value', isList: false },
        { id: '00000000-0000-0000-0000-000000000002', name: 'Static List', isList: true }
    ];

    @Output() cancelEdit = new EventEmitter<any>();
    @Output() undoCancel = new EventEmitter<any>();
    @Output() saveChanges = new EventEmitter<any>();
    @Output() editConstant = new EventEmitter<any>();
    @Output() listEditChange = new EventEmitter<any>();

    @ViewChild(MatTabGroup) matTabGroup: MatTabGroup;
    private formBeforeChanges: any;
    private isSaving = false;
    private filterableStaticMapkeysEnabled = false;

    @Input()
    set rules(value: RulesDTO[]) {
        this._rules = value;
        if (this.filterableStaticMapkeysEnabled)
            this.addRuleNameToForm();
    }

    get rules(): RulesDTO[] {
        return this._rules;
    }

    _rules: RulesDTO[];
    @Input() parentPathDisplay: string;

    @Input()
    set constant(value: any) {
        if (this._constant && value && this._constant.id === value.id) return;//Do nothing because we're already editing this constant

        //If the form has changed then we need to prompt the user telling them that they will lose their changes
        if (this.constantForm?.dirty && this._constant && !this.isSaving) {
            this.confirmationService.open({
                message: 'You will lose your changes.',
                title: "Cancel Changes?",
                onOk: () => {
                    this.initializeNewMapkey(value);
                },
                onCancel: () => {
                    this.undoCancel.emit(this._constant);
                },
                showCancel: true,
                cancelLabel: "Continue Editing",
                okLabel: "Yes, Cancel"
            });
        } else {
            this.initializeNewMapkey(value);
            this.isSaving = false;
        }
    }

    get constant(): any {
        return this._constant;
    }

    initializeNewMapkey(value: any) {
        this._constant = value;
        this.title = value.title;
        this.constantForm = this._fb.group({
            id: value.id,
            name: [value.name, Validators.required],
            mapKeyEntityId: value.mapKeyEntityId,
            accountId: value.accountId,
            clientId: value.clientId,
            isConstant: value.isConstant,
            isList: [value.isList, Validators.required],
            constantMapKeyValues: this._fb.array([]),
            constantMapkeyVariants: this._fb.array([])
        });

        if (value.id) {
            const control = <UntypedFormArray>this.constantForm.get('constantMapKeyValues');
            if (value.isList) {
                value.constantMapKeyValues.sort((a, b) => a.order - b.order).forEach(item => {
                    control.push(this._fb.group({
                        id: [item.id],
                        value: [item.value, Validators.compose([Validators.required, Validators.maxLength(this.ValueMaxLength), Validators.pattern('[^,]+')])],
                        displayName: [item.displayName, Validators.compose([Validators.required, Validators.maxLength(this.DisplayMaxLength)])],
                        order: [item.order]
                    }));
                });
                this.getConstantOrder();

                if (this.filterableStaticMapkeysEnabled) {
                    if (this.filterableStaticMapkeysEnabled && value.constantMapkeyVariants) {
                        const variantsControl = this.constantForm.get('constantMapkeyVariants') as UntypedFormArray;
                        value.constantMapkeyVariants.sort((a, b) => a.sortOrder - b.sortOrder).forEach((item, index) => {
                            const newControl = this._fb.group({
                                id: [item.id],
                                name: [item.name, [Validators.required, ValidateUniqueName]],
                                ruleId: [item.ruleId, [Validators.required, ValidateUniqueRuleId]],
                                constantMapkeyVariantValues: this._fb.array([], [ValidateVariantListValueSelection]),
                                sortOrder: [index + 1]
                            });

                            //Get the Mapkey Value and Display name for the variants
                            const variantValueControl = newControl.get('constantMapkeyVariantValues') as UntypedFormArray;
                            item.constantMapkeyVariantValues.forEach(variantValue => {
                                const mapkeyValue = value.constantMapKeyValues.find(x => x.id === variantValue.constantMapkeyValueId);
                                if (mapkeyValue) {
                                    variantValueControl.push(this._fb.group({
                                        constantMapkeyValueId: [variantValue.constantMapkeyValueId],
                                        value: [mapkeyValue.value],
                                        displayName: [mapkeyValue.displayName],
                                        order: [this.constantForm.get("constantMapKeyValues").value.find(x => x.id === variantValue.constantMapkeyValueId)?.order]
                                    }));
                                }
                            });
                            //Sort the constantMapkeyVariantValues by the order of their order
                            variantValueControl.controls.sort((a, b) => a.value.order - b.value.order);
                            variantsControl.push(newControl);
                        });
                    }

                    if (this.rules) {
                        this.addRuleNameToForm();
                    }
                }
            } else {
                value.constantMapKeyValues.sort((a, b) => a.order - b.order).forEach(item => {
                    control.push(this._fb.group({
                        id: [item.id],
                        value: [item.value, Validators.compose([Validators.required, Validators.maxLength(this.ValueMaxLength), Validators.pattern('[^,]+')])],
                        displayName: [''],
                        order: [item.order]
                    }));
                });
            }

            this.isEditView = this.constant.isEdit;

        } else {
            const control = <UntypedFormArray>this.constantForm.get('constantMapKeyValues');
            control.push(this.constantFields());
            this.isEditView = true;
        }

        this.formBeforeChanges = this.constantForm.value;
    }


    constructor(private _fb: UntypedFormBuilder, private appService: AppService,
        private reportDownloadService: ReportDownloadService,
        private httpClient: HttpClient,
        private notificationService: NotificationService,
        private confirmationService: ConfirmationDialogService,
        private featureManagerService: FeatureManagerService,
        private routingService: RoutingService) {
        this.filterableStaticMapkeysEnabled = this.featureManagerService.getByName(FeatureToggle.FilterableStaticMapkeys).enabled;
        this.isEditView = true;
    }

    constantFields(): UntypedFormGroup {
        const length = this.constantForm.get('constantMapKeyValues')['controls'].length;
        return this._fb.group({
            id: [length],
            value: ['', Validators.compose([Validators.required, Validators.maxLength(this.ValueMaxLength), Validators.pattern('[^,]+')])],
            displayName: this._constant.isList ? ['', Validators.compose([Validators.required, Validators.maxLength(this.DisplayMaxLength)])] : ['']
        });

    }

    addNewInputField(): void {
        const control = <UntypedFormArray>this.constantForm.get('constantMapKeyValues');
        control.push(this.constantFields());
    }

    removeInputField(i: number): void {

        if (this.filterableStaticMapkeysEnabled) {
            const index = i;
            const variantControl = this.constantForm.get('constantMapkeyVariants') as UntypedFormArray;
            variantControl.controls.forEach((variantControlItem: UntypedFormGroup) => {
                const variantValues = variantControlItem.get('constantMapkeyVariantValues') as UntypedFormArray;
                variantValues.controls.forEach((variantValueControl: UntypedFormGroup) => {
                    if (variantValueControl.get('constantMapkeyValueId').value === this.constantForm.controls.constantMapKeyValues['controls'][index].value.id) {
                        variantValues.removeAt(variantValues.controls.indexOf(variantValueControl));
                    }
                    if (variantValues.length === 0) {
                        this.constantForm.setErrors({ 'invalidVariant': true });
                        variantControlItem.setErrors({ 'noValuesSelected': true });
                        variantControlItem.markAsTouched();
                    }
                });
            });
        }

        const control = <UntypedFormArray>this.constantForm.controls.constantMapKeyValues;
        control.removeAt(i);
    }

    ngOnInit() {
        if (!this.constant || (this.constant.id && !this.constant.isEdit)) {
            this.isEditView = false;
        }
    }

    addRuleNameToForm() {
        const variantControl = this.constantForm.get('constantMapkeyVariants') as UntypedFormArray;
        variantControl.controls.forEach((control: UntypedFormGroup) => {
            const ruleName = this.rules.find(x => x.id === control.get('ruleId').value)?.name;
            if (ruleName) {
                if (!control.get('ruleName')) {
                    control.addControl('ruleName', new UntypedFormControl(ruleName));
                } else {
                    control.get('ruleName').setValue(ruleName);
                }
            } else {
                control.get('ruleId').patchValue('');
            }
        });
    }

    editItem() {
        this.isEditView = true;
    }

    exportItem() {
        const baseUrl = this.appService.getAPIBaseURL();
        const url = baseUrl + `MapKeys/${this.constant.id}/ExportConstantList`;

        const httpPost = this.httpClient.get(url, { headers: this.appService.getHeaders(), responseType: 'blob', observe: 'response' });

        httpPost.subscribe({
            next: (response: HttpResponse<Blob>) => {
                this.reportDownloadService.saveToFileSystem(response, 'xls');
            },
            error: (error) => this.appService.showResponseErrorMsg(error)
        });
    }

    selectFile(event) {
        const files = event.target.files;
        if (!files) {
            return;
        } else {
            const extension = files[0].name.match(/\.([^.]+)$/)[1];
            if (extension.toLowerCase() === 'xlsx') {
                const formData: FormData = new FormData();
                for (let i = 0; i < files.length; i++) {
                    formData.append(i.toString(), files[i], files[i].name);
                }
                this.fileData = formData;
                this.importList();
            } else {
                this.appService.showMsg('error', 'Please upload the xlsx document type only', false);
            }
        }
    }

    importList() {
        this.appService.display(true);

        const url = `${this.appService.getAPIBaseURL()}MapKeys/${this.constant.id}/ImportConstantList`;
        this.httpClient.post(url, this.fileData, {
            headers: this.appService.getUploadHttpHeaders()
        }).subscribe({
            next: (response) => {
                let data: any;
                if (response['_body']) {
                    data = JSON.parse(response['_body']);
                } else {
                    data = response;
                }
                if (data.status === 'success') {
                    data.data.title = this.title;
                    this.constant = data.data;
                    this.editConstant.emit(data.data);
                    this.fileData = undefined;
                    this.appService.showMsg('success', data.message);
                    this.appService.display(false);
                    this.initializeNewMapkey(data.data);
                } else {
                    this.appService.showMsg('error', data.message);
                }
            },
            error: (error) => {
                this.appService.showResponseErrorMsg(error);
                this.appService.display(false);
            }
        });
    }

    onClick(event) {
        event.target.value = null;
    }

    constantTypeChange(option) {
        const control = <UntypedFormArray>this.constantForm.get('constantMapKeyValues');
        if (option.value && this._constant.isList) {
            this.listEditChange.emit();
        } else {
            while (control.length > 1) {
                control.removeAt(control.length - 1);
            }
        }
    }

    changeConstantOrder(from, to) {
        const control = <UntypedFormControl[]>this.constantForm.controls.constantMapKeyValues['controls'];
        control.splice(to, 0, control.splice(from, 1)[0]);

        this.constantForm.value.constantMapKeyValues.splice(to, 0, this.constantForm.value.constantMapKeyValues.splice(from, 1)[0]);
        this.getConstantOrder();
    }

    getConstantOrder() {
        const constantsWithOrder = [];
        this.constantForm.value.constantMapKeyValues.forEach((element, index) => {
            constantsWithOrder.push({
                displayName: this.constantForm.value.isList ? element.displayName : '',
                value: element.value,
                order: index,
                id: element.id
            });
        });
        this.constantForm.value.constantMapKeyValues = constantsWithOrder;
    }


    saveConstant() {
        this.isSaving = true;
        this.constantForm.markAllAsTouched();
        this.constantForm.updateValueAndValidity();
        let isEdit = false;
        let url = 'MapKeys';
        if (!this.constantForm.value.isList) {
            this.constantForm.patchValue({
                constantMapKeyValues: [{
                    displayName: ' '
                }]
            });
        }

        //If any of the constantMapkeyVariants has no constantMapkeyVariantValues selected then the form is invalid
        const variantControl = this.constantForm.get('constantMapkeyVariants') as UntypedFormArray;
        variantControl.controls.forEach((control: UntypedFormGroup) => {
            const variantValues = control.get('constantMapkeyVariantValues').value;
            if (variantValues.length === 0) {
                this.constantForm.setErrors({ 'invalidVariant': true });
                control.setErrors({ 'noValuesSelected': true });
                control.markAsTouched();
            }
        });


        if (this.constantForm.valid) {
            this.getConstantOrder();
            if (this.constantForm.get('id').value) {
                isEdit = true;
                url = `MapKeys/${this.constant.id}`;
                this.constantForm.value.constantMapKeyValues.forEach(element => {
                    element['mapKeysId'] = this.constantForm.value.id;
                });
            }

            const constantFormCopy = _.cloneDeep(this.constantForm);
            const constantMapkeyValuesFromCopy = constantFormCopy.get('constantMapKeyValues') as UntypedFormArray;
            const constantMapkeyVariantsFromCopy = constantFormCopy.get('constantMapkeyVariants') as UntypedFormArray;

            if (constantMapkeyVariantsFromCopy.value.length > 0) {
                constantMapkeyVariantsFromCopy.controls.forEach(variantControl => {
                    const variantValues = variantControl.get('constantMapkeyVariantValues') as UntypedFormArray;
                    variantValues.controls.forEach(element => {

                        //If not a Guid do not send up the id
                        if (typeof (element.get('constantMapkeyValueId').value) === "number") {
                            const constValuesFromCopy = constantMapkeyValuesFromCopy.controls.find(x => x.value.id === element.get('constantMapkeyValueId').value);
                            element.get('constantMapkeyValueId').setValue(null);
                            element.get('value').setValue(constValuesFromCopy.value.value);
                        }
                    });
                });
            }

            if (constantMapkeyValuesFromCopy.value.length > 0) {
                constantMapkeyValuesFromCopy.controls.forEach(element => {
                    //If not a Guid do not send up the id
                    if (typeof (element.get('id').value) === "number") {
                        element.get('id').setValue(null);
                    }
                });
            }

            this.appService.postData(url, constantFormCopy.value, isEdit, false)
                .subscribe(
                    data => {
                        if (data.status === 'success') {
                            this.isEditView = false;
                            this.appService.showMsg('success', 'Saved successfully ...');
                            this.constant = data.data;
                            if (isEdit) {
                                this.editConstant.emit(data.data);
                                this.initializeNewMapkey(data.data);
                            } else {
                                this.saveChanges.emit(data.data);
                            }
                        } else {
                            this.appService.showMsg('error', data.message);
                        }
                    }
                );
        } else {
            this.notificationService.showNotification(
                {
                    severity: NotificationSeverity.Error,
                    message: "Correct existing errors"
                });
            this.constantForm.get('constantMapkeyVariants')['controls'].forEach((variantControl, index) => {
                if (variantControl.invalid) {
                    this.matTabGroup.selectedIndex = index + 1;
                    return;
                }
            });
            this.constantForm.get('constantMapKeyValues')['controls'].forEach((constantMapKeyValues) => {
                if (constantMapKeyValues.invalid) {
                    this.matTabGroup.selectedIndex = 0;
                    return;
                }
            });
        }
    }

    checkACL(permissionType, redirect?) {
        return this.appService.checkACL('Accounts', permissionType, redirect);
    }

    cancel() {
        if (this.constantForm.dirty || this.constantForm.invalid) {
            this.confirmationService.open({
                message: 'You will lose your changes.',
                title: "Cancel Changes?",
                onOk: () => {
                    this.initializeNewMapkey(this._constant);
                    this.isEditView = false;
                },
                onCancel: () => {
                    //Do nothing
                },
                showCancel: true,
                cancelLabel: "Continue Editing",
                okLabel: "Yes, Cancel"
            });
        } else {
            this.isEditView = false;
        }
    }

    canDeactivate(routerSnapshot: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): boolean {
        if ((this.constantForm.dirty || this.constantForm.invalid) && this.isEditView) {
            this.confirmationService.open({
                message: 'You will lose your changes.',
                title: "Cancel Changes?",
                onOk: () => {
                    this.initializeNewMapkey(this._constant);   //Reset the form
                    this.routingService.navigateToUrl(nextState.url);
                    return true;
                },
                onCancel: () => {
                    return false;
                },
                showCancel: true,
                cancelLabel: "Continue Editing",
                okLabel: "Yes, Cancel"
            });
            return false;
        } else {
            return true;
        }
    }

    expandAllSections() {
        this.showDetails = true;
        this.showList = true;
    }

    addNewVariant() {
        const control = <UntypedFormArray>this.constantForm.get('constantMapkeyVariants');
        control.push(this.constanVariantFields());
        this.matTabGroup.selectedIndex = control.length;
    }

    private constanVariantFields(): FormGroup {
        return this._fb.group({
            id: [null],
            name: ['', [Validators.required, ValidateUniqueName]],
            ruleId: ['', [Validators.required, ValidateUniqueRuleId]],
            ruleName: [''],
            sortOrder: [this.constantForm.get('constantMapkeyVariants')['controls'].length + 1],
            constantMapkeyVariantValues: this._fb.array([], [ValidateVariantListValueSelection]),
        });
    }

    allVariantValuesChecked(variantValueControl: UntypedFormControl) {
        return !!(variantValueControl?.value && variantValueControl.value.length === this.constantForm.value.constantMapKeyValues.length);
    }

    someVariantValuesChecked(variantValue: UntypedFormControl) {
        return !!(variantValue?.value && variantValue.value.length && variantValue.value.length < this.constantForm.value.constantMapKeyValues.length);
    }

    toggleAllVariantValuesSelection(variantControl: UntypedFormGroup) {
        const variantControlValue = variantControl.value;
        if (variantControlValue.constantMapkeyVariantValues && variantControlValue.constantMapkeyVariantValues.length === this.constantForm.value.constantMapKeyValues.length) {
            const arrayTemp = variantControl.get('constantMapkeyVariantValues') as UntypedFormArray;
            arrayTemp.clear();
            variantControl.setErrors({ 'noValuesSelected': true });
        } else {
            const variantValuesControl = variantControl.get('constantMapkeyVariantValues') as UntypedFormArray;
            this.constantForm.get('constantMapKeyValues')['controls'].forEach(constantMapkeyValueControl => {
                if (!this.variantIsSelected(constantMapkeyValueControl, variantValuesControl)) {
                    variantValuesControl.push(this._fb.group({
                        constantMapkeyValueId: [constantMapkeyValueControl.value.id],
                        value: [constantMapkeyValueControl.value.value],
                        displayName: [constantMapkeyValueControl.value.displayName]
                    }));
                }
            });
        }
        this.markVariantAsDirty(variantControl);
    }

    variantIsSelected(constantMapkeyValueControl: UntypedFormControl, variantValuesControl: UntypedFormArray) {
        const identifier = constantMapkeyValueControl.value.id ?? constantMapkeyValueControl.value.value;
        return variantValuesControl?.value && variantValuesControl.value.some((variantValue) => variantValue.constantMapkeyValueId === identifier || variantValue.value === identifier);
    }

    toggleVariantValueSelection(constantMapkeyValueForm: UntypedFormGroup, constantMapkeyVariantValues: UntypedFormArray) {
        if (constantMapkeyVariantValues.value.some(x => x.constantMapkeyValueId === constantMapkeyValueForm.value.id)) {
            constantMapkeyVariantValues.value.filter(x => x.constantMapkeyValueId === constantMapkeyValueForm.value.id).forEach(element => {
                constantMapkeyVariantValues.removeAt(constantMapkeyVariantValues.value.findIndex(x => x === element));
            });


            //If any of the constantMapkeyVariants has no constantMapkeyVariantValues selected then the form is invalid
            if (constantMapkeyVariantValues.length === 0) {
                this.constantForm.setErrors({ 'invalidVariant': true });
                constantMapkeyVariantValues.parent.setErrors({ 'noValuesSelected': true });
            }
        } else {
            const constantMapkeyValueFormValue = constantMapkeyValueForm.value;
            constantMapkeyVariantValues.push(this._fb.group({
                constantMapkeyValueId: [constantMapkeyValueFormValue.id],
                value: [constantMapkeyValueFormValue.value],
                displayName: [constantMapkeyValueFormValue.displayName]
            }));
        }
        this.markVariantAsDirty(constantMapkeyVariantValues.parent as UntypedFormGroup);
    }
    markVariantAsDirty(variantControl: UntypedFormGroup) {
        const foundPreChangedVariant = _.cloneDeep(this.formBeforeChanges.constantMapkeyVariants.find(x => x.id === variantControl.value.id));
        if (foundPreChangedVariant) {
            foundPreChangedVariant.constantMapkeyVariantValues = foundPreChangedVariant.constantMapkeyVariantValues.map(x => x.constantMapkeyValueId);
            foundPreChangedVariant.constantMapkeyVariantValues.sort(function (a, b) {
                return (a.toString() < b.toString()) ? -1 : (a.toString() > b.toString()) ? 1 : 0;
            });

            if (foundPreChangedVariant.ruleName) {
                delete foundPreChangedVariant.ruleName;
            }
        }
        const currentChangedVariant = _.cloneDeep(variantControl.value);
        currentChangedVariant.constantMapkeyVariantValues = currentChangedVariant.constantMapkeyVariantValues.map(x => x.constantMapkeyValueId);
        currentChangedVariant.constantMapkeyVariantValues.sort(function (a, b) {
            return (a.toString() < b.toString()) ? -1 : (a.toString() > b.toString()) ? 1 : 0;
        });

        if (currentChangedVariant.ruleName) {
            delete currentChangedVariant.ruleName;
        }

        if (JSON.stringify(currentChangedVariant) == JSON.stringify(foundPreChangedVariant)) {
            variantControl.markAsPristine();

        } else {
            variantControl.markAsDirty();
        }
    }

    variantIsInvalid(variantControl: UntypedFormControl) {
        return variantControl.invalid;
    }

    variantIsDirty(variantControl: UntypedFormControl) {
        return variantControl.dirty;
    }
    ValidateValueDuplicate(control) {
        ValidateValueDuplicate(control.get('value'));
    }
    deleteVariant(index) {
        (this.constantForm.get('constantMapkeyVariants') as UntypedFormArray).removeAt(index);
    }
    variantListValueSelectionHasErrors(constantMapkeyVariant) {
        const variantValues = constantMapkeyVariant.get('constantMapkeyVariantValues').value;
        if (variantValues.length === 0) {
            return true;
        }
        return false;
    }
    defaultHasErrors() {
        const constantMapKeyValues = this.constantForm.get('constantMapKeyValues')['controls'];
        let hasErrors = false;
        constantMapKeyValues.forEach(element => {
            const value = element.get('value');
            const displayName = element.get('displayName');
            if (value.errors || displayName.errors) {
                hasErrors = true;
            }
        });
        return hasErrors;
    }
}

//Validate that the ruleId for this control is not the same as the other controls
export function ValidateUniqueRuleId(control: UntypedFormControl): { [key: string]: boolean } | null {
    if (control.parent) {
        let throwError = false;
        const ruleId = control.value;
        const variantControl = control.parent;
        const arrayControl = variantControl.parent as UntypedFormArray;
        arrayControl.controls.filter(control => control !== variantControl)
            .forEach(siblingControl => {
                if (siblingControl.value.ruleId === ruleId) {
                    throwError = true;

                    //Need to run this asynchronously so that change detection can happen properly
                    setTimeout(() => {
                        siblingControl.get('ruleId').setErrors({ 'uniqueRuleId': true });
                        siblingControl.markAllAsTouched();
                        siblingControl.markAsTouched();
                    }, 0);
                } else {

                    //Need to run this asynchronously so that change detection can happen properly
                    setTimeout(() => {
                        siblingControl.get('ruleId').setErrors(null);
                        siblingControl.markAllAsTouched();
                        siblingControl.markAsTouched();
                    }, 0);
                }
            });
        if (throwError) {
            //Mark this control as invalid
            return { 'uniqueRuleId': true };
        }
    }
    return null;
}

//Validate that the name for this control is not the same as the other controls
//Make this an async validator

export function ValidateUniqueName(control: UntypedFormControl): { [key: string]: boolean } | null {
    if (control.parent) {
        let throwError = false;
        const name = control.value;
        const variantControl = control.parent;
        const arrayControl = variantControl.parent as UntypedFormArray;
        arrayControl.controls.filter(control => control !== variantControl)
            .forEach(siblingControl => {
                if (siblingControl.value.name.toLowerCase() === name.toLowerCase()) {
                    throwError = true;

                    //Need to run this asynchronously so that change detection can happen properly
                    setTimeout(() => {
                        siblingControl.get('name').setErrors({ 'uniqueName': true });
                        siblingControl.markAllAsTouched();
                        siblingControl.markAsTouched();
                    }, 0);
                } else {

                    //Need to run this asynchronously so that change detection can happen properly
                    setTimeout(() => {
                        siblingControl.get('name').setErrors(null);
                        siblingControl.markAllAsTouched();
                        siblingControl.markAsTouched();
                    }, 0);
                }
            });
        if (throwError) {
            //Mark this control as invalid
            return { 'uniqueName': true };
        }
    }
    return null;
}

export function ValidateVariantListValueSelection(control: UntypedFormControl): { [key: string]: boolean } | null {
    if (control.parent) {
        const variantControl = control.parent;
        if (variantControl.get('constantMapkeyVariantValues').value.length === 0) {
            return { 'noValuesSelected': true };
        }
    }
    return null;
}

export function ValidateValueDuplicate(control: UntypedFormControl): { [key: string]: boolean } | null {
    if (control.parent) {
        const fullList = control.parent.parent as UntypedFormArray;
        const potentialDuplicates = fullList.controls.map(currentControl => {
            const controlGroup = currentControl as UntypedFormGroup;
            return controlGroup.get('value').value;
        });
        let needToError = false;
        fullList.controls
            .forEach(siblingControl => {
                const siblingControlItem = siblingControl as UntypedFormGroup;
                const siblingControlValue = siblingControlItem.get('value');
                const siblingControlDisplayName = siblingControlItem.get('displayName');
                siblingControlDisplayName.markAsTouched();
                const count = potentialDuplicates.filter(x => x === siblingControlValue.value).length;
                needToError = count > 1;
                if (needToError && siblingControlValue.value) {
                    setTimeout(() => {
                        const errors = siblingControlValue.errors;
                        if (errors) {
                            errors['duplicate'] = true;
                            siblingControlValue.setErrors(errors);
                        } else {
                            siblingControlValue.setErrors({ 'duplicate': true });
                        }
                        siblingControlValue.markAllAsTouched();
                        siblingControlValue.markAsTouched();
                    }, 0);
                } else {
                    setTimeout(() => {
                        const errors = siblingControlValue.errors;
                        if (errors) {
                            delete errors['duplicate'];
                            if (Object.keys(errors).length === 0) {
                                siblingControlValue.setErrors(null);
                            } else {
                                siblingControlValue.setErrors(errors);
                            }
                            siblingControlValue.markAllAsTouched();
                            siblingControlValue.markAsTouched();
                        }
                    }, 0);
                }
            });
        if (needToError) {

            return { 'duplicate': true };
        }
    }

    return null;
}
