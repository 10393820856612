import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {AuthLegacyService} from '../../auth/auth-legacy.service';
import {InterviewService} from "app/interview.service";
import {ActivatedRoute} from "@angular/router";
import { PurlService } from '../../services';

@Component({
    selector: 'applicant-end-of-interview',
    templateUrl: 'applicant-end-of-interview.component.html',
    styleUrls: ['../interview.component.scss']
})
export class ApplicantEndOfInterviewComponent implements OnInit {
  @ViewChild('discardDialog', {static: true}) discardDialog: TemplateRef<any>;
  accountInfo: any;
  clickSource: any;
  cancelledCase: boolean;
  summaryMessage: string;


  constructor(
    private dialog: MatDialog,
    private authService: AuthLegacyService,
    private interviewService: InterviewService,
    private purlService: PurlService,
    public route : ActivatedRoute) {
  }

  ngOnInit() {
      if(this.purlService.isPurlCaseCanceled){
          this.cancelledCase = true;
      }else{
          this.cancelledCase = false;
          var interviewData = this.interviewService.getInterviewData();
          this.summaryMessage = interviewData.summaryMessage ? interviewData.summaryMessage : '';
      }

      this.authService.logout(false);

      this.dialog.open(this.discardDialog, {
          width: '600px',
          data: {},
          panelClass: 'modal-dark-background',
          disableClose: true
      });
  }


}
