import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { lastValueFrom, Observable } from 'rxjs';
import { InitializationResponseModel } from 'app/models';
import { IConfigService } from 'app/config/iconfigservice';
import { BaseService } from 'app/services';
import { AppService } from 'app/app.service';
 
@Injectable({
    providedIn: 'root'
})
export class CepQuestionnaireService extends BaseService{
 
    baseUrl: string;
    publicApiBaseUrl: string;

    constructor(
        public appService: AppService,
        public httpClient: HttpClient,
        public configService: IConfigService
    ) {
        super(appService, configService, httpClient);

        this.baseUrl = this.appService.getAPIBaseURL();
        this.publicApiBaseUrl = this.configService.getConfiguration().publicApiUrl;
    }
 

    getAssessmentId(clientCode: string, accountCode: string) {
        const url = `${this.publicApiBaseUrl}clientCode/${clientCode}/account/${accountCode}/Gtl/InitializeAssessment`;
 
        return super.postData(url); 
    }
  
    renewToken(baseUrl, assessmentId): Promise<InitializationResponseModel> {
        const renewTokenUrl = `${baseUrl}/integration/${assessmentId}/renewToken`;
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type','application/json; charset=utf-8');

        return lastValueFrom(this.httpClient.post<InitializationResponseModel>(renewTokenUrl, { headers }));
    }

    initialize(baseUrl, widgetData: any): Observable<InitializationResponseModel> {
        const initializeUrl = `${baseUrl}/integration`;
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type','application/json; charset=utf-8');
 
        const payload = JSON.parse(widgetData);
    
        return this.httpClient.post<InitializationResponseModel>(initializeUrl, payload, { headers: headers });
    }

    getAssessment(baseUrl, assessmentId: string): Observable<any> {
        const initializeUrl = `${baseUrl}/assessment/${assessmentId}`;
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type','application/json; charset=utf-8');
     
        return this.httpClient.get<any>(initializeUrl, { headers: headers });
    }
}