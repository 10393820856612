import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { FeatureToggle } from 'app/enums/feature-toggle.enum';
import { RoutesEnum, RoutingService } from '../';
import { FeatureManagerService } from '../global/feature-manager/feature-manager.service';

@Injectable()
export class AuthV2GuardService implements CanActivate {
    constructor(
        public routingService: RoutingService,
        public featureManagerService: FeatureManagerService) { }

    canActivate(_route: ActivatedRouteSnapshot): Promise<boolean> {

        return new Promise((resolve) => {
            const useV2 = this.featureManagerService.getByName(FeatureToggle.GlobalUseAuthenticationV2)?.enabled;

            if (useV2) {
                this.routingService.navigateToRoute(RoutesEnum.loginV2);
            } else {
                resolve(true);
            }
        });
    }
}
