import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { AppService } from './app.service';
import { MonitoringService } from './monitor.service';
import { FeatureManagerService, FeatureToggleResponse } from './services/global/feature-manager/feature-manager.service';
import { InjectableScripts, ScriptLoaderService } from './services/script.service';
import { RoutingService } from './services';
import { Router } from '@angular/router';
import { FeatureToggle } from '@Enums';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    title = 'MRS';
    loggedIn = false;

    loginForm: UntypedFormGroup;
    showLoader: number;
    showSecondaryLoadingMask: boolean;
    forceHideLoadingIndicator: boolean;

    constructor(
        private router: Router,
        private appService: AppService,
        private monitorService: MonitoringService,
        private cdr: ChangeDetectorRef,
        public featureManager: FeatureManagerService,
        public scriptLoader: ScriptLoaderService,
        public routingService: RoutingService
    ) {
        router.initialNavigation();
    }

    //#region Helpers

    private hotjarEnabled(featureFlags: FeatureToggleResponse[]) {
        if(!featureFlags.some(x => x.name === 'App.Script.Hotjar' && x.enabled))
            return false;

        return featureFlags.some(x => x.name === 'App.Script.Hotjar.User' && x.enabled);
    }

    private zendeskEnabled(featureFlags: FeatureToggleResponse[]) {
        if(!featureFlags.some(x => x.name === 'App.Script.Zendesk' && x.enabled))
            return false;

        return featureFlags.some(x => x.name === 'App.Script.Zendesk.User' && x.enabled);
    }

    //#endregion

    initFeatureFlagScripts(): void {
        this.featureManager.loadFeatureFlags();
        this.featureManager.featureFlags$.subscribe(featureFlags => {
            if (this.hotjarEnabled(featureFlags)) this.scriptLoader.load(InjectableScripts.Hotjar).subscribe();
            if (this.zendeskEnabled(featureFlags)) this.scriptLoader.load(InjectableScripts.Zendesk).subscribe();
        });
    }

    ngOnInit() {
        this.initFeatureFlagScripts();
      
        this.monitorService.initLogging();
        let urlParams: any = window.location.search;
        const params: any = [];
        const pathName = window.location.pathname.toLowerCase();
        if (pathName && (pathName.includes('summary') || pathName.includes('endofcall'))) {
            sessionStorage.setItem('redirectUrl', pathName);
        }
        if (urlParams.length > 1) {

            urlParams = urlParams.substr(1).split('&');

            if (urlParams.length) {
                urlParams.forEach(param => {
                    const paramDetails = param.split('=');
                    if (paramDetails.length) {
                        params[paramDetails[0]] = paramDetails[1];
                    }
                });
            }

            if (params['redirect']) {
                if (params['id']) {
                    setTimeout(() => {
                        this.router.navigate([params.redirect, params.id]);
                    }, 2000);
                } else {
                    setTimeout(() => {
                        this.router.navigate([params.redirect]);
                    }, 2000);
                }
            }
        }
        
        const connection = params['connection'];
        if (connection){
            const redirectTo = params['redirectTo'];
            if (redirectTo){
                this.routingService.navigateToUrl(decodeURIComponent(redirectTo));
            }
        }

        if (this.featureManager.getByName(FeatureToggle.GlobalAppLoader).enabled) {
            this.forceHideLoadingIndicator = this.appService.forceHideLoadingIndicator;
            this.appService.status.subscribe((val: number) => {
                this.showLoader = val;
            });

            this.appService.secondaryLoadingMask.subscribe((val: boolean) => {
                this.showSecondaryLoadingMask = val;
            });
        }
    }

    ngAfterViewChecked(){
        if (!this.featureManager.getByName(FeatureToggle.GlobalAppLoader).enabled) {
            this.forceHideLoadingIndicator = this.appService.forceHideLoadingIndicator;
            this.appService.status.subscribe((val: number) => {
                this.showLoader = val;
            });

            this.appService.secondaryLoadingMask.subscribe((val: boolean) => {
                this.showSecondaryLoadingMask = val;
            });
        }
        this.cdr.detectChanges();
    }
}
