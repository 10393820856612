import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfigDataDTO } from '@DTOs';
import { FeatureToggle } from '@Enums';
import { Account } from '@Models';
import { ConfirmationDialogService, FeatureManagerService, RoutesEnum } from '@Services';
import { AppService } from 'app/app.service';
import * as $ from 'jquery';

@Component({
    selector: 'account-rules',
    host: { 'class': 'bg-white d-flex flex-row flex-fill' },
    templateUrl: './account-rules.component.html',
    styleUrls: ['./account-rules.component.scss']
})
export class AccountRulesComponent implements OnInit {

    ruleConfig: any;
    @Input() accountInfo: Account;
    @Input() mapKeys;
    @Input() nonListNonVirtualMapKeys;
    @Input() variableMapKeys;
    @Input() nonVirtualMapKeys;
    @Output() newRuleAdded = new EventEmitter<any>();
    rules: any[];
    configData: ConfigDataDTO;

    showTestRuleForm = false;
    testMapkeys: any = [];
    testResult: any = {};
    isTestExecuted = false;

    filterValue: string;
    filteredRules: any[] = [];
    showDetails = true;
    usedInFeatureToggleEnabled = false;
    showUsedIn = true;
    showUsedInSection = false;

    constructor(
        private appService: AppService,
        private confirmationService: ConfirmationDialogService,
        private featureManagerService: FeatureManagerService,
        public dialog: MatDialog
    ) {
    }

    ngOnInit() {
        this.checkACL("R", RoutesEnum.dashboard);
        this.usedInFeatureToggleEnabled = this.featureManagerService.getByName(FeatureToggle.ConfigRulesUsedIn).enabled;
        this.getRules();
        this.getConfigData();
        this.showUsedIn = this.usedInFeatureToggleEnabled && this.ruleConfig && Object.keys(this.ruleConfig).length === 0;
    }


    checkACL(permissionType, redirect = undefined) {
        return this.appService.checkACL("Accounts", permissionType, redirect);
    }

    getRules(id = undefined) {
        this.appService.getData(`Rules/${this.accountInfo.id}`).subscribe(
            data => {
                if (data.status == "success") {
                    this.rules = data.data;

                    this.filteredRules = this.rules;

                    this.newRuleAdded.emit();

                    if (id) {
                        const rule = this.rules.find(r => r.id == id);
                        if (rule) {
                            this.getRule(rule, id);
                        }
                    }
                }
            }
        );
    }

    getRule(rule, id = undefined) {
        this.clearTestResults();

        this.appService.getData(`Rules/${rule.accountId}/Rule/${rule.id}`).subscribe(
            data => {
                if (data.status == "success") {
                    this.ruleConfig = data.data;
                    this.showUsedInSection = true;
                    this.showUsedIn = true;
                    if (id) {
                        setTimeout(function () {
                            $(".left-panel-body").animate({
                                scrollTop: $(".list-item.active")[0].offsetTop - 50
                            }, 600);
                        }, 10);
                    }
                }
            }
        );
    }

    clearTestResults() {
        this.testMapkeys = [];
        this.testResult = [];
        this.isTestExecuted = false;
    }

    getConfigData() {
        this.appService.getAllConfigData().then((data) => {
            this.configData = data;
        });
    }

    addRule() {
        this.ruleConfig = {};
        this.showUsedInSection = false;
        this.clearTestResults();
    }


    deleteRule(id) {
        this.confirmationService.confirm({
            message: 'Are you sure that you want to delete this rule?',
            key: 'rulesConfirm',
            accept: () => {
                this.appService.deleteData(`Rules/${id}`).subscribe(
                    data => {
                        if (data.status == "success") {
                            this.ruleConfig = null;
                            this.getRules();
                            this.appService.showMsg("success", data.message);
                        } else {
                            this.appService.showMsg("error", data.message);
                        }
                    }
                );
            },
            showCancel: true
        });

    }

    getTestMapkeys(ruleData, templateRef: any) {
        this.ruleConfig.id = this.ruleConfig.id ? this.ruleConfig.id : ruleData.id;
        this.appService.getData(`MapKeys/${this.ruleConfig.id}/GetMapKeysForRule`).subscribe(
            data => {
                if (data.status === 'success') {
                    this.testMapkeys = data.data;
                    this.testMapkeys.forEach(mapkey => {
                        if (mapkey.isJson) {
                            mapkey.value = JSON.stringify(JSON.parse(mapkey.value), null, 2);
                        }
                    });
                    this.showTestRuleForm = true;
                    this.testRule(templateRef);
                }
            }
        );
    }

    testRule(templateRef: any) {
        this.dialog.open(templateRef,
            {
                width: '800px',
                maxHeight: '90%',
                data: {
                    testMapkeys: this.testMapkeys
                }
            });
    }

    closeDialog() {
        this.dialog.closeAll();
    }

    runTest() {
        this.appService.postData(`Rules/${this.ruleConfig.id}/Test`, this.testMapkeys).subscribe(
            data => {
                if (data.status === 'success') {
                    this.testResult = data.data;
                    this.showTestRuleForm = false;
                    this.isTestExecuted = true;
                    this.closeDialog();
                }
            }
        );
    }

    searchRules() {
        if (this.filterValue) {
            this.filteredRules = this.rules.filter(p => p.name.toLowerCase().indexOf(this.filterValue.toLowerCase()) !== -1);
        } else {
            this.filteredRules = this.rules;
        }
    }

}
