import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import { AppService } from '../../../../app.service';
import { ConfirmationDialogService } from '../../../../../app/services';

@Component({
    selector: 'request',
    templateUrl: './request.component.html',
    styleUrls: ['./request.component.scss']
})
export class RequestComponent implements OnInit {

    _request;
    _expanded;
    showRequestTemplate = false;
    requestTemplate: any;
    showMockResponse = false;
    mockResponse: any;

    isEditView: boolean;
    requestForm: UntypedFormGroup;
    @Output() onUpdateRequests = new EventEmitter<any>();

    constructor(
        private _fb: UntypedFormBuilder,
        private appService: AppService,
        private confirmationService: ConfirmationDialogService,
        public dialog: MatDialog
    ) {
        this.isEditView = false;
    }

    ngOnInit() { }

    @Input()
    set expanded(value) {
        this._expanded = value;
    }
    get expanded() {
        return this._expanded;
    }

    @Input()
    set request(value) {

        if (!value.id) {
            this.isEditView = true;
            this.expanded = true;
        }

        this._request = value;

        this.requestForm = this._fb.group({
            id: value.id,
            accountIntegrationID: [value.accountIntegrationID, Validators.required],
            name: [value.name, Validators.required],
            action: value.action,
            actionTo: value.actionTo,
            namespacePrefix: value.namespacePrefix,
            namespace: value.namespace,
            requestTemplate: [value.requestTemplate, Validators.required],
            isMockMode: value.isMockMode,
            mockResponse: [value.mockResponse, Validators.required]
        });
    }
    get request() {
        return this._request;
    }


    saveRequest() {

        let isEdit = false;
        if (this.requestForm.get('id').value) { isEdit = true; }

        const params = this.requestForm.value;

        if (this.requestForm.valid) {
            this.appService.postData('IntegrationRequest', this.requestForm.value, isEdit, true)
                .subscribe(
                    data => {
                        if (data.status === 'success') {
                            this.isEditView = false;
                            this.onUpdateRequests.emit();
                            this.appService.showMsg('success', 'Saved successfully ...');
                        } else {
                            this.appService.showMsg('error', data.message);
                        }
                    }
                );
        } else {
            this.appService.showMsg('error', 'Invalid Form Data. Please fill all details.', false);
        }
    }


    deleteRequest() {
        this.confirmationService.open({
            message: 'Are you sure that you want to delete this request?',
            onOk: () => {
                if (this.request.id) {
                    this.appService.deleteData(`IntegrationRequest/${this.request.id}`).subscribe(
                        data => {
                            if (data.status === 'success') {
                                this.onUpdateRequests.emit();
                                this.appService.showMsg('success', data.message);
                            } else {
                                this.appService.showMsg('error', data.message);
                            }
                        }
                    );
                } else {
                    this.onUpdateRequests.emit();
                }
            }
        });
    }

    editRequest() {
        this.isEditView = true;
    }

    checkACL(permissionType, redirect?) {
        return this.appService.checkACL('Accounts', permissionType, redirect);
    }

    expandRequestTemplate(templateRef: any) {
        this.requestTemplate = this.requestForm.get('requestTemplate').value;
        this.dialog.open(templateRef,
            {
                width: '800px',
                maxHeight: '90%',
                data: {
                    requestTemplate: this.requestTemplate
                }
            });
        this.showRequestTemplate = true;
    }

    closeRequestTemplate() {
        this.requestForm.get('requestTemplate').patchValue(this.requestTemplate);
        this.dialog.closeAll();
        this.showRequestTemplate = false;
    }

    expandMockResponse(templateRef: any) {
        this.mockResponse = this.requestForm.get('mockResponse').value;
        this.dialog.open(templateRef,
            {
                width: '800px',
                maxHeight: '90%',
                data: {
                    mockResponse: this.mockResponse
                }
            });
        this.showMockResponse = true;
    }

    closeMockResponse() {
        this.requestForm.get('mockResponse').patchValue(this.mockResponse);
        this.dialog.closeAll();
        this.showMockResponse = false;
    }
}
