<mat-card appearance="outlined" style="width: 100%">
  <mat-card-header>
    <mat-card-title>
      <h2>Document Signing Settings</h2>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content style="margin-left: 5px">
    <mat-tab-group>
      <mat-tab label="Document Signing Provider">
        <form [formGroup]="signingProviderForm" (ngSubmit)="save()" novalidate autocomplete="off">
          <mat-form-field class="w-100 mt-3">
            <mat-label>Document Signing Provider</mat-label>
            <mat-select formControlName='documentSigningProvider'>
              <mat-option value="AlphaTrust">AlphaTrust</mat-option>
              <mat-option value="Docusign">Docusign</mat-option>
            </mat-select>
          </mat-form-field>
          <div class="pt-4 flex" style="justify-content: flex-end;">
            <button type="submit" mat-flat-button color="primary">
              SAVE
            </button>
          </div>
        </form>

      </mat-tab>
      <mat-tab label="Account Signers">
        <ng-template matTabContent>
          <account-signers [accountInfo]="accountInfo" [mapKeys]="mapKeys"></account-signers>
        </ng-template>
      </mat-tab>
      <mat-tab label="Envelopes">
        <ng-template matTabContent>
          <account-envelopes [accountInfo]="accountInfo" [mapKeys]="mapKeys" [rules]=rules></account-envelopes>
        </ng-template>
      </mat-tab>
    </mat-tab-group>

  </mat-card-content>
</mat-card>