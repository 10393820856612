<div *ngIf="!useV2">
  <h1 mat-dialog-title>Attach Document</h1>
  <div mat-dialog-content>
    <form [formGroup]="attachmentForm" novalidate enctype="multipart/form-data" autocomplete="off">
      <div>
        <input type="file" id="fileInput" (change)="selectFile($event)" (click)="selectFile($event)" *ngIf="!isEdit" />
        <div *ngIf="isEdit">{{data.document.remoteName}}</div>
        <section class="w-100 pt-3">
          <mat-checkbox formControlName="includeInCasePackage" class="mr-2">Include in Case Package</mat-checkbox>
        </section>
        <object-tags [(tags)]="attachmentTags" [account]="data.account"></object-tags>
      </div>
    </form>
  </div>
  <div mat-dialog-actions>
    <div class="flex-1"></div>
    <button mat-button class="material-default text-uppercase mr-2" (click)="cancel()">Cancel</button>
    <button mat-button class="material-default text-uppercase" (click)="addAttachment()" cdkFocusInitial
      (disabled)="!validFile">Save</button>
  </div>
</div>

<div *ngIf="useV2">
  <div mat-dialog-title *ngIf="!isEdit">Add Attachment</div>
  <div mat-dialog-title *ngIf="isEdit">Edit Attachment</div>
  <div mat-dialog-content>
    <form [formGroup]="attachmentForm" novalidate enctype="multipart/form-data" autocomplete="off">
      <div>
        <div *ngIf="!isEdit">
          <input #fileInput hidden="true" type="file" id="fileInput" (change)="selectFileV2($event)" (click)="selectFileV2($event)" />
          <div class="flex">
            <button mat-flat-button color="primary" class="text-uppercase" (click)="fileInput.click()">Select File</button>
          </div>
          <div class="flex">{{fileName}}</div>
        </div>
        <div *ngIf="isEdit">{{data.document.remoteName}}</div>
        <section class="w-100 pt-3">
          <mat-checkbox formControlName="includeInCasePackage" class="mr-2">Include in Case Package</mat-checkbox>
        </section>
        <object-tags [(tags)]="attachmentTags" [account]="data.account"></object-tags>
      </div>
    </form>
    <div style="position: relative; margin-top: 10px;" *ngIf="!fileUploadComplete">
      <mat-progress-bar mode="determinate" [value]="fileUploadService.uploadProgress" class="upload-progress-bar"></mat-progress-bar>
      <span *ngIf="!fileUploadComplete" style="position: absolute; top: 0.5em;" [ngStyle]="{'left': fileUploadService.uploadProgress + '%'}">
        {{fileUploadService.uploadProgress}}%
      </span>
      <span *ngIf="fileUploadService.uploadProgress === 100 && fileUploadComplete" style="position: absolute; top: 0.3em; left: 40%; color: white">
        File upload complete
      </span>
      <span *ngIf="fileUploadService.uploadProgress === 100 && !fileUploadComplete" style="position: absolute; top: 0.3em; left: 40%; color: white">
        File upload processing
      </span>
    </div>
  </div>
  <div mat-dialog-actions align="end">
    <div *ngIf="fileUploadComplete">
      <div class="flex-1"></div>
      <button mat-button mat-button color="basic" class="mr-1 material-default text-uppercase" (click)="cancel()">Cancel</button>
      <button mat-button mat-button color="primary" class="mr-1 material-default" (click)="addAttachmentV2()" cdkFocusInitial (disabled)="!validFile">Save</button>
    </div>
  </div>
</div>
