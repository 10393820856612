<div class="left-panel w-25 bg-white d-flex flex-column">
    <div class="left-panel-header flex flex-column">
        <div>
            <span class="left-panel-title">Documents</span>
            <button *ngIf="checkACL('W')" type="button" class="btn btn-link btn-sm custom float-right"
                (click)="addDocument()">
                <i aria-hidden="true" class="fa fa-plus-circle"></i> Add Document
            </button>
            <button *ngIf="checkACL('W')" type="button" class="btn btn-link btn-sm custom float-right"
                (click)="this.hideArchived = !this.hideArchived">
                <i aria-hidden="true" class="fa fa-archive"></i>
                <span *ngIf="hideArchived">Show Archived</span>
                <span *ngIf="!hideArchived">Hide Archived</span>
            </button>
        </div>
        <div>
            <mrs-search-field [searchText]="filterValue" (searchTextChange)="filterValue = $event; searchDocuments()"
                [placeholder]="'Search Documents'"></mrs-search-field>
        </div>
    </div>
    <div class="left-panel-body flex-fill overflow-y-auto custom-scrollbar">
        <div class="list-container">
            <div class="list-item" (click)="getDocumentInfo(document)" matRipple
                *ngFor="let document of filteredDocuments"
                [ngClass]="{'active': document.id == this.selectedDocument?.id, 'disabled': document.documentStatusId == '00000000-0000-0000-0000-000000000002', 'd-none': (document.documentStatusId == '00000000-0000-0000-0000-000000000003' && hideArchived), 'archived': (document.documentStatusId == '00000000-0000-0000-0000-000000000003' && !hideArchived)}">
                <div class="list-item-title">{{document.name}}</div>
                <div class="list-item-actions  mr-3">
                    <div *ngIf="checkACL('F')" (click)="deleteDocument(document.id)">
                        <mat-icon>delete</mat-icon>
                    </div>
                </div>
            </div>
        </div>
        <div class="p-4 text-center text-muted" *ngIf="documents?.length == 0">
            <img src="./assets/images/no-data-placeholder.png" alt="" class="pb-3" />
            <h6>No Documents Found</h6>
        </div>
    </div>
</div>
<div class="right-panel flex-fill d-flex flex-column">
    <div class="right-panel-body flex-fill overflow-y-auto custom-scrollbar p-3">

        <div class="row" *ngIf="showForm">
            <div class="col-9 mx-auto">
                <form class="px-5 py-3" [formGroup]="documentForm" (ngSubmit)="saveDocument()" novalidate
                    enctype="multipart/form-data" autocomplete="off">
                    <mat-card appearance="outlined">
                        <mat-card-header>
                            <h2>Document Details</h2>
                        </mat-card-header>
                        <mat-card-content>
                            <input type="hidden" formControlName='id'>
                            <input type="hidden" formControlName='accountId'>
                            <input type="hidden" formControlName='clientId'>
                            <input type="hidden" formControlName='path'>
                            <input type="hidden" formControlName='isFile'>
                            <input type="hidden" formControlName='contentType'>
                            <input type="hidden" formControlName='documentMd5'>
                            <input type="hidden" formControlName='schemaName'>
                            <mat-form-field class="w-100 pb-3">
                                <mat-label>Document Type</mat-label>
                                <mat-select formControlName='doctypeId' (selectionChange)="onInputTypeSelect($event)">
                                    <mat-option *ngFor="let type of filteredInputTypes" [value]="type.id">
                                        {{ type.name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="documentForm.get('doctypeId').hasError('required')">
                                    Input Type
                                    <strong>required</strong>
                                </mat-error>
                            </mat-form-field>
                            <div *ngIf="showIntegration">
                                <mat-form-field class="w-100 pb-3">
                                    <mat-label>Integration</mat-label>
                                    <mat-select formControlName='integrationId'>
                                        <mat-option *ngFor="let type of accountIntegrations" [value]="type.id">
                                            {{ type.name }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="documentForm.get('doctypeId').hasError('required')">
                                        Integration
                                        <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div>
                                <div class="mb-4" *ngIf="showSelectFile">
                                    <label>
                                        {{documentName}}
                                    </label>
                                    <label class="float-right">
                                        <input type="file" id="fileInput" (change)="selectFile($event)"
                                            (click)="onClick($event)" formControlName='file' class="d-none"
                                            accept="{{getExtensionFormat(documentForm.get('doctypeId').value)}}"
                                            [attr.disabled]="documentForm.get('doctypeId').value ==''?'':null" />
                                        <a class="text-decoration-none" mat-raised-button color="primary"
                                            [disabled]="documentForm.get('doctypeId').value ==''?true:null">Select
                                            Document</a>
                                        <button type="button" mat-button color="basic" class="pl-1"
                                            (click)='unSelectFile()'>
                                            <i aria-hidden="true" class="fa fa-close"></i> Clear
                                        </button>
                                    </label>
                                </div>

                                <mat-form-field class="w-100">
                                    <mat-label>Document Name</mat-label>
                                    <input matInput formControlName='name'>
                                    <mat-error *ngIf="documentForm.get('name').hasError('required')">
                                        Name
                                        <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field class="w-100 pb-3" *ngIf="showOutputType">
                                    <mat-label>Output Type</mat-label>
                                    <mat-select formControlName='generateTo'
                                        (selectionChange)="onOutputTypeSelect($event)">
                                        <mat-option *ngFor="let type of filteredOutputTypes" [value]="type.id">
                                            {{ type.name }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="documentForm.get('generateTo').hasError('required')">
                                        Output Type
                                        <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field class="w-100 pb-3"
                                    *ngIf="documentForm.get('generateTo').value === doctypeXML">
                                    <mat-label>Validation XSD</mat-label>
                                    <mat-select formControlName='schemaName'>
                                        <mat-option></mat-option>
                                        <mat-option *ngFor="let docs of xsdDocuments" [value]="docs.remoteName">
                                            {{docs.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <auto-suggest *ngIf="showRule" [list]="rules" placeholder="Rule" field="name" dataKey="id"
                                formControlName="rulesId"
                                [showRequiredError]="documentForm.get('rulesId').hasError('required')">
                            </auto-suggest>
                            <div *ngIf="showCombinedDocuments" class="mb-1">
                                <button type="button" mat-raised-button color="primary" class="material-default"
                                    (click)="selectCombinedDocuments()">
                                    Choose Combined Documents
                                </button>
                            </div>
                            <div *ngIf="showSigningRule">
                                <auto-suggest *ngIf="isNotXSDDoc(documentForm.get('doctypeId').value)" [list]="rules"
                                    placeholder="Signing Rule" field="name" dataKey="id"
                                    formControlName="signingRuleId"></auto-suggest>
                                <mat-form-field class="w-100"
                                    *ngIf="documentForm.get('signingRuleId').value && documentForm.get('signingRuleId').value != appService.emptyGUID">
                                    <mat-label>Signature Field</mat-label>
                                    <input matInput formControlName='signatureField'>
                                    <mat-error *ngIf="documentForm.get('signatureField').hasError('required')">
                                        Name
                                        <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <mat-form-field class="w-100 custom-tool-tip" *ngIf="showOutputFileName">
                                <mat-label>Output File Name Convention</mat-label>
                                <input matInput formControlName='convention'>
                                <span matSuffix *ngIf="true" (click)="openToolTip()"
                                    style="cursor: pointer; margin-right: 10px"><mat-icon>info</mat-icon></span>

                                <mat-error *ngIf="documentForm.get('convention').hasError('required')">
                                    Output file name convention
                                    <strong>required</strong>
                                </mat-error>
                            </mat-form-field>
                            {{previewConvention}}
                            <mrs-btn-icon class="float-right" buttonLabel="Validate" buttonId="addButton"
                                (onClick)="validateAndPreviewConvention(documentForm.get('convention').value)"
                                [isSubmitButton]="false"
                                iconName="check"></mrs-btn-icon>
                            <mat-form-field class="w-100 pb-3" *ngIf="selectedDocument && showDocumentStatus">
                                <mat-label>Document Status</mat-label>
                                <mat-select formControlName='documentStatusId'>
                                    <mat-option *ngFor="let status of documentStatus" [value]="status.id">
                                        {{status.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <auto-suggest [list]="secretKeys" placeholder="Signing Certificate" field="name"
                                dataKey="id"
                                *ngIf="documentForm.get('generateTo').value === doctypePDF && showSigningCertificate"
                                formControlName="signingCertificate"
                                [showRequiredError]="documentForm.get('rulesId').hasError('required')">
                            </auto-suggest>
                            <mat-form-field class="w-100 textarea-formfield" *ngIf="showXslData">
                                <mat-label>XSL Data</mat-label>
                                <textarea matInput matTextareaAutosize matAutosizeMinRows="4" matAutosizeMaxRows="10"
                                    formControlName="xslData"></textarea>
                            </mat-form-field>
                            <section class="w-100 pt-1" *ngIf="showExcludeTransmit">
                                <mat-checkbox formControlName="isExcludeTransmit" class="mr-2">Exclude Case
                                    Package</mat-checkbox>
                            </section>
                            <section class="w-100 pt-1" *ngIf="isPDFDoc(documentForm.get('doctypeId').value)">
                                <mat-checkbox formControlName="includeInSummary" class="mr-2">Include In Summary
                                    Document</mat-checkbox>
                            </section>
                            <mat-form-field class="w-100 pb-3">
                                <mat-label>Change Key Tag</mat-label>
                                <mat-select formControlName='changeKeyTag'>
                                    <mat-option *ngFor="let tag of tagSuggestions" [value]="tag">{{tag}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <section class="w-100 pt-1" *ngIf="showRenumberPages">
                                <mat-checkbox formControlName="renumberPages" class="mr-2">Renumber Pages in combined document</mat-checkbox>
                            </section>
                            <section class="w-100 pt-1" *ngIf="showEmbeddedDocuments">
                                <i aria-hidden="true" (click)="editEmbeddedDocuments(selectedDocument)"
                                    class="fa fa-pencil-alt m-2" style="cursor: pointer; padding-top:6px;"></i>
                                <label style="margin-right: 5px;">Embedded Documents</label>
                            </section>
                            <div class="col-12 pt-5 text-center">
                                <mrs-btn-primary buttonId="save" buttonLabel="Save"
                                    [isSubmitButon]="true"></mrs-btn-primary>
                                <mrs-btn-tertiary buttonLabel="Close" buttonId="cancelButton"
                                    (onClick)="cancel()">Cancel
                                </mrs-btn-tertiary>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </form>
            </div>
        </div>
        <div class="col-6 mx-auto mt-5" *ngIf="!showForm && selectedDocument?.id">
            <mat-card appearance="outlined">
                <mat-card-header>
                    <mat-card-title-group>
                        <div class="page-header">Document Details</div>
                        <div class="flex-1"></div>
                        <div style="justify-content: flex-end; padding-bottom: 5px;">
                            <mrs-btn-primary buttonLabel="Edit" buttonId="editButton" *ngIf="checkACL('W')"
                                (onClick)="editDocument()">
                            </mrs-btn-primary>
                            <mrs-btn-icon buttonLabel="Download" buttonId="downloadButton"
                                *ngIf="checkACL('W') && selectedDocument.doctypeId=='00000000-0000-0000-0000-000000000001' "
                                (onClick)="downloadDocument()" iconName="cloud">
                            </mrs-btn-icon>
                        </div>
                    </mat-card-title-group>
                </mat-card-header>
                <mat-card-content>
                    <div class="label-title">Document Type</div>
                    <div class="label-content">{{getTypeName(selectedDocument.doctypeId)}}</div>
                    <div class="label-title">Document Name</div>
                    <div class="label-content">{{selectedDocument.name}}</div>
                    <div class="label-title" *ngIf="showCombinedDocuments">Combined Documents</div>
                    <div class="label-content" *ngIf="showCombinedDocuments">{{combinedDocumentNameList}}</div>
                    <div class="label-title" *ngIf="showOutputType">Output Type</div>
                    <div class="label-content" *ngIf="showOutputType">{{getTypeName(selectedDocument.generateTo)}}</div>
                    <div class="label-title" *ngIf="showRule"> Rule</div>
                    <div class="label-content" *ngIf="showRule">{{getRuleName(selectedDocument.rulesId)}}</div>
                    <div *ngIf="showSigningRule">
                        <div class="label-title"> Signing Rule</div>
                        <div class="label-content">{{getRuleName(selectedDocument.signingRuleId)}}</div>
                        <div class="label-title"
                            *ngIf="selectedDocument.signingRuleId && selectedDocument.signingRuleId != appService.emptyGUID">
                            Signature Field
                        </div>
                        <div class="label-content"
                            *ngIf="selectedDocument.signingRuleId && selectedDocument.signingRuleId != appService.emptyGUID">
                            {{selectedDocument.signatureField}}</div>
                    </div>
                    <div class="label-title" *ngIf="showOutputFileName">Naming Convention</div>
                    <div class="label-content" *ngIf="showOutputFileName">{{selectedDocument.convention}}</div>

                    <div class="label-title" *ngIf="selectedDocument.generateTo === doctypeXML">Validation XSD</div>
                    <div class="label-content" *ngIf="selectedDocument.generateTo === doctypeXML">
                        {{getSchemaName(selectedDocument.schemaName)}}</div>
                    <div class="label-title" *ngIf="showDocumentStatus">Document Status</div>
                    <div class="label-content" *ngIf="showDocumentStatus">
                        {{getDocumentStatusName(selectedDocument.documentStatusId)}}</div>
                    <div class="label-title" *ngIf="showExcludeTransmit">Exclude Case Package</div>
                    <div class="label-content" *ngIf="showExcludeTransmit">
                        <i aria-hidden="true" class="fa"
                            [ngClass]="{'fa-check-circle text-success': selectedDocument.isExcludeTransmit, 'fa-times-circle text-danger': !selectedDocument.isExcludeTransmit}"></i>
                    </div>
                    <div *ngIf="showIncludeInSummary">
                        <div class="label-title">Include in Summary Document</div>
                        <div class="label-content"><i aria-hidden="true" class="fa"
                                [ngClass]="{'fa-check-circle text-success': selectedDocument.includeInSummary, 'fa-times-circle text-danger': !selectedDocument.includeInSummary}"></i>
                        </div>
                    </div>
                    <div *ngIf="selectedDocument.signingCertificate">
                        <div class="label-title">Signing Certificate</div>
                        <div class="label-content">{{selectedDocument.signingCertificate}}</div>
                    </div>
                    <div class="label-title" *ngIf="showXslData">XSL
                        Data
                    </div>
                    <div class="label-content" *ngIf="showXslData">
                        {{selectedDocument.xslData}}
                    </div>
                    <div *ngIf="showRenumberPages">
                        <div class="label-title">Renumber pages in combined document</div>
                        <div class="label-content"><i aria-hidden="true" class="fa"
                                [ngClass]="{'fa-check-circle text-success': selectedDocument.renumberPages, 'fa-times-circle text-danger': !selectedDocument.renumberPages}"></i>
                        </div>
                    </div>
                    <div style="text-align: end;" *ngIf="showDownloadTemplateButton">
                        <mrs-btn-icon buttonLabel="Download Template" buttonId="downloadButton" *ngIf="checkACL('W')"
                            (onClick)="downloadDocumentTemplate()">
                        </mrs-btn-icon>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>
<ng-template #toolTip>
    <mat-dialog-content>
        <span class="tool-tip-text">
            The filename convention is used to generate the name of the generated document. It should include the
            extension and the current date parameter. The date parameter is necessary for regeneration.
            Mapkey replacement in the filename uses '%MAPKEY%'.<br />
            Replaceable parameters wrapped with curly braces: {{'{'}}Parameter{{'}'}}.<br />
            The filename convention supports the following replaceable parameters:<br />
            - Account (Account entity)<br />
            - Case (Case entity)<br />
            - Now (current date/time), can be formatted using .NET format specifiers<br />
            <br />
            Examples:<br />
            1.
            {{'{'}}Account.Code{{'}'}}-{{'{'}}Case.CaseNumber{{'}'}}-SIWL_GDP_App-{{'{'}}Now:yyyyMMddHHmmss{{'}'}}.pdf<br />
            = 123-001-1-SIWL_GDP_App-20181109083440.pdf<br /><br />
            2. {{'{'}}Case.PolicyNumber{{'}'}}-SIWL_GDP_App-{{'{'}}Now:yyyyMMddHHmmss{{'}'}}.tiff<br /> =
            000005123456-SIWL_GDP_App-20181109083440.tiff
        </span>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <mrs-btn-tertiary buttonLabel="Close" buttonId="closeButton" mat-dialog-close>Close</mrs-btn-tertiary>
    </mat-dialog-actions>
</ng-template>