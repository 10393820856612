import { Component, OnInit, Inject, ViewChild, ElementRef, ComponentRef, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
    selector: 'primoris-dialog-component',
    host: { 'class': 'dialog-iframe-content' },
    templateUrl: './primoris-dialog.component.html'
})


export class PrimorisDialogComponent implements OnInit{
  @ViewChild('iframe', {static: false}) iframe: ElementRef;

  paymentPrefs: any;
  url: any;
  doc;

  constructor(private vcRef: ViewContainerRef,
    private resolver: ComponentFactoryResolver,
    private sanitized: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data) {
      this.paymentPrefs = data.paymentPrefs;
  }
  ngOnInit(): void {
      this.url = this.sanitized.bypassSecurityTrustResourceUrl(`primoris-embedded.component.html?domain=${this.paymentPrefs.domain}&pubKey=${this.paymentPrefs.pubkey}&view=${this.paymentPrefs.view}&amount=${this.paymentPrefs.amount}&name=${this.paymentPrefs.form.name}&address=${this.paymentPrefs.form.address}&city=${this.paymentPrefs.form.city}&state=${this.paymentPrefs.form.state}&zip=${this.paymentPrefs.form.zip}&callBackHostKey=${this.paymentPrefs.callBackHostKey}&callBackUrl=${this.paymentPrefs.callBackUrl}&caseId=${this.data.caseId}&tenantId=${this.data.clientId}&caseIntegrationQueueId=${this.data.caseIntegrationQueueId}`);
  }

  onLoad(iframe){
      this.doc = iframe.contentDocument || iframe.contentWindow;
  }
}
