import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthClientConfig } from '@auth0/auth0-angular';
import { FeatureToggleResponse } from 'app/services/global/feature-manager/feature-manager.service';
import { lastValueFrom } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ApiVersionEnum } from '../enums/global/api-versions.enum';
import { WhiteLabelConfigResponse } from '../models';
import { Configuration } from './config-model';
import { IConfigService } from './iconfigservice';

@Injectable()
export class ConfigService implements IConfigService {
    private config: Configuration;
    private whiteLabelConfiguration: any;
    private featureFlags: Array<FeatureToggleResponse>;
    authClientConfig: AuthClientConfig;
    handler: HttpBackend;
    constructor(private http: HttpClient, handler: HttpBackend, authClientConfig: AuthClientConfig) {
        this.authClientConfig = authClientConfig;
        this.handler = handler;
    }


    async load(url?: string) {
        if (url === undefined) {
            url = environment.configFile;
        }

        if (this.config !== undefined) {
            return new Promise((resolve, reject) => {
                resolve(this.config);
            });
        }
        const httpClient = new HttpClient(this.handler);
        return lastValueFrom(httpClient.get(url))
            .then(async (config: Configuration) => {
                this.config = config;

                const port = window.location.port;

                if (port !== '') {
                    this.config.auth0Config.redirectUri = `${window.location.protocol}//${window.location.hostname}:${port}`;
                } else {
                    this.config.auth0Config.redirectUri = `${window.location.protocol}//${window.location.hostname}`;
                }

                this.authClientConfig.set({
                    domain: this.config.auth0V2Config.domain,
                    clientId: this.config.auth0V2Config.clientId,
                    authorizationParams: {
                        redirectUri: window.location.origin + '/authorize',
                        audience: this.config.auth0V2Config.audience,
                    },
                    cacheLocation: 'localstorage',
                    httpInterceptor: {
                        allowedList: [
                            {
                                uri: this.config.apiUrl.replace('v1/', '*'),
                                allowAnonymous: true
                            },
                            {
                                uri: this.config.publicApiUrl.replace('v2/', '*'),
                                allowAnonymous: true
                            },
                            {
                                uri: this.config.whiteLabelResourceUrl,
                                allowAnonymous: true
                            },
                            {
                                uri:this.config.underwritingApiUrl + '/*',
                                allowAnonymous: true
                            }
                        ]
                    }
                });

                await this.loadThemes();
                return config;
            });
    }

    getConfiguration(): Configuration {
        return this.config;
    }

    public async loadThemes() {
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');

        const allThemesUrl = `${this.getPublicApiUrl(ApiVersionEnum.v3)}global/whitelabelconfigs`;
        return lastValueFrom(this.http.get<WhiteLabelConfigResponse>(allThemesUrl, { headers: headers})
            .pipe(
                tap(response => {
                    this.whiteLabelConfiguration = {
                        themeDefaults: response.defaultConfiguration,
                        subdomainThemes: response.clientConfigurations,
                        themesLoaded: true,
                        whiteLabelResourceUrl: this.config.whiteLabelResourceUrl
                    };
                })
            ));
    }

    getPublicApiUrl(apiVersion: ApiVersionEnum): string {
        if (apiVersion !== ApiVersionEnum.v2) {
            return this.config.publicApiUrl.replace('v2', apiVersion);
        }
        return this.config.publicApiUrl;
    }

    getWhiteLabelConfiguration(){
        return this.whiteLabelConfiguration;
    }

    getFeatureFlags(){
        return this.featureFlags;
    }

    getUnderwriterApiUrl(): string {
        return this.config.underwritingApiUrl;
    }

    getAllowedLogoutRoutes(): string {
        return this.config.allowedLogoutRoutes;
    }
}
