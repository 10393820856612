import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { BaseInterviewFormFieldComponent, BaseFormFieldServices } from '../base-interview-form-field/base-interview-form-field.component';

@Component({
    selector: 'ssn-answer',
    templateUrl: './ssn-answer.component.html',
    styleUrls: ['./ssn-answer.component.scss']
})
export class SSNAnswerComponent extends BaseInterviewFormFieldComponent implements OnInit {
    public mask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

    constructor(public services: BaseFormFieldServices) {

        super(services);
    }

    ngOnInit() {
        super.initialize({
            inputType: 'text',
            additionalValidators: [
                Validators.pattern(/^\d{3}-?\d{2}-?\d{4}$/)
            ]
        });
    }
}
