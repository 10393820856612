import { AccountRule } from '@Audit';
import { RulesDTO } from '@DTOs';
import { AccountMinimal } from '@Models';
import { RulesDataService } from '@Services';
import { Utils } from '@Utils';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { MatSortModule, Sort } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';

export type AccountRuleSelection = {
    ruleId: string;
    disabled: boolean;
}

export type AccountRuleTableRow = {
    account: AccountMinimal;
    rules?: RulesDTO[]; // null rules will be treated as "not loaded". Empty rules will be treated as "loaded but empty".
    selectedRule: AccountRuleSelection; 
}
export type AccountRuleChange = {
    accountId: string;
    ruleId: string;
}

export type AccountRuleSelectorTableSort = {
    columnName: AccountRuleSelectorColumnNames;
    sortDirection: AccountRuleSelectorSortDirection;
}

// Only export the columns that can be sorted
// Did not take time to sort rule as it can be one of 3 values
export enum AccountRuleSelectorColumnNames {
    Account='account',
    Version='version',
    Activated='activated',
}

export enum AccountRuleSelectorSortDirection {
    Ascending='asc',
    Descending = 'desc'
}

@Component({
    selector: 'account-rule-selector-table',
    templateUrl: './account-rule-selector-table.component.html',
    styleUrls: ['./account-rule-selector-table.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class AccountRuleSelectorTable implements OnInit, OnChanges {
    @Input() accounts: AccountMinimal[] = [];
    @Input() selectedAccountRules: AccountRule[] = [];
    @Input() initialSort: AccountRuleSelectorTableSort;
    @Output() onAuditRuleChange = new EventEmitter<AccountRuleChange>;

    // Table Data
    displayedColumns = [AccountRuleSelectorColumnNames.Account, AccountRuleSelectorColumnNames.Version, AccountRuleSelectorColumnNames.Activated, 'rule'];
    tableRows: AccountRuleTableRow[] = [];
    tableRows_org: AccountRuleTableRow[] = [];
    currentSort: AccountRuleSelectorTableSort;

    constructor(
        private _rulesDataService: RulesDataService,
    ) { }

    //#region Helpers

    loadRules(tableRow: AccountRuleTableRow) {
        const accountId = tableRow.account.id;
        const clientId = tableRow.account.clientId;
        const hideWaiting = true;

        // DEV NOTE: RulesDataService will cache up to 4 accounts. This design might require loading more rules than that.
        // We might need to consider caching rules locally in this component based on user behavior/app design*.
        //
        // * We'll need to rebuild the table of rules whenever the accounts change. The accounts could change by changing the dates or other user behavior.
        //   Keep an eye on this page/component use and network calls to see about caching.
        this._rulesDataService.load(accountId, clientId, hideWaiting).subscribe(rules => {
            tableRow.rules = [];

            if (rules.length > 0) tableRow.rules = [Utils.rulesDTO_none, ...rules];

            const selectedRulesForAccount = this.selectedAccountRules.filter(x => x.accountId === accountId);
            selectedRulesForAccount.every((x) => { 
                tableRow.selectedRule.ruleId = x.ruleId;
                tableRow.selectedRule.disabled = true;
            });
        });

    }
   
    mapTableRows() {
        this.tableRows = this.accounts.map(((account): AccountRuleTableRow => {
            const row: AccountRuleTableRow = { account, selectedRule: { ruleId: '00000000-0000-0000-0000-000000000000', disabled: false }};
            this.loadRules(row);
            return row;
        }));
        this.tableRows_org = [...this.tableRows];
    }

    sortByColumn(table: AccountRuleTableRow[], columnName: string, ascending: boolean) {
        const sort = function(x:unknown, y:unknown, isAscending: boolean) {
            let sortResult = 0;
            if (isAscending) {
                sortResult = (x > y ? 1 : -1);
            } else {
                sortResult = (x < y ? 1 : -1);
            }
            return sortResult; 
        };
        const sortFactory = {
            account: function(x:AccountRuleTableRow, y:AccountRuleTableRow) {
                const xValue = x.account.name;
                const yValue = y.account.name;
                return sort(xValue, yValue, ascending);
            },
            version: function(x:AccountRuleTableRow, y:AccountRuleTableRow) {
                const xValue = `${x.account.versionLabel} - V${x.account.versionNumber}`;
                const yValue = `${y.account.versionLabel} - V${y.account.versionNumber}`;
                return sort(xValue, yValue, ascending);
            },
            activated: function(x:AccountRuleTableRow, y:AccountRuleTableRow) {
                const xValue = x.account.activationDate;
                const yValue = y.account.activationDate;
                return sort(xValue, yValue, ascending);
            }
        };

        const sortFunction = sortFactory[columnName];
        if (sortFunction === undefined) {
            console.warn(`No sort function is provided for column ${columnName}.`);
            return table;
        }

        return [...table.sort(sortFunction)];
    }

    //#endregion
    //#region Lifecycle

    ngOnInit(): void {
        console.log(this.initialSort);
        if (this.initialSort !== undefined) {
            this.currentSort = {
                columnName: this.initialSort.columnName,
                sortDirection: this.initialSort.sortDirection
            };
        } else {
            this.currentSort = {
                columnName: AccountRuleSelectorColumnNames.Activated,
                sortDirection: AccountRuleSelectorSortDirection.Descending
            };
        }

        this.sortByColumn(this.tableRows, this.currentSort.columnName, (this.currentSort.sortDirection === AccountRuleSelectorSortDirection.Ascending));

    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.accounts) {
            this.mapTableRows();
        }
    }

    //#endregion
    //#region Handlers

    handleRuleChange(change: MatSelectChange, row: AccountRuleTableRow) {
        const accountRuleChange: AccountRuleChange = {
            accountId: row.account.id,
            ruleId: change.value,
        };

        this.onAuditRuleChange.emit(accountRuleChange);
    }

    onSortChange(sortState: Sort) {

        if (sortState.direction === '') {
            this.tableRows = [...this.tableRows_org];
        } else {
            this.tableRows = this.sortByColumn(this.tableRows, sortState.active, sortState.direction == 'asc');
        }

    }

    //#endregion
}
