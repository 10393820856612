import { FilterableListService } from "@Services/config/filterable-list.service";
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FeatureToggle } from "app/enums";
import { FeatureManagerService } from "app/services";
import { Subscription } from "rxjs";
import { SubSink } from "subsink";
import { BaseFormFieldServices, BaseInterviewFormFieldComponent } from '../base-interview-form-field/base-interview-form-field.component';
import _ = require('lodash');

@Component({
    selector: 'multi-choice-multi-answer-v2',
    templateUrl: './multi-choice-multi-answer-v2.component.html',
    styleUrls: ['./multi-choice-multi-answer-v2.component.scss']
})
export class MultiChoiceMultiAnswerV2Component extends BaseInterviewFormFieldComponent implements OnInit, OnDestroy {
    acResult: any = [];
    indentReflexiveQuestions: boolean;
    reflexiveQuestions: any = [];
    reflexiveFormId: any = [];
    checkedList: any = [];
    inputTypeCheckbox = 'checkbox';
    constantList: any = [];
    disableSort = false;
    subs = new SubSink();
    showList = true;

    private filterableListUpdateSub: Subscription;

    constructor(public services: BaseFormFieldServices, private filterableListService: FilterableListService, private featureManagerService: FeatureManagerService) {
        super(services);
    }
    override constantListUpdatedLogic() {
        if (this.interviewMode && this.config['multiChoiceAnswers'][0]) {
            if(this.featureManagerService.getByName(FeatureToggle.CaseQuestionOnlyFilterListsWhenLoading).enabled){
                //Only filter the list if the active section is this question's section
                if(this.config.sectionsId === this.services.interviewService.getInterviewData().activeSectionId)
                    this.getListValuesToQuestion(this.config['multiChoiceAnswers'][0]['constantKey'], this.config);
            }else{
                // get option data from 'multichoiceanswers' property and assign it to list values
                this.getListValuesToQuestion(this.config['multiChoiceAnswers'][0]['constantKey'], this.config);
            }
        } else {
            this.setupConfigConstantValues();
        }
    }

    updatedListValue(data) {
        if (this.config.isReflexive) {
            let answerids = [];
            const defaultList = this.constantLists[this.config['multiChoiceAnswers'][0].constantKeyNavigation?.id];
            data.forEach(item => {

                const listHasItem = defaultList
                    ? defaultList.filter(l => item === l.value && !l.ruleId).map(x => x.id)
                    : this.constantList.filter(l => item === l.value && !l.ruleId).map(x => x.id);

                const constantList = this.constantLists[this.config['multiChoiceAnswers'][0].constantKeyNavigation.id];

                if (constantList && constantList.some(x => x.ruleId) && this.config.listValues.filter(l => item === l.value)) {
                    const filteredListHasItem = this.config.listValues.filter(l => item === l.value).map(x => x.id);
                    if (filteredListHasItem) {
                        answerids = _.concat(...answerids, ...filteredListHasItem);
                    }
                }
                
                if (listHasItem) {
                    answerids = _.concat(...answerids, ...listHasItem);
                }
            });
            // if (answerids) {
            this.showReflexiveQuestions(answerids);
            // }
        }
        this.onBlur();
        // on value change , update dependent questions, i.e questions dependent on the value of this question and also on the values of the reflexive questions present.
        this.updateDependentQuestions();
    }


    ngOnInit() {
        this.subs.add(this.filterableListService.filterableListUpdated$.subscribe((filteredList) => {
            if (this.filterableListService.filteredListOrConfigOrContantListHasRuleId(filteredList, this.constantList, this.config)) {
                this.disableSort = true;
                if (Object.keys(filteredList).length != 0 && this.config['multiChoiceAnswers'][0].constantKeyNavigation?.id) {
                    this.constantList = filteredList[this.config['multiChoiceAnswers'][0].constantKeyNavigation.id];
                }

                if (group.value && group.value.length > 0 && Array.isArray(group.value)) {
                    const values = [];
                    group.value.forEach(value => {
                        if (this.config?.AllowNewValues === "true") {
                            values.push(value);
                        } else if (this.constantList && this.constantList.map(x => x.value).includes(value)) {
                            values.push(value);
                        }
                    });
                    this.group.get(this.config.id).setValue(values);

                    if (this.config.DisplayType === 'checkbox') {
                        const checkedValues = this.config?.answerValue?.split(', ');
                        if (this.constantList && this.constantList.length) {
                            for (const c of this.constantList) {
                                if ((checkedValues && checkedValues.includes(c.value))) {
                                    c.checked = true;
                                    if (!this.checkedList.includes(c.value)) {
                                        this.checkedList.push(c.value);
                                    }
                                } else {

                                    c.checked = false;
                                }
                            }
                        }
                    }
                } else {
                    this.constantList.forEach(c => {
                        c.checked = false;
                    });

                    if (this.config.isReflexive) {
                        this.reflexiveQuestions = [];
                        this.showReflexiveQuestions('');
                    }

                    this.config.answerValue = "";
                }
                if (this.featureManagerService.getByName(FeatureToggle.GlobalVariantsResetJSONOnVariantFilter).enabled) {
                    this.services.interviewService.updateInterviewJson(null, null, true);
                }
            }

            this.showList = true;
        }));
        
        if (this.interviewMode === 'repeat') {
            this.reflexiveFormId = this.config.repeatFormId;
        } else {
            this.reflexiveFormId = this.config.id;
        }
        const group = this.group.get(this.config.id);
        let values = [];
        if (group.value && group.value.length > 0) {

            const valuesNonMapped = group.value.split(',');
            values = valuesNonMapped.map((x: string) => x.trim());
            this.group.get(this.config.id).setValue(values);
        } else {
            this.group.get(this.config.id).setValue([]);
        }
        if (this.interviewMode && this.config['multiChoiceAnswers'][0]) {
            // get option data from 'multichoiceanswers' property and assign it to list values
            this.getListValuesToQuestion(this.config['multiChoiceAnswers'][0]['constantKey'], this.config);


            this.indentReflexiveQuestions = this.config['multiChoiceAnswers'][0].indentReflexiveQuestions;
        } else {
            this.setupConfigConstantValues();
        }
        this.updatedListValue(values);
        super.initialize();
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
        super.ngOnDestroy();
    }

    setupConfigConstantValues() {
        if (this.config['multiChoiceAnswers'][0] &&
            this.config['multiChoiceAnswers'][0].constantKey) {
            this.constantList = this.constantLists[this.config['multiChoiceAnswers'][0].constantKey];
        }
    }

    // this method get options for the question and if the field is already answered, selects all the answered values.
    getListValuesToQuestion(mapKeyId, question) {
        if (this.config['multiChoiceAnswers'][0] &&
            this.config['multiChoiceAnswers'][0].constantKeyNavigation) {
            if (this.constantLists ) {
                this.constantList = this.constantLists[this.config['multiChoiceAnswers'][0].constantKeyNavigation.id];
                
                if (this.constantList && this.constantList.filter(x=> x.ruleId).length > 0){
                    this.filterableListService.updateFilterableListAsync(this.constantLists, this.interviewMode);

                    if (!this.isCaseSummary())
                        this.showList = false;
                }
            }

            if (this.config.DisplayType === 'checkbox') {
                const checkedValues = this.config?.answerValue?.split(', ');
                if (this.constantList && this.constantList.length) {
                    for (const c of this.constantList) {
                        if (checkedValues && checkedValues.includes(c.value)) {
                            c.checked = true;
                            if (!this.checkedList.includes(c.value)) {
                                this.checkedList.push(c.value);
                            }
                        } else {
                            c.checked = false;
                        }
                    }
                }
            }

            if (this.config['multiChoiceAnswers'][0].constantKeyNavigation.constantMapKeyValues) {
                this.config['listValues'] = this.config['multiChoiceAnswers'][0].constantKeyNavigation.constantMapKeyValues;
            }
        }
    }

    // if display type is checkbox, then on change of checkbox for each option, it is either removed or added to answer value based on if it's checked
    onCheckboxChange(option, event) {
        if (event.checked) {
            this.checkedList.push(option.value);
        } else {
            for (let i = 0; i < this.constantList.length; i++) {
                if (this.checkedList[i] === option.value) {
                    this.checkedList.splice(i, 1);
                }
            }
        }
        this.group.get(this.config.id).setValue(this.checkedList);

        super.onBlur();
        this.updatedListValue(this.checkedList);
    }
    onBlur() {
        if (this.isLiveMode()) {
            const fieldControl = this.group.get(this.config.id);
            if (fieldControl && fieldControl.dirty && fieldControl.valid) {
                if (this.featureManagerService.getByName(FeatureToggle.GlobalFilterableStaticListMCMAOnBlur).enabled) {
                    const cleanedValue = fieldControl.value.join(', ');
                    this.services.interviewService.setQuestionAnswer(this.config, cleanedValue);
                } else {
                    this.services.interviewService.setQuestionAnswer(this.config, fieldControl.value);
                }
                super.onBlur();
            }
        } else {
            super.onBlur();
        }

    }

    acSearch(event) {
        const query = event.query;
        this.acResult = this.config.listValues.filter(item => {
            return (item.displayName.toLowerCase().includes(query.toLowerCase()));
        });
    }

    showReflexiveQuestions(questionIds) {
        this.config.removingDependentQuestions = [];
        this.config.newDependentQuestions = [];
        if (questionIds && (this.isLiveMode() || this.isCaseSummary())) {
            let listMapKeyValues = [];
            questionIds.forEach(questionId => {
                const values = this.config.listValues.filter(l => questionId === l.id);
                if (values) {
                    listMapKeyValues = _.concat(...listMapKeyValues, ...values);
                }
            });
            const currentSection = this.services.interviewService.getActiveSection();

            // first remove all the existing dependent questions
            if (this.reflexiveQuestions && this.reflexiveQuestions.length) {
                this.reflexiveQuestions.forEach(reflexiveQuestion => {
                    if (reflexiveQuestion.answerType === 'RepeatBlock' && reflexiveQuestion.blocks && reflexiveQuestion.blocks.length) {
                        this.services.interviewService.removeRepeatBlocks(reflexiveQuestion);
                        reflexiveQuestion.blocks.forEach(block => {
                            if (this.services.interviewService.repeatForms[block.repeatId]) {
                                delete this.services.interviewService.repeatForms[block.repeatId];
                            }
                        });
                        reflexiveQuestion.blocks = null;
                    }
                });

                if (this.config.dependentQuestions && this.config.dependentQuestions.length > 0) {
                    for (const eachReflexiveQuestion of this.reflexiveQuestions) {
                        if (eachReflexiveQuestion.dependentQuestions && eachReflexiveQuestion.dependentQuestions.length > 0) {
                            for (const eachDepdentQuestion of eachReflexiveQuestion.dependentQuestions) {
                                if (this.config.dependentQuestions.includes(eachDepdentQuestion)) {
                                    this.config.removingDependentQuestions = this.config.removingDependentQuestions ? this.config.removingDependentQuestions : [];
                                    // let dependentQuestionIndex = this.config.dependentQuestions.lastIndexOf(eachDepdentQuestion);
                                    this.config.removingDependentQuestions.push(eachDepdentQuestion);
                                }
                            }
                        }
                    }
                }
            }

            // remove reflexive questions from repeat block form
            this.reflexiveQuestions.forEach(reflexiveQuestion => {
                if (reflexiveQuestion.repeatFormId) {
                    const repeatForm = this.services.interviewService.repeatForms[reflexiveQuestion.repeatFormId];
                    if (repeatForm) {
                        repeatForm.removeControl(reflexiveQuestion.id);
                    }
                }
            });

            // reassign reflexiveQuestions property to empty array, we will add new questions to this array based on the value changed.
            this.reflexiveQuestions = [];
            if (this.services.interviewService.reflexiveForms[this.reflexiveFormId]) {
                // delete the existing reflexive form reference from interviewservice. It shall be added if any new questions will get added on value changed.
                delete this.services.interviewService.reflexiveForms[this.reflexiveFormId];

            }
            const currentRefQuestions = [];
            if (listMapKeyValues) {
                for (const listMapkeyValue of listMapKeyValues) {
                    const mapKeyReflexives = this.config.reflexiveQuestions.filter(function (x) { return listMapkeyValue.id === x.answerValueId; });

                    for (const eachCurrentRefQuestion of mapKeyReflexives) {
                        currentRefQuestions.push(eachCurrentRefQuestion.refQuestionId);
                    }
                }
            }
            // update display and value for the reflexive questions (display property for each question is the one used to show/hide it in form)
            for (const eachRefQuestion of this.config.reflexiveQuestions) {
                if (!currentRefQuestions.includes(eachRefQuestion.refQuestionId)) {
                    this.hideNestedReflexives(currentSection, eachRefQuestion);
                }
            }
            // time out is required as if there are no changes in reflexiveQuestions array, angular doesnt create a new form (so there is no form reference in interviewService as it got deleted in our previous step, hence form values don't update)
            setTimeout(() => {
                if (this.config.listValues && this.config.listValues.length > 0) {

                    if (listMapKeyValues) {
                        for (const listMapkeyValue of listMapKeyValues) {
                            for (const eachQuestion of this.config.reflexiveQuestions) {

                                if (listMapkeyValue.id === eachQuestion.answerValueId) {
                                    let newRefQuestion;
                                    if (this.config.repeatFormId) {
                                        const repeatFormId = this.config.repeatFormId;
                                        newRefQuestion = currentSection.questions.find(q => q.id === eachQuestion.refQuestionId && q.repeatFormId === repeatFormId);
                                        if (!newRefQuestion) {
                                            const displayTypeQuestion = currentSection.questions.find(q => q.id === eachQuestion.refQuestionId && q.answerType === 'DisplayMessage');
                                            if (displayTypeQuestion) {
                                                newRefQuestion = displayTypeQuestion;
                                            }
                                        }
                                    } else {
                                        newRefQuestion = currentSection.questions.find(q => q.id === eachQuestion.refQuestionId);
                                    }

                                    if (newRefQuestion) {
                                        // validate action results to see if the field in the current form should be hidden because of action results.
                                        newRefQuestion.display = this.validateActionResults(newRefQuestion);
                                        if (!this.reflexiveQuestions.includes(newRefQuestion)) {

                                            this.reflexiveQuestions.push(newRefQuestion);

                                            // add dependent questions for the reflexive inner questions to the main question..so when value of main question is changed their preactions will get called.
                                            if (newRefQuestion.dependentQuestions && newRefQuestion.dependentQuestions.length > 0) {
                                                this.config.dependentQuestions = this.config.dependentQuestions ? this.config.dependentQuestions : [];
                                                for (const eachDepdentQuestion of newRefQuestion.dependentQuestions) {

                                                    if (!this.config.dependentQuestions.includes(eachDepdentQuestion)) {
                                                        this.config.newDependentQuestions = this.config.newDependentQuestions ? this.config.newDependentQuestions : [];
                                                        this.config.newDependentQuestions.push(eachDepdentQuestion);
                                                    }
                                                }

                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    this.updateDependentQuestions();
                }
                if (this.reflexiveQuestions.length === 0) {
                    delete this.services.interviewService.reflexiveForms[this.reflexiveFormId];
                }
            }, 200);
        }
    }

    validateActionResults(question) {
        let questionDisplay = true;

        if (question.actionResult && question.actionResult.length) {
            const allActionResults = question.actionResult;
            for (const eachActionResult of allActionResults) {
                if (eachActionResult.actionResults && eachActionResult.actionResults.data && (eachActionResult.actionResults.data.display === 'false' || eachActionResult.actionResults.data.display === false)) {
                    questionDisplay = false;
                }
            }

            return questionDisplay;
        }

        if (question.questionActions && question.questionActions.length) {
            const actions = question.questionActions;
            for (const action of actions) {
                if (action.actionResults && action.actionResults.data && (action.actionResults.data.display === 'false' || action.actionResults.data.display === false)) {
                    questionDisplay = false;
                }
            }
        }

        return questionDisplay;
    }

    updateValueOnButtonClick(value) {
        this.group.get(this.config.id).markAsDirty();
        this.group.get(this.config.id).setValue(value);
    }

    hideNestedReflexives(currentSection, reflexiveQuestion) {
        let newRefQuestion;
        if (this.config.repeatFormId) {
            const repeatFormId = this.config.repeatFormId;
            newRefQuestion = currentSection.questions.find(q => q.id === reflexiveQuestion.refQuestionId && q.repeatFormId === repeatFormId);
        } else {
            newRefQuestion = currentSection.questions.find(q => q.id === reflexiveQuestion.refQuestionId);
        }
        if (newRefQuestion) {
            newRefQuestion.display = false;
            this.services.interviewService.setQuestionAnswer(newRefQuestion, '');
            if (newRefQuestion.reflexiveQuestions && newRefQuestion.reflexiveQuestions.length > 0) {
                newRefQuestion.reflexiveQuestions.forEach(element => {
                    this.hideNestedReflexives(currentSection, element);
                });
            }
        }
    }
    updateDependentQuestions() {
        if (this.config.removingDependentQuestions) {

            for (const eachRemovingQuestion of this.config.removingDependentQuestions) {
                const dependentQuestionIndex = this.config.dependentQuestions.lastIndexOf(eachRemovingQuestion);
                if (dependentQuestionIndex > -1)
                    this.config.dependentQuestions.splice(dependentQuestionIndex, 1);
            }
        }
        if (this.config.newDependentQuestions) {
            for (const eachNewQuestion of this.config.newDependentQuestions) {
                this.config.dependentQuestions.push(eachNewQuestion);
            }
        }
    }
}
