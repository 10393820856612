import { RulesDTO } from '@DTOs';

export function isNullOrUndefined(obj: any) {
    if (obj === null) return true;
    if (obj === undefined) return true;
    return false;
}

export function isNullOrWhitespace(value: string) {
    if (isNullOrUndefined(value)) return true;
    if (`${value}`.trim() === '') return true;
    return false;
}

export function isDesktop() {
    return window.innerWidth > 1650;
}

export const emptyGuid = '00000000-0000-0000-0000-000000000000';

export function noop() {
    // do nothing
}

export function isNullOrEmptyGuid(guid?: string) {
    if (!guid) return true; // null, undefined or ''
    if (guid == emptyGuid) return true;
    return false;
}

export function cloneMap<K, V>(originalMap: Map<K, V>): Map<K, V> {
    const clonedMap = new Map<K, V>();

    // Iterate over the entries of the original map and add them to the cloned map
    for (const [key, value] of originalMap.entries()) {
        clonedMap.set(key, value);
    }

    return clonedMap;
}

export function clone<T>(item: T) {
    return JSON.parse(JSON.stringify(item)) as T;
}

/**
 * Parse a JSON string to the object type.  Return "defaultValue" if JSON parse throws an exception (ie. invalid JSON).
 * 
 * **NOTE**  Providing a `null` string is not considered an exception and will return `null` because null IS VALID JSON!
 * @param input 
 * @param defaultValue 
 * @returns 
 */
export function parseJSONOrDefault<T>(input: string, defaultValue: T = null): T {
    try {
        return JSON.parse(input);
    } catch (e) {
        return defaultValue;
    }
}

/**
 * We often use a "None" placeholder option for a Rule dropdown (select).
 * This is an object is so we don't have to keep remaking this placeholder object.
 */
export const rulesDTO_none: RulesDTO = {
    id: emptyGuid,
    filterId: emptyGuid,
    name: 'None',
    accountId: emptyGuid,
    jsonTestResponse: null
};