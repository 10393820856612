<div class="col-5 d-flex flex-column accounts-container">
    <div class="py-4 px-5">
        <div class="d-flex mt-2">
            <div>
                <h5>Account Details</h5>
            </div>
            <div style="margin-left: auto; margin-top: -7px">
                <mrs-btn-primary 
                    *ngIf="!isNewAccount && !editForm && checkACL('W')"
                    buttonLabel="Edit" 
                    buttonId="editButton" 
                    (onClick)="editAccount()">
                </mrs-btn-primary>
                <div *ngIf="editForm">                    
                    <mrs-btn-tertiary
                        *ngIf="!isNewAccount &&accountInfo.code"
                        buttonLabel="Cancel" 
                        buttonId="cancelButton" 
                        (onClick)="editForm=false">
                    </mrs-btn-tertiary>
                    <mrs-btn-primary 
                        *ngIf="checkACL('W')"
                        buttonLabel="Save" 
                        buttonId="saveButton" 
                        (onClick)="saveAccount()">
                    </mrs-btn-primary>
                </div>
            </div>
        </div>
        <div class="flex-fill overflow-y-auto custom-scrollbar pr-5">
            <div *ngIf="isNewAccount || editForm">
                <form [formGroup]="accountForm" novalidate autocomplete="off">
                    <mat-form-field class="full-width mb-1" *ngIf="!isNewAccount && accountInfo.code">
                        <mat-label>Account Code</mat-label>
                        <input matInput formControlName="code">
                    </mat-form-field>
                    <mat-form-field class="full-width mb-1">
                        <mat-label>Account Name</mat-label>
                        <input matInput formControlName="name">
                        <mat-error *ngIf="accountForm.get('name').hasError('required')">
                            Account name
                            <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                    <input type="hidden" formControlName="statusId" value="00000000-0000-0000-0000-000000000001">
                    <mat-form-field class="full-width mb-1">
                        <mat-label>Preferred Name for Certificate/Policy</mat-label>
                        <mat-select formControlName="preferredName">
                            <mat-option *ngFor="let type of preferredName" [value]="type.value">
                                {{ type.value }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="full-width mb-1">
                        <mat-label>Seed Value for Certificate/Policy #</mat-label>
                        <input matInput formControlName="seedPolicyNumber">
                    </mat-form-field>
                    <mat-form-field class="full-width mb-1">
                        <mat-label>Certificate/Policy # Format</mat-label>
                        <input matInput formControlName="policyNumberPrefix">
                    </mat-form-field>
                </form>
            </div>
            <div *ngIf="!isNewAccount && !editForm">
                <div class="label-title">Account Code </div>
                <div class="label-content">{{accountInfo.code}}</div>
                <div class="label-title">Account Name </div>
                <div class="label-content">{{accountInfo.name}}</div>
                <div class="label-title">Client Name </div>
                <div class="label-content">{{accountInfo.clientName}}</div>
                <div class="label-title">Preferred Name for Certificate/Policy </div>
                <div class="label-content">{{accountInfo.preferredName}}</div>
                <div class="label-title">Seed Value for Certificate/Policy # </div>
                <div class="label-content">{{accountInfo.seedPolicyNumber}}</div>
                <div class="label-title">Certificate/Policy # Format</div>
                <div class="label-content">{{accountInfo.policyNumberPrefix}}</div>
            </div>
        </div>
    </div>
</div>
<div class="col-7 flex-fill bg-white d-flex flex-column">
    <div class="flex-fill overflow-y-auto custom-scrollbar py-4 px-5" *ngIf="!isNewAccount">
        <div class="d-flex mt-2">
            <div>
                <h5>Contacts Details</h5>
            </div>
            <div style="margin-left: auto; margin-top: -7px">
                <mrs-btn-tertiary *ngIf="!showContactForm && checkACL('W')"  
                    buttonLabel="Add Contact" 
                    buttonId="addContactButton" 
                    (onClick)="addAccountContact()"
                    iconName="add"></mrs-btn-tertiary>
            </div>
        </div>

        <div class="p-4 text-center text-muted" *ngIf="!accountContacts?.length && !showContactForm">
            <img src="./assets/images/no-data-placeholder.png" alt="" class="pb-3" />
            <h6>No contacts were added yet. Please add contacts for the client.</h6>
        </div>

        <div *ngIf="showContactForm" class="mb-3">
            <mat-card appearance="outlined">
                <mat-card-content>
                    <form [formGroup]="addContactForm" (ngSubmit)="addContact()" novalidate>
                        <div class="row">
                            <div class="col-6">
                                <mat-form-field class="w-100">
                                    <mat-label>Contacts</mat-label>
                                    <input matInput [matAutocomplete]="auto" formControlName="contactId">
                                    <mat-autocomplete #auto="matAutocomplete">
                                        <mat-option *ngFor="let contact of contactsCombo" [value]="contact.name">
                                            {{ contact.name }}
                                        </mat-option>
                                    </mat-autocomplete>
                                    <mat-error *ngIf="addContactForm.get('contactId').hasError('required')">
                                        Contacts
                                        <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-6">
                                <mat-form-field class="w-100">
                                    <mat-label>Contact Type</mat-label>
                                    <mat-select formControlName="contactTypeId">
                                        <mat-option *ngFor="let type of contactTypeCombo" [value]="type.id">
                                            {{ type.name }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="addContactForm.get('contactTypeId').hasError('required')">
                                        Contact Type
                                        <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-12 text-right mt-3">
                            <button *ngIf="checkACL('W')" mat-raised-button color="primary" type="submit">
                                <i aria-hidden="true" class="fa fa-plus-circle"></i> Save
                            </button>
                            <button mat-button color="basic" *ngIf="showContactForm" (click)='showContactForm = false'>
                                <i aria-hidden="true" class="fa fa-times"></i> Cancel
                            </button>
                        </div>
                    </form>
                </mat-card-content>
            </mat-card>
        </div>
        <contacts [data]='contact.contacts' (delete)="deleteContact($event)" [type]="'contacts'" *ngFor="let contact of accountContacts"></contacts>
    </div>
</div>
