<h1 mat-dialog-title>Configure Acord</h1>
  <div mat-dialog-content style="overflow-y: scroll !important;overflow-x:none !important; min-height: 80vh !important;">
  <div class="subtext">Used to send case information to an API. Contact API owner for required webhook information</div>
  <div [formGroup]="accordForm">
    <mat-form-field class="w-100">
      <input id="url" placeholder="Url" matInput formControlName='url'>
      <mat-error *ngIf="accordForm.get('url').hasError('required')">required</mat-error>
    </mat-form-field>
    <mat-form-field class="w-100">
      <mat-select id="authType" placeholder="Auth Type" formControlName="authType">
        <mat-option></mat-option>
        <mat-option value="HMAC">HMAC</mat-option>
        <mat-option value="OAuth2">OAuth2</mat-option>
      </mat-select>
    </mat-form-field>
    <div *ngIf="accordForm.get('authType').value && accordForm.get('authType').value === 'HMAC'">
      <div formGroupName="hmacRequest" novalidate autocomplete="off">
        <mat-form-field class="w-100">
          <mat-label>Username</mat-label>
          <input id="userName" matInput formControlName='userName'>
        </mat-form-field>
        <auto-suggest *ngIf="secretKeys" [list]="secretKeys" placeholder="Password" field="name" dataKey="id"
          formControlName="secret" >
        </auto-suggest>
      </div>
    </div>
    <div *ngIf="accordForm.get('authType').value && accordForm.get('authType').value === 'OAuth2'">
      <div formGroupName="oAuth2Request" novalidate autocomplete="off">
        <mat-form-field class="w-100">
          <mat-label>Grant Type</mat-label>
          <mat-select id="grantType" formControlName="grantType">
            <mat-option></mat-option>
            <mat-option value="client_credentials">Client Credentials</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="w-100">
          <mat-label>Access Token URL</mat-label>
          <input id="tokenEndpoint" matInput formControlName='tokenEndpoint'>
        </mat-form-field>
        <mat-form-field class="w-100">
          <mat-label>Client ID</mat-label>
          <input id="clientId" matInput formControlName='clientId'>
        </mat-form-field>
        <auto-suggest *ngIf="secretKeys" [list]="secretKeys" placeholder="Password" field="name" dataKey="id"
          formControlName="clientSecret" >
        </auto-suggest>
        <mat-form-field class="w-100">
          <mat-label>Scope</mat-label>
          <input id="scope" matInput formControlName='scope'>
        </mat-form-field>
      </div>
    </div>
    <div class="flex flex-row">
      <mat-form-field class="mr-5">
        <mat-label>Max # of Attempts</mat-label>
        <input id="retryAttempts" type="number" matInput formControlName="retryAttempts"/>
      </mat-form-field>
      <mat-form-field class="mr-5">
        <mat-label>Expiration (Hours)</mat-label>
        <input id="expiration" type="number" matInput formControlName="expirationHours"/>
      </mat-form-field>
      <div style="display: flex; align-items:center;">
        <mat-slide-toggle id="errorCase" formControlName="errorCaseOnFailure">Failures Result in Case Error
        </mat-slide-toggle>
      </div>
    </div>
    <mat-form-field class="w-100 textarea-formfield">
      <mat-label>Content</mat-label>
      <textarea id="content" matInput formControlName='content' rows="10"></textarea>
      <mat-error *ngIf="formSubmitted && accordForm.get('content').hasError('required')">
        Content
        <strong>required</strong>
      </mat-error>
    </mat-form-field>
    <div class="bold-label-18">Headers</div>

    
    <ng-control formArrayName="headers" >
      <div *ngFor="let header of headers.controls; let i = index; let odd = odd"
        [class.odd]="odd">
        <div class="row" [formGroupName]="i">
          <div class="col-md">
            <mat-form-field class="w-100">
              <mat-label>Name</mat-label>
              <input id="{{'mapKey_Id' + i}}" matInput formControlName="key">
            </mat-form-field>
          </div>
          <div class="col-md">
            <mat-form-field class="w-100">
              <mat-label>Value</mat-label>
              <input id="{{'metadata_Id' + i}}" matInput formControlName="value">
            </mat-form-field>
          </div>
          <div class="col-md-1">
            <div *ngIf="i !== 0" (click)="removeHeader(i)" style="cursor: pointer;">
              <mat-icon svgIcon="delete" style="height:1.25rem;width:1.25rem;margin-top:1rem;"></mat-icon>
            </div>
          </div>
        </div>
      </div>
    </ng-control>

    <div class="row">
      <button type="button" class="btn btn-link btn-sm float-right custom" (click)="addHeader ()">
        <i aria-hidden="true" class="fa fa-plus"></i> ADD HEADER
      </button>
    </div>
  </div>
</div>

<div style="padding-right:20px">
  <mat-dialog-actions align="end">
    <mrs-btn-tertiary buttonLabel="Cancel" buttonId="cancelButton" mat-dialog-close>Cancel</mrs-btn-tertiary>
    <mrs-btn-primary buttonId="okButton" buttonLabel="Continue" (onClick)="saveAccountMessage()">Continue</mrs-btn-primary>
  </mat-dialog-actions>
</div>