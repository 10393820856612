import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';
import * as _ from 'lodash';
import { AppService } from '../../app.service';
import { Account, Client } from '../../models';
import { FeatureToggleService, RoutesEnum, RoutingService } from '../../services';

@Component({
    selector: 'app-clients',
    host: { 'class': 'content' },
    templateUrl: './client-details.component.html',
    styleUrls: ['./client-details.component.scss']
})
export class ClientDetailsComponent implements OnInit {

    client: Client;
    isClientEditable = false;
    clientDetails: any;
    newContact: any;

    menu: any = [];
    activeMenu: any = '';
    newClientForm: UntypedFormGroup;
    accounts: any = [];
    accountsType: any;

    contacts: any = [];
    contactForm: any;
    clientname: string;
    showContactForm = false;
    showClientsDetails = false;
    checked = false;
    public companyLogo: any = this.appService.getDefaultLogo();
    showClientDetails = false;

    constructor(
        private route: ActivatedRoute,
        private appService: AppService,
        private routingService: RoutingService,
        private featureToggleService: FeatureToggleService
    ) { }

    async ngOnInit() {

        $('#sidenav').removeClass('hidenav');

        this.route.data.subscribe(data => {
            this.client = data.client;
        });

        this.checkACL('Clients', 'R', RoutesEnum.default);

        const phoneRegex = /^(1[- .]?)?(\([2-9]\d{2}\)[. -]?|[2-9]\d{2}[. -]?)[2-9]\d{2}[- .]?\d{4}$/;

        this.newClientForm = new UntypedFormGroup({
            id: new UntypedFormControl(),
            name: new UntypedFormControl('', [Validators.required]),
            status: new UntypedFormControl(''),
            logo: new UntypedFormControl('')
        });

        this.contactForm = new UntypedFormGroup({
            firstName: new UntypedFormControl('', [Validators.required]),
            lastName: new UntypedFormControl('', [Validators.required]),
            phone: new UntypedFormControl('', Validators.compose([Validators.required, Validators.pattern(phoneRegex)])),
            email: new UntypedFormControl('', Validators.compose([Validators.required, Validators.pattern('[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})')])),
            isPrimary: new UntypedFormControl(),
        });

        if (this.client) {
            this.getClientDetails(this.client.id);
            this.getAccounts(this.client.id);
            this.getContacts(this.client.id);
        } else {
            this.showClientDetails = true;
        }

    }

    checkACL(feature, permissionType, redirect?) {
        return this.appService.checkACL(feature, permissionType, redirect);
    }

    back() {
        this.routingService.navigateToRoute(RoutesEnum.clients);
    }

    isActive(selected) {
        return this.activeMenu == selected;
    }

    getClientDetails(clientId) {
        this.appService.getData('Clients/' + clientId).subscribe(
            data => {
                this.clientDetails = data.data;
                this.showClientDetails = true;
            }
        );
    }
    getContacts(clientId) {
        this.appService.getData('Contacts/' + clientId + '/Client').subscribe(
            data => {
                this.newContact = undefined;
                this.contacts = data.data;
                const response = _.sortBy(this.contacts, ['isPrimary']).reverse();
                this.contacts = response;
            }
        );
    }
    getAccounts(clientId) {
        this.appService.getData("Accounts/" + clientId + "/Client").subscribe(
            data => {
                this.accounts = data.data;
            }
        );
    }
    uploadImage() {
        const image = document.getElementById('file');
        if (image['files'].length > 0) {
            // TECH DEBT: This regex is used in many spots.  DRY it and move into a REGEX utility file.
            const ext = image['files'][0].name.match(/\.([^.]+)$/)[1];
            if (ext == 'jpg' || ext == 'png' || ext == 'jpeg') {
                this.getBase64Image(image['files'][0]);
            } else {
                this.appService.showMsg("error", 'Please upload only Images');
            }
        }
    }

    getBase64Image(file) {
        const me = this;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            const image = new Image();
            image.src = reader.result as string;
            image.onload = function () {
                const height = this['height'];
                const width = this['width'];
                if ((height >= 150 && width >= 150) && (height <= 500 && width <= 500)) {
                    if (height == width) {
                        me.companyLogo = reader.result;
                    } else {
                        me.appService.showMsg("error", 'Please upload images with same height and width with minimum 150px and maximium 500px dimensions.');
                    }
                    return true;
                } else {
                    me.appService.showMsg("error", 'Please upload images with same height and width with minimum 150px and maximium 500px dimensions.');
                    return false;
                }
            };
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    saveClient() {
        let isEdit = false;
        this.newClientForm.value.logo = this.companyLogo;
        if (!this.clientDetails) {
            delete this.newClientForm.value.id;
        } else {
            isEdit = true;
        }

        if (this.newClientForm.valid) {
            this.appService.postData("Clients", this.newClientForm.value, isEdit).subscribe(
                data => {
                    if (data.status == "success") {
                        this.appService.showMsg("success", "Saved successfully ...");
                        this.clientDetails = data.data;
                        this.isClientEditable = false;
                    } else {
                        this.appService.showMsg("error", data.message);
                    }
                }
            );
        }
    }

    editClient() {
        this.newClientForm.patchValue(this.clientDetails);
        this.isClientEditable = true;
    }

    cancel() {
        if (!this.client) {
            this.routingService.navigateToRoute(RoutesEnum.clients);
        } else {
            this.isClientEditable = false;
        }
    }

    addAccount() {
        this.routingService.navigateToRoute(RoutesEnum.accountCreation, {
            clientCode: this.client.code
        });
    }

    viewAccount(account: Account) {
        this.routingService.navigateToRoute(RoutesEnum.accountDetails, {
            clientCode: this.client.code,
            accountCode: account.code,
            versionNumber: account.versionNumber
        });
    }

    addContact() {
        this.showContactForm = true;
        this.newContact = {
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
            clientId: this.clientDetails.id,
            isPrimary: false,
        };
    }

}