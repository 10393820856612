<div *ngIf="totalPages > 0" class="actions">
    <nav>
        <div>
            Page
            <input type="number"
                id="current-page"
                [value]="currentPage"
                min="1"
                [max]="totalPages"
                [disabled]="isLoading"
                (change)="handlePageChange($event.target.value)" />
            of {{ totalPages }}
        </div>
        <div>
            <button mat-icon-button matTooltip="First Page" aria-label="First Page" [disabled]="currentPageIndex == 0 || isLoading" (click)="handlePageChange(1)"><mat-icon>first_page</mat-icon></button>
        </div>
        <div>
            <button mat-icon-button matTooltip="Preview Page" aria-label="Previous Page" [disabled]="currentPageIndex == 0 || isLoading" (click)="handlePageChange(currentPage - 1)"><mat-icon>chevron_left</mat-icon></button>
        </div>
        <div>
            <button mat-icon-button matTooltip="Next Page" aria-label="Next Page" [disabled]="currentPageIndex == (totalPages - 1) || isLoading" (click)="handlePageChange(currentPage + 1)"><mat-icon>chevron_right</mat-icon></button>
        </div>
        <div>
            <button mat-icon-button matTooltip="Last Page" aria-label="Last Page" [disabled]="currentPageIndex == (totalPages - 1) || isLoading" (click)="handlePageChange(totalPages)"><mat-icon>last_page</mat-icon></button>
        </div>
    </nav>
</div>
<div class="page">
    <div *ngIf="isLoading" class="page-loading">
        <mat-spinner class="spinner" mode="indeterminate" diameter="100"></mat-spinner>
    </div>
    <ng-container *ngIf="!isLoading">
        <img [src]="src" />
    </ng-container>
</div>