<div class="page-heading" id="subheading">
    <span class="page-heading-title">User Roles</span>
    <mrs-btn-icon class="float-right" buttonLabel="Add Role" buttonId="addButton" (onClick)="addRole()"
        *ngIf="!showPermissions &&  checkACL('W')" iconName="add"></mrs-btn-icon>
</div>

<div id="innercontent" class="custom-scrollbar">
    <div *ngIf="!showPermissions">
        <div class="container pt-3 pb-5">
            <div class="row" *ngIf="rolesList.length">
                <div class="col-3 mt-4" *ngFor="let role of rolesList">
                    <mat-card appearance="outlined" class="item" (click)="editRole($event, role)">
                        <mat-card-title>
                            <div class="item-title py-4">{{role.roleName}}</div>
                        </mat-card-title>
                    </mat-card>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="showPermissions">
        <form novalidate [formGroup]="form" (ngSubmit)="saveRole()" class="container-fluid p-4">
            <div class="row">
                <div class="offset-1 col-10">
                    <mat-card appearance="outlined">
                        <mat-card-header>
                            <mat-card-title>
                                <h2>Role Details</h2>
                            </mat-card-title>
                        </mat-card-header>
                        <mat-card-content>
                            <div class="row">
                                <div class="col-3">
                                    <mat-form-field class="w-100">
                                        <input matInput type="text" placeholder="Role Name" formControlName="roleName"
                                            autocomplete="off">
                                        <mat-error *ngIf="form.get('roleName').hasError('required')">
                                            Role Name is
                                            <strong>required</strong>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-9" *ngIf="role && role.roleName !=='' ">
                                    <button class="btn btn-danger float-right" *ngIf="checkACL('W')" color="warn"
                                        type="button" (click)="showDeleteDialog(deleteRoleDialog)">
                                        <i aria-hidden="true" class="fa fa-trash-alt"></i> Delete Role
                                    </button>
                                </div>
                            </div>

                            <div class="form-group row" *ngIf="authPermissions.length || (role.ID && showPermissions)">
                                <div class="col-7 mt-3">
                                    <div class="table-title">Data Access</div>
                                    <table class="permissions">
                                        <tr>
                                            <th>Role Access</th>
                                            <th>Permission</th>
                                            <th>None</th>
                                            <th>Read</th>
                                            <th>Write</th>
                                            <th>Full</th>
                                        </tr>
                                        <!-- ngFor let-contact [ngForOf]="contacts | async" -->
                                        <ng-template ngFor let-authPermission [ngForOf]="authPermissions">
                                            <tr *ngIf="authPermission.isDataAccess">
                                                <td>{{getAuthPermissionLabel(authPermission.id)}}</td>
                                                <td class="{{getAccessTypeValue(authPermission.id)}}">
                                                    {{getAccessTypeValue(authPermission.id)}}</td>
                                                <ng-template ngFor let-apt [ngForOf]="authPermissionTypes">
                                                    <td *ngIf="apt.authPermissionType != 'VIEW'">
                                                        <input [disabled]="!checkACL('W')" type="radio"
                                                            name="apt{{authPermission.id}}" value="{{apt.id}}"
                                                            [(ngModel)]="role.userRolePermissions[getRolePermissionIndex(authPermission.id)].authPermissionsTypeId"
                                                            [ngModelOptions]="{standalone: true}">
                                                    </td>
                                                </ng-template>
                                            </tr>
                                        </ng-template>
                                    </table>
                                </div>

                                <div class="col-5 mt-3">
                                    <div class="table-title">Views</div>
                                    <table class="permissions">
                                        <tr>
                                            <th>Component</th>
                                            <th>Permission</th>
                                            <th>None</th>
                                            <th>View</th>
                                        </tr>
                                        <!-- ngFor let-contact [ngForOf]="contacts | async" -->
                                        <ng-template ngFor let-authPermission [ngForOf]="authPermissions">
                                            <tr *ngIf="!authPermission.isDataAccess">
                                                <td>{{getAuthPermissionLabel(authPermission.id)}}</td>
                                                <td class="{{getAccessTypeValue(authPermission.id)}}">
                                                    {{getAccessTypeValue(authPermission.id)}}</td>
                                                <ng-template ngFor let-apt [ngForOf]="authPermissionTypes">
                                                    <td
                                                        *ngIf="apt.authPermissionType == 'NONE' || apt.authPermissionType == 'VIEW'">
                                                        <input [disabled]="!checkACL('W')" type="radio"
                                                            name="apt{{authPermission.id}}" value="{{apt.id}}"
                                                            [(ngModel)]="role.userRolePermissions[getRolePermissionIndex(authPermission.id)].authPermissionsTypeId"
                                                            [ngModelOptions]="{standalone: true}">
                                                    </td>
                                                </ng-template>
                                            </tr>
                                        </ng-template>
                                    </table>
                                </div>
                            </div>

                            <div class="form-group row mt-4 mb-0">
                                <div class=" col-6">
                                    <mrs-btn-primary buttonId="save" buttonLabel="Save" [isSubmitButon]="true"
                                        *ngIf="checkACL('W')"></mrs-btn-primary>
                                    <mrs-btn-tertiary buttonLabel="Close" buttonId="cancelButton"
                                        (onClick)="closeForm()">Cancel</mrs-btn-tertiary>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
        </form>
    </div>
</div>
<ng-template #deleteRoleDialog let-data style="width: '800px', max-height='90%'">
    <h2 mat-dialog-title>Confirm Delete</h2>
    <div mat-dialog-content class="row">
        Are you sure you want to delete this Role?
    </div>
    <div mat-dialog-actions>
        <button *ngIf="checkACL('F')" type="button" class="btn btn-danger" (click)="deleteRole()">
            <i aria-hidden="true" class="fa fa-check"></i> Delete
        </button>
        <button type="button" class="btn btn-outline-secondary" (click)="closeDialog()">
            <i aria-hidden="true" class="fa fa-times"></i> No
        </button>
    </div>
</ng-template>