import { AuditCase, AuditCasesGroup, AuditGroupByTypes, AuditViewStatusFilters } from '@Audit';

export const UnassignedAuditGroupName = '(Unassigned)';

export function filterSortAndGroupAuditCases(auditCases: AuditCase[], auditGroupByType: AuditGroupByTypes, auditViewFilter: AuditViewStatusFilters): AuditCasesGroup[] {
    const auditCases_sorted = toSortCases(auditCases);
    let auditCasesGrouped = null;
    if (auditGroupByType === AuditGroupByTypes.AssignedTo) {
        auditCasesGrouped = toGroupAndFilterAuditCasesByAssignee(auditCases_sorted, auditViewFilter);
    } else if (auditGroupByType === AuditGroupByTypes.CreatedBy) {
        auditCasesGrouped = toGroupAndFilterAuditCasesByCaseCreatedBy(auditCases_sorted, auditViewFilter);
    } else if (auditGroupByType === AuditGroupByTypes.CompletedBy) {
        auditCasesGrouped = toGroupAndFilterAuditCasesByCaseCompletedBy(auditCases_sorted, auditViewFilter);
    } else {
        throw new Error(`The ${auditGroupByType} does not have a defined function to group and filter.`);
    }
 
    const auditCases_grouped_withCases = removeGroupsWithoutCases(auditCasesGrouped);

    const auditCasesGroups = toList_sortedByName(auditCases_grouped_withCases);

    return auditCasesGroups;
}

/**
 * Creates a new array of the filtered cases that is sorted by `selectedOn`, `completedOn`, (neither selected/completed) 
 * and then secondary sorted by `caseNumberDisplay`
 * @returns new array of the sorted filtered cases
 */
export function toSortCases(cases: AuditCase[]): AuditCase[] {
    // Sort all the cases by "Selected", "Completed" and then "" and then by CaseNumberDisplay
    // This way, when we group the cases, they'll be added to the group by their sorted order.
    const cases_sorted = [...cases];
    cases_sorted.sort((a, b) => {
        const selectedOnAExists = a.selectedOn !== undefined;
        const completedOnAExists = a.completedOn !== undefined;
        const selectedOnBExists = b.selectedOn !== undefined;
        const completedOnBExists = b.completedOn !== undefined;
        const onlySelectedA = selectedOnAExists && !completedOnAExists;
        const onlySelectedB = selectedOnBExists && !completedOnBExists;

        // Selected (but not compelted) items first
        if (onlySelectedA && (!selectedOnBExists || completedOnBExists)) return -1;
        if (onlySelectedB && (!selectedOnAExists || completedOnAExists)) return 1;
        if (onlySelectedA && onlySelectedB) {
            return (b.selectedOn_date?.getTime() ?? 0) - (a.selectedOn_date?.getTime() ?? 0);
        }

        // Completed items second
        if (selectedOnAExists && completedOnAExists && !selectedOnBExists) return -1;
        if (selectedOnBExists && completedOnBExists && !selectedOnAExists) return 1;
        if (selectedOnAExists && completedOnAExists && selectedOnBExists && completedOnBExists) {
            return (b.completedOn_date?.getTime() ?? 0) - (a.completedOn_date?.getTime() ?? 0);
        }

        // Unselected items third
        if (!selectedOnAExists && !completedOnAExists && (selectedOnBExists || completedOnBExists)) return 1;
        if (!selectedOnBExists && !completedOnBExists && (selectedOnAExists || completedOnAExists)) return -1;
        if (!selectedOnAExists && !completedOnAExists && !selectedOnBExists && !completedOnBExists) {
            // Secondary sort by caseDisplayNumber
            return Intl.Collator().compare(a.caseNumberDisplay, b.caseNumberDisplay);
        }

        return 0;
    });

    return cases_sorted;
}

export function removeGroupsWithoutCases(auditCasesGroupsLookup: { [groupName: string]: AuditCasesGroup }) {
    const groupsWithCases = Object.keys(auditCasesGroupsLookup).reduce((acc, key) => {
        if (auditCasesGroupsLookup[key].filteredCases.length > 0) {
            acc[key] = auditCasesGroupsLookup[key];
        }
        return acc;
    }, {} as { [groupName: string]: AuditCasesGroup });

    return groupsWithCases;
}

export function toGroupAndFilterAuditCasesByCaseCompletedBy(cases: AuditCase[], filter: AuditViewStatusFilters): { [groupName: string]: AuditCasesGroup } {
    const groupedCasesLookup: { [groupName: string]: AuditCasesGroup } = cases.reduce((accumulator, currentCase) => {
        const caseCompletedBy = currentCase.caseLastCompletedBy ?? null;
        let groupName = `${caseCompletedBy?.firstName ?? ''} ${caseCompletedBy?.lastName ?? ''}`.trim();
        groupName = groupName || UnassignedAuditGroupName;

        // Check if there's already a group for this assignee
        if (!accumulator[groupName]) {
            accumulator[groupName] = {
                name: groupName,
                lastAssignee: caseCompletedBy,
                filteredCases: [],
                totalUnfilteredCases: 0,
                totalSelected: 0,
                totalCompleted: 0,
            };
        }

        const auditCasesGroup = accumulator[groupName];

        // Add the current case to the assignee's group
        addCaseToAll(auditCasesGroup, currentCase, filter);
        addCaseToSelected(auditCasesGroup, currentCase, filter);
        addCaseToCompleted(auditCasesGroup, currentCase, filter);

        // Return the accumulator for the next iteration
        return accumulator;
    }, {} as { [groupName: string]: AuditCasesGroup });

    return groupedCasesLookup;
}

export function toGroupAndFilterAuditCasesByCaseCreatedBy(cases: AuditCase[], filter: AuditViewStatusFilters): { [groupName: string]: AuditCasesGroup } {
    const groupedCasesLookup: { [groupName: string]: AuditCasesGroup } = cases.reduce((accumulator, currentCase) => {
        const caseCreatedBy = currentCase.caseCreatedByUser ?? null;
        let groupName = `${caseCreatedBy?.firstName ?? ''} ${caseCreatedBy?.lastName ?? ''}`.trim();
        groupName = groupName || UnassignedAuditGroupName;

        // Check if there's already a group for this assignee
        if (!accumulator[groupName]) {
            accumulator[groupName] = {
                name: groupName,
                lastAssignee: caseCreatedBy,
                filteredCases: [],
                totalUnfilteredCases: 0,
                totalSelected: 0,
                totalCompleted: 0,
            };
        }

        const auditCasesGroup = accumulator[groupName];

        // Add the current case to the assignee's group
        addCaseToAll(auditCasesGroup, currentCase, filter);
        addCaseToSelected(auditCasesGroup, currentCase, filter);
        addCaseToCompleted(auditCasesGroup, currentCase, filter);

        // Return the accumulator for the next iteration
        return accumulator;
    }, {} as { [groupName: string]: AuditCasesGroup });

    return groupedCasesLookup;
}

export function toGroupAndFilterAuditCasesByAssignee(cases: AuditCase[], filter: AuditViewStatusFilters): { [groupName: string]: AuditCasesGroup } {
    const groupedCasesLookup: { [groupName: string]: AuditCasesGroup } = cases.reduce((accumulator, currentCase) => {
        const lastAssignee = currentCase.lastAssigneeToCase ?? null;
        let groupName = `${lastAssignee?.firstName ?? ''} ${lastAssignee?.lastName ?? ''}`.trim();
        groupName = groupName || UnassignedAuditGroupName;

        // Check if there's already a group for this assignee
        if (!accumulator[groupName]) {
            accumulator[groupName] = {
                name: groupName,
                lastAssignee,
                filteredCases: [],
                totalUnfilteredCases: 0,
                totalSelected: 0,
                totalCompleted: 0,
            };
        }

        const auditCasesGroup = accumulator[groupName];

        // Add the current case to the assignee's group
        addCaseToAll(auditCasesGroup, currentCase, filter);
        addCaseToSelected(auditCasesGroup, currentCase, filter);
        addCaseToCompleted(auditCasesGroup, currentCase, filter);

        // Return the accumulator for the next iteration
        return accumulator;
    }, {} as { [groupName: string]: AuditCasesGroup });

    return groupedCasesLookup;
}

export function addCaseToAll(auditCasesGroup: AuditCasesGroup, currentCase: AuditCase, auditViewFilter: AuditViewStatusFilters) {
    auditCasesGroup.totalUnfilteredCases++;

    if (auditViewFilter == AuditViewStatusFilters.All) auditCasesGroup.filteredCases.push(currentCase);
}

export function addCaseToSelected(auditCasesGroup: AuditCasesGroup, currentCase: AuditCase, auditViewFilter: AuditViewStatusFilters) {
    if (!currentCase.selectedOn) return;

    auditCasesGroup.totalSelected++;
    if (auditViewFilter == AuditViewStatusFilters.Selected) auditCasesGroup.filteredCases.push(currentCase);
}

export function addCaseToCompleted(auditCasesGroup: AuditCasesGroup, currentCase: AuditCase, auditViewFilter: AuditViewStatusFilters) {
    if (!currentCase.completedOn) return;

    auditCasesGroup.totalCompleted++;
    if (auditViewFilter == AuditViewStatusFilters.Completed) auditCasesGroup.filteredCases.push(currentCase);
}

export function toList_sortedByName(auditCasesGroups: { [groupName: string]: AuditCasesGroup }): AuditCasesGroup[] {
    const casesGroups_sorted = Object.values(auditCasesGroups);

    casesGroups_sorted.sort((a, b) => {
        // Move unassigned names to the bottom
        if (a.name === UnassignedAuditGroupName && b.name !== UnassignedAuditGroupName) return 1;
        if (a.name !== UnassignedAuditGroupName && b.name === UnassignedAuditGroupName) return -1;

        // case-insensitive name sorting
        return Intl.Collator().compare(a.name, b.name);
    });

    return casesGroups_sorted;
}