<div class="page-heading" id="subheading">
  <span class="page-heading-title">Select an Account</span>
</div>

<div id="innercontent" class="custom-scrollbar" *ngIf="!defaultAccount || defaultAccount === null">
  <div class="container-fluid container-background pb-4">
    <div class="container mt-2 p-0 pb-4" style="background-color: white;">
      <div>
        <div class="flex flex-align-center py-2 px-4">
          <mat-form-field floatLabel="never">
            <input matInput placeholder="Filter Accounts..." name="filterValue" (keyup)="onKeyUp(filterValue)"
              [(ngModel)]="filterValue">
          </mat-form-field>
        </div>
        <div class="client-list" *ngFor="let client of filteredData;  let clientIndex = index; trackBy: trackByClientFunction">
          <div class="mb-3 px-4 " *ngIf="(client.accounts && client.accounts.length > 0) || isConfigView">
            <div class="flex flex-row">
              <div class="flex client-name" style="font-size: 18px; font-weight: 500;">{{client.name}}</div>
              <div class="flex-1"></div>
              <div class="flex flex-row" style="justify-content: flex-end; flex-wrap: nowrap; align-items: center;"
                (click)="stopPropagation($event)" *ngIf="isConfigView">
                <button class="btn btn-sm" (click)="editClient(client)" matTooltip="Edit Client"
                  *ngIf="clientWriteAccess">
                  <i aria-hidden="true" class="fa fa-edit"></i>
                </button>
                <button class="btn btn-sm" (click)="addAccount(client)" matTooltip="Add Account"
                  *ngIf="accountWriteAccess">
                  <i aria-hidden="true" class="fa fa-plus-circle"></i>
                </button>
                <label *ngIf="canImport" matTooltip="Import New Account" style="margin-bottom: 0 !important;">
                  <input #fileInput hidden="true" type="file" (change)="selectFile($event, client, null)" (click)="this.value = null" class="d-none"
                    accept=".json" />
                    <span class="btn btn-sm">
                      <a class="text-decoration-none a-button">
                        <i aria-hidden="true" class="fa fa-upload"></i>
                      </a>
                    </span>
                </label>
              </div>
            </div>
            <mat-accordion [hideToggle]="noDraftAccess" style="min-width: 800px">
              <mat-expansion-panel *ngFor="let account of client.accounts;  let accountIndex = index; trackBy: trackByAccountFunction" #matExpansionPanel
                [expanded]="account.open">
                <mat-expansion-panel-header
                  (click)="expandPanel(client, account, matExpansionPanel, $event); $event.stopPropagation();">
                  <mat-panel-title [attr.data-test-account]="account.code">
                    <div class="flex flex-row" style="align-items: center; width: 100%">
                      <button class="btn btn-sm" (click)="viewInterview(client, account)" matTooltip="Start"
                        *ngIf="!isConfigView">
                        <i aria-hidden="true" class="fa fa-file-alt"></i>
                      </button>
                      <div>
                        {{account.name}} ({{account.code}})
                      </div>
                      <div class="expansion-indicator">&nbsp;</div>
                    </div>
                    
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                <div *ngIf="!noDraftAccess">
                  <mat-card appearance="outlined" *ngFor="let version of account.versions; trackBy: trackByAccountFunction" class="mb-3 px-4" style="cursor: pointer;"
                    (click)="viewInterview(client,version)">
                    <mat-card-content>
                      
                      <div class="flex flex-row">
                        <div class="flex" style="flex-direction: column">
                          <div class="flex flex-row" style="align-items: center;">
                            <div class="mr-1 version-label">v{{version.versionNumber}} - {{version.versionLabel}}</div>
                          </div>
                          <div class="flex flex-row" style="align-items: center;">
                            <div class="version-status version-status-active" *ngIf="version.status === 'Active'">
                              {{version.status}}
                            </div>
                            <div class="version-status version-status-inactive" *ngIf="version.status === 'Inactive'">
                              {{version.status}}
                            </div>
                            <div class="version-status"
                              *ngIf="version.status !== 'Active' && version.status !== 'Inactive'">
                              {{version.status}}
                            </div>
                            <div style="color: gray; font-size: 10px">
                              on {{version.lastModifiedDate | date: 'MM/dd/yyyy'}}
                            </div>
                          </div>
                        </div>
                        <div class="flex-1"></div>
                        <div class="flex flex-row"
                          style="justify-content: flex-end; flex-wrap: nowrap; align-items: center;"
                          (click)="stopPropagation($event)" *ngIf="!noDraftAccess && isConfigView">
                          <div *ngIf="version.status !== 'Copying'">
                            <button type="button" mat-stroked-button class="mr-1 material-default"
                              [disabled]="version.status === 'Active' || version.status === 'Failed'"
                              (click)="activateVersion(client, version, account)">ACTIVATE</button>
                            <button class="btn btn-sm" (click)="copyAccount(version)" matTooltip="Copy this version"
                              [disabled]="version.status === 'Failed'">
                              <i aria-hidden="true" class="fa fa-copy"></i>
                            </button>
                            <button class="btn btn-sm" [disabled]="version.status === 'Active'"
                              (click)="archiveVersion(client, version)" matTooltip="Archive this version">
                              <i aria-hidden="true" class="fa fa-archive"></i>
                            </button>
                            <button class="btn btn-sm"  *ngIf="canExport && version.status !== 'Failed'"
                              (click)="exportVersion(client, version)" matTooltip="Export this version">
                              <i aria-hidden="true" class="fa fa-download"></i>
                            </button>
                            <label *ngIf="canImport && version.status !== 'Failed'"
                              matTooltip="Import Account as a version of this Account">
                              <input #fileInput hidden="true" type="file" (change)="selectFile($event, client, version)" (click)="this.value = null" class="d-none"
                                accept=".json" />
                                <span class="btn btn-sm">
                                  <a class="text-decoration-none a-button">
                                    <i aria-hidden="true" class="fa fa-upload"></i>
                                  </a>
                                </span>
                            </label>
                          </div>
                          <div *ngIf="version.status === 'Copying'" class="mr-1">
                            <mat-spinner mode="indeterminate" diameter="30"></mat-spinner>
                          </div>
                        </div>
                        <div class="flex flex-row"
                          style="justify-content: flex-end; flex-wrap: nowrap; align-items: center;"
                          (click)="stopPropagation($event)" *ngIf="!isConfigView">
                          <button type="button" mat-stroked-button class="mr-1 material-default"
                            (click)="viewInterview(client, version)">START</button>
                        </div>
                      </div>
                    </mat-card-content>
                  </mat-card>
                </div>
              </ng-template>
              </mat-expansion-panel>
              <mat-expansion-panel *ngIf="isConfigView && (!client.accounts || client.accounts.length === 0)"
                #matExpansionPanel [expanded]="false">
                <mat-expansion-panel-header>
                  <mat-panel-title style="width: 200px">
                    <div class="flex flex-row" style="align-items: center;">
                      <div>
                        No Accounts
                      </div>
                    </div>
                  </mat-panel-title>
                  <mat-panel-description>
                  </mat-panel-description>
                </mat-expansion-panel-header>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
      </div>
      <ng-template #noAccountsPlaceholder>
        <div class="p-4 text-center text-muted">
          <img src="./assets/images/no-data-placeholder.png" alt="" class="pb-3" />
          <h6>No Active Accounts Found</h6>
        </div>
      </ng-template>
      <ng-template #copyVersionDialog let-data style="width: '800px', max-height='90%'">
        <h2 mat-dialog-title>Copy v{{selectedAccount.versionNumber}} - {{selectedAccount.versionLabel}}</h2>
        <div mat-dialog-content>
          <form [formGroup]="versionForm" autocomplete="off">
            <mat-form-field floatLabel="never" style="width: 100%; padding-right: 25px">
              <mat-label>Version Label</mat-label>
              <input matInput formControlName="versionLabel" required>
            </mat-form-field>
          </form>
        </div>
        <div mat-dialog-actions align="end">
          <button type="button" mat-button mat-dialog-close class="mr-1 material-default">
            CANCEL
          </button>
          <button type="button" mat-button class="mr-1 material-default" (click)="createVersion()">
            COPY
          </button>
        </div>
      </ng-template>
      <ng-template #versionStatusDialog let-data style="width: '800px', max-height='90%'">
        <h2 mat-dialog-title>
          Create version status: {{this.selectedAccount.name}} - {{this.selectedAccount.versionLabel}}
        </h2>
        <div mat-dialog-content>
          <table mat-table [dataSource]="dataSource" style="width:100%;">
            <ng-container matColumnDef="taskType">
              <th mat-header-cell *matHeaderCellDef style="width:200px;">Task Type</th>
              <td mat-cell *matCellDef="let element"> {{element.taskType}} </td>
            </ng-container>
            <ng-container matColumnDef="task">
              <th mat-header-cell *matHeaderCellDef style="width:200px;">Task</th>
              <td mat-cell *matCellDef="let element"> {{element.task}} </td>
            </ng-container>
            <ng-container matColumnDef="message">
              <th mat-header-cell *matHeaderCellDef style="width:400px;">Status</th>
              <td mat-cell *matCellDef="let element"> {{element.message}} </td>
            </ng-container>
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef style="width:50px;"></th>
              <td mat-cell *matCellDef="let element">
                <i aria-hidden="true" class="fa fa-check" *ngIf="element.status === 'Complete'" style="color: green"></i>
                <div (click)="restartAccountImport(element.id)" *ngIf="element.status === 'Failed'">
                  <mat-icon style="color: red" matTooltip="Restart account version task">restart_alt</mat-icon>
                </div>
                <i aria-hidden="true" class="fa fa-spinner" *ngIf="element.status === 'In Progress'"></i>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
          </table>
        </div>
        <div mat-dialog-actions align="end">
          <button type="button" mat-button class="mr-1 material-default" (click)="getVersionStatus(selectedAccount)">
            REFRESH
          </button>
          <button type="button" mat-button mat-dialog-close class="mr-1 material-default">
            CLOSE
          </button>
        </div>
      </ng-template>
      <ng-template #editClientDialog let-data>
        <h2 mat-dialog-title>
          Edit Client
        </h2>
        <form class="client-name-input" [formGroup]="newClientForm" novalidate>
          <div mat-dialog-content>
            <mat-form-field class="full-width">
              <mat-label>Client Name</mat-label>
              <input matInput formControlName="name" autocomplete="off">
              <mat-error *ngIf="newClientForm.get('name').hasError('required')">
                Client name
                <strong>required</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div mat-dialog-actions align="end">
            <button type="button" mat-button mat-dialog-close class="mr-1 material-default">
              CANCEL
            </button>
            <button type="button" mat-button class="mr-1 material-default" (click)="saveClient()">
              SAVE
            </button>
          </div>
        </form>
      </ng-template>
      <ng-template #activateVersionDialog let-data>
        <h2 mat-dialog-title>
          Activate v{{selectedAccount.versionNumber}} - {{selectedAccount.versionLabel}}
        </h2>
        <div mat-dialog-content>
          <div class="flex" *ngIf="selectedAccount.id !== activeAccount.id">
            This will activate "v{{selectedAccount.versionNumber}} - {{selectedAccount.versionLabel}}"? and inactivate
            "v{{activeAccount.versionNumber}} - {{activeAccount.versionLabel}}".
          </div>
          <div class="flex" *ngIf="selectedAccount.id === activeAccount.id">
            This will activate "v{{selectedAccount.versionNumber}} - {{selectedAccount.versionLabel}}".
          </div>
          <div *ngIf="selectedAccount.id !== activeAccount.id">
            <h5 style="margin-top: 15px;">Copy Settings</h5>
            <div class="flex">
              Would you like to copy settings from the previously active version?
            </div>
            <mat-selection-list [(ngModel)]="selectedOptions">
              <mat-list-option checkboxPosition="before" [value]="copySettings">General/Case Transfer Settings</mat-list-option>
              <mat-list-option checkboxPosition="before" [value]="integrationSettings">
                <div class="flex flex-row">
                  <div style="padding-right: 5px; ">Integrations</div>
                  <i aria-hidden="true" class="fas fa-info-circle" matTooltip="Integration settings will only be updated.  New or deleted integrations will not be affected." matTooltipClass="multiline-tooltip"></i>
                </div>
              </mat-list-option>
              <mat-list-option checkboxPosition="before" [value]="eventSettings">
                <div class="flex flex-row">
                  <div style="padding-right: 5px; ">Events</div>
                  <i aria-hidden="true" class="fas fa-info-circle" matTooltip="System events will be toggled to the existing setting." matTooltipClass="multiline-tooltip"></i>
                </div>
              </mat-list-option>
            </mat-selection-list>
          </div>
        </div>
        <div mat-dialog-actions align="end">
          <button type="button" mat-button mat-dialog-close class="mr-1 material-default">
            CANCEL
          </button>
          <button type="button" mat-button class="mr-1 material-default" (click)="saveActivateVersion()">
            ACTIVATE
          </button>
        </div>
      </ng-template>
    </div>
  </div>
</div>
