import { AuditCaseEnhanced } from '@Pages/audit';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';


@Component({
    selector: 'audit-cases-table-stats',
    templateUrl: './audit-cases-table-stats.component.html',
    styleUrls: ['./audit-cases-table-stats.component.scss']
})
export class AuditCasesTableStatsComponent implements OnChanges {
    @Input() auditGoalPercent = 0;
    @Input() totalCases = 0;
    @Input() auditCases: AuditCaseEnhanced[] = [];

    showGoal = false;

    // Simple metrics
    selectedAuditCasesTotal = 0;
    completedAuditCasesTotal = 0;

    calculateSelectedItemsMetrics() {
        let totalSelectedItems = 0;
        let totalCompletedItems = 0;
        this.auditCases?.forEach(auditCase => {
            if (auditCase.selectedOn) totalSelectedItems = totalSelectedItems + 1;
            if (auditCase.completedOn) totalCompletedItems = totalCompletedItems + 1;
        });

        this.selectedAuditCasesTotal = totalSelectedItems;
        this.completedAuditCasesTotal = totalCompletedItems;


        if (this.auditGoalPercent > 0)
        {
            this.showGoal = true;
        } else {
            this.showGoal = false;
        }
        
    }

    ngOnChanges(_changes: SimpleChanges) {
        this.calculateSelectedItemsMetrics();
    }

}
