import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import {
    CaseRequirement,
    CaseRequirementResponse,
    NoteResponse,
    RequirementResponse,
    UWBaseProps,
    UWRequirementActionEvent,
    UWRequirementTableData,
} from '@Underwriting/models';
import { CaseRequirementStatuses, UWRequirementType } from '@Underwriting/types';
import { AccountSettings, FeatureToggle } from '@Enums';

import { UwwUtils } from '../../utils';
import { AccountSettingsDataService, FeatureManagerService } from '@Services';
import { SubSink } from 'subsink';
import { UWRequirementsDataService } from '@Underwriting/services';
import { forkJoin } from 'rxjs';
import { AccountSettingsDTO } from '@DTOs';

export enum UWRequirementTableActions {
    ViewDocument = 'view document',
    ViewDocumentIcon = 'view document icon',
    RunIntegration = 'run integration',
    AttachDocument = 'attach document',
    OrderSubmitted = 'order submitted',
    FollowUp = 'follow up',
    EditSubtitle = 'edit subtitle',
    Waive = 'waive',
    Completed = 'completed',
    Pending = 'pending',
    Cancelled = 'cancelled',
    Delete = 'delete',
    OpenSection = 'order'
}

export type UWRequirementTableProps = {
    showRequirementLabel: boolean;
    reviewDocumentsEnabled: boolean;
    hideSetPendingMenuOption: boolean;
    hideSetCancelledMenuOption: boolean;
    hideDeleteMenuOption: boolean;
    hideEndStateToEndStateTransition: boolean;
    hideCompleteMenuOption?: boolean;
}

@Component({
    selector: 'uw-requirements-expand-table',
    templateUrl: './uw-requirements-expand-table.component.html',
    styleUrls: ['./uw-requirements-expand-table.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class UWRequirementsExpandTable implements OnInit, OnChanges, OnDestroy {
    @Input() baseProps: UWBaseProps;
    @Input() caseRequirements: CaseRequirementResponse[];
    @Input() requirements: RequirementResponse[];
    @Output() onMenuAction = new EventEmitter<UWRequirementActionEvent>();

    uwRequirementsDataSet = new MatTableDataSource([]);
    noDataMessage = 'No requirements have been added';
    displayedColumns: Array<string> = ['expand', 'name', 'ordered', 'followUp', 'received', 'status', 'actions'];
    expandedElement: CaseRequirement | null;

    featureTogglesLoaded = false;

    notes: NoteResponse[] = [];
    notesLoaded = false;


    subs = new SubSink();
    tableProps: UWRequirementTableProps = {
        showRequirementLabel: true,
        reviewDocumentsEnabled: false,
        hideSetPendingMenuOption: false,
        hideSetCancelledMenuOption: false,
        hideDeleteMenuOption: false,
        hideEndStateToEndStateTransition: false,
    };

    constructor(
        private featureManagerService: FeatureManagerService,
        private uwRequirementsDataService: UWRequirementsDataService,
        private accountSettingsDataService: AccountSettingsDataService
    ) { }

    //#region Utilities

    // Dev Note: We need to expose the enum to allow the html template to use it in a condition.
    public get UWRequirementType() {
        return UWRequirementType;
    }

    public get CaseRequirementStatuses() {
        return CaseRequirementStatuses;
    }

    public get UWRequirementTableActions() {
        return UWRequirementTableActions;
    }

    //#endregion
    //#region Helpers

    private mapRequirementsToTableData() {
        const { integrationRequirements } = this.baseProps;
        this.uwRequirementsDataSet.data = UwwUtils.mapRequirementsToTableData(integrationRequirements, this.caseRequirements, this.requirements, this.notes, this.baseProps.caseIntegrationQueueEntries);  
    }

    private setNotOrderedTableProps(accountSettings: AccountSettingsDTO[]) {
        const uwDecisionSectionSetting = accountSettings?.find(x => x.name === AccountSettings.EvidenceNotRequiredForManualRequirements);

        if (uwDecisionSectionSetting && uwDecisionSectionSetting.value.toLowerCase() === "true") {
            this.tableProps.hideCompleteMenuOption = false;
        }else {
            this.tableProps.hideCompleteMenuOption = true;
        }
    }

    //#endregion
    //#region Subscriptions

    private subscribeToAccountSettings() {
        this.subs.add(this.accountSettingsDataService.loadAccountSettings(this.baseProps.account.id).subscribe(accountSettings => {
            this.setNotOrderedTableProps(accountSettings);
        }));
    }
    
    private subsribeToFeatureToggle() {
        const { client } = this.baseProps;

        forkJoin([
            this.featureManagerService.isEnabled(FeatureToggle.UWWorkbenchRequirementsReviewedClient, client.id),
            this.featureManagerService.isEnabled(FeatureToggle.UWWorkbenchFeatureReviewDocumentsClients, client.id),
            this.featureManagerService.isEnabled(FeatureToggle.UWWorkbenchRequirementsHidePendingClient, client.id),
            this.featureManagerService.isEnabled(FeatureToggle.UWWorkbenchRequirementsHideCancelledClient, client.id),
            this.featureManagerService.isEnabled(FeatureToggle.UWWorkbenchRequirementsHideDeleteClient, client.id),
            this.featureManagerService.isEnabled(FeatureToggle.UWWorkbenchRequirementsHideEndStateToEndStateTransitionClient, client.id),
        ]).subscribe(([showRequirementLabel, reviewDocumentsEnabled, hidePending, hideCancelled, hideDelete, hideEndStateToEndStateTransition]) => {
            this.tableProps.showRequirementLabel = showRequirementLabel;
            this.tableProps.reviewDocumentsEnabled = reviewDocumentsEnabled;
            this.tableProps.hideSetPendingMenuOption = hidePending;
            this.tableProps.hideSetCancelledMenuOption = hideCancelled;
            this.tableProps.hideDeleteMenuOption = hideDelete;
            this.tableProps.hideEndStateToEndStateTransition = hideEndStateToEndStateTransition;

            this.featureTogglesLoaded = true;
        });

    }

    private subscribeToNotes() {
        this.subs.add(this.uwRequirementsDataService.loadNotes(this.baseProps.client.code, this.baseProps.case.id).subscribe({
            next: notes => {
                this.notes = notes;
                this.notesLoaded = true;
                this.mapRequirementsToTableData();
            },
        }));
    }


    //#endregion
    //#region Lifecycle

    ngOnInit() {
        this.subsribeToFeatureToggle();
        this.subscribeToAccountSettings();
        this.subscribeToNotes();
    }

    ngOnChanges(changes: SimpleChanges) {
        // BUG-39752 people adding case requirement notes would lose data
        // Gehred figured out that angular on change event will fire for any
        // data that is bound on the form (https://www.telerik.com/blogs/angular-basics-detecting-updates-onchanges-lifecyle-hook).
        // Turns out BaseProps fires every 30-60 seconds
        // the result was mapRequirementsToTableData would clear the data table and reset it,
        // which in turn would reset all the components on the page.
        // The fix you see here has a weakness and that is if a user is editing a case requirement note
        // and any of the Case Requirements change, add followupdate, state change, etc. etc. then 
        // the note would still be lost. A redesign of the UI would be required to fix this or a much
        // more sophisticated way to update the CaseRequirement table and the associated lists.
        if (changes.caseRequirements) {
            this.mapRequirementsToTableData();
        }
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    //#endregion
    //#region Handlers

    handleViewDocuments(columnAction: UWRequirementTableActions, selectedRequirement: UWRequirementTableData) {
        const viewAction = columnAction == UWRequirementTableActions.ViewDocumentIcon;
        if (viewAction && !selectedRequirement?.hasDocument)
            return; // We're faking disabled button via CSS so we can stop event propagation, to prevent expanding the column by clicking disabled button

        const actionEvent: UWRequirementActionEvent = { action: columnAction, data: selectedRequirement };
        this.onMenuAction.emit(actionEvent);
    }

    handleMenuAction(actionEvent: UWRequirementActionEvent) {
        this.onMenuAction.emit(actionEvent);
    }

    //#endregion
}
