import { Component, OnInit, ViewChildren } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MaskValueDirective } from 'app/directives';
import { BaseFormFieldServices, BaseInterviewFormFieldComponent } from '../base-interview-form-field/base-interview-form-field.component';

@Component({
    selector: 'short-answer',
    templateUrl: './short-answer.component.html',
    styleUrls: ['./short-answer.component.scss']
})
export class ShortAnswerComponent extends BaseInterviewFormFieldComponent implements OnInit {
    constructor(
        private fb: UntypedFormBuilder, public services: BaseFormFieldServices) {

        super(services);
    }
    @ViewChildren(MaskValueDirective) maskValueDirective;
    forcedFieldName: string;

    ngOnInit() {
        super.initialize({ inputType: 'text' });
        this.forcedFieldName = `${this.config.id}_forceUpdate`;
        this.group.addControl(this.forcedFieldName, this.fb.control(''));

        this.subscriptions.push(this.group.get(this.forcedFieldName).valueChanges.subscribe(
            data => {
                this.maskValueDirective.forEach(element => {
                    element.formatPrefillValue();
                });
            }
        ));
    }

    filterInput(event) {

        let allowedInput = [];
        const defualtAllowed = ['Backspace', 'Delete', 'Tab', 'ArrowLeft', 'ArrowRight'];

        switch (this.config.AllowInput) {
            case 'numbers':
                allowedInput = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
                break;
        }

        allowedInput = [...defualtAllowed, ...allowedInput];

        if (this.config.AllowInput && this.config.AllowInput !== 'any' && !allowedInput.includes(event.key)) {
            event.preventDefault ? event.preventDefault() : event.returnValue = false;
            return false;
        }
    }
}
