import { animate, style, transition, trigger } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { AppService } from '@App';
import { CaseAssignee, ClientUser, ManagementQueue, ManagementQueueResult, SearchCasesResults, UWResponse } from '@Models';
import { ConfirmationDialogData } from 'app/components/confirmation-dialog/confirmation-dialog.component';
import {
    MultiSelectDropdownListComponent,
} from 'app/components/multi-select-dropdown-list/multi-select-dropdown-list.component';
import { IConfigService } from 'app/config/iconfigservice';
import { Clients, FeatureToggle, MrsColor, RolesEnum } from '@Enums';
import { IGetNextCase } from 'app/models/case/case-get-next-case.interface';
import { FeatureManagerService } from 'app/services/global/feature-manager/feature-manager.service';
import * as $ from 'jquery';
import * as _ from 'lodash';
import { forkJoin, Subscription } from 'rxjs';
import { SubSink } from 'subsink';

import { MultiSelectModel } from '../../components/multi-select-dropdown-list/models/MultiSelectModel.interface';
import { InterviewService } from '../../interview.service';
import { NotesDialogComponent } from '../../management-queues/notes-dialog.component';
import { MonitoringService } from '../../monitor.service';
import {
    CaseAssigneeService,
    CaseSummaryService,
    ClientService,
    ConfirmationDialogService,
    GlobalService,
    ManagementQueueService,
    NotificationService,
    NotificationSeverity,
    RoutesEnum,
    RoutingService,
    UtilityService,
} from '../../services';
import { ManagementQueueUpdates } from '../models/ManagementQueueConfigModel.interface.tx';
import { NewBusinessWorkbenchStateService } from '../services/new-business-workbench-state.service';
import { NewBusinessWorkbenchService } from '../services/new-business-workbench.service';
import { Utils } from '@Utils';

@Component({
    selector: 'view-queues',
    host: { 'class': 'overflow-content' },
    templateUrl: 'view-queues.component.html',
    styleUrls: ['view-queues.component.scss'],
    animations: [
        trigger('flyRightToLeft', [
            transition(':enter', [
                style({ transform: 'translateX(-100%)' }),
                animate(250),
                animate('10ms', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                animate(200, style({ transform: 'translateX(-100%)' })),
                animate('10ms', style({ opacity: 0 }))
            ])
        ])
    ]
})
export class ViewQueuesComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild(MatSort) set matSort(ms: MatSort) {
        this.sort = ms;
        this.setDataSourceAttributes();
    }
    @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
        this.paginator = mp;
        this.setDataSourceAttributes();
    }
    @ViewChild(MatTabGroup) tabGroup: MatTabGroup;
    @ViewChildren(MatMenuTrigger) matMenuTriggers: MatMenuTrigger[];
    @ViewChild('actionMenu') actionMenu: MatMenu;
    @ViewChild('userInput', { read: MatAutocompleteTrigger }) userInput: MatAutocompleteTrigger;
    @ViewChild('assigneeDropdown') assigneeDropdown: MultiSelectDropdownListComponent;
    @ViewChild('clientDropdown') clientDropdown: MultiSelectDropdownListComponent;

    @Input() clientId;

    get settings() {
        return this.nbwStateService.getSavedSettings();
    }

    filterValue: any;
    menu: any = [];
    activeMenu: any = '';

    sort: MatSort;
    paginator: MatPaginator;

    clientUsers: ClientUser[] = [];
    queues: ManagementQueue[] = [];
    cases: ManagementQueueResult[] = [];
    dialogRef: MatDialogRef<NotesDialogComponent>;
    caseDataSource = new MatTableDataSource<ManagementQueueResult>();
    selectedQueue: ManagementQueue;
    selectedIndex: any = 0;
    timeZone: string;
    useSavedData = false;
    canAssignCase = false;
    canConfigure = false;
    selectedClients: UntypedFormControl;
    contextMenuPosition = { x: '0px', y: '0px' };
    selectedUser: UntypedFormControl;
    appServiceGetDataSub: any;
    columnsToDisplay = [];
    customColumns: string[] = [];
    selection: SelectionModel<ManagementQueueResult>;
    actionMenuTrigger: MatMenuTrigger;
    subscriptions: Subscription[] = [];
    filteredUserList: ClientUser[];
    assigneeSelectListItems: MultiSelectModel[];
    selectedAssignees: UntypedFormControl;
    onLoadSelectedAssigneesList: string[];
    onLoadSelectedClientsList: string[];
    queueDataLoaded = false;
    subs = new SubSink();
    columnMapkeys;
    initialSort = "caseCreated";

    showGetNextCase = false;
    selectedClientCode = '';
    selectedClientId = '';
    publicApiBaseUrl = '';
    getNextCaseIsLoaded = true;
    roles: any;

    constructor(
        public appService: AppService,
        public datePipe: DatePipe,
        public interviewService: InterviewService,
        public routingService: RoutingService,
        public activatedRoute: ActivatedRoute,
        public dialog: MatDialog,
        public monitoringService: MonitoringService,
        public nbwStateService: NewBusinessWorkbenchStateService,
        public clientService: ClientService,
        public caseAssigneeService: CaseAssigneeService,
        public notificationService: NotificationService,
        public snackBar: MatSnackBar,
        public utilityService: UtilityService,
        public globalService: GlobalService,
        public caseSummaryService: CaseSummaryService,
        public newBusinessWorkbenchService: NewBusinessWorkbenchService,
        public changeDetector: ChangeDetectorRef,
        private confirmationDialogService: ConfirmationDialogService,
        private featureManagerService: FeatureManagerService,
        public configService: IConfigService,
        private managementQueueService: ManagementQueueService
    ) {
        this.appService.getAllConfigData();
        this.selection = new SelectionModel<ManagementQueueResult>(true, []);

        this.globalService.getAllAssignees().subscribe(
            assignees => {
                const selectModels = new Array<MultiSelectModel>();
                assignees.sort(function (a, b) {
                    const nameA = `${a.firstName?.toUpperCase()} ${a.lastName?.toUpperCase()}`; // ignore upper and lowercase
                    const nameB = `${b.firstName?.toUpperCase()} ${b.lastName?.toUpperCase()}`; // ignore upper and lowercase
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }

                    // names must be equal
                    return 0;
                });
                assignees.forEach(assignee => {
                    selectModels.push({
                        id: assignee.id,
                        name: `${assignee.firstName} ${assignee.lastName}`,
                        isSelected: false
                    });
                });
                this.assigneeSelectListItems = selectModels;
            });
        this.publicApiBaseUrl = this.configService.getConfiguration().publicApiUrl;
    }

    //#region Helpers

    getPresenceUsers(data: SearchCasesResults) {
        let result = null;
        if (data.presenceUsers && data.presenceUsers.length > 0) {
            result = Object.keys(data.presenceUsers).map(function (k) {
                return data.presenceUsers[k].name;
            }).join(', ');
        }
        return result;
    }


    HasValidRole(validRoles: RolesEnum[]) {
        const userRole = this.appService.getUserRoleName().toLowerCase();
        return validRoles.some(x => x.toLowerCase() === userRole);
    }

    //#endregion
    //#region Subscriptions

    subscribeAssignNextCaseToggle(): void {
        this.subs.add(this.featureManagerService.isEnabled(FeatureToggle.GlobalCaseManagerV2AssignNextCaseClient, this.selectedClientId).subscribe(isEnabled => {
            this.showGetNextCase = isEnabled;

            if (!this.showGetNextCase) return;

            // Limit by client/role access
            if (this.clientId.toLowerCase() === Clients.AEG) {
                this.showGetNextCase = this.HasValidRole([RolesEnum.PNProcessor, RolesEnum.Processor, RolesEnum.Underwriter, RolesEnum.TIRepresentative]);
            }
            else if (this.clientId.toLowerCase() === Clients.RGA) {
                this.showGetNextCase = this.HasValidRole([RolesEnum.RgaUnderwriter]);
            }
            else if (this.clientId.toLowerCase() === Clients.AmFamPoC.toLowerCase()) {
                this.showGetNextCase = this.HasValidRole([RolesEnum.Supervisor, RolesEnum.CSR]);
            }
        }));
    }

    //#endregion
    //#region Lifecycle

    ngOnInit() {
        this.subscribeAssignNextCaseToggle();
        $('#sidenav').removeClass('hidenav');

        if (this.nbwStateService.getSavedSettings() && this.nbwStateService.getSavedSettings().caseData) {
            this.useSavedData = true;
            this.queueDataLoaded = true;
        }

        this.nbwStateService.clientId = this.clientId;
        this.newBusinessWorkbenchService.startSignalRConnection(this.clientId);

        this.selectedUser = new UntypedFormControl('');
        this.selectedAssignees = new UntypedFormControl([]);

        this.subs.sink = this.selectedUser.valueChanges.subscribe(val => {
            const filterValue = val ? val.toLowerCase() : '';

            if (this.clientUsers.length > 0) {
                this.filteredUserList = this.clientUsers.filter((item: ClientUser) => {
                    return (`${item.firstName} ${item.lastName}`.toLowerCase().includes(filterValue));
                });
                this.filteredUserList = _.sortBy(this.filteredUserList, ['firstName', 'lastName']);
            }
        });

        this.subs.sink = this.selectedAssignees.valueChanges.subscribe(val => {
            this.filterCases();
        });

        this.subs.sink = this.nbwStateService.queuesUpdated$.subscribe((queuesUpdatedMessage: ManagementQueueUpdates) => {
            this.queuesUpdated(queuesUpdatedMessage);
        });

        this.canAssignCase = this.checkACL('Allow Case Assignment', 'F');
        this.canConfigure = this.checkACL('Case Manager: Configure', 'W');

        this.queuesUpdated(this.nbwStateService.getNbwQueueUpdates());

        if (this.useSavedData) {
            this.getQueues();
        }
    }

    ngAfterViewInit() {
        this.updateUsingSavedSettings();

        this.actionMenuTrigger = this.matMenuTriggers.find(matMenuTrigger => matMenuTrigger.menu === this.actionMenu);
    }

    ngOnDestroy() {
        this.queues.forEach(x => x.isNewQueue = false);
        this.subs.unsubscribe();
        this.saveSettings();
    }

    //#endregion
    //#region Handlers

    viewCaseDetails(record, newTab = false) {
        this.caseSummaryService.routeToCase(record, newTab);
    }

    stopPropagation($event) {
        $event.stopPropagation();
    }

    openMenu(event: MouseEvent, record) {
        event.preventDefault();
        if (this.selection.selected.length === 0 || !this.selection.isSelected(record)) {
            this.selection.clear();
            this.selection.select(record);
        }
        this.contextMenuPosition.x = event.clientX + 'px';
        this.contextMenuPosition.y = event.clientY + 'px';
        this.actionMenuTrigger.openMenu();
    }

    selectQueue(tab: MatTabChangeEvent) {
        this.setSelectedQueue(tab.index);
    }

    selectedClientsChanged(newlySelectedClients: string[]) {
        this.selectedClients.setValue(newlySelectedClients);
    }

    selectedAssigneesChanged(newlySelectedAssignees: string[]) {
        this.selectedAssignees.setValue(newlySelectedAssignees);
    }

    updateFilteredCases() {
        if (this.filterValue) {
            this.caseDataSource.filter = this.filterValue.trim().toLowerCase();
        } else {
            this.caseDataSource.filter = null;
        }
    }

    editQueues() {
        this.routingService.navigateToRoute(RoutesEnum.caseManangerEdit);
    }

    getNextCase(): void {
        this.getNextCaseIsLoaded = false;
        this.subs.sink = this.managementQueueService.getNextCase(this.clientId).subscribe({
            next: (value: IGetNextCase) => {
                this.getNextCaseIsLoaded = true;
                if (value && JSON.stringify(value) !== '{}') {
                    this.routingService.navigateToUrl(`/client/${value.clientCode}/account/${value.accountCode}/${value.accountVersion}/${value.caseId}/summary`);
                }
                else {
                    const options: ConfirmationDialogData = {
                        title: 'No Cases Available',
                        message: 'Try again in a few minutes.',
                        okLabel: 'OKAY',
                        okTextColor: MrsColor.UIBlue
                    };
                    this.confirmationDialogService.open(options);
                }
            },
            error: (_err) => {
                this.getNextCaseIsLoaded = true;
            }
        });
    }

    openInNewWindow() {
        const selectedItems = this.getSelectedCases();

        if (selectedItems.length === 0) {
            this.appService.showMsg(NotificationSeverity.Warning, 'Select an item...');
            return;
        }

        selectedItems.forEach(record => {
            this.viewCaseDetails(record, true);
        });
    }

    viewNotes(item: any) {
        this.dialogRef = this.dialog.open(NotesDialogComponent, {
            width: '75%',
            data: item
        });
    }

    getClientUsers() {
        const observableBatch = [];
        const selectedCases = this.getSelectedCases();

        const clientIds = selectedCases.map(item => item.clientId)
            .filter((value, index, self) => self.indexOf(value) === index);

        this.clientUsers = [];
        clientIds.forEach(clientId => {
            observableBatch.push(this.clientService.getClientUsers(clientId));
        });

        this.subs.sink = forkJoin(observableBatch).subscribe({
            next: (responses) => {
                responses.forEach((response: ClientUser[]) => {
                    if (this.clientUsers.length === 0) {
                        this.clientUsers = response;
                    } else {
                        this.clientUsers = this.utilityService.compareTwoArrays(this.clientUsers, response, true);
                    }
                });
            },
            complete: () => this.filteredUserList = _.sortBy(this.clientUsers, ['firstName', 'lastName'])
        });
    }

    //#endregion

    setDataSourceAttributes() {
        this.caseDataSource.paginator = this.paginator;
        this.caseDataSource.sort = this.sort;
    }

    queuesUpdated(queuesUpdatedMessage: ManagementQueueUpdates) {
        if (queuesUpdatedMessage?.queuesUpdated || queuesUpdatedMessage?.queuesRemoved) {
            this.setupQueuesData(queuesUpdatedMessage.queues);
        }

        if (this.nbwStateService.getSavedSettings()?.selectedQueue == null && this.queues?.length > 0) {
            this.selectedIndex = 0;
            this.getCases(this.queues[0]);
        }

        this.nbwStateService.showQueuesMessage();
    }

    updateUsingSavedSettings() {
        if (this.nbwStateService.getSavedSettings() && this.nbwStateService.getSavedSettings().queues) {
            this.selectedQueue = this.nbwStateService.getSavedSettings().selectedQueue;
            this.caseDataSource.data = this.nbwStateService.getSavedSettings().caseData;
            this.cases = this.nbwStateService.getSavedSettings().caseData;
            this.queues = this.nbwStateService.getSavedSettings().queues;
            this.selectedAssignees.setValue(this.nbwStateService.getSavedSettings().selectedAssignees);
            this.onLoadSelectedAssigneesList = this.nbwStateService.getSavedSettings().selectedAssignees;
            this.selectedIndex = this.nbwStateService.getSavedSettings().selectedQueue?.order - 1;
            this.filterValue = this.nbwStateService.getSavedSettings().filter;
            this.columnMapkeys = this.nbwStateService.getSavedSettings().columnMapkeys;
            this.updateFilteredCases();
        } else {
            this.nbwStateService.clearSettings();
            this.getQueues();
        }
    }

    checkACL(feature, permissionType) {
        return this.appService.checkACL(feature, permissionType);
    }

    setAttributes() {
        this.caseDataSource.paginator = this.paginator;
        this.caseDataSource.sort = this.sort;

        if (this.nbwStateService.getSavedSettings() && this.paginator) {
            this.paginator.pageIndex = this.nbwStateService.getSavedSettings().pageIndex;
            this.paginator.pageSize = this.nbwStateService.getSavedSettings().pageSize;

            this.filterCases();
        }
    }

    getQueues() {
        this.subs.sink = this.newBusinessWorkbenchService.getFilteredWorkbenchQueues(this.clientId).subscribe(
            results => {
                this.setupQueuesData(results);
            }
        );
    }

    setupQueuesData(results: ManagementQueue[]) {
        this.queues = results.sort((a, b) => {
            if (a.order < b.order) return -1;
            if (a.order < b.order) return 1;
            return 0;
        });

        this.queueDataLoaded = true;
        this.columnMapkeys = [];
        const columns = [];

        this.queues.forEach(queue => {
            columns.push(...queue.managementQueueColumnMapKeys.filter(x => !Utils.isNullOrWhitespace(x.mapKeyName)));
        });

        this.columnMapkeys = _.uniqBy(columns, 'columnDefinition');

        if (this.nbwStateService.getSavedSettings()) {
            this.nbwStateService.getSavedSettings().queues = this.queues;
        }

        const currentlySelectedQueue = this.nbwStateService.getSavedSettings()?.selectedQueue;

        if (!this.nbwStateService.getSavedSettings()?.selectedQueue && this.queues.length > 0) {
            this.nbwStateService.clearSettings();
            this.nbwStateService.getSavedSettings().selectedQueue = this.queues[0];
            this.nbwStateService.getSavedSettings().caseData = null;
            this.useSavedData = false;

            setTimeout(function () {
                this.setSelectedQueue(0);
                this.getCases(this.nbwStateService.getSavedSettings().selectedQueue);
                this.selectedIndex = 0;
                this.changeDetector.markForCheck();
            }.bind(this), 0);
        } else {
            const idx = this.queues.findIndex(x => x.id === currentlySelectedQueue.id);
            this.selectedIndex = idx;
            this.setSelectedQueue(idx);
        }
    }

    getCases(queue: ManagementQueue) {
        this.selectedQueue = queue;
        this.selection.clear();
        if (this.useSavedData) {
            this.useSavedData = false;
            this.setAttributes();
        } else {
            this.caseDataSource = new MatTableDataSource<ManagementQueueResult>();
            const metricEvent = this.monitoringService.beginMetric(`Search: Case Manager-${this.selectedQueue?.name}`);

            // TECH DEBT: BUG?: The results from this call should be of of type `UWResponse<SearchCasesResults[]>` but
            // mapping `result.data` into a new model but that model doesn't fit the `ManagementQueueResult` format.
            this.subs.sink = this.appService.getData<UWResponse<SearchCasesResults[]>>(`CaseManager/Search/${queue.id}`, this.clientId).subscribe(results => {
                const searchResults = results.data ?? [];

                // TECH DEBT: We need to refactor this because it's a mess and typings aren't matching up. I think this entire component might
                // expect the bad typings to exist.
                this.cases = searchResults.map(c => (Object.assign({}, c, {
                    canResume: this.interviewService.canResumeCase(c.status),
                    note: c.displayNote,
                    usersInCase: this.getPresenceUsers(c),
                    clientId: c.clientId,
                    checked: false
                }, c.mapkeys.reduce((dict, next) => {
                    dict[next.name] = next.value;
                    return dict;
                }, {})))) as any;

                this.setDataSourceAttributes();
                this.caseDataSource.data = this.cases;

                this.updateFilteredCases();
                this.saveSettings();

                this.filterCases();

                this.caseDataSource.sortingDataAccessor = (item, property) => {
                    const column = this.selectedQueue.managementQueueColumnMapKeys.find(x => x.columnDefinition == property);

                    if (item[column?.mapKeyName] !== undefined) {
                        const value = item[column.mapKeyName];
                
                        if (typeof value === 'string') {
                            if(this.utilityService.isDate(value)){
                                return new Date(value).getTime();
                            }
                            return value.toLowerCase();
                        } else if (typeof value === 'number') {
                            return value;
                        }
                    }

                    return '';
                };
                this.monitoringService.endMetric(metricEvent);
                this.monitoringService.flushMetrics();
            });
        }
    }

    getSortDirection(currentDirection) {
        if (currentDirection === 'asc') {
            return null;
        }

        if (currentDirection === 'desc') {
            return 'asc';
        }

        if (currentDirection === null) {
            return 'desc';
        }
    }

    sortUpdated($event) {
        this.saveSettings();
        this.filterCases();
    }

    setSelectedQueue(tabIndex: number) {
        const queue = this.queues[tabIndex];
        if (this.selectedQueue !== queue && this.selectedQueue) {
            this.selectedQueue.isNewQueue = false;
            this.selection.clear();
        }

        const sortedColumns = _.orderBy(queue.managementQueueColumnMapKeys, ['order']);
        const columns = sortedColumns.map(item => item.columnDefinition).filter((value, index, self) => self.indexOf(value) === index);
        this.columnsToDisplay = columns;

        this.customColumns = columns.filter((value) => value !== 'selection');

        this.getCases(queue);
    }

    restartInterview(record) {
        this.routingService.navigateToRoute(RoutesEnum.interviewRestart, {
            clientCode: record.clientCode,
            accountCode: record.accountCode,
            versionNumber: record.accountVersionNumber,
            caseId: record.caseId
        });
    }

    saveSettings() {
        const settings = {
            caseData: this.cases,
            filter: this.filterValue,
            selectedClients: this.selectedClients?.value,
            selectedAssignees: this.selectedAssignees?.value,
            queues: this.queues,
            selectedQueue: this.selectedQueue,
            pageIndex: this.paginator?.pageIndex,
            pageSize: this.paginator?.pageSize,
            sortActiveColumn: this.sort?.active,
            sortDirection: this.sort?.direction,
            columnMapkeys: this.columnMapkeys
        };

        this.nbwStateService.saveSettings(settings);
    }


    filterCases() {
        const assigneeIds = this.selectedAssignees.value;

        let dataSource = this.nbwStateService.getSavedSettings() ? this.nbwStateService.getSavedSettings().caseData : this.caseDataSource.data;

        if (assigneeIds?.length !== 0 && dataSource && dataSource !== null) {
            dataSource = dataSource.filter(caseData => assigneeIds?.includes(caseData.assignedTo?.toLowerCase()));
        }

        this.caseDataSource.data = dataSource;
    }

    toggleAllSelection() {
        if (this.isAllSelected()) {
            this.selection.clear();
        } else {
            this.caseDataSource.filteredData.forEach(row => {
                if (!this.selection.isSelected(row)) {
                    this.selection.select(row);
                }
            });
        }
    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.caseDataSource.filteredData.length;
        return numSelected === numRows;
    }

    getSelectedCases(): ManagementQueueResult[] {
        return this.selection.selected;
    }


    assignUser() {
        const user = this.clientUsers.find(x => x.id === this.selectedUser.value);
        const caseAssignees = this.buildCaseAssigneeList(user.id);
        const completedCases = [];
        const errorCases = [];

        this.subs.sink = this.caseAssigneeService.setCaseUserAssignee(caseAssignees).subscribe(response => {
            response.forEach(res => {
                if (typeof res.userErrors !== 'undefined') {
                    errorCases.push(res);
                } else {
                    completedCases.push(res);
                }
            });

            if (completedCases.length > 0) {
                this.toastCompletedCases(completedCases);
            }

            if (errorCases.length > 0) {
                this.toastErrorCases(errorCases);
            }
            this.clearSelection();

            this.useSavedData = false;
            this.getCases(this.selectedQueue);
        });
    }

    public toastErrorCases(errorCases: any[]) {
        if (errorCases.length > 0) {
            this.notificationService.showNotification({
                severity: NotificationSeverity.Error,
                message: `${errorCases.length} ${errorCases.length > 1 ? 'cases' : 'case'} errored.`
            });
        }
    }

    public toastCompletedCases(completedCases: any[]) {
        const user = this.clientUsers.find(x => x.id === this.selectedUser.value);
        if (completedCases.length > 0) {
            this.notificationService.showNotification({
                severity: NotificationSeverity.Success,
                message: `${completedCases.length} ${completedCases.length > 1 ? 'cases have' : 'case has'} been assigned to ${user.firstName} ${user.lastName}`
            });
            this.selection.selected.forEach(selectedCase => {
                selectedCase.assignedTo = user.id;
                selectedCase.assignedToFullName = `${user.firstName} ${user.lastName}`;
            });
        }
    }

    buildCaseAssigneeList(userId?) {
        const caseAssignees: CaseAssignee[] = [];

        this.getSelectedCases().forEach(selectedCase => {
            const caseAssignee: CaseAssignee = {
                caseId: selectedCase.caseId,
                clientCode: selectedCase.clientId,
                userId: userId ?? selectedCase.assignedTo
            };

            caseAssignees.push(caseAssignee);
        });

        return caseAssignees;
    }

    claimCase() {
        const completedCases = [];

        const caseAssignees = this.buildCaseAssigneeList();

        this.subs.sink = this.caseAssigneeService.removeCaseUserAssignee(caseAssignees).subscribe(response => {
            response.forEach(res => {
                completedCases.push(res);

                this.getCases(this.selectedQueue);
            });

            this.notificationService.showNotification({
                severity: NotificationSeverity.Success,
                message: `${completedCases.length} ${completedCases.length > 1 ? 'cases' : 'case'} cleared!`
            });

            this.selection.selected.forEach(selectedCase => {
                selectedCase.assignedTo = null;
            });
            this.clearSelection();
        });
    }

    canSelectedCasesBeAssign() {
        let areAllAssignable = true;
        this.selection.selected.forEach(selectedRow => {
            if (!selectedRow.canAssignTo) {
                areAllAssignable = false;
            }
        });

        return areAllAssignable;
    }

    clearSelection() {
        this.selection.clear();
        this.matMenuTriggers.forEach((matMenuTrigger) => matMenuTrigger.closeMenu());
        this.selectedUser.reset();
        this.userInput.closePanel();
    }

    getOptionText(option) {
        const user = this.clientUsers.find((clientUser) => clientUser.id === option);
        if (user) {
            return `${user.firstName} ${user.lastName}`;
        }
    }

}
