import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'mrs-btn-primary',
    templateUrl: './mrs-btn-primary.component.html',
    styleUrls: ['./mrs-btn-primary.component.scss']
})
export class MrsBtnPrimaryComponent {
    @Input() buttonLabel: string;
    @Input() buttonId: string;
    @Input() isSubmitButton = false;
    @Input() disabled = false;
    @Input() iconName: string;
    @Input() iconPosition = 'before';
    @Output() onClick = new EventEmitter<Event>();
}
