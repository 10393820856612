import { NotificationService, NotificationSeverity, SignalRService } from '@Services';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DocusignStatusEnum } from '@Enums';


@Component({
    selector: 'signature-loading',
    templateUrl: 'signature-loading.component.html',
    styleUrls: ['signature-loading.component.scss']
})
export class SignatureLoadingComponent implements OnInit {
    message: string;
    generatingDocuments= true;
    generatingDocumentsDone= false;
    packagingDocuments= true;
    packagingDocumentsDone= false;
    preparingSignature= true;
    totalNumberOfDocuments = 0;
    numberOfDocumentsComplete = 0;
    signalRConnection: any;

    constructor(
        private signalRService: SignalRService,        
        private notificationService: NotificationService,
        private dialog: MatDialog,
        public dialogRef: MatDialogRef<SignatureLoadingComponent>,
        @Inject(MAT_DIALOG_DATA) public context: any,
        public changeDetector: ChangeDetectorRef) {
    }

    async ngOnInit() {        
        this.signalRConnection = this.context.signalRConnection;

        this.signalRConnection.on('docusignStatusNotification', (event) => {
            
            console.log(`Docusign SignalR connection:  ${JSON.stringify(event)}`);
            if (this.context.caseId === event.caseDetailId || this.context.caseId == event.caseId) {
                switch (event.docusignStatus) {                    
                    case DocusignStatusEnum.GeneratingDocuments: {
                        this.totalNumberOfDocuments = event.numberOfDocuments;
                        break;
                    }              
                    case DocusignStatusEnum.GeneratingDocumentsComplete: {
                        this.generatingDocuments = false;
                        this.generatingDocumentsDone = true;
                        break;
                    }
                    case DocusignStatusEnum.DocumentGenerationComplete: {
                        this.numberOfDocumentsComplete++;
                        this.totalNumberOfDocuments = event.numberOfDocuments;
                        break;
                    }
                    case DocusignStatusEnum.PackagingDocuments: {
                        this.packagingDocuments = false;
                        this.generatingDocuments = false;
                        this.packagingDocumentsDone = true;
                        break;
                    }
                    case DocusignStatusEnum.DocumentGenerationFailed: {
                        this.dialogRef.close();
                        this.notificationService.showNotification({ severity: NotificationSeverity.Error, message: 'Document generation failed.' });
                        break;
                    }
                }
                this.message = event.docusignStatus;
            }
        });
    }

    ok() {
        this.dialogRef.close();
    }

    cancel() {
        this.dialogRef.close();
    }
}
