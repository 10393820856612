<div class="mx-auto mt-3" style="width: 100%">
  <mat-card appearance="outlined">
    <mat-card-title style="min-height: 36px;">

      <button mat-raised-button color="primary" class="float-right material-default" (click)="addEnvelope()"
        *ngIf="selectedEnvelope == null">
        <i aria-hidden="true" class="fa fa-plus"></i> ADD
      </button>
    </mat-card-title>
    <mat-card appearance="outlined" *ngFor="let envelope of envelopes" class="mt-3"
      [ngClass]="{'envelope-card': selectedEnvelope != envelope}">
      <mat-card-content>
        <div class="flex flex-row" (click)="selectEnvelope(envelope)">
          <span>{{ envelope.name }}</span>
          <div class="flex-1"></div>
          <div style="justify-content: flex-end">
            <i aria-hidden="true" class="fa fa-trash" (click)="deleteAccountEnvelope(envelope)" *ngIf="envelope.id != null"></i>
          </div>
        </div>
        <div *ngIf="selectedEnvelope === envelope">
          <form class="px-5 py-3" [formGroup]="envelopeForm" (ngSubmit)="saveEnvelope()" novalidate
            autocomplete="off">
            <mat-form-field class="w-100">
              <mat-label>Name</mat-label>
              <input matInput formControlName='name'>
              <mat-error *ngIf="formSubmitted && envelopeForm.get('name').hasError('required')">
                Label
                <strong>required</strong>
              </mat-error>
            </mat-form-field>
            <auto-suggest [list]="workflowTypes" placeholder="Workflow Type" field="name" dataKey="id"
              formControlName="workflowTypeId"
              [showRequiredError]="formSubmitted && envelopeForm.get('workflowTypeId').hasError('required')">
            </auto-suggest>
            <auto-suggest [list]="documents" placeholder="Document Certificate" field="name" dataKey="id"
            formControlName="documentId"
            [showRequiredError]="formSubmitted && envelopeForm.get('documentId').hasError('required')">
          </auto-suggest>
            <mat-slide-toggle class="mr-2 mb-3" formControlName="isCaseSummary">
                Is Case Summary
            </mat-slide-toggle>
            <mat-card appearance="outlined" cdkDropList (cdkDropListDropped)="drop($event)" *ngIf="envelope.id && envelope.id != null">
              <mat-card-header>
              <mat-card-title-group>
                <mat-card-title>
                  <h2>Signers</h2>
                </mat-card-title>
                <button mat-flat-button color="primary" type="button" class="float-right"
                  (click)="addEnvelopeSigner()" *ngIf="selectedEnvelopeSigner == null">
                  <i aria-hidden="true" class="fa fa-plus"></i> ADD
                </button>
              </mat-card-title-group>
            </mat-card-header>
              <mat-card appearance="outlined" *ngFor="let envelopeSigner of envelopeSigners" class="mt-3"
                [ngClass]="{'envelope-card': selectedEnvelopeSigner != envelopeSigner}" cdkDrag>
                <mat-card-content>
                  <div class="flex flex-row" (click)="selectEnvelopeSigner(envelopeSigner)">
                    <i aria-hidden="true" class="fa fa-grip-horizontal mr-2 pt-1" cdkDragHandle>
                    </i>
                    <span>{{ getAccountSignerLabel(envelopeSigner) }}</span>
                    <div class="flex-1"></div>
                    <div style="justify-content: flex-end">
                      <i aria-hidden="true" class="fa fa-trash" (click)="deleteAccountEnvelopeSigner(envelopeSigner, $event)"
                        *ngIf="envelopeSigner.id != null"></i>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </mat-card>
            <div class="pt-4 flex" style="justify-content: flex-end;">              
              <mrs-btn-tertiary buttonLabel="Cancel" buttonId="cancelButton"
                (onClick)="cancelEnvelope(envelope)">Cancel</mrs-btn-tertiary>
              <mrs-btn-primary buttonLabel="Save" buttonId="billingSave" isSubmitButton="true"></mrs-btn-primary>
            </div>
          </form>
        </div>
      </mat-card-content>
    </mat-card>
  </mat-card>
</div>
