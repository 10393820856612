import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AppService, MonitoringService } from '@App';
import { AttachDocumentDialogComponent } from '@CaseSummary/attachments';
import { CaseDocumentTypes, FeatureToggle } from '@Enums';
import { Account, Client } from '@Models';
import { CaseDetailsDTO, CaseDocumentsDTO } from '@Models/domain/dto';
import {
    CaseAttachmentService,
    CaseDocumentsDataService,
    CaseSummaryService,
    ConfirmationDialogService,
    FeatureManagerService,
    ReportDownloadService,
} from '@Services';
import { FileUtils, Utils } from '@Utils';
import { ViewDocumentDialog, ViewDocumentDialogData } from 'app/components/view-document-dialog';
import { SubSink } from 'subsink';


@Component({
    selector: 'case-summary-document-attachments',
    host: { 'class': 'content' },
    templateUrl: './case-summary-document-attachments.component.html',
    styleUrls: ['../documents/case-summary-documents.component.scss'],
})
export default class CaseSummaryDocumentAttachmentsComponent implements OnInit, OnDestroy {
    @ViewChild('audioControl') audioControlRef: ElementRef;
    @Input() case: CaseDetailsDTO;
    @Input() selectedCaseId;

    constructor(
        private route: ActivatedRoute,
        private appService: AppService,
        private reportDownloadService: ReportDownloadService,
        private caseAttachmentService: CaseAttachmentService,
        private confirmationService: ConfirmationDialogService,
        public featureManagerService: FeatureManagerService,
        private caseSummaryService: CaseSummaryService,
        private monitorService: MonitoringService,
        private sanitizer: DomSanitizer,
        public dialog: MatDialog,
        private caseDocumentsDataService: CaseDocumentsDataService
    ) {
    }

    client: Client;
    account: Account;
    selectedDocs: any = [];
    selectedDocument: any;
    isRegenerating: boolean;
    caseDocuments: CaseDocumentsDTO[] = [];


    useV2 = false;
    documentHeader: string;
    pdfResult: any;
    dataToDiplsay: any;
    textToDiplsay: any;
    audioData: any;
    imageBlobUrl: any;
    selectedType = 'pdf';
    showPdf = false;
    page = 1;
    fileType = 'any';
    tiffPageCount: any;

    protected canViewDetails = false;
    protected canAddAttachments = false;
    protected canDownload = false;
    protected isLoaded = false;

    private subs = new SubSink();

    protected toolTipText = "Preview does not support TIFF file types.";

    //#region Helpers

    protected documentIsTIFF = (caseDocument: CaseDocumentsDTO) => FileUtils.isTiff(caseDocument);

    protected subscribeCaseDocumentAttachments() {
        this.subs.add(this.caseDocumentsDataService.loadAttachments(this.selectedCaseId).subscribe({
            next: response => {
                this.caseDocuments = response;
                this.selectedDocs = [];
            }
        }));
    }

    protected hasViewPermission = (feature: string) => this.appService.hasViewPermission(feature);

    //#endregion
    //#region Lifecycle

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.selectedCaseId = params.caseId;
        });

        this.route.data.subscribe((data) => {
            this.account = data.account;
            this.client = data.client;
        });

        this.canViewDetails = this.appService.checkACL('CaseSummary', 'W');
        this.canAddAttachments = this.appService.checkACL('CaseAttachments', 'W');
        this.canDownload = this.hasViewPermission('Download Documents and Attachments');
        this.useV2 = this.featureManagerService.getByName(FeatureToggle.CaseDocumentsAttachmentsUploadInChunks).enabled;

        this.subscribeCaseDocumentAttachments();
    }


    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    //#endregion
    //#region Handlers

    protected refreshAttachments() {
        this.caseDocumentsDataService.reloadAttachments(this.selectedCaseId);
    }

    //#endregion

    viewDocument(caseDocument: CaseDocumentsDTO) {
        this.monitorService.logEvent(`${this.case.caseNumber} - Viewed Summary Document`);

        this.dialog.open<ViewDocumentDialog, ViewDocumentDialogData, ViewDocumentDialogData>(ViewDocumentDialog, {
            height: '100%',
            minWidth: Utils.isDesktop() ? '55vw' : '90%',
            maxWidth: null,
            maxHeight: '90vh',
            data: {
                client: this.client,
                account: this.account,
                caseId: this.selectedCaseId,
                caseDocument,
                caseDocumentType: CaseDocumentTypes.Attachment
            }
        });
    }

    downloadDocument(document) {
        if (this.useV2) {
            this.subs.add(this.caseAttachmentService.getAttachmentUri(this.account.clientId, this.account.id, this.selectedCaseId, document.id).subscribe(result => {
                window.open(result, '_blank');
            }));
        } else {
            this.selectedDocument = document;
            const url = `CaseDocuments/${document.id}/Download`;
            const fileType = `application/${document.doctypeName}`;
            this.reportDownloadService.downloadReport(fileType, url);
        }
    }

    addAttachment() {
        const dialogRef = this.dialog.open(AttachDocumentDialogComponent, {
            width: '800px',
            data: {
                caseId: this.selectedCaseId,
                document: null,
                account: this.account
            },
            disableClose: true
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.caseDocumentsDataService.reloadAttachments(this.selectedCaseId);
            }
        });
    }

    deleteAttachment(caseDocument) {
        this.confirmationService.open({
            title: caseDocument.remoteName,
            message: `Are you sure you want to remove this attachment?`,
            showCancel: true,
            onOk: () => {
                this.subs.add(this.appService.deleteData(`CaseDocumentAttachment?id=${caseDocument.id}`).subscribe(
                    data => {
                        if (data.status === 'success') {
                            this.appService.showMsg('success', 'Attachment deleted successfully');
                            this.caseDocumentsDataService.reloadAttachments(this.selectedCaseId);
                        } else {
                            this.appService.showMsg('error', data.message);
                        }
                    }
                ));
            }
        });
    }

    editAttachment(caseDocument) {
        const dialogRef = this.dialog.open(AttachDocumentDialogComponent, {
            width: '800px',
            data: {
                caseId: this.selectedCaseId,
                document: caseDocument,
                account: this.account
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.caseDocumentsDataService.reloadAttachments(this.selectedCaseId);
            }
        });
    }
}

