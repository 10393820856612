<!-- Spinner -->
<ng-container *ngIf="!featureTogglesLoaded || !notesLoaded">
    <mat-spinner diameter="20"></mat-spinner>
</ng-container>

<!-- Table -->
<ng-container *ngIf="featureTogglesLoaded && notesLoaded">
    <table mat-table
        multiTemplateDataRows
        [dataSource]="uwRequirementsDataSet"
        aria-label="Elements"
        class="mrs-table case-requirements-expand-table mat-elevation-z0">

        <!-- Expand Arrow Column -->
        <ng-container matColumnDef="expand">
            <th mat-header-cell *matHeaderCellDef class="column-header" aria-label="row actions">&nbsp;</th>
            <td mat-cell *matCellDef="let element" style="cursor: default" class="cell-style">
                <button mat-icon-button aria-label="expand row" (click)="(expandedElement = (expandedElement === element) ? null : element); $event.stopPropagation()">
                    <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
                    <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
                </button>
            </td>
        </ng-container>
        <!-- Name Column -->
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef class="column-header">Name</th>
            <td mat-cell *matCellDef="let element" style="cursor: default" class="cell-style">
                {{ element.displayName }}
            </td>
        </ng-container>
        <!-- Ordered Date Column -->
        <ng-container matColumnDef="ordered">
            <th mat-header-cell *matHeaderCellDef class="column-header">Ordered</th>
            <td mat-cell *matCellDef="let element" style="cursor: default" class="cell-style">
                <span [matTooltip]="element.timeOfDayOrdered" matTooltipPosition="after">{{ element.dateOrdered }}</span>
            </td>
        </ng-container>
        <!-- Follow Up Date Column -->
        <ng-container matColumnDef="followUp">
            <th mat-header-cell *matHeaderCellDef class="column-header">Follow&nbsp;Up</th>
            <td mat-cell *matCellDef="let element" style="cursor: default" class="cell-style">
                <span
                    matTooltipPosition="after"
                    class="follow-up"
                    [matTooltip]="element.timeOfDayOrdered"
                    [ngClass]="{ 'today':  element.isFollowUpToday, 'past':  element.isFollowUpPast }">
                    {{ element.dateFollowUp }}
                </span>
            </td>
        </ng-container>
        <!-- Receivd Date Column -->
        <ng-container matColumnDef="received">
            <th mat-header-cell *matHeaderCellDef class="column-header">Received</th>
            <td mat-cell *matCellDef="let element" style="cursor: default" class="cell-style">
                <span [matTooltip]="element.timeOfDayReceived" matTooltipPosition="after">{{ element.dateReceived }}</span>
            </td>
        </ng-container>
        <!-- Status Column -->
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef class="column-header">Status</th>
            <td mat-cell *matCellDef="let element" class="cell-style">
                <div [ngClass]="element.statusClassName" [ngStyle]="{'margin-top': element.hasSummaryStatus ? '12.5px' : '0px' }" *ngIf="!(element.isRunning)">
                    <div>
                        {{ element.statusDisplay }}
                        <mat-icon *ngIf="element.isPending">autorenew</mat-icon>
                    </div>
                    <div *ngIf="element.isSummarizing" class="status-remaining-attempts">
                        <div class="row summary-related-row">
                            <mat-icon class="summarizing-icon" svgIcon="autorenew" />
                            <label class="summarizing-label">Summarizing</label>
                        </div>
                    </div>
                    <div *ngIf="element.isSummarized" class="status-remaining-attempts">
                        <div class="row summary-related-row">
                            <mat-icon class="summarized-icon" svgIcon="check" />
                            <label class="summarized-label">Summarized</label>
                        </div>
                    </div>
                    <div *ngIf="element.isSummaryFailed" class="status-remaining-attempts">
                        <div class="row summary-related-row">
                            <mat-icon class="summary-failed-icon" svgIcon="close-circle-outline" />
                            <label class="summary-failed-label">Summary Failed</label>
                        </div>
                    </div>
                    <div *ngIf="element.hasIntegration" class="status-remaining-attempts">{{ element.retryMessage }}</div>
                </div>
                <div class="status-display-running" *ngIf="element.isRunning">
                    <span>Running</span><mat-spinner diameter="20"></mat-spinner>
                </div>
            </td>
        </ng-container>
        <!-- Action Menu Column -->
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef class="column-header action-column-header" style="width: 132px; left: 0 !important;">Actions</th>
            <td mat-cell *matCellDef="let element" class="cell-style action-cell">
                <!-- View Document Icon -->
                <button mat-icon-button
                    (click)="$event.stopPropagation(); handleViewDocuments(UWRequirementTableActions.ViewDocumentIcon, element);"
                    [disabled]="!element.hasDocument"
                    [disableRipple]="!element.hasDocument"
                    class="icon-button">
                    <mat-icon>open_in_new</mat-icon>
                </button>

                <!-- Action Menu -->
                <button class="icon-button" mat-icon-button [matMenuTriggerFor]="menu" [disabled]="baseProps.readonly" (click)="$event.stopPropagation();">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <uw-requirements-actions-not-ordered *ngIf="element.status === CaseRequirementStatuses.NotOrdered || element.status.toLowerCase() === 'not started'" [props]="tableProps" [caseRequirement]="element" (onMenuAction)="handleMenuAction($event)"></uw-requirements-actions-not-ordered>
                    <uw-requirements-actions-ordered *ngIf="element.status === CaseRequirementStatuses.Ordered" [props]="tableProps" [caseRequirement]="element" (onMenuAction)="handleMenuAction($event)"></uw-requirements-actions-ordered>
                    <uw-requirements-actions-received *ngIf="element.status === CaseRequirementStatuses.Received" [props]="tableProps" [caseRequirement]="element" (onMenuAction)="handleMenuAction($event)"></uw-requirements-actions-received>
                    <uw-requirements-actions-waived *ngIf="element.status === CaseRequirementStatuses.Waived" [baseProps]="baseProps" [props]="tableProps" [caseRequirement]="element" (onMenuAction)="handleMenuAction($event)"></uw-requirements-actions-waived>
                    <uw-requirements-actions-completed *ngIf="element.status === CaseRequirementStatuses.Completed" [props]="tableProps" [caseRequirement]="element" (onMenuAction)="handleMenuAction($event)"></uw-requirements-actions-completed>
                    <uw-requirements-actions-failed *ngIf="element.status === CaseRequirementStatuses.Failed" [props]="tableProps" [caseRequirement]="element" (onMenuAction)="handleMenuAction($event)"></uw-requirements-actions-failed>
                    <uw-requirements-actions-pending *ngIf="element.status === CaseRequirementStatuses.Pending" [props]="tableProps" [caseRequirement]="element" (onMenuAction)="handleMenuAction($event)"></uw-requirements-actions-pending>
                    <uw-requirements-actions-cancelled *ngIf="element.status === CaseRequirementStatuses.Cancelled" [props]="tableProps" [caseRequirement]="element" (onMenuAction)="handleMenuAction($event)"></uw-requirements-actions-cancelled>
                </mat-menu>
            </td>
        </ng-container>

        <!-- Expanded Row Content -->
        <ng-container matColumnDef="expandedContent">
            <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                <div class="expanded-content-wrap" *ngIf="element == expandedElement" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                    <div class="expanded-content">
                        <uw-notes-add-form class="add-note" [baseProps]="baseProps" [props]="element.uwNotesAddForm"></uw-notes-add-form>
                        <uw-notes-list class="list-notes" [baseProps]="baseProps" [notes]="element.notes"></uw-notes-list>
                    </div>
                </div>
            </td>
        </ng-container>

        <!-- Templates -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row
            *matRowDef="let element; columns: displayedColumns;"
            [class.expanded-row]="expandedElement === element"
            (click)="expandedElement = (expandedElement === element) ? null : element">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedContent']" class="expanded-content-row"></tr>
        <!-- No Data Template -->
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" [attr.colspan]="displayedColumns.length">
                <div class="no-data">{{ noDataMessage }}</div>
            </td>
        </tr>
    </table>
</ng-container>