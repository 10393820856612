import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BaseFormFieldServices, BaseInterviewFormFieldComponent } from '../base-interview-form-field/base-interview-form-field.component';

@Component({
    selector: 'repeat-block',
    templateUrl: './repeat-block.component.html',
    styleUrls: ['./repeat-block.component.scss']
})

export class RepeatBlockComponent extends BaseInterviewFormFieldComponent implements OnInit {

    config;
    group: UntypedFormGroup;
    expanded: boolean;
    isSelected: boolean;
    formIndex: string;
    blocks: any = [];
    blocksUsed: any = [];
    hasValidationAction = false;
    shouldCallActions = false;

    constructor(public services: BaseFormFieldServices) {
        super(services);
    }

    ngOnInit() {
        if (this.interviewMode) {
            if (this.config.answerValue !== undefined && this.config.answerValue !== '') {
                this.group.get(this.config.id).setValue(Number(this.config.answerValue));
            } else {
                this.group.get(this.config.id).setValue('1');
            }
            for (let i = 0; i < this.config.MaxRepeatBlocks; i++) {
                const repeatBlock: any = {};
                repeatBlock.repeatId = this.config.id + 'block' + i;
                // cloned questions array to be displayed in this repeat block
                repeatBlock.questions = [];
                // cloned reflexive questions array that are present for multichoicesingleanswer questions in this block
                repeatBlock.refQuestions = [];
                for (const eachQuestion of this.config.repeatBlockQuestions) {
                    const activeSection = this.services.interviewService.getActiveSection();

                    const originalQuestion = activeSection.questions.find(q => q.id === eachQuestion.repeatedQuestionId);
                    const actions = originalQuestion.questionActions.find(x => x.actionsId === "00000000-0000-0000-0000-000000000001" || x.actionsId === "00000000-0000-0000-0000-000000000002");
                    if (!actions) {
                        originalQuestion.display = true;
                    }
                    this.addQuestionToBlock(originalQuestion, repeatBlock, i, activeSection, 'normal');
                }
                this.blocks.push(repeatBlock);
            }
            // blocksUsed array is used to display blocks in interview. and blocks are used for questions reference with cloned values.(these cloned questions are used when new block is added)
            for (const eachBlock of this.blocks) {
                if (eachBlock.isIncluded) {
                    this.blocksUsed.push(eachBlock);
                }
            }

            this.config.blocks = this.blocks;
            super.subscribeToTextUpdates();
        }
    }

    addBlock() {
        if (this.interviewMode) {
            const currentLength = this.blocksUsed.length;
            this.group.get(this.config.id).setValue(Number(currentLength + 1));
            const activeSection = this.services.interviewService.getActiveSection();

            for (const eachQuestion of this.blocks[currentLength].questions) {
                const cloneQuestion = Object.assign({}, eachQuestion);
                const currentblock = eachQuestion.repBlockMapKeys.find(x => x.blockNumber == currentLength);
                if (currentblock != null) {
                    cloneQuestion.mapkeysId = currentblock.mapkeysId;
                    cloneQuestion.mapKeyName = currentblock.mapkeyName;
                }
                activeSection.questions.push(cloneQuestion);
            }
            for (const eachQuestion of this.blocks[currentLength].refQuestions) {
                const cloneQuestion = Object.assign({}, eachQuestion);
                const currentblock = eachQuestion.repBlockMapKeys.find(x => x.blockNumber == currentLength);
                if (currentblock != null) {
                    cloneQuestion.mapkeysId = currentblock.mapkeysId;
                    cloneQuestion.mapKeyName = currentblock.mapkeyName;
                }
                activeSection.questions.push(cloneQuestion);
            }

            if (currentLength < this.blocks.length) {
                this.blocks[currentLength].questions.forEach(q => {
                    q.answerValue = '';
                });
                this.blocksUsed.push(this.blocks[currentLength]);
            }
        }
    }

    removeBlock() {
        if (this.interviewMode) {
            const currentLength = this.blocksUsed.length;
            const activeSection = this.services.interviewService.getActiveSection();

            for (const eachQuestion of this.blocks[currentLength - 1].questions) {
                const existingQuestionIndex = activeSection.questions.findIndex(q => q.mapkeysId === eachQuestion.mapkeysId);
                if (existingQuestionIndex != null && existingQuestionIndex !== -1) {
                    activeSection.questions.splice(existingQuestionIndex, 1);
                }
            }
            for (const eachQuestion of this.blocks[currentLength - 1].refQuestions) {
                const existingQuestionIndex = activeSection.questions.findIndex(q => q.mapkeysId === eachQuestion.mapkeysId);
                if (existingQuestionIndex != null && existingQuestionIndex !== -1) {
                    activeSection.questions.splice(existingQuestionIndex, 1);
                }
            }
            this.blocksUsed.pop();
            this.group.get(this.config.id).setValue(this.blocksUsed.length);
            // remove block form reference from interviewService
            delete this.services.interviewService.repeatForms[this.config.id + 'block' + this.blocksUsed.length];
            // need to call validate sum action as a block is removed and sum has changed.
            this.callValidateAction();
        }
    }

    callValidateAction() {
        if (this.hasValidationAction) {
            const currentBlock = this.blocksUsed[0];
            for (const eachQuestion of currentBlock.questions) {

                if (eachQuestion.questionActions && eachQuestion.questionActions.length > 0) {
                    for (const eachAction of eachQuestion.questionActions) {
                        if (eachAction.actions.id === '00000000-0000-0000-0000-000000000013') {
                            this.services.interviewService.callPostActions(eachQuestion);
                        }
                    }
                }
            }
        }
    }

    ngAfterViewInit() {
        this.callValidateAction();
    }

    ngOnDestroy() {
        for (const eachBlockIndex in this.blocksUsed) {
            delete this.services.interviewService.repeatForms[this.config.id + 'block' + eachBlockIndex];
        }
    }

    updateQuestionBasedOnActions(question) {
        // for validate sum and other question validations
        for (const eachActionResult of question.questionActions) {
            if (eachActionResult.actions.id === '00000000-0000-0000-0000-000000000013') {
                const errorMessage = eachActionResult.actionResults && eachActionResult.actionResults.isError ? eachActionResult.actionResults.error.message : '';
                if (errorMessage) {
                    question.errorData = errorMessage;
                }
            }
            if (eachActionResult.actions.id === '00000000-0000-0000-0000-000000000005') {

                if (eachActionResult.actionResults && eachActionResult.actionResults.data && eachActionResult.actionResults.data.hasDuplicateSSN) {

                    question.errorData = eachActionResult.actionResults.data;
                } else {
                    question.errorData = null;
                }
            }
        }
    }

    addQuestionToBlock(originalQuestion, repeatBlock, i, actiiveSection, questionType) {
        // first question is directly added without cloning, as mapkeys are created for extra blocks but not first question itself.
        originalQuestion.repeatFormId = this.config.id + 'block' + 0;
        const newQuestion = Object.assign({}, originalQuestion);

        newQuestion.display = originalQuestion.display;
        if (newQuestion.questionActions && newQuestion.questionActions.length > 0) {
            for (const eachAction of newQuestion.questionActions) {
                if (eachAction.actions.id === '00000000-0000-0000-0000-000000000013') {
                    // this property is used to call for validate action once the view is created (used for back and forth or when removing a block)
                    this.hasValidationAction = true;
                }
            }
        }
        // for extra blocks that are added, original question is cloned, then its mapkeysid is reassigned to new property and also its formid(config.id+ block num).
        if (i > 0) {
            newQuestion.repeatFormId = this.config.id + 'block' + i;
            if (newQuestion.repBlockMapKeys.length > 0) {
                newQuestion.mapkeysId = newQuestion.repBlockMapKeys[i - 1].mapkeysId;
                const checkQuestion = actiiveSection.questions.find(q => q.mapkeysId === newQuestion.mapkeysId);

                if (checkQuestion) {
                    checkQuestion.repeatFormId = this.config.id + 'block' + i;
                    repeatBlock.isIncluded = true;
                }
                this.services.interviewService.setQuestionAnswer(newQuestion, checkQuestion ? checkQuestion.answerValue : '');
            }
        } else {
            // if the answer value already exists or for initial block, it is included already to display.
            repeatBlock.isIncluded = true;
        }
        this.updateQuestionBasedOnActions(newQuestion);
        if (questionType === 'normal') {
            repeatBlock.questions.push(newQuestion);
        } else {
            repeatBlock.refQuestions.push(newQuestion);
        }
        // if any reflexive questions are present, they are also cloned and added to repeat block.
        if (newQuestion.reflexiveQuestions && newQuestion.reflexiveQuestions.length > 0) {
            for (const eachRefQuestion of newQuestion.reflexiveQuestions) {
                const originalRefQuestion = actiiveSection.questions.find(q => q.id === eachRefQuestion.refQuestionId);
                this.addQuestionToBlock(originalRefQuestion, repeatBlock, i, actiiveSection, 'reflexive');
            }
        }
    }
}
