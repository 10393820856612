import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { StorageKeysEnum } from 'app/enums';
import { FeatureToggle } from 'app/enums/feature-toggle.enum';
import { RoutesEnum, RoutingService } from '../';
import { AuthLegacyService } from '../../auth/auth-legacy.service';
import { FeatureManagerService } from '../global/feature-manager/feature-manager.service';
import { PurlService } from '../purl.service';

@Injectable()
export class AuthGuardService implements CanActivate {
    constructor(
    public authService: AuthLegacyService,
    public auth0Service: AuthService,
    public routingService: RoutingService,
    public featureManagerService: FeatureManagerService,
    public purlService: PurlService) { }

    canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {

        return new Promise(async (resolve) => {
            let loggedIn = false;

            if (this.useV2Auth()) {
                const purlUser = localStorage.getItem(StorageKeysEnum.PurlResult);
                if (purlUser){
                    resolve(await this.handleLoginV2());
                }else {
                    this.auth0Service.isAuthenticated$.subscribe(async result => {
                        loggedIn = result || this.purlService.isPurlUser();
                        if (loggedIn) {
                            resolve(await this.handleLoginV2());
                        } else {
                            const queryParams: any = {};

                            if (window.location.search !== ''){
                                const params = new URLSearchParams(window.location.search);

                                if (window.location.search !== ''){
                                    localStorage.setItem('customAttributes', window.location.search);
                                }

                                params.forEach(function(value, key) {
                                    queryParams[key] = value;
                                });
                            }

                            if(queryParams && queryParams !== null){
                                this.routingService.navigateToRoute(RoutesEnum.loginV2, null, queryParams);
                            } else {
                                this.routingService.navigateToRoute(RoutesEnum.loginV2);
                            }
                        }
                    });
                }
            } else {
                resolve(await this.handleLegacyLogin());
            }
        });
    }

    useV2Auth(){
        if (window.location.hash !== null && window.location.hash.indexOf('access_token') > 0) {
            localStorage.setItem('legacy', 'true');
            return false;
        }
        const legacy = JSON.parse(localStorage.getItem('legacy'));
        let useV2 = this.featureManagerService.getByName(FeatureToggle.GlobalUseAuthenticationV2)?.enabled;

        if (useV2 && (legacy === false || legacy == null)) {
            return true;
        }

        return false;
    }

    async handleLegacyLogin(): Promise<boolean> {
        let loggedIn = false;
        await this.authService.handleAuthentication(); // Parses the auth0 token if it exists.

        if (!this.authService.isAuthenticated()) {
            loggedIn = false;
        } else {
            loggedIn = await this.authService.loadAuthenticatedData();
        }

        if (!loggedIn) {
            this.authService.logout(); // clear old entries and nav to login
        }

        return Promise.resolve(loggedIn);
    }

    async handleLoginV2(): Promise<boolean> {
        let loggedIn = false;

        loggedIn = await this.authService.loadAuthenticatedData();

        if (!loggedIn) {
            this.routingService.navigateToRoute(RoutesEnum.loginV2);
        }

        return Promise.resolve(loggedIn);
    }
}
