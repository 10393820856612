import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Account, ClientAccountSetting } from '@Models';
import { AppService } from 'app/app.service';
import { AccountSettingsService, MapKeyService, QuestionService } from 'app/services';
import { SubSink } from 'subsink';


export type CaseSummarySidebarItem = {
    name: string;
    mapkeyId: string;
    entityHierarchy: string;
    order: number
};

export type CaseSummarySidebarItemForm = {
    name: FormControl<string>;
    mapkeyId: FormControl<string>;
    entityHierarchy: FormControl<string>;
    order: FormControl<number>;
};

export type CaseSummarySidebarForm = {
    items: FormArray<FormGroup<CaseSummarySidebarItemForm>>
}

@Component({
    selector: 'case-summary-sidebar',
    host: { 'class': 'd-flex flex-row flex-fill' },
    templateUrl: './case-summary-sidebar.component.html',
    styleUrls: ['case-summary-sidebar.component.scss']
})
export default class CaseSummarySidebarComponent implements OnInit, OnDestroy {
    @Input() account: Account;

    mapkeys: any[];
    subs = new SubSink();
    caseSummarySidebarAccountSetting: ClientAccountSetting;
    caseSummaryWriteAccess = false;

    caseSummarySidebarForm: FormGroup<CaseSummarySidebarForm>;

    get sidebarItems() {
        // Shorthand / Alias helper to just get the FormArray items
        return this.caseSummarySidebarForm.controls.items;
    }

    constructor(
        public accountSettingService: AccountSettingsService,
        public appService: AppService,
        public questionService: QuestionService,
        private fb: FormBuilder,
        private mapKeyService: MapKeyService
    ) {
    }

    //#region Helpers

    addItem = (item: FormGroup<CaseSummarySidebarItemForm>) => this.sidebarItems.push(item);

    setFormValues() {
        // JSON stored in the [Value] field of the [Config].dbo.[AccountSettings] table
        const sidebarItems = JSON.parse(this.caseSummarySidebarAccountSetting.value);// as CaseSummarySidebarItem[];

        sidebarItems.forEach((sidebarItem: CaseSummarySidebarItem) => {
            const formGroup = this.fb.group<CaseSummarySidebarItemForm>({
                name: this.fb.control(sidebarItem.name, Validators.required),
                mapkeyId: this.fb.control(sidebarItem.mapkeyId, Validators.required),
                entityHierarchy: this.fb.control(sidebarItem.entityHierarchy),
                order: this.fb.control(sidebarItem.order),
            });

            if (!this.caseSummaryWriteAccess)
                formGroup.disable();

            this.addItem(formGroup);
        });
    }

    //#endregion
    //#region Subscriptions

    subscribeToAccountSettings() {
        this.subs.add(this.accountSettingService.getAccountSetting(this.account, 'caseSummarySidebar').subscribe(accountSetting => {
            if (accountSetting) {
                this.caseSummarySidebarAccountSetting = accountSetting;
                this.setFormValues();
            }
        }));
    }

    subscribeToMapkeys() {
        this.subs.add(this.mapKeyService.mapKeysObserver.subscribe(hasUpdates => {
            if (hasUpdates) {
                this.mapkeys = this.mapKeyService.mapKeys;
            }
        }));
    }

    //#endregion
    //#region Lifecycle

    ngOnInit() {
        this.caseSummarySidebarForm = this.fb.group({ items: this.fb.array<FormGroup<CaseSummarySidebarItemForm>>([]) });

        this.subscribeToAccountSettings();
        this.mapkeys = this.mapKeyService.mapKeys;
        this.subscribeToMapkeys();

        this.caseSummaryWriteAccess = this.appService.checkACL('CaseSummary', 'W');
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    //#endregion
    //#region Handlers

    handleAddRow(mapkeyId = '', entityHierarchy = '') {
        const formGroup = this.fb.group<CaseSummarySidebarItemForm>({
            name: this.fb.control('', Validators.required),
            mapkeyId: this.fb.control(mapkeyId, Validators.required),
            entityHierarchy: this.fb.control(entityHierarchy),
            order: this.fb.control(this.sidebarItems.length),
        });

        this.addItem(formGroup);
    }

    handleAddHeaderRow = () => this.handleAddRow('Header', 'Header');

    handleReorderDrop(event: CdkDragDrop<string[]>) {
        const { items } = this.caseSummarySidebarForm.controls;
        moveItemInArray(items.controls, event.previousIndex, event.currentIndex); // Sorts the items in the FormArray
        items.controls.forEach((item, index) => item.patchValue({ order: index })); // Update the `order` property with the new index
    }

    handleDeleteSidebarMapkey(index: number) {
        this.sidebarItems.removeAt(index);
    }

    saveSidebarMapkeys() {
        if (!this.caseSummarySidebarForm.valid)
            return;

        this.sidebarItems.controls.forEach(sidebarItemFormGroup => {
            const { mapkeyId, entityHierarchy } = sidebarItemFormGroup.controls;

            if (mapkeyId.value.toLowerCase() !== 'header') {
                const mapKeyMatch = this.mapKeyService.mapKeys.find(mapkey => mapkey.id === mapkeyId.value || mapkey.entityHierarchy === mapkeyId.value);
                if (!mapKeyMatch) {
                    console.log('Error: A match for this mapkey ' + mapkeyId.value + ' was not found');
                }
                else {
                    entityHierarchy.setValue(mapKeyMatch.entityHierarchy);
                    mapkeyId.setValue(mapKeyMatch.id);
                }
            } else {
                mapkeyId.setValue('Header');
                entityHierarchy.setValue('Header');
            }
        });

        const accountSetting: ClientAccountSetting = {
            id: this.caseSummarySidebarAccountSetting?.id,
            clientId: this.account.clientId,
            accountId: this.account.id,
            name: 'caseSummarySidebar',
            value: JSON.stringify(this.sidebarItems.value),
        };

        this.accountSettingService.saveAccountSetting(this.account, accountSetting).subscribe(() => {
            this.appService.showMsg('success', 'Saved successfully ...');
        });
    }

    //#endregion
}