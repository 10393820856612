import {Component, Inject, OnInit} from "@angular/core";
import {AngularEditorConfig} from "@kolkov/angular-editor";
import {MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {UntypedFormBuilder} from "@angular/forms";

@Component({
    selector: 'html-editor-modal',
    host: {'class': 'd-flex flex-row flex-fill'},
    templateUrl: 'html-editor-modal.html'
})

export class HTMLEditorModal implements OnInit {

    constructor(
    @Inject(MAT_DIALOG_DATA) public matDialogRefData
      : HTMLEditorProperties,
    public dialogRef : MatDialogRef<HTMLEditorModal>,
    private formBuilder : UntypedFormBuilder) {
    }

    editorConfig: AngularEditorConfig;
    confirmationText:string;
    htmlContentForm = this.formBuilder.group({
        htmlContent:['']
    });

    ngOnInit(): void {
        this.editorConfig = this.matDialogRefData.editorConfig;
        this.confirmationText = this.matDialogRefData.confirmationText;
        this.htmlContentForm.get('htmlContent').setValue(this.matDialogRefData.htmlText);
    }

    saveText(){
        this.dialogRef.close(this.htmlContentForm.get('htmlContent').value);
    }
}

export class HTMLEditorProperties{
    public editorConfig:AngularEditorConfig;
    public htmlText:string;
    public confirmationText:string;
    public titleText:string;
    public descriptionText:string;
    public helperText:string;
}
