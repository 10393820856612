import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { InterviewSectionSummaryDTO } from '@DTOs';
import { InterviewService } from 'app/interview.service';


@Component({
    selector: 'interview-section-list-item',
    templateUrl: './interview-section-list-item.component.html',
    styleUrls: ['../../interview.component.scss'] // <-- This was a hack to hit reset the styles for this component
})
export default class InterviewSectionListItem implements OnChanges {
    @Input() section: InterviewSectionSummaryDTO;
    @Output() onSelectSection = new EventEmitter<InterviewSectionSummaryDTO>();

    disabledOrMissingData: boolean;
    disabledButHasData: boolean;
    classStyles: object = {};
    title = '';

    constructor(public interviewService: InterviewService) { }

    updateSectionFields() {
        // Disable this section item if it doesn't have the data DTO. This can happen when later sections are created
        // before earlier sections (ie. there's a 'gap' in sections that have been reached in the interview). This
        // gap can happen because individual sections can be shown on other pages (eg. decision sections on the Underwriter Tab)
        const hasSectionData = this.interviewService.getInterviewData()?.sections.find(s => s.id === this.section.id);

        this.disabledOrMissingData = this.section?.isDisabled || !hasSectionData;
        this.disabledButHasData = (this.section?.isDisabled && hasSectionData !== undefined);
        
        this.title = this.section?.header || this.section?.name;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.section) this.updateSectionFields();
    }

    handleSelectSection(section: InterviewSectionSummaryDTO) {
        this.onSelectSection.emit(section);
    }
}
