import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { WhiteLabelTheme } from '../../models';
import { WhiteLabelService } from '../utility';

@Injectable()
export class WhiteLabelThemeResolver implements Resolve<WhiteLabelTheme> {
    constructor(private whiteLabelService: WhiteLabelService) {}

    async resolve(): Promise<WhiteLabelTheme> {
        let themeOverride: string;

        try {
            const urlParams = new URLSearchParams(window.location.search);
            themeOverride = urlParams.get('debug.theme');
        } catch (error) {}

        return this.whiteLabelService.getCurrentTheme(themeOverride)
            .then((theme: WhiteLabelTheme) => {
                this.whiteLabelService.applyTheme(theme);
                return theme;
            });
    }
}
