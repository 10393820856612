<!-- Old Decission Components -->
<ng-container *ngIf="!this.decisionSection">
    <underwriting-decision-view
        *ngIf="showViewDecision && updateDecisionToggleEnabled"
        [baseProps]="baseProps"
        [uwLabelName]="uwLabelName"
        [uwDecisionNote]="uwDecisionNote"
        (buttonClick)="handleShowDecision()">
    </underwriting-decision-view>
    <underwriting-decision-update
        *ngIf="!showViewDecision && updateDecisionToggleEnabled"
        [labels]="labels"
        [uwLabelName]="uwLabelName"
        (onSave)="handleSaveDecision($event)"
        (onCancel)="handleHideDecision()">
    </underwriting-decision-update>
</ng-container>

<!-- New Decission as a Section Component -->
<ng-container *ngIf="this.decisionSection && updateDecisionToggleEnabled">
    <div class="flex flex-row flex-fill">
        <div class="flex-fill decision" [ngClass]="{ 'made': uwLabelName, 'incomplete': !uwLabelName }">
            {{ uwLabelName ? uwLabelName : 'Underwriting Incomplete' }}
        </div>
        <div class="m-1 flex-end">
            <mrs-btn-outline *ngIf="isDecisionButtonVisible" buttonLabel="Update Decision" buttonId="editDecisionBtn" (onClick)="handleOpenDecisionSection()"></mrs-btn-outline>
        </div>
    </div>
</ng-container>