<app-navigation *ngIf="!isLoading"></app-navigation>

<ng-template #waitDialog>
  <div mat-dialog-content>
    <div class="content flex flex-column mt-5 mb-5" style="align-items: center; justify-content: center;">
      <div class="center-bold-dialog-title m-2">
        Please wait while we load your application.
      </div>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
  </div>
</ng-template>
