import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppService } from '@App';
import { ViewDocumentDialog, ViewDocumentDialogData } from '@Components/view-document-dialog';
import { CaseDocumentStatus, CaseDocumentTypes, FeatureToggle } from '@Enums';
import { CaseAttachment } from '@Models';
import { CaseDocumentsDTO } from '@Models/domain/dto';
import {
    CaseDocumentsDataService,
    CaseSummaryService,
    FeatureManagerService,
    FileUploadService,
    NotificationService,
    NotificationSeverity,
} from '@Services';
import { CaseRequirementResponse, EvidenceReviewResponse, RequirementResponse } from '@Underwriting/models';
import { UWRequirementsDataService, UWWorkbenchService } from '@Underwriting/services';
import { FileUtils, TimeUtils, Utils } from '@Utils';
import { SubSink } from 'subsink';

import { EvidenceManagerDialogData, EvidenceManagerDialogResponse } from './models';
import { forkJoin } from 'rxjs';

// Exported for unit test sake
export type EvidenceItem = {
    caseDocumentId: string;
    name: string;
    uploadedOn: Date;
    uploadedOnDay: string;
    uploadedOnTime: string;
    uploadedBy: string;
    isTiffFile: boolean;
    type: CaseDocumentTypes;
    hasBeenReviewed: boolean;
    reviews: EvidenceReviewItem[];
    hasSummarizedDocument?: boolean;
    isSummaryInProgress?: boolean;
    isSummaryCompleted?: boolean;
    isSummaryDocument?: boolean;
    isSummaryFailed?: boolean;
};
export type EvidenceReviewItem = EvidenceReviewResponse & {
    reviewedDate: Date;
    reviewedOnDay: string;
    reviewedOnTime: string;
};

@Component({
    selector: 'evidence-manager-dialog',
    templateUrl: './evidence-manager-dialog.component.html',
    styleUrls: ['./evidence-manager-dialog.component.scss']
})
export default class EvidenceManagerDialog implements OnInit, OnDestroy {
    @ViewChild('fileInput', { static: false }) fileInputRef: ElementRef;

    readonly Error_UnexpectedUpload = 'Something unexpected went wrong trying to upload the document.';
    readonly Error_NoDocuments = 'No documents selected.';
    readonly Error_MultipleDocuments = 'Only one document can be uploaded.  Multiple documents where selected.';
    readonly Error_DocumentExtension = 'Only pdf and Tiff files are allowed to be uploaded';
    readonly Error_UploadFailed = 'Upload failed.';
    readonly Error_UploadCanceled = 'Upload Canceled.';
    readonly Msg_AttachmentUploaded = 'Attachment added successfully';

    dialogTitle = 'Documents';
    caseRequirement: CaseRequirementResponse;
    requirement: RequirementResponse;
    evidenceItems: EvidenceItem[] = [];
    requirements: RequirementResponse[] = [];
    caseRequirements: CaseRequirementResponse[] = [];

    caseDocumentDocuments: CaseDocumentsDTO[] = [];
    caseDocumentAttachments: CaseDocumentsDTO[] = [];

    // State when there's a file uploading
    uploading = false;
    fileUploadCanceled = false;

    msg_TiffPreviewNotSupported = "Preview does not support TIFF file types.";
    disableSummarize = false;

    // Feature Toggle
    directDownloadIsEnabled = false;
    openInNewTabIsEnabled = false;
    downloadTiffEnabled = false;
    includeInCasePackageEnabled = false;  

    subs = new SubSink();

    constructor(
        public dialogRef: MatDialogRef<EvidenceManagerDialog, EvidenceManagerDialogResponse>,
        @Inject(MAT_DIALOG_DATA) public data: EvidenceManagerDialogData,
        private caseDocumentsDataService: CaseDocumentsDataService,
        private notificationService: NotificationService,
        private fileUploadService: FileUploadService,
        private uwRequirementsDataService: UWRequirementsDataService,
        private uwWorkbenchService: UWWorkbenchService,
        private caseSummaryService: CaseSummaryService,
        private dialog: MatDialog,
        private featureManagerService: FeatureManagerService,
        private router: Router,
        public appService: AppService,
    ) {
    }

    //#region Helpers
    handleSummarizeDocumentClick(e: Event, caseDocumentId: string) {
        e.stopPropagation(); 
        this.summarizeDocument(caseDocumentId);
    }

    summarizeDocument(caseDocumentId: string) {
        this.uwWorkbenchService.summarizeEvidence(this.data.account.id, this.data.caseRequirement.caseId, caseDocumentId, this.data.caseRequirement.requirementId )
            .subscribe(() => {
                this.uwRequirementsDataService.reloadCaseRequirements(this.data.client.code, this.data.caseRequirement.caseId)
                    .subscribe(() => this.formatEvidence());
            });
    }

    formatEvidence() {
        const evidenceItems: EvidenceItem[] = [];

        this.caseRequirement?.evidence?.forEach(evidence => {
            // This code could run before all depedencies are loaded.  Skip if we can't find the document
            const caseDocument = this.caseDocumentAttachments.find(x => x.id == evidence.caseDocumentId) || this.caseDocumentDocuments.find(x => x.id == evidence.caseDocumentId);
            if (!caseDocument) return;

            // DEV NOTE: We want to the date/person who created (ie. added) this document for display but the UWW API doesn't track
            // this. We'll need to update the Core `/CaseDocumentAttachment/{id}` and `/CaseDocuments/{id}` endpoints to return the
            // "CreatedBy" name so we can use it... and/or we need to start recording the "createdBy" in UWW.
            const creationDate = TimeUtils.convertToMomentCst(caseDocument.creationDate);

            const reviews = evidence.reviews.map(review => {
                const reviewedOn = TimeUtils.convertToMomentCst(review.reviewedOn);
                const reviewItem: EvidenceReviewItem = {
                    ...review,
                    reviewedDate: reviewedOn.toDate(),
                    reviewedOnDay: TimeUtils.formatDay(reviewedOn, 'M/D/YY'),
                    reviewedOnTime: TimeUtils.formatTime(reviewedOn, 'h:mma'),
                };
                return reviewItem;
            });
            const evidenceItem: EvidenceItem = {
                caseDocumentId: caseDocument.id,
                name: caseDocument.remoteName,
                uploadedOn: creationDate.toDate(),
                uploadedOnDay: TimeUtils.formatDay(creationDate, 'M/D/YY'),
                uploadedOnTime: TimeUtils.formatTime(creationDate, 'h:mma'),
                isTiffFile: FileUtils.isTiff(caseDocument),
                uploadedBy: caseDocument.createdByName,
                type: CaseDocumentTypes.Integration,
                reviews,
                hasBeenReviewed: evidence.reviews.length > 0,
                isSummaryInProgress: evidence.statusId == CaseDocumentStatus.Summarizing,
                isSummaryCompleted: evidence.statusId == CaseDocumentStatus.Summarized,
                isSummaryDocument: Array.isArray(caseDocument.tags) && caseDocument.tags.includes('Summary'),
                isSummaryFailed: evidence.statusId == CaseDocumentStatus.SummaryFailed
            };
            evidenceItems.push(evidenceItem);
        });

        evidenceItems.sort((a, b) => {
            if (a.uploadedOn.getTime() > b.uploadedOn.getTime()) return -1;
            if (a.uploadedOn.getTime() < b.uploadedOn.getTime()) return 1;
            return 0;
        });

        this.evidenceItems = evidenceItems;
    }

    GetCaseDocument(caseDocumentId: string) {
        let caseDocument = this.caseDocumentAttachments.find(x => x.id == caseDocumentId);
        caseDocument = caseDocument || this.caseDocumentDocuments.find(x => x.id == caseDocumentId);
        return caseDocument;
    }

    previewInTab(evidenceItem: EvidenceItem) {
        const { client, account, caseRequirement } = this.data;
        const { caseDocumentId } = evidenceItem;
        const { id: caseRequirementId, caseId } = caseRequirement;

        const urlTree = this.router.createUrlTree(
            ['/client', client.code, 'account', account.code, account.versionNumber, caseId, 'view-document', caseDocumentId],
            { queryParams: { caseRequirementId } }
        );

        window.open(this.router.serializeUrl(urlTree), '_blank');
    }

    previewInSamePage(evidenceItem: EvidenceItem) {
        const { client, account, caseRequirement } = this.data;
        const { caseId } = caseRequirement;
        const { caseDocumentId } = evidenceItem;

        this.dialog.open<ViewDocumentDialog, ViewDocumentDialogData, ViewDocumentDialogData>(ViewDocumentDialog, {
            height: '100%',
            minWidth: Utils.isDesktop() ? '55vw' : '90%',
            maxWidth: null,
            maxHeight: '90vh',
            data: {
                client: client,
                account: account,
                caseId,
                caseRequirement: this.caseRequirement,
                caseDocument: this.GetCaseDocument(caseDocumentId),
                caseDocumentType: evidenceItem.type
            }
        });
    }

    getIncludeInCasePackageFlag(): boolean {
        if (!this.includeInCasePackageEnabled)
            return false;

        return this.data.requirement?.includeInCasePackage || false;
    }
    //#endregion
    //#region Subscriptions

    subsribeToFeatureToggles() {
        const { client } = this.data;

        forkJoin({
            directDownloadIsEnabled: this.featureManagerService.isEnabled(FeatureToggle.UWWorkbenchDirectDownloadAttachmentClient, client.id),
            openInNewTabIsEnabled: this.featureManagerService.isEnabled(FeatureToggle.GlobalFeaturePreviewDocumentOpenInNewTabClient, client.id),
            downloadTiffEnabled: this.featureManagerService.isEnabled(FeatureToggle.GlobalFeaturePreviewDocumentTiffDownloadClient, client.id),
            includeInCasePackageEnabled: this.featureManagerService.isEnabled(FeatureToggle.ConfigSettingsWorkbenchIncludeInCasePackageClient, client.id),
        }).subscribe((featureToggles) => {
            this.directDownloadIsEnabled = featureToggles.directDownloadIsEnabled;
            this.openInNewTabIsEnabled = featureToggles.openInNewTabIsEnabled;
            this.downloadTiffEnabled = featureToggles.downloadTiffEnabled;
            this.includeInCasePackageEnabled = featureToggles.includeInCasePackageEnabled;
        });
    }

    subscribeToRequirements() {
        this.subs.add(this.uwRequirementsDataService.loadRequirements(this.data.client.code, this.data.account.id).subscribe({
            next: requirementResponses => {
                this.requirements = requirementResponses;

                this.requirement = this.requirements.find(x => x.id === this.data.caseRequirement.requirementId);
            },
        }));

        this.subs.add(this.uwRequirementsDataService.loadCaseRequirements(this.data.client.code, this.data.caseRequirement.caseId).subscribe({
            next: caseRequirementResponses => {
                this.caseRequirements = caseRequirementResponses || [];
                this.caseRequirement = this.caseRequirements.find(x => x.id === this.data.caseRequirement.id);
                this.formatEvidence();
            },
        }));
    }

    subscribeToDocuments() {
        this.subs.add(this.caseDocumentsDataService.loadDocuments(this.data.caseRequirement.caseId).subscribe({
            next: caseDocumentsDTOs => {
                this.caseDocumentDocuments = caseDocumentsDTOs || [];
                this.formatEvidence();
            }
        }));

        this.subs.add(this.caseDocumentsDataService.loadAttachments(this.data.caseRequirement.caseId).subscribe({
            next: caseDocumentsDTOs => {
                this.caseDocumentAttachments = caseDocumentsDTOs || [];
                this.formatEvidence();
            }
        }));
    }

    //#endregion
    //#region Lifecycle

    ngOnInit() {
        this.dialogTitle = this.data.title;

        this.subsribeToFeatureToggles();
        this.subscribeToRequirements();
        this.subscribeToDocuments();
    }

    // ngOnChage => Doesn't really work for DialogComponents because we're passing data by a sub-property.

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    //#endregion
    //#region Handlers

    handleDownload(evidence: EvidenceItem) {
        const { client, account, caseRequirement } = this.data;
        const { caseDocumentId, type } = evidence;

        if (type == CaseDocumentTypes.Attachment) {
            this.subs.add(this.caseSummaryService.downloadAttachedDocument(client.id, account.id, caseRequirement.caseId, caseDocumentId, true).subscribe({
                next: downloadUri => {
                    window.open(downloadUri, '_blank'); // open in new tab
                },
                error: _err => {
                    this.notificationService.showNotification({ severity: NotificationSeverity.Error, message: 'Error loading document' });
                }
            }));
        }
        else {
            this.subs.add(this.caseSummaryService.getCaseDocumentUri(client.id, account.id, caseRequirement.caseId, caseDocumentId, true).subscribe({
                next: downloadUri => {
                    window.open(downloadUri, '_blank'); // open in new tab
                },
                error: _err => {
                    this.notificationService.showNotification({ severity: NotificationSeverity.Error, message: 'Error loading document' });
                }
            }));
        }
    }

    handlePreview(evidence: EvidenceItem) {
        if (this.directDownloadIsEnabled)
            return this.handleDownload(evidence);

        if (this.downloadTiffEnabled && evidence.isTiffFile) return;

        if (this.openInNewTabIsEnabled)
            return this.previewInTab(evidence);

        this.previewInSamePage(evidence);
    }

    formatCaseRequirementPurpose(caseRequirement: CaseRequirementResponse): string {
        if (caseRequirement.subtitle) {
            return `UW Requirement ${caseRequirement.name}: ${caseRequirement.subtitle}`;
        }
        return `UW Requirement ${caseRequirement.name}`;
    }

    async handleUploadEvidence(event: Event) {
        const target = event.target as HTMLInputElement;

        if (!target?.files)
            return this.notificationService.showNotification({ severity: NotificationSeverity.Error, message: this.Error_UnexpectedUpload, log: true });
        if (target.files.length <= 0)
            return this.notificationService.showNotification({ severity: NotificationSeverity.Error, message: this.Error_NoDocuments, log: true });
        if (target.files.length > 1)
            return this.notificationService.showNotification({ severity: NotificationSeverity.Error, message: this.Error_MultipleDocuments, log: true });

        const file = target.files[0];

        if (!FileUtils.isAllowedEvidenceExtensions(file.name))
            return this.notificationService.showNotification({ severity: NotificationSeverity.Error, message: this.Error_DocumentExtension, log: true });

        this.uploading = true;
        const formData = new FormData();
        formData.append('1', file, file.name);

        const caseAttachment: CaseAttachment = {
            includeInCasePackage: this.getIncludeInCasePackageFlag(),
            tags: ['UW Evidence', `UW ${this.caseRequirement.name}`],
            caseId: this.caseRequirement.caseId,
            attachmentPurpose: this.formatCaseRequirementPurpose(this.caseRequirement)
        };
        await this.fileUploadService.sendCaseAttachmentInChunks(this.data.client.id, this.data.account.id, this.caseRequirement.caseId, caseAttachment, file)
            .then(commitResult => {

                this.subs.add(commitResult.subscribe({
                    next: caseDocumentId => {
                        if (caseDocumentId == null && this.fileUploadCanceled)
                            return this.notificationService.showNotification({ severity: NotificationSeverity.Error, message: this.Error_UploadCanceled, log: true });
                        else if (caseDocumentId == null)
                            return this.notificationService.showNotification({ severity: NotificationSeverity.Error, message: this.Error_UploadFailed, log: true });

                        this.subs.add(this.uwWorkbenchService.updateCaseRequirementAttachment(this.caseRequirement.id, caseDocumentId, this.data.account.id).subscribe(
                            {
                                next: _ => {
                                    if (this.requirement.autoSummarizeEnabled)
                                    {
                                        this.summarizeDocument(caseDocumentId);
                                    }
                                }
                            }
                        ));

                        this.notificationService.showNotification({ severity: NotificationSeverity.Success, message: this.Msg_AttachmentUploaded });
                    },
                    error: error => {
                        this.notificationService.showNotification({ severity: NotificationSeverity.Error, message: error, log: true });
                    },
                    complete: () => {
                        this.fileInputRef.nativeElement.value = ''; // clear input to allow file-picker to re-pick the same file and detect changes
                        this.uploading = false;
                        this.caseDocumentsDataService.reloadAttachments(this.caseRequirement.caseId);
                    }
                }));
            }).catch(err => {
                this.fileInputRef.nativeElement.value = ''; // clear input to allow file-picker to re-pick the same file and detect changes
                this.uploading = false;
                this.notificationService.showNotification({ severity: NotificationSeverity.Error, message: err, log: true });
            });
    }

    handleCancel() {
        this.fileUploadCanceled = true;
        this.fileUploadService.cancelUpload();

        this.dialogRef.close({
            action: "cancel"
        });
    }

    //#endregion
}
