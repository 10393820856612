import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MrsColor } from 'app/enums';

@Component({
    selector: 'subtitle-dialog',
    templateUrl: './subtitle-dialog.component.html',
    styleUrls: ['./subtitle-dialog.component.scss']
})
export default class SubtitleDialog {
    subtitleForm: FormGroup;
    buttonColor = MrsColor.UIBlue;
    title: string;
    isEdit = false;
    msg: string;
    subtitle: string;

    constructor(
        public dialogRef: MatDialogRef<SubtitleDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _fb: FormBuilder
    ) {
        this.title = data.title;
        this.msg = data.msg;
        this.subtitle = data.subtitle;
        this.subtitleForm = _fb.group({ subtitle: [this.subtitle] });
    }

    //#region Handlers

    updateSubtitle(): void {
        this.dialogRef.close({
            subtitle: this.subtitleForm.get('subtitle').value
        });
    }

    cancel(): void {
        this.dialogRef.close();
    }

    //#endregion
}
