import { HttpErrorResponse } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AppService } from '@App';
import { ActionsDTO, ConfigDataDTO, InterviewDTO, InterviewQuestionDTO, InterviewSectionDTO, InterviewSectionDTO_extended, QuestionActionsDTO } from '@DTOs';
import { ActionEnum, AnswerTypes, CaseStatus, FeatureToggle, IntegrationEnum, InterviewCloseEnum, QueueStatusEnum } from '@Enums';
import { Account, AccountCheckStatusV2, IntegrationPending, UWResponse } from '@Models';
import { ConfirmationDialogService, ConstantMapKeyService, FeatureManagerService, InterviewSectionActionService, IpAddressService, NotificationService, NotificationSeverity, ObservableService, RuleDictionary, RulesService, UtilityService } from '@Services';
import { Utils } from '@Utils';
import * as _ from 'lodash';
import { firstValueFrom, lastValueFrom, Subject, Subscription, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { HTTP_ERRORRESPONSE_RNA_SSN_DUP_CHECK_ERROR } from '@Shared';
import { MetricEvent, MonitoringService } from './monitor.service';
import moment = require('moment');

export type PostActionResponse = {
    data?: QuestionActionsDTO[],
    questionIds?: string[]
    type?: string;
    questionId?: string;
}

export type PreShowHideResponse = {
    action: QuestionActionsDTO,
    questionId: string;
}

export type CallActionsInSequenceRequest = {
    questionIds: string[];
    section: InterviewSectionDTO_extended;
    actionFilters: ActionEnum[];
    mapkeyIds: string[];
}

@Injectable()
export class InterviewService {

    // Interview data
    private interviewData: InterviewDTO;
    public stopSocketMonitor = false;
    private mib = { status: '', details: null, errorMessage: null, questionId: '', waitForResponse: false, sourceAction: '', integrationId: '00000000-0000-0000-0000-000000000002', name: 'MIB' };
    private rx = { status: '', details: null, errorMessage: null, questionId: '', waitForResponse: false, sourceAction: '', integrationId: '00000000-0000-0000-0000-000000000003', name: 'RX' };
    private giact = { status: '', details: null, errorMessage: null, questionId: '', waitForResponse: false, sourceAction: '', integrationId: '00000000-0000-0000-0000-000000000001', name: 'GIACT' };
    private agent = { status: '', details: null, errorMessage: null, questionId: '', waitForResponse: false, sourceAction: '', integrationId: '00000000-0000-0000-0000-000000000005', name: 'Agent' };
    private quote = { status: '', details: null, errorMessage: null, questionId: '', waitForResponse: false, sourceAction: '', integrationId: '00000000-0000-0000-0000-000000000004', name: 'Quote' };
    private integrationV2 = { id: '', integrationId: '', status: '', details: null, errorMessage: null, questionId: '', waitForResponse: false, sourceAction: '', name: '' };
    private integrations: any = [];
    public ruleFacts: any = [];
    public answerValues: any = [];
    public waitingForIntegrationDetails: string = "Please wait.";

    isInterviewSaving = false;

    // TECH DEBT: I believe this is a ReactiveForm `AbstractControl` which could be `FormControl`, `FormGroup` or `FormArray`. See: https://angular.io/api/forms/AbstractControl
    // Like `reflexiveForms`, `repeatForms` and `healthForms`, this control is set in `dynamic-form.component.ts` in the `config` setter (~line 77) using `setDynamicForm()`.
    // I'm not sure why this property uses getter/setter instead of just being a public property.
    private dynamicForm: UntypedFormGroup;

    // TECH DEBT: I believe this is a dictionary of Reactive Form `FormGroup`. This is init in `interview.component.ts -> ngOnInit() & selectSection()` (~line 300 and 778)
    // and then populated in `dynamic-form.component.ts` in the `config` setter (~line 88). This is similar to `repeatForms` and `healthForms`.
    // The dictionary key is the parent id of the question. The value is the Reactive Form `FormGroup` for the question.
    public reflexiveForms: { [parentId: string]: UntypedFormGroup } = {};

    // TECH DEBT: I believe this is a dictionary of Reactive Form `FormGroup`. This is init in `interview.component.ts -> ngOnInit() & selectSection()` (~line 301 and 779)
    // and then populated in `dynamic-form.component.ts` in the `config` setter (~line 92). This is similar to `reflexiveForms` and `healthForms`.
    // The dictionary key is the parent id of the question. The value is the Reactive Form `FormGroup` for the question.
    // Unlike `reflexiveForms`, `repeatForms` appears to be the only group that can have multiple controls
    public repeatForms: { [parentId: string]: UntypedFormGroup } = {};

    // TECH DEBT:1 I believe this is a dictionary of Reactive Form `FormGroup`. This appears to get initalized `interview.component.ts`(~line 301 and 779)
    // and then populated in `dynamic-form.component.ts` in the `config` setter (~line 94). This is similar to `reflexiveForms` and `repeatForms`.
    // Unlike `reflexiveForms` and `repeatForms` this property is only initialized here with a default value of `{}`.
    // TECH DEBT:2 Unlike `reflexiveForms` and `repeatForms`, this property is not initalized in `interview.component.ts -> ngOnInit() or selectSection()`.
    // This property appears to be similar to those, so it's not clear why this does get treated the same. I believe the `ngOnInit` might not be necessary
    // for any property because it's initalized to empty in this service. However, the `selectSection()` might be important to clear the old values for the new section.
    public healthForms: { [parentId: string]: UntypedFormGroup } = {};

    // Dictionary of Reactive Forms that are on reflexive questions within a repeat block
    public repeatReflexiveForms: { [parentId: string]: UntypedFormGroup } = {};

    private virtualMapkeys: any;
    private updatedData = new Subject<InterviewDTO>();
    public interviewUpdated = false;
    updatedInterview = this.updatedData.asObservable();
    public interviewUpdateError = new Subject<boolean>();
    notifyUpdateInterviewError = this.interviewUpdateError.asObservable();
    isCaseSaving = false;
    interviewWaitForIntegrationPrePopup = false;
    pendingQuestionActions = [];
    private caseSavedSource = new Subject<any>();
    caseSavedObservable = this.caseSavedSource.asObservable();
    isCallingAction = false;
    private actionData = new Subject<PostActionResponse>();
    actionObservable = this.actionData.asObservable();
    private preShowHideData = new Subject<PreShowHideResponse>();
    processPreShowHideObservable = this.preShowHideData.asObservable();
    private cancelAction = new Subject<any>();
    cancelActionObservable = this.cancelAction.asObservable();
    private cancelActionButton: any;
    private unauthorizedAccessSource = new Subject<any>();
    unauthorizedAccessObservable = this.unauthorizedAccessSource.asObservable();

    showActionDialog: EventEmitter<IntegrationPending> = new EventEmitter<IntegrationPending>();
    hideActionDialog: EventEmitter<any> = new EventEmitter<any>();
    showEnvelopeDialog: EventEmitter<any> = new EventEmitter<any>();
    waitForResponseDialog: EventEmitter<any> = new EventEmitter<any>();

    private triggerRefreshIntegrationSubject = new Subject<any>();
    onTriggerRefreshIntegration = this.triggerRefreshIntegrationSubject.asObservable();

    private ActionButtonsCancelList = [];
    configData: ConfigDataDTO;
    isReadOnly = false;
    private INTEGRATION_CATEGORY = '00000000-0000-0000-0000-000000000002';
    public answeredQuestion: any;
    public answeredQuestionId: any;
    public lastAnsweredQuestionId: any;
    public answeredQuestionForEft: any;
    private accountRules: RuleDictionary;
    public account: Account;
    public clickSource: any;
    private integrationMetrics: { [integrationId: string]: MetricEvent } = {};
    actionResponseHandlers: { [actionId: string]: (action: any) => void } = {
        [ActionEnum.DocuSignESig]: this.signatureActionResponseHandler.bind(this)
    };

    public isInterviewLocked = false;
    clientIp: string;

    constantLists;
    prefillReflexivesInRepeatsToggle: boolean;
    isCheckingRnaSsnDup: boolean;
    sectionWaitSubscription: Subscription;
    constructor(
        private appService: AppService,
        private confirmationService: ConfirmationDialogService,
        private interviewSectionActiveService: InterviewSectionActionService,
        private rulesService: RulesService,
        private utilityService: UtilityService,
        private monitoringService: MonitoringService,
        private observableService: ObservableService,
        private constantMapKeyService: ConstantMapKeyService,
        private ipAddressService: IpAddressService,
        private notificationService: NotificationService,
        private featureManagerService: FeatureManagerService) {

        this.appService.getAllConfigData().then((data) => {
            this.configData = data;
        });
        this.ipAddressService.captureClientIpAddress()
            .then(response => response.json())
            .then(response => {
                this.clientIp = response?.ip;
            })
            .catch(err => {
                this.monitoringService.logError(err);
            });

        this.prefillReflexivesInRepeatsToggle = this.featureManagerService.getByName(FeatureToggle.CaseRuntimePrefillReflexiveInRepeats).enabled;
        this.interviewWaitForIntegrationPrePopup = this.featureManagerService.getByName(FeatureToggle.GlobalInterviewWaitForIntegrationPrePopup).enabled;
    }

    setAccount(account: Account) {
        this.account = account;
    }

    async loadAccountRules(accountId: string) {
        this.accountRules = await this.rulesService.getSimpleRulesByAccount(accountId);
    }

    canResumeCase(caseStatusId: string): boolean {
        const isAgentBehavior = this.appService.getIsAgentBehavior();

        return (
            (isAgentBehavior && caseStatusId === CaseStatus.PendingSubmission)
            || (!isAgentBehavior && (caseStatusId === CaseStatus.OnHold || caseStatusId === CaseStatus.PendingInitialReview || caseStatusId === CaseStatus.Submitted))
        );
    }

    getVirtualMapkeyData(accountId: string) {
        const me = this;
        return new Promise((resolve, _reject) => {
            if (me.virtualMapkeys !== undefined) {
                resolve(me.virtualMapkeys);
            } else {
                if (accountId == null) {
                    resolve(null);
                } else {
                    me.appService.getData(`MapKeys/${accountId}/VirtualMapKeys`).subscribe({
                        next: (res) => {
                            if (res.status === 'success') {
                                me.virtualMapkeys = res.data;
                            }
                            resolve(me.virtualMapkeys);
                        },
                        error: (error) => {
                            me.notificationService.showNotification({ severity: NotificationSeverity.Error, message: error, log: true });
                            resolve(me.virtualMapkeys);
                        }
                    });
                }
            }
        });
    }

    getDynamicForm() {
        return this.dynamicForm;
    }

    setDynamicForm(formGroup: UntypedFormGroup) {
        this.dynamicForm = formGroup;
    }

    getInterviewData() {
        return this.interviewData;
    }

    getMibData() {
        return this.mib;
    }

    getRxData() {
        return this.rx;
    }

    getGiactData() {
        return this.giact;
    }

    getAgentData() {
        return this.agent;
    }

    getQuoteData() {
        return this.quote;
    }

    getIntegrationV2Data(integrationRequestId) {
        let integration = this.integrations.find((i) => i.id === integrationRequestId);
        if (!integration) {
            integration = { id: integrationRequestId, integrationId: '', status: '', details: null, errorMessage: null, questionId: '', waitForResponse: false, sourceAction: '', name: '' };
            this.integrations.push(integration);
        }
        return integration;
    }

    getIntegrations() {
        return this.integrations;
    }

    hasIntegrations() {
        return this.integrations.length > 0 || this.giact.status || this.rx.status || this.mib.status || this.agent.status || this.quote.status;
    }

    shouldWaitForAnyIntegration() {
        return this.giact.waitForResponse || this.integrationV2.waitForResponse || this.rx.waitForResponse || this.mib.waitForResponse || this.agent.waitForResponse || this.quote.waitForResponse;
    }

    getIntegrationDataById(id) {
        // For now wanted to keep it hardcoded.
        switch (id) {
            case '00000000-0000-0000-0000-000000000001': return this.giact;
            case '00000000-0000-0000-0000-000000000002': return this.mib;
            case '00000000-0000-0000-0000-000000000003': return this.rx;
            case '00000000-0000-0000-0000-000000000004': return this.quote;
            case '00000000-0000-0000-0000-000000000005': return this.agent;
            default:
                return null;
        }
    }

    getIntegrationDataByName(name) {
        switch (name) {
            case 'GIACT': return this.giact;
            case 'MIB': return this.mib;
            case 'RX': return this.rx;
            case 'Agent': return this.agent;
            case 'Quote': return this.quote;
            default:
                return null;
        }
    }

    getIntegrationStatus(id) {
        const integrationStatus = this.appService.getConfigData('queueStatus');
        const status = integrationStatus.find(x => x.id === id);
        if (status?.name == 'Received') {
            return 'Completed';
        }
        return (status !== undefined) ? status.name : '';
    }

    getIntgrationsList() {
        return ['MIB', 'RX', 'GIACT', 'Agent', 'Quote'];
    }

    setIntegrationData() {
        this.getIntgrationsList().forEach(intgName => {
            let data = this.getCaseData(intgName);
            const existingData = this.getIntegrationDataByName(intgName);
            existingData.waitForResponse = false;
            existingData.details = null;
            if (data) {
                if (!data.status) {
                    data = JSON.parse(data);
                }
                existingData.details = data;
                existingData.status = data.status;
                existingData.errorMessage = data.message;
            } else if (existingData.status === '') {
                existingData.status = '';
                existingData.errorMessage = null;
            }
        });
    }

    clearIntegrationData() {
        this.getIntgrationsList().forEach(intgName => {
            const existingData = this.getIntegrationDataByName(intgName);
            existingData.status = '';
            existingData.errorMessage = null;
            existingData.details = null;
        });

        this.integrations = [];
    }

    clearRuleFacts() {
        this.ruleFacts = [];
        this.answerValues = [];
    }

    returnString(input: string) {
        return input;
    }

    updateIntegrationActionResult(integrData) {
        if (integrData.sectionId) {
            const section = this.interviewData.sections.find(x => x.id === integrData.sectionId);
            if (section) {
                section.sectionActions.forEach(eachAction => {
                    if (eachAction.id === integrData.sourceAction) {
                        if (eachAction.actionResults && eachAction.actionResults.data && eachAction.actionResults.data != null) {
                            eachAction.actionResults.data.integrationStatus = integrData.status;
                        }
                    }
                });
                return;
            }
        } else {
            for (const section of this.interviewData.sections) {
                const question = section.questions.find(x => x.id === integrData.questionId);
                if (question) {
                    question.questionActions.forEach(eachAction => {
                        if (eachAction.id === integrData.sourceAction) {
                            if (eachAction.actionResults && eachAction.actionResults.data && eachAction.actionResults.data != null) {
                                eachAction.actionResults.data.integrationStatus = integrData.status;
                            }
                        }
                    });
                    return;
                }
            }
        }
    }

    setInterviewData(interview: InterviewDTO) {
        this.interviewData = interview;
        this.lastAnsweredQuestionId = this.interviewData.activeQuestionId;
        this.setIntegrationData();
    }

    initializeRuleData() {
        if (this.interviewData.caseDetails) {

            this.interviewData.caseDetails.mapkeys.forEach(mapKey => {
                const val = this.getMapkeyFormattedValue(mapKey.value);
                this.ruleFacts.push({ name: mapKey.name, value: val });
                this.answerValues.push({ name: mapKey.name, value: mapKey.value });
            });
        }
    }


    mergeInterviewData(newData) {
        if (this.interviewData === null) {
            this.interviewData = newData;
            return;
        }

        // Copy the interview properties and new sections
        this.interviewData.maxVisitedSection = newData.maxVisitedSection;
        this.interviewData.activeSectionId = newData.activeSectionId;
        this.interviewData.activeQuestionNumber = newData.activeQuestionNumber;
        this.interviewData.workflowCompleted = newData.workflowCompleted;
        this.interviewData.dependencyQuestionList = newData.dependencyQuestionList;
        this.interviewData.isCallMasking = newData.isCallMasking;
        this.interviewData.visibleSections = newData.visibleSections;
        this.interviewData.summaryMessage = newData.summaryMessage;

        for (const newSection of newData.sections) {
            let found = false;
            for (let i = 0; i < this.interviewData.sections.length; i++) {
                const existingSection = this.interviewData.sections[i];
                if (existingSection.id === newSection.id) {
                    this.interviewData.sections.splice(i, 1, newSection);
                    found = true;
                    break;
                }
            }
            if (!found) {
                this.interviewData.sections.push(newSection);
            }
        }

        // CaseDetails & mapkeys
        this.interviewData.caseDetails.policyNumber = newData.caseDetails.policyNumber;
        this.interviewData.caseDetails.uwLabelId = newData.caseDetails.uwLabelId;
        this.interviewData.caseDetails.statusId = newData.caseDetails.statusId;
        this.deleteMapkeys(newData.caseDetails.deletedMapkeys);
        this.mergeMapkeys(newData.caseDetails.mapkeys);
        this.mergeCaseData(newData.caseDetails.data);
    }

    mergeMapkeys(newMapkeyValues) {
        if (newMapkeyValues && newMapkeyValues.length > 0) {
            // If current list is empty, can just copy over
            if (this.interviewData.caseDetails.mapkeys && this.interviewData.caseDetails.mapkeys.length > 0) {

                for (const newMapkeyValue of newMapkeyValues) {
                    // If exists, update it or add it
                    const existingMapkeyValue = this.interviewData.caseDetails.mapkeys.find(m => m.name === newMapkeyValue.name);
                    if (existingMapkeyValue) {
                        existingMapkeyValue.value = newMapkeyValue.value;
                        existingMapkeyValue.lastModifiedDate = newMapkeyValue.lastModifiedDate;
                    } else {
                        this.interviewData.caseDetails.mapkeys.push(newMapkeyValue);
                    }

                    this.updateRuleFacts(newMapkeyValue.name, newMapkeyValue.value);
                }
            } else {
                this.interviewData.caseDetails.mapkeys = newMapkeyValues;

                this.interviewData.caseDetails.mapkeys.forEach(mapKey => {
                    this.updateRuleFacts(mapKey.name, mapKey.value, null);
                });
            }
        }

        this.observableService.onMapKeysUpdated.next(1);
    }

    deleteMapkeys(deletedMapkeys) {
        if (deletedMapkeys && deletedMapkeys.length > 0) {
            // Remove the deleted mapkeys so they won't get pushed back up
            for (const deletedMapkey of deletedMapkeys) {
                const index = this.interviewData.caseDetails.mapkeys.findIndex(m => m.name === deletedMapkey.name);
                if (index > -1) {
                    this.interviewData.caseDetails.mapkeys.splice(index, 1);
                }
            }
        }
    }

    mergeCaseData(newCaseData) {
        if (newCaseData && newCaseData.length > 0) {
            // If current list is empty, can just copy over
            if (this.interviewData.caseDetails.data && this.interviewData.caseDetails.data.length > 0) {
                for (const caseData of newCaseData) {
                    // If exists, update it or add it
                    const existingCaseData = this.interviewData.caseDetails.data.find(m => m.name === caseData.name);
                    if (existingCaseData) {
                        existingCaseData.jsonData = caseData.jsonData;
                        existingCaseData.lastModifiedDate = caseData.lastModifiedDate;
                    } else {
                        this.interviewData.caseDetails.data.push(caseData);
                    }
                }
            } else {
                this.interviewData.caseDetails.data = newCaseData;
            }

            this.observableService.onCaseDataUpdated.next(1);
        }
    }

    getCaseData(name) {
        if (this.interviewData.caseDetails && this.interviewData.caseDetails.data) {
            const caseData = this.interviewData.caseDetails.data.find(s => s.name === name);
            if (caseData) {
                return caseData.jsonData;
            }
        }

        return null;
    }

    getCaseMapkeyValue(mapkeyName: string) {
        const existingMapkeyValue = this.interviewData?.caseDetails?.mapkeys.find(m => m.name === mapkeyName);

        return existingMapkeyValue;
    }

    setCaseData(data) {
        if (this.interviewData.caseDetails === undefined || this.interviewData.caseDetails === null) {
            console.log('Attempt to store case data before caseDetails is set.');
            return;
        }

        if (this.interviewData.caseDetails.data) {
            const caseData = this.interviewData.caseDetails.data.find(s => s.name === data.name);
            if (caseData) {
                caseData.jsonData = data.jsonData;
                caseData.lastModifiedBy = data.lastModifiedBy;
                caseData.lastModifiedDate = data.lastModifiedDate;
                return;
            }
        }

        // Not found so add it
        if (this.interviewData.caseDetails.data === undefined || this.interviewData.caseDetails.data === null) {
            this.interviewData.caseDetails.data = [];
        }

        this.interviewData.caseDetails.data.push(data);
    }

    getActiveSection() {
        const activeSectionId = this.interviewData?.activeSectionId.toLowerCase();
        return this.interviewData?.sections?.find(s => s.id.toLowerCase() === activeSectionId);
    }

    getActiveVisibleSectionSummary() {
        const activeSectionId = this.interviewData?.activeSectionId.toLowerCase();
        return this.interviewData?.visibleSections?.find(s => s.id.toLowerCase() === activeSectionId);
    }

    setQuestionAnswer(question, answerValue) {
        // TODO don't update timestamp if the queston hasn't been answered yet
        if (question.answerValue !== answerValue) {
            question.answerValue = answerValue;
            question.answerTimeStamp = new Date();
        }

        if (this.interviewData.caseDetails) {
            this.updateRuleFacts(question.mapKeyName, question.answerValue, question.id);
        }
    }

    updateRuleFacts(mapKeyName, mapKeyValue, questionId?) {
        const mapKey = this.ruleFacts.find(x => x.name === mapKeyName);
        const val = this.getMapkeyFormattedValue(mapKeyValue);

        if (mapKey) {
            mapKey.value = val;
        } else {
            this.ruleFacts.push({ name: mapKeyName, value: val, questionID: questionId });
        }

        this.updateAnswerValues(mapKeyName, mapKeyValue);
    }

    updateAnswerValues(mapKeyName, mapKeyValue) {
        const mapKey = this.answerValues.find(x => x.name === mapKeyName);
        if (mapKey) {
            mapKey.value = mapKeyValue;
        } else {
            this.answerValues.push({ name: mapKeyName, value: mapKeyValue });
        }
    }

    getMapkeyFormattedValue(answerValue: any) {
        let val = answerValue;
        if (!answerValue || answerValue == null || answerValue === '' || this.utilityService.isSSN(answerValue)) {
            val = answerValue;
        } else if (this.utilityService.isInt(answerValue)) {
            val = +answerValue;
        } else if (this.utilityService.isFloat(answerValue)) {
            val = parseFloat(answerValue);
        } else if (this.utilityService.isDate(answerValue)) {
            val = new Date(answerValue);
        }
        return val;
    }

    updateInterviewJson(answeredQuestion, update?: boolean, maintainFormValues?: boolean) {
        if (answeredQuestion) {
            this.answeredQuestionId = answeredQuestion.id;
            this.lastAnsweredQuestionId = null;
        }

        if (answeredQuestion && update) {
            // this.updateLastAnswered();
        }

        // set interview updated to true, AutoSave happens only if interviewupdated is true.
        this.interviewUpdated = true;
        const activeSection = this.getActiveSection();
        if (activeSection) {
            // iterate through each question in current section and update its value from forms.
            activeSection.questions.forEach(question => {
                if (question.mapKeyName) {
                    if (question.repeatFormId) {
                        // if question has repeatformid, it means its in repeat forms, so check it's block num and update value.
                        const repeatForm = this.repeatForms[question.repeatFormId];
                        if (repeatForm && repeatForm.get(question.id)) {
                            this.setQuestionAnswer(question, this.getFormValue(repeatForm, question, maintainFormValues));
                        }
                    } else {
                        if (question.parentQuestionId === '00000000-0000-0000-0000-000000000000') {
                            // empty answer value, if the field is not displayed.
                            if (!question.display && !answeredQuestion) {
                                this.setQuestionAnswer(question, '');
                            } else {
                                if (this.dynamicForm && this.dynamicForm.get(question.id)) {
                                    this.setQuestionAnswer(question, this.getFormValue(this.dynamicForm, question, maintainFormValues));
                                }
                            }
                        } else {
                            // if parent question id is not default, it means its in reflexive form.
                            const reflexiveForm = this.reflexiveForms[question.parentQuestionId];
                            const healthForm = this.healthForms[question.parentQuestionId];
                            const refQuestion = activeSection.questions.find(q => q.id === question.parentQuestionId);
                            if (!answeredQuestion && ((refQuestion && !refQuestion.display) || !question.display) && !question.mapKeyName.endsWith('Corrected')) {
                                question.display = false;
                                this.setQuestionAnswer(question, '');
                            } else {
                                if (reflexiveForm && reflexiveForm.get(question.id)) {
                                    this.setQuestionAnswer(question, this.getFormValue(reflexiveForm, question, maintainFormValues));
                                } else if (healthForm && healthForm.get(question.id)) {
                                    this.setQuestionAnswer(question, this.getFormValue(healthForm, question, maintainFormValues));
                                }
                            }
                        }
                    }
                }
            });
            if (answeredQuestion) {
                this.answeredQuestion = answeredQuestion;
                this.answeredQuestionForEft = {
                    id: answeredQuestion.id,
                    value: answeredQuestion.answerValue,
                    mapkeysId: answeredQuestion.mapkeysId
                };
                this.callPreActionForDepQuestions(answeredQuestion);
                this.checkForConstantVariantDependantQuestions(answeredQuestion).catch((e) => { console.log(e); });
            }
        }
    }

    getFormValue(form, question, maintainFormValues?: boolean) {
        let returnValue = '';
        returnValue = form.get(question.id).value;

        if (question.answerType === 'UploadFileActionButton') {
            returnValue = question.answerValue;
        }

        if (question.answerType === 'PhoneNumberAnswer') {
            // Add extension value to final answer value of phone number answer.
            if (form.get('ext' + question.id)) {
                const extenstionValue = form.get('ext' + question.id).value;
                if (extenstionValue) {
                    returnValue = returnValue + '-' + extenstionValue;
                }
            }
        }

        if (question.answerType === 'MultiChoiceMultiAnswer' || question.answerType === 'MultiChoiceMultiAnswerV2') {
            // make the array into string for multichoicemultianswer question's answer value.
            const q = form.get(question.id);
            if (q && q.value && q.value.length > 0 && Array.isArray(q.value)) {
                returnValue = q.value.map(v => v.trim()).join(', ');
            } else if (q && q.value && q.value.length > 0) {
                returnValue = q.value;
            } else {
                returnValue = '';
            }
        }

        if (question.answerType === 'NumericAnswer') {
            // if dollar is present, remove it.
            if (question.IsCurrency === 'true' && form.get(question.id).value) {
                returnValue = form.get(question.id).value.replace(/\$|,/g, '');
            }
        }

        // TECH DEBT - We need to type `form` and `question` on this method because it's not clear what `question.mapkeysId` is.
        // The `CaseMapkeyDTO` doesn't have an `id` property but `interviewQuestionDTO` does and it needs to be set for this condition to work.
        // However, inside of this condition, we don't lookup the MapKey by `id` but by `mapKeyName`.  So, it doesn't make sense that
        // the conditional is checking one value (`mapkeysId`) and then using a different value (`mapKeyName`). It seems like this might have
        // been refactored away from using 'id' to using 'name' but the conditional was never updated.
        if (question.mapkeysId && returnValue === '' && question.isRequired && !maintainFormValues) {
            if (this.interviewData.caseDetails && this.interviewData.caseDetails.mapkeys) {
                const mapkeyValue = this.interviewData.caseDetails.mapkeys.find(m => m.name === question.mapKeyName);
                if (mapkeyValue && mapkeyValue.value && mapkeyValue.value !== '') {
                    returnValue = mapkeyValue.value;
                    form.get(question.id).setValue(returnValue);
                }
            }
        }

        return returnValue;
    }

    callDependentQuestionActionsByMapKey(activeSection: InterviewSectionDTO, mapkeyId: string) {
        const depquestionist = this.getInterviewData().dependencyQuestionList;
        const depQuestionsArray = [];
        // TECH DEBT[depquestionist.length]: See: `interivew-dto.d.ts` for details
        if (depquestionist && depquestionist.length > 0) {
            for (const [questionId, mapKeyIds] of Object.entries(depquestionist)) {
                if ((mapKeyIds as any).some(x => x === mapkeyId)) {
                    const eachQuestion = activeSection.questions.find(q => q.id === questionId);
                    if (eachQuestion.questionActions) {
                        depQuestionsArray.push(eachQuestion);
                    }
                }
            }
        }

        if (depQuestionsArray.length > 0) {
            this.callPreActionsForHiddenFields(depQuestionsArray, undefined);
        }
    }

    async checkForConstantVariantDependantQuestions(question) {
        if (question.dependentQuestions) {
            const depQuestionsArray = [];
            const activeSection = this.interviewData.sections.find(s => s.id === this.interviewData.activeSectionId);

            for (const eachQuestionId of question.dependentQuestions) {
                const eachQuestion = activeSection.questions.find(q => q.id === eachQuestionId);
                if (eachQuestion.questionActions) {
                    depQuestionsArray.push(eachQuestion);
                }

            }
            const multiChoiceQuestions = depQuestionsArray.filter(q => q.multiChoiceAnswers && q.multiChoiceAnswers.length > 0);
            if (multiChoiceQuestions?.length > 0) {
                const constantLists = await this.constantMapKeyService.getCachedConstantMapkeys(this.account.id, this.account.versionNumber, this.getActiveSection().id);
                for (const key of Object.keys(constantLists)) {
                    const constantList = constantLists[key];
                    const questionsWithConstantListVariants = multiChoiceQuestions.filter(question => question.multiChoiceAnswers.some(mca => constantList.some(list => list.mapkeysId === mca.constantKey)));
                    if (questionsWithConstantListVariants?.length > 0)
                        this.observableService.triggerConstantMapkeyVariantUpdate.emit();
                }
            }
        }
    }

    callPreActionForDepQuestions(question) {
        if (question.dependentQuestions) {

            const metricEvent = this.monitoringService.beginInterviewMetric('PreActions', this.getDefaultEventProperties());
            const depQuestionsArray = [];
            const activeSection = this.getActiveSection();

            for (const eachQuestionId of question.dependentQuestions) {
                const eachQuestion = activeSection.questions.find(q => q.id === eachQuestionId);
                if (eachQuestion.questionActions) {
                    depQuestionsArray.push(eachQuestion);
                }

            }

            this.callPreActionsForHiddenFields(depQuestionsArray, undefined, metricEvent);
        }
    }

    callPreActionsForHiddenFields(questionsRequired: InterviewQuestionDTO[], actionFilter: ActionEnum[], metricEvent?: MetricEvent) {
        // Build the list of questions
        const questionIds: string[] = [];
        const mapkeyIds: string[] = [];
        let actionTypeId: string = null;
        for (const q of questionsRequired) {
            const preAction = q.questionActions.find(p => p.actions.actionTypes.code === 'PRE');
            if (preAction) {
                questionIds.push(q.id);
                actionTypeId = preAction.actionTypeId;
                if (q.mapkeysId)
                    mapkeyIds.push(q.mapkeysId);
            }
        }

        if (questionIds.length > 0) {
            return this.processQuestionActions(questionIds, actionTypeId, actionFilter, metricEvent, false, mapkeyIds);
        }

        return Promise.resolve();
    }

    processPreShowHideRules(questionIds) {
        const me = this;
        const notEvaluatedQuestionIds = [];
        const section = this.getActiveSection();

        if (section) {
            questionIds.forEach(questionId => {
                me.updateInterviewJson({ id: questionId }, false);

                const question = section.questions.find(x => x.id === questionId);
                if (question) {
                    let questionPreActions = question.questionActions.filter(x => x.actions && x.actions.actionTypes.code === 'PRE');
                    questionPreActions = _.sortBy(questionPreActions, 'order');

                    questionPreActions.forEach(action => {
                        if (this.accountRules[action.rulesId] == null) {
                            notEvaluatedQuestionIds.push(questionId);
                        } else { // todo: remove jsonRule
                            this.rulesService.execute(this.interviewData.caseDetails.id, this.accountRules[action.rulesId], me.ruleFacts, this.interviewData.clientId).then(result => {

                                const question = section.questions.filter(x => x.id === questionId);
                                if (action.actions.name === 'Hide') {
                                    action.actionResults.data.display = !result;

                                    if (question && question[0].repeatFormId && question[0].parentQuestionId) {
                                        question.forEach(updateQuestion => {
                                            updateQuestion.display = !result;
                                        });
                                    }
                                } else if (action.actions.name === 'Disabled') {
                                    action.actionResults.data.disabled = result;

                                    if (question && question[0].repeatFormId && question[0].parentQuestionId) {
                                        question.forEach(updateQuestion => {
                                            updateQuestion.disabled = result;
                                        });
                                    }
                                } else {
                                    action.actionResults.data.display = result;
                                    if (question && question[0].repeatFormId && question[0].parentQuestionId) {
                                        question.forEach(updateQuestion => {
                                            updateQuestion.display = result;
                                        });
                                    }
                                }

                                me.preShowHideData.next({ 'action': action, 'questionId': questionId });
                            });
                        }
                    });
                }
            });

        }

        return notEvaluatedQuestionIds;
    }

    signatureActionResponseHandler(action) {
        if (action.actionResults.isError === true) {
            // there were no documents that require signature
            this.hideActionDialog.emit();
        } else if (action.actionResults.responseCode === 'embeddedResponse') {
            // an envelope already existed, and the document changekeys did not change, therefore
            // we are popping the existing envelope and do not need to run the integration.
            this.hideActionDialog.emit();
            this.showEnvelopeDialog.emit(action.actionResults.data);
        } else if (action.actionResults.responsecode === 'integrationTriggered') {
            // leave the action dialog up -- we will be updating it as the integration updates come in.
        }
    }

    getFormControlPrefill(question: InterviewQuestionDTO, formControlId: string) {
        if (question.repeatFormId && question.repeatFormId !== '00000000-0000-0000-0000-000000000000' && this.prefillReflexivesInRepeatsToggle) {
            //Check for reflxive, if so then dive in
            const repeatForm = this.repeatForms[question.repeatFormId];
            if (!repeatForm?.get(formControlId)) {
                const reflexiveForm = this.repeatReflexiveForms[question.repeatFormId];
                if (reflexiveForm?.get(formControlId)) {
                    return reflexiveForm.get(formControlId);
                }
            }
        }
        return this.getFormControl(question, formControlId);
    }

    getFormControl(question: InterviewQuestionDTO, formControlId: string) {
        if (question.parentQuestionId === '00000000-0000-0000-0000-000000000000') {
            if (this.getDynamicForm().get(formControlId)) {
                return this.getDynamicForm().get(formControlId);
            }
        } else {
            if (question.repeatFormId && question.repeatFormId !== '00000000-0000-0000-0000-000000000000') {
                const repeatForm = this.repeatForms[question.repeatFormId];
                if (repeatForm?.get(formControlId)) {
                    return repeatForm.get(formControlId);
                }
            }

            const reflexiveForm = this.reflexiveForms[question.parentQuestionId];
            if (reflexiveForm?.get(formControlId)) {
                return reflexiveForm.get(formControlId);
            }
        }
        return null;
    }

    populateFieldsByMapKeyName(mapKeyName, value) {
        this.interviewData.sections.forEach(section => {
            section.questions.forEach(question => {
                if (question.mapKeyName === mapKeyName) {
                    question.answerValue = value;
                    const formControl = this.getFormControl(question, question.id);
                    if (formControl !== null) {
                        formControl.setValue(value);
                    }
                }
            });
        });
    }

    processActionResponse(action: QuestionActionsDTO) {
        if (action.actionsId === ActionEnum.TriggerPrefill) {
            action.actionResults.mapkeyValues.forEach(mapkey => {

                this.populateFieldsByMapKeyName(mapkey.name, mapkey.value);
                this.interviewData.sections.forEach(section => {
                    section.questions.forEach(question => {
                        if (question.mapKeyName === mapkey.name) {
                            if (question.answerTypeId === AnswerTypes.DateAnswer) {
                                question.answerTimeStamp = new Date();
                                const formControl = this.getFormControlPrefill(question, `${question.id}_forceUpdate`);
                                if (formControl !== null) {
                                    formControl.setValue(moment(mapkey.value).format('YYYY-MM-DD'));
                                }
                            } else if ((question.answerTypeId === AnswerTypes.ShortAnswer || question.answerTypeId === AnswerTypes.NumericAnswer) && (this.prefillReflexivesInRepeatsToggle)) {
                                const formControl = this.getFormControlPrefill(question, `${question.id}_forceUpdate`);
                                if (formControl !== null) {
                                    // The value is set forces the directives to run and applies masking.
                                    formControl.setValue(mapkey.value);
                                }
                            } else {
                                const formControl = this.getFormControl(question, `${question.id}_forceUpdate`);
                                if (formControl !== null) {
                                    formControl.setValue(mapkey.value);
                                }
                            }
                        }
                    });
                });
            });
            action.actionResults.mapkeyValues = null;
        }
    }

    processQuestionActions(questionIds: string[], actionTypeId: string, actionFilter: ActionEnum[], metricEvent?: MetricEvent, bypassLoader = false, mapkeyIds?: string[]) {
        let resultPromise: Promise<void> = Promise.resolve();

        let notEvaluatedQuestionIds = questionIds;
        if (this.interviewData.caseDetails && this.interviewData.caseDetails.mapkeys && actionTypeId === '00000000-0000-0000-0000-000000000001') {
            notEvaluatedQuestionIds = this.processPreShowHideRules(questionIds);
        }

        if (notEvaluatedQuestionIds.length > 0) {
            const request: CallActionsInSequenceRequest = {
                questionIds: notEvaluatedQuestionIds,
                section: this.getActiveSection(),
                actionFilters: actionFilter,
                mapkeyIds: mapkeyIds ?? []
            };

            if (!(this.isCaseSaving || this.isCallingAction)) {
                this.isCallingAction = true;

                resultPromise = lastValueFrom(this.callActionsInSequence(request, actionTypeId, bypassLoader).pipe(
                    map(response => {
                        this.actionData.next({ data: response.data, questionIds: request.questionIds });

                        if (response.status === 'success') {
                            response.data.forEach(action => {
                                if (action.actionResults && action.actionResults.mapkeyValues) {
                                    this.mergeMapkeys(action.actionResults.mapkeyValues);
                                    this.processActionResponse(action);
                                    if (this.featureManagerService.getByName(FeatureToggle.CaseRuntimeProcessPreActions).enabled) {
                                        this.updateQuestionPreActionsForSection(this.interviewData.activeSectionId);
                                    }
                                }
                                
                                if (action.actionResults && action.actionResults.rulesResult && action.actionResults.rulesResult.result === false) {                                    
                                    this.waitForResponseDialog.emit({ show: false });
                                }

                                if (this.actionResponseHandlers[action.actionsId] != null) {
                                    this.actionResponseHandlers[action.actionsId](action);
                                }

                                if (this.isCheckingRnaSsnDup) this.checkedRnaSsnDupes();
                            });
                        } else {
                            this.notificationService.showNotification({ severity: NotificationSeverity.Error, message: response.message, log: true });

                            this.waitForResponseDialog.emit({ show: false });
                        }
                    }),
                    tap(_ => {
                        this.isCallingAction = false;
                        this.callPendingActions();

                        if (metricEvent) {
                            this.monitoringService.endInterviewMetric(metricEvent);
                        }
                    }),
                    catchError((err: HttpErrorResponse) => {
                        this.isCallingAction = false;
                        if (err.name != 'HttpErrorResponse') {
                            this.callPendingActions();
                        }
                        this.hideActionDialog.emit();
                        this.waitForResponseDialog.emit({ show: false });

                        if (this.isCheckingRnaSsnDup) {
                            this.handleRnaSsnDupCheckError(request, questionIds);
                        }
                        if (metricEvent) {
                            this.monitoringService.endInterviewMetric(metricEvent);
                        }

                        const error = new Error(err.message);
                        return throwError(() => error);
                    })
                ));

                resultPromise.then(Utils.noop, error => {
                    this.handleError(error);
                });
            } else {
                const pendingAction = { questionIds: questionIds, actionTypeId: actionTypeId };
                this.pendingQuestionActions.push(pendingAction);

                if (metricEvent) {
                    this.monitoringService.endInterviewMetric(metricEvent);
                }
            }
        } else {
            if (metricEvent) {
                this.monitoringService.endInterviewMetric(metricEvent);
            }
        }

        return resultPromise;
    }

    handleRnaSsnDupCheckError(request: CallActionsInSequenceRequest, questionIds: string[]) {
        this.checkedRnaSsnDupes();
        this.monitoringService.logEvent('RNA SSN Dup Check fired but failed due to server error');
        if (this.featureManagerService.getByName(FeatureToggle.HandleHttpErrorResponseForSSNDupeCheck).enabled) {
            request.section.questions.forEach(question => {
                // TECH DEBT[question.actionIds]: See `interview-question-dto.d.ts`
                if (question.id === questionIds[0] && question.actionIds && JSON.parse(question.actionIds).some(actionId => actionId === ActionEnum.RNASSNDupCheck)) {

                    this.dynamicForm.get(request.questionIds)?.setErrors({ 'validationError': HTTP_ERRORRESPONSE_RNA_SSN_DUP_CHECK_ERROR });
                }
            });
        }
    }

    handleError(error: HttpErrorResponse) {
        if (error == null) {
            return;
        }

        if (error.status === 403)
            this.handleUnauthorizedError(error);
    }

    handleUnauthorizedError(error) {
        this.unauthorizedAccessSource.next(error.error);
    }

    callPendingActions() {
        if (this.pendingQuestionActions.length > 0) {
            const pendingAction = this.pendingQuestionActions[0];
            this.shouldDoRnaSsnDupCheck(pendingAction.id);
            this.pendingQuestionActions.splice(0, 1);

            const mapkeyIds: string[] = [];
            if (this.featureManagerService.getByName(FeatureToggle.GlobalPreFillSequenceContainsNoElementsBugFix).enabled && pendingAction && pendingAction.questionIds && pendingAction.questionIds.length > 0) {
                const section = this.interviewData.sections.find(s => s.id === this.interviewData.activeSectionId);
                const questionMapkeys = section.questions.filter(question => pendingAction.questionIds.some(q => q === question.id)).map(x => x.mapkeysId);
                for (const qM of questionMapkeys) {
                    if (qM && !mapkeyIds.some(x => x === qM)) {
                        mapkeyIds.push(qM);
                    }
                }
            }
            this.processQuestionActions(pendingAction.questionIds, pendingAction.actionTypeId, null, null, false, mapkeyIds);
        }
    }

    shouldDoRnaSsnDupCheck(actionId: string) {
        if (actionId == ActionEnum.RNASSNDupCheck)
            this.isCheckingRnaSsnDup = true;
    }

    checkedRnaSsnDupes() {
        this.isCheckingRnaSsnDup = false;
    }

    async processInterviewSaveSuccess(response: UWResponse<InterviewDTO>, activeSection: InterviewSectionDTO, processingPopupVisible: boolean) {
        if (response.status === 'success') {
            this.mergeInterviewData(response.data);
            this.updateInterviewBasedOnSectionActions(activeSection.id, response.data.sections);
            const sectionHasErrors = this.checkForSectionErrors(activeSection.id, response.data.sections);

            if (sectionHasErrors) {
                // Not sure this is the way to go but from a UX point of view the user
                // Can close the SaaD if needed.
                //this.interviewSectionActiveService.closePopup();
                this.updatedData.next(this.interviewData);
            } else {
                if (processingPopupVisible) { // if it was popped above, now re-run the rule.
                    processingPopupVisible = await this.interviewSectionActiveService.rerunPopupRule(this.interviewData.caseDetails.accountId, this.interviewData.caseDetails.id, activeSection.id, this.interviewData.visibleSections);
                } else {
                    processingPopupVisible = await this.interviewSectionActiveService.sectionShouldWait(this.interviewData.caseDetails.accountId, this.interviewData.caseDetails.id, activeSection.id, this.interviewData.visibleSections);
                }

                if (!processingPopupVisible) {
                    this.updatedData.next(this.interviewData);
                } else {
                    this.sectionWaitSubscription = this.interviewSectionActiveService.sectionWaitingObservable.subscribe((waiting: boolean) => {
                        if (!waiting) {
                            // we were waiting so need to re-run section show/hide rules
                            this.processSectionRules(activeSection, activeSection.id);
                        } else {
                            this.processNextSectionData();
                        }
                    });
                }
            }
        } else {
            this.notificationService.showNotification({ severity: NotificationSeverity.Error, message: response.message });
        }

        this.interviewUpdated = false;
        this.isInterviewSaving = false;

    }

    processInterviewSaveException(error: any) {
        this.isInterviewSaving = false;
        this.interviewUpdateError.next(true);
        this.handleError(error);
    }

    async processInterviewAsync(activeSection: InterviewSectionDTO, processingPopupVisible: boolean) {
        try {
            const response = await firstValueFrom(this.appService.addData<UWResponse<InterviewDTO>>(`Interview/${this.interviewData.id}/Process`, activeSection));
            await this.processInterviewSaveSuccess(response, activeSection, processingPopupVisible);
        }
        catch (error) {
            this.processInterviewSaveException(error);
        }
    }

    subscribeToProcessInterview(activeSection: InterviewSectionDTO, processingPopupVisible: boolean) {
        this.appService.addData<UWResponse<InterviewDTO>>(`Interview/${this.interviewData.id}/Process`, activeSection).subscribe({
            next: async (response) => {
                await this.processInterviewSaveSuccess(response, activeSection, processingPopupVisible);
            },
            error: (error) => {
                this.processInterviewSaveException(error);
                this.isInterviewSaving = false;
                this.handleError(error);
            }
        });
    }

    async saveInterview(activeSectionId: string) {
        if (this.isReadOnly === true && !this.isInterviewLocked) {
            return;
        }

        if (this.answeredQuestion) {
            this.interviewData.activeQuestionNumber = this.answeredQuestion.order;
        }

        this.isInterviewSaving = true;
        this.interviewData.activeSectionId = activeSectionId;
        const activeSection = this.getActiveSection();

        if (activeSection === null) {
            console.log('No active section!');
            return;
        }

        const processingPopupVisible = await this.interviewSectionActiveService.sectionShouldWait(this.interviewData.caseDetails.accountId, this.interviewData.caseDetails.id, activeSection.id, this.interviewData.visibleSections);

        activeSection.isMasking = this.interviewData.isCallMasking;
        this.updateInterviewJson(null);

        if (this.featureManagerService.getByName(FeatureToggle.GlobalInterviewWaitForSaveAsPromise).enabled) {
            await this.processInterviewAsync(activeSection, processingPopupVisible);
        }
        else {
            this.subscribeToProcessInterview(activeSection, processingPopupVisible);
        }
    }


    // TECH DEBT: If we're passing the active section, we don't need to pass the active section id
    processSectionRules(activeSection: any, activeSectionId: any) {
        this.appService.addData(`Interview/${this.interviewData.id}/ProcessSectionRules`, activeSection).subscribe((result) => {
            this.mergeInterviewData(result.data);
            this.updateInterviewBasedOnSectionActions(activeSectionId, result.data.sections);
            this.processNextSectionData();
            
        });
    }

    checkForSectionErrors(activeSectionId, sections): boolean {
        let hasErrors = false;
        const section = sections.find(s => s.id === activeSectionId);
        hasErrors = section.errors && section.errors.length > 0;
        return hasErrors;
    }

    private processNextSectionData() {
        // if we were waiting, then at some point we updated VMKs, so we need to update the _next_ section
        // show/hides based on those re-calculations _before_ we send the signal to swtich sections.
        this.updateQuestionPreActionsForSection(this.interviewData.activeSectionId);
        this.updatedData.next(this.interviewData);

        if (this.sectionWaitSubscription)
            this.sectionWaitSubscription.unsubscribe();
    }


    updateQuestionPreActionsForSection(sectionId: string) {
        const section = this.interviewData.sections.find(s => s.id === sectionId);
        const questions: InterviewQuestionDTO[] = [];
        const actionQuestions = section.questions.filter(question => question.questionActions && question.questionActions.length > 0);

        actionQuestions.forEach(eachQuestion => {
            if (eachQuestion.questionActions.some(action => action.actionsId === ActionEnum.Show || action.actionsId === ActionEnum.Hide || action.actionsId === ActionEnum.Disabled)) {
                questions.push(eachQuestion);
            }
        });

        if (questions && questions.length > 0) {
            return this.callPreActionsForHiddenFields(questions, [ActionEnum.Show, ActionEnum.Hide, ActionEnum.Disabled]);
        }

        return Promise.resolve();
    }

    updateInterviewBasedOnSectionActions(sectionId, sections, _currentSection?) {
        const section = sections.find(s => s.id === sectionId);
        if (section.sectionActions && section.sectionActions.length > 0) {
            section.sectionActions.forEach(sectionAction => {
                if (sectionAction.actions.integration_Id && sectionAction.actions.integration_Id != null
                    && sectionAction.actions.integrationVersion === 2
                    && sectionAction.actionResults
                    && !sectionAction.actionResults.suppressError) {
                    const integrationV2 = this.getIntegrationV2Data(sectionAction.id);
                    integrationV2.sourceAction = sectionAction.id;
                    integrationV2.integrationId = sectionAction.actions.integration_Id;
                    integrationV2.name = sectionAction.actions.name;
                    integrationV2.sectionId = sectionAction.sectionId;
                    integrationV2.status = (sectionAction.actionResults.data && sectionAction.actionResults.data.integrationStatus) ? sectionAction.actionResults.data.integrationStatus : integrationV2.status;
                    if (sectionAction.actionResults.isError) {
                        this.notificationService.showNotification({ severity: NotificationSeverity.Error, message: sectionAction.actionResults.message });
                    }

                    this.triggerRefreshIntegrationSubject.next(integrationV2);
                }
            });
        }
    }

    updateSectionDisabledAction(sections, currentSection) {
        const section = sections.find(s => s.id === currentSection.id);
        if (section.sectionActions && section.sectionActions.length > 0) {
            section.sectionActions.forEach(sectionAction => {
                if (sectionAction.actions.id === ActionEnum.Disabled && sectionAction.actionResults) {
                    currentSection.isLocked = sectionAction.actionResults.data.disabled;
                }
            });
        }
    }

    saveCase(accountId: string) {
        if (this.isReadOnly === true && !this.isInterviewLocked) {
            return;
        }
        const url = `Interview/${accountId}/SaveCase`;

        this.updateInterviewJson(null);
        this.isCaseSaving = true;

        this.interviewData.clientIp = this.clientIp;
        this.appService.addData(url, this.interviewData).subscribe(
            data => {
                this.caseSavedSource.next(data.data);
                if (data.status === 'success') {
                    // this.appService.showMsg("success", data.message);
                } else {
                    this.notificationService.showNotification({ severity: NotificationSeverity.Error, message: data.message });
                }
                this.interviewUpdated = false;
                this.isCaseSaving = false;
                this.callPendingActions();
                //Update any questions with constant variants that have dependencies on the first question
                const firstSection = data.data.sections[0];
                this.checkForConstantVariantDependantQuestions(firstSection.questions[0]).catch((e) => { console.log(e); });
            },
            error => {
                this.isCaseSaving = false;
                this.pendingQuestionActions = [];
                this.handleError(error);
            }
        );
    }

    async autoSaveInterview(accountId: string, value, terminateMessage, clickSource = null) {
        this.monitoringService.logEvent(`${this.getInterviewData().caseDetails.caseNumber} - Autosave interview`);
        if (this.isReadOnly === true && !this.isInterviewLocked) {
            return;
        }

        if (this.answeredQuestion) {
            this.interviewData.activeQuestionNumber = this.answeredQuestion.order;
        }

        if (this.answeredQuestionId) {
            this.interviewData.activeQuestionId = this.answeredQuestionId;
        }

        let url = '';
        if (value === 'AutoSave') {
            url = `Interview/${accountId}/AutoSave/`;
            if (!this.isInterviewSaving && !this.isCallingAction && this.interviewUpdated) {
                this.appService.updateData(url, this.interviewData).subscribe({
                    next: (data) => {
                        // this.updatedData.next(data.data);
                        // this.caseSavedSource.next(data.data);
                        if (data.status === 'success') {
                            // this.appService.showMsg("success", data.message);
                        } else {
                            this.notificationService.showNotification({ severity: NotificationSeverity.Error, message: data.message });
                        }
                        this.interviewUpdated = false;
                    },
                    error: (error) => {
                        this.handleError(error);
                    }
                });
            }
        } else {
            url = `Interview/${accountId}/${value}`;
            if (value === 'TerminateInterview') {
                this.interviewData.terminationCaseNotes = terminateMessage;
                if (this.cancelActionButton) {
                    url += `/${this.cancelActionButton}`;
                }
            }
            if (!this.isInterviewSaving && !this.isCallingAction) {
                this.appService.updateData(url, this.interviewData)
                    .subscribe({
                        next: (data: any) => {
                            if (clickSource !== InterviewCloseEnum.SignOut && clickSource !== InterviewCloseEnum.ApplicantTerminate) {
                                this.updatedData.next(data.data);
                            }
                            this.stopSocketMonitor = true;

                            // this.caseSavedSource.next(data.data);
                            if (data.status === 'success') {
                                // close socket
                                // this.appService.showMsg("success", data.message);
                            } else {
                                this.notificationService.showNotification({ severity: NotificationSeverity.Error, message: data.message });

                            }
                            this.interviewUpdated = false;
                        },
                        error: (error) => {
                            this.handleError(error);
                        }
                    });

            }
        }


    }

    // TECH DEBT: This method doesn't appear to be used anywhere. Can this be removed?
    // callPreActions(config) {
    //     if (config.questionActions && config.questionActions.length) {
    //         const preAction = config.questionActions.find(p => p.actions.actionTypes.code === 'PRE');
    //         if (preAction) {
    //             const questionIds = [];
    //             questionIds.push(config.id);
    //             this.processQuestionActions(questionIds, preAction.actionTypeId, null);
    //         }
    //     }
    // }

    anyPendingIntegrations(question) {
        if (!this.ActionButtonsCancelList.some(x => x === question.id)) {
            return false;
        }
        const integrationActions = this.appService.getDynamicConfigData('integrationActions');
        const integrationIds = [];
        question.questionActions.forEach(action => {
            const intaction = integrationActions.find(y => y.actionId === action.actionsId);
            if (intaction) {
                integrationIds.push(intaction.id);
            }
        });
        let anyPendingActions = false;
        integrationIds.forEach(intgId => {
            const data = this.getIntegrationDataById(intgId);
            const integrationStatus = this.getIntegrationStatus(data.status);
            if (integrationStatus === 'Pending' || integrationStatus === 'Running') {
                anyPendingActions = true;
            }
        });

        // are any of my actions for this question still pending?
        question.questionActions.forEach(questionAction => {
            const integrationId = questionAction.actions.integration_Id;
            if (integrationId) {
                const integration = this.integrations.find(x => x.integrationId === integrationId);

                const integrationStatus = this.getIntegrationStatus(integration?.status);
                if (integrationStatus === 'Pending' || integrationStatus === 'Running') {
                    anyPendingActions = true;
                }
            }
        });


        if (anyPendingActions) {
            return true;
        }
        return false;
    }

    isActionsCallble(question) {
        const integrationActionExists = question.questionActions.some(x => x.actions.actionCategoriesId === this.INTEGRATION_CATEGORY);
        if (question.answerType === 'ActionButton' && integrationActionExists) { // && check for any  Actions with Integrations category available
            if (this.anyPendingIntegrations(question)) {
                return false;
            }
            if (!this.ActionButtonsCancelList.some(x => x === question.id)) {
                this.ActionButtonsCancelList.push(question.id);
            }
        }
        return true;
    }

    integrationInProgress(question) {
        let integrationnames = '';
        question.questionActions.forEach(eachAction => {
            if (eachAction.actions.actionCategoriesId === this.INTEGRATION_CATEGORY) {
                if (eachAction.actions.integrationVersion === 2) {
                    integrationnames += eachAction.actions.name + ',';
                } else {
                    const integrationActions = this.appService.getDynamicConfigData('integrationActions');
                    const integrationAction = integrationActions.find(x => x.actionId === eachAction.actionsId);
                    integrationnames += integrationAction.name + ',';
                }
            }
        });
        integrationnames = integrationnames.slice(0, integrationnames.length - 1);
        this.confirmationService.confirm({
            message: `The ${integrationnames} integration is already in progress.`,
            key: 'giactCancelConfirm',
            showCancel: false,
            okLabel: 'Ok',
            accept: Utils.noop
        });
    }

    refreshIntegration(integrationId, integrationRequestId, status, name) {
        const url = `Case/${this.getInterviewData().caseDetails.id}/RefreshIntegration`;
        return this.appService.postData(url, { 'id': integrationId, 'name': name, 'integrationRequestId': integrationRequestId }, true, false);
    }

    popupIntegrationResponse(questionId, sectionActionId) {
        let triggerDocuSignEmailMapping = this.appService.getDynamicConfigData('actionIntegrationMappings').filter(x =>
            x.action_Id === "00000000-0000-0000-0000-000000000036" &&
            x.metadata_Id === 7 &&
            x.mappingType_Id === "00000000-0000-0000-0000-000000000001");

        if (questionId) {
            triggerDocuSignEmailMapping = triggerDocuSignEmailMapping.find(x =>
                x.question_Id === questionId);
        } else {
            triggerDocuSignEmailMapping = triggerDocuSignEmailMapping.find(x =>
                x.sectionAction_Id === sectionActionId);
        }


        if (triggerDocuSignEmailMapping) {
            const mapKey_Id = triggerDocuSignEmailMapping.mapKey_Id;
            const virtualMapkey = this.virtualMapkeys.find(x => x.id === mapKey_Id);

            const mapKeyInInterview = this.getAnswerValueForMapKeyId(mapKey_Id);
            const vmkValue = this.getMapkeyValue(virtualMapkey.entityHierarchy);

            if ((mapKeyInInterview && mapKeyInInterview.toLowerCase() === 'true') || (vmkValue && vmkValue.toLowerCase() === 'true')) {
                return false;
            }
        }
        return true;
    }

    checkIfNeededToWait(actionResult, integration?) {
        let waitResult = false;
        let waitActionParam;

        const configActionData = this.configData.actions.find(a => a.id === actionResult.actions.id);
        if (configActionData) {
            if (configActionData.actionParams && configActionData.actionParams.length > 0) {
                waitActionParam = configActionData.actionParams.find(p => p.params == 'waitForResponse');

                if (waitActionParam) {
                    if (actionResult.actionParameters && actionResult.actionParameters.length > 0) {
                        const waitActionObj = actionResult.actionParameters.find(x => x.actionParamsId == waitActionParam.id);

                        if (waitActionObj && waitActionObj.paramValue.toLowerCase() === 'true') {
                            waitResult = true;
                        }
                    }
                }
            }
        }

        if (waitResult) {
            const configActionData = this.configData.actions.find(a => a.id === actionResult.actions.id);
            const statusMessage = configActionData.actionParams.find(p => p.params === 'statusMessage');
            const statusDisplayType = configActionData.actionParams.find(p => p.params === 'statusDisplayType');
            let displayTypeObj: any;
            let statusMessageObj: any;
            if (statusDisplayType) {
                displayTypeObj = actionResult.actionParameters.find(x => x.actionParamsId == statusDisplayType.id);
            }

            if (statusMessage) {
                statusMessageObj = actionResult.actionParameters.find(x => x.actionParamsId == statusMessage.id);
            }
            if (displayTypeObj && displayTypeObj.paramValue.toLowerCase() == 'popup') {
                const pendingIntegration: IntegrationPending = {
                    actionName: actionResult.actions.displayName,
                    actionStatus: this.getIntegrationStatus(actionResult.actionResults.data.integrationStatus),
                    message: statusMessageObj.paramValue,
                    integration: integration
                };
                const waitForResponseDialogMessage = {
                    pendingIntegration: pendingIntegration,
                    show: statusMessage
                }

                this.waitForResponseDialog.emit(waitForResponseDialogMessage);
            }
        }
        return waitResult;
    }

    callPostActions(config) {
        if (config.questionActions && config.questionActions.length) {
            const postAction = config.questionActions.find(p => p.actions.actionTypes.code === 'POST');
            if (postAction) {
                if (this.isActionsCallble(config)) {
                    for (const eachAction of config.questionActions) {
                        if (eachAction.actions.id === '00000000-0000-0000-0000-000000000007') {
                            this.actionData.next({ type: 'GIACT', questionId: config.id });
                        } else if (eachAction.actions.id === '00000000-0000-0000-0000-000000000009') {
                            this.actionData.next({ type: 'MIB', questionId: config.id });
                        } else if (eachAction.actions.id === '00000000-0000-0000-0000-000000000010') {
                            this.actionData.next({ type: 'RX', questionId: config.id });
                        } else if (eachAction.actions.id === '00000000-0000-0000-0000-000000000012') {
                            this.actionData.next({ type: 'Agent', questionId: config.id });
                        } else if (eachAction.actions.id === '00000000-0000-0000-0000-000000000011') {
                            this.actionData.next({ type: 'Quote', questionId: config.id });
                        } else if (eachAction.actions.id === '00000000-0000-0000-0000-000000000004' && config.answerType === 'ActionButton') {
                            if (eachAction.actionParameters && eachAction.actionParameters[0]) {
                                const status = _.find(this.configData.caseStatus, ['id', eachAction.actionParameters[0].paramValue]);
                                this.cancelAction.next(status);
                                this.cancelActionButton = config.id;
                                return;
                            }
                        } else {
                            this.actionData.next({ type: eachAction.actions.name, questionId: config.id });
                        }

                        this.shouldDoRnaSsnDupCheck(eachAction.actions.id);
                    }
                    const questionIds = [];
                    const mapkeyIds = [];
                    questionIds.push(config.id);
                    if (config.mapkeysId)
                        mapkeyIds.push(config.mapkeysId);

                    // See if there is a docusign post action - if so, we are going to pre-emptively pop a custom dialog
                    // if it is not an email flow

                    const docuSignAction = config.questionActions && config.questionActions.find(x => x.actions.id === ActionEnum.DocuSignESig);
                    const isDocuSign = docuSignAction != null;

                    if (isDocuSign && this.popupIntegrationResponse(config.id, '')) {
                        this.showActionDialog.next({
                            actionName: 'Preparing Signature Package',
                            actionStatus: 'Pending',
                            message: 'Generating your documents...',
                            footer: 'Hang tight, this process may take a couple minutes!',
                            alternateLoader: './assets/images/document.gif',
                            hideRefresh: true,
                            hideStatus: true,
                            textAlignment: 'center',
                            integrationId: IntegrationEnum.Docusign,
                            integration: {}
                        });
                    }

                    if (config.questionActions && config.questionActions.find(x => x.actions.id === ActionEnum.Magnum)) {
                        this.showActionDialog.next({
                            actionName: 'Swiss Re: Magnum',
                            actionStatus: 'Pending',
                            message: 'Preparing Magnum Interview Display Component...',
                            footer: 'Getting Magnum Case Started',
                            alternateLoader: './assets/images/document.gif',
                            hideRefresh: true,
                            hideStatus: true,
                            textAlignment: 'center',
                            integrationId: IntegrationEnum.Magnum,
                            integration: {}
                        });
                    }

                    this.processQuestionActions(questionIds, postAction.actionTypeId, null, null, isDocuSign, mapkeyIds);
                } else {
                    this.integrationInProgress(config);
                    this.ActionButtonsCancelList = this.ActionButtonsCancelList.splice(this.ActionButtonsCancelList.indexOf(config.id) - 1, 1);
                }
            }
        }
    }

    getDependentMapKeys(questionId: string, questionActionId: string) {
        const url = `Interview/${questionId}/QuestionAction/${questionActionId}`;
        this.appService.getData(url).subscribe(
            data => {
                const questions = this.getActiveSection().questions;
                for (const eachMapKey of data.data) {
                    const depQuestion = questions.find(q => q.mapkeysId === eachMapKey);
                    if (depQuestion) {
                        depQuestion.dependentQuestions = depQuestion.dependentQuestions ? depQuestion.dependentQuestions : [];
                        if (!depQuestion.dependentQuestions.includes(questionId)) {
                            depQuestion.dependentQuestions.push(questionId);
                        }

                    }
                }
            }
        );

    }

    checkIfNeedToStartIntegrationInWaitingState(action: ActionsDTO, questionId: string) {
        if (action.integration_Id && action.integration_Id != null
            && action.integrationVersion === 2) {
            const integrationV2 =
            {
                id: questionId,
                integrationId: action.integration_Id,
                status: QueueStatusEnum.Pending,
                details: null,
                errorMessage: null,
                questionId: '',
                waitForResponse: false,
                sourceAction: questionId,
                name: action.name,
                sectionId: this.interviewData.activeSectionId
            };
            const configActionData = this.configData.actions.find(a => a.id === action.id);
            const params = configActionData?.actionParams?.map(x => {
                const param: any = x;
                param.actionParamsId = x.id;
                if (x.params === 'statusMessage') {
                    param.paramValue = this.waitingForIntegrationDetails;
                }
                else if (x.params === 'statusDisplayType') {
                    param.paramValue = 'popup';
                }
                else {
                    param.paramValue = 'true';
                }
                return param;
            });
            const actionResult = { actions: action, actionParameters: params, actionResults: { data: { integrationStatus: '' } } };
            this.checkIfNeededToWait(actionResult, integrationV2);
        }
    }
    callActionsInSequence(request: CallActionsInSequenceRequest, actionTypeId: string, hideWaiting = false) {
        const url = `Interview/${this.interviewData.id}/ProcessQuestionActions/${actionTypeId}`;

        if (actionTypeId === '00000000-0000-0000-0000-000000000002') {
            request.questionIds.forEach(questionId => {
                const targetQuestion = request.section.questions.find(x => x.id === questionId);
                const integrationActions = targetQuestion.questionActions.filter(x => x.actions.integration_Id != null && x.actions.integration_Id !== '');
                integrationActions.forEach(x => {
                    this.addIntegrationMetric(x.actions.integration_Id);
                    if (this.interviewWaitForIntegrationPrePopup) {
                        this.checkIfNeedToStartIntegrationInWaitingState(x.actions, questionId);
                    }
                });
            });
        }

        // Would normally handle the unauthenticated errors here but they are handled in processquestionactions via piping.
        return this.appService.updateData<UWResponse<QuestionActionsDTO[]>>(url, request, this.interviewData.clientId, hideWaiting);
    }

    addIntegrationMetric(integrationId: string): MetricEvent {
        const metricEvent = this.monitoringService.beginInterviewMetric(`Integration ${integrationId}`, this.getDefaultEventProperties());
        this.integrationMetrics[integrationId] = metricEvent;
        return metricEvent;
    }

    completeIntegrationMetric(integrationId: string): MetricEvent {
        const metricEvent = this.integrationMetrics[integrationId];

        if (metricEvent != null) {
            this.monitoringService.endInterviewMetric(metricEvent);
            delete this.integrationMetrics[integrationId];
        }

        this.monitoringService.flushInterviewMetrics();

        return metricEvent;
    }

    updateMultiChoiceAnswer(questionId: string, value: any) {
        const question = this.getActiveSection().questions.find(q => q.id === questionId);
        this.setQuestionAnswer(question, value);
        this.updatedData.next(this.interviewData);
    }

    getDynamicTextParts(questionText): string[] {
        if (questionText == null) {
            return [];
        }

        return questionText.split(/(%[^%]*%)|({{[^}]*}})/);
    }

    formattedText(questionText) {
        /*
        * The following split() regex splits the text with tokens wrapped with %Mapkey% and {{Case JSON value}}.
        * This will be useful when we implement the ability to include any Case propery, not just mapkeys.
        * This function can be updated to only lookup the included mapkeys/properties.  If the result has
        * only 1 item, there is nothing to replace.  If more than one, loop looking for mapkeys/properties
        * to replace.  Once replaced, join the parts back together.
        */
        const parts = this.getDynamicTextParts(questionText);
        if (parts.length <= 1) {
            return questionText;
        }

        const final = [];
        parts.forEach(part => {
            if (part !== undefined && part !== null) {
                if (part.startsWith('%')) {
                    final.push(this.getMapkeyValue(part));
                } else if (part.startsWith('{{')) {
                    final.push(this.getCasePropertyValue(part));
                } else {
                    final.push(part);
                }
            }
        });

        return final.join('');
    }

    getMapkeyValue(mapkeyName) {
        mapkeyName = mapkeyName.toLowerCase().replace(/%/g, '');
        // If it's a virtual, replace with the replacement string and return it
        if (this.virtualMapkeys) {
            const virtualMapkeyQuestion = this.virtualMapkeys.find(record => mapkeyName === record.entityHierarchy.toLowerCase());
            if (virtualMapkeyQuestion && !virtualMapkeyQuestion.isVirtualExpression) {
                const virtualQuestion = virtualMapkeyQuestion.virtualMapKeysList
                    .map(function (element: any) {
                        return `%${element.listMapKeyName}%`;
                    }).join(virtualMapkeyQuestion.delimiter);

                return this.formattedText(virtualQuestion);
            }
        }

        // Second: Check if there are front-end VMK changes (these changes won't be seen on CaseDetails)
        const mapKey = this.answerValues.find(x => x.name?.toLowerCase() === mapkeyName);
        if (mapKey)
            return mapKey.value;

        // Third: Check for Mapkey value in the backend list
        if (this.interviewData.caseDetails && this.interviewData.caseDetails.mapkeys) {
            for (let i = 0; i < this.interviewData.caseDetails.mapkeys.length; i++) {
                const quoteMapkey = this.interviewData.caseDetails.mapkeys[i];
                if (quoteMapkey.name && quoteMapkey.name.toLowerCase() === mapkeyName) {
                    return quoteMapkey.value;
                }
                // TECH DEBT[quoteMapkey.Name]: See `case-mapkey-dto.d.ts`
                if (quoteMapkey.Name && quoteMapkey.Name.toLowerCase() === mapkeyName) {
                    return quoteMapkey.Value;
                }
            }
        }

        let mapkeyValue = '';
        if (this.interviewData.sections) {
            const sections = this.interviewData.sections;
            sections.forEach(section => {
                section.questions.forEach(async question => {
                    if (question.mapKeyName && question.mapKeyName.toLowerCase() === mapkeyName && question.answerValue) {
                        if (question.answerType === 'MultiChoiceSingleAnswer') {
                            const constantLists = await this.constantMapKeyService.getCachedConstantMapkeys(this.account.id, this.account.versionNumber, section.id);

                            if (question['multiChoiceAnswers'][0] &&
                                question['multiChoiceAnswers'][0].constantKeyNavigation &&
                                question['multiChoiceAnswers'][0].constantKeyNavigation.constantMapKeyValues) {

                                const constantList = constantLists[question['multiChoiceAnswers'][0].constantKeyNavigation.id];
                                const listValue = constantList.find(v => v.value === question.answerValue);
                                mapkeyValue = listValue.displayName;
                            }
                        } else {
                            mapkeyValue = question.answerValue;
                        }
                    }
                });
            });
        }

        return mapkeyValue;
    }

    /*
    This accepts any JSON reference into the data and returns it
    */
    getCasePropertyValue(path) {
        try {
            return _.get(this.interviewData, path.replace('{{', '').replace('}}', ''));
        } catch (e) {
            return path;
        }
    }

    getInnerText(config, form) {

        if (config.integrationMessages === 'SSN') {
            if (config.intergrationData && config.intergrationData.hasDuplicateSSN) {
                if (!form.get(config.id).hasError('ssn')) {
                    form.get(config.id).setErrors({
                        'ssn': true
                    });
                }

                return config.intergrationData.message;
            } else {
                form.get(config.id).setErrors(null);
                config.integrationMessages = false;
                return '';
            }


        }
        if (config.integrationMessages === 'GIACT') {
            return config.intergrationData;
        }


    }

    getErrorMessage(config, form) {
        if (config.errorData && config.errorData.message) {

            if (!form.get(config.id).hasError('validationError')) {
                form.get(config.id).setErrors({
                    'validationError': true
                });
            }

            return config.errorData.message;
        } else {
            form.get(config.id).setErrors(null);
            config.integrationMessages = false;
            return '';
        }


    }

    getAnswerValueForMapKeyId(mapKeyId) {
        let returnValue;
        for (const eachSection of this.interviewData.sections) {
            for (const eachQuestion of eachSection.questions) {
                if (eachQuestion.mapkeysId === mapKeyId) {
                    if (eachQuestion.answerValue) {
                        returnValue = eachQuestion.answerValue;
                    }

                }
            }
        }
        return returnValue;
    }

    clearCancelActionButton() {
        this.cancelActionButton = null;
    }

    updateLastAnswered() {
        const url = `Interview/${this.interviewData.id}/UpdateInterview`;
        const activeSection = this.getActiveSection();

        if (this.interviewData.caseDetails && this.interviewData.caseDetails.id) {
            const updateInterview: any = {
                id: this.interviewData.caseDetails.id,
                activeQuestionId: this.answeredQuestionId,
                activeSectionId: activeSection.id
            };

            this.appService.sendData(url, updateInterview);
        }
    }

    private getDefaultEventProperties(): { [key: string]: string } {

        const result = {};

        if (this.interviewData) {
            result['clientId'] = this.interviewData.clientId;
            result['accountId'] = this.interviewData.accountId;
            result['interviewId'] = this.interviewData.id;
            result['sectionId'] = this.interviewData.activeSectionId;
        }

        return result;
    }

    public flushInterviewMetrics() {
        this.monitoringService.flushInterviewMetrics();
    }

    public selectSection(section: InterviewSectionDTO) {
        section.isMasking = this.interviewData.isCallMasking;
        const url = `interview/${this.interviewData.id}/section`;

        return this.appService.addData<UWResponse<InterviewDTO>>(url, section);
    }

    public checkStatus(section: InterviewSectionDTO) {
        const url = `interview/${this.interviewData.id}/CheckStatus`;
        return this.appService.addData<UWResponse<boolean>>(url, section);
    }

    public checkStatusV2() {
        const url = `interview/${this.interviewData.caseDetails.id}/CheckStatusV2`;
        return this.appService.getData<UWResponse<AccountCheckStatusV2>>(url);
    }

    public removeRepeatBlocks(repeatBlockQuestion) {
        const activeSection = this.getActiveSection();

        // delete extra repeat blocks, clear first block mapkey
        let idx = 0;
        repeatBlockQuestion.blocks.forEach(block => {
            block.questions.forEach(question => {
                if (idx === 0) {
                    const existingQuestion = activeSection.questions.find(q => q.mapkeysId === question.mapkeysId);
                    if (existingQuestion != null) {
                        existingQuestion.display = false;
                        existingQuestion.answerValue = '';
                        existingQuestion.answerTimeStamp = new Date();
                    }
                } else {
                    const existingQuestionIndex = activeSection.questions.findIndex(q => q.mapkeysId === question.mapkeysId);
                    if (existingQuestionIndex != null && existingQuestionIndex !== -1) {
                        activeSection.questions.splice(existingQuestionIndex, 1);
                    }
                }
            });

            if (block.refQuestions && block.refQuestions.length > 0) {
                block.refQuestions.forEach(refQuestion => {
                    if (idx === 0) {
                        const existingQuestion = activeSection.questions.find(q => q.mapkeysId === refQuestion.mapkeysId);
                        if (existingQuestion != null) {
                            existingQuestion.display = false;
                            existingQuestion.answerValue = '';
                            existingQuestion.answerTimeStamp = new Date();
                        }
                    } else {
                        const existingQuestionIndex = activeSection.questions.findIndex(q => q.mapkeysId === refQuestion.mapkeysId);
                        if (existingQuestionIndex != null && existingQuestionIndex !== -1) {
                            activeSection.questions.splice(existingQuestionIndex, 1);
                        }
                    }
                });
            }
            idx++;
        });
    }
}
