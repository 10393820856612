import { Component, OnInit, ViewChildren } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MaskValueDirective } from 'app/directives';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { BaseFormFieldServices, BaseInterviewFormFieldComponent } from '../base-interview-form-field/base-interview-form-field.component';

@Component({
    selector: 'numeric-answer',
    templateUrl: './numeric-answer.component.html',
    styleUrls: ['./numeric-answer.component.scss']
})
export class NumericAnswerComponent extends BaseInterviewFormFieldComponent implements OnInit {
    // used for adding $ if field is currency type.
    numberMask: any;

    constructor(private fb: UntypedFormBuilder, public services: BaseFormFieldServices) {

        super(services);
    }
    @ViewChildren(MaskValueDirective) maskValueDirective;
    forcedFieldName: string;

    ngOnInit() {
        super.initialize({ inputType: 'text' });

        if (this.config.MaxNumberDecimalPlaces && this.config.MaxNumberDecimalPlaces != null) {
            this.numberMask = createNumberMask({
                prefix: '$',
                suffix: '',
                allowDecimal: true,
                decimalLimit: parseInt(this.config.MaxNumberDecimalPlaces)
            });
        } else {
            this.numberMask = createNumberMask({
                prefix: '$',
                suffix: '',
                allowDecimal: false
            });
        }

        this.forcedFieldName = `${this.config.id}_forceUpdate`;
        this.group.addControl(this.forcedFieldName, this.fb.control(''));

        this.subscriptions.push(this.group.get(this.forcedFieldName).valueChanges.subscribe(
            data => {
                this.maskValueDirective.forEach(element => {
                    element.formatPrefillValue();
                });
            }
        ));
    }

    onBlur() {
        if (this.isLiveMode()) {
            const fieldControl = this.group.get(this.config.id);
            if (fieldControl && fieldControl.dirty && fieldControl.valid) {
                // todo: Do we need this stuff?  Can we integrate it into the base?
                this.config.answerValue = fieldControl.value;
                this.config.answerTimeStamp = new Date();
            }
        }

        super.onBlur();
    }

    filterNonNumbers(event) {
        let allowedValues = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'Delete', 'Tab', 'ArrowLeft', 'ArrowRight'];

        if (this.config.MaxNumberDecimalPlaces) {
            allowedValues = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'Delete', 'Tab', 'ArrowLeft', 'ArrowRight', '.'];
        }

        if (!allowedValues.includes(event.key)) {
            event.preventDefault ? event.preventDefault() : event.returnValue = false;
            return false;
        }
    }
}
