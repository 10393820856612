<div class="d-flex flex-row justify-content-between">
  <div class="d-flex flex-column align-items-left" matSnackBarLabel>

    <span *ngFor="let message of messages">{{message}}</span>

  </div>
  <span matSnackBarActions>
    <button mat-icon-button id="variablesButton" (click)="snackBarRef.dismissWithAction()">
      <mat-icon>close</mat-icon>
    </button>
  </span>
</div>
