<h1 mat-dialog-title>Messages</h1>
<div mat-dialog-content>
  <div *ngFor="let message of messages; let i = index" class="mb-2">
    <div [class.case-summary-message]="i < (messages.length - 1)">
      <div class="rendered-quill-content ql-editor" [innerHTML]="message"></div>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <div class="flex-1"></div>
  <button mat-button class="material-default text-uppercase mr-2" (click)="cancel()">CLOSE</button>
</div>
