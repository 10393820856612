import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'app/shared/shared.module';
import { SystemComponent } from 'app/system/system.component';
import { CasePresenceComponent } from 'app/system/case-presence/case-presence.component';
import { RouterModule, Routes } from '@angular/router';


export const routes: Routes = [
    { path: '', pathMatch: 'full', component: SystemComponent }
];


@NgModule({
    declarations: [
        SystemComponent,
        CasePresenceComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forChild(routes),
    ],
    providers: [
    ]
})
export class SystemModule { }
