import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ManagementQueueColumnMapkeysChange } from '@CNBW';
import { CanNavigateAwayWithConfirmGuard } from '@CNBW/guards';
import { NewBusinessWorkbenchService, NewBusinessWorkbenchStateService } from '@CNBW/services';
import { MultiSelectModel } from '@Components/multi-select-dropdown-list/models/MultiSelectModel.interface';
import { FeatureToggle } from '@Enums';
import { ManagementQueue, ManagementQueueAccount, ManagementQueueColumnMapKey, ManagementQueueRole } from '@Models';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import {
    AccountService,
    ConfirmationDialogService,
    FeatureManagerService,
    GlobalService,
    ManagementQueueService,
    NotificationService,
    NotificationSeverity,
    RoutesEnum,
    RoutingService,
} from '@Services';
import { MrsChip } from '@Shared';
import { Utils } from '@Utils';
import { AppService } from 'app';
import * as $ from 'jquery';
import moment = require('moment');
import { forkJoin } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SubSink } from 'subsink';


export type QueueData = {
    id: string;
    internalId: number;
    name: string;
    description: string;
    order: number;
    managementQueueAccounts: ManagementQueueAccount[];
    managementQueueRoles: ManagementQueueRole[];
    managementQueueColumnMapKeys: ManagementQueueColumnMapKey[];
    lastModified: string;
    lastModifiedByUser: string;
};

export type QueueForm = {
    id: FormControl<string>;
    internalId: FormControl<number>;
    name: FormControl<string>;
    description: FormControl<string>;
    order: FormControl<number>;
    managementQueueAccounts: FormControl<ManagementQueueAccount[]>;
    managementQueueRoles: FormControl<ManagementQueueRole[]>;
    managementQueueColumnMapKeys: FormControl<ManagementQueueColumnMapKey[]>;
    lastModified: FormControl<string>;
    lastModifiedByUser: FormControl<string>;
}

export type QueuesForm = {
    queues: FormArray<FormGroup<QueueForm>>;
    errorMessage: FormControl<string>;
}


@Component({
    selector: 'edit-queues',
    host: { 'class': 'overflow-content' },
    templateUrl: 'edit-queues.component.html',
    styleUrls: ['edit-queues.component.scss']
})
export class EditQueuesComponent implements OnInit, OnDestroy, CanNavigateAwayWithConfirmGuard {
    constructor(
        public appService: AppService,
        public notificationService: NotificationService,
        public managementQueueService: ManagementQueueService,
        public newBusinessWorkbenchService: NewBusinessWorkbenchService,
        public newBusinessWorkbenchStateService: NewBusinessWorkbenchStateService,
        public confirmationService: ConfirmationDialogService,
        public fb: FormBuilder,
        public changeDetector: ChangeDetectorRef,
        public activatedRoute: ActivatedRoute,
        public routingService: RoutingService,
        private accountService: AccountService,
        private globalService: GlobalService,
        private featureManagerService: FeatureManagerService,
    ) {
        this.appService.getAllConfigData();
    }

    selectedClients: MultiSelectModel[];
    queueData: ManagementQueue[] = [];
    queueDataLoaded = false;
    selectedQueueForm: FormGroup<QueueForm>;
    selectedQueue: ManagementQueue;
    selectedIndex: number;
    queuesForm: FormGroup<QueuesForm>;
    accountRules: any;
    accounts: any;
    isLoaded = false;
    subs = new SubSink();
    maxQueueNameCharacters = 20;
    dirtyQueues = new Map<number, boolean>();
    roles: MrsChip[];
    sortedRoles: any[] = [];
    currentUserRoleId: string;
    lastTabObserver: IntersectionObserver;
    firstTabObserver: IntersectionObserver;

    caseManagerViewPermission = "00000000-0000-0000-0000-000000000030";
    caseManagerViewType = "00000000-0000-0000-0000-000000000005";

    abc: string;
    leftTabIdx = 0;
    atStart = true;
    atEnd = false;

    configureColumnsEnabled = false;

    get queues() {
        return this.queuesForm?.controls.queues;
    }

    //#region Guards

    canDeactivate(component: EditQueuesComponent, routerSnapshot: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): boolean {
        if (this.dirtyQueues.size > 0) {
            this.confirmationService.open({
                message: 'You will lose your changes.',
                title: "Cancel?",
                onOk: () => {
                    this.dirtyQueues.clear();
                    this.routingService.navigateToUrl(nextState.url);
                    return true;
                },
                onCancel: () => {
                    return false;
                },
                showCancel: true,
                cancelLabel: "Continue Editing",
                okLabel: "Yes, Cancel"
            });
            return false;
        } else {
            return true;
        }
    }

    //#endregion
    //#region Scrolling

    scrollTab(x: number) {
        if (this.atStart && x < 0 || this.atEnd && x > 0) {
            return;
        }

        this.leftTabIdx = this.leftTabIdx + x;
        this.abc = `translateX(${(this.leftTabIdx) * -140}px)`;
    }

    setIsAtStart(entries: IntersectionObserverEntry[]) {
        if (entries[0].intersectionRatio >= 1) { // If in view
            this.atStart = true;
        } else {
            this.atStart = false;
        }
    }

    setIsAtEnd(entries: IntersectionObserverEntry[]) {
        if (entries[0].intersectionRatio >= 1) { // If in view
            this.atEnd = true;
        } else {
            this.atEnd = false;
        }
    }

    setupScrolling() {
        setTimeout(function () {
            if (this.firstTabObserver) {
                this.firstTabObserver.disconnect();
            }

            const firstTab = document.getElementById(`list-0`);

            this.firstTabObserver = new IntersectionObserver((entries) => {
                this.setIsAtStart(entries);
            }, { threshold: 1 });

            this.firstTabObserver.observe(firstTab);

            if (this.lastTabObserver) {
                this.lastTabObserver.disconnect();
            }

            const lastTab = document.getElementById(`list-${this.queues.length - 1}`);

            this.lastTabObserver = new IntersectionObserver((entries) => {
                this.setIsAtEnd(entries);
            }, { threshold: 1 });

            this.lastTabObserver.observe(lastTab);
        }.bind(this), 0);
    }

    //#endregion
    //#region Helpers

    setupFormDirtyCheck(queueForm: FormGroup<QueueForm>) {
        this.subs.add(queueForm.valueChanges.subscribe((data: QueueData) => this.doDirtyCheck(data)));
    }

    buildQueueForm(internalId: number, queue: ManagementQueue = null): FormGroup<QueueForm> {
        const lastModified = queue
            ? moment(queue.lastModifiedDate).utc().format('l') === '1/1/0001' ? '' : moment(queue.lastModifiedDate).utc().format('MMMM Do YYYY, h:mm a')
            : '';

        const queueForm = this.fb.group<QueueForm>({
            id: this.fb.control(queue?.id || Utils.emptyGuid),
            internalId: this.fb.control(internalId),
            name: this.fb.control(queue?.name || 'New', [Validators.required, Validators.maxLength(20), RxwebValidators.unique()]), //['', [Validators.required, Validators.maxLength(20), RxwebValidators.unique()]],
            description: this.fb.control(queue?.description || ''),
            order: this.fb.control(queue?.order || 0),
            managementQueueAccounts: this.fb.control<ManagementQueueAccount[]>(queue?.managementQueueAccounts || []),
            managementQueueRoles: this.fb.control<ManagementQueueRole[]>(queue?.managementQueueRoles || []),
            managementQueueColumnMapKeys: this.fb.control<ManagementQueueColumnMapKey[]>(queue?.managementQueueColumnMapKeys || []),
            lastModified: this.fb.control(lastModified),
            lastModifiedByUser: this.fb.control(queue?.lastModifiedByUser || ''),
        });

        return queueForm;
    }

    getFormErrorMessage() {
        this.queues.controls.forEach(queue => {
            if (queue.invalid) {
                if (queue.get('name').hasError('required')) {
                    this.queuesForm.patchValue({
                        errorMessage: 'One or more queues is missing a name'
                    });
                    return;
                }
                if (queue.get('name').hasError('unique')) {
                    this.queuesForm.patchValue({
                        errorMessage: 'Each queue name needs to be unique'
                    });
                }
            }
        });
    }

    initializeForm() {
        let idx = 0;
        this.queueData.forEach(queue => {
            const queueForm = this.buildQueueForm(idx++, queue);

            this.queues.push(queueForm);
            this.setupFormDirtyCheck(queueForm);

            // delay a split second to have the form update before retrieving message
            this.subs.add(queueForm.controls.name.statusChanges
                .pipe(
                    debounceTime(100),
                )
                .subscribe(value => {
                    if (value === 'INVALID') {
                        this.getFormErrorMessage();
                    }
                })
            );

            this.queueDataLoaded = true;
        });
    }

    setupRoles() {
        this.roles = [];
        const managementQueueRoleForm = this.selectedQueueForm.controls.managementQueueRoles.value;
        this.sortedRoles.forEach(role => {
            const isRoleSelected = managementQueueRoleForm?.findIndex(x => x.userRoleId === role.id) > -1 || role.roleName === 'Admin';
            const disabled: boolean = role.roleName === 'Admin';

            this.roles.push({
                id: role.id,
                label: role.roleName,
                selected: isRoleSelected,
                value: role.id,
                disabled: disabled,
                selectable: !disabled
            });
        });
    }

    initializeClients() {
        const routeData = this.activatedRoute.snapshot.data;
        this.accounts = routeData.accounts;
        this.selectedClients = this.newBusinessWorkbenchService.getUniqueClients(this.accounts);
    }

    initializeQueueForm() {
        this.queuesForm = this.fb.group<QueuesForm>({
            queues: new FormArray<FormGroup<QueueForm>>([]),
            errorMessage: this.fb.control(''),
        });
        this.selectedQueueForm = this.buildQueueForm(0);
    }

    setupQueues(queues: ManagementQueue[]) {
        this.queueData = queues.sort((a, b) => {
            if (a.order < b.order) return -1;
            if (a.order < b.order) return 1;
            return 0;
        });

        this.initializeForm();

        this.setSelectedQueue(0);
        this.isLoaded = true;
        window.setTimeout(() => {
            this.selectedIndex = 0;
            this.setupScrolling();
            this.changeDetector.markForCheck();
        });
    }

    doDirtyCheck(data: QueueData) {

        let isDirty = false;
        const queueId = data.id;
        const managementQueue = this.queueData.find(x => x.id === queueId);

        if (Utils.isNullOrWhitespace(queueId)) isDirty = true;

        if (managementQueue.name !== data.name) isDirty = true;

        if (managementQueue.order !== data.order) isDirty = true;

        // Compare ruleIds
        const prevAccountRuleIds = managementQueue?.managementQueueAccounts.map(a => a.ruleId).sort() ?? [];
        const currAccountRuleIds = data.managementQueueAccounts?.map(a => a.ruleId).sort() ?? [];

        if (prevAccountRuleIds.length !== currAccountRuleIds.length || !prevAccountRuleIds.every((ruleId, index) => ruleId === currAccountRuleIds[index]))
            isDirty = true;


        // Compare userRoleIds
        const prevUserRoleIds = managementQueue?.managementQueueRoles?.map(a => a.userRoleId).sort() ?? [];
        const currUserRoleIds = data.managementQueueRoles?.map(a => a.userRoleId).sort() ?? [];

        if (prevUserRoleIds.length !== currUserRoleIds.length || !prevUserRoleIds.every((userRoleId, index) => userRoleId == currUserRoleIds[index]))
            isDirty = true;


        // Compare columnMapKeys
        const prevColumnMapkeyValues = managementQueue?.managementQueueColumnMapKeys?.sort((a, b) => a.order - b.order).map(a => `${a.displayName ?? ''}:${a.columnDefinition ?? ''}:${a.mapKeyName ?? ''}`) ?? [];
        const currColumnMapkeyValues = data.managementQueueColumnMapKeys?.sort((a, b) => a.order - b.order).map(a => `${a.displayName ?? ''}:${a.columnDefinition ?? ''}:${a.mapKeyName ?? ''}`) ?? [];

        if (prevColumnMapkeyValues.length !== currColumnMapkeyValues.length || !prevColumnMapkeyValues.every((columnMapkeyValue, index) => columnMapkeyValue == currColumnMapkeyValues[index]))
            isDirty = true;


        // Update dirtyQueues
        if (isDirty && !this.dirtyQueues.has(data.internalId)) this.dirtyQueues.set(data.internalId, true);
        if (!isDirty && this.dirtyQueues.has(data.internalId)) this.dirtyQueues.delete(data.internalId);
    }

    removeQueue() {
        const formArray = this.queuesForm.controls.queues;

        const idx = formArray.value.findIndex(form => form.order === this.selectedQueueForm.controls.order.value);
        this.queueData = this.queueData.filter(x => x.id != this.selectedQueueForm.controls.id.value);
        formArray.removeAt(idx);

        this.selectedIndex = 0;
        let order = 1;

        // TECH DEBT: DRY?
        // - `drop()`
        // - `removeQueue()`
        for (let i = 0; i < this.queues.controls.length; i++) {
            const newOrder = order++;
            const queueForm = this.queues.controls[i];

            if (newOrder !== queueForm.controls.order.value) {
                this.queues.controls[i].patchValue({ order: newOrder });
            }
        }

        if (formArray.length > 0) {
            this.setSelectedQueue(0);
        }

        this.setupScrolling();
        this.changeDetector.markForCheck();
    }

    closeEditQueues() {
        this.routingService.navigateToRoute(RoutesEnum.caseMananger);
    }

    //#endregion
    //#region Subscriptions

    getData() {
        forkJoin({
            rules: this.newBusinessWorkbenchService.getRules(this.selectedClients[0].id),
            accountVersions: this.accountService.getAccountVersions(this.selectedClients[0].id),
            queues: this.newBusinessWorkbenchService.getFilteredWorkbenchQueues(this.selectedClients[0].id)
        }).subscribe(({ rules, accountVersions, queues }) => {
            this.accountRules = rules;
            this.accounts = accountVersions;
            this.setupQueues(queues);
        });
    }

    getUserRolesAndQueues() {
        this.globalService.getUserRolesWithPermission(this.caseManagerViewPermission).subscribe(result => {
            this.sortedRoles = result.sort((a, b) => {
                if (a.roleName === b.roleName) return 0;
                if (a.roleName === "Admin") return -1;
                if (b.roleName === "Admin") return 1;

                if (a.roleName < b.roleName) return -1;
                if (a.roleName < b.roleName) return 1;
                return 0;
            });

            this.initializeClients();
            this.initializeQueueForm();
            this.subscribeToFeatureToggles();
            this.getData();
        });
    }

    subscribeToFeatureToggles() {
        this.subs.add(this.featureManagerService.isEnabled(FeatureToggle.GlobalCaseManagerV2ConfigColumns, this.selectedClients[0].id).subscribe(isEnabled => {
            this.configureColumnsEnabled = isEnabled;
        }));
    }

    //#endregion
    //#region Lifecycle

    ngOnInit() {
        // TECH DEBT: We should need to use jQuery. We should work to remove this package and find an "Angular" way of doing this.
        $('#sidenav').addClass('hidenav');

        this.newBusinessWorkbenchService.stopSignalRConnection();
        this.newBusinessWorkbenchStateService.clearSettings();
        this.currentUserRoleId = this.appService.getUserRoleId();

        this.getUserRolesAndQueues();

        this.newBusinessWorkbenchStateService.queuesAccountsUpdated$?.subscribe(newQueueAccounts => {
            this.selectedQueueForm.get('managementQueueAccounts').setValue(newQueueAccounts);
        });
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    //#endregion
    //#region Handlers

    onChipSelectionChange(roles: MrsChip[]) {
        const managementQueueRoles: ManagementQueueRole[] = [];

        roles.forEach(role => {
            if (role.label != 'Admin') managementQueueRoles.push({ managementQueueId: this.selectedQueue.id, userRoleId: role.id });
        });

        this.selectedQueueForm.controls.managementQueueRoles.setValue(managementQueueRoles);
    }

    drop(event: CdkDragDrop<any[]>) {

        const previousIndex = event.previousIndex;
        const currentIndex = event.currentIndex;
        if (!(!isNaN(previousIndex) && !isNaN(currentIndex) && previousIndex !== undefined && currentIndex !== undefined && previousIndex !== currentIndex)) {
            return;
        }
        const temp = this.queues.controls;
        moveItemInArray(temp, previousIndex, currentIndex);
        this.queues.controls = null;
        this.queues.controls = temp;

        // TECH DEBT: DRY?
        // - `drop()`
        // - `removeQueue()`
        for (let i = 0; i < this.queues.controls.length; i++) {
            const newOrder = i + 1;
            const queueForm = this.queues.controls[i];

            if (newOrder !== queueForm.controls.order.value) {
                this.queues.controls[i].patchValue({ order: i + 1 });
            }
        }

        this.setupScrolling();
        this.changeDetector.detectChanges();
    }

    saveQueue() {
        this.queuesForm.markAllAsTouched();
        if (!this.queuesForm.valid) {
            this.notificationService.showNotification({ severity: NotificationSeverity.Error, message: "Please correct any errors" });
            this.queuesForm.updateValueAndValidity({ onlySelf: false });
            return;
        }

        const newQueues = this.queuesForm.controls.queues.value
            .filter(x => Utils.isNullOrWhitespace(x.id))
            .map(x => ({ ...x } as ManagementQueue));

        const updatedQueues = this.queuesForm.controls.queues.controls
            .filter(x =>
                this.dirtyQueues.has(x.controls.internalId.value)
                && x.controls.internalId.value !== null
                && x.controls.id.value !== ""
                && x.controls.id.value !== null
            )
            .map(queueForm => ({ ...queueForm.value } as ManagementQueue));

        this.newBusinessWorkbenchService.updateWorkbenchQueue(updatedQueues, newQueues, this.selectedClients[0].id).subscribe(_result => {
            this.notificationService.showNotification({ severity: NotificationSeverity.Success, message: "Your changes have been saved. They will take effect after midnight CST." });
            this.dirtyQueues.clear();
            this.queuesForm.markAsPristine();
            this.managementQueueService.clearSettings();
            this.closeEditQueues();
        });
    }

    setSelectedQueue(internalId: number) {
        this.selectedIndex = internalId;

        this.selectedQueueForm = this.queuesForm.controls.queues.controls.find(x => x.value.internalId === internalId);
        // TECH DEBT: Isn't this just the `.order` property? When we drag/drop the queue tab, the order should be updated.
        const queueIndex = this.queuesForm.controls.queues.controls.findIndex(x => x.value.internalId === internalId);

        if (this.selectedQueueForm.value.id === "") {
            this.selectedQueue = JSON.parse(JSON.stringify(this.queueData.find(x => x.order == this.selectedQueueForm.value.order)));
        } else {
            this.selectedQueue = JSON.parse(JSON.stringify(this.queueData.find(x => x.id == this.selectedQueueForm.value.id)));
        }

        this.setupRoles();
        this.scrollTab(queueIndex - this.leftTabIdx - 1);

        this.newBusinessWorkbenchStateService.setCurrentQueueAccounts(this.selectedQueueForm.get('managementQueueAccounts').value);
    }

    addQueue() {
        const newQueue = this.buildQueueForm(this.queues.controls.length);
        const currentUserRole = { managementQueueId: null, userRoleId: this.currentUserRoleId };
        const order = this.queues.controls.length + 1;

        newQueue.patchValue({
            order,
            managementQueueRoles: [currentUserRole]
        });

        this.setupFormDirtyCheck(newQueue);
        this.dirtyQueues.set(newQueue.controls.internalId.value, true);
        this.queues.push(newQueue);

        const queueModel = { ...newQueue.value } as ManagementQueue;

        this.queueData.push(queueModel);
        this.selectedQueueForm = this.queues.controls[this.queues.length - 1];
        this.selectedQueue = queueModel;
        this.setupRoles();

        this.isLoaded = true;
        this.setSelectedQueue(newQueue.controls["internalId"].value);
        this.setupScrolling();
    }

    deleteQueue() {
        this.confirmationService.open({
            message: 'You will lose your configuration for this queue. This action cannot be undone.',
            title: "Delete Queue?",
            onOk: () => {
                if (this.selectedQueue.id !== null && this.selectedQueue.id !== '') {
                    this.newBusinessWorkbenchService.deleteWorkbenchQueue(this.selectedClients[0].id, this.selectedQueue.id).subscribe(() => {
                        this.notificationService.showNotification(
                            {
                                severity: NotificationSeverity.Success,
                                message: "The queue has been deleted."
                            });

                        this.removeQueue();
                        this.managementQueueService.clearSettings();
                    });
                } else {
                    this.removeQueue();
                }
            },
            okLabel: "Delete Queue",
            showCancel: true
        });
    }

    handleColumnMapkeyChange(changes: ManagementQueueColumnMapkeysChange) {
        this.selectedQueueForm.controls.managementQueueColumnMapKeys.setValue(changes.managementQueueColumnMapKeys);
        if (changes.hasErrors) this.selectedQueueForm.controls.managementQueueColumnMapKeys.setErrors({ 'columnErrors': true });
    }

    //#endregion
}