import { Injectable } from "@angular/core";
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from "@angular/forms";

@Injectable()
export class InterviewValidationService {


    validateReflexiveForms(reflexiveForms: { [parentId: string]: UntypedFormGroup }, invalidControls: AbstractControl[]): boolean {
        let allFormsValid = true;

        for (const eachReflexiveFormIndex in reflexiveForms) {
            const eachReflexiveForm = reflexiveForms[eachReflexiveFormIndex];

            eachReflexiveForm.updateValueAndValidity();
            this.findInvalidControls(eachReflexiveForm, invalidControls);
            if (allFormsValid) {
                allFormsValid = !eachReflexiveForm.invalid;
            }
        }

        return allFormsValid;
    }

    /**
     * Appends the provided `_input` control to the list of `_invalidControls` if it is invalid.
     * If the provided `_input` is a `FormArray` or `FormGroup`, it will recursively call this method.
     * 
     * @param _input
     * @param _invalidControls 
     * @returns 
     */
    findInvalidControls(_input: AbstractControl, _invalidControls: AbstractControl[]): AbstractControl[] {
        if (!_invalidControls) {
            _invalidControls = [];
        }
        if (_input instanceof UntypedFormControl) {
            if (_input.invalid) {
                _invalidControls.push(_input);
            }
            return _invalidControls;
        }

        if (!(_input instanceof UntypedFormArray) && !(_input instanceof UntypedFormGroup)) {
            return _invalidControls;
        }

        const controls = _input.controls;
        for (const name in controls) {
            const control = controls[name];
            // In this component we sort the controls by formIndex
            // so that if they are invalid the system scrolls to the "first"
            // invalid control. Some controls don't have an index so we just remove those.
            if (control.formIndex !== undefined) {
                if (control.invalid) {
                    _invalidControls.push(control);
                }
                switch (control.constructor.name) {
                    case 'FormArray':
                        (<UntypedFormArray>control).controls.forEach(_control => _invalidControls = this.findInvalidControls(_control, _invalidControls));
                        break;

                    case 'FormGroup':
                        _invalidControls = this.findInvalidControls(control, _invalidControls);
                        break;
                }
            }
        }

        return _invalidControls;
    }
}