import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { AccountDataService } from '../';
import { Account } from '../../models';

@Injectable()
export class AccountsResolverService implements Resolve<Account[]> {
    constructor(private accountDataService: AccountDataService) {}

    resolve(): Promise<Account[]> {
        return lastValueFrom(this.accountDataService.getAccounts(true));
    }
}
