import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { PipesModule } from '@Pipes/pipes.module';
import { UnderwritingTab } from '@Underwriting';
import { ExcludeExcludedNotesFromMaster, UWNotes, UWNotesAddForm, UWNotesList } from '@Underwriting/components/notes';
import {
    UWRequirements,
    UWRequirementsActionsItemDocumentComponent,
    UWRequirementsActionsCompletedComponent,
    UWRequirementsActionsFailedComponent,
    UWRequirementsActionsNotOrderedComponent,
    UWRequirementsActionsOrderedComponent,
    UWRequirementsActionsReceivedComponent,
    UWRequirementsActionsWaivedComponent,
    UWRequirementsActionsPendingComponent,
    UWRequirementsActionsCancelledComponent,
    UWRequirementsActionsDeleteComponent,
    UWRequirementsAddForm,
} from '@Underwriting/components/requirements';
import {
    UWDecisionServices,
    UWRequirementsDataService,
    UWWorkbenchService,
} from '@Underwriting/services';
import { TableModule } from 'primeng/table';

import { SharedModule } from '../../shared/shared.module';
import { UWDecisionComponent } from './components/decision';
import { UnderwritingUpdate } from './components/decision/components/update/underwriting-decision-update.component';
import { UnderwritingView } from './components/decision/components/view/underwriting-decision-view.component';
import {
    AttachmentFileManagerDialog,
    EvidenceManagerDialog,
    FollowUpDialog,
    SubtitleDialog,
    UWRequirementsExpandTable,
} from './components/requirements';
import UWNotesFeatureToggleDataService from './services/uw-notes-feature-toggle/uw-notes-feature-toggle.data.service';

export const routes: Routes = [
    { path: '', pathMatch: 'full', component: UnderwritingTab }
];

@NgModule({
    declarations: [
        UnderwritingTab,
        // UW Decission
        UWDecisionComponent,
        UnderwritingView,
        UnderwritingUpdate,
        // UW Requirements
        UWRequirements,
        UWRequirementsAddForm,
        UWRequirementsExpandTable,
        SubtitleDialog,
        FollowUpDialog,
        AttachmentFileManagerDialog,
        EvidenceManagerDialog,
        // Menu Items
        UWRequirementsActionsItemDocumentComponent,
        // Menus
        UWRequirementsActionsCompletedComponent,
        UWRequirementsActionsFailedComponent,
        UWRequirementsActionsNotOrderedComponent,
        UWRequirementsActionsOrderedComponent,
        UWRequirementsActionsReceivedComponent,
        UWRequirementsActionsWaivedComponent,
        UWRequirementsActionsPendingComponent,
        UWRequirementsActionsCancelledComponent,
        UWRequirementsActionsDeleteComponent,
        // UW Notes
        UWNotes,
        UWNotesList,
        UWNotesAddForm,
        ExcludeExcludedNotesFromMaster,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forChild(routes),
        SharedModule,
        TableModule,
        PipesModule,
    ],
    exports: [
        UnderwritingTab
    ],
    providers: [
        UWDecisionServices,
        UWRequirementsDataService,
        UWWorkbenchService,
        UWNotesFeatureToggleDataService
    ]
})
export default class UnderwritingModule { }
