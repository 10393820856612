<div (cdkDropListDropped)="drop(section, $event)" [cdkDropListData]="section" cdkDropList
    cdkDropListAutoScrollDisabled="false" cdkDropListAutoScrollStep="5" cdkDropListGroup class="drag-drop-list"
    [cdkDropListDisabled]="disableDragAndDrop">
    <div (cdkDragEnded)="isDragging=false;" (cdkDragStarted)="isDragging=true;"
        (click)="$event.stopPropagation(); selectQuestion(section, question, isReflexiveChildren)"
        *ngFor="let question of filterReflexiveQuestionsToQuesionIds; let questionIndex = index"
        [cdkDragData]="{questionIndex, questions}" [cdkDragDisabled]="isReflexiveChildren || isRepeatBlockChildren"
        cdkDrag cdkDragBoundary=".drag-drop-list" [id]="question.id">

        <div *cdkDragPreview class="node-container" id="drag-and-drop-preview-template"
            style="width: 400px; height:54px">
            <div class="node" style="padding-left: 20px">
                <span [title]="question.mapKeyName" class="node-name">{{question.mapKeyName}}</span>
            </div>
        </div>

        <div *cdkDragPlaceholder style="padding:4px; height: 40px">
            <div class="drag-drop-custom-placeholder"></div>
        </div>

        <div #nameToolTip='matTooltip' *ngIf="!hideQuestion(question)" [matTooltipDisabled]="isDragging"
            [matTooltip]="question.mapKeyName"
            [ngClass]="{'active-selected': selectedQuestion?.id === question.id, 'parent-selected': (selectedSection?.id === question.sectionsId || selectedQuestion?.sectionsId === question.sectionsId) && selectedQuestion?.id !== question.id,'dragging': isDragging}"
            class="node-container" matTooltipPosition="right" style="height: 40px">
            <mat-icon *ngIf="checkACL('W') && !isReflexiveChildren && !isRepeatBlockChildren && !disableDragAndDrop"
                class="actions drag-handle">drag_indicator
            </mat-icon>
            <div [ngStyle]="{'margin-left':nestedLevel * 2 + 2 + 'rem'}" class="node">
                <button
                    (click)="$event.stopPropagation(); getReflexiveQuestions(question); question.expanded = !question.expanded;"
                    *ngIf="question.isReflexive" mat-icon-button style="margin-right: -32px; margin-left:-12px;">
                    <mat-icon>{{question.expanded ? 'expand_less' : 'expand_more'}}</mat-icon>
                </button>

                <button
                    (click)="$event.stopPropagation(); getRepeatBlockQuestions(question); question.expanded = !question.expanded;"
                    *ngIf="question.answerType == 'RepeatBlock'" mat-icon-button
                    style="margin-right: -32px; margin-left:-12px;">
                    <mat-icon>{{question.expanded ? 'expand_less' : 'expand_more'}}</mat-icon>
                </button>
                <div [ngStyle]="{'margin-left':question.isReflexive || question.answerType == 'RepeatBlock'?'31px':'34px'}"
                    class="justify-content-between">
                    <div [id]="questionId(question)" class="node-name">{{question.mapKeyName}}</div>
                </div>

                <mat-menu #menu="matMenu">
                    <ng-template matMenuContent>
                        <div class="d-flex flex-row align-items-center flex-fill mat-menu-header"
                            style="font-size: 20px" mat-menu-item>
                            Move {{question.mapKeyName}} to...
                        </div>
                        <div *ngFor="let section of sections;"
                            [matTooltipDisabled]="!disabledSections.includes(section.id)"
                            [matTooltip]="getToolTip(question)" cdkDrag
                            class="d-flex flex-row align-items-center flex-fill">
                            <button (click)="moveQuestionToSection(question, section)"
                                *ngIf="section.id != question.sectionsId"
                                [disabled]="disabledSections.includes(section.id)"
                                mat-menu-item>{{section.name}}</button>
                        </div>
                    </ng-template>
                </mat-menu>
                <div (mouseenter)="nameToolTip.hide()" (mouseleave)="nameToolTip.show()" class="actions pr-1">
                    <div [matTooltip]="questionIsUsedInPrefill(question)?'Remove from &quot;Pre-fill Answer&quot; field in related questions.':''">
                    <button (click)="$event.stopPropagation(); openMoveToSectionMenu(question)"
                        [disabled]="questionIsUsedInPrefill(question)"
                        *ngIf="moveToSectionEnabled && checkACL('W') && !isReflexiveChildren && !isRepeatBlockChildren"
                        [matMenuTriggerFor]="menu" [matTooltip]="'Move to Section'" mat-icon-button>
                        <mat-icon svgIcon="file-document-arrow-right-outline" />
                    </button>
                    </div>
                    <div
                        [matTooltip]="isReflexiveChildren?'Remove as reflexive question to delete':isRepeatBlockChildren?'Remove as repeat block question to delete':'Delete Question'">

                        <button (click)="$event.stopPropagation(); deleteQuestion(question.id)" *ngIf="checkACL('W')"
                            [disabled]="isReflexiveChildren || isRepeatBlockChildren" mat-icon-button>
                            <mat-icon aria-label="delete question">delete</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="question.reflexiveChildren?.length && question.expanded" [@showHide] class="r-l-container">
            <interview-navigation-reflexive-choice
                *ngFor="let reflexiveChoice of filteredReflexiveChilden(question.reflexiveChildren)"
                [nestedLevel]="nestedLevel + 1" [parentQuestions]="parentQuestions" [question]="question"
                [reflexiveChoice]="reflexiveChoice" [section]="section" [selectedQuestion]="selectedQuestion"
                [selectedReflexiveChoice]="selectedReflexiveChoice" [selectedSection]="selectedSection"
                [sections]="sections" [filterReflexiveQuestionsToQuesions]="_filterReflexiveQuestionsToQuesions">
            </interview-navigation-reflexive-choice>
        </div>
        <div *ngIf="question.repeatBlockChildren?.length && question.expanded" [@showHide] class="r-l-container">
            <interview-navigation-question [currentQuestion]="question" [isReflexiveChildren]="false"
                [isRepeatBlockChildren]="true" [nestedLevel]="nestedLevel + 1" [parentQuestions]="parentQuestions"
                [questions]="question.repeatBlockChildren" [repeatBlockParent]="question" [section]="section"
                [selectedQuestion]="selectedQuestion" [selectedReflexiveChoice]="selectedReflexiveChoice"
                [selectedSection]="selectedSection" [sections]="sections"
                [filterReflexiveQuestionsToQuesionIds]="_filterReflexiveQuestionsToQuesions">
            </interview-navigation-question>
        </div>
    </div>
</div>
