import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { KeyVaultDTO, MapKeysDTO, RulesDTO } from '@DTOs';
import { FeatureToggle } from '@Enums';
import { Account, Client } from '@Models';
import { FeatureManagerService } from '@Services';
import { forkJoin } from 'rxjs';

export enum SettingsMenuItemCode {
    GeneralSettings = 'GeneralSettings',
    CaseTransferSettings = 'CaseTransferSettings',
    DocumentSigning = 'DocumentSigning',
    MyCases = 'MyCases',
    ApiMappings = 'ApiMappings',
    InterviewSettings = 'InterviewSettings',
    PurlSettings = 'PurlSettings',
    CaseSummarySidebar = 'CaseSummarySidebar',
    WorkbenchSettings = 'WorkbenchSettings'
}

export type SettingsMenuItem = {
    code: SettingsMenuItemCode;
    name: string;
    hide: boolean;
}


@Component({
    selector: 'account-settings-v2',
    host: { 'class': 'd-flex flex-row flex-fill' },
    templateUrl: './account-settings-v2.component.html',
    styleUrls: ['./account-settings-v2.component.scss']
})
export class AccountSettingsV2Component implements OnInit {
    @Input() client: Client;
    @Input() account: Account;
    @Input() rules: RulesDTO[];
    @Input() mapKeys: MapKeysDTO[];
    @Input() secretKeys: KeyVaultDTO[];
    @Output() getSecretKeys: EventEmitter<void>;

    // labels: any[] = []; // TODO: is this being used?
    ftpSettingsForm: UntypedFormGroup;
    generalSettingsForm: UntypedFormGroup;
    showForm = false;
    showContent = false;

    readonly SettingsMenuItemCode = SettingsMenuItemCode;
    protected settings: SettingsMenuItem[] = [
        { code: SettingsMenuItemCode.GeneralSettings, name: 'General', hide: false },
        { code: SettingsMenuItemCode.CaseTransferSettings, name: 'Case Transfer', hide: false },
        { code: SettingsMenuItemCode.DocumentSigning, name: 'Document Signing', hide: false },
        { code: SettingsMenuItemCode.MyCases, name: 'My Cases', hide: false },
        { code: SettingsMenuItemCode.ApiMappings, name: 'Data Import/Export', hide: false },
        { code: SettingsMenuItemCode.InterviewSettings, name: 'Application', hide: false },
        { code: SettingsMenuItemCode.PurlSettings, name: 'Personal URL', hide: false },
        { code: SettingsMenuItemCode.CaseSummarySidebar, name: 'Case Summary Sidebar', hide: true },
        { code: SettingsMenuItemCode.WorkbenchSettings, name: 'Workbench', hide: true },
    ];

    protected hasSettings = true;
    protected selectedSetting = this.settings.find(x => x.code === SettingsMenuItemCode.GeneralSettings);

    constructor(
        public featureManagerService: FeatureManagerService
    ) {
        this.getSecretKeys = new EventEmitter<void>();
    }

    //#region Helpers

    private UpdateHasSettings() {
        this.hasSettings = Object.values(this.settings).filter(x => !x.hide).length > 0;
    }

    //#endregion
    //#region Subscriptions


    subscribeFeatureFlags() {
        forkJoin([
            this.featureManagerService.isEnabled(FeatureToggle.ConfigAccountSettingsWorkbenchConfiguration),
            this.featureManagerService.isEnabled(FeatureToggle.UWWorkbenchHiddenNotesAccountSetting, this.account.clientId),
        ]).subscribe(([workbenchEnabled, hiddenNotesEnabled]) => {
            if (workbenchEnabled) this.settings.find(x => x.code === SettingsMenuItemCode.CaseSummarySidebar).hide = false;
            if (hiddenNotesEnabled) this.settings.find(x => x.code === SettingsMenuItemCode.WorkbenchSettings).hide = false;

            this.UpdateHasSettings();
        });
    }

    //#endregion
    //#region Lifecycle

    async ngOnInit() {
        this.getSecretKeys.emit();

        this.subscribeFeatureFlags();
    }

    //#endregion
    //#region Handlers

    setActiveSetting(setting: SettingsMenuItem) {
        this.selectedSetting = setting;
    }

    //#endregion
}
