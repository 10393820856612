import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import * as $ from 'jquery';
import { FeatureManagerService } from "@Services";
import { FeatureToggle } from "@Enums";

@Component({
    selector: 'app-reports',
    host: { 'class': 'content' },
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit, AfterViewInit, OnDestroy {

    menuObservable: any;
    scrollHeight: any;
    scrollWidth: any;
    updateReportToggle = false;

    constructor(private appService: AppService, private featureManagerService: FeatureManagerService) {
    }

    ngOnInit() {
        this.updateReportToggle = this.featureManagerService.getByName(FeatureToggle.GlobalOrderDetailReportUpdates).enabled;
        this.menuObservable = this.appService.sideMenuObservable.subscribe(
            data => {
                this.scrollWidth = ($(window).width() - (data ? 30 : 270));
            }
        );
    }

    ngAfterViewInit() {
        $("#sidenav").toggleClass("hidenav");
        setTimeout(() => {
            this.scrollWidth = ($(window).width() - 270);
            this.scrollHeight = ($(window).height() - 340);
        }, 10);
    }

    ngOnDestroy() {
        this.menuObservable.unsubscribe();
    }

    hasViewPermission(feature) {
        return this.appService.hasViewPermission(feature);
    }

}
