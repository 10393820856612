import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AccountConfigComponent } from './account/account-config/account-config.component';
import { ActiveAccountsComponent } from './active-accounts/active-accounts.component';
import { Auth0LoginComponent } from './auth/auth0-login/auth0-login.component';
import { CallManagerComponent } from './call-manager/call-manager.component';
import { CaseSummaryComponent } from './case-summary/case-summary.component';
import { ClientDetailsComponent } from './clients/client-details/client-details.component';
import { SignatureCallbackComponent } from './components/signature-envelope-dialog/signature-callback.component';
import { CepQuestionnaireComponent } from './components/third-party-section/cep-questionnaire/cep-questionnaire.component';
import { TrustCommerceCallbackComponent } from './components/trustcommerce/trustcommerce-callback.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { EndOfCallComponent } from './endofcall/endofcall.component';
import { ApplicantEndOfInterviewComponent } from './interview/end-of-interview/applicant-end-of-interview.component';
import { InterviewComponent } from './interview/interview.component';
import { PurlVerificationComponent } from './interview/purl/purl-verification/purl-verification.component';
import { PurlComponent } from './interview/purl/purl.component';
import { ManageUsersPageComponent } from './manage-users/manage-users-page.component';
import { MyCasesComponent } from './my-cases/my-cases.component';
import { NavigationComponent } from './navigation/navigation.component';
import CaseManagerRoute from './new-business-workbench/routing/casemanager.route';
import { NotFoundComponent } from './not-found.component';
import { ViewDocumentPageRoute } from './pages/view-document/view-document-page.component';
import { ReportsComponent } from './reports/reports.component';
import * as Guards from './services/guards';
import * as Resolvers from './services/resolvers';
import { CanNavigateAwayWithConfirmGuardAccountConfig } from "app/new-business-workbench/guards/canNavigateAway.guard";
import { CaseAssignmentAndRestartComponent } from './components/case-assignment/case-assignment-and-restart.component';
import { AuditRoutes } from '@Pages';

const appRoutes: Routes = [

    {
        path: '',
        resolve: {
            theme: Resolvers.WhiteLabelThemeResolver
        },
        canActivate: [Guards.FeatureToggleGuard],
        children: [
            {
                path: 'auth0login', // Just in case bookmarks.
                component: Auth0LoginComponent,
                canActivate: [Guards.AuthV2GuardService]
            },
            { path: 'signature-complete', component: SignatureCallbackComponent },
            { path: 'trust-commerce-complete', component: TrustCommerceCallbackComponent },
            { path: 'end-of-application', component: ApplicantEndOfInterviewComponent },
            {
                path: 'purl',
                component: PurlComponent,
                children: [
                    {
                        path: 'client/:clientCode/account/:accountCode/:versionNumber/interview/:caseId',
                        component: InterviewComponent,
                        canActivate: [Guards.AuthGuardService],
                        canDeactivate: [Guards.CanDeactivateGuardService],
                        resolve: {
                            client: Resolvers.ClientResolverService,
                            account: Resolvers.AccountResolverService
                        },
                        data: {
                            restart: true
                        }
                    }
                ]
            },
            {
                path: 'purl/:id',
                component: PurlVerificationComponent,
                children: [
                    {
                        path: 'client/:clientCode/account/:accountCode/:versionNumber/interview/:caseId',
                        component: InterviewComponent,
                        canActivate: [Guards.AuthGuardService],
                        canDeactivate: [Guards.CanDeactivateGuardService],
                        resolve: {
                            client: Resolvers.ClientResolverService,
                            account: Resolvers.AccountResolverService
                        },
                        data: {
                            restart: true
                        }
                    }
                ]
            },
            {
                path: 'login',
                component: Auth0LoginComponent,
                canActivate: [Guards.AuthV2GuardService],
            },
            {
                path: '',
                canActivate: [Guards.AuthGuardService],
                canActivateChild: [Guards.ValidThemeGuard, Guards.RoleGuard, Guards.RedirectGuard],
                component: NavigationComponent,
                children: [

                    { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
                    AuditRoutes,
                    { path: 'assign-and-restart', component: CaseAssignmentAndRestartComponent },
                    { path: 'cep', component: CepQuestionnaireComponent },
                    {
                        path: 'dashboard',
                        component: DashboardComponent,
                        canActivate: [Guards.AclGuardService],
                        data: {
                            permission: 'Dashboard',
                            permissionType: 'V',
                            rolesNotAllowed: ['Applicant']
                        },
                        resolve: {
                            accounts: Resolvers.AccountsResolverService
                        }
                    },
                    {
                        path: 'mycases',
                        component: MyCasesComponent,
                        canActivate: [Guards.AclGuardService],
                        data: {
                            permission: 'Case',
                            permissionType: 'R',
                            rolesNotAllowed: ['Applicant']
                        },
                        resolve: {
                            accounts: Resolvers.AccountsResolverService
                        }
                    },
                    CaseManagerRoute,
                    {
                        path: 'clients', component: ActiveAccountsComponent,
                        data: {
                            isConfigView: true,
                        }
                    },
                    { path: 'roles', loadChildren: () => import('./roles/roles.module').then(m => m.RolesModule) },
                    {
                        path: 'accounts', component: ActiveAccountsComponent,
                        data: {
                            isConfigView: false,
                        }
                    },
                    {
                        path: 'reports',
                        component: ReportsComponent
                    },
                    { path: 'callmanager', component: CallManagerComponent },
                    { path: 'navigation/endofcall/:id/:tenant', component: EndOfCallComponent },
                    { path: 'navigation/dashboard', redirectTo: 'dashboard' },
                    {
                        path: 'interview', // resolve client and account from query string
                        component: InterviewComponent,
                        resolve: {
                            account: Resolvers.ActiveAccountResolverService
                        }
                    },
                    {
                        path: 'manage-users', component: ManageUsersPageComponent,
                        canActivate: [Guards.AclGuardService],
                        data: {
                            permission: 'Users',
                            permissionType: 'V'
                        },
                        resolve: {
                            accounts: Resolvers.AccountsResolverService
                        }
                    },
                    { path: 'system', loadChildren: () => import('./system/system.module').then(m => m.SystemModule) },
                    {
                        path: 'client/:clientCode',
                        canActivate: [Guards.ClientGuardService],
                        resolve: {
                            client: Resolvers.ClientResolverService
                        },
                        children: [
                            { path: '', redirectTo: 'details', pathMatch: 'full' },
                            { path: 'details', component: ClientDetailsComponent },
                            {
                                path: 'account/new',
                                component: AccountConfigComponent,
                                data: {
                                    new: true
                                }
                            },
                            {
                                path: 'account',
                                children: [
                                    { path: 'new', component: AccountConfigComponent, data: { new: true } },
                                    {
                                        path: ':accountCode',
                                        children: [
                                            {
                                                path: ':versionNumber',
                                                resolve: {
                                                    account: Resolvers.AccountResolverService
                                                },
                                                children: [
                                                    {
                                                        path: '',
                                                        component: AccountConfigComponent,
                                                        pathMatch: 'full',
                                                        data: {
                                                            new: false
                                                        },
                                                        canDeactivate: [CanNavigateAwayWithConfirmGuardAccountConfig]
                                                    },
                                                    {
                                                        path: 'interview',
                                                        children: [
                                                            {
                                                                path: '',
                                                                component: InterviewComponent,
                                                                canDeactivate: [Guards.CanDeactivateGuardService],
                                                                resolve: {
                                                                    interview: Resolvers.InterviewResolverService
                                                                },
                                                                pathMatch: 'full',
                                                                data: {
                                                                    restart: false
                                                                }
                                                            },
                                                            {
                                                                path: ':caseId',
                                                                component: InterviewComponent,
                                                                canActivate: [Guards.ValidCaseGuard],
                                                                canDeactivate: [Guards.CanDeactivateGuardService],
                                                                data: {
                                                                    restart: true
                                                                }
                                                            }
                                                        ]
                                                    },
                                                    {
                                                        path: ':caseId',
                                                        canActivate: [Guards.ValidCaseGuard],
                                                        children: [
                                                            {
                                                                path: 'summary',
                                                                component: CaseSummaryComponent,
                                                            },
                                                            ViewDocumentPageRoute,
                                                        ]
                                                    }
                                                ]
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    },
                    { path: '404', component: NotFoundComponent },
                    { path: '**', component: NotFoundComponent }
                ]
            }
        ]
    }
];

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        RouterModule.forRoot(appRoutes, { initialNavigation: 'disabled' }),
    ]
})
export class AppRoutingModule { }
