import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { BaseInterviewFormFieldComponent, BaseFormFieldServices } from '../base-interview-form-field/base-interview-form-field.component';

@Component({
    selector: 'multi-choice-multi-answer',
    templateUrl: './multi-choice-multi-answer.component.html',
    styleUrls: ['./multi-choice-multi-answer.component.scss']
})
export class MultiChoiceMultiAnswerComponent extends BaseInterviewFormFieldComponent implements OnInit {
    acResult: any = [];
    indentReflexiveQuestions: boolean;
    reflexiveQuestions: any = [];
    checkedList: any = [];
    inputTypeCheckbox: string = 'checkbox';
    constantList: any = [];

    constructor(public services: BaseFormFieldServices) {
        super(services);
    }

    ngOnInit() {
        if (this.group.get(this.config.id).value) {
            this.group.get(this.config.id).setValue(this.group.get(this.config.id).value.split(','));
        } else {
            this.group.get(this.config.id).setValue([]);
        }

        if (this.interviewMode && this.config['multiChoiceAnswers'][0]) {
            // get option data from 'multichoiceanswers' property and assign it to list values
            this.getListValuesToQuestion(this.config['multiChoiceAnswers'][0]['constantKey'], this.config);
        }

        super.initialize();
    }

    // this method get options for the question and if the field is already answered, selects all the answered values.
    getListValuesToQuestion(mapKeyId, question) {
        if (this.config['multiChoiceAnswers'][0] &&
      this.config['multiChoiceAnswers'][0].constantKeyNavigation) {

            this.constantList = this.constantLists[this.config['multiChoiceAnswers'][0].constantKeyNavigation.id];

            if (this.config.answerValue && this.config.DisplayType === 'checkbox') {
                const checkedValues = this.config.answerValue.split(',');

                if (this.constantList && this.constantList.length) {
                    for (const eachListValue of this.constantList) {
                        if (checkedValues.includes(eachListValue.id)) {
                            eachListValue.checked = true;
                            this.checkedList.push(eachListValue.id);
                        } else {
                            eachListValue.checked = false;
                        }
                    }
                }
            }

            if (this.config['multiChoiceAnswers'][0].constantKeyNavigation.constantMapKeyValues){
                this.config['listValues'] = this.config['multiChoiceAnswers'][0].constantKeyNavigation.constantMapKeyValues;
            }
        }
    }

    // if display type is checkbox, then on change of checkbox for each option, it is either removed or added to answer value based on if it's checked
    onCheckboxChange(option, event) {
        if (event.checked) {
            this.checkedList.push(option.id);
        } else {
            for (let i = 0; i < this.constantList.length; i++) {
                if (this.checkedList[i] === option.id) {
                    this.checkedList.splice(i, 1);
                }
            }
        }
        this.group.get(this.config.id).setValue(this.checkedList);
        this.onBlur();
    }

    acSearch(event) {
        const query = event.query;
        this.acResult = this.config.listValues.filter(item => {
            return (item.displayName.toLowerCase().includes(query.toLowerCase()));
        });
    }
}
