import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from '@App';
import { Account, CaseTransferSettings, ClientAccountSetting } from '@Models';
import { BaseService } from '@Services';

import { IConfigService } from '../../config/iconfigservice';

@Injectable()
export class AccountSettingsService extends BaseService {

    publicApiBaseUrl: string;

    constructor(public appService: AppService, public configService: IConfigService, public httpClient: HttpClient) {
        super(appService, configService, httpClient);
        this.publicApiBaseUrl = this.configService.getConfiguration().publicApiUrl;
    }

    editAccountFtpSettings(account: Account, id: string, caseTransferSettings: CaseTransferSettings) {
        const clientId = this.appService.getClientId('');
        const url = `${this.publicApiBaseUrl}client/${clientId}/account/${account.id}/${account.versionNumber}/settings/casetransfer/${id}`;
        return super.putData<CaseTransferSettings>(url, caseTransferSettings);
    }

    addAccountFtpSettings(account: Account, caseTransferSettings: CaseTransferSettings) {
        const clientId = this.appService.getClientId('');
        const url = `${this.publicApiBaseUrl}client/${clientId}/account/${account.id}/${account.versionNumber}/settings/casetransfer`;
        return super.postData<CaseTransferSettings>(url, caseTransferSettings);
    }

    saveAccountSetting(account: Account, accountSetting: ClientAccountSetting) {
        if (accountSetting.id) {
            return this.updateAccountSetting(account, accountSetting);
        } else {
            return this.addAccountSetting(account, accountSetting);
        }
    }

    addAccountSetting(account: Account, accountSetting: ClientAccountSetting) {
        const clientId = this.appService.getClientId('');
        const url = `${this.publicApiBaseUrl}client/${clientId}/account/${account.id}/${account.versionNumber}/setting`;
        return super.postData<ClientAccountSetting>(url, accountSetting);
    }

    updateAccountSetting(account: Account, accountSetting: ClientAccountSetting) {
        const clientId = this.appService.getClientId('');
        const url = `${this.publicApiBaseUrl}client/${clientId}/account/${account.id}/${account.versionNumber}/setting`;
        return super.putData<ClientAccountSetting>(url, accountSetting);
    }

    getAccountSetting(account: Account, accountSettingName: string) {
        const clientId = this.appService.getClientId('');
        const url = `${this.publicApiBaseUrl}client/${clientId}/account/${account.id}/${account.versionNumber}/setting/${accountSettingName}`;
        return super.getData<ClientAccountSetting>(url);
    }
}
