export enum ActionEnum {
    Show = '00000000-0000-0000-0000-000000000001',
    Hide = '00000000-0000-0000-0000-000000000002',
    TriggerUWProcess = '00000000-0000-0000-0000-000000000003',
    ChangeCaseStatus = '00000000-0000-0000-0000-000000000004',
    CheckForDuplicateApp = '00000000-0000-0000-0000-000000000005',
    UpdateCasePolicyNumber = '00000000-0000-0000-0000-000000000006',
    GiactVerification = '00000000-0000-0000-0000-000000000007',
    MIBIntegration = '00000000-0000-0000-0000-000000000009',
    RxIntegration = '00000000-0000-0000-0000-000000000010',
    RnaQuote = '00000000-0000-0000-0000-000000000011',
    RnaGetAgentInfo = '00000000-0000-0000-0000-000000000012',
    ValidateRepeatBlockSum = '00000000-0000-0000-0000-000000000013',
    DocuSignESig = '00000000-0000-0000-0000-000000000036',
    Disabled = '00000000-0000-0000-0000-000000000039',
    Braintree = '00000000-0000-0000-0000-000000000055',
    Magnum = '00000000-0000-0000-0000-000000000049',
    TriggerPrefill = "00000000-0000-0000-0000-000000000102",
    RNASSNDupCheck = "00000000-0000-0000-0000-000000000045"
}
