<h1 mat-dialog-title>Test Expression</h1>
<div mat-dialog-content>
  <div *ngFor="let mapkey of testMapkeys; let i = index;">    
    <div class="flex flex-row">
    <mat-form-field class="w-100">
        <mat-label>{{mapkey.mapKeyName}}</mat-label>
        <input matInput [(ngModel)]="mapkey.value" autocomplete="off"
          [readonly]="mapkey.isNull">
        <mat-checkbox [(ngModel)]="mapkey.isNull" matSuffix>Is Null</mat-checkbox>
    </mat-form-field>
  </div>
  </div>
  <div class="flex" *ngIf="showResult">
    <strong style="color: rgb(0, 138, 0);">Test results: {{testResult}}</strong>
  </div>
</div>

<mat-dialog-actions align="end">
  <button type="button" mat-stroked-button color="basic" (click)="closeModal()">
    {{closeText}}
  </button>
  <button type="button" mat-stroked-button color="primary" (click)="runTest()" class="mr-1">
    RUN TEST
  </button>
</mat-dialog-actions>
