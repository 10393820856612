import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';


@Component({
    selector: 'plain-text-viewer',
    templateUrl: './plain-text-viewer.component.html',
    styleUrls: ['./plain-text-viewer.component.scss']
})
export default class PlainTextViewer implements OnChanges {
    @Input() src: string;

    contents = '';

    loadContents() {
        fetch(this.src).then(value => value.text()).then(txt => this.contents = txt);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.src.currentValue)
            this.loadContents();
    }
}
