<div>
    <button mat-menu-item
        *ngIf="caseRequirement.hasSection"
        (click)="handleMenuAction(UWRequirementTableActions.OpenSection, caseRequirement)">
        Order
    </button>
    <uw-requirements-actions-item-documents
        [caseRequirement]="caseRequirement"
        (onMenuAction)="handleMenuActionEvent($event)">
    </uw-requirements-actions-item-documents>
    <button mat-menu-item (click)="handleMenuAction(UWRequirementTableActions.FollowUp, caseRequirement)">
        <span *ngIf="!caseRequirement.dateFollowUp; else updateFollowUpTemplate">Add Follow Up</span>
        <ng-template #updateFollowUpTemplate>Edit Follow Up</ng-template>
    </button>
    <button mat-menu-item
        (click)="handleMenuAction(UWRequirementTableActions.EditSubtitle, caseRequirement)">
        <span *ngIf="caseRequirement.subtitle && caseRequirement.subtitle != ''; else addSubtitleTemplate">Edit Subtitle</span>
        <ng-template #addSubtitleTemplate>Add Subtitle</ng-template>
    </button>
    <mat-divider></mat-divider>
    <button mat-menu-item
        *ngIf="!caseRequirement.hasIntegration"
        (click)="handleMenuAction(UWRequirementTableActions.Completed, caseRequirement)">
        Complete
    </button>
    <button mat-menu-item (click)="handleMenuAction(UWRequirementTableActions.Waive, caseRequirement)">
        Waive
    </button>
    <button mat-menu-item
        *ngIf="!props.hideSetCancelledMenuOption"
        (click)="handleMenuAction(UWRequirementTableActions.Cancelled, caseRequirement)">
        Cancel
    </button>
</div>