<div class="page-heading" id="subheading" *ngIf="interviewMode!='readonly'">
    <i aria-hidden="true" class="page-heading-icon fa fa-file-alt"></i>
    <span class="page-heading-title">NEW APPLICATION - {{accountInfo.name}}</span>
    <span class="page-heading-title" *ngIf="accountInfo.statusId == accountDraftStatus"
        style="color: gray; font-size: 18px;">DRAFT</span>
    <button class="float-right" mat-button color="basic" *ngIf="!caseStatus && !isApplicant" (click)="back()">
        <i aria-hidden="true" class="fa fa-chevron-left"></i> Back
    </button>
</div>
<div class="d-flex flex-fill" (click)="idleSecondsCounter=0" (mousemove)="idleSecondsCounter=0" (keypress)="idleSecondsCounter=0">
    <mat-drawer-container hasBackdrop="false" style="flex: 1 1 auto;">
        <!-- Left Side Panel (list of sections) -->
        <mat-drawer mode="side" opened position="start" class="left-panel">
            <div class="full-height d-flex flex-column" style="height: 98% !important">
                <div class="left-panel-header">
                    <span class="left-panel-title">Sections</span>
                </div>
                <div class="left-panel-body flex-fill overflow-y-auto flex-column">
                    <div class="list-container" *ngIf="sectionSummaries?.length; else noSectionsPlaceholder">
                        <interview-section-list-item *ngFor="let section of sectionSummaries" [section]="section" (onSelectSection)="selectSectionIfEnabled($event)"></interview-section-list-item>
                    </div>
                 
                    <ng-template #noSectionsPlaceholder>
                        <div class="p-4 text-center text-muted">
                            <img src="./assets/images/no-data-placeholder.png" alt="" class="pb-3" />
                            <h6>No Sections Found</h6>
                        </div>
                    </ng-template>
                </div>
                <div class="exit-application" *ngIf="!interviewService.isReadOnly">
                    <mrs-btn-outline [buttonLabel]="exitText" buttonId="cancel" (onClick)="cancelInterview()"></mrs-btn-outline>
                </div>   
            </div>
        </mat-drawer>

        <!-- Right Side Panel (Case Details, Integration Running, etc)-->
        <mat-drawer *ngIf="!interviewService.isReadOnly && canViewCaseDetails" [mode]="mobileQuery.matches ? 'side' : 'over'" position="end" #sidenav [opened]="mobileQuery.matches" class="right-panel">
            <div class="overflow-y-auto bg-light full-height">
                <mat-tab-group [selectedIndex]="currentRightTabIndex" class="flex-fill overflow-y-auto full-height" disablePagination="true">
                    <mat-tab>
                        <ng-template mat-tab-label>Case Details</ng-template>
                        <div class="right-panel-body d-flex flex-column flex-fill overflow-y-auto">
                            <div *ngIf="caseStatus">
                                <div class="p-3">
                                    <span class="float-right">
                                        <button *ngIf="hasViewPermission('DownloadCaseXML')"
                                            class="btn btn-sm btn-link custom" (click)="toggleShowInterviewJson(true)">
                                            <i aria-hidden="true" class="fa fa-code"></i> Interview JSON</button>
                                    </span>
                                    <div class="label-title"> Case Status </div>
                                    <div class="label-content"> {{caseStatus}} </div>
                                    <div class="label-title"> Submitted by </div>
                                    <div class="label-content"> {{accountInfo.name}} </div>
                                    <div class="label-title"> {{accountInfo.preferredName}} </div>
                                    <div class="label-content"> {{getInterviewData()?.caseDetails.policyNumber}} </div>
                                    <div class="label-title"> Case Number </div>
                                    <div class="label-content"
                                        [attr.data-test-casenumber]="getInterviewData()?.caseDetails.caseNumber">
                                        {{getInterviewData()?.caseDetails.caseNumber}} </div>
                                    <div class="label-title"> Agent Info </div>
                                    <div class="label-content"> {{getMapKeysValue('agent_name')}}<br />
                                        {{getMapKeysValue('agent_number')}}
                                        <br />{{getMapKeysValue('agent_phone')}}
                                    </div>
                                    <div class="label-title"> Applicant Name </div>
                                    <div class="label-content"> {{getMapKeysValue('applicant_name')}} </div>
                                    <div class="label-title"> Applicant Age </div>
                                    <div class="label-content"> {{getApplicantAge('applicant_dob')}} </div>
                                    <div class="label-title"> Applicant DOB </div>
                                    <div class="label-content"> {{getMapKeysValue('applicant_dob')}} </div>
                                    <div class="label-title"> Writing State</div>
                                    <div class="label-content"> {{getMapKeysValue('Case_WritingState')}} </div>
                                </div>
                            </div>
                            <div class="p-4 text-center text-muted" *ngIf="!caseStatus">
                                <img src="./assets/images/no-data-placeholder.png" alt="" class="pb-3" />
                                <h6>Case Not Created Yet</h6>
                            </div>
                        </div>
                    </mat-tab>

                    <mat-tab *ngIf="interviewService.hasIntegrations() || rxHx">
                        <ng-template mat-tab-label>Integrations </ng-template>
                        <div class="d-flex flex-column overflow-y-auto flex-fill ">

                            <div class="integration-container" *ngIf="interviewService.getRxData().status"
                                class="d-flex flex-column">
                                <div class="question-header">
                                    <span class="d-inline-block pl-2 pt-2"> Rx </span>
                                    <button type="button" class="btn btn-link float-right"
                                        (click)="rxExpanded = !rxExpanded">
                                        <i aria-hidden="true" class="fa"
                                            [ngClass]="{'fa-angle-right': rxExpanded, 'fa-angle-down': !rxExpanded}"></i>
                                    </button>
                                </div>
                                <div class="p-2 rx-container" *ngIf="!rxExpanded">
                                    <div class="label-title"> Rx Status </div>
                                    <div class="label-content mb-0">
                                        {{ interviewService.getIntegrationStatus(interviewService.getRxData().status )}}
                                        <button type="button" class="btn btn-link btn-sm custom float-right"
                                            (click)="refreshIntegration('00000000-0000-0000-0000-000000000003', interviewService.getRxData(), 'RX')"
                                            *ngIf="interviewService.getIntegrationStatus(interviewService.getRxData().status) == 'Running' ">
                                            <i aria-hidden="true" class="fas fa-sync-alt"></i> Refresh
                                        </button>
                                    </div>
                                    <div class="label-title"
                                        *ngIf="interviewService.getIntegrationStatus(interviewService.getRxData().status) == 'Failed' ">
                                        Error Message </div>
                                    <div class="label-content mb-0"
                                        *ngIf="interviewService.getIntegrationStatus(interviewService.getRxData().status) == 'Failed' ">
                                        {{interviewService.getRxData().errorMessage}}</div>

                                    <div class="mb-3"
                                        *ngIf="interviewService.getIntegrationStatus(interviewService.getRxData().status) === 'Completed' && rxResultsVisibility">
                                        <p-table *ngIf="interviewService.getRxData().details?.severities"
                                            [value]="interviewService.getRxData().details?.severities">
                                            <ng-template pTemplate="header">
                                                <tr>
                                                    <th>Severity</th>
                                                    <th style="width: 75px">Status</th>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="body" let-severity>
                                                <tr>
                                                    <td>{{severity.mapKeyName}}</td>
                                                    <td *ngIf="severity.value">Yes</td>
                                                    <td *ngIf="!severity.value">No</td>
                                                </tr>
                                            </ng-template>
                                        </p-table>
                                        <div *ngIf="interviewService.getRxData().details?.drugs">
                                            <div
                                                *ngFor="let risk of (interviewService.getRxData().details?.drugs | filterNull: ['riskType'])">
                                                <div class="question-container">
                                                    <div class="p-2">
                                                        <div class="rx-list">
                                                            <span class="rx-list-label-title">Drug : </span>
                                                            <span class="rx-list-label-content">{{risk.label}}</span>
                                                        </div>
                                                        <div class="rx-list">
                                                            <span class="rx-list-label-title">Risk : </span>
                                                            <span class="rx-list-label-content">{{risk.riskType}}</span>
                                                        </div>
                                                        <div class="rx-list">
                                                            <span class="rx-list-label-title">Code Description : </span>
                                                            <span
                                                                class="rx-list-label-content">{{risk.interviewerDirective}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="integration-container" *ngIf="rxHx" class="d-flex flex-column">
                                <div class="question-header">
                                    <span class="d-inline-block pl-2 pt-2"> RxHx </span>
                                    <button type="button" class="btn btn-link float-right"
                                        (click)="rxHxExpanded = !rxHxExpanded">
                                        <i aria-hidden="true" class="fa"
                                            [ngClass]="{'fa-angle-right': rxHxExpanded, 'fa-angle-down': !rxHxExpanded}"></i>
                                    </button>
                                </div>
                                <div class="p-2 rx-container" *ngIf="!rxHxExpanded">
                                    <div class="label-title"> Rx Status </div>
                                    <div class="label-content mb-0">
                                        <div class="mb-3">
                                            <p-table *ngIf="rxHx?.severities" [value]="rxHx?.severities">
                                                <ng-template pTemplate="header">
                                                    <tr>
                                                        <th>Severity</th>
                                                        <th style="width: 75px">Status</th>
                                                    </tr>
                                                </ng-template>
                                                <ng-template pTemplate="body" let-severity>
                                                    <tr>
                                                        <td>{{severity.mapKeyName}}</td>
                                                        <td *ngIf="severity.value">Yes</td>
                                                        <td *ngIf="!severity.value">No</td>
                                                    </tr>
                                                </ng-template>
                                            </p-table>
                                        </div>
                                        <div *ngIf="rxHx?.drugs">
                                            <div *ngFor="let risk of (rxHx?.drugs | filterNull: ['riskType'])">
                                                <div class="question-container">
                                                    <div class="p-2">
                                                        <div class="rx-list">
                                                            <span class="rx-list-label-title">Drug : </span>
                                                            <span class="rx-list-label-content">{{risk.label}}</span>
                                                        </div>
                                                        <div class="rx-list">
                                                            <span class="rx-list-label-title">Risk : </span>
                                                            <span class="rx-list-label-content">{{risk.riskType}}</span>
                                                        </div>
                                                        <div class="rx-list">
                                                            <span class="rx-list-label-title">Code Description : </span>
                                                            <span
                                                                class="rx-list-label-content">{{risk.interviewerDirective}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="integration-container" *ngIf="interviewService.getGiactData().status">
                                <div class="question-header">
                                    <span class="d-inline-block pt-2 pl-2"> GIACT </span>
                                    <button type="button" class="btn btn-link float-right"
                                        (click)="giactExpanded = !giactExpanded">
                                        <i aria-hidden="true" class="fa"
                                            [ngClass]="{'fa-angle-right': giactExpanded, 'fa-angle-down': !giactExpanded}"></i>
                                    </button>
                                </div>
                                <div class="p-2" *ngIf="!giactExpanded">
                                    <div class="label-title"> Status </div>
                                    <div class="label-content mb-0">
                                        {{ interviewService.getIntegrationStatus(interviewService.getGiactData().status)
                                        }}
                                        <button data-test-refresh-integration-name="GIACT"
                                            data-test-refresh-integration-id="00000000-0000-0000-0000-000000000001"
                                            type="button" class="btn btn-link btn-sm custom float-right"
                                            (click)="refreshIntegration('00000000-0000-0000-0000-000000000001', interviewService.getGiactData(), 'GIACT')"
                                            *ngIf="interviewService.getIntegrationStatus(interviewService.getGiactData().status) == 'Running' ">
                                            <i aria-hidden="true" class="fas fa-sync-alt"></i> Refresh
                                        </button>
                                    </div>
                                    <div class="label-title" *ngIf="interviewService.getGiactData().details?.message">
                                        Response </div>
                                    <div class="label-content mb-0"
                                        *ngIf="interviewService.getGiactData().details?.message">
                                        {{interviewService.getGiactData().details?.message}}
                                    </div>
                                </div>
                            </div>

                            <div class="integration-container" *ngIf="interviewService.getMibData().status">
                                <div class="question-header">
                                    <span class="d-inline-block pl-2 pt-2"> MIB </span>
                                    <button type="button" class="btn btn-link float-right"
                                        (click)="mibExpanded = !mibExpanded">
                                        <i aria-hidden="true" class="fa"
                                            [ngClass]="{'fa-angle-right': mibExpanded, 'fa-angle-down': !mibExpanded}"></i>
                                    </button>
                                </div>
                                <div class="p-2" *ngIf="!mibExpanded">
                                    <div class="label-title"> Status </div>
                                    <div class="label-content mb-0">
                                        {{ interviewService.getIntegrationStatus( interviewService.getMibData().status
                                        )}}
                                        <button data-test-refresh-integration-name="MIB"
                                            data-test-refresh-integration-id="00000000-0000-0000-0000-000000000002"
                                            type="button" class="btn btn-link btn-sm custom float-right"
                                            (click)="refreshIntegration('00000000-0000-0000-0000-000000000002', interviewService.getMibData(), 'MIB')"
                                            *ngIf="interviewService.getIntegrationStatus(interviewService.getMibData().status) == 'Running' ">
                                            <i aria-hidden="true" class="fas fa-sync-alt"></i> Refresh
                                        </button>
                                    </div>
                                    <div class="label-title"
                                        *ngIf="interviewService.getIntegrationStatus(interviewService.getMibData().status) == 'Failed' ">
                                        Error Message </div>
                                    <div class="label-content mb-0"
                                        *ngIf="interviewService.getIntegrationStatus(interviewService.getMibData().status) == 'Failed' ">
                                        {{interviewService.getMibData().errorMessage}}</div>
                                </div>
                            </div>

                            <div class="integration-container" *ngIf="interviewService.getAgentData().status">
                                <div class="question-header">
                                    <span class="d-inline-block pl-2 pt-2"> Agent </span>
                                    <button type="button" class="btn btn-link float-right"
                                        (click)="agentExpanded = !agentExpanded">
                                        <i aria-hidden="true" class="fa"
                                            [ngClass]="{'fa-angle-right': agentExpanded, 'fa-angle-down': !agentExpanded}"></i>
                                    </button>
                                </div>
                                <div class="p-2" *ngIf="!agentExpanded">
                                    <div class="label-title"> Status </div>
                                    <div class="label-content mb-0">
                                        {{ interviewService.getIntegrationStatus(
                                        interviewService.getAgentData().status) }}
                                        <button data-test-refresh-integration-name="Agent"
                                            data-test-refresh-integration-id="00000000-0000-0000-0000-000000000005"
                                            type="button" class="btn btn-link btn-sm custom float-right"
                                            (click)="refreshIntegration('00000000-0000-0000-0000-000000000005', interviewService.getAgentData(), 'Agent')"
                                            *ngIf="interviewService.getIntegrationStatus(interviewService.getAgentData().status) == 'Running' ">
                                            <i aria-hidden="true" class="fas fa-sync-alt"></i> Refresh
                                        </button>
                                    </div>
                                    <div class="label-title"
                                        *ngIf="interviewService.getIntegrationStatus(interviewService.getAgentData().status) == 'Failed' ">
                                        Error Message </div>
                                    <div class="label-content mb-0"
                                        *ngIf="interviewService.getIntegrationStatus(interviewService.getAgentData().status) == 'Failed' ">
                                        {{interviewService.getAgentData().errorMessage}}</div>
                                </div>
                            </div>

                            <div class="integration-container" *ngIf="interviewService.getQuoteData().status">
                                <div class="question-header">
                                    <span class="d-inline-block pl-2 pt-2"> Quote </span>
                                    <button type="button" class="btn btn-link float-right"
                                        (click)="quoteExpanded = !quoteExpanded">
                                        <i aria-hidden="true" class="fa"
                                            [ngClass]="{'fa-angle-right': quoteExpanded, 'fa-angle-down': !quoteExpanded}"></i>
                                    </button>
                                </div>
                                <div class="p-2" *ngIf="!quoteExpanded">
                                    <div class="label-title"> Status </div>
                                    <div class="label-content mb-0">
                                        {{ interviewService.getIntegrationStatus(
                                        interviewService.getQuoteData().status) }}
                                        <button data-test-refresh-integration-name="Quote"
                                            data-test-refresh-integration-id="00000000-0000-0000-0000-000000000004"
                                            type="button" class="btn btn-link btn-sm custom float-right"
                                            (click)="refreshIntegration('00000000-0000-0000-0000-000000000004', interviewService.getQuoteData(), 'Quote')"
                                            *ngIf="interviewService.getIntegrationStatus(interviewService.getQuoteData().status) == 'Running' ">
                                            <i aria-hidden="true" class="fas fa-sync-alt"></i> Refresh
                                        </button>
                                    </div>
                                    <div class="label-title"
                                        *ngIf="interviewService.getIntegrationStatus(interviewService.getQuoteData().status) == 'Failed' ">
                                        Error Message </div>
                                    <div class="label-content mb-0"
                                        *ngIf="interviewService.getIntegrationStatus(interviewService.getQuoteData().status) == 'Failed' ">
                                        {{interviewService.getQuoteData().errorMessage}}</div>
                                </div>
                            </div>

                            <div *ngIf="interviewService.getIntegrations().length > 0">
                                <div *ngFor="let integrationV2 of interviewService.getIntegrations()">
                                    <div class="integration-container" *ngIf="integrationV2.status">
                                        <div class="question-header">
                                            <span class="d-inline-block pl-2 pt-2"> {{integrationV2.name }}</span>
                                            <button type="button" class="btn btn-link float-right"
                                                (click)="integrationV2Expanded = !integrationV2Expanded">
                                                <i aria-hidden="true" class="fa"
                                                    [ngClass]="{'fa-angle-right': quoteExpanded, 'fa-angle-down': !integrationV2Expanded}"></i>
                                            </button>
                                        </div>
                                        <div class="p-2" *ngIf="!integrationV2Expanded">
                                            <div class="label-title"> Status </div>
                                            <div class="label-content mb-0">
                                                {{ interviewService.getIntegrationStatus( integrationV2.status) }}
                                                <button [attr.data-test-refresh-integration-name]="integrationV2.name"
                                                    [attr.data-test-refresh-integration-id]="integrationV2.integrationId"
                                                    type="button" class="btn btn-link btn-sm custom float-right"
                                                    (click)="refreshIntegration(integrationV2.integrationId, integrationV2, integrationV2.name)"
                                                    *ngIf="interviewService.getIntegrationStatus(integrationV2.status) == 'Running' ">
                                                    <i aria-hidden="true" class="fas fa-sync-alt"></i> Refresh
                                                </button>
                                            </div>
                                            <div class="label-title"
                                                *ngIf="interviewService.getIntegrationStatus(integrationV2.status) == 'Failed' ">
                                                Error Message
                                            </div>
                                            <div class="label-content mb-0"
                                                *ngIf="interviewService.getIntegrationStatus(integrationV2.status) == 'Failed' ">
                                                {{integrationV2.errorMessage}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </mat-tab>

                    <mat-tab disabled>
                        <ng-template mat-tab-label><i aria-hidden="true" class="fa fa-times" (click)="toggleSidenav()"></i>
                        </ng-template>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </mat-drawer>

        <!-- Center Column (questions) -->
        <mat-drawer-content>
            <button mat-flat-button color="primary" (click)="toggleSidenav()" class="vertical-button"
                *ngIf="isCaseDetailsClosed() && !interviewService.isReadOnly && canViewCaseDetails">
                Case Details
            </button>
            <div class="center-panel full-height d-flex flex-column">
                <div class="center-panel-header flex flex-row justify-content-between">
                    <div class="center-panel-title">{{ formattedSection?.header ? formattedSection?.header : formattedSection?.name}}</div>
                    <div class="small-text" *ngIf="caseStatus && !interviewService.isReadOnly">Last Saved On {{ lastSavedTime | date:'MM/dd/yyyy HH:mm:ss' }}</div>
                    <div *ngIf="interviewService.isReadOnly && clearSectionJsonPermission">
                        <mrs-btn-outline buttonLabel="Clear JSON Data" iconName="cancel" (onClick)="clearSectionJsonData()"></mrs-btn-outline>
                    </div>
                </div>

                <div #interviewContainer class="center-panel-body flex-fill overflow-y-auto interview-container"
                    *ngIf="formattedSection?.questions?.length > 0 || formattedSection?.isThirdParty" id="interviewForm"
                    style="will-change: transform;">
                    <div class="pb-4">
                        <div class="integration-container"
                            *ngIf="interviewService.isReadOnly && formattedSection?.sectionActions && formattedSection?.sectionActions.length>0">
                            <div class="question-header">
                                <span class="d-inline-block pt-2 pl-2">Section Actions Data </span>
                                <button type="button" class="btn btn-link float-right"
                                    (click)="actionsExpanded = !actionsExpanded">
                                    <i aria-hidden="true" class="fa"
                                        [ngClass]="{'fa-angle-right': actionsExpanded, 'fa-angle-down': !actionsExpanded}"></i>
                                </button>
                            </div>
                            <div class="p-2" *ngIf="!actionsExpanded">
                                <div *ngFor="let action of formattedSection?.sectionActions">
                                    <div class="integration-container">
                                        <div class="question-header">
                                            <span class="d-inline-block pt-2 pl-2">{{action.actions.actionTypes.name}} -
                                                {{action.actions.name}}
                                                Action Results</span>
                                            <button type="button" class="btn btn-link float-right"
                                                (click)="action.expanded = !action.expanded">
                                                <i aria-hidden="true" class="fa"
                                                    [ngClass]="{'fa-angle-right': action.expanded, 'fa-angle-down': !action.expanded}"></i>
                                            </button>
                                        </div>

                                        <div class="p-2" *ngIf="action.expanded">
                                            <div [ngSwitch]="action.actions.name">
                                                <div *ngSwitchCase="'Disabled'">
                                                    <div class="label-title"> Disabled </div>
                                                    <div class="label-content mb-0">{{
                                                        action.actionResults.data?.disabled}} </div>
                                                </div>
                                                <div *ngSwitchCase="'Hide'">
                                                    <div class="label-title"> Display </div>
                                                    <div class="label-content mb-0">{{
                                                        action.actionResults.data?.display}} </div>
                                                </div>
                                                <div *ngSwitchCase="'Show'">
                                                    <div class="label-title"> Display </div>
                                                    <div class="label-content mb-0"> {{
                                                        action.actionResults.data?.display}} </div>
                                                </div>
                                                <div *ngSwitchCase="'Trigger UW Process'">
                                                    <div class="label-title"> Details </div>
                                                    <div class="label-content mb-0"
                                                        *ngIf="action.actionResults?.data !== null"> {{
                                                        action.actionResults.data |json}} </div>
                                                </div>
                                                <div *ngSwitchCase="'Change Case Status'">
                                                    <div class="label-title"> Details </div>
                                                    <div class="label-content mb-0"
                                                        *ngIf="action.actionResults?.data !== null"> {{
                                                        action.actionResults.data |json}} </div>
                                                </div>
                                                <div *ngSwitchCase="'Check For Duplicate App'">
                                                    <div class="label-title"> Details </div>
                                                    <div class="label-content mb-0"
                                                        *ngIf="action.actionResults?.data !== null"> {{
                                                        action.actionResults.data |json}} </div>
                                                </div>
                                                <div *ngSwitchCase="'Update Case Policy Number'">
                                                    <div class="label-title"> Details </div>
                                                    <div class="label-content mb-0"
                                                        *ngIf="action.actionResults?.data !== null"> {{
                                                        action.actionResults.data |json}} </div>
                                                </div>
                                                <div *ngSwitchCase="'Validate Repeat Block Sum'">
                                                    <div class="label-title"> Details </div>
                                                    <div class="label-content mb-0"
                                                        *ngIf="action.actionResults?.data !== null"> {{
                                                        action.actionResults.data |json}} </div>
                                                </div>
                                                <div *ngSwitchCase="'Trigger Billing'">
                                                    <div class="label-title"> Details </div>
                                                    <div class="label-content mb-0"
                                                        *ngIf="action.actionResults?.data !== null"> {{
                                                        action.actionResults.data |json}} </div>
                                                </div>

                                            </div>
                                            <div *ngIf="action.actionResults && action.actionResults.rulesResult">
                                                <div class="label-title"> Rule </div>
                                                <div class="label-content mb-0">{{
                                                    action.actionResults.rulesResult?.name}} - {{
                                                    action.actionResults.rulesResult?.result}} </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div *ngIf="formattedSection?.isThirdParty">
                            <div>
                                <third-party-section [embeddedData]="formattedSection.embeddedIntegrationData"
                                    [isCaseSummary]="readonly">
                                </third-party-section>
                            </div>
                        </div>

                        <dynamic-form *ngIf="!formattedSection?.isThirdParty"
                            [expandAllQuestions]="true"
                            [selectedQuestion]="selectedQuestion"
                            [interviewMode]="interviewMode"
                            [parentIndex]=""
                            [sectionLocked]="formattedSection.isLocked"
                            [(constantLists)]="constantLists"
                            [config]="formattedSection.questions"
                            [formattedSection]="formattedSection">
                        </dynamic-form>

                        <div class="px-4" *ngIf="interviewMode!='readonly'">
                            <mrs-btn-tertiary 
                                *ngIf="formattedSection?.order > 1 && !previousDisabled"
                                buttonLabel="Previous" 
                                buttonId="previous" 
                                (onClick)="selectPreviousSection(formattedSection)" 
                                iconName="navigate_before">
                            </mrs-btn-tertiary>
                            <mrs-btn-primary 
                                *ngIf="formattedSection?.id!=lastSection?.id"
                                buttonLabel="Next" 
                                buttonId="submit"
                                (onClick)="saveInterview()"
                                iconName="chevron_right"
                                iconPosition="after"></mrs-btn-primary>
                            <mrs-btn-primary 
                                *ngIf="formattedSection?.id==lastSection?.id"
                                buttonLabel="Submit" 
                                buttonId="submit"
                                (onClick)="saveInterview()"></mrs-btn-primary>
                        </div>
                    </div>
                </div>

                <div class="center-panel-body flex-fill overflow-y-auto interview-container"
                    *ngIf="formattedSection?.questions?.length < 1 && !formattedSection?.isThirdParty">
                    <div class="p-4 text-center text-muted">
                        <img src="./assets/images/no-data-placeholder.png" alt="" class="pb-3" />
                        <h6>No Questions Found</h6>
                    </div>
                </div>
            </div>
        </mat-drawer-content>

    </mat-drawer-container>
</div>

<p-dialog header="Interview JSON" [(visible)]="showInterviewJson" modal="true" [contentStyle]="{'width': '700px' }"
    appendTo="body">
    <textarea readonly class="interview-json-container" #interviewJsonContainer>{{ interviewJson }}</textarea>
    <p-footer>
        <button type="submit" mat-raised-button color="primary" class="mr-1"
            (click)="copyJSONToClipboard(interviewJsonContainer)">
            <i aria-hidden="true" class="fa fa-copy"></i> Copy to Clipboard
        </button>
        <button mat-button color="basic" class="mr-1" (click)="toggleShowInterviewJson(false)">
            <i aria-hidden="true" class="fa fa-times"></i> Close
        </button>
    </p-footer>
</p-dialog>

<p-dialog [(visible)]="showRestartScript" [contentStyle]="{'width': '350px' }" [closable]="false" [resizable]='false'
    id="interviewDialog" appendTo="body" modal="true">
    <p-header>
        Interview Restart
    </p-header>

    <div [innerHTML]="restartScript">

    </div>
    <button (click)="showRestartScript=false;" class="mr-1" mat-raised-button color="primary">
        Continue
    </button>
</p-dialog>

<ng-template #sectionProcessing>
    <h1 mat-dialog-title style="display: flex;">
    </h1>
    <div mat-dialog-content class="flex">
        <div class="flex flex-column flex-center pb-4 pt-4">
            <div class="mb-2">{{displayMessage}}</div>
        </div>
    </div>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-template>
