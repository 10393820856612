import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import * as _ from 'lodash';
import * as moment from 'moment';
import { AppService } from '../../../app.service';
import { DocType } from '../../../enums';
import { Account } from '../../../models';
import { ConfirmationDialogService, DocumentsService, RoutesEnum, TagService } from '../../../services';
import { ReportDownloadService } from '../../../services/reportdownload.service';
import { CombinedDocumentsComponent } from './combined-documents/combined-documents.component';
import { EmbeddedDocumentsSettingsComponent } from './embedded-documents-settings/embedded-documents-settings.component';


@Component({
    selector: 'account-documents',
    host: { 'class': 'bg-white d-flex flex-row flex-fill' },
    templateUrl: './account-documents.component.html',
    styleUrls: ['./account-documents.component.scss']
})
export class AccountDocumentsComponent implements OnInit, OnChanges {
    @Input() accountInfo: Account;
    @Input() rules;
    @Input() mapKeys;
    @ViewChild('toolTip', { static: true }) toolTipDialog: TemplateRef<any>;

    documents: any = [];
    xsdDocuments: any = [];
    documentForm: UntypedFormGroup;
    showForm: any;
    documentName: any;
    selectedDocument: any;
    myfile: any;
    fileData: any = {};
    fileSelected: any;
    configData: any = [];
    filteredOutputTypes: any = [];
    filteredInputTypes: any = [];
    documentStatus: any = [];
    accountIntegrations: any = [];
    hideArchived = true;
    documentIsEditMode = false;
    previewConvention: string;
    combinedDocuments: string;
    combinedDocumentNameList: string;
    @Input() secretKeys;
    @Output() getSecretKeys: EventEmitter<any>;
    doctypeXML = DocType.XML;
    doctypePDF = DocType.PDF;
    xsdGuid = DocType.XSD;
    integrationDocType = DocType.Integration;
    combinedPDFDocType = DocType.CombinedDocument;

    showDownloadTemplateButton: boolean;
    showIntegration = false;
    showSelectFile = false;
    showOutputType = false;
    showValidationXsd = false;
    showRule = false;
    showSigningRule = false;
    showOutputFileName = false;
    showDocumentStatus = false;
    showSigningCertificate = false;
    showXslData = false;
    showExcludeTransmit = false;
    showIncludeInSummary = false;
    showCombinedDocuments = false;
    showEmbeddedDocuments = false;
    showRenumberPages = false;
    tagSuggestions: string[] = [];

    filterValue: string;
    filteredDocuments: any[] = [];

    combinedDocDialogRef: MatDialogRef<CombinedDocumentsComponent>;
    embeddedDialogRef: MatDialogRef<EmbeddedDocumentsSettingsComponent>;
    constructor(
        public dialog: MatDialog,
        public appService: AppService,
        private reportDownloadService: ReportDownloadService,
        private tagService: TagService,
        private _fb: UntypedFormBuilder,
        private confirmationService: ConfirmationDialogService,
        private httpClient: HttpClient,
        private documentService: DocumentsService
    ) {
        this.getSecretKeys = new EventEmitter<any>();
    }

    ngOnChanges(changes: SimpleChanges) {
        // console.log(changes);
    }

    ngOnInit() {
        this.tagService.getTagsByAccountAndType(this.accountInfo, ['MapKey', 'MapKeyEntity']).then(response => {
            this.tagSuggestions = _.sortBy((response || []).map(x => x.name));
        });


        this.documentStatus = this.appService.getConfigData('documentStatus');
        this.checkACL('R', RoutesEnum.dashboard);
        if (this.accountInfo) {
            this.getDocuments();
        }
        this.appService.getAllConfigData().then((data) => {
            this.configData = data;

            this.filteredInputTypes = this.configData.docTypes.filter(x => x.id == DocType.PDF 
                || x.id == DocType.Transcript
                || x.id == DocType.XSLT
                || x.id == DocType.XSD
                || x.id == DocType.CombinedDocument
                || x.id == DocType.Integration
                || x.id == DocType.IntegrationResponse);
        });
        // this.getSecretKeys();
        this.getSecretKeys.emit();
        this.getXSDDocuments();
        this.documentForm = this._fb.group({
            id: '',
            accountId: ['', Validators.required],
            clientId: ['', Validators.required],
            name: ['', Validators.required],
            doctypeId: ['', Validators.required],
            generateTo: ['', Validators.required],
            rulesId: [''],
            signingRuleId: [''],
            signatureField: [''],
            signatureFieldCount: 0,
            convention: [''],
            file: [''],
            schemaName: [''],
            isFile: true,
            signingCertificate: '',
            isExcludeTransmit: false,
            includeInSummary: false,
            documentStatusId: [''],
            path: [''],
            contentType: [''],
            documentMd5: [''],
            xslData: [''],
            integrationId: [''],
            changeKeyTag: [''],
            isEmbeddedDocuments: [''],
            objectProperties: [''],
            combinedDocuments: [''],
            selectedItems: [''],
            renumberPages: ['']
        });

        this.rules.unshift({
            id: this.appService.emptyGUID,
            name: ' None'
        });        
    }

    checkACL(permissionType, redirect?) {
        return this.appService.checkACL('Accounts', permissionType, redirect);
    }

    onInputTypeSelect(inputType) {
        if (this.documentName !== '') {

            const selectedExtension = this.documentName.match(/\.([^\.]+)$/)[1];

            let inputTypeExtension;
            if (this.configData.docTypes && this.configData.docTypes.length) {
                inputTypeExtension = _.find(this.configData.docTypes, ['id', this.documentForm.get('doctypeId').value]).name;
            }
            if (inputTypeExtension !== selectedExtension) {
                this.fileData = undefined;
                this.documentName = '';
                this.documentForm.patchValue({
                    file: '',
                    isFile: true
                });
            }
        }

        this.setupFieldsToShow(inputType.value);
    }

    getAccountIntegrations() {
        this.appService.getData(`Accounts/${this.accountInfo.id}/Integrations`).subscribe(
            result => {
                if (result.status === 'success') {
                    const integrations = result.data;

                    this.accountIntegrations = _.filter(integrations, function (e) {
                        return e.accountIntegrations && e.accountIntegrations != null;
                    });
                }
            }
        );
    }

    isNotXSDDoc(doctypeId) {
        if (doctypeId !== this.xsdGuid) {
            return true;
        } else {
            return false;
        }
    }

    isPDFDoc(doctypeId) {
        if (doctypeId === this.doctypePDF) {
            return true;
        } else {
            return false;
        }
    }

    isXMLOutput(outputType) {
        if (outputType === this.doctypeXML) {
            return true;
        }
        return false;
    }

    onOutputTypeSelect(outputType) {
        if (outputType.value !== this.doctypePDF) {
            this.documentForm.patchValue({
                signingCertificate: '',
            });
        }
        if (outputType.value !== this.doctypeXML) {
            this.documentForm.patchValue({
                schemaName: '',
            });
        }
    }

    viewArchived() {
        this.hideArchived = this.hideArchived ? false : true;
    }

    getDocuments() {
        this.appService.getData(`Documents/${this.accountInfo.id}`).subscribe(
            data => {
                if (data.status === 'success') {
                    this.documents = data.data;
                    this.filteredDocuments = this.documents;
                }
            }
        );
    }

    getXSDDocuments() {
        this.appService.getData(`Documents/${this.accountInfo.id}/xsdList`).subscribe(
            data => {
                if (data.status === 'success') {
                    this.xsdDocuments = data.data;
                }
            }
        );
    }

    addDocument() {
        this.showForm = true;
        this.selectedDocument = undefined;
        this.fileData = undefined;
        this.documentName = '';
        this.previewConvention = '';
        this.documentForm.reset();
        this.getXSDDocuments();
        this.documentIsEditMode = false;
        this.documentForm.patchValue({
            id: '',
            accountId: this.accountInfo.id,
            clientId: this.accountInfo.clientId,
            name: '',
            doctypeId: '',
            generateTo: '',
            rulesId: this.appService.emptyGUID,
            signingRuleId: '',
            signatureField: '',
            convention: '',
            file: '',
            schemaName: '',
            xslData: '',
            isFile: false,
            isExcludeTransmit: false,
            includeInSummary: false,
            signingCertificate: '',
            // signingCertificate: this.appService.emptyGUID,
            documentStatusId: '00000000-0000-0000-0000-000000000001',
            integrationId: '',
            path: '',
            contentType: '',
            documentMd5: '',
            objectProperties: '',
            renumberPages: ''
        });
    }

    getExtensionFormat(formatId) {
        if (formatId && this.configData.docTypes && this.configData.docTypes.length && (formatId !== DocType.Integration || formatId !== DocType.CombinedDocument || formatId !== DocType.IntegrationResponse)) {
            const formatExtension = _.find(this.configData.docTypes, ['id', formatId]).extension;
            if (formatExtension) {
                return `${formatExtension.toLowerCase()}, ${formatExtension.toUpperCase()}`;
            }
        } else {
            return '';
        }
    }

    getSchemaName(docname) {
        const doc = this.xsdDocuments.filter(x => x.remoteName === docname);
        if (doc !== undefined && doc[0] !== undefined && doc !== null) {
            return doc[0].name;
        }
        return '';
    }
    getDocumentInfo(document) {
        this.showForm = false;
        this.selectedDocument = document;
        this.setupFieldsToShow(document.doctypeId);
        if (document.doctypeId === DocType.CombinedDocument) {
            this.setCombinedDocumentList(document);
        }
    }

    // clear value once he clicks on the select document
    onClick(event) {
        event.target.value = null;
    }

    selectFile(event) {
        if (this.documentForm.get('doctypeId').value !== '') {
            const files = event.target.files;
            if (!files) {
                return;
            } else {
                this.documentForm.patchValue({
                    isFile: true
                });
                const extension = files[0].name.match(/\.([^\.]+)$/)[1];

                if (this.documentForm.get('doctypeId').value === DocType.Transcript) {
                    if (extension.toLowerCase() !== 'xslt') {
                        this.appService.showMsg('error', 'Please choose an xslt file for the transcript', false);
                    } else {
                        this.documentName = files[0].name;
                        const formData: FormData = new FormData();

                        for (let i = 0; i < files.length; i++) {
                            formData.append(i.toString(), files[i], files[i].name);
                        }
                        this.fileData = formData;
                    }
                } else {
                    let fileExtension;
                    if (this.configData.docTypes && this.configData.docTypes.length) {
                        fileExtension = _.find(this.configData.docTypes, ['id', this.documentForm.get('doctypeId').value]).name;
                    }

                    if (fileExtension.toLowerCase() === extension.toLowerCase()) {
                        this.documentName = files[0].name;
                        const formData: FormData = new FormData();

                        for (let i = 0; i < files.length; i++) {
                            formData.append(i.toString(), files[i], files[i].name);
                        }
                        this.fileData = formData;
                    } else {
                        this.appService.showMsg('error', 'Cannot upload the document please check input type', false);
                    }
                }
            }
        } else {
            this.appService.showMsg('error', 'Please choose an input type', false);
        }
    }

    selectSchemaFile(event) {
        if (this.documentForm.get('schemaName').value !== '') {
            const files = event.target.files;
            if (!files) {
                return;
            } else {
                this.documentForm.patchValue({
                    isFile: true
                });
                const extension = files[0].name.match(/\.([^\.]+)$/)[1];
                let fileExtension;
                if (this.configData.docTypes && this.configData.docTypes.length) {
                    fileExtension = _.find(this.configData.docTypes, ['id', this.documentForm.get('schemaName').value]).name;
                }

                if (fileExtension.toLowerCase() === extension.toLowerCase()) {
                    this.documentName = files[0].name;
                    const formData: FormData = new FormData();

                    for (let i = 0; i < files.length; i++) {
                        formData.append(i.toString(), files[i], files[i].name);
                    }
                    this.fileData = formData;
                } else {
                    this.appService.showMsg('error', 'Cannot upload the document please check input type', false);
                }
            }
        } else {
            this.appService.showMsg('error', 'Please choose an input type', false);
        }
    }

    editDocument() {
        this.documentForm.reset();
        this.documentForm.patchValue(this.selectedDocument);
        this.documentForm.patchValue({
            isFile: false,
            file: ''
        });
        this.documentName = this.selectedDocument.remoteName;
        this.showForm = true;
        this.documentIsEditMode = true;
        this.getAccountIntegrations();
        this.setupFieldsToShow(this.selectedDocument.doctypeId);
    }

    cancel() {
        this.showForm = false;
        this.selectedDocument = undefined;
    }

    unSelectFile() {
        this.documentName = '';
        this.documentForm.patchValue({
            file: ''
        });
        if (this.selectedDocument) {
            this.documentForm.patchValue({
                isFile: true,
            });
        }
    }

    unSelectSchemaFile() {
        this.documentName = '';
        this.documentForm.patchValue({
            schemaName: ''
        });
        if (this.selectedDocument) {
            this.documentForm.patchValue({
                isFile: true,
            });
        }
    }

    saveDocument() {
        if ((this.documentForm.value.isFile && this.documentForm.value.file === '') || (this.selectedDocument && this.documentForm.value.isFile && this.documentForm.value.file === '')) {
            this.appService.showMsg('error', 'Please Upload Document', false);
        } else {
            if (this.documentForm.value.doctypeId === this.xsdGuid) {
                this.documentForm.patchValue({
                    generateTo: this.documentForm.value.doctypeId,
                    isExcludeTransmit: true,
                    includeInSummary: false,
                    convention: this.documentForm.value.name
                });
                this.documentForm.controls['convention'].clearValidators();
            } else if (this.documentForm.value.doctypeId === DocType.Integration) {
                this.documentForm.controls['convention'].clearValidators();
                if (this.documentForm.value.integrationId !== "00000000-0000-0000-0000-000000000078") {
                    this.documentForm.patchValue({
                        generateTo: DocType.PDF
                    });
                }
            } else if (this.documentForm.value.doctypeId === DocType.IntegrationResponse) {
                this.documentForm.controls['convention'].clearValidators();
                this.documentForm.patchValue({
                    generateTo: DocType.XML
                });
            } else {
                this.documentForm.controls['convention'].setValidators(Validators.required);
            }

            if (this.documentForm.valid) {
                // In order to perform request type either POST or PUT based on that url gets changed.
                let requestType, url = '';
                if (this.documentForm.value.id && this.documentForm.value.id !== '') {
                    requestType = 'put';
                    this.documentForm.value.RemoteName = this.selectedDocument.remoteName;
                    // the below urls are for different types of editing 1. with changing the document and 2. without changing the document.
                    if (this.documentForm.value.isFile) {
                        url = `${this.appService.getAPIBaseURL()}Documents/${this.documentForm.value.id}/${this.documentForm.value.isFile}`;
                    } else {
                        url = `${this.appService.getAPIBaseURL()}Documents/${this.documentForm.value.id}`;
                    }
                } else {
                    delete this.documentForm.value.id;
                    requestType = 'post';

                    if (this.documentForm.value.doctypeId === DocType.Integration || this.documentForm.value.doctypeId === DocType.CombinedDocument || this.documentForm.value.doctypeId === DocType.IntegrationResponse) {
                        url = this.appService.getAPIBaseURL() + 'Documents/Add';
                    } else {
                        url = this.appService.getAPIBaseURL() + 'Documents';
                    }
                }

                this.documentForm.value.rulesId = this.documentForm.value.rulesId === this.appService.emptyGUID ? '' : this.documentForm.value.rulesId;
                this.documentForm.value.signingCertificate = this.documentForm.value.signingCertificate === this.appService.emptyGUID ? '' : this.documentForm.value.signingCertificate;

                if (this.documentForm.value.isFile) {
                    // This will append the field values to the form data if document is uploaded.
                    this.fileData.append('data', JSON.stringify(this.documentForm.value));
                } else {
                    // This will just send the form values when in edit mode with out changing the document.
                    this.fileData = this.documentForm.value;
                }

                this.appService.display(true);
                this.httpClient[requestType](url, this.fileData, {
                    headers: this.appService.getUploadHttpHeaders()
                }).subscribe(
                    (response) => {
                        let data: any;
                        if (response._body) {
                            data = JSON.parse(response._body);
                        } else {
                            data = response;
                        }

                        if (data.status === 'success') {
                            this.getDocuments();
                            this.showForm = false;
                            if (requestType === 'put') {
                                this.selectedDocument = data.data;
                                if (this.selectedDocument.doctypeId === DocType.CombinedDocument) {
                                    this.setCombinedDocumentList(this.selectedDocument);
                                }
                            }
                            this.fileData = undefined;
                            this.appService.showMsg('success', 'Document Saved Successfully');

                            if (requestType === 'put' && this.documentForm.value.isFile && this.selectedDocument.envelopeNames) {
                                this.appService.showMsg('error', `This document is being used in envelopes: ${this.selectedDocument.envelopeNames}. Please verify all signer form fields for accuracy`);
                            }

                        } else {
                            this.appService.showMsg('error', data.message);
                            this.documentName = '';
                            this.fileData = undefined;
                            if (requestType === 'post') {
                                this.documentForm.reset();
                            }
                            this.documentForm.patchValue({
                                accountId: this.accountInfo.id,
                                file: '',
                            });
                        }
                    },
                    error => {
                        this.appService.showResponseErrorMsg(error);
                        this.appService.display(false);
                    },
                    () => this.appService.display(false)
                );

            }
        }
    }

    downloadDocument() {
        const url = `Documents/${this.selectedDocument.id}/formfieldsxml/${this.selectedDocument.name}`;
        this.reportDownloadService.downloadReport('application/xml', url);
    }

    downloadDocumentTemplate() {
        this.documentService.getDocumentTemplateUri(this.selectedDocument.accountId, this.selectedDocument.id).subscribe(result => {
            window.open(result, '_blank');
        });
    }

    deleteDocument(documentId) {

        this.confirmationService.open({
            message: 'Are you sure that you want to delete this document?',
            onOk: () => {
                this.appService.deleteData(`Documents/${documentId}`).subscribe(
                    data => {
                        if (data.status === 'success') {
                            this.showForm = false;
                            this.selectedDocument = undefined;
                            this.getDocuments();
                            this.appService.showMsg('success', 'Document Deleted Successfully');
                        }
                    }
                );
            },
            showCancel: true
        });

    }

    getRuleName(ruleId) {
        if (ruleId && this.rules && this.rules.length) {
            return _.find(this.rules, ['id', ruleId]).name;
        } else {
            return '';
        }
    }

    getTypeName(typeId) {
        if (typeId && this.configData.docTypes && this.configData.docTypes.length) {
            return _.find(this.configData.docTypes, ['id', typeId])?.name;
        } else {
            return '';
        }
    }

    getDocumentStatusName(typeId) {
        if (typeId && this.configData.documentStatus && this.configData.documentStatus.length) {
            return _.find(this.configData.documentStatus, ['id', typeId]).name;
        } else {
            return 'Active';
        }
    }

    validateAndPreviewConvention(convention) {
        if (convention && convention !== '') {
            convention = convention.replace('{Account.Code}', this.accountInfo.code);
            // As it is in config we can't get case number here. Displaying '100-0001-000001' by default
            convention = convention.replace('{Case.CaseNumber}', '100-0001-000001');
            // As it is in config we can't get case number here. Displaying '000001' by default
            convention = convention.replace('{Case.PolicyNumber}', '000001');
            convention = convention.replace('{Now:yyyyMMddHHmmss}', this.getTimeStamp(true));
            convention = convention.replace('{Now:yyyyMMddhhmmss}', this.getTimeStamp(false));
            this.previewConvention = 'Preview: ' + convention;
        }
    }

    getTimeStamp(is24Hr) {
        if (is24Hr) {
            return moment(new Date()).format('YYYYMMDDHHmmss');
        } else {
            return moment(new Date()).format('YYYYMMDDhhmmss');
        }
    }

    setupFieldsToShow(inputType) {
        this.showIntegration = false;
        this.showSelectFile = true;
        this.showOutputType = false;
        this.showValidationXsd = false;
        this.showRule = true;
        this.showSigningRule = true;
        this.showOutputFileName = true;
        this.showDocumentStatus = true;
        this.showSigningCertificate = true;
        this.showXslData = true;
        this.showExcludeTransmit = true;
        this.showIncludeInSummary = true;
        this.showCombinedDocuments = false;
        this.showEmbeddedDocuments = false;
        this.showRenumberPages = false;
        this.filteredOutputTypes = this.configData.docTypes;        

        switch (inputType) {
            case DocType.Integration:
            case DocType.IntegrationResponse:
                this.documentForm.controls['generateTo'].clearValidators();
                this.documentForm.controls['integrationId'].setValidators(Validators.required);
                this.getAccountIntegrations();

                this.documentForm.controls['convention'].setValidators(Validators.required);
                this.showDownloadTemplateButton = false;
                this.showIntegration = true;
                this.showOutputType = true;
                this.showSelectFile = false;
                this.showSigningRule = false;
                this.showDocumentStatus = false;
                this.showXslData = false;
                this.showSigningCertificate = false;
                this.showIncludeInSummary = false;
                break;
            case DocType.XSD:
                this.documentForm.controls['convention'].clearValidators();
                this.documentForm.controls['integrationId'].clearValidators();
                this.documentForm.patchValue({
                    integrationId: null
                });
                this.showRule = false;
                this.showOutputFileName = false;
                this.showXslData = false;
                this.showExcludeTransmit = false;
                this.showDownloadTemplateButton = true;
                break;
            case DocType.CombinedDocument:
                this.documentForm.controls['convention'].clearValidators();
                this.showSelectFile = false;
                this.showSigningRule = false;
                this.showDocumentStatus = false;
                this.showXslData = false;
                this.showSigningCertificate = false;
                this.showCombinedDocuments = true;
                this.showOutputType = true;
                this.showIncludeInSummary = true;
                this.filteredOutputTypes = this.configData.docTypes.filter(x => x.id == DocType.PDF || x.id == DocType.TIFF);
                this.showDownloadTemplateButton = false;
                this.showRenumberPages = true;
                break;
            case DocType.Transcript:
                this.showOutputType = true;
                this.showSelectFile = true;
                this.showSigningRule = false;
                this.filteredOutputTypes = this.configData.docTypes.filter(x => x.id == DocType.PDF || x.id == DocType.TIFF || x.id == DocType.XML);
                this.showDownloadTemplateButton = true;
                break;
            case DocType.XML:
                this.showValidationXsd = true;
                this.showDownloadTemplateButton = true;
                break;
            case DocType.XSLT:
                this.showEmbeddedDocuments = true;
                this.showOutputType = true;
                this.showDownloadTemplateButton = true;
                break;
            case DocType.PDF:                
                this.filteredOutputTypes = this.configData.docTypes.filter(x => x.id == DocType.PDF || x.id == DocType.TIFF);                
                this.showOutputType = true;
                this.showDownloadTemplateButton = true;
                break;
            default:
                this.documentForm.controls['integrationId'].clearValidators();
                this.documentForm.patchValue({
                    integrationId: null
                });

                this.documentForm.controls['convention'].setValidators(Validators.required);

                this.showOutputType = true;
                break;
        }
    }

    setCombinedDocumentList(document) {
        const combinedDocumentNames = [];
        document.combinedDocuments.forEach(element => {
            const d = _.find(this.documents, function (o) { return o.id === element.documentId; });
            if (d) {
                combinedDocumentNames.push(d.name);
            } else {
                combinedDocumentNames.push(element.tagName);
            }
        });
        this.combinedDocumentNameList = combinedDocumentNames.join(', ');
    }

    selectCombinedDocuments() {
        this.combinedDocDialogRef = this.dialog.open(CombinedDocumentsComponent, {
            width: '50%',
            data: {
                selectedDocument: this.documentForm.value,
                documents: this.documents,
                accountInfo: this.accountInfo,
                documentFormData: this.documentForm.value
            }
        });

        this.combinedDocDialogRef.afterClosed().subscribe(result => {
            if (result.event === 'CombinedDocuments') {
                this.documentForm.controls['combinedDocuments'].setValue(result.form.get('selectedDocuments').value);
            }
        });
    }

    editEmbeddedDocuments() {
        this.embeddedDialogRef = this.dialog.open(EmbeddedDocumentsSettingsComponent, {
            width: '50%',
            data: {
                selectedDocument: this.documentForm.value,
                documents: this.documents
            }
        });

        this.embeddedDialogRef.afterClosed().subscribe(result => {
            if (result.data) {
                this.documentForm.controls['objectProperties'].setValue(result.data);
            }
        });
    }

    searchDocuments() {
        if (this.filterValue) {
            this.filteredDocuments = this.documents.filter(p => p.name.toLowerCase().indexOf(this.filterValue.toLowerCase()) !== -1);
        } else {
            this.filteredDocuments = this.documents;
        }
    }

    openToolTip(){
        this.dialog.open(this.toolTipDialog);
    }
}
