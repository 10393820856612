import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';


@Component({
    selector: 'delete-column-mapkey-dialog',
    templateUrl: './delete-column-mapkey-dialog.component.html',
    styleUrls: ['./delete-column-mapkey-dialog.component.scss']
})
export default class DeleteColumnMapkeyDialog {

    constructor(
        public dialogRef: MatDialogRef<DeleteColumnMapkeyDialog, boolean>,
    ) { }

    handleCancel = () => this.dialogRef.close(false);
    handleDelete = () => this.dialogRef.close(true);
}
