import { Component, Input } from '@angular/core';


@Component({
    selector: 'img-viewer',
    templateUrl: './img-viewer.component.html',
    styleUrls: ['./img-viewer.component.scss']
})
export default class ImgViewer {
    @Input() src: string;
}
