<section id="audit-page" class="ag-page" [formGroup]="auditForm">
    <div class="page-header">
        <div class="title">Audits</div>
        <div class="title-content">
            <div class="client-picker">
                <mat-form-field class="form-field">
                    <mat-label>Client</mat-label>
                    <mat-select panelClass="auto-width-panel" formControlName="clientId">
                        <mat-option *ngFor="let client of clients" [value]="client.id">{{ client.name }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="title-buttons">
            <mrs-btn-primary class="create-btn" buttonLabel="Create" (onClick)="onCreate()"></mrs-btn-primary>
        </div>
    </div>

    <ng-container *ngIf="!clientsLoaded">
        <mat-spinner mode="indeterminate" diameter="30"></mat-spinner>
    </ng-container>

    <div class="page-content" *ngIf="clientsLoaded">
        <div class="case-results">
            <ng-container *ngIf="loadingAudits">
                <mat-spinner mode="indeterminate" diameter="30"></mat-spinner>
            </ng-container>

            <div *ngIf="!loadingAudits && currentAudits && currentAudits.length == 0" class="no-cases">No Audit Pools</div>

            <div *ngIf="!loadingCases && currentAudits && currentAudits.length > 0">
                <table mat-table [dataSource]="currentAudits" class="table">

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row class="audit-row" *matRowDef="let row; columns: displayedColumns;" (click)="onViewAudit(row)"></tr>

                    <ng-container matColumnDef="rowNumber">
                        <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
                        <td mat-cell *matCellDef="let item; index as i;">{{ i + 1 }}</td>
                    </ng-container>

                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef>Name</th>
                        <td mat-cell *matCellDef="let item">
                                {{ item.name }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="selected-cases">
                        <th mat-header-cell *matHeaderCellDef>Selected</th>
                        <td mat-cell  *matCellDef="let item">
                            <audit-percent-label 
                                [auditGoal]="item.auditProgressStatistics.goalPercent" 
                                [allocatedCases]="item.auditProgressStatistics.selectedCases"
                                [totalCases]="item.auditProgressStatistics.totalCases"
                                toolTipEnabled=true />
                         </td>
                    </ng-container>

                    <ng-container matColumnDef="completed-cases">
                        <th mat-header-cell *matHeaderCellDef>Completed</th>
                        <td mat-cell *matCellDef="let item">
                            <audit-percent-label 
                                [auditGoal]="item.auditProgressStatistics.goalPercent" 
                                [allocatedCases]="item.auditProgressStatistics.completedCases"
                                [totalCases]="item.auditProgressStatistics.totalCases"
                                toolTipEnabled=true />

                        </td>
                    </ng-container>

                    <ng-container matColumnDef="account">
                        <th mat-header-cell *matHeaderCellDef>Account</th>
                        <td mat-cell *matCellDef="let item">{{ item.accountNames }}</td>
                    </ng-container>

                    <ng-container matColumnDef="startDate">
                        <th mat-header-cell *matHeaderCellDef>Start Date</th>
                        <td mat-cell *matCellDef="let item">{{ item.startDateFormatted }}</td>
                    </ng-container>

                    <ng-container matColumnDef="endDate">
                        <th mat-header-cell *matHeaderCellDef>End Date</th>
                        <td mat-cell *matCellDef="let item">{{ item.endDateFormatted }}</td>
                    </ng-container>

                    <ng-container  matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
                        <td mat-cell class="edit-audit-button-cell" *matCellDef="let item">
                            <div class="material-icons hideOnHover">&nbsp;</div>
                            <button *ngIf="canViewAuditEdit" class="material-icons edit-audit-button showOnHover" mat-icon-button matTooltip="Edit" (click)="onEditAudit(item)">
                                <mat-icon svgIcon="square-edit-outline"/>
                            </button>                            
                        </td>
                    </ng-container>
                </table>
            </div>
        </div>
    </div>
</section>