import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';

import { CaseSignatureService } from '../../services';
import { SignatureEnvelope, SignatureEnvelopeRecipient, SignatureEnvelopeEmbeddedView } from '../../models';
import { EmbeddedSignatureDialogComponent } from './embedded-signature-dialog.component';

@Component({
    selector: 'signature-callback',
    template: `
        <h2>Signature Complete</h2>
    `,
})
export class SignatureCallbackComponent implements OnInit {
    constructor() {}

    ngOnInit() {
        window.parent.postMessage('signatureComplete', '*');
    }
}
