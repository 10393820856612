import { MrsSnackbarComponent } from "@Shared/components/mrs-snackbar/mrs-snackbar.component";
import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MonitoringService } from '../monitor.service';
import { ConfirmationDialogService } from '../services/utility/confirmation-dialog.service';

export enum NotificationSeverity {
    Info = 'info',
    Success = 'success',
    Error = 'error',
    Warning = 'warning',
    Critical = 'critical'
}

export interface NotificationOptions {
    severity?: NotificationSeverity;
    message?: string;
    messages?: string[];
    title?: string;
    component?: any;
    componentData?: any;

    actionLabel?: string;
    action?: () => void;

    log?: boolean;
}

@Injectable()
export class NotificationService {

    constructor(
        private snackbarService: MatSnackBar,
        private monitorService: MonitoringService,
        private confirmationDialogService: ConfirmationDialogService,
        private zone: NgZone) {
    }

    private defaultOptions: NotificationOptions = {
        severity: NotificationSeverity.Info,
        message: '',
        title: 'Error',
        actionLabel: 'Ok',
        action: undefined,
        log: false
    };

    public showNotification(options: NotificationOptions) {

        if (options.severity === NotificationSeverity.Critical) {
            const title = options?.title || this.defaultOptions.title;
            return this.confirmationDialogService.open({ title, message: options.message });
        }

        const snackbarOptions = {
            ...this.defaultOptions, // overall defaults
            ...options // user overrides
        };

        if (snackbarOptions.log || options.severity === NotificationSeverity.Error) {
            this.monitorService.logError(new Error(snackbarOptions.message));
        }

        this.zone.run(() => {
            const snackbarRef = this.snackbarService.openFromComponent(MrsSnackbarComponent, { data: snackbarOptions });
            onAction(snackbarRef);

            function onAction(snackbarRef) {
                if (snackbarOptions.action != null) {
                    const subscription = snackbarRef.onAction().subscribe(() => {
                        snackbarOptions.action();

                        if (subscription) { // clean up our own subscription to prevent leaks.
                            subscription.unsubscribe();
                        }
                    });
                }
            }
        });
    }
}
