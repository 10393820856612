// Import the core angular services.
import { Component, ComponentFactoryResolver, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FeatureToggleService, RoutingService } from 'app/services';
import { FeatureManagerService } from 'app/services/global/feature-manager/feature-manager.service';

import { ViewQueuesComponent } from '../new-business-workbench/view-queues/view-queues.component';
import { ManagementQueuesComponent } from './management-queues.component';


// ----------------------------------------------------------------------------------- //
// ----------------------------------------------------------------------------------- //

@Component({
    selector: 'app-projects-switcher',
    styles: [`:host { display: none; }`],
    template:`<!-- Switcher for Projects variations. -->`
})
export class ManagementQueuesSwitcherComponent {
    constructor(
    private featureManagerService: FeatureManagerService,
    private featureToggleService: FeatureToggleService,
    private viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private activatedRoute: ActivatedRoute,
    private routingService: RoutingService
    ) {
        this.activatedRoute.data.subscribe(data => {
            const uniqueClientIds = [...new Set<string>(data.accounts.map(item => item.clientId))];
            const hasSingleClient = uniqueClientIds.length == 1;
            const cnbwEnabled = this.featureManagerService.getByName('Global.CaseManager.V2')?.enabled;
            const useCNBWPage = hasSingleClient && cnbwEnabled;

            // CNBW is disabled globally
            if (!useCNBWPage)
            {
                this.viewContainerRef.createComponent(ManagementQueuesComponent);
                return;
            }

            // Use CNBW if the Client has access
            this.featureToggleService.getFeatureFlag('Global.CaseManager.V2.Client', uniqueClientIds[0]).subscribe(clientHasAccessToCNBW => {
                if (clientHasAccessToCNBW) {
                    const viewQueuesComponent = this.viewContainerRef.createComponent(ViewQueuesComponent);
                    viewQueuesComponent.instance.clientId = uniqueClientIds[0];
                }
                else {
                    this.viewContainerRef.createComponent(ManagementQueuesComponent);
                }
            });
      
        });
    }
}
