<h1 mat-dialog-title>Configure Integration</h1>
  <div mat-dialog-content>
    <div  class="flex-fill d-flex flex-column overflow-y-auto">
      <form [formGroup]="messageForm" novalidate autocomplete="off" >
      <mat-form-field class="w-100">
        <mat-label>Client Secret</mat-label>
        <mat-select id="integrationId" formControlName="integrationId">
          <mat-option *ngFor="let accountIntegration of accountIntegrations" [value]="accountIntegration.id">{{accountIntegration.name}}</mat-option>
        </mat-select>
        <mat-error *ngIf="formSubmitted && messageForm.get('integrationId').hasError('required')">
          Integration
          <strong>required</strong>
        </mat-error>
      </mat-form-field>
      </form>
    </div>
  </div>
  <mat-dialog-actions align="end">
    <button id="closeButton" mat-button id="cancelButton" color="basic" class="mr-1 material-default" mat-dialog-close >
      CANCEL
    </button>
    <button id="okButton" mat-button color="primary" class="mr-1 material-default" (click)="saveAccountMessage()">
      OK
    </button>
  </mat-dialog-actions>
