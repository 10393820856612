import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import * as Guards from '../services/guards';
import { MrsButtonsModule } from './components/mrs-buttons';
import { MrsChipListModule } from './components/mrs-chip-list/mrs-chip-list.module';
import { MrsDropDownModule } from './components/mrs-dropdowns';
import { MentionModule } from './components/mrs-mention';
import { MrsTableModule } from './components/mrs-table';
import { SidenavService } from './services/sidenav.service';
import { TableDataService } from './services/table-data.service';
import { MrsSearchFieldModule } from '@Shared/components/mrs-search-field/mrs-search-field.module';
import { TextMaskModule } from 'angular2-text-mask';

@NgModule({
    declarations: [],
    imports: [],
    exports: [
        CommonModule,
        MatButtonModule,
        MatInputModule,
        MatToolbarModule,
        MatSidenavModule,
        MatCardModule,
        MatRadioModule,
        MatListModule,
        MatTabsModule,
        MatIconModule,
        MatSelectModule,
        MatFormFieldModule,
        MatMenuModule,
        MatAutocompleteModule,
        MatDialogModule,
        MatExpansionModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatCheckboxModule,
        MatRippleModule,
        MatSlideToggleModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatTreeModule,
        MatStepperModule,
        MatChipsModule,
        MatSortModule,
        MatSnackBarModule,
        MatTooltipModule,
        MatTableModule,
        DragDropModule,
        FormsModule,
        ReactiveFormsModule,
        RxReactiveFormsModule,
        ScrollingModule,
        MrsButtonsModule,
        MrsDropDownModule,
        MrsTableModule,
        MrsChipListModule,
        MrsSearchFieldModule,
        MentionModule,
        TextMaskModule
    ],
    providers: [
        TableDataService,
        SidenavService,
        Guards.CanDeactivateGuardService,
        Guards.ClientGuardService,
        Guards.ValidThemeGuard,
        Guards.RoleGuard,
        Guards.AclGuardService,
        Guards.AuthGuardService,
        Guards.FeatureFlagGuard,
        Guards.AuthV2GuardService,
        Guards.ValidCaseGuard
    ]
})
export class SharedModule {
}
