import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { FeatureToggle } from "@Enums";
import { FeatureManagerService } from "app/services";
import { Observable, lastValueFrom } from 'rxjs';
import { AppService } from '../../app.service';
import { IConfigService } from '../../config/iconfigservice';
import { BaseService } from '../base.service';

@Injectable()
export class ConstantMapKeyService extends BaseService {

    publicApiBaseUrl: string;
    private _constantMapKeys: { [cacheKey: string]: Observable<[]> };
    filterableStaticListFeature = false;

    constructor(public appService: AppService, public configService: IConfigService, public httpClient: HttpClient, public featureManagerService: FeatureManagerService) {
        super(appService, configService, httpClient);
        this.publicApiBaseUrl = this.configService.getConfiguration().publicApiUrl;
        this.filterableStaticListFeature = this.featureManagerService.getByName(FeatureToggle.FilterableStaticMapkeys).enabled;
    }

    getConstantMapKeys(accountId, accountVersion, sectionId) {
        const cacheKey = `${accountId}_${accountVersion}_${sectionId}`;
        if (this._constantMapKeys[cacheKey]) {
            return this._constantMapKeys[cacheKey];
        }

        const clientId = this.appService.getClientId('');
        let url;
        if (this.filterableStaticListFeature) {
            url = `${this.publicApiBaseUrl}client/${clientId}/account/${accountId}/${accountVersion}/section/${sectionId}/constantmapkeys/valueswithvariants`;
        } else {
            url = `${this.publicApiBaseUrl}client/${clientId}/account/${accountId}/${accountVersion}/section/${sectionId}/constantmapkeys`;
        }

        this._constantMapKeys[cacheKey] = super.getDataForCache(url, clientId);
        return this._constantMapKeys[cacheKey];
    }

    clearConstantMapKeys() {
        this._constantMapKeys = {};
    }

    async getCachedConstantMapkeys(accountId, accountVersion, sectionId) {
        const cacheKey = `${accountId}_${accountVersion}_${sectionId}`;

        if (this._constantMapKeys[cacheKey]) {
            return lastValueFrom(this._constantMapKeys[cacheKey]);
        }

    }

}
