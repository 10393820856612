<div class="flex-fill d-flex flex-column overflow-y-auto">
  <div class="page-header title-margin">{{title}}</div>
  <form [formGroup]="userForm" style="min-height: 200px">

    <mat-horizontal-stepper linear #stepper labelPosition="right">
      <mat-step id="step1" label="Set up profile">
        <div class="step">
          <div style="padding-top: 8px">
            <mat-checkbox formControlName="sso" (change)="connectionTypeChange($event)"
              *ngIf="!selectedUser && showForm">
              Single Sign-On User</mat-checkbox>
            <div *ngIf="selectedUser && enterpriseUser">
              <div class="mb-1">
                <b>This user is set up using single sign-on.</b>
              </div>
              <div class="mb-4">
                Some user profile changes must be done through your organization’s enterprise connection system. Please
                contact your system administrator for further assistance.
              </div>
            </div>
            <mat-form-field class="full-width">
              <mat-label>First Name</mat-label>
              <input matInput formControlName='firstName'>
              <mat-error *ngIf="userForm.get('firstName').hasError('required')">
                <small>Required</small>
              </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width">
              <mat-label>Last Name</mat-label>
              <input matInput formControlName='lastName'>
              <mat-error *ngIf="userForm.get('lastName').hasError('required')">
                <small>Required</small>
              </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width">
              <mat-label>Email</mat-label>
              <input matInput formControlName='email'>
              <mat-error *ngIf="userForm.get('email').hasError('required')">
                <small>Required</small>
              </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width">
              <mat-label>Role</mat-label>
              <mat-select formControlName='userRoleId'>
                <mat-option *ngFor="let role of roles" [value]="role.id">
                  {{ role.roleName }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="userForm.get('userRoleId').hasError('required')">
                <small>Required</small>
              </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width" *ngIf="!selectedUser && !enterpriseUser">
              <mat-label>Password</mat-label>
              <input [type]="fieldTextType ? 'text' : 'password'" matInput formControlName='password'>
              <button matSuffix mat-icon-button aria-label="Show" (click)="toggleFieldTextType()">
                <mat-icon *ngIf="!fieldTextType" svgIcon="eye"></mat-icon>
                <mat-icon *ngIf="fieldTextType" svgIcon="eye-outline"></mat-icon>
              </button>
              <mat-error *ngIf="userForm.get('password').hasError('required')">
                <small>Required</small>
              </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width">
              <mat-label>InContact User Id</mat-label>
              <input matInput formControlName='agentId'>
            </mat-form-field>
          </div>
          <div></div>
          <div class="flex-center-bottom mt-4">
            <div class="flex-item-bottom">
              <button type="button" mat-button color="basic" class="material-default" style="color: #26333B !important"
                (click)="cancel()">Cancel</button>
              <button type="button" mat-button color="primary" class="material-default" matStepperNext>Continue</button>
            </div>
          </div>
        </div>
      </mat-step>
      <mat-step id="step2" label="Assign clients and accounts">
        <div class="step ">
          <mrs-treeview [data]="treeData" [selectedItems]="selectedUser?.clients"
            (selectionModel)="updateSelectedClients($event)"></mrs-treeview>
          <div class="flex-center-bottom mt-4">
            <div class="flex-item-bottom">
              <button type="button" mat-button color="basic" class="material-default" style="color: #26333B !important"
                (click)="cancel()">Cancel</button>
              <button type="button" mat-button color="basic" class="material-default" style="color: #26333B !important"
                matStepperPrevious>Previous</button>
              <button type="button" mat-button color="primary" class="material-default" (click)="saveUser()">
                Save Changes</button>
            </div>
          </div>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
  </form>
</div>