import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveEnd, Router } from '@angular/router';
import { AppInsights } from 'applicationinsights-js';
import * as moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { IConfigService } from './config/iconfigservice';
import { CustomEventLogMode, CustomEventType } from './enums';
import { RuleEvaluation } from './models';

@Injectable()
export class MonitoringService {

    private _metricQueue: { [key: string]: CustomMetric } = {};
    private _requestId = uuidv4();
    private _ruleExecutionLogs: RuleEvaluation[] = [];
    private _caseNumber: string;

    constructor(
        private router: Router,
        public configService: IConfigService
    ) { }

    getRequestId() {
        return this._requestId;
    }

    generateNewRequestId() {
        this._requestId = uuidv4();
    }

    setCaseNumber(caseNumber) {
        this._caseNumber = caseNumber;
    }

    setAuthenticatedUserId(userId: string): void {
        AppInsights.setAuthenticatedUserContext(userId);
    }

    private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {

        if (snapshot.firstChild) {
            return this.getActivatedComponent(snapshot.firstChild);
        }

        return snapshot.component;
    }

    private getRouteTemplate(snapshot: ActivatedRouteSnapshot): string {
        let path = '';
        if (snapshot.routeConfig) {
            path += snapshot.routeConfig.path;
        }

        if (snapshot.firstChild) {
            return path + this.getRouteTemplate(snapshot.firstChild);
        }

        return path;
    }

    private AddGlobalProperties(properties?: { [key: string]: string }): { [key: string]: string } {
        if (!properties) {
            properties = {};
        }

        if (!properties["caseNumber"]) {
            properties["caseNumber"] = this._caseNumber;
        }
        
        return properties;
    }

    public initLogging() {
        console.log('Monitor started...');
        const instrumentation = this.configService.getConfiguration();

        if (instrumentation.appInsights && instrumentation.appInsights.instrumentationKey) {
            AppInsights.downloadAndSetup(instrumentation.appInsights);
        }

        this.router.events.subscribe(
            e => {
                if (e instanceof ResolveEnd) {
                    const evt: ResolveEnd = e as ResolveEnd;
                    const activatedComponent = this.getActivatedComponent(evt.state.root);
                    if (activatedComponent) {
                        this.logPageView(`${activatedComponent.name} ${this.getRouteTemplate(evt.state.root)}`, evt.urlAfterRedirects);
                    }
                }
            });
    }

    public logPageView(
        name: string,
        url?: string,
        properties?: { [key: string]: string },
        measurements?: { [key: string]: number },
        duration?: number) {

        AppInsights.trackPageView(name, url, this.AddGlobalProperties(properties), measurements, duration);
    }

    public logCaseEvent(name: string, caseId: string, correlationId: string, eventType: CustomEventType, mode: CustomEventLogMode) {
        const eventLog = {
            'CaseId': caseId,
            'CorrelationId': correlationId,
            'EventType': eventType,
            'Mode': `${mode}`,
            'Name': name,
            'Timestamp': moment().utc().format()
        };

        this.logEvent(name, eventLog);
    }

    public logEvent(name: string, properties?: { [key: string]: string }, measurements?: { [key: string]: number }) {
        AppInsights.trackEvent(name, this.AddGlobalProperties(properties), measurements);
    }

    public logError(error: Error, properties?: { [key: string]: string }, measurements?: { [key: string]: number }) {
        let message: any;
        if (error.message) {
            message = error.message;
        } else {
            message = error;
        }
        AppInsights.trackException(message, null, this.AddGlobalProperties(properties), measurements);
    }

    public beginInterviewMetric(name: string, properties?: { [key: string]: string }): MetricEvent {
        const accountCode = this.getAccountCode();
        const metricKey = `${accountCode}-${name}`;
        return this.beginMetric(metricKey, properties);
    }

    public endInterviewMetric(metricEvent: MetricEvent) {
        this.endMetric(metricEvent);
    }

    public flushInterviewMetrics() {
        this.flushMetrics();
    }

    public beginMetric(name: string, properties?: { [key: string]: string }): MetricEvent {
        const metricKey = `${name}`;
        let metric = this._metricQueue[metricKey];

        if (!metric) {
            metric = {
                name: metricKey,
                properties,
                sum: 0,
                count: 0,
                min: 0,
                max: 0
            };

            this._metricQueue[metricKey] = metric;
        }

        const metricEvent = {
            name: metricKey,
            start: moment.utc(),
            end: moment.utc(),
        };

        return metricEvent;
    }

    public endMetric(metricEvent: MetricEvent) {

        const metric = this._metricQueue[metricEvent.name];

        if (!metric) {
            console.log(`no metric for ${name} exists to complete`);
            return;
        }

        const start = metricEvent.start;
        const end = moment.utc();
        const milliseconds = moment.duration(end.diff(start)).as('milliseconds');

        metric.count += 1;
        metric.sum += milliseconds;

        if (milliseconds > metric.max) {
            metric.max = milliseconds;
        }

        if (metric.min === 0 || milliseconds < metric.min) {
            metric.min = milliseconds;
        }
    }

    public flushMetric(key) {
        const metric = this._metricQueue[key];
        const avg = metric.sum / metric.count;

        AppInsights.trackMetric(metric.name, avg, metric.count, metric.min, metric.max, metric.properties);

        delete this._metricQueue[key];
    }

    public flushMetrics() {
        for (const key in this._metricQueue) {
            this.flushMetric(key);
        }
    }

    private getAccountCode() {
        let route = this.router.routerState.root;
        while (route.firstChild) {
            // iterate down to the bottom, we can only start from root.
            route = route.firstChild;
        }

        const routeData = route.snapshot.data;
        return routeData && routeData.account ? routeData.account.code : undefined;
    }

    public addRuleExecutionLog(ruleEvaluation: RuleEvaluation) {
        this._ruleExecutionLogs.push(ruleEvaluation);
    }

    public getRuleExecutionLogs(): RuleEvaluation[] {
        return this._ruleExecutionLogs;
    }

    public clearRuleExecutionLogs() {
        this._ruleExecutionLogs = [];
    }
}

export interface CustomEvent {
    name: string;
    properties: { [key: string]: string };
}

export interface MetricEvent {
    name: string;
    start: moment.Moment;
    end: moment.Moment;
}

export interface CustomMetric {
    name: string;
    properties: { [key: string]: string };
    count: number;
    sum: number;
    min: number;
    max: number;
}
