import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Note, PatchNoteRequest, UWBaseProps } from '@Underwriting/models';
import { UWWorkbenchService } from '@Underwriting/services';
import UWNotesFeatureToggleDataService from 'app/case-summary/underwriting/services/uw-notes-feature-toggle/uw-notes-feature-toggle.data.service';
import { SubSink } from 'subsink';


@Component({
    selector: 'uw-notes-list',
    templateUrl: './uw-notes-list.component.html',
    styleUrls: ['./uw-notes-list.component.scss']
})
export default class UWNotesList implements OnInit, OnDestroy {
    @Input() baseProps: UWBaseProps;
    @Input() notes: Note[];
    @Input() showRequirementHandle: boolean;

    isHiddenNoteFeatureEnabled = false;
    isHiddenNoteAccountSettingEnabled = false;

    subs = new SubSink();

    constructor(
        private underWriterWorkbenchService: UWWorkbenchService,
        private uwNotesFeatureToggle: UWNotesFeatureToggleDataService,
    ) { }

    //#region Helpers

    createPatchRequest(noteId: string, isInternalNote: boolean): PatchNoteRequest {
        return {
            "noteId": noteId,
            "isInternalNote": isInternalNote
        };
    }

    toggleNoteVisibility(noteId: string) {

        const note = this.notes.filter(x => x.id === noteId);
        if (note.length !== 1)
            return;

        if (note[0].isInternalNote)
            note[0].isInternalNote = false;
        else
            note[0].isInternalNote = true;

        const request = this.createPatchRequest(note[0].id, note[0].isInternalNote);

        this.subs.add(this.underWriterWorkbenchService.patchNote(this.baseProps.account.id, request).subscribe());
    }

    //#endregion
    //#region Lifecycle

    ngOnInit() {

        this.subs.add(this.uwNotesFeatureToggle.loadHiddenNotesFeatureSetting(this.baseProps.account).subscribe((hiddenNoteFeatureSetting) => {
            this.isHiddenNoteFeatureEnabled = hiddenNoteFeatureSetting.featureToggle;
            this.isHiddenNoteAccountSettingEnabled = hiddenNoteFeatureSetting.accountSetting;
        }));

    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    //#endregion
}
