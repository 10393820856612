import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { AppService } from '../app.service';
import { Client } from '../models';

@Injectable()
export class ClientDataService {
    private baseUrl: string;
    private _cachedClients: Observable<Client[]>;

    constructor(
        private appService: AppService,
        private httpClient: HttpClient
    ) {
        this.baseUrl = this.appService.getAPIBaseURL();
    }

    getClients(cache?: boolean): Observable<Client[]> {
        if (!cache || (cache && this._cachedClients == null)) {
            this._cachedClients = this.fetchClients()
                .pipe(shareReplay(1));
        }

        return this._cachedClients;
    }

    fetchClients(): Observable<Client[]> {
        return this.appService.getData('Clients')
            .pipe(map(result => result.data));
    }
}
