<div class="py-2 px-4 bg-light border-bottom documents-header">
  <span class="float-left property-box-title">Billing Events</span>
  <mat-slide-toggle class="d-inline-block float-right pr-1" [checked]='!case?.caseNonBillable'
    (change)="changeBillable($event.checked)">Billable</mat-slide-toggle>
</div>
<div class="documents-body p-4">
  <p-table [value]="caseBillings" *ngIf="caseBillings.length; else noCaseBillingsPlaceholder">
    <ng-template pTemplate="header">
      <tr>
        <th [width]="250" id="eventName">Event Name</th>
        <th [width]="100" id="amount">Amount</th>
        <th [width]="150" [pSortableColumn]="'creationDate'" id="createdOn">Created On
          <p-sortIcon [field]="'creationDate'"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-caseBilling>
      <tr styleClass="ptable-tr">
        <td class="ptable-td">{{caseBilling.billingEventName}}</td>
        <td class="ptable-td text-right">{{caseBilling.amount | currency}}</td>
        <td class="ptable-td">{{caseBilling.creationDate | date: 'MM/dd/yyyy HH:mm:ss': timeZone}}</td>
      </tr>
    </ng-template>
    <ng-template pTemplate="footer">
      <tr>
        <td>Total</td>
        <td class="text-right">{{getTotalAmount(caseBillings) | currency}}</td>
        <td></td>
      </tr>
    </ng-template>
  </p-table>
  <ng-template #noCaseBillingsPlaceholder>
    <div class="p-4 text-center text-muted">
      <img src="./assets/images/no-data-placeholder.png" alt="" class="pb-3" />
      <h6>No Case Billings</h6>
    </div>
  </ng-template>
</div>
