<div *ngIf="editContactForm">
    <mat-card appearance="outlined" class="py-3 mb-3 px-4">
        <mat-card-content>
            <form [formGroup]="contactForm" (ngSubmit)="saveContact()" novalidate>
                <div class="row">
                    <input type="hidden" formControlName="id">
                    <input type="hidden" formControlName="clientId">
                    <div class="col-4">
                        <mat-form-field class="w-100">
                            <input matInput placeholder="First Name" formControlName="firstName">
                            <mat-error *ngIf="contactForm.get('firstName').hasError('required')">
                                First name
                                <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-4">
                        <mat-form-field class="w-100">
                            <input matInput placeholder="Last Name" formControlName="lastName">
                            <mat-error *ngIf="contactForm.get('lastName').hasError('required')">
                                Last name
                                <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-4">
                        <mat-form-field class="w-100">
                            <input matInput placeholder="Phone" formControlName="phone">
                            <mat-error *ngIf="contactForm.get('phone').hasError('required')">
                                Phone number
                                <strong>required</strong>
                            </mat-error>
                            <mat-hint align="start"> 234-234-5678</mat-hint>
                            <mat-error *ngIf="contactForm.get('phone').hasError('pattern')">
                                Please enter valid phone number
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-4">
                        <mat-form-field class="w-100">
                            <input type="email" matInput placeholder="Email" formControlName="email">
                            <mat-error *ngIf="contactForm.get('email').hasError('required')">
                                Email
                                <strong>required</strong>
                            </mat-error>
                            <mat-error *ngIf="contactForm.get('email').hasError('pattern')">
                                Please enter valid email
                                <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-4">
                        <section class="w-100 pt-3">
                            <mat-checkbox formControlName="isPrimary">Is Primary</mat-checkbox>
                        </section>
                    </div>
                    <div class="col-4 text-right pr-4">
                        <button *ngIf="checkACL('W')" type="submit" mat-raised-button color="primary" class="mr-1">
                            <i aria-hidden="true" class="fa fa-cloud"></i> Save
                        </button>
                        <button mat-button color="basic" (click)='cancel()'>
                            <i aria-hidden="true" class="fa fa-times"></i> Cancel
                        </button>
                    </div>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</div>
<div *ngIf="editAccountForm">
    <mat-card appearance="outlined" class="py-3 mb-3 px-4">
        <mat-card-content>
            <form [formGroup]="addContactForm" (ngSubmit)="updateAccountContact()" novalidate>
                <div class="row">
                    <div class="col-6">
                        <mat-form-field class="w-100">
                            <input matInput placeholder="Contacts" formControlName="contactsId">
                        </mat-form-field>
                    </div>
                    <div class="col-6">
                        <mat-form-field class="w-100">
                            <mat-select placeholder="Contact Type" formControlName="contactTypeId">
                                <mat-option *ngFor="let type of contactTypeCombo" [value]="type.id">
                                    {{ type.name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="addContactForm.get('contactTypeId').hasError('required')">
                                Contact Type
                                <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-12 text-right">
                    <button *ngIf="checkACL('W')" mat-raised-button color="primary" type="submit">
                        <i aria-hidden="true" class="fa fa-plus-circle"></i> Save
                    </button>
                    <button mat-button color="basic" (click)='cancel()'>
                        <i aria-hidden="true" class="fa fa-times"></i> Cancel
                    </button>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</div>
<div *ngIf="showDetails">
    <mat-card appearance="outlined" class="py-3 mb-2 px-4 item">
        <mat-card-content>
            <div class="row">
                <div class="col-8 my-auto">
                    <div class="title">{{data.firstName}} {{data.lastName}}
                        <span *ngIf="data.isPrimary && !data.contactTypeId">(primary)</span>
                        <span *ngIf="data.contactTypeValue != '' && data.contactTypeValue">({{data.contactTypeValue}})</span>
                    </div>
                    <div class="my-1 content-details">
                        <i aria-hidden="true" class="fa fa-envelope pr-2" aria-hidden="true"></i>
                        <a href="mailto:{{data.email}}">{{data.email}}</a>
                    </div>
                    <div class="my-1 content-details">
                        <i aria-hidden="true" class="fa fa-phone pr-2" aria-hidden="true"></i>
                        <a href="tel:{{data.phone}}">{{data.phone}}</a>
                    </div>
                </div>
                <div class="col-4 text-right my-auto">
                    <button mat-icon-button class="text-warning" *ngIf="checkACL('W')" (click)="editContact(data)">
                        <i aria-hidden="true" class="fa fa-edit"></i>
                    </button>
                    <button mat-icon-button class="text-danger" *ngIf="data.contactTypeId && checkACL('F')" (click)="deleteContact(data)">
                        <i aria-hidden="true" class="fa fa-trash"></i>
                    </button>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>
