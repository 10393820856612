<ng-template #discardDialog>
  <div class="modal-dark-background" *ngIf="!cancelledCase">
    <div mat-dialog-content>
      <div *ngIf="summaryMessage !=''" [innerHtml]="summaryMessage | safeHtml"></div>
      <div *ngIf="summaryMessage ===''">
        <h1 mat-dialog-title>
          <div class="flex flex-column center-bold-dialog-title">
            <mat-icon svgIcon="check-circle-outline" style="height: 100px; width: 100px; color: white" class="mt-4"></mat-icon>
          </div>
        </h1>
        <div class="content flex flex-column" style="align-items: center; justify-content: center;">
          <div mat-dialog-title style="text-align: center; color: white" class="m-4">Your application was received.</div>
          <div style="text-align: center;font-size: 16px; " class="m-4">
            Someone will follow up on your application status and next steps shortly.
          </div>
        </div>
      </div>
    </div>
  </div>
    <div class="modal-dark-background" *ngIf="cancelledCase">
      <h1 mat-dialog-title>
        <div class="flex flex-column center-bold-dialog-title">
          <mat-icon svgIcon="note-outline" style="height: 100px; width: 100px; color: white" class="mt-4"></mat-icon>
        </div>
      </h1>
      <div mat-dialog-content>
        <div class="content flex flex-column" style="align-items: center; justify-content: center;">
          <div style="text-align: center;font-size: 27px;  font-weight:700" class="m-4">Your application has been saved.</div>
          <div style="text-align: center;font-size: 16px; " class="m-4">
            You may return later to complete your application.
          </div>
        </div>
      </div>
    </div>
</ng-template>
