import { AppService } from './../../app.service';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { RoutingService, RoutesEnum, ClientDataService } from '../';
import { Client } from '../../models';
import { lastValueFrom, Observable } from 'rxjs';

@Injectable()
export class ClientResolverService implements Resolve<Client> {
    constructor(
        private appService: AppService,
        private clientDataService: ClientDataService,
        private routingService: RoutingService) {
    }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Client> {
        return new Promise(async (resolve, reject) => {
            const clientCode = route.params.clientCode;
            const clients = await lastValueFrom(this.clientDataService.getClients(true));

            const target = clients.find(client => client.code == clientCode);

            if (target == null) {
                this.appService.showMsg('error', 'Cannot find the requested client.');
                this.routingService.navigateToDefault();
                reject();
            } else {
                resolve(target);
            }
        });
    }
}
