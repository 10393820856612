import { Injectable } from '@angular/core';
import * as _ from 'lodash';

import { AppService } from 'app/app.service';
import { QueueStatusEnum } from 'app/enums';
import { NotificationSeverity } from 'app/services';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { InterviewService } from 'app/interview.service';
import { TrustCommerceComponent } from 'app/components/trustcommerce/trustcommerce.component';
import { IConfigService } from 'app/config/iconfigservice';

@Injectable()
export class TrustCommerceIntegrationResponseHandler{

    tcDialog: MatDialogRef<TrustCommerceComponent>;

    constructor(
        public appService: AppService,
        public interviewService: InterviewService,
        public dialog: MatDialog,
        public configService: IConfigService
    ) {}

    handleResponse(clientId: string, accountId: string,integrationResponse, integrationData){
        if (integrationResponse.status === QueueStatusEnum.Completed) {
            const trustCommerceUrl = _.get(integrationData, 'details.clientProperties.url');
            const callbackUrl =  _.get(integrationData, 'details.clientProperties.callBackUrl');

            if (trustCommerceUrl != null) {
                this.showTrustCommerceDialog(clientId, accountId, integrationData.caseDetailId, integrationData.caseIntegrationQueueId, trustCommerceUrl, callbackUrl);
            }
        }else if (integrationResponse.status === QueueStatusEnum.Failed) {
            this.appService.showMsg(NotificationSeverity.Error, integrationResponse.errorMessage);
        }
    }

    showTrustCommerceDialog(clientId: string, accountId: string, caseId: string, caseIntegrationQueueId: string, trustCommerceUrl: string, callbackUrl: string) {
        const baseUrl = this.configService.getConfiguration().webUrl;
        
        trustCommerceUrl = trustCommerceUrl.replace(baseUrl, window.location.origin);
        
        this.tcDialog = this.dialog.open(TrustCommerceComponent, {
            width: '50%',
            disableClose: true,
            data: {
                clientId: clientId,
                caseId: caseId,
                caseIntegrationQueueId: caseIntegrationQueueId,
                trustCommerceUrl: trustCommerceUrl,
                callbackUrl: callbackUrl,
                accountId: accountId
            }
        });
    }
}
