<div class="right-panel flex-fill d-flex flex-column">
    <div class="right-panel-body flex-fill overflow-y-auto custom-scrollbar p-3">
        <div class="row">
            <div class="col-6 mx-auto">
                <mat-card appearance="outlined">
                    <mat-card-header>
                        <mat-card-title-group>
                            <mat-card-title>
                                <h2>Billing Details</h2>
                            </mat-card-title>
                            <button mat-button color="basic" class="float-right" *ngIf="!isEditMode && checkACL('W')"
                                (click)="editBilling()">
                                <i aria-hidden="true" class="fa fa-edit pr-1"></i>Edit
                            </button>
                        </mat-card-title-group>
                    </mat-card-header>
                    <mat-card-content>
                        <div class="d-flex py-3 border-bottom bg-light">
                            <div class="col-5">
                                <strong>Billing Event</strong>
                            </div>
                            <div class="col-2">
                                <strong>Amount</strong>
                            </div>
                            <div class="col-4">
                                <strong>Billing Type</strong>
                            </div>
                            <div class="col-1"></div>
                        </div>
                        <div *ngIf="isEditMode && checkACL('W')">
                            <form [formGroup]="billingForm" (ngSubmit)="saveAccountBillingEvents()" novalidate
                                autocomplete="off">
                                <input type="hidden" formControlName='accountId'>
                                <input type="hidden" formControlName='clientId'>
                                <div formArrayName="billingDetails"
                                    *ngFor="let control of billingForm.get('billingDetails')['controls']; let i = index">
                                    <div [formGroupName]="i" class="d-flex border-bottom">
                                        <div class="col-5">
                                            <mat-form-field class="w-100">
                                                <input matInput formControlName="name" required />
                                                <mat-error
                                                    *ngIf="billingForm.get('billingDetails.' + i + '.name').hasError('required')">
                                                    <small>Required</small>
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-2">
                                            <mat-form-field class="w-100">
                                                <span matPrefix>$ &nbsp;</span>
                                                <input matInput formControlName="amount" type="number">
                                                <mat-error
                                                    *ngIf="billingForm.get('billingDetails.' + i + '.amount').hasError('required')">
                                                    <small>Required</small>
                                                </mat-error>
                                                <mat-error
                                                    *ngIf="billingForm.get('billingDetails.' + i + '.amount').hasError('pattern')">
                                                    <small>Only digits allowed</small>
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-4">
                                            <mat-form-field class="w-100">
                                                <mat-select formControlName="billingEventTypeId">
                                                    <mat-option *ngFor="let billingEventType of globalBillingEventTypes"
                                                        [value]="billingEventType.id">{{billingEventType.name}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-1 flex flex-center">
                                            <mat-icon svgIcon="delete" aria-label="Delete Billing Event" style="margin-bottom: 10px"
                                                (click)="removeBillingEvent(i, control)" class="mat-icon-gray"
                                                *ngIf="!control.value?.usageList?.length"></mat-icon>
                                            <span *ngIf="control.value?.usageList?.length > 0" class="in-use-label"
                                                (click)="viewAccountBillingEventUse(control)">IN USE</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="pt-4 flex" *ngIf="isEditMode && checkACL('W')">
                                    <mrs-btn-primary buttonLabel="Save" buttonId="billingSave"
                                        isSubmitButton="true"></mrs-btn-primary>
                                    <mrs-btn-tertiary buttonLabel="Cancel" buttonId="cancelButton"
                                        (onClick)="isEditMode = false">Cancel</mrs-btn-tertiary>
                                    <div class="flex-1"></div>
                                    <button mat-button color="basic" (click)="addBillingEvent()">
                                        <i aria-hidden="true" class="fa fa-plus"></i> Add
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div *ngIf="!isEditMode && accountBillingEvents?.length && checkACL('R')">
                            <div *ngFor="let event of accountBillingEvents">
                                <div class="d-flex py-3 border-bottom">
                                    <div class="col-5">
                                        {{event.name}}
                                    </div>
                                    <div class="col-2">
                                        {{event.amount | currency:'USD':'symbol':'1.2-2'}}
                                    </div>
                                    <div class="col-5">
                                        {{getBillingEventTypeName(event.billingEventTypeId)}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="!isEditMode && accountBillingEvents?.length === 0">
                            <div class="text-center py-3">You do not have any billing events configured.</div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
</div>