import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot, CanActivateChild,  ActivatedRoute} from '@angular/router';
import { AppService } from '../../app.service';
import { RolesEnum } from '../../enums';
import { AuthLegacyService } from '../../auth/auth-legacy.service';
import { IConfigService } from '../../config/iconfigservice';

@Injectable()
export class RoleGuard implements CanActivateChild {

    constructor(
    private appService: AppService,
    private authService: AuthLegacyService,
    public route : ActivatedRoute,
    public configService: IConfigService
    ) { }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const currentUserRole = this.appService.getUserRoleName();
        const canActivate = this.handleRole(currentUserRole);
        const allowedLogoutRoutes: string = this.configService.getAllowedLogoutRoutes();

        if (!canActivate) {
            const routePlusQueryString = window.location.pathname + window.location.search;
            if (routePlusQueryString.includes("/?") || allowedLogoutRoutes?.includes(window.location.pathname)) {
                this.authService.logout(true, routePlusQueryString);
            }
            else {
                this.authService.logout();
            }
            this.appService.showResponseErrorMsg({
                error: {
                    sourceError: `Unauthorized. ${currentUserRole} is not allowed to view this content.`,
                }
            });
        }

        return canActivate;
    }

    handleRole(roleName: string): boolean {
        let canActivateRoute;

        switch (roleName) {
        case RolesEnum.Applicant:
            canActivateRoute = false;
            break;

        case RolesEnum.Agent:
        case RolesEnum.ClientCSR:
        case RolesEnum.ClientSupervisor:
        default:
            canActivateRoute = true;
            break;
        }

        return canActivateRoute;
    }

}
