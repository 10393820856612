import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from '@App';
import { Account, BillingEventUsage } from '@Models';
import * as _ from 'lodash';
import { lastValueFrom, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';



@Injectable()
export class AccountDataService {
    private baseUrl: string;
    private _cachedAccounts: Observable<Account[]>;

    public activeAccount: Account;

    constructor(
        private appService: AppService,
        private httpClient: HttpClient
    ) {
        this.baseUrl = this.appService.getAPIBaseURL();
    }

    getAccounts(cache?: boolean): Observable<Account[]> {
        if (!cache || (cache && this._cachedAccounts == null)) {
            this._cachedAccounts = this.fetchAccounts()
                .pipe(shareReplay(1));
        }

        return this._cachedAccounts;
    }

    clearCache() {
        this._cachedAccounts = null;
    }

    fetchAccounts(): Observable<Account[]> {
        return this.appService.getData('Accounts')
            .pipe(
                map(result => result.data.map(
                    accountEntity => this.mapAccount(accountEntity))
                )
            );
    }

    getAccount(accountId: string): Observable<Account> {
        return this.appService.getData(`Accounts/${accountId}`)
            .pipe(
                map(result => this.mapAccount(result.data))
            );
    }

    saveAccount(account: Account, isNew: boolean): Observable<Account> {
        const url = isNew ?
            `Accounts/Accounts`
            : `Accounts`;

        return this.appService.postData(url, account, !isNew)
            .pipe(
                map(result => {
                    if (result.status === 'success') {
                        this.clearCache();
                        return result.data;
                    } else {
                        this.appService.showMsg('error', result.message);
                    }
                })
            );
    }

    addAccountContact(accountId: string, contactId: string, contactTypeId: string): Observable<Account> {
        return this.appService.postData('Accounts/AccountContacts', {
            accountId,
            contactId,
            contactTypeId
        }).pipe(
            map(result => {
                if (result.status === 'success') {
                    return result.data;
                } else {
                    this.appService.showMsg('error', result.message);
                }
            })
        );
    }

    getAccountBillingEvents(accountId: string): Promise<any> {
        return lastValueFrom(this.appService.getData(`AcctBilling/${accountId}`)
            .pipe(
                map(result => result.data)
            ));
    }

    getAccountBillingEventUsage(accountId: string): Promise<BillingEventUsage[]> {
        return lastValueFrom(this.appService.getData(`AcctBilling/${accountId}/usage`)
            .pipe(
                map(result => result.data)
            ));
    }

    private mapAccount(accountEntity) {
        return {
            ...accountEntity,
            workflowId: _.get(accountEntity.workFlows, '[0].id')
        };
    }
}
