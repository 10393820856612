import { FeatureToggle } from "@Enums";
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TableColumnDefinition } from "app/shared/components/mrs-table/models/table-models";
import { AppService } from '../../app.service';
import { Account, AccountsByClient } from '../../models';
import { CaseSummaryService, FeatureManagerService, RoutesEnum, RoutingService } from '../../services';
import { ReportDownloadService } from '../../services/reportdownload.service';
import moment = require('moment');

@Component({
    selector: 'transmission-report',
    host: { 'class': 'd-flex flex-row flex-fill custom-scrollbar' },
    templateUrl: './transmission-report.component.html',
    styleUrls: ['./transmission-report.component.scss']
})
export class TransmissionReportComponent implements OnInit {

    public accounts: Account[] = [];
    public activeAccount: Account;
    public clients: AccountsByClient;

    public caseStatus: any;
    public transmissionForm: UntypedFormGroup;
    public filteredData: any = [];
    public noDataPlaceholder = false;
    public selectedRecord: any;
    public sumaryInfo: any;
    tableConfig: any = [];
    @Input() scrollWidth;
    @Input() scrollHeight;
    timeZone: string;
    displayedColumns: TableColumnDefinition[];
    reportData: any;
    searchableFields: [];
    clientAccountOverride = false;

    constructor(
        private appService: AppService,
        private reportDownloadService: ReportDownloadService,
        private _fb: UntypedFormBuilder,
        private routingService: RoutingService,
        private caseSummaryService: CaseSummaryService,
        private featureManagerService: FeatureManagerService
    ) {
        this.clientAccountOverride = this.featureManagerService.getByName(FeatureToggle.GlobalReportsTransmissionReportClientAccountSplit).enabled;
        this.timeZone = this.appService.getTimeZone();
    }

    ngOnInit() {
        this.transmissionForm = this._fb.group({
            account: ['', Validators.required],
            startDate: ['', Validators.required],
            endDate: ['', Validators.required]
        });
        this.tableConfig = this.getTableConfig();

    }

    getSelectedAccount(): Account {
        return this.transmissionForm.get('account').value;
    }

    getReport() {
        if (this.transmissionForm.valid) {
            this.filteredData = [];
            const params = this.getParams();
            const url = `Reports/GetTransmissionReport`;

            this.appService.postData(url, params, false, false, this.getSelectedAccount().clientId)
                .subscribe(
                    data => {
                        if (data.status === 'success') {
                            this.sumaryInfo = data.data;
                            this.activeAccount = this.getSelectedAccount();
                            this.filteredData = data.data.transmissionReports;

                            this.getTableConfig();
                            this.reportData = data.data.transmissionReports.map((mappingData) => ({
                                ...mappingData,
                                doneDate: mappingData.doneDate ? moment(mappingData.doneDate).utc().local().format('MM/DD/yyyy') : null,
                                dateSent: mappingData.dateSent ? moment(mappingData.dateSent).utc().local().format('MM/DD/yyyy HH:mm:ss') : null,
                                dateReceived: mappingData.dateReceived ? moment(mappingData.dateReceived).utc().local().format('MM/DD/yyyy HH:mm:ss') : null,
                                dateProcessed: mappingData.dateProcessed ? moment(mappingData.dateProcessed).utc().local().format('MM/DD/yyyy HH:mm:ss') : null,
                                caseUrl: this.getCaseUrl(mappingData)
                            }));
                        }

                        this.noDataPlaceholder = !this.filteredData.length;
                    }
                );
        }
    }

    downloadReport() {
        const params = this.getParams();

        this.reportDownloadService.downloadReport('application/csv', 'Reports/DownloadCSV/GetTransmissionReport', params, this.getSelectedAccount().clientId);
    }

    getTableConfig() {
        this.displayedColumns = [
            {
                def: 'accountVersion',
                header: 'Account Version',
                sortFields: ['accountVersion'],
                columnStyles:
                {
                    'width': '200px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'accountVersion',
                    }
                ]
            },
            {
                def: 'caseNumber',
                header: 'MRS Order ID',
                sortFields: ['caseNumber'],
                columnStyles:
                {
                    'width': '150px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'caseNumber',
                        isLink: true,
                        urlField: 'caseUrl'
                    }
                ]
            },
            {
                def: 'policyNumber',
                header: 'Certificate #',
                sortFields: ['policyNumber'],
                columnStyles:
                {
                    'width': '150px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'policyNumber',
                    }
                ]
            },
            {
                def: 'doneDate',
                header: 'MRS Order Done',
                sortFields: ['doneDate'],
                columnStyles:
                {
                    'width': '120px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'doneDate',
                    }
                ]
            },
            {
                def: 'dateSent',
                header: 'Date Sent',
                sortFields: ['dateSent'],
                columnStyles:
                {
                    'width': '120px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'dateSent',
                    }
                ]
            },
            {
                def: 'dateReceived',
                header: 'Date Received',
                sortFields: ['dateReceived'],
                columnStyles:
                {
                    'width': '120px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'dateReceived',
                    }
                ]
            },
            {
                def: 'dateProcessed',
                header: 'Date Processed',
                sortFields: ['dateProcessed'],
                columnStyles:
                {
                    'width': '120px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'dateProcessed',
                    }
                ]
            },
            {
                def: 'message',
                header: 'Message',
                sortFields: ['message'],
                columnStyles:
                {
                    'width': '200px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'message',
                    }
                ]
            },
            {
                def: 'actions',
                header: '',
                columnStyles:
                {
                    'width': '25px'
                },
                isAction: true,
                actions: [
                    {
                        name: '',
                        icon: 'open_in_new',
                        actionType: 'openInNewTab',
                        permission: true,
                    }
                ]
            }
        ];

    }

    getParams() {
        const params = JSON.parse(JSON.stringify(this.transmissionForm.value));
        params.accountId = params.account.id;
        params.startDate = new Date(params.startDate);
        const end = new Date(params.endDate);
        end.setHours(23, 59, 59, 999);
        params.endDate = end;
        return params;
    }

    getCaseUrl(data) {
        const clientCode = this.activeAccount.code.split('-');

        return this.routingService.createUrl(RoutesEnum.caseSummary, {
            clientCode: clientCode[0],
            accountCode: this.activeAccount.code,
            versionNumber: data.versionNumber,
            caseId: data.caseId
        });
    }

    tableAction(event) {
        if (event.action?.actionType === 'openInNewTab') {
            const clientCode = this.activeAccount.code.split('-');

            this.caseSummaryService.routeToCase({
                clientCode: clientCode[0],
                accountCode: this.activeAccount.code,
                accountVersionNumber: event.record.versionNumber,
                caseId: event.record.caseId
            }, true);
        }
    }
}
