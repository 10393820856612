<div [formGroup]="conditionSetForm" class="conditionset-container" [ngClass]="{'sub-rule': isSubRule}">
    <div class="conditionset-header" [ngClass]="{'rule-success': (testResult?.hasConditionSetResult === true || testResult?.conditionSetResult === true) && isTestExecuted, 'rule-fail': (testResult?.hasConditionSetResult === false || testResult?.conditionSetResult === false) && isTestExecuted}">
        <div class="col4">
            <button type="button" class="btn btn-link btn-sm custom" (click)="expanded = !expanded">
                <i aria-hidden="true" class="fa" [ngClass]="{'fa-angle-right': !expanded, 'fa-angle-down': expanded}"></i>
            </button>

            <span class="d-inline-block">
                <mat-radio-group formControlName="filterId" [disabled]="!isEditView">
                    <mat-radio-button value="00000000-0000-0000-0000-000000000001" [disabled]="!isEditView">All</mat-radio-button>
                    <mat-radio-button value="00000000-0000-0000-0000-000000000002" [disabled]="!isEditView">Any</mat-radio-button>
                    <mat-radio-button value="00000000-0000-0000-0000-000000000003" [disabled]="!isEditView">None</mat-radio-button>
                    </mat-radio-group>
                <i aria-hidden="true" class="fa" [ngClass]="{'fa-check-circle text-success': (testResult?.hasConditionSetResult === true || testResult?.conditionSetResult === true) && isTestExecuted, 'fa-times-circle text-danger': (testResult?.hasConditionSetResult === false || testResult?.conditionSetResult === false) && isTestExecuted}"></i>
            </span>
            <span class="d-inline-block">
                <mat-error *ngIf="formSubmitted && conditionSetForm.get('filterId').hasError('required')">
                    required
                </mat-error>
            </span>

            <span *ngIf="!isSubRule" class="float-right">
                <button type="button" *ngIf="checkACL('W') && isEditView" class="btn btn-link btn-sm custom" (click)="addCondition()">
                    <i aria-hidden="true" class="fa fa-plus"></i> Add Condition
                </button>
                <button type="button" *ngIf="checkACL('W') && isEditView" class="btn btn-link btn-sm custom" (click)="delete(conditionSetForm.get('id'))">
                    <i aria-hidden="true" class="far fa-trash-alt"></i> Delete
                </button>
            </span>
            <!-- <button type="button" class="btn btn-link btn-sm custom" (click)="expanded = !expanded">
                <i aria-hidden="true" class="fa fa-chevron-down"></i>
            </button> -->
        </div>
    </div>
    <div class="conditionset-body" *ngIf="expanded">
        <div class="condition-container header row m-0 border-bottom bg-light" *ngIf="!isEditView">
            <div class="col-4 text-uppercase text-muted">Mapkey</div>
            <div class="col-2 text-uppercase text-muted">Condition</div>
            <div class="col-2 text-uppercase text-muted">Value Type</div>
            <div class="col-3 text-uppercase text-muted">Value</div>
            <div *ngIf="isTestExecuted" class="col-1 text-uppercase text-muted">Result</div>
        </div>

        <condition
            *ngFor="let condition of conditionSetForm.get('conditions')['controls']; let i = index;"
            [config]="condition"
            [isSubRule]="isSubRule"
            [isEditView]="isEditView"
            [mapKeys]="mapKeys"
            [nonListNonVirtualMapKeys]="nonListNonVirtualMapKeys"
            [variableMapKeys]="variableMapKeys"
            [nonVirtualMapKeys]="nonVirtualMapKeys"
            [formSubmitted]="formSubmitted"
            (deleteCondition)="deleteCondition(i)"
            [comboData]="comboData"
            [rules]="rules"
            [testResult]="getConditionTestResult(i)"
            [isTestExecuted]="isTestExecuted"
        ></condition>
        <ng-container *ngIf="!conditionSetForm.get('conditions').length">
            <div class="text-center" [ngClass]="{'text-danger': formSubmitted, 'text-muted': !formSubmitted}">Add a Condition</div>
        </ng-container>
    </div>
</div>
