import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from '@App';
import { IConfigService } from '@App/config/iconfigservice';
import { AccountStatusEnum } from '@Enums';
import { Account, AccountExport, AccountImport, AccountMinimal } from '@Models';
import { AccountDataService, BaseService } from '@Services';
import { lastValueFrom, Observable } from 'rxjs';

// AcountCoordinator.cs
export type ActivateAccountResponse = {
    success: boolean;
    message: string;
}

@Injectable()
export class AccountService extends BaseService {

    protected publicApiBaseUrl: string;

    constructor(
        public appService: AppService,
        public configService: IConfigService,
        public httpClient: HttpClient,
        private accountDataService: AccountDataService
    ) {
        super(appService, configService, httpClient);

        this.publicApiBaseUrl = this.configService.getConfiguration().publicApiUrl;
    }

    // ClientController.cs
    getAccountVersions(clientId: string): Observable<Account[]> {
        const url = `${this.publicApiBaseUrl}client/${clientId}/account`;
        return super.getData<Account[]>(url, clientId);
    }

    // ClientController.cs
    getAccount(clientId: string, accountCode: string, versionNumber: number | string): Observable<Account> {
        const accountParts = accountCode.split('-');
        if (accountParts.length === 2) accountCode = accountParts[1];

        const url = `${this.publicApiBaseUrl}client/${clientId}/account/${accountCode}/${versionNumber}/version`;

        return super.getData<Account>(url, clientId);
    }

    // AccountController.cs
    getActiveAccount(clientId: string, accountCode: string): Observable<Account> {
        const accountParts = accountCode.split('-');
        if (accountParts.length === 2) accountCode = accountParts[1];

        const url = `${this.publicApiBaseUrl}client/${clientId}/account/${accountCode}`;

        return super.getData<Account>(url, clientId);
    }

    // ClientController.cs
    getActiveAccountsBetweenRange(clientId: string, startDate: string, endDate: string): Observable<AccountMinimal[]> {
        const options = {
            params: new HttpParams().set('startDate', startDate).set('endDate', endDate),
        };
        const hideWaiting = false;
        const url = `${this.publicApiBaseUrl}client/${clientId}/account/active-between`;

        return super.getData<AccountMinimal[]>(url, clientId, hideWaiting, options);
    }

    // AccountController.ImportExport.Partial.cs
    createAccountVersion(clientId: string, accountCode: string, versionNumber: number | string, versionLabel: string): Observable<AccountImport> {
        const url = `${this.publicApiBaseUrl}client/${clientId}/account/${accountCode}/${versionNumber}/version`;
        return super.postData<AccountImport>(url, { versionLabel }, clientId);
    }

    // AccountController.ImportExport.Partial.cs
    getVersionStatus(clientId: string, accountCode: string, versionNumber: number | string): Observable<AccountImport> {
        const url = `${this.publicApiBaseUrl}client/${clientId}/account/${accountCode}/${versionNumber}/import/status`;
        return super.getData<AccountImport>(url, clientId, true);
    }

    // AccountController.cs
    setAccountActive(clientId: string, account: Account, copySettings): Observable<ActivateAccountResponse> {
        const url = `${this.publicApiBaseUrl}client/${clientId}/account/${account.id}/${account.versionNumber}/activate`;
        return super.patchData<ActivateAccountResponse>(url, copySettings, clientId);
    }

    // AccountController.ImportExport.Partial.cs
    restartAccountImport(clientId: string, account: Account, accountImportTaskId: string): Observable<AccountImport> {
        const url = `${this.publicApiBaseUrl}client/${clientId}/account/${account.id}/${account.versionNumber}/import/restart/${accountImportTaskId}`;
        return super.putData<AccountImport>(url, null, clientId);
    }

    // AccountController.cs
    setAccountRemoved(clientId: string, account: Account): Observable<ActivateAccountResponse> {
        const url = `${this.publicApiBaseUrl}client/${clientId}/account/${account.id}/${account.versionNumber}/remove`;
        return super.patchData<ActivateAccountResponse>(url, null, clientId);
    }

    // AccountController.ImportExport.Partial.cs
    exportVersion(clientId: string, accountCode: string, versionNumber: number | string): Observable<AccountExport> {
        const url = `${this.publicApiBaseUrl}client/${clientId}/account/${accountCode}/${versionNumber}/export`;
        return super.getData<AccountExport>(url, clientId, false);
    }

    // AccountController.ImportExport.Partial.cs
    importVersion(clientId: string, accountCode: string, importData: any): Observable<AccountImport> {
        const url = `${this.publicApiBaseUrl}client/${clientId}/account/${accountCode}/version`;
        return super.postData<AccountImport>(url, importData, clientId);
    }

    // ClientController.cs
    importNewAccount(clientId: string, importData: any): Observable<AccountImport> {
        const url = `${this.publicApiBaseUrl}client/${clientId}/account/import`;
        return super.postData<AccountImport>(url, importData, clientId);
    }

    async getTargetAccount(accountCode: string, versionNumber: number | string, isInterview = false, caseId: string = null): Promise<Account> {
        const accounts = await lastValueFrom(this.accountDataService.getAccounts(true));

        const target = accounts.find(account => account.code === accountCode);

        if (target == null) {
            this.appService.showMsg('error', 'Cannot find the requested account.');
            return null;
        }

        if (target.versionNumber.toString() === versionNumber && isInterview && caseId == null && target.statusId === AccountStatusEnum.Inactive) {
            const activeAccount = await lastValueFrom(this.getActiveAccount(target.clientId, accountCode));
            return activeAccount;
        } else if (target.versionNumber.toString() === versionNumber) {
            return target;
        }

        if (target.versionNumber.toString() !== versionNumber) {
            const account = await lastValueFrom(this.getAccount(target.clientId, accountCode, versionNumber));

            if (account.versionNumber.toString() === versionNumber && isInterview && caseId == null && account.statusId === AccountStatusEnum.Inactive) {
                const activeAccount = await lastValueFrom(this.getActiveAccount(target.clientId, accountCode));
                return activeAccount;
            } else if (account.versionNumber.toString() === versionNumber) {
                return account;
            }
        }
    }
}
