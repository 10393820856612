import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

import { Observable, Subscription } from 'rxjs';
import { CustomEventLogMode, CustomEventType } from '../../enums';
import { SignatureEnvelope, SignatureEnvelopeRecipient } from '../../models';
import { MonitoringService } from '../../monitor.service';
import { CaseSignatureService, ConfirmationDialogService } from '../../services';
import { EmbeddedSignatureDialogComponent } from './embedded-signature-dialog.component';

export interface SignatureEnvelopeDialogParams {
    accountId: string;
    caseId: string;
    caseIntegrationQueueId: string;
    envelope: SignatureEnvelope;
    onEnvelopeUpdate: Observable<any>;
    mapKeys: any;
}

@Component({
    selector: 'signature-envelope-dialog',
    templateUrl: 'signature-envelope-dialog.html',
    styleUrls: ['signature-envelope-dialog.scss']
})
export class SignatureEnvelopeDialogComponent implements OnInit, OnDestroy {
    envelope: SignatureEnvelope;
    envelopeStatus: { label: string; class: string; complete: boolean; signed: boolean; };
    loadingEnvelope: boolean = false;
    loadingEmbeddedUrl: boolean = false;
    subscriptions: Subscription[] = [];
    docuSignSignerStatusDelay: number;
    mapKeys: any;
    showCancelButton: boolean = true;
    loadingEnvelopeMessage: string = 'Loading signature status, this may take a minute...';

    constructor(
        private caseSignatureService: CaseSignatureService,
        private dialog: MatDialog,
        private confirmationService: ConfirmationDialogService,
        private monitoringService: MonitoringService,
        public dialogRef: MatDialogRef<SignatureEnvelopeDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public context: SignatureEnvelopeDialogParams,
        public changeDetector: ChangeDetectorRef) {
    }

    async ngOnInit() {
        if (this.context.envelope != null) {
            this.setEnvelope(this.context.envelope);
        }

        if (this.context.mapKeys) {
            this.mapKeys = this.context.mapKeys;
        }

        if (this.context.onEnvelopeUpdate) {
            this.subscriptions.push(this.context.onEnvelopeUpdate.subscribe((envelope: SignatureEnvelope) => {
                if (envelope.envelopeId === this.context.envelope.envelopeId) {
                    this.setEnvelope(envelope);
                }
            }));
        }

        this.docuSignSignerStatusDelay = 2000;
    }

    ngOnDestroy() {
        this.subscriptions.forEach(x => {
            if (typeof x?.unsubscribe === 'function') {
                x.unsubscribe();
            }
        });
    }

    ok() {
        this.dialogRef.close();
    }

    cancel() {
        this.confirmationService.open({
            title: 'Cancel Signatures',
            message: 'Are you sure you want to cancel the signature process?',
            showCancel: true,
            onOk: () => {
                // todo: make call to void the envelope.
                this.dialogRef.close();
            }
        });
    }

    refreshEnvelope(): void {
        this.loadingEnvelope = true;
        setTimeout(() => {
            this.caseSignatureService.getSignatureEnvelope(this.context.accountId, this.context.caseId, this.context.envelope.envelopeId, this.context.caseIntegrationQueueId).then(envelope => {
                if (envelope && envelope != null) {
                    this.setEnvelope(envelope);
                }
            });
        }, this.docuSignSignerStatusDelay);

        //Hide cancel button to ensure interviewer does not cancel before callback
        setTimeout(() => {
            this.showCancelButton = true;
            this.loadingEnvelopeMessage = 'Signing documents is taking longer than usual. Press cancel to return to the interview';
        }, 30000);
    }

    setEnvelope(envelope: SignatureEnvelope) {
        this.envelope = envelope;
        this.envelopeStatus = this.getEnvelopeStatus(this.envelope);

        this.envelope.signers.forEach(s => {
            switch (s.status) {
                case 'completed': {
                    s.statusDescription = `Signed`;
                    return;
                }
                case 'declined': {
                    s.statusDescription = `Declined, with reason: ${s.declinedReason}`;
                    return;
                }
                case 'failed_id_check': {
                    s.statusDescription = `Failed ID check`;
                    return;
                }
                default: return;
            }
        });

        this.loadingEnvelope = false;
    }

    async sign(signer: SignatureEnvelopeRecipient, reloadAfterClose: boolean = true) {
        this.loadingEmbeddedUrl = true;

        this.monitoringService.logCaseEvent(`Client - Signature button clicked. Signer: ${JSON.stringify(signer)}`, this.context.caseId, this.context.caseIntegrationQueueId, CustomEventType.IntegrationMetric, CustomEventLogMode.Standalone);

        try {

            const embeddedSignatureView = await this.caseSignatureService.getSignatureEnvelopeEmbeddedView(this.context.caseId, this.context.envelope.envelopeId, signer);

            this.loadingEmbeddedUrl = false;

            const embeddedSignatureDialog = this.dialog.open(EmbeddedSignatureDialogComponent, {
                width: '80%',
                height: '80%',
                disableClose: true,
                panelClass: 'mat-dialog--no-padding',
                data: {
                    embeddedSignatureView
                }
            });

            if (reloadAfterClose) {
                const afterClosedSubscription = embeddedSignatureDialog.afterClosed().subscribe((signEvent: any) => {

                    switch (signEvent.event) {
                        case 'decline':
                            signer.status = 'declined';
                            break;
                        case 'cancel':
                            break;
                        case 'signing_complete':
                            signer.status = 'completed';
                            break;
                        default:
                            signer.status = signEvent.event;
                            break;
                    }

                    let lastSigner = true;
                    let nextSignerSent = false;
                    this.envelope.signers.forEach(s => {
                        if (s.clientUserId !== signer.clientUserId && s.status === 'created' && !nextSignerSent) {
                            if (signer.status === 'completed') {
                                s.status = 'sent';
                                nextSignerSent = true;
                            }
                            lastSigner = false;
                        }
                    });

                    this.caseSignatureService.putSignatureEnvelope(this.context.accountId, this.context.caseId, this.context.envelope.envelopeId, this.envelope);
                    if (lastSigner) {
                        this.showCancelButton = false;
                        this.refreshEnvelope();
                    } else {
                        this.setEnvelope(this.envelope);
                    }
                    afterClosedSubscription.unsubscribe();
                });
            }
        } catch (error) {
            this.loadingEmbeddedUrl = false;
        }
    }

    async viewSignedDocuments() {
        const firstSigner = this.envelope.signers[0];
        await this.sign(firstSigner, false);
    }

    getEnvelopeStatus(envelope: SignatureEnvelope): { label: string; class: string; complete: boolean; signed: boolean; } {
        const failedIdCheck = envelope.signers.find(x => x.status === 'failed_id_check');

        switch (envelope.status) {
            case 'sent': return failedIdCheck ?
                { label: 'Failed ID check', class: 'text-danger', complete: true, signed: false }
                : { label: 'Pending', class: 'text-info', complete: false, signed: false };
            case 'completed': return { label: 'Completed', class: 'text-success', complete: true, signed: true };
            case 'declined': return { label: 'Declined', class: 'text-danger', complete: true, signed: false };
            default: return null;
        }
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
