import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { BaseFormFieldServices, BaseInterviewFormFieldComponent } from '../base-interview-form-field/base-interview-form-field.component';

import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

@Component({
    selector: 'date-answer',
    templateUrl: './date-answer.component.html',
    styleUrls: ['./date-answer.component.scss'],
    providers: [
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: ['YYYY-MM-DD', 'MM/DD/YYYY'],
                },
                display: {
                    dateInput: 'MM/DD/YYYY',
                    monthYearLabel: 'MMM YYYY',
                    dateA11yLabel: 'LL',
                    monthYearA11yLabel: 'MMMM YYYY',
                }
            }
        }
    ]
})
export class DateAnswerComponent extends BaseInterviewFormFieldComponent implements OnInit {
    maxDate = '';
    minDate = '';
    maxYear = '';
    minYear = '';
    pickerFieldName: string;
    forcedFieldName: string;

    constructor(
        private fb: UntypedFormBuilder,
        public services: BaseFormFieldServices) {

        super(services);
    }

    ngOnInit() {
        if (this.config.DateRangeType) {

            if (this.config.DateRangeType === 'absolute') {
                // if dateRangetype==absolute, set mindate and maxdate validations directly from config.
                if (this.config.MaxDate) {
                    this.maxDate = this.config.MaxDate;
                }
                if (this.config.MinDate) {
                    this.minDate = this.config.MinDate;
                }
                if (this.config.MaxYear) {
                    this.maxYear = this.config.MaxYear;
                }
                if (this.config.MinYear) {
                    this.minYear = this.config.MinYear;
                }
            } else if (this.config.DateRangeType === 'relative') {
                // if daterangetype is 'relative', calculate mindate and maxdate validations using prior and later values from config and calculate it from current date.
                if (this.config.MinDateType && this.config.MinNoOfDays != null) {
                    const currMinDate = new Date(new Date().toISOString().split('T')[0]);

                    if (this.config.MinDateType === 'priorDays') {
                        currMinDate.setDate(currMinDate.getDate() - parseInt(this.config.MinNoOfDays));
                    } else if (this.config.MinDateType === 'laterDays') {
                        currMinDate.setDate(currMinDate.getDate() + parseInt(this.config.MinNoOfDays));
                    }

                    this.minDate = currMinDate.toISOString().split('T')[0];
                }
                if (this.config.MaxDateType && this.config.MaxNoOfDays != null) {
                    const currMaxDate = new Date(new Date().toISOString().split('T')[0]);

                    if (this.config.MaxDateType === 'priorDays') {
                        currMaxDate.setDate(currMaxDate.getDate() - parseInt(this.config.MaxNoOfDays));
                    } else if (this.config.MaxDateType === 'laterDays') {
                        currMaxDate.setDate(currMaxDate.getDate() + parseInt(this.config.MaxNoOfDays));
                    }

                    this.maxDate = currMaxDate.toISOString().split('T')[0];
                }

                if (this.config.MinDateType && this.config.MinNoOfYears != null) {
                    const currMinYear = new Date(new Date().toISOString().split('T')[0]);

                    if (this.config.MinDateType === 'priorYears') {
                        currMinYear.setFullYear(currMinYear.getFullYear() - parseInt(this.config.MinNoOfYears));
                    } else if (this.config.MinDateType === 'laterYears') {
                        currMinYear.setFullYear(currMinYear.getFullYear() + parseInt(this.config.MinNoOfYears));
                    }

                    this.minDate = currMinYear.toISOString().split('T')[0];
                }
                if (this.config.MaxDateType && this.config.MaxNoOfYears != null) {
                    const currMaxYear = new Date(new Date().toISOString().split('T')[0]);

                    if (this.config.MaxDateType === 'priorYears') {
                        currMaxYear.setFullYear(currMaxYear.getFullYear() - parseInt(this.config.MaxNoOfYears));
                    } else if (this.config.MaxDateType === 'laterYears') {
                        currMaxYear.setFullYear(currMaxYear.getFullYear() + parseInt(this.config.MaxNoOfYears));
                    }

                    this.maxDate = currMaxYear.toISOString().split('T')[0];
                }
            }
        }

        this.pickerFieldName = `${this.config.id}_picker`;
        this.forcedFieldName = `${this.config.id}_forceUpdate`;

        this.group.addControl(this.pickerFieldName, this.fb.control(''));
        this.group.addControl(this.forcedFieldName, this.fb.control(''));
        const pickerField = this.group.get(this.pickerFieldName);
        if (this.config.isRequired) {
            pickerField.setValidators(Validators.required);
        }

        const existingValue = this.group.get(this.config.id).value;
        if (existingValue != null && existingValue !== '') {
            pickerField.setValue(moment.utc(existingValue));
        }

        super.initialize({ inputType: 'text' });
        this.subscriptions.push(this.group.get(this.forcedFieldName).valueChanges.subscribe(
            data => {
                const forcedControl = this.group.get(this.forcedFieldName);
                const pickerControl = this.group.get(this.pickerFieldName);
                if (forcedControl != null && pickerControl?.value !== forcedControl.value) {
                    pickerControl.setValue(forcedControl.value);
                }
                this.onDateChange();
            }
        ));
    }

    // on blur of the field, validate the field manually as Validations.minDate and maxDate doesnt work on clicking the date popup calendar.
    onDateChange() {
        if (this.isLiveMode()) {
            const pickerControl = this.group.get(this.pickerFieldName);
            const fieldControl = this.group.get(this.config.id);
            const pickerValue = pickerControl.value == null || pickerControl.value === '' ?
                null
                : moment(pickerControl.value).format('YYYY-MM-DD');

            fieldControl.setValue(pickerValue);
            fieldControl.markAsTouched();
            fieldControl.markAsDirty();
            fieldControl.updateValueAndValidity();

            // needed to trigger the red question block, since validation isn't actually occuring
            // on the `config.id` control.
            fieldControl.setErrors(pickerControl.errors);
        }

        super.onBlur();
    }
}
