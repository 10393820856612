<mat-sidenav-container class="sidenav-container" (backdropClick)="close()" style="height: 100%">
  <mat-sidenav #tableSideNav (keydown.escape)="close()" disableClose position="end" (closed)="true"
    style="width: 35rem; height: 100%;">
    <app-manage-edit-page [selectedUser]="activeUser" [accounts]="accounts" (reloadUsers)="getUsers()"
      *ngIf="tableSideNav.opened"></app-manage-edit-page>
  </mat-sidenav>
  <mat-sidenav-content>
      <div class="main-page-heading">
        Users
      </div>
      <manage-users-table [data]="users" [searchableFields]="searchableFields" [displayedColumns]="displayedColumns"
        [showClientFilter]="hasMultipleClients" [clients]="clientSelectListItems" [addRecordAction]="addRecordAction"
        initialSort="nameAndEmail" (tableEvent)="tableAction($event)"></manage-users-table>
  </mat-sidenav-content>
</mat-sidenav-container>