<div class="flex flex-column">
    <div class="flex flex-row">
        <form [formGroup]="orderForm" novalidate>
            <div class="d-flex mt-2" *ngIf="!reportUpdateToggle">
                <div class="mr-3">
                    <client-account-dropdown formField="account" class="w-100" [formGroup]="orderForm" [clientAccountSplitOverride]="clientAccountOverride"></client-account-dropdown>
                </div>
                <div class="mr-3">
                    <mat-radio-group formControlName="dateType">
                        <div style="display: grid">
                            <mat-radio-button *ngFor="let listValue of reportDateTypes" [value]="listValue.id">{{listValue.name}}
                            </mat-radio-button>
                        </div>
                        <mat-error class="text-center" *ngIf="formSubmitted &&orderForm.get('dateType')?.hasError('required')">
                            required</mat-error>
                    </mat-radio-group>
                </div>
                <div >
                    <div class="mr-3 report-date-input">
                        <mat-form-field >
                            <mat-label>Choose a start date</mat-label>
                            <input matInput [matDatepicker]="startDate" formControlName="startDate"
                                readonly>
                            <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                            <mat-datepicker #startDate disabled="false"></mat-datepicker>
                            <mat-error *ngIf="orderForm.get('startDate').hasError('required')">required</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div >
                    <div class="mr-3 report-date-input">
                        <mat-form-field>
                            <mat-label>Choose an end date</mat-label>
                            <input matInput [matDatepicker]="endDate" formControlName="endDate"
                                readonly>
                            <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                            <mat-datepicker #endDate disabled="false"></mat-datepicker>
                            <mat-error *ngIf="orderForm.get('endDate').hasError('required')">required</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="mr-3">
                    <mat-form-field>
                        <mat-label>Order State</mat-label>
                        <mat-select formControlName='orderStateIds' multiple>
                            <mat-option #allSelected (click)="toggleAllSelection()" value="ALL">All</mat-option>
                            <mat-option *ngFor="let status of caseStatus" [value]="status.id" (click)="togglePerOne(allSelected.viewValue)">
                                {{status.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="orderForm.get('orderStateIds').hasError('required')">
                            required
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="float-right mt-2">
                    <mrs-btn-icon class="float-right" buttonLabel="Show Report" buttonId="showReportButton" (onClick)="getReport()"
                        iconName="search"></mrs-btn-icon>
                    <mrs-btn-tertiary class="float-right" *ngIf="filteredData.length" buttonLabel="Download"
                        buttonId="downloadButton" (onClick)="downloadReport()" iconName="download"  textColor="#2C6EED"></mrs-btn-tertiary>
                </div>
            </div>
            <div class="d-flex mt-2" *ngIf="reportUpdateToggle">
                <div class="mr-4">
                    <client-account-dropdown formField="account" class="w-100" [formGroup]="orderForm" [clientAccountSplitOverride]="clientAccountOverride"></client-account-dropdown>
                </div>
                <div class="mr-4">
                    <mat-form-field>
                        <mat-label>Run On</mat-label>
                        <mat-select id="dateType" formControlName='dateType'>
                            <mat-option *ngFor="let type of reportDateTypes" [value]="type.id">
                                {{type.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="formSubmitted &&orderForm.get('dateType')?.hasError('required')">
                            required
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="mr-4">
                    <mat-form-field>
                        <mat-label>Case Status</mat-label>
                        <mat-select formControlName='orderStateIds' multiple>
                            <mat-option #allSelected (click)="toggleAllSelection()" value="ALL">All</mat-option>
                            <mat-option *ngFor="let status of caseStatus" [value]="status.id"
                                (click)="togglePerOne(allSelected.viewValue)">
                                {{status.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="orderForm.get('orderStateIds').hasError('required')">
                            required
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="mr-4 report-date-input">
                    <mat-form-field>
                        <mat-label>Date Range</mat-label>
                        <mat-date-range-input [rangePicker]="picker">
                            <input matStartDate placeholder="Start date" formControlName="startDate">
                            <input matEndDate placeholder="End date" formControlName="endDate">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                    </mat-form-field>
                </div>
                <div class="mt-2" style="flex:1 0 auto">
                    <mrs-btn-primary class="float-right" buttonLabel="Run Report" buttonId="showReportButton" (onClick)="getReport()"></mrs-btn-primary>
                    <mrs-btn-tertiary class="float-right" *ngIf="filteredData.length" buttonLabel="Download"
                        buttonId="downloadButton" (onClick)="downloadReport()" iconName="download" textColor="#2C6EED"></mrs-btn-tertiary>
                </div>
            </div>
        </form>
    </div>

    <div *ngIf="filteredData.length" class="overflow-x-auto">
        <mrs-table [data]="reportData" [displayedColumns]="displayedColumns" [searchableFields]="searchableFields"
            (tableEvent)="tableAction($event)">
        </mrs-table>
    </div>

    <div *ngIf="!reportUpdateToggle">
        <div class="pt-3">
            <div *ngIf="noDataPlaceholder">
                <div class="p-4 text-center text-muted ">
                    <img src="./assets/images/no-data-placeholder.png " alt=" " class="pb-3 " />
                    <h6>No Data Found</h6>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="reportUpdateToggle">
        <div class="pt-3">
            <div *ngIf="noDataPlaceholder">
                <div class="p-4 text-center">
                    <h2>No Cases Found</h2>
                    <div>Change criteria and try again.</div>
                </div>
            </div>
        </div>

        <div class="pt-3">
            <div *ngIf="notRunPlaceholder">
                <div class="p-4 text-center">
                    <h2>No Report Run</h2>
                    <div>Select criteria and run report to see cases for a date range.</div>
                </div>
            </div>
        </div>
    </div>
</div>
