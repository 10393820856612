import { Component, EventEmitter, Input, Output } from '@angular/core';
export interface MrsDropDownPrimaryItem {
    value: string;
    displayValue: string;
}

@Component({
    selector: 'mrs-dropdown-primary',
    templateUrl: './mrs-dropdown-primary.component.html',
    styleUrls: ['./mrs-dropdown-primary.component.scss']
})
export class MrsDropDownPrimaryComponent {
    @Input() selectedValue: string;
    @Input() label: string;
    @Input() values: MrsDropDownPrimaryItem[];
    @Output() selectedValueChange = new EventEmitter<string>();

    constructor(
    ) {
        // consloe.log("test");
        // this.selectedValue = "steak-0";
    }

    updateSelectedValue(event) {
        this.selectedValueChange.emit(event);
    }
}
