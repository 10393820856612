<div style="padding-top: 23px; padding-bottom: 20px">
    <h2 class="title">Requirements</h2>

    <ng-container *ngIf="!baseProps.integrationRequirementsLoaded || !requirementsLoaded || !caseIntegrationQueueEntriesLoaded">
        <mat-spinner mode="indeterminate" diameter="30"></mat-spinner>
    </ng-container>

    <ng-container *ngIf="baseProps.integrationRequirementsLoaded && requirementsLoaded && caseIntegrationQueueEntriesLoaded">

        <uw-requirements-expand-table
            [baseProps]="baseProps"
            [caseRequirements]="caseRequirements"
            [requirements]="requirements"
            (onMenuAction)="handleCaseRequirementAction($event)">
        </uw-requirements-expand-table>

        <uw-requirements-add-form [baseProps]="baseProps" [caseRequirements]="caseRequirements"></uw-requirements-add-form>
    </ng-container>
</div>