<div class="background baseBox">
  <h2>Execution Log</h2>
  <div class="flex flex-align-center bg-white py-2 px-4" style="justify-content: space-between">
    <mat-form-field id="filter" class="filter-cases" floatLabel="never" appearance="fill" style="width: 22rem">
      <input matInput placeholder="Search" name="filterValue" (keyup)="searchLogs()" [(ngModel)]="filterValue">
      <mat-icon aria-hidden="false" aria-label="Search" matSuffix>
        search
      </mat-icon>
    </mat-form-field>
    <div>
      <button type="button" class="btn btn-link btn-sm float-right custom" (click)="refresh()">
        <i aria-hidden="true" class="fa fa-sync-alt"></i> Refresh
      </button>
    </div>
  </div>
  <div class="flex flex-row">
    <section class="tabs-container">
      <ul class="tabs">
        <ng-container>
          <li class="mat-tab-label" [ngClass]="{ 'mat-tab-label-active': currentTab === 'Rules' }"
            (click)="tabChange('Rules')">
            <div style="cursor: pointer; display: flex; align-items: center;">
              <div>Rules</div>
            </div>
          </li>
        </ng-container>
        <ng-container>
          <li class="mat-tab-label" [ngClass]="{ 'mat-tab-label-active': currentTab === 'VMKs' }"
            (click)="tabChange('VMKs')">
            <div style="cursor: pointer; display: flex; align-items: center;">
              <div>VMKs</div>
            </div>
          </li>
        </ng-container>
        <ng-container>
          <li class="mat-tab-label" [ngClass]="{ 'mat-tab-label-active': currentTab === 'Documents' }"
            (click)="tabChange('Documents')">
            <div style="cursor: pointer; display: flex; align-items: center;">
              <div>Documents</div>
            </div>
          </li>
        </ng-container>
        <ng-container>
          <li class="mat-tab-label" [ngClass]="{ 'mat-tab-label-active': currentTab === 'STP' }"
            (click)="tabChange('STP')">
            <div style="cursor: pointer; display: flex; align-items: center;">
              <div>STP</div>
            </div>
          </li>
        </ng-container>
        <ng-container>
          <li class="mat-tab-label" [ngClass]="{ 'mat-tab-label-active': currentTab === 'Mapkey History' }"
            (click)="tabChange('Mapkey History')">
            <div style="cursor: pointer; display: flex; align-items: center;">
              <div>Mapkey History</div>
            </div>
          </li>
        </ng-container>
      </ul>
    </section>
  </div>

  <ng-container>
    <div class="innercontent" *ngIf="!noDataPlaceholder && currentTab === 'Rules'">
      <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows style="width:100%">
        <ng-container matColumnDef="evaluationType">
          <th mat-header-cell *matHeaderCellDef style="width: 75px" (click)="sortColumn('evaluationType')"> Evaluation
            Type </th>
          <td mat-cell *matCellDef="let element">
            {{element.ruleEvaluationModel?.evaluationType}}{{element.vmkEvaluationModel?.evaluationType}} </td>
        </ng-container>

        <ng-container matColumnDef="sectionName">
          <th mat-header-cell *matHeaderCellDef style="width: 250px" (click)="sortColumn('sectionName')"> Section Name
          </th>
          <td mat-cell *matCellDef="let element">
            {{element.ruleEvaluationModel?.sectionName}}{{element.vmkEvaluationModel?.sectionName}} </td>
        </ng-container>

        <ng-container matColumnDef="operationName">
          <th mat-header-cell *matHeaderCellDef style="width: 250px" (click)="sortColumn('operationName')"> Operation
            Name </th>
          <td mat-cell *matCellDef="let element">
            {{element.ruleEvaluationModel?.operationName}}{{element.vmkEvaluationModel?.operationName}} </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef style="width: 250px" (click)="sortColumn('name')"> Name </th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <ng-container matColumnDef="executionDate">
          <th mat-header-cell *matHeaderCellDef style="width: 150px" (click)="sortColumn('executionDate')"> Execution
            Date </th>
          <td mat-cell *matCellDef="let element"> {{element.executionDate | date:'medium'}} </td>
        </ng-container>

        <ng-container matColumnDef="duration">
          <th mat-header-cell *matHeaderCellDef style="width: 75px" (click)="sortColumn('duration')"> Duration(ms) </th>
          <td mat-cell *matCellDef="let element">
            {{element.ruleEvaluationModel?.duration}}{{element.vmkEvaluationModel?.duration}} </td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length" style="width: 450px">
            <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">

              <div *ngIf="element.metricType === 'Rule' && element == expandedElement">
                <div class="example-element-description">
                  <pre>{{element.ruleEvaluationModel | json}}</pre>
                </div>
              </div>

              <div *ngIf="element.metricType === 'Vmk' && element == expandedElement">
                <div class="example-element-description">
                  <pre>{{element.vmkEvaluationModel | json}}</pre>
                </div>
              </div>

              <div *ngIf="element.metricType === 'Metric' && element == expandedElement">
                <div class="example-element-description">
                  <pre>{{element.caseMetric | json}}</pre>
                </div>
              </div>

            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row"
          [class.example-expanded-row]="expandedElement === element" style="cursor: pointer;"
          (click)="expandedElement = expandedElement === element ? null : element">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"
          style="cursor: pointer;"></tr>
      </table>

    </div>
  </ng-container>
  <ng-container>
    <div class="innercontent" *ngIf="!noDataPlaceholder && currentTab === 'VMKs'">
      <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows style="width:100%">
        <ng-container matColumnDef="evaluationType">
          <th mat-header-cell *matHeaderCellDef style="width: 75px" (click)="sortColumn('evaluationType')"> Evaluation
            Type </th>
          <td mat-cell *matCellDef="let element">
            {{element.ruleEvaluationModel?.evaluationType}}{{element.vmkEvaluationModel?.evaluationType}} </td>
        </ng-container>

        <ng-container matColumnDef="sectionName">
          <th mat-header-cell *matHeaderCellDef style="width: 250px" (click)="sortColumn('sectionName')"> Section Name
          </th>
          <td mat-cell *matCellDef="let element">
            {{element.ruleEvaluationModel?.sectionName}}{{element.vmkEvaluationModel?.sectionName}} </td>
        </ng-container>

        <ng-container matColumnDef="operationName">
          <th mat-header-cell *matHeaderCellDef style="width: 250px" (click)="sortColumn('operationName')"> Operation
            Name </th>
          <td mat-cell *matCellDef="let element">
            {{element.ruleEvaluationModel?.operationName}}{{element.vmkEvaluationModel?.operationName}} </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef style="width: 250px" (click)="sortColumn('name')"> Name </th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <ng-container matColumnDef="executionDate">
          <th mat-header-cell *matHeaderCellDef style="width: 150px" (click)="sortColumn('executionDate')"> Execution
            Date </th>
          <td mat-cell *matCellDef="let element"> {{element.executionDate | date:'medium'}} </td>
        </ng-container>

        <ng-container matColumnDef="duration">
          <th mat-header-cell *matHeaderCellDef style="width: 75px" (click)="sortColumn('duration')"> Duration(ms) </th>
          <td mat-cell *matCellDef="let element">
            {{element.ruleEvaluationModel?.duration}}{{element.vmkEvaluationModel?.duration}} </td>
        </ng-container>
        <ng-container matColumnDef="isVmkReEvaluated">
          <th mat-header-cell *matHeaderCellDef style="width: 250px" (click)="sortColumn('isVmkReEvaluated')"> isVmkReEvaluated </th>
          <td mat-cell *matCellDef="let element"> {{element.vmkEvaluationModel.isVmkReEvaluated}} </td>
        </ng-container>
        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="vmkVolumnsToDisplay.length" style="width: 450px">
            <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">

              <div *ngIf="element.metricType === 'Rule' && element == expandedElement">
                <div class="example-element-description">
                  <pre>{{element.ruleEvaluationModel | json}}</pre>
                </div>
              </div>

              <div *ngIf="element.metricType === 'Vmk' && element == expandedElement">
                <div class="example-element-description">
                  <pre>{{element.vmkEvaluationModel | json}}</pre>
                </div>
              </div>

              <div *ngIf="element.metricType === 'Metric' && element == expandedElement">
                <div class="example-element-description">
                  <pre>{{element.caseMetric | json}}</pre>
                </div>
              </div>

            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="vmkVolumnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; columns: vmkVolumnsToDisplay;" class="example-element-row"
          [class.example-expanded-row]="expandedElement === element" style="cursor: pointer;"
          (click)="expandedElement = expandedElement === element ? null : element">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"
          style="cursor: pointer;"></tr>
      </table>

    </div>
  </ng-container>
  <ng-container>
    <div class="innercontent" *ngIf="!noDataPlaceholder && currentTab === 'Documents'">
      <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows style="width:100%">
        <ng-container matColumnDef="documentName">
          <th mat-header-cell *matHeaderCellDef style="width: 250px" (click)="sortColumn('documentName')"> Document Name
          </th>
          <td mat-cell *matCellDef="let element"> {{element.documentName}} </td>
        </ng-container>

        <ng-container matColumnDef="success">
          <th mat-header-cell *matHeaderCellDef style="width: 75px" (click)="sortColumn('success')"> Success? </th>
          <td mat-cell *matCellDef="let element"> {{element.success}} </td>
        </ng-container>

        <ng-container matColumnDef="message">
          <th mat-header-cell *matHeaderCellDef style="width: 250px" (click)="sortColumn('message')"> Message </th>
          <td mat-cell *matCellDef="let element">
            <div *ngIf="element.success">{{element.message}}</div>
            <div *ngIf="!element.success" style="color: red">{{element.message}}</div>
          </td>
        </ng-container>

        <ng-container matColumnDef="sizeInBytes">
          <th mat-header-cell *matHeaderCellDef style="width: 150px" (click)="sortColumn('sizeInBytes')"> Size </th>
          <td mat-cell *matCellDef="let element"> {{element.sizeInBytes}} </td>
        </ng-container>

        <ng-container matColumnDef="startDateTime">
          <th mat-header-cell *matHeaderCellDef style="width: 175px" (click)="sortColumn('startDateTime')"> Start Date
          </th>
          <td mat-cell *matCellDef="let element"> {{element.startDateTime | date:'medium'}} </td>
        </ng-container>

        <ng-container matColumnDef="endDateTime">
          <th mat-header-cell *matHeaderCellDef style="width: 175px" (click)="sortColumn('endDateTime')"> End Date </th>
          <td mat-cell *matCellDef="let element"> {{element.endDateTime | date:'medium'}} </td>
        </ng-container>

        <ng-container matColumnDef="duration">
          <th mat-header-cell *matHeaderCellDef style="width: 75px"> Duration(ms) </th>
          <td mat-cell *matCellDef="let element"> {{diffMilliSeconds(element.endDateTime, element.startDateTime)}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="documentColumnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; columns: documentColumnsToDisplay;" class="example-element-row">
        </tr>
      </table>

    </div>
  </ng-container>
  <ng-container>
    <div class="innercontent" *ngIf="!noDataPlaceholder && currentTab === 'STP'">
      <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows style="width:100%">
        <ng-container matColumnDef="step">
          <th mat-header-cell *matHeaderCellDef style="width: 250px" (click)="sortColumn('step')"> Step </th>
          <td mat-cell *matCellDef="let element"> {{element.step}} </td>
        </ng-container>

        <ng-container matColumnDef="task">
          <th mat-header-cell *matHeaderCellDef style="width: 75px" (click)="sortColumn('task')"> Task </th>
          <td mat-cell *matCellDef="let element"> {{element.task}} </td>
        </ng-container>

        <ng-container matColumnDef="element">
          <th mat-header-cell *matHeaderCellDef style="width: 250px" (click)="sortColumn('element')"> Element </th>
          <td mat-cell *matCellDef="let element"> {{element.element}} </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef style="width: 150px" (click)="sortColumn('status')"> Status </th>
          <td mat-cell *matCellDef="let element"> {{element.status}} </td>
        </ng-container>

        <ng-container matColumnDef="processDateTime">
          <th mat-header-cell *matHeaderCellDef style="width: 175px" (click)="sortColumn('processDateTime')"> Date </th>
          <td mat-cell *matCellDef="let element"> {{element.processDateTime | date:'medium'}} </td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length" style="width: 450px">
            <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">

              <div *ngIf="element == expandedElement">
                <div class="example-element-description">
                  <pre>{{element.message | json}}</pre>
                </div>
              </div>

            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="stpColumnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; columns: stpColumnsToDisplay;" class="example-element-row"
          [class.example-expanded-row]="expandedElement === element" style="cursor: pointer;"
          (click)="expandedElement = expandedElement === element ? null : element">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"
          style="cursor: pointer;"></tr>
      </table>

    </div>
  </ng-container>
  <ng-container>
    <div class="innercontent" *ngIf="!noDataPlaceholder && currentTab === 'Mapkey History'">
      <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows style="width:100%">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef style="width: 150px" (click)="sortColumn('name')"> Name </th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <ng-container matColumnDef="value">
          <th mat-header-cell *matHeaderCellDef style="width: 250px" (click)="sortColumn('value')"> Value </th>
          <td mat-cell *matCellDef="let element"> {{element.value}} </td>
        </ng-container>

        <ng-container matColumnDef="lastModifiedDate">
          <th mat-header-cell *matHeaderCellDef style="width: 100px" (click)="sortColumn('lastModifiedDate')"> Last
            Modified Date </th>
          <td mat-cell *matCellDef="let element"> {{element.lastModifiedDate | date:'medium'}} </td>
        </ng-container>
        <ng-container matColumnDef="lastModifiedBy">
          <th mat-header-cell *matHeaderCellDef style="width: 150px" (click)="sortColumn('lastModifiedBy')"> Last Modified By </th>
          <td mat-cell *matCellDef="let element"> {{element.lastModifiedBy}} </td>
        </ng-container>
        <ng-container matColumnDef="vmkInputHash">
          <th mat-header-cell *matHeaderCellDef style="width: 150px" (click)="sortColumn('vmkInputHash')"> VMK Input Hash </th>
          <td mat-cell *matCellDef="let element"> {{element.vmkInputHash}} </td>
        </ng-container>        
        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" colspan="3">
            <div [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">

              <div *ngIf="element == expandedElement" style="width: 100%;">
                <table #innerTables mat-table #innerSort="matSort" [dataSource]="element.caseMapkeys" matSort
                  style="width: 100%; background-color: #E9E9E9;">
                  <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef style="width: 150px" mat-sort-header> Name </th>
                    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                  </ng-container>

                  <ng-container matColumnDef="value">
                    <th mat-header-cell *matHeaderCellDef style="width: 250px" mat-sort-header> Value </th>
                    <td mat-cell *matCellDef="let element"> {{element.value}} </td>
                  </ng-container>

                  <ng-container matColumnDef="lastModifiedDate">
                    <th mat-header-cell *matHeaderCellDef style="width: 100px" mat-sort-header> Last Modified Date </th>
                    <td mat-cell *matCellDef="let element"> {{element.lastModifiedDate | date:'medium'}} </td>
                  </ng-container>
                  <ng-container matColumnDef="lastModifiedBy">
                    <th mat-header-cell *matHeaderCellDef style="width: 150px"> Last Modified By </th>
                    <td mat-cell *matCellDef="let element"> {{element.lastModifiedBy}} </td>
                  </ng-container>
                  <ng-container matColumnDef="vmkInputHash">
                    <th mat-header-cell *matHeaderCellDef style="width: 150px" (click)="sortColumn('vmkInputHash')"> VMK Input Hash </th>
                    <td mat-cell *matCellDef="let element"> {{element.vmkInputHash}} </td>
                  </ng-container>     
                  <tr mat-header-row *matHeaderRowDef="mapKeyColumnsToDisplay"></tr>
                  <tr mat-row *matRowDef="let row; columns: mapKeyColumnsToDisplay;"></tr>
                </table>
              </div>

            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="mapKeyColumnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; columns: mapKeyColumnsToDisplay;" class="example-element-row"
          [class.example-expanded-row]="expandedElement === element" style="cursor: pointer;"
          (click)="expandedElement = expandedElement === element ? null : element">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"
          style="cursor: pointer;"></tr>
      </table>

    </div>
  </ng-container>
  <div style="margin-left: auto" *ngIf="currentTab !== '' && !noDataPlaceholder">
    <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]">
    </mat-paginator>
  </div>
  <div class="pt-3" *ngIf="currentTab === ''">
    <div>
      <div class="p-4 text-center">
        <h2>No Metric Selected</h2>
        <div>Select a metric.</div>
      </div>
    </div>
  </div>
  <div class="pt-3">
    <div *ngIf="noDataPlaceholder">
      <div>
        <div class="p-4 text-center">
          <h2>No Data</h2>
          <div>There is no data for this metric.</div>
        </div>
      </div>
    </div>
  </div>
</div>