<button
    *ngIf="isSubmitButton"
    type="submit"
    mat-stroked-button
    color="primary"
    id="{{buttonId}}"
    [disabled]="disabled"
    (click)="disabled?null:onClick.emit($event)">
    <mat-icon *ngIf="iconName && iconName !== ''">{{iconName}}</mat-icon>
    <span class="inner-text-margin">{{buttonLabel}}</span>
</button>
<button
    mat-stroked-button
    *ngIf="!isSubmitButton"
    color="primary"
    id="{{buttonId}}"
    [disabled]="disabled"
    (click)="disabled?null:onClick.emit($event)">
    <mat-icon *ngIf="iconName && iconName !== ''">{{iconName}}</mat-icon>
    <span class="inner-text-margin">{{buttonLabel}}</span>
</button>

