<div class="case-manager-container">
    <div class="main-page-heading">
        Reports
    </div>
    <div class="case-manager-inner-container">
        <div class="flex flex-align-center" style="justify-content: space-between">
            <mat-tab-group class="flex-fill">
                <mat-tab [label]="updateReportToggle?'Case Detail':'Order Detail Report'" *ngIf="hasViewPermission('OrderDetailReport')">
                    <case-detail-report [scrollWidth]="scrollWidth" [scrollHeight]="scrollHeight">
                    </case-detail-report>
                </mat-tab>
                <mat-tab [label]="updateReportToggle?'Transmission':'Transmission Report'" *ngIf="hasViewPermission('TransmissionReport')">
                    <transmission-report [scrollWidth]="scrollWidth" [scrollHeight]="scrollHeight">
                    </transmission-report>
                </mat-tab>
                <mat-tab [label]="updateReportToggle?'Billing':'Billing Report'" *ngIf="hasViewPermission('BillingReport')">
                    <billing-report [scrollWidth]="scrollWidth" [scrollHeight]="scrollHeight"></billing-report>
                </mat-tab>
                <mat-tab [label]="updateReportToggle?'User Roles':'User Roles Report'" *ngIf="hasViewPermission('UserRolesReport')">
                    <user-access-report [scrollWidth]="scrollWidth" [scrollHeight]="scrollHeight"></user-access-report>
                </mat-tab>
                <mat-tab [label]="updateReportToggle?'Call Detail':'Call Detail Report'" *ngIf="hasViewPermission('CallDetailReport')">
                    <call-detail-report [scrollWidth]="scrollWidth" [scrollHeight]="scrollHeight"></call-detail-report>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</div>
