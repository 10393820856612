import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';

import { AppService } from '../../app.service';
import { IConfigService } from '../../config/iconfigservice';
import { BaseService } from '../base.service';
import { ManagementQueueSettings } from '../../models';
import { Observable} from 'rxjs';

@Injectable()
export class ManagementQueueService extends BaseService {

    publicApiBaseUrl: string;
    settings: ManagementQueueSettings;

    constructor(
      public appService: AppService,
      public configService: IConfigService,
      public httpClient: HttpClient) {
        super(appService, configService, httpClient);
        this.publicApiBaseUrl = this.configService.getConfiguration().publicApiUrl;
    }

    saveSettings(data : ManagementQueueSettings) {
        this.settings = data;
    }

    getSavedSettings() {
        return this.settings;
    }

    clearSettings(){
        this.settings = null;
    }

    getNextCase( clientCode: string ): Observable<any> {
        return super.getData<any>( `${this.publicApiBaseUrl}client/${clientCode}/assignNextCase`);
    }
}
