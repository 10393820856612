import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { AppService } from 'app';
import { RoutingService } from '..';

@Injectable()
export class AclGuardService implements CanActivate {

    constructor(
        private appService: AppService,
        private routingService: RoutingService,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): boolean {
        const permission: string = route.data['permission'];
        const permissionType: string = route.data['permissionType'];

        if (permission == null || permissionType == null) {
            console.error('AclGuardService: No permission and/or type defined for guard.');
            return false;
        }

        if (!this.appService.checkACL(permission, permissionType)) {
            this.routingService.navigateToDefault();
            return false;
        }

        return true;
    }

}
