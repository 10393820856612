<h1 mat-dialog-title style="display: flex;">
    <div class="mt-2">Document Signature</div>
    <div class="flex-1"></div>
</h1>
<div mat-dialog-content class="flex">
    <hr />
    <div *ngIf="loadingEnvelope" class="flex flex-column flex-center pb-4 pt-4">
        <div class="mb-2">{{loadingEnvelopeMessage}}</div>
        <mat-progress-bar class="w-33" mode="indeterminate"></mat-progress-bar>
    </div>
    <ng-template [ngIf]="envelope && !loadingEnvelope" *ngFor="let signer of envelope?.signers; let i = index;">
        <hr *ngIf="i > 0" style="padding-top: 15px"/>
        <div class="signer flex align-items-center" *ngIf="!signer.signerInfo?.remoteSigner">
            <div class="flex-1">
                <div class="bold-label-18">{{ signer.name }}</div>
                <div><small>{{ signer.statusDescription }}</small></div>
            </div>
            <div class="signer--actions">
                <button class="material-default" mat-raised-button *ngIf="!envelopeStatus?.complete && (signer.status === 'sent' || signer.status === 'delivered') " (click)="sign(signer)" [disabled]="loadingEnvelope || loadingEmbeddedUrl"><i aria-hidden="true" class="fas fa-signature mr-2"></i>Sign</button>
                <button class="material-default" mat-button disabled *ngIf="!envelopeStatus?.complete && signer.status === 'created'">Pending</button>
                <button class="material-default" mat-button disabled *ngIf="signer.status === 'completed'">
                    <i aria-hidden="true" class="fas fa-check text-success"></i>
                </button>
                <button class="material-default" mat-button disabled *ngIf="signer.status === 'declined' || signer.status === 'failed_id_check'">
                    <i aria-hidden="true" class="fas fa-times text-danger"></i>
                </button>
            </div>
        </div>
        <div *ngIf="signer.signerInfo?.remoteSigner">
            <remote-signer [context]="context" [signer]="signer" [envelopeStatus]="envelopeStatus"></remote-signer>
        </div>
    </ng-template>
    <hr />
</div>
<div mat-dialog-actions align="end">
    <button class="material-default" mat-raised-button *ngIf="envelopeStatus?.signed" (click)="viewSignedDocuments()" [disabled]="loadingEnvelope || loadingEmbeddedUrl">VIEW SIGNED DOCUMENTS</button>
    <div class="flex-1"></div>
    <button class="material-default done-button text-danger" mat-button *ngIf="!envelopeStatus?.complete && showCancelButton" (click)="cancel()">CANCEL</button>
    <button class="done-button" mat-raised-button color="primary" *ngIf="envelopeStatus?.complete" (click)="ok()" [disabled]="loadingEnvelope">OK</button>
</div>
