import {Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DialogTemplateData
{
    title: string;
    content: string;
    confirmButtonLabel: string;
}

@Component({
    selector: 'audit-save-dialog',
    templateUrl: 'audit-save-dialog.component.html',
    styleUrls: ['./audit-save-dialog.component.scss']
})
export class AuditSaveDialog {

    constructor(
        public dialogRef: MatDialogRef<AuditSaveDialog, boolean>,
        @Inject(MAT_DIALOG_DATA) public data: DialogTemplateData,
    ) {}
    
    onNoClick(): void {
        this.dialogRef.close(false);
    }

    onOkClick(): void {
        this.dialogRef.close(true);
    }
}
