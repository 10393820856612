import { Component, Input } from '@angular/core';

@Component({
    selector: 'display-lv',
    template: `<div class="label-title" [ngClass]="{'text-uppercase': isUpperCase=='true'}">{{label}} </div>
    <div class="label-content">{{value ? value : '-' }}</div>`,
})
export class DisplayLabelValueComponent {

    @Input() label;
    @Input() value;
    @Input() isUpperCase;

}