import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MrsColor } from '../../../shared/mrs-color.enum';
import { I } from '@angular/cdk/keycodes';

@Component({
    selector: 'mrs-btn-tertiary',
    templateUrl: './mrs-btn-tertiary.component.html',
    styleUrls: ['./mrs-btn-tertiary.component.scss']
})
export class MrsBtnTertiaryComponent implements OnInit, OnChanges {
    isSvg = false;

    @Input() textColor: string;
    @Input() buttonLabel: string;
    @Input() buttonId: string;
    @Input() iconName = '';
    @Input() svgIconName = '';
    @Input() isSubmitButton = false;
    @Input() disabled = false;
    @Input() showSpinner = false;

    // tslint:disable-next-line: no-output-on-prefix
    @Output() onClick = new EventEmitter<Event>();

    ngOnChanges(changes: SimpleChanges) {
        if (changes.disabled?.currentValue) {
            switch (this.textColor) {
                case MrsColor.UIBlue:
                    this.textColor = MrsColor.UIBlueDisabled;
                    break;
                case MrsColor.UIRed:
                    this.textColor = MrsColor.UIRedDisabled;
                    break;
                case MrsColor.MidGray:
                    this.textColor = MrsColor.LightGray;
                    break;
            }
        } else {
            switch (this.textColor) {
                case MrsColor.UIBlueDisabled:
                    this.textColor = MrsColor.UIBlue;
                    break;
                case MrsColor.UIRedDisabled:
                    this.textColor = MrsColor.UIRed;
                    break;
                case MrsColor.LightGray:
                    this.textColor = MrsColor.MidGray;
                    break;
                case MrsColor.DarkBlueGray:
                    this.textColor = MrsColor.DarkBlueGray;
                    break;
            }

        }
    }

    ngOnInit(): void {
        // Only pay attention to svgIconName when this.iconName is blank and not the submit button
        // Trying to limit scope of changes
        this.isSvg = ((this.iconName === '') && (this.svgIconName !== '') && !this.isSubmitButton);
    }
}
