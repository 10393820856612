import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AppService } from "app/app.service";
import { CaseAttachment } from "app/models";
import { FileUploadService } from "app/services/case/file-upload.service";
import $ from 'jquery';
import { finalize } from "rxjs/operators";
import { BaseFormFieldServices, BaseInterviewFormFieldComponent } from "../form-fields/base-interview-form-field/base-interview-form-field.component";
import { AuraService } from "./aura.service";
declare var deui: any;

@Component({
    selector: 'aura-mrs',
    host: { 'class': 'content' },
    templateUrl: './aura.component.html'
})
export class AuraComponent extends BaseInterviewFormFieldComponent implements OnInit, OnDestroy {

    authToken: string;
    clientId: string;
    caseId: string;
    accountId: string;
    caseIntegrationQueueId: string;

    isSuccessfulVault: boolean = false;
    verifyingPayment: boolean = false;
    errors: string = null;
    deuiApp = deui.App;
    deuiAppConfig: any = {};

    constructor(
    public appService: AppService,
    public services: BaseFormFieldServices,
    public auraService: AuraService,
    public fileUploadService: FileUploadService
    ) {

        super(services);
    }

    ngOnInit(): void {
        this.auraService.auraSectionSubmittedObservable.subscribe(
            () => {
                this.deuiApp.submit();
            }
        );

        this.auraService.setAuraSectionComplete(false);

        this.authToken = this.config.propertyValues[0].value;

        this.deuiAppConfig = {
            allowModificationsAfterSubmit: true,
            sectionNav: deui.App.SECTION_NAV.NONE,
            questionLayout: deui.App.QUESTION_LAYOUT.ALL_SECTIONS,
            openToQaReview: false,
            showButtons: false,
            snapScrollBuffer: 90,
            autScrollToQuestion: true,
            autoScrollAfterQuestion: false,
            autoScrollToQuestionOffset: 0,
            displayHelpText: true,
            deUrl: "https://demo-prod-disclosures.auraservices.cloud/disclosures",
            onInterviewComplete: this.interviewComplete.bind(this),
            onSaveAndExitComplete: function (deResult) {
                console.log("SaveAndExit Complete");
            },
            onInterviewStarted: function() {
                console.log("Interview has started" );
            },
            unexpectedConditionOccured: this.errorHandling.bind(this),
            onInterviewStatusChange: function(status) {
                console.log("Interview Status Change:  " + status.canBeSubmitted);
            },
            navigation:
      {
          sectionActivated: function (activeSectionId, priorSectionId) {
              console.log("Active section changed - ActiveSection=" + activeSectionId + " PriorSection=" + priorSectionId);

              var CurrIdx = deui.App.getCurrentSectionIndex();
              console.log("Current section index = " + CurrIdx);
          },
          sectionStatusChanged: function (sectionId) {
          // section is complete or not complete
          // special status of validation error if we break out sections into different pages
          // disable external nav if some sections not complete, otherwise show it...
          // $("#externalSubmit").attr("disabled", this.someSectionsNotComplete());
          //console.log("sectionStatusChanged Called with '" + this.deuiAppConfig.navigation.navSections[sectionId].status + "'");
          },
          initNavControls: function() {
              console.log("initNavControls");
          },
          navSections: []
      }
        };

        this.deuiApp.setConfig(this.deuiAppConfig);
        this.auraService.setDeuiAppConfig(this.deuiAppConfig);

        this.startInterview();
    }

    ngOnDestroy() {
        this.auraService.setAuraSectionComplete(true, false);
    }

    errorHandling(error) {
        console.log("Error message:  " + error.message);
        this.appService.showResponseErrorMsg(error);
        return true;
    }

    interviewComplete(deResult) {
        this.appService.display(true);
        const decision = this.getOverallDecisionFromXml(deResult.rulesResults);
        const caseDetails = this.services.interviewService.getInterviewData().caseDetails;

        const fieldControl = this.group.get(this.config.id);
        fieldControl.setValue(decision);
        fieldControl.markAsTouched();
        fieldControl.markAsDirty();
        fieldControl.updateValueAndValidity();

        this.uploadResults(deResult);
        this.auraService.saveAuraResults(caseDetails.clientId, caseDetails.id, deResult.rulesResults, deResult.interviewDetails)
            .pipe(
                finalize(() => {this.appService.display(false);})
            )
            .subscribe(() =>{
                this.auraService.setAuraSectionComplete(true);
            });
    }

    uploadResults(deResult) {
        const caseDetails = this.services.interviewService.getInterviewData().caseDetails;

        const caseAttachment: CaseAttachment = {data: btoa(JSON.stringify(deResult)), fileName: 'deResults.json', includeInCasePackage: false, caseId: caseDetails.id, tags: [] };
        this.fileUploadService.uploadCaseAttachment(caseDetails.clientId, this.services.interviewService.account, caseDetails.id, caseAttachment).subscribe( () => {});
    }

    someSectionsNotComplete () {
        return this.deuiAppConfig.navigation.navSections.some((navSection) => navSection.status !== 'complete');
    }

    getOverallDecisionFromXml(xml) {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xml, "text/xml");

        xmlDoc.getElementsByTagName("UnderwritingDecision")[0].nodeValue;
        const uwDecision = xmlDoc.getElementsByTagName("UnderwritingDecision")[0];
        return uwDecision.attributes[0].value;
    }

    startInterview() {
        const caseDetails = this.services.interviewService.getInterviewData().caseDetails;

        const sampleXml: any = `<TXLife>
    <TXLifeRequest>
        <TransRefGUID>${caseDetails.id}</TransRefGUID>
        <TransType tc="160100"/>
        <OLifE>
            <Holding id="Holding_1">
                <Policy id="Policy">
                    <CarrierCode>RGA</CarrierCode>
                    <Life>
                        <Coverage id="Coverage_1">
                            <ProductCode>Life</ProductCode>
                            <DeathBenefitAmt>10000000</DeathBenefitAmt>
                            <LifeParticipant PartyID="Party_1" id="LifeParticipant_1">
                                <LifeParticipantRoleCode tc="1">Insured Primary</LifeParticipantRoleCode>
                            </LifeParticipant>
                        </Coverage>
                    </Life>
                    <ApplicationInfo>
                        <TrackingID>${caseDetails.id}</TrackingID>
                    </ApplicationInfo>
                </Policy>
            </Holding>
            <Party id="Party_1">
                <Person>
                    <Gender tc="1">M</Gender>
                    <BirthDate>1980-04-04</BirthDate>
                </Person>
                <Attachment id="MIBAttachment1">
					<AttachmentData tc="1">
						<TXLife Version="2.20.00">
							<TXLifeResponse>
								<TransRefGUID>1440D73342315B04031FC9C0555D9AF1</TransRefGUID>
								<TransType tc="401">MIB Inquiry</TransType>
								<TransExeDate>2014-02-07</TransExeDate>
								<TransExeTime>17:44:08Z</TransExeTime>
								<TestIndicator tc="0">False</TestIndicator>
								<MIBRequest>
									<MIBPriority tc="1">STANDARD</MIBPriority>
									<MIBSearchDepth tc="1">STANDARD</MIBSearchDepth>
									<MIBServiceDescriptor>
										<MIBService tc="1">Checking Service</MIBService>
										<MIBServiceOptions>
											<MIBFollowUpInd tc="0">False</MIBFollowUpInd>
										</MIBServiceOptions>
									</MIBServiceDescriptor>
									<MIBServiceDescriptor>
										<MIBService tc="3">IAI</MIBService>
										<MIBServiceOptions>
											<MIBFollowUpInd tc="0">False</MIBFollowUpInd>
										</MIBServiceOptions>
									</MIBServiceDescriptor>
								</MIBRequest>
								<TransResult>
									<ResultCode tc="1">Success</ResultCode>
									<RecordsFound>1</RecordsFound>
								</TransResult>
								<OLifE Version="2.20.00">
									<Party id="Person_1_1">
										<PartyKey/>
										<Person/>
									</Party>
									<Holding id="Holding_1_1">
										<Policy>
											<ApplicationInfo>
												<HOAssignedAppNumber/>
												<TrackingID>FCC5E13B-D1FD-4A91-9</TrackingID>
											</ApplicationInfo>
											<RequirementInfo AppliesToPartyID="Person_1_1">
												<ReqCode tc="21">MIB Check</ReqCode>
												<RequirementInfoUniqueID/>
												<ReqStatus tc="20">Published</ReqStatus>
												<MIBInquiryReason tc="1">New Business</MIBInquiryReason>
												<ReqSubStatus tc="3">Included in Transaction</ReqSubStatus>
											</RequirementInfo>
										</Policy>
									</Holding>
									<Party id="Person_1_1_BBa">
										<GovtID/>
										<ResidenceState tc="50"/>
										<ResidenceCountry tc="1"/>
										<ResidenceZip/>
										<Person>
											<FirstName>BBa</FirstName>
											<MiddleName/>
											<LastName>UNKNOWN</LastName>
											<Prefix/>
											<Suffix/>
											<Occupation/>
											<Gender tc="2">Female</Gender>
											<BirthDate>1970-10-17</BirthDate>
											<OccupClass tc="0"/>
											<BirthCountry tc="951"/>
											<BirthJurisdictionTC tc="0"/>
										</Person>
										<KeyedValue tc="8">
											<KeyName>MIBTerritory</KeyName>
											<VendorCode>12</VendorCode>
											<KeyValue>E</KeyValue>
										</KeyedValue>
									</Party>
									<Relation id="Relation_1_1_BBa_2010-06-30" RelatedObjectID="Person_1_1_BBa" OriginatingObjectID="Person_1_1">
										<RelationRoleCode tc="1002">TRY</RelationRoleCode>
									</Relation>
									<Relation id="CS_Relation_1_1_BBa_2010-06-30_1" RelatedObjectID="Person_1_1_BBa" OriginatingObjectID="CS_FormInstance_1_1_BBa_2010-06-30_1">
										<RelationRoleCode tc="107"/>
									</Relation>
									<!--NOTE: Model MIB Codes ...-->
									<FormInstance id="CS_FormInstance_1_1_BBa_2010-06-30_1">
										<FormName>MIB/Checking/CodedReport</FormName>
										<FormVersion>2010</FormVersion>
										<SubmitDate>2014-03-03</SubmitDate>
										<FormResponse>
											<ResponseData>346GAA</ResponseData>
										</FormResponse>
										<FormResponse>
											<ResponseData>204G5C</ResponseData>
										</FormResponse>
										<FormResponse>
											<ResponseData>203KGD</ResponseData>
										</FormResponse>
										<FormResponse>
											<ResponseData>057GZ#</ResponseData>
										</FormResponse>
										<FormResponse>
											<ResponseData>057HRN</ResponseData>
										</FormResponse>
										<FormResponse>
											<ResponseData>057H##</ResponseData>
										</FormResponse>
										<FormResponse>
											<ResponseData>900#X#(24a)</ResponseData>
										</FormResponse>
										<FormResponse>
											<ResponseData>912###</ResponseData>
										</FormResponse>
										<FormResponse>
											<ResponseData>912###</ResponseData>
										</FormResponse>
										<KeyedValue tc="8">
											<KeyName>MIBTerritory</KeyName>
											<VendorCode>12</VendorCode>
											<KeyValue>A</KeyValue>
										</KeyedValue>
									</FormInstance>
									<Relation id="Relation_1_1_BBa_0" RelatedObjectID="Person_1_1_BBa" OriginatingObjectID="Person_1_1">
										<RelationRoleCode tc="1002">TRY</RelationRoleCode>
									</Relation>
									<Holding id="IAI_Holding_1_1_BBa_0">
										<HoldingTypeCode tc="2">Policy</HoldingTypeCode>
										<HoldingStatus tc="0">Unknown</HoldingStatus>
										<HoldingForm tc="0">Unknown</HoldingForm>
										<Policy>
											<LineOfBusiness tc="1">Life</LineOfBusiness>
											<RequirementInfo>
												<MIBInquiryReason tc="1">New Business</MIBInquiryReason>
											</RequirementInfo>
										</Policy>
									</Holding>
									<Relation id="IAI_Relation_1_1_BBa_0_1" RelatedObjectID="Person_1_1_BBa" OriginatingObjectID="IAI_FormInstance_1_1_BBa_0_1">
										<RelationRoleCode tc="107"/>
									</Relation>
									<Party id="IAI_Party_1_1_BBa_0_1">
										<PartyTypeCode tc="2">Organization</PartyTypeCode>
										<Carrier>
											<CarrierCode/>
											<CarrierForm tc="1">Direct</CarrierForm>
										</Carrier>
									</Party>
									<FormInstance id="IAI_FormInstance_1_1_BBa_0_1" ProviderPartyID="IAI_Party_1_1_BBa_0_1">
										<FormName>MIB/IAI</FormName>
										<SubmitDate>2014-02-06</SubmitDate>
										<FormResponse>
											<AssociatedResponseData TopLevelObjectID="IAI_Holding_1_1_BBa_0"/>
										</FormResponse>
									</FormInstance>
								</OLifE>
							</TXLifeResponse>
						</TXLife>
					</AttachmentData>
					<AttachmentType tc="MIBResults">MIB Results</AttachmentType>
					<ReceivedDate>2021-03-03</ReceivedDate>
				</Attachment>
            </Party>
      <PolicyProduct id="PolicyProduct_1">		<DistributionChannelInfo>			<DistributionChannel tc="160003">drv_DirectMarketingTelephone</DistributionChannel>		</DistributionChannelInfo>	</PolicyProduct>            <Relation OriginatingObjectID="Holding_1" RelatedObjectID="Party_1" id="Relation_1">
                <OriginatingObjectType tc="4">Holding</OriginatingObjectType>
            </Relation>
        </OLifE>
        <OLifEExtension>
            <NgRequest transactionId=">${caseDetails.id}" projectId="50220" interviewId="50610"/>
        </OLifEExtension>
    </TXLifeRequest>
  </TXLife>`;

        this.deuiApp.startInterview(sampleXml, "deuiContent", this.authToken, "en_US");
    }
}
