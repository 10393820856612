import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CaseDetailsDTO } from '@Models/domain/dto';
import { AppService } from '../../app.service';

@Component({
    selector: 'case-summary-billing',
    host: { 'class': 'content' },
    templateUrl: './case-summary-billing.component.html',
    styleUrls: ['./../case-summary.component.scss']
})

export class CaseSummaryBillingComponent implements OnInit {
  @Input() selectedCaseId: string;

  caseValue: CaseDetailsDTO;

  @Input()
  get case() {
      return this.caseValue;
  }
  set case(val) {
      this.caseValue = val;
      this.caseDataChange.emit(this.caseValue);
  }

  @Output()
      caseDataChange = new EventEmitter<any>();

  public caseBillings: any = [];
  timeZone: string;

  constructor(
    private appService: AppService,
  ) {
  }

  ngOnInit(): void {
      this.getCaseBillings();
  }


  getCaseBillings() {
      this.appService.getData(`CaseBilling/${this.selectedCaseId}`).subscribe(
          data => {
              this.caseBillings = data.data;
          }
      );
  }

  getTotalAmount(caseBillings) {
      let totalAmount = 0;
      caseBillings.forEach(element => {
          totalAmount = totalAmount + element.amount;
      });
      return totalAmount;
  }

  changeBillable(option) {
      this.appService.postData(`Interview/${this.selectedCaseId}/CaseNonBillable/${!option}`, {}, true, false).subscribe(
          data => {
              if (data.status === 'success') {
                  this.case.caseNonBillable = data.data.caseNonBillable;
                  this.appService.showMsg('success', data.message);
              } else {
                  this.appService.showMsg('error', data.message);
              }
          }
      );
  }

}
