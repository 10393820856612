import { Component, Input, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, AbstractControl } from '@angular/forms';
import { AngularEditorComponent, AngularEditorConfig } from '@kolkov/angular-editor';
import { Subject } from 'rxjs';
import { tap, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-rich-text-editor',
    templateUrl: './rich-text-editor.component.html'
})
export class RichTextEditorComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() title: string;
  @Input() htmlContent: string;
  @Input() maxLength: number;
  @ViewChild('angularEditor') angularEditor: AngularEditorComponent;
  private unsubscribe$ = new Subject<void>();

  textEditorFormGroup: UntypedFormGroup;
  characterCount: number;
  isEditable = true;
  editorConfig: AngularEditorConfig = {
      editable: true,
      spellcheck: true,
      height: '6rem',
      minHeight: '5rem',
      placeholder: 'Enter text here...',
      translate: 'no',
      defaultParagraphSeparator: 'p',
      defaultFontName: 'Arial',
      sanitize: true,
      toolbarHiddenButtons: [
          ['insertImage',
              'insertVideo',
              'textColor',
              'backgroundColor',
              'customClasses',
              'link',
              'unlink',
              'insertHorizontalRule',
              'removeFormat',]
      ]
  };

  defaultMessage = `<p></p><ul><li>Direct [Applicant Name Mapkey or &#8220;Signer&#8221;] to visit [URL Mapkey or &#8220;signing portal&#8221;]</li><li>[Applicant Name Mapkey or &#8220;Signer&#8221;] will enter secure code and last four of SSN</li></ul><p></p>`;


  constructor(private _fb: UntypedFormBuilder) {
      this.characterCount = this.htmlContent?.length ?? this.defaultMessage.length;
  }

  ngAfterViewInit(): void {
      this.getTextFieldValue()?.valueChanges
          .pipe(
              tap(res => {
                  this.characterCount = res.length;
              })
          )
          .pipe(
              takeUntil(this.unsubscribe$)
          )
          .subscribe((data) => {
              if (data.length > this.maxLength) {
                  this.getTextFieldValue()?.setErrors({
                      maxLength: true
                  });
              }
              this.textEditorFormGroup.updateValueAndValidity();
          });
  }

  ngOnInit() {
      this.textEditorFormGroup = this._fb.group({
          htmlEditorControl: ['', Validators.maxLength(this.maxLength)]
      });

      if (!this.htmlContent || this.htmlContent === null) {
          this.htmlContent = this.defaultMessage;
      }

      this.getTextFieldValue().setValue(this.htmlContent);

      this.characterCount = this.htmlContent.length;
  }

  private getTextFieldValue(): AbstractControl {
      return this.textEditorFormGroup.get('htmlEditorControl');
  }

  ngOnDestroy(): void {
      this.unsubscribe$?.next();
      this.unsubscribe$?.complete();
  }

}
