<div class="page-heading" id="subheading">
  <span class="page-heading-title">Dashboard</span>
</div>

<div id="innercontent" class="custom-scrollbar">
  <div class="container-fluid container-background">
    <div class="mat-elevation-z8 my-4 mx-2 pb-4">
      <form (ngSubmit)="getCases()" novalidate autocomplete="off">
        <div class="flex flex-align-center bg-white py-2 px-4" style="width:100%; justify-content: space-between">
          <mat-form-field class="filter-cases" floatLabel="never">
            <input matInput placeholder="Search for Case" name="searchValue" [(ngModel)]="searchValue">
            <i matSuffix mat-icon-button aria-label="Info" matTooltip="Search by Case Number, Policy / Certificate Number, Applicant First or Last Name, Agent First or Last Name, Agent ID OR Case Creation Date">
              <mat-icon style="height:18px; width: 18px; font-size: 18px; color: #6A6A6A">info</mat-icon>
            </i>
          </mat-form-field>
          <mat-form-field style="margin-left: 10px; margin-right: 10px; width: 250px;">
            <mat-label>Client</mat-label>
            <mat-select [(ngModel)]="clientId" name="clientId">
              <mat-option value="">All</mat-option>
              <mat-option *ngFor="let client of clients" [value]="client.id">{{client.name}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field style="margin-left: 10px; margin-right: 10px; width: 250px;">
            <mat-label>Date Range</mat-label>
            <mat-select [(ngModel)]="daysBack" name="daysBack">
              <mat-option value="30">Past 30 days</mat-option>
              <mat-option value="60">Past 60 days</mat-option>
              <mat-option value="90">Past 90 days</mat-option>
              <mat-option value="180">Past 180 days</mat-option>
              <mat-option value="">All</mat-option>
            </mat-select>
            <mat-hint>{{getDaysBackDateRange()}}</mat-hint>
          </mat-form-field>

          <mrs-btn-icon buttonLabel="Search" buttonId="searchDashboardButton"
              iconName="search" isSubmitButton="true"></mrs-btn-icon>

        </div>
      </form>
      <!-- Inline styles on purpose... seems more straightforward than referencing these 1-1 things from the stylesheet somewhere -->
      <table mat-table [dataSource]="caseDataSource" matSort matSortActive="caseCreatedDateFormatted"
        style="width:100%;">
        <ng-container matColumnDef="clientName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:200px;">Client</th>
          <td mat-cell *matCellDef="let element"> {{element.clientName}} </td>
        </ng-container>
        <ng-container matColumnDef="accountName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:200px;">Account</th>
          <td mat-cell *matCellDef="let element"> {{element.accountName}} </td>
        </ng-container>
        <ng-container matColumnDef="caseNumber">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:140px;">Case Number</th>
          <td mat-cell *matCellDef="let element"> {{element.caseNumber}} </td>
        </ng-container>
        <ng-container matColumnDef="policyNumber">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:140px;">Policy Number</th>
          <td mat-cell *matCellDef="let element"> {{element.policyNumber}} </td>
        </ng-container>
        <ng-container matColumnDef="applicant">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Applicant</th>
          <td mat-cell *matCellDef="let element"> {{element.Case_Applicant_FirstName}} {{element.Case_Applicant_LastName}} </td>
        </ng-container>
        <ng-container matColumnDef="dateOfBirthFormatted">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>DOB</th>
          <td mat-cell *matCellDef="let element"> {{element.dateOfBirthFormatted}} </td>
        </ng-container>
        <ng-container matColumnDef="agent">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Agent</th>
          <td mat-cell *matCellDef="let element"> {{element.Case_Agent_FirstName}} {{element.Case_Agent_LastName}} </td>
        </ng-container>
        <ng-container matColumnDef="Case_Agent_AgentNumber">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Agent Id</th>
          <td mat-cell *matCellDef="let element"> {{element.Case_Agent_AgentNumber}} </td>
        </ng-container><ng-container matColumnDef="statusName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
          <td mat-cell *matCellDef="let element"> {{element.statusName}} </td>
        </ng-container>
        <ng-container matColumnDef="caseCreatedDateFormatted">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:90px;">Created On</th>
          <td mat-cell *matCellDef="let element"> {{element.caseCreatedDateFormatted}} </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:90px;"></th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button (click)="viewCaseDetails(element, true); stopPropagation($event)">
               <mat-icon matTooltip="View Case" aria-label="View Case">
                   open_in_new
               </mat-icon>
           </button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsToDisplay;" (click)="viewCaseDetails(row, false)" class="link-row"></tr>
      </table>
      <div *ngIf="caseDataSource.filteredData == null || caseDataSource.filteredData.length == 0" class="bg-white">
        <div class="p-4 text-center text-muted ">
          <img src="./assets/images/no-data-placeholder.png " alt=" " class="pb-3 " />
          <h6>No Cases Found</h6>
        </div>
      </div>
      <mat-paginator [pageSize]="25" [pageSizeOptions]="[5, 10, 25, 100]">
      </mat-paginator>
    </div>
  </div>
</div>
