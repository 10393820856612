import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AppService } from '../../app.service';
import { IntegrationMappingType } from '../../enums/global';
import { Account } from '../../models';
import { IntegrationService, MapKeyService } from '../../services';

@Component({
    selector: 'integration-mapping',
    templateUrl: './integration-mapping.component.html',
    styleUrls: ['./integration-mapping.component.scss']
})
export class IntegrationMappingComponent implements OnInit {
    @ViewChild(MatDialog) dialog;
    @Input() group: UntypedFormGroup;
    @Input() integration: any;
    @Input() accountIntegration: any;
    @Input() isView: boolean = true;
    account: Account;
    mapKeys: any[];
    responseMapKeys: any = [];
    inputParameterMapKeys: any = [];
    outputParameterMapKeys: any = [];
    inputParameters: any = [];
    outputParameters: any = [];
    outputs: any = [];
    metadata: any = {};
    filteredInputMapKeys: Observable<any[]>[] = [];
    filteredOutputMapKeys: Observable<any[]>[] = [];
    filteredResponseMapKeys: Observable<any[]>;
    responseFormControl = new UntypedFormControl();

    constructor(private _fb: UntypedFormBuilder,
        private appService: AppService,
        private intergrationService: IntegrationService,
        private route: ActivatedRoute,
        private mapKeyService: MapKeyService) {
    }

    ngOnInit() {
        // todo CHECK IF THESE ALREADY EXIST
        if (this.group.get('inputParams')) {
            (this.group.get('inputParams') as UntypedFormArray).reset();
        } else {
            this.group.addControl('inputParams', this._fb.array([]));
        }

        if (this.group.get('outputParams')) {
            (this.group.get('outputParams') as UntypedFormArray).reset();
        } else {
            this.group.addControl('outputParams', this._fb.array([]));
        }
        this.responseFormControl.setValidators(Validators.required);

        if (!this.group.get('response')) {
            this.group.addControl('response', this._fb.control(''));
        }

        this.route.data.subscribe(data => {
            this.account = data.account;
        });

        this.intergrationService.getIntegrationMetadata(this.integration.id).subscribe(
            data => {
                this.metadata = data;
                this.setupParameters();
            }
        );

        this.mapKeys = this.mapKeyService.mapKeys;
        this.setupMapKeys();
    }

    setupParameters() {
        const responseMapping = _.find(this.accountIntegration.accountIntegrationMappings, function (mapping) {
            return mapping.mappingTypeId === IntegrationMappingType.Response;
        });
        if (responseMapping) {
            this.group.get('response').patchValue(responseMapping.mapKeyId);
        }

        const inputFormArray = this.group.get('inputParams') as UntypedFormArray;
        while (inputFormArray.length > 0) {
            inputFormArray.removeAt(0);
        }
        this.inputParameters = this.metadata.input;
        const inputMappings = _.filter(this.accountIntegration.accountIntegrationMappings, function (mapping) {
            return mapping.mappingTypeId === IntegrationMappingType.Input;
        });
        const mappedInputParams = _.keyBy(inputMappings || [], (param) => param.metadataId);
        this.inputParameters.forEach(x => {
            const selectedMapKey = _.find(mappedInputParams, function (param) {
                return param.metadataId === x.id;
            });

            let mapKeyId = null;
            if (selectedMapKey && selectedMapKey.mapKeyId) {
                mapKeyId = selectedMapKey.mapKeyId;
            }

            const inputParamWithMapping = this.createParam(
                x.id,
                mapKeyId,
                x.isRequired,
                IntegrationMappingType.Input
            );
            inputFormArray.push(inputParamWithMapping);
            //this.manageInputMapKeyControl(inputFormArray.length - 1);
        });

        const outputFormArray = this.group.get('outputParams') as UntypedFormArray;
        while (outputFormArray.length > 0) {
            outputFormArray.removeAt(0);
        }
        this.outputParameters = this.metadata.output;
        const outputMappings = _.filter(this.accountIntegration.accountIntegrationMappings, function (mapping) {
            return mapping.mappingTypeId === IntegrationMappingType.Output;
        });

        if (outputMappings) {
            this.outputs = outputMappings;
            outputMappings.forEach(x => {
                outputFormArray.push(this.createParam(x.metadataId, x.mapKeyId, false, IntegrationMappingType.Output));
                //this.manageOutputMapKeyControl(outputFormArray.length - 1);
            });
        }
    }

    manageInputMapKeyControl(index: number) {
        const arrayControl = this.group.get('inputParams') as UntypedFormArray;
        this.filteredInputMapKeys[index] = arrayControl.at(index).get('mapKey_Id').valueChanges
            .pipe(
                startWith<string>(''),
                // map(value => typeof value === 'string' ? value : value.entityHierarchy),
                map(entityHierarchy => entityHierarchy ? this._filterInputMapKeys(entityHierarchy) : this.inputParameterMapKeys.slice())
            );
    }

    manageOutputMapKeyControl(index: number) {
        const arrayControl = this.group.get('outputParams') as UntypedFormArray;
        this.filteredOutputMapKeys[index] = arrayControl.at(index).get('mapKey_Id').valueChanges
            .pipe(
                startWith<string>(''),
                // map(value => typeof value === 'string' ? value : value.entityHierarchy),
                map(entityHierarchy => entityHierarchy ? this._filterOutputMapKeys(entityHierarchy) : this.outputParameterMapKeys.slice())
            );
    }

    setupMapKeys() {
        this.responseMapKeys = this.mapKeyService.filterIntegrationResponseMapKeys(this.mapKeys);
        this.filteredResponseMapKeys = this.responseFormControl.valueChanges
            .pipe(
                startWith(''),
                map(value => this._filterResponseMapKeys(value))
            );

        this.inputParameterMapKeys = this.mapKeyService.filterInputMapKeys(this.mapKeys);
        this.outputParameterMapKeys = this.mapKeyService.filterOutputMapKeys(this.mapKeys);
    }

    addOutputParameter() {
        this.outputs.push({
            metadata_Id: '',
            mapKey_Id: '',
            mappingType_Id: IntegrationMappingType.Output
        });
        const outputFormArray = this.group.get('outputParams') as UntypedFormArray;
        outputFormArray.push(this.createParam(null, null, false, IntegrationMappingType.Output));
        this.manageOutputMapKeyControl(outputFormArray.length - 1);
    }

    removeOutputParameter(idx) {
        this.outputs.splice(idx, 1);
        const outputFormArray = this.group.get('outputParams') as UntypedFormArray;
        outputFormArray.removeAt(idx);
    }

    createParam(metadata_id, mapKey_Id, isRequired, mappingTypeId): UntypedFormGroup {
        if (isRequired) {
            return this._fb.group({
                mapKey_Id: [mapKey_Id, Validators.required],
                metadata_Id: [metadata_id, Validators.required],
                mappingType_Id: mappingTypeId
            });
        } else {
            return this._fb.group({
                mapKey_Id: [mapKey_Id],
                metadata_Id: [metadata_id],
                mappingType_Id: mappingTypeId
            });
        }
    }

    displayFn(mapKeyId?: any): string | undefined {
        const mapKey = _.find(this.mapKeys, function (m) {
            return m.id === mapKeyId;
        });
        return mapKey ? mapKey.entityHierarchy : undefined;
    }

    private _filterResponseMapKeys(mapkey: string): any[] {
        const filterValue = mapkey.toLowerCase();
        return this.responseMapKeys(option => option.entityHierarchy.toLowerCase().indexOf(filterValue) > -1);
    }

    private _filterInputMapKeys(mapkey: string): any[] {
        const filterValue = mapkey.toLowerCase();
        return _.filter(this.inputParameterMapKeys, function (mapKey) {
            return mapKey.entityHierarchy.toLowerCase().indexOf(filterValue) > -1;
        });
    }

    private _filterOutputMapKeys(mapkey: string): any[] {
        const filterValue = mapkey.toLowerCase();
        return _.filter(this.outputParameterMapKeys, function (mapKey) {
            return mapKey.entityHierarchy.toLowerCase().indexOf(filterValue) > -1;
        });
    }

    getFormControl(formControlName, index) {
        const formArray = this.group.get(formControlName) as UntypedFormArray;
        return formArray.at(index).get('mapKey_Id');
    }
}
