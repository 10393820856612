import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from 'app/app.service';
import { IConfigService } from 'app/config/iconfigservice';
import { FeatureToggle } from 'app/enums';
import { BehaviorSubject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class FeatureManagerService {
    private apiUrl: string;
    private baseHeaders: HttpHeaders = new HttpHeaders();
    private loading = false;

    public _featureFlags: FeatureToggleResponse[] = null;

    public featureFlags = new BehaviorSubject<FeatureToggleResponse[]>([]);
    public featureFlags$ = this.featureFlags.asObservable();
 
  constructor(private configService: IConfigService,
    private httpClient: HttpClient,
    private appService: AppService)
  {
  }

    loadFeatureFlags() {
        if (this.loading) return;
    
        this.baseHeaders = this.appService.getHeaders();
        var publicApiUrl = this.configService.getConfiguration().publicApiUrl;
        this.apiUrl = `${publicApiUrl}global/featureflags`;

    this.loading = true;
    this.featureFlags$ = this.httpClient.get<FeatureToggleResponse[]>(this.apiUrl, { headers: this.baseHeaders }).pipe(shareReplay(1));
    this.featureFlags$.subscribe({
      next: _result => {
        this._featureFlags = _result;
      },
      error: error => {
        this.appService.showResponseErrorMsg(error);
      }
    });
  }

    getByName(featureName: FeatureToggle | string) {
        const featureFlag = this._featureFlags?.find(x => x.name === featureName);

        return featureFlag || { enabled: false, name: 'undefined' };
    }

    isEnabled(featureName: FeatureToggle | string, tenantId?: string) {
        this.baseHeaders = this.appService.getHeaders(tenantId);
    
        var publicApiUrl = this.configService.getConfiguration().publicApiUrl;
        this.apiUrl = `${publicApiUrl}global/FeatureFlags/${featureName}`;

        const response$ = this.httpClient.get<boolean>(this.apiUrl, { headers: this.baseHeaders }).pipe(shareReplay(1));

        return response$;
    }
}

export interface FeatureToggleResponse {
  enabled: boolean;
  name: string;
}

export interface FeatureToggleRequest {
  key: string;
  label: string;
}
