<h1 mat-dialog-title>Attach Documents</h1>
<div class="email-phi-warning">
  <mat-icon class="material-icons-outlined md-18">info</mat-icon>
  <h3>Email transmission not secure. Do not attach documents that may contain PHI or other sensitive information.</h3>
</div>
<div mat-dialog-content>
  <mat-form-field class="w-100">
    <input matInput placeholder="Search" name="searchDocuments" (keyup)="handleSearchKeyUp($event)" />
  </mat-form-field>
  <ul style="list-style-type: none">
    <li *ngFor="let documentOption of filteredDocumentOptions">
      <mat-checkbox [checked]="shouldCheckboxLoadAsChecked(documentOption.id)" (change)="update($event.checked, documentOption.id)">
        {{documentOption.name}}
      </mat-checkbox>
    </li>
  </ul>
  <mat-chip-set *ngIf="attachedDocuments.length > 0">
    <mat-chip>{{attachedDocuments.length}} documents selected</mat-chip>
  </mat-chip-set>
</div>
<mat-dialog-actions align="end">
  <button id="closeButton" mat-button id="cancelButton" color="basic" class="mr-1 material-default" (click)="handleCancelButtonClicked()">
    CANCEL
  </button>
  <button id="okButton" mat-button color="primary" class="mr-1 material-default" (click)="handleAttachDocumentsButtonClicked()">
    ATTACH DOCUMENTS
  </button>
</mat-dialog-actions>
