<form [formGroup]="noteForm" [class.dropShadow]="props.dropShadow">
  <div class="add-note-form" *ngIf="!baseProps.readonly">
    <mat-form-field class="text-area-container textarea-formfield">
      <mat-label>Add Note</mat-label>
      <button mat-icon-button 
          class="mat-mdc-note-icon-button" 
          matPrefix 
          [matTooltip]="noteForm.controls['isInternalNote'].value ? 'Hidden on case document' : 'Visible on case document'" 
          fromControlName="isInternalNote"
          (click)="toggleNoteVisibility()"
          *ngIf="isHiddenNoteFeatureEnabled && isHiddenNoteAccountSettingEnabled">
        <mat-icon>
          <ng-container *ngIf="!noteForm.controls['isInternalNote'].value">visibility</ng-container>
          <ng-container *ngIf="noteForm.controls['isInternalNote'].value">visibility_off</ng-container>
        </mat-icon>
      </button>
      <textarea matInput formControlName="note" cdkTextareaAutosize cdkAutosizeMaxRows="10"></textarea>
      <mat-error *ngIf="noteError">Note is required</mat-error>
    </mat-form-field>
    
    <div class="action-button-container">
      <button mat-flat-button style="background-color: transparent" (click)="clearNote()">Clear</button>
      <button mat-stroked-button color="primary" (click)="addNote()">Save Note</button>
    </div>
  </div>
</form>