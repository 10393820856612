import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, ElementRef, HostListener, Input, NgZone, OnDestroy, OnInit, TemplateRef, ViewChild, } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ScrollToConfigOptions, ScrollToService } from '@nicky-lenaers/ngx-scroll-to';
import { AuraService } from 'app/components/aura/aura.service';
import { ThirdPartyNavigationDirection, ThirdPartySectionService, } from 'app/components/third-party-section/third-party-section.service';
import { FeatureManagerService } from 'app/services/global/feature-manager/feature-manager.service';
import * as $ from 'jquery';
import * as _ from 'lodash';
import * as moment from 'moment';
import { forkJoin, interval, Subject, Subscription } from 'rxjs';

import { InterviewDTO, InterviewSectionDTO, InterviewSectionSummaryDTO } from '@DTOs';
import { Account, Client, IntegrationPending, SignatureEnvelope, UWResponse, VirtualMapKeyUpdateMessage } from '@Models';
import { FilterableListService } from "@Services/config/filterable-list.service";
import { AuthLegacyService } from '../auth/auth-legacy.service';
import { SignatureEnvelopeDialogComponent, } from '../components/signature-envelope-dialog/signature-envelope-dialog-component';
import { IConfigService } from '../config/iconfigservice';
import { AccountSettings, AccountStatusEnum, ActionEnum, CaseStatus, CustomEventLogMode, CustomEventType, FeatureToggle, IntegrationEnum, InterviewCloseEnum, QueueStatusEnum, RolesEnum, } from '../enums';
import { InterviewService } from '../interview.service';
import { MonitoringService } from '../monitor.service';
import { AccountSettingsService, CaseSummaryService, ConfirmationDialogService, ConstantMapKeyService, FeatureToggleService, IdleService, InterviewClientDefaultsService, NotificationService, NotificationSeverity, ObservableService, RoutesEnum, RoutingService, SignalRService, } from '../services';
import { CaseDynamicListMapkeyService, CaseMapKeyService, CaseNotificationsService } from '../services/case';
import { InterviewSectionActionService } from '../services/case/interview-section-action.service';
import { CanComponentDeactivate } from '../services/guards';
import { AppService } from './../app.service';
import { ActionDialogComponent } from './action-dialog.component';
import { EndOfInterviewHandler } from './end-of-interview/end-of-interview-handler.component';
import { AuraIntegrationResponseHandler } from './handlers/aura/aura-integration-response-handler.component';
import { BraintreeIntegrationResponseHandler } from './handlers/braintree/braintree-integration-response-handler.component';
import { SbliOneIncPaymentIntegrationHandler } from './handlers/oneinc/sbli-oneincpayment-integration-handler.component';
import { PrimorisIntegrationResponseHandler } from './handlers/primoris/primoris-integration-response-handler.component';
import { StripeIntegrationResponseHandler } from './handlers/stripe/stripe-integration-response-handler.component';
import { TrustCommerceIntegrationResponseHandler, } from './handlers/trustcommerce/trustcommerce-integration-response-handler.component';
import PdfViewerDialog from './pdf-viewer-dialog.component';
import { InterviewValidationService } from './services';
import { SignatureLoadingComponent } from '@Components/signature-envelope-dialog/v2/signature-loading.component';
import { Utils } from '@Utils';
import { SignatureEnvelopeDialogV2Component } from "@Components/signature-envelope-dialog/v2/signature-envelope-dialog/signature-envelope-dialog-v2.component";


@Component({
    selector: 'app-interview',
    host: { 'class': 'content' },
    templateUrl: './interview.component.html',
    styleUrls: ['./interview.component.scss']
})
export class InterviewComponent implements OnInit, OnDestroy, CanComponentDeactivate {
    //@ViewChild('myPdfViewer') myPdfViewer: TemplateRef<any>;
    @Input() interviewDTO: InterviewDTO;
    @Input() readonly: boolean;

    client: Client;
    accountInfo: Account;

    caseId: string;
    isRestart: boolean;
    disableSave: boolean;
    interviewJson: string;
    showInterviewJson = false;
    isRefresh = false;
    public showInterview: false;
    hubConnection: any;
    selectedSection: any;
    sectionSummaries: InterviewSectionSummaryDTO[];
    formattedSection: any;
    lastSection: any;
    configData: any;
    activeSection: any;
    actionsExpanded: any;
    interviewMode: any;
    selectedQuestion: any;
    caseStatus: any;
    subscriptions: any = [];
    idleSecondsCounter = 0;
    cancelDialog: any;
    textDialog: any;
    IDLE_TIMEOUT = 30;
    idleTimer: any;
    caseOpenUpdateTimer: any;
    interviewTerminateForm: any;
    lastSavedTime: any;
    clearStorage = false;
    showRestartScript = false;
    giactExpanded: boolean;
    rxExpanded: boolean;
    rxHxExpanded: boolean;
    mibExpanded: boolean;
    agentExpanded: boolean;
    quoteExpanded: boolean;
    currentRightTabIndex = 0;
    isCancellActionSelected: boolean;
    caseSocket: any;
    activateRouteGuard: any;
    waitForResponse = false;
    waitIntegration = [];
    previousDisabled = false;
    rxHx: any;
    signalrConnection: any;
    isSocketManuallyClosed = false;
    showPdf = false;
    pdfResult: any;
    page = 1;
    totalPages: number;
    isLoaded = false;
    firstQuestionError = false;
    sidenavOpened = false;
    mobileQuery: MediaQueryList;
    private _mobileQueryListener: () => void;
    dialogRef: MatDialogRef<ActionDialogComponent>;
    processingDialogRef: MatDialogRef<InterviewComponent>;
    signatureLoadingDialogRef: MatDialogRef<SignatureLoadingComponent>;
    canViewCaseDetails = true;
    targetElement: any;
    displayMessage: any;
    accountDraftStatus = AccountStatusEnum.Draft;
    constantLists: any;
    shareMapKeysAcrossSections = false;
    constantMapKeySub: Subscription;
    isApplicant = false;
    auraEnabled = false;
    clearSectionJsonPermission = false;
    userRole: string;
    exitText = 'Cancel';
    casePreActionsMultipleOverwriting = false;

    nextButtonClicked = false;
    private sectionSubmittedSuccessfullySubject = new Subject<any>();
    onSectionSubmittedSuccessfully = this.sectionSubmittedSuccessfullySubject.asObservable();
    interviewWaitForIntegrationPrePopup = false;

    private envelopeUpdateSubject = new Subject<any>();
    onEnvelopeUpdate = this.envelopeUpdateSubject.asObservable();
    useDocusignV2 = false;

    @ViewChild('interviewContainer') interviewContainer: ElementRef;
    @ViewChild('sidenav') sidenav: any;
    @ViewChild('sectionProcessing') sectionProcessingDialog: TemplateRef<any>;

    rxResultsVisibility = true;

    restartScript = '';
    caseDetailsMapkeys = {
        'applicant_phone': ['Case_Applicant_PhoneNumber'],
        'agent_name': ['Case_Agent_FirstName', 'Case_Agent_LastName'],
        'agent_number': ['Case_Agent_AgentNumber'],
        'applicant_name': ['Case_Applicant_FirstName', 'Case_Applicant_MiddleName', 'Case_Applicant_LastName'],
        'applicant_phonenumber': ['Case_Applicant_PhoneNumber'],
        'applicant_age': ['Case_Applicant_Age'],
        'applicant_dob': ['Case_Applicant_DOB'],
        'Case_WritingState': ['Case_WritingState'],
        'agent_phone': ['Case_Agent_PhoneNumber']
    };
    private calledFromThirdParty = false;

    @HostListener('window:beforeunload', ['$event'])
    beforeunloadHandler($event) {
        if (this.clearStorage) {
            localStorage.removeItem('currentOpenCase');
        }

        if (!this.idleService.loggingOut && this.interviewService.clickSource !== InterviewCloseEnum.SignOut && this.caseStatus && this.interviewMode !== 'readonly') {
            this.cancelDialog = true;
            this.showCancelInterviewModal();
            $event.returnValue = 'Save Changes to Interview?';
        }
    }

    @HostListener('window:pagehide', ['$event'])
    pageHide($event) {
        if (this.clearStorage) {
            localStorage.removeItem('currentInterviewCaseId');
        }
        // if (this.interviewService.interviewUpdated) {
        if (!this.idleService.loggingOut && this.interviewService.clickSource !== InterviewCloseEnum.SignOut && this.caseStatus && this.interviewMode !== 'readonly') {
            this.cancelDialog = true;
            this.showCancelInterviewModal();
            $event.returnValue = 'Save Changes to Interview?';
        }
    }

    @HostListener('window:unload', ['$event'])
    unloadHandler(_event) {
        this.caseNotificationsService.removeUserFromCase();
    }

    constructor(
        private authService: AuthLegacyService,
        private route: ActivatedRoute,
        private appService: AppService,
        public interviewService: InterviewService,
        private confirmationService: ConfirmationDialogService,
        public configService: IConfigService,
        private interviewClientDefaultService: InterviewClientDefaultsService,
        private routingService: RoutingService,
        private _scrollToService: ScrollToService,
        private caseSummaryService: CaseSummaryService,
        private notificationService: NotificationService,
        public dialog: MatDialog,
        private signalRService: SignalRService,
        private caseNotificationsService: CaseNotificationsService,
        private monitorService: MonitoringService,
        private idleService: IdleService,
        private interviewSectionActionService: InterviewSectionActionService,
        private caseMapKeyService: CaseMapKeyService,
        private featureToggleService: FeatureToggleService,
        private observableService: ObservableService,
        private constantMapKeyService: ConstantMapKeyService,
        private caseDynamicListMapkeyService: CaseDynamicListMapkeyService,
        private braintreeIntegrationResponseHandler: BraintreeIntegrationResponseHandler,
        private auraIntegrationResponseHandler: AuraIntegrationResponseHandler,
        private trustCommerceIntegrationResponseHandler: TrustCommerceIntegrationResponseHandler,
        private stripeIntegrationResponseHandler: StripeIntegrationResponseHandler,
        private primorisIntegrationResponseHandler: PrimorisIntegrationResponseHandler,
        private portalOneComponentIntegrationHandler: SbliOneIncPaymentIntegrationHandler,
        private endOfInterviewHandler: EndOfInterviewHandler,
        private featureManagerService: FeatureManagerService,
        private auraService: AuraService,
        private thirdPartySectionService: ThirdPartySectionService,
        private filterableListService: FilterableListService,
        private interviewValidationService: InterviewValidationService,
        media: MediaMatcher,
        changeDetectorRef: ChangeDetectorRef,
        private accountSettingService: AccountSettingsService,
        private zone: NgZone
    ) {
        this.route.params.subscribe(params => {
            this.caseId = params.caseId;
        });

        this.route.data.subscribe(data => {
            this.client = data.client;
            this.accountInfo = data.account;
            this.isRestart = data.restart || false;
        });

        this.mobileQuery = media.matchMedia('(min-width: 1025px)');
        this._mobileQueryListener = () => changeDetectorRef.detectChanges();
        this.mobileQuery.addListener(this._mobileQueryListener);

        this.interviewService.setAccount(this.accountInfo);
    }

    //#region SignalR

    monitorCaseUpdates() {
        this.setupSignalRConnection();
    }

    stopMonitoringCaseUpdates() {
        if (this.signalrConnection != null) {
            this.isSocketManuallyClosed = true;
            this.signalrConnection.stop().then(function () {
                console.log(' stop the connection');
                if (this) {
                    this.closeSocket = true;
                }
            });
        }
    }

    setupSignalRConnection() {
        if (this.interviewMode !== 'readonly') {
            this.signalrConnection = this.signalRService.getCaseSignalRConnection(this.getInterviewData().caseDetails.id);

            this.signalrConnection.serverTimeoutInMilliseconds = 1000 * 60 * 15; // 15 minutes
            this.signalrConnection.on('connected', function (event) {
                console.log('SignalR Connected : ' + event);
            });

            this.signalrConnection.on('caseEnvelopeStatusUpdate', (event) => {
                if (this.caseId === event.caseDetailId || this.caseId == event.caseId) {
                    this.envelopeUpdateSubject.next(event.status);
                }
            });

            this.signalrConnection.on('integrationResponse', (event) => {
                if (this.caseId === event.caseDetailId || this.caseId == event.caseId) {
                    this.integrationResponseHandler(event, this);
                }
            });

            this.signalrConnection.on('webhookResponse', (event) => {
                if (this.caseId === event.caseDetailId || this.caseId == event.caseId) {
                    this.webhookResponseHandler(event, this);
                }
            });

            this.signalrConnection.on('virtualMapKeyUpdate', async (_event: VirtualMapKeyUpdateMessage) => {
                // await this.virtualMapKeyUpdateHandler(event);
            });

            this.signalrConnection.start()
                .then(function () {
                    this.caseNotificationsService.addUserToCase(this.client.id, this.getInterviewData().caseDetails.id);
                    console.log('SignalR connection started');
                }.bind(this))
                .catch((error) => {
                    console.error(error.message);
                });

            this.signalrConnection.onclose(() => {
                this.caseNotificationsService.removeUserFromCase();
                console.log('SignalR Disconnected ');
                if (!this.isSocketManuallyClosed) {
                    console.log('SignalR Reconnecting....');
                    this.signalrConnection.start();
                }
            });
        }
    }

    //#endregion
    //#region LifeCycle

    async ngOnInit() {

        this.userRole = this.appService.getUserRoleName();
        this.auraEnabled = this.featureManagerService.getByName('Config.Interview.Aura').enabled;
        this.casePreActionsMultipleOverwriting = this.featureManagerService.getByName('Case.PreActions.MultipleOverwriting').enabled;
        this.interviewWaitForIntegrationPrePopup = this.featureManagerService.getByName(FeatureToggle.GlobalInterviewWaitForIntegrationPrePopup).enabled;
        this.isApplicant = this.userRole === RolesEnum.Applicant;

        this.constantMapKeyService.clearConstantMapKeys();
        this.idleService.isInterviewActive = true;
        this.caseNotificationsService.params = this.route.snapshot.params;
        this.interviewTerminateForm = new UntypedFormGroup({
            terminateMessage: new UntypedFormControl('', [Validators.required])
        });

        this.featureToggleService.getFeatureFlag(FeatureToggle.GlobalTextInterviewToApplication).subscribe(result => {
            if (result) {
                this.exitText = 'Exit Application';
            }
        });

        this.appService.getData(`AccountSettings/${this.accountInfo.id}`).subscribe(response => {
            if (response && response.data) {
                let setting = response.data.find(x => {
                    return x.name === 'shareMapKeysAcrossSections';
                });

                if (setting) {
                    this.shareMapKeysAcrossSections = setting.value;
                }

                setting = response.data.find(x => {
                    return x.name === 'runtime2Enabled';
                });

                if (setting) {
                    this.appService.apiV2Enabled = (setting.value === 'true');
                }
            }
        });

        this.interviewService.isInterviewLocked = false;
        if (this.readonly) {
            // we should just ax the interviewMode of readonly but trying to limit changes in this sweep.
            this.interviewMode = 'readonly';
            this.interviewService.isReadOnly = true;
        }

        if (this.interviewDTO) {
            this.setInterviewData(this.interviewDTO);
            this.sectionSummaries = this.interviewDTO.visibleSections;
        }

        const idleSubscription = this.idleService.triggeredSignoutAutoSave.subscribe(async _data => {
            await this.autoSaveInterview();
            this.authService.logout();
        });

        this.subscriptions.push(idleSubscription);

        $('#sidenav').addClass('hidenav');

        this.appService.getAllConfigData().then((data) => {
            this.configData = data;
        });

        if (this.accountInfo.id) {
            this.appService.getAllDynamicConfigData(this.accountInfo.id);
        }

        this.interviewService.reflexiveForms = {};
        this.interviewService.repeatForms = {};
        this.interviewService.clearIntegrationData();
        this.interviewService.clearRuleFacts();
        // add subscriptions only when interview mode is not readonly
        if (this.interviewMode !== 'readonly') {
            // this is used to update interview data after save call is processed.
            const updateInterviewSubscription = this.interviewService.updatedInterview.subscribe(updatedInterview => {
                this.setInterviewData(updatedInterview);
                if (this.getInterviewData().visibleSections && this.getInterviewData().visibleSections.length > 0) {
                    this.sectionSummaries = this.getInterviewData().visibleSections;
                }
                // process only if interview completed, else move to case summary section.
                if (!this.checkIfInterviewCompleted()) {
                    this.lastSavedTime = new Date();
                    this.formatSectionData(this.getInterviewData());
                    this.updateSectionStyles();
                }
            });
            this.subscriptions.push(updateInterviewSubscription);

            const secondaryLoadingMaskSubscription = this.appService.secondaryLoadingMask.subscribe(val => {
                if (val) {
                    this.displayMessage = this.interviewSectionActionService.displayMessage;
                    this.processingDialogRef = this.dialog.open(this.sectionProcessingDialog,
                        {
                            width: '800px',
                            maxHeight: '90%',
                            minHeight: '250px',
                            disableClose: true
                        });
                    this.processingDialogRef.afterClosed().subscribe(() => {
                        this.interviewContainer.nativeElement.scrollTo(0, 0);
                        const container = document.querySelector('#interviewForm');
                        container.scrollTop = 0;
                    });
                } else {
                    if (this.processingDialogRef) {
                        this.processingDialogRef.close();
                        console.log('popup closed');
                    }
                }
            });
            this.subscriptions.push(secondaryLoadingMaskSubscription);

            // subscription to update case details once case is saved after answering first question.
            const caseSavedSubscription = this.interviewService.caseSavedObservable.subscribe(data => {
                this.getInterviewData().caseDetails = data.caseDetails;
                this.getCaseStatusLabel();
                this.getSetOtherCaseData();
                if (data.visibleSections && data.visibleSections.length > 0) {
                    this.sectionSummaries = data.visibleSections;
                }
                this.updateSectionStyles();
                this.caseId = data.caseDetails.id;

                this.logCaseNumberAndDescriptionToMonitorService("Started Application");

                // Update the URL (but don't renavigate) so a refresh will bring you back here.
                this.routingService.setLocationWithoutRouting(RoutesEnum.interviewRestart, {
                    clientCode: this.client.code,
                    accountCode: this.accountInfo.code,
                    versionNumber: this.accountInfo.versionNumber,
                    caseId: this.caseId
                });

                this.lastSavedTime = new Date();
            });
            this.subscriptions.push(caseSavedSubscription);

            // subscription to update interview data on action processed.
            const preActionSubscription = this.interviewService.processPreShowHideObservable.subscribe(results => {
                if (results.action) {
                    const actions = [];
                    actions.push(results.action);
                    this.updateInterviewBasedOnActions(actions);
                }
            });
            this.subscriptions.push(preActionSubscription);

            this.auraService.auraInterviewCompleteObservable.subscribe(() => this.saveInterview());

            const thirdPartyNavigationSub = this.thirdPartySectionService.navigationFromThirdPartySectionObservable.subscribe(
                (navigationDirection) => {
                    this.calledFromThirdParty = true;
                    if (navigationDirection.direction === ThirdPartyNavigationDirection.Next) {
                        this.saveInterview();
                    } else {
                        this.selectPreviousSection(this.formattedSection);
                    }

                }
            );
            this.subscriptions.push(thirdPartyNavigationSub);

            const actionSubscription = this.interviewService.actionObservable.subscribe(actionResponse => {
                if (actionResponse.data) {
                    if (actionResponse.data[0].actions.name === 'Validate Manual Rx RiskMap') {
                        this.rxHx = actionResponse.data[0].actionResults.data;
                        this.currentRightTabIndex = 1;
                    }
                    this.updateInterviewBasedOnActions(actionResponse.data);
                } else if (actionResponse.type) {
                    const intData = this.interviewService.getIntegrationDataByName(actionResponse.type);
                    if (intData) {
                        // Setup question that launched the action plus reset status to Pending
                        intData.questionId = actionResponse.questionId;
                        intData.status = '00000000-0000-0000-0000-000000000002';

                        const caseData = this.interviewService.getCaseData(actionResponse.type);
                        if (caseData) {
                            caseData.status = intData.status;
                        }
                    }
                }
            });
            this.subscriptions.push(actionSubscription);

            this.subscriptions.push(this.interviewService.unauthorizedAccessObservable.subscribe((message) => this.handleUnauthorizedAccess(message)));
            this.subscriptions.push(this.interviewService.onTriggerRefreshIntegration.subscribe((integrationV2) => {
                this.refreshIntegration(integrationV2.integrationId, integrationV2, integrationV2.name);
            }));

            this.subscriptions.push(this.observableService.triggerMergeMapKeys.subscribe((mapkeys) => {
                this.interviewService.mergeMapkeys(mapkeys);
            }));

            this.subscriptions.push(this.observableService.triggerConstantMapkeyVariantUpdate.subscribe(() => {
                this.filterableListService.updateFilterableListAsync(this.constantLists, this.interviewMode);
            }));

            this.subscriptions.push(this.interviewService.showActionDialog.subscribe((pendingIntegration: IntegrationPending) => {
                if (pendingIntegration.integrationId === IntegrationEnum.Docusign && this.useDocusignV2) {
                    return;
                }

                this.displayPopup(pendingIntegration, 500);
            }));

            this.accountSettingService.getAccountSetting(this.accountInfo, AccountSettings.UseDocusignV2).subscribe(result => {
                if (Utils.isNullOrWhitespace(result?.value)) return;

                this.useDocusignV2 = result?.value === 'true';

                if (this.useDocusignV2) {
                    this.subscriptions.push(this.interviewService.showActionDialog.subscribe(() => {
                        this.signatureLoadingDialogRef = this.dialog.open(SignatureLoadingComponent, {
                            width: '520px',
                            disableClose: true,
                            data: {
                                caseId: this.interviewService.getInterviewData().caseDetails.id,
                                signalRConnection: this.signalrConnection
                            }
                        });
                    }));
                }
            });

            this.subscriptions.push(this.interviewService.hideActionDialog.subscribe(() => {
                if (this.dialogRef != null) {
                    this.dialogRef.close();
                }
            }));

            this.subscriptions.push(this.interviewService.showEnvelopeDialog.subscribe((caseSignatureData: any) => {
                this.showSignatureDialog(caseSignatureData.caseId, caseSignatureData.caseIntegrationQueueId, caseSignatureData.envelope);
            }));
            this.subscriptions.push(this.interviewService.waitForResponseDialog.subscribe((response: any) => {
                if (this.interviewWaitForIntegrationPrePopup && (response?.pendingIntegration?.integrationId === IntegrationEnum.Docusign && !this.useDocusignV2) || (response?.pendingIntegration?.integrationId !== IntegrationEnum.Docusign)) {
                    if (response.show) {
                        this.displayPopup(response.pendingIntegration);
                    } else {
                        this.dialogRef?.close();
                    }
                }
            }));

            const cancelSubscription = this.interviewService.cancelActionObservable.subscribe(data => {
                if (data.code === 'Cancelled') {
                    this.verifyCaseStatus();
                }
            });
            this.subscriptions.push(cancelSubscription);

            const hearbeatInterval = interval(parseInt(this.configService.getConfiguration().heartbeatInterval));
            const heartbeatSubscription = hearbeatInterval.subscribe(_val => {
                if (this.getInterviewData().caseDetails && this.getInterviewData().caseDetails.id) {
                    const caseHeartBeatSub = this.caseNotificationsService.sendCaseHeartbeat(this.getInterviewData().caseDetails.clientId, this.getInterviewData().caseDetails.id);
                    if (caseHeartBeatSub)
                        caseHeartBeatSub.subscribe();
                }
            });
            this.subscriptions.push(heartbeatSubscription);

            await this.interviewService.loadAccountRules(this.accountInfo.id);
        }

        this.subscriptions.push(this.filterableListService.filterableListUpdated$.subscribe((filterableList) => {
            if (Object.keys(filterableList).length != 0)
                this.interviewService.constantLists = filterableList;
        }));

        this.getSections();
        this.interviewService.getVirtualMapkeyData(this.accountInfo.id);
        this.canViewCaseDetails = this.hasViewPermission('ViewCaseDetails');
        this.clearSectionJsonPermission = this.appService.checkACL('Case Summary: Clear Section Json', 'F');
    }

    ngOnDestroy() {
        // clear local storage containing current interview details.
        if (this.clearStorage) {

            localStorage.removeItem('currentOpenCase');

        }
        // remove all subscriptions.
        for (const eachSubscription of this.subscriptions) {
            if (eachSubscription && eachSubscription !== null) {
                eachSubscription.unsubscribe();
            }
        }

        // remove route guards and idle time intervals.
        this.activateRouteGuard = false;
        clearInterval(this.idleTimer);
        clearInterval(this.caseOpenUpdateTimer);
        // remove account info as this will be set again when a new interview page is created.
        this.interviewService.isReadOnly = false;
        this.idleService.isInterviewActive = false;
        this.mobileQuery.removeListener(this._mobileQueryListener);
        this.appService.apiV2Enabled = false;

        this.stopMonitoringCaseUpdates();
    }

    //#endregion

    handleUnauthorizedAccess(message: string) {
        this.notificationService.showNotification({
            severity: NotificationSeverity.Error,
            message,
            actionLabel: 'Go To Case Summary',
            action: () => {
                this.activateRouteGuard = false;
                this.routingService.navigateToRoute(RoutesEnum.caseSummary, {
                    clientCode: this.client.code,
                    accountCode: this.accountInfo.code,
                    versionNumber: this.accountInfo.versionNumber,
                    caseId: this.caseId,
                });
            }
        });
    }

    formatDate(inputDate) {
        const date = new Date(inputDate);
        if (!isNaN(date.getTime())) {
            return date.getUTCMonth() + 1 + '/' + date.getUTCDate() + '/' + date.getUTCFullYear();
        }
    }

    getInterviewData() {
        return this.interviewService.getInterviewData();
    }

    setInterviewData(data) {
        return this.interviewService.setInterviewData(data);
    }

    checkIfInterviewCompleted() {
        if (this.getInterviewData().workflowCompleted) {
            this.interviewService.flushInterviewMetrics();
            const interviewData = this.getInterviewData(); // Store SummaryMessage on interview data
            this.zone.run(() => {

                if (this.appService.getUserRoleName() === RolesEnum.Applicant) {
                    // navigate to End of Interview
                    this.routingService.navigateToRoute(RoutesEnum.endOfInterview, {
                        clientCode: this.client.code,
                        accountCode: this.accountInfo.code,
                        versionNumber: this.accountInfo.versionNumber,
                        caseId: interviewData.caseDetails.id
                    });
                } else {
                    // navigate to Case Summary

                    this.logCaseNumberAndDescriptionToMonitorService("Completed Application");

                    this.routingService.navigateToRoute(RoutesEnum.caseSummary, {
                        clientCode: this.client.code,
                        accountCode: this.accountInfo.code,
                        versionNumber: this.accountInfo.versionNumber,
                        caseId: interviewData.caseDetails.id
                    });
                }
            });
        }
        return this.getInterviewData().workflowCompleted;
    }

    checkACL(feature) {
        if (feature === '') {
            return true;
        }
        return this.appService.checkACL(feature, 'R');
    }

    hasViewPermission(feature) {
        return this.appService.hasViewPermission(feature);
    }

    getSections() {
        if (this.accountInfo && this.accountInfo.id) {
            const url = `Interview/${this.accountInfo.id}/Sections/${this.caseId ? this.caseId : ""}`;

            this.appService.getData<UWResponse<InterviewSectionSummaryDTO[]>>(url).subscribe(data => {
                if (data.data && data.data.length > 0) {
                    this.sectionSummaries = data.data;
                }
                this.interviewSectionActionService.sectionSummaries = this.sectionSummaries;
                this.lastSection = this.sectionSummaries[this.sectionSummaries.length - 1];
                this.loadInterviewData();
                this.getAccountSettings();
            });
        }
    }

    loadInterviewData() {
        if (this.interviewMode === 'readonly') {
            if (this.featureManagerService.getByName(FeatureToggle.CheckInterviewStatusV2).enabled) {
                this.interviewService.checkStatusV2().subscribe(response => {
                    const interviewDisabledSettings = response.data;

                    if (!interviewDisabledSettings["disableInterviewRuleId"] && !interviewDisabledSettings["interviewReadOnlyOnDisable"]) {
                        for (const section of this.sectionSummaries) {
                            section.isDisabled = true;
                            this.previousDisabled = true;
                        }
                    } else {
                        this.previousDisabled = false;
                        this.formatSectionData(this.getInterviewData());
                        this.updateSectionStyles();
                        if (this.getInterviewData().caseDetails) {
                            this.getCaseStatusLabel();
                            this.getSetOtherCaseData();
                        }
                    }
                });
            } else {
                this.interviewService.checkStatus(this.lastSection).subscribe(response => {
                    if (response.data === false) {
                        for (const section of this.sectionSummaries) {
                            section.isDisabled = true;
                            this.previousDisabled = true;
                        }
                    } else {
                        this.previousDisabled = false;
                        this.formatSectionData(this.getInterviewData());
                        this.updateSectionStyles();
                        if (this.getInterviewData().caseDetails) {
                            this.getCaseStatusLabel();
                            this.getSetOtherCaseData();
                        }
                    }
                });
            }
        } else if (this.isRestart) {
            this.appService.getData<UWResponse<InterviewDTO>>(`Interview/${this.caseId}/RestartInterview`).subscribe({
                next: (data) => {
                    this.interviewService.setInterviewData(data.data);

                    this.interviewService.checkStatus(this.lastSection).subscribe(response => {
                        if (response.data === false) {
                            this.readonly = true;
                            this.interviewService.isReadOnly = true;
                            this.interviewService.isInterviewLocked = true;
                            for (const section of this.sectionSummaries) {
                                if (section.id !== data.data.activeSectionId) {
                                    section.isDisabled = true;
                                    this.previousDisabled = true;
                                }
                            }
                        } else {
                            this.interviewService.isInterviewLocked = false;
                            this.previousDisabled = false;
                        }

                        this.formatSectionData(this.getInterviewData());
                        
                        this.logCaseNumberAndDescriptionToMonitorService(`Restarted Application`);
                        // update section styles based on maxvisited section and active section.
                        this.sectionSummaries = this.getInterviewData().visibleSections;
                        this.interviewSectionActionService.sectionSummaries = this.sectionSummaries;
                        this.lastSection = this.sectionSummaries[this.sectionSummaries.length - 1];

                        this.updateSectionStyles();
                        if (this.getInterviewData().caseDetails) {
                            this.interviewService.initializeRuleData();
                            this.getCaseStatusLabel();
                            this.getSetOtherCaseData();
                        }

                        const activeSection = data.data.sections.find(x => x.id == data.data.activeSectionId);
                        if (activeSection.isThirdParty) {
                            this.thirdPartySectionService.interviewRestarted = true;
                        }
                    });
                },
                error: (err) => {
                    this.interviewService.handleError(err);
                }
            });
        } else {
            this.appService.getData<UWResponse<InterviewDTO>>(`Interview/${this.accountInfo.id}/Initalize`).subscribe(data => {
                this.interviewService.setInterviewData(data.data);
                this.formatSectionData(this.getInterviewData());
                this.updateSectionStyles();
            });
        }

    }

    getAccountSettings() {
        this.appService.getData(`AccountSettings/${this.accountInfo.id}`).subscribe(
            data => {
                if (data.status === 'success') {
                    if (data.data && data.data.length) {
                        const setting = data.data.find(item => item.name === 'rxResultsVisibility');
                        this.rxResultsVisibility = (setting && setting.value ? JSON.parse(setting.value) : false);
                    }
                }
            }
        );
    }

    refreshIntegration(integrationId, integrationData, name) {
        let status: any;
        const me = this;
        let id = integrationId;

        if (integrationData != null) {
            status = integrationData.status;

            if (integrationId === '') {
                id = integrationData.id;
            }
        }

        this.interviewService.refreshIntegration(id, integrationData.id, status, name).subscribe({
            next: (data) => {
                if (data.status === 'success') {
                    const resp = JSON.parse(data.data);

                    if (resp.details) {
                        me.integrationResponseHandler(data.data, me);
                    }
                }
            },
            error: (error) => {
                this.appService.showResponseErrorMsg(error);

                // if refresh fails should we close the popup?
                if (this.dialogRef) {
                    this.dialogRef.close();
                }
            }
        });
    }

    selectSectionIfEnabled(section) {
        if (this.featureManagerService.getByName(FeatureToggle.CheckInterviewStatusV2).enabled && this.interviewMode === 'readonly') {
            this.interviewService.checkStatusV2().subscribe(response => {
                const interviewDisabledSettings = response.data;

                if (!interviewDisabledSettings["disableInterviewRuleId"] && !interviewDisabledSettings["interviewReadOnlyOnDisable"]) {
                    section.isDisabled = true;
                } else {
                    this.selectSection(section);
                }
            });
        } else {
            this.interviewService.checkStatus(this.interviewService.getActiveSection()).subscribe(response => {
                if (response.data === true) {
                    this.selectSection(section);
                    this.previousDisabled = false;
                } else {
                    for (const eachSection of this.sectionSummaries) {
                        eachSection.isDisabled = true;
                        this.previousDisabled = true;
                    }
                }
            });
        }
    }

    // called when section is selected by clickin on it in left panel
    selectSection(section: InterviewSectionDTO) {
        const me = this;
        section.isReadOnly = this.readonly;

        forkJoin([
            this.interviewService.selectSection(section),
            this.constantMapKeyService.getConstantMapKeys(this.accountInfo.id, this.accountInfo.versionNumber, section.id)
        ]).subscribe(async ([first, second]) => {
            const response = first;
            this.constantLists = second;

            if (this.caseId) {
                const constantKeyList = await this.caseDynamicListMapkeyService.getDynamicMapKeys(this.accountInfo.id, this.caseId, section.id);
                const c = _.extend(this.constantLists, constantKeyList);
                this.constantLists = c;
            }

            this.interviewService.constantLists = this.constantLists;
            me.interviewService.getInterviewData().isCallMasking = response.data.isCallMasking;
            // Save the current data
            me.interviewService.autoSaveInterview(me.accountInfo.id, 'AutoSave', null);

            // Go to the next section
            me.interviewService.reflexiveForms = {};
            me.interviewService.repeatForms = {};
            me.formattedSection = me.getInterviewData().sections.find(s => s.id === section.id);

            // Bug-38062 - Interview throwing a front end error.
            // Cliff notes, team could not reliably reproduce this bug, add logging so we could see
            // how often the UI gets into this state.
            if ((me.formattedSection == null) || (me.formattedSection == undefined)) {
                this.monitorService.logError(new Error(`SelectSection: Active section missing from interview data. Sections [${me.getInterviewData().sections.map(section => section.id).join(',')}], looking for ${section.id} in caseId: ${this.caseId ?? 'not available'}`));
            }

            this.logCaseNumberAndDescriptionToMonitorService(`Select Section`);

            const caseMapKeys = me.interviewService.getInterviewData().caseDetails.mapkeys;

            if (me.formattedSection?.isThirdParty) {
                me.formattedSection.embeddedIntegrationData.readonly = (this.interviewMode === 'readonly');
            }

            me.formattedSection.questions.forEach(function (question) {
                const answerTypeObject = me.configData.answerTypes.find(answerType => answerType.id === question.answerTypeId);
                question.answerType = answerTypeObject.code;
                if (question.order === 1) {
                    me.selectedQuestion = question;
                }
                question.propertyValues.forEach(property => {
                    question[property.name] = property.value;
                });

                if (me.shareMapKeysAcrossSections) {
                    const currentCaseMapKey = caseMapKeys.find(x => x.name == question.mapKeyName);

                    if (currentCaseMapKey && currentCaseMapKey.value !== '' && currentCaseMapKey.value !== question.answerValue) {
                        question.answerValue = currentCaseMapKey.value;
                        question.answerTimeStamp = currentCaseMapKey.lastModifiedDate;
                    }
                }
            });

            me.getInterviewData().activeSectionId = me.formattedSection.id;
            me.updateSectionStyles();
            me.interviewService.updateInterviewBasedOnSectionActions(me.formattedSection.id, me.getInterviewData().sections, me.formattedSection);

            if (!this.interviewService.isReadOnly) {
                me.interviewService.updateQuestionPreActionsForSection(me.formattedSection.id);
            }

            me.interviewService.updateSectionDisabledAction(response.data.sections, me.formattedSection);
        });
    }

    isIntegrationDetailsVisible(item) {
        const data = this.interviewService.getIntegrationDataByName(item);
        if (data !== undefined) {
            return data.status !== undefined;
        }
        return false;
    }

    formatSectionData(interviewData: InterviewDTO) {
        const me = this;

        if (interviewData.caseDetails) {
            if (this.interviewMode !== 'readonly') {
                this.interviewMode = 'existing';
            }
        } else {
            this.interviewMode = 'new';
        }
        if (this.interviewMode === 'readonly') {
            this.getInterviewData().activeSectionId = this.getInterviewData().sections[0].id;
        }

        this.constantMapKeySub = this.constantMapKeyService.getConstantMapKeys(this.accountInfo.id, this.accountInfo.versionNumber, this.getInterviewData().activeSectionId).subscribe(
            async response => {
                this.constantLists = response;

                if (this.caseId) {
                    const constantKeyList: any = await this.caseDynamicListMapkeyService.getDynamicMapKeys(this.accountInfo.id, this.caseId, this.getInterviewData().activeSectionId);
                    const c = _.extend(this.constantLists, constantKeyList);
                    this.constantLists = c;
                }
                this.filterableListService.updateFilterableListAsync(this.constantLists, this.interviewMode);
                this.interviewService.constantLists = this.constantLists;
                
                // If there is no section matching the Active Section then use the last section in the list
                if (!interviewData.sections.some(section => section.id === interviewData.activeSectionId)) {
                    interviewData.activeSectionId = interviewData.sections[interviewData.sections.length - 1].id;
                }

                const activeSectionId = interviewData.activeSectionId;
                const activeQuestionNumber = interviewData.activeQuestionNumber;
                this.formattedSection = interviewData.sections.find(section => section.id === activeSectionId);
                const depqnslist = interviewData.dependencyQuestionList;

                // Bug-38062 - Interview throwing a front end error.
                // Cliff notes, team could not reliably reproduce this bug, add logging so we could see
                // how often the UI gets into this state. When this comment was written there was code above
                // that forced this.formattedSection to always be the last section. 
                if ((this.formattedSection == null) || (this.formattedSection == undefined)) {
                    this.monitorService.logError(new Error(`FormatSectionData: Active section missing questions property. Sections [${interviewData.sections.map(section => section.id).join(',')}], looking for ${activeSectionId} in caseId: ${this.caseId ?? 'not available'}`));
                } 

                this.formattedSection.questions.forEach(function (question) {
                    const answerTypeObject = me.configData.answerTypes.find(answerType => answerType.id === question.answerTypeId);
                    question.answerType = answerTypeObject.code;
                    if (question.order === activeQuestionNumber) {
                        me.selectedQuestion = question;
                    }
                    question.propertyValues.forEach(property => {
                        question[property.name] = property.value;
                    });
                    if (question.questionActions && question.questionActions.length > 0) {
                        if (me.interviewMode !== 'readonly') {
                            // pre actions are run in backend itself before section is loaded, so update the interview based on their results.
                            me.updateInterviewBasedOnActions(question.questionActions);
                        }
                    }
                });                    
                

                if (this.interviewMode !== 'readonly') {
                    if (depqnslist) {
                        // this is to track and call pre actions on dependent questions if any of the questions whose value change might affect the show/hide of those dependent questions.
                        for (const eachQuestionId of Object.keys(depqnslist)) {
                            const mapKeyData = depqnslist[eachQuestionId];
                            for (const eachMapKey of mapKeyData) {
                                const depQuestion = this.formattedSection.questions.find(q => q.mapkeysId === eachMapKey);
                                if (depQuestion) {
                                    depQuestion.dependentQuestions = depQuestion.dependentQuestions ? depQuestion.dependentQuestions : [];
                                    if (!depQuestion.dependentQuestions.includes(eachQuestionId)) {
                                        depQuestion.dependentQuestions.push(eachQuestionId);
                                    }
                                }
                            }
                        }
                    }

                    for (const eachError of this.formattedSection.errors) {
                        if (eachError.questionId) {
                            if (eachError.questionId === this.appService.emptyGUID) {
                                this.notificationService.showNotification({
                                    severity: NotificationSeverity.Error,
                                    message: eachError.message
                                });
                            } else {
                                const errorQuestion = this.formattedSection.questions.find(q => q.id === eachError.questionId);
                                if (errorQuestion) {
                                    errorQuestion.errorData = eachError;
                                    this.setErrorsForQuestion(errorQuestion, true, eachError.message);
                                }
                            }
                        }
                    }
                }
                
                // this is to set integration data on interview restart or on clickin next.
                this.setIntegrationData();
                if (this.interviewMode !== 'readonly') {
                    this.processSectionsActions(this.formattedSection);
                }

                if (this.interviewMode !== 'readonly'
                    && this.isRestart
                    && this.formattedSection.restartScript) {

                    // if interview is restarted, show restart script configured for that section.
                    this.restartScript = this.interviewService.formattedText(this.formattedSection.restartScript);
                    this.showRestartScript = true;
                    this.isRestart = false;
                }

            }
        );

        if (this.formattedSection?.isThirdParty) {
            this.formattedSection.embeddedIntegrationData.readonly = (this.interviewMode === 'readonly');
        }

        this.subscriptions.push(this.constantMapKeySub);
    }

    setIntegrationData() {
        // same logic as when integration response is received from socket.
        const me = this;
        if (me.interviewService.getMibData().details && me.interviewService.getMibData().details.parties) {
            const questionMibMap = {};
            const parentQuestionMap = [];
            for (const eachDetail of me.interviewService.getMibData().details.parties) {
                eachDetail.mibCodes.forEach(mibCode => {
                    if (mibCode.mapkeyId && eachDetail.relationRoleCode) {
                        parentQuestionMap.push([mibCode.mapkeyId, eachDetail.relationRoleCode]);
                    }
                    Object.assign(mibCode, eachDetail);
                    delete mibCode.mibCodes;
                    questionMibMap[mibCode.mapkeyId] = questionMibMap[mibCode.mapkeyId] ? questionMibMap[mibCode.mapkeyId] : [];
                    questionMibMap[mibCode.mapkeyId].push(mibCode);
                });
            }
            const visiblequestionList = [];

            me.getInterviewData().sections.forEach(section => {
                section.questions.forEach(question => {
                    if (question.healthQuestionDetail && question.healthQuestionDetail.length) {
                        if (question.healthQuestionDetail[0].questionId === question.id) {
                            for (let i = 0; i < parentQuestionMap.length; i++) {
                                if (parentQuestionMap[i][0] === question.mapkeysId && question.display === true) {
                                    if (parentQuestionMap[i][1].toLowerCase() === 'hit') {
                                        visiblequestionList.push(question.healthQuestionDetail[0].hitQuestionId);
                                        visiblequestionList.push(question.healthQuestionDetail[0].correctedQuestionId);
                                    }
                                    if (parentQuestionMap[i][1].toLowerCase() === 'try') {
                                        visiblequestionList.push(question.healthQuestionDetail[0].tryQuestionId);
                                        visiblequestionList.push(question.healthQuestionDetail[0].correctedQuestionId);
                                    }
                                }
                            }
                        }
                    }
                });
            });
            me.getInterviewData().sections.forEach(section => {
                section.questions.forEach(question => {
                    if (visiblequestionList.includes(question.id)) {
                        question.display = true;
                    }
                });
            });
            me.getInterviewData().sections.forEach(section => {
                section.questions.forEach(question => {
                    if (questionMibMap[question.mapkeysId] && questionMibMap[question.mapkeysId].length) {
                        question.mibDetails = questionMibMap[question.mapkeysId];
                    }
                });
            });
        }
        if (me.interviewService.getGiactData().details) {
            const activeSectionId = me.getInterviewData().activeSectionId;

            const currentSection = me.getInterviewData().sections.find(section => section.id === activeSectionId);
            const giactQuestion = currentSection.questions.find(q => q.id === me.interviewService.getGiactData().questionId);
            if (giactQuestion) {

                giactQuestion.integrationMessages = 'GIACT';
                giactQuestion.intergrationData = me.interviewService.getGiactData().details.message;
            }
        }


    }

    back() {
        const dest = this.interviewClientDefaultService.getUserBackDestination(this.accountInfo);
        this.routingService.navigateToRoute(dest);
    }

    logCaseNumberAndDescriptionToMonitorService(messageDescription: string) {
        const interviewData = this.interviewService.getInterviewData();
        let caseNumber = 'No CaseNumber due to the interview not being created yet';

        if (interviewData && interviewData.caseDetails && interviewData.caseDetails.caseNumber) {
            caseNumber = interviewData.caseDetails.caseNumber;
        }

        this.monitorService.setCaseNumber(caseNumber);

        this.monitorService.logEvent(messageDescription,
            {
                "sectionName": this.formattedSection?.name,
                "caseNumber": caseNumber,
                "accountCode": this.accountInfo?.code,
                "role": this.userRole,
                "sessionId": this.signalRService.getSessionId(),
                "caseCreatedDate": this.getInterviewData().caseDetails?.creationDate,
                "eventType": "Client Runtime"
            }
        );
    }

    async saveInterview() {
        try {

            if (this.auraEnabled && !this.auraService.isAuraSectionComplete()) {
                this.auraService.buttonClicked();
                return;
            }

            if (this.formattedSection.isThirdParty && !this.calledFromThirdParty) {
                this.thirdPartySectionService.moveToNext();
                return;
            } else if (this.calledFromThirdParty) {
                this.calledFromThirdParty = false;
                this.interviewContainer.nativeElement.scrollTop = 0;
                await this.interviewService.saveInterview(this.formattedSection.id);

                const container = document.querySelector('#interviewForm');
                container.scrollTop = 0;
                return;
            }

            this.disableSave = true;

            this.logCaseNumberAndDescriptionToMonitorService('Save Interview');

            const invalidControls: AbstractControl[] = [];

            // check if all forms are valid, i.e, regular dynamic form, all reflexive forms present in section and all repeat forms.
            const dynamicForm = this.interviewService.getDynamicForm();
            this.interviewValidationService.findInvalidControls(dynamicForm, invalidControls);

            const reflexiveForms = this.interviewService.reflexiveForms;
            const repeatForms = this.interviewService.repeatForms;
            const healthForms = this.interviewService.healthForms;

            let allFormsValid = dynamicForm.disabled || !dynamicForm.invalid;
            allFormsValid &&= this.interviewValidationService.validateReflexiveForms(reflexiveForms, invalidControls);

            for (const eachRepeatFormIndex in repeatForms) {
                const eachRepeatForm = repeatForms[eachRepeatFormIndex];
                this.interviewValidationService.findInvalidControls(eachRepeatForm, invalidControls);
                if (allFormsValid) {
                    allFormsValid = !eachRepeatForm.invalid;
                }

            }
            for (const eachHealthFormIndex in healthForms) {

                // if (allFormsValid) {
                //     allFormsValid = eachHealthForm.valid;
                // }

                if (allFormsValid) {
                    const eachHealthForm = healthForms[eachHealthFormIndex];
                    this.interviewValidationService.findInvalidControls(eachHealthForm, invalidControls);
                    const interviewSections = this.getInterviewData().sections;
                    const activeSection = interviewSections.find(s => s.id === this.getInterviewData().activeSectionId);
                    activeSection.questions.forEach(question => {
                        for (const eachHealthFormId in eachHealthForm.value) {
                            if (question.id === eachHealthFormId && question.display === true && question.answerValue === '') {
                                allFormsValid = false;
                            }
                        }
                    });
                }

            }

            let isErrorExists = false;
            for (const eachQuestion of this.formattedSection.questions) {
                if (eachQuestion.answerType === 'DisplayMessage' && eachQuestion.MessageType === 'Error' && eachQuestion.display) {
                    isErrorExists = true;
                }
            }

            if (invalidControls && invalidControls.length > 0) {
                invalidControls.sort(function (a, b) {
                    let formindexa = a['formIndex'];
                    const formindexb = b['formIndex'];
                    if (!formindexa) {
                        formindexa = (invalidControls.length + 1).toString();
                    }
                    if (!formindexb) {
                        formindexa = (invalidControls.length + 2).toString();
                    }
                    const a1 = formindexa.split('.');
                    const b1 = formindexb.split('.');
                    const len = Math.max(a1.length, b1.length);

                    for (let i = 0; i < len; i++) {
                        const _a = +a1[i] || 0;
                        const _b = +b1[i] || 0;
                        if (_a !== _b)
                            return _a > _b ? 1 : -1;
                    }
                    return 0;
                });

                const invalidControl = invalidControls[0];
                this.scrollToError(invalidControl['questionId']);
            }

            if (allFormsValid && !this.interviewService.shouldWaitForAnyIntegration() && !isErrorExists) {
                this.interviewContainer.nativeElement.scrollTop = 0;
                await this.interviewService.saveInterview(this.formattedSection.id);

                const container = document.querySelector('#interviewForm');
                container.scrollTop = 0;

            } else if (isErrorExists) {
                //This happens when there are Error Display messages being shown in the interview
                this.notificationService.showNotification({ severity: NotificationSeverity.Error, message: 'Check error message(s)' });
            } else if (!allFormsValid) {
                // if invalid, update validity for all fields, i.e show validation errors at field level itself.
                this.validateAllFormFields(dynamicForm);
                for (const eachReflexiveFormIndex in reflexiveForms) {
                    const eachReflexiveForm = reflexiveForms[eachReflexiveFormIndex];
                    this.validateAllFormFields(eachReflexiveForm);

                }
                for (const eachRepeatFormIndex in repeatForms) {
                    const eachRepeatForm = repeatForms[eachRepeatFormIndex];
                    this.validateAllFormFields(eachRepeatForm);

                }
                for (const eachHealthFormIndex in healthForms) {
                    const eachHealthForm = healthForms[eachHealthFormIndex];
                    this.validateAllFormFields(eachHealthForm);
                }
            } else if (this.interviewService.shouldWaitForAnyIntegration()) {
                this.notificationService.showNotification({ severity: NotificationSeverity.Error, message: 'Wait for running integration(s) to complete' });
            }
        } finally {
            this.disableSave = false;
        }
    }

    getSectionTypeLabel(id) {
        if (id && this.sectionSummaries && this.sectionSummaries.length) {
            return _.find(this.sectionSummaries, ['id', id]).name;
        } else {
            return '';
        }
    }

    findPreviousEnabledSection(currentSection) {
        const currentIndex = this.sectionSummaries.findIndex(s => s.id === currentSection.id);
        const prevIndex = currentIndex - 1;
        const prevSection = this.sectionSummaries[prevIndex];
        const prevSectionData = this.interviewService.getInterviewData().sections.find(s => s.id === prevSection.id);

        if (prevSection.isDisabled || !prevSectionData) {
            this.findPreviousEnabledSection(prevSection);
        } else {
            // TECH DEBT: This appears to be picking the "summary" object and passing it as `InterviewSectionDTO`.
            // This can't be the 'real' DTO because it could be missing (never created). But that means we'll need to
            // update `selectSection` to handle either type.  That will have knock-on effects that we'll have to address.
            this.selectSection(prevSection as InterviewSectionDTO);
        }
    }

    selectPreviousSection(currentSection) {
        if (currentSection.isThirdParty && !this.calledFromThirdParty) {
            this.thirdPartySectionService.moveToPrevious();
        } else {
            this.interviewService.checkStatus(currentSection).subscribe(response => {
                this.calledFromThirdParty = false;
                if (response.data === true) {
                    this.previousDisabled = false;
                    this.updateSectionStyles();
                    this.findPreviousEnabledSection(currentSection);
                } else {
                    for (const section of this.sectionSummaries) {
                        section.isDisabled = true;
                        this.previousDisabled = true;
                    }
                }
            });
        }
    }

    validateAllFormFields(form) {
        Object.keys(form.controls).forEach(field => {
            const control = form.get(field);
            if (control instanceof UntypedFormControl) {
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof UntypedFormGroup) {
                this.validateAllFormFields(control);
            }
        });
    }

    getCaseStatusLabel() {
        if (this.configData.caseStatus && this.configData.caseStatus.length && this.getInterviewData().caseDetails) {
            // case status is sent as id, show it's description using configdata
            this.caseStatus = this.configData.caseStatus.find(c => c.id === this.getInterviewData().caseDetails.statusId).name;
        } else {
            return '';
        }
    }

    getSetOtherCaseData() {
        const currentOpenCase = localStorage.getItem('currentOpenCase');

        const currentCase = JSON.parse(currentOpenCase);

        // if the same interview with same case id is already open in another tab, navigate to dashboard.
        if (this.isCaseOpen(currentCase)) {
            this.notificationService.showNotification({ severity: NotificationSeverity.Error, message: 'The application is already open in another window', log: true });
            this.routingService.navigateToDefault();
            return;
        } else {
            localStorage.setItem('currentOpenCase', JSON.stringify({
                caseId: this.getInterviewData().caseDetails.id,
                activeTime: Date.now()
            }));
            this.clearStorage = true;

            this.caseOpenUpdateTimer = setInterval(() => {
                const currentOpenCase = localStorage.getItem('currentOpenCase');
                const currentCase = JSON.parse(currentOpenCase);
                if (currentCase) {
                    currentCase.activeTime = Date.now();
                }
            }, 1000);
        }

        // this route guard prevents navigating to other locations unless case is set to onhold.
        this.activateRouteGuard = true;

        this.monitorCaseUpdates();
    }

    isCaseOpen(currentCase) {
        let result = false;

        if (!currentCase) {
            return false;
        }

        if (this.getInterviewData().caseDetails.id === currentCase.caseId
            && !this.interviewService.isReadOnly
            && !this.isRefresh) {
            result = true;
        }

        const isOlderthanXSeconds = (Date.now() - currentCase.activeTime) > 15000;

        if (result && isOlderthanXSeconds) {
            result = false;
        }

        return result;
    }


    async autoSaveInterview() {
        if (this.caseStatus && this.interviewService.interviewUpdated) {
            await this.interviewService.autoSaveInterview(this.accountInfo.id, 'AutoSave', null);
        }
    }

    updateSectionStyles() {
        const interviewSections = this.getInterviewData().sections;
        const activeSectionOrder = interviewSections.find(s => s.id === this.getInterviewData().activeSectionId).order;

        const updatedSectionSummaries: InterviewSectionSummaryDTO[] = [];
        if (this.sectionSummaries) {
            for (const section of this.sectionSummaries) {
                const updatedSection = { ...section };

                updatedSection.isActive = false;
                updatedSection.isErrors = false;
                updatedSection.isCompleted = false;
                updatedSection.isDisabled = false;
                {
                    if (this.interviewMode !== 'readonly' && updatedSection.order > activeSectionOrder) {
                        updatedSection.isDisabled = true;
                        this.setSectionActive(updatedSection, activeSectionOrder);
                    } else {
                        const interviewSection = interviewSections.find(s => s.id === updatedSection.id);
                        if (interviewSection) {
                            if (interviewSection.errors.length > 0 || interviewSection.impactFlag) {
                                updatedSection.isErrors = true;

                            } else if (interviewSection.status === 'DONOT_PROCESS') {
                                updatedSection.isDisabled = true;
                            } else {
                                updatedSection.isCompleted = true;
                            }

                            if (interviewSection.status === 'NEXT' && interviewSection.isDisabled) {
                                updatedSection.isDisabled = true;
                            }

                            if (this.interviewService.isInterviewLocked) {
                                updatedSection.isDisabled = true;
                            }

                        } else {
                            this.setSectionActive(updatedSection, activeSectionOrder);
                        }
                    }
                    this.setSectionActive(updatedSection, activeSectionOrder);
                }

                updatedSectionSummaries.push(updatedSection);
            }

            this.sectionSummaries = updatedSectionSummaries;
        }
    }

    setSectionActive(section: InterviewSectionSummaryDTO, activeSectionOrder: number) {
        if (section.order === activeSectionOrder) {
            section.isActive = true;
        }
    }

    verifyCaseStatus() {
        // when a case is getting cancelled , we ask for a popup to as whether the user really wants to cancel.
        this.confirmationService.confirm({
            message: `Are you sure you want to cancel the interview?`,
            key: 'interviewActionCancelConfirm',
            accept: () => {
                this.showCancelInterviewModal(true);
            },
            reject: () => {
                this.isCancellActionSelected = true;
            }
        });
    }

    updateInterviewBasedOnActions(questionActions) {
        const activeSectionId = this.getInterviewData().activeSectionId;
        const activeSection = this.getInterviewData().sections.find(s => s.id === activeSectionId);
        for (const eachActionResult of questionActions) {
            let questionDisplay = true;
            let questionDisable = activeSection.isLocked;

            const question = activeSection.questions.find(q => q.id === eachActionResult.questionsId);

            if (this.casePreActionsMultipleOverwriting) {
                //set existing disable so we don't overwrite it
                questionDisable = activeSection?.isLocked || question?.isLocked;
            }

            const questionAction = question == null ? null : question.questionActions.find(qa => qa.id === eachActionResult.id);
            if (!questionAction) {
                continue;
            }
            questionAction.actionResults = eachActionResult.actionResults;
            questionAction.triggerCount = eachActionResult.triggerCount;
            if (eachActionResult.actionResults && eachActionResult.actionResults.mapkeyValues) {
                this.interviewService.mergeMapkeys(eachActionResult.actionResults.mapkeyValues);
            }
            if (this.interviewWaitForIntegrationPrePopup && eachActionResult.actionResults?.isError &&
                eachActionResult.actionResults?.message === "You've already executed this action the maximum times" &&
                this.dialogRef?.componentInstance?.data?.message === this.interviewService.waitingForIntegrationDetails) {
                this.dialogRef.close();
            }
            if (eachActionResult.actionResults && eachActionResult.actionResults.data && (eachActionResult.actionResults.data.display === 'false' || eachActionResult.actionResults.data.display === false)) {
                // for preactions, this is used to set display property of question which is the only property used to hide/show question in form. No other property is used to hide/show question.
                questionDisplay = false;

                if (question.answerType === 'RepeatBlock' && question.blocks && question.blocks.length) {
                    this.interviewService.removeRepeatBlocks(question);
                    question.blocks.forEach(block => {
                        if (this.interviewService.repeatForms[block.repeatId]) {
                            delete this.interviewService.repeatForms[block.repeatId];
                        }
                    });
                    question.blocks = null;
                }
            }

            if (this.casePreActionsMultipleOverwriting && eachActionResult?.actionResults?.data && !Object.prototype.hasOwnProperty.call(eachActionResult?.actionResults?.data, 'display')) {
                questionDisplay = question.display;
            }

            if (!activeSection.isLocked
                && eachActionResult.actionResults
                && eachActionResult.actionResults.data
                // && eachActionResult.actionResults.data.hasOwnProperty('disabled')
                && Object.prototype.hasOwnProperty.call(eachActionResult.actionResults.data, 'disabled')
            ) {
                questionDisable = eachActionResult.actionResults.data.disabled;
            }

            // TODO: use configdata instead of action id's: using ids as of now as the config data's names are being changed regularly.
            if (eachActionResult.actions.id === '00000000-0000-0000-0000-000000000013') {
                // validate sum action
                const errorMessage = eachActionResult.actionResults && eachActionResult.actionResults.isError ? eachActionResult.actionResults.error.message : '';
                this.setErrorsForQuestion(question, errorMessage, errorMessage);
            }
            if (eachActionResult.actions.id === '00000000-0000-0000-0000-000000000016') {
                // Validate Sum
                const errorMessage = eachActionResult.actionResults && eachActionResult.actionResults.isError ? eachActionResult.actionResults.error.message : '';
                this.setErrorsForQuestion(question, errorMessage, errorMessage);
            }
            if (eachActionResult.actions.id === '00000000-0000-0000-0000-000000000004'
                && eachActionResult.actionResults != null
                && eachActionResult.actionResults.data != null
                && !this.isRestart) { // Case Status
                this.getInterviewData().caseDetails.statusId = eachActionResult.actionResults.data;
                this.getCaseStatusLabel();
            }
            if (eachActionResult.actions.id === '00000000-0000-0000-0000-000000000005' || eachActionResult.actions.id === '00000000-0000-0000-0000-000000000024') {
                // ssn validations
                if (eachActionResult.actionResults && eachActionResult.actionResults.data) {
                    if (eachActionResult.actionResults.data.hasDuplicateSSN) {
                        this.setErrorsForQuestion(question, true, eachActionResult.actionResults.data.message);
                        question.errorData = eachActionResult.actionResults.data;
                    }
                    const mapkeyId = eachActionResult.actionResults.data.mapkeyId;
                    this.interviewService.mergeMapkeys(eachActionResult.actionResults.mapkeyValues);
                    this.interviewService.callDependentQuestionActionsByMapKey(activeSection, mapkeyId);
                } else {
                    question.errorData = null;
                    this.setErrorsForQuestion(question, false, '');
                }
            }
            if (eachActionResult.actions.id === '00000000-0000-0000-0000-000000000006') {
                // policy num update action
                if (eachActionResult.actionResults && eachActionResult.actionResults.data && !eachActionResult.actionResults.isError) {
                    this.getInterviewData().caseDetails.policyNumber = eachActionResult.actionResults.data;
                }
            }
            if (eachActionResult.actions.id === '00000000-0000-0000-0000-000000000007' && eachActionResult.actionResults) {
                // giact action
                const giact = this.interviewService.getGiactData();
                giact.questionId = eachActionResult.questionsId;
                this.currentRightTabIndex = 1;
                giact.sourceAction = eachActionResult.id;
                giact.questionId = eachActionResult.questionsId;
                if (eachActionResult.actionResults.isError) {
                    this.notificationService.showNotification({ severity: NotificationSeverity.Error, message: eachActionResult.actionResults.message });
                }
                if (giact.status === QueueStatusEnum.Pending) {
                    if (this.checkIfNeededToWait(eachActionResult, giact)) {
                        this.waitForResponse = true;
                        giact.waitForResponse = true;
                        this.waitIntegration.push('giact');
                    }
                }
            }
            if (eachActionResult.actions.id === '00000000-0000-0000-0000-000000000012' && eachActionResult.actionResults) {
                // RNA Agent Intergace action
                const agent = this.interviewService.getAgentData();
                this.currentRightTabIndex = 1;
                agent.sourceAction = eachActionResult.id;
                agent.questionId = eachActionResult.questionsId;
                if (eachActionResult.actionResults.isError) {
                    this.notificationService.showNotification({ severity: NotificationSeverity.Error, message: eachActionResult.actionResults.message });
                }
                if (agent.status === QueueStatusEnum.Pending) {
                    if (this.checkIfNeededToWait(eachActionResult, agent)) {
                        this.waitForResponse = true;
                        agent.waitForResponse = true;
                        this.waitIntegration.push('agent');
                    }
                }
            }
            if (eachActionResult.actions.id === '00000000-0000-0000-0000-000000000011' && eachActionResult.actionResults) {
                // RNA quote interface action
                const quote = this.interviewService.getQuoteData();
                this.currentRightTabIndex = 1;
                quote.sourceAction = eachActionResult.id;
                quote.questionId = eachActionResult.questionsId;
                if (eachActionResult.actionResults.isError) {
                    this.notificationService.showNotification({ severity: NotificationSeverity.Error, message: eachActionResult.actionResults.message });
                }
                if (quote.status === QueueStatusEnum.Pending) {
                    if (this.checkIfNeededToWait(eachActionResult, quote)) {
                        this.waitForResponse = true;
                        quote.waitForResponse = true;
                        this.waitIntegration.push('quote');
                    }
                }
            }
            // RX
            if (eachActionResult.actions.id === '00000000-0000-0000-0000-000000000010' && eachActionResult.actionResults) {
                const rx = this.interviewService.getRxData();
                this.currentRightTabIndex = 1;
                rx.sourceAction = eachActionResult.id;
                rx.questionId = eachActionResult.questionsId;
                if (eachActionResult.actionResults.isError) {
                    this.notificationService.showNotification({ severity: NotificationSeverity.Error, message: eachActionResult.actionResults.message });
                }
                if (rx.status === QueueStatusEnum.Pending) {
                    if (this.checkIfNeededToWait(eachActionResult, rx)) {
                        this.waitForResponse = true;
                        rx.waitForResponse = true;
                        this.waitIntegration.push('rx');
                    }
                }
            }
            // MIB
            if (eachActionResult.actions.id === '00000000-0000-0000-0000-000000000009' && eachActionResult.actionResults) {
                const mib = this.interviewService.getMibData();
                this.currentRightTabIndex = 1;
                mib.sourceAction = eachActionResult.id;
                mib.questionId = eachActionResult.questionsId;
                if (eachActionResult.actionResults.isError) {
                    this.notificationService.showNotification({ severity: NotificationSeverity.Error, message: eachActionResult.actionResults.message });
                }
                if (mib.status === QueueStatusEnum.Pending) {
                    if (this.checkIfNeededToWait(eachActionResult, mib)) {
                        this.waitForResponse = true;
                        mib.waitForResponse = true;
                        this.waitIntegration.push('mib');
                    }
                }
            }

            if (eachActionResult.actions.integration_Id && eachActionResult.actions.integration_Id != null && eachActionResult.actions.integrationVersion == 2 && eachActionResult.actionResults) {
                const integrationV2 = this.interviewService.getIntegrationV2Data(eachActionResult.id);
                this.currentRightTabIndex = 1;
                integrationV2.sourceAction = eachActionResult.id;
                integrationV2.integrationId = eachActionResult.actions.integration_Id;
                integrationV2.name = eachActionResult.actions.name;
                integrationV2.questionId = eachActionResult.questionsId;
                integrationV2.status = (eachActionResult.actionResults.data && eachActionResult.actionResults.data.integrationStatus) ? eachActionResult.actionResults.data.integrationStatus : integrationV2.status;

                if (eachActionResult.actionResults.isError) {
                    this.notificationService.showNotification({ severity: NotificationSeverity.Error, message: eachActionResult.actionResults.message });

                    if (this.signatureLoadingDialogRef) {
                        this.signatureLoadingDialogRef.close();
                    }
                }

                if (integrationV2.status === QueueStatusEnum.Pending) {
                    if (this.checkIfNeededToWait(eachActionResult, integrationV2)) {
                        this.waitForResponse = true;
                        integrationV2.waitForResponse = true;
                        this.waitIntegration.push('integrationV2');
                    }
                }
            }

            if (eachActionResult.actions.id === '00000000-0000-0000-0000-000000000022' && eachActionResult.actionResults) {
                const docAction = this.interviewService.getIntegrationV2Data(eachActionResult.questionsId);
                this.currentRightTabIndex = 1;
                docAction.sourceAction = eachActionResult.id;
                docAction.name = eachActionResult.actions.name;
                docAction.questionId = eachActionResult.questionsId;
                docAction.status = (eachActionResult.actionResults.data && eachActionResult.actionResults.data.integrationStatus) ? eachActionResult.actionResults.data.integrationStatus : docAction.status;
                if (eachActionResult.actionResults.isError) {
                    this.notificationService.showNotification({ severity: NotificationSeverity.Error, message: eachActionResult.actionResults.message });
                }
                if (docAction.status === QueueStatusEnum.Pending) {
                    if (this.checkIfNeededToWait(eachActionResult, docAction)) {
                        this.waitForResponse = true;
                        docAction.waitForResponse = true;
                        this.waitIntegration.push('integrationV2');
                    }
                }
            }

            if ((eachActionResult.actions.id === '00000000-0000-0000-0000-000000000044' || eachActionResult.actions.id === '00000000-0000-0000-0000-000000000045')
                && eachActionResult.actionResults) {

                this.interviewService.updateQuestionPreActionsForSection(this.interviewService.getInterviewData().activeSectionId);
            }

            if (question.repeatFormId) {
                // adjusting display for repeat block questions.
                if (question.parentQuestionId === question.repeatFormId.split('block')[0]) {
                    question.display = questionDisplay;
                    question.isLocked = questionDisable;
                }
                const repeatQuestion = activeSection.questions.find(q => q.id === question.parentQuestionId);
                if (repeatQuestion.blocks) {
                    for (const eachRepeatBlock of repeatQuestion.blocks) {
                        const eachRepeatQuestion = eachRepeatBlock.questions.find(q => q.id === question.id);
                        if (eachRepeatQuestion) {
                            eachRepeatQuestion.display = questionDisplay;
                            eachRepeatQuestion.isLocked = questionDisable;
                        }
                    }
                }
            } else {
                // adjusting display for dyanamic form and reflexive form questions.
                if (question.parentQuestionId === '00000000-0000-0000-0000-000000000000') {
                    question.display = questionDisplay;
                    question.isLocked = questionDisable;
                } else {
                    const refQuestion = activeSection.questions.find(q => q.id === question.parentQuestionId);
                    if (refQuestion) {
                        if (refQuestion.parentQuestionId === '00000000-0000-0000-0000-000000000000') {
                            if (this.interviewService.getDynamicForm()) {
                                if (this.interviewService.getDynamicForm().get(refQuestion.id)) {
                                    if (refQuestion.listValues && refQuestion.listValues.length) {
                                        const refAnswerValueCombo = refQuestion.listValues.find(p => p.value === this.interviewService.getDynamicForm().get(refQuestion.id).value);
                                        if (refAnswerValueCombo) {
                                            const thisQuestionRefAnswer = refAnswerValueCombo.reflexiveQuestions.find(p => p.refQuestionId === question.id);
                                            question.isLocked = questionDisable;
                                            if (thisQuestionRefAnswer) {
                                                question.display = questionDisplay;
                                            } else {
                                                question.display = false;
                                            }
                                        }
                                    }
                                }
                            }
                        } else {
                            const reflexiveForm = this.interviewService.reflexiveForms[refQuestion.parentQuestionId];
                            if (reflexiveForm && reflexiveForm.get(refQuestion.id)) {
                                if (refQuestion.listValues && refQuestion.listValues.length) {

                                    const selectedValues = reflexiveForm.get(refQuestion.id).value;
                                    let refAnswerValueCombo;
                                    
                                    if (Array.isArray(selectedValues)){
                                        selectedValues.forEach(val => {
                                            if (!refAnswerValueCombo) {
                                                refAnswerValueCombo = refQuestion.listValues.find(p => p.value === val);
                                            }
                                        });                                        
                                    }else{
                                        refAnswerValueCombo = refQuestion.listValues.find(p => p.value === selectedValues);
                                    }

                                    
                                    if (refAnswerValueCombo) {
                                        const thisQuestionRefAnswer = refAnswerValueCombo.reflexiveQuestions.find(p => p.refQuestionId === question.id);
                                        question.isLocked = questionDisable;
                                        if (thisQuestionRefAnswer) {
                                            question.display = questionDisplay;
                                        } else {
                                            question.display = false;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    setErrorsForQuestion(question, isError, errorData) {
        try {
            // if error exists, show validation error from backend server at field level itself.
            if (isError) {

                question.errorData = errorData;
                if (this.interviewService.getDynamicForm().get(question.id)) {
                    this.interviewService.getDynamicForm().get(question.id).setErrors({ validationError: errorData });
                }
                for (const eachReflexiveFormId in this.interviewService.reflexiveForms) {
                    const reflexiveForm = this.interviewService.reflexiveForms[eachReflexiveFormId];
                    if (reflexiveForm.get(question.id)) {
                        reflexiveForm.get(question.id).setErrors({ validationError: errorData });
                    }
                }
                if (question.repeatFormId) {
                    const repeatQuestionId = question.repeatFormId.split('block')[0];
                    if (repeatQuestionId === question.parentQuestionId) {
                        const repeatFormKeys = [];
                        for (const eachKey in this.interviewService.repeatForms) {
                            if (eachKey.includes(repeatQuestionId)) {
                                repeatFormKeys.push(eachKey);
                            }
                        }
                        for (const eachRepeatFormKey of repeatFormKeys) {
                            const repeatForm = this.interviewService.repeatForms[eachRepeatFormKey];

                            if (repeatForm && repeatForm.get(question.id)) {
                                repeatForm.get(question.id).setErrors({ validationError: errorData });

                            }
                        }
                    }
                }
                if (question.answerType === 'ActionButton') {
                    this.scrollToError(question.id);
                }
            } else {
                // if no error then remove all previous errors from backend at field level.
                question.errorData = '';
                if (this.interviewService.getDynamicForm().get(question.id)) {
                    this.interviewService.getDynamicForm().get(question.id).setErrors(null);

                }
                for (const eachReflexiveFormId in this.interviewService.reflexiveForms) {
                    const reflexiveForm = this.interviewService.reflexiveForms[eachReflexiveFormId];
                    if (reflexiveForm.get(question.id)) {
                        reflexiveForm.get(question.id).setErrors(null);

                    }
                }
                if (question.repeatFormId) {
                    const repeatQuestionId = question.repeatFormId.split('block')[0];
                    const repeatFormKeys = [];
                    for (const eachKey in this.interviewService.repeatForms) {
                        if (eachKey.includes(repeatQuestionId)) {
                            repeatFormKeys.push(eachKey);
                        }
                    }
                    for (const eachRepeatFormKey of repeatFormKeys) {
                        const repeatForm = this.interviewService.repeatForms[eachRepeatFormKey];

                        if (repeatForm && repeatForm.get(question.id)) {
                            repeatForm.get(question.id).setErrors(null);

                        }
                    }
                }
            }
        } catch (error) {
            // do nothing
        }
    }

    checkIfNeededToWait(actionResult, integration?) {
        if (this.interviewWaitForIntegrationPrePopup) {
            return this.interviewService.checkIfNeededToWait(actionResult, integration);
        }
        let waitResult = false;
        let waitActionParam;
        let statusMessage;
        let statusDisplayType;

        const configActionData = this.configData.actions.find(a => a.id === actionResult.actions.id);
        if (configActionData) {
            if (configActionData.actionParams && configActionData.actionParams.length > 0) {
                waitActionParam = configActionData.actionParams.find(p => p.params == 'waitForResponse');

                if (waitActionParam) {
                    if (actionResult.actionParameters && actionResult.actionParameters.length > 0) {
                        const waitActionObj = actionResult.actionParameters.find(x => x.actionParamsId == waitActionParam.id);

                        if (waitActionObj && waitActionObj.paramValue.toLowerCase() === 'true') {
                            waitResult = true;
                        }
                    }
                }
            }
        }

        if (waitResult) {
            statusMessage = configActionData.actionParams.find(p => p.params === 'statusMessage');
            statusDisplayType = configActionData.actionParams.find(p => p.params === 'statusDisplayType');
            let displayTypeObj: any;
            let statusMessageObj: any;
            if (statusDisplayType) {
                displayTypeObj = actionResult.actionParameters.find(x => x.actionParamsId == statusDisplayType.id);
            }

            if (statusMessage) {
                statusMessageObj = actionResult.actionParameters.find(x => x.actionParamsId == statusMessage.id);
            }

            if (displayTypeObj && displayTypeObj.paramValue.toLowerCase() == 'popup') {
                const pendingIntegration: IntegrationPending = {
                    actionName: actionResult.actions.displayName,
                    actionStatus: this.interviewService.getIntegrationStatus(actionResult.actionResults.data.integrationStatus),
                    message: statusMessageObj.paramValue,
                    integration: integration
                };


                this.displayPopup(pendingIntegration);
            }
        }
        return waitResult;
    }

    displayPopup(pendingIntegration: IntegrationPending, width = 450) {
        this.appService.forceHideLoadingIndicator = true;

        if (pendingIntegration?.integration?.integrationId === IntegrationEnum.Docusign && this.useDocusignV2) {
            return;
        }

        if (this.dialogRef != null) {
            this.dialogRef.componentInstance.data.message = pendingIntegration.message;
            this.dialogRef.componentInstance.data.actionStatus = pendingIntegration.actionStatus;
            return;
        }

        if (!pendingIntegration.integration.status || pendingIntegration.integration.status === QueueStatusEnum.Pending) {
            this.dialogRef = this.dialog.open(ActionDialogComponent, {
                width: `${width}px`,
                data: pendingIntegration,
                disableClose: true
            });

            const sub = this.dialogRef.componentInstance.onRefresh.subscribe((integrationData) => {
                this.refreshIntegration(integrationData.integrationId, integrationData, integrationData.name);
            });

            this.dialogRef.afterClosed().subscribe(() => {
                sub.unsubscribe();
                this.dialogRef = null;
                this.appService.forceHideLoadingIndicator = false;
            });
        }
    }

    processSectionsActions(section) {
        if (section.sectionActions && section.sectionActions.length > 0) {
            for (const eachActionResult of section.sectionActions) {
                // GIACT
                if (eachActionResult.actions.id === '00000000-0000-0000-0000-000000000007' && eachActionResult.actions.actionResults) {
                    if (!this.interviewService.getGiactData().status) {
                        this.interviewService.getGiactData().status = '00000000-0000-0000-0000-000000000002';
                        this.currentRightTabIndex = 1;
                    }
                }
                // RX
                if (eachActionResult.actions.id === '00000000-0000-0000-0000-000000000010' && eachActionResult.actions.actionResults) {
                    if (!this.interviewService.getRxData().status) {
                        this.interviewService.getRxData().status = '00000000-0000-0000-0000-000000000002';
                        this.currentRightTabIndex = 1;
                    }

                }
                // MIB
                if (eachActionResult.actions.id === '00000000-0000-0000-0000-000000000009' && eachActionResult.actions.actionResults) {
                    if (!this.interviewService.getMibData().status) {
                        this.interviewService.getMibData().status = '00000000-0000-0000-0000-000000000002';
                        this.currentRightTabIndex = 1;
                    }
                }

                if (eachActionResult.actions.id === '00000000-0000-0000-0000-000000000013' && eachActionResult.actionResults) {
                    if (eachActionResult.actionResults.isError) {
                        this.notificationService.showNotification({ severity: NotificationSeverity.Error, message: eachActionResult.actionResults.message });
                    }
                }

                if (eachActionResult.actions.id === ActionEnum.Disabled && eachActionResult.actionResults) {
                    section.isLocked = eachActionResult.actionResults.data.disabled;
                }

            }
        }
    }

    updateMIBRxData(mibrxData) {
        if (mibrxData.type === 'mib') {
            this.interviewService.getMibData().status = 'success';
            // process mib response

        }
        if (mibrxData.type === 'rx') {
            this.interviewService.getRxData().status = 'success';
            // prcess rx response
        }
    }

    cancelInterview() {
        const caseDetails = this.interviewService.getInterviewData().caseDetails;

        if (caseDetails) {
            this.logCaseNumberAndDescriptionToMonitorService(`Cancel Interview`);
            this.cancelDialog = true;
            this.showCancelInterviewModal();
        } else {
            this.back();
        }
    }


    terminate() {
        this.interviewTerminateForm.patchValue({
            terminateMessage: '',
        });
        this.cancelDialog = false;
        this.textDialog = true;
    }

    canDeactivate() {
        if (this.activateRouteGuard) {
            if (this.interviewService.isReadOnly
                || !this.getInterviewData().caseDetails
                || this.getInterviewData().workflowCompleted) {
                return true;
            }

            if (this.interviewService.clickSource !== InterviewCloseEnum.SignOut && this.interviewService.clickSource !== InterviewCloseEnum.ApplicantTerminate && !this.idleService.loggingOut) {
                this.cancelDialog = true;
                this.showCancelInterviewModal();
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    submitTerminateReason() {
        if (this.interviewTerminateForm.valid) {
            this.logCaseNumberAndDescriptionToMonitorService(`Terminate Interview`);
            this.textDialog = false;
            this.interviewService.autoSaveInterview(this.accountInfo.id, 'TerminateInterview', this.interviewTerminateForm.value.terminateMessage);
            this.interviewService.flushInterviewMetrics();
        }
    }

    interviewOnHold() {
        this.logCaseNumberAndDescriptionToMonitorService(`Put Interview on Hold`);
        this.interviewService.autoSaveInterview(this.accountInfo.id, 'InterviewOnHold', '');
        this.interviewService.flushInterviewMetrics();
    }

    runIdleTimer() {
        this.idleSecondsCounter++;
        if (this.idleSecondsCounter >= this.IDLE_TIMEOUT) {
            this.autoSaveInterview();
            this.idleSecondsCounter = 0;
        }
    }


    copyJSONToClipboard(htmlElement) {
        htmlElement.select();
        document.execCommand('copy');
    }



    async webhookResponseHandler(event, me) {
        if (event === undefined) {
            console.log('Webhook response handler called with out data');
            return;
        }

        // socket response data
        const actionResponse = (typeof event === 'string') ? JSON.parse(event) : event;
        me.interviewService.mergeMapkeys(actionResponse.details.mapkeys);

        const interviewData = me.getInterviewData();
        const activeSectionId = interviewData.activeSectionId;
        if (interviewData.caseDetails.statusId === CaseStatus.Submitted || (this.interviewService.isInterviewSaving && actionResponse.integrationName === 'DocuSign: eSig')) {
            return;
        }

        me.interviewService.updateQuestionPreActionsForSection(activeSectionId);
    }

    async integrationResponseHandler(event, me) {
        if (event === undefined) {
            console.log('Integration response handler called with out data');
            return;
        }

        // socket response data
        const actionResponse = (typeof event === 'string') ? JSON.parse(event) : event;

        let isIntegrationStatusCompleted = false;

        if (actionResponse.responseType === 'document') {
            isIntegrationStatusCompleted = this.downloadDocument(actionResponse, me);
        } else if (actionResponse.responseType === 'integration') {
            isIntegrationStatusCompleted = this.processIntegrationResponse(actionResponse, me);
        } else if (actionResponse.responseType === 'V2') {
            isIntegrationStatusCompleted = this.processIntegrationResponseV2(actionResponse, me);
        }

        if (isIntegrationStatusCompleted) {
            if (actionResponse.details.updatedMapKeys) {
                const dynamicListMapkeys = _.filter(actionResponse.details.updatedMapKeys, function (e) {
                    return e.name.toLowerCase().startsWith("account");
                });
                dynamicListMapkeys.forEach(element => {
                    const currentValue = JSON.parse(element.value);
                    if (Array.isArray(currentValue) && currentValue.length > 0) {
                        const currentConstValue = { [currentValue[0].mapKeysId]: currentValue };

                        const c = _.extend(this.constantLists, currentConstValue);
                        this.constantLists = c;
                        this.interviewService.constantLists = this.constantLists;
                    }
                });
            }

            if (!this.interviewSectionActionService.sectionConfiguredToWait(me.getInterviewData().activeSectionId, me.getInterviewData().visibleSections)
                || !this.interviewSectionActionService.isActive) {
                await this.caseMapKeyService.updateAndMergeVirtualMapKeys(me.getInterviewData().caseDetails.accountId, me.getInterviewData().caseDetails.id);
            } else {
                await this.interviewSectionActionService.rerunPopupRule(me.getInterviewData().caseDetails.accountId, me.getInterviewData().caseDetails.id, me.getInterviewData().activeSectionId, me.getInterviewData().visibleSections);
            }

            if (this.dialogRef) {
                if ((actionResponse.integrationId !== IntegrationEnum.Docusign && this.dialogRef.componentInstance.data && this.dialogRef.componentInstance.data.integrationId !== IntegrationEnum.Docusign) ||
                    (actionResponse.integrationId === IntegrationEnum.Docusign && this.dialogRef.componentInstance.data && this.dialogRef.componentInstance.data.integrationId === IntegrationEnum.Docusign)) {
                    this.dialogRef.close();
                }
            }

            if (event.integrationRequestId != null) {
                let integrationName = '';
                if (actionResponse.responseType === 'V2') {
                    integrationName = actionResponse.integrationName;
                }

                this.monitorService.logCaseEvent(`Client - Integration ${integrationName} complete`, event.caseDetailId, event.caseIntegrationQueueId, CustomEventType.IntegrationMetric, CustomEventLogMode.Standalone);
            }

            const interviewData = me.getInterviewData();
            const activeSectionId = interviewData.activeSectionId;
            if (interviewData.caseDetails.statusId === CaseStatus.Submitted || (this.interviewService.isInterviewSaving && actionResponse.integrationName === 'DocuSign: eSig')) {
                return;
            }

            me.interviewService.updateQuestionPreActionsForSection(activeSectionId);
        }
    }

    downloadDocument(documentResponse, me) {
        let isIntegrationStatusCompleted = false;
        const documentQueueId = documentResponse.details;
        const docData = me.interviewService.getIntegrationV2Data(documentResponse.integrationRequestId);
        docData.details = documentResponse.details;
        docData.status = documentResponse.status;
        docData.errorMessage = documentResponse.message;
        me.interviewService.updateIntegrationActionResult(docData);
        if (this.interviewService.getIntegrationStatus(docData.status) === 'Completed' && documentQueueId) {
            isIntegrationStatusCompleted = true;

            this.caseSummaryService.getCaseDocumentFromQueue(documentQueueId).subscribe(
                {
                    next: (result) => {
                        this.page = 1;
                        this.pdfResult = result.objectUrl;
                        this.targetElement = document.querySelector('#myPdfViewer');
                        const calculateWidth = this.isDesktop() ? '55%' : '90%';
                        const dialogRef = this.dialog.open(PdfViewerDialog, {
                            minWidth: calculateWidth,
                            height: '90%',
                            maxWidth: '90%',
                            data: this.pdfResult,
                            panelClass: 'mat-dialog--no-padding'
                        });
                        //this.disableScroll();

                        // dialogRef.afterClosed().subscribe(r => {
                        //     enableBodyScroll(this.myPdfViewer);
                        // });
                    },
                    error: (error) => {
                        this.appService.showResponseErrorMsg(error);
                    }
                });
        }
        return isIntegrationStatusCompleted;
    }

    // disableScroll() {
    //    // disableBodyScroll(this.myPdfViewer);
    // }

    processIntegrationResponseV2(integrationData, me) {
        let isIntegrationStatusCompleted = false;

        if (integrationData.integrationType === 'V2' || integrationData.responseType === 'V2') {
            if (!integrationData.details.integrationRequestId) {
                integrationData.details = JSON.parse(integrationData.details);
            }

            const v2Data = me.interviewService.getIntegrationV2Data(integrationData.details.integrationRequestId);

            if (v2Data.integrationId == null || v2Data.integrationId === '') {
                v2Data.integrationId = integrationData.integrationId;
                v2Data.name = integrationData.integrationName;
            }

            isIntegrationStatusCompleted = true;
            v2Data.details = integrationData.details;
            v2Data.status = integrationData.details.status;
            v2Data.errorMessage = integrationData.details.message;
            me.interviewService.updateIntegrationActionResult(v2Data);
            me.interviewService.mergeMapkeys(v2Data.details.updatedMapKeys);

            if (v2Data.details.updatedMapKeys) {
                for (const mapKey of v2Data.details.updatedMapKeys) {
                    me.populateFieldsByMapKeyName(mapKey.name, mapKey.value);
                }
            }

            this.interviewService.completeIntegrationMetric(v2Data.integrationId);

            me.currentRightTabIndex = 1;

            if (v2Data.integrationId === IntegrationEnum.Docusign) {
                if (v2Data.status === QueueStatusEnum.Completed) {

                    const envelope = _.get(integrationData, 'details.clientProperties.envelope');
                    if (envelope != null && v2Data.id !== IntegrationEnum.Docusign && this.interviewService.popupIntegrationResponse(v2Data.questionId, v2Data.id)) {
                        this.showSignatureDialog(integrationData.caseDetailId, integrationData.caseIntegrationQueueId, envelope);
                    }
                } else if (v2Data.status === QueueStatusEnum.Failed) {
                    this.notificationService.showNotification({ severity: NotificationSeverity.Error, message: v2Data.errorMessage, log: true });
                    if (this.signatureLoadingDialogRef) {
                        this.signatureLoadingDialogRef.close();
                    }
                }
            }

            if (v2Data.integrationId === IntegrationEnum.BrainTree) {
                this.braintreeIntegrationResponseHandler.handleResponse(this.client.id, this.accountInfo.id, v2Data, integrationData);
            }

            if (v2Data.integrationId === IntegrationEnum.Aura) {
                this.auraIntegrationResponseHandler.handleResponse(this.client.id, this.accountInfo.id, v2Data, integrationData);
            }

            if (v2Data.integrationId === IntegrationEnum.TrustCommerce) {
                this.trustCommerceIntegrationResponseHandler.handleResponse(this.client.id, this.accountInfo.id, v2Data, integrationData);
            }

            if (v2Data.integrationId === IntegrationEnum.Stripe) {
                this.stripeIntegrationResponseHandler.handleResponse(this.client.id, this.accountInfo.id, v2Data, integrationData);
            }

            if (v2Data.integrationId === "00000000-0000-0000-0000-000000000056") {
                this.primorisIntegrationResponseHandler.handleResponse(this.client.id, this.accountInfo.id, v2Data, integrationData);
            }

            if (v2Data.integrationId === IntegrationEnum.PortalOne) {//oneINC
                const sbliOneIncPaymentResponse = _.get(integrationData, 'details.clientProperties');
                this.portalOneComponentIntegrationHandler.handleResponse(this.client.id, this.accountInfo.id, sbliOneIncPaymentResponse, integrationData);
            }
            if (v2Data.integrationId === IntegrationEnum.LexistNexisLifeDataPrefill) {
                this.filterableListService.updateFilterableListAsync(this.constantLists, this.interviewMode);
            }
        }

        return isIntegrationStatusCompleted;
    }

    showSignatureDialog(caseId: string, caseIntegrationQueueId: string, envelope: SignatureEnvelope) {
        if (this.signatureLoadingDialogRef) {
            this.signatureLoadingDialogRef.close();
        }

        if (this.useDocusignV2) {
            this.dialog.open(SignatureEnvelopeDialogV2Component, {
                minWidth: '29.6rem',
                disableClose: true,
                data: {
                    caseId: caseId,
                    caseIntegrationQueueId: caseIntegrationQueueId,
                    envelope,
                    onEnvelopeUpdate: this.onEnvelopeUpdate,
                    accountId: this.accountInfo.id,
                    mapKeys: this.interviewService.getInterviewData().caseDetails.mapkeys,
                    integrationId: IntegrationEnum.Docusign,
                    signalrConnection: this.signalrConnection
                }
            }).afterClosed().subscribe(_response => {
                this.checkInterviewStatus();
            });

        } else {
            this.dialog.open(SignatureEnvelopeDialogComponent, {
                width: '60%',
                disableClose: true,
                data: {
                    caseId: caseId,
                    caseIntegrationQueueId: caseIntegrationQueueId,
                    envelope,
                    onEnvelopeUpdate: this.onEnvelopeUpdate,
                    accountId: this.accountInfo.id,
                    mapKeys: this.interviewService.getInterviewData().caseDetails.mapkeys,
                    integrationId: IntegrationEnum.Docusign
                }
            }).afterClosed().subscribe(_response => {
                this.checkInterviewStatus();
            });
        }
    }


    checkInterviewStatus() {
        this.interviewService.checkStatus(this.selectedSection).subscribe(response => {
            if (response.data === true) {
                this.previousDisabled = false;
                this.updateSectionStyles();
            } else {
                for (const section of this.sectionSummaries) {
                    section.isDisabled = true;
                    this.previousDisabled = true;
                }
            }
        });
    }

    processIntegrationResponse(integrationData, me) {
        let isIntegrationStatusCompleted = false;
        if (integrationData.integrationType === 'RX') {
            // if data is received, set corresponding integration status to "Completed" which is shown in the right panel.
            const rx = me.interviewService.getRxData();
            isIntegrationStatusCompleted = true;
            if (!integrationData.details.rxDetails) {
                integrationData.details = JSON.parse(integrationData.details);
            }
            rx.details = integrationData.details.rxDetails.jsonData;
            rx.status = rx.details.status;
            rx.errorMessage = rx.details.message;
            me.interviewService.updateIntegrationActionResult(rx);
            me.interviewService.setCaseData(integrationData.details.rxDetails);
            me.interviewService.mergeMapkeys(integrationData.details.mapkeys);
            me.currentRightTabIndex = 1;
            if (me.waitIntegration.includes('rx') && me.waitForResponse) {
                rx.waitForResponse = me.waitForResponse = false;
                me.waitIntegration = me.waitIntegration.filter(function (item) {
                    return item !== 'rx';
                });
            }
        }
        if (integrationData.integrationType === 'Quote') {
            const quote = me.interviewService.getQuoteData();
            isIntegrationStatusCompleted = true;
            if (!integrationData.details.quoteDetails) {
                integrationData.details = JSON.parse(integrationData.details);
            }
            quote.details = integrationData.details.quoteDetails.jsonData;
            quote.status = quote.details.status;
            quote.errorMessage = quote.details.message;
            me.interviewService.updateIntegrationActionResult(quote);
            me.interviewService.setCaseData(integrationData.details.quoteDetails);
            me.interviewService.mergeMapkeys(integrationData.details.mapkeys);
            if (quote.status === '00000000-0000-0000-0000-000000000003') {
                me.populateFieldsByMapKeyName('Case_Applicant_MonthlyPremAmt', quote.details.quoteValues[0].monthlyPremAmt);
                me.populateFieldsByMapKeyName('Case_Applicant_QuarterlyPremAmt', quote.details.quoteValues[0].quarterlyPremAmt);
                me.populateFieldsByMapKeyName('Case_Applicant_SemiAnnualPremAmt', quote.details.quoteValues[0].semiAnnualPremAmt);
                me.populateFieldsByMapKeyName('Case_Applicant_AnnualPremAmt', quote.details.quoteValues[0].annualPremAmt);
            }
            if (me.waitIntegration.includes('quote') && me.waitForResponse) {
                quote.waitForResponse = me.waitForResponse = false;
                me.waitIntegration = me.waitIntegration.filter(function (item) {
                    return item !== 'quote';
                });
            }
        }
        if (integrationData.integrationType === 'Agent') {
            const agent = me.interviewService.getAgentData();
            isIntegrationStatusCompleted = true;
            if (!integrationData.details.agentDetails) {
                integrationData.details = JSON.parse(integrationData.details);
            }
            agent.details = integrationData.details.agentDetails.jsonData;
            agent.status = agent.details.status;
            agent.errorMessage = agent.details.message;
            me.interviewService.updateIntegrationActionResult(agent);
            me.interviewService.setCaseData(integrationData.details.agentDetails);
            me.interviewService.mergeMapkeys(integrationData.details.mapkeys);
            // fill the coresponding mapkeys based on data received.
            let agentMapKeyNames = [];
            if (agent.status === '00000000-0000-0000-0000-000000000004') {
                // Failed
                agentMapKeyNames = [
                    { mapkey: 'Case_Agent_FirstName', value: '' },
                    { mapkey: 'Case_Agent_LastName', value: '' },
                    { mapkey: 'Case_Agent_BusinessName', value: '' },
                    { mapkey: 'Case_Agent_IsActive', value: '' }
                ];
            } else if (integrationData.details.agentNumberMapkey === 'Case_Agent_AgentNumber') {
                if (!agent.details.agentInfos) {
                    const d = JSON.parse(integrationData.details.agentDetails.jsonData);
                    agent.details = d;
                }

                agentMapKeyNames = [
                    { mapkey: 'Case_Agent_FirstName', value: agent.details.agentInfos[0].agentNameFirst },
                    { mapkey: 'Case_Agent_LastName', value: agent.details.agentInfos[0].agentNameLast },
                    { mapkey: 'Case_Agent_BusinessName', value: agent.details.agentInfos[0].agentBusinessName },
                    { mapkey: 'Case_Agent_IsActive', value: agent.details.agentInfos[0].isActive }
                ];
            } else {
                agentMapKeyNames = [
                    { mapkey: 'Case_Agent2_FirstName', value: agent.details.agentInfos[1].agentNameFirst },
                    { mapkey: 'Case_Agent2_LastName', value: agent.details.agentInfos[1].agentNameLast },
                    { mapkey: 'Case_Agent2_BusinessName', value: agent.details.agentInfos[1].agentBusinessName },
                    { mapkey: 'Case_Agent2_IsActive', value: agent.details.agentInfos[1].isActive }
                ];
            }
            for (const agentMapKeyName of agentMapKeyNames) {
                me.populateFieldsByMapKeyName(agentMapKeyName.mapkey, agentMapKeyName.value);
            }
            if (me.waitIntegration.includes('agent') && me.waitForResponse) {
                agent.waitForResponse = me.waitForResponse = false;
                me.waitIntegration = me.waitIntegration.filter(function (item) {
                    return item !== 'agent';
                });
            }
        }
        if (integrationData.integrationType === 'MIB') {
            const parentQuestionMap = [];
            const mib = me.interviewService.getMibData();
            isIntegrationStatusCompleted = true;
            if (!integrationData.details.mibDetails) {
                integrationData.details = JSON.parse(integrationData.details);
            }
            mib.details = integrationData.details.mibDetails.jsonData;
            mib.status = mib.details.status;
            mib.errorMessage = mib.details.message;
            me.interviewService.updateIntegrationActionResult(mib);
            me.interviewService.setCaseData(integrationData.details.mibDetails);
            me.interviewService.mergeMapkeys(integrationData.details.mapkeys);
            me.currentRightTabIndex = 1;
            const questionMibMap = {};
            if (me.waitIntegration.includes('mib') && me.waitForResponse) {
                mib.waitForResponse = me.waitForResponse = false;
                me.waitIntegration = me.waitIntegration.filter(function (item) {
                    return item !== 'mib';
                });
            }
            if (mib.status === '00000000-0000-0000-0000-000000000003') {
                // following logic adds the HIT details to corresponding health question.
                // this data is shown below the health question in the form of a table.
                for (const eachDetail of mib.details.parties) {
                    eachDetail.mibCodes.forEach(mibCode => {
                        if (mibCode.riskType) {
                            if (mibCode.mapkeyId && eachDetail.relationRoleCode) {
                                parentQuestionMap.push([mibCode.mapkeyId, eachDetail.relationRoleCode]);
                            }
                            Object.assign(mibCode, eachDetail);
                            delete mibCode.mibCodes;
                            questionMibMap[mibCode.mapkeyId] = questionMibMap[mibCode.mapkeyId] ? questionMibMap[mibCode.mapkeyId] : [];
                            questionMibMap[mibCode.mapkeyId].push(mibCode);
                        }
                    });
                }
                const visiblequestionList = [];

                me.getInterviewData().sections.forEach(section => {
                    section.questions.forEach(question => {
                        if (question.healthQuestionDetail && question.healthQuestionDetail.length) {
                            if (question.healthQuestionDetail[0].questionId === question.id) {
                                for (let i = 0; i < parentQuestionMap.length; i++) {
                                    if (parentQuestionMap[i][0] === question.id) {
                                        if (parentQuestionMap[i][1].toLowerCase() === 'hit') {
                                            visiblequestionList.push(question.healthQuestionDetail[0].hitQuestionId);
                                            visiblequestionList.push(question.healthQuestionDetail[0].correctedQuestionId);
                                        }
                                        if (parentQuestionMap[i][1].toLowerCase() === 'try') {
                                            visiblequestionList.push(question.healthQuestionDetail[0].tryQuestionId);
                                            visiblequestionList.push(question.healthQuestionDetail[0].correctedQuestionId);
                                        }
                                    }
                                }
                            }
                        }
                    });
                });
                me.getInterviewData().sections.forEach(section => {
                    section.questions.forEach(question => {
                        if (questionMibMap[question.mapkeysId] && questionMibMap[question.mapkeysId].length) {
                            question.mibDetails = questionMibMap[question.mapkeysId];
                        }
                    });
                });
            }
        }
        if (integrationData.integrationType === 'GIACT') {
            const giact = me.interviewService.getGiactData();
            isIntegrationStatusCompleted = true;
            if (!integrationData.details.giactDetails) {
                integrationData.details = JSON.parse(integrationData.details);
            }
            giact.details = integrationData.details.giactDetails.jsonData;
            giact.status = giact.details.status;
            giact.errorMessage = giact.details.message;
            me.interviewService.updateIntegrationActionResult(giact);
            me.interviewService.setCaseData(integrationData.details.giactDetails);
            me.interviewService.mergeMapkeys(integrationData.details.mapkeys);
            me.currentRightTabIndex = 1;
            const activeSectionId = me.getInterviewData().activeSectionId;

            const currentSection = me.getInterviewData().sections.find(section => section.id === activeSectionId);
            const giactQuestion = currentSection.questions.find(q => q.id === giact.questionId);
            // get the question and show the giact response under the question.
            if (giactQuestion) {

                giactQuestion.integrationMessages = 'GIACT';
                giactQuestion.intergrationData = giact.details.message;
            }
            if (me.waitIntegration.includes('giact') && me.waitForResponse) {
                giact.waitForResponse = me.waitForResponse = false;
                me.waitIntegration = me.waitIntegration.filter(function (item) {
                    return item !== 'giact';
                });
            }
        }

        if (isIntegrationStatusCompleted) {
            const currentSection = this.interviewService.getActiveSection();

            const section = _.find(this.sectionSummaries, function (o) {
                return o.id === currentSection.id;
            });

            for (const sectionAction of section.sectionActions) {
                if (integrationData.integrationType === 'MIB' && sectionAction.actionsId === '00000000-0000-0000-0000-000000000009') {
                    integrationData.integrationRequestId = sectionAction.id;
                }
            }
        }


        return isIntegrationStatusCompleted;
    }
    populateFieldsByMapKeyName(mapKeyName, value) {
        this.interviewService.populateFieldsByMapKeyName(mapKeyName, value);
    }

    getApplicantAge(mapkey) {
        const date = this.getMapKeysValue(mapkey, true);
        if (!date || date === '') {
            return '';
        }
        const age = moment().diff(date, 'years');
        return (isNaN(age) ? '' : age);
    }

    getMapKeysValue(detailName, unformatted?: boolean) {
        let returnValue = '';
        const detailMapKeys = this.caseDetailsMapkeys[detailName];
        const detailAnswers = [];
        if (detailMapKeys && detailMapKeys.length) {
            for (const eachDetailMapKey of detailMapKeys) {
                this.getInterviewData().sections.forEach(section => {
                    section.questions.forEach(question => {
                        if (question.mapKeyName === eachDetailMapKey) {
                            if (eachDetailMapKey === 'Case_Applicant_DOB' && !unformatted) {
                                if (question.answerValue) {
                                    const result = this.formatDate(question.answerValue);
                                    detailAnswers.push(result);
                                }
                            } else {
                                detailAnswers.push(question.answerValue);
                            }
                        }
                    });
                });
            }
        }
        returnValue = returnValue + detailAnswers.join(' ');
        return returnValue;
    }

    setEmptyValueForQuestion(question) {
        if (this.interviewService.getDynamicForm().get(question.id)) {
            this.interviewService.getDynamicForm().get(question.id).setValue('');
        } else {
            for (const eachReflexiveFormId in this.interviewService.reflexiveForms) {
                const reflexiveForm = this.interviewService.reflexiveForms[eachReflexiveFormId];
                if (reflexiveForm.get(question.id)) {
                    reflexiveForm.get(question.id).setValue('');

                }
            }

        }
    }

    resetCancelQuestionIfAvailable() {
        this.textDialog = false;
        this.interviewService.clearCancelActionButton();
    }

    afterLoadComplete(pdfData: any) {
        this.totalPages = pdfData.numPages;
    }

    nextPage() {
        this.page++;
    }

    prevPage() {
        this.page--;
    }

    closePdf() {
        this.showPdf = false;
    }




    toggleSidenav() {
        this.sidenav.toggle();
        this.sidenavOpened = this.sidenav.opened;
    }

    isCaseDetailsClosed() {
        if (this.sidenav) {
            return !this.sidenav.opened;
        } else {
            return true;
        }
    }

    toggleShowInterviewJson(show: boolean) {
        if (show) {
            this.interviewJson = JSON.stringify(this.getInterviewData(), null, 2);
        }

        this.showInterviewJson = show;
    }

    showCancelInterviewModal(isTerminate = false) {
        this.endOfInterviewHandler.handleClose(this.accountInfo, InterviewCloseEnum.Interview, isTerminate);
    }

    scrollToError(target: any) {
        const config: ScrollToConfigOptions = {
            target: target,
            container: 'interviewForm'
        };
        this._scrollToService.scrollTo(config);
    }

    clearSectionJsonData() {
        this.caseSummaryService.clearSectionJsonData(this.caseId, this.accountInfo.id, this.formattedSection.id).subscribe(result => {
            if (result) {
                this.notificationService.showNotification({ severity: NotificationSeverity.Success, message: 'The section JSON data has been cleared. Refresh the page.' });
            } else {
                this.notificationService.showNotification({ severity: NotificationSeverity.Error, message: 'Problem clearing section JSON data', log: true });
            }
        });
    }

    getZoom() {
        return this.isDesktop() ? "80%" : "auto";
    }

    isDesktop() {
        return window.innerWidth > 1650;
    }
}
