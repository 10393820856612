<div class="question-container repeat-block" [formGroup]="group" [ngClass]="{active: isSelected, interviewQuestion: interviewMode}">
    <div class="question-header" *ngIf="!interviewMode" (click)="selectQuestion()">
        <button type="button" class="btn btn-link float-left" (click)="expanded = !expanded">
            <i aria-hidden="true" class="fa" [ngClass]="{'fa-angle-right': !expanded, 'fa-angle-down': expanded}"></i>
        </button>
        <span class="question-mapkey">
            {{config.mapKeyName}}
        </span>
        <span class="question-mapkey float-right pr-2">
            {{config.answerType}}
        </span>
    </div>
    <div class="question-body" *ngIf="expanded">
        <div class="question-text rendered-quill-content ql-editor" [innerHTML]="questionText"></div>
        <div class="help-text rendered-quill-content ql-editor" [innerHTML]="helpText" *ngIf="config.helpText?.length"></div>

        <div class="repeat-block-container">
            <!-- <div class="repeat-block-header">
            </div> -->
            <div class="repeat-block-body" *ngFor="let block of blocksUsed; let i = index;">
                <div>
                    <dynamic-form  [expandAllQuestions]="true"
                        [interviewMode]="'repeat'" [parentId]="block.repeatId" [parentIndex]="formIndex + '.' + i"
                        [constantLists]="constantLists"
                        [config]="block.questions"></dynamic-form>
                </div>

            </div>
            <div class="repeat-block-footer">
                <button class="btn btn-sm btn-link custom float-right" data-test-operation="remove" *ngIf="!isReadOnly() && blocksUsed?.length!=1" (click)="removeBlock()">
                    <i aria-hidden="true" class="fa fa-times"></i> Remove
                </button>
                <button class="btn btn-sm btn-link custom float-right mr-3" data-test-operation="add" *ngIf="!isReadOnly() && blocksUsed?.length!=blocks?.length" (click)="addBlock()">
                    <i aria-hidden="true" class="fa fa-plus"></i> Add
                </button>
            </div>
        </div>

    </div>
</div>
