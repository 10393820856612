import { CacheContent } from '@Models';
import { Utils } from '@Utils';

/**
 * Simple helper to determine if the cached value is expired or if we're being forced to refresh.
 * @param forceRefresh 
 * @param cached 
 * @returns 
 */
export function isExpired<T>(cached?: CacheContent<T>) {
    if (!cached) return true; // there's no cache (null/undefined)
    if (cached.expiry == 0) return false; // no expiration = 0
    return cached.expiry <= Date.now();
}

export function getCacheValueIfFresh<T>(cacheContent: CacheContent<T>): T {
    if (isExpired(cacheContent)) return null;
    return cacheContent.value;
}

export function getLocalStorage<T>(key: string, defaultValue: T = null): CacheContent<T> {
    const storageValue = localStorage.getItem(key);
    const value = Utils.parseJSONOrDefault<CacheContent<T>>(storageValue, { expiry: -1, value: defaultValue });
    return value;
}

export function getLocalStorageValue<T>(key: string, defaultValue: T = null): T {
    const cacheContent = getLocalStorage<T>(key);
    const value = getCacheValueIfFresh(cacheContent) ?? defaultValue;
    return value;
}

export function setLocalStorage<T>(key: string, value: T, expiry = 0): void {
    const content: CacheContent<T> = { expiry, value };
    localStorage.setItem(key, JSON.stringify(content));
}

export function getSessionStorage<T>(key: string): CacheContent<T> {
    const storageValue = sessionStorage.getItem(key);
    const value = Utils.parseJSONOrDefault<CacheContent<T>>(storageValue);
    return value;
}

export function getSessionStorageValue<T>(key: string, defaultValue: T = null): T {
    const cacheContent = getSessionStorage<T>(key);
    const value = getCacheValueIfFresh(cacheContent) ?? defaultValue;
    return value;
}

export function setSessionStorage<T>(key: string, value: T, expiry = 0): void {
    const content: CacheContent<T> = { expiry, value };
    sessionStorage.setItem(key, JSON.stringify(content));
}
