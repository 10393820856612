<div class="page-heading" id="subheading">
    <span class="page-heading-title">
        <ng-template [ngIf]="isNewAccount">New Account</ng-template>
        <ng-template [ngIf]="!isNewAccount">{{account?.name}} Configuration
            <span style="color: gray; font-size: 18px;">(v{{account.versionNumber}} - {{account.versionLabel}})</span>
        </ng-template>
    </span>

    <div class="d-inline float-right">
        <button *ngIf="isAdmin" mat-button class="mr-2 float-left" (click)="flushCache()" style="padding:0 .5rem;">
            <mat-icon>cached</mat-icon>
            Clear Cache
        </button>

        <button mat-button color="basic" class="mr-2 float-left" *ngIf="!isNewAccount && !accountInactive"
            (click)="startInterview()" [disabled]="disableStartInterview">
            <i aria-hidden="true" class="fa fa-file-alt"></i> Start Application
        </button>
        <button class="float-left" mat-button color="basic" (click)="back()">
            <i aria-hidden="true" class="fa fa-chevron-left"></i> Back
        </button>
    </div>
</div>

<div id="innercontent" class="custom-scrollbar account-config">

    <mat-tab-group class="flex-fill" (selectedTabChange)="tabChange($event)">
        <div *ngIf="!loadingData">
        <mat-tab label="Account" *ngIf="checkACL('Accounts', 'R')">
            <account-summary [accountInfo]="account" [clientId]="client.id" [isNewAccount]="isNewAccount"
                (accountSaved)="updateAccountInfo($event)"></account-summary>
        </mat-tab>
        <mat-tab label="Application" *ngIf="!isNewAccount && checkACL('Accounts', 'R')">
            <account-interview *ngIf="activatedTabs['Application']" [accountInfo]="account" [mapKeys]="mapKeys"
                [rules]="rules" [constantListMapKeys]="constantListMapKeys" [actions]="actions"
                [nonConstantMapKeys]="nonConstantMapKeys" [variableMapKeys]="variableMapKeys"
                (onActionsUpdated)="VerifyMibRxActions()" (newMapkeyAdded)="getMapKeys(true)"
                (onHealthQuestionUpdated)="getHealthQuestions()">
            </account-interview>
        </mat-tab>
        <mat-tab label="Rules" *ngIf="!isNewAccount && checkACL('Accounts', 'R')">
            <account-rules *ngIf="activatedTabs['Rules']" [accountInfo]="account" [mapKeys]="mapKeys"
                [nonListNonVirtualMapKeys]="nonListNonVirtualMapKeys" [variableMapKeys]="variableMapKeys"
                [nonVirtualMapKeys]="nonVirtualMapKeys" (newRuleAdded)="getRules()"></account-rules>
        </mat-tab>
        <mat-tab label="Integrations" *ngIf="!isNewAccount">
            <account-integrations *ngIf="activatedTabs['Integrations']" [accountInfo]="account"
                [hiddenIntegrations]="hiddenIntegrations"></account-integrations>
        </mat-tab>
        <mat-tab label="MIB Risk Map" *ngIf="!isNewAccount && showMibRisk">
            <account-mib *ngIf="activatedTabs['MIB Risk Map']" [accountInfo]="account"
                [healthQuestions]='healthQuestions'></account-mib>
        </mat-tab>
        <mat-tab label="Rx Risk Map" *ngIf="!isNewAccount && showRxRisk">
            <account-rx *ngIf="activatedTabs['Rx Risk Map']" [accountInfo]="account"></account-rx>
        </mat-tab>
        <mat-tab label="Underwriting" *ngIf="!isNewAccount">
            <account-underwriting *ngIf="activatedTabs['Underwriting']" [accountInfo]="account" [rules]="rules">
            </account-underwriting>
        </mat-tab>
        <mat-tab label="Documents" *ngIf="!isNewAccount">
            <account-documents *ngIf="activatedTabs['Documents']" [accountInfo]="account" [rules]="rules"
                [secretKeys]="secretKeys" (getSecretKeys)="getSecretKeys()"></account-documents>
        </mat-tab>
        <mat-tab label="MapKeys" *ngIf="!isNewAccount && checkACL('Accounts', 'R')">
            <account-mapkeys *ngIf="activatedTabs['MapKeys']" [accountInfo]="account" [rules]="rules"
                (newMapKeyAdded)="getMapKeys($event)"></account-mapkeys>
        </mat-tab>
        <mat-tab label="Events" *ngIf="!isNewAccount && checkACL('Accounts', 'R')">
            <account-events *ngIf="activatedTabs['Events']" [accountInfo]="account" [rules]="rules"
                [mapKeys]="nonListNonVirtualMapKeys" [nonListMapKeys]="nonListMapKeys"></account-events>
        </mat-tab>
        <mat-tab label="Billing" *ngIf="!isNewAccount && checkACL('Billing', 'R')">
            <account-billing *ngIf="activatedTabs['Billing']" [accountInfo]="account"></account-billing>
        </mat-tab>
        <mat-tab label="Settings" *ngIf="!isNewAccount && checkACL('System Data', 'R')">
            <account-settings-v2 *ngIf="activatedTabs['Settings']" [client]="client" [account]="account" [secretKeys]="secretKeys"
                [mapKeys]="mapKeys" [rules]="rules" (getSecretKeys)="getSecretKeys()"></account-settings-v2>
        </mat-tab>
    </div>
    </mat-tab-group>

</div>