<h1 mat-dialog-title>Configure Webhook
    <div class="subtext">Used to send case information to an API. Contact API owner for required webhook information</div>
</h1>
<div mat-dialog-content>
    <div class="flex-fill d-flex flex-column overflow-y-auto">
        <form [formGroup]="webhookForm" novalidate autocomplete="off" class="addpadding">
            <div class="bold-label-18 headerLabel">Details</div>
            <mat-form-field class="w-100">
                <mat-label>Request Method</mat-label>
                <mat-select id="methodType" formControlName="methodType">
                    <mat-option value="post">Post</mat-option>
                    <mat-option value="get">Get</mat-option>
                    <mat-option value="put">Put</mat-option>
                    <mat-option value="patch">Patch</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="w-100">
                <mat-label>URL</mat-label>
                <input id="url" matInput formControlName='url'>
                <mat-error *ngIf="webhookForm.get('url').hasError('required')">required</mat-error>
            </mat-form-field>
            <div class="flex flex-row">
                <mat-slide-toggle id="toggleCondition" formControlName="regenerateDocuments">
                    Regenerate Documents</mat-slide-toggle>
            </div>
            <div class="bold-label-18 headerLabel">Credentials</div>
            <mat-form-field class="w-100">
                <mat-label>Auth Type</mat-label>
                <mat-select id="authType" formControlName="authType">
                    <mat-option></mat-option><mat-option value="OAuth2">OAuth2</mat-option>
                    <mat-option value="HMAC">HMAC</mat-option>
                </mat-select>
            </mat-form-field>
            <div *ngIf="webhookForm.get('authType').value && webhookForm.get('authType').value === 'HMAC'">
                <div formGroupName="hmacRequest" novalidate autocomplete="off">
                    <mat-form-field class="w-100">
                        <mat-label>Username</mat-label>
                        <input id="userName" matInput formControlName='userName'>
                    </mat-form-field>
                    <auto-suggest *ngIf="secretKeys" [list]="secretKeys" placeholder="Password" field="name"
                        dataKey="id" formControlName="secret">
                    </auto-suggest>
                </div>
            </div>
            <div *ngIf="webhookForm.get('authType').value && webhookForm.get('authType').value === 'OAuth2'">
                <div formGroupName="oAuth2Request" novalidate autocomplete="off">
                    <mat-form-field class="w-100">
                        <mat-label>Authentication Content Type</mat-label>
                        <mat-select id="authContentType" 
                            formControlName="authContentType">
                            <mat-option></mat-option>
                            <mat-option value="formEncoded">Form Encoded</mat-option>
                            <mat-option value="rawJson">Raw JSON</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="w-100">
                        <mat-label>Grant Type</mat-label>
                        <mat-select id="grantType" formControlName="grantType">
                            <mat-option></mat-option>
                            <mat-option value="client_credentials">Client Credentials</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="w-100">
                        <mat-label>Access Token URL</mat-label>
                        <input id="tokenEndpoint" matInput
                            formControlName='tokenEndpoint'>
                    </mat-form-field>
                    <mat-form-field class="w-100">
                        <mat-label>Client ID</mat-label>
                        <input id="clientId" matInput formControlName='clientId'>
                    </mat-form-field>
                    <auto-suggest *ngIf="secretKeys" [list]="secretKeys" placeholder="Password" field="name"
                        dataKey="id" formControlName="clientSecret">
                    </auto-suggest>
                    <mat-form-field class="w-100">
                        <mat-label>Scope</mat-label>
                        <input id="scope" matInput formControlName='scope'>
                    </mat-form-field>
                </div>
            </div>
            <div class="bold-label-18 headerLabel">Input</div>
            <mat-form-field class="w-100">
                <mat-label>Content Type</mat-label>
                <input id="contentType" matInput formControlName='contentType'>
            </mat-form-field>
            <mat-form-field class="w-100 textarea-formfield">
                <mat-label>Content</mat-label>
                <textarea id="content" matInput formControlName='content' rows="10"></textarea>
                <mat-error *ngIf="formSubmitted && webhookForm.get('content').hasError('required')">
                    Content
                    <strong>required</strong>
                </mat-error>
            </mat-form-field>
            <div class="bold-label-18 headerLabel">Output</div>
            <ng-control formArrayName="outputs">
                <div *ngFor="let outputItem of outputs.controls; let i = index; let odd = odd" [class.odd]="odd"
                    class="flex flex-row" [formGroupName]="i">
                    <div class="col-md removePadding">
                        <mat-form-field class="w-100">
                            <mat-label>Source Value</mat-label>
                            <input id="{{'mapKey_Id' + i}}" matInput
                                formControlName="outputProperty">
                            <mat-error *ngIf="formSubmitted && outputItem.get('outputProperty').hasError('required')">
                                Source Value required
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md removePadding">
                        <map-keys-dropdown class="wider-input" [displayList]="mapkeys" showRequiredError="true"
                            showArrow="true" ngDefaultControl placeholder="Mapkey" id="{{'metadata_Id' + i}}"
                            [allowedTypes]="[mapKeyTypeEnum.NonVirtual]" [formControl]="outputItem.get('mapkeyId')">
                        </map-keys-dropdown>
                    </div>
                    <div class="col-md-1">
                        <div (click)="removeOutput(i)" style="cursor: pointer;">
                            <mat-icon svgIcon="delete" style="height:1.25rem;width:1.25rem;margin-top:1rem;"></mat-icon>
                        </div>
                    </div>
                </div>
            </ng-control>

            <div class="flex flex-row">
                <button type="button" class="btn btn-link btn-sm float-left btn-no-padding" (click)="addOutput()">
                    <i aria-hidden="true" class="fa fa-plus"></i> ADD Output
                </button>
            </div>
            <div class="bold-label-18 headerLabel">Retry Attempts</div>
            <div class="flex flex-row">
                <mat-form-field class="mr-5">
                    <mat-label>Max # of Attempts</mat-label>
                    <input id="retryAttempts" type="number" matInput formControlName="retryAttempts"/>
                </mat-form-field>
                <mat-form-field class="mr-5">
                    <mat-label>Expiration (Hours)</mat-label>
                    <input id="expiration" type="number" matInput formControlName="expirationHours"/>
                </mat-form-field>
                <div style="display: flex; align-items:center;">
                    <mat-slide-toggle id="errorCase" formControlName="errorCaseOnFailure">Failures Result in Case Error
                    </mat-slide-toggle>
                </div>
            </div>
            <div class="bold-label-18 headerLabel">Headers</div>
            <ng-control formArrayName="headers">
                <div *ngFor="let header of headers.controls; let i = index; let odd = odd" [class.odd]="odd"
                    class="flex flex-row" [formGroupName]="i">
                    <div class="col-md removePadding">
                        <mat-form-field class="w-100">
                            <mat-label>Name</mat-label>
                            <input id="{{'mapKey_Id' + i}}" matInput formControlName="key">
                        </mat-form-field>
                    </div>
                    <div class="col-md removePadding">
                        <mat-form-field class="w-100">
                            <mat-label>Value</mat-label>
                            <input id="{{'metadata_Id' + i}}" matInput formControlName="value">
                        </mat-form-field>
                    </div>
                    <div class="col-md-1">
                        <div (click)="removeHeader(i)" style="cursor: pointer;">
                            <mat-icon svgIcon="delete" style="height:1.25rem;width:1.25rem;margin-top:1rem;"></mat-icon>
                        </div>
                    </div>
                </div>
            </ng-control>

            <div class="flex flex-row">
                <button type="button" class="btn btn-link btn-sm float-left btn-no-padding" (click)="addHeader()">
                    <i aria-hidden="true" class="fa fa-plus"></i> ADD HEADER
                </button>
            </div>
        </form>
    </div>
</div>
<div style="padding-right:20px">
    <mat-dialog-actions align="end">
        <mrs-btn-tertiary buttonLabel="Cancel" buttonId="cancelButton" mat-dialog-close>Cancel</mrs-btn-tertiary>
        <mrs-btn-primary buttonId="continueButton" buttonLabel="Continue" (onClick)="saveAccountMessage()">Continue
        </mrs-btn-primary>
    </mat-dialog-actions>
</div>