enum AccountStatusEnum {
    Active = '00000000-0000-0000-0000-000000000001',
    Inactive = '00000000-0000-0000-0000-000000000002',
    Draft = '00000000-0000-0000-0000-000000000003',
    Archived = '00000000-0000-0000-0000-000000000004',
    Copying = '00000000-0000-0000-0000-000000000005',
    Failed = '00000000-0000-0000-0000-000000000006'
}

export { AccountStatusEnum };
