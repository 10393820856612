<div class="left-panel w-25 bg-light d-flex flex-column">
    <div class="left-panel-header">
        <span class="left-panel-title">RX Risk Maps</span>
        <button *ngIf="checkACL('W')" type="button" class="btn btn-link btn-sm custom float-right"
            (click)="addRxRisk()">
            <i aria-hidden="true" class="fa fa-plus-circle"></i> Add RX Risk Map
        </button>
        <span class="d-inline" *ngIf="checkACL('W')">
            <label class="float-right">
                <input type="file" id="fileInput" (change)="selectFile($event)" (click)="onClick($event)" class="d-none"
                    accept=".xlsx" />
                <span class="btn btn-link btn-sm custom float-right">
                    <a class="text-decoration-none" color="primary">
                        <i aria-hidden="true" class="fa fa-download"></i> Import</a>
                </span>
            </label>
        </span>
    </div>
    <div class="left-panel-body flex-fill overflow-y-auto custom-scrollbar">
        <div class="list-container">
            <div class="list-item" (click)="getRxInfo(risk)" matRipple *ngFor="let risk of rxRisks; let i = index"
                [ngClass]="{'active': risk.id == this.selectedLabel?.id}">
                <div class="list-item-title">{{risk.drugLabel}}</div>
                <div class="list-item-actions">
                    <button *ngIf="checkACL('F')" class="btn btn-link btn-sm custom" (click)="deleteRxRisk(risk.id)">
                        <i aria-hidden="true" class="far fa-trash-alt"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="p-4 text-center text-muted" *ngIf="rxRisks?.length == 0">
            <img src="./assets/images/no-data-placeholder.png" alt="" class="pb-3" />
            <h6>No RX Risk Maps Found</h6>
        </div>
    </div>
</div>
<div class="right-panel flex-fill d-flex flex-column">
    <div class="left-panel-header">
        <span class="left-panel-title">RX Risk Map Details</span>
    </div>
    <div class="right-panel-body flex-fill overflow-y-auto custom-scrollbar p-3">

        <div class="row" *ngIf="showForm">
            <div class="col-6 mx-auto">
                <form class="p-5" [formGroup]="rxForm" (ngSubmit)="saveRxRisk()" novalidate autocomplete="off">
                    <mat-card appearance="outlined">
                        <mat-card-header><mat-card-title>RX Risk Map Detail</mat-card-title></mat-card-header>
                        <mat-card-content>
                            <input type="hidden" formControlName='id'>
                            <input type="hidden" formControlName='accountId'>
                            <mat-form-field class="w-100">
                                <input placeholder="Drug Label" matInput formControlName='drugLabel'
                                    maxlength="{{this.DrugLabelMaxLength}}">
                                <mat-error *ngIf="rxForm.get('drugLabel').hasError('required')">
                                    Drug Label
                                    <strong>required</strong>
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="w-100">
                                <input placeholder="Drug Class" matInput formControlName='drugClass'
                                    maxlength="{{this.DrugClassMaxLength}}">
                                <!-- <mat-error *ngIf="rxForm.get('drugClass').hasError('required')">
                                Drug Class
                                <strong>required</strong>
                            </mat-error> -->
                            </mat-form-field>
                            <mat-form-field class="w-100">
                                <input placeholder="Drug Risk Date Boundary Start" matInput
                                    formControlName='drugRiskDateBoundaryStart'>
                                <!-- <mat-error *ngIf="rxForm.get('drugRiskDateBoundaryStart').hasError('required')">
                                Drug Risk Date Start
                                <strong>required</strong>
                            </mat-error> -->
                                <mat-error *ngIf="rxForm.get('drugRiskDateBoundaryStart').hasError('pattern')">
                                    Please enter only number type
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="w-100">
                                <input placeholder="Drug Risk Date Boundary End" matInput
                                    formControlName='drugRiskDateBoundaryEnd'>
                                <!-- <mat-error *ngIf="rxForm.get('drugRiskDateBoundaryEnd').hasError('required')">
                                Drug Risk Date End
                                <strong>required</strong>
                            </mat-error> -->
                                <mat-error *ngIf="rxForm.get('drugRiskDateBoundaryEnd').hasError('pattern')">
                                    Please enter only number type
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="w-100">
                                <input placeholder="Treated Condition" matInput formControlName='treatedCondition'
                                    maxlength="{{this.TreatedConditionMaxLength}}">
                                <!-- <mat-error *ngIf="rxForm.get('treatedCondition').hasError('required')">
                                Treated Condition
                                <strong>required</strong>
                            </mat-error> -->
                            </mat-form-field>
                            <mat-form-field class="w-100 pb-3">
                                <mat-select placeholder="Severity" formControlName='drugRiskTypeId'>
                                    <mat-option *ngFor="let drugRiskType of drugRiskTypes" [value]="drugRiskType.id">
                                        {{ drugRiskType.code }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="rxForm.get('drugRiskTypeId').hasError('required')">
                                    Severity
                                    <strong>required</strong>
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="w-100">
                                <input placeholder="Rx Code Description" matInput formControlName='interviewerDirective'
                                    maxlength="{{this.InterviewerDirectiveMaxLength}}">
                                <!-- <mat-error *ngIf="rxForm.get('interviewerDirective').hasError('required')">
                                <strong>required</strong>
                            </mat-error> -->
                            </mat-form-field>
                        </mat-card-content>
                        <mat-card-actions align="end">
                            <mrs-btn-tertiary buttonLabel="Cancel" buttonId="cancelButton"
                                (onClick)="cancel()">Cancel</mrs-btn-tertiary>
                            <mrs-btn-primary buttonLabel="Save" buttonId="billingSave"
                                isSubmitButton="true"></mrs-btn-primary>
                        </mat-card-actions>
                    </mat-card>
                </form>
            </div>
        </div>

        <div class="col-5 mx-auto mt-5" *ngIf="!showForm && selectedRx?.id">
            <mat-card appearance="outlined">
                <mat-card-header>
                    <mat-card-title-group>
                        <mat-card-title>
                            RX Risk Map Details
                        </mat-card-title>
                        <button mat-button color="basic" class="float-right" (click)="editRxRisk()"
                            *ngIf="checkACL('W')">
                            <i aria-hidden="true" class="fa fa-edit pr-1"></i>Edit
                        </button>
                    </mat-card-title-group>
                </mat-card-header>
                <mat-card-content>
                    <div class="label-title">Drug Label </div>
                    <div class="label-content">{{selectedRx.drugLabel}}</div>

                    <div class="label-title">Drug Class </div>
                    <div class="label-content">{{selectedRx.drugClass}}</div>

                    <div class="label-title">Drug Risk Date Start </div>
                    <div class="label-content">{{selectedRx.drugRiskDateBoundaryStart}}</div>

                    <div class="label-title">Drug Risk Date End </div>
                    <div class="label-content">{{selectedRx.drugRiskDateBoundaryEnd}}</div>

                    <div class="label-title">Treated Condition </div>
                    <div class="label-content">{{selectedRx.treatedCondition}}</div>

                    <div class="label-title">Drug Risk Type </div>
                    <div class="label-content">{{getDrugRiskType(selectedRx.drugRiskTypeId)}}</div>

                    <div class="label-title">Rx Code Description </div>
                    <div class="label-content">{{selectedRx.interviewerDirective}}</div>

                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>