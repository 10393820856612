import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { AppService } from '../../app.service';
import { IConfigService } from '../../config/iconfigservice';
import { BaseService } from '../base.service';
import { SearchParams } from '../../models';

@Injectable()
export class DashboardService extends BaseService {

    publicApiBaseUrl: string;
    searchParams: SearchParams;

    constructor(public appService: AppService, public configService: IConfigService,
        public httpClient: HttpClient) {
        super(appService, configService, httpClient);
        this.publicApiBaseUrl = this.configService.getConfiguration().publicApiUrl;
    }

    saveSearchParams(data) {
        this.searchParams = data;
    }

    getSearchParams() {
        return this.searchParams;
    }
}
