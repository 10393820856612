import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnDestroy, Output } from '@angular/core';
import { EmbeddedIntegrationData, ThirdPartySectionComponent } from '../third-party-section.component';
import { ThirdPartyNavigationDirection, ThirdPartySectionService } from '../third-party-section.service';
import { NotificationService, NotificationSeverity } from 'app/services';
import { Subscription } from "rxjs";
import { AppService } from "app/app.service";
import { FeatureManagerService } from "app/services/global/feature-manager/feature-manager.service";
import { FeatureToggle } from "app/enums";

@Component({
    selector: 'cep-questionnaire',
    templateUrl: './cep-questionnaire.component.html',
    styleUrls: ['./cep-questionnaire.scss']
})
export class CepQuestionnaireComponent implements ThirdPartySectionComponent, OnDestroy {
    get embeddedData() {
        return this._embeddedData;
    }

  @Input() set embeddedData(value) {
        this._embeddedData = value;
        this.readonly = this.embeddedData?.readonly;
        this.initialize();
    }

  @Output() sectionSubmittedSuccessfully = new EventEmitter<boolean>();

  private moveToNextSection = false;
  private loadFromRestart = false;

  ASSESSMENT_ID_MAPKEY = 'Case_CEP_AssessmentId';
  hanoverData: any;
  questionnaireUrl = 'assets/questionnaire.js';
  activeAssessmentId = '';
  questionnaireConfig = {
      backendTimeoutWarning: 3000,
      backendTimeout: 30000,
      headingStartingLevel: 3,
      api: {
          urlPrefix: '',
      },
      pagination: {
          freePagination: false,
          dismissAlertDelay: 5000
      },
      questionAnnotation: {
          required: 'legend'
      },
      assessmentFactorGroups: {
          collapsiblesForSearchChildren: true,
      },
      blockingQuestionnaire: {
          backdrop: 'fullpage',
          spinner: true
      }
  };
  enExtended = {
      components: {
          date: {
              formats: {
                  year: "yyyy",
                  month: "MMMM yyyy",
                  day: "MM/dd/yyyy",
                  hour: "MM/dd/yyyy HH:mm",
                  minute: "MM/dd/yyyy HH:mm"
              },
              placeholder: {
                  year: "YYYY",
                  month: "eg. \"January 1970\"",
                  day: "MM/DD/YYYY"
              }
          },
          assessmentFactorGroups: {
              texts: {
                  "addAnotherSearchTerm": "Back to Top to Add/Edit",
                  "continue": "Continue",
                  "delete": "Delete"
              }
          }
      }
  };
  readonly = false;
  isRtl = false;
  language = 'en-US';
  translations = [{ action: 'extend', localeKey: 'en', translationTable:  this.enExtended}];
  paginationMode = 'QuestionGroup';
  paginationModeFullInterview = 'Questionaire';
  activePageId: any;
  applicationData: any;
  sectionPageExistsInAssessment = false;
  thirdPartySectionSubmittedSubscription: Subscription;
  cepInitialLoad = false;
  cepInitialized = false;
  _embeddedData: EmbeddedIntegrationData;
  showPaginator: any;
  CEP_NAVIGATION_BUTTON_ERROR_MESSAGE = 'Error loading page.  Please refresh your browser.  If you continue to get this error please contact Support.';

  private isAssessmentValid(sectionCepPage, hannoverActiveCepPage) {
      const activeCepPageIsUserActivePage = sectionCepPage === hannoverActiveCepPage;

      if (sectionCepPage && sectionCepPage.validationState !== "valid" && activeCepPageIsUserActivePage) {
          sectionCepPage.isActivePage = true;
          this.activePageId = this.hanoverData.activePageId;
          this.moveToNextSection = false;
          this.notificationService.showNotification({severity: NotificationSeverity.Error, message: "Please correct any errors before moving on."});
          return false;
      }
      return true;
  }

  activePageIdChange = (event: any) => {
      if (event != this.hanoverData.activePageId)
          this.activePageId = this.hanoverData.activePageId;
  };

  onQuestionnaireInit = () => {
      this.cepInitialLoad = true;
      this.cepInitialized = true;
      this.sectionPageExistsInAssessment = true;
      if (!this.hanoverData.isReflexiveSection) {
          this.appService.display(false);
      }

      if (this.activePageId === 'GTLTerm.GTLTerm_Beneficiaries___Beneficiaries'){
          this.expandBeneficiaryGroups();
      }
  };

  onAssessmentFinished = (url: string) => {
      if (this.moveToNextSection) {
          this.thirdPartySectionService.thirdPartySectionNavigation(ThirdPartyNavigationDirection.Next);
      }
  };

  onPaginationPagesChange = (cepPages: any) => {
      if (!this.moveToNextSection && !this.loadFromRestart && !this.cepInitialLoad) {
          return;
      }
      this.cepInitialLoad = false;
      this.loadFromRestart = false;
      const sectionCepPage = cepPages.find(x => x.questionGroupId === this.activePageId);
      const hannoverActiveCepPage = cepPages.find(x => x.isActivePage);

      if (sectionCepPage)
          this.sectionPageExistsInAssessment = true;
      else {
          this.sectionPageExistsInAssessment = false;
      }

      if (this.moveToNextSection && this.isAssessmentValid(sectionCepPage, hannoverActiveCepPage)) {
          this.moveToNextSection = false;
          this.thirdPartySectionService.thirdPartySectionNavigation(ThirdPartyNavigationDirection.Next);
      }
  };

  expandBeneficiaryGroups(){
      setTimeout(() => {
          const primaryBeneficiaryDiv = document.querySelector('div[data-question-id="PrimaryBeneficiary1"]');

          if (!primaryBeneficiaryDiv)
              return;

          const primaryCard = primaryBeneficiaryDiv.querySelector('.card');
      
          if (!primaryCard)
              return;
      
          const collapsed = primaryCard.querySelector('.collapse');
          if (!collapsed)
              return;

          collapsed.classList.add('show');
      },0);
  }

  private hideNonReflexiveQuestions(appServiceRef : AppService, featureManagerServiceRef : FeatureManagerService) {
      // We need to wait until CEP gives the assessment back to us
      setTimeout(() => {

          if (featureManagerServiceRef.getByName(FeatureToggle.CaseHannoverReHideGTLTermCTLRiderEmptyElement).enabled) {
              // Remove Empty Pages
              const cep2QuestionnaireContainterElements = document.getElementsByTagName('cep2-questionnaire-container');
              for (const container of cep2QuestionnaireContainterElements) {
                  if (container.firstChild == container.lastChild && (container as HTMLElement).innerText == '') {
                      (container as HTMLElement).style['display'] = 'none';
                  }
              }
          }

          // Find last GTL Element
          const assessmentQuestions = document.querySelectorAll('div[id*="question-container-GTLTerm"]');
          if (!assessmentQuestions || assessmentQuestions.length == 0) {
              this.hideNonReflexiveQuestions(appServiceRef, featureManagerServiceRef);
          } else {
              if (featureManagerServiceRef.getByName(FeatureToggle.CaseHannoverReHideGTLTermCTLRiderEmptyElement).enabled) {
                  assessmentQuestions.forEach(element => {
                      (element as HTMLElement).style['display'] = 'none';
                  });
              }
              let lastElement: Element;
              const riderQuestions = document.querySelectorAll('div[id*="question-container-CTLRider"]');
              if (riderQuestions.length != 0) {

                  if (featureManagerServiceRef.getByName(FeatureToggle.CaseHannoverReHideGTLTermCTLRiderEmptyElement).enabled) {
                      riderQuestions.forEach(element => {
                          (element as HTMLElement).style['display'] = 'none';
                      });
                  }
                  lastElement = riderQuestions[riderQuestions.length - 1];
              } else {
                  lastElement = assessmentQuestions[assessmentQuestions.length - 1];
              }
              // Scroll to last Element; therefore showing the beginning of the reflexives
              if (lastElement) {
                  lastElement.scrollIntoView();
              }
              appServiceRef.display(false);
          }
      }, 1000);
  }

  private updateAssessmentForReflexive() {
      // Update assesment to not have pagination
      this.paginationMode = this.paginationModeFullInterview;
      this.sectionPageExistsInAssessment = true;
      // Kick off polling
      this.hideNonReflexiveQuestions(this.appService, this.featureManagerService);
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public notificationService: NotificationService,
    private thirdPartySectionService: ThirdPartySectionService,
    private appService: AppService,
    private featureManagerService: FeatureManagerService) {
  }

  private selectDataSource() {
      if (this.hanoverData.isReflexiveSection) {
          this.updateAssessmentForReflexive();
      }

      this.showPaginator = this.hanoverData.showPaginator ?? false;
      window.localStorage.setItem('cep-authorization', this.hanoverData.token);
      this.activePageId = this.hanoverData.activePageId;
      this.activeAssessmentId = this.hanoverData.assessmentId;
      if (this.cepInitialized && !this.sectionPageExistsInAssessment) {
          this.sectionPageExistsInAssessment = true;  //Required in case the error section is displayed and "previous" is clicked
      }
  }

  initialize() {
      this.paginationMode = 'QuestionGroup';
      this.hanoverData = JSON.parse(this.embeddedData.payloadData);
      if (!this.cepInitialized || this.hanoverData.isReflexiveSection) {
          this.appService.display(true);
      }
      this.loadFromRestart = this.thirdPartySectionService.interviewRestarted;
      this.thirdPartySectionService.interviewRestarted = false;
      this.questionnaireConfig.api.urlPrefix = `${this.embeddedData.baseUrl}/`;
      this.selectDataSource();
      if (!this.thirdPartySectionSubmittedSubscription) {
          this.thirdPartySectionSubmittedSubscription = this.thirdPartySectionService.navigationFromInterviewObservable.subscribe(
              (results) => {
                  this.moveToNextSection = results.userClickedNext;
                  // If we land on a section where the CEP Assessment does not have a page (Rider)
                  // we want to still navigate next but we bypass CEP because there are no CEP buttons
                  // to press
                  if (!this.sectionPageExistsInAssessment) {
                      this.thirdPartySectionService.thirdPartySectionNavigation(ThirdPartyNavigationDirection.Next);
                  }
                  this.submitForm();
              }
          );
      }

      const container = document.querySelector('#interviewForm');
      if (container && !this.hanoverData.isReflexiveSection) {
          container.scrollTop = 0;
      }
  }

  ngOnDestroy() {
      if (this.thirdPartySectionSubmittedSubscription) {
          this.thirdPartySectionSubmittedSubscription.unsubscribe();
      }
  }

  submitForm() {
      const nextPage = document.getElementsByClassName("cep-qa_questionnaire_full-pagination_next-page-link")[0] as HTMLButtonElement;
      const submitButton = document.getElementsByClassName("cep-qa_questionnaire_submit-btn")[0] as HTMLButtonElement;
      // Reflexives can contain empty pages so we have to click Submit
      if (this.hanoverData.isReflexiveSection) {
          if (!submitButton) {
              this.notificationService.showNotification({
                  severity: NotificationSeverity.Error,
                  message: this.CEP_NAVIGATION_BUTTON_ERROR_MESSAGE
              });
              return;
          }
          submitButton.click();
      } else {
          if (!nextPage || !submitButton) {
              this.notificationService.showNotification({
                  severity: NotificationSeverity.Error,
                  message: this.CEP_NAVIGATION_BUTTON_ERROR_MESSAGE
              });
              return;
          }
          const isNextDisabled = nextPage.parentElement.classList.contains('disabled');
          if (isNextDisabled && submitButton) {
              submitButton.click();
          } else {
              if (nextPage)
                  nextPage.click();
          }

      }
  }
}
