<div>
    <button mat-menu-item
        *ngIf="caseRequirement.hasSection"
        (click)="handleMenuAction(UWRequirementTableActions.OpenSection, caseRequirement)">
        Order
    </button>    
    <uw-requirements-actions-item-documents
        [caseRequirement]="caseRequirement"
        (onMenuAction)="handleMenuActionEvent($event)">
    </uw-requirements-actions-item-documents>
    <button mat-menu-item
        (click)="handleMenuAction(UWRequirementTableActions.EditSubtitle, caseRequirement)">
        <span *ngIf="caseRequirement.subtitle && caseRequirement.subtitle != ''; else addSubtitleTemplate">Edit Subtitle</span>
        <ng-template #addSubtitleTemplate>Add Subtitle</ng-template>
    </button>
    <mat-divider></mat-divider>
    <button mat-menu-item
        *ngIf="!props.hideSetPendingMenuOption"
        (click)="handleMenuAction(UWRequirementTableActions.Pending, caseRequirement)">
        Set Pending
    </button>
    <button mat-menu-item
        *ngIf="!props.hideEndStateToEndStateTransition"
        (click)="handleMenuAction(UWRequirementTableActions.Waive, caseRequirement)">
        Waive
    </button> 
    <button mat-menu-item
        *ngIf="!props.hideSetCancelledMenuOption && !props.hideEndStateToEndStateTransition"
        (click)="handleMenuAction(UWRequirementTableActions.Cancelled, caseRequirement)">
        Cancel
    </button>  
</div>