import { MrsColor } from '@Enums';
import { Component, EventEmitter, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IntegrationPending } from '../models';

@Component({
    selector: 'action-dialog',
    templateUrl: 'action-dialog.component.html',
})
export class ActionDialogComponent {
    refreshTextColor?: MrsColor = MrsColor.UIBlue;
    cancelTextColor?: MrsColor = MrsColor.MidGray;
    cancelLabel: string = 'Cancel';
    refreshLabel: string = 'Refresh';
    canCancel: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<ActionDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IntegrationPending) {
        data.textAlignment = data.textAlignment || 'left';
    }

    onRefresh = new EventEmitter();

    refresh(): void {
        this.onRefresh.emit(this.data.integration);
    }

}
