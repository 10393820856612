<div class="question-body mt-2" [ngClass]="getInnerTextMessageType(question)" *ngIf="getFieldStatus(question) && question?.integrationMessages">
    <div class="question-text rendered-quill-content ql-editor">{{getInnerText(question)}}</div>

</div>
<div class="integration-container" *ngIf="interviewService.isReadOnly && question.questionActions && question.questionActions.length>0">
    <div class="question-header">
        <span class="d-inline-block pt-2 pl-2"> Actions Data </span>
        <button type="button" class="btn btn-link float-right" (click)="actionsExpanded = !actionsExpanded">
            <i aria-hidden="true" class="fa" [ngClass]="{'fa-angle-right': actionsExpanded, 'fa-angle-down': !actionsExpanded}"></i>
        </button>
    </div>
    <div class="p-2" *ngIf="!actionsExpanded">
        <div *ngFor="let action of question.questionActions">
            <div class="integration-container">
                <div class="question-header">
                    <span class="d-inline-block pt-2 pl-2">{{action.actions.actionTypes.name}} - {{action.actions.name}} Action Results</span>
                    <button type="button" class="btn btn-link float-right" (click)="action.expanded = !action.expanded">
                        <i aria-hidden="true" class="fa" [ngClass]="{'fa-angle-right': action.expanded, 'fa-angle-down': !action.expanded}"></i>
                    </button>
                </div>

                <div class="p-2" *ngIf="action.expanded">
                    <div [ngSwitch]="action.actions.name">
                        <div *ngSwitchCase="'Disabled'">
                          <div class="label-title"> Disabled </div>
                          <div class="label-content mb-0">{{ action.actionResults.data?.disabled}} </div>
                        </div>
                        <div *ngSwitchCase="'Hide'">
                            <div class="label-title"> Display </div>
                            <div class="label-content mb-0">{{ action.actionResults.data?.display}} </div>
                        </div>
                        <div *ngSwitchCase="'Show'">
                            <div class="label-title"> Display </div>
                            <div class="label-content mb-0"> {{ action.actionResults.data?.display}} </div>
                        </div>
                        <div *ngSwitchCase="'Trigger UW Process'">
                            <div class="label-title"> Details </div>
                            <div class="label-content mb-0" *ngIf="action.actionResults?.data !== null"> {{ action.actionResults.data |json}} </div>
                        </div>
                        <div *ngSwitchCase="'Change Case Status'">
                            <div class="label-title"> Details </div>
                            <div class="label-content mb-0" *ngIf="action.actionResults?.data !== null"> {{ action.actionResults.data |json}} </div>
                        </div>
                        <div *ngSwitchCase="'Check For Duplicate App'">
                            <div class="label-title"> Details </div>
                            <div class="label-content mb-0" *ngIf="action.actionResults?.data !== null"> {{ action.actionResults.data |json}} </div>
                        </div>
                        <div *ngSwitchCase="'Update Case Policy Number'">
                            <div class="label-title"> Details </div>
                            <div class="label-content mb-0" *ngIf="action.actionResults?.data !== null"> {{ action.actionResults.data |json}} </div>
                        </div>
                        <div *ngSwitchCase="'Validate Repeat Block Sum'">
                            <div class="label-title"> Details </div>
                            <div class="label-content mb-0" *ngIf="action.actionResults?.data !== null"> {{ action.actionResults.data |json}} </div>
                        </div>
                        <div *ngSwitchCase="'Trigger Billing'">
                            <div class="label-title"> Details </div>
                            <div class="label-content mb-0" *ngIf="action.actionResults?.data !== null"> {{ action.actionResults.data |json}} </div>
                        </div>
                        <div *ngSwitchCase="'GIACT Verification'">
                            <div class="label-title"> Response </div>
                            <div class="label-content mb-0" *ngIf="interviewService.getGiactData().details?.message"> {{interviewService.getGiactData().details?.message}} </div>
                        </div>
                        <div *ngSwitchCase="'MIB Integration'">
                            <div class="label-title"> Status </div>
                            <div class="label-content mb-0" *ngIf="interviewService.getMibData().status"> {{interviewService.getMibData().status}} </div>
                        </div>
                        <div *ngSwitchCase="'RNA: Get Agent Info'">
                            <div class="label-title"> Status </div>
                            <div class="label-content mb-0" *ngIf="interviewService.getAgentData().status"> {{interviewService.getAgentData().status}} </div>
                        </div>
                        <div *ngSwitchCase="'RNA: Quote'">
                            <div class="label-title"> Status </div>
                            <div class="label-content mb-0" *ngIf="interviewService.getQuoteData().status"> {{interviewService.getQuoteData().status}} </div>
                        </div>
                        <div *ngSwitchCase="'Rx Integration'">
                            <div class="label-title"> Rx Details </div>

                            <div class="mb-3" *ngIf="interviewService.getRxData().details">
                                <p-table *ngIf="interviewService.getRxData().details?.severities" [value]="interviewService.getRxData().details?.severities">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th>Severity</th>
                                            <th style="width: 75px">Status</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-severity>
                                        <tr>
                                            <td>{{severity.mapKeyName}}</td>
                                            <td *ngIf="severity.value">Yes</td>
                                            <td *ngIf="!severity.value">No</td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </div>
                            <div *ngIf="interviewService.getRxData().details?.drugs">
                                <div *ngFor="let risk of (interviewService.getRxData().details?.drugs | filterNull: ['riskType'])">
                                    <div class="question-container">
                                        <div class="p-2">
                                            <div class="rx-list">
                                                <span class="rx-list-label-title">Drug : </span>
                                                <span class="rx-list-label-content">{{risk.label}}</span>
                                            </div>
                                            <div class="rx-list">
                                                <span class="rx-list-label-title">Risk : </span>
                                                <span class="rx-list-label-content">{{risk.riskType}}</span>
                                            </div>
                                            <div class="rx-list">
                                                <span class="rx-list-label-title">Code Description : </span>
                                                <span class="rx-list-label-content">{{risk.interviewerDirective}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="action.actionResults && action.actionResults.rulesResult">
                      <div class="label-title"> Rule </div>
                      <div class="label-content mb-0">{{ action.actionResults.rulesResult?.name}} - {{ action.actionResults.rulesResult?.result}} </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="question-body" *ngIf="displayQuestionLastUpdated">
    <span class="question-mapkey float-right pr-2 last-saved">Last updated on: {{(question.answerTimeStamp | date: 'MM/dd/yyyy HH:mm:ss': timeZone) || 'never' }}
    </span>
</div>
