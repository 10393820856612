<div style="margin: 25px">
    <cep-web-questionnaire
      #questionnaireComponent
      id="questionnaireComponent"
      *ngIf="activeAssessmentId"
      [ngStyle]="{'display':sectionPageExistsInAssessment?'':'none'}"
      [assessmentId]="activeAssessmentId"
      [config]="questionnaireConfig"
      [readOnly]="readonly"
      [language]="language"
      [translations]="translations"
      [paginationMode]="paginationMode"
      [activePageId]="activePageId"
      [application]="applicationData"
      [rtl]="isRtl"
      [debugMode]="false"
      (activePageIdChange)="activePageIdChange($event.detail)"
      (initializedChange)="onQuestionnaireInit()"
      (assessmentFinished)="onAssessmentFinished($event.detail)"
      (applicationChange)="this.applicationData = $event.detail;"
      (paginationPagesChange)="onPaginationPagesChange($event.detail)">
      <div slot="before">
      </div>
      <div slot="after">
        <cep-web-full-page-pagination [ngStyle]="{'display':showPaginator?'':'none'}"></cep-web-full-page-pagination>
        <cep-web-submit-button [shouldBeHidden]="true" shouldBeDisabled="never" submitMode="valid"></cep-web-submit-button>
      </div>
    </cep-web-questionnaire>
  <div id='nonExistentSectionError' *ngIf="!sectionPageExistsInAssessment && cepInitialized" class="info_banner info_banner-text">This section does not exist. Select next to continue or contact Support if you believe you received this in error.</div>
</div>
