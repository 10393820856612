import { Component, OnInit, Input } from '@angular/core';
import * as _ from 'lodash';

import { SystemEventService, NotificationSeverity, SignerService } from '../../services';
import { SignatureEnvelopeRecipient, SignatureEnvelope } from '../../models';
import { SigningUrlDelivery } from '../../models/case';
import { InterviewService } from '../../interview.service';
import { SignatureEnvelopeDialogParams } from './signature-envelope-dialog-component';
import { AppService } from '../../app.service';

@Component({
    selector: 'remote-signer',
    templateUrl: 'remote-signer.component.html',
    styleUrls: ['signature-envelope-dialog.scss']
})
export class RemoteSignerComponent implements OnInit {
  @Input() context: SignatureEnvelopeDialogParams;
  @Input() signer: SignatureEnvelopeRecipient;
  @Input() envelopeStatus: any;
  envelope: SignatureEnvelope;
  deliveryMethod: string;
  embeddedSignatureView: any;
  email: string = 'An email was not provided';
  phoneNumber: string = 'A phone number was not provided';
  instructions: string = 'Instructions were not provided';

  constructor(
    private appService: AppService,
    private interviewService: InterviewService,
    private systemEventService: SystemEventService,
    private signerService: SignerService) {
  }

  ngOnInit() {
      this.envelope = this.context.envelope;

      this.getAccountSigner();
  }

  getAccountSigner() {
      var caseDetails = this.interviewService.getInterviewData().caseDetails;
      this.signerService.getAccountSigner(caseDetails.accountId, this.signer.signerInfo.accountSignerId).subscribe(response => {
          const emailMapKey = this.interviewService.ruleFacts.find(x => x.name === response.emailMapKey);
          if (emailMapKey) {
              this.email = emailMapKey.value;
          }

          const phoneMapKey = this.interviewService.ruleFacts.find(x => x.name === response.phoneMapKey);
          if (phoneMapKey) {
              this.phoneNumber = phoneMapKey.value;
          }
          this.signerService.getSignerInstructions(caseDetails.accountId, response.remoteEmbeddedInstructions, caseDetails.id).subscribe(innerResponse => {
              this.instructions = innerResponse;
          });
      });
  }
  sendCode(deliveryMethod) {
      const signingUrlDelivery: SigningUrlDelivery = {
          accessCode: `${this.signer.signerInfo.accessCode}`,
          deliveryMethod: deliveryMethod,
          accountSignerId: this.signer.signerInfo.accountSignerId,
          envelopeId: this.envelope.envelopeId,
          signer: this.signer
      };

      this.systemEventService.deliverSigningUrl(this.interviewService.account.id,
          this.interviewService.getInterviewData().caseDetails.id, signingUrlDelivery).subscribe((response) => {
          if (response) {
              this.appService.showMsg(NotificationSeverity.Success, 'Message successfully sent');
          } else {
              this.appService.showMsg(NotificationSeverity.Error, 'There was an issue sending the message');
          }
      });
  }
}
