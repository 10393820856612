<mat-chip-listbox 
id="chipListid"
aria-label="aria-label"
[multiple]="multiple"
[selectable]="selectable">
    <mat-chip-option *ngFor="let chip of chips" 
    [selected]="chip.selected"
    [selectable]="chip.selectable"
    [disabled]="chip.disabled"
    (click)="toggleChipClick(chip)"
    >
    <span class="mat-chip-label">{{chip.label}}</span></mat-chip-option>
</mat-chip-listbox>