import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@Services';
import { CaseRequirementResponse, NoteResponse, RequirementResponse } from '@Underwriting/models';
import { AppService } from 'app/app.service';
import { IConfigService } from 'app/config/iconfigservice';
import moment = require('moment');
import { ReplaySubject } from 'rxjs';
import { SubSink } from 'subsink';

/// DEV NOTE: https://stackoverflow.com/a/33676723/253564
// The above SO as helpful in working towards what I'm trying to achieve with this service.

@Injectable({
    providedIn: 'root'
})
export default class UWRequirementsDataService extends BaseService {
    // Requirements State
    private requirementsLoaded = false;
    private accountIdForLoadedRequirements = '';
    private requirementsSub = new ReplaySubject<RequirementResponse[]>(1);
    public requirements$ = this.requirementsSub.asObservable();
    // Case Requirements State
    private caseRequirementsLoaded = false;
    private caseIdForLoadedCaseRequirements = '';
    private caseRequirementsSub = new ReplaySubject<CaseRequirementResponse[]>(1);
    public caseRequirements$ = this.caseRequirementsSub.asObservable();
    // Notes State
    private notesLoaded = false;
    private caseIdForLoadedNotes = '';
    private notesSub = new ReplaySubject<NoteResponse[]>(1);
    public notes$ = this.notesSub.asObservable();


    private subs = new SubSink();

    constructor(
        public configService: IConfigService,
        public httpClient: HttpClient,
        public appService: AppService
    ) {
        super(appService, configService, httpClient);
    }

    //#region Helpers

    private requirementsAreLoaded = (caseId: string) => caseId == this.accountIdForLoadedRequirements && this.requirementsLoaded;
    private caseRequirementsAreLoaded = (caseId: string) => caseId == this.caseIdForLoadedCaseRequirements && this.caseRequirementsLoaded;
    private notesAreLoaded = (caseId: string) => caseId == this.caseIdForLoadedNotes && this.notesLoaded;

    // DEV NOTE: instead of having each method need a `clientCode: string` parameter, I would like to refactor client data into a
    // `client.data.service.ts` that's responsible for loading / caching the current client context. Anyone component that needs client data
    // just needs to inject that service.  Maybe this should be put into `AppService`?.
    // There's already a similar concept going on in this code-base. See `app-routing.module.ts` and look for `Resolvers.ClientResolverService`.
    // Certain routes have client resolvers. The `ClientResolverService` injects  `clientDataService`. The `clientDataService` loads all the clients
    // and is pretty similar to the new 'data.service' concept (see: this service).  The resolver gets all the clients and finds the one that matches
    // the route `clientCode` parameter. If then makes this client available in the `ActivatedRoute` services's `data` property. (See: `case-summary.component.ts`)
    // This would be a similar concept I'm considering. Except, instead of putting resolvers on routes that have to be remembered to be configured,
    // the "new" ClientsDataService will load all clients (ie. same as old one but using BaseService) and then having a helper method that
    // uses a router service that gets the current route params and if there's a `ClientCode`, return the "current client" object.
    // The goal would be, at some point, this could be updated to return a "global client parameter" instead of a route.  Eg. The site would be updated
    // to require a client to be picked before continuing (similar to picking your local e-commerce store).
    private baseUrl = (clientCode: string) => `${this.baseUwwApiUrl}/v2/client/${clientCode}/`;

    //#endregion
    //#region Lifecycle

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    //#endregion
    //#region Requirements

    public reloadRequirements(clientCode: string, accountId: string, hideWaiting = false) {
        const url = `${this.baseUrl(clientCode)}Requirements/account/${accountId}`;

        const sub = super.getData<RequirementResponse[]>(url, null, hideWaiting).subscribe({
            next: requirements => {
                // The BE should sort but to be safe, we'll make sure they're sorted.
                const sortedRequirements = requirements.sort((a, b) => {
                    if (a.sortOrder < b.sortOrder) return -1;
                    if (a.sortOrder > b.sortOrder) return 1;
                    if (a.name < b.name) return -1;
                    if (a.name > b.name) return 1;
                    return 0;
                });
                this.requirementsSub.next(sortedRequirements);
                this.requirementsLoaded = true;
                this.accountIdForLoadedRequirements = accountId;
            },
            error: () => undefined,
            complete: () => {
                sub.unsubscribe();
            }
        });

        return this.requirements$;
    }

    public loadRequirements(clientCode: string, accountId: string) {
        if (this.requirementsAreLoaded(accountId)) return this.requirements$;

        const response = this.reloadRequirements(clientCode, accountId);
        return response;
    }

    //#endregion
    //#region Case Requirements

    public reloadCaseRequirements(clientCode: string, caseId: string, hideWaiting = false) {
        const url = `${this.baseUrl(clientCode)}CaseRequirements/case/${caseId}`;

        const sub = super.getData<CaseRequirementResponse[]>(url, null, hideWaiting).subscribe({
            next: caseRequirements => {
                this.caseRequirementsSub.next(caseRequirements);
                this.caseRequirementsLoaded = true;
                this.caseIdForLoadedCaseRequirements = caseId;
            },
            error: () => undefined,
            complete: () => {
                sub.unsubscribe();
            }
        });

        return this.caseRequirements$;
    }

    public loadCaseRequirements(clientCode: string, caseId: string) {
        if (this.caseRequirementsAreLoaded(caseId)) return this.caseRequirements$;

        const response = this.reloadCaseRequirements(clientCode, caseId);
        return response;
    }

    //#endregion
    //#region Notes

    // NOTE: split this into it's own data service or rename this data service to "UWWorkenchDataService" instead of being specific to "Requirements"
    public reloadNotes(clientCode: string, caseId: string, hideWaiting = false) {
        const url = `${this.baseUrl(clientCode)}Notes/case/${caseId}`;

        const sub = super.getData<NoteResponse[]>(url, null, hideWaiting).subscribe({
            next: notes => {
                const sortedNotes = [...notes].sort((a: NoteResponse, b: NoteResponse) => {
                    const first = moment(a.audit.createdOn);
                    const second = moment(b.audit.createdOn);

                    return second.diff(first, 'seconds');
                });

                sortedNotes.forEach(note => {
                    note.createdOnDisplay = moment.utc(note.audit.createdOn).tz('America/Chicago').format("M/D/YYYY h:mm a");
                });

                this.notesSub.next(notes);
                this.notesLoaded = true;
                this.caseIdForLoadedNotes = caseId;
            },
            error: () => undefined,
            complete: () => {
                sub.unsubscribe();
            }
        });

        return this.notes$;
    }

    public loadNotes(clientCode: string, caseId: string) {
        if (this.notesAreLoaded(caseId)) return this.notes$;

        const response = this.reloadNotes(clientCode, caseId);
        return response;
    }

    //#endregion

    public load(clientCode: string, accountId: string, caseId: string) {
        this.loadRequirements(clientCode, accountId);
        this.loadCaseRequirements(clientCode, caseId);
        this.loadNotes(clientCode, caseId);
    }
}
