<section>
  <h2 mat-dialog-title>{{ data.title }}</h2>
  <div mat-dialog-content cdkFocusInitial>
      <p>{{ data.content }}</p>
  </div>
  <mat-dialog-actions align="end">
    <mrs-btn-tertiary class="audit-dialog-action" buttonLabel="Cancel" buttonId="cancelButton" (click)="onNoClick()"/>
    <mrs-btn-tertiary class="audit-dialog-action" [buttonLabel]="data.confirmButtonLabel" textColor="#2C6EED" mat-button (click)="onOkClick()"/>
  </mat-dialog-actions>
</section>
