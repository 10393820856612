<div id="main" (click)="resetTimeCounter()" (mousemove)="resetTimeCounter()" (keypress)="resetTimeCounter()">
    <div id="header">
        <i aria-hidden="true" class="toggle-menu fa fa-bars" (click)="toggleClick()" *ngIf="!isApplicant"></i>
        <i aria-hidden="true" *ngIf="isApplicant" style="margin-left: 20px"></i>
        <div class="logo-container mr-2"></div>
        <span class="badge badge-dark" style="color: #fff" *ngIf="displayEnvironmentName">
            <span>{{environment?.mode | uppercase }}</span>
        </span>
        <div class="flex-1"></div>
        <span class="text-white ml-2 d-flex align-items-center" *ngIf="!isApplicant">
            <i aria-hidden="true" class="fa fa-user-circle"></i>
            <span class="ml-1">{{username}}</span>
        </span>
        <button class="btn btn-link text-white text-white ml-1" (click)="signOut()" *ngIf="!isApplicant">
            <i aria-hidden="true" class="fa fa-sign-out-alt"></i>
            <span>Sign Out</span>
        </button>
    </div>
    <div id="body" class="flex-fill">
        <div id="sidenav" class="custom-scrollbar" [ngClass]="{'hidenav': !showSideNav }" *ngIf="!isApplicant">
            <div *ngFor="let item of menu" (click)="selectMenu($event, item)" [ngClass]="{'active': activeMenu == item.title}">
                <div class="menu-item" *ngIf="item.hasAccess" [class.disabled]="item.disabled">
                    <div class="menu-item-content">
                        <i aria-hidden="true" class="menu-item-icon fa {{item.icon}}"></i>
                        <span class="menu-item-title">{{item.title}}</span>
                    </div>
                </div>
            </div>
        </div>

        <router-outlet style="display: none;"></router-outlet>
    </div>
</div>