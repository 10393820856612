<mat-dialog-content>
<mat-card appearance="outlined" style="border: none !important" >
  <mat-card-header>
    <mat-card-title>{{ model.title }}</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="flex mb-3" style="flex-direction: column;" >
      <div *ngFor="let error of model.errors" style="font-size: smaller;">
        <p>
          <span style="color: black">{{ error }}</span>
        </p>
      </div>
    </div>
    
    <mat-accordion *ngIf="isAdmin && data.stackTrace">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>View Details</mat-panel-title>
        </mat-expansion-panel-header>
        <div class="stackTrace">
          <div >{{ model.stackTrace }}</div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    
  </mat-card-content>
  <mat-card-footer>
    <div class="flex" style="justify-content: space-between; margin-top:20px;">
      <div *ngIf="data.correlationId" class="flex">
        <div id="correlationId" style="margin-top: 8px;color: black;">{{ model.correlationId }}</div>
        <button class="btn btn-sm" (click)="copyCorrelationIdToClipBoard()" matTooltip="Copy to clipboard"><i aria-hidden="true" class="fa fa-copy"></i></button>
      </div>
      <div></div>
      <div>
        <button type="button" mat-button class="mr-1 material-default" matDialogClose>CLOSE</button>
      </div>
    </div>
  </mat-card-footer>
</mat-card>
</mat-dialog-content>

