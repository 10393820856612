import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AppService, InterviewService, MonitoringService } from '@App';
import { AuraService } from '@Components/aura/aura.service';
import { ThirdPartySectionService } from '@Components/third-party-section/third-party-section.service';
import {
    CaseDetailsDTO,
    ConfigDataDTO,
    EmbeddedIntegrationData,
    InterviewDTO,
    InterviewQuestionDTO,
    InterviewSectionDTO,
    QuestionActionsDTO,
} from '@DTOs';
import {
    ActionEnum,
    CaseStatus,
    CustomEventLogMode,
    CustomEventType,
    FeatureToggle,
    IntegrationEnum,
    InterviewCloseEnum,
    InterviewMode,
    QueueStatusEnum,
} from '@Enums';
import { HubConnection } from '@microsoft/signalr';
import { Account, Client, IntegrationPending, VirtualMapKeyUpdateMessage } from '@Models';
import { ScrollToConfigOptions, ScrollToService } from '@nicky-lenaers/ngx-scroll-to';
import {
    CaseDynamicListMapkeyService,
    CaseMapKeyService,
    CaseNotificationsService,
    CaseSummaryService,
    ConfirmationDialogService,
    ConstantMapKeyService,
    FeatureManagerService,
    FilterableListService,
    InterviewSectionActionService,
    NotificationService,
    NotificationSeverity,
    ObservableService,
    SignalRService,
} from '@Services';
import { Utils } from 'app/utils';
import { SubSink } from 'subsink';
import _ = require('lodash');

import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActionDialogComponent } from '../action-dialog.component';
import { AuraIntegrationResponseHandler } from '../handlers/aura/aura-integration-response-handler.component';
import { BraintreeIntegrationResponseHandler } from '../handlers/braintree/braintree-integration-response-handler.component';
import { SbliOneIncPaymentIntegrationHandler } from '../handlers/oneinc/sbli-oneincpayment-integration-handler.component';
import { PrimorisIntegrationResponseHandler } from '../handlers/primoris/primoris-integration-response-handler.component';
import { StripeIntegrationResponseHandler } from '../handlers/stripe/stripe-integration-response-handler.component';
import {
    TrustCommerceIntegrationResponseHandler,
} from '../handlers/trustcommerce/trustcommerce-integration-response-handler.component';
import { InterviewValidationService } from './interview-validation.service';
import { EndOfInterviewHandler } from '@Interview/end-of-interview/end-of-interview-handler.component';

export type FormattedEmbeddedIntegrationData = EmbeddedIntegrationData & {
    readonly?: boolean;
}

export type FormattedInterviewSectionDTO = InterviewSectionDTO & {
    // Front-End (FE) Only fields
    sectionLocked?: boolean;
    config?: any;
    // Override for FE Only Fields
    embeddedIntegrationData: FormattedEmbeddedIntegrationData
};


@Injectable() // TODO: should this be? { providedIn: 'root' }
export default class InterviewManagerService {
    ERROR_ServiceNotInitialized = 'Service not initialized. Call initialize() before using other methods.';
    LOG_NoCaseNumberForNewInterview = 'No CaseNumber due to the interview not being created yet';

    // These props need to be provided via init()
    client: Client;
    account: Account;
    caseDetails: CaseDetailsDTO;
    sectionContainerHtmlId = '';

    configData: ConfigDataDTO;
    constantLists: any = null;
    selectedQuestion: InterviewQuestionDTO;
    interviewMode = InterviewMode.Existing;

    formattedSection: Partial<FormattedInterviewSectionDTO> = {
        sectionLocked: false,
        config: null, // TODO: Type?
    };

    constantMapKeysLoaded = false;
    userRole = '';
    auraEnabled = false;
    disableSave = false;
    calledFromThirdParty = false;


    // TECH DEBT: We don't have a "right tab" with a section modal. This is only for the interview/runtime.
    // which is needed if/when we want to update `interview.component.ts` to use this service.
    dialogRef: MatDialogRef<ActionDialogComponent>;

    currentRightTabIndex = 0;
    waitForResponse = false;
    waitIntegration: string[] = [];

    // Interal Props: only to this service
    subs = new SubSink();
    initialized = false;

    // SignalR
    signalrConnection: HubConnection;

    // Cancel
    isCancelActionSelected: boolean;

    constructor(
        private appService: AppService,
        private interviewService: InterviewService,
        private notificationService: NotificationService,
        private monitorService: MonitoringService,
        private constantMapKeyService: ConstantMapKeyService,
        private caseDynamicListMapkeyService: CaseDynamicListMapkeyService,
        private auraService: AuraService,
        private thirdPartySectionService: ThirdPartySectionService,
        private scrollToService: ScrollToService,
        private featureManagerService: FeatureManagerService,
        private interviewValidationService: InterviewValidationService,
        // Added for handling Integrations
        private interviewSectionActionService: InterviewSectionActionService,
        private caseMapKeyService: CaseMapKeyService,
        private caseSummaryService: CaseSummaryService,
        private signalRService: SignalRService,
        private caseNotificationsService: CaseNotificationsService,
        private braintreeIntegrationResponseHandler: BraintreeIntegrationResponseHandler,
        private auraIntegrationResponseHandler: AuraIntegrationResponseHandler,
        private trustCommerceIntegrationResponseHandler: TrustCommerceIntegrationResponseHandler,
        private stripeIntegrationResponseHandler: StripeIntegrationResponseHandler,
        private primorisIntegrationResponseHandler: PrimorisIntegrationResponseHandler,
        private portalOneComponentIntegrationHandler: SbliOneIncPaymentIntegrationHandler,
        private observableService: ObservableService,
        private filterableListService: FilterableListService,
        public dialog: MatDialog,
        private confirmationService: ConfirmationDialogService,
        private endOfInterviewHandler: EndOfInterviewHandler,
    ) {
    }

    //#region Init

    async init(client: Client, account: Account, caseDetails: CaseDetailsDTO, sectionContainerHtmlId: string) {
        this.client = client;
        this.account = account;
        this.caseDetails = caseDetails;
        this.sectionContainerHtmlId = sectionContainerHtmlId;

        this.userRole = this.appService.getUserRoleName();
        this.configData = await this.appService.getAllConfigData();
        this.auraEnabled = this.featureManagerService.getByName(FeatureToggle.ConfigInterviewAura).enabled;

        this.subscribeInterviewUpdates();
        this.subscribePostActionCancelUpdates();
        this.subscribeObervableService();
        this.subscribePreShowHide();
        this.subscribeInterviewActions();

        this.initialized = true;
    }

    checkInitialized() {
        if (!this.initialized)
            throw new Error(this.ERROR_ServiceNotInitialized);
    }

    //#endregion
    //#region Helpers

    scrollToTopOfContainer() {
        // this.sectionContainer.nativeElement.scrollTop = 0; // TODO: when updating the run-time with this service, verify we don't need this reference anymore.
        const container = document.querySelector(`#${this.sectionContainerHtmlId}`);
        if (!container) return;

        container.scrollTop = 0;
    }

    scrollToError(target: string) {
        // A better solution would be to return information regarding where the caller could scroll to.
        const scrollConfig: ScrollToConfigOptions = {
            target: target,
            container: this.sectionContainerHtmlId,
        };
        this.scrollToService.scrollTo(scrollConfig);
    }

    logCaseNumberAndDescriptionToMonitorService(messageDescription: string) {
        const interviewData = this.interviewService.getInterviewData();
        const caseNumber = interviewData?.caseDetails?.caseNumber || this.LOG_NoCaseNumberForNewInterview;

        this.monitorService.logEvent(`${caseNumber} - ${messageDescription} - ${this.userRole}`);
    }

    setInterviewMode(caseDetails: CaseDetailsDTO) {
        if (caseDetails) {
            if (this.interviewMode !== InterviewMode.Readonly) {
                this.interviewMode = InterviewMode.Existing;
            }
        } else {
            this.interviewMode = InterviewMode.New;
        }
    }

    setErrorsForQuestion(question: InterviewQuestionDTO, isError: boolean, errorData: string) {
        try {
            // if error exists, show validation error from backend server at field level itself.
            if (isError) {
                question.errorData = errorData;

                if (this.interviewService.getDynamicForm().get(question.id)) {
                    this.interviewService.getDynamicForm().get(question.id).setErrors({ validationError: errorData });
                }

                for (const reflexiveFormId in this.interviewService.reflexiveForms) {
                    const reflexiveForm = this.interviewService.reflexiveForms[reflexiveFormId];
                    const reflexiveQuestion = reflexiveForm.get(question.id);

                    if (reflexiveQuestion) {
                        reflexiveQuestion.setErrors({ validationError: errorData });
                    }
                }

                if (question.repeatFormId) {
                    const repeatQuestionId = question.repeatFormId.split('block')[0];
                    if (repeatQuestionId === question.parentQuestionId) {
                        const repeatFormKeys = [];
                        for (const eachKey in this.interviewService.repeatForms) {
                            if (eachKey.includes(repeatQuestionId)) {
                                repeatFormKeys.push(eachKey);
                            }
                        }
                        for (const eachRepeatFormKey of repeatFormKeys) {
                            const repeatForm = this.interviewService.repeatForms[eachRepeatFormKey];

                            if (repeatForm && repeatForm.get(question.id)) {
                                repeatForm.get(question.id).setErrors({ validationError: errorData });

                            }
                        }
                    }
                }

                if (question.answerType === 'ActionButton') {
                    this.scrollToError(question.id);
                }
            } else {
                // if no error then remove all previous errors from backend at field level.
                question.errorData = '';

                if (this.interviewService.getDynamicForm().get(question.id)) {
                    this.interviewService.getDynamicForm().get(question.id).setErrors(null);
                }

                for (const eachReflexiveFormId in this.interviewService.reflexiveForms) {
                    const reflexiveForm = this.interviewService.reflexiveForms[eachReflexiveFormId];
                    if (reflexiveForm.get(question.id)) {
                        reflexiveForm.get(question.id).setErrors(null);
                    }
                }

                if (question.repeatFormId) {
                    const repeatQuestionId = question.repeatFormId.split('block')[0];
                    const repeatFormKeys = [];

                    for (const eachKey in this.interviewService.repeatForms) {
                        if (eachKey.includes(repeatQuestionId)) {
                            repeatFormKeys.push(eachKey);
                        }
                    }

                    for (const eachRepeatFormKey of repeatFormKeys) {
                        const repeatForm = this.interviewService.repeatForms[eachRepeatFormKey];
                        if (repeatForm && repeatForm.get(question.id)) {
                            repeatForm.get(question.id).setErrors(null);
                        }
                    }
                }
            }
        } catch (error) {
            // do nothing
        }
    }

    checkIfNeededToWait(actionResult, integration?) {
        let waitResult = false;
        let waitActionParam;
        let statusMessage;
        let statusDisplayType;

        const configActionData = this.configData.actions.find(a => a.id === actionResult.actions.id);
        if (configActionData) {
            if (configActionData.actionParams && configActionData.actionParams.length > 0) {
                waitActionParam = configActionData.actionParams.find(p => p.params == 'waitForResponse');

                if (waitActionParam) {
                    if (actionResult.actionParameters && actionResult.actionParameters.length > 0) {
                        const waitActionObj = actionResult.actionParameters.find(x => x.actionParamsId == waitActionParam.id);

                        if (waitActionObj && waitActionObj.paramValue.toLowerCase() === 'true') {
                            waitResult = true;
                        }
                    }
                }
            }
        }

        if (waitResult) {
            statusMessage = configActionData.actionParams.find(p => p.params === 'statusMessage');
            statusDisplayType = configActionData.actionParams.find(p => p.params === 'statusDisplayType');
            let displayTypeObj: any;
            let statusMessageObj: any;
            if (statusDisplayType) {
                displayTypeObj = actionResult.actionParameters.find(x => x.actionParamsId == statusDisplayType.id);
            }

            if (statusMessage) {
                statusMessageObj = actionResult.actionParameters.find(x => x.actionParamsId == statusMessage.id);
            }

            if (displayTypeObj && displayTypeObj.paramValue.toLowerCase() == 'popup') {
                const pendingIntegration: IntegrationPending = {
                    actionName: actionResult.actions.displayName,
                    actionStatus: this.interviewService.getIntegrationStatus(actionResult.actionResults.data.integrationStatus),
                    message: statusMessageObj.paramValue,
                    integration: integration
                };


                this.displayPopup(pendingIntegration);
            }
        }
        return waitResult;
    }

    displayPopup(pendingIntegration: IntegrationPending, width = 450) {
        this.appService.forceHideLoadingIndicator = true;

        if (this.dialogRef != null) {
            this.dialogRef.componentInstance.data.message = pendingIntegration.message;
            return;
        }

        if (!pendingIntegration.integration.status || pendingIntegration.integration.status === QueueStatusEnum.Pending) {
            this.dialogRef = this.dialog.open(ActionDialogComponent, {
                width: `${width}px`,
                data: pendingIntegration,
                disableClose: true
            });

            const sub = this.dialogRef.componentInstance.onRefresh.subscribe((integrationData) => {
                this.refreshIntegration(integrationData.integrationId, integrationData, integrationData.name);
            });

            this.dialogRef.afterClosed().subscribe(() => {
                sub.unsubscribe();
                this.dialogRef = null;
                this.appService.forceHideLoadingIndicator = false;
            });
        }
    }

    refreshIntegration(integrationId, integrationData, name) {
        this.logCaseNumberAndDescriptionToMonitorService(`Refresh Integration (${integrationId})`);
        let status: any;
        let id = integrationId;

        if (integrationData != null) {
            status = integrationData.status;

            if (integrationId === '') {
                id = integrationData.id;
            }
        }

        this.interviewService.refreshIntegration(id, integrationData.id, status, name).subscribe({
            next: (data) => {
                if (data.status === 'success') {
                    const resp = JSON.parse(data.data);

                    if (resp.details) {
                        this.integrationResponseHandler(data.data, this);
                    }
                }
            },
            error: (error) => {
                this.appService.showResponseErrorMsg(error);

                // if refresh fails should we close the popup?
                if (this.dialogRef) {
                    this.dialogRef.close();
                }
            }
        });
    }

    setIntegrationData() {
        // same logic as when integration response is received from socket.
        if (this.interviewService.getMibData().details && this.interviewService.getMibData().details.parties) {
            const questionMibMap = {};
            const parentQuestionMap = [];
            for (const eachDetail of this.interviewService.getMibData().details.parties) {
                eachDetail.mibCodes.forEach(mibCode => {
                    if (mibCode.mapkeyId && eachDetail.relationRoleCode) {
                        parentQuestionMap.push([mibCode.mapkeyId, eachDetail.relationRoleCode]);
                    }
                    Object.assign(mibCode, eachDetail);
                    delete mibCode.mibCodes;
                    questionMibMap[mibCode.mapkeyId] = questionMibMap[mibCode.mapkeyId] ? questionMibMap[mibCode.mapkeyId] : [];
                    questionMibMap[mibCode.mapkeyId].push(mibCode);
                });
            }
            const visiblequestionList = [];

            this.interviewService.getInterviewData().sections.forEach(section => {
                section.questions.forEach(question => {
                    if (question.healthQuestionDetail && question.healthQuestionDetail.length) {
                        if (question.healthQuestionDetail[0].questionId === question.id) {
                            for (let i = 0; i < parentQuestionMap.length; i++) {
                                if (parentQuestionMap[i][0] === question.mapkeysId && question.display === true) {
                                    if (parentQuestionMap[i][1].toLowerCase() === 'hit') {
                                        visiblequestionList.push(question.healthQuestionDetail[0].hitQuestionId);
                                        visiblequestionList.push(question.healthQuestionDetail[0].correctedQuestionId);
                                    }
                                    if (parentQuestionMap[i][1].toLowerCase() === 'try') {
                                        visiblequestionList.push(question.healthQuestionDetail[0].tryQuestionId);
                                        visiblequestionList.push(question.healthQuestionDetail[0].correctedQuestionId);
                                    }
                                }
                            }
                        }
                    }
                });
            });
            this.interviewService.getInterviewData().sections.forEach(section => {
                section.questions.forEach(question => {
                    if (visiblequestionList.includes(question.id)) {
                        question.display = true;
                    }
                });
            });
            this.interviewService.getInterviewData().sections.forEach(section => {
                section.questions.forEach(question => {
                    if (questionMibMap[question.mapkeysId] && questionMibMap[question.mapkeysId].length) {
                        question.mibDetails = questionMibMap[question.mapkeysId];
                    }
                });
            });
        }

        if (this.interviewService.getGiactData().details) {
            const activeSectionId = this.interviewService.getInterviewData().activeSectionId;
            const currentSection = this.interviewService.getInterviewData().sections.find(section => section.id === activeSectionId);
            const giactQuestion = currentSection.questions.find(q => q.id === this.interviewService.getGiactData().questionId);

            if (giactQuestion) {
                giactQuestion.integrationMessages = 'GIACT';
                giactQuestion.intergrationData = this.interviewService.getGiactData().details.message;
            }
        }
    }

    processSectionsActions(section: Partial<FormattedInterviewSectionDTO>) {
        if (section.sectionActions && section.sectionActions.length > 0) {
            for (const eachActionResult of section.sectionActions) {
                // GIACT
                // TECH DEBT::ACTION_RESULTS (See `actions-dto.d.ts`) there's no "actionResults" prop on `.actions`. I've
                // added the property for the sake of Typescript but we need to investigate to see if this is a bug or if
                // something is dynamically adding the property on the front-end side. There is an "actionsResult" on the 
                // `SectionsActionDTO` model.  Maybe it was meant to be that?
                if (eachActionResult.actions.id === ActionEnum.GiactVerification && eachActionResult.actions.actionResults) {
                    if (!this.interviewService.getGiactData().status) {
                        this.interviewService.getGiactData().status = '00000000-0000-0000-0000-000000000002';
                        // this.currentRightTabIndex = 1;
                    }
                }
                // RX
                if (eachActionResult.actions.id === ActionEnum.RxIntegration && eachActionResult.actions.actionResults) {
                    if (!this.interviewService.getRxData().status) {
                        this.interviewService.getRxData().status = '00000000-0000-0000-0000-000000000002';
                        // this.currentRightTabIndex = 1;
                    }
                }
                // MIB
                if (eachActionResult.actions.id === ActionEnum.MIBIntegration && eachActionResult.actions.actionResults) {
                    if (!this.interviewService.getMibData().status) {
                        this.interviewService.getMibData().status = '00000000-0000-0000-0000-000000000002';
                        // this.currentRightTabIndex = 1;
                    }
                }
                if (eachActionResult.actions.id === ActionEnum.ValidateRepeatBlockSum && eachActionResult.actionResults) {
                    if (eachActionResult.actionResults.isError) {
                        this.notificationService.showNotification({
                            severity: NotificationSeverity.Error,
                            message: eachActionResult.actionResults.message,
                            log: false
                        });
                    }
                }

                if (eachActionResult.actions.id === ActionEnum.Disabled && eachActionResult.actionResults) {
                    section.isLocked = eachActionResult.actionResults.data.disabled;
                }
            }
        }
    }

    validateAllFormFields(form) {
        Object.keys(form.controls).forEach(field => {
            const control = form.get(field);
            if (control instanceof UntypedFormControl) {
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof UntypedFormGroup) {
                this.validateAllFormFields(control);
            }
        });
    }

    /**
     * Checks if there's a `DisplayMessage` Question of type `Error` that is visable.
     * @returns `true` on the first occurance of a visable Error DisplayMessage; otherwise `false`.
     */
    hasVisableErrorDisplayMessageQuestion() {
        for (const eachQuestion of this.formattedSection.questions) {
            if (eachQuestion.answerType === 'DisplayMessage' && eachQuestion.MessageType === 'Error' && eachQuestion.display)
                return true;
        }

        return false;
    }

    async finalizeSave(allFormsValid: boolean, dynamicForm: any, reflexiveForms: any, repeatForms: any, healthForms: any) {

        // Reasons not to save

        if (!allFormsValid) {
            // if invalid, update validity for all fields, i.e show validation errors at field level itself.
            this.validateAllFormFields(dynamicForm);
            for (const eachReflexiveFormIndex in reflexiveForms) {
                const eachReflexiveForm = reflexiveForms[eachReflexiveFormIndex];
                this.validateAllFormFields(eachReflexiveForm);

            }
            for (const eachRepeatFormIndex in repeatForms) {
                const eachRepeatForm = repeatForms[eachRepeatFormIndex];
                this.validateAllFormFields(eachRepeatForm);

            }
            for (const eachHealthFormIndex in healthForms) {
                const eachHealthForm = healthForms[eachHealthFormIndex];
                this.validateAllFormFields(eachHealthForm);
            }

            return false;
        }

        if (this.hasVisableErrorDisplayMessageQuestion()) {
            this.notificationService.showNotification({ severity: NotificationSeverity.Error, message: 'Check error message(s)' });
            return false;
        }

        if (this.interviewService.shouldWaitForAnyIntegration()) {
            this.notificationService.showNotification({ severity: NotificationSeverity.Error, message: 'Wait for running integration(s) to complete' });
            return false;
        }

        // Save
        this.scrollToTopOfContainer();

        await this.interviewService.saveInterview(this.formattedSection.id);

        // The return here simply means save interview was called.
        // It does not mean the saveInterview was successful.
        return true;
    }

    showCancelInterviewModal(isTerminate = false) {
        this.endOfInterviewHandler.handleClose(this.account, InterviewCloseEnum.Interview, isTerminate);
    }

    verifyCaseStatus() {
        // when a case is getting cancelled , we ask for a popup to as whether the user really wants to cancel.
        this.confirmationService.confirm({
            message: `Are you sure you want to cancel the interview?`,
            key: 'interviewActionCancelConfirm',
            accept: () => {
                this.showCancelInterviewModal(true);
            },
            reject: () => {
                this.isCancelActionSelected = true;
            }
        });
    }
    //#endregion
    //#region Integration Helpers

    populateFieldsByMapKeyName(mapKeyName, value) {
        this.interviewService.getInterviewData().sections.forEach(section => {
            section.questions.forEach(question => {
                if (question.mapKeyName === mapKeyName) {
                    question.answerValue = value;
                    if (question.parentQuestionId === '00000000-0000-0000-0000-000000000000') {
                        if (this.interviewService.getDynamicForm().get(question.id)) {
                            const formControl = this.interviewService.getDynamicForm().get(question.id);

                            formControl.setValue(value);
                            // formControl.disable();

                        }
                    } else {
                        const reflexiveForm = this.interviewService.reflexiveForms[question.parentQuestionId];
                        if (reflexiveForm && reflexiveForm.get(question.id)) {
                            const formControl = reflexiveForm.get(question.id);

                            formControl.setValue(value);
                            // formControl.disable();
                        }
                    }

                }
            });
        });
    }

    stopWaitIntegration(name: string, integrationData: { waitForResponse: boolean }) {
        if (this.waitIntegration.includes(name) && this.waitForResponse) {
            integrationData.waitForResponse = this.waitForResponse = false;
            this.waitIntegration = this.waitIntegration.filter(item => item !== name);
        }
    }

    downloadDocument(documentResponse, me) {
        let isIntegrationStatusCompleted = false;
        const documentQueueId = documentResponse.details;
        const docData = this.interviewService.getIntegrationV2Data(documentResponse.integrationRequestId);
        docData.details = documentResponse.details;
        docData.status = documentResponse.status;
        docData.errorMessage = documentResponse.message;
        this.interviewService.updateIntegrationActionResult(docData);

        if (this.interviewService.getIntegrationStatus(docData.status) === 'Completed' && documentQueueId) {
            isIntegrationStatusCompleted = true;

            // TODO: TECH DEBT: Need to re-enable this before this can be used in `interview.component.ts`  See: `integrationResponseHandler()` below
            // this.caseSummaryService.getCaseDocumentFromQueue(documentQueueId).subscribe({
            //     next: (result) => {
            //         this.page = 1;
            //         this.pdfResult = result.objectUrl;
            //         this.targetElement = document.querySelector('#myPdfViewer');
            //         const calculateWidth = this.isDesktop() ? '55%' : '90%';
            //         const dialogRef = this.dialog.open(PdfViewerDialog, {
            //             minWidth: calculateWidth,
            //             height: '90%',
            //             maxWidth: '90%',
            //             data: this.pdfResult,
            //             panelClass: 'mat-dialog--no-padding'
            //         });
            //     },
            //     error: (error) => {
            //         this.appService.showResponseErrorMsg(error);
            //     }
            // });
        }

        return isIntegrationStatusCompleted;
    }

    processIntegrationResponse(integrationData, me) {
        let isIntegrationStatusCompleted = false;

        if (integrationData.integrationType === 'RX') {
            // if data is received, set corresponding integration status to "Completed" which is shown in the right panel.
            const rx = this.interviewService.getRxData();
            isIntegrationStatusCompleted = true;
            if (!integrationData.details.rxDetails) {
                integrationData.details = JSON.parse(integrationData.details);
            }
            rx.details = integrationData.details.rxDetails.jsonData;
            rx.status = rx.details.status;
            rx.errorMessage = rx.details.message;
            this.interviewService.updateIntegrationActionResult(rx);
            this.interviewService.setCaseData(integrationData.details.rxDetails);
            this.interviewService.mergeMapkeys(integrationData.details.mapkeys);
            this.currentRightTabIndex = 1;

            this.stopWaitIntegration('rx', rx);
        }

        if (integrationData.integrationType === 'Quote') {
            const quote = this.interviewService.getQuoteData();
            isIntegrationStatusCompleted = true;
            if (!integrationData.details.quoteDetails) {
                integrationData.details = JSON.parse(integrationData.details);
            }
            quote.details = integrationData.details.quoteDetails.jsonData;
            quote.status = quote.details.status;
            quote.errorMessage = quote.details.message;
            this.interviewService.updateIntegrationActionResult(quote);
            this.interviewService.setCaseData(integrationData.details.quoteDetails);
            this.interviewService.mergeMapkeys(integrationData.details.mapkeys);

            if (quote.status === '00000000-0000-0000-0000-000000000003') {
                this.populateFieldsByMapKeyName('Case_Applicant_MonthlyPremAmt', quote.details.quoteValues[0].monthlyPremAmt);
                this.populateFieldsByMapKeyName('Case_Applicant_QuarterlyPremAmt', quote.details.quoteValues[0].quarterlyPremAmt);
                this.populateFieldsByMapKeyName('Case_Applicant_SemiAnnualPremAmt', quote.details.quoteValues[0].semiAnnualPremAmt);
                this.populateFieldsByMapKeyName('Case_Applicant_AnnualPremAmt', quote.details.quoteValues[0].annualPremAmt);
            }

            this.stopWaitIntegration('quote', quote);
        }

        if (integrationData.integrationType === 'Agent') {
            const agent = this.interviewService.getAgentData();
            isIntegrationStatusCompleted = true;
            if (!integrationData.details.agentDetails) {
                integrationData.details = JSON.parse(integrationData.details);
            }
            agent.details = integrationData.details.agentDetails.jsonData;
            agent.status = agent.details.status;
            agent.errorMessage = agent.details.message;
            this.interviewService.updateIntegrationActionResult(agent);
            this.interviewService.setCaseData(integrationData.details.agentDetails);
            this.interviewService.mergeMapkeys(integrationData.details.mapkeys);

            // fill the coresponding mapkeys based on data received.
            let agentMapKeyNames = [];
            if (agent.status === '00000000-0000-0000-0000-000000000004') {
                // Failed
                agentMapKeyNames = [
                    { mapkey: 'Case_Agent_FirstName', value: '' },
                    { mapkey: 'Case_Agent_LastName', value: '' },
                    { mapkey: 'Case_Agent_BusinessName', value: '' },
                    { mapkey: 'Case_Agent_IsActive', value: '' }
                ];
            } else if (integrationData.details.agentNumberMapkey === 'Case_Agent_AgentNumber') {
                if (!agent.details.agentInfos) {
                    const d = JSON.parse(integrationData.details.agentDetails.jsonData);
                    agent.details = d;
                }

                agentMapKeyNames = [
                    { mapkey: 'Case_Agent_FirstName', value: agent.details.agentInfos[0].agentNameFirst },
                    { mapkey: 'Case_Agent_LastName', value: agent.details.agentInfos[0].agentNameLast },
                    { mapkey: 'Case_Agent_BusinessName', value: agent.details.agentInfos[0].agentBusinessName },
                    { mapkey: 'Case_Agent_IsActive', value: agent.details.agentInfos[0].isActive }
                ];
            } else {
                agentMapKeyNames = [
                    { mapkey: 'Case_Agent2_FirstName', value: agent.details.agentInfos[1].agentNameFirst },
                    { mapkey: 'Case_Agent2_LastName', value: agent.details.agentInfos[1].agentNameLast },
                    { mapkey: 'Case_Agent2_BusinessName', value: agent.details.agentInfos[1].agentBusinessName },
                    { mapkey: 'Case_Agent2_IsActive', value: agent.details.agentInfos[1].isActive }
                ];
            }
            for (const agentMapKeyName of agentMapKeyNames) {
                this.populateFieldsByMapKeyName(agentMapKeyName.mapkey, agentMapKeyName.value);
            }

            this.stopWaitIntegration('agent', agent);
        }

        if (integrationData.integrationType === 'MIB') {
            const parentQuestionMap = [];
            const mib = this.interviewService.getMibData();
            isIntegrationStatusCompleted = true;
            if (!integrationData.details.mibDetails) {
                integrationData.details = JSON.parse(integrationData.details);
            }
            mib.details = integrationData.details.mibDetails.jsonData;
            mib.status = mib.details.status;
            mib.errorMessage = mib.details.message;
            this.interviewService.updateIntegrationActionResult(mib);
            this.interviewService.setCaseData(integrationData.details.mibDetails);
            this.interviewService.mergeMapkeys(integrationData.details.mapkeys);
            this.currentRightTabIndex = 1;
            const questionMibMap = {};

            this.stopWaitIntegration('mib', mib);

            if (mib.status === '00000000-0000-0000-0000-000000000003') {
                // following logic adds the HIT details to corresponding health question.
                // this data is shown below the health question in the form of a table.
                for (const eachDetail of mib.details.parties) {
                    eachDetail.mibCodes.forEach(mibCode => {
                        if (mibCode.riskType) {
                            if (mibCode.mapkeyId && eachDetail.relationRoleCode) {
                                parentQuestionMap.push([mibCode.mapkeyId, eachDetail.relationRoleCode]);
                            }
                            Object.assign(mibCode, eachDetail);
                            delete mibCode.mibCodes;
                            questionMibMap[mibCode.mapkeyId] = questionMibMap[mibCode.mapkeyId] ? questionMibMap[mibCode.mapkeyId] : [];
                            questionMibMap[mibCode.mapkeyId].push(mibCode);
                        }
                    });
                }
                const visiblequestionList = [];

                this.interviewService.getInterviewData().sections.forEach(section => {
                    section.questions.forEach(question => {
                        if (question.healthQuestionDetail && question.healthQuestionDetail.length) {
                            if (question.healthQuestionDetail[0].questionId === question.id) {
                                for (let i = 0; i < parentQuestionMap.length; i++) {
                                    if (parentQuestionMap[i][0] === question.id) {
                                        if (parentQuestionMap[i][1].toLowerCase() === 'hit') {
                                            visiblequestionList.push(question.healthQuestionDetail[0].hitQuestionId);
                                            visiblequestionList.push(question.healthQuestionDetail[0].correctedQuestionId);
                                        }
                                        if (parentQuestionMap[i][1].toLowerCase() === 'try') {
                                            visiblequestionList.push(question.healthQuestionDetail[0].tryQuestionId);
                                            visiblequestionList.push(question.healthQuestionDetail[0].correctedQuestionId);
                                        }
                                    }
                                }
                            }
                        }
                    });
                });
                this.interviewService.getInterviewData().sections.forEach(section => {
                    section.questions.forEach(question => {
                        if (questionMibMap[question.mapkeysId] && questionMibMap[question.mapkeysId].length) {
                            question.mibDetails = questionMibMap[question.mapkeysId];
                        }
                    });
                });
            }
        }

        if (integrationData.integrationType === 'GIACT') {
            const giact = this.interviewService.getGiactData();
            isIntegrationStatusCompleted = true;
            if (!integrationData.details.giactDetails) {
                integrationData.details = JSON.parse(integrationData.details);
            }
            giact.details = integrationData.details.giactDetails.jsonData;
            giact.status = giact.details.status;
            giact.errorMessage = giact.details.message;
            this.interviewService.updateIntegrationActionResult(giact);
            this.interviewService.setCaseData(integrationData.details.giactDetails);
            this.interviewService.mergeMapkeys(integrationData.details.mapkeys);
            this.currentRightTabIndex = 1;
            const activeSectionId = this.interviewService.getInterviewData().activeSectionId;

            const currentSection = this.interviewService.getInterviewData().sections.find(section => section.id === activeSectionId);
            const giactQuestion = currentSection.questions.find(q => q.id === giact.questionId);
            // get the question and show the giact response under the question.
            if (giactQuestion) {

                giactQuestion.integrationMessages = 'GIACT';
                giactQuestion.intergrationData = giact.details.message;
            }

            this.stopWaitIntegration('giact', giact);
        }

        if (isIntegrationStatusCompleted) {
            const currentSectionSummary = this.interviewService.getActiveVisibleSectionSummary();

            for (const sectionAction of currentSectionSummary.sectionActions) {
                if (integrationData.integrationType === 'MIB' && sectionAction.actionsId === '00000000-0000-0000-0000-000000000009') {
                    integrationData.integrationRequestId = sectionAction.id;
                }
            }
        }


        return isIntegrationStatusCompleted;
    }

    processIntegrationResponseV2(integrationData, me) {
        let isIntegrationStatusCompleted = false;

        if (integrationData.integrationType !== 'V2' && integrationData.responseType !== 'V2')
            return isIntegrationStatusCompleted;

        if (!integrationData.details.integrationRequestId) {
            integrationData.details = JSON.parse(integrationData.details);
        }

        const v2Data = this.interviewService.getIntegrationV2Data(integrationData.details.integrationRequestId);

        if (v2Data.integrationId == null || v2Data.integrationId === '') {
            v2Data.integrationId = integrationData.integrationId;
            v2Data.name = integrationData.integrationName;
        }

        isIntegrationStatusCompleted = true;
        v2Data.details = integrationData.details;
        v2Data.status = integrationData.details.status;
        v2Data.errorMessage = integrationData.details.message;
        this.interviewService.updateIntegrationActionResult(v2Data);
        this.interviewService.mergeMapkeys(v2Data.details.updatedMapKeys);

        if (v2Data.details.updatedMapKeys) {
            for (const mapKey of v2Data.details.updatedMapKeys) {
                this.populateFieldsByMapKeyName(mapKey.name, mapKey.value);
            }
        }

        this.interviewService.completeIntegrationMetric(v2Data.integrationId);

        this.currentRightTabIndex = 1;

        if (v2Data.integrationId === IntegrationEnum.Docusign) {
            if (v2Data.status === QueueStatusEnum.Completed) {

                const envelope = _.get(integrationData, 'details.clientProperties.envelope');
                if (envelope != null && v2Data.id !== IntegrationEnum.Docusign && this.interviewService.popupIntegrationResponse(v2Data.questionId, v2Data.id)) {
                    // TODO: Port over `showSignatureDialog` from `interview.component.ts`
                    // this.showSignatureDialog(integrationData.caseDetailId, integrationData.caseIntegrationQueueId, envelope);
                }
            } else if (v2Data.status === QueueStatusEnum.Failed) {
                this.notificationService.showNotification({ severity: NotificationSeverity.Error, message: v2Data.errorMessage, log: true });
            }
        }

        if (v2Data.integrationId === IntegrationEnum.BrainTree) {
            this.braintreeIntegrationResponseHandler.handleResponse(this.client.id, this.account.id, v2Data, integrationData);
        }

        if (v2Data.integrationId === IntegrationEnum.Aura) {
            this.auraIntegrationResponseHandler.handleResponse(this.client.id, this.account.id, v2Data, integrationData);
        }

        if (v2Data.integrationId === IntegrationEnum.TrustCommerce) {
            this.trustCommerceIntegrationResponseHandler.handleResponse(this.client.id, this.account.id, v2Data, integrationData);
        }

        if (v2Data.integrationId === IntegrationEnum.Stripe) {
            this.stripeIntegrationResponseHandler.handleResponse(this.client.id, this.account.id, v2Data, integrationData);
        }

        if (v2Data.integrationId === IntegrationEnum.PrimorisEmbedded) {
            this.primorisIntegrationResponseHandler.handleResponse(this.client.id, this.account.id, v2Data, integrationData);
        }

        if (v2Data.integrationId === IntegrationEnum.PortalOne) {//oneINC
            const sbliOneIncPaymentResponse = _.get(integrationData, 'details.clientProperties');
            this.portalOneComponentIntegrationHandler.handleResponse(this.client.id, this.account.id, sbliOneIncPaymentResponse, integrationData);
        }

        return isIntegrationStatusCompleted;
    }

    async integrationResponseHandler(event, me) {
        if (event === undefined) {
            console.log('Integration response handler called with out data');
            return;
        }

        // socket response data
        const actionResponse = (typeof event === 'string') ? JSON.parse(event) : event;

        let isIntegrationStatusCompleted = false;

        if (actionResponse.responseType === 'document') {
            isIntegrationStatusCompleted = this.downloadDocument(actionResponse, me);
        } else if (actionResponse.responseType === 'integration') {
            isIntegrationStatusCompleted = this.processIntegrationResponse(actionResponse, me);
        } else if (actionResponse.responseType === 'V2') {
            isIntegrationStatusCompleted = this.processIntegrationResponseV2(actionResponse, me);
        }

        if (isIntegrationStatusCompleted) {
            if (actionResponse.details.updatedMapKeys) {
                const dynamicListMapkeys = _.filter(actionResponse.details.updatedMapKeys, function (e) {
                    return e.name.toLowerCase().startsWith("account");
                });
                dynamicListMapkeys.forEach(element => {
                    const currentValue = JSON.parse(element.value);
                    const currentConstValue = { [currentValue[0].mapKeysId]: currentValue };

                    const c = _.extend(this.constantLists, currentConstValue);
                    this.constantLists = c;
                    this.interviewService.constantLists = this.constantLists;
                });
            }

            if (!this.interviewSectionActionService.sectionConfiguredToWait(this.interviewService.getInterviewData().activeSectionId, this.interviewService.getInterviewData().visibleSections) || !this.interviewSectionActionService.isActive) {
                await this.caseMapKeyService.updateAndMergeVirtualMapKeys(this.interviewService.getInterviewData().caseDetails.accountId, this.interviewService.getInterviewData().caseDetails.id);
            } else {
                await this.interviewSectionActionService.rerunPopupRule(this.interviewService.getInterviewData().caseDetails.accountId, this.interviewService.getInterviewData().caseDetails.id, this.interviewService.getInterviewData().activeSectionId, this.interviewService.getInterviewData().visibleSections);
            }

            if (this.dialogRef) {
                if ((actionResponse.integrationId !== IntegrationEnum.Docusign && this.dialogRef.componentInstance.data && this.dialogRef.componentInstance.data.integrationId !== IntegrationEnum.Docusign) ||
                    (actionResponse.integrationId === IntegrationEnum.Docusign && this.dialogRef.componentInstance.data && this.dialogRef.componentInstance.data.integrationId === IntegrationEnum.Docusign)) {
                    this.dialogRef.close();
                }
            }

            if (event.integrationRequestId != null) {
                let integrationName = '';
                if (actionResponse.responseType === 'V2') {
                    integrationName = actionResponse.integrationName;
                }

                this.monitorService.logCaseEvent(`Client - Integration ${integrationName} complete`, event.caseDetailId, event.caseIntegrationQueueId, CustomEventType.IntegrationMetric, CustomEventLogMode.Standalone);
            }

            const interviewData = this.interviewService.getInterviewData();
            const activeSectionId = interviewData.activeSectionId;
            if (interviewData.caseDetails.statusId === CaseStatus.Submitted || (this.interviewService.isInterviewSaving && actionResponse.integrationName === 'DocuSign: eSig')) {
                return;
            }

            this.interviewService.updateQuestionPreActionsForSection(activeSectionId);
        }
    }

    //#endregion
    //#region Subscriptions

    // DEV NOTE: Things to consider pulling over from `interview.component.ts`
    // this.interviewService.unauthorizedAccessObservable.subscribe();
    // this.interviewService.onTriggerRefreshIntegration.subscribe();
    // this.interviewService.cancelActionObservable.subscribe();  // Is this the "cancel" interview that opens the Save & Exit, discard
    subscribePostActionCancelUpdates() {
        this.subs.add(this.interviewService.cancelActionObservable.subscribe(data => {
            this.verifyCaseStatus();
        }));
    }


    subscribeInterviewUpdates() {
        this.subs.add(this.interviewService.updatedInterview.subscribe(data => {
            this.interviewService.setInterviewData(data);
        }));
    }

    subscribeObervableService() {
        this.subs.add(this.observableService.triggerMergeMapKeys.subscribe((mapkeys) => {
            this.interviewService.mergeMapkeys(mapkeys);
        }));

        this.subs.add(this.observableService.triggerConstantMapkeyVariantUpdate.subscribe(() => {
            this.filterableListService.updateFilterableListAsync(this.constantLists, this.interviewMode);
        }));

        this.subs.add(this.filterableListService.filterableListUpdated$.subscribe((filterableList) => {
            if (Object.keys(filterableList).length != 0)
                this.interviewService.constantLists = filterableList;
        }));
    }

    subscribeToConstantMapkeys(interview: InterviewDTO) {
        this.subs.add(this.constantMapKeyService.getConstantMapKeys(this.account.id, this.account.versionNumber, this.interviewService.getInterviewData().activeSectionId).subscribe(async response => {
            this.constantLists = response;
            if (this.caseDetails.id) {
                const constantKeyList = await this.caseDynamicListMapkeyService.getDynamicMapKeys(this.account.id, this.caseDetails.id, this.interviewService.getInterviewData().activeSectionId);
                const c = _.extend(this.constantLists, constantKeyList);
                this.constantLists = c;
            }
            this.interviewService.constantLists = this.constantLists;

            if (!interview.sections.some(section => section.id === interview.activeSectionId)) {
                interview.activeSectionId = interview.sections[interview.sections.length - 1].id;
            }

            const activeSectionId = interview.activeSectionId;
            const activeQuestionNumber = interview.activeQuestionNumber;

            this.formattedSection = interview.sections.find(section => section.id === activeSectionId);
            this.formattedSection.questions.forEach(question => {
                const answerTypeObject = this.configData.answerTypes.find(answerType => answerType.id === question.answerTypeId);
                question.answerType = answerTypeObject.code;
                if (question.order === activeQuestionNumber) {
                    this.selectedQuestion = question;
                }
                // TECH DEBT: DEV NOTE: I've moved `DisplayType` onto type and that appears to be the easiest property to identy. However, I just came across a second value that is not
                // throwing typing compile errors: `ButtonText`. Without this value some buttons might will be missing text. This makes me question how much more typing needs to be
                // converted to feel comfortable removing this logic.
                question.propertyValues.forEach(property => {
                    question[property.name] = property.value;
                });

                if (question.questionActions && question.questionActions.length > 0) {
                    if (this.interviewMode !== InterviewMode.Readonly) {
                        // pre actions are run in backend itself before section is loaded, so update the interview based on their results.
                        this.updateInterviewBasedOnActions(question.questionActions);
                    }
                }
            });

            if (this.interviewMode !== InterviewMode.Readonly) {

                const depqnslist = interview.dependencyQuestionList; // TODO: It would be useful if we can type `.dependencyQuestionList`
                if (depqnslist) {
                    // this is to track and call pre actions on dependent questions if any of the questions whose value change might affect the show/hide of those dependent questions.
                    for (const eachQuestionId of Object.keys(depqnslist)) {
                        const mapKeyData = depqnslist[eachQuestionId];
                        for (const eachMapKey of mapKeyData) {
                            const depQuestion = this.formattedSection.questions.find(q => q.mapkeysId === eachMapKey);
                            if (depQuestion) {
                                depQuestion.dependentQuestions = depQuestion.dependentQuestions ? depQuestion.dependentQuestions : [];
                                if (!depQuestion.dependentQuestions.includes(eachQuestionId)) {
                                    depQuestion.dependentQuestions.push(eachQuestionId);
                                }
                            }
                        }
                    }
                }

                for (const eachError of this.formattedSection.errors) {
                    if (eachError.questionId) {
                        if (eachError.questionId === Utils.emptyGuid) {
                            this.notificationService.showNotification({ severity: NotificationSeverity.Error, message: eachError.message });
                        } else {
                            const errorQuestion = this.formattedSection.questions.find(q => q.id === eachError.questionId);
                            if (errorQuestion) {
                                // DEV NOTE: the method `setErrorsForQuestion` will modify `question.errorData`. The below setting of the value
                                // seems to be redundant. It's also worth noting that 
                                errorQuestion.errorData = eachError;
                                this.setErrorsForQuestion(errorQuestion, true, eachError.message);
                            }
                        }
                    }
                }
            }

            // this is to set integration data on interview restart or on clickin next.
            this.setIntegrationData();
            if (this.interviewMode !== InterviewMode.Readonly) {
                this.processSectionsActions(this.formattedSection);
            }

            // TODO::REENABLE
            // TODO: Is a "Restart Script" necessary for our UW Tab context?  Must ask someone about this.
            // if (this.interviewMode !== InterviewMode.Readonly && this.isRestart && this.formattedSection.restartScript) {
            //     // if interview is restarted, show restart script configured for that section.
            //     this.restartScript = this.interviewService.formattedText(this.formattedSection.restartScript);
            //     this.showRestartScript = true;
            //     this.isRestart = false;
            // }

            this.constantMapKeysLoaded = true;
        }));
    }

    subscribePreShowHide() {
        this.subs.add(this.interviewService.processPreShowHideObservable.subscribe(results => {
            if (!results.action) return;

            this.updateInterviewBasedOnActions([results.action]);
        }));
    }

    subscribeInterviewActions() {
        this.subs.add(this.interviewService.actionObservable.subscribe(actionResponse => {
            if (actionResponse.data) {
                if (actionResponse.data[0].actions.name === 'Validate Manual Rx RiskMap') {
                    // TODO: Renable for Runtime Integraiton
                    // this.rxHx = actionResponse.data[0].actionResults.data;
                    // this.currentRightTabIndex = 1;
                }
                this.updateInterviewBasedOnActions(actionResponse.data);
            } else if (actionResponse.type) {
                const intData = this.interviewService.getIntegrationDataByName(actionResponse.type);
                if (intData) {
                    // Setup question that launched the action plus reset status to Pending
                    intData.questionId = actionResponse.questionId;
                    intData.status = '00000000-0000-0000-0000-000000000002';

                    const caseData = this.interviewService.getCaseData(actionResponse.type);
                    if (caseData) {
                        caseData.status = intData.status;
                    }
                }
            }
        }));
    }

    //#endregion
    //#region SignalR Handlers

    signalrCaseMatches(eventCaseId: string) {
        if (eventCaseId === this.caseDetails.id) return true;

        console.warn(`CaseId's Don't match. signalr: [${eventCaseId}] <=> [${this.caseDetails.id}] : current page`);
        return false;
    }

    signalrHandleCaseEnvelopeStatusUpdate(event: any) { // TODO: need to type this signalr event
        if (!this.signalrCaseMatches(event.caseDetailId)) return;

        //this.envelopeUpdateSubject.next(event.status);
    }

    signalrHandleIntegrationResponse(event: any) { // TODO: need to type this signalr event
        if (!this.signalrCaseMatches(event.caseDetailId)) return;

        this.integrationResponseHandler(event, this);
    }

    signalrHandleWebhookResponse(event: any) { // TODO: need to type this signalr event
        if (!this.signalrCaseMatches(event.caseDetailId)) return;

        //this.webhookResponseHandler(event, this);
    }

    async signalrHandleVirtualMapkeyUpdate(_event: VirtualMapKeyUpdateMessage) {
        // await this.virtualMapKeyUpdateHandler(event);
    }

    signalrStarted() {
        this.subs.add(this.caseNotificationsService.addUserToCase(this.client.id, this.caseDetails.id).subscribe(_next => {
            console.log('SignalR connection started');
        }));
    }

    signalrOnClose() {
        this.caseNotificationsService.removeUserFromCase();
        console.log('SignalR Disconnected ');
    }

    setupSignalRConnection() {
        this.checkInitialized();
        if (this.interviewMode === InterviewMode.Readonly) return;

        // Create HubConnection
        this.signalrConnection = this.signalRService.getCaseSignalRConnection(this.caseDetails.id);
        this.signalrConnection.serverTimeoutInMilliseconds = 1000 * 60 * 15; // 15 minutes

        // Setup Listeners
        this.signalrConnection.on('connected', event => console.log('SignalR Connected : ' + event));
        this.signalrConnection.on('caseEnvelopeStatusUpdate', this.signalrHandleCaseEnvelopeStatusUpdate.bind(this));
        this.signalrConnection.on('integrationResponse', this.signalrHandleIntegrationResponse.bind(this));
        this.signalrConnection.on('webhookResponse', this.signalrHandleWebhookResponse.bind(this));
        this.signalrConnection.on('virtualMapKeyUpdate', this.signalrHandleVirtualMapkeyUpdate.bind(this));
        this.signalrConnection.onclose(this.signalrOnClose.bind(this));

        // Start SignalR
        this.signalrConnection.start().then(this.signalrStarted.bind(this)).catch(err => console.error(err?.message));
    }

    //#endregion

    updateInterviewBasedOnActions(questionActions: QuestionActionsDTO[]) {
        this.checkInitialized();

        const activeSection = this.interviewService.getActiveSection();

        for (const eachActionResult of questionActions) {
            let questionDisplay = true;
            let questionDisable = activeSection.isLocked;

            const question = activeSection.questions.find(q => q.id === eachActionResult.questionsId);
            const questionAction = question == null ? null : question.questionActions.find(qa => qa.id === eachActionResult.id);
            if (!questionAction) {
                continue;
            }

            questionAction.actionResults = eachActionResult.actionResults;
            questionAction.triggerCount = eachActionResult.triggerCount;
            if (eachActionResult.actionResults && eachActionResult.actionResults.mapkeyValues) {
                this.interviewService.mergeMapkeys(eachActionResult.actionResults.mapkeyValues);
            }

            if (eachActionResult.actionResults && eachActionResult.actionResults.data && (eachActionResult.actionResults.data.display === 'false' || eachActionResult.actionResults.data.display === false)) {
                // for preactions, this is used to set display property of question which is the only property used to hide/show question in form. No other property is used to hide/show question.
                questionDisplay = false;

                if (question.answerType === 'RepeatBlock' && question.blocks && question.blocks.length) {
                    this.interviewService.removeRepeatBlocks(question);
                    question.blocks.forEach(block => {
                        if (this.interviewService.repeatForms[block.repeatId]) {
                            delete this.interviewService.repeatForms[block.repeatId];
                        }
                    });
                    question.blocks = null;
                }
            }

            if (!activeSection.isLocked
                && eachActionResult.actionResults
                && eachActionResult.actionResults.data
                && Object.prototype.hasOwnProperty.call(eachActionResult.actionResults.data, 'disabled')
            ) {
                questionDisable = eachActionResult.actionResults.data.disabled;
            }

            // TODO: use configdata instead of action id's: using ids as of now as the config data's names are being changed regularly.
            if (eachActionResult.actions.id === '00000000-0000-0000-0000-000000000013') {
                // validate sum action
                const errorMessage = eachActionResult.actionResults && eachActionResult.actionResults.isError ? eachActionResult.actionResults.error.message : '';
                this.setErrorsForQuestion(question, Boolean(errorMessage), errorMessage);
            }

            if (eachActionResult.actions.id === '00000000-0000-0000-0000-000000000016') {
                // Validate Sum
                const errorMessage = eachActionResult.actionResults && eachActionResult.actionResults.isError ? eachActionResult.actionResults.error.message : '';
                this.setErrorsForQuestion(question, Boolean(errorMessage), errorMessage);
            }

            if (eachActionResult.actions.id === '00000000-0000-0000-0000-000000000004'
                && eachActionResult.actionResults != null
                && eachActionResult.actionResults.data != null
                // && !this.isRestart // TODO::REENABLE
            ) { // Case Status
                this.interviewService.getInterviewData().caseDetails.statusId = eachActionResult.actionResults.data;
                // this.getCaseStatusLabel(); // TODO: Do we need this method pull over from `interview.component.ts`?
            }

            if (eachActionResult.actions.id === '00000000-0000-0000-0000-000000000005' || eachActionResult.actions.id === '00000000-0000-0000-0000-000000000024') {
                // ssn validations
                if (eachActionResult.actionResults && eachActionResult.actionResults.data) {
                    if (eachActionResult.actionResults.data.hasDuplicateSSN) {
                        this.setErrorsForQuestion(question, true, eachActionResult.actionResults.data.message);
                        question.errorData = eachActionResult.actionResults.data;
                    }
                    const mapkeyId = eachActionResult.actionResults.data.mapkeyId;
                    this.interviewService.mergeMapkeys(eachActionResult.actionResults.mapkeyValues);
                    this.interviewService.callDependentQuestionActionsByMapKey(activeSection, mapkeyId);
                } else {
                    question.errorData = null;
                    this.setErrorsForQuestion(question, false, '');
                }
            }

            if (eachActionResult.actions.id === '00000000-0000-0000-0000-000000000006') {
                // policy num update action
                if (eachActionResult.actionResults && eachActionResult.actionResults.data && !eachActionResult.actionResults.isError) {
                    this.interviewService.getInterviewData().caseDetails.policyNumber = eachActionResult.actionResults.data;
                }
            }

            if (eachActionResult.actions.id === '00000000-0000-0000-0000-000000000007' && eachActionResult.actionResults) {
                // giact action
                const giact = this.interviewService.getGiactData();
                giact.questionId = eachActionResult.questionsId;
                this.currentRightTabIndex = 1;
                giact.sourceAction = eachActionResult.id;
                giact.questionId = eachActionResult.questionsId;
                if (eachActionResult.actionResults.isError) {
                    this.appService.showMsg('error', eachActionResult.actionResults.message, false);
                }
                // TODO::REENABLE
                // if (giact.status === QueueStatusEnum.Pending) { // TODO: we probably need to enable this; removed to continue PoC development
                //     if (this.checkIfNeededToWait(eachActionResult, giact)) {
                //         this.waitForResponse = true;
                //         giact.waitForResponse = true;
                //         this.waitIntegration.push('giact');
                //     }
                // }
            }

            if (eachActionResult.actions.id === '00000000-0000-0000-0000-000000000012' && eachActionResult.actionResults) {
                // RNA Agent Intergace action
                const agent = this.interviewService.getAgentData();
                this.currentRightTabIndex = 1;
                agent.sourceAction = eachActionResult.id;
                agent.questionId = eachActionResult.questionsId;
                if (eachActionResult.actionResults.isError) {
                    this.appService.showMsg('error', eachActionResult.actionResults.message, false);
                }
                // TODO::REENABLE
                // if (agent.status === QueueStatusEnum.Pending) { // TODO: we probably need to enable this; removed to continue PoC development
                //     if (this.checkIfNeededToWait(eachActionResult, agent)) {
                //         this.waitForResponse = true;
                //         agent.waitForResponse = true;
                //         this.waitIntegration.push('agent');
                //     }
                // }
            }

            if (eachActionResult.actions.id === '00000000-0000-0000-0000-000000000011' && eachActionResult.actionResults) {
                // RNA quote interface action
                const quote = this.interviewService.getQuoteData();
                this.currentRightTabIndex = 1;
                quote.sourceAction = eachActionResult.id;
                quote.questionId = eachActionResult.questionsId;
                if (eachActionResult.actionResults.isError) {
                    this.appService.showMsg('error', eachActionResult.actionResults.message, false);
                }
                // TODO::REENABLE
                // if (quote.status === QueueStatusEnum.Pending) { // TODO: we probably need to enable this; removed to continue PoC development
                //     if (this.checkIfNeededToWait(eachActionResult, quote)) {
                //         this.waitForResponse = true;
                //         quote.waitForResponse = true;
                //         this.waitIntegration.push('quote');
                //     }
                // }
            }

            // RX
            if (eachActionResult.actions.id === '00000000-0000-0000-0000-000000000010' && eachActionResult.actionResults) {
                const rx = this.interviewService.getRxData();
                this.currentRightTabIndex = 1;
                rx.sourceAction = eachActionResult.id;
                rx.questionId = eachActionResult.questionsId;
                if (eachActionResult.actionResults.isError) {
                    this.appService.showMsg('error', eachActionResult.actionResults.message, false);
                }
                // TODO::REENABLE
                // if (rx.status === QueueStatusEnum.Pending) { // TODO: we probably need to enable this; removed to continue PoC development
                //     if (this.checkIfNeededToWait(eachActionResult, rx)) {
                //         this.waitForResponse = true;
                //         rx.waitForResponse = true;
                //         this.waitIntegration.push('rx');
                //     }
                // }
            }

            // MIB
            if (eachActionResult.actions.id === '00000000-0000-0000-0000-000000000009' && eachActionResult.actionResults) {
                const mib = this.interviewService.getMibData();
                this.currentRightTabIndex = 1;
                mib.sourceAction = eachActionResult.id;
                mib.questionId = eachActionResult.questionsId;
                if (eachActionResult.actionResults.isError) {
                    this.appService.showMsg('error', eachActionResult.actionResults.message, false);
                }
                // if (mib.status === QueueStatusEnum.Pending) { // TODO: we probably need to enable this; removed to continue PoC development
                //     if (this.checkIfNeededToWait(eachActionResult, mib)) {
                //         this.waitForResponse = true;
                //         mib.waitForResponse = true;
                //         this.waitIntegration.push('mib');
                //     }
                // }
            }

            if (eachActionResult.actions.integration_Id
                && eachActionResult.actions.integration_Id != null
                && eachActionResult.actions.integrationVersion == 2
                && eachActionResult.actionResults) {

                const integrationV2 = this.interviewService.getIntegrationV2Data(eachActionResult.id);
                // this.currentRightTabIndex = 1; // TODO: Is this the "intergration" right column tab?  We don't have this on this view.
                integrationV2.sourceAction = eachActionResult.id;
                integrationV2.integrationId = eachActionResult.actions.integration_Id;
                integrationV2.name = eachActionResult.actions.name;
                integrationV2.questionId = eachActionResult.questionsId;
                integrationV2.status = (eachActionResult.actionResults.data && eachActionResult.actionResults.data.integrationStatus) ? eachActionResult.actionResults.data.integrationStatus : integrationV2.status;

                if (eachActionResult.actionResults.isError) {
                    this.appService.showMsg('error', eachActionResult.actionResults.message, false);
                }

                if (integrationV2.status === QueueStatusEnum.Pending) {
                    if (this.checkIfNeededToWait(eachActionResult, integrationV2)) {
                        this.waitForResponse = true;
                        integrationV2.waitForResponse = true;
                        this.waitIntegration.push('integrationV2');
                    }
                }
            }

            if (eachActionResult.actions.id === '00000000-0000-0000-0000-000000000022' && eachActionResult.actionResults) {
                const docAction = this.interviewService.getIntegrationV2Data(eachActionResult.questionsId);
                // this.currentRightTabIndex = 1; // TODO: Is this the "intergration" right column tab?  We don't have this on this view.
                docAction.sourceAction = eachActionResult.id;
                docAction.name = eachActionResult.actions.name;
                docAction.questionId = eachActionResult.questionsId;
                docAction.status = (eachActionResult.actionResults.data && eachActionResult.actionResults.data.integrationStatus) ? eachActionResult.actionResults.data.integrationStatus : docAction.status;
                if (eachActionResult.actionResults.isError) {
                    this.appService.showMsg('error', eachActionResult.actionResults.message, false);
                }
                // if (docAction.status === QueueStatusEnum.Pending) { // TODO: we probably need to enable this; removed to continue PoC development
                //     if (this.checkIfNeededToWait(eachActionResult, docAction)) {
                //         this.waitForResponse = true;
                //         docAction.waitForResponse = true;
                //         this.waitIntegration.push('integrationV2');
                //     }
                // }
            }

            if ((eachActionResult.actions.id === '00000000-0000-0000-0000-000000000044' || eachActionResult.actions.id === '00000000-0000-0000-0000-000000000045') && eachActionResult.actionResults) {

                this.interviewService.updateQuestionPreActionsForSection(this.interviewService.getInterviewData().activeSectionId);
            }

            if (question.repeatFormId) {
                // adjusting display for repeat block questions.
                if (question.parentQuestionId === question.repeatFormId.split('block')[0]) {
                    question.display = questionDisplay;
                    question.isLocked = questionDisable;
                }
                const repeatQuestion = activeSection.questions.find(q => q.id === question.parentQuestionId);
                if (repeatQuestion.blocks) {
                    for (const eachRepeatBlock of repeatQuestion.blocks) {
                        const eachRepeatQuestion = eachRepeatBlock.questions.find(q => q.id === question.id);
                        if (eachRepeatQuestion) {
                            eachRepeatQuestion.display = questionDisplay;
                            eachRepeatQuestion.isLocked = questionDisable;
                        }
                    }
                }
            } else {
                // adjusting display for dyanamic form and reflexive form questions.
                if (question.parentQuestionId === '00000000-0000-0000-0000-000000000000') {
                    question.display = questionDisplay;
                    question.isLocked = questionDisable;
                } else {
                    const refQuestion = activeSection.questions.find(q => q.id === question.parentQuestionId);
                    if (refQuestion) {
                        if (refQuestion.parentQuestionId === '00000000-0000-0000-0000-000000000000') {
                            if (this.interviewService.getDynamicForm()) {
                                if (this.interviewService.getDynamicForm().get(refQuestion.id)) {
                                    if (refQuestion.listValues && refQuestion.listValues.length) {
                                        const refAnswerValueCombo = refQuestion.listValues.find(p => p.value === this.interviewService.getDynamicForm().get(refQuestion.id).value);
                                        if (refAnswerValueCombo) {
                                            const thisQuestionRefAnswer = refAnswerValueCombo.reflexiveQuestions.find(p => p.refQuestionId === question.id);
                                            question.isLocked = questionDisable;
                                            if (thisQuestionRefAnswer) {
                                                question.display = questionDisplay;
                                            } else {
                                                question.display = false;
                                            }
                                        }
                                    }
                                }
                            }
                        } else {
                            const reflexiveForm = this.interviewService.reflexiveForms[refQuestion.parentQuestionId];
                            if (reflexiveForm && reflexiveForm.get(refQuestion.id)) {
                                if (refQuestion.listValues && refQuestion.listValues.length) {
                                    const refAnswerValueCombo = refQuestion.listValues.find(p => p.value === reflexiveForm.get(refQuestion.id).value);
                                    if (refAnswerValueCombo) {
                                        const thisQuestionRefAnswer = refAnswerValueCombo.reflexiveQuestions.find(p => p.refQuestionId === question.id);
                                        question.isLocked = questionDisable;
                                        if (thisQuestionRefAnswer) {
                                            question.display = questionDisplay;
                                        } else {
                                            question.display = false;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    /**
     * TODO: Now that we're trying to move this interview actions into a sharable service (so we can use it with interview/interview-section-dialog)
     * I want to understand what's ultimatly changing from these actions.  I believe it should be `formattedSection` and `constantLists`.
     * 
     * A good approach is to make these two data items (1formattedSection` and `constantList`) Observables.
     * 
     *  callers to this function and just subscribe to any data changes.
     * 
     * If callers want to use the `interview-manager` then they can just subscribe to the `formattedSection` and `constantLists` Observables for updates.
     * 
     * TODO: Investigate the idea of these observables:
     * - formattedSection
     * - constantLists
     * - selectedQuestion
     * - interviewMode
     * 
     */
    formatSectionData(interview: InterviewDTO) {
        this.constantMapKeysLoaded = false;

        this.checkInitialized();

        this.setInterviewMode(interview.caseDetails);
        this.subscribeObervableService();
        this.subscribeToConstantMapkeys(interview);
        this.interviewService.initializeRuleData();

        if (this.formattedSection?.isThirdParty) {
            this.formattedSection.embeddedIntegrationData.readonly = (this.interviewMode === InterviewMode.Readonly);
        }
    }

    async saveInterview() {
        this.checkInitialized();

        let allFormsValid = true;
        this.disableSave = true;
        let invalidControls: AbstractControl[] = [];

        try {
            if (this.auraEnabled && !this.auraService.isAuraSectionComplete()) {
                this.auraService.buttonClicked();
                return true;
            }

            if (this.formattedSection.isThirdParty && !this.calledFromThirdParty) {
                this.thirdPartySectionService.moveToNext();
                return true;
            } else if (this.calledFromThirdParty) {
                this.calledFromThirdParty = false;
                await this.interviewService.saveInterview(this.formattedSection.id);
                this.scrollToTopOfContainer();
                return true;
            }

            this.logCaseNumberAndDescriptionToMonitorService('Save Interview');

            // check if all forms are valid, i.e, regular dynamic form, all reflexive forms present in section and all repeat forms.
            //#region Validate Dynamic Form

            const dynamicForm = this.interviewService.getDynamicForm();
            invalidControls = this.interviewValidationService.findInvalidControls(dynamicForm, invalidControls);
            allFormsValid = dynamicForm.disabled || !dynamicForm.invalid;

            //#endregion
            //#region Validate Reflexive Forms

            const reflexiveForms = this.interviewService.reflexiveForms;
            for (const eachReflexiveFormIndex in reflexiveForms) {
                invalidControls = this.interviewValidationService.findInvalidControls(reflexiveForms[eachReflexiveFormIndex], invalidControls);
                allFormsValid &&= !reflexiveForms[eachReflexiveFormIndex].invalid;
            }

            //#endregion
            //#region Validate Repeat Forms

            const repeatForms = this.interviewService.repeatForms;
            for (const eachRepeatFormIndex in repeatForms) {
                invalidControls = this.interviewValidationService.findInvalidControls(repeatForms[eachRepeatFormIndex], invalidControls);
                if (allFormsValid) {
                    allFormsValid &&= !repeatForms[eachRepeatFormIndex].invalid;
                }

            }

            //#endregion
            //#region Validate Health Forms

            const healthForms = this.interviewService.healthForms;
            for (const eachHealthFormIndex in healthForms) {
                if (allFormsValid) {
                    const eachHealthForm = healthForms[eachHealthFormIndex];
                    invalidControls = this.interviewValidationService.findInvalidControls(eachHealthForm, invalidControls);
                    const interviewSections = this.interviewService.getInterviewData().sections;
                    const activeSection = interviewSections.find(s => s.id === this.interviewService.getInterviewData().activeSectionId);
                    activeSection.questions.forEach(question => {
                        for (const eachHealthFormId in eachHealthForm.value) {
                            if (question.id === eachHealthFormId && question.display === true && question.answerValue === '') {
                                allFormsValid = false;
                            }
                        }
                    });
                }
            }

            //#endregion
            //#region Process Invalid Controls to find the first invalid control to scroll to it

            if (invalidControls && invalidControls.length > 0) {
                invalidControls.sort(function (a, b) {
                    let formindexa = a['formIndex'];
                    const formindexb = b['formIndex'];
                    if (!formindexa) {
                        formindexa = (invalidControls.length + 1).toString();
                    }
                    if (!formindexb) {
                        formindexa = (invalidControls.length + 2).toString();
                    }
                    const a1 = formindexa.split('.');
                    const b1 = formindexb.split('.');
                    const len = Math.max(a1.length, b1.length);

                    for (let i = 0; i < len; i++) {
                        const _a = +a1[i] || 0;
                        const _b = +b1[i] || 0;
                        if (_a !== _b)
                            return _a > _b ? 1 : -1;
                    }
                    return 0;
                });

                const invalidControl = invalidControls[0];

                this.scrollToError(invalidControl['questionId']);
            }

            //#endregion

            return await this.finalizeSave(allFormsValid, dynamicForm, reflexiveForms, repeatForms, healthForms);

        } finally {
            this.disableSave = false;
        }
    }

}
