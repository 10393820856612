<div class="page-header">Payment Setup</div>
<div mat-dialog-content>
  <div *ngIf="isSuccessfulVault">
    <div id="paymentSuccessfulMessage">Payment method has been successfully verified</div>
  </div>
  <div id="verifyingPayment" class="standard-centered-text" *ngIf="verifyingPayment">
    Verifying payment information
  </div>
  <div *ngIf="!isSuccessfulVault && errors !== null" id="paymentFailedMessage">
    <div *ngFor="let error of errors" class="error-centered-text">
      {{error}}
    </div>
  </div>
  <div id="dropin-container" *ngIf="!isSuccessfulVault"></div>
</div>
<mat-dialog-actions align="end">
  <button mat-button id="cancelButton" color="basic" class="mr-1 material-default" mat-dialog-close *ngIf="!isSuccessfulVault" >
    CANCEL
  </button>
  <button mat-button id="closeButton" color="basic" class="mr-1 material-default" mat-dialog-close *ngIf="isSuccessfulVault" >
    CLOSE
  </button>
  <button #payButton id="payButton" mat-button color="primary" class="mr-1 material-default" [disabled]="isSuccessfulVault" >
    SETUP PAYMENT
  </button>
</mat-dialog-actions>