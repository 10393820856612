import { Directive, Input, OnInit, ViewContainerRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { DynamicActionFormControlComponent } from '../components/form-fields/dynamic-action-form-control/dynamic-action-form-control.component';

@Directive({
    selector: '[dynamicActionFormControl]'
})
export class DynamicActionFormControlDirective implements OnInit {

    component;

    @Input() config;

    @Input() group: UntypedFormGroup;

    constructor(
        private viewContainerRef: ViewContainerRef,
        private container: ViewContainerRef
    ) { }

    ngOnInit() {
        this.component = this.viewContainerRef.createComponent(DynamicActionFormControlComponent);
        this.component.instance.config = this.config;
        this.component.instance.group = this.group;
    }
}
