import { Directive, Input, OnInit, ViewContainerRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { InterviewQuestionDTO_extended } from '@DTOs';
import { FeatureToggle } from "@Enums";
import { FeatureManagerService } from "@Services";
import { FilterableListService } from "@Services/config/filterable-list.service";
import { AuraComponent } from '../components/aura/aura.component';
import { ActionButtonComponent } from '../components/form-fields/action-button/action-button.component';
import { DateAnswerComponent } from '../components/form-fields/date-answer/date-answer.component';
import { DisplayMessageComponent } from '../components/form-fields/display-message/display-message.component';
import { EFTDateComponent } from '../components/form-fields/eft-date/eft-date.component';
import { EmailAnswerComponent } from '../components/form-fields/email-answer/email-answer.component';
import { HealthAnswerComponent } from '../components/form-fields/health-answer/health-answer.component';
import { HeightAnswerComponent } from '../components/form-fields/height-answer/height-answer.component';
import { MultiChoiceMultiAnswerV2Component } from '../components/form-fields/multi-choice-multi-answer-v2/multi-choice-multi-answer-v2.component';
import { MultiChoiceMultiAnswerComponent } from '../components/form-fields/multi-choice-multi-answer/multi-choice-multi-answer.component';
import { MultiChoiceSingleAnswerComponent } from '../components/form-fields/multi-choice-single-answer/multi-choice-single-answer.component';
import { MultiLineAnswerComponent } from '../components/form-fields/multi-line-answer/multi-line-answer.component';
import { NumericAnswerComponent } from '../components/form-fields/numeric-answer/numeric-answer.component';
import { PhoneNumberAnswerComponent } from '../components/form-fields/phone-number-answer/phone-number-answer.component';
import { RepeatBlockComponent } from '../components/form-fields/repeat-block/repeat-block.component';
import { RoutingNumberAnswerComponent } from '../components/form-fields/routing-number-answer/routing-number-answer.component';
import { ShortAnswerComponent } from '../components/form-fields/short-answer/short-answer.component';
import { SSNAnswerComponent } from '../components/form-fields/ssn-answer/ssn-answer.component';
import { UploadFileActionButtonComponent } from '../components/form-fields/upload-file-button/upload-file-button.component';
import { InterviewService } from '../interview.service';

const components = {
    DateAnswer: DateAnswerComponent,
    DisplayMessage: DisplayMessageComponent,
    EmailAnswer: EmailAnswerComponent,
    HeightAnswer: HeightAnswerComponent,
    MultiLineAnswer: MultiLineAnswerComponent,
    NumericAnswer: NumericAnswerComponent,
    PhoneNumberAnswer: PhoneNumberAnswerComponent,
    RoutingNumberAnswer: RoutingNumberAnswerComponent,
    ShortAnswer: ShortAnswerComponent,
    SSNAnswer: SSNAnswerComponent,
    MultiChoiceSingleAnswer: MultiChoiceSingleAnswerComponent,
    MultiChoiceMultiAnswer: MultiChoiceMultiAnswerComponent,
    MultiChoiceMultiAnswerV2: MultiChoiceMultiAnswerV2Component,
    ActionButton: ActionButtonComponent,
    UploadFileActionButton: UploadFileActionButtonComponent,
    ActionAnswer: ActionButtonComponent,
    HealthAnswer: HealthAnswerComponent,
    RepeatBlock: RepeatBlockComponent,
    EFTDate: EFTDateComponent,
    Aura: AuraComponent
};

@Directive({
    selector: '[dynamicField]'
})
export class DynamicFieldDirective implements OnInit {
    _interviewMode;

    component;
    instancecomp;

    @Input() config: InterviewQuestionDTO_extended;
    @Input() group: UntypedFormGroup;
    @Input() interviewMode;
    @Input() formIndex;
    private filterableStaticListEnabled = false;

    @Input()
    set answeredQuestion(answeredQuestion) {
        if (this.component && this.config && this.config.answerType === 'EFTDate') {
            this.component.instance.answeredQuestion = this.interviewService.answeredQuestion;
        }
    }

    @Input()
    set answeredQuestionForEft(answeredQuestionForEft) {
        if (this.component && this.config && this.config.answerType === 'EFTDate') {
            this.component.instance.answeredQuestionForEft = this.interviewService.answeredQuestionForEft;
        }
    }

    _constantLists: any;
    @Input()
    set constantLists(value) {
        this._constantLists = value;
    }
    get constantLists() {
        return this._constantLists;
    }

    _expanded;
    @Input()
    set expanded(value) {
        this._expanded = value;
        if (this.component) {
            this.component.instance.expanded = value;
        }
    }
    get expanded() {
        return this._expanded;
    }

    _isSelected;
    @Input()
    set isSelected(value) {
        this._isSelected = value;
        if (this.component) {
            this.component.instance.isSelected = value;
        }
    }
    get isSelected() {
        return this._isSelected;
    }

    constructor(
        private viewContainerRef: ViewContainerRef,
        private container: ViewContainerRef,
        public interviewService: InterviewService,
        public filterableListService: FilterableListService,
        private featureManagerService: FeatureManagerService
    ) {
        this.filterableListService.filterableListUpdated$.subscribe((constantLists) => {
            if (this.component) {
                this.component.instance.constantLists = JSON.parse(JSON.stringify(constantLists));
            }
        });
        this.filterableStaticListEnabled = this.featureManagerService.getByName(FeatureToggle.FilterableStaticMapkeys).enabled;
    }

    ngOnInit() {
        const component = components[this.config.answerType];
        this.component = this.viewContainerRef.createComponent(component);
        this.component.instance.config = this.config;
        this.component.instance.group = this.group;
        this.component.instance.expanded = this.expanded;
        this.component.instance.isSelected = this.isSelected;
        this.component.instance.interviewMode = this.interviewMode;
        this.component.instance.formIndex = this.formIndex;
        if (this.constantLists) {
            this.component.instance.constantLists = JSON.parse(JSON.stringify(this.constantLists));
        }
        if (this.config.answerType === 'EFTDate') {
            this.component.instance.answeredQuestion = this.interviewService.answeredQuestion;
            this.component.instance.answeredQuestionForEft = this.interviewService.answeredQuestionForEft;
        }
    }
}
