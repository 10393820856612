import { InterviewNavigationQuestionComponent } from "@AccountConfig/account-interview/interview-navigation/interview-navigation-question/interview-navigation-question.component";
import { FeatureToggle } from "@Enums";
import { AccountDataService, FeatureManagerService, SectionService } from '@Services';
import { animate, style, transition, trigger } from '@angular/animations';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit, QueryList, ViewChildren, forwardRef } from '@angular/core';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions } from "@angular/material/tooltip";
import { AppService } from "app/app.service";
import { saveAs } from 'file-saver';
import { lastValueFrom } from 'rxjs';
import { AccountInterviewService } from "../account-interview.service";

const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
    showDelay: 0,
    hideDelay: 0,
    touchendHideDelay: 1500,
    disableTooltipInteractivity: true
};
@Component({
    selector: 'interview-navigation',
    // host: { 'class': 'content' },
    templateUrl: './interview-navigation.component.html',
    styleUrls: ['./interview-navigation.component.scss'],
    animations: [
        trigger('showHide', [
            transition(":enter", [
                style({ opacity: 0 }), //apply default styles before animation starts
                animate(
                    "20ms ease-in",
                    style({ opacity: 1 })
                )
            ]),
            transition(":leave", [
                style({ opacity: 1 }), //apply default styles before animation starts
                animate(
                    "20ms ease-in",
                    style({ opacity: 0 })
                )
            ])
        ])
    ],
    providers: [
        { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults }
    ],
})
export class InterviewNavigationComponent implements OnInit {

    _filterValue: string;
    _sections: string;

    @Input() set sections(val: any) {
        this._sections = val;
        this.filteredSections = this._sections;
        if (!this.clickedFirstSection && this._sections && this._sections.length) {
            this.selectSection(this._sections[0]);
            this.clickedFirstSection = true;
        }
    }

    get sections() {
        return this._sections;
    }

    @Input() selectedSection;
    @Input() selectedQuestion;
    @Input() selectedReflexiveChoice;

    @Input() set filterValue(val: string) {
        this._filterValue = val;

        this.search();
    }

    get filterValue(): string {
        return this._filterValue;
    }


    @ViewChildren(forwardRef(() => InterviewNavigationQuestionComponent)) interviewNavigationQuestionChildren: QueryList<InterviewNavigationQuestionComponent>;
    selectDroppedSectionToggle = false;
    filteredSections: any;
    isDragging = false;
    clickedFirstSection = false;
    filterReflexiveQuestionsToQuesionIds = [];

    constructor(
        private appService: AppService,
        private interviewService: AccountInterviewService,
        private accountDataService: AccountDataService,
        private sectionService: SectionService,
        private featureManagerService: FeatureManagerService) {

    }

    ngOnInit() {
        this.selectDroppedSectionToggle = this.featureManagerService.getByName(FeatureToggle.GlobalConfigSetDroppedSectionAsActive).enabled;
    }

    search() {
        if (this.filterValue && this.filterValue.length) {
            const filterValue = this.filterValue.toLowerCase();

            const filteredQuestionsIds = [];
            this.sections.forEach(section => {
                const relatedQuestionidFoundReflexiveIds = this.getAllParentQuestionIdsRelatedToAFilter(filterValue, section.sectionReflexives);
                filteredQuestionsIds.push(...relatedQuestionidFoundReflexiveIds);
                const repeatBlockQuestions = section.questions.filter(subElement =>
                    subElement.mapKeyName.toLowerCase().includes(filterValue) ||
                    subElement.questionTitle.toLowerCase().includes(filterValue) ||
                    filteredQuestionsIds.includes(subElement.id) ||
                    subElement.repeatBlockQuestions.some(repeatQuestion => filteredQuestionsIds.includes(repeatQuestion.repeatedQuestionId)))
                    .map(question => question.id);
                filteredQuestionsIds.push(...repeatBlockQuestions);
            });
            this.filterReflexiveQuestionsToQuesionIds = filteredQuestionsIds;

            this.filteredSections = this.sections
                .filter(element =>
                    element.name.toLowerCase().includes(filterValue) || element.questions
                        .some(subElement =>
                            subElement.mapKeyName.toLowerCase().includes(filterValue) ||
                            subElement.questionTitle.toLowerCase().includes(filterValue) ||
                            filteredQuestionsIds.includes(subElement.id) ||
                            subElement.repeatBlockQuestions.some(repeatQuestion => filteredQuestionsIds.includes(repeatQuestion.repeatedQuestionId))
                        )
                )
                .map(element => {
                    const n = Object.assign({}, element, {
                        'questions': element.questions.filter(
                            subElement =>
                                element.name.toLowerCase().includes(filterValue) ||
                                subElement.mapKeyName.toLowerCase().includes(filterValue) ||
                                subElement.questionTitle.toLowerCase().includes(filterValue) ||
                                filteredQuestionsIds.includes(subElement.id) ||
                                subElement.repeatBlockQuestions.some(repeatQuestion => filteredQuestionsIds.includes(repeatQuestion.repeatedQuestionId))
                        )
                    });

                    n.expanded = true;
                    return n;
                });
        } else {
            this.filteredSections = this.sections;
            this.filterReflexiveQuestionsToQuesionIds = [];
        }
    }

    selectSection(section) {
        this.interviewService.selectSection(section);
    }

    checkACL(permissionType, redirect = undefined) {
        return this.appService.checkACL("Accounts", permissionType, redirect);
    }

    deleteSection(sectionId) {
        this.interviewService.deleteSection(sectionId);
    }
    getSectionId(section) {
        return section.id + "_";
    }

    scrollToQuestionOrSection() {
        setTimeout(() => {
            if (this.selectedSection) {
                let elementId = this.selectedSection.id + "_";
                if (this.selectedQuestion) {
                    elementId += this.selectedQuestion.id;
                }
                const item = document.getElementById(elementId);
                item.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }, 0);
    }


    dropSection(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.sections, event.previousIndex, event.currentIndex);
        let hasOrderChanged = false;
        this.sections.forEach((section, index) => {
            if (section.order !== (index + 1)) {
                hasOrderChanged = true;
            }

            section.order = (index + 1);
        });

        if (hasOrderChanged) {
            this.interviewService.changeSectionOrder({ from: 0, to: 0 });
            if (this.selectDroppedSectionToggle)
                this.selectSection(this.sections[event.currentIndex]);

            setTimeout(() => event.item.element.nativeElement.scrollIntoView({ behavior: 'smooth' }), 50);
        }
    }

    async exportSection(section) {
        const account = await lastValueFrom(this.accountDataService.getAccount(section.accountId));
        this.sectionService.exportSection(section.id, account.clientId, account.id, account.versionNumber).subscribe(result => {
            const blob = new Blob([JSON.stringify(result)], { type: 'application/json' });
            saveAs(blob, `${account.code}-${account.versionNumber}-${section.name}.json`);

            this.appService.showMsg('success', 'Section exported successfully...');
        });
    }

    collapseAllChildren() {
        this.interviewNavigationQuestionChildren.forEach((child) => {
            child.collapseAllChildren();
        });
        this.sections.forEach((section) => {
            section.expanded = false;
        });
        this.filteredSections.forEach((section) => {
            section.expanded = false;
        });
    }

    getAllParentQuestionIdsRelatedToAFilter(filterValue, sectionReflexives): string[] {
        if (!sectionReflexives) return [];

        const reflexiveQuestionIds = sectionReflexives.filter(x =>
            x.questionTitle.toLowerCase().includes(filterValue)
            || ((!x.questionTitle || x.questionTitle === '') && x.mapKeyName.toLowerCase().includes(filterValue)));
        if (!reflexiveQuestionIds || reflexiveQuestionIds.length === 0) return [];

        const parentQuestionIds: string[] = [];

        reflexiveQuestionIds.forEach(element => {
            const response = this.findTopLevelReflexiveQuestionId(element.id, sectionReflexives);

            parentQuestionIds.push(...response);
        });
        return parentQuestionIds;
    }

    findTopLevelReflexiveQuestionId(questionId, sectionReflexives) {

        const parentQuestionIds: string[] = [];
        const reflexiveQuestion = sectionReflexives.find(x => x.id === questionId && x.parentQuestionId && x.parentQuestionId !== "00000000-0000-0000-0000-000000000000");
        parentQuestionIds.push(questionId);
        if (!reflexiveQuestion) return parentQuestionIds;
        const parentQuestion = sectionReflexives.find(x => x.id === reflexiveQuestion.parentQuestionId);

        const response = this.findTopLevelReflexiveQuestionId(parentQuestion.id, sectionReflexives);
        parentQuestionIds.push(...response);
        return parentQuestionIds;
    }
}
