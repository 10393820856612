<div mat-dialog-content class="flex">
    <ngx-extended-pdf-viewer [src]="pdfResult" [useBrowserLocale]="true" [height]="'100%'" [zoom]="getZoom()"
        [customToolbar]="multiToolbar">>
    </ngx-extended-pdf-viewer>
    <ng-template #multiToolbar>
        <div id="toolbarViewerLeft">
            <div class="toolbarButtonSpacer"></div>
            <pdf-paging-area></pdf-paging-area>
        </div>
        <pdf-zoom-toolbar>
            <pdf-zoom-out id="zoomOut" />
            <pdf-zoom-dropdown id="scaleSelect" value="getZoom()" />
            <pdf-zoom-in id="zoomIn" />
        </pdf-zoom-toolbar>
        <div id="toolbarViewerRight">
            <!-- <pdf-print *ngIf="hasViewPermission('Download Documents and Attachments')"></pdf-print> -->
            <!-- <pdf-download *ngIf="hasViewPermission('Download Documents and Attachments')"></pdf-download> -->
            <div class="verticalToolbarSeparator hiddenSmallView"></div>
        </div>
    </ng-template>
</div>
<mat-dialog-actions align="end" style="height: 50px">
    <mrs-btn-tertiary buttonLabel="Close" buttonId="closeButton" mat-dialog-close>Close</mrs-btn-tertiary>
</mat-dialog-actions>