<div class="py-2 px-4 bg-light border-bottom documents-header">
    <span class="float-left property-box-title">List of Documents Generated</span>
    <span class="tooltip-wrapper disabled float-right"
        [attr.title]="!transmitEnabled ? 'Transmissions are disabled for this account' : null">
        <button type="button"
            *ngIf="hasViewPermission('Retransmit') && case && case.statusId == '00000000-0000-0000-0000-000000000003'"
            class="btn btn-link btn-sm float-right custom" (click)="caseRetransmit()" [disabled]="!transmitEnabled">
            <i aria-hidden="true" class="fas fa-cloud-upload-alt"></i> Case Retransmit
        </button>
    </span>
    <button *ngIf="canViewDetails && hasMillimanReport && !isCaseDone()" type="button"
        class="btn btn-link btn-sm float-right custom" (click)="requeueMillimanReport(caseIntegrationQueueId)">
        <i aria-hidden="true" class="fas fa-redo"></i> Retrieve Milliman Report
    </button>
    <button
        *ngIf="canViewDetails && showRequestSignatures && !isRequestingSignatures && (signatureProviderSummary && signatureProviderSummary.signatureType === signatureTypeEnum.Email)"
        type="button" class="btn btn-link btn-sm float-right custom" [disabled]='isRegenerating'
        (click)="requestSignatures()">
        <i aria-hidden="true" class="fas fa-file-signature"></i> Request Signatures
    </button>
    <button *ngIf="canViewDetails && !isCaseDone()" type="button" class="btn btn-link btn-sm float-right custom"
        [disabled]='isRegenerating' (click)="regenerateDocuments()">
        <i aria-hidden="true" class="far fa-file-archive"></i> Regenerate Documents
    </button>
    <button type="button" class="btn btn-link btn-sm float-right custom" (click)="refreshDocuments()">
        <i aria-hidden="true" class="fa fa-sync-alt"></i> Refresh
    </button>
</div>
<div class="documents-body p-4">
    <p-table [value]="caseDocuments" [lazy]="true" [(selection)]="selectedDocs"
        *ngIf="caseDocuments.length; else noDocumentsPlaceholder">
        <ng-template pTemplate="header">
            <tr>
                <th class="ptable-td" style="width: 20px">
                    <p-tableHeaderCheckbox class="prime-checkbox-override"></p-tableHeaderCheckbox>
                </th>
                <th style="width: 300px">Document Name</th>
                <th style="width: 100px">Type</th>
                <th>File Name</th>
                <th style="width: 100px">Status</th>
                <th style="width: 150px">File Date</th>
                <th *ngIf="hasViewPermission('Download Documents and Attachments')" style="width: 30px"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-caseDocument>
            <tr styleClass="ptable-tr" [class.highlighted]="!documentIsTIFF(caseDocument)">
                <td class="ptable-td">
                    <p-tableCheckbox [value]="caseDocument" class="prime-checkbox-override"></p-tableCheckbox>
                </td>
                <td (click)="!documentIsTIFF(caseDocument) && viewDocument(caseDocument)"
                    class="ptable-td ptable-td-override " [class.clickable]="!documentIsTIFF(caseDocument)"
                    [matTooltipDisabled]="!documentIsTIFF(caseDocument)" [matTooltip]="toolTipText"
                    style="width: 300px">{{caseDocument.docTemplateName}}
                </td>
                <td (click)="!documentIsTIFF(caseDocument) && viewDocument(caseDocument)"
                    class="ptable-td ptable-td-override " [class.clickable]="!documentIsTIFF(caseDocument)"
                    [matTooltipDisabled]="!documentIsTIFF(caseDocument)" [matTooltip]="toolTipText"
                    style="width: 100px">{{caseDocument.doctypeName}}
                </td>
                <td (click)="!documentIsTIFF(caseDocument) && viewDocument(caseDocument)"
                    [matTooltipDisabled]="!documentIsTIFF(caseDocument)" [matTooltip]="toolTipText"
                    class="ptable-td ptable-td-override text-truncate "
                    [class.clickable]="!documentIsTIFF(caseDocument)">{{caseDocument.remoteName}}</td>
                <td (click)="!documentIsTIFF(caseDocument) && viewDocument(caseDocument)"
                    [matTooltipDisabled]="!documentIsTIFF(caseDocument)" [matTooltip]="toolTipText"
                    class="ptable-td ptable-td-override " [class.clickable]="!documentIsTIFF(caseDocument)"
                    style="width: 100px">{{caseDocument.statusName}}</td>
                <td (click)="!documentIsTIFF(caseDocument) && viewDocument(caseDocument)"
                    [matTooltipDisabled]="!documentIsTIFF(caseDocument)" [matTooltip]="toolTipText"
                    class="ptable-td ptable-td-override " [class.clickable]="!documentIsTIFF(caseDocument)"
                    style="width: 150px">{{caseDocument.creationDate |
                    date:
                    'MM/dd/yyyy
                    HH:mm:ss': timeZone}}</td>
                <td *ngIf="hasViewPermission('Download Documents and Attachments')" class="ptable-td ptable-td-override"
                    style="width: 30px">
                    <i aria-hidden="true" class="btn-link fa fa-download clickable"
                        (click)="downloadDocument(caseDocument)"></i>
                </td>
            </tr>
        </ng-template>
    </p-table>

    <ng-template #noDocumentsPlaceholder>
        <div class="p-4 text-center text-muted">
            <img src="./assets/images/no-data-placeholder.png" alt="" class="pb-3" />
            <h6>No Documents Found</h6>
        </div>
    </ng-template>
</div>
