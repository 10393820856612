<div class="left-panel w-25 bg-white d-flex flex-column">
  <div class="left-panel-header">
    <div>
      <span class="left-panel-title">Rules</span>
      <button (click)="addRule()" *ngIf="checkACL('W')" class="btn btn-link btn-sm custom float-right" type="button">
        <i aria-hidden="true" class="fa fa-plus-circle"></i> Add Rule
      </button>
    </div>
    <div>
      <mrs-search-field placeholder="Search Rules" (searchTextChange)="filterValue = $event; searchRules()"
        [searchText]="filterValue"></mrs-search-field>
    </div>
  </div>
  <div class="left-panel-body flex-fill overflow-y-auto custom-scrollbar">

    <div class="list-container">
      <div (click)="getRule(rule)" *ngFor="let rule of filteredRules" [ngClass]="{'active': rule.id == ruleConfig?.id}"
        class="list-item" matRipple>
        <div class="list-item-title">{{rule.name}}</div>
        <div class="list-item-actions">
          <div (click)="deleteRule(rule.id)" *ngIf="checkACL('W')">
            <mat-icon>delete</mat-icon>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="rules?.length == 0" class="p-4 text-center text-muted">
      <img alt="" class="pb-3" src="./assets/images/no-data-placeholder.png" />
      <h6>No Rules Found</h6>
    </div>

  </div>
</div>

<div class="right-panel flex-fill d-flex flex-column" style="background-color: #EFEFEF">
  <div class="right-panel-body flex-fill overflow-y-auto custom-scrollbar">
    <div class="row">
      <div class="col-12">
        <div *ngIf="!ruleConfig" class="standard-centered-text" style="padding-top: 96px">
          <h2 style="padding-bottom: 18px">No Rules Selected</h2>
          <div class="body-platform" style="padding-bottom: 28px">To get started, select a Rule form the list or</div>
          <mrs-btn-outline (onClick)="addRule()" buttonId="addRule" buttonLabel="ADD A NEW RULE" iconName="add"
            textColor="#2C6EED"></mrs-btn-outline>
        </div>
        <div [ngStyle]="{'display':ruleConfig?'':'none'}" style="padding-left: 45px; padding-top: 48px">
          <h1>{{ruleConfig?.name}}</h1>
          <span class="d-inline-flex">
            <mat-icon (click)="showDetails = !showDetails">{{showDetails ? 'expand_less' : 'expand_more'}}</mat-icon>
            <h2 style="padding-left: 4px">Details</h2>
          </span>
          <div [ngStyle]="{'display':showDetails?'':'none'}" class="mat-card-content">
            <rule (clearTestResults)="clearTestResults()" (newRuleAdded)="getRules($event)"
              (onTestClick)="getTestMapkeys($event, testVirtualMapkeyDialog)" (ruleCanceled)="ruleConfig = undefined"
              [accountInfo]="accountInfo" [comboData]="configData" [config]="ruleConfig"
              [isTestExecuted]="isTestExecuted" [mapKeys]="mapKeys"
              [nonListNonVirtualMapKeys]="nonListNonVirtualMapKeys" [nonVirtualMapKeys]="nonVirtualMapKeys"
              [rules]="rules" [testResult]="testResult" [variableMapKeys]="variableMapKeys"></rule>
          </div>

          <div *ngIf="showUsedInSection && ruleConfig && usedInFeatureToggleEnabled" class="mapkey-section-pane"
            id="usedIn">
            <div style="line-height: 0">
              <span class="d-inline-flex">
                <mat-icon (click)="showUsedIn = !showUsedIn">{{showUsedIn ? 'expand_less' :
                  'expand_more'}}</mat-icon>
                <h2 style="padding-left: 4px; margin-bottom: 0">Used In</h2>
              </span>
            </div>
            <div style="line-height:0; margin-bottom: 8px;">
              <a href="https://surveys.hotjar.com/5ec68911-c00a-416c-943f-2d82bd0e2eb6" rel="noopener noreferrer"
                style="margin-left: 29px" target="_blank">Let us know if areas are missing</a>
            </div>
            <div [ngStyle]="{'display':showUsedIn?'':'none'}">
              <div class="mapkey-section-pane">
                <used-in [accountId]="accountInfo.id" [clientId]="accountInfo.clientId"
                  [ruleId]="ruleConfig.id"></used-in>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #testVirtualMapkeyDialog let-data style="width: '800px', max-height='90%'">
  <h2 mat-dialog-title>Test Rule</h2>
  <div mat-dialog-content class="row">
    <div *ngFor="let mapkey of data.testMapkeys; let i = index;">
      <div *ngIf="!mapkey.isJson">
        <mat-form-field class="w-100">
          <mat-label>{{mapkey.mapKeyName}}</mat-label>
          <input matInput [(ngModel)]="mapkey.value" autocomplete="off">
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="row" *ngFor="let mapkey of data.testMapkeys; let i = index;">
    <div class="col-12" *ngIf="mapkey.isJson">
      <mat-form-field class="w-100 textarea-formfield">
        <mat-label>{{mapkey.mapKeyName}}</mat-label>
        <textarea rows="25" cols="25" matInput [(ngModel)]="mapkey.value" autocomplete="off"></textarea>
      </mat-form-field>
    </div>
  </div>
  <mat-dialog-actions align="end">
    <mrs-btn-tertiary buttonLabel="Cancel" buttonId="closeButton" (onClick)="closeDialog()">
    </mrs-btn-tertiary>
    <mrs-btn-tertiary buttonLabel="Run Test" buttonId="okButton" (onClick)="runTest()" textColor="#2C6EED">
    </mrs-btn-tertiary>
  </mat-dialog-actions>
</ng-template>