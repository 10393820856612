import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Account } from '@Models';
import { MapkeyOperation } from 'app/account/account-config/account-mapkeys/models/mapkey-operation';

@Component({
    selector: 'map-keys-modal',
    templateUrl: 'map-keys-modal.component.html',
})
export class MapKeysModalComponent {

    account: Account;
    mapkeyOperation: MapkeyOperation;

    constructor(
        public dialogRef: MatDialogRef<MapKeysModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.account = data.account;
        this.mapkeyOperation = data.mapkeyOperation;
    }

    closeModal(data) {
        this.dialogRef.close(data);
    }
}
