import { Component, OnInit, Input } from '@angular/core';
import { AppService } from "../../../app.service";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import * as _ from "lodash";

import { Account } from '../../../models';
import { ConfirmationDialogService } from '../../../../app/services';

@Component({
    selector: 'account-mib',
    host: {'class': 'd-flex flex-row flex-fill'},
    templateUrl: './account-mib.component.html',
    styleUrls: ['./account-mib.component.scss']
})
export class AccountMIBComponent implements OnInit {
    @Input() accountInfo: Account;
    @Input() healthQuestions;
    @Input() rules;
    mibRisks: any[] = [];

    mibForm: UntypedFormGroup;
    showForm = false;
    showContent = false;
    selectedMIB: any = {};

    mibSeverities: any = [];
    mibSources: any = [];
    mibTimeFrames: any = [];
    mibRiskLevels: any = [];

    constructor(
        private appService: AppService,
        private _fb: UntypedFormBuilder,
        private confirmationService: ConfirmationDialogService
    ) {
    }

    ngOnInit() {
        const numberType = /^\d+$/;
        this.mibForm = this._fb.group({
            id: '',
            mibCode: ['', Validators.compose([Validators.required, Validators.pattern("^[0-9]{3}$")])],
            severityId: ['', Validators.required],
            sourceId: ['', Validators.required],
            timeFrameId: ['', Validators.required],
            riskLevelId: ['', Validators.required],
            mibDateRiskBoundary: ['', Validators.compose([Validators.required, Validators.pattern(numberType)])],
            description: ['', Validators.required],
            mapkeyId: [''],
            accountId: [this.accountInfo.id, Validators.required],
        });

        this.mibSeverities = this.appService.getConfigData('mibSeverity');
        this.mibSources = this.appService.getConfigData('mibSource');
        this.mibTimeFrames = this.appService.getConfigData('mibTimeFrame');
        this.mibRiskLevels = this.appService.getConfigData('riskLevel');
        this.getMIBRisks();
    }

    checkACL(permissionType, redirect?) {
        return this.appService.checkACL("Accounts", permissionType, redirect);
    }

    changelabelOrder(from, to) {
        this.mibRisks.splice(to, 0, this.mibRisks.splice(from, 1)[0]);
        this.reOrderlabels();
    }

    getMIBRisks() {
        this.appService.getData(`MibRiskMap/${this.accountInfo.id}`).subscribe(
            data => {
                if (data.status == "success") {
                    this.mibRisks = data.data;
                }
            }
        );
    }

    reOrderlabels() {
        //     let labelsWithOrder = [];
        //     this.mibRisks.forEach((label, index) => {
        //         labelsWithOrder.push({
        //             id: label.id,
        //             name: label.name,
        //             ruleId: label.ruleId,
        //             workFlowId: this.accountInfo.selectedAccount.workFlowId,
        //             order: index + 1
        //         });
        //     });
        //     console.log(labelsWithOrder);
        //     this.appService.postData("UwdecisionLabel/Reorder", labelsWithOrder, true, false).subscribe(
        //         data => {
        //             if (data.status == "success") {
        //                 this.appService.showMsg("success", "Order changed...");
        //             } else {
        //                 this.appService.showMsg("error", data.message);
        //             }
        //         }
        //     );
    }

    addMIBRisk() {
        this.showForm = true;
        this.selectedMIB = {};
        this.mibForm.patchValue({
            id: '',
            mibCode: '',
            severityId: '',
            sourceId: '',
            timeFrameId: '',
            riskLevelId: '',
            mibDateRiskBoundary: '',
            description: '',
            mapkeyId: '',
            accountId: this.accountInfo.id,
        });
    }

    editMIBRisk() {
        this.mibForm.patchValue(this.selectedMIB);
        this.showForm = true;
    }

    getMIBInfo(label) {
        this.showForm = false;
        this.selectedMIB = label;
    }

    cancel() {
        this.showForm = false;
        this.selectedMIB = {};
    }

    saveMIBRisk() {
        if (this.mibForm.valid) {
            let isEdit = false;
            let url = "MibRiskMap";
            if (this.selectedMIB.id) {
                isEdit = true;
                url = `MibRiskMap/${this.selectedMIB.id}`;
            }
            this.mibForm.value.mibDateRiskBoundary = parseInt(this.mibForm.value.mibDateRiskBoundary);
            this.mibForm.value.mapkeyId = this.mibForm.value.mapkeyId === this.appService.emptyGUID ? '' : this.mibForm.value.mapkeyId;
            this.appService.postData(url, this.mibForm.value, isEdit, false)
                .subscribe(
                    data => {
                        if (data.status == "success") {
                            this.showForm = false;
                            this.selectedMIB = Object.assign({}, data.data);
                            this.getMIBRisks();
                            this.appService.showMsg("success", "Saved successfully ...");
                        } else {
                            this.appService.showMsg("error", data.message);
                        }
                    }
                );
        }
    }


    deleteMIBRisk(id) {
        this.confirmationService.confirm({
            message: 'Are you sure that you want to delete this MIB Risk?',
            key: 'mibMapKeyConfirm',
            accept: () => {
                this.appService.deleteData(`MibRiskMap/${id}`).subscribe(
                    data => {
                        if (data.status == "success") {
                            this.showForm = false;
                            this.showContent = false;
                            this.selectedMIB = null;
                            this.getMIBRisks();
                            this.appService.showMsg("success", data.message);
                        }
                    }
                );
            },
            showCancel: true
        });

    }

    getSeverityName(severityId) {
        if (severityId && this.mibSeverities && this.mibSeverities.length) {
            return _.find(this.mibSeverities, ['id', severityId]).name;
        } else {
            return "";
        }
    }

    getSourceName(sourceId) {
        if (sourceId && this.mibSources && this.mibSources.length) {
            return _.find(this.mibSources, ['id', sourceId]).name;
        } else {
            return "";
        }
    }

    getTimeFrameName(timeframeId) {
        if (timeframeId && this.mibTimeFrames && this.mibTimeFrames.length) {
            return _.find(this.mibTimeFrames, ['id', timeframeId]).name;
        } else {
            return "";
        }
    }

    getRiskLevelName(riskLevelId) {
        if (riskLevelId && this.mibRiskLevels && this.mibRiskLevels.length) {
            return _.find(this.mibRiskLevels, ['id', riskLevelId]).name;
        } else {
            return "";
        }
    }

    getHealthQuestionText(healthQuestionId) {
        if (healthQuestionId && this.healthQuestions && this.healthQuestions.length) {
            return _.find(this.healthQuestions, ['mapkeysId', healthQuestionId]).mapKeyName;
        } else {
            return "";
        }
    }


}
