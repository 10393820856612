import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogComponent, ConfirmationDialogData } from '../../components/confirmation-dialog/confirmation-dialog.component';

@Injectable()
export class ConfirmationDialogService {
    constructor(private dialog: MatDialog) {}

    public open(options: ConfirmationDialogData): MatDialogRef<ConfirmationDialogComponent> {
        const dialog: MatDialogRef<ConfirmationDialogComponent> = this.dialog.open(ConfirmationDialogComponent, {
            data: options
        });

        const subscription = dialog.afterClosed().subscribe((result?: boolean) => {
            if (result && options.onOk) {
                options.onOk();
            } else if (options.onCancel) {
                options.onCancel();
            }

            // after close is always called, regardless of outcome.
            if (options.onClose != null) {
                options.onClose();
            }

            if (subscription != null) {
                subscription.unsubscribe();
            }
        });

        return dialog;
    }

    public confirm(options: ConfirmationDialogData): MatDialogRef<ConfirmationDialogComponent> {
        const dialog: MatDialogRef<ConfirmationDialogComponent> = this.dialog.open(ConfirmationDialogComponent, {
            data: options
        });

        const subscription = dialog.afterClosed().subscribe((result?: boolean) => {
            if (result && options.accept) {
                options.accept();
            } else if (options.reject) {
                options.reject();
            }

            // after close is always called, regardless of outcome.
            if (options.onClose != null) {
                options.onClose();
            }

            if (subscription != null) {
                subscription.unsubscribe();
            }
        });

        return dialog;
    }
}
