<div class="question-container" [formGroup]="group" [ngClass]="{active: isSelected, interviewQuestion: interviewMode}">
  <div [ngClass]="isConfigInvalid()?'invalid-question':''">
    <div class="question-header" *ngIf="!interviewMode" (click)="selectQuestion()">
      <button type="button" class="btn btn-link float-left" (click)="expanded = !expanded">
        <i aria-hidden="true" class="fa" [ngClass]="{'fa-angle-right': !expanded, 'fa-angle-down': expanded}"></i>
      </button>
      <span class="question-mapkey">
        {{config.mapKeyName}}
      </span>
      <span class="question-mapkey float-right pr-2">
        {{config.answerTypeName}}
      </span>
    </div>
    <div class="question-body" *ngIf="expanded">
      <div class="question-text rendered-quill-content ql-editor" [innerHTML]="questionText"></div>
      <div class="help-text rendered-quill-content ql-editor" [innerHTML]="helpText"
        *ngIf="config.helpText?.length"></div>
      <div class="question-form">
        <p-calendar (onFocus)="onFocus()" (onBlur)="onBlur()" (onSelect)="onBlur()" [(ngModel)]="dateModel" [showIcon]="true"
          [disabledDates]="invalidDates" [minDate]="minDate" [maxDate]="maxDate" [disabled]="isReadOnly()"
          [ngModelOptions]="{standalone: true}" [attr.data-test-input-type]="inputType"></p-calendar>
        <mat-error *ngIf="group.get(config.id)?.hasError('required')">required</mat-error>
      </div>
    </div>
  </div>
</div>
