import { Component, ElementRef, EventEmitter, Inject, Output, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CaseDocumentTypes, FeatureToggle } from '@Enums';
import { CaseAttachment } from '@Models';
import { CaseDocumentsDTO } from '@Models/domain/dto';
import {
    CaseDocumentsDataService,
    CaseSummaryService,
    FeatureManagerService,
    FileUploadService,
    NotificationService,
    NotificationSeverity,
} from '@Services';
import { CaseRequirementResponse, RequirementResponse } from '@Underwriting/models';
import { UWRequirementsDataService, UWWorkbenchService } from '@Underwriting/services';
import { FileUtils, TimeUtils, Utils } from '@Utils';
import { ViewDocumentDialog, ViewDocumentDialogData } from 'app/components/view-document-dialog';
import { SubSink } from 'subsink';

import { AttachmentFileManagerDialogData, AttachmentFileManagerDialogResponse, CaseRequirementAttachment } from './models';


@Component({
    selector: 'attachment-file-manager-dialog',
    templateUrl: './attachment-file-manager-dialog.component.html',
    styleUrls: ['./attachment-file-manager-dialog.component.scss']
})
export default class AttachmentFileManagerDialog {
    @Output() onViewAttachment = new EventEmitter<CaseRequirementAttachment>();
    @Output() onAttachmentUploaded = new EventEmitter<string>();
    @ViewChild('fileInput', { static: false }) fileInputRef: ElementRef;

    readonly Error_UnexpectedUpload = 'Something unexpected went wrong trying to upload the document.';
    readonly Error_NoDocuments = 'No documents selected.';
    readonly Error_MultipleDocuments = 'Only one document can be uploaded.  Multiple documents where selected.';
    readonly Error_DocumentExtension = 'Only pdf and Tiff files are allowed to be uploaded';
    readonly Error_UploadFailed = 'Upload failed.';
    readonly Error_UploadCanceled = 'Upload Canceled.';
    readonly Msg_AttachmentUploaded = 'Attachment added successfully';

    dialogTitle = 'Documents';
    caseRequirement: CaseRequirementResponse;
    attachments: CaseRequirementAttachment[] = [];
    requirements: RequirementResponse[] = [];
    caseRequirements: CaseRequirementResponse[] = [];

    caseDocumentDocuments: CaseDocumentsDTO[] = [];
    caseDocumentAttachments: CaseDocumentsDTO[] = [];

    // State when there's a file uploading
    uploading = false;
    fileUploadCanceled = false;

    msg_TiffPreviewNotSupported = "Preview does not support TIFF file types.";

    // Feature Toggle
    directDownloadIsEnabled = false;
    openInNewTabIsEnabled = false;

    subs = new SubSink();

    constructor(
        public dialogRef: MatDialogRef<AttachmentFileManagerDialog, AttachmentFileManagerDialogResponse>,
        @Inject(MAT_DIALOG_DATA) public data: AttachmentFileManagerDialogData,
        private caseDocumentsDataService: CaseDocumentsDataService,
        private notificationService: NotificationService,
        private fileUploadService: FileUploadService,
        private uwRequirementsDataService: UWRequirementsDataService,
        private uwWorkbenchService: UWWorkbenchService,
        private caseSummaryService: CaseSummaryService,
        private dialog: MatDialog,
        private featureManagerService: FeatureManagerService,
        private router: Router,
    ) {
    }

    //#region Helpers

    formatAttachments() {
        const attachments = this.caseDocumentAttachments.filter(x => this.caseRequirement?.attachmentCaseDocumentIds?.includes(x.id));
        const documents = this.caseDocumentDocuments.filter(x => this.caseRequirement?.attachmentCaseDocumentIds?.includes(x.id));

        const formattedDocuments = documents.map(caseDoc => {
            const creationDate = TimeUtils.convertToMomentCst(caseDoc.creationDate);
            return {
                caseDocumentId: caseDoc.id,
                name: caseDoc.remoteName,
                uploadedOn: creationDate.toDate(),
                uploadedOnDay: TimeUtils.formatDay(creationDate, 'MMMM Do YYYY'),
                uploadedOnTime: TimeUtils.formatTime(creationDate),
                isTiffFile: FileUtils.isTiff(caseDoc),
                // uploadedBy: attachment.lastModifiedBy // Attachments don't current report who uploaded created/uploaded by
                type: CaseDocumentTypes.Integration
            } as CaseRequirementAttachment;
        });
        const formattedAttachments = attachments.map(caseDoc => {
            const creationDate = TimeUtils.convertToMomentCst(caseDoc.creationDate);
            return {
                caseDocumentId: caseDoc.id,
                name: caseDoc.remoteName,
                uploadedOn: creationDate.toDate(),
                uploadedOnDay: TimeUtils.formatDay(creationDate, 'MMMM Do YYYY'),
                uploadedOnTime: TimeUtils.formatTime(creationDate),
                isTiffFile: FileUtils.isTiff(caseDoc),
                // uploadedBy: attachment.lastModifiedBy // Attachments don't current report who uploaded created/uploaded by
                type: CaseDocumentTypes.Attachment
            } as CaseRequirementAttachment;
        });

        // We might want to sort these in some way in the future, for now, they'll appear in the order we spread them
        this.attachments = [...formattedDocuments, ...formattedAttachments];
    }

    updateCaseRequirementAttachment(caseDocumentId: string) {
        this.subs.add(this.uwWorkbenchService.updateCaseRequirementAttachment(this.caseRequirement.id, caseDocumentId, this.data.account.id).subscribe());
    }

    previewInTab(attachment: CaseRequirementAttachment) {
        const { client, account, caseRequirement: { caseId } } = this.data;
        const { caseDocumentId } = attachment;

        const url = this.router.serializeUrl(
            this.router.createUrlTree([`/client/${client.code}/account/${account.code}/${account.versionNumber}/${caseId}/view-document/${caseDocumentId}`])
        );
        window.open(url, '_blank');
    }

    previewInSamePage(attachment: CaseRequirementAttachment) {
        const { client, account, caseRequirement } = this.data;
        const { caseId } = caseRequirement;
        const { caseDocumentId } = attachment;

        // See if it's an "attachment"
        let caseDocument = this.caseDocumentAttachments.find(x => x.id == caseDocumentId);
        // If it's not an attachment, check the "documents"
        caseDocument = caseDocument || this.caseDocumentDocuments.find(x => x.id == caseDocumentId);

        this.dialog.open<ViewDocumentDialog, ViewDocumentDialogData, ViewDocumentDialogData>(ViewDocumentDialog, {
            height: '100%',
            minWidth: Utils.isDesktop() ? '55vw' : '90%',
            maxWidth: null,
            maxHeight: '90vh',
            data: {
                client: client,
                account: account,
                caseId,
                caseDocument,
                caseDocumentType: attachment.type,
            }
        });
    }

    //#endregion
    //#region Subscriptions

    subscribeToRequirements() {
        this.subs.add(this.uwRequirementsDataService.loadRequirements(this.data.client.code, this.data.account.id).subscribe({
            next: requirementResponses => {
                this.requirements = requirementResponses;
            },
        }));
    }

    subscribeToCaseRequirements() {
        this.subs.add(this.uwRequirementsDataService.loadCaseRequirements(this.data.client.code, this.data.caseRequirement.caseId).subscribe({
            next: caseRequirementResponses => {
                this.caseRequirements = caseRequirementResponses || [];
                this.caseRequirement = this.caseRequirements.find(x => x.id === this.data.caseRequirement.id);
                this.formatAttachments();
            },
        }));
    }

    subscribeToAttachments() {
        this.subs.add(this.caseDocumentsDataService.loadAttachments(this.data.caseRequirement.caseId).subscribe({
            next: caseDocumentsDTOs => {
                this.caseDocumentAttachments = caseDocumentsDTOs || [];
                this.formatAttachments();
            }
        }));
    }

    subscribeToDocuments() {
        this.subs.add(this.caseDocumentsDataService.loadDocuments(this.data.caseRequirement.caseId).subscribe({
            next: caseDocumentsDTOs => {
                this.caseDocumentDocuments = caseDocumentsDTOs || [];
                this.formatAttachments();
            }
        }));
    }

    subsribeToFeatureToggles() {
        const { client } = this.data;

        this.subs.add(this.featureManagerService.isEnabled(FeatureToggle.UWWorkbenchDirectDownloadAttachmentClient, client.id).subscribe({
            next: isEnabled => {
                this.directDownloadIsEnabled = isEnabled;
            }
        }));

        this.subs.add(this.featureManagerService.isEnabled(FeatureToggle.GlobalFeaturePreviewDocumentOpenInNewTabClient, client.id).subscribe({
            next: isEnabled => {
                this.openInNewTabIsEnabled = isEnabled;
            }
        }));
    }

    //#endregion
    //#region Lifecycle

    ngOnInit() {
        this.dialogTitle = this.data.title;

        this.subsribeToFeatureToggles();
        this.subscribeToRequirements();
        this.subscribeToCaseRequirements();
        this.subscribeToDocuments();
        this.subscribeToAttachments();
    }

    // ngOnChage => Doesn't really work for DialogComponents because we're passing data by a sub-property.

    ngOnDestory() {
        this.subs.unsubscribe();
    }

    //#endregion
    //#region Handlers

    handleDownload(attachment: CaseRequirementAttachment) {
        const { client, account, caseRequirement } = this.data;
        const { caseDocumentId } = attachment;

        if (attachment.type == CaseDocumentTypes.Attachment) {
            this.subs.add(this.caseSummaryService.downloadAttachedDocument(client.id, account.id, caseRequirement.caseId, caseDocumentId, true).subscribe({
                next: downloadUri => {
                    window.open(downloadUri, '_blank'); // open in new tab
                },
                error: _err => {
                    this.notificationService.showNotification({ severity: NotificationSeverity.Error, message: 'Error loading document' });
                }
            }));
        }
        else {
            this.subs.add(this.caseSummaryService.getCaseDocumentUri(client.id, account.id, caseRequirement.caseId, caseDocumentId, true).subscribe({
                next: downloadUri => {
                    window.open(downloadUri, '_blank'); // open in new tab
                },
                error: _err => {
                    this.notificationService.showNotification({ severity: NotificationSeverity.Error, message: 'Error loading document' });
                }
            }));
        }
    }

    handlePreview(attachment: CaseRequirementAttachment) {
        if (this.directDownloadIsEnabled)
            return this.handleDownload(attachment);

        // DEV NOTE: If the user can't direct download and this is a TIFF, don't do anything
        // because TIFF can't be previewed until we add that feature to the previewer
        if (attachment.isTiffFile) return;

        if (this.openInNewTabIsEnabled)
            return this.previewInTab(attachment);

        this.previewInSamePage(attachment);
    }

    formatCaseRequirementPurpose(caseRequirement: CaseRequirementResponse): string {
        if (caseRequirement.subtitle) {
            return `UW Requirement ${caseRequirement.name}: ${caseRequirement.subtitle}`;
        }
        return `UW Requirement ${caseRequirement.name}`;
    }

    async handleUploadAttachment(event: Event) {
        const target = event.target as HTMLInputElement;

        if (!target?.files) return this.notificationService.showNotification({ severity: NotificationSeverity.Error, message: this.Error_UnexpectedUpload, log: true });
        if (target.files.length <= 0)
            return this.notificationService.showNotification({ severity: NotificationSeverity.Error, message: this.Error_NoDocuments, log: true });

        if (target.files.length > 1)
            return this.notificationService.showNotification({ severity: NotificationSeverity.Error, message: this.Error_MultipleDocuments, log: true });

        const file = target.files[0];
        const extension = file.name.match(/\.([^.]+)$/)[1];
        if (!FileUtils.isAllowedDocumentExtensions(extension)) return this.notificationService.showNotification({ severity: NotificationSeverity.Error, message: this.Error_DocumentExtension, log: true });

        this.uploading = true;
        const formData = new FormData();
        formData.append('1', file, file.name);

        const caseAttachment: CaseAttachment = {
            includeInCasePackage: false,
            tags: ['UW Evidence', `UW ${this.caseRequirement.name}`],
            caseId: this.caseRequirement.caseId,
            attachmentPurpose: this.formatCaseRequirementPurpose(this.caseRequirement)
        };

        await this.fileUploadService.sendCaseAttachmentInChunks(this.data.client.id, this.data.account.id, this.caseRequirement.caseId, caseAttachment, file)
            .then(commitResult => {
                this.subs.add(commitResult.subscribe({
                    next: caseDocumentId => {
                        if (caseDocumentId == null && this.fileUploadCanceled)
                            return this.notificationService.showNotification({ severity: NotificationSeverity.Error, message: this.Error_UploadCanceled, log: true });
                        else if (caseDocumentId == null)
                            return this.notificationService.showNotification({ severity: NotificationSeverity.Error, message: this.Error_UploadFailed, log: true });

                        // Notify listener who should update the `CaseRequirement` -> caseDocumentId (ie. attachment). Since this class is subscribed to CaseRequirements
                        // observables via the data service, any reloading of CaseRequirements should trigger here as well... IFF reloading is done though the `CaseRequirementsDataService`.
                        // If a listener make a direct HTTP call, any listeners won't know this.
                        this.onAttachmentUploaded.emit(caseDocumentId);
                        this.updateCaseRequirementAttachment(caseDocumentId);

                        this.notificationService.showNotification({ severity: NotificationSeverity.Success, message: this.Msg_AttachmentUploaded });
                    },
                    error: error => {
                        this.notificationService.showNotification({ severity: NotificationSeverity.Error, message: error, log: true });
                    },
                    complete: () => {
                        // DEV NOTE: This line of code allows a user to upload the same file
                        // it clears out the file picker cache which allows change event to
                        // fire for the same file.
                        this.fileInputRef.nativeElement.value = '';
                        this.uploading = false;
                        this.caseDocumentsDataService.reloadAttachments(this.caseRequirement.caseId);
                    }
                }));
            }).catch(err => {
                // DEV NOTE: This line of code allows a user to upload the same file
                // it clears out the file picker cache which allows change event to
                // fire for the same file.
                this.fileInputRef.nativeElement.value = '';
                this.uploading = false;
                this.notificationService.showNotification({ severity: NotificationSeverity.Error, message: err, log: true });
            });
    }

    handleCancel() {
        this.fileUploadCanceled = true;
        this.fileUploadService.cancelUpload();

        this.dialogRef.close({
            action: "cancel"
        });
    }

    //#endregion
}
