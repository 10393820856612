import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as moment from 'moment';

@Injectable()
export class LocalTimeInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (req.url.indexOf('.gtlic.com') > 0){
            return next.handle(req);
        }

        const utcUnixTimestamp = `${moment.utc().toISOString()}`;
        const request = req.clone({
            headers: req.headers.append('X-Client-Timestamp', utcUnixTimestamp)
        });

        return next.handle(request);
    }

}
