import { Injectable } from '@angular/core';
import { IConfigService } from 'app/config/iconfigservice';
import signalR = require('@microsoft/signalr');
import { v4 as uuidv4 } from 'uuid';

@Injectable()
export class SignalRService {
    private _sessionId: string;
    private _sessionIdKey = 'uwpipeline.session_id';

    constructor(public configService: IConfigService) { }

    public getSessionId() {
        if (this._sessionId == null || this._sessionId === '') {
            let persistentSessionId = localStorage.getItem(this._sessionIdKey);
            if (persistentSessionId == null || persistentSessionId === '') {
                persistentSessionId = uuidv4();
                localStorage.setItem(this._sessionIdKey, persistentSessionId);

                console.info(`Generated new Session ID`);
            } else {
                console.info(`Found existing Session ID`);
            }

            this._sessionId = persistentSessionId;

            console.info(`SignalR Session ID: ${this._sessionId}`);
        }

        return `${this._sessionId}`;
    }

    getCaseSignalRConnection(caseId: string): signalR.HubConnection {
        const baseUrl = this.configService.getConfiguration().signalRBaseUrl;

        return new signalR.HubConnectionBuilder()
            .withUrl(`${baseUrl}session/${this.getSessionId()}?case=${caseId}`)
            .withAutomaticReconnect()
            .build();
    }

    getClientSignalRConnection(clientId: string): signalR.HubConnection {
        const baseUrl = this.configService.getConfiguration().signalRBaseUrl;

        return new signalR.HubConnectionBuilder()
            .withUrl(`${baseUrl}session/${this.getSessionId()}?clientId=${clientId}`)
            .withAutomaticReconnect()
            .build();
    }
}
