import { Injectable } from '@angular/core';
import { AppService } from '../../app.service';
import * as _ from 'lodash';
import { shareReplay, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { _fixedSizeVirtualScrollStrategyFactory } from '@angular/cdk/scrolling';

export interface FeatureFlags {
    [name: string]: boolean;
}

export enum EnvironmentName {
    Production = 'prod',
    Stage = 'stage',
    Test = 'test',
    Development = 'dev',
    WIP = 'wip'
}

export enum FeatureName {
    LaunchRnaEApp = 'launchRnaEApp'
}

export interface Environment {
    mode: EnvironmentName;
    buildNumber: string;
    isExportEnabled: false;
    isImportEnabled: false;
    featureFlags: FeatureFlags;
}

@Injectable()
export class EnvironmentService {
    private _cachedEnvironment: Observable<Environment>;

    constructor(private appService: AppService) {}

    public getEnvironment(cache = true): Observable<Environment> {
        if (cache && this._cachedEnvironment != null) {
            return this._cachedEnvironment;
        }

        this._cachedEnvironment = this.appService.getData('EnvInfo').pipe(
            // map the API result so we can apply the feature flag defaults
            map(result => {
                return result.data;
            }),

            // cache the last value in memory until a refresh
            shareReplay(1)
        );

        return this._cachedEnvironment;
    }
}
