// Permission types are part of the Role/Permissions. These are used in `app.service.ts` -> `CheckACL()`
// and `acl.guard.ts`.  This enum was created much later than the usage and is a better way than hard-coded strings of "R"
//const levels = { 'N': 0, 'R': 1, 'V': 1, 'W': 2, 'F': 3 };
export enum PermissionTypes {
    None = 'N', // 0
    Read = 'R', // 1 (same as View)
    View = 'V', // 1 (same as Read)
    Write = 'W', // 2
    Full = 'F', // 3
}
export const PermissionTypesIdMap = new Map(Object.values(PermissionTypes).map(x => [x.toString(), x]));