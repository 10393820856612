import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { AppService } from '@App';
import { MapKeyEntitiesDTO, MapKeysDTO, RulesDTO } from '@DTOs';
import { MapKeyType } from '@Enums';
import { UWResponse } from '@Models';
import { BaseService } from '@Services';
import { IConfigService } from 'app/config/iconfigservice';
import * as _ from 'lodash';
import { forkJoin, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

// import { MapkeyDto } from "app/account/account-config/account-mapkeys/models/mapkey-dto";
@Injectable()
export class MapKeyService extends BaseService {

    @Output() treeReloading = new EventEmitter();
    publicApiBaseUrl: string;
    mapKeys: MapKeysDTO[]; // Should be MapkeysDto[]
    constantListMapKeys: any[];
    nonListNonVirtualMapKeys: any[];
    nonListMapKeys: any[];
    nonVirtualMapKeys: any[];
    nonConstantMapKeys: any[];
    variableMapKeys: any[];
    virtualMapKeys: any[];
    preFillMapkeys: MapKeysDTO[];
    dynamicListMapkeys: MapKeysDTO[];
    mapKeysObserver: Subject<boolean> = new Subject<boolean>();
    treeData: any;	// Mixture of MapkeysEntityDTO and MapkeysDto objects
    rules: RulesDTO[];
    rootMapNode: any;
    rootMapNodeId: any;
    parentData: any[];

    constructor(public appService: AppService, public configService: IConfigService, public httpClient: HttpClient) {
        super(appService, configService, httpClient);
        this.publicApiBaseUrl = this.configService.getConfiguration().publicApiUrl;

        this.mapKeysObserver.subscribe(() => true);
    }

    clearData() {
        this.treeData = undefined;
        this.mapKeys = undefined;
        this.rules = undefined;
    }

    filterConstantListMapKeysForMultipeChoiceQuestions() {
        const filteredMapKeys = _.concat(...this.constantListMapKeys??[], ...this.dynamicListMapkeys??[]);
        return this.sortMapKeys(filteredMapKeys);
    }

    filterInputMapKeys(mapKeys: any[]) {
        const filteredMapKeys = (mapKeys || []).filter(mapKey => (
            (mapKey.isConstant && !mapKey.isList) ||
            (mapKey.isVirtual && mapKey.isVirtualExpression) ||
            (!mapKey.isVirtual && !mapKey.isConstant)));

        return this.sortMapKeys(filteredMapKeys);
    }

    filterOutputMapKeys(mapKeys: any[]) {
        const filteredMapKeys = (mapKeys || []).filter(mapKey =>
            (!mapKey.isVirtual && !mapKey.isConstant));

        return this.sortMapKeys(filteredMapKeys);
    }

    filterIntegrationResponseMapKeys(mapKeys: any[]) {
        const filteredMapKeys = (mapKeys || []).filter(x => x.isJson && x.integrationId != null);

        return this.sortMapKeys(filteredMapKeys);
    }

    sortMapKeys(mapKeys: any[]) {
        return mapKeys.sort((a, b) => a.entityHierarchy.localeCompare(b.entityHierarchy, 'en', {sensitivity: 'base'}));
    }

    saveMapKey(url, mapkey, isEditValue): Observable<any> {
        return this.appService.postData(url, mapkey, isEditValue).pipe(
            map(
                response => {
                    if (response.status === 'success') {
                        this.appService.showMsg('success', 'Saved successfully ...');
                        if (this.treeData && this.treeData !== null) {
                            if (url === 'mapKeyEntities') {
                                response.data.canAdd = true;
                            }

                            if (!isEditValue) {
                                this.addChildToNode(response.data);
                            } else {
                                this.editChildInNode(response.data);
                            }
                        }

                        return response.data;
                    } else {
                        this.appService.showMsg('error', response.message);
                    }
                }
            ));
    }

    createMapKeyFromDropdown(mapKey: any, accountId: string): Observable<any> {
        return this.appService.postData(`MapKeys/${accountId}/CreateFromQuestion`, mapKey, false).pipe(
            map(response => {
                return response;
            }));

    }

    getMapKeys(accountId: string, reloadTreeData?: boolean) {
        if (reloadTreeData) {
            this.treeReloading.emit();
        }
        if (!this.treeData || reloadTreeData) {
            return forkJoin([
                this.appService.getData<UWResponse<MapKeysDTO[]>>(`MapKeys/${accountId}/MapKeys`),
                this.appService.getData<UWResponse<MapKeyEntitiesDTO[]>>(`MapKeyEntities/${accountId}`)
            ]).pipe(
                map(([first, second]) => {
                    this.setMapKeys(first);

                    if (second.status === 'success') {
                        this.treeData = second.data;
                        this.formatData(this.treeData);
                    }
                })
            );
        } else {
            return forkJoin([this.appService.getData<UWResponse<MapKeysDTO[]>>(`MapKeys/${accountId}/MapKeys`)]).pipe(
                map(([first]) => {
                    this.setMapKeys(first);
                }));
        }
    }

    isDynamic(mapKey) {
        return mapKey.mapKeyTypeId == MapKeyType.DynamicList;
    }

    setMapKeys(data: UWResponse<MapKeysDTO[]>) {
        if (data.status === 'success') {
            this.mapKeys = [];
            this.constantListMapKeys = [];
            this.nonConstantMapKeys = [];
            this.nonVirtualMapKeys = [];
            this.nonListNonVirtualMapKeys = [];
            this.nonListMapKeys = [];
            this.variableMapKeys = [];
            this.dynamicListMapkeys = [];
            this.virtualMapKeys = [];

            data.data.forEach(mapKey => {
                this.mapKeys = [...this.mapKeys, mapKey];

                if (this.isDynamic(mapKey)) {
                    this.dynamicListMapkeys = [...this.dynamicListMapkeys, mapKey];
                }
                if (!this.isDynamic(mapKey)) {
                    if (mapKey.isConstant && mapKey.isList) {
                        this.constantListMapKeys = [...this.constantListMapKeys, mapKey];
                    }

                    if (!mapKey.isConstant) {
                        this.nonConstantMapKeys = [...this.nonConstantMapKeys, mapKey];
                    }

                    if (!mapKey.isVirtual) {
                        this.nonVirtualMapKeys = [...this.nonVirtualMapKeys, mapKey];
                    }

                    if (!mapKey.isVirtual && !mapKey.isList) {
                        this.nonListNonVirtualMapKeys = [...this.nonListNonVirtualMapKeys, mapKey];
                    }

                    if (!mapKey.isList) {
                        this.nonListMapKeys = [...this.nonListMapKeys, mapKey];
                    }
                }
                if (!this.isDynamic(mapKey) && !mapKey.isConstant && ((mapKey.isVirtual && mapKey.isVirtualExpression) || (!mapKey.isVirtual))) {
                    this.variableMapKeys = [...this.variableMapKeys, mapKey];
                }

                if (mapKey.isVirtual && mapKey.isVirtualExpression) {
                    this.virtualMapKeys = [...this.virtualMapKeys, mapKey];
                }
            });

            this.variableMapKeys = _.sortBy(this.variableMapKeys, function (val) {
                return val.entityHierarchy;
            });

            this.mapKeys = _.sortBy(this.mapKeys, function (val) {
                return val.entityHierarchy;
            });

            this.mapKeysObserver.next(true);
        }
    }

    formatData(tree, parentNode?) {
        tree.forEach(node => {

            // if (!node) debugger;

            if (!node.parentId && !node.mapKeyEntityId) {
                node['level'] = 'root';
            } else if (node.parentId) {
                node['level'] = 'entity';
            } else if (node.mapKeyEntityId) {
                node['level'] = 'mapkey';
            }

            if (node.level !== 'mapkey') {
                if (parentNode) {
                    node.parentNames = [...parentNode.parentNames, node.name];
                } else {
                    node.parentNames = [node.name];
                }
                node.parentPath = node.parentNames.join('/');
            } else {
                node.parentPath = parentNode.parentNames.join('/');
            }

            switch (node.level) {
                case 'root':
                    node.expandedIcon = 'fas fa-folder-open';
                    node.collapsedIcon = 'fas fa-folder';
                    this.rootMapNodeId = node.id;
                    this.rootMapNode = node.name;
                    if (node.name !== 'System' && node.name !== 'Account' && node.name !== 'Client') {
                        node.canAdd = true;
                    }
                    break;
                case 'entity':
                    node.expandedIcon = 'fas fa-folder-open';
                    node.collapsedIcon = 'fas fa-folder';
                    node.canAdd = true;
                    break;
                case 'mapkey':
                    if (node.isSystemGenerated) {
                        node.collapsedIcon = 'fas fa-server';
                    } else if (!node.isConstant && node.mapKeyTypeId === MapKeyType.Virtual) {
                        node.collapsedIcon = 'fas fa-magic';
                    } else if (!node.isConstant && node.mapKeyTypeId === MapKeyType.VirtualDocument) {
                        node.collapsedIcon = 'far fa-file-code mr-1';
                    } else if (!node.isConstant) {
                        node.collapsedIcon = 'fas fa-key';
                    } else if (node.isConstant && node.isList) {
                        node.collapsedIcon = 'fas fa-list';

                    } else {
                        node.collapsedIcon = 'fa-regular fa-circle-dot';
                    }

                    node.canAdd = false;
                    break;
            }

            node.label = node.name;
            node.data = node.id;
            node.rootMapNodeId = this.rootMapNodeId;
            node.rootMapNode = this.rootMapNode;
            if (node.inverseParent && node.mapKeys) {
                node.children = node.inverseParent.concat(node.mapKeys);
            } else {
                node.children = [];
            }
            if (node.children.length) {
                this.formatData(node.children, node);
            }

        });
    }

    addChildToNode(childData) {
        let newChild: any = {};
        let parentNodeId;
        if (childData.isConstant) {
            newChild = childData;
            newChild.rootNode = 'Account';
            parentNodeId = childData.mapKeyEntityId;
            newChild.id = childData.id;
            newChild.label = childData.name;
            newChild.mapKeyEntityId = childData.mapKeyEntityId;
            newChild.entityHierarchy = childData.entityHierarchy;
            newChild.level = 'mapkey';
            if (childData.isList) {
                newChild.collapsedIcon = 'fas fa-list';
            } else {
                newChild.collapsedIcon = 'fa-regular fa-circle-dot';
            }
        } else if (childData.mapKeyEntityId) {
            parentNodeId = childData.mapKeyEntityId;
            newChild = childData;
            newChild.label = childData.name;
            newChild.level = 'mapkey';
            newChild.sanitizedValue = childData.sanitizedValue;
            newChild.isPii = childData.isPii;
            newChild.canAdd = true;

            if (childData.mapKeyTypeId === MapKeyType.Virtual) {
                newChild.collapsedIcon = 'fas fa-magic';
            } else if (childData.mapKeyTypeId === MapKeyType.VirtualDocument) {
                newChild.collapsedIcon = 'far fa-file-code mr-1';
            } else {
                newChild.collapsedIcon = 'fas fa-key';
            }
        } else if (childData.parentId) {
            newChild = childData;
            parentNodeId = childData.parentId;
            newChild.label = childData.name;
            newChild.level = 'entity';
            newChild.expandedIcon = 'fas fa-folder-open';
            newChild.collapsedIcon = 'fas fa-folder';
            newChild.children = [];
        }
        for (const child of this.treeData) {
            this.rootMapNode = child.rootMapNode;
            this.rootMapNodeId = child.rootMapNodeId;
            this.pushChildInTreeData(child, parentNodeId, newChild);
        }

    }

    pushChildInTreeData(child, id, childNode) {
        if (child.id === id) {
            childNode.rootMapNode = this.rootMapNode;
            childNode.rootMapNodeId = this.rootMapNodeId;
            childNode.parentNames = child.parentNames;
            childNode.parentPath = child.parentPath;
            child.children.push(childNode);
            // child.children = _.sortBy(child.children, [function (o) { return o.label.toLowerCase(); }]);

            child.children.sort(function (entity1, entity2) {
                if (entity1.level === 'mapkey' && entity2.level === 'entity') {
                    return 1;
                }
                if (entity1.level === 'entity' && entity2.level === 'mapkey') {
                    return -1;
                }

                if (entity1.label.toLowerCase() > entity2.label.toLowerCase()) {
                    return 1;
                }
                if (entity1.label.toLowerCase() < entity2.label.toLowerCase()) {
                    return -1;
                }
            });
            return;
        } else {
            if (child.children && child.children.length) {
                for (const newChild of child.children) {
                    if (newChild.level === 'entity') {
                        this.pushChildInTreeData(newChild, id, childNode);
                    }
                }
            }
        }
    }

    editChildInNode(childData) {
        const childId = childData.id;
        for (const child of this.treeData) {
            this.editChildData(child, childId, childData);
        }
    }

    editChildData(child, id, childData) {
        if (child.id === id) {
            child.label = childData.name;
            child.entityHierarchy = childData.entityHierarchy;
            child.virtualExpression = childData.virtualExpression;
            child.isVirtualExpression = childData.isVirtualExpression;
            child.sanitizedValue = childData.sanitizedValue;
            child.integrationId = childData.integrationId;
            child.mapKeyTypeId = childData.mapKeyTypeId;
            child.delimiter = childData.delimiter;
            child.isPii = childData.isPii;

            if (childData.isSystemGenerated) {
                child.collapsedIcon = 'fas fa-server';
            } else if (childData.mapKeyTypeId === MapKeyType.Virtual) {
                child.collapsedIcon = 'fas fa-magic';
                child.delimiter = childData.delimiter;
            } else if (childData.mapKeyTypeId === MapKeyType.VirtualDocument) {
                child.collapsedIcon = 'far fa-file-code mr-1';
                child.delimiter = childData.delimiter;
            } else if (childData.mapKeyTypeId !== MapKeyType.Virtual && !childData.isConstant && childData.entityHierarchy) {
                child.collapsedIcon = 'fas fa-key';
            }

            if (childData.isConstant) {
                child.constantMapKeyValues = childData.constantMapKeyValues;
                child.constantMapkeyVariants = childData.constantMapkeyVariants;
                child.isList = childData.isList;
                if (childData.isList) {
                    child.collapsedIcon = 'fas fa-list';
                } else {
                    child.collapsedIcon = 'fa-regular fa-circle-dot';
                }
            }

            return;
        } else if (child.children && child.children.length) {
            for (const newChild of child.children) {
                this.editChildData(newChild, id, childData);
            }
        }
    }

}

export interface MapkeyNode {
    listMapKeyName: string;
    expressionId: string;
    sanitizedValue?: string;
    accountId: string;
    canAdd: boolean;
    children: MapkeyNode[];
    clientId?: string;
    collapsedIcon: string;
    constantMapKeyValues?: any[];
    createdBy?: string;
    creationDate?: string;
    data?: string;
    delimiter?: string;
    entityHierarcy?: string;
    expandedIcon?: string;
    entityHierarchy: string;
    id: string;
    integrationId?: string;
    inverseParent?: MapkeyNode[];
    isConstant?: boolean;
    isJson?: boolean;
    isList?: boolean;
    isPii?: boolean;
    isRepeatable?: boolean;
    isSystemGenerated?: boolean;
    isVirtual?: boolean;
    isVirtualExpression?: boolean;
    label: string;
    lastModifiedBy: string;
    lastModifiedDate: string;
    level: string;
    mapkeys?: any[];
    mapKeyEntityId?: string;
    mapKeyTypeId?: string;
    name: string;
    parent: MapkeyNode;
    parentId: string;
    parentNames?: MapkeyNode[];
    parentPath: string;
    repBlockMapkeys?: any;
    rootMapNode: string;
    rootMapNodeId: string;
    virtualExpression?: string;
    virtualMapKeysList: any[];
    constantMapkeyVariants?: any[];
}
