import {Pipe, PipeTransform} from '@angular/core';
@Pipe({
    name: 'secondsToTime'
})
export class SecondsToTimePipe implements PipeTransform {
    times = {
        year: 31557600,
        month: 2629746,
        day: 86400,
        hour: 3600,
        minute: 60,
        second: 1
    };

    transform(seconds) {
        const hours = Math.floor(seconds / this.times.hour);
        seconds = seconds - (hours * this.times.hour);
        const minutes = Math.floor(seconds / this.times.minute);
        seconds = seconds - (minutes * this.times.minute);

        if (hours > 0) {
            return hours + ':' + ('00' + minutes).slice(-2) + ':' + ('00' + seconds).slice(-2);
        } else {
            return ('00' + minutes).slice(-2) + ':' + ('00' + seconds).slice(-2);
        }
    }
}
