import { Injectable } from '@angular/core';
import { ConditionEvaluation, ConditionSetEvaluation, MapkeyEvaluation, RuleEvaluation } from 'app/models';
import { MonitoringService } from 'app/monitor.service';
import { Engine } from 'json-rules-engine';
import moment = require('moment');

@Injectable({
    providedIn: 'root'
})
export class RulesEngineService {

    constructor(public monitoringService: MonitoringService) { }

    execute(caseId: string, rule, factValues, clientId?: string): Promise<boolean> {
        const ruleEvaluation = this.generateRuleEvaluationModel(caseId, rule, factValues, clientId);
        const ruleDetail = rule.ruleDetail;

        const engine = new Engine();
        engine.addRule({
            conditions: ruleDetail,
            event: {
                type: 'ruleEval',
                params: {
                    message: 'true: ' + rule
                }
            }
        });

        rule.facts.forEach(function (fact) {
            const factValue = factValues.find(function (x) { return x.name === fact; });
            if (factValue && factValue.value != null) {
                engine.addFact(factValue.name, factValue.value);
            }
            else {
                engine.addFact(fact, '');
            }
        });

        return engine.run().then(function (result) {
            let ruleResult: boolean;
            if (result.events && result.events.length > 0) {
                ruleResult = true;
            }
            else {
                ruleResult = false;
            }

            ruleEvaluation.ruleResult = ruleResult;
            if (ruleEvaluation.caseId)
                this.monitoringService.addRuleExecutionLog(ruleEvaluation);

            return ruleResult;
        }.bind(this));
    }

    generateRuleEvaluationModel(caseId: string, rule, factValues, clientId?: string): RuleEvaluation {
        const ruleEvaluation: RuleEvaluation = {
            clientId,
            caseId,
            name: rule.ruleName,
            id: rule.ruleId,
            conditionSets: [],
            correlationId: this.monitoringService.getRequestId(),
            executionDate: new Date(moment.utc().format()),
            evaluationType: "Clientside Rule"
        };

        if (rule.ruleDetail.any) {
            ruleEvaluation.ruleFilter = "Any";

            rule.ruleDetail.any.forEach(conditionSet => {
                const conditionSetEvaluation = this.buildConditionSet(conditionSet, factValues);
                ruleEvaluation.conditionSets.push(conditionSetEvaluation);
            });
        }

        if (rule.ruleDetail.all) {
            ruleEvaluation.ruleFilter = "All";

            rule.ruleDetail.all.forEach(conditionSet => {
                const conditionSetEvaluation = this.buildConditionSet(conditionSet, factValues);
                ruleEvaluation.conditionSets.push(conditionSetEvaluation);
            });
        }

        return ruleEvaluation;
    }

    private buildConditionSet(conditionSet, factValues): ConditionSetEvaluation {
        const conditionSetEvaluation: ConditionSetEvaluation = { conditions: [] };

        if (conditionSet.all) {
            conditionSetEvaluation.conditionSetFilter = 'All';

            conditionSet.all.forEach(condition => {
                const conditionEvaluation = this.buildCondition(condition, factValues);
                conditionSetEvaluation.conditions.push(conditionEvaluation);
            });
        }

        if (conditionSet.any) {
            conditionSetEvaluation.conditionSetFilter = 'Any';
            conditionSet.any.forEach(condition => {
                const conditionEvaluation = this.buildCondition(condition, factValues);
                conditionSetEvaluation.conditions.push(conditionEvaluation);
            });
        }

        return conditionSetEvaluation;
    }

    private buildCondition(condition, factValues): ConditionEvaluation {
        const conditionEvaluation: ConditionEvaluation = { inputs: [] };
        conditionEvaluation.expression = `${condition.fact} ${condition.operator} ${condition.value}`;

        const factOperand = factValues.find(x => x.name === condition.fact);
        const valueOperand = factValues.find(x => x.name === condition.value);

        const factMapkeyEvaluation: MapkeyEvaluation =
        {
            entityHierarchy: condition.fact,
            value: factOperand?.value
        };

        conditionEvaluation.inputs.push(factMapkeyEvaluation);

        if (valueOperand) {
            const valueMapkeyEvaluation: MapkeyEvaluation =
            {
                entityHierarchy: condition.value,
                value: valueOperand?.value
            };

            conditionEvaluation.inputs.push(valueMapkeyEvaluation);
        }

        return conditionEvaluation;
    }
}
