import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {  MatChipInputEvent } from '@angular/material/chips';
import { AccountEvent, Account, Tag, FaxEvent, IFaxItem, TagFaxItem, DocumentFaxItem, DocumentExternal } from '../../../../../models';
import { DocumentsService, MapKeyService, NotificationService, NotificationSeverity, TagService } from '../../../../../services';

@Component({
    selector: 'event-action-fax',
    templateUrl: 'event-action-fax.component.html'
})

export class EventActionFaxComponent implements OnInit {

    public stepOneFormGroup: UntypedFormGroup;
    public stepTwoFormGroup: UntypedFormGroup;
    public stepThreeFormGroup: UntypedFormGroup;
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];

    accountInfo: Account;
    event: AccountEvent;
    mapKeys: any;
    documents: DocumentExternal[];
    tags: Tag[];
    selectedDocuments: any[] = [];
    selectedTags: Tag[] = [];
    selectedItems: any[] = [];
    template: FaxEvent;

    constructor(
    private _fb: UntypedFormBuilder,
    public notificationService: NotificationService,
    public mapKeyService: MapKeyService,
    public documentService: DocumentsService,
    public tagService: TagService,
    public dialogRef: MatDialogRef<EventActionFaxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit() {
        this.event = this.data.event;
        this.accountInfo = this.data.accountInfo;
        this.mapKeys = this.mapKeyService.nonConstantMapKeys;

        this.stepOneFormGroup = this._fb.group({
            mapKeyId: ['', Validators.required]
        });
        this.stepTwoFormGroup = this._fb.group({});
        this.stepThreeFormGroup = this._fb.group({});

        this.setupData();
    }

    async setupData(){
        await this.getDocuments();
        await this.getTags();
        await this.setupForm();
    }

    async setupForm() {
        if (this.event.configuration && this.event.configuration !== null) {
            const configuration = JSON.parse(this.event.configuration);
            this.template = JSON.parse(configuration["faxSetup"]);

            this.stepOneFormGroup.patchValue({
                mapKeyId: this.template['mapKeyId']
            });

            for await (const faxItem of this.template.faxItems) {
                // is tag?
                const tagCheck = (p: any): p is TagFaxItem => p.hasOwnProperty('tagName');

                if (tagCheck(faxItem)) {
                    const tag = this.tags.find(x => x.name === faxItem.tagName);
                    this.selectedTags.push(tag);
                    this.selectedItems.push(tag);
                    tag.checked = true;
                }
                else {
                    const documentFaxItem = <DocumentFaxItem>faxItem;
                    const document = this.documents.find(x => x.id === documentFaxItem.documentId);
                    this.selectedDocuments.push(document);
                    this.selectedItems.push(document);
                    document.checked = true;
                }
            }
        }
    }

    async getDocuments() {
        this.documents = await this.documentService.getDocumentList(this.accountInfo.id, this.accountInfo.versionNumber);
    }

    async getTags() {
        this.tags = await this.tagService.getTagsByAccount(this.accountInfo);
    }

    addTag(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;

        if ((value || '').trim()) {
            var newTag: Tag = { accountId: this.accountInfo.id, name: value.trim(), count: 0 };

            this.tags.push(newTag);
        }

        if (input) {
            input.value = '';
        }
    }

    isItemSelected(item: any, itemList): boolean {
        const index = itemList.indexOf(item);
        return index >= 0;
    }

    toggleItem(item: any, itemList): void {
        const index = itemList.indexOf(item);
        const selectedItemsIndex = this.selectedItems.indexOf(item);

        if (index >= 0) {
            itemList.splice(index, 1);
        } else {
            itemList.push(item);
        }

        if (selectedItemsIndex >= 0) {
            this.selectedItems.splice(selectedItemsIndex, 1);
        } else {
            this.selectedItems.push(item);
        }
    }

    drop(event: CdkDragDrop<any[]>) {
        moveItemInArray(this.selectedItems, event.previousIndex, event.currentIndex);
    }

    remove(item: any): void {
        const selectedItemsIndex = this.selectedItems.indexOf(item);
        const selectedDocumentsIndex = this.selectedDocuments.indexOf(item);
        const selectedTagsIndex = this.selectedTags.indexOf(item);

        if (selectedItemsIndex >= 0) {
            this.selectedItems.splice(selectedItemsIndex, 1);
        }

        if (selectedDocumentsIndex >= 0) {
            this.selectedDocuments.splice(selectedDocumentsIndex, 1);
        }

        if (selectedTagsIndex >= 0) {
            this.selectedTags.splice(selectedTagsIndex, 1);
        }
    }

    saveToEvent() {
        const faxItems: IFaxItem[] = [];
        let idx = 0;

        if (this.selectedItems.length <= 0) {
            this.notificationService.showNotification({
                severity: NotificationSeverity.Error,
                message: 'Please select at least one document or tag'
            });
            return false;
        }

        this.selectedItems.forEach(item => {
            const selectedDocumentsIndex = this.selectedDocuments.indexOf(item);
            const selectedTagsIndex = this.selectedTags.indexOf(item);

            if (selectedTagsIndex >= 0) {
                const selectedTag = this.selectedTags[selectedTagsIndex];
                const tagItem: TagFaxItem = {
                    order: idx,
                    tagName: selectedTag.name
                };
                faxItems.push(tagItem);
            }

            if (selectedDocumentsIndex >= 0) {
                const selectedDocument = this.selectedDocuments[selectedDocumentsIndex];
                const documentItem: DocumentFaxItem = {
                    order: idx,
                    documentId: selectedDocument.id
                };
                faxItems.push(documentItem);
            }

            idx++;
        });

        const faxEvent: FaxEvent = {
            mapKeyId: this.stepOneFormGroup.get("mapKeyId").value,
            faxItems: faxItems
        };

        const faxSetup = {
            "faxSetup": JSON.stringify(faxEvent)
        };
        this.event.configuration = JSON.stringify(faxSetup);


        this.dialogRef.close();
    }
}
