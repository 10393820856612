import { HttpEvent, HttpHandler, HttpParams, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthHttpInterceptor } from "@auth0/auth0-angular";
import { Observable } from "rxjs";

@Injectable()
export class AuthHttpInterceptorExtended  extends AuthHttpInterceptor {

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (req.url.endsWith('whitelabelconfigs') || req.url.indexOf('.gtlic.com') > 0) {
            return  next.handle(req);
        }
        else {
            const customParams = localStorage.getItem('customAttributes');

            if (customParams !== null && customParams !== ''){
                const params = new URLSearchParams(customParams);
                let httpParam = new HttpParams();

                params.forEach(function(value, key) {
                    if (key !== 'connection' && key !== 'redirectTo')
                        httpParam = httpParam.append(key, value);
                });

                req.params.keys().forEach(function(key) {
                    if (key !== 'connection' && key !== 'redirectTo')
                        httpParam = httpParam.append(key, req.params.get(key));
                });

                const modifiedReq = req.clone({
                    params: httpParam,
                });

                return super.intercept(modifiedReq, next);
            }else{
                return super.intercept(req, next);
            }

        }
    }
}
