import * as config from '../assets/environment.json';

export const environment = {
    production: true,
    configFile: 'assets/environment.json'
};

export const environmentConfig = {
    ...config,
    httpInterceptor: {
        allowedList: [
            {
                uri: config.apiUrl.replace('v1/', '*'),
                allowAnonymous: true
            },
            {
                uri: config.publicApiUrl.replace('v2/', '*'),
                allowAnonymous: true
            },
            {
                uri: config.whiteLabelResourceUrl,
                allowAnonymous: true
            }
        ]
    }
};
