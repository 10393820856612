<div>
    <div *ngIf="tableRows.length == 0" class="no-cases">{{ noAccounts }}</div>

    <div *ngIf="tableRows.length > 0">
        <table mat-table multiTemplateDataRows [matSortActive]="currentSort.columnName" [matSortDirection]="currentSort.sortDirection" [dataSource]="tableRows" matSort class="expand-table" (matSortChange)="onSortChange($event)">
            <!-- Templates -->
            <!-- Header -->
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row class="table-row" *matRowDef="let row; let i = index; columns: displayedColumns;"></tr>

            <ng-container matColumnDef="account">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by account">Account</th>
                <td mat-cell *matCellDef="let row">{{row.account.name}} ({{row.account.code}})</td>
            </ng-container>
            
            <ng-container matColumnDef="activated">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by activated date">Activated</th>
                <td mat-cell *matCellDef="let row">{{row.account.activationDateDisplay}}</td>
            </ng-container>

            <ng-container matColumnDef="version">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by version">Version</th>
                <td mat-cell *matCellDef="let row">
                    {{row.account.versionLabel}} - V{{row.account.versionNumber}}
                </td>
            </ng-container>

            <ng-container matColumnDef="rule">
                <th mat-header-cell *matHeaderCellDef>Rule</th>
                <td mat-cell *matCellDef="let row">
                    <div *ngIf="row.rules == null">
                        <mat-spinner mode="indeterminate" diameter="30"></mat-spinner>
                    </div>
                    <div *ngIf="row.rules?.length == 0">
                        No rules found for this account
                    </div>
                    <div *ngIf="row.rules?.length > 0">
                        <mat-form-field class="rule-select">
                            <mat-select panelClass="auto-width-panel" [(value)]="row.selectedRule.ruleId" [disabled]="row.selectedRule.disabled" (selectionChange)="handleRuleChange($event, row)">
                                <mat-option *ngFor="let rule of row.rules" [value]="rule.id">{{ rule.name }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </td>
            </ng-container>
        </table>

    </div>
</div>