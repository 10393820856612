<div id="innercontent" class="custom-scrollbar">
  <div class="container-fluid container-background">
    <div class="mat-elevation-z8 my-4 mx-2 pb-4">
      <div class="flex flex-align-center bg-white py-2 px-4" style="justify-content: space-between">
        <mat-form-field class="filter-cases" floatLabel="never">
          <input matInput placeholder="Filter Cases..." name="filterValue" (keyup)="updateFilteredCases()"
            [(ngModel)]="filterValue">
        </mat-form-field>
        <div>
          <button type="button" class="btn btn-link btn-sm float-right custom" (click)="getActiveUsers()">
            <i aria-hidden="true" class="fa fa-sync-alt"></i> Refresh
          </button>
        </div>
      </div>
      <table mat-table [dataSource]="caseDataSource" matSort matSortActive="lastModifiedDate" matSortDirection="desc"
        style="width:100%;">
        <ng-container matColumnDef="clientName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:200px;">Client</th>
          <td mat-cell *matCellDef="let element"> {{element.clientName}} </td>
        </ng-container>
        <ng-container matColumnDef="accountName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:200px;">Account</th>
          <td mat-cell *matCellDef="let element"> {{element.accountName}} </td>
        </ng-container>
        <ng-container matColumnDef="caseNumberDisplay">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:140px;">Case Number</th>
          <td mat-cell *matCellDef="let element"> {{element.caseNumberDisplay}} </td>
        </ng-container>
        <ng-container matColumnDef="userName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:140px;">User Name</th>
          <td mat-cell *matCellDef="let element"> {{element.userName}} </td>
        </ng-container>
        <ng-container matColumnDef="lastModifiedDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Modified</th>
          <td mat-cell *matCellDef="let element"> {{element.lastModifiedDate}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
      </table>
      <div *ngIf="caseDataSource.filteredData == null || caseDataSource.filteredData.length == 0" class="bg-white">
        <div class="p-4 text-center text-muted ">
          <img src="./assets/images/no-data-placeholder.png " alt=" " class="pb-3 " />
          <h6>No Cases Found</h6>
        </div>
      </div>
      <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]">
      </mat-paginator>
    </div>
  </div>
</div>
