import { Component, Input, OnInit } from '@angular/core';
import { AppService } from 'app/app.service';
import { AccountInterviewService } from '../../account-interview.service';

@Component({
    selector: 'interview-navigation-reflexive-choice',
    // host: { 'class': 'content' },
    templateUrl: './interview-navigation-reflexive-choice.component.html',
    styleUrls: ['./interview-navigation-reflexive-choice.component.scss']
})
export class InterviewNavigationReflexiveChoiceComponent implements OnInit {

    @Input() reflexiveChoice;
    @Input() selectedQuestion;
    @Input() selectedReflexiveChoice;
    @Input() parentQuestions;
    @Input() selectedSection;
    @Input() section;
    @Input() sections;
    @Input() question;
    @Input() nestedLevel: number;
    @Input() filterReflexiveQuestionsToQuesions: [];

    constructor(
        private appService: AppService,
        private interviewService: AccountInterviewService) {
    }

    ngOnInit() {
        if (this.filterReflexiveQuestionsToQuesions && this.filterReflexiveQuestionsToQuesions.length) {
            this.reflexiveChoice.expanded = true;
        }
    }

    selectReflexiveChoice(choice) {
        this.interviewService.selectReflexiveChoice(choice);
    }

    checkACL(permissionType, redirect = undefined) {
        return this.appService.checkACL('Accounts', permissionType, redirect);
    }
}
