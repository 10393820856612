﻿<h1 mat-dialog-title>{{modalTitle}}</h1>
<mat-dialog-content>
  <div>
    <audio controls controlsList="nodownload" style="width: 100%" #audioControl oncontextmenu="return false;">
      Your browser does not support the audio element.
    </audio>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button color="primary" style="float: right" (click)="closeDialog()">Close</button>
</mat-dialog-actions>