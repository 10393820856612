import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AppService } from '@App';
import { CaseStatus, FeatureToggle, SignatureTypeEnum } from '@Enums';
import { HubConnection } from '@microsoft/signalr';
import {
    Account,
    CaseDetails,
    CaseDocument,
    CaseSummaryDisplayMessages,
    Client,
    GenerateDocumentStatus,
    PdfResult,
    SignatureProviderSummary,
    UWResponse,
} from '@Models';
import { CaseDetailsDTO, InterviewDTO } from '@Models/domain/dto';
import { UWWorkbenchUpdateSignalr } from '@Models/uwworkbench';
import {
    AccountSettingsService,
    CaseDocumentsDataService,
    CaseNotificationsService,
    CaseSummaryService,
    ConfirmationDialogService,
    FeatureToggleService,
    InterviewDataService,
    NotificationService,
    NotificationSeverity,
    ReportDownloadService,
    RoutesEnum,
    RoutingService,
    SignalRService,
} from '@Services';
import { SignerService } from '@Services/config/signer.service';
import { UWRequirementsDataService } from '@Underwriting/services';
import { Utils } from '@Utils';
import { CaseSummarySidebarItem } from 'app/account/account-config/account-settings';
import { IConfigService } from 'app/config/iconfigservice';
import { ICaseUserActivity } from 'app/shared/models/ICaseUserActivity';
import * as _ from 'lodash';
import * as moment from 'moment';
import { first, interval, Subject, Subscription } from 'rxjs';
import { SubSink } from 'subsink';

import { ConfirmationDialogMessageMode } from '../components/confirmation-dialog/confirmation-dialog.component';
import { InterviewService } from '../interview.service';
import { MonitoringService } from '../monitor.service';
import { CaseSummaryBillingComponent } from './billing/case-summary-billing.component';
import { CaseSummaryDocumentComponent } from './documents/case-summary-documents.component';
import { CaseSummaryMessagesDialogComponent } from './messages/case-summary-messages.component';
import { IntegrationStatusUpdateNotification } from '@Models';
import CaseSummaryBaseProps from './models/case-summary-base-props';

export type CaseSummarySidebarDisplayItem = CaseSummarySidebarItem & {
    mapkeyValue?: string;
}

@Component({
    selector: 'case-summary',
    host: { 'style': 'flex: 1; height:100%; overflow-x:hidden;background: linear-gradient(to bottom, #ece9e6, #f6f5f3);height:100%' },
    templateUrl: './case-summary.component.html',
    styleUrls: ['./case-summary.component.scss']
})
export class CaseSummaryComponent implements OnInit, OnDestroy {
    @ViewChild(CaseSummaryDocumentComponent) documentComponent: CaseSummaryDocumentComponent;
    @ViewChild(CaseSummaryBillingComponent) billingComponent: CaseSummaryBillingComponent;

    // Base Props
    baseProps: CaseSummaryBaseProps = {
        client: null,
        account: null,
        case: null,
    };

    // Obsolete: Use the values on baseProps, which make passing these to child components easier. We'll want to refactor away from using
    // this individual props.
    client: Client;
    account: Account;
    case: CaseDetailsDTO;

    heartbeatSubscription: Subscription;
    disableRestartInterview = false;
    selectedCaseId: any;
    interviewData: InterviewDTO;
    configData: any;
    clearStorage = true;
    statusChanged: any = null;
    editStatus: boolean;
    showCallLogs = false;
    selectedLogs: any[] = [];
    selectedCaseCallLog: any;
    uwDecision: any;
    previousUrl: any;
    viewStatusEdit = false;
    currentTabIndex = 1;
    interviewLoaded = false;
    timeZone: string;
    canResumeCase = false;
    isCaseProcessing = false;
    signalrConnection: HubConnection;
    caseSocket: WebSocket;
    showPdf = false;
    pdfResult: any;
    page = 1;
    isLoaded = false;
    documentHeader: string;
    isSocketManuallyClosed = false;
    showAgentMessage = false;
    agentWarningMessage: any;
    signatureProviderSummary: SignatureProviderSummary;
    summaryDocumentConfig = {
        interval: null,
        pollRateMs: 3000,
        elapsedMs: 0,
        timeoutMs: 120000
    };
    availableCaseStatuses: any;

    // OBSOLETE: This is the old "Case Presence" and contained a string of users on the case and has been replaced by "ActiveCaseUsers"
    usersInCase: any;
    activeCaseUsers: ICaseUserActivity[];
    myCaseActivity: ICaseUserActivity;
    activeCaseUsersFormatted: string;
    // OBSOLETE::END

    currentMetricTabIndex = 0;
    displayMessages: CaseSummaryDisplayMessages;

    // Feature Toggles
    useMetricsV2 = false;
    uwWorkBenchEnabled = false;
    uwWorkBenchPermission = false;
    sidebarCaseSummaryEnabled = false;
    // Because `sideBarCaseSummaryEnabled` is false, the component will render a specific group of elements.
    // After the toggle loads and the value changes to true, we'll render a different group of elements.
    // However, these elements both do things like load Case Notes. This means we could be triggering API calls unnecessarily
    // simply because we're changing which "view" we're rendering.  This `sideBarFlagLoaded` toggle is to make sure we don't
    // show any elements until we know which path we're going to render, to reduce unnecassry rendering and/or API calls.
    sidebarFlagLoaded = false;
    // Account Settings
    isTransmitEnabled = false;
    // Permissions
    canChangeCaseStatus = false;
    canSetCaseTest = false;
    canViewDetails = false;

    caseSummarySidebarItems: CaseSummarySidebarItem[] = [];
    caseSummarySidebarDisplayItems: CaseSummarySidebarDisplayItem[] = [];

    currentUrlPath: string;
    applicationText = 'Case Details';
    restartText = 'Restart Interview';

    private subs = new SubSink();

    caseDetailsMapkeys = {
        'applicant_name': ['Case_Applicant_FirstName', 'Case_Applicant_MiddleName', 'Case_Applicant_LastName'],
        'applicant_phonenumber': ['Case_Applicant_PhoneNumber'],
        'applicant_age': ['Case_Applicant_Age'],
        'applicant_dob': ['Case_Applicant_DOB'],
        'applicant_gender': ['Case_Applicant_Gender'],
        'applicant_state': ['Case_Applicant_State'],
        'agent_name': ['Case_Agent_FirstName', 'Case_Agent_LastName'],
        'agent_code': ['Case_Agent_AgentNumber'],
        'agent_phonenumber': ['Case_Agent_PhoneNumber']
    };

    public updateStatus: any = [
        { id: '00000000-0000-0000-0000-000000000003', name: 'Done' }
    ];

    public callLogs: any = [];

    mibData: any;
    private caseHeartBeatSub: Subscription;
    private caseUserActivitySub: Subscription;
    private addUserToCaseSub: Subscription;

    private triggerRefreshDocumentsSubject = new Subject<any>();
    onTriggerRefreshDocuments = this.triggerRefreshDocumentsSubject.asObservable();

    constructor(
        private activatedRoute: ActivatedRoute,
        private appService: AppService,
        private caseSummaryService: CaseSummaryService,
        private reportDownloadService: ReportDownloadService,
        public interviewService: InterviewService,
        private routingService: RoutingService,
        private notificationService: NotificationService,
        private signalrService: SignalRService,
        private signerService: SignerService,
        private configService: IConfigService,
        private caseNotificationsService: CaseNotificationsService,
        private confirmationDialogService: ConfirmationDialogService,
        private featureToggleService: FeatureToggleService,
        public dialog: MatDialog,
        private monitorService: MonitoringService,
        private accountSettingService: AccountSettingsService,
        private uwRequirementsDataService: UWRequirementsDataService,
        private caseDocumentsDataService: CaseDocumentsDataService,
    ) {
    }

    //#region Browser Host

    @HostListener('window:beforeunload', ['$event'])
    beforeUnloadHander() {
        this.caseNotificationsService.deactivateUserOnCase(this.client.id, this.selectedCaseId).subscribe();
        this.caseNotificationsService.removeUserFromCase();
        this.unsubscribeAllSubscriptions();

        return undefined;
    }

    //#endregion
    //#region Helpers

    hasUWWorkbenchAccess() {
        return this.canViewDetails && this.uwWorkBenchEnabled && this.uwWorkBenchPermission;
    }

    setAclParams() {
        this.canChangeCaseStatus = this.appService.checkACL('Case Status: Change Case Status', 'W');
        this.canViewDetails = this.appService.checkACL('CaseSummary', 'W');
        this.canSetCaseTest = this.appService.checkACL('Set Case Test', 'W');
    }

    updateCaseSummarySidebarDisplayItems() {
        // Due to async subscriptions, this could be called before the caseDetails are loaded.
        // There's no point processing if there's no mapkeys we could possibly map.
        // We just need to make sure we call this method anytime case or sidebar account settings change.
        if (!this.case?.mapkeys) return;

        this.caseSummarySidebarDisplayItems = this.caseSummarySidebarItems.map(sidebarItem => {
            let mapkeyValue: string = null;

            if (sidebarItem.entityHierarchy.toLowerCase() !== 'header') {
                const value = this.case.mapkeys.find(mapkey => mapkey.name === sidebarItem.entityHierarchy)?.value;
                mapkeyValue = value;
            }

            return {
                ...sidebarItem,
                mapkeyValue
            };
        });
    }

    //#endregion
    //#region SignalR

    signalrHandleConnected(event: any) {
        console.log('SignalR Connected : ' + event);
    }

    signalrHandleIntegrationResponse(event: IntegrationStatusUpdateNotification) {
        if (this.case.id !== event.caseDetailId) // Ignore if not for this case
            return;

        if (event === undefined) {
            console.log('Integration response handler called with out data');
            return;
        }

        this.caseSummaryService.updatedData.next(event);
        this.subscribeCaseDetails();
    }

    signalrHandleUWWorkbenchUpdate(event: UWWorkbenchUpdateSignalr) {
        if (!this.hasUWWorkbenchAccess()) return;
        if (event.caseId !== this.case.id) {
            console.warn(`CaseId's Don't match. signalr: [${event.caseId}] <=> [${this.case.id}] : current page`);
            return;
        }

        const hideGlobalSpinner = true;

        if (event.caseRequirementIds?.length) {
            this.uwRequirementsDataService.reloadCaseRequirements(this.client.code, event.caseId, hideGlobalSpinner);
            this.handleUpdateInterview();
        }
        if (event.noteIds?.length) {
            this.uwRequirementsDataService.reloadNotes(this.client.code, event.caseId, hideGlobalSpinner);
        }
        if (event.caseDocumentIds?.length) {
            this.caseDocumentsDataService.reloadDocuments(event.caseId, hideGlobalSpinner);
            this.caseDocumentsDataService.reloadAttachments(event.caseId, hideGlobalSpinner);
        }
    }

    signalrStarted() {
        this.addUserToCaseSub = this.caseNotificationsService.addUserToCase(this.client.id, this.selectedCaseId).subscribe(_data => {
            console.log('SignalR connection started');
        });
    }

    signalrOnClose() {
        this.caseNotificationsService.removeUserFromCase();
        console.log('SignalR Disconnected ');

        if (!this.isSocketManuallyClosed) {
            console.log('SignalR Reconnecting....');
            this.signalrConnection.start();
        }
    }

    signalrStartError(error: any) {
        console.error(error.message);
    }

    monitorCaseUpdates() {
        // Create HubConnection
        this.signalrConnection = this.signalrService.getCaseSignalRConnection(this.selectedCaseId);
        this.signalrConnection.serverTimeoutInMilliseconds = 1000 * 60 * 15; // 15 minutes

        // Setup Listeners
        this.signalrConnection.on('connected', this.signalrHandleConnected);
        this.signalrConnection.on('integrationResponse', this.signalrHandleIntegrationResponse.bind(this));
        this.signalrConnection.on('underwriterWorkbenchUpdate', this.signalrHandleUWWorkbenchUpdate.bind(this));
        this.signalrConnection.on('documentGenerationComplete', (event) => {
            if (this.case.id !== event.caseId) return;

            this.triggerRefreshDocumentsSubject.next(event.generateDocumentStatus);

        });
        this.signalrConnection.onclose(this.signalrOnClose.bind(this));

        // Start SignalR
        this.signalrConnection.start().then(this.signalrStarted.bind(this)).catch(this.signalrStartError);
    }

    stopMonitoringCaseUpdates() {
        if (this.signalrConnection != null) {
            console.log('SignalR Disconnecting...');
            this.isSocketManuallyClosed = true;
            this.signalrConnection.stop();
        } else if (this.caseSocket != null && this.caseSocket.readyState === this.caseSocket.OPEN) {
            console.log('WebSocket Disconnecting...');
            this.caseSocket.close();
        }
    }

    //#endregion
    //#region Subscriptions

    subscribeFeatureFlags() {
        this.featureToggleService.getFeatureFlag(FeatureToggle.CaseExecutionLog).subscribe(enabled => {
            this.useMetricsV2 = enabled;
        });

        this.featureToggleService.getFeatureFlag(FeatureToggle.ConfigAccountSettingsUWWorkBench).subscribe(enabled => {
            this.uwWorkBenchEnabled = enabled;
            if (this.uwWorkBenchEnabled) {
                this.uwWorkBenchPermission = this.appService.checkACL('Case Summary UW Tab - Full Access', 'W');
            }
        });

        this.featureToggleService.getFeatureFlag(FeatureToggle.GlobalTextInterviewToApplication).subscribe(enabled => {
            if (enabled) {
                this.applicationText = 'Application';
                this.restartText = 'Edit Application';
            }
        });

        this.featureToggleService.getFeatureFlag(FeatureToggle.ConfigAccountSettingsWorkbenchConfiguration).subscribe(enabled => {
            this.sidebarFlagLoaded = true;
            this.sidebarCaseSummaryEnabled = enabled;
            this.subscribeCaseSummarySidebarSettings();
        });
    }

    subscribeActivatedRoute() {
        this.caseNotificationsService.params = this.activatedRoute.snapshot.params;

        this.activatedRoute.params.subscribe(params => {
            this.selectedCaseId = params.caseId;
        });

        this.activatedRoute.data.subscribe(data => {
            this.baseProps.client = this.client = data.client;
            this.baseProps.account = this.account = data.account;
        });
    }

    notifyCasePresence() {
        if (!this.selectedCaseId) return;

        // original "Case Presence"
        this.caseHeartBeatSub = this.caseNotificationsService.sendCaseHeartbeat(this.client.id, this.selectedCaseId).subscribe();

        // new "Case User Activity" presence
        this.caseUserActivitySub = this.caseNotificationsService.activateUserOnCase(this.client.id, this.selectedCaseId, this.routingService.url())
            .subscribe((response: ICaseUserActivity[]) => {
                const myId = this.appService.getUserId().toLowerCase();
                this.activeCaseUsers = response.map(x => ({ ...x, userId: x.userId.toLowerCase() } as ICaseUserActivity)); // make sure we have lowercase userId's for comparison
                this.myCaseActivity = this.activeCaseUsers.find(x => x.userId.toLowerCase() == myId);
                this.activeCaseUsersFormatted = response
                    .filter(x => x.isActive && x.userId.toLowerCase() != myId)
                    .map(x => `${x.userFullName}${x.isEditor ? ' (Editor)' : ''}`)
                    .join(', ')
                    .trim();
            });
    }

    subscribeUsersOnCase() {
        const myId = this.appService.getUserId().toLowerCase();
        this.subs.add(this.caseNotificationsService.getUsersOnCase(this.client.id, this.selectedCaseId).subscribe(data => {
            if (data && data.length > 0) {
                const names = data.filter(user => user.id.toLowerCase() != myId).map(user => user.name).join(', ');
                this.usersInCase = names;
            } else {
                this.usersInCase = null;
            }
        }));
    }

    subscribeCaseMessages() {
        this.caseSummaryService.getCaseMessages(this.selectedCaseId, this.account.id).subscribe(data => {
            this.displayMessages = data;
            this.viewMessages(this.displayMessages.isFirstTime);
        });
    }

    subscribeToCasePresenceInterval() {
        this.notifyCasePresence();

        const heartbeatInterval = this.configService.getConfiguration().heartbeatInterval;
        const intervalValue = parseInt(heartbeatInterval);
        const heartbeatInterval$ = interval(intervalValue);

        this.heartbeatSubscription = heartbeatInterval$.subscribe(() => this.notifyCasePresence());
    }

    subscribeCaseSummarySidebarSettings() {
        if (!this.sidebarCaseSummaryEnabled) return;

        this.subs.add(this.accountSettingService.getAccountSetting(this.account, 'caseSummarySidebar').subscribe(accountSetting => {
            if (Utils.isNullOrWhitespace(accountSetting?.value)) return;

            this.caseSummarySidebarItems = JSON.parse(accountSetting.value) as CaseSummarySidebarItem[];
            this.updateCaseSummarySidebarDisplayItems();
        }));
    }

    subscribeCaseDetails() {
        // TECH DEBT: This API (GET `Interview/{caseId}`) is called from the methods:
        //   - handleUpdateInterview() - Used as callback for underwriter tab and when UWW signalr case requirements are updated
        //   - subscribeCaseDetails()  - Used with `ngOnInit()` and `signalrHandleIntegrationResponse()`
        //   - subscribeInterviewDTO() - Used when changing/saving Case Status
        // We need to understand why we have three redundant API calls and see if we can consolidate them into a single call.

        this.subs.add(this.appService.getData<UWResponse<InterviewDTO>>(`Interview/${this.selectedCaseId}`).subscribe(response => {
            this.interviewData = response.data;
            this.case = { ...response.data.caseDetails };
            this.baseProps = { ...this.baseProps, case: this.case };

            this.canResumeCase = this.interviewService.canResumeCase(this.case.statusId);
            this.currentTabIndex = 0;
            this.interviewLoaded = true;
            this.isShowAgentMessage();
            this.isCaseProcessing = this.caseSummaryService.isCaseProcessing(this.case.statusId, this.case.creationDate);

            if (this.case.data) {
                const caseData = this.case.data.find(m => m.name === 'UWDetails');
                if (caseData) {
                    this.uwDecision = caseData.jsonData;
                }
                this.mibData = this.case.data.find(m => m.name === 'MIB');
            }

            this.setMIBIntegrationData();
            this.updateCaseSummarySidebarDisplayItems();
        }));

        this.subscribeUsersOnCase();
    }

    unsubscribeAllSubscriptions() {
        if (this.heartbeatSubscription) this.heartbeatSubscription.unsubscribe();
        if (this.caseHeartBeatSub) this.caseHeartBeatSub.unsubscribe();
        if (this.addUserToCaseSub) this.addUserToCaseSub.unsubscribe();
        if (this.caseUserActivitySub) this.caseUserActivitySub.unsubscribe();
    }


    subscribeInterviewDTO() {
        // TECH DEBT: This API (GET `Interview/{caseId}`) is called from the methods:
        //   - handleUpdateInterview() - Used as callback for underwriter tab and when UWW signalr case requirements are updated
        //   - subscribeCaseDetails()  - Used with `ngOnInit()` and `signalrHandleIntegrationResponse()`
        //   - subscribeInterviewDTO() - Used when changing/saving Case Status
        // We need to understand why we have three redundant API calls and see if we can consolidate them into a single call.

        this.appService.getData<UWResponse<InterviewDTO>>(`Interview/${this.selectedCaseId}`).subscribe(response => {
            this.interviewData = response.data;
            this.case = { ...response.data.caseDetails };
            this.baseProps = { ...this.baseProps, case: this.case };

            this.canResumeCase = this.interviewService.canResumeCase(this.case.statusId);
            this.updateCaseSummarySidebarDisplayItems();
        });
    }

    //#endregion
    //#region Lifecycle

    ngOnInit() {
        this.subscribeFeatureFlags();
        this.setAclParams();
        this.subscribeActivatedRoute();
        this.subscribeCaseMessages();

        this.appService.getAllConfigData().then((data) => {
            this.configData = data;
            this.monitorCaseUpdates();
            this.availableCaseStatuses = _.filter(this.configData.caseStatus, { isTransition: true });
        });

        this.signerService.getSignatureProviderSummary(this.account.id, this.selectedCaseId).subscribe(res => {
            this.signatureProviderSummary = res;
        });

        this.subscribeCaseDetails();
        this.subscribeUsersOnCase();

        this.previousUrl = this.appService.getPreviousUrl();

        this.subscribeToCasePresenceInterval();
    }

    ngOnDestroy() {
        if (this.previousUrl && this.previousUrl !== RoutesEnum.reports) {
            this.appService.setOrderReports(undefined);
        }

        this.caseNotificationsService.deactivateUserOnCase(this.client.id, this.selectedCaseId).subscribe();

        this.unsubscribeAllSubscriptions();
        this.stopMonitoringCaseUpdates();
        this.subs.unsubscribe();
    }

    //#endregion
    //#region Handlers

    // "Handlers" are for callbacks used in the HTML template

    deactivateMe() {
        this.caseNotificationsService.deactivateUserOnCase(this.client.id, this.selectedCaseId).subscribe();
    }

    viewMessages(viewDialog = true) {
        if (viewDialog && this.displayMessages.messages && this.displayMessages.messages.length > 0) {
            this.dialog.open(CaseSummaryMessagesDialogComponent, {
                width: '800px',
                data: { displayMessages: this.displayMessages.messages },
                disableClose: true
            });
        }
    }

    //#endregion

    handleUpdateInterview() {
        // TECH DEBT: This API (GET `Interview/{caseId}`) is called from the methods:
        //   - handleUpdateInterview() - Used as callback for underwriter tab and when UWW signalr case requirements are updated
        //   - subscribeCaseDetails()  - Used with `ngOnInit()` and `signalrHandleIntegrationResponse()`
        //   - subscribeInterviewDTO() - Used when changing/saving Case Status
        // We need to understand why we have three redundant API calls and see if we can consolidate them into a single call.

        this.appService.getData<UWResponse<InterviewDTO>>(`Interview/${this.selectedCaseId}`).pipe(first()).subscribe(response => {
            this.interviewData = { ...response.data };
            this.case = { ...response.data.caseDetails };
            this.baseProps = { ...this.baseProps, case: this.case };
            this.updateCaseSummarySidebarDisplayItems();
        });
    }

    hasViewPermission(feature) {
        return this.appService.hasViewPermission(feature);
    }

    isShowAgentMessage() {
        this.showAgentMessage = false;
        this.appService.getData(`AccountSettings/${this.account.id}`).subscribe(data => {
            if (data.status === 'success') {
                if (data.data && data.data.length) {
                    const transmissionEnabledSetting = data.data.find(x => x.name === 'transmissionEnabled');

                    if (transmissionEnabledSetting && transmissionEnabledSetting.value?.toLowerCase() === 'true') {
                        this.isTransmitEnabled = true;
                    }

                    const agentWarning = data.data.find(item => item.name === 'agentWarningMessage');
                    if (agentWarning && agentWarning.value && agentWarning.value !== null && agentWarning.value !== '') {
                        this.agentWarningMessage = agentWarning.value;

                        if (this.appService.getIsAgentBehavior()
                            && (this.case.statusId === CaseStatus.PendingInitialReview
                                || this.case.statusId === CaseStatus.OnHold
                                || this.case.statusId === CaseStatus.Submitted)) {
                            this.showAgentMessage = true;
                        }
                    }
                }
            }
        });
    }

    setMIBIntegrationData() {
        // same logic as when integration response is received from socket.
        // eslint-disable-next-line @typescript-eslint/no-this-alias  -- We probably want to use binding so we can remove this eslint disable
        const me = this;
        if (me.mibData) {
            if (me.mibData.jsonData && me.mibData.jsonData.parties) {
                const questionMibMap = {};
                const parentQuestionMap = [];
                for (const eachParty of me.mibData.jsonData.parties) {
                    eachParty.mibCodes.forEach(mibCode => {
                        if (mibCode.mapkeyId && eachParty.relationRoleCode) {
                            parentQuestionMap.push([mibCode.mapkeyId, eachParty.relationRoleCode]);
                        }
                        Object.assign(mibCode, eachParty);
                        delete mibCode.mibCodes;
                        questionMibMap[mibCode.mapkeyId] = questionMibMap[mibCode.mapkeyId] ? questionMibMap[mibCode.mapkeyId] : [];
                        questionMibMap[mibCode.mapkeyId].push(mibCode);
                    });
                }
                me.interviewData.sections.forEach(section => {
                    section.questions.forEach(question => {
                        if (questionMibMap[question.mapkeysId] && questionMibMap[question.mapkeysId].length) {
                            question.mibDetails = questionMibMap[question.mapkeysId];
                        }
                    });
                });
            }
        }
    }

    formatDate(inputDate) {
        const date = new Date(inputDate);
        if (!isNaN(date.getTime())) {
            return date.getUTCMonth() + 1 + '/' + date.getUTCDate() + '/' + date.getUTCFullYear();
        }
    }

    getMapKeysValue(detailName) {
        let returnValue = '';
        const mapkeys = this.case.mapkeys;
        const detailMapKeys = this.caseDetailsMapkeys[detailName];
        const detailAnswers = [];
        if (detailMapKeys && detailMapKeys.length) {
            for (const eachDetailMapKey of detailMapKeys) {
                for (const mapkey of mapkeys) {
                    if (mapkey.name === eachDetailMapKey) {
                        // Also, Gender was being mapped to display text.  Need a generic way to handle mapping constants
                        if (eachDetailMapKey === 'Case_Applicant_DOB') {
                            if (mapkey.value) {
                                const result = moment().diff(mapkey.value, 'years') + ' – ' + this.formatDate(mapkey.value);
                                detailAnswers.push(result);
                            }
                        } else {
                            detailAnswers.push(mapkey.value);
                        }
                        break;
                    }
                }
            }
        }
        returnValue = returnValue + detailAnswers.join(' ');
        return returnValue;
    }

    getStatus(id) {
        if (id && this.configData) {
            return _.find(this.configData.caseStatus, ['id', id]).name;
        } else {
            return '';
        }
    }

    getStatusColor(stausId) {
        switch (stausId) {
            case CaseStatus.PendingInitialReview:
            case CaseStatus.OnHold:
            case CaseStatus.PendingSignature:
                return 'text-warning';
            case CaseStatus.PendingSubmission:
            case CaseStatus.InProgress:
            case CaseStatus.Processing:
                return 'text-info';
            case CaseStatus.Cancelled:
            case CaseStatus.Inactive:
            case CaseStatus.Error:
                return 'text-danger';
            case CaseStatus.Done:
                return 'text-success';
            case CaseStatus.New:
            case CaseStatus.Submitted:
                return 'text-secondary';
            default:
                break;
        }
    }

    getStatusIcon(stausId) {
        switch (stausId) {
            case CaseStatus.New:
                return 'fa fa-hourglass';
            case CaseStatus.Submitted:
                return 'fa fa-hourglass-start';
            case CaseStatus.InProgress:
            case CaseStatus.PendingInitialReview:
                return 'fa fa-hourglass-half';
            case CaseStatus.OnHold:
                return 'fa fa-pause-circle';
            case CaseStatus.PendingSignature:
                return 'fa fa-file-signature';
            case CaseStatus.Inactive:
                return 'fa fa-stop-circle';
            case CaseStatus.Cancelled:
                return 'fa fa-ban';
            case CaseStatus.Error:
                return 'fa fa-exclamation-circle';
            case CaseStatus.Done:
                return 'fa fa-hourglass-end';
            case CaseStatus.PendingSubmission:
                return 'fa fa-pencil-alt';
            case CaseStatus.Processing:
                return 'fas fa-cogs';
            default:
                break;
        }
    }

    restartInterview() {
        this.logCaseNumberAndDescriptionToMonitorService('Restarting interview from Case Summary');

        this.routingService.navigateToRoute(RoutesEnum.interviewRestart, {
            clientCode: this.client.code,
            accountCode: this.account.code,
            versionNumber: this.account.versionNumber,
            caseId: this.case.id
        });
    }

    logCaseNumberAndDescriptionToMonitorService(messageDescription: string) {
        const interviewData = this.interviewService.getInterviewData();
        let caseNumber = 'No CaseNumber due to the interview not being created yet';

        if (interviewData && interviewData.caseDetails && interviewData.caseDetails.caseNumber) {
            caseNumber = interviewData.caseDetails.caseNumber;
        }
        this.monitorService.logEvent(`${caseNumber} - ${messageDescription}`);
    }

    async changeCaseStatus() {
        let saveStatus = true;

        if (this.statusChanged === CaseStatus.Done) {
            const docsPendingSignature: CaseDocument[] = await this.caseSummaryService.getDocumentsPendingSignature(this.selectedCaseId);

            if (docsPendingSignature && docsPendingSignature.length > 0 && this.signatureProviderSummary.signatureType === SignatureTypeEnum.Email) {
                const documentNames = docsPendingSignature.map(x => `<li>${x.remoteName}</li>`).join('');
                const message = `<p>The following documents require agent signatures and/or acknowledgement before it can be marked Done:</p>
                    <ul>${documentNames}</ul>
                    <p>Signature requests will be sent, and this case can be marked done once signatures are received.  Until signatures are received, this case will remain in it's current status.</p>`;

                this.confirmationDialogService.open({
                    title: 'Request Signatures?',
                    message: message,
                    messageMode: ConfirmationDialogMessageMode.Html,
                    showCancel: true,
                    okLabel: 'Accept',
                    onOk: () => {
                        this.saveStatus(true);
                    }
                });

                saveStatus = false;
            }
        }

        if (saveStatus) {
            this.saveStatus();
        }

        this.viewStatusEdit = false;
    }

    saveStatus(refreshDocuments = false) {
        this.appService.postData(`Interview/${this.selectedCaseId}/CaseStatus/${this.statusChanged}`, this.case, true, false).subscribe(
            data => {
                if (data.status === 'success') {
                    this.appService.showMsg('success', data.message);
                    this.subscribeInterviewDTO();
                    this.viewStatusEdit = false;
                    this.case.statusId = this.statusChanged;

                    if (refreshDocuments) {
                        this.documentComponent.refreshDocuments();
                    }

                    if (this.isTransmitEnabled) {
                        if (this.statusChanged === CaseStatus.Done && this.interviewData.caseDetails.transmitDateTime) {
                            this.confirmationDialogService.open({
                                title: 'Transmit Case?',
                                message: 'This case has already been transmitted successfully. Are you sure you want to transmit the case again?',
                                messageMode: ConfirmationDialogMessageMode.Html,
                                showCancel: true,
                                okLabel: 'Transmit',
                                onOk: () => {
                                    this.transmitCase();
                                }
                            });
                        } else if (this.statusChanged === CaseStatus.Done && (
                            this.interviewData.caseDetails.transmitDateTime == undefined
                            || this.interviewData.caseDetails.transmitDateTime == null)) {
                            // If CaseStatus is done and it has never been transmitted go ahead and transmit the case...
                            this.transmitCase();
                        }
                    }

                } else {
                    this.notificationService.showNotification({
                        severity: NotificationSeverity.Error,
                        message: data.message
                    });
                }
            }
        );
    }

    transmitCase() {
        this.appService.postData(`CaseDocuments/${this.selectedCaseId}/CaseReTransmit`, false, false).subscribe(
            data => {
                this.appService.showMsg('success', data.message);
            }
        );
    }

    changeStatus(option) {
        this.statusChanged = option.value;
    }

    downloadCaseXml() {
        const url = `Interview/${this.selectedCaseId}/xml`;
        this.reportDownloadService.downloadReport('application/xml', url);
    }

    downloadCaseJson() {
        const url = `Interview/${this.selectedCaseId}/json`;
        this.reportDownloadService.downloadReport('application/json', url);
    }

    checkACL(permissionType, redirect?) {
        return this.appService.checkACL('Case', permissionType, redirect);
    }

    summaryDocumentStartPolling(documentStatus: GenerateDocumentStatus) {
        this.summaryDocumentStopPolling();

        console.log('case summary: start polling.');

        this.summaryDocumentConfig.elapsedMs = 0;

        this.summaryDocumentConfig.interval = setInterval(() => {
            this.caseSummaryService.getCaseDocumentFromQueue(documentStatus.caseIntegrationQueueId)
                .subscribe((pdfResult: PdfResult) => {
                    console.info('case summary: document complete.');

                    this.page = 1;
                    this.pdfResult = pdfResult.objectUrl;
                    this.showPdf = true;
                    this.isLoaded = true;
                    this.documentHeader = 'Application Documents';
                    this.isLoaded = true;

                    this.summaryDocumentStopPolling();
                }, (error) => {
                    if (error.status === 406 && this.summaryDocumentConfig.elapsedMs < this.summaryDocumentConfig.timeoutMs) {
                        console.info(`case summary: waiting for document to be complete. [${this.summaryDocumentConfig.elapsedMs} ms]`);

                        // keep polling
                        this.summaryDocumentConfig.elapsedMs += this.summaryDocumentConfig.pollRateMs;
                    } else {
                        this.appService.showMsg('error', 'No documents generated.', false);
                        this.showPdf = false;
                        this.isLoaded = true;

                        this.summaryDocumentStopPolling();
                    }
                }
                );
        }, this.summaryDocumentConfig.pollRateMs);
    }

    summaryDocumentGenerate(templateRef) {
        if (!this.pdfResult || this.pdfResult === null) {
            this.monitorService.logEvent(`${this.case.caseNumber} - Viewed Summary Document`);
            const caseDetails: CaseDetails = { id: this.selectedCaseId, clientId: this.client.id, accountId: this.account.id };
            this.caseSummaryService.createSummaryDocument(caseDetails).subscribe({
                next: (result) => {
                    this.showPdf = true;
                    this.isLoaded = false;
                    this.documentHeader = 'Generating Document';

                    this.summaryDocumentStartPolling(result);

                    const calculateWidth = this.isDesktop() ? '55%' : '90%';
                    this.dialog.open(templateRef, {
                        maxHeight: '90%',
                        minWidth: calculateWidth,
                        minHeight: '90%',
                        maxWidth: null
                    });
                },
                error: (error) => {
                    this.appService.showMsg('error', 'No documents generated.');
                    console.error(error);
                }
            });
        } else {
            const calculateWidth = this.isDesktop() ? '55%' : '90%';
            this.dialog.open(templateRef, {
                maxHeight: '90%',
                minWidth: calculateWidth,
                minHeight: '90%'
            });
        }
    }

    closeDialog() {
        this.dialog.closeAll();
    }

    summaryDocumentDialogClosed() {
        this.summaryDocumentStopPolling();
    }

    summaryDocumentStopPolling() {
        if (this.summaryDocumentConfig.interval != null) {
            console.log('case summary: stopped polling.');

            clearInterval(this.summaryDocumentConfig.interval);
            this.summaryDocumentConfig.interval = null;
        }
    }

    nextPage() {
        this.page++;
    }

    prevPage() {
        this.page--;
    }

    getZoom() {
        return this.isDesktop() ? "100%" : "auto";
    }

    isDesktop() {
        return window.innerWidth > 1650;
    }

    closePdf() {
        this.showPdf = false;
    }
}
