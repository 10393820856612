import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';

import { AppService } from '../../app.service';
import { IConfigService } from '../../config/iconfigservice';
import { BaseService } from '../base.service';

@Injectable()
export class SectionApiSettingsService extends BaseService {

    publicApiBaseUrl: string;

    constructor(public appService: AppService, public configService: IConfigService, public httpClient: HttpClient) {
        super(appService, configService, httpClient);
        this.publicApiBaseUrl = this.configService.getConfiguration().publicApiUrl;
    }

    saveSectionApiSettings(accountInfo, sectionId, objectProperty) {
        const clientId = this.appService.getClientId('');
        const url = `${this.publicApiBaseUrl}client/${clientId}/account/${accountInfo.id}/${accountInfo.versionNumber}/section/${sectionId}/apisettings`;

        return super.postData(url, objectProperty, clientId);
    }

    getSectionApiSettings(accountInfo, sectionId){
        const clientId = this.appService.getClientId('');
        const url = `${this.publicApiBaseUrl}client/${clientId}/account/${accountInfo.id}/${accountInfo.versionNumber}/section/${sectionId}/apisettings`;

        return super.getData<any>(url, clientId);
    }

}
