import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MrsButtonsModule } from '../mrs-buttons';
import { MrsChipListComponent } from './mrs-chip-list.component';

@NgModule({
    imports: [
        CommonModule,
        MatTableModule,
        MatPaginatorModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatIconModule,
        MatSortModule,
        MatButtonModule,
        MatInputModule,
        MatMenuModule,
        MrsButtonsModule,
        MatChipsModule
    ],
    exports: [MrsChipListComponent],
    declarations: [MrsChipListComponent]
})
export class MrsChipListModule {
}
