import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { BaseInterviewFormFieldComponent, BaseFormFieldServices } from '../base-interview-form-field/base-interview-form-field.component';

@Component({
    selector: 'email-answer',
    templateUrl: './email-answer.component.html',
    styleUrls: ['./email-answer.component.scss']
})
export class EmailAnswerComponent extends BaseInterviewFormFieldComponent implements OnInit {
    constructor(public services: BaseFormFieldServices) {

        super(services);
    }

    ngOnInit() {
        super.initialize({
            inputType: 'text',
            additionalValidators: [
                Validators.email
            ]
        });
    }
}
