import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CaseDocumentsDTO } from '@DTOs';
import { CaseDocumentTypes } from '@Enums';
import { Account, Client } from '@Models';
import { CaseDocumentsDataService, NotificationService, NotificationSeverity } from '@Services';
import { SubSink } from 'subsink';

export type TiffProps = {
    client: Client;
    account: Account;
    caseId: string;
    caseDocumentId: string;
    caseDocument: CaseDocumentsDTO;
    caseDocumentType: CaseDocumentTypes;
    // Maybe case document object?
}


@Component({
    selector: 'tiff-viewer',
    templateUrl: './tiff-viewer.component.html',
    styleUrls: ['./tiff-viewer.component.scss']
})
export default class TiffViewer implements OnInit, OnDestroy {
    @Input() src: string;
    @Input() props: TiffProps;

    base64Prefix = 'data:image/png;base64,';

    isLoading = false;

    currentPageIndex = 0;
    currentPage = 1;
    totalPages = 0;
    pagesPerRequest = 5; // number of pages to render per request
    loadedPages: { [page: string]: string } = {};

    subs = new SubSink();

    constructor(
        protected caseDocumentsDataService: CaseDocumentsDataService,
        protected notificationService: NotificationService
    ) { }

    //#region Helpers

    isValidPageChange(nextPage: number) {
        if (1 <= nextPage && nextPage <= this.totalPages)
            return true;

        this.notificationService.showNotification({
            severity: NotificationSeverity.Error,
            message: `Invalid page '${nextPage}'. Page must be between 1 and ${this.totalPages}`
        });

        return false;
    }

    loadPages(pageIndexesToLoad: number[]) {
        const { client, account, caseId, caseDocument } = this.props;

        // Nothing to load
        if (!pageIndexesToLoad.length)
            return;

        const startIndex = pageIndexesToLoad[0];
        // DEV NOTE: this is simple range picker. If the first index is less than the current page, use twice the radius to assume someone 'jumped'
        // to a deep page. (eg. 5 pages before + current + 5 pages after). If we switch to just loading the provided CSV of page indexes, 
        // this won't be necessary. Instead, we'll just want to make sure we request enough pages to make it worth while.
        const numberOfPages = startIndex < this.currentPageIndex ? (this.pagesPerRequest * 2) + 1 : this.pagesPerRequest + 1;


        if (this.isLoading) {
            this.notificationService.showNotification({
                severity: NotificationSeverity.Warning,
                message: `Currently Loading pages. Please wait for current request to finish before loading more pages.`
            });
            return;
        }

        this.isLoading = true;
        this.subs.add(this.caseDocumentsDataService.getTiffCaseDocumentAsJpg({
            clientCode: client.code,
            accountCode: account.id,
            caseId: caseId,
            caseDocumentId: caseDocument.id,
            startIndex,
            numberOfPages,
            pageIndexesToLoad,
        }).subscribe(tiffToJpg => {
            this.isLoading = false;

            this.loadedPages = { ...this.loadedPages, ...tiffToJpg.pages };
            this.totalPages = tiffToJpg.totalPages;

            // If we just received the current page, set the src
            if (this.currentPageIndex in tiffToJpg.pages) {
                this.src = `${this.base64Prefix}${this.loadedPages[this.currentPageIndex]}`;
            }
        }));
    }

    pageIndexesToLoad() {
        // For now, only load pages if we hit an empty page
        if (this.currentPageIndex in this.loadedPages)
            return [] as number[];

        // Find all empty pages within range of current page. When the page first loads, totalPages will always be zero until we get 
        // our first API response. We'll ask the API for the first 'x' pages. It will properly return only what's available.
        const finalPageIndex = this.totalPages - 1;
        const startIndexRange = this.currentPageIndex - this.pagesPerRequest;
        const endIndexRange = this.currentPageIndex + this.pagesPerRequest;
        const startIndex = startIndexRange < 0 ? 0 : startIndexRange;
        // Stay within total pages. Default to `pages per request` if total pages is unknown.
        let endIndex = endIndexRange > finalPageIndex ? finalPageIndex : endIndexRange;
        endIndex = endIndex < 0 ? (this.pagesPerRequest - 1) : endIndex;

        // Get empty pages between 'x' radius of pages. eg. [n-x, ..., n-1, n, n+1, ..., n+x]
        const pageIndexesToLoad = [] as number[];
        for (let pageIndex = startIndex; pageIndex <= endIndex; pageIndex++) {
            if (!(pageIndex in this.loadedPages))
                pageIndexesToLoad.push(pageIndex);
        }

        // DEV NOTE: If we want to be more efficent, we should make sure any request to load pages has a minimum number of pages to return.
        // Because each request to convert pages is expensive (the API has to download the TIFf an convert it), we want to make
        // sure we aren't making API requests for 1 or 2 pages but always a minimum of, say, 5 pages.
        // Here we could check the total pages to load and if it's below a threshold, allow the "pages to load" to go beyond the
        // standard "pages per request" range and search all unloaded pages for empty pages to add to the request.

        return pageIndexesToLoad;
    }

    setCurrentPage(nextPage: number) {
        this.currentPage = nextPage;
        this.currentPageIndex = this.currentPage - 1;

        if (this.currentPageIndex in this.loadedPages)
            this.src = `${this.base64Prefix}${this.loadedPages[this.currentPageIndex]}`;

        const pagesToLoad = this.pageIndexesToLoad();
        this.loadPages(pagesToLoad);
    }

    //#endregion
    //#region Lifecycle

    ngOnInit() {
        this.setCurrentPage(1);
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    // #endregion
    //#region Handlers

    handlePageChange(nextPageVal: number | string) {
        const nextPage = Number(nextPageVal);

        if (!this.isValidPageChange(nextPage))
            return;

        this.setCurrentPage(nextPage);
    }

    //#endregion
}
