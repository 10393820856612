<button
  *ngIf="isSubmitButton"
  [disabled]="disabled"
  type="submit"
  color="primary" mat-stroked-button aria-label="Example icon button with a open in new tab icon" (click)="onClick.emit($event)">
    <span class="material-icons">{{iconName}}</span>
    <span class="inner-text-margin">{{buttonLabel}}</span>
</button>
<button
*ngIf="!isSubmitButton"
[disabled]="disabled"
type="button"
color="primary" mat-stroked-button aria-label="Example icon button with a open in new tab icon" (click)="onClick.emit($event)">
  <span class="material-icons">{{iconName}}</span>
  <span class="inner-text-margin">{{buttonLabel}}</span>
</button>
