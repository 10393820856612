import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UWRequirementActionEvent, UWRequirementTableData } from '@Underwriting/models';
import { UWRequirementTableActions } from '../uw-requirements-expand-table';

@Component({
    selector: 'uw-requirements-actions-item-documents',
    templateUrl: './uw-requirements-actions-item-documents.component.html',
    styleUrls: ['./uw-requirements-actions-item-documents.component.scss'],
})
export default class UWRequirementsActionsItemDocumentComponent {
    @Input() caseRequirement: UWRequirementTableData;
    @Output() onMenuAction = new EventEmitter<UWRequirementActionEvent>();

    public get UWRequirementTableActions() {
        return UWRequirementTableActions;
    }

    handleMenuAction() {

        const actionEvent: UWRequirementActionEvent = {
            action: UWRequirementTableActions.AttachDocument,
            data: this.caseRequirement
        };
        this.onMenuAction.emit(actionEvent);
    }
}