import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot, CanActivateChild } from '@angular/router';
import { AppService } from '../../app.service';
import { RoutingService } from '../routing.service';

@Injectable()
export class RedirectGuard implements CanActivateChild {

    constructor(
    private appService: AppService,
    private routingService: RoutingService
    ) { }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const redirectTo = localStorage.getItem('uwpipeline.redirectTo');

        if (redirectTo) {
            localStorage.removeItem('uwpipeline.redirectTo');
            this.routingService.navigateToUrl(redirectTo);
        }
        
        return true;
    }


}
