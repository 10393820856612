<h1 mat-dialog-title >Add a Payment Method</h1>
<div mat-dialog-content class="payment">
  <div [hidden]="hasError" class="uwp-snackbar-plus">
    <mat-error id="error-message" class="uwp-snackbar--error rounded"></mat-error>
  </div>
  <form id="payment-form">
    <div id="payment-element">
      <!-- Elements will create form elements here -->
    </div><button [hidden]="true" #Submit id="Submit" mat-button color="primary" class="mr-1 material-default">
      Submit
    </button>
  </form>
</div>
<mat-dialog-actions align="end">
  <button id="closeButton" mat-button id="cancelButton" color="basic" class="mr-1 material-default" mat-dialog-close>
    CANCEL
  </button>
  <button onclick="Submit.click()" mat-button color="primary" class="mr-1 material-default">
    Submit
  </button>
</mat-dialog-actions>