import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MultiSelectModel } from 'app/components/multi-select-dropdown-list/models/MultiSelectModel.interface';
import { MultiSelectDropdownListComponent } from 'app/components/multi-select-dropdown-list/multi-select-dropdown-list.component';
import { MrsColor, StorageKeysEnum } from 'app/enums';
import { ActionsEntity, TableColumnDefinition } from 'app/shared/components/mrs-table/models/table-models';
import * as $ from 'jquery';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SubSink } from 'subsink';

@Component({
    selector: 'manage-users-table',
    templateUrl: './manage-users-table.component.html',
    styleUrls: ['./manage-users-page.component.scss']
})
export class ManageUsersTableComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
    private subs = new SubSink();
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
    @ViewChild(MultiSelectDropdownListComponent) selectList: MultiSelectDropdownListComponent;
    @Input() data: any;
    @Input() displayedColumns: TableColumnDefinition[];
    @Input() searchableFields: string[];
    @Input() showClientFilter = false;
    @Input() clients: MultiSelectModel[];
    @Input() initialSort: string;
    @Input() addRecordAction: ActionsEntity;
    @Output() tableEvent = new EventEmitter<any>();

    private hasInactiveUsersSub = new Subject<boolean>();
    hasInactiveUsers$ = this.hasInactiveUsersSub.asObservable();

    selectedClientStorageKey = StorageKeysEnum.SelectedClients;
    searchValue = '';
    mrsDataSource = new MatTableDataSource<any>();
    displayedColumnsDef: string[];
    showActionsColumn = false;

    filterValue = '';
    showInactiveUsers = true;
    hasInactiveUsers: boolean;
    showInactiveUsersFormGroup: UntypedFormGroup;
    globalFilter: any = {
        clientIds: [],
        showInactiveUsers: false
    };

    constructor(private fb: UntypedFormBuilder) {
        this.showInactiveUsersFormGroup = fb.group({
            hasInactiveUsersCtrl: false
        });
    }
    ngAfterViewInit(): void {
        this.showHideInactiveUsers();
    }

    setStyles() {
        setTimeout(() => {
            const elementCollection = Array.from(document.querySelectorAll('.name-line'));
            elementCollection?.forEach(item => {
                if (item.innerHTML.includes('(inactive)')) {
                    item.closest('tr').style.backgroundColor = MrsColor.DisabledTableColumn;
                    const originalHtml = item.innerHTML;
                    const newHtml = originalHtml.replace('(inactive)', `<span style="color:${MrsColor.BrandRedAccent};">(inactive)</span>`);
                    item.innerHTML = newHtml;
                }
            });
        }, 0);
    }

    ngOnChanges(changes: SimpleChanges): void {
        console.log('ngOnChanges');
        if (changes.data?.currentValue !== changes.data?.previousValue && changes.data?.currentValue !== null) {
            this.setData();
            this.setStyles();
        }
    }

    ngOnInit() {
        $('#sidenav').removeClass('hidenav');
        this.subs.sink = this.showInactiveUsersFormGroup.valueChanges
            .pipe(
                tap(() => {
                    const hasInactiveUsers = this.data.some((user: { disabled: boolean; }) => user.disabled);
                    this.hasInactiveUsersSub.next(hasInactiveUsers);
                })
            )
            .subscribe(() => this.showHideInactiveUsers());

        this.globalFilter.searchableFields = this.searchableFields;
    }

    setData() {
        this.hasInactiveUsers = this.data.filter(x => x.disabled)?.length > 0;
        this.hasInactiveUsersSub.next(this.hasInactiveUsers);
        this.mrsDataSource.sortingDataAccessor = (item, property) => {
            const def = this.displayedColumns.find(x => x.def === property);

            if (def.sortFields && def.sortFields.length > 0) {
                let val = '';
                def.sortFields.forEach(element => {
                    val += item[element];
                });

                return val;
            } else {
                return item[property];
            }
        };

        const actionColumn = this.displayedColumns.find(x => x.isAction);
        if (actionColumn) {
            this.data.forEach(element => {
                actionColumn.actions.forEach(action => {
                    if (action.permission) {
                        this.showActionsColumn = true;
                    }

                    let showAction = true;
                    action.conditions?.forEach(condition => {
                        if (element[condition.showProperty] !== condition.showValue) {
                            showAction = false;
                        }
                    });

                    element[action.actionType] = showAction;
                });
            });
        }

        this.mrsDataSource.paginator = this.paginator;
        this.mrsDataSource.sort = this.sort;
        this.mrsDataSource.data = this.data;

        this.mrsDataSource.filterPredicate = this.tableFilter();
        if (!this.showActionsColumn) {
            this.displayedColumns.splice(this.displayedColumns.indexOf(actionColumn), 1);
        }
        this.displayedColumnsDef = this.displayedColumns.map(x => x.def);
        this.updateFilteredCases();
    }

    updateFilteredCases() {
        this.globalFilter.filterValue = this.filterValue.trim().toLowerCase();
        this.mrsDataSource.filter = JSON.stringify(this.globalFilter);
        this.setStyles();
    }

    tableFilter(): (data: any, filter: any) => boolean {
        return function (data, filter,): boolean {
            const globalFilter = JSON.parse(filter);
            let dataFound = false;
            globalFilter?.searchableFields?.forEach(field => {
                if (data[field] && data[field]?.toLowerCase().indexOf(globalFilter.filterValue) !== -1) {
                    dataFound = true;
                }
            });
            let clientIdFound = false;
            if ((globalFilter.clientIds?.includes('00000000-0000-0000-0000-0000000000000') || globalFilter.clientIds?.length === 0)) {
                clientIdFound = true;
            } else {
                clientIdFound = globalFilter.clientIds.some(r => data.clientIds.indexOf(r) >= 0);
            }

            let showInactiveUesr = true;
            if (!globalFilter.showInactiveUsers && data.disabled) {
                showInactiveUesr = false;
            }

            return dataFound && clientIdFound && showInactiveUesr;
        };
    }

    showHideInactiveUsers() {
        const selected = this.showInactiveUsersFormGroup.get('hasInactiveUsersCtrl').value;

        this.globalFilter.showInactiveUsers = selected;
        this.updateFilteredCases();
    }

    filterData(selectedClients: string[]) {
        const clientIds = selectedClients;
        this.globalFilter.clientIds = clientIds;
        this.updateFilteredCases();
    }

    tableAction(columnAction, selectedRecord) {
        this.tableEvent.emit(
            {
                action: columnAction,
                record: selectedRecord
            }
        );
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

}
