import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { InterviewService } from '../../interview.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { CaseNotificationsService, FeatureToggleService } from '../../services';
import { FeatureToggle } from 'app/enums';

@Component({
    selector: 'interview-close',
    templateUrl: 'interview-terminate.component.html',
    styleUrls: ['../interview.component.scss']
})
export class InterviewTerminateComponent implements OnInit {

    interviewTerminateForm: any;
    accountInfo: any;
    clickSource: any;
    exitText = 'Interview';
    discardText = 'Discard Interview';

    constructor(
        public dialogRef: MatDialogRef<InterviewTerminateComponent>,
        public interviewService: InterviewService,
        public caseNotificationsService: CaseNotificationsService,
        private featureToggleService: FeatureToggleService,
        public dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit() {
        const isCaseCreated = this.interviewService.getInterviewData().caseDetails;
        if (!isCaseCreated) {
            this.dialogRef.close(this.data.clickSource);
        }

        this.accountInfo = this.data.accountInfo;
        if (!this.accountInfo) {
            this.accountInfo = this.interviewService.account;
        }

        this.clickSource = this.data.clickSource;
        this.interviewService.clickSource = this.clickSource;
        this.interviewTerminateForm = new UntypedFormGroup({
            terminateMessage: new UntypedFormControl('', [Validators.required])
        });

        this.featureToggleService.getFeatureFlag(FeatureToggle.GlobalTextInterviewToApplication).subscribe(result => {
            if (result){
                this.exitText = 'Application';
                this.discardText = 'Discard Application';
            }
        });
    }

    close() {
        this.dialogRef.close();
    }

    submitTerminateReason() {
        if (this.interviewTerminateForm.valid) {
            this.interviewService.autoSaveInterview(this.accountInfo.id, 'TerminateInterview', this.interviewTerminateForm.value.terminateMessage, this.clickSource);
            this.interviewService.flushInterviewMetrics();
            this.dialogRef.close(this.clickSource);
        }
    }

    cancelTerminate() {
        this.close();
        this.interviewService.clearCancelActionButton();
    }
}
