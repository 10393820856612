import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from '@App';
import { User, UserRole } from '@Models';

import { IConfigService } from '../../config/iconfigservice';
import { BaseService } from '../base.service';

@Injectable()
export class GlobalService extends BaseService {

    publicApiBaseUrl: string;
    featureToggles: any;

    constructor(public appService: AppService, public configService: IConfigService, public httpClient: HttpClient) {
        super(appService, configService, httpClient);
        this.publicApiBaseUrl = this.configService.getConfiguration().publicApiUrl;
    }

    flushPublicApiCache() {
        const clientId = this.appService.getClientId('');
        const url = `${this.publicApiBaseUrl}global/config/cache`;

        return super.deleteData<boolean>(url, clientId);
    }

    flushFunctionCache() {
        const clientId = this.appService.getClientId('');
        const url = `${this.publicApiBaseUrl}global/functions/cache`;

        return super.deleteData<boolean>(url, clientId);
    }
    
    flushSdkApiCache() {
        const sdkApiBaseUrl = this.configService.getConfiguration().sdkApiUrl;
        const clientId = this.appService.getClientId('');
        const url = `${sdkApiBaseUrl}cache`;

        return super.deleteData<boolean>(url, clientId);
    }

    resetShardMap() {
        const clientId = this.appService.getClientId('');
        const url = `${this.publicApiBaseUrl}global/resetShardMap`;

        return super.deleteData<boolean>(url, clientId);
    }

    getAllAssignees() {
        const url = `${this.publicApiBaseUrl}global/assignees`;
        return super.getData<User[]>(url, null, true);
    }

    getUserRolesWithPermission(permissionId: string) {
        const url = `${this.publicApiBaseUrl}global/roles/${permissionId}`;
        return super.getData<UserRole[]>(url, null, true);
    }

    grantAdminAccess(clientId: string) {
        const url = `${this.publicApiBaseUrl}user/grantaccess/${clientId}`;
        return super.getData<boolean>(url);
    }
}
