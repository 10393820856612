import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Injectable()
export class ObservableService {
    public onQuestionBlur: EventEmitter<any> = new EventEmitter<any>();
    public onMapKeysUpdated: EventEmitter<any> = new EventEmitter<any>();
    public onCaseDataUpdated: EventEmitter<any> = new EventEmitter<any>();
    public triggerMergeMapKeys: EventEmitter<any> = new EventEmitter<any>();
    public triggerConstantMapkeyVariantUpdate: EventEmitter<any> = new EventEmitter<any>();

    public onTriggerUpdateDynamicText: CompoundEventEmitter = new CompoundEventEmitter([
        this.onQuestionBlur,
        this.onMapKeysUpdated,
        this.onCaseDataUpdated
    ]);

    public onTriggerUpdateLists: CompoundEventEmitter = new CompoundEventEmitter([
        this.onMapKeysUpdated
    ]);
    constructor() { }
}

@Injectable()
export class CompoundEventEmitter extends EventEmitter<any> implements OnDestroy {
    subscriptions: Subscription[] = [];

    constructor(children: EventEmitter<any>[]) {
        super();

        this.initializeChildSubscriptions(children);
    }

    ngOnDestroy() {
        if (this.subscriptions != null) {
            this.subscriptions.forEach(s => s.unsubscribe());
        }
    }

    initializeChildSubscriptions(children: EventEmitter<any>[]) {
        if (children != null) {
            children.forEach(ee => {
                // When any of our children emit, then emit ourselves.
                this.subscriptions.push(
                    ee.subscribe(x => this.next(true))
                );
            });
        }
    }
}
