import { Component, OnInit, ViewChild, Input, SimpleChanges, Inject } from '@angular/core';
import { AppService } from '../../../app.service';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, NgForm, Validators, UntypedFormArray } from '@angular/forms';
import * as _ from 'lodash';

import { Account, AccountBillingEvent, BillingEventUsage } from '../../../models';
import { AccountDataService, ConfirmationDialogService } from '../../../services';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'account-billing',
    host: { 'class': 'd-flex flex-row flex-fill' },
    templateUrl: './account-billing.component.html',
    styleUrls: ['./account-billing.component.scss']
})
export class AccountBillingComponent implements OnInit {
    @Input() accountInfo: Account;

    billingForm: UntypedFormGroup;
    isEditMode = false;
    globalBillingEventTypes: any = [];
    configData: any = [];

    accountBillingEvents: AccountBillingEvent[] = [];
    billingEventUsage: BillingEventUsage[] = [];

    constructor(
        private appService: AppService,
        private accountDataService: AccountDataService,
        public dialog: MatDialog,
        private _fb: UntypedFormBuilder,
        private confirmationService: ConfirmationDialogService
    ) {
    }

    async ngOnInit() {
        this.configData = await this.appService.getAllConfigData();
        this.globalBillingEventTypes = this.configData['billingEventTypes'];


        const [billingEvents, billingUsage] = await Promise.all([
            this.accountDataService.getAccountBillingEvents(this.accountInfo.id),
            this.accountDataService.getAccountBillingEventUsage(this.accountInfo.id)
        ]);

        this.billingEventUsage = billingUsage;
        this.setAccountBillingEvents(billingEvents);
    }

    createFormData() {
        this.billingForm = this._fb.group({
            accountId: [this.accountInfo.id, Validators.required],
            clientId: [this.accountInfo.clientId, Validators.required],
            billingDetails: this._fb.array([])
        });

        const control = <UntypedFormArray>this.billingForm.get('billingDetails');

        for (const billingEvent of this.accountBillingEvents) {
            control.push(this.createItem(billingEvent));
        }
    }

    createItem(billingEvent: any): UntypedFormGroup {
        let usageText = '';
        if (billingEvent.usageList && billingEvent.usageList.length > 0) {
            usageText = (billingEvent.usageList || []).map(usage => `[${usage.type}] ${usage.name} - ${usage.actionName}`).join('\n');
        }

        return this._fb.group({
            id: [billingEvent.id],
            code: [billingEvent.code],
            sequence: [billingEvent.sequence],
            name: [billingEvent.name, Validators.required],
            billingEventId: [billingEvent.billingEventId],
            amount: [billingEvent.amount, Validators.compose([Validators.required, Validators.pattern(/^(\d+|\d+\.\d+)$/)])],
            accountId: [this.accountInfo.id],
            clientId: [this.accountInfo.clientId],
            billingEventTypeId: [billingEvent.billingEventTypeId, Validators.required],
            usageList: [billingEvent.usageList],
            usageText: [usageText]
        });
    }

    checkACL(permissionType, redirect?) {
        return this.appService.checkACL('Billing', permissionType, redirect);
    }

    addBillingEvent() {
        const billingEventGroup = this.createItem({
            id: undefined,
            code: '',
            sequence: undefined,
            name: undefined,
            billingEventId: undefined,
            amount: 0,
            billingEventTypeId: '00000000-0000-0000-0000-000000000001'
        });

        const formArray = <UntypedFormArray>this.billingForm.get('billingDetails');
        formArray.push(billingEventGroup);
    }

    removeBillingEvent(index: number, control: UntypedFormControl) {
        this.confirmationService.open({
            title: control.value.name,
            message: `Are you sure you want to remove this event?`,
            showCancel: true,
            onOk: () => {
                const formArray = <UntypedFormArray>this.billingForm.get('billingDetails');
                formArray.removeAt(index);
            }
        });
    }

    getAccountBillingEvents() {
        const url = `AcctBilling/${this.accountInfo.id}`;
        this.appService.getData(url).subscribe(data => {
            if (data.status === 'success') {
                this.setAccountBillingEvents(data.data);
            }
        });
    }

    viewAccountBillingEventUse(control: UntypedFormControl) {
        this.dialog.open(AccountBillingEventUsageDialogComponent, {
            width: '800px',
            data: control.value
        });
    }

    setAccountBillingEvents(accountBillingEvents) {
        this.accountBillingEvents = _.orderBy(accountBillingEvents || [], ['sequence'], ['asc']);
        this.accountBillingEvents.forEach(event => {
            event.usageList = this.billingEventUsage.filter(x => x.billingEventId === event.billingEventId);
        });

        this.createFormData();
    }

    saveAccountBillingEvents() {
        if (this.billingForm.valid) {
            const url = `AcctBilling/${this.accountInfo.id}`;
            const billingEvents = this.billingForm.value.billingDetails;

            this.appService.postData(url, billingEvents, false, false)
                .subscribe(data => {
                    if (data.status === 'success') {
                        this.setAccountBillingEvents(data.data);
                        this.appService.getAllDynamicConfigData(this.accountInfo.id);
                        this.appService.showMsg('success', 'Saved Successfully');

                        this.isEditMode = false;
                    } else {
                        this.appService.showMsg('error', data.message);
                    }
                });
        }
    }

    editBilling() {
        this.createFormData();
        this.isEditMode = true;
    }

    getBillingEventTypeName(typeId) {
        if (typeId && this.globalBillingEventTypes && this.globalBillingEventTypes.length) {
            return _.find(this.globalBillingEventTypes, ['id', typeId]).name;
        } else {
            return '';
        }
    }
}

@Component({
    selector: 'account-billing-event-usage-dialog',
    templateUrl: 'account-billing-event-usage-dialog.html',
})
export class AccountBillingEventUsageDialogComponent {
    columnsToDisplay: string[] = ['type', 'name', 'actionName'];

    constructor(
        public dialogRef: MatDialogRef<AccountBillingEventUsageDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public accountBillingEvent: AccountBillingEvent) { }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
