<mat-dialog-content style="overflow-y: hidden !important">
  <div class="flex-fill overflow-y-auto custom-scrollbar">
    <div >
      <form [formGroup]="arrangeDocsFormGroup" id="stepThreeFormGroup" style="min-height: 200px">
        <div cdkDropList (cdkDropListDropped)="drop($event)" class="list-container">
          <div *ngFor="let selectedItem of selectedItems" cdkDrag class="document-box" cdkDragPreviewContainer="parent">
            <div *cdkDragPlaceholder class="drag-drop-custom-placeholder"></div>
            <div class="flex flex-row">
              <mat-icon>drag_indicator</mat-icon>
              <div style="margin-left: 15px; margin-top: 2px;">{{selectedItem?.name}}</div>
            </div>
          </div>
        </div>
        <div *ngIf="selectedItems.length  <= 0" class="mt-5">
          No documents or tags have been selected...
        </div>
      </form>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button id="close-fax-event-modal" mat-button id="document-wizard-document-arrange-cancelButton" color="basic"
    class="mr-1 material-default" mat-dialog-close>
    CANCEL
  </button>
  <button id="document-wizard-document-arrange-backButton" mat-button color="primary" class="mr-1 material-default"
    matStepperPrevious>
    BACK
  </button>
  <button id="document-wizard-document-arrange-addtoeventbutton" mat-button color="primary"
    class="mr-1 material-default" (click)="close()">
    {{nextButtonText}}
  </button>
</mat-dialog-actions>