import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'trust-commerce-callback',
    template: ``,
})
export class TrustCommerceCallbackComponent implements OnInit {
    constructor(public route : ActivatedRoute) {}

    ngOnInit() {
        const routeQueryParams = this.route.snapshot.queryParams;
        window.parent.postMessage({'messageType': 'trustCommerceCallback', 'queryParams': routeQueryParams}, '*');
    }
}
