<div class="question-container" [formGroup]="group" [ngClass]="{active: isSelected, interviewQuestion: interviewMode}" *ngIf="interviewMode || !hideQuestion(config)">
<div [ngClass]="isConfigInvalid()?'invalid-question':''">
    <div class="question-header" *ngIf="!interviewMode" (click)="selectQuestion()">
        <button type="button" class="btn btn-link float-left" (click)="expanded = !expanded">
            <i aria-hidden="true" class="fa" [ngClass]="{'fa-angle-right': !expanded, 'fa-angle-down': expanded}"></i>
        </button>
        <span class="question-mapkey">
            {{config.mapKeyName}}
        </span>
        <span class="question-mapkey float-right pr-2">
            {{config.answerTypeName}}
        </span>
    </div>
    <div class="question-body" *ngIf="expanded && config?.DisplayType">
        <div class="question-text rendered-quill-content ql-editor" [innerHTML]="questionText"></div>
        <div class="help-text rendered-quill-content ql-editor" [innerHTML]="helpText" *ngIf="config.helpText?.length"></div>
        <div class="question-form" [ngSwitch]="config?.DisplayType" *ngIf="showList">

            <div class="w-100" *ngSwitchCase="'button'" [attr.data-test-input-type]="inputTypeButton">
                <input type="hidden" [formControlName]="config.id">
                <div *ngFor="let listValue of constantList" class="d-block mb-3" >
                    <button matButton (click)="updateValueOnButtonClick(listValue.value)" [disabled]="isReadOnly()"  
                    [attr.data-test-value]="listValue.value"
                    style="text-transform:none">{{listValue.displayName}}
                        <i aria-hidden="true" class="fa fa-check" *ngIf="this.group.get(config?.id).value==listValue.value"></i>
                    </button>
                </div>
            </div>

            <mat-radio-group *ngSwitchCase="'radio'" formControlName="{{config.id}}" [attr.data-test-input-type]="inputTypeRadiobutton">
                <mat-radio-button (focusin)="onFocus()" class="d-block" [disabled]="isReadOnly()" *ngFor="let listValue of constantList"
                    [value]="listValue.value" [attr.data-test-value]="listValue.value">{{listValue.displayName}}</mat-radio-button>
            </mat-radio-group>
            <mat-error *ngIf="group.get(config.id)?.hasError('required') && (group.get(config.id)?.dirty || group.get(config.id)?.touched)" class="error-text">required</mat-error>

            <!-- <mat-form-field *ngSwitchDefault class="w-100">
                <mat-select [placeholder]="config.Placeholder" (focusin)="onFocus()" formControlName="{{config.id}}" [disabled]="isReadOnly()">
                    <mat-option *ngFor="let listValue of config.listValues" [value]="listValue.value">{{listValue.displayName}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-error *ngIf="group.get(config.id)?.hasError('validationError')">{{group.get(config.id)?.errors.validationError}}</mat-error> -->

            <auto-suggest *ngSwitchDefault disableSort="true" [list]="constantList" [placeholder]="config.Placeholder" field="displayName" dataKey="value"
                formControlName="{{config.id}}" (focusin)="onFocus()" [showRequiredError]="group.get(config.id)?.hasError('required') && (group.get(config.id)?.touched || group.get(config.id)?.dirty)"
                [disabled]="isReadOnly()"></auto-suggest>
            <mat-error *ngIf="group.get(config.id)?.hasError('validationError')">{{group.get(config.id)?.errors.validationError}}</mat-error>

            <dynamic-form *ngIf="indentReflexiveQuestions && reflexiveQuestions?.length"  [expandAllQuestions]="true"
                [interviewMode]="'reflexive'" [parentId]="reflexiveFormId" [parentIndex]="formIndex"
                [constantLists]="constantLists"
                [config]="reflexiveQuestions"></dynamic-form>
        </div>
        <app-field-details [question]="config" [form]="group" [readOnlyMode]="isReadOnly()"></app-field-details>
    </div>
</div>
</div>

<dynamic-form *ngIf="!indentReflexiveQuestions && reflexiveQuestions?.length"  [expandAllQuestions]="true"
    [interviewMode]="'reflexive'" [parentId]="reflexiveFormId" [removeIndent]="true" [parentIndex]="formIndex"
    [constantLists]="constantLists"
    [config]="reflexiveQuestions"></dynamic-form>
