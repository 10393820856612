import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {NotificationSeverity,NotificationService} from 'app/services';
import {SbliOneIncPaymentService} from 'app/services/case/sbli-one-inc-payment.service';
import {InterviewService} from '../../interview.service';
import {CasePortalOnePayloadInterface} from "app/models/case/case-portalone-payload.interface";

declare var OneInc: any;

@Component({
    selector: 'app-portal-one',
    templateUrl: './portal-one.component.html'
})
export class PortalOneComponent implements OnInit {

    portalOne: any;
    poData: CasePortalOnePayloadInterface;
    clientId: string;
    caseId: string;
    accountId: string;
    caseIntegrationQueueId: string;
    successClose: boolean = false;
    portalOneError: string = '';

    constructor(
    public interviewService: InterviewService,
    public dialogRef: MatDialogRef<PortalOneComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public portaloneService: SbliOneIncPaymentService,
    public notificationService: NotificationService) {

        this.poData = data.sbliResp;
        this.clientId = data.clientId;
        this.caseId = data.caseId;
        this.accountId = data.accountId;
        this.caseIntegrationQueueId = data.caseIntegrationQueueId;
    }

    ngOnInit(): void {
        let container = document.getElementById('portalOneContainer');
        this.portalOne = new OneInc.PortalOne(container, this.subscribeToEvents(this, container));
        this.openModalWindow();
    }


    public subscribeToEvents(thisParam, container: HTMLElement): void {

        if (!container || container == null) {
            thisParam.closeIt(NotificationSeverity.Error, 'Error loading payment component...');
        }
        else {
            container.addEventListener("portalOne.saveComplete", function (e: CustomEvent) {
                let payInfo = {
                    clientId: thisParam.clientId,
                    caseId: thisParam.caseId,
                    accountId: thisParam.accountId,
                    caseIntegrationQueueId: thisParam.caseIntegrationQueueId,
                    tokenId: e.detail.tokenId,
                    cardType: e.detail.cardType,
                    cardExpirationMonth: e.detail.cardExpirationMonth,
                    cardExpirationYear: e.detail.cardExpirationYear,
                    lastFourDigits: e.detail.lastFourDigits
                };
                thisParam.saveMapKeyInfo(payInfo);
            });

            container.addEventListener("portalOne.unload", function () {
                if (thisParam.successClose) {
                    thisParam.closeIt(NotificationSeverity.Success, 'Save payment method completed successfully...');
                }else{
                    thisParam.saveModalErrorsToOutput();
                    thisParam.dialogRef.close();
                }
            });

            container.addEventListener("portalOne.saveCanceled", function () {
                thisParam.closeIt(NotificationSeverity.Info, 'Save payment method canceled...');
            });

            container.addEventListener("portalOne.error", function (e: CustomEvent) {
                thisParam.portalOneError = e.detail.Description;
            });
        }
    }

    saveMapKeyInfo(payload: any) {
        this.portaloneService.saveMapkeyData(payload).subscribe(
            (value) => {
                this.interviewService.mergeMapkeys(value);
                this.interviewService.updateQuestionPreActionsForSection(this.interviewService.getInterviewData().activeSectionId);
                this.successClose = true;
            },
            (err) => {
                console.log("saveMapKeyInfo Error: " + JSON.stringify(err));
                this.closeIt(NotificationSeverity.Error, err.message);
            }
        );
    }

    openModalWindow(): void {

        this.portalOne.savePaymentMethod({
            paymentCategory: OneInc.PaymentCategory.CreditCard,
            confirmationDisplay: true,
            displayMode: 'Modal',
            allowClosing: true,
            acceptCreditCards: true,
            acceptPrepaidCards: true,
            billingZip: this.poData.billingZip,
            billingAddressStreet: this.poData.billingAddressStreet,
            policyHolderName: this.poData.payorName,
            sessionId: this.poData.sessionId,
            clientReferenceData1: this.poData.clientReferenceData1,
            customerId: this.poData.walletId

        });
    }

    closeIt(state: string, msg: string) {
        let retRes = {"state": state, "msg": msg};
        this.dialogRef.close(retRes);
    }

    saveModalErrorsToOutput(){
        if(this.portalOneError !== '') {
            let payLoad = {
                clientId: this.clientId,
                caseId: this.caseId,
                accountId: this.accountId,
                caseIntegrationQueueId: this.caseIntegrationQueueId,
                errors: this.portalOneError,
                tokenId:'',
                cardType: '',
                cardExpirationMonth: '',
                cardExpirationYear: '',
                lastFourDigits: '',
            };
            this.saveMapKeyInfo(payLoad);
        }
    }
}
