import { Component, ComponentRef, Input, OnInit, ViewChild } from "@angular/core";
import { IntegrationEnum } from "app/enums";
import { CepQuestionnaireComponent } from "./cep-questionnaire/cep-questionnaire.component";
import { ThirdPartySectionDirective } from "./third-party-section.directive";
import { CepQuestionnaireService } from './cep-questionnaire/cep-questionnaire.service';
import { MagnumComponent } from "@Components/third-party-section/magnum/magnum.component";
import { AppService } from "app/app.service";

@Component({
    selector: 'third-party-section',
    template: `
        <div>
            <ng-template thirdParty></ng-template>
        </div>
    `
})
export class ThirdPartySection implements OnInit {
    componentRef: ComponentRef<ThirdPartySectionComponent>;
    _embeddedData: EmbeddedIntegrationData;

    constructor(
        private cepQuestionnaireService: CepQuestionnaireService,
        private appService: AppService
    ) {

    }

    get embeddedData() {
        return this._embeddedData;
    }

    @Input() set embeddedData(value) {
        this._embeddedData = value;

        if (this.componentRef) {
            this.componentRef.instance.embeddedData = this.embeddedData;
        }
    }

    @Input() isCaseSummary: boolean;

    @ViewChild(ThirdPartySectionDirective, {static: true}) thirdPartySection!: ThirdPartySectionDirective;

    ngOnInit(): void {
        this.loadThirdPartySectionComponent();
    }

    async loadThirdPartySectionComponent() {
        const viewContainerRef = this.thirdPartySection.viewContainerRef;
        viewContainerRef.clear();

        const factory = await this.getComponent();

        this.componentRef = viewContainerRef.createComponent<ThirdPartySectionComponent>(factory);
        this.componentRef.instance.embeddedData = this.embeddedData;
    }

    async getComponent() {
        switch (this.embeddedData.integrationId) {
            case IntegrationEnum.HanoverRe:
                if (this.isCaseSummary) {
                    const embeddedIntegrationPayload = JSON.parse(this.embeddedData.payloadData);
                    await this.cepQuestionnaireService.renewToken(this.embeddedData.baseUrl, embeddedIntegrationPayload.assessmentId).then(result => {
                        embeddedIntegrationPayload.token = result.token;
                        this.embeddedData.payloadData = JSON.stringify(embeddedIntegrationPayload);
                    });
                }
                return CepQuestionnaireComponent;
            case IntegrationEnum.Magnum:
                this.embeddedData.isCaseSummary = this.isCaseSummary;
                //Show spinner only when in the runtime
                if (!this.isCaseSummary) {
                    this.appService.display(true);
                }
                return MagnumComponent;
            default:
                break;
        }
    }
}

export interface EmbeddedIntegrationData {
    baseUrl: string;
    integrationId: string;
    component: any;
    readonly: boolean;
    payloadData: string;
    isCaseSummary?: boolean;
}

export interface ThirdPartySectionComponent {
    embeddedData: EmbeddedIntegrationData;
}
