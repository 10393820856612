import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DocumentsDTO } from '@DTOs';
import { ConfirmationDialogService } from '@Services';

@Component({
    selector: 'attach-documents',
    templateUrl: './attach-documents.component.html',
    styleUrls: ['./attach-documents.component.scss'],
})
export class AttachDocumentsComponent implements OnInit {
    documentOptions: DocumentsDTO[];
    filteredDocumentOptions: DocumentsDTO[];
    attachedDocuments: DocumentsDTO[];
    attachedDocumentsClone: DocumentsDTO[];
    constructor(
        private confirmationDialogService: ConfirmationDialogService,
        private dialogRef: MatDialogRef<AttachDocumentsComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { 
                                                accountId: string,
                                                documentOptions: DocumentsDTO[], 
                                                attachedDocuments: DocumentsDTO[]
                                            }
    ) {}

    ngOnInit() {
        this.documentOptions = this.data.documentOptions;
        this.filteredDocumentOptions = this.data.documentOptions;
        this.attachedDocuments = this.data.attachedDocuments;
        this.attachedDocumentsClone = [...this.data.attachedDocuments]
    }

    update(checked: boolean, documentId: string) {
        if (checked) {
            const document = this.documentOptions.find(d => d.id === documentId);
            this.attachedDocuments.push(document);
        }
        else {
            const indexToRemove = this.data.attachedDocuments.findIndex(x => x.id === documentId);
            this.attachedDocuments.splice(indexToRemove, 1);
        }
    }

    shouldCheckboxLoadAsChecked(documentId: string): boolean {
        return this.attachedDocuments.map(x => x.id).includes(documentId);
    }

    handleAttachDocumentsButtonClicked() {
        this.confirmationDialogService.open({
            title: "Attach Documents?",
            message: `Email transmission not secure. Do not attach documents that may contain PHI or other sensitive information.`,
            showCancel: true,
            onOk: () => {
                this.dialogRef.close()
            }
        });
    }

    handleCancelButtonClicked() {
        // DEV NOTE: It seems like all I should have to do is the following:
        // this.attachedDocuments = [...this.attachedDocumentsClone];
        // However, for whatever reason, I had to do this to get the parent component
        // to recognize the changes:

        const attachedDocumentsLength = this.attachedDocuments.length;
        for (let i = 0; i < attachedDocumentsLength; i++) {
            this.attachedDocuments.splice(0, 1);
        }
        this.attachedDocumentsClone.forEach(element => {
            this.attachedDocuments.push(element);
        });
        this.dialogRef.close();
    }

    handleSearchKeyUp(keyUpEvent) {
        const filterText = keyUpEvent.target.value.toLowerCase();
        this.filteredDocumentOptions = this.documentOptions.filter(d => filterText.length === 0 || d.name.toLowerCase().includes(filterText));
    }
}