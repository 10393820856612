<div [formGroup]="conditionForm">
  <!-- view -->
  <div class="condition-container row m-0" *ngIf="!isEditView" [ngClass]="{'rule-success': (testResult?.hasConditionResult === true || testResult?.conditionResult === true) && isTestExecuted, 'rule-fail': (testResult?.hasConditionResult === false || testResult?.conditionResult === false) && isTestExecuted}">
    <div class="col-2" title="{{getMapKeyLabel(conditionForm.get('operand').value)}}">{{getMapKeyLabel(conditionForm.get('operand').value)}}</div>
    <div class="col-2" ><span *ngIf="isJsonResponse">{{getJsonPropertyLabel(conditionForm.get('jsonPropertyId').value)}}</span></div>
    <div class="col-2">{{getOperatorLabel(conditionForm.get('operatorId').value)}}</div>
    <div class="col-2">{{getOperandTypeLabel(conditionForm.get('operandTypeId').value)}}</div>
    <div class="col-3" *ngIf="conditionForm.get('operandTypeId').value == '00000000-0000-0000-0000-000000000001'">{{conditionForm.get('valueoperand').value}}</div>
    <div class="col-3" title="{{getMapKeyLabel(conditionForm.get('mapkeyOperand').value)}}" *ngIf="conditionForm.get('operandTypeId').value == '00000000-0000-0000-0000-000000000002'">{{getMapKeyLabel(conditionForm.get('mapkeyOperand').value)}}</div>
    <div *ngIf="isTestExecuted" class="col-1"><i aria-hidden="true" class="fa" [ngClass]="{'fa-check-circle text-success': (testResult?.hasConditionResult === true || testResult?.conditionResult === true) && isTestExecuted, 'fa-times-circle text-danger': (testResult?.hasConditionResult === false || testResult?.conditionResult === false)  && isTestExecuted}"></i></div>
  </div>

  <!-- edit -->
  <div class="condition-container row m-0 pt-2" *ngIf="isEditView">
    <div class="col-2 condition-param">
      <map-keys-dropdown
              [displayList]="variableMapKeys" placeholder="Mapkey"
          formControlName="operand" [disabled]="!this.checkACL('W')"
          [formControl]="conditionForm.get('operand')"
          [showRequiredError]="conditionForm.get('operand').hasError('required')">
        </map-keys-dropdown>
    </div>
    <div *ngIf="isJsonResponse" class="col-2">
      <mat-form-field class="w-100">
        <mat-label>Property</mat-label>
        <mat-select formControlName="jsonPropertyId">
          <mat-option *ngFor="let p of outputProperties" [value]="p.id" [matTooltip]="p.property" [matTooltipPosition]="'left'">
            {{p.property}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-2 condition-param">
      <mat-form-field class="w-100">
        <mat-label>Condition</mat-label>
        <mat-select formControlName="operatorId">
          <mat-option *ngFor="let operator of conditionalOperators" [value]="operator.id">
            {{operator.name}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="conditionForm.get('operatorId').hasError('required')">
          required
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-2 condition-param">
      <mat-form-field class="w-100">
        <mat-label>Type</mat-label>
        <mat-select formControlName="operandTypeId">
          <mat-option *ngFor="let operatorType of operatorTypes" [value]="operatorType.id">
            {{operatorType.value}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="conditionForm.get('operandTypeId').hasError('required')">
          required
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-3 condition-param">
      <mat-form-field class="w-100" *ngIf="conditionForm.get('operandTypeId').value == '00000000-0000-0000-0000-000000000001'">
        <mat-label>Value</mat-label>
        <input matInput formControlName="valueoperand" autocomplete="off">
      </mat-form-field>
      <div>
        <map-keys-dropdown *ngIf="conditionForm.get('operandTypeId').value == '00000000-0000-0000-0000-000000000002'"
                           [displayList]="mapKeys" placeholder="Mapkey"
          formControlName="mapkeyOperand" [disabled]="!this.checkACL('W')"
          [formControl]="conditionForm.get('mapkeyOperand')"
          [showRequiredError]="conditionForm.get('mapkeyOperand').hasError('required')">
        </map-keys-dropdown>
      </div>
    </div>
    <div class="col-1 px-0">
      <button *ngIf="checkACL('W')" type="button" class="btn btn-link btn-sm custom" (click)="delete()">
        <i aria-hidden="true" class="far fa-trash-alt"></i> Delete
      </button>
    </div>
  </div>
</div>
