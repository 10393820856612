<div class="question-container" [formGroup]="group" [ngClass]="{active: isSelected, interviewQuestion: interviewMode}">
  <div [ngClass]="isConfigInvalid()?'invalid-question':''">
    <div class="question-header" *ngIf="!interviewMode" (click)="selectQuestion()">
      <button type="button" class="btn btn-link float-left" (click)="expanded = !expanded">
        <i aria-hidden="true" class="fa" [ngClass]="{'fa-angle-right': !expanded, 'fa-angle-down': expanded}"></i>
      </button>
      <span class="question-mapkey">
        {{config.mapKeyName}}
      </span>
      <span class="question-mapkey float-right pr-2">
        {{config.answerTypeName}}
      </span>
    </div>
    <div class="question-body" *ngIf="expanded">
      <div class="question-text rendered-quill-content ql-editor" [innerHTML]="questionText"></div>
      <div class="help-text rendered-quill-content ql-editor" [innerHTML]="helpText"
        *ngIf="config.helpText?.length"></div>
      <div class="question-form">
        <mat-form-field class="w-100 mb-1">
          <input matInput [textMask]="{mask: mask}" [placeholder]="config?.Placeholder" [formControlName]="config.id"
            (focus)="onFocus()" (blur)="onBlur(true)" [readonly]="isReadOnly()" [attr.data-test-input-type]="inputType">
          <mat-error *ngIf="group.get(config.id)?.hasError('required')">required</mat-error>
          <mat-error *ngIf="group.get(config.id)?.hasError('pattern')">Enter Valid SSN 123-45-6789</mat-error>
          <mat-error *ngIf="group.get(config.id)?.hasError('validationError')">{{group.get(config.id)?.errors.validationError}}</mat-error>
        </mat-form-field>
      </div>
      <app-field-details [question]="config" [form]="group" [readOnlyMode]="isReadOnly()"></app-field-details>
    </div>
  </div>
</div>
