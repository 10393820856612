import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AppService} from '../../../../app.service';
import {UntypedFormBuilder, UntypedFormControl} from '@angular/forms';

import {MapKeyService, SectionApiSettingsService} from '../../../../services';
import {Account} from '../../../../models';
import {AccountInterviewService} from '../account-interview.service';
import {MatSlideToggleChange} from "@angular/material/slide-toggle";

@Component({
    selector: 'interview-section-api-settings',
    host: {'class': 'd-flex flex-row flex-fill'},
    templateUrl: './interview-section-api-settings.component.html',
    styleUrls: ['./interview-section-api-settings.component.scss']
})

export class SectionApiSettingsComponent implements OnInit, OnDestroy {
  @Input() accountInfo: Account;
  @Input() section: any;
  @Input() rules: any;
  @Input() mapKeys:any;

  @Output() apiSettingsUpdated = new EventEmitter<any>();
  subscriptions: any = [];
  apiSettings: any;
  apiSettingsForm: any;
  apiMapKeyId: any;

  constructor(
    public appService: AppService,
    private _fb: UntypedFormBuilder,
    private accountInterviewService: AccountInterviewService,
    private sectionApiSettingsService: SectionApiSettingsService,
    private mapKeyService: MapKeyService,
  ) {

  }

  ngOnInit() {
      this.mapKeys = this.mapKeyService.mapKeys;
      this.apiSettingsForm = this._fb.group({
          enabled: '',
          ruleId: new UntypedFormControl({value:'',disabled:true}),
          mapKeyId: new UntypedFormControl({value:'',disabled:true})
      });
      this.subscriptions.push(
          this.accountInterviewService.onSectionSelect.subscribe(selectedSection => {
              this.section = selectedSection;
              this.getApiSettings();
          })
      );

      this.getApiSettings();

  }

  getApiSettings() {
      this.sectionApiSettingsService.getSectionApiSettings(this.accountInfo, this.section.id).subscribe(
          result => {
              this.apiSettings = result;
              this.apiSettingsForm.patchValue({
                  enabled: this.apiSettings.enabled,
                  ruleId: this.apiSettings.ruleId,
                  mapKeyId: this.apiSettings.mapKeyId
              });
              if(this.apiSettingsForm.controls['enabled'].value){
                  this.apiSettingsForm.controls['mapKeyId'].enable();
                  this.apiSettingsForm.controls['ruleId'].enable();
              }
          });
  }

  toggleDisplay(event:MatSlideToggleChange) {
      if(event.checked){
          this.apiSettingsForm.controls['mapKeyId'].enable();
          this.apiSettingsForm.controls['ruleId'].enable();

      }else{
          this.apiSettingsForm.controls['mapKeyId'].disable();
          this.apiSettingsForm.controls['ruleId'].disable();
      }
  }

  saveApiSettings() {
      this.sectionApiSettingsService.saveSectionApiSettings(this.accountInfo, this.section.id, this.apiSettingsForm.value).subscribe(
          (setting) => {
              this.appService.showMsg('success', 'API Settings saved successfully');
              this.apiSettingsUpdated.emit(setting);
          }
      );
  }

  ngOnDestroy() {
      this.subscriptions.forEach(subscription => {
          subscription.unsubscribe();
      });
  }
}
