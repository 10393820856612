import { Audit, AuditExtended, AuditGoalStates, AuditProgressStatistics } from '@Audit';
import { MrsColor } from '@Enums';
import { Account, Client } from '@Models';
import { AuditService, ClientContextService } from '@Services';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

export type AuditForm = {
    clientId: FormControl<string>;
};

export type AuditDisplay = AuditExtended & {
    accountNames: string;
    auditProgressStatistics: AuditProgressStatistics
};

@Component({
    selector: 'audit-page',
    templateUrl: './audit-page.component.html',
    styleUrls: ['./audit-page.component.scss']
})
export default class AuditPageComponent implements OnInit {
    auditForm: FormGroup<AuditForm> = this._fb.group({
        clientId: ['', Validators.required],
    });

    canViewAuditEdit = false;
    clients: Client[] = [];
    clientsLoaded = false;

    loadingAudits = false;
    currentAudits: AuditDisplay[] = null;

    public get MrsColor() { return MrsColor; }
    get AuditGoalStates() { return AuditGoalStates; } // return enum from a getter method. Keeping the same names makes it easy to remember.

    // Table Data
    displayedColumns = [ // The columns and order to show in the template
        'rowNumber',
        'name',
        //'total-cases',
        'selected-cases',
        'completed-cases',
        'account',
        'startDate',
        'endDate',
        'action'
    ];

    constructor(
        private _fb: FormBuilder,
        private _router: Router,
        private _clientContextService: ClientContextService,
        private _auditService: AuditService,
    ) { }

    //#region Helpers

    getAccountNames(accountIds: string[], clientId: string): string {
        const client = this.clients.find(x => x.id === clientId);
        const accounts = new Map<string, Account>();

        accountIds.forEach(accountId => {
            // We'll check the active accounts first. It's most likely going to be here.
            const activeAccount = client.accounts.find(x => x.id === accountId);
            if (activeAccount) {
                accounts.set(accountId, activeAccount);
                return;
            }

            // The account must be an inactive version. We'll need to search each accounts version
            for (const activeAccount of client.accounts) {
                const accountVersion = activeAccount.versions.find(x => x.id === accountId);
                if (accountVersion) {
                    accounts.set(accountId, accountVersion);
                    break; // we don't need to check other active accounts if we found the one we're looking for
                }
            }
        });

        const accountNames = accounts.size == 1
            ? Array.from(accounts.values())[0].name
            : 'Multiple';

        return accountNames;
    }

    loadAudits() {
        this.loadingAudits = true;
        const clientId = this._clientContextService.client.id;

        this._auditService.getAudits(clientId).subscribe(audits => {
            this.currentAudits = audits.map((audit): AuditDisplay => {
                const accountIds = audit.accountRules.map(x => x.accountId);
                const accountNames = this.getAccountNames(accountIds, clientId);
                const auditProgressStatistics = {
                    totalCases: audit.totalCases,
                    selectedCases: (audit.selectedCases + audit.completedCases),
                    completedCases: audit.completedCases,
                    goalPercent: audit.goal,
                };

                return { ...audit, accountNames, auditProgressStatistics };
            });
            this.loadingAudits = false;
        });
    }

    //#endregion

    async ngOnInit() {
        await this._clientContextService.ready;

        this.canViewAuditEdit = this._auditService.canViewAuditEdit();
        this.clients = this._clientContextService.clients;
        this.clientsLoaded = this._clientContextService.clientsLoaded;

        this.auditForm.controls.clientId.valueChanges.subscribe(clientId => {
            this._clientContextService.setClient(clientId);
            this.loadAudits();
        });

        // If there's a client set in the context, set the client in the drop-down
        if (this._clientContextService.client)
            this.auditForm.controls.clientId.setValue(this._clientContextService.client.id);
    }

    onCreate() {
        this._router.navigate(['/audit/create']);
    }

    onViewAudit(audit: AuditDisplay) {
        const client = this.clients.find(x => x.id === audit.clientId);
        this._router.navigate([`/audit/client/${client.code}/${audit.id}`]);
    }

    onEditAudit(audit: Audit) {
        const client = this.clients.find(x => x.id === audit.clientId);
        this._router.navigate([`/audit/client/${client.code}/${audit.id}/edit`]);
    }
}
