<mat-menu #menu>
  <ng-container *ngFor="let item of filteredItems">
      <button *ngIf="!item.children || item.children.length === 0 ; else branch" mat-menu-item style="text-transform:none"
        (click)="itemSelected(item)">{{item.name}}</button>
      <ng-template #branch>
          <button mat-menu-item [matMenuTriggerFor]="innerPanel.menu" style="text-transform:none"
            (click)="itemSelected(item)">{{item.name}}</button>
          <app-menu-item #innerPanel [items]="item.children"(onItemSelect)="itemSelected($event)"></app-menu-item>
      </ng-template>
  </ng-container>
</mat-menu>
