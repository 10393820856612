import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AppService } from '../app.service';
import { InterviewService } from '../interview.service';
import { DatePipe } from '@angular/common';
import * as $ from 'jquery';
import { MonitoringService } from '../monitor.service';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { Account } from '../models';

import { CaseSummaryService, FeatureToggleService, RoutingService } from '../services';

@Component({
    selector: 'my-cases',
    host: {'class': 'content'},
    templateUrl: './my-cases.component.html',
    styleUrls: ['./my-cases.component.scss']
})
export class MyCasesComponent implements OnInit {
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    searchValue: any;
    filterValue: any;

    menu: any = [];
    activeMenu: any = '';

    cases: any = [];

    caseDataSource = new MatTableDataSource<any>();

    timeZone: string;

    accounts: Account[];
    columnsToDisplay = ['accountName', 'caseNumber', 'policyNumber', 'applicant', 'statusName', 'caseCreated', 'actions'];
    isAgentBehavior: boolean = false;

    constructor(
        private appService: AppService,
        private datePipe: DatePipe,
        public interviewService: InterviewService,
        private routingService: RoutingService,
        private activatedRoute: ActivatedRoute,
        private monitoringService: MonitoringService,
        private featureToggleService: FeatureToggleService,
        private caseSummaryService: CaseSummaryService
    ) {
        this.isAgentBehavior = this.appService.getIsAgentBehavior();
        this.appService.getAllConfigData();
        this.activatedRoute.data.subscribe(data => {
            this.accounts = data.accounts;
        });
    }

    ngOnInit() {
        $('#sidenav').removeClass('hidenav');
        this.timeZone = this.appService.getTimeZone();
        this.getCases();
    }

    getCases() {
        const metricEvent = this.monitoringService.beginMetric('Search: My Cases');
        this.appService.getData(`Case/Search?createdBy=${this.appService.getUserId()}&includeMapKeys=Case_Applicant_FirstName&includeMapKeys=Case_Applicant_LastName`).subscribe(
            data => {
                this.cases = (data.data || []).map(c => (Object.assign({}, c, {
                    caseCreatedDateFormatted: this.datePipe.transform(c.caseCreated, 'MM/dd/yyyy', this.timeZone),
                    canResume: this.interviewService.canResumeCase(c.status)
                }, c.mapkeys.reduce((dict, next) => {
                    dict[next.name] = next.value;
                    return dict;
                }, {}))));

                this.caseDataSource.data = this.cases;

                this.caseDataSource.sortingDataAccessor = (item, property) => {
                    switch (property) {
                        case 'caseCreated':
                            return item.caseCreated;
                        case 'applicant':
                            return `${item.Case_Applicant_FirstName} ${item.Case_Applicant_LastName}`.toLowerCase();
                        default:
                            return item[property];
                    }
                };

                this.monitoringService.endMetric(metricEvent);
                this.monitoringService.flushMetrics();

                this.caseDataSource.paginator = this.paginator;
                this.caseDataSource.sort = this.sort;
                this.caseDataSource.filterPredicate = this.tableFilter();
            }
        );
    }

    updateFilteredCases() {
        this.caseDataSource.filter = this.filterValue.trim().toLowerCase();
    }

    tableFilter(): (data: any, filter: string) => boolean {
        const filterFunction = function (data, filter): boolean {
            return data.accountName.toLowerCase().indexOf(filter) !== -1
                || data.caseNumber.toString().toLowerCase().indexOf(filter) !== -1
                || (data.policyNumber && data.policyNumber.toLowerCase().indexOf(filter) !== -1)
                || data.statusName.toLowerCase().indexOf(filter) !== -1
                || (data.Case_Applicant_FirstName + ' ' + data.Case_Applicant_LastName).toLowerCase().indexOf(filter) !== -1;
        };
        return filterFunction;
    }

    viewCaseDetails(record) {
        if (record.canResume && this.isAgentBehavior) {
            this.restartInterview(record);
        } else {
            this.caseSummaryService.routeToCase(record);
        }
    }

    restartInterview(record) {
        this.monitoringService.logEvent(`${record.caseNumber} - Restarting interview from My Cases`);
        this.caseSummaryService.restartInterviewInNewTab(record);
    }

    stopPropagation($event) {
        $event.stopPropagation();
    }
}
