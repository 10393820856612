<div class="edit-cnbw-columns">
    <div class="section-header">
        <h2 class="section-header-title">{{managementQueue.name}} Columns</h2>
        <span class="section-header-button">
            <mrs-btn-tertiary buttonLabel="Add Column" iconName="add" textColor="#2C6EED" (onClick)="handleAddColumnItem()"></mrs-btn-tertiary>
        </span>
    </div>

    <div *ngIf="!columnItems?.length" class="no-columns">
        <mat-icon class="material-icons-outlined md-18">info</mat-icon>
        <div class="content">
            <p><strong>You currently have no columns.</strong></p>
            <mrs-btn-outline buttonLabel="Add Column" iconName="add" (onClick)="handleAddColumnItem()"></mrs-btn-outline>
        </div>
    </div>

    <!-- Tabs - We use a drag & drop list because material-angular tabbs don't support drag/drop re-ordering -->
    <div *ngIf="columnItems?.length > 0" class="tab-navigation" cdkDropList [cdkDropListData]="columnItems" (cdkDropListDropped)="handleColumnTabDropped($event)" cdkDropListOrientation="horizontal">
        <button class="change-btn prev hide" (click)="handleScrollLeft()" [disabled]="disableScrollLeft">
            <mat-icon>arrow_back_ios</mat-icon>
        </button>

        <div #tabsWrap id="column-mapKey-tabs-wrap" class="tabs-wrap">
            <ul #tabs id="column-mapKey-tabs" class="tabs" [ngStyle]="{ 'transform': tabsTransform } ">
                <li cdkDrag
                    *ngFor="let columnItem of columnItems.controls; let i = index"
                    [id]="'column-index-' + i"
                    class="tab"
                    [ngClass]="{ 'active': this.activeColumnItem?.controls.order.value === i, 'error': columnItem.invalid }"
                    (click)="handleSelectColumnTab(columnItem)">
                    <div class="drag-placeholder" *cdkDragPlaceholder></div>
                    <button class="drag-handle" mat-icon-button cdkDragHandle>
                        <mat-icon class="btn-drag">drag_indicator</mat-icon>
                    </button>
                    <span class="error-indicator" *ngIf="columnItem.invalid">
                        <mat-icon class="small-error-icon mr-2">error_outline</mat-icon>
                    </span>

                    <span class="column-name" *ngIf="columnItem.controls.displayName.value; else columnDefinitionValue">{{ columnItem.controls.displayName.value }}</span>
                    <ng-template #columnDefinitionValue><span *ngIf="columnItem.controls.columnDefinition.value; else mapKeyDefaultValue">{{ '(' + columnItem.controls.columnDefinition.value +')' }}</span></ng-template>
                    <ng-template #mapKeyDefaultValue>(mapkey)</ng-template>
                </li>
            </ul>
        </div>
        <button class="change-btn next hide" (click)="handleScrollRight()" [disabled]="disableScrollRight">
            <mat-icon>arrow_forward_ios</mat-icon>
        </button>
    </div>

    <!-- Tab Content -->
    <div *ngIf="columnItems?.length > 0 && activeColumnItem" class="tab-content">
        <form [formGroup]="activeColumnItem">
            <div class="column-type-section">
                <h3>Column Settings</h3>
                <mat-form-field class="column-type-select">
                    <mat-label>Select Type</mat-label>
                    <mat-select (selectionChange)="handleColumnTypeChange($event.value)" formControlName="columnDefinitionType" name="columnTypeSelect">
                        <mat-option *ngFor="let columnType of columnTypes" [value]="columnType.value" [disabled]="columnType.disabled">{{ columnType.name }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="activeColumnItem.controls.columnDefinitionType.hasError('required') && activeColumnItem.controls.mapKeyName.value">
                        The Mapkey doesn't exist on any account
                    </mat-error>
                    <mat-error *ngIf="activeColumnItem.controls.columnDefinition.hasError('required') && !activeColumnItem.controls.mapKeyName.value">
                        Please select a Mapkey below
                    </mat-error>
                    <mat-error *ngIf="activeColumnItem.controls.columnDefinition.hasError('required') && activeColumnItem.controls.mapKeyName.value">
                        The column definition has an unexpected value. Please contact support.
                    </mat-error>
                </mat-form-field>
            </div>

            <mat-form-field class="column-name-section">
                <mat-label>Name</mat-label>
                <input matInput class="column-name-field" maxlength="20" formControlName="displayName">
                <mat-hint *ngIf="activeColumnItem?.controls.displayName.valid">
                    {{ 20 - activeColumnItem?.controls.displayName?.value?.length }} / 20 Characters Remaining
                </mat-hint>
                <mat-error *ngIf="activeColumnItem?.controls.displayName.hasError('required')">Name is required</mat-error>
                <mat-error *ngIf="activeColumnItem?.controls.displayName.hasError('name-exists')">Name already exists</mat-error>
            </mat-form-field>

            <div *ngIf="activeColumnItem?.controls.columnDefinitionType.value === ColumnDefinitionsEnum.Mapkey" class="column-mapKey-section">
                <h3>Mapkeys</h3>

                <account-mapkey-selector
                    *ngFor="let account of accounts"
                    [account]="account"
                    [mapKeyEntityHierarchy]="activeColumnItem.controls.mapKeyName.value"
                    (onMapKeyChange)="handleMapKeyChange($event)"
                    (onValidChange)="handleMapKeyValidChange($event)"></account-mapkey-selector>
            </div>

            <div class="delete-column">
                <mrs-btn-tertiary buttonLabel="Delete Column" iconName="delete_forever" textColor="#900606" (onClick)="handleDeleteColumnItem(activeColumnItem)"></mrs-btn-tertiary>
            </div>
        </form>
    </div>
</div>