<div class="page-header" mat-dialog-title>Add a Payment Method</div>
<div mat-dialog-content style="height: 450px" >
  <iframe title="Trust Commerce" name="tc-frame" [src]="url" style="height: 95% !important" *ngIf="!isTrustCommerceInterviewComplete"></iframe>

  <h3 *ngIf="isTrustCommerceInterviewComplete" class="mt-5" style="text-align: center;">Processing Results...</h3>
</div>
<mat-dialog-actions  align="end">
  <button mat-button id="closeButton" color="basic" class="mr-1 material-default" mat-dialog-close>
    CLOSE
  </button>
</mat-dialog-actions>
