enum MapKeyType {
    NonVirtual = '00000000-0000-0000-0000-000000000001',
    Virtual = '00000000-0000-0000-0000-000000000002',
    Integration = '00000000-0000-0000-0000-000000000003',
    NonPersistent = '00000000-0000-0000-0000-000000000004',
    ExpressionBuilder = '00000000-0000-0000-0000-000000000005',
    VirtualDocument = '00000000-0000-0000-0000-000000000006',
    DynamicList = '00000000-0000-0000-0000-000000000007'
}

enum MapkeyTypeName {
    NonVirtual = 'Non-Virtual',
    Virtual = 'Virtual',
    Integration = 'Integration',
    NonPersistent = 'Non-Persistent',
    ExpressionBuilder = 'Expression Builder',
    VirtualDocument = 'Virtual Document',
    SystemGenerated = 'System-generated',
    ConstantList = 'Constant List',
    ConstantValue = 'Constant Value',
    DynamicList = 'Dynamic List'
}

export { MapKeyType, MapkeyTypeName };

