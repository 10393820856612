<div class="mx-auto page-borders w-100">
  <form [formGroup]="purlSettingsForm" (ngSubmit)="saveSettings()" novalidate autocomplete="off">
    <div class="m-5">
      <div class="flex flex-row">
        <div class="page-header">Personal URL</div>
        <div class="flex-1"></div>
        <div style="justify-content: flex-end; padding-bottom: 5px;">
          <mrs-btn-primary class="float-right" buttonLabel="Save" buttonId="saveButton" type="submit">
          </mrs-btn-primary>
        </div>
      </div>
      <mat-slide-toggle class="d-inline-block" formControlName="isPurlEnabled" (change)="onEnabledToggle($event)">
        Activate Personal URL
      </mat-slide-toggle>
    </div>

    <div class="m-5" *ngIf="purlEnabled">
      <div class="page-sub-header">Expiration</div>
      <mat-card appearance="outlined">

        <mat-card-content style="margin-left: 5px">
          <div class="flex flex-row" style="display: inline-block;">
            Personal URL expires
            <mat-form-field style="width: 75px">
              <input type="number" matInput formControlName="expirationDays" min="1" max="90">
              <mat-error *ngIf="purlSettingsForm.get('expirationDays').hasError('required')">required</mat-error>
              <mat-error *ngIf="purlSettingsForm.get('expirationDays').hasError('min')">Min value is 1</mat-error>
              <mat-error *ngIf="purlSettingsForm.get('expirationDays').hasError('max')">Max value is 90</mat-error>
            </mat-form-field>
            days after case creation.
          </div>
          <app-rich-text-editor #expirationMessageEditor [htmlContent]="this.purlSettings?.expirationMessageText"
            title="Message">
          </app-rich-text-editor>
        </mat-card-content>
      </mat-card>


      <div class="page-sub-header mt-4">Verification</div>
      <mat-card appearance="outlined">

        <mat-card-content>
          <div class="flex flex-row" style="display: inline-block;">
            Maximum verification attempts:
            <mat-form-field style="width: 60px">
              <input type="number" matInput formControlName="numberOfRetries" min="1" max="10">
              <mat-error *ngIf="purlSettingsForm.get('numberOfRetries').hasError('required')">required</mat-error>
              <mat-error *ngIf="purlSettingsForm.get('numberOfRetries').hasError('min')">Min value is 1</mat-error>
              <mat-error *ngIf="purlSettingsForm.get('numberOfRetries').hasError('max')">Max value is 10</mat-error>
            </mat-form-field>
          </div>

          <app-rich-text-editor #verificationAttemptsMessageEditor [htmlContent]="this.purlSettings?.verificationText"
            title="Message">
          </app-rich-text-editor>

          <div class="section-header mt-4">Questions</div>

          <div formArrayName="verificationQuestions">
            <div *ngIf="verificationQuestions.controls.length <=0" class="warning-text mt-1">
              Please select at least one verification question
            </div>
            <div class="mt-3">
              <ng-container *ngFor="let control of verificationQuestions.controls; let i = index; last as isLast">
                <div [formGroupName]="i" id="verificationQuestion" [ngClass]="{'mb-5': !isLast}">
                  <div class="flex flex-row">
                    <div>
                      <mat-form-field style="width:450px" class="mr-2">
                        <mat-label>Select a verification Question</mat-label>
                        <mat-select formControlName="id" (selectionChange)="selectQuestion(i)">
                          <mat-option *ngFor="let question of questions" [value]="question.id">
                            {{question.mapKey.entityHierarchy}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="verificationQuestions.controls[i].get('id').hasError('unique')">Please choose
                          a question that hasn't been used</mat-error>
                      </mat-form-field>
                    </div>
                    <div style="display: flex; align-items: center; margin-bottom: 10px;">
                      <mat-icon svgIcon="delete" aria-label="Delete" (click)="deleteQuestion(i)"
                        class="mat-icon-gray"></mat-icon>
                    </div>
                  </div>
                  <div>
                    <div class="question-property-label">Question Text</div>
                    <p-editor formControlName="verificationText" styleClass="p-editor-inline"
                      bounds="verificationQuestion" placeholder="Verification Question Text">
                    </p-editor>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
          <button type="button" mat-button color="primary" class="mr-1 material-default" (click)="addQuestion()"
            style="text-transform: uppercase; padding-left: 0;">
            <mat-icon style="margin-bottom: 4px;">add</mat-icon>Add Question
          </button>
        </mat-card-content>
      </mat-card>
      <div class="page-sub-header mt-4">Welcome Message</div>
      <mat-card appearance="outlined">
        <mat-card-content>
          <div class="section-header mt-4">Header</div>
          <mat-form-field class="w-100">
            <input matInput type="text" formControlName="welcomeHeaderText" maxlength="50" #welcomeHeaderInput>
            <mat-hint align="end">{{welcomeHeaderInput.value?.length || 0}}/50</mat-hint>
            <mat-error *ngIf="purlSettingsForm.get('welcomeHeaderText').hasError('required')">required</mat-error>
          </mat-form-field>
          <div class="section-header mt-4">Body</div>
          <mat-form-field class="w-100 textarea-formfield">
            <mat-label>Welcome Body Text</mat-label>
            <textarea matInput type="text" formControlName="welcomeBodyText" matAutosizeMinRows="4"
              matAutosizeMaxRows="10"></textarea>
            <mat-error *ngIf="purlSettingsForm.get('welcomeBodyText').hasError('required')">required</mat-error>
          </mat-form-field>
        </mat-card-content></mat-card>
    </div>
  </form>
</div>