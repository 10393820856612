<div class="left-panel w-25 bg-light d-flex flex-column">
    <div class="left-panel-header">
        <span class="left-panel-title">MIB Risk Maps</span>
        <button *ngIf="checkACL('W')" type="button" class="btn btn-link btn-sm custom float-right"
            (click)="addMIBRisk()">
            <i aria-hidden="true" class="fa fa-plus-circle"></i> Add MIB Risk Map
        </button>
    </div>
    <div class="left-panel-body flex-fill overflow-y-auto custom-scrollbar">
        <div class="list-container">
            <div class="list-item" (click)="getMIBInfo(risk)" matRipple *ngFor="let risk of mibRisks; let i = index"
                [ngClass]="{'active': risk.id == this.selectedLabel?.id}">
                <div class="list-item-title">{{risk.mibCode}}</div>
                <div class="list-item-actions">
                    <button *ngIf="checkACL('F')" class="btn btn-link btn-sm custom" (click)="deleteMIBRisk(risk.id)">
                        <i aria-hidden="true" class="far fa-trash-alt"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="p-4 text-center text-muted" *ngIf="mibRisks?.length == 0">
            <img src="./assets/images/no-data-placeholder.png" alt="" class="pb-3" />
            <h6>No MIB Risk Maps Found</h6>
        </div>
    </div>
</div>
<div class="right-panel flex-fill d-flex flex-column">
    <div class="left-panel-header">
        <span class="left-panel-title">MIB Risk Map Details</span>
    </div>
    <div class="right-panel-body flex-fill overflow-y-auto custom-scrollbar p-3">

        <div class="row" *ngIf="showForm">
            <div class="col-6 mx-auto">
                <form class="p-5" [formGroup]="mibForm" (ngSubmit)="saveMIBRisk()" novalidate autocomplete="off">
                    <mat-card appearance="outlined">
                        <mat-card-header>MIB Risk Map Detail</mat-card-header>
                        <mat-card-content>
                            <input type="hidden" formControlName='id'>
                            <input type="hidden" formControlName='accountId'>
                            <mat-form-field class="w-100">
                                <input autofocus placeholder="MIB Code" matInput formControlName='mibCode'>
                                <mat-error *ngIf="mibForm.get('mibCode').hasError('required')">
                                    MIB code
                                    <strong>required</strong>
                                </mat-error>
                                <mat-error *ngIf="mibForm.get('mibCode').hasError('pattern')">
                                    Please enter only 3 digits
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="w-100 pb-3">
                                <mat-select placeholder="Severity" formControlName='severityId'>
                                    <mat-option *ngFor="let severity of mibSeverities" [value]="severity.id">
                                        {{ severity.name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="mibForm.get('severityId').hasError('required')">
                                    Severity
                                    <strong>required</strong>
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="w-100 pb-3">
                                <mat-select placeholder="Source" formControlName='sourceId'>
                                    <mat-option *ngFor="let source of mibSources" [value]="source.id">
                                        {{ source.name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="mibForm.get('sourceId').hasError('required')">
                                    Source
                                    <strong>required</strong>
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="w-100 pb-3">
                                <mat-select placeholder="Timeframe" formControlName='timeFrameId'>
                                    <mat-option *ngFor="let timeFrame of mibTimeFrames" [value]="timeFrame.id">
                                        {{ timeFrame.name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="mibForm.get('timeFrameId').hasError('required')">
                                    Timeframe
                                    <strong>required</strong>
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="w-100 pb-3">
                                <mat-select placeholder="Risk Level" formControlName='riskLevelId'>
                                    <mat-option *ngFor="let riskLevel of mibRiskLevels" [value]="riskLevel.id">
                                        {{ riskLevel.name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="mibForm.get('riskLevelId').hasError('required')">
                                    Risk Level
                                    <strong>required</strong>
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="w-100">
                                <input placeholder="MIB Risk Date Boundary (Months)" matInput
                                    formControlName='mibDateRiskBoundary'>
                                <mat-error *ngIf="mibForm.get('mibDateRiskBoundary').hasError('required')">
                                    MIB Risk Date Boundary (Months)
                                    <strong>required</strong>
                                </mat-error>
                                <mat-error *ngIf="mibForm.get('mibDateRiskBoundary').hasError('pattern')">
                                    Please enter only number type
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="w-100">
                                <input autofocus placeholder="Description" matInput formControlName='description'>
                                <mat-error *ngIf="mibForm.get('description').hasError('required')">
                                    Description
                                    <strong>required</strong>
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="w-100 pb-3">
                                <mat-select placeholder="Health Question" formControlName='mapkeyId'>
                                    <mat-option *ngFor="let healthQuestion of healthQuestions"
                                        [value]="healthQuestion.mapkeysId">
                                        {{ healthQuestion.questionTitle ? healthQuestion.questionTitle :
                                        healthQuestion.mapKeyName }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="mibForm.get('mapkeyId').hasError('required')">
                                    Health Question
                                    <strong>required</strong>
                                </mat-error>
                            </mat-form-field>
                        </mat-card-content>
                        <mat-card-actions align="end">     
                            <mrs-btn-tertiary buttonLabel="Cancel" buttonId="cancelButton"
                                (onClick)="cancel()">Cancel</mrs-btn-tertiary>                       
                            <mrs-btn-primary buttonLabel="Save" buttonId="billingSave" isSubmitButton="true"></mrs-btn-primary>
                        </mat-card-actions>
                    </mat-card>
                </form>
            </div>
        </div>

        <div class="col-5 mx-auto mt-5" *ngIf="!showForm && selectedMIB?.id">
            <mat-card appearance="outlined">
                <mat-card-header>
                    <mat-card-title-group>
                        <mat-card-title>MIB Risk Map Details</mat-card-title>
                        <button mat-button color="basic" class="float-right" (click)="editMIBRisk()"
                            *ngIf="checkACL('W')">
                            <i aria-hidden="true" class="fa fa-edit pr-1"></i>Edit
                        </button>
                    </mat-card-title-group>
                </mat-card-header>
                <mat-card-content>
                    <div class="label-title">MIB Code </div>
                    <div class="label-content">{{selectedMIB.mibCode}}</div>

                    <div class="label-title">Severity </div>
                    <div class="label-content">{{getSeverityName(selectedMIB.severityId)}}</div>

                    <div class="label-title">Source </div>
                    <div class="label-content">{{getSourceName(selectedMIB.sourceId)}}</div>

                    <div class="label-title">Timeframe </div>
                    <div class="label-content">{{getTimeFrameName(selectedMIB.timeFrameId)}}</div>

                    <div class="label-title">Risk Level </div>
                    <div class="label-content">{{getRiskLevelName(selectedMIB.riskLevelId)}}</div>

                    <div class="label-title">MIB Risk Date Boundary (Months) </div>
                    <div class="label-content">{{selectedMIB.mibDateRiskBoundary}}</div>

                    <div class="label-title">Description </div>
                    <div class="label-content">{{selectedMIB.description}}</div>

                    <div class="label-title">Health Question </div>
                    <div class="label-content" [innerHTML]="getHealthQuestionText(selectedMIB.mapkeyId) | safeHtml">
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>