<div class="loading-spinner" *ngIf="!isPdfLoaded">
    <mat-spinner diameter="20"></mat-spinner><span>Loading Document</span>
</div>
<ng-container *ngIf="isPdfLoaded">
    <ngx-extended-pdf-viewer [src]="contents" [showPrintButton]="hasViewPermission" [showDownloadButton]="false"
        [useBrowserLocale]="true" [height]="'100%'" [zoom]="zoomLevel" [customToolbar]="multiToolbar">>
    </ngx-extended-pdf-viewer>
    <ng-template #multiToolbar>
        <div id="toolbarViewerLeft">
            <div class="toolbarButtonSpacer"></div>
            <pdf-paging-area></pdf-paging-area>
        </div>
        <pdf-zoom-toolbar>
            <pdf-zoom-out id="zoomOut" />
            <pdf-zoom-dropdown id="scaleSelect" />
            <pdf-zoom-in id="zoomIn" />
        </pdf-zoom-toolbar>
        <div id="toolbarViewerRight">
            <pdf-print *ngIf="hasViewPrintButtonPermission"></pdf-print>
            <!-- <pdf-download *ngIf="hasViewDownloadButtonPermission"></pdf-download> -->
            <div class="verticalToolbarSeparator hiddenSmallView"></div>
        </div>
    </ng-template>
</ng-container>