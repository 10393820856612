import { AppService } from '@App';
import { ActionsDTO, KeyVaultDTO, MapKeysDTO, RulesDTO } from '@DTOs';
import { AccountStatusEnum, FeatureToggle } from '@Enums';
import { Account, Client, UWResponse } from '@Models';
import { AccountDataService, ConfirmationDialogService, FeatureManagerService, GlobalService, MapKeyService, RoutesEnum, RoutingService, } from '@Services';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AccountMapKeysComponent } from 'app/account/account-config/account-mapkeys/account-mapkeys-list.component';
import { CanNavigateAwayWithConfirmGuardAccountConfig } from "app/new-business-workbench/guards/canNavigateAway.guard";
import * as $ from 'jquery';
import { forkJoin } from 'rxjs';
import { AccountInterviewComponent } from './account-interview/account-interview.component';

@Component({
    selector: 'account-config',
    host: { 'class': 'content' },
    templateUrl: './account-config.component.html',
    styleUrls: ['./account-config.component.scss']
})
export class AccountConfigComponent implements OnInit, OnDestroy, CanNavigateAwayWithConfirmGuardAccountConfig {
    @ViewChild(AccountMapKeysComponent) accountMapKeysComponent: AccountMapKeysComponent;
    @ViewChild(MatTabGroup) tabGroup: MatTabGroup;
    @ViewChild(AccountMapKeysComponent) accountMapKeys: AccountMapKeysComponent;
    @ViewChild(AccountInterviewComponent) accountInterviewComponent: AccountInterviewComponent;
    client: Client;
    account: Account;
    accountInactive = false;

    isNewAccount = false;
    hiddenIntegrations: any = ['00000000-0000-0000-0000-000000000082'];
    menu: any = [];
    activeMenu: any = '';
    newClient = false;
    newClientForm: UntypedFormGroup;
    currentClientID: any;
    public companyLogo: any = this.appService.getDefaultLogo();

    selectedTabIndex: number;

    mapKeys: MapKeysDTO[];
    constantListMapKeys: any[];
    nonListNonVirtualMapKeys: any[];
    nonListMapKeys: any[];
    nonVirtualMapKeys: any[];
    nonConstantMapKeys: any[];
    variableMapKeys: any[];
    rules: RulesDTO[];
    showMibRisk = false;
    showRxRisk = false;
    healthQuestions: any[] = [];
    secretKeys: KeyVaultDTO[];
    activatedTabs: any = [];
    actions: ActionsDTO[] = [];
    isAdmin: boolean;
    autoSelectInterview = false;
    reload = false;
    loadingData = false;

    constructor(
        public routingService: RoutingService,
        private appService: AppService,
        private route: ActivatedRoute,
        private mapKeyService: MapKeyService,
        private globalService: GlobalService,
        private accountDataService: AccountDataService,
        private featureManagerService: FeatureManagerService,
        public confirmationService: ConfirmationDialogService
    ) {
    }

    ngOnInit() {
        this.autoSelectInterview = this.featureManagerService.getByName(FeatureToggle.AutoSelectInterview).enabled;
        this.isAdmin = this.appService.getUserRoleName() === 'Admin';
        $('#sidenav').addClass('hidenav');

        this.route.data.subscribe(async data => {
            this.account = data.account;

            this.accountDataService.activeAccount = this.account;
            this.client = data.client;
            this.isNewAccount = data.new;
        });

        this.checkACL('Accounts', 'R', RoutesEnum.dashboard);

        this.newClientForm = new UntypedFormGroup({
            name: new UntypedFormControl('', [Validators.required]),
            code: new UntypedFormControl(''),
            status: new UntypedFormControl(''),
            logo: new UntypedFormControl('')
        });

        if (!this.isNewAccount) {
            this.getAccountData();
        } else {
            this.selectedTabIndex = 0;
        }

        this.accountInactive = (this.account?.statusId === AccountStatusEnum.Inactive);
    }

    VerifyMibRxActions() {
        this.appService.getData(`Accounts/${this.account.id}/VerifyMibRxAction`).subscribe(
            data => {
                if (data.status === 'success') {

                    this.showMibRisk = (data.data.isMibAction ? true : false);
                    this.showRxRisk = (data.data.isRxAction ? true : false);

                } else {
                    this.appService.showMsg('error', data.message);
                }
            }
        );
    }

    getHealthQuestions() {
        this.appService.getData(`Questions/${this.account.workflowId}/HealthQuestions`).subscribe(
            data => {
                if (data.status === 'success') {
                    this.healthQuestions = data.data;
                }
            }
        );
    }

    canDeactivate(component: AccountConfigComponent, routerSnapshot: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot) {
        if (this.accountMapKeysComponent) {
            return this.accountMapKeysComponent.canDeactivate(routerSnapshot, currentState, nextState);
        } else {
            //Leave
            return true;
        }
    }

    getAccountData() {
        this.loadingData = this.reload ? false : true;
        forkJoin([
            this.mapKeyService.getMapKeys(this.account.id, false),
            this.appService.getData<UWResponse<RulesDTO[]>>(`Rules/${this.account.id}`),
            this.appService.getData(`Accounts/${this.account.id}/VerifyMibRxAction`),
            this.appService.getData(`Questions/${this.account.workflowId}/HealthQuestions`),
            this.appService.getData(`Actions/client/${this.client.id}`),
            this.appService.getAllDynamicConfigData(this.account.id)
        ]).subscribe(
            result => {
                this.setMapKeyData();

                this.rules = result[1].data;
                this.mapKeyService.rules = this.rules;

                if (result[3].status === 'success') {

                    this.showMibRisk = (result[2].data.isMibAction ? true : false);
                    this.showRxRisk = (result[2].data.isRxAction ? true : false);

                } else {
                    this.appService.showMsg('error', result[2].message);
                }

                if (result[3].status === 'success') {
                    this.healthQuestions = result[3].data;
                }

                this.actions = result[4].data;

                if (!this.reload) {
                    if (this.autoSelectInterview) {
                        this.selectedTabIndex = 1;
                        this.tabGroup.selectedIndex = 1;
                    } else {
                        this.selectedTabIndex = 0;
                    }
                }

                this.loadingData = false;
            });
    }


    flushCache() {
        const url = `System/FlushCache`;

        forkJoin([
            this.appService.addData(url, null),
            this.globalService.flushPublicApiCache()
        ]).subscribe(result => {
            const internalStatus = result[0].status;
            const publicApiStatus = result[1];

            if (internalStatus === 'success' && publicApiStatus) {
                this.reload = true;
                this.getAccountData();
            }
        });
    }

    tabChange(event) {
        if (event.tab.textLabel !== 'Billing') {
            // We need to reload the billing tab control between tab views
            this.activatedTabs['Billing'] = false;
        }
        if (event.tab.textLabel === 'MapKeys' && this.activatedTabs[event.tab.textLabel] != null && this.accountMapKeysComponent) {
            this.accountMapKeysComponent.refreshView();
        }

        if (!this.activatedTabs[event.tab.textLabel]) {
            this.activatedTabs[event.tab.textLabel] = true;
        }
        if (event.tab.textLabel === 'Application') {
            if (this.accountInterviewComponent) {
                this.accountInterviewComponent.scrollToQuestionOrSectionInNavigation();
            }
        }
    }

    updateAccountInfo(account: Account) {
        if (this.isNewAccount) {
            // Navigate to the new URL so we reload everything properly
            this.routingService.navigateToRoute(RoutesEnum.accountDetails, {
                clientCode: this.client.code,
                accountCode: account.code,
                versionNumber: account.versionNumber
            });
        } else {
            Object.assign(this.account, account);

            this.getMapKeys(true);
            this.getRules();
        }
    }

    checkACL(feature, permissionType, redirect?) {
        return this.appService.checkACL(feature, permissionType, redirect);
    }

    back() {
        this.routingService.navigateToRoute(RoutesEnum.clients);
    }

    isActive(selected) {
        return this.activeMenu === selected;
    }

    addClient() {
        this.newClientForm.reset();

        this.newClientForm.patchValue({
            code: 'AA',
            status: '00000000-0000-0000-0000-000000000000',
            name: '',
        });
        this.companyLogo = this.appService.getDefaultLogo();

        this.currentClientID = undefined;
        this.newClient = true;
    }

    getMapKeys(reloadTree) {
        if (!this.isNewAccount) {
            this.mapKeyService.getMapKeys(this.account.id, reloadTree).subscribe(() => {
                this.setMapKeyData();
            });
        }
    }

    setMapKeyData() {
        this.mapKeys = this.mapKeyService.mapKeys;
        this.constantListMapKeys = this.mapKeyService.constantListMapKeys;
        this.nonConstantMapKeys = this.mapKeyService.nonConstantMapKeys;
        this.nonVirtualMapKeys = this.mapKeyService.nonVirtualMapKeys;
        this.nonListNonVirtualMapKeys = this.mapKeyService.nonListNonVirtualMapKeys;
        this.nonListMapKeys = this.mapKeyService.nonListMapKeys;
        this.variableMapKeys = this.mapKeyService.variableMapKeys;
    }

    getRules() {
        this.appService.getData<UWResponse<RulesDTO[]>>(`Rules/${this.account.id}`).subscribe(data => {
            if (data.status === 'success') {
                this.rules = data.data;
                this.mapKeyService.rules = this.rules;
                if (this.accountMapKeys) {
                    this.accountMapKeys.rules = this.rules;
                }
            }
        });
    }

    startInterview() {
        this.routingService.navigateToRouteInNewTab(RoutesEnum.interview, {
            clientCode: this.client.code,
            accountCode: this.account.code,
            versionNumber: this.account.versionNumber
        });
    }

    getSecretKeys() {
        if (!this.secretKeys) {
            this.appService.getData<UWResponse<KeyVaultDTO[]>>(`ConfigData/GetSecretKeys`).subscribe(
                data => {
                    if (data.status === 'success') {
                        this.secretKeys = data.data.sort((a, b) => a.name.localeCompare(b.name, 'en', { sensitivity: 'base' }));
                    } else {
                        this.secretKeys = [];
                    }
                }
            );
        }
    }

    ngOnDestroy() {
        this.mapKeyService.clearData();
    }

}
