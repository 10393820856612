import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { BaseFormFieldServices, BaseInterviewFormFieldComponent } from '../base-interview-form-field/base-interview-form-field.component';

@Component({
    selector: 'eft-date',
    templateUrl: './eft-date.component.html',
    styleUrls: ['./eft-date.component.scss']
})
export class EFTDateComponent extends BaseInterviewFormFieldComponent implements OnInit {
    minDate: any = new Date();
    maxDate: any = new Date();
    invalidDates: Date[] = [];
    invalidDateStrings: string[] = [];
    dateModel: Date;

    @Input()
    set answeredQuestionForEft(val){
        this.updateEftDates(val);
    }

    @Input()
    set answeredQuestion(answeredQuestion) {
        this.updateEftDates(answeredQuestion);
    }

    constructor(
        public services: BaseFormFieldServices) {

        super(services);
    }

    private updateEftDates(answeredQuestion: any) {
        if (answeredQuestion && answeredQuestion.mapkeysId && (this.config.WednesdayMapkey || this.config.SpecificdayMapkey)) {
            if (answeredQuestion.mapkeysId === this.config.WednesdayMapkey || answeredQuestion.mapkeysId === this.config.SpecificdayMapkey) {
                this.configureEFTDate();

                // We are comparing against a separate string representation because the dates in `invalidDates`
                // have times, and the value does not.
                if (this.dateModel && this.invalidDateStrings.includes(this.dateModel.toLocaleDateString())) {
                    this.dateModel = undefined;
                    this.group.get(this.config.id).setValue(null);
                    this.group.get(this.config.id).updateValueAndValidity();
                }
            }
        }

        this.group.get(this.config.id)['formIndex'] = this.formIndex;
        this.group.get(this.config.id)['questionId'] = this.config.id;
    }

    ngOnInit() {
        const answerValue = this.group.get(this.config.id).value;
        if (answerValue) {
            this.dateModel = new Date(answerValue);
        }

        super.initialize({ inputType: 'eftDate' });
    }

    onFocus() {
        if (this.interviewMode) {
            this.configureEFTDate();
        }

        super.onFocus();
    }

    onBlur() {
        const fieldControl = this.group.get(this.config.id);
        if (this.dateModel) {
            fieldControl.setValue(moment(this.dateModel).format('MM/DD/YYYY'));
        } else {
            fieldControl.setValue('');
        }

        super.onBlur();

        this.group.get(this.config.id).updateValueAndValidity();
    }

    configureEFTDate() {
        const MaxAllowedDays = this.config.MaxAllowedDays;
        let MaxAllowedBackDate = this.config.MaxAllowedBackDate;
        if (!MaxAllowedBackDate || MaxAllowedBackDate == null) {
            MaxAllowedBackDate = 0;
        }

        const specificMapKeyId = this.config.SpecificdayMapkey;
        let onlyValidDayOfTheMonth = this.services.interviewService.getAnswerValueForMapKeyId(specificMapKeyId);

        if (onlyValidDayOfTheMonth) {
            onlyValidDayOfTheMonth = parseInt(onlyValidDayOfTheMonth);
            if (isNaN(onlyValidDayOfTheMonth)) {
                onlyValidDayOfTheMonth = 0;
            }
        } else {
            onlyValidDayOfTheMonth = 0;
        }


        // let WednesdayMapkey = 2;
        const wednesdayMapkeyId = this.config.WednesdayMapkey;
        let onlyWeekOfTheMonthWithSelectedWednesday = this.services.interviewService.getAnswerValueForMapKeyId(wednesdayMapkeyId);

        if (onlyWeekOfTheMonthWithSelectedWednesday) {
            onlyWeekOfTheMonthWithSelectedWednesday = parseInt(onlyWeekOfTheMonthWithSelectedWednesday);
            if (isNaN(onlyWeekOfTheMonthWithSelectedWednesday)) {
                onlyWeekOfTheMonthWithSelectedWednesday = 0;
            }
        } else {
            onlyWeekOfTheMonthWithSelectedWednesday = 0;
        }

        // restrict dates based on min, max, specificdaymapkey and wednesdaymapkey
        this.invalidDates = [];
        this.invalidDateStrings = [];

        const startDay = this.setMinDate(MaxAllowedBackDate);

        this.maxDate = moment().add(MaxAllowedDays, 'days').toDate();

        for (let i = startDay; i <= MaxAllowedDays; i++) {

            const queryDate = moment().add(i, 'days');
            const dateInPast = queryDate.toDate() <= moment().toDate();
            //queryDate is in the past, back dates are allowed, and it's the weekend or not the only valid day of the month
            if (dateInPast && MaxAllowedBackDate !== 0 && (queryDate.day() === 0 || queryDate.day() === 6 || onlyValidDayOfTheMonth !== queryDate.date())) {
                this.invalidDates.push(queryDate.toDate());
            } else if (onlyValidDayOfTheMonth > 0 && onlyValidDayOfTheMonth < 32) { //only valid day of the month is set
                // The queryDate is today or it is not the only valid day of the month
                if ((queryDate.toDate().getTime() === moment().toDate().getTime() && !dateInPast) || onlyValidDayOfTheMonth !== queryDate.date()) {
                    this.invalidDates.push(queryDate.toDate());
                }
            } else if (onlyWeekOfTheMonthWithSelectedWednesday > 0 && onlyWeekOfTheMonthWithSelectedWednesday < 5) {    // only week of the month is set

                // The date is not wednesday
                if (queryDate.day() !== 3) {
                    this.invalidDates.push(queryDate.toDate());
                } else {
                    const monthStartDay = moment(queryDate).startOf('month').day();
                    let weekAdditionNummber;
                    if (monthStartDay > 3) {
                        weekAdditionNummber = 0;
                    } else {
                        weekAdditionNummber = 1;
                    }
                    const week = queryDate.week() - moment(queryDate).startOf('month').week() + weekAdditionNummber;
                    // queryDate (Wednesday) week is not the same week as the only selected wednesday of the month
                    if (week !== onlyWeekOfTheMonthWithSelectedWednesday) {
                        this.invalidDates.push(queryDate.toDate());
                    }
                }
            }
        }

        if(this.config.AllowToday && this.config.AllowToday.toLowerCase() === 'true'){
            //remove today from invalid dates
            const todayIndex = this.invalidDates.findIndex(date => moment(date).format("MMDDYYYY") === moment().format("MMDDYYYY"));
            if (todayIndex > -1) {
                this.invalidDates.splice(todayIndex, 1);
            }
        }

        this.invalidDates.forEach(id => this.invalidDateStrings.push(id.toLocaleDateString()));
    }

    setMinDate(MaxAllowedBackDate) {
        let startDay = 0;

        if (MaxAllowedBackDate === 0) {
            this.minDate = moment().add(1, 'days').toDate();
            startDay = 0;
        } else {
            const backDate = this.addWeekdays(moment(), MaxAllowedBackDate * -1);
            startDay = backDate.diff(moment(), 'days');

            this.minDate = backDate.toDate();
        }

        return startDay;
    }

    addWeekdays(date, days) {
        const signal = days < 0 ? -1 : 1;
        days = Math.abs(days);
        const d = date.clone().add(Math.floor(days / 5) * 7 * signal, 'd');
        let remaining = days % 5;
        while (remaining) {
            d.add(signal, 'd');
            if (d.day() !== 0 && d.day() !== 6) {
                remaining--;
            }
        }
        return d;
    }
}
