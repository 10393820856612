import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Pipe } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { SafeHtmlPipe } from './safe-html.pipe';
import { FilterNullPipe } from './filter-null.pipe';
import { SecondsToTimePipe } from './secondstotime.pipe';
import { YesNoPipe } from './yesno.pipe';
import { FilterPipe } from './filter.pipe';
import { SortPipe } from './sort.pipe';
import {RemoveHtmlTagsPipe} from "app/pipes/removehtmltags.pipe";
import { FilterExactPipe } from './filter-exact.pipe';

@NgModule({
    declarations: [
        SafeHtmlPipe,
        FilterNullPipe,
        SecondsToTimePipe,
        YesNoPipe,
        FilterPipe,
        FilterExactPipe,
        SortPipe,
        RemoveHtmlTagsPipe
    ],
    imports: [
        CommonModule
    ],
    exports: [
        SafeHtmlPipe,
        FilterNullPipe,
        SecondsToTimePipe,
        YesNoPipe,
        FilterPipe,
        FilterExactPipe,
        SortPipe,
        RemoveHtmlTagsPipe
    ]
})
export class PipesModule { }
