import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthModule, AuthService } from '@auth0/auth0-angular';
import { NavigationComponent } from '../navigation/navigation.component';
import { SharedModule } from '../shared/shared.module';
import { AuthorizationComponent } from './authorization.component';
import { AuthHttpInterceptorExtended } from './authorization-interceptor';
import { FeatureToggleGuard } from '../services/guards/feature-toggle.guard';

const routes: Routes = [
    {
        path: 'authorize',
        component: AuthorizationComponent,
        canActivate: [FeatureToggleGuard],
        children: [
            { path: 'dashboard', component: NavigationComponent }
        ]
    }
];

@NgModule({
    declarations: [AuthorizationComponent ],
    imports: [
        CommonModule,
        SharedModule,
        RouterModule.forChild(routes),
        AuthModule.forRoot()
    ],
    exports: [AuthModule, RouterModule],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptorExtended, multi: true },
    ]
})
export class AuthorizationModule {
    static forRoot(): ModuleWithProviders<AuthorizationModule> {
        return {
            ngModule: AuthorizationModule,
            providers: [AuthService],
        };
    }
}
