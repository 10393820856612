
enum AccountSettings {
    CaseSummarySidebar = 'caseSummarySidebar',
    UWDecisionSection = 'uwDecisionSection',
    UWWorkbenchHiddenNotes = 'uwWorkbenchHiddenNotes',
    DisableInterviewRuleId = 'DisableInterviewRuleId',
    InterviewReadOnlyOnDisable = 'interviewReadOnlyOnDisable',
    PrefillQuestions = 'prefillQuestions',
    AddNoteForVariants = 'addNoteForVariants',
    UseDocusignV2 = 'useDocusignV2',    
    EvidenceNotRequiredForManualRequirements = 'evidenceNotRequiredForManualRequirements',
    disabledNamedActions = 'DisableNamedActions'
}

export default AccountSettings;
