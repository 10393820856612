import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { MrsColor } from 'app/enums';
import { RoutingService } from 'app/services';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ActionsEntity, TableColumnDefinition } from './models/table-models';

@Component({
    selector: 'mrs-table',
    templateUrl: './mrs-full-table.component.html',
    styleUrls: ['./mrs-full-table.component.scss']
})
export class MrsFullTableComponent implements OnInit, OnChanges, AfterViewInit {
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @Input() data: any;
    @Input() displayedColumns: TableColumnDefinition[];
    @Input() searchableFields: string[];
    @Input() showClientFilter = false;
    @Input() initialSort: string;
    @Input() addRecordAction: ActionsEntity;
    @Input() hasSearchOrFilterRow: boolean;
    @Input() tableWidth: string;
    @Output() tableEvent = new EventEmitter<any>();

    private hasInactiveUsersSub = new Subject<boolean>();
    hasInactiveUsers$ = this.hasInactiveUsersSub.asObservable();

    searchValue = '';
    mrsDataSource = new MatTableDataSource<any>();
    displayedColumnsDef: string[];
    showActionsColumn = false;

    filterValue = '';
    showInactiveUsers = true;
    hasInactiveUsers: boolean;
    showInactiveUsersFormGroup: UntypedFormGroup;

    constructor(
        fb: UntypedFormBuilder,
        public routingService: RoutingService,
        public sanitizer: DomSanitizer) {
        this.showInactiveUsersFormGroup = fb.group({
            hasInactiveUsersCtrl: false
        });
    }

    ngAfterViewInit(): void {
        this.showHideInactiveUsers();
        this.mrsDataSource.paginator = this.paginator;
        this.mrsDataSource.sort = this.sort;
    }

    setStyles(): void {
        setTimeout(() => {
            const elementCollection = Array.from(document.querySelectorAll('.name-line'));
            elementCollection?.forEach(item => {
                if (item.innerHTML.includes('(inactive)')) {
                    item.closest('tr').style.backgroundColor = MrsColor.DisabledTableColumn as string;
                    const originalHtml = item.innerHTML;
                    const newHtml = originalHtml.replace('(inactive)', `<span style="color:${MrsColor.BrandRedAccent as string};">(inactive)</span>`);
                    item.innerHTML = newHtml;
                }
            });
        }, 0);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.data?.currentValue !== changes.data?.previousValue && changes.data?.currentValue !== null) {
            this.setData();
            this.setStyles();
        }
    }

    ngOnInit(): void {
        this.showInactiveUsersFormGroup.valueChanges
            .pipe(
                tap(() => {
                    const hasInactiveUsers = this.data.some((user: { disabled: boolean; }) => user.disabled);
                    this.hasInactiveUsersSub.next(hasInactiveUsers);
                })
            )
            .subscribe(() => this.showHideInactiveUsers());
    }

    // tslint:disable-next-line: typedef
    setData() {
        this.hasInactiveUsers = this.data.filter(x => x.disabled)?.length > 0;
        this.hasInactiveUsersSub.next(this.hasInactiveUsers);
        this.mrsDataSource.sortingDataAccessor = (item, property) => {
            const def = this.displayedColumns.find(x => x.def === property);

            if (def.sortFields && def.sortFields.length > 0) {
                let val = '';
                def.sortFields.forEach(element => {
                    val += item[element];
                });

                return val;
            } else {
                return item[property];
            }
        };


        const actionColumn = this.displayedColumns.find(x => x.isAction);
        if (actionColumn) {
            this.data.forEach(element => {
                const numberOfShownActions = [];
                actionColumn.actions.forEach(action => {
                    if (action.permission) {
                        this.showActionsColumn = true;
                    }

                    let showAction = true;
                    action.conditions?.forEach(condition => {
                        if (element[condition.showProperty] !== condition.showValue) {
                            showAction = false;
                        }
                    });

                    if (showAction) {
                        numberOfShownActions.push(action);
                    }
                    element[action.actionType] = showAction;
                });

                element.numberOfActions = numberOfShownActions.length;
            });
        }

        this.mrsDataSource.data = this.data;
        this.mrsDataSource.paginator = this.paginator;
        this.mrsDataSource.sort = this.sort;
        this.mrsDataSource.filterPredicate = this.tableFilter(this.searchableFields);

        if (!this.showActionsColumn) {
            this.displayedColumns.splice(this.displayedColumns.indexOf(actionColumn), 1);
        }

        this.displayedColumnsDef = this.displayedColumns.map(x => x.def);

        this.showHideInactiveUsers();
        this.setStyles();
    }

    updateFilteredCases(): void {
        this.mrsDataSource.filter = this.filterValue.trim().toLowerCase();
        this.setStyles();
    }

    tableFilter(searchableFields): (data: any, filter: string) => boolean {
        return (data, filter): boolean => {
            let dataFound = false;
            searchableFields.forEach((field: string) => {
                if (data[field]?.toLowerCase().indexOf(filter) !== -1) {
                    dataFound = true;
                }
            });

            return dataFound;
        };
    }

    showHideInactiveUsers(): void {
        const selected = this.showInactiveUsersFormGroup.get('hasInactiveUsersCtrl').value;
        if (!selected) {
            this.mrsDataSource.data = this.mrsDataSource.data.filter(users => !users.disabled);
        } else {
            this.mrsDataSource.data = this.data;
        }

        this.setStyles();
    }

    filterData(selectedClients: string[]): void {
        const clientIds = selectedClients;

        if ((clientIds?.includes('00000000-0000-0000-0000-0000000000000') || clientIds?.length === 0)) {
            this.mrsDataSource.data = this.data;
            this.setStyles();
            return;
        }

        if (selectedClients?.length !== 0) {
            this.mrsDataSource.data = this.mrsDataSource.data.filter(userData => clientIds.some(r => userData.clientIds.indexOf(r) >= 0));
        }
        this.setStyles();
    }

    tableAction(columnAction, selectedRecord): void {
        this.tableEvent.emit(
            {
                action: columnAction,
                record: selectedRecord
            }
        );
    }

    linkClick(url, event: Event) {

        this.routingService.navigateToRoute(url);
        event.preventDefault();
    }
}
