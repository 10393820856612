<div [ngClass]="{'remote-signer-container': signer.status === 'sent' || signer.status === 'delivered' }">
  <div class="signer flex align-items-center" [ngClass]="{'signer-active': !envelopeStatus?.complete && (signer.status === 'sent' || signer.status === 'delivered')}">
    <div class="flex-1 flex-row">
      <div class="flex flex-1 flex-row" [ngClass]="{
          'signer-completed':signer.signerStatus === 'Signed'}">
          <div style="min-width: 32px;">
            <mat-spinner diameter="24" style="margin-right: .5rem" *ngIf="showSpinner && signer.signerStatus !== 'Signed'"></mat-spinner>
            <mat-icon *ngIf="signer.signerStatus === 'Signed'" style="margin-right: .5rem" svgIcon="check-circle" class="status-check-mark"></mat-icon>
          </div>
          <div class="signer-name">
            {{ signer.name }}
          </div>
      </div>
  </div>
    <div class="signer--actions">
      <mrs-btn-tertiary
        class="material-default"
        [textColor]="DarkBlueGray"
        mat-button
        id = "custom"
        [disabled]="true"
        *ngIf="signer.signerStatus !== 'Signed'"
        [buttonLabel]="customStatus"></mrs-btn-tertiary>

      <mrs-btn-tertiary
          [textColor]="UIGreen"
          class="material-default"
          mat-button
          id = "signed"
          [disabled]="true"
          *ngIf="signer.signerStatus === 'Signed'"
          [buttonLabel]="'Signed'"></mrs-btn-tertiary>
    </div>
  </div>
  <div *ngIf="!envelopeStatus?.complete && (signer.status === 'sent' || signer.status === 'delivered') ">
    <div class="flex flex-column signer-instructions signer-border">
      <div style="padding-bottom: 18px;">Signing instructions for {{ signer.name }}:</div>
      <div style="padding-bottom: 16px;">
        <ul>
          <li>Tell {{signer.name}} to go to <br><b>{{url}}</b></li>
          <li>Tell them to enter secure code <b>{{signer.signerInfo.accessCode}}</b></li>
          <li>Tell them to enter last four digits of <b>SSN</b></li>
        </ul>
      </div>
    </div>
    <div class="flex flex-column signer-instructions signer-border signer-action remote-signer-button" style="padding-bottom: 16px; padding-top: 16px;"
      *ngIf="signer.status !== 'completed' && email !== ''" matRipple> 
      <div (click)="sendCode('email')"  >
        <mat-icon>mail_outline</mat-icon> Email instructions to {{email}}
      </div>
    </div>
    <div class="flex flex-column signer-instructions signer-border signer-action remote-signer-button"  style="padding-bottom: 16px; padding-top: 16px;"
    *ngIf="signer.status !== 'completed' && phoneNumber !== ''" matRipple>      
      <div (click)="sendCode('sms')"  >
        <mat-icon>chat</mat-icon>Text instructions to {{phoneNumber}}
      </div>
    </div>
  </div>
</div>