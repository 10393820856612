import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IConfigService } from '@Config';
import { CaseMapKeyHistory } from '@Models';
import { BaseService } from '@Services';
import { AppService } from 'app';

import { ExecutionLog, GenerateDocumentStatusModel, ProcessInterviewTask } from '../models/execution-log.interface';


@Injectable({
    providedIn: 'root'
})
export class CaseMetricService extends BaseService {

    publicApiBaseUrl: string;

    constructor(public appService: AppService, public configService: IConfigService, public httpClient: HttpClient) {
        super(appService, configService, httpClient);
        this.publicApiBaseUrl = this.configService.getConfiguration().publicApiUrl;
    }

    getRuleExecutionLogPaths(caseId) {
        const clientId = this.appService.getClientId('');
        const url = `${this.publicApiBaseUrl}client/${clientId}/case/${caseId}/executionlogs/rules`;
        return super.getData<string[]>(url);
    }

    getVmkExecutionLogPaths(caseId) {
        const clientId = this.appService.getClientId('');
        const url = `${this.publicApiBaseUrl}client/${clientId}/case/${caseId}/executionlogs/vmks`;
        return super.getData<string[]>(url);
    }

    getRuleExecutionLog(caseId, path: string) {
        const clientId = this.appService.getClientId('');
        const url = `${this.publicApiBaseUrl}client/${clientId}/case/${caseId}/executionlogs/rules`;
        return super.postData<ExecutionLog[]>(url, `"${path}"`);
    }

    getVmkExecutionLog(caseId, path: string) {
        const clientId = this.appService.getClientId('');
        const url = `${this.publicApiBaseUrl}client/${clientId}/case/${caseId}/executionlogs/vmks`;
        return super.postData<ExecutionLog[]>(url, `"${path}"`);
    }
    getDocumentExecutionLog(caseId) {
        const clientId = this.appService.getClientId('');
        const url = `${this.publicApiBaseUrl}client/${clientId}/case/${caseId}/executionlogs/documents`;
        return super.getData<GenerateDocumentStatusModel[]>(url);
    }


    getSTPExecutionLog(caseId) {
        const clientId = this.appService.getClientId('');
        const url = `${this.publicApiBaseUrl}client/${clientId}/case/${caseId}/executionlogs/stp`;
        return super.getData<ProcessInterviewTask[]>(url);
    }

    getCaseMapkeyHistory(caseId) {
        const clientId = this.appService.getClientId('');
        const url = `${this.publicApiBaseUrl}client/${clientId}/case/${caseId}/mapkeys/history`;
        return super.getData<CaseMapKeyHistory[]>(url);
    }
}
