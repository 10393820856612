export enum IntegrationEnum {
    Aura = '00000000-0000-0000-0000-000000000057',
    BrainTree = '00000000-0000-0000-0000-000000000043',
    Docusign = '00000000-0000-0000-0000-000000000025',
    HanoverRe = '00000000-0000-0000-0000-000000000066',
    Magnum = '00000000-0000-0000-0000-000000000037',
    IntelliScript = '00000000-0000-0000-0000-000000000021',
    Irix = '00000000-0000-0000-0000-000000000008',
    PrimorisEmbedded = '00000000-0000-0000-0000-000000000056',
    PortalOne = '00000000-0000-0000-0000-000000000061',
    Stripe = '00000000-0000-0000-0000-000000000077',
    TrustCommerce = '00000000-0000-0000-0000-000000000048',
    LexistNexisLifeDataPrefill = '00000000-0000-0000-0000-000000000089',
    DigitalOwl = '00000000-0000-0000-0000-000000000094'
}
