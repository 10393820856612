import { EditableConstantMapkey } from 'app/account/account-config/account-mapkeys/models/constant-mapkey';
import { MapKeyType } from 'app/enums';
import { MapkeyNode } from 'app/services';


export interface MapkeyOperation {
    integrationMapkeyIntegrationName?: string;
    isSystemGenerated: boolean;
    mapkeyTypeDisplay: string;
    isView: boolean;
    entityType: EntityType;
    isNew?: boolean;
    selectedNode: MapkeyNode;
    isModal?: boolean;
    allowedTypes?: MapKeyType[];
    constant?: EditableConstantMapkey;
    dynamicList?: any;
    isDynamicList?: boolean;
    entityHierarchy?: string;
    name?: string;
    isVirtualExpression?: boolean;
    virtualExpression?: string;
    virtualExpressionParsed?: string;
    title?: string;
    id?: string;
    mapKeyEntityId?: string;
    accountId?: string;
    clientId?: string;
    isConstant?: boolean;
    isList?: boolean;
    isEdit?: boolean;
}

export enum EntityType {
    Entity = 'Entitiy',
    Constant = 'Constant',
    Mapkey = 'Mapkey',
    DynamicList = 'DynamicList'
}
