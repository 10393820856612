import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MrsColor } from 'app/enums';

export enum ConfirmationDialogMessageMode {
    Text,
    Html
}

export interface ConfirmationDialogData {
    title?: string;
    message: string;
    messageMode?: ConfirmationDialogMessageMode;
    key?: string;
    onOk?: () => void;
    accept?: () => void;
    reject?: () => void;
    onCancel?: () => void;
    onClose?: () => void;
    okLabel?: string;
    cancelLabel?: string;
    showCancel?: boolean;
    cancelTextColor?: MrsColor;
    okTextColor?: MrsColor;
    isDestructive?: boolean;
}

@Component({
    selector: 'confirmation-dialog',
    templateUrl: 'confirmation-dialog.html',
})
export class ConfirmationDialogComponent {
    messageMode = ConfirmationDialogMessageMode;

    constructor(
        public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData) {
        this.data.title = this.data.title || 'Confirm';
        this.data.okLabel = this.data.okLabel || 'Ok';
        this.data.cancelLabel = this.data.cancelLabel || 'Cancel';
        this.data.messageMode = this.data.messageMode || ConfirmationDialogMessageMode.Text;
    }

    onOk(): void {
        this.dialogRef.close(true);
    }

    onCancel(): void {
        this.dialogRef.close(false);
    }

    accept(): void {
        this.dialogRef.close(true);
    }

    reject(): void {
        this.dialogRef.close(false);
    }
}
