<div>
    <button mat-menu-item
        *ngIf="caseRequirement.hasIntegration"
        [disabled]="!caseRequirement.canRun"
        (click)="handleMenuAction(UWRequirementTableActions.RunIntegration, caseRequirement)">
        <span *ngIf="props.showRequirementLabel; else runLabel">Run</span>
        <ng-template #runLabel>Order</ng-template>
    </button>
    <button mat-menu-item
        *ngIf="!caseRequirement.hasIntegration && !caseRequirement.hasSection"
        (click)="handleMenuAction(UWRequirementTableActions.OrderSubmitted, caseRequirement)">
        Order
    </button>
    <button mat-menu-item
        *ngIf="caseRequirement.hasSection"
        (click)="handleMenuAction(UWRequirementTableActions.OpenSection, caseRequirement)">
        Order
    </button>        
    <button mat-menu-item
        *ngIf="!caseRequirement.hasIntegration || !props.showRequirementLabel"
        (click)="handleMenuAction(UWRequirementTableActions.FollowUp, caseRequirement)">
        <span *ngIf="!caseRequirement.dateFollowUp; else updateFollowUpTemplate">Add Follow Up</span>
        <ng-template #updateFollowUpTemplate>Edit Follow Up</ng-template>
    </button>
    <button mat-menu-item
        (click)="handleMenuAction(UWRequirementTableActions.EditSubtitle, caseRequirement)">
        <span *ngIf="caseRequirement.subtitle && caseRequirement.subtitle != ''; else addSubtitleTemplate">Edit Subtitle</span>
        <ng-template #addSubtitleTemplate>Add Subtitle</ng-template>
    </button>
    <mat-divider/>
    <button mat-menu-item
        *ngIf="!props.hideCompleteMenuOption && !caseRequirement.hasIntegration && caseRequirement.section === null"
        (click)="handleMenuAction(UWRequirementTableActions.Completed, caseRequirement)">
        Complete
    </button>
    <button mat-menu-item
        *ngIf="!caseRequirement.hasIntegration || !props.showRequirementLabel"
        (click)="handleMenuAction(UWRequirementTableActions.Waive, caseRequirement)">
        Waive
    </button>
    <button mat-menu-item
        *ngIf="!caseRequirement.hasIntegration && !props.hideDeleteMenuOption"
        (click)="handleMenuAction(UWRequirementTableActions.Delete, caseRequirement)">
        Delete
    </button>    

</div>