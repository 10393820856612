import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { BaseInterviewFormFieldComponent, BaseFormFieldServices } from '../base-interview-form-field/base-interview-form-field.component';
import { FeatureToggle } from '@Enums';

@Component({
    selector: 'height-answer',
    templateUrl: './height-answer.component.html',
    styleUrls: ['./height-answer.component.scss']
})
export class HeightAnswerComponent extends BaseInterviewFormFieldComponent implements OnInit {
    feetValue;
    inchValue;

    heightFeatureToggle = false;

    constructor(private fb: UntypedFormBuilder,
        public services: BaseFormFieldServices) {

        super(services);
    }

    ngOnInit() {
        this.heightFeatureToggle = this.services.featureService.getByName(FeatureToggle.CaseRuntimeHeightDefaultToBlank).enabled;
                    
        if (!this.heightFeatureToggle) {
            this.feetValue = 0;
            this.inchValue = 0;
        }

        let currentAnswerValue = this.group.get(this.config.id).value;
        // there are two fields in this component, feet value and inch value. Their values are calculated based on answer value.
        // while submitting, the values in both the above fields are then recalculated to map the original question's answervalue
        if (currentAnswerValue) {
            currentAnswerValue = parseInt(currentAnswerValue);
            this.feetValue = Math.floor(currentAnswerValue / 12);
            this.inchValue = currentAnswerValue % 12;
        }
        if (!this.group.get('inch' + this.config.id)) {
            this.group.addControl('inch' + this.config.id, this.fb.control(this.inchValue));
        }
        if (!this.group.get('feet' + this.config.id)) {
            this.group.addControl('feet' + this.config.id, this.fb.control(this.feetValue));
        }

        const finalValue = this.feetValue * 12 + this.inchValue;
        if (finalValue) {
            this.group.get(this.config.id).setValue(finalValue);
        } else {
            this.group.get(this.config.id).setValue(' ');
        }

        super.initialize({
            inputType: 'text',
            additionalFormFieldNames: [
                `feet${this.config.id}`,
                `inch${this.config.id}`
            ]
        });
        
    }

    validateHeight() {
        // const EMAIL_REGEXP = ...

        // return EMAIL_REGEXP.test(c.value) ? null : {
        //     validateEmail: {
        //         valid: false
        //     }
        // };
        // c.value
    }

    onFocus() {
        if (this.isLiveMode()) {
            this.onInchesFocus();
        }

        super.onFocus();
    }

    onBlur() {
        if (this.isLiveMode()) {
            this.onInchesFocus();
            this.calculateFieldValue();
        }

        super.onBlur();
    }

    // validate inches value to be less than 12.
    onInchesFocus() {
        const feetValue =  this.group.get('feet' + this.config.id).value;
        if (feetValue > 0) {
            const validations = [];
            validations.push(Validators.min(0));
            validations.push(Validators.max(12));
            this.group.get('inch' + this.config.id).setValidators(validations);
            this.group.get('inch' + this.config.id).updateValueAndValidity();
        } else {
            this.group.get('inch' + this.config.id).clearValidators();
            this.group.get('inch' + this.config.id).updateValueAndValidity();
        }
    }

    // add both feet and inch value and calculate answervalue.
    calculateFieldValue() {
        const feetValue = this.group.get('feet' + this.config.id).value;
        const inchValue = this.group.get('inch' + this.config.id).value;
        const finalValue = feetValue * 12 + inchValue;

        if (this.heightFeatureToggle && !this.config.isRequired && Number.isNaN(finalValue)) {
            this.group.get(this.config.id).setValue(' ');
        }else{
            this.group.get(this.config.id).setValue(finalValue);
        }        
    }
}
