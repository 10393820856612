import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { ClientDataService } from '../';
import { Client } from '../../models';

@Injectable()
export class ClientsResolverService implements Resolve<Client[]> {
    constructor(private clientDataService: ClientDataService) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Client[]> {
        return lastValueFrom(this.clientDataService.getClients(true));
    }
}
