<div class="flex flex-column" style="align-items: center; ">
    <div style="margin-top:100px">
        <h1 style="font-weight: 700 !important">Sorry, we can't find that page.</h1>
    </div>
    <div style="margin-top: 18px">
        <h5 style="font-weight: 700 !important">The page you are looking for does not exist or is no longer available.
        </h5>
    </div>
    <div style="margin-top: 28px">
        <mrs-btn-primary buttonLabel="Go Home" buttonId="goHomeBtn" (onClick)="goHome()"></mrs-btn-primary>
    </div>
</div>