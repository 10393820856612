import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    UWDecisionGetRequest,
    UWDecisionNote,
    UWDecisionPatch,
    UWDecisionPatchRequest,
    UWIntegrationRequirement,
    UWLabel,
    UWOrderIntegrationRequest,
} from '@Underwriting/models';
import { AppService } from 'app/app.service';
import { IConfigService } from 'app/config/iconfigservice';
import { BaseService } from 'app/services';
import * as _ from 'lodash';
import { lastValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export default class UWDecisionServices extends BaseService {
    private _apiUrl: string;
    private callCount = 0;

    constructor(
        public configService: IConfigService,
        public httpClient: HttpClient,
        public appService: AppService
    ) {
        super(appService, configService, httpClient);
        this._apiUrl = this.configService.getConfiguration().publicApiUrl.replace('v2', 'v3');
    }

    async getLabels(workFlowId: string): Promise<UWLabel[]> {
        return lastValueFrom(this.appService.getData(`UwdecisionLabel/${workFlowId}`).pipe(
            map(result => _.sortBy(result.data, 'name'))
        ));
    }

    async getDecisionNote(request: UWDecisionGetRequest): Promise<UWDecisionNote> {
        const url = `${this._apiUrl}client/${request.ClientCode}/account/${request.AccountCode}/case/${request.CaseId}/decision/note`;

        this.display(true);
        return lastValueFrom(this.httpClient.get<UWDecisionNote>(url)).finally(() => {
            this.display(false);
        });
    }

    async patchDecision(request: UWDecisionPatchRequest): Promise<UWDecisionPatch> {
        const url = `${this._apiUrl}client/${request.ClientCode}/account/${request.AccountCode}/case/${request.CaseId}/decision`;

        this.display(true);
        return lastValueFrom(this.httpClient.patch<UWDecisionPatch>(url, request.UWDecisionPatch)).finally(() => {
            this.display(false);
        });
    }

    getUnderwritingRequirements(caseId: string) {
        const clientId = this.appService.getClientId('');
        return super.getData<UWIntegrationRequirement[]>(`${this._apiUrl}client/${clientId}/case/${caseId}/uwrequirement`, undefined, false);
    }

    runUnderwritingRequirementV2(caseId: string, request: UWOrderIntegrationRequest) {
        const clientId = this.appService.getClientId('');
        return super.postData<UWIntegrationRequirement[]>(`${this._apiUrl}client/${clientId}/case/${caseId}/uwrequirement`, request);
    }

    display(value: boolean) {
        if (this.callCount < 0) this.callCount = 0;

        if (value) this.callCount += 1;
        else this.callCount -= 1;
        
        this.appService.status.next(this.callCount);
    }
}
