import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AppService } from '../../app.service';
import { CaseSummaryService, FeatureManagerService, RoutesEnum, RoutingService } from '../../services';
import { ReportDownloadService } from '../../services/reportdownload.service';

import { UISelectData } from "@DTOs";
import { AccountStatusEnum, FeatureToggle } from "@Enums";
import { MatOption } from '@angular/material/core';
import { SecondsToTimePipe } from 'app/pipes/secondstotime.pipe';
import { TableColumnDefinition } from 'app/shared/components/mrs-table/models/table-models';
import { Account } from '../../models';
import moment = require('moment');

@Component({
    selector: 'case-detail-report',
    host: { 'class': 'custom-scrollbar' },
    templateUrl: './case-detail-report.component.html',
    styleUrls: ['./case-detail-report.component.scss']
})
export class CaseDetailReportComponent implements OnInit {

    public accounts: Account[] = [];
    public caseStatus: any;
    public orderForm: UntypedFormGroup;
    public filteredData: any = [];
    public noDataPlaceholder = false;
    public notRunPlaceholder = true;
    public selectedRecord: any;
    public reportDateTypes: UISelectData[];
    public activeAccount: Account;
    public clients: [];

    @Input() scrollWidth;
    @Input() scrollHeight;

    @ViewChild('allSelected') private allSelected: MatOption;

    formSubmitted: boolean;
    timeZone: string;
    displayedColumns: TableColumnDefinition[];
    reportData: any;
    searchableFields: [];
    reportUpdateToggle = false;
    clientAccountOverride = false;

    constructor(
        private appService: AppService,
        private reportDownloadService: ReportDownloadService,
        private _fb: UntypedFormBuilder,
        private routingService: RoutingService,
        private secondsToTimePipe: SecondsToTimePipe,
        private caseSummaryService: CaseSummaryService,
        private featureManagerService: FeatureManagerService
    ) {
        this.clientAccountOverride = this.featureManagerService.getByName(FeatureToggle.GlobalReportsCaseDetailReportClientAccountSplit).enabled;
    }

    ngOnInit() {
        this.reportUpdateToggle = this.featureManagerService.getByName(FeatureToggle.GlobalOrderDetailReportUpdates).enabled;
        this.timeZone = this.appService.getTimeZone();
        this.appService.getAllConfigData().then((data) => {
            const configData = data;
            this.reportDateTypes = configData.reportDateTypes as UISelectData[];
            this.setDefaultCriteria();
            this.caseStatus = configData.caseStatus;
            this.isReturningToReport();
        });
        this.orderForm = this._fb.group({
            account: ['', Validators.required],
            dateType: ['', Validators.required],
            startDate: ['', Validators.required],
            endDate: ['', Validators.required],
            orderStateIds: ['', Validators.required]
        });
        this.setDefaultCriteria();
        this.formSubmitted = false;
        this.getTableConfig();


    }

    setDefaultCriteria() {
        if (this.orderForm?.get('dateType')?.value === '' && this.reportDateTypes?.length > 0) {
            if(this.reportUpdateToggle) {
                this.orderForm.get('dateType').setValue(this.reportDateTypes.find(x => x.name === 'Application Received Date').id);
            }else{
                this.orderForm.get('dateType').setValue(this.reportDateTypes.find(x => x.name === 'Received Date').id);
            }
        }
        if (this.orderForm?.get('orderStateIds')?.value === '') {
            this.orderForm.get('orderStateIds').setValue(['ALL']);
        }
    }

    isReturningToReport() {
        // todo: this should really get cleaned up.  Ideas:
        // Put the report param selections into the querystring so we can a) refresh, b) rehydrate on "back"
        // Shouldn't need to "set selected client id" once we are completely driven off the URL for client id

        const url = this.appService.getPreviousUrl();
        if (url && url.includes('/summary') && this.appService.getOrderReports()) {
            const orderReportFormData: any = this.appService.getOrderReports();
            this.orderForm.patchValue(orderReportFormData);
            this.getReport();
        }
    }

    getFormattedCustomerAccount(customerAccount) {
        return `${customerAccount.name}<br/><span style='color: gray;font-size:12px;'>${customerAccount.code}</span>`;
    }
    getAccountStatusColor(statusId) {
        if (AccountStatusEnum.Active === statusId) {
            return "#1D8F0B";
        }
        else if (AccountStatusEnum.Draft === statusId) {
            return '#CF6B0F';
        }
        return '#6A6A6A';
    }
    getFormattedAccountVersion(accountVersion) {
        const statusName = Object.keys(AccountStatusEnum)[Object.values(AccountStatusEnum).indexOf(accountVersion.statusId)];
        const statusColor = this.getAccountStatusColor(accountVersion.statusId);
        return `v${accountVersion.versionNumber}-${accountVersion.versionLabel}<br/><span style='font-weight: 700;color: ${statusColor};font-size:12px;'>${statusName.toUpperCase()}</span>`;
    }



    populateReportDataUpdated(data) {
        return {
            ...data,
            receivedDate: moment(data.receivedDate).utc().local().format('MM/DD/yyyy HH:mm:ss'),
            completedDate: data.completedDate && data.completedDate !== null ? moment(data.completedDate).utc().local().format('MM/DD/yyyy HH:mm:ss') : '',
            doneDate: data.doneDate && data.doneDate !== null ? moment(data.doneDate).utc().local().format('MM/DD/yyyy HH:mm:ss') : '',
            callLength: data.callLength && data.callLength !== null && data.callLength !== 0 ? this.secondsToTimePipe.transform(data.callLength) : null,
            transmitDateTime: data.transmitDateTime,
            caseUrl: this.getCaseUrl(data),
            customerAccount: this.getFormattedCustomerAccount(JSON.parse(data.customerAccount)),
            accountVersion: this.getFormattedAccountVersion(JSON.parse(data.accountVersion)),
        };
    }

    populateReportData(data) {
        if (this.reportUpdateToggle) {
            return this.populateReportDataUpdated(data);
        }
        return {
            ...data,
            receivedDate: moment(data.receivedDate).utc().local().format('MM/DD/yyyy HH:mm:ss'),
            completedDate: data.completedDate && data.completedDate !== null ? moment(data.completedDate).utc().local().format('MM/DD/yyyy HH:mm:ss') : '',
            callLength: this.secondsToTimePipe.transform(data.callLength),
            transmitDateTime: data.transmitDateTime && data.transmitDateTime !== null ? "Yes" : "No",
            caseUrl: this.getCaseUrl(data)
        };
    }

    getReport() {
        this.formSubmitted = true;
        if (this.orderForm.valid) {
            this.filteredData = [];
            this.appService.setOrderReports(this.orderForm.value);

            const params = this.getParams();
            const url = `Reports/GetOrderDetailReport`;
            this.appService.postData(url, params, false, false, params.account.clientId)
                .subscribe(
                    data => {
                        if (data.status === 'success') {
                            this.activeAccount = this.getSelectedAccount();
                            this.filteredData = data.data;
                        }

                        this.noDataPlaceholder = !this.filteredData.length;
                        this.notRunPlaceholder = false;
                        this.getTableConfig();
                        this.reportData = data.data.map((data) => (this.populateReportData(data)));
                    }
                );
        }
    }

    getSelectedAccount(): Account {
        return this.orderForm.get('account').value;
    }

    downloadReport() {
        const params = this.getParams();
        if(this.reportUpdateToggle){
            this.reportDownloadService.downloadReport('application/csv', 'Reports/DownloadCSV/GetCaseDetailReport', params, this.getSelectedAccount().clientId);
        }else{
            this.reportDownloadService.downloadReport('application/csv', 'Reports/DownloadCSV/GetOrderDetailReport', params, this.getSelectedAccount().clientId);
        }

    }
    getUpdatedTableConfig() {
        this.displayedColumns = [
            {
                def: 'actions',
                header: '',
                columnStyles:
                {
                    'width': '10px',

                },
                isAction: true,
                actions: [
                    {
                        name: null,
                        icon: 'open_in_new',
                        actionType: 'openInNewTab',
                        permission: true,
                    }
                ]
            },
            {
                def: 'caseNumber',
                header: 'Case #',
                sortFields: ['caseNumber'],
                columnStyles:
                {
                    'width': '150px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'caseNumber',
                        isLink: true,
                        urlField: 'caseUrl'
                    }
                ]
            },
            {
                def: 'policyNumber',
                header: 'Policy #',
                sortFields: ['policyNumber'],
                columnStyles:
                {
                    'width': '150px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'policyNumber',
                    }
                ]
            },
            {
                def: 'customerAccount',
                header: 'Account',
                sortFields: ['customerAccount'],
                columnStyles:
                {
                    'width': '150px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'customerAccount',
                        isHTML: true
                    }
                ]
            },
            {
                def: 'accountVersion',
                header: 'Version',
                sortFields: ['accountVersion'],
                columnStyles:
                {
                    'width': '100px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'accountVersion',
                        isHTML: true
                    }
                ]
            },
            {
                def: 'receivedDate',
                header: 'App Received (CST)',
                sortFields: ['receivedDate'],
                columnStyles:
                {
                    'width': '160px',
                },
                rowDefs: [
                    {
                        className: 'break-on-spaces',
                        dataName: 'receivedDate',
                    }
                ]
            },
            {
                def: 'representativeName',
                header: 'App Received By',
                sortFields: ['representativeName'],
                columnStyles:
                {
                    'width': '150px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'representativeName',
                    }
                ]
            },
            {
                def: 'orderState',
                header: 'Status',
                sortFields: ['orderState'],
                columnStyles:
                {
                    'width': '100px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'orderState',
                    }
                ]
            },
            {
                def: 'applicant',
                header: 'Applicant',
                sortFields: ['applicant'],
                columnStyles:
                {
                    'width': '150px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'applicant',
                    }
                ]
            },
            {
                def: 'agent',
                header: 'Agent',
                sortFields: ['agent'],
                columnStyles:
                {
                    'width': '100px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'agent',
                    }
                ]
            },
            {
                def: 'decisionLabel',
                header: 'Decision',
                sortFields: ['decisionLabel'],
                columnStyles:
                {
                    'width': '120px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'decisionLabel',
                    }
                ]
            },
            {
                def: 'completedDate',
                header: 'App Completed (CST)',
                sortFields: ['completedDate'],
                columnStyles:
                {
                    'width': '160px',
                },
                rowDefs: [
                    {
                        className: 'break-on-spaces',
                        dataName: 'completedDate',
                    }
                ]
            },
            {
                def: 'completedByName',
                header: 'App Completed By',
                sortFields: ['completedByName'],
                columnStyles:
                {
                    'width': '200px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'completedByName',
                    }
                ]
            },
            {
                def: 'doneDate',
                header: 'Case Completed (CST)',
                sortFields: ['doneDate'],
                columnStyles:
                {
                    'width': '160px',
                },
                rowDefs: [
                    {
                        className: 'break-on-spaces',
                        dataName: 'doneDate',
                    }
                ]
            },
            {
                def: 'lastModifiedByName',
                header: 'Last Modified By',
                sortFields: ['lastModifiedByName'],
                columnStyles:
                {
                    'width': '200px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'lastModifiedByName',
                    }
                ]
            },
            {
                def: 'callLength',
                header: 'Call Length',
                sortFields: ['callLength'],
                columnStyles:
                {
                    'width': '100px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'callLength',
                    }
                ]
            },
            {
                def: 'transmitDateTime',
                header: 'Transmitted (UTC)',
                sortFields: ['transmitDateTime'],
                columnStyles:
                {
                    'width': '150px',
                },
                rowDefs: [
                    {
                        className: 'break-on-spaces',
                        dataName: 'transmitDateTime',
                    }
                ]
            }
        ];

    }
    getTableConfig() {
        if (this.reportUpdateToggle) {
            this.getUpdatedTableConfig();
            return;
        }
        this.displayedColumns = [
            {
                def: 'caseNumber',
                header: 'Case #',
                sortFields: ['caseNumber'],
                columnStyles:
                {
                    'width': '250px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'caseNumber',
                        isLink: true,
                        urlField: 'caseUrl'
                    }
                ]
            },
            {
                def: 'customerAccount',
                header: 'Customer Account',
                sortFields: ['customerAccount'],
                columnStyles:
                {
                    'width': '150px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'customerAccount',
                    }
                ]
            },
            {
                def: 'accountVersion',
                header: 'Account Version',
                sortFields: ['accountVersion'],
                columnStyles:
                {
                    'width': '100px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'accountVersion',
                    }
                ]
            },
            {
                def: 'applicant',
                header: 'Applicant Name',
                sortFields: ['applicant'],
                columnStyles:
                {
                    'width': '150px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'applicant',
                    }
                ]
            },
            {
                def: 'receivedDate',
                header: 'Received Date',
                sortFields: ['receivedDate'],
                columnStyles:
                {
                    'width': '100px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'receivedDate',
                    }
                ]
            },
            {
                def: 'completedDate',
                header: 'Completed Date',
                sortFields: ['completedDate'],
                columnStyles:
                {
                    'width': '120px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'completedDate',
                    }
                ]
            },
            {
                def: 'policyNumber',
                header: 'Policy #',
                sortFields: ['policyNumber'],
                columnStyles:
                {
                    'width': '150px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'policyNumber',
                    }
                ]
            },
            {
                def: 'agent',
                header: 'Agent',
                sortFields: ['agent'],
                columnStyles:
                {
                    'width': '100px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'agent',
                    }
                ]
            },
            {
                def: 'orderState',
                header: 'Order State',
                sortFields: ['orderState'],
                columnStyles:
                {
                    'width': '200px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'orderState',
                    }
                ]
            },
            {
                def: 'decisionLabel',
                header: 'Decision Label',
                sortFields: ['decisionLabel'],
                columnStyles:
                {
                    'width': '120px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'decisionLabel',
                    }
                ]
            },
            {
                def: 'callLength',
                header: 'Call Length',
                sortFields: ['callLength'],
                columnStyles:
                {
                    'width': '100px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'callLength',
                    }
                ]
            },
            {
                def: 'representativeName',
                header: 'Representative Name',
                sortFields: ['representativeName'],
                columnStyles:
                {
                    'width': '100px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'representativeName',
                    }
                ]
            },
            {
                def: 'transmitDateTime',
                header: 'File Transferred',
                sortFields: ['transmitDateTime'],
                columnStyles:
                {
                    'width': '75px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'transmitDateTime',
                    }
                ]
            },
            {
                def: 'actions',
                header: '',
                columnStyles:
                {

                },
                isAction: true,
                actions: [
                    {
                        name: '',
                        icon: 'open_in_new',
                        actionType: 'openInNewTab',
                        permission: true,
                    }
                ]
            }
        ];


    }

    getParams() {
        const params = JSON.parse(JSON.stringify(this.orderForm.value));
        params.accountId = params.account.id;
        params.startDate = new Date(params.startDate);
        const end = new Date(params.endDate);
        end.setHours(23, 59, 59, 999);
        params.endDate = end;

        const idx = params.orderStateIds.indexOf("ALL");
        if (idx > -1) {
            params.orderStateIds.splice(idx, 1);
        }
        return params;
    }

    getCaseUrl(data) {
        const clientCode = this.activeAccount.code.split('-');

        return this.routingService.createUrl(RoutesEnum.caseSummary, {
            clientCode: clientCode[0],
            accountCode: this.activeAccount.code,
            versionNumber: data.versionNumber,
            caseId: data.caseId
        });
    }


    togglePerOne(all) {
        if (this.allSelected.selected) {
            this.allSelected.deselect();
            return false;
        }
        if (this.orderForm.controls.orderStateIds.value.length == this.caseStatus.length)
            this.allSelected.select();

    }
    toggleAllSelection() {
        if (this.allSelected.selected) {
            this.orderForm.controls.orderStateIds
                .patchValue([...this.caseStatus.map(item => item.id), "ALL"]);
        } else {
            this.orderForm.controls.orderStateIds.patchValue([]);
        }
    }

    tableAction(event) {
        if (event.action?.actionType === 'openInNewTab') {
            const clientCode = this.activeAccount.code.split('-');

            this.caseSummaryService.routeToCase({
                clientCode: clientCode[0],
                accountCode: this.activeAccount.code,
                accountVersionNumber: event.record.versionNumber,
                caseId: event.record.caseId
            }, true);
        }
    }
}
