import { AccountMinimalEnhanced, AccountRule, AccountRuleChange, AccountRuleSelectorColumnNames, AccountRuleSelectorTableSort, AccountRuleSelectorSortDirection, AuditCaseEnhanced, AuditCasesGroup, AuditDateTypes, AuditGroupByDisplayTypes, AuditGroupByTypes, AuditSaveDialog, AuditUtils, AuditViewStatusFilters, CreateAuditRequest } from '@Audit';
import { AuditService } from '@Audit/services/audit.service';
import { RulesDTO } from '@DTOs';
import { Account, Client } from '@Models';
import { AccountService, CaseSummaryRoute, CaseSummaryService, ClientContextService, NotificationService, NotificationSeverity, RulesDataService } from '@Services';
import { FormUtils, TimeUtils, Utils } from '@Utils';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, startWith, switchMap } from 'rxjs';
import { SubSink } from 'subsink';

export type DisplayAuditDateType = {
    displayName: string,
    apiValue: AuditDateTypes
}

export type DisplayAuditGroupByType = {
    displayName: string,
    apiValue: AuditGroupByTypes
}

export type AccountRuleForm = {
    accountId: FormControl<string>;
    ruleId: FormControl<string>;
}

export type AuditForm = {
    name: FormControl<string>;
    clientId: FormControl<string>;
    auditDateType: FormControl<DisplayAuditDateType>;
    groupBy: FormControl<DisplayAuditGroupByType>;
    startDate: FormControl<Date>;
    endDate: FormControl<Date>;
    auditGoalPercentage: FormControl<number>;
    ruleFilter: FormControl<string | RulesDTO>;
    accountRules: FormArray<FormGroup<AccountRuleForm>>;
};

@Component({
    selector: 'audit-create-page',
    templateUrl: './audit-create-page.component.html',
    styleUrls: ['./audit-create-page.component.scss']
})
export default class AuditCreatePageComponent implements OnInit, OnDestroy {
    @ViewChild('auto') autocomplete: MatAutocomplete;

    error_nameRequired = 'An audit name is required.';
    error_invalidForm = 'Audit configuration is invalid. Check your values.';
    error_accountRuleRequired = 'Audit requires at least one Account and Rule.';
    error_invalidGoal = 'Valid values are [0-100].';
    
    showAllCases = true;

    auditForm: FormGroup<AuditForm> = this._fb.group({
        name: ['', Validators.required],
        clientId: ['', Validators.required],
        auditDateType: [undefined as DisplayAuditDateType, Validators.required],
        groupBy: [undefined as DisplayAuditGroupByType, Validators.required],
        startDate: [TimeUtils.getStartOfMonth(), Validators.required],
        endDate: [TimeUtils.getEndOfMonth(), Validators.required],
        auditGoalPercentage: [0, Validators.pattern(/^(?:100|[1-9]?\d)$/)],
        ruleFilter: ['' as string | RulesDTO],
        accountRules: this._fb.array<FormGroup<AccountRuleForm>>([]),
    });

    // Client Data
    clients: Client[];
    clientsLoaded = false;
    selectedClient: Client;

    // Account Data
    loadingSelectedClientAccounts = false;
    activeAccountsForSelectedClient: AccountMinimalEnhanced[] = null; // The active accounts for the `selectedClient` & time range

    selectedAccount: Account;
    loadingCases = false;
    casesLoaded = false;
    isAuditGroupingEnabled = false;
    selectedAuditGroupByType = AuditGroupByTypes.None;
    auditCases: AuditCaseEnhanced[] = [];
    auditCasesGroups: AuditCasesGroup[] = [];

    // Rules
    rules: RulesDTO[] = [];
    rules_filtered: RulesDTO[]; // Autocomplete filtering
    accountRulesLoaded = false;

    // Audit date types
    // the apiValue has to match the enum AuditDateTypes in UWPipline.Core.Models
    auditDateTypes: DisplayAuditDateType[] = [{ displayName: 'Completed Date', apiValue: AuditDateTypes.CompletionDate }, { displayName: 'Created Date', apiValue: AuditDateTypes.CreationDate }];
    auditGroupByTypes: DisplayAuditGroupByType[] = AuditGroupByDisplayTypes;

    defaultSort: AccountRuleSelectorTableSort = { columnName: AccountRuleSelectorColumnNames.Account, sortDirection: AccountRuleSelectorSortDirection.Ascending };
    // Table Data
    displayedColumns = [ // The columns and order to show in the template
        'rowNumber',
        'accountName',
        'caseNumberDisplay',
        'caseStatusName',
        'caseCreatedDate',
        'uwDecisionLabel',
        'assigneeNames',
    ];

    subs = new SubSink();

    constructor(
        private _fb: FormBuilder,
        private _auditService: AuditService,
        private _rulesDataService: RulesDataService,
        private _caseSummaryService: CaseSummaryService,
        private _router: Router,
        private _clientContextService: ClientContextService,
        private _notificationService: NotificationService,
        private _accountService: AccountService,
        private _dialog: MatDialog
    ) { }

    //#region Helpers

    loadRules() {
        this.accountRulesLoaded = false;
        this._rulesDataService.load(this.selectedAccount.id, this.selectedAccount.clientId).subscribe(rulesDTOs => {
            this.rules = rulesDTOs;
            this.accountRulesLoaded = true;
            this.rules_filtered = this.filterRules('');
        });
    }

    clearResults() {
        this.auditCases = [];
        this.auditCasesGroups = [];
        this.casesLoaded = false;
    }

    filterRules(name: string): RulesDTO[] {
        const name_lower = name?.toLowerCase();
        const filteredRules = name_lower ? this.rules.filter(x => x.name.toLowerCase().includes(name_lower)) : this.rules.slice();
        return filteredRules;
    }

    displayWithRuleName(rule: RulesDTO) {
        return rule?.name ?? '';
    }

    getSelectedClient() {
        const clientId = this.auditForm.controls.clientId.value;
        const client = this.clients.find(x => x.id == clientId);

        return client;
    }

    createAuditRequest(): CreateAuditRequest {
        const name = this.auditForm.controls.name.value;
        const startDateUtc = this.auditForm.controls.startDate.value.toISOString();
        const endDateUtc = new Date(this.auditForm.controls.endDate.value.setHours(23, 59, 59, 999)).toISOString();

        const accountRules = this.auditForm.controls.accountRules.value as AccountRule[];
        const auditDateType = this.auditForm.controls.auditDateType.value;
        const apiAuditDateType = (auditDateType) ? auditDateType.apiValue : AuditDateTypes.CreationDate;
        const auditGoal = (this.auditForm.controls.auditGoalPercentage.value) ? this.auditForm.controls.auditGoalPercentage.value : 0;

        const auditGroupByType = this.auditForm.controls.groupBy.value;
        const apiAuditGroupByType = (auditGroupByType) ? auditGroupByType.apiValue : AuditGroupByTypes.None;


        const request: CreateAuditRequest = {
            name,
            auditDateType: apiAuditDateType,
            groupBy: apiAuditGroupByType,
            startDateUtc,
            endDateUtc,
            accountRules,
            goal: auditGoal
        };

        return request;
    }

    clearAccountRules() {
        this.auditForm.controls.accountRules.clear();
    }

    setSelectedClient(clientId: string) {
        const selectedClient = this.clients.find(x => x.id === clientId);

        this.selectedClient = selectedClient;
        this.clearAccountRules();
        this.clearResults();
    }

    //#endregion
    //#region Helpers - Date Range

    /**
     * Enable or disable which dates will be allowed to be selected on the calendar UI.
     * 
     * This works by the calendar component sending every individual date being rendered/shown on the calendar UI to this method and this method
     * returning `true` if it can be selected or `false` if it should be disabled.
     * 
     * Example:
     * If the calendar shows the month Aug 2023 then it will individually send these dates for validation:
     * [2023-08-01, 2023-08-02, ...n, 2023-08-30, 2023-08-31]
     * 
     * DEV NOTE: This must be a closure (ie. arrow function) or `this` won't be bound to this controller and `this.auditForm` wont' be available.
     * @param d The date on the calendar UI to validate
     * @returns true if the date is selectable and false if it should be disabled
     */
    dateRangeFilter = (d: Date | null) => {
        const startDate = this.auditForm.controls.startDate.value;

        // If no dates are currently selected, allow all dates
        if (!startDate) return true;

        let oneYearLimit = new Date(startDate.getTime());
        oneYearLimit.setFullYear(oneYearLimit.getFullYear() + 1);
        // Don't allow current date, a year later, to be included in the range (eg. Aug 1st, 2022 - July 31st, 2023) 
        // or we'll be "off-by-one" (eg. `Aug 1st, 2022` - `Aug 1st, 2023`)
        oneYearLimit.setHours(0, 0, 0, 0);
        oneYearLimit = new Date(oneYearLimit.getTime() - 1); // subtract 1 millisecond to roll the day back to end of day the previous day

        // We only limit future dates or the picker won't allow the user to a start date before the current date.
        if (d.getTime() > oneYearLimit.getTime()) return false;

        return true;
    };

    getActiveAccounts() {
        const clientId = this.selectedClient.id;
        const startDate = this.auditForm.controls.startDate.value?.toISOString();
        const endDate = this.auditForm.controls.endDate.value?.toISOString();

        this.loadingSelectedClientAccounts = true;

        this._accountService.getActiveAccountsBetweenRange(clientId, startDate, endDate).subscribe(activeAccounts => {
            this.loadingSelectedClientAccounts = false;

            this.activeAccountsForSelectedClient = activeAccounts.map((account) => {
                return {...account, activationDateDisplay:TimeUtils.formatDay(account.activationDate, 'M/D/YYYY') };
            });
        });
    }

    validateDate(dateControl: FormControl<Date>) {
        if (dateControl.value == null) dateControl.setErrors({ 'required': true });
    }

    validateDateRange() {
        FormUtils.removeError(this.auditForm.controls.startDate, 'dateRange');
        FormUtils.removeError(this.auditForm.controls.endDate, 'dateRange');

        // If start/end Date need to be valid to validate range;
        if (this.datesAreInvalid()) return;

        const lessThanAYear = TimeUtils.areDatesLessThanAYear(this.auditForm.controls.startDate.value, this.auditForm.controls.endDate.value);
        if (lessThanAYear) return;

        this.auditForm.controls.startDate.setErrors({ 'dateRange': true });
        this.auditForm.controls.endDate.setErrors({ 'dateRange': true });
    }

    datesAreValid() {
        return this.auditForm.controls.startDate.valid && this.auditForm.controls.endDate.valid;
    }

    datesAreInvalid() {
        return this.auditForm.controls.startDate.invalid || this.auditForm.controls.endDate.invalid;
    }

    updateDateControl(date: Date, control: FormControl<Date>) {
        control.setErrors(null); // clear errors so we can revalidate

        this.validateDate(control);
        this.validateDateRange();

        if (this.datesAreValid()) this.getActiveAccounts();
        this.clearAccountRules();
        this.clearResults();
    }

    //#endregion
    //#region Lifecycle

    async ngOnInit() {
        await this._clientContextService.ready;

        this._clientContextService.clients$.subscribe(clients => {
            this.clients = clients;
            this.clientsLoaded = true;
        });

        // Autocomplete filtering
        this.subs.add(this.auditForm.controls.ruleFilter.valueChanges.pipe(
            startWith(''),
            debounceTime(500), // Add a debounce to wait for user to stop typing
            distinctUntilChanged(), // Only react if the value changes
        ).subscribe(filterValue => {
            const filter = typeof filterValue === 'string' ? filterValue : filterValue?.name;
            this.rules_filtered = this.filterRules(filter);
        }));

        // Form Field Changes
        this.subs.add(this.auditForm.controls.startDate.valueChanges.pipe(
            debounceTime(500),
        ).subscribe(startDate => this.updateDateControl(startDate, this.auditForm.controls.startDate)));

        this.subs.add(this.auditForm.controls.endDate.valueChanges.pipe(
            debounceTime(500),
        ).subscribe(endDate => this.updateDateControl(endDate, this.auditForm.controls.endDate)));

        this.subs.add(this.auditForm.controls.clientId.valueChanges.subscribe(clientId => {
            this.setSelectedClient(clientId);
            this.getActiveAccounts();
        }));

        // Set the client context if there's already a client set
        if (this._clientContextService.client) {
            this.auditForm.controls.clientId.setValue(this._clientContextService.client.id);
        }
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    //#endregion
    //#region Handlers


    onGroupByChange(groupByChange: MatSelectChange) {
        this.selectedAuditGroupByType = groupByChange.value.apiValue;
        this.isAuditGroupingEnabled = groupByChange.value.apiValue !== AuditGroupByTypes.None;
        
        if (this.casesLoaded) {
            if (this.selectedAuditGroupByType === AuditGroupByTypes.None) {
                this.auditCasesGroups = [];
            } else {
                this.auditCasesGroups = AuditUtils.filterSortAndGroupAuditCases(this.auditCases, this.selectedAuditGroupByType, AuditViewStatusFilters.All);
            }
            this._notificationService.showNotification({ severity: NotificationSeverity.Info, message: `Preview has been grouped by '${groupByChange.value.displayName}'` });
        }
    }

    onShowAllCases = () => { this.showAllCases = !this.showAllCases; };

    onAccountChange(change: MatSelectChange) {
        const selectedAccount = this.selectedClient.accounts.find(x => x.id === change.value);

        this.selectedAccount = selectedAccount;
        this.clearResults();
        this.loadRules();
    }

    onSubmit() {
        if (this.auditForm.controls.name.invalid) {
            this._notificationService.showNotification({ severity: NotificationSeverity.Error, message: this.error_nameRequired });
            return;
        }
        else if (this.auditForm.invalid) {
            this._notificationService.showNotification({ severity: NotificationSeverity.Error, message: this.error_invalidForm });
            return;
        }
        else if (this.auditForm.controls.accountRules.length === 0) {

            this._notificationService.showNotification({ severity: NotificationSeverity.Error, message: this.error_accountRuleRequired });
            return;
        }
        else if (this.auditForm.controls.auditGoalPercentage.invalid) {
            this._notificationService.showNotification({ severity: NotificationSeverity.Error, message: this.error_invalidGoal});
        }

        const dialogRef = this._dialog.open(AuditSaveDialog, {
            data: { title: 'Save Audit?', content: 'Rules on existing accounts or versions cannot be modified once saved.', confirmButtonLabel: 'Yes, Save Audit' }
        });
        
        dialogRef.afterClosed().subscribe(shouldSaveAudit => {
            if (shouldSaveAudit) {
                this.loadingCases = true;
                const client = this.getSelectedClient();
                const hideWaiting = false;
                const forceRefresh = true;
        
                const request = this.createAuditRequest();
                this._auditService.createAudit(request, client.id).pipe(
                    switchMap(_audit => this._auditService.getAudits(client.id, hideWaiting, forceRefresh)), // reload the audits (cache busting)
                ).subscribe(_audits => {
                    this.loadingCases = false;
                    this.casesLoaded = true;
        
                    this.goToAuditPage();
                });
            }
        });

    }

    onPreview() {
        this.loadingCases = true;

        const client = this.getSelectedClient();

        const request = this.createAuditRequest();
        this._auditService.getAuditPreview(request, client.id).subscribe(auditPreview => {
            this.auditCases = auditPreview.auditCases.map((auditCase): AuditCaseEnhanced => {
                const account = this.activeAccountsForSelectedClient.find(x => x.id === auditCase.accountId);
                return { ...auditCase, client, account };
            });

            if (this.selectedAuditGroupByType !== AuditGroupByTypes.None) {
                this.auditCasesGroups = AuditUtils.filterSortAndGroupAuditCases(this.auditCases, this.selectedAuditGroupByType, AuditViewStatusFilters.All);
            }

            this.loadingCases = false;
            this.casesLoaded = true;
        });
    }

    onOpenCase(auditCase: AuditCaseEnhanced, newTab = true) {
        const routeInfo: CaseSummaryRoute = {
            clientCode: auditCase.client.code,
            accountCode: auditCase.account.code,
            accountVersionNumber: auditCase.account.versionNumber,
            caseId: auditCase.caseId,
        };
        this._caseSummaryService.routeToCase(routeInfo, newTab);
    }

    goToAuditPage() {
        this._router.navigate(['/audit']);
    }

    handleRuleChange(change: AccountRuleChange) {
        const index = this.auditForm.controls.accountRules.controls.findIndex(formGroup =>
            formGroup.controls.accountId.value === change.accountId
        );

        // Update the existing form item
        if (index !== -1) {
            // Remove the item if it's empty
            if (change.ruleId === Utils.emptyGuid) {
                this.auditForm.controls.accountRules.removeAt(index);
            }
            else {
                const accountRuleGroup = this.auditForm.controls.accountRules.at(index);
                accountRuleGroup.controls.ruleId.setValue(change.ruleId);
            }
        }
        // Create the form item
        else {
            const formGroup: FormGroup<AccountRuleForm> = this._fb.group({ accountId: change.accountId, ruleId: change.ruleId });
            this.auditForm.controls.accountRules.push(formGroup);
        }

        this.clearResults();
    }

    onAuditDateChange(_: MatSelectChange) {
        this.clearResults();
    }

    //#endregion
}
