import { Injectable, OnDestroy } from '@angular/core';
import { AbstractControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { FeatureManagerService, ObservableService, SignalRService } from '@Services';
import { AppService } from 'app/app.service';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';

import { ConstantListGroup } from 'app/models/config/constant-mapkey-value';
import { AccountInterviewService } from '../../../account/account-config/account-interview/account-interview.service';
import { MaskValueOptions } from '../../../directives';
import { InterviewService } from '../../../interview.service';
import { MonitoringService } from '../../../monitor.service';

export interface InterviewFormFieldOptions {
    additionalValidators?: ValidatorFn[];
    additionalFormFieldNames?: string[];
    inputType?: string;
    skipValidators?: boolean;
}

// We inject these into every single form field so that we can pass them down to the base,
// so I'm consolidating them here to make changes easier.
@Injectable()
export class BaseFormFieldServices {
    constructor(
        public interviewService: InterviewService,
        public accountInterviewService: AccountInterviewService,
        public monitorService: MonitoringService,
        public observableService: ObservableService,
        public sanitizer: DomSanitizer,
        public appService: AppService,
        public featureService: FeatureManagerService,
        public signalRService: SignalRService
    ) { }
}

@Injectable()
export class BaseInterviewFormFieldComponent implements OnDestroy {
    public config: any; // todo: strongly type this
    public expanded: boolean;
    public formIndex: string;
    public group: UntypedFormGroup;
    public interviewMode: string;
    public isSelected: boolean;
    public previousFormFieldValues: { [fieldName: string]: any } = {};
    public selectedQuestion: boolean;
    public inputType: string;
    public maskConfig: MaskValueOptions;
    private _options: InterviewFormFieldOptions = {};

    public questionText: SafeHtml;
    public helpText: SafeHtml;

    public userRole: string;

    public constantLists: ConstantListGroup;

    public subscriptions: Subscription[] = [];

    constructor(public services: BaseFormFieldServices) { }

    constantListUpdatedLogic() {
        // TECH DEBT: Why is this left empty instead of being removed?
    }

    public subscribeToTextUpdates() {

        const questionTextParts = this.services.interviewService.getDynamicTextParts(this.config.questionText);
        if (questionTextParts.length > 1) {
            this.subscriptions.push(this.services.observableService.onTriggerUpdateDynamicText.subscribe(() => {
                this.questionText = this.formatText(this.config.questionText);
            }));
        }

        const helpTextParts = this.services.interviewService.getDynamicTextParts(this.config.helpText);
        if (helpTextParts.length > 1) {
            this.subscriptions.push(this.services.observableService.onTriggerUpdateDynamicText.subscribe(() => {
                this.helpText = this.formatText(this.config.helpText);
            }));
        }

        this.subscriptions.push(this.services.observableService.onTriggerUpdateLists.subscribe(() => {
            this.constantLists = this.services.interviewService.constantLists;
            if (this.constantLists) {
                this.constantListUpdatedLogic();
            }
        }));

        this.questionText = this.formatText(this.config.questionText);
        this.helpText = this.formatText(this.config.helpText);
    }

    public initialize(options: InterviewFormFieldOptions = {}) {
        this._options = options;
        this.userRole = this.services.appService.getUserRoleName();
        const control = this.group.get(this.config.id);

        if (this.services.interviewService.isInterviewLocked || this.config.isLocked) {
            control.disable();

            if (this.group.get(`${this.config.id}_picker`)) {
                const pickerControl = this.group.get(`${this.config.id}_picker`);
                pickerControl.disable();
            }

            if (this.group.get(`${this.config.id}_forceUpdate`)) {
                const forceUpdateControl = this.group.get(`${this.config.id}_forceUpdate`);
                forceUpdateControl.disable();
            }
        }
        // Should we be specifying validators per-control on this component?  e.g. Phone Number + Extension?
        // Right now this only applies validations to the _first_ control, and keeping it that way to not change
        // current behavior before we thouroughly understand what we want.
        if (!this._options.skipValidators && !this.services.interviewService.isInterviewLocked) {
            this.setupValidators(control, this.config, this._options.additionalValidators);
        }

        control['formIndex'] = this.formIndex;
        control['questionId'] = this.config.id;

        // Set initial values for each field in the control
        const formFieldNames = this.getFormFieldNames();
        formFieldNames.forEach(ffn => {
            this.previousFormFieldValues[ffn] = this.group.get(ffn).value;
        });

        this.inputType = this._options.inputType || this.config.DisplayType;

        this.configureMasking();
        this.subscribeToTextUpdates();
    }

    public isReadOnly() {
        return this.services.interviewService.isReadOnly || this.config.isLocked;
    }

    public isLiveMode() {
        return !this.services.interviewService.isReadOnly && this.interviewMode != null && this.interviewMode;
    }
    public isCaseSummary() {
        return this.services.interviewService.isReadOnly && this.interviewMode != null && this.interviewMode;
    }

    public onFocus() {
        if (!this.isLiveMode()) { return; }

        this.isSelected = true;
    }

    public onBlur(force?: boolean) {
        if (!this.isLiveMode()) { return; }

        // Check all fields in this control for value changes
        let fieldChanged = false;

        const formFieldNames = this.getFormFieldNames();
        formFieldNames.forEach(ffn => {
            const fieldControl = this.group.get(ffn);

            if (fieldControl == null || (this.inputType !== 'checkbox' && !fieldControl.valid)) {
                return;
            }

            // not using the config `answerValue` because there are transforms that happen in there.
            // and lodash because the value may be an array (checkbox) or a scalar.
            if (!_.isEqual(fieldControl.value, this.previousFormFieldValues[ffn])) {
                this.previousFormFieldValues[ffn] = _.cloneDeep(fieldControl.value); // must deep clone in the case of Arrays, or the values are refs and makes change detection puke.
                fieldChanged = true;
            }
        });

        this.isSelected = false;

        // We may have forced the blur actions, e.g. Action Buttons have no `blur` _or_ values to compare against.
        if (fieldChanged || force) {
            if (this.config.answerType !== 'ActionButton' && this.config.answerType !== 'UploadFileActionButton') {
                const caseDetails = this.services.interviewService.getInterviewData().caseDetails;
                if (caseDetails) {
                    this.services.monitorService.logEvent(`Answered Question`, 
                        {
                            "sectionName": this.services.interviewService.getActiveSection().name, 
                            "caseNumber": caseDetails.caseNumber, 
                            "accountCode": this.services.interviewService.account.code, 
                            "role": this.userRole,
                            "sessionId": this.services.signalRService.getSessionId(),
                            "questionReference": this.config.mapKeyName,
                            "caseCreatedDate": caseDetails.creationDate,
                            "eventType": "Client Runtime"
                        }
                    );
                }
            }

            this.services.interviewService.updateInterviewJson(this.config, true);
            this.services.interviewService.callPostActions(this.config);
        }

        this.services.observableService.onQuestionBlur.next(1);
    }

    public selectQuestion() {
        this.services.accountInterviewService.selectPreviewQuestion({ question: this.config });
    }

    public isConfigInvalid() {
        return this.group.get(this.config.id).touched
            && this.group.get(this.config.id).invalid;
    }

    public ngOnDestroy() {
        if (this.subscriptions != null) {
            this.subscriptions.forEach(s => s.unsubscribe());
        }
    }

    private formatText(text: string): SafeHtml {
        // DO NOT BIND HTML TO THIS FUNCTION DIRECTLY.

        let formattedText = text;

        if (this.interviewMode) {
            formattedText = this.services.interviewService.formattedText(text);
        }

        return this.services.sanitizer.bypassSecurityTrustHtml(formattedText);
    }

    private getFormFieldNames() {
        return [this.config.id].concat(this._options.additionalFormFieldNames || []);
    }

    private setupValidators(control: AbstractControl, config: any, validators: ValidatorFn[]): void {
        const validations = validators || [];

        if (config.IsCurrency === 'true') { validations.push(this.dollarValueValidator()); }
        if (config.isRequired) { validations.push(Validators.required); }
        if (config.MinLength) { validations.push(Validators.minLength(config.MinLength)); }
        if (config.MaxLength) { validations.push(Validators.maxLength(config.MaxLength)); }
        if (config.MinValue) { validations.push(Validators.min(parseInt(config.MinValue))); }
        if (config.MaxValue) { validations.push(Validators.max(parseInt(config.MaxValue))); }
        if (config.MinInches) { validations.push(Validators.min(config.MinInches)); } // todo: why are we being so specific for _inches_ vs using MinValue/MaxValue?
        if (config.MaxInches) { validations.push(Validators.max(config.MaxInches)); }
        if (config.MaxNumberDecimalPlaces) {
            validations.push(this.maxDecimalPlacesValidator());
        }
        control.setValidators(validations);
        control.updateValueAndValidity();
    }

    private configureMasking() {
        this.maskConfig = {
            enabled: this.config.IsMasked
        };

        if (this.maskConfig.enabled) {
            this.maskConfig.character = this.config.MaskCharacter;
            this.maskConfig.visibleCharacters = this.config.MaskVisibleCharacters;
        }
    }

    // custom validator for validating dollar value min and max validations
    validateDollarValue(c) {
        if (c.value !== null && c.value !== undefined) {
            let testValue = c.value.replace(/\$|,/g, '');
            if (this.config.MaxNumberDecimalPlaces) {
                testValue = parseFloat(testValue);
            } else {
                testValue = parseInt(testValue);
            }

            const errors: any = {};
            if (this.config.MinValue) {
                if (testValue < parseInt(this.config.MinValue)) {
                    errors.min = true;
                }
            }
            if (this.config.MaxValue) {
                if (testValue > parseInt(this.config.MaxValue)) {
                    errors.max = true;
                }
            }
            if (errors.min || errors.max) {
                return errors;
            } else {
                return null;
            }
        }
        return null;
    }

    validateMaxDecimalPlaces(c) {
        if (c.value !== null && c.value !== undefined) {
            const errors: any = {};
            if (this.config.MaxNumberDecimalPlaces) {
                const testValue = c.value.toString().replace(/\$|,/g, '');
                const testSplit = testValue.split('.');
                if (testSplit && testSplit.length > 2) {
                    errors.maxDecimal = true;
                }

                const maxRegexDecimal = `^(\\d+(\\.{0,1})\\d{0,${this.config.MaxNumberDecimalPlaces}})?$`;
                const isMatch = c.value.toString().replace(/\$|,/g, '').match(maxRegexDecimal);
                if (!isMatch) {
                    errors.maxDecimal = true;
                }
            }

            if (errors.maxDecimal) {
                return errors;
            } else {
                return null;
            }
        }
        return null;
    }

    dollarValueValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            return this.validateDollarValue(control);
        };
    }


    maxDecimalPlacesValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            return this.validateMaxDecimalPlaces(control);
        };
    }

}
