import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {AppService} from "../../../../app.service";
import {UntypedFormBuilder, UntypedFormGroup,Validators} from '@angular/forms';
import * as _ from "lodash";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {HTMLEditorModal, HTMLEditorProperties} from "app/components/html-editor-modal/html-editor-modal";
import {UWLabel} from "app/account/account-config/account-underwriting/account-underwriting.component";
import {Account} from "app/models";

@Component({
    selector: 'uw-label',
    templateUrl: './uw-label.component.html',
    styleUrls: ['./uw-label.component.scss']
})
export class UWLabelComponent implements OnInit {

    _label: UWLabel;
    labelForm: UntypedFormGroup;
    showForm: boolean = false;
    htmlEditorDialogRef: MatDialogRef<any>;
    htmlEditorProperties: HTMLEditorProperties;
    purlEnabled: boolean = false;

  @Input() rules;
  @Input() index;
  @Input() labelsLength;
  @Input() accountInfo: Account;

  @Input()
  set label(labelValue : UWLabel) {
      this._label = labelValue;
      this.labelForm = this._fb.group({
          id: labelValue.id,
          name: [labelValue.name, Validators.required],
          ruleId: labelValue.ruleId || this.appService.emptyGUID,
          workFlowId: [labelValue.workFlowId, Validators.required],
          summaryMessage: labelValue.summaryMessage
      });

      if (!labelValue.id) this.showForm = true;
  }

  get label() {
      return this._label;
  }

  @Output() onDelete = new EventEmitter<any>();
  @Output() moveDown = new EventEmitter<any>();
  @Output() moveUp = new EventEmitter<any>();
  @Output() onSave = new EventEmitter<any>();

  constructor(
    private _fb: UntypedFormBuilder,
    private appService: AppService,
    public dialog: MatDialog
  ) {
  }

  ngOnInit() {
      this.htmlEditorProperties = {
          editorConfig: {
              editable: true,
              spellcheck: true,
              height: '225px',
              minHeight: '0',
              maxHeight: 'auto',
              width: 'auto',
              minWidth: '0',
              translate: 'yes',
              enableToolbar: true,
              showToolbar: true,
              sanitize: false,
              toolbarHiddenButtons: [
                  ['insertImage',
                      'insertVideo',
                      'textColor',
                      'backgroundColor',
                      'customClasses',
                      'link',
                      'unlink',
                      'insertHorizontalRule',
                      'removeFormat',]
              ]
          },
          htmlText:"",
          confirmationText: "SAVE MESSAGING",
          titleText:"Configuring Messaging",
          helperText:"This message will be shared with applicants. Let them know their policy status and if there are next steps or additional communication they should expect.",
          descriptionText:""
      };

      this.loadPurlAccountSetting();
  }

  checkACL(permissionType, redirect ?) {
      return this.appService.checkACL("Accounts", permissionType, redirect);
  }

  editLabel() {
      this.labelForm.patchValue(this.label);
      this.showForm = true;
  }

  cancel() {
      this.showForm = false;
      this.labelForm.reset();
  }

  openHTMLEditor(uwLabel: UWLabel) {
      this.htmlEditorProperties.htmlText = uwLabel.summaryMessage;
      this.htmlEditorDialogRef = this.dialog.open(HTMLEditorModal, {
          width: '50%',
          data: this.htmlEditorProperties
      });

      this.htmlEditorDialogRef.afterClosed().subscribe((htmlText)=>{
          this.labelForm.get('summaryMessage').setValue(htmlText);
      });

  }

  saveLabel() {
      if (this.labelForm.valid) {

          let isEdit = false;
          let url = "UwdecisionLabel";

          if (this.labelForm.get("id").value) {
              isEdit = true;
              url = `UwdecisionLabel/${this.labelForm.get("id").value}`;
          }

          this.labelForm.value.ruleId = this.labelForm.value.ruleId === this.appService.emptyGUID ? '' : this.labelForm.value.ruleId;

          this.appService.postData(url, this.labelForm.value, isEdit, false)
              .subscribe(
                  data => {
                      if (data.status == "success") {
                          this.showForm = false;
                          if (!isEdit) this.onSave.emit();
                          this.label = Object.assign({}, data.data);
                          this.appService.showMsg("success", "Saved successfully ...");
                      } else {
                          this.appService.showMsg("error", data.message);
                      }
                  }
              );
      } else {
          this.appService.showMsg("error", "Please fill all fields");
      }
  }

  getRuleName(ruleId) {
      if (ruleId && ruleId != '00000000-0000-0000-0000-000000000000' && this.rules && this.rules.length) {
          return _.find(this.rules, ['id', ruleId]).name;
      } else {
          return "None";
      }
  }

  loadPurlAccountSetting() {
      this.appService.getData(`AccountSettings/${this.accountInfo.id}`).subscribe(
          response => {
              if (response.status === 'success') {
                  const setting = _.find(response.data, { 'name': 'purlSettings' });
                  if (setting) {
                      const purlSettings = JSON.parse(setting.value);
                      this.purlEnabled = purlSettings.isPurlEnabled;
                  }
              }
          });
  }
}
