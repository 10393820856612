import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MapKeysDTO } from '@DTOs';
import { AccountExternal } from '@Models';
import { MapkeysDataService } from '@Services';
import { Utils } from '@Utils';
import { debounceTime, distinctUntilChanged, Subject, tap } from 'rxjs';
import { SubSink } from 'subsink';


export type AccountMapKeyChange = {
    account: AccountExternal;
    mapKeyEntityHierarchy: string;
    error: boolean; // TODO: Since we created a second output, do we still need this?
}

export type AccountMapKeyValidChange = {
    account: AccountExternal;
    mapKeyEntityHierarchy: string;
    error: boolean;
}

/**
 * DEV NOTE: TECH DEBT: There's also `map-keys-dropdown.component.ts` but that component has several unused methods that make `implements ControlValueAccessor` unnecessary.
 * I don't know why that component needed to implement that. The component also include features that are (currently) always on and can't be part of our needs
 * (Add New Mapkey button).  For this reason, I created a new component who's only use is to select mapKeys.  If we can clean up `map-keys-dropdown.component.ts`
 * we might be able to use that instead.
 */
@Component({
    selector: 'account-mapkey-selector',
    host: {},
    templateUrl: 'account-mapkey-selector.component.html',
    styleUrls: ['account-mapkey-selector.component.scss']
})
export default class AccountMapkeySelectorComponent implements OnInit, OnChanges {
    @Input() account: AccountExternal;
    @Input() mapKeyEntityHierarchy: string;
    @Output() onMapKeyChange = new EventEmitter<AccountMapKeyChange>();
    @Output() onValidChange = new EventEmitter<AccountMapKeyValidChange>();

    mapKeysLoaded = false;
    mapKeys: MapKeysDTO[] = []; // The full list of mapkeys
    mapKeyEntityHierarchyLookup = new Set<string>(); // A lookup of the full entity hierarchies (for fast "exists" validation at the cost of memory)


    filteredMapKeys: MapKeysDTO[]; // The filtered drop-down of autoComplete
    filteredMapKeysSub = new Subject<MapKeysDTO[]>();

    mapKeyFilterInput = new FormControl<string>('');

    // DEV NOTE: Instead of subscribing to the FormControl's ".valueChanged" observable, we need to create our own subject so we can
    // know the difference between a user manually (typing/pasting) changing a value and when a value is coming in from an `ngOnChanges`
    // event.  The FormControl.valueChanged observable will fire for *any* change to the value and not just user changes.
    private mapKeyInputSub = new Subject<string>();

    subs = new SubSink();

    constructor(
        private mapKeysDataService: MapkeysDataService
    ) { }


    //#region Helpers

    filterMapKeys() {
        const entityHierarchy_lower = this.mapKeyEntityHierarchy?.toLowerCase() ?? '';

        // DEV NOTE: Don't iterate and 'filter' mapKeys if we know we're going to include everything. There's a lot of mapKeys (1k+)
        // and this would be a slow/waisted effort.
        if (Utils.isNullOrWhitespace(entityHierarchy_lower)) {
            this.filteredMapKeysSub.next(this.mapKeys);
            return;
        }

        const filteredMapKeys = this.mapKeys.filter(mapKey => mapKey.entityHierarchy.toLowerCase().includes(entityHierarchy_lower));
        this.filteredMapKeysSub.next(filteredMapKeys);
    }

    getMapKeyForAccount(entityHierarchy: string) {
        // An existing mapKey is one that's in the mapKey list (NOT a 'required' validation)
        if (Utils.isNullOrWhitespace(entityHierarchy)) return null;

        const mapKey = this.mapKeys.find(x => x.entityHierarchy == entityHierarchy);

        return mapKey;
    }

    setMapKeyValue(entityHierarchy: string) {

        if (!this.mapKeysLoaded)
            return;

        this.mapKeyFilterInput.setValue(entityHierarchy);
        const mapKeyMissing = !this.mapKeyEntityHierarchyLookup.has(entityHierarchy);

        if (mapKeyMissing)
            this.mapKeyFilterInput.setErrors({ 'is-not-found': true });

        this.mapKeyFilterInput.markAsTouched();

        // Since this is the only spot the determines if the new value is valid; notify the parent if it's valid.
        this.onValidChange.emit({ account: this.account, mapKeyEntityHierarchy: entityHierarchy, error: mapKeyMissing });

        this.filterMapKeys();
    }

    changeMapKeyInputValue(entityHierarchyValue: string) {
        const mapKeyMissing = !this.mapKeyEntityHierarchyLookup.has(entityHierarchyValue);

        this.onMapKeyChange.emit({ account: this.account, mapKeyEntityHierarchy: entityHierarchyValue, error: mapKeyMissing });
    }

    //#endregion
    //#region Lifecycle

    ngOnInit() {
        // DEV NOTE: We need to pass the client id because this page doesn't have a client route parameter
        const hideWaiting = true; // Disable global spinners since we have inner-page spinners
        this.mapKeysDataService.loadMapkeys(this.account.id, this.account.clientId, hideWaiting).subscribe(mapKeys => {
            this.mapKeysLoaded = true;

            // Full list of mapkeys for this account
            this.mapKeys = mapKeys;

            // Create mapkey lookup so we don't have to search 1k items to verify if a value exists
            this.mapKeyEntityHierarchyLookup = new Set(this.mapKeys.map(x => x.entityHierarchy));

            // Because it takes time to load the mapkeys list; set the value again so it will show up after loading.
            this.setMapKeyValue(this.mapKeyEntityHierarchy);
        });

        this.subs.add(this.mapKeyInputSub.pipe(
            // startWith(this.mapKeyEntityHierarchy),
            debounceTime(500), // Add a debounce to wait for user to stop typing
            distinctUntilChanged(), // Only react if the value changes
        ).subscribe(entityHierarchyValue => {
            // DEV NOTE: We know this is from the user entering a value (typing/pasting) and not from ngOnChanges updating the FormControl.
            // This is 
            this.changeMapKeyInputValue(entityHierarchyValue);
        }));
    }

    ngOnChanges(changes: SimpleChanges) {
        // DEV NOTE: When changing between tabs, the components are not destroyed but the "ActiveTab" value changes.
        // This means, the `ngOnChanges` will be called for the new "ActiveTab"'s values
        if (changes.mapKeyEntityHierarchy) this.setMapKeyValue(changes.mapKeyEntityHierarchy.currentValue);
    }

    ngOnDesteory() {
        this.subs.unsubscribe();
    }

    //#endregion
    //#region Handlers

    handleMapKeySelected(entityHierarchySelected: MatAutocompleteSelectedEvent) {
        // DEV NOTE: This is when a user clicks on an an Autocomplete drop-down item.
        this.changeMapKeyInputValue(entityHierarchySelected.option.value);
    }

    /**
     * When someone types or pasts a value into the Autocomplete input (ie. filter) field
     * @param entityHierarchy the value
     */
    handleMapKeyInput = (entityHierarchy: string) => this.mapKeyInputSub.next(entityHierarchy);

    //#endregion
}
