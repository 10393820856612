import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import {
    AbstractControl,
    UntypedFormBuilder,
    UntypedFormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { AppService } from '@App';
import { MapKeysDTO } from '@DTOs';
import { MapKeyType } from '@Enums';
import { IntegrationService, MapKeyService } from '@Services';

@Component({
    selector: 'dynamic-list',
    templateUrl: './dynamic-list.component.html',
    styleUrls: ['./dynamic-list.component.scss']
})
export class DynamicListComponent {
    readonly ValueMaxLength = 1000;
    readonly DisplayMaxLength = 200;
    isEditView: boolean;
    dynamicListForm: UntypedFormGroup = new UntypedFormGroup({});
    listId: any = 1;
    title: any;
    fileData: any;
    showDetails: boolean = true;
    showList: boolean = true;
    @Output() cancelEdit = new EventEmitter<any>();
    @Output() saveChanges = new EventEmitter<any>();
    @Output() editDynamicList = new EventEmitter<any>();
    @Output() listEditChange = new EventEmitter<any>();
    @Input() parentPathDisplay: string;
    initialFormData;
    unmatchedTokenErrorMessage: string = 'A data element is not formatted properly. Data elements must be in curly brackets {{ }}';
    mentionConfig = {};
    mentionItems: any;
    filteredMentionItems: any;
    expressionPlaceHolderText: string = 'Start typing { to add data elements...';
    protected integrationResponseMapkeys: MapKeysDTO[];

    constructor(private _fb: UntypedFormBuilder,
        private appService: AppService,
        private httpClient: HttpClient,
        private integrationService: IntegrationService,
        private mapkeyService: MapKeyService,
        private el: ElementRef) {
    }

    protected _dynamicList: any;

    get dynamicList() {
        return this._dynamicList;
    }

    @Input()
    set dynamicList(value) {
        this._dynamicList = value;
        this.title = value.title;

        this.dynamicListForm = this._fb.group({
            id: value.id,
            name: [value.name, Validators.required],
            mapKeyEntityId: value.mapKeyEntityId,
            accountId: value.accountId,
            clientId: value.clientId,
            mapkeyTypeId: MapKeyType.DynamicList,
            integrationResponseMapkey: ['', Validators.required],
            valueFormat: ['', Validators.required],
            displayNameFormat: ['', Validators.required],
            virtualExpression: ''
        });

        if ((this._dynamicList.virtualExpression ?? '') != '') {
            let dynamicListComponents: DynamicListComponents = JSON.parse(this._dynamicList.virtualExpression);
            this.dynamicListForm.controls['valueFormat'].patchValue(dynamicListComponents.valueFormat);
            this.dynamicListForm.controls['displayNameFormat'].patchValue(dynamicListComponents.displayNameFormat);
            this.dynamicListForm.controls['integrationResponseMapkey'].patchValue(dynamicListComponents.integrationResponseMapkey.toLowerCase());
            this.getIntegrationMetadata();
        }

        if (value.id) {
            this.isEditView = this.dynamicList.isEdit;
        } else {
            this.isEditView = true;
        }

        this.initialFormData = this.dynamicListForm.value;
        this.integrationResponseMapkeys = this.mapkeyService.mapKeys.filter(x => x.integrationId);
    }

    getIntegrationMetadata() {
        let integrationResponseIntegrationId = this.mapkeyService.mapKeys.filter(x => x.id == this.dynamicListForm.controls['integrationResponseMapkey'].value)[0];
        this.integrationService.getIntegrationMetadata(integrationResponseIntegrationId.integrationId).subscribe(
            data => {
                let outputs: any[] = data['output'].filter(x => x.isArray);
                this.mentionItems = outputs.map(x => x.property);
                this.filteredMentionItems = this.mentionItems;
                this.mentionConfig = {
                    items: this.filteredMentionItems,
                    mentionSelect: (item) => `{{${item.label}}}`,
                    triggerChar: '{',
                    disableSearch: true
                };
                this.dynamicListForm.controls['valueFormat'].setValidators([ValidateExpressionInput(this.mentionItems)]);
                this.dynamicListForm.controls['displayNameFormat'].setValidators([ValidateExpressionInput(this.mentionItems)]);
            });
    }

    onSearch(event): void {
        this.filteredMentionItems = this.mentionItems.filter(member => member.toLowerCase().includes(event.toLowerCase()));
    }

    editItem() {
        this.isEditView = true;
    }


    saveDynamicList() {
        this.dynamicListForm.markAllAsTouched();
        this.validateAndFocusFields();
        let isEdit = false;
        let url = 'Mapkeys';
        if (this.dynamicListForm.valid) {
            if (this.dynamicListForm.get('id').value) {
                isEdit = true;
                url = `MapKeys/${this.dynamicList.id}`;
            }
            let dynamicListComponents: DynamicListComponents = {
                integrationResponseMapkey: this.dynamicListForm.controls['integrationResponseMapkey'].value,
                displayNameFormat: this.dynamicListForm.controls['displayNameFormat'].value,
                valueFormat: this.dynamicListForm.controls['valueFormat'].value
            };

            this.dynamicListForm.controls['virtualExpression'].patchValue(JSON.stringify(dynamicListComponents));
            this.appService.postData(url, this.dynamicListForm.value, isEdit, false)
                .subscribe(
                    data => {
                        if (data.status === 'success') {
                            this.isEditView = false;
                            this.appService.showMsg('success', 'Saved successfully.');
                            this.dynamicList = data.data;
                            if (isEdit) {
                                this.editDynamicList.emit(data.data);
                            } else {
                                this.saveChanges.emit(data.data);
                            }

                        } else {
                            this.appService.showMsg('error', data.message);
                        }
                    }
                );
        }
    }

    validateAndFocusFields() {
        let focused = false;
        let errorString = '';
        Object.keys(this.dynamicListForm.controls).forEach(key => {
            let control = this.dynamicListForm.get(key);
            if (control.invalid && !focused) {
                this.el.nativeElement.querySelector(`[formcontrolname="${key}"]`).focus();
                focused = true;
                errorString = this.getErrorString(control, key);
                this.appService.showMsg('error', errorString);
            }
        });

    }

    formControlNiceName(controlName: string) {
        switch (controlName) {
        case 'name':
            return 'Name';
        case 'integrationResponseMapkey':
            return 'Source';
        case 'valueFormat':
            return 'Value';
        case 'displayNameFormat':
            return 'Display Name';
        default:
            return controlName;
        }
    }

    cancel() {
        this.isEditView = false;
        this.dynamicListForm.reset(this.initialFormData);
    }

    getIntegrationResponseMapkeyName(integrationResponseMapkeyId: string) {
        let results = this.integrationResponseMapkeys.filter(x => x.id.toLowerCase() == integrationResponseMapkeyId.toLowerCase());
        if (results.length == 0)
            return '';
        else
            return results[0].entityHierarchy;
    }

    private getErrorString(control: AbstractControl, key: string) {
        let errorString = '';
        if (control.errors?.['required']) {
            errorString += `${this.formControlNiceName(key)} is required.`;
        }

        if (control.errors?.['missingExpression']) {
            errorString += `${this.formControlNiceName(key)} must contain at least one data element.`;
        }

        if (control.errors?.['hasComma'] && key != 'displayNameFormat') {
            //Commas are allowed for 'displayNameFormat'
            errorString += `${this.formControlNiceName(key)} cannot contain commas.`;
        }

        if (control.errors?.['unmatchedToken']) {
            errorString += `${this.formControlNiceName(key)} is not formatted properly missing curly brackets.`;
        }

        if (control.errors?.['invalidExpression']) {
            errorString += `${this.formControlNiceName(key)} contains an invalid expression`;
        }
        return errorString;
    }
}

export function ValidateExpressionInput(expectedDataList: any[]): ValidatorFn {

    return (control: AbstractControl): ValidationErrors | null => {

        const controlName = (Object.keys(control.parent.controls).find(key => control.parent.controls[key] === control));
        // Do we have ANY expressions?
        if (!control.value.includes('{{')) {
            return { missingExpression: true };
        }

        // Do we have a comma?
        if (control.value.includes(',') && controlName != 'displayNameFormat') {
            return { hasComma: true };
        }

        let matchCount = 0;
        // Check that data is in metadata
        if (expectedDataList) {
            let matches = control.value.matchAll(new RegExp(/\{\{([^{}]+)}}/gm));
            for (const match of matches) {
                matchCount++;
                if (!expectedDataList.includes(match[1]))
                    return { invalidExpression: match[0] };
            }
        }

        // Are the pairs equal and do we have any odd syntax
        let numOfLeft = (control.value.match(/{{/gm) || []).length;
        let numOfRight = (control.value.match(/}}/gm) || []).length;
        let oddNumOfLeft = (control.value.match(/{/gm) || []).length % 2;
        let oddNumOfRight = (control.value.match(/}/gm) || []).length % 2;
        if (numOfLeft != numOfRight || oddNumOfLeft != 0 || oddNumOfRight != 0)
            return { unmatchedToken: true };

        // Do we have as many matches as pairs?
        return null;
    };
}

export interface DynamicListComponents {
    integrationResponseMapkey?: string;
    valueFormat?: string;
    displayNameFormat?: string;
}
