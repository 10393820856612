﻿<div class="w-100">
    <form [formGroup]="caseSummarySidebarForm" (ngSubmit)="saveSidebarMapkeys()" novalidate autocomplete="off">
        <div class="m-5">
            <div class="flex flex-row">
                <div class="page-header">Case Summary Sidebar</div>
                <div class="flex-1"></div>
                <div style="justify-content: flex-end; padding-bottom: 5px;" *ngIf="caseSummaryWriteAccess">
                    <mrs-btn-primary class="float-right" buttonLabel="Save" buttonId="saveButton" type="submit"></mrs-btn-primary>
                </div>
            </div>
            <mat-card appearance="outlined">
                <mat-card-title style="font-size: 23px; font-weight:bold;">Case Summary Sidebar</mat-card-title>
                <mat-card-subtitle style="font-size: 16px;"><i>Status and Case Number cannot be removed or changed.</i></mat-card-subtitle>
                <mat-card-content>
                    <div formArrayName="items">
                        <div cdkDropList (cdkDropListDropped)="handleReorderDrop($event)">
                            <mat-list>
                                <!-- Column Headers -->
                                <mat-list-item [ngStyle]="{ 'margin-bottom' : sidebarItems.length === 0 ? '' : '-25px' }" style="height:60px; margin-left:-16px">
                                    <div class="flex-row" style="width:100%;">
                                        <div matLine style="display:flex; flex: 1 1 0px; align-items:center;">
                                            <span *ngIf="caseSummaryWriteAccess" style="width:24px; padding-left:0px;"></span>
                                            <span class="table-header">Name</span>
                                        </div>
                                        <div matLine style="flex:0 1 46px"></div>
                                        <div matLine style="display:flex; flex:1 1 0px;align-items:center;">
                                            <span class="table-header">Mapkey</span>
                                        </div>
                                    </div>
                                </mat-list-item>
                                <!-- Column Values -->
                                <mat-list-item *ngFor="let itemFormGroup of sidebarItems?.controls; let i = index" style="height:60px; margin-left:-16px">
                                    <div class="flex-row" style="width:100%;" formGroupName="{{i}}" cdkDrag [cdkDragDisabled]="!caseSummaryWriteAccess">
                                        <!-- Name Column -->
                                        <div mat-line class="flex-row" style="display:flex; flex: 1 1 0px; align-items:center;">
                                            <span class="move-cursor"><mat-icon cdkDragHandle *ngIf="!itemFormGroup.disabled">drag_indicator</mat-icon></span>
                                            <mat-form-field class="wider-input" [ngClass]="itemFormGroup.get('mapkeyId').value.toLowerCase() === 'header' ? 'header-input' : ''">
                                                <input matInput required formControlName="name">
                                                <mat-error *ngIf="itemFormGroup.get('name').hasError('required')">Name is required</mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div matLine style="flex:0 1 46px"></div>
                                        <!-- Mapkey Column -->
                                        <div matLine style="display:flex; flex:1 1 0px;align-items:center;">
                                            <mat-form-field class="wider-input" *ngIf="itemFormGroup.controls.mapkeyId.value.toLowerCase() === 'header'">
                                                <input matInput disabled value="Header">
                                            </mat-form-field>
                                            <map-keys-dropdown
                                                *ngIf="itemFormGroup.controls.mapkeyId.value.toLowerCase() !== 'header'"
                                                class="wider-input"
                                                [displayList]="mapkeys"
                                                showRequiredError="true"
                                                showArrow="true"
                                                ngDefaultControl
                                                [formControl]="itemFormGroup.controls.mapkeyId"></map-keys-dropdown>
                                            <mat-icon *ngIf="caseSummaryWriteAccess" style="margin-left:20px; cursor: pointer" (click)="handleDeleteSidebarMapkey(i)">delete</mat-icon>
                                        </div>
                                    </div>
                                    <!-- Preview HTML for dragging -->
                                    <div *cdkDragPreview>
                                        <div mat-line class="flex-row" style="display:flex; flex: 1 1 0px; align-items:center;">
                                            <div class="wider-input" [ngClass]="itemFormGroup.get('mapkeyId').value.toLowerCase()==='header'?'header-input':''">
                                                <input matInput required formControlName="name">
                                            </div>
                                        </div>
                                        <div matLine style="flex:0 1 46px"></div>
                                        <div matLine style="display:flex; flex:1 1 0px;align-items:center;">
                                            <div class="wider-input" *ngIf="itemFormGroup.get('mapkeyId').value.toLowerCase()==='header'">
                                                <input matInput disabled value="Header">
                                            </div>
                                            <map-keys-dropdown
                                                *ngIf="itemFormGroup.get('mapkeyId').value.toLowerCase()!=='header'"
                                                class="wider-input"
                                                [displayList]="mapkeys"
                                                [disabled]="itemFormGroup.disabled"
                                                showRequiredError="true"
                                                showArrow="true"
                                                ngDefaultControl
                                                [formControl]="itemFormGroup.get('mapkeyId')"></map-keys-dropdown>
                                            <mat-icon *ngIf="caseSummaryWriteAccess" style="margin-left:20px; cursor: pointer" (click)="handleDeleteSidebarMapkey(i)">delete</mat-icon>
                                        </div>
                                    </div>
                                </mat-list-item>
                            </mat-list>
                        </div>
                    </div>
                    <div style="display: flex; flex-direction:row;">

                        <button *ngIf="caseSummaryWriteAccess" type="button" class="btn btn-link" (click)="handleAddRow()" style="padding-left:0;">
                            <span><mat-icon>add</mat-icon></span>
                            Add Mapkey
                        </button>
                        <button *ngIf="caseSummaryWriteAccess" type="button" class="btn btn-link" (click)="handleAddHeaderRow()">
                            <span><mat-icon>add</mat-icon></span>
                            Add Header
                        </button>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </form>
</div>