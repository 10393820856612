<div class="py-2 px-4 bg-light border-bottom documents-header">
  <span class="float-left property-box-title">Attachments</span>
  <button type="button" class="btn btn-link btn-sm float-right custom" (click)="refreshAttachments()">
    <i aria-hidden="true" class="fa fa-sync-alt"></i> Refresh
  </button>
  <button type="button" class="btn btn-link btn-sm float-right custom" (click)="addAttachment()" *ngIf="canAddAttachments">
    <i aria-hidden="true" class="fa fa-paperclip"></i> Add Attachment
  </button>
</div>
<div class="documents-body p-4">
  <p-table [value]="caseDocuments" [lazy]="true" [(selection)]="selectedDocs" *ngIf="caseDocuments.length; else noDocumentsPlaceholder">
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 28px"><p-tableHeaderCheckbox class="prime-header-checkbox-override"></p-tableHeaderCheckbox></th>
        <th style="width: 300px"> Attachment Name</th>
        <th style="width: 100px">Type</th>
        <th>Include in Case Package</th>
        <th>Status</th>
        <th style="width: 150px">File Date</th>
        <th style="width: 200px">Tags</th>
        <th style="width: 70px"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-caseDocument>
      <tr styleClass="ptable-tr" [class.highlighted]="!documentIsTIFF(caseDocument)">
        <td class="ptable-td ptable-td-override">
          <p-tableCheckbox [value]="caseDocument" class="prime-checkbox-override"></p-tableCheckbox>
        </td>
        <td
          (click)="!documentIsTIFF(caseDocument) && viewDocument(caseDocument)"
          [matTooltipDisabled]="!documentIsTIFF(caseDocument)"
          [matTooltip]="toolTipText"
          class="ptable-td text-truncate ptable-td-override"
          [class.clickable]="!documentIsTIFF(caseDocument)">{{ caseDocument.remoteName }}</td>
        <td
          (click)="!documentIsTIFF(caseDocument) && viewDocument(caseDocument)"
          [matTooltipDisabled]="!documentIsTIFF(caseDocument)"
          [matTooltip]="toolTipText"
          class="ptable-td ptable-td-override"
          [class.clickable]="!documentIsTIFF(caseDocument)">{{ caseDocument.doctypeName ?? caseDocument.documentMd5 }}</td>
        <td
          (click)="!documentIsTIFF(caseDocument) && viewDocument(caseDocument)"
          [matTooltipDisabled]="!documentIsTIFF(caseDocument)"
          [matTooltip]="toolTipText"
          class="ptable-td ptable-td-override"
          [class.clickable]="!documentIsTIFF(caseDocument)">
            <i aria-hidden="true" class="fa"
              [ngClass]="{'fa-check-circle text-success': caseDocument.includeInCasePackage, 'fa-times-circle text-danger': !caseDocument.includeInCasePackage}"></i></td>
        <td
          (click)="!documentIsTIFF(caseDocument) && viewDocument(caseDocument)"
          [matTooltipDisabled]="!documentIsTIFF(caseDocument)"
          [matTooltip]="toolTipText"
          class="ptable-td ptable-td-override"
          [class.clickable]="!documentIsTIFF(caseDocument)">{{caseDocument.statusName}}</td>
        <td
          (click)="!documentIsTIFF(caseDocument) && viewDocument(caseDocument)"
          [matTooltipDisabled]="!documentIsTIFF(caseDocument)"
          [matTooltip]="toolTipText"
          class="ptable-td ptable-td-override"
          [class.clickable]="!documentIsTIFF(caseDocument)">
            {{ (caseDocument.lastModifiedDate || caseDocument.creationDate) | date: 'MM/dd/yyyy HH:mm:ss': timeZone }}
        </td>
        <td
          (click)="!documentIsTIFF(caseDocument) && viewDocument( caseDocument)"
          [matTooltipDisabled]="!documentIsTIFF(caseDocument)"
          [matTooltip]="toolTipText"
          class="ptable-td ptable-td-override"
          [class.clickable]="!documentIsTIFF(caseDocument)">
            <mat-chip-listbox>
              <mat-chip-option *ngFor="let tag of caseDocument.tags" class="mat-custom-chip" style="padding: 10px;">{{tag}}</mat-chip-option>
            </mat-chip-listbox>
        </td>
        <td class="ptable-td ptable-td-override">
          <i aria-hidden="true" class="btn-link fa fa-download mr-1 clickable" (click)="downloadDocument(caseDocument)" *ngIf="canDownload"></i>
          <i aria-hidden="true" class="btn-link fa fa-edit mr-1 clickable" (click)="editAttachment(caseDocument)" *ngIf="canAddAttachments"></i>
          <i aria-hidden="true" class="btn-link fa fa-trash-alt clickable" (click)="deleteAttachment(caseDocument)" *ngIf="canAddAttachments"></i>
        </td>
      </tr>
    </ng-template>
  </p-table>

  <ng-template #noDocumentsPlaceholder>
    <div class="p-4 text-center text-muted">
      <img src="./assets/images/no-data-placeholder.png" alt="" class="pb-3" />
      <h6>No Attachments Found</h6>
    </div>
  </ng-template>
</div>
