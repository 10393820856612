<form class="dynamic-form" [formGroup]="form" [ngClass]="{'p-4': !removeIndent}" autocomplete="off">
    <ng-container *ngFor="let field of config; index as i;">
        <div [id]="field?.id"
            [attr.data-test-mapkey]="field.mapKeyName"
            [attr.data-test-answertype]="field.answerType"
            [attr.data-test-required]="field.isRequired"
            [attr.data-test-question-id]="field?.id"
            [attr.data-test-actionIds]="field.actionIds">

            <ng-template *ngIf="getFieldStatus(field)"
                [dynamicField]
                [config]="field"
                [group]="form"
                [expanded]="expandAllQuestions"
                [isSelected]="field?.id == selectedQuestion?.id"
                [interviewMode]="interviewMode"
                [answeredQuestion]="interviewService.answeredQuestion"
                [answeredQuestionForEft]="interviewService.answeredQuestionForEft"
                [formIndex]="parentIndex + i"
                [(constantLists)]="_constantLists">
            </ng-template>
        </div>
    </ng-container>
</form>