<div class="content flex-fill d-flex flex-column">
<div mat-dialog-content>
    <mat-tab-group [selectedIndex]="0" class="flex-fill" (selectedTabChange)="displayTable = false; setupData()">      
        <mat-tab label="Custom" >
            <ng-template matTabContent>
                <mrs-table [data]="functionDataSource" [initialSort]="initialSort" [displayedColumns]="functionsDisplayColumns"  *ngIf="displayTable">
                </mrs-table>
            </ng-template>
        </mat-tab>
        <mat-tab label="Logical">
            <ng-template matTabContent>
                <mrs-table [data]="basicFunctionDataSource" [initialSort]="initialSort" [displayedColumns]="basicFunctionsDisplayColumns" *ngIf="displayTable">
                </mrs-table>
            </ng-template>
        </mat-tab>
        <mat-tab label="Math">
            <ng-template matTabContent>
                <mrs-table [data]="mathFunctionDataSource" [initialSort]="initialSort" [displayedColumns]="mathFunctionsDisplayColumns" *ngIf="displayTable">
                </mrs-table>
            </ng-template>
        </mat-tab>
        <mat-tab label="Values">
            <ng-template matTabContent>
                <mrs-table [data]="valueFunctionDataSource" [initialSort]="initialSort" [displayedColumns]="valueFunctionsDisplayColumns"  *ngIf="displayTable">
                </mrs-table>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>
<mat-dialog-actions align="end">
    <mrs-btn-tertiary buttonLabel="Close" buttonId="cancelButton" mat-dialog-close>Close</mrs-btn-tertiary>
</mat-dialog-actions>
</div>