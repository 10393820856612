import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { CaseRequirement, NoteResponse, UWBaseProps } from '@Underwriting/models';
import { UWRequirementsDataService, UWWorkbenchService } from '@Underwriting/services';
import { SubSink } from 'subsink';
import { UWNotesAddFormProps } from './components/uw-notes-add-form/uw-notes-add-form.component';

@Component({
    selector: 'uw-notes',
    templateUrl: './uw-notes.component.html',
    styleUrls: ['./uw-notes.component.scss']
})
export default class UWNotes implements OnInit {
    @Input() baseProps: UWBaseProps;
    @Output() onNoteAdded = new EventEmitter();

    emptyMessage = 'No Notes Added';
    noteRequiredMessage = 'Note is required';

    uwNotesAddForm: UWNotesAddFormProps = { dropShadow: true };
    notes: NoteResponse[] = [];
    notesLoaded = false;
    caseRequirements: CaseRequirement[] = [];
    caseRequirementsLoaded = false;

    subs = new SubSink();

    constructor(
        public _fb: UntypedFormBuilder,
        private uwWorkbenchService: UWWorkbenchService,
        private uwRequirementsDataService: UWRequirementsDataService,
    ) { }

    //#region Helpers

    enrichNotesForCaseRequirements() {
        // Set the Case Requirement Name/Subtitle from the actual case requirement so it updates with SignalR updates
        this.notes.forEach(note => {
            const caseRequirement = this.caseRequirements.find(x => x.id === note.caseRequirementNote?.caseRequirementId);
            if (!caseRequirement) return;

            note.caseRequirementNote.caseRequirementName = caseRequirement.name;
            note.caseRequirementNote.caseRequirementSubtitle = caseRequirement.subtitle;
        });
    }

    //#endregion
    //#region Subscriptions

    subscribeToNotes() {
        this.subs.add(this.uwRequirementsDataService.loadNotes(this.baseProps.client.code, this.baseProps.case.id).subscribe(notes => {
            this.notes = notes;
            this.notesLoaded = true;
            this.enrichNotesForCaseRequirements();
        }));
    }

    subscribeToCaseRequirements() {
        this.subs.add(this.uwRequirementsDataService.loadCaseRequirements(this.baseProps.client.code, this.baseProps.case.id).subscribe(caseRequirements => {
            this.caseRequirements = caseRequirements;
            this.caseRequirementsLoaded = true;
            this.enrichNotesForCaseRequirements();
        }));
    }

    //#endregion
    //#region Lifecycle

    ngOnInit() {
        this.uwWorkbenchService.setClientInfo(this.baseProps.client);
        this.subscribeToNotes();
        this.subscribeToCaseRequirements();
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    //#endregion
}
