import { Component, Input, OnInit, ViewChildren } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AccountExternal, ManagementQueueAccount } from '@Models';
import { AppService } from '@App';
import { MultiSelectModel } from 'app/components/multi-select-dropdown-list/models/MultiSelectModel.interface';
import {
    MultiSelectDropdownListComponent,
} from 'app/components/multi-select-dropdown-list/multi-select-dropdown-list.component';
import { distinct } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { NewBusinessWorkbenchStateService } from '../services/new-business-workbench-state.service';

@Component({
    selector: 'queue-rules',
    templateUrl: 'queue-rules.component.html',
    styleUrls: ['queue-rules.component.scss']
})

export class QueueRulesComponent implements OnInit {
    private _selectedQueueId: string;

    @Input() clientId: string;
    @Input()
    set selectedQueueId(value) {
        this._selectedQueueId = value;
        if (this.multiSelectDropdownListComponents && this.accounts && this.rules) {
            this.buildRules();
        }
    }
    get selectedQueueId() {
        return this._selectedQueueId;
    }

    @Input() rules: any;
    @Input() accounts: AccountExternal[];

    emptyGUID = '00000000-0000-0000-0000-000000000000';
    @ViewChildren(MultiSelectDropdownListComponent) multiSelectDropdownListComponents: MultiSelectDropdownListComponent[];
    queueRulesFormGroup: UntypedFormGroup;
    queueConfig: any;
    isLoaded = false;
    subs = new SubSink();

    get accountRules() {
        return this.queueRulesFormGroup.get('accounts') as UntypedFormArray;
    }

    constructor(
        private _fb: UntypedFormBuilder,
        private appService: AppService,
        private newBusinessWorkbenchStateService: NewBusinessWorkbenchStateService
    ) {
        this.queueRulesFormGroup = this._fb.group({
            accounts: this._fb.array([]),
        });
    }

    //#region Helpers

    loadQueueRules(resetAccountRules: boolean) {
        // Clear multi-select selections
        if (this.multiSelectDropdownListComponents) {
            this.multiSelectDropdownListComponents.forEach(component => {
                component.clearSelections();
            });
        }

        if (resetAccountRules) {
            this.accountRules.controls.forEach(x =>
                x.get("selectedAccountRules").setValue([])
            );
        }

        // Load new selection values
        this.newBusinessWorkbenchStateService.getCurrentQueueAccounts()?.forEach(account => {
            const accountRulesControl = this.accountRules.controls.filter(accountControl => accountControl.get('accountId').value === account.accountId)[0] as UntypedFormControl;
            if (accountRulesControl) {
                let accountRules = accountRulesControl.get("selectedAccountRules").value;
                if (!accountRules) {
                    accountRules = [];
                }
                if (accountRules.indexOf(x => x == account.ruleId) === -1) {
                    accountRules.push(account?.ruleId);
                }
                accountRulesControl.get("selectedAccountRules").setValue(accountRules.filter(distinct));
            }
        });

        this.appService.showSecondaryLoadingMask(false);
        this.isLoaded = true;
    }

    buildRules() {
        (this.queueRulesFormGroup.get('accounts') as UntypedFormArray).clear();

        this.accounts.forEach(account => {
            const accountRules = this.rules[account.id];
            if (accountRules) {
                const selectModel = new Array<MultiSelectModel>();
                const selectedRules = [];
                let managementQueueAccountRules = [];
                if (this.newBusinessWorkbenchStateService.getCurrentQueueAccounts()) {
                    managementQueueAccountRules = this.newBusinessWorkbenchStateService.getCurrentQueueAccounts().filter(x => x.accountId == account.id);
                }

                accountRules.forEach(rule => {
                    if (managementQueueAccountRules && managementQueueAccountRules.filter(x => x.ruleId == rule.id).length > 0) {
                        selectedRules.push(rule.id);
                    }

                    selectModel.push({
                        id: rule.id,
                        name: rule.name,
                        isSelected: false
                    });
                });

                const accountRulesControl = this._fb.group({
                    accountId: account.id,
                    accountName: account.name,
                    accountRules: this._fb.array(selectModel),
                    selectedAccountRules: [],
                    viewableBy: []
                });

                accountRulesControl.get("selectedAccountRules").setValue(selectedRules);
                this.accountRules.push(accountRulesControl);
            }
        });

        this.accountRules.controls.sort((a, b) => a.get('accountName').value.toLowerCase() > b.get('accountName').value.toLowerCase() ? 1 : -1);


        this.isLoaded = true;
        this.loadQueueRules(true);
    }

    //#endregion
    //#region Lifecycle

    ngOnInit() {
        this.buildRules();
    }

    //#endregion
    //#region Handlers

    ruleSelectionChanged() {
        const accountRules = this.accountRules.value;

        const selectedManagementQueueAccounts: ManagementQueueAccount[] = [];

        accountRules.forEach(account => {
            const accountConfigData = this.newBusinessWorkbenchStateService.getCurrentQueueAccounts()?.filter(mqa => mqa.accountId === account.accountId);

            const selectedRules = account.accountRules.filter(x => x.isSelected);

            selectedRules.forEach(selectedRule => {

                const existingRule = accountConfigData?.find(x => x.ruleId === selectedRule.id);
                if (existingRule) {
                    selectedManagementQueueAccounts.push(existingRule);
                } else {
                    const managementQueueAccount: ManagementQueueAccount = {
                        id: this.emptyGUID,
                        accountId: account.accountId,
                        ruleId: selectedRule.id
                    };

                    selectedManagementQueueAccounts.push(managementQueueAccount);
                }
            });
        });

        this.newBusinessWorkbenchStateService.updateQueueAccounts(selectedManagementQueueAccounts);
    }

    //#endregion
}
