<div class="uw-card">

    <form *ngIf="showForm" class="row" [formGroup]="tagForm" (ngSubmit)="saveTag()" novalidate autocomplete="off">
        <input type="hidden" formControlName="id">
        <input type="hidden" formControlName="workFlowId">
        <div class="col-12 text-right">
            <button type="submit" class="btn btn-link btn-sm custom">
                <i aria-hidden="true" class="fa fa-check"></i> Save
            </button>
            <button type="button" *ngIf="tag.id" class="btn btn-link btn-sm custom" (click)="cancel()">
                <i aria-hidden="true" class="fa fa-times"></i> Close
            </button>
            <button type="button" *ngIf="!tag.id" class="btn btn-link btn-sm custom" (click)="onDelete.emit()">
                <i aria-hidden="true" class="fa fa-trash"></i> Cancel
            </button>
        </div>
        <div class="col-3">
            <mat-form-field class="w-100">
                <mat-label>UW Tag Name</mat-label>
                <input matInput formControlName="tagName">
                <mat-error *ngIf="tagForm.get('tagName').hasError('required')">
                    <strong>required</strong>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-3" formArrayName="uwdecisionTagDetails" *ngFor="let decision of tagForm.get('uwdecisionTagDetails')['controls']; let i = index">
            <div [formGroupName]="i">
                <auto-suggest [list]="rules" placeholder="{{getDecisionCategoryName(decision.value.decisionCategoryId)}} Rule" field="name" dataKey="id" formControlName="ruleId"></auto-suggest>
            </div>
        </div>
    </form>

    <div class="row" *ngIf="!showForm">
        <div class="col-12 text-right">
            <button type="submit" *ngIf="checkACL('W')" class="btn btn-link btn-sm custom" (click)="editTag()">
                <i aria-hidden="true" class="fa fa-edit"></i> Edit
            </button>
            <button *ngIf="checkACL('F')" class="btn btn-link btn-sm custom" (click)="onDelete.emit()">
                <i aria-hidden="true" class="fa fa-trash"></i> Delete
            </button>
            <button class="btn btn-link btn-sm custom custom" *ngIf="(index > 0) && checkACL('W')" (click)="moveUp.emit()">
                <i aria-hidden="true" class="fa fa-arrow-up"></i>
            </button>
            <button class="btn btn-link btn-sm custom" *ngIf="(index < (tagsLength - 1)) && checkACL('W')" (click)="moveDown.emit()">
                <i aria-hidden="true" class="fa fa-arrow-down"></i>
            </button>
        </div>
        <div class="col-3">
            <div class="label-title">UW Tag Name</div>
            <div class="label-content">{{_tag?.tagName}}</div>
        </div>
        <div class="col-3" *ngFor="let listItem of _tag.uwdecisionTagDetails">
            <div class="label-title">{{getDecisionCategoryName(listItem?.decisionCategoryId)}} Rule</div>
            <div class="label-content">{{getRuleName(listItem?.ruleId)}}</div>
        </div>
    </div>

</div>
