<!--- Runtime --->
<div *ngIf="!embeddedData.isCaseSummary">
    <magnum-icw *ngIf="!showErrorMessage" id="magnumEmbeddedApplication" layout="ONE_COLUMN"></magnum-icw>

    <div class="p-4" *ngIf="showErrorMessage">
        <div class="question-container">
            <div class="question-body" *ngIf="showErrorMessage">
                <div id='nonExistentSectionError' *ngIf="showErrorMessage" class="info_banner info_banner-text">
                    {{INTEGRATION_ERROR_MESSAGE}}
                </div>
            </div>
        </div>
    </div>
</div>
<!--- Case Summary --->
<div class="p-4" *ngIf="embeddedData.isCaseSummary">
    <div class="question-container">
        <div class="question-body" *ngIf="embeddedData.isCaseSummary">
            {{CASE_SUMMARY_MESSAGE}}
        </div>
    </div>
</div>