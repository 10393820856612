import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { InterviewService } from '../../interview.service';
import { AppService } from '../../app.service';
import * as moment from "moment-timezone";
import { FeatureManagerService } from '../../services';
import { FeatureToggle } from 'app/enums';

@Component({
    selector: 'app-field-details',
    templateUrl: './field-details.component.html',
    styleUrls: ['./field-details.component.scss']
})
export class FieldDetailsComponent implements OnInit {
    @Input() question;
    @Input() form;
    @Input() readOnlyMode;
    interviewData: any;
    timeZone: string;
    displayQuestionLastUpdated = false;

    /**
     * This component is used for showing field details, action results in readonly mode etc.
     * @param  {InterviewService} interviewService
     */
    constructor(
        public interviewService: InterviewService,
        private appService: AppService,
        private featureManagerService: FeatureManagerService
    ) {
        this.timeZone = this.appService.getTimeZone();
        this.interviewData = this.interviewService.getInterviewData();
    }

    ngOnInit() {        
        this.displayQuestionLastUpdated = this.featureManagerService.getByName(FeatureToggle.GlobalDisplayQuestionLastUpdated).enabled;
    }

    getInnerTextMessageType(config) {
        let messageType = 'message-warning';

        if (config.integrationMessages === 'SSN') {
            if (config.intergrationData && !config.intergrationData.hasDuplicateSSN) {
                messageType = 'message-info';
            }
        } else if (config.integrationMessages === 'GIACT') {
            const giactData = this.interviewService.getGiactData();
            if (giactData.details && giactData.details.result === 'Pass') {
                messageType = 'message-info';
            }
            if (giactData.details && giactData.details.result === 'Fail') {
                messageType = 'message-danger';
            }
        }

        return messageType;
    }

    timeZoneCode(date) {
        if (date) {
            const jun = moment(date);
            return jun.tz('America/Chicago').format('z');
        }
    }

    getInnerText(config) {

        if (config.integrationMessages === 'SSN') {
            if (config.intergrationData && config.intergrationData.hasDuplicateSSN) {
                this.form.get(config.id).setErrors({
                    'ssn': true
                });
                return config.intergrationData.message;
            } else {
                this.form.get(config.id).setErrors(null);
                config.integrationMessages = false;
                return '';
            }


        } else if (config.integrationMessages === 'GIACT') {
            return 'GIACT Response: ' + config.intergrationData;
        }

    }
    getFieldStatus(field) {
        if (field.answerType === 'DisplayMessage' && field.DisplayType === 'Popup') {
            if (field.display) {
                field.display = false;
                return true;
            } else {
                return false;
            }

        }

        if (!field.display) {
            if (this.form.get(field.id)) {
                this.form.removeControl(field.id);
            }
            return false;
        } else {
            // add more conditiosn to this such as display message, actionbuttons
            if (!this.form.get(field.id)) {
                const controValue = field.answerValue ? field.answerValue : '';
                this.form.addControl(field.id, new UntypedFormControl(controValue));
            }
            return true;
        }
    }
}
