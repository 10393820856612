import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterNull',
    pure: false
})

/*
Usage:
    <div *ngFor="let risk of risks | filterNull: ['riskType'])">
where filterNull = array of property names
*/

export class FilterNullPipe implements PipeTransform {
    transform(items: any[], args: string[]): any[] {
        if (typeof items === 'object') {
            let resultArray = [];
            if (args.length === 0) {
                resultArray = items;
            } else {
                for (const item of items) {
                    let passed = true;
                    for (const arg of args) {
                        const propVal = item[args[0]];
                        passed = passed && propVal !== null && propVal !== undefined;
                    }
                    if (passed) {
                        resultArray.push(item);
                    }
                }
            }

            return resultArray;
        } else {
            return null;
        }

    }
}
