import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Account, AccountEvent, DocumentExternal, FaxEvent, Tag } from '../../../models';
import { DocumentsService, MapKeyService, NotificationService, TagService } from '../../../services';


@Component({
    selector: 'document-wizard-document-arrange',
    templateUrl: './document-arrange.component.html',
    styleUrls: ['./document-arrange.component.scss']
})

export class DocumentWizardDocumentArrange implements OnInit {

    public stepOneFormGroup: UntypedFormGroup;
    public addDocsFormGroup: UntypedFormGroup;
    public arrangeDocsFormGroup: UntypedFormGroup;
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];

    accountInfo: Account;
    event: AccountEvent;
    mapKeys: any;
  @Input() nextButtonText: string;
  @Input() canCancel: boolean;
  @Input() documents: DocumentExternal[];
  @Input() tags: Tag[];
  @Input() selectedDocuments: any[] = [];
  @Input() selectedTags: Tag[] = [];
  @Input() selectedItems: any[] = [];
  @Input() isFaxEvent: boolean;
  @Output() completeArrangeEvent = new EventEmitter();
  template: FaxEvent;

  close() {
      this.dialogRef.close(this.selectedItems);
      this.completeArrangeEvent.emit(this.selectedItems);
  }

  constructor(
    private _fb: UntypedFormBuilder,
    public notificationService: NotificationService,
    public mapKeyService: MapKeyService,
    public documentService: DocumentsService,
    public tagService: TagService,
    public dialogRef: MatDialogRef<DocumentWizardDocumentArrange>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  async ngOnInit() {
      this.event = this.data.event;
      this.accountInfo = this.data.accountInfo;
      this.mapKeys = this.mapKeyService.nonConstantMapKeys;

      this.stepOneFormGroup = this._fb.group({
          mapKeyId: ['', Validators.required]
      });
      this.addDocsFormGroup = this._fb.group({});
      this.arrangeDocsFormGroup = this._fb.group({
          documents: []
      });
  }

  isItemSelected(item: any, itemList): boolean {
      const index = itemList.indexOf(item);
      return index >= 0;
  }

  drop(event: CdkDragDrop<any[]>) {
      moveItemInArray(this.selectedItems, event.previousIndex, event.currentIndex);
  }

  remove(item: any): void {
      const selectedItemsIndex = this.selectedItems.indexOf(item);
      const selectedDocumentsIndex = this.selectedDocuments.indexOf(item);
      const selectedTagsIndex = this.selectedTags.indexOf(item);

      if (selectedItemsIndex >= 0) {
          this.selectedItems.splice(selectedItemsIndex, 1);
      }

      if (selectedDocumentsIndex >= 0) {
          this.selectedDocuments.splice(selectedDocumentsIndex, 1);
      }

      if (selectedTagsIndex >= 0) {
          this.selectedTags.splice(selectedTagsIndex, 1);
      }
  }

}
