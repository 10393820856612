<div class="flex flex-column">
    <div class="flex flex-row">
        <form [formGroup]="orderForm" (ngSubmit)="getReport()" novalidate>
            <div class="d-flex mt-2" style="align-items: baseline;">
                <div class="mr-5">
                    <mat-form-field class="w-100">
                        <mat-select placeholder="Client Name" formControlName='clientId' (selectionChange)="getAccounts()">
                            <mat-option *ngFor="let client of clients" [value]="client.id">
                                {{client.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="orderForm.get('clientId').hasError('required')">
                            required
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="mr-5">
                    <mat-form-field class="w-100">
                        <mat-select placeholder="Account Name" formControlName='accountId'>
                            <mat-option *ngFor="let account of accounts" [value]="account.id">
                                {{account.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="orderForm.get('accountId').hasError('required')">
                            required
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="mr-5">
                    <button class="ml-2 float-right" type="button" mat-button color="primary" *ngIf="filteredData.length" (click)="downloadReport()">
                        <i aria-hidden="true" class="fa fa-download"></i> Download
                    </button>
                    <mrs-btn-icon buttonLabel="Show Report" buttonId="showReportButton" isSubmitButton="true"
                        iconName="search"></mrs-btn-icon>
                </div>
            </div>
        </form>
    </div>

    <div *ngIf="filteredData.length" class="d-flex flex-row flex-nowrap overflow-auto">
        <mrs-table [data]="reportData" [displayedColumns]="displayedColumns" [searchableFields]="searchableFields">
        </mrs-table>
      </div>

    <div class="pt-3" *ngIf="noDataPlaceholder">
        <div >
            <div class="p-4 text-center text-muted ">
                <img src="./assets/images/no-data-placeholder.png " alt=" " class="pb-3 " />
                <h6>No Data Found</h6>
            </div>
        </div>
    </div>
</div>
