﻿<!-- Page Header / Configure Button-->
<div class="case-manager-header">
    <div class="main-page-heading">
        Case Manager
    </div>
    <div style="margin-left: auto">
        <ng-container *ngIf="!getNextCaseIsLoaded">
            <mat-spinner mode="indeterminate" diameter="30"></mat-spinner>
        </ng-container>
        <mrs-btn-tertiary *ngIf="canConfigure && showGetNextCase" buttonLabel="Configure" iconName="edit" (onClick)="editQueues()"></mrs-btn-tertiary>
        <mrs-btn-icon *ngIf="canConfigure && !showGetNextCase" buttonLabel="Configure" iconName="edit" (onClick)="editQueues()"></mrs-btn-icon>
        <span *ngIf="showGetNextCase" class="ml-2">
            <button class="float-right" color="primary" [disabled]="!getNextCaseIsLoaded" aria-label="Get the next case to work on" (click)="getNextCase()">
                <span class="material-icons">add</span>
                <span class="inner-text-margin">GET NEXT CASE</span>
            </button>
        </span>
    </div>
</div>

<!-- Main Content -->
<div class="case-manager-container">
    <div class="case-manager-inner-container">
        <div class="flex flex-column edit-content" style="align-items: center;" *ngIf="queueDataLoaded && queues.length === 0">
            <div class="mt-5">
                <mat-icon class="large-warning-icon">error_outline</mat-icon>
            </div>
            <h2 class="mt-4">You do not have access to any queues.</h2>
        </div>
        <div *ngIf="queues?.length > 0">
            <div class="flex flex-align-center" style="justify-content: space-between">
                <div>
                    <mat-form-field id="filterCasesFormField" class="filter-cases" floatLabel="never" appearance="fill">
                        <input class="case-summary-search-input" matInput placeholder="Search for a Case" name="filterValue" (keyup)="updateFilteredCases()" [(ngModel)]="filterValue">
                        <mat-icon class="case-summary-search-icon" aria-hidden="false" aria-label="Case search icon" matSuffix>search</mat-icon>
                    </mat-form-field>
                </div>
                <div class="ml-2" style="width:22rem;" *ngIf="hasMultipleClients">

                    <app-multi-select-dropdown-list #clientDropdown class="multi-select-dropdown-list"
                        [listTitle]="'Filter by Client'" [completeList]="clientSelectListItems"
                        [selectedItemsList]="onLoadSelectedClientsList" (selectionChanged)="selectedClientsChanged($event)">
                    </app-multi-select-dropdown-list>
                </div>
                <div class="ml-2" style="width:22rem;">

                    <app-multi-select-dropdown-list #assigneeDropdown class="multi-select-dropdown-list"
                        [listTitle]="'Filter by Assignee(s)'" [completeList]="assigneeSelectListItems"
                        [selectedItemsList]="onLoadSelectedAssigneesList" (selectionChanged)="selectedAssigneesChanged($event)">
                    </app-multi-select-dropdown-list>
                </div>

                <div style="margin-left: auto">
                    <button type="button" class="btn float-right standard mrs-primary text-uppercase"
                        (click)="getCases(selectedQueue)">
                        <i aria-label="refresh" class="fa fa-sync-alt rotate90"></i> Refresh
                    </button>
                </div>
            </div>

            <!-- Queue Tabs and Content -->
            <mat-tab-group (selectedTabChange)="selectQueue($event)" [selectedIndex]="selectedIndex">
                <mat-tab *ngFor="let queue of queues; let index = index" style="overflow: hidden;">
                    <!-- Queue Tab -->
                    <ng-template mat-tab-label>
                        <mat-icon *ngIf="queue.isNewQueue" class="new-queue-icon mr-2">fiber_new</mat-icon>
                        {{queue.name}}
                    </ng-template>

                    <!-- Content -->
                    <ng-template matTabContent>
                        <div class="d-flex flex-row flex-nowrap overflow-auto">
                            <table #dataTable mat-table matSort [matSortActive]="initialSort" [dataSource]="caseDataSource" style="width:100%;overflow:hidden;">

                                <!-- Select Box -->
                                <ng-container matColumnDef="selection" sticky="true">
                                    <th mat-header-cell *matHeaderCellDef style="max-width:55px; min-width: 55px" id="selectionHeader"></th>
                                    <td mat-cell *matCellDef="let element" (click)="stopPropagation($event)">
                                        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(element) : null" [checked]="selection.isSelected(element)"></mat-checkbox>
                                    </td>
                                </ng-container>

                                <!-- Dynamic Columns -->
                                <ng-container [matColumnDef]="column.columnDefinition" *ngFor="let column of columnMapkeys | filterNull: ['mapKeyName']">
                                    <th [id]="column.columnDefinition" mat-header-cell mat-sort-header sortingCache *matHeaderCellDef style="width:200px;">
                                        {{ (queue.managementQueueColumnMapKeys | filterExact : ['mapKeyName'] : column.mapKeyName)[0]?.displayName }}
                                    </th>
                                    <td mat-cell *matCellDef="let element"> {{element[column.mapKeyName]}} </td>
                                </ng-container>

                                <!-- Note -->
                                <ng-container matColumnDef="note">
                                    <th mat-header-cell *matHeaderCellDef style="min-width: 150px; max-width:175px;" (click)="sortColumn('note')" id="noteHeader">
                                        {{(queue.managementQueueColumnMapKeys|filterExact : ['columnDefinition'] : 'note')[0]?.displayName}}
                                        <em aria-hidden="true" class="fas fa-arrow-up" *ngIf="settings?.sortActiveColumn === 'note' && settings?.sortDirection === 'desc'"></em>
                                        <em aria-hidden="true" class="fas fa-arrow-down" *ngIf="settings?.sortActiveColumn === 'note' && settings?.sortDirection === 'asc'"></em>
                                    </th>
                                    <td style="min-width: 150px; max-width: 175px; white-space: break-spaces;" mat-cell *matCellDef="let element" [innerHTML]="element.latestNoteContent | removeHTMLTags"></td>
                                </ng-container>

                                <!-- Actions -->
                                <ng-container matColumnDef="actions">
                                    <th *matHeaderCellDef></th>
                                    <td mat-cell class="ml-2 align-content-center" *matCellDef="let element" (click)="stopPropagation($event)" class="cell-icon">
                                        <em aria-hidden="true"
                                            class="ml-1 fa fa-asterisk"
                                            style="color: red; margin-right: 10px;"
                                            *ngIf="element.usersInCase && element.usersInCase != null"
                                            [matTooltip]="element.usersInCase"></em>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
                                <tr mat-row *matRowDef="let row; columns: columnsToDisplay;" (click)="viewCaseDetails(row)" style="cursor: pointer;" (contextmenu)="openMenu($event, row)"></tr>
                            </table>
                        </div>
                        <div *ngIf="caseDataSource.filteredData == null || caseDataSource.filteredData.length == 0" class="bg-white">
                            <div class="p-4 text-center text-muted ">
                                <img src="./assets/images/no-data-placeholder.png " alt=" " class="pb-3 " />
                                <h6>No Cases Found</h6>
                            </div>
                        </div>
                    </ng-template>
                </mat-tab>
            </mat-tab-group>

            <!-- Paginator below the Table Content -->
            <div class="flex mat-paginator-sticky mat-mdc-paginator-container">
                <div id="actionButtons" *ngIf="selection.selected.length > 0" class="flex" [@flyRightToLeft]>
                    <div id="selectedChip" style="overflow: visible; margin-bottom: auto; margin-top: auto">
                        <mat-chip-listbox>
                            <mat-chip-option>{{selection.selected.length}} selected</mat-chip-option>
                        </mat-chip-listbox>
                    </div>
                    <div>
                        <button type="button" class="btn btn-link text-uppercase" [matMenuTriggerFor]="assignToMenu"
                            (menuOpened)="getClientUsers()" matTooltip="Assign To" aria-label="Assign To" aria-describedby="Assign To"
                            *ngIf="canAssignCase && canSelectedCasesBeAssign()">
                            <em class="material-icons">assignment_ind</em>
                            Assign To
                        </button>
                        <button type="button" class="btn btn-link text-uppercase" *ngIf="selection.selected.length===1"
                            (click)="viewNotes(selection.selected[0])" matTooltip="Open Notes" aria-label="Open Notes"
                            aria-describedby="Open Notes">
                            <em class="material-icons">note</em>
                            View Notes
                        </button>
                        <button type="button" class="btn btn-link text-uppercase" matTooltip="Open In New"
                            aria-label="Open In New Tab" aria-describedby="Open In New Tab" (click)="openInNewWindow()">
                            <em class="material-icons">open_in_new</em>
                            Open in new tab
                        </button>
                    </div>
                </div>
                <div style="margin-left: auto">
                    <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
                </div>
            </div>
        </div>
    </div>

    <div id="actionMenuOverlay" style="visibility: hidden; position: fixed" [style.left]="contextMenuPosition.x" [style.top]="contextMenuPosition.y" [matMenuTriggerFor]="actionMenu"></div>
</div>

<!-- Right Click / Checkbox Menu-->
<mat-menu #actionMenu="matMenu">
    <ng-template matMenuContent>
        <div class="context-menu">
            <div class="menu-item text-uppercase" mat-menu-item [matMenuTriggerFor]="assignToMenu" (menuOpened)="getClientUsers()" *ngIf="canAssignCase && canSelectedCasesBeAssign()">
                <em class="material-icons mr-4">assignment_ind</em>Assign To
            </div>
            <div class="menu-item text-uppercase" mat-menu-item (click)="viewNotes(selection.selected[0])" *ngIf="selection.selected.length === 1">
                <em class="material-icons mr-4">note</em>View Notes
            </div>
            <div class="menu-item text-uppercase" mat-menu-item (click)="openInNewWindow()">
                <em class="material-icons mr-4">open_in_new</em>
                Open in new tab
            </div>
        </div>
    </ng-template>
</mat-menu>

<mat-menu #assignToMenu="matMenu" [aria-labelledby]="'Assign To Menu'" yPosition="above">
    <ng-template matMenuContent>
        <div class="menu-item" mat-menu-item *ngIf="clientUsers.length > 0">
            <form novalidate (click)="stopPropagation($event)">
                <mat-form-field class="w-100">
                    <mat-label>Assign to</mat-label>
                    <input type="text" #userInput [placeholder]="'Select User...'" matInput [formControl]="selectedUser"
                        [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption
                        (optionSelected)="selectedUser.value === this.appService.emptyGUID?claimCase():assignUser()"
                        [displayWith]="getOptionText.bind(this)">
                        <mat-option [value]="appService.emptyGUID">Unassign</mat-option>
                        <mat-option *ngFor="let clientUser of filteredUserList" [value]="clientUser.id">
                            {{clientUser.firstName}} {{clientUser.lastName}}
                        </mat-option>
                    </mat-autocomplete>
                    <mat-error *ngIf="selectedUser.hasError('required')">
                        Please select a user to assign the case to
                    </mat-error>
                </mat-form-field>
            </form>
        </div>
        <div mat-menu-item *ngIf="clientUsers.length==0">No users for selection.</div>
    </ng-template>
</mat-menu>