import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { ActivatedRoute, Route } from '@angular/router';
import { CaseDocumentsDTO } from '@DTOs';
import { CaseDocumentTypes } from '@Enums';
import { HubConnection } from '@microsoft/signalr';
import { Account, Client } from '@Models';
import { CaseDocumentsDataService, CaseNotificationsService, SignalRService } from '@Services';
import { CaseRequirementResponse } from '@Underwriting/models';
import { UWRequirementsDataService } from '@Underwriting/services';
import { ViewDocumentDialog, ViewDocumentDialogData } from 'app/components/view-document-dialog';
import { UWWorkbenchUpdateSignalr } from 'app/models/uwworkbench';
import { combineLatest } from 'rxjs';
import { SubSink } from 'subsink';

@Component({
    selector: 'view-document',
    templateUrl: './view-document-page.component.html',
    styleUrls: ['./view-document-page.component.scss']
})
export default class ViewDocumentPage implements OnInit {
    subs = new SubSink();

    client: Client;
    account: Account;
    caseId: string;
    caseDocumentId: string;
    caseDocumentDocuments: CaseDocumentsDTO[] = [];
    caseDocumentAttachments: CaseDocumentsDTO[] = [];
    caseDocument: CaseDocumentsDTO;
    caseDocumentType: CaseDocumentTypes;
    caseRequirementId: string;
    caseRequirement: CaseRequirementResponse;

    dialogRef: MatDialogRef<ViewDocumentDialog, ViewDocumentDialogData>;

    signalrConnection: HubConnection;

    isAttachment = false;

    constructor(
        private activatedRoute: ActivatedRoute,
        private dialog: MatDialog,
        private caseDocumentsDataService: CaseDocumentsDataService,
        private uwRequirementsDataService: UWRequirementsDataService,
        private signalrService: SignalRService,
        private caseNotificationsService: CaseNotificationsService,
    ) {
    }

    //#region Helpers

    getDocumentType() {
        if (this.isAttachment)
            return CaseDocumentTypes.Attachment;

        return CaseDocumentTypes.Config; // Really it can be anything but attachment
    }

    openDialog() {
        // Make sure we only open the modal once. Prevent observables form re-opening the dialog.
        if (this.dialogRef && this.dialogRef.getState() == MatDialogState.OPEN)
            return;

        this.dialogRef = this.dialog.open<ViewDocumentDialog, ViewDocumentDialogData, ViewDocumentDialogData>(ViewDocumentDialog, {
            disableClose: true,
            width: '100%',
            height: '100%',
            maxWidth: '100vw',
            maxHeight: '100vh',
            data: {
                client: this.client,
                account: this.account,
                caseId: this.caseId,
                caseRequirementId: this.caseRequirementId,
                caseDocument: this.caseDocument,
                caseDocumentType: this.caseDocumentType,
                fullscreen: true,
                hideClose: true,
            }
        });
    }

    FindAndSetDocument() {
        // DEV NOTE: A document could be in either one of these groups, we need to search both.
        this.caseDocument = this.caseDocumentAttachments.find(x => x.id == this.caseDocumentId);
        if (this.caseDocument) {
            this.caseDocumentType = CaseDocumentTypes.Attachment;
            return;
        }


        this.caseDocument = this.caseDocumentDocuments.find(x => x.id == this.caseDocumentId);
        if (this.caseDocument) {
            this.caseDocumentType = CaseDocumentTypes.Config;
            return;
        }

    }

    //#endregion
    //#region Signalr

    signalrStarted() {
        this.subs.add(this.caseNotificationsService.addUserToCase(this.client.id, this.caseId).subscribe(_next => {
            console.log('SignalR connection started');
        }));
    }

    signalrOnClose() {
        this.caseNotificationsService.removeUserFromCase();
        console.log('SignalR Disconnected ');
    }

    signalrHandleUWWorkbenchUpdate(event: UWWorkbenchUpdateSignalr) {
        if (event.caseId !== this.caseId) {
            console.warn(`CaseId's Don't match. signalr: [${event.caseId}] <=> [${this.caseId}] : current page`);
            return;
        }

        // Only Case Requirements 
        if (event.caseRequirementIds?.length) this.uwRequirementsDataService.reloadCaseRequirements(this.client.code, event.caseId, true);
    }

    connectToSignalr() {
        // Create HubConnection
        this.signalrConnection = this.signalrService.getCaseSignalRConnection(this.caseId);
        this.signalrConnection.serverTimeoutInMilliseconds = 1000 * 60 * 15; // 15 minutes

        // Setup Listeners
        this.signalrConnection.on('connected', event => console.log('SignalR Connected : ' + event));
        this.signalrConnection.on('underwriterWorkbenchUpdate', this.signalrHandleUWWorkbenchUpdate.bind(this));
        this.signalrConnection.onclose(this.signalrOnClose.bind(this));

        // Start SignalR
        this.signalrConnection.start().then(this.signalrStarted.bind(this)).catch(err => console.error(err?.message));
    }

    //#endregion
    //#region Subscriptions

    subscribeToData() {
        this.subs.add(combineLatest([
            this.activatedRoute.data,
            this.activatedRoute.params,
            this.activatedRoute.queryParams
        ]).subscribe(([data, params, queryParams]) => {
            this.client = data.client;
            this.account = data.account;
            this.caseId = params.caseId;
            this.caseDocumentId = params.caseDocumentId;
            this.caseRequirementId = queryParams['caseRequirementId'];

            this.subs.add(
                combineLatest([
                    this.caseDocumentsDataService.loadAttachments(this.caseId),
                    this.caseDocumentsDataService.loadDocuments(this.caseId)
                ]).subscribe(([attachments, documents]) => {
                    this.caseDocumentAttachments = attachments || [];
                    this.caseDocumentDocuments = documents || [];

                    this.FindAndSetDocument();
                    this.openDialog();
                })
            );
        }));
    }

    //#endregion
    //#region Lifecycle

    ngOnInit() {
        this.subscribeToData();
        this.connectToSignalr();
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
        this.signalrConnection?.stop();
    }

    //#endregion
}

export const ViewDocumentPageRoute: Route = {
    path: 'view-document/:caseDocumentId',
    component: ViewDocumentPage
};
