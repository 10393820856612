import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, FormControl, Validators } from '@angular/forms';
import { AppService } from '../../app.service';
import * as _ from 'lodash';

import { Account } from '../../models';

@Component({
    selector: 'subrule',
    templateUrl: './subrule.component.html',
    styleUrls: ['./subrule.component.scss']
})
export class SubRuleComponent implements OnInit {

    @Input() accountInfo: Account;
    @Input('config') subRuleForm: UntypedFormGroup;
    @Input() mapKeys;
    @Input() nonListNonVirtualMapKeys;
    @Input() variableMapKeys;
    @Input() nonVirtualMapKeys;
    expanded: boolean;
    isSubRule: boolean;
    isEditView: boolean;
    @Output() deleteSubRuleEvent = new EventEmitter<any>();
    @Input() comboData;
    @Input() rules;
    @Input() formSubmitted;

    @Input() isParentEditView;
    @Input() testResult;
    @Input() isTestExecuted;

    collapseChildren: boolean;

    ruleForm: UntypedFormGroup;

    isSubRuleSelected: boolean;
    showSubRuleDetails: boolean;
    // rules: any[];

    subRuleFormSelect: UntypedFormGroup;

    constructor(private _fb: UntypedFormBuilder, private appService: AppService) {

        this.isSubRuleSelected = false;
        this.showSubRuleDetails = false;

    }

    ngOnInit() {
        this.subRuleFormSelect = this._fb.group({
            targetRuleId: ['', Validators.required]
        });

        // if(this.subRuleForm.get('type')) { //subrule
        this.expanded = false;
        this.isSubRule = true;
        this.isEditView = false;
        this.collapseChildren = false;
        // } else { // rule
        //     this.expanded = true;
        //     this.isSubRule = false;
        //     this.isEditView = true;
        //     this.collapseChildren = true;
        // }

        // this.expanded = true;

        // this.getRules();

    }

    @Input()
    set collapseMe(value) {
        this.expanded = value;
    }

    deleteRule() {
    }

    deleteSubRule() {
        this.deleteSubRuleEvent.emit();
    }


    insertSubRule() {

        // debugger;

        if (this.subRuleFormSelect.valid) {
            this.getRule(this.subRuleFormSelect.get('targetRuleId').value);
        } else {
            this.subRuleFormSelect.controls['targetRuleId'].markAsTouched();
        }

    }

    getRule(rule) {
        this.appService.getData(`Rules/${this.accountInfo.id}/Rule/${rule}`).subscribe(
            data => {
                if (data.status === 'success') {
                    // this.ruleConfig = data.data;
                    this.isSubRuleSelected = true;

                    console.log(data.data);
                    // debugger;
                }
            }
        );
    }

    getSubRuleDetails() {
        this.appService.getData(`Rules/${this.accountInfo.id}/Rule/${this.subRuleForm.get('targetRuleId').value}`).subscribe(
            data => {
                if (data.status === 'success') {
                    // this.ruleConfig = data.data;
                    // this.isSubRuleSelected = true;

                    // this.

                    // console.log(data.data);
                    // debugger;

                    this.addSubRuleDetails(data.data);

                } else {

                    this.appService.showMsg('error', data.message);
                }
            }
        );
    }


    addSubRuleDetails(ruleConfig) {
        // this.subRuleForm.reset();



        this.subRuleForm.patchValue({
            id: ruleConfig.id,
            name: ruleConfig.name,
            filterId: ruleConfig.filterId,
            AccountId: ruleConfig.accountId,
            // conditionSets: conditionSets
        });

        // let conditionSets = this._fb.array([]);
        const conditionSets = this.subRuleForm.get('conditionSets') as UntypedFormArray;

        // conditionSets = [];

        if (conditionSets) {
            while (conditionSets.length) {
                conditionSets.removeAt(0);
            }
        }

        ruleConfig.conditionSets.forEach(conditionSet => {

            if (conditionSet.targetRuleId) {

                conditionSets.push(this._fb.group({
                    type: 'Rule',
                    targetRuleId: conditionSet.targetRuleId,
                    name: '',
                    filterId: '',
                    AccountId: '',
                    conditionSets: this._fb.array([])
                }));


            } else {

                const conditions = this._fb.array([]);
                // let conditions = conditionSets.get('conditions') as FormArray;

                conditionSet.conditionSet.conditions.forEach(condition => {

                    conditions.push(this._fb.group({
                        operand: [condition.operand, Validators.required],
                        operatorId: [condition.operatorId, Validators.required],
                        operandTypeId: [condition.operandTypeId, Validators.required],
                        valueoperand: condition.valueOperand,
                        mapkeyOperand: condition.mapkeyOperand,
                        jsonPropertyId: condition.jsonPropertyId,
                        jsonResponse: ['']
                    }));

                });

                conditionSets.push(this._fb.group({
                    type: 'ConditionSet',
                    filterId: conditionSet.conditionSet.filterId,
                    conditionSet: conditionSet,
                    conditions: conditions
                }));

                // debugger;
            }

        });


        this.expanded = true;
        this.isEditView = false;
        this.collapseChildren = false;
        this.showSubRuleDetails = true;
    }

    getRuleName(id) {
        return _.find(this.rules, ['id', id]).name;
    }

    checkACL(permissionType, redirect?) {
        return this.appService.checkACL('Accounts', permissionType, redirect);
    }

    getConditionSet(i) {
        if (this.subRuleForm.value.conditionSets) {
            return this.subRuleForm.value.conditionSets[i];
        }
        return null;
    }

    getConditionSetTestResult(i) {
        if (this.testResult && this.testResult.conditionSetResults) {
            // Find the condition set
            const cs = this.getConditionSet(i);
            if (cs) {
                if (cs.targetRuleId) {
                    return this.testResult.conditionSetResults.find(tr => tr.ruleId === cs.targetRuleId);
                } else {
                    return this.testResult.conditionSetResults.find(tr => tr.conditionSetId === cs.conditionSet.conditionSetId);
                }
            }
        }
        return null;
    }
}
