import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { NotificationService, NotificationSeverity, UtilityService } from 'app/services';
import { BaseInterviewFormFieldComponent, BaseFormFieldServices } from '../base-interview-form-field/base-interview-form-field.component';

@Component({
    selector: 'display-message',
    templateUrl: './display-message.component.html',
    styleUrls: ['./display-message.component.scss']
})
export class DisplayMessageComponent extends BaseInterviewFormFieldComponent implements OnInit {
    constructor(
        private notificationService: NotificationService,
        private utilityService: UtilityService,
        public services: BaseFormFieldServices) {

        super(services);
    }

    ngOnInit() {
        let messageType = 'info';

        if (this.config.MessageType == null) {
            this.config.MessageType = 'Information';
        } else if (this.config.MessageType === 'Information') {
            messageType = 'info';
        } else {
            messageType = this.config.MessageType.toLowerCase();
        }

        if (this.config.DisplayType === 'Popup' && this.interviewMode) {
            const formattedMessage = this.utilityService.removeHtmlFormatting(this.config.questionText);
            this.notificationService.showNotification({
                severity: messageType as NotificationSeverity,
                message: formattedMessage
            });
        }

        super.subscribeToTextUpdates();
    }
}
