import {Component, Input, OnInit, ViewChild, EventEmitter, Output} from '@angular/core';
import {Router} from '@angular/router';
import { MatMenu } from '@angular/material/menu';
import * as _ from 'lodash';

@Component({
    selector: 'app-menu-item',
    templateUrl: './mapkey-menu-item.component.html',
    styleUrls: ['./mapkey-menu-item.component.scss'],
})
export class MenuItemComponent implements OnInit {
  @Input() items: any[];
  @Output() onItemSelect = new EventEmitter<any>();

  @ViewChild("menu", {static: true}) menu: MatMenu;

  filteredItems: any[];
  constructor(public router: Router) {
  }

  ngOnInit() {
      const cloneItems = _.cloneDeep(this.items);

      this.filteredItems = cloneItems.filter(x => { return x.canAdd; });
      this.filteredItems.forEach(element => {
          if (element.children && element.children.length > 0) {
              element.children = element.children.filter(x => {return x.canAdd; });
          }
      });
  }

  itemSelected(item) {
      this.onItemSelect.emit(item);
  }
}
