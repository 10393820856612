<h2 mat-dialog-title>{{ title }}</h2>

<form [formGroup]="subtitleForm">
  <mat-dialog-content>
      <mat-form-field [style.width.px]=324>
        <mat-label>Subtitle</mat-label>
        <input matInput formControlName="subtitle" maxlength="32">
        <mat-hint *ngIf="subtitleForm?.get('subtitle').valid">
          {{(32 - subtitleForm?.get('subtitle').value?.length) < 0 ? 0 : 32 -
          subtitleForm?.get('subtitle').value?.length}}/32 characters remaining
        </mat-hint>
      </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <mrs-btn-tertiary buttonLabel="Cancel" buttonId="closeButton" (onClick)="cancel()">
    </mrs-btn-tertiary>
    <mrs-btn-tertiary buttonLabel="Save" buttonId="okButton" (onClick)="updateSubtitle()" textColor="#2C6EED">
    </mrs-btn-tertiary>
  </mat-dialog-actions>
</form>
