<h1 mat-dialog-title>Edit Fax Action</h1>
<div mat-dialog-content id="event-fax-modal">
  <div class="flex-fill d-flex flex-column overflow-y-auto">
    <mat-horizontal-stepper linear #stepper labelPosition="bottom">
      <mat-step id="step1" [stepControl]="stepOneFormGroup" label="Add Fax Number" class="step-one-fax">
        <form [formGroup]="stepOneFormGroup" style="min-height: 200px">
          <div class="page-sub-header mb-1">
            Where should we send the fax?
          </div>
          <div>
            <map-keys-dropdown
              [displayList]="mapKeys"
              placeholder="Mapkey"
              formControlName="mapKeyId"
              [formControl]="stepOneFormGroup.get('mapKeyId')"
              ></map-keys-dropdown>
          </div>
        </form>
        <mat-dialog-actions align="end">
          <button id="closeButton" mat-button id="cancelButton" color="basic" class="mr-1 material-default" mat-dialog-close>
            CANCEL
          </button>
          <button id="addPagesButton" mat-button color="primary" class="mr-1 material-default" matStepperNext>
            ADD DOCUMENTS
          </button>
        </mat-dialog-actions>
      </mat-step>
      <mat-step id="step2" [stepControl]="stepTwoFormGroup" label="Add Documents" class="step-two-fax">
        <document-wizard-document-add
          [nextButtonText]="'ARRANGE DOCUMENTS'"
          [canCancel]="true"
          [documents]="documents"
          [tags]="tags"
          [selectedDocuments]="selectedDocuments"
          [selectedTags]="selectedTags"
          [selectedItems]="selectedItems"
          ></document-wizard-document-add>
      </mat-step>
      <mat-step id="step3" [stepControl]="stepThreeFormGroup" label="Arrange Documents" class="step-three-fax">
        <document-wizard-document-arrange
          [nextButtonText]="'ADD TO EVENT'"
          [canCancel]="true"
          [documents]="documents"
          [tags]="tags"
          [selectedDocuments]="selectedDocuments"
          [selectedTags]="selectedTags"
          [selectedItems]="selectedItems"
          (completeArrangeEvent)="saveToEvent()"
          ></document-wizard-document-arrange>
      </mat-step>
    </mat-horizontal-stepper>
  </div>
