<div class="flex flex-column">
  <div class="flex flex-row">
    <form [formGroup]="orderForm" novalidate >
      <div class="d-flex mt-2">
        <div class="mr-3">
          <client-account-dropdown formField="account" [formGroup]="orderForm" [clientAccountSplitOverride]="clientAccountOverride"></client-account-dropdown>
        </div>
        <div class="mr-3">
          <div class="report-date-input">
            <mat-form-field >
              <mat-label>Choose a start date</mat-label>
              <input matInput [matDatepicker]="startDate" formControlName="startDate"
                readonly>
              <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
              <mat-datepicker #startDate disabled="false"></mat-datepicker>
              <mat-error *ngIf="orderForm.get('startDate').hasError('required')">required</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="mr-3">
          <div class="report-date-input">
            <mat-form-field>
              <mat-label>Choose an end date</mat-label>
              <input matInput [matDatepicker]="endDate" formControlName="endDate"
                readonly>
              <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
              <mat-datepicker #endDate disabled="false"></mat-datepicker>
              <mat-error *ngIf="orderForm.get('endDate').hasError('required')">required</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="mr-3">
          <mat-form-field >
            <mat-label>Order State</mat-label>
            <mat-select formControlName='orderStateIds' multiple>
              <mat-option #allSelected (click)="toggleAllSelection()" value="ALL">All</mat-option>
              <mat-option *ngFor="let status of caseStatus" [value]="status.id" (click)="togglePerOne(allSelected.viewValue)">
                {{status.name}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="orderForm.get('orderStateIds').hasError('required')">
              required
            </mat-error>
          </mat-form-field>
        </div>
        <div class="flex flex-row mr-3" style="justify-content: end;">
            <mrs-btn-tertiary class="float-right ml-3" buttonLabel="Change To Billed" buttonId="changeToBilled"
            (onClick)="changeToBilled()" *ngIf="filteredData.length" textColor="#2C6EED"></mrs-btn-tertiary>
          <mrs-btn-tertiary class="float-right ml-3" buttonLabel="Download CSV" buttonId="downloadCsvButton"
            (onClick)="downloadReport('csv')" iconName="download" *ngIf="filteredData.length" textColor="#2C6EED"></mrs-btn-tertiary>
          <mrs-btn-tertiary class="float-right ml-3" buttonLabel="Download XLS" buttonId="downloadXlsButton"
            (onClick)="downloadReport('xls')" iconName="download" *ngIf="filteredData.length" textColor="#2C6EED"></mrs-btn-tertiary>            
          <mrs-btn-icon class="float-right" buttonLabel="Show Report" buttonId="showReportButton"
          (onClick)="getReport()" iconName="search"></mrs-btn-icon>
        </div>
      </div>
    </form>
  </div>

  <div *ngIf="filteredData.length" class="d-flex flex-row flex-nowrap overflow-auto">
    <mrs-table [data]="reportData" [displayedColumns]="displayedColumns" [searchableFields]="searchableFields"
      (tableEvent)="tableAction($event)" [tableWidth]="scrollWidth + 600">
    </mrs-table>
  </div>

  <div class="pt-3">
    <div *ngIf="noDataPlaceholder">
      <div class="p-4 text-center text-muted ">
        <img src="./assets/images/no-data-placeholder.png " alt=" " class="pb-3 " />
        <h6>No Data Found</h6>
      </div>
    </div>
  </div>
</div>
