<div class="content flex-fill d-flex flex-column">
  <h1 mat-dialog-title>Edit Embedded Documents</h1>
  <div mat-dialog-content>

    <mat-card-title style="min-height: 36px;">
      <button mat-raised-button color="primary" class="float-right material-default" (click)="add()"
      *ngIf="selectedControl === null">
        <i aria-hidden="true" class="fa fa-plus"></i> ADD
      </button>
    </mat-card-title>
    <div class="flex-fill d-flex flex-column overflow-y-auto">
      <form class="px-5 py-3" [formGroup]="embeddedDocumentForm" novalidate autocomplete="off">
        <div class="drag-drop-list" cdkDropList (cdkDropListDropped)="dropDocument($event)">
          <mat-card appearance="outlined" formArrayName="embeddedDocuments"
            *ngFor="let control of embeddedDocumentForm.get('embeddedDocuments')['controls']; let i = index"
            cdkDrag [cdkDragDisabled]="selectedControl !== null">
            <mat-card-content class="drag-drop-box" style="border-style: none !important; cursor: pointer;" [formGroupName]="i">
              <div class="flex flex-row" (click)="selectDocument(control)" attr.id="doc-{{i}}">
                <div class="drag-drop-custom-placeholder" *cdkDragPlaceholder></div>
                <span class="ml-1 cdk-drag-placeholder">
                  <i aria-hidden="true" class="fa fa-grip-horizontal mr-2 pt-1 " cdkDragHandle></i>
                </span>
                <div>{{ getDocumentLabel(control) }}</div>
                <div class="flex-1"></div>
                <div style="justify-content: flex-end" *ngIf="selectedControl === null">
                  <i aria-hidden="true" class="fa fa-trash" (click)="delete(i)"></i>
                </div>
              </div>
              <div class="flex flex-column" style="width: 100%;" *ngIf="selectedControl === control">
                <div class="flex flex-row">
                  <mat-form-field class="w-100">
                    <mat-label>Documents</mat-label>
                    <mat-select formControlName="documentId">
                      <mat-option *ngFor="let document of documents" [value]="document.id">{{document.name}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div style="width: 100%" class="flex flex-row">
                  <mat-form-field style="width: 100%" appearance="fill" class="textarea-formfield">
                    <mat-label>XML</mat-label>
                    <textarea matInput formControlName="markup" #myTextArea
                    (click)="getCaretPos(myTextArea)" (keyup)="getCaretPos(myTextArea)"
                    rows="10" cols="25"
                    placeholder="Add the xml for the embedded documents along with any replacement variables needed..."></textarea>
                  </mat-form-field>
                  <button mat-icon-button [matMenuTriggerFor]="variables" id="variablesButton">
                    <i aria-hidden="true" class="fas fa-info-circle" matTooltip="Replacement variables"></i>
                  </button>
                  <mat-menu #variables="matMenu">
                    <button mat-menu-item (click)="addText('Base64Data', control)">Form Data</button>
                    <button mat-menu-item (click)="addText('FileName', control)">File Name</button>
                  </mat-menu>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </form>
    </div>
  </div>
  <mat-dialog-actions align="end">
    <button type="button" mat-button mat-dialog-close class="mr-1 material-default">
      CANCEL
    </button>
    <button type="button" (click)="save()" mat-raised-button color="primary" class="material-default">
      CLOSE
    </button>
  </mat-dialog-actions>
</div>
