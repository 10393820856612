import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CaseSignatureService } from "@Services";

export interface EmbeddedSignatureDialogOptionsv2 {
    caseId: string,
    envelopeId: string,
    signer: any
}

@Component({
    selector: 'embedded-signature-dialog-v2',
    templateUrl: 'embedded-signature-dialog-v2.component.html',
    styleUrls: ['./embedded-signature-dialog-v2.component.scss']
})
export class EmbeddedSignatureDialogV2Component implements OnDestroy, OnInit {
    url: SafeResourceUrl;
    browserMessageHandler: any = this.handleBrowserMessage.bind(this);
    docusignFrameLoadHandler: any = this.handleDocusignFrameLoad.bind(this);
    loadingDocuments = false;
    docusignFrame: HTMLElement;

    constructor(
        public dialogRef: MatDialogRef<EmbeddedSignatureDialogV2Component>,
        private sanitizer: DomSanitizer,
        @Inject(MAT_DIALOG_DATA) public data: EmbeddedSignatureDialogOptionsv2,
        private caseSignatureService: CaseSignatureService) {
    }

    ngOnInit() {
        this.docusignFrame = document.getElementById('docusignFrame');
        this.docusignFrame.addEventListener('load', this.docusignFrameLoadHandler);

        this.loadingDocuments = true;
        this.caseSignatureService.getSignatureEnvelopeEmbeddedView(this.data.caseId, this.data.envelopeId, this.data.signer)
            .then((embeddedSignatureView) => {
                this.setTrustedUrl(embeddedSignatureView.url);
                window.addEventListener('message', this.browserMessageHandler);
            });
    }

    ngOnDestroy() {
        window.removeEventListener('message', this.browserMessageHandler);
        this.docusignFrame.removeEventListener('load', this.docusignFrameLoadHandler);
    }

    handleDocusignFrameLoad(){
        
        //setTimeout(() => this.docusignFrame.style.display = 'inline', 0);
    }

    setTrustedUrl(url: string) {        
        this.loadingDocuments = false;
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        this.docusignFrame.style.display = 'inline';
    }

    handleBrowserMessage(event: any) {
        if (event == null || event.data == null) {
            return;
        }

        try {
            const messageData = JSON.parse(event.data);
            if (messageData.messageType !== 'docuSignCallback') {
                return;
            }

            const returnData = {event: messageData.event, canceled: messageData.event === 'cancel'};
            this.dialogRef.close(returnData);
        } catch (error) {
            // non-json payload, someone else's message
        }
    }

    onOk(): void {
        this.dialogRef.close(true);
    }

    onCancel(): void {
        this.dialogRef.close(false);
    }

    accept(): void {
        this.dialogRef.close(true);
    }

    reject(): void {
        this.dialogRef.close(false);
    }
}
