import { Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
    selector: 'app-snackbar',
    templateUrl: 'view-queue-message.component.html',
    styleUrls: ['view-queue-message.component.scss'],
})
export class ViewQueueMessageComponent {
    constructor(
    public snackBarRef: MatSnackBarRef<ViewQueueMessageComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any
    ) { }

}
