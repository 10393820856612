import { Component, Input, OnInit } from '@angular/core';
import { CaseDetailsDTO, NotesDTO, NoteTypeDTO } from '@DTOs';
import { NoteType, FeatureToggle } from '@Enums';
import { KeyValueObj, NoteTypeReason, UWResponse } from '@Models';
import { TimeUtils } from '@Utils';
import { AppService } from 'app';
import { FeatureManagerService } from '@Services';
import * as _ from 'lodash';

@Component({
    selector: 'notes-list',
    templateUrl: './notes-list.component.html',
    styleUrls: ['./notes-list.component.scss']
})
export class NotesListComponent implements OnInit {
    @Input() case: CaseDetailsDTO;

    notes: NotesDTO[] = [];
    filteredNotes: NotesDTO[] = [];

    expandAllNotes = false;

    noteTypes: NoteTypeDTO[] = [];
    activeNoteTypes: NoteTypeDTO[] = [];
    selectedNoteTypes: NoteTypeDTO[] = [];
    noteTypeReasons: NoteTypeReason[] = [];
    noteRelations: KeyValueObj[] = [];
    noteFollowupInDays: KeyValueObj[] = [];
    isClientUser: boolean;

    constructor(private appService: AppService, private featureManagerService: FeatureManagerService) { }

    //#region Helpers

    getNoteTypeReasons() {
        if (!this.case?.id) return;

        this.appService.getData<UWResponse<NoteTypeReason[]>>(`Notes/${this.case.id}/reasons`)
            .subscribe(response => this.noteTypeReasons = response.data);
    }

    //#endregion
    //#region Lifecycle

    async ngOnInit() {
        this.featureManagerService.isEnabled(FeatureToggle.ConfigAccountSettingsUWWorkBench, this.case.clientId).subscribe( async isWorkbenchFeatureFlagEnabled => {
            this.featureManagerService.isEnabled(FeatureToggle.UWWorkbenchHideUWNotesInCaseNotesClient, this.case.clientId).subscribe( async clientWantsUWNotesHidden => {
                this.getNotes();
                this.getNoteTypeReasons();
                this.noteTypes = (await this.appService.getAllConfigData())?.noteTypes || [];
                if (!isWorkbenchFeatureFlagEnabled || clientWantsUWNotesHidden) {
                    this.noteTypes = this.noteTypes.filter(x => x.id != NoteType.Underwriting);
                }
                this.activeNoteTypes = _.filter(this.noteTypes, x => x.isActive);
                this.selectedNoteTypes = _.filter(this.activeNoteTypes, x => (x.id !== NoteType.SystemLog) && (x.id !== NoteType.Error));
                this.noteRelations = [
                    { key: 'Case', value: 'Case' }
                ];
                this.noteFollowupInDays = TimeUtils.getNextNDates();
                this.isClientUser = JSON.parse(localStorage.getItem('data')).userRoleName === 'Client';
            });
        });
    }

    //#endregion
    //#region Handlers

    checkACL(permissionType: string, redirect?: string) {
        return this.appService.checkACL('Case', permissionType, redirect);
    }

    addNote() {
        const newNotes = this.filteredNotes.filter(note => !note.id);

        if (!newNotes.length) {
            const newNote: NotesDTO = {
                caseDetailsId: this.case.id,
                relatedTo: '',
                noteTypeId: '',
                noteContent: '',
                isClientViewable: false,
                userName: '',
                noteType: null,
            };

            this.filteredNotes = [newNote, ...this.filteredNotes];
        } else {
            this.appService.showMsg('warning', 'Please save new note, then add another ...');
        }
    }

    filterNotes() {
        const selectedNoteTypeIds = this.selectedNoteTypes.map(x => x.id);
        const selectedNotes = _.filter(this.notes, x => {
            return selectedNoteTypeIds.includes(x.noteTypeId);
        });
    
        this.filteredNotes = this.isClientUser ? selectedNotes.filter(x => x.isClientViewable) : selectedNotes;
    }

    getNotes() {
        if (!this.case?.id) return;

        this.appService.getData<UWResponse<NotesDTO[]>>(`Notes/${this.case.id}`).subscribe(response => {
            if (response.status === 'success') {
                this.notes = response.data;
                this.filterNotes();
            }
        });
    }

    //#endregion
}
