<div class="ac-container" [ngClass]="{'error': showRequiredError, 'multiple': multiple}" [title]="getSelectedTitle()">
    <label *ngIf="showLabel || value" class="ac-label">{{placeholder}}</label>
    <form novalidate [formGroup]="autoSuggestForm">
        <p-autoComplete formControlName="modalValue" [suggestions]="filteredList" (completeMethod)="filterList($event)"
            [forceSelection]="true" [placeholder]="showLabel || value ? '' : placeholder" [field]="field"
            [dataKey]="dataKey" styleClass="ac-component" inputStyleClass="ac-input" (onFocus)="showLabel = true"
            delay="10" (onSelect)="onSelect($event)" (onUnselect)="onUnSelect($event)" (onBlur)="onBlur($event)"
            (onDropdownClick)="onDropdownClick($event)" (onClear)="onClear()" [dropdown]="true" appendTo="body"
            [multiple]="multiple" [disabled]="disabled" (onKeyUp)="onKeyUp($event)" (onFocus)="focusin.emit()"
            (ngModelChange)="valueChanges.emit(value)" [attr.data-test-input-type]="inputTypeDropdown">
            <ng-template let-item pTemplate="item">
                <span [attr.data-test-value]="item[dataKey]">{{item[field]}}</span>
            </ng-template>
        </p-autoComplete>
    </form>
    <div class="small text-danger w-50 d-inline-block" *ngIf="showRequiredError">required</div>
    <div class="small w-50 d-inline-block" *ngIf="!showRequiredError && multiple">Items Selected:
        <b>{{value.length}}</b>
    </div>
    <div class="small w-40 d-inline-block float-right text-right" *ngIf="allowNewValues == 'true'">Press semicolon key
        to add new value. <em>Ex: value;</em>.<br />Comma (,) is not allowed</div>
</div>
<!-- {{value | json}} -->