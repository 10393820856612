import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { MultiSelectModel } from './models/MultiSelectModel.interface';

@Component({
    selector: 'app-multi-select-dropdown-list',
    templateUrl: './multi-select-dropdown-list.component.html'
})
export class MultiSelectDropdownListComponent implements OnChanges {
  // Simple Inputs
  @Input() listTitle = '';
  @Input() appearance: string;
  @Input() showSearchIcon: boolean;
  @Input() completeList: MultiSelectModel[];
  _selectedItemsList: string[] = [];
  @Input() set selectedItemsList(val) {
      this._selectedItemsList = val;
  }

  get selectedItemsList() {
      return this._selectedItemsList;
  }

  // Outputs
  @Output() selectionChanged = new EventEmitter();

  // View Children
  @ViewChild('auto') autocomplete: MatAutocomplete;
  @ViewChild('virtualScrollViewport') virtualScrollViewport: CdkVirtualScrollViewport;

  // Component Variables
  filteredItems: Observable<MultiSelectModel[]>;
  inputControl: UntypedFormControl = new UntypedFormControl();

  private _completeList = [];
  subs = new SubSink();
  isLoading = true;
  selectedItems: MultiSelectModel[] = [];
  selectAllItem: MultiSelectModel = {
      id: '00000000-0000-0000-0000-0000000000000', // 1 extra char to avoid collision
      name: 'All',
      isSelected: false
  };

  ngOnChanges(changes: SimpleChanges) {
      // Input list has been loaded with data from parent
      let selectedItemsUpdated = false;
      if (changes.completeList?.currentValue) {
          const newList = changes.completeList.currentValue;
          this._completeList = newList?.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
          this._completeList.forEach(listItem => listItem.isSelected = false);
          this.isLoading = false;
          this.filteredItems = this.inputControl.valueChanges
              .pipe(
                  startWith(''),
                  map(value => {
                      if (typeof (value) === 'string') {
                          return this._filter(value);
                      }
                  })
              );
          if (this.selectedItemsList) {
              const newlySelectedItems = this.completeList.filter(item => this.selectedItemsList.includes(item.id));
              newlySelectedItems.forEach(item => this.onSelect(new Event('filler'), item));
              this.applyInputText();
              selectedItemsUpdated = true;
          }
      }

      if (changes.selectedItemsList && changes.selectedItemsList.currentValue && this.completeList && !selectedItemsUpdated) {
          const newlySelectedItems = this.completeList.filter(item => changes.selectedItemsList.currentValue.includes(item.id));
          newlySelectedItems.forEach(item => this.onSelect(new Event('filler'), item));
          this.applyInputText();
      }
  }

  onSelectAll(event: Event) {
      event.stopImmediatePropagation();
      this.selectAllItem.isSelected = !this.selectAllItem.isSelected;
      this.setSelectAll();
      this.emitSelectionChanged();
  }

  emitSelectionChanged() {
      this.selectionChanged.emit(this.selectedItems.map(item => item.id));
  }

  setSelectAll() {
      if (this.selectAllItem.isSelected) {
          this.completeList.forEach(listItem => {
              listItem.isSelected = true;
              this.selectedItems.push(listItem);
          });
      } else {
          this.completeList.forEach(listItem => {
              listItem.isSelected = false;
          });
          this.selectedItems = [];
      }
  }

  clearSelections() {
      this.completeList.forEach(listItem => {
          listItem.isSelected = false;
      });
      this.selectedItems = [];
      this.applyInputText();
  }

  applyInputText() {
      let outputText = '';
      if (this.selectedItems?.length > 0) {
          if (this.selectedItems.length === 1) {
              outputText = this.selectedItems[0].name;
          } else {
              outputText = `Multiple (${this.selectedItems.filter(item => item.id !== this.selectAllItem.id)?.length})`;
          }
      }
      this.inputControl.setValue(outputText);
  }

  onSelect(event: Event, selectedItem: MultiSelectModel) {
      event.stopImmediatePropagation();
      selectedItem.isSelected = !selectedItem.isSelected;

      // Check if we selected "All"
      if (selectedItem.id === this.selectAllItem.id) {
          this.setSelectAll();
      } else {
      // Otherwise update the selected item
          if (selectedItem.isSelected) {
              // Add to selecteItems collection
              this.selectedItems.push(selectedItem);
              // Update complete list for UI
              this.completeList.filter(item => item.id === selectedItem.id)[0].isSelected = true;
          } else {
              // Filter all items that aren't the selected item
              this.selectedItems = this.selectedItems.filter(item => item.id !== selectedItem.id);
              // Update complete list for UI
              this.completeList.filter(item => item.id === selectedItem.id)[0].isSelected = false;
          }
      }

      // If the only item left unchecked is the "All" option, then select it
      const unselectedOptions = this.completeList.filter(option => !option.isSelected);
      if (unselectedOptions?.length === 0) {
          this.selectAllItem.isSelected = true;
      } else if (this.selectAllItem.isSelected) {
          this.selectAllItem.isSelected = false;
      }

      // Let Listeners know something changed
      this.emitSelectionChanged();
  }

  updateAutoComplete() {
      this.inputControl.setValue('');
      // Necessary in order for the virtual viewport to scroll to the top
      // and have the list items rendered in the DOM
      this.virtualScrollViewport.scrollToIndex(1);
      this.virtualScrollViewport.scrollToIndex(0);
  }

  private _filter(value: string): MultiSelectModel[] {
      const filterValue = value?.toLowerCase();

      if (!value || value === '' || filterValue === 'all') {
          return this._completeList;
      }

      return this._completeList?.filter(item => item.name.toLowerCase().includes(filterValue));
  }

}
