import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MapKeyType } from 'app/enums';
import { AppService } from '../../../../../app.service';
import { AccountEvent, AccountMessage } from '../../../../../models';

@Component({
    selector: 'app-event-action-arcus-transmission',
    templateUrl: './event-action-arcus-transmission.component.html',
    styleUrls: ['./event-action-arcus-transmission.component.scss']
})
export class EventActionArcusTransmissionComponent implements OnInit {

    public webhookForm: UntypedFormGroup;
    formSubmitted = false;
    event: AccountEvent;
    template: any;
    messageId: any;
    secretKeys: any;
    mapkeys: any;
    mapKeyTypeEnum: any = MapKeyType;

    constructor(private _fb: UntypedFormBuilder,
              public appService: AppService,
              public dialogRef: MatDialogRef<EventActionArcusTransmissionComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit() {
        this.event = this.data.event;
        this.mapkeys = this.data.mapkeys;
        this.webhookForm = this._fb.group({
            contentUrl: ['', Validators.required],
            attachmentsUrl: ['', Validators.required],
            oAuth2Request: this._fb.group(
                {
                    grantType: [''],
                    audience: [''],
                    tokenEndpoint: [''],
                    clientId: [''],
                    clientSecret: [''],
                }),
            regenerateDocuments: ['false'],
            statusMapkey: [''],
            applicationContent: ['', Validators.required],
            applicationDocumentsContent: ['', Validators.required]
        });
        this.setupForm();
        this.getSecretKeys();

    }

    setupForm() {
        if (this.event.accountMessage && this.event.accountMessage.template) {
            this.template = JSON.parse(this.event.accountMessage.template);

            this.webhookForm.patchValue({
                contentUrl: this.template?.contentUrl,
                attachmentsUrl: this.template?.attachmentsUrl,
                oAuth2Request: {
                    grantType: this.template.oAuth2Request?.grantType,
                    audience: this.template.oAuth2Request?.audience,
                    tokenEndpoint: this.template.oAuth2Request?.tokenEndpoint,
                    clientId: this.template.oAuth2Request?.clientId,
                    clientSecret: this.template.oAuth2Request?.clientSecret,
                },
                regenerateDocuments: this.template?.regenerateDocuments,
                statusMapkey: this.template?.statusMapkey,
                applicationContent: this.template?.applicationContent,
                applicationDocumentsContent: this.template?.applicationDocumentsContent
            });
        }
    }

    saveAccountMessage() {
        const message = this.webhookForm.value;
        this.formSubmitted = true;

        this.webhookForm.markAllAsTouched();
        if (this.webhookForm.valid) {
            if (this.event.accountMessage) {
                this.event.accountMessage.template = JSON.stringify(message);
            } else {
                const accountMessage: AccountMessage = {
                    id: this.messageId,
                    template: JSON.stringify(message)
                };
                this.event.accountMessage = accountMessage;
            }

            this.dialogRef.close();
        } else {
            this.webhookForm.markAsTouched();
            this.webhookForm.controls.content.markAsTouched();
        }
    }

    getSecretKeys() {
        this.appService.getData(`ConfigData/GetSecretKeys`).subscribe(result => {
            if (result.status === 'success') {
                this.secretKeys = result.data.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0));
            } else {
                this.secretKeys = [];
            }
        });
    }
}
