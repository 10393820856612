﻿<div class="case-manager-container" *ngIf="queueDataLoaded">
    <!-- Page Header -->
    <div class="case-manager-header edit">
        <h1 class="main-page-heading">Configure Case Manager</h1>

        <div style="margin-top: 20px">
            <mrs-btn-tertiary iconName="add" (onClick)="addQueue()" buttonLabel="Add Queue" textColor="#2C6EED" buttonId="addQueue"></mrs-btn-tertiary>
        </div>

        <div style="margin-left: auto; margin-top: 20px" class="flex flex-column">
            <div class="flex flex-row">
                <mrs-btn-tertiary buttonLabel="Cancel" buttonId="cancel" (onClick)="closeEditQueues()"></mrs-btn-tertiary>
                <mrs-btn-primary buttonLabel="Save Changes" buttonId="save" (onClick)="saveQueue()" [disabled]="queues.controls.length < 1"></mrs-btn-primary>
            </div>
        </div>
    </div>

    <!-- Number Modified -->
    <div class="modified-queue-row">
        <div *ngIf="dirtyQueues.size > 0" class="warning-text">
            {{dirtyQueues.size}} queue(s) modified
        </div>
    </div>

    <!-- Queue Tabs and Content  -->
    <div class="case-manager-inner-container">
        <!-- Queue Tabs -->
        <div class="flex flex-row">
            <section class="tabs-container">
                <span class="change-btn prev" (click)="scrollTab(-1)" [class.hide]="atStart">
                    <mat-icon>arrow_back_ios</mat-icon>
                </span>
                <ul class="tabs" [ngStyle]="{'transform': abc }" cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)">
                    <ng-container *ngFor="let queue of queues.controls; let i = index">
                        <li [id]="'list-'+i"
                            class="mat-tab-label"
                            [ngClass]="{ 'mat-tab-label-active': selectedIndex === queue.get('internalId').value, 'error': queue.invalid }"
                            (click)="setSelectedQueue(queue.get('internalId').value)"
                            cdkDrag>
                            <div class="drag-drop-box">
                                <div class="drag-drop-tab-placeholder" *cdkDragPlaceholder></div>
                                <div class="flex flex-row">
                                    <button mat-icon-button cdkDragHandle style="cursor: move; display: flex">
                                        <mat-icon cdkDragHandle class="btn-drag">drag_indicator</mat-icon>
                                    </button>
                                    <div *ngIf="queue.valid" style="cursor: pointer; display: flex; align-items: center;">
                                        <mat-icon class="warning-icon-tab mr-2" *ngIf="dirtyQueues.has(queue.get('internalId').value)">circle</mat-icon>
                                        <div>{{queue.get('name').value}}</div>
                                    </div>
                                    <div *ngIf="!queue.valid" style="cursor: pointer; display: flex; align-items: center;">
                                        <mat-icon class="small-error-icon mr-2">error_outline</mat-icon>
                                        <div class="error-text-tab">{{queue.get('name').value}}</div>
                                    </div>
                                    <div *cdkDragPreview class="mat-tab-label mat-tab-label-content">{{queue.get('name').value}}</div>
                                </div>
                            </div>
                        </li>
                    </ng-container>
                </ul>
                <span class="change-btn next" [class.hide]="atEnd" (click)="scrollTab(1)">
                    <mat-icon>arrow_forward_ios</mat-icon>
                </span>
            </section>
        </div>

        <!-- Queue Tab Content -->
        <div class="edit-content">
            <form [formGroup]="selectedQueueForm">
                <!-- Content Header (+last modified date) -->
                <div class="flex flex-row flex-fill">
                    <h2>Settings</h2>
                    <div class="small-text" style="margin-left: auto"
                        *ngIf="selectedQueueForm.get('id').value !== '' && selectedQueueForm.get('id').value !== null">
                        Last updated by: {{selectedQueueForm?.get('lastModifiedByUser').value}} on
                        {{selectedQueueForm?.get('lastModified').value}}
                    </div>
                </div>

                <!-- Queue Name -->
                <div class="flex flex-row">
                    <mat-form-field style="width:200px">
                        <mat-label>Name</mat-label>
                        <input matInput class="queue-name-input" appMaxLength="20" formControlName="name">
                        <mat-hint *ngIf="selectedQueueForm?.get('name').valid">{{(20 - selectedQueueForm?.get('name').value.length) < 0 ? 0 : 20 - selectedQueueForm?.get('name').value.length}}/20 Characters Remaining </mat-hint>
                                <mat-error *ngIf="selectedQueueForm?.get('name').hasError('required')">Name is required</mat-error>
                                <mat-error *ngIf="selectedQueueForm?.get('name').hasError('unique')">Each queue name needs to be unique</mat-error>
                    </mat-form-field>
                </div>

                <!-- Viewable By (Role Access) -->
                <div class="component-wrapper" *ngIf="roles && roles.length > 0">
                    <h2>Viewable By</h2>
                    <mrs-chip-list [chips]="roles"
                        [multiple]="true"
                        [selectable]="true"
                        (onChipSelectionChange)="onChipSelectionChange($event)"></mrs-chip-list>
                </div>

                <!-- Rules -->
                <div class="component-wrapper">
                    <queue-rules
                        *ngIf="accountRules"
                        [clientId]="selectedClients[0].id"
                        [selectedQueueId]="selectedIndex"
                        [rules]="accountRules"
                        [accounts]="accounts"></queue-rules>
                </div>

                <!-- Columns -->
                <div class="component-wrapper" *ngIf="configureColumnsEnabled">
                    <edit-cnbw-columns
                        [managementQueue]="selectedQueue"
                        [columnMapKeys]="selectedQueue.managementQueueColumnMapKeys"
                        [accounts]="accounts"
                        (onChange)="handleColumnMapkeyChange($event)"></edit-cnbw-columns>
                </div>

                <!-- Additional Options (Delete Queue) -->
                <div class="component-wrapper">
                    <h2>Additional Options</h2>
                    <div class="flex flex-column">
                        <span class="sub-heading">Delete Queue</span>
                        <span>You will lose your configuration for this queue.</span>
                        <span>This action cannot be undone.</span>
                        <mrs-btn-icon class="float-right" buttonLabel="Delete this queue" buttonId="deleteButton" (onClick)="deleteQueue()" iconName="delete"></mrs-btn-icon>
                    </div>
                </div>
            </form>
        </div>

        <!-- No Queues Message -->
        <div class="flex flex-column edit-content" style="align-items: center;" *ngIf="queueDataLoaded && queues.controls.length === 0">
            <div class="mt-5">
                <mat-icon class="large-warning-icon">error_outline</mat-icon>
            </div>
            <h2 class="mt-4">You currently have no queues.</h2>
            <div class="mt-2">
                <button mat-stroked-button color="primary" type="button" (click)="addQueue()">
                    <mat-icon>add</mat-icon>Add Queue
                </button>
            </div>
        </div>
    </div>
</div>