import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppService } from '../../../../../app.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AccountEvent, AccountMessage } from '../../../../../models';
import { QuillEditorComponent } from 'ngx-quill';
import * as _ from 'lodash';

@Component({
    selector: 'account-event-action',
    templateUrl: './event-action-integration.component.html'
})
export class EventIntegrationActionComponent implements OnInit {

    public messageForm: UntypedFormGroup;
    template: any;
    messageId: any;
    formSubmitted: boolean = false;
    accountIntegrations: any = [];
    event: AccountEvent;

    eventIntegrations: Array<string> =
        ['00000000-0000-0000-0000-000000000030'];

    @ViewChild('content', {
        static: true
    }) content: QuillEditorComponent;

    constructor(
        private appService: AppService,
        private _fb: UntypedFormBuilder,
        public dialogRef: MatDialogRef<EventIntegrationActionComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit() {
        this.messageForm = this._fb.group({
            integrationId: ['', Validators.required]
        });
        
        this.event = this.data.event;
        
        this.getAccountIntegrations();
    }

    saveAccountMessage() {
        const message = this.messageForm.value;
        this.formSubmitted = true;

        if (this.messageForm.valid) {
            if (this.event.accountMessage) {
                this.event.accountMessage.template = JSON.stringify(message);
            } else {
                const accountMessage: AccountMessage = {
                    id: this.messageId,
                    template: JSON.stringify(message)
                };
                this.event.accountMessage = accountMessage;
            }

            this.dialogRef.close();
        } else {
            this.messageForm.markAsTouched();
            this.messageForm.controls.content.markAsTouched();
        }
    }

    patchValues(){
        if (this.event.accountMessage) {
            this.template = JSON.parse(this.event.accountMessage.template);
            this.messageForm.patchValue({
                integrationId: this.template['integrationId']
            });
        }
    }

    getAccountIntegrations() {
        this.appService.getData(`Accounts/${this.data.accountInfo.id}/Integrations`).subscribe(
            result => {
                if (result.status === 'success') {
                    const integrations = result.data;

                    var integrationed = _.filter(integrations, function(e) {
                        return e.accountIntegrations && e.accountIntegrations != null;
                    });

                    this.accountIntegrations = integrationed.filter(f => this.eventIntegrations.includes(f.id));
                    this.patchValues();
                }
            }
        );
    }
}
