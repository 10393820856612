<div (cdkDropListDropped)="dropSection($event)" *ngIf="sections?.length; else noSectionsPlaceholder;" cdkDropList
    cdkDropListAutoScrollDisabled="false" cdkDropListAutoScrollStep="3" class="drag-drop-list"
    [cdkDropListDisabled]="!!filterValue">
    <div (cdkDragEnded)="isDragging = false;" (cdkDragStarted)="isDragging = true;"
        *ngFor="let section of filteredSections; let i = index" cdkDrag cdkDragPreviewContainer="parent">
        <div *cdkDragPreview class="node-container" id="drag-and-drop-preview-template" style="width:400px">
            <div class="node" style="margin-left: 1rem">
                <span class="node-name">{{section.name}}</span>
            </div>
        </div>

        <div *cdkDragPlaceholder style="padding:4px">
            <div class="drag-drop-custom-placeholder"></div>
        </div>

        <div #nameToolTip='matTooltip' (click)="selectSection(section)" [matTooltipDisabled]="isDragging"
            [matTooltip]="section.name" [id]="section.id"
            [ngClass]="{'active-selected': selectedSection?.id === section.id && !selectedQuestion, 'active-non-selected': selectedQuestion?.sectionsId === section.id, 'dragging':isDragging }"
            class="node-container" matTooltipPosition="right">
            <div class="d-flex flex-row align-items-center">
                <mat-icon *ngIf="checkACL('W') && !filterValue" class="actions drag-handle">drag_indicator</mat-icon>
            </div>
            <div class="node" style="margin-left: 1rem">
                <button (click)="$event.stopPropagation(); section.expanded = !section.expanded"
                    [disabled]="!section.questions?.length" mat-icon-button style="margin-right:4px;">
                    <mat-icon [ngStyle]="{'display':section.questions?.length?'':'none'}">
                        {{ section.expanded ? 'expand_less' : 'expand_more'}}</mat-icon>
                </button>
                <div class="d-flex flex-row flex-grow-1 align-items-center justify-content-between">
                    <div class="node-name">{{section.name}}</div>
                    <div (mouseenter)="nameToolTip.hide()" (mouseleave)="nameToolTip.show()" *ngIf="!isDragging"
                        class="pr-1 actions">
                        <button (click)="$event.stopPropagation(); exportSection(section)" *ngIf="checkACL('W')"
                            mat-icon-button matTooltip="Export Section">
                            <mat-icon>get_app</mat-icon>
                        </button>
                        <button (click)="$event.stopPropagation(); deleteSection(section.id)" *ngIf="checkACL('F')"
                            mat-icon-button matTooltip="Delete Section">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div [@showHide] [hidden]="section.questions.length == 0 || !section.expanded" [id]="getSectionId(section)">
            <interview-navigation-question [isReflexiveChildren]="false" [nestedLevel]=1 [parentQuestions]="[]"
                [disableDragAndDrop]="!!filterValue" [questions]="section.questions" [section]="section"
                [sections]="sections" [selectedQuestion]="selectedQuestion"
                [selectedReflexiveChoice]="selectedReflexiveChoice" [selectedSection]="selectedSection"
                [filterReflexiveQuestionsToQuesionIds]="filterReflexiveQuestionsToQuesionIds">
            </interview-navigation-question>
        </div>
    </div>
</div>


<ng-template #noSectionsPlaceholder>
    <div class="p-4 text-center text-muted">
        <img alt="" class="pb-3" src="./assets/images/no-data-placeholder.png" />
        <h6>No Sections Found</h6>
    </div>
</ng-template>