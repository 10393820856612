<!-- Form Results -->
<div class="case-results">
    <div *ngIf="auditCaseItems.length == 0" class="no-cases">{{ noCasesMessage }}</div>

    <div *ngIf="auditCaseItems.length > 0">
        <table mat-table [dataSource]="auditCaseItems" class="table" [ngClass]="{ 'sticky-headers': stickyHeaders }">
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row
                class="table-row"
                *matRowDef="let row; let i = index; columns: displayedColumns;"
                [ngClass]="{ 'highlight': (i) % interval === 0 }"></tr>

            <ng-container matColumnDef="rowNumber">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let item; index as i;">{{ i + 1 }}</td>
            </ng-container>

            <ng-container matColumnDef="caseNumberDisplay">
                <th mat-header-cell *matHeaderCellDef>Case</th>
                <td mat-cell *matCellDef="let item">
                    <a class="case-number-display-link" (click)="onOpenCase(item)">
                        {{ item?.caseNumberDisplay }}
                        <mat-icon class="link-icon" aria-hidden="false" aria-label="open icon" fontIcon="open_in_new"></mat-icon>
                    </a>
                </td>
            </ng-container>

            <ng-container matColumnDef="auditStatus">
                <th mat-header-cell *matHeaderCellDef>Audit Status</th>
                <td mat-cell *matCellDef="let item">{{ item.auditStatus }}</td>
            </ng-container>

            <ng-container matColumnDef="caseCreatedDate">
                <th mat-header-cell *matHeaderCellDef>Created On</th>
                <td mat-cell *matCellDef="let item">
                    <span [matTooltip]="item.caseCreatedTimeFormatted">{{ item.caseCreatedDayFormatted }}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="uwDecisionLabel">
                <th mat-header-cell *matHeaderCellDef>Decision</th>
                <td mat-cell *matCellDef="let item">{{ item.uwDecisionLabel }}</td>
            </ng-container>

            <ng-container matColumnDef="assigneeNames">
                <th mat-header-cell *matHeaderCellDef>Assignee(s)</th>
                <td mat-cell *matCellDef="let item">{{ item.assigneeNames }}</td>
            </ng-container>

            <ng-container matColumnDef="caseCompletedDate">
                <th mat-header-cell *matHeaderCellDef>Case Completed</th>
                <td mat-cell *matCellDef="let item">
                    <span [matTooltip]="item.caseCompletedDateTimeFormatted">{{ item.caseCompletedDateDayFormatted }}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="auditSelected">
                <th mat-header-cell *matHeaderCellDef>Audit Selected</th>
                <td mat-cell *matCellDef="let item">
                    <span [matTooltip]="item.selectedOnTimeFormatted">{{ item.selectedOnDayFormatted }}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="auditCompleted">
                <th mat-header-cell *matHeaderCellDef>Audit Completed</th>
                <td mat-cell *matCellDef="let item">
                    <span [matTooltip]="item.completedOnTimeFormatted">{{ item.completedOnDayFormatted }}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let item">
                    <mrs-btn-tertiary *ngIf="item.isSelected && !item.isCompleted" buttonLabel="Complete" [textColor]="MrsColor.UIBlue" (click)="onCompleteCaseAudit(item)"></mrs-btn-tertiary>
                </td>
            </ng-container>
        </table>
    </div>
</div>