import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { AppService } from '@App';
import { SectionDTO } from '@DTOs';
import { UWResponse } from '@Models';
import { BaseService } from '@Services';
import { IConfigService } from 'app/config/iconfigservice';
import { first, ReplaySubject } from 'rxjs';
import { SubSink } from 'subsink';


@Injectable({
    providedIn: 'root'
})
export default class SectionDataService extends BaseService implements OnDestroy {
    forceReload = false;

    loadedWorkflowId = '';
    sectionsForWorkflowSub = new ReplaySubject<SectionDTO[]>(1);
    sectionsForWorkflow$ = this.sectionsForWorkflowSub.asObservable();

    subs = new SubSink();

    constructor(
        public configService: IConfigService,
        public httpClient: HttpClient,
        public appService: AppService
    ) {
        super(appService, configService, httpClient);
    }

    //#region Helpers

    private isLoaded = (workflowId: string) => workflowId == this.loadedWorkflowId;

    //#endregion
    //#region Lifecycle

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    //#endregion

    public forceOnNextLoad() {
        this.forceReload = true;
    }

    public reloadSectionsForWorkflow(workflowId: string, hideWaiting?: boolean) {
        this.forceReload = false;
        const url = new URL(`./Section/${workflowId}`, this.baseApiUrl);

        super.getData<UWResponse<SectionDTO[]>>(url.toString(), null, hideWaiting)
            .pipe(first())
            .subscribe(response => {
                this.sectionsForWorkflowSub.next(response.data);
                this.loadedWorkflowId = workflowId;
            });

        return this.sectionsForWorkflow$;
    }

    public loadSectionsForWorkflow(workflowId: string) {
        if (this.forceReload || !this.isLoaded(workflowId))
            return this.reloadSectionsForWorkflow(workflowId);

        return this.sectionsForWorkflow$;
    }
}
