<span class="auditpercentlabel"
    [matTooltip]="allocatedGoalFormatted"
    [matTooltipDisabled]="!(toolTipEnabled && isAllocatedGoalEnabled)">
<!--
It is sad that matTooltipDisabled is inverted.

This control has a way to enable the tool tip (toolTipEnabled).
Only show tool tips when the allocated goal was enabled

| toolTipEnabled | isAllocatedEnabled | Result               | !(toolTipEnabled && isAllocatedGoalEnabled)
|----------------|--------------------|----------------------|--------------------------------
|   Yes          |    Yes             | Show Tool tip        | false -> (show tool tip)   
|   Yes          |    No              | Don't show tool tip  | true  -> (Hide tool tip)
|   No           |    Yes             | Don't show tool tip  | true -> (Hide tool tip)
|   No           |    No              | Don't show tool tip. | true -> (Hide tool tip)

-->   
    <span [ngClass]="{                                    
        'goalDisable': allocatedGoalState === AuditGoalStates.Disabled,  
        'goalFail': allocatedGoalState === AuditGoalStates.Below, 
        'goalPass': allocatedGoalState === AuditGoalStates.Above}">
        {{ allocatedPercent | number: '1.0-1' }}% 
    </span>
    ({{ allocatedCases}}/{{ totalCases }} cases)
</span>