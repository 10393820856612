import { Pipe, PipeTransform } from '@angular/core';
import { Note } from '@Underwriting/models';

@Pipe({
    name: 'removeExcludedNotesFromMaster'
})
export default class ExcludeExcludedNotesFromMaster implements PipeTransform {
    transform(notes: Note[]): any[] {
        const filteredNotes = notes.filter(x => !x.excludeFromMasterList);
        return filteredNotes;
    }
}