<div class="question-container" [formGroup]="group" [ngClass]="{active: isSelected, interviewQuestion: interviewMode}">
  <div [ngClass]="isConfigInvalid()?'invalid-question':''">
    <div class="question-header" *ngIf="!interviewMode" (click)="selectQuestion()">
      <button type="button" class="btn btn-link float-left" (click)="expanded = !expanded">
        <i aria-hidden="true" class="fa" [ngClass]="{'fa-angle-right': !expanded, 'fa-angle-down': expanded}"></i>
      </button>
      <span class="question-mapkey">
        {{config.mapKeyName}}
      </span>
      <span class="question-mapkey float-right pr-2">
        {{config.answerTypeName}}
      </span>
    </div>
    <div class="question-body" *ngIf="expanded">
      <div class="question-text rendered-quill-content ql-editor" [innerHTML]="questionText"></div>
      <div class="help-text rendered-quill-content ql-editor" [innerHTML]="helpText"
        *ngIf="config.helpText?.length"></div>
      <div class="question-form">
        <!-- <mat-form-field class="w-100 mb-1">
                <input type="email" matInput [placeholder]="config.answerType" [formControlName]="config.id" (focus)="onFocus()" (blur)="onBlur()">
                <mat-error *ngIf="group.get(config.id)?.hasError('required')">required</mat-error>
                <mat-error *ngIf="group.get(config.id)?.hasError('email')">Enter valid email</mat-error>
            </mat-form-field> -->
        <mat-radio-group formControlName="{{config.id}}" data-test-input-type="radioButton">
          <mat-radio-button class="d-block mb-3" [value]="'Yes'" [disabled]="isReadOnly()"
            data-test-value="Yes">Yes</mat-radio-button>
          <mat-radio-button [disabled]="isReadOnly()" class="d-block mb-3" [value]="'No'"
            data-test-value="No">No</mat-radio-button>
        </mat-radio-group>
        <mat-error *ngIf="group.get(config.id)?.hasError('required') && (group.get(config.id)?.dirty || group.get(config.id)?.touched)"
          class="error-text">required</mat-error>
        <div class="question-text rendered-quill-content ql-editor" *ngIf="interviewMode && config?.mibDetails">According
          to MIB, someone with identifiers similar to yours has a history of...</div>
        <p-table *ngIf="config?.mibDetails" [value]="config?.mibDetails">
          <ng-template pTemplate="header">
            <tr>
              <th>Name</th>
              <th>Birth Place</th>
              <th>DOB</th>
              <th>Gender</th>
              <th>Occupation</th>
              <th>Relation</th>
              <th>Risk</th>
              <th>Description</th>
              <th>Date Reported</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-mibDetail>
            <tr>
              <td>{{mibDetail.personReported}}</td>
              <td>{{mibDetail.birthPlace}}</td>
              <td>{{mibDetail.dob}}</td>
              <td>{{mibDetail.gender}}</td>
              <td>{{mibDetail.occupation}}</td>
              <td>{{mibDetail.relationRoleCode}}</td>
              <td>{{mibDetail.riskType}}</td>
              <td>{{mibDetail.description}}</td>
              <td>{{mibDetail.dateReported}}</td>
            </tr>
          </ng-template>
        </p-table>
        <div class="question-text rendered-quill-content ql-editor mt-3" *ngIf="interviewMode && config?.mibDetails">Is
          the above information consistent with your history?</div>
      </div>
      <app-field-details [question]="config" [form]="group" [readOnlyMode]="isReadOnly()"></app-field-details>
      <dynamic-form *ngIf="group.get(config.id)?.value && group.get(config.id)?.value.toLowerCase() == 'no' && config.mibDetails && healthQuestions?.length>0"
        [expandAllQuestions]="true" [interviewMode]="'health'" [parentId]="config?.id" [constantLists]="constantLists"
        [parentIndex]="formIndex" [config]="healthQuestions"></dynamic-form>
    </div>
  </div>
</div>
