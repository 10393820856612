import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import * as _ from 'lodash';

import { AppService } from '../../app.service';
import { IConfigService } from '../../config/iconfigservice';
import { BaseService } from '../base.service';
import { ObservableService } from '../observable.service';

@Injectable()
export class CallManagerService extends BaseService {
    baseUrl: string;
    publicApiBaseUrl: string;

    constructor(
        public appService: AppService,
        public httpClient: HttpClient,
        public configService: IConfigService,
        public observableService: ObservableService
    ) {
        super(appService, configService, httpClient);

        this.baseUrl = this.appService.getAPIBaseURL();
        this.publicApiBaseUrl = this.configService.getConfiguration().publicApiUrl;
    }

    importContact(clientId: string, contactId: string) {
        const url = `${this.publicApiBaseUrl}client/${clientId}/calllog`;

        return super.postData<any>(url, contactId, clientId);
    }
}
