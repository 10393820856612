<h1 mat-dialog-title>Configure Display Message</h1>
  <div mat-dialog-content>
    <div class="flex-fill d-flex flex-column overflow-y-auto">
      <form [formGroup]="messageForm" novalidate autocomplete="off" >
        <map-keys-dropdown
                [displayList]="mapKeys" placeholder="Mapkey"
        formControlName="mapKeyId" [formControl]="messageForm.get('mapKeyId')"></map-keys-dropdown>
        <p-editor formControlName="content"></p-editor>
          <mat-error *ngIf="formSubmitted && messageForm.get('content').hasError('required')">
            Body
            <strong>required</strong>
          </mat-error>
      </form>
    </div>
  </div>
  <mat-dialog-actions align="end">
    <button id="closeButton" mat-button id="cancelButton" color="basic" class="mr-1 material-default" mat-dialog-close >
      CANCEL
    </button>
    <button id="okButton" mat-button color="primary" class="mr-1 material-default" (click)="saveAccountMessage()">
      OK
    </button>
  </mat-dialog-actions>
