import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { AppService } from '../../app.service';
import { IConfigService } from '../../config/iconfigservice';
import { AccountEvent } from '../../models';

@Injectable()
export class AccountEventsService {

    publicApiBaseUrl: string;

    constructor(private appService: AppService, private configService: IConfigService, private httpClient: HttpClient) {
        this.publicApiBaseUrl = this.configService.getConfiguration().publicApiUrl;
    }

    getAccountEvents(accountId: string) {
        this.appService.display(true);
        const clientId = this.appService.getClientId('');
        const url = `${this.publicApiBaseUrl}client/${clientId}/config/account/${accountId}/events`;

        return this.httpClient.get<AccountEvent[]>(url, {headers: this.appService.getHeaders(clientId)})
            .pipe(
                tap(_accountEvents => {
                    this.appService.display(false);
                }),
                catchError(error => {
                    this.appService.display(false);
                    this.appService.showResponseErrorMsg(error);
                    return throwError(() => new Error(error.message));
                })
            );
    }

    addAccountEvent(accountEvent: AccountEvent) {
        this.appService.display(true);
        const clientId = this.appService.getClientId('');
        const url = `${this.publicApiBaseUrl}client/${clientId}/config/account/${accountEvent.accountId}/events`;

        const response = this.httpClient.post(url, accountEvent, {
            headers: this.appService.getHeaders(clientId)
        }).pipe(
            map((res: Response) => {
                this.appService.display(false);
                if (res) {
                    return res;
                }
            }),
            catchError((error: any) => {
                this.appService.display(false);
                this.appService.showResponseErrorMsg(error);
                return throwError(() => new Error(error.message));
            }));

        return response;
    }

    editAccountEvent(accountEvent: AccountEvent) {
        this.appService.display(true);
        const clientId = this.appService.getClientId('');
        const url = `${this.publicApiBaseUrl}client/${clientId}/config/account/${accountEvent.accountId}/events`;

        const response = this.httpClient.put(url, accountEvent, {
            headers: this.appService.getHeaders(clientId)
        }).pipe(
            map((res: Response) => {
                this.appService.display(false);
                if (res) {
                    return res;
                }
            }),
            catchError((error: any) => {
                this.appService.display(false);
                this.appService.showResponseErrorMsg(error);
                return throwError(() => new Error(error.message));
            }));

        return response;
    }

    deleteAccountEvent(accountEvent: AccountEvent) {
        this.appService.display(true);
        const clientId = this.appService.getClientId('');
        const url = `${this.publicApiBaseUrl}client/${clientId}/config/account/${accountEvent.accountId}/events/${accountEvent.id}`;

        const response = this.httpClient.delete(url, {
            headers: this.appService.getHeaders(clientId)
        }).pipe(
            map((res: Response) => {
                this.appService.display(false);
                if (res) {
                    return res;
                }
            }),
            catchError((error: any) => {
                this.appService.display(false);
                this.appService.showResponseErrorMsg(error);
                return throwError(() => new Error(error.message));
            }));

        return response;
    }

    getAccountEventMessage(accountId: any, id: any) {
        this.appService.display(true);
        const clientId = this.appService.getClientId('');
        const url = `${this.publicApiBaseUrl}client/${clientId}/config/account/${accountId}/events/message/${id}`;

        return this.httpClient.get(url, {
            headers: this.appService.getHeaders(clientId)
        }).pipe(
            map((resData) => {
                this.appService.display(false);
                const res: any = resData;
                if (res) {
                    return res;
                }
            }),
            catchError((error: any) => {
                this.appService.display(false);
                this.appService.showResponseErrorMsg(error);
                return throwError(() => new Error(error.message));
            }));
    }
}
