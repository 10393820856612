<div class="section-header">
  {{title}}
</div>
<div [formGroup]="textEditorFormGroup">
  <angular-editor #angularEditor
      [formControl]="textEditorFormGroup.controls.htmlEditorControl"
      [config]="editorConfig">
      [maxLength]="maxLength"
  </angular-editor>
</div>

<div *ngIf="maxLength" class="container" style="display:flex; justify-content: flex-end;">
  <div [ngStyle]="{'color': characterCount > maxLength ? 'red' : 'inherit'}">{{characterCount}} / {{maxLength}}</div>
</div>

