// I'm not sure we really want clients defined in here, but I feel better
// about it than using a string.
enum Clients {
    AEG = 'fc2f83b8-821c-4ee4-a4d9-eb21578d37d3',
    EMC = '23d946e1-db98-4a3a-a9de-e625a0e8d2a9',
    RGA = 'dfe45ccd-f9c1-4779-92d7-6d17d8e92a0a',
    RNA = '7c5d4247-f346-4773-e11e-08d5de8f262b',
    AmFamPoC = '80BBD20B-5F16-4FA7-9F01-68F9CC558D8A'
}

export default Clients;
