import { Component, Input, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { AppService, MonitoringService } from '@App';
import { AccountSettingsDTO } from '@DTOs';
import { AccountSettings } from '@Enums';
import { Account, Client, UWResponse } from '@Models';
import { AccountSettingsDataService } from '@Services';
import { TimeUtils } from '@Utils';
import { catchError, map, of } from 'rxjs';
import { SubSink } from 'subsink';

export interface NoteAccountSettingWarning {
    title: string,
    message: string,
}

export interface NotesAccountSettingItem {
    accountSettingName: string;
    formControlName: string;
    accountSettingTitle: string;
    accountSettingDescription: string;
    loadError?: boolean;
    loadErrorMessage?: string;
    warning?: NoteAccountSettingWarning;
    initialValue?: boolean;
    currentValue?: boolean;
    isDirty?: boolean;
    accountSettingId?: string;
}


@Component({
    selector: 'workbench-notes-config',
    templateUrl: './workbench-notes-config.component.html',
    styleUrls: ['workbench-notes-config.component.scss'],
})
export default class WorkbenchNotesConfigComponent implements OnDestroy {
    @ViewChild('disableWarningDialog', { static: true }) disableWarningDialog: TemplateRef<any>;
    @Input() client: Client;
    @Input() account: Account;

    disableWarningDialogReference: MatDialogRef<any, any>;

    accountSettings: AccountSettingsDTO[] = [];
    accountSettingsLoaded = false;

    workbenchNotesForm: UntypedFormGroup;
    allowHiddenNotesFormControlName = 'allowHiddenNotes';
    notesAccountSettingItems: NotesAccountSettingItem[] = [];
    lastUpdated: Date;

    subs = new SubSink();

    constructor(
        private dialog: MatDialog,
        private appService: AppService,
        private _fb: UntypedFormBuilder,
        private monitoringService: MonitoringService,
        private accountSettingsDataService: AccountSettingsDataService,
    ) {
    }

    //#region Helpers

    setNoteAccountSettings() {
        const notesSettingDates: Date[] = [];

        this.notesAccountSettingItems.forEach(notesSetting => {
            const filteredAccountSettings = this.accountSettings.filter(x => x.name === notesSetting.accountSettingName);

            if (filteredAccountSettings.length == 1) {
                // account setting has been defined in db set the current values
                const isOn = filteredAccountSettings[0].value?.toLowerCase() === 'true' || false;
                notesSetting.currentValue = isOn;
                notesSetting.initialValue = isOn;
                notesSetting.isDirty = false;
                notesSetting.accountSettingId = this.accountSettings[0].id;
                this.workbenchNotesForm.patchValue({ [notesSetting.formControlName]: notesSetting.currentValue }, { onlySelf: true, emitEvent: false });

                notesSettingDates.push(TimeUtils.convertToDateCst(filteredAccountSettings[0].lastModifiedDate ?? filteredAccountSettings[0].creationDate));

                return;
            }

            if (filteredAccountSettings.length > 1) {
                const message = `The setting '${filteredAccountSettings[0].name}' has duplicate values set and cannot be resolved. Please contact support.`;
                this.monitoringService.logError(new Error(message));
                notesSetting.loadError = true;
                notesSetting.loadErrorMessage = message;
                return;
            }
        });

        if (notesSettingDates.length == 0)
            return;

        this.lastUpdated = notesSettingDates.sort((a, b) => b.getTime() - a.getTime())[0];
    }

    setNoteAccountSettingsItem(noteAccountSettingItem: NotesAccountSettingItem, formControlName: string) {
        noteAccountSettingItem.currentValue = this.workbenchNotesForm.get(formControlName).value;
        noteAccountSettingItem.isDirty = (noteAccountSettingItem.initialValue !== noteAccountSettingItem.currentValue);

        // "Auto-Save"
        this.save().subscribe();
    }

    validateSingleAccountSetting(accountSettings: NotesAccountSettingItem[], name: string) {
        if (accountSettings.length > 1) {
            console.error(`Found multiple settings for acccount setting. [${name}] (developer error)`);
            return false;
        }
        return true;
    }

    validateSettingExists(accountSettings: NotesAccountSettingItem[], name: string) {
        if (accountSettings.length == 0) {
            console.error(`Unexpected Account Setting Provided by the UI. [${name}] (developer error)`);
            return false;
        }
        return true;
    }

    validSettings(accountSettings: NotesAccountSettingItem[], name: string) {
        let validSettings = true;
        validSettings &&= this.validateSingleAccountSetting(accountSettings, name);
        validSettings &&= this.validateSettingExists(accountSettings, name);

        return validSettings;
    }

    //#endregion
    //#region Subscriptions

    subscribeToAccountSettings() {
        this.accountSettingsDataService.loadAccountSettings(this.account.id).subscribe(accountSettings => {
            this.accountSettings = accountSettings;
            this.accountSettingsLoaded = true;

            this.setNoteAccountSettings();
        });
    }

    //#endregion
    //#region Lifecycle

    ngOnInit() {
        this.workbenchNotesForm = this._fb.group({
            allowHiddenNotes: false
        });

        // Add Any Note Settings Here
        this.notesAccountSettingItems.push({
            accountSettingName: AccountSettings.UWWorkbenchHiddenNotes,
            formControlName: this.allowHiddenNotesFormControlName,
            accountSettingTitle: 'Hidden Notes',
            accountSettingDescription: 'Allow internal underwriting notes to be hidden from the notes document.',
            warning: {
                title: 'Documents out of sync',
                message: 'Underwriting notes documents on all current cases may be out of sync until they are regenerated.'
            },
            initialValue: false,
            currentValue: false,
            isDirty: false,
        });

        this.subscribeToAccountSettings();
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    //#endregion
    //#region Handlers

    onToggleFeature(toggleChange: MatSlideToggleChange) {
        const toggleName = toggleChange.source.name;
        const filteredAccountSettings = this.notesAccountSettingItems.filter(noteAccountSetting => noteAccountSetting.formControlName === toggleName);

        if (!this.validSettings(filteredAccountSettings, toggleName)) return;

        // Above validation should ensure that there is only one account setting
        const accountSetting = filteredAccountSettings[0];

        // Open Warning Modal Before Setting
        if (accountSetting.warning) {
            this.disableWarningDialogReference = this.dialog.open(this.disableWarningDialog, {
                width: '650px',
                data: {
                    content: accountSetting.warning.message,
                    title: accountSetting.warning.title
                },
                disableClose: false,
                autoFocus: 'dialog',

            });

            this.disableWarningDialogReference.afterClosed().subscribe(_result => {
                this.setNoteAccountSettingsItem(accountSetting, toggleName);
            });

            return;
        }

        // Base Case
        this.setNoteAccountSettingsItem(accountSetting, toggleName);
    }

    handleClose() {
        this.disableWarningDialogReference.close();
    }

    save() {
        const accountSettings = this.notesAccountSettingItems.filter(x => x.isDirty).map(x => ({
            'id': x.accountSettingId,
            'accountId': this.account.id,
            'name': x.accountSettingName,
            'value': x.currentValue.toString()
        } as AccountSettingsDTO));

        const saveSettings$ = this.appService.postData<UWResponse<AccountSettingsDTO[]>>('AccountSettings', accountSettings).pipe(
            map(_response => {
                this.accountSettingsDataService.reloadAccountSettings(this.account.id).subscribe();
                return true;
            }),
            catchError((_err) => {
                return of(false);
            }),
        );

        return saveSettings$;
    }

    //#endregion
}