<form [formGroup]="apiSettingsForm" (ngSubmit)="saveApiSettings()" novalidate class="flex-fill d-flex flex-column"
      autocomplete="off">
  <div class="property-box-header text-right px-4 py-2">
    <span class="float-left property-box-title">API Configuration</span>
    <button *ngIf="appService.checkACL('Accounts', 'W')" type="submit" class="btn btn-link btn-sm float-right custom">
      <i aria-hidden="true" class="fa fa-check"></i> Save
    </button>
  </div>
  <div class="flex-fill d-flex flex-column property-box-body p-4 overflow-y-auto custom-scrollbar">
    <div class="mt-3">
      <mat-slide-toggle class="d-inline-block" formControlName="enabled" labelPosition="after" style="font-size: 13px;"
                        (change)="toggleDisplay($event)">
        Available for API
      </mat-slide-toggle>
    </div>
    <div class="mt-2">
      <auto-suggest placeholder="Rule" field="name" dataKey="id" formControlName="ruleId"
                    [list]="rules"
                    [disabled]="apiSettingsForm.get('ruleId').disabled"
      ></auto-suggest>
    </div>
    <map-keys-dropdown formControlName="mapKeyId" placeholder="Response MapKey"
                       [displayList]="mapKeys"
                       [formControl]="apiSettingsForm.get('mapKeyId')"></map-keys-dropdown>
  </div>
</form>
