import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from '@App';
import { Account, IntegrationMetadata } from '@Models';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { IConfigService } from '../../config/iconfigservice';
import { BaseService } from '../base.service';

const CACHE_SIZE = 1;

@Injectable()
export class IntegrationService extends BaseService {

    publicApiBaseUrl: string;

    constructor(public appService: AppService, public configService: IConfigService, public httpClient: HttpClient) {
        super(appService, configService, httpClient);
        this.publicApiBaseUrl = this.configService.getConfiguration().publicApiUrl;
    }
    private cache$: { [integrationId: string]: Observable<any> } = {};

    getIntegrationMetadata(integrationId: string, cache = true): Observable<IntegrationMetadata> {
        if (!this.cache$[integrationId]) {
            this.cache$[integrationId] = this.requestMetadata(integrationId).pipe(
                shareReplay(CACHE_SIZE)
            );
        }
        return this.cache$[integrationId];
    }

    private requestMetadata(integrationId): Observable<IntegrationMetadata> {
        return this.appService.getData(`IntegrationRequest/GetMetadata/${integrationId}`)
            .pipe(
                map(response => response.data)
            );
    }

    getV1IntegrationId(integrationType) {
        switch (integrationType) {
            case 'GIACT':
                return '00000000-0000-0000-0000-000000000007';
            case 'MIB':
                return '00000000-0000-0000-0000-000000000009';
            case 'Intelliscript':
                return '00000000-0000-0000-0000-000000000010';
            case 'RNAQuote':
                return '00000000-0000-0000-0000-000000000011';
            case 'RNAAgent':
                return '00000000-0000-0000-0000-000000000012';
        }
    }

    saveAccountIntegration(account: Account, data) {
        const clientId = this.appService.getClientId('');
        const url = `${this.publicApiBaseUrl}client/${clientId}/account/${account.id}/${account.versionNumber}/accountintegration`;
        return super.postData(url, data, clientId);
    }

    activateAccountIntegration(account: Account, integrationId, accountIntegrationId) {
        const clientId = this.appService.getClientId('');
        const url = `${this.publicApiBaseUrl}client/${clientId}/account/${account.id}/${account.versionNumber}/accountintegration/${integrationId}/activate/${accountIntegrationId}`;
        return super.putData(url);
    }

    toggleIntegration(account: Account, integrationId, toggle: boolean) {
        const clientId = this.appService.getClientId('');
        const url = `${this.publicApiBaseUrl}client/${clientId}/account/${account.id}/${account.versionNumber}/accountintegration/${integrationId}/toggle/${toggle}`;
        return super.putData(url);
    }
}
