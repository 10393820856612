import { Component, OnInit, ViewChild } from '@angular/core';
import { AppService } from '../../app.service';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import * as _ from 'lodash';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'case-presence',
    host: { 'class': 'content' },
    templateUrl: './case-presence.component.html'
})
export class CasePresenceComponent implements OnInit {
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    searchValue: any;
    filterValue: any;
    timeZone: string;
    activeUsers: any;
    caseDataSource = new MatTableDataSource<any>();

    columnsToDisplay = ['clientName', 'accountName', 'caseNumberDisplay', 'userName', 'lastModifiedDate'];

    constructor(
        private appService: AppService,
        private datePipe: DatePipe
    ) {
    }

    ngOnInit() {
        this.timeZone = this.appService.getTimeZone();
        this.getActiveUsers();
    }

    getActiveUsers() {
        this.appService.getData('CasePresence/GetActiveUsers').subscribe(
            response => {
                const data = response;
                this.activeUsers = (data || []).map(c => (Object.assign({}, c, {
                    lastModifiedDate: this.datePipe.transform(c.lastModifiedDate, 'MM/dd/yyyy hh:mm:ss', this.timeZone)
                }, {})));

                this.caseDataSource.data = this.activeUsers;

                this.caseDataSource.sortingDataAccessor = (item, property) => {
                    switch (property) {
                    case 'lastModifiedDate': return item.lastModifiedDate;
                    default: return item[property];
                    }
                };

                this.caseDataSource.paginator = this.paginator;
                this.caseDataSource.sort = this.sort;
                this.caseDataSource.filterPredicate = this.tableFilter();
            }
        );
    }

    updateFilteredCases() {
        this.caseDataSource.filter = this.filterValue.trim().toLowerCase();
    }

    tableFilter(): (data: any, filter: string) => boolean {
        const filterFunction = function(data, filter): boolean {
            return data.accountName.toLowerCase().indexOf(filter) !== -1
            || data.caseNumberDisplay.toString().toLowerCase().indexOf(filter) !== -1
            || data.clientName.toLowerCase().indexOf(filter) !== -1
            || data.userName.toLowerCase().indexOf(filter) !== -1;
        };
        return filterFunction;
    }
}
