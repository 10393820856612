import { Pipe, PipeTransform, Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable()
@Pipe({
    name: 'filter'
})

export class FilterPipe implements PipeTransform {
    transform(items: any[], fields: any[], filterText: any): any {
        if (filterText == null || filterText === '') {
            return items;
        }

        return items.filter(item => {
            const targetFields = (fields != null && fields.length > 0) ? fields : Object.keys(item);
            for (let i = 0; i < targetFields.length; i++) {
                const compare = _.get(item, `${targetFields[i]}`, '');
                if (compare && compare.toLowerCase().indexOf(filterText.toLowerCase()) > -1) {
                    return true;
                }
            }

            return false;
        });
    }
}