import { Component, Inject, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { SignatureEnvelopeEmbeddedView } from '../../models';

export interface EmbeddedSignatureDialogOptions {
    embeddedSignatureView: SignatureEnvelopeEmbeddedView;
}

@Component({
    selector: 'embedded-signature-dialog',
    templateUrl: 'embedded-signature-dialog.html',
    styleUrls: ['./embedded-signature-dialog.scss']
})
export class EmbeddedSignatureDialogComponent implements OnDestroy {
    url: SafeResourceUrl;
    browserMessageHandler: any = this.handleBrowserMessage.bind(this);

    constructor(
        public dialogRef: MatDialogRef<EmbeddedSignatureDialogComponent>,
        private sanitizer: DomSanitizer,
        @Inject(MAT_DIALOG_DATA) public data: EmbeddedSignatureDialogOptions) {

        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(data.embeddedSignatureView.url);

        window.addEventListener('message', this.browserMessageHandler);
    }

    ngOnDestroy() {
        window.removeEventListener('message', this.browserMessageHandler);
    }

    handleBrowserMessage(event: any) {
        if (event == null || event.data == null) { return; }

        try {
            const messageData = JSON.parse(event.data);
            if (messageData.messageType !== 'docuSignCallback') { return; }

            const returnData = { event: messageData.event, canceled: messageData.event === 'cancel' };
            this.dialogRef.close(returnData);
        } catch (error) {
            // non-json payload, someone else's message
        }
    }

    onOk(): void {
        this.dialogRef.close(true);
    }

    onCancel(): void {
        this.dialogRef.close(false);
    }

    accept(): void {
        this.dialogRef.close(true);
    }

    reject(): void {
        this.dialogRef.close(false);
    }
}
