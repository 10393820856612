import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CaseDocumentsDTO } from '@DTOs';
import { Client } from '@Models';
import { BaseService, NotificationService, NotificationSeverity } from '@Services';
import {
    AddCaseRequirementRequest,
    CaseRequirement,
    CaseRequirementResponse,
    CreateMyNoteRequest,
    CreateRequirementRequest,
    DeleteCaseRequirementByIdResponse,
    EvidenceRequest,
    PatchNoteRequest,
    PatchRequirementEvidenceProcessorRequest,
    PatchRequirementRuleRequest,
    PatchRequirementSectionRequest,
    PatchRequirementsSortOrderRequest,
    Requirement,
    RequirementResponse,
    SummarizeEvidenceRequest,
    UpdateCaseRequirementAttachmentRequest,
    UpdateCaseRequirementFollowUpRequest,
    UpdateCaseRequirementStatusRequest,
    UpdateCaseRequirementSubtitleRequest,
    UpdateIntegrationCaseRequirementFollowUpRequest,
    UpdateIntegrationCaseRequirementStatusRequest,
    UWIntegrationRequirement,
} from '@Underwriting/models';
import { AppService } from 'app/app.service';
import { IConfigService } from 'app/config/iconfigservice';
import { TimeUtils } from 'app/utils';
import moment = require('moment');
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

import { UwwUtils } from '../../components/requirements/utils';
import NoteResponse from '../../models/uww-note-response';
import PatchRequirementIncludeInCasePackageRequest from '@CaseSummary/underwriting/models/uww-patch-requirement-include-in-case-package-request';
import PatchRequirementIntegrationRequest from '@CaseSummary/underwriting/models/uww-patch-requirement-integration-request';
import PatchRequirementAutomaticallySummarizeRequest from '@CaseSummary/underwriting/models/uww-patch-requirement-automatically-summarize-request';
import UpdateCaseRequirementMatchRequest from '@CaseSummary/underwriting/models/uww-update-case-requirement-match-request';

@Injectable({
    providedIn: 'root'
})
export default class UWWorkbenchService extends BaseService {
    baseUrl = '';
    clientId = '';
    clientCode = '';

    setClientInfo(client: Client): void {
        if (client) {
            this.clientId = client.id;
            this.clientCode = client.code;
            this.baseUrl = this.configService.getUnderwriterApiUrl() + `/v2/client/${this.clientCode}/`;
        }
    }

    constructor(
        public configService: IConfigService,
        public httpClient: HttpClient,
        public appService: AppService,
        private notificationService: NotificationService
    ) {
        super(appService, configService, httpClient);
    }

    //#region Helpers

    handleHttpError(error: any) {
        const errResponse = { ...error };

        // modify the error if the status is 0 so we can show a message when the service isn't available
        if (errResponse.status == 0) {
            errResponse.status = 999;
            errResponse.error = {
                sourceError: 'UW Workbench Service Unavailable',
                userErrors: ['The UW Workbech service is not available. Please contact MRS.']
            };
            errResponse.message = 'The UW Workbench service is not available. Please contact MRS.';
        }

        this.notificationService.showNotification({ severity: NotificationSeverity.Error, message: errResponse.message });
        return throwError(() => new Error(errResponse.message));
    }

    GetHttpCall<T>(url: string, mapper: (value: T) => T = null) {
        const responseMapper = mapper || this.defaultResponseMapper;

        return this.httpClient
            .get(url, { headers: this.appService.getHeaders() })
            .pipe(
                map((response: T) => responseMapper(response)),
                catchError(error => this.handleHttpError(error)),
                finalize(() => this.appService.display(false))
            );
    }

    PatchHttpCall<T>(url: string, request: any, mapper: (value: T) => T = null) {
        const responseMapper = mapper || this.defaultResponseMapper;
        this.appService.display(true);
        return this.httpClient
            .patch(url, request, { headers: this.appService.getHeaders() })
            .pipe(
                map((response: T) => responseMapper(response)),
                catchError(error => this.handleHttpError(error)),
                finalize(() => this.appService.display(false))
            );
    }

    //#endregion
    //#region Mapper

    defaultResponseMapper<T>(response: T) {
        return response;
    }

    enrichUWNote(note: NoteResponse) {
        note.createdOnDisplay = TimeUtils.convertToMomentCst(note.audit.createdOn).format("M/D/YYYY h:mm a");
        return note;
    }

    enrichUWNotes(notes: NoteResponse[]) {
        // DEV NOTE: I tried having `sort` and `forEach` inline functions passed as external methods but something isn't working
        // right with all the layers of clousures and observables.  It's possible that it might be `moment` that fails when moved
        // into their own methods. Instead, just leave inline methods and it should be ok.
        const sortedNotes = [...notes].sort((a: NoteResponse, b: NoteResponse) => {
            const first = moment(a.audit.createdOn);
            const second = moment(b.audit.createdOn);

            return second.diff(first, 'seconds');
        });

        sortedNotes.forEach(note => {
            note.createdOnDisplay = moment.utc(note.audit.createdOn).tz('America/Chicago').format("M/D/YYYY h:mm a");
        });

        return sortedNotes;
    }

    //#endregion
    //#region Notes

    getNoteList(caseId: string) {
        const url = `${this.baseUrl}Notes/case/${caseId}`;
        return this.GetHttpCall<NoteResponse[]>(url, this.enrichUWNotes);
    }

    addMyNote(accountId: string, request: CreateMyNoteRequest) {
        const url = `${this.baseUrl}account/${accountId}/Notes/my`;
        return super.postData<any>(url, request);
    }

    patchNote(accountId: string, request: PatchNoteRequest) {
        const url = `${this.baseUrl}account/${accountId}/Notes`;
        return this.PatchHttpCall<NoteResponse>(url, request, this.enrichUWNote);
    }

    //#endregion
    //#region Requirements (Config)

    createRequirement(CreateRequirementRequest: CreateRequirementRequest) {
        const url = `${this.baseUrl}Requirements`;
        return this.postData<RequirementResponse>(url, CreateRequirementRequest);
    }

    getRequirements(accountId: string) {
        const url = `${this.baseUrl}Requirements/account/${accountId}`;
        return this.GetHttpCall<Requirement[]>(url);
    }

    async patchRequirementSection(request: PatchRequirementSectionRequest) {
        const url = `${this.baseUrl}Requirements/section`;
        return super.patchDataAsync<RequirementResponse>(url, request);
    }

    patchRequirementRule(request: PatchRequirementRuleRequest) {
        const url = `${this.baseUrl}Requirements/rule`;
        return super.patchData<RequirementResponse>(url, request);
    }

    async patchRequirementIntegration(request: PatchRequirementIntegrationRequest) {
        const url = `${this.baseUrl}Requirements/integration`;
        return super.patchDataAsync<RequirementResponse>(url, request);
    }

    patchRequirementIncludeInCasePackage(request: PatchRequirementIncludeInCasePackageRequest) {
        const url = `${this.baseUrl}Requirements/includeInCasePackage`;
        return super.patchData<RequirementResponse>(url, request);
    }

    patchRequirementAutoSummarizedEnabled(request: PatchRequirementAutomaticallySummarizeRequest) {
        const url = `${this.baseUrl}Requirements/autoSummarizeEnabled`;
        return super.patchData<RequirementResponse>(url, request);
    }

    patchSortOrder(request: PatchRequirementsSortOrderRequest) {
        const url = `${this.baseUrl}Requirements/sortOrder`;
        return super.patchData<number>(url, request);
    }

    patchRequirementEvidenceProcessor(request: PatchRequirementEvidenceProcessorRequest) {
        const url = `${this.baseUrl}Requirements/evidenceprocessor`;
        return super.patchData<RequirementResponse>(url, request);
    }

    deleteRequirement(requirementId: string) {
        const url = `${this.baseUrl}Requirements/${requirementId}`;
        return super.deleteData<RequirementResponse>(url);
    }

    //#endregion
    //#region Case Requirements

    getCaseRequirements(caseId: string) {
        const url = `${this.baseUrl}CaseRequirements/case/${caseId}`;
        return super.getData<CaseRequirement[]>(url);
    }

    deleteCaseRequirement(accountId: string, caseRequirement: CaseRequirement) {
        const url = `${this.baseUrl}account/${accountId}/CaseRequirements/case/${caseRequirement.caseId}/caserequirement/${caseRequirement.id}`;
        return super.deleteData<DeleteCaseRequirementByIdResponse>(url);
    }

    addCaseRequirement(accountId: string, caseRequirement: CaseRequirement, caseId: string) {
        const request: AddCaseRequirementRequest = {
            caseId: caseId,
            requirementId: caseRequirement.requirementId,
            name: caseRequirement.name,
            subtitle: caseRequirement.subtitle,
            status: "00000000-0000-0000-0000-000000000001"
        };

        return super.postData<CaseRequirementResponse>(this.baseUrl + `account/${accountId}/CaseRequirements`, request);
    }

    updateCaseRequirementStatus(caseRequirementId: string, status: string, accountId: string) {
        const payload: UpdateCaseRequirementStatusRequest = { caseRequirementId, status };
        return super.putData<CaseRequirementResponse>(this.baseUrl + `account/${accountId}/CaseRequirements/status`, payload);
    }

    updateCaseRequirementSubtitle(caseRequirementId: string, subtitle: string, accountId: string) {
        const payload: UpdateCaseRequirementSubtitleRequest = { caseRequirementId, subtitle };
        return super.putData<CaseRequirementResponse>(this.baseUrl + `account/${accountId}/CaseRequirements/subtitle`, payload);
    }

    updateCaseRequirementFollowUp(caseRequirementId: string, followUp: string, accountId: string) {
        const payload: UpdateCaseRequirementFollowUpRequest = { caseRequirementId, followUp };
        return super.putData<CaseRequirementResponse>(this.baseUrl + `account/${accountId}/CaseRequirements/followup`, payload);
    }

    updateCaseRequirementAttachment(caseRequirementId: string, caseDocumentId: string, accountId: string) {
        const payload: UpdateCaseRequirementAttachmentRequest = { caseRequirementId, caseDocumentId };
        return super.putData<CaseRequirementResponse>(this.baseUrl + `account/${accountId}/CaseRequirements/attachment`, payload);
    }

    updateCaseRequirementMatch(caseRequirementId: string, requirementId: string, name: string, subtitle: string, accountId: string) {
        const payload: UpdateCaseRequirementMatchRequest = { caseRequirementId, requirementId, name, subtitle };
        return super.putData<CaseRequirementResponse>(this.baseUrl + `account/${accountId}/CaseRequirements/update-match`, payload);
    }

    //#endregion
    //#region Integration

    updateCaseRequirementStatusByIntegration(integration: UWIntegrationRequirement, status: string, accountId: string, caseId: string) {
        const payload: UpdateIntegrationCaseRequirementStatusRequest = {
            caseId,
            integrationId: integration.integrationId,
            integrationActionId: integration.integrationActionId,
            integrationName: integration.name,
            status,
        };
        return super.putData<CaseRequirementResponse>(this.baseUrl + `account/${accountId}/CaseRequirements/integration/status`, payload);
    }

    updateCaseRequirementFollowUpByIntegration(integration: UWIntegrationRequirement, followUp: string, accountId: string, caseId: string) {
        const status = UwwUtils.mapIntegrationStatusToCaseRequirementStatus(integration.status);
        const payload: UpdateIntegrationCaseRequirementFollowUpRequest = {
            caseId,
            integrationId: integration.integrationId,
            integrationActionId: integration.integrationActionId,
            status,
            integrationName: integration.name,
            followUp,
        };
        return super.putData<CaseRequirementResponse>(this.baseUrl + `account/${accountId}/CaseRequirements/integration/followup`, payload);
    }

    //#endregion
    //#region Attachments

    viewedEvidence(accountId: string, caseRequirementId: string, caseDocument: CaseDocumentsDTO) {
        this.appService.display(true); // page spinner (baseService will disable it)

        const request: EvidenceRequest = {
            caseDocumentId: caseDocument.id,
            documentName: caseDocument.remoteName
        };
        return super.postData<CaseRequirementResponse>(this.baseUrl + `account/${accountId}/CaseRequirements/${caseRequirementId}/attachment/viewed`, request);
    }

    reviewedEvidence(accountId: string, caseRequirementId: string, caseDocument: CaseDocumentsDTO) {
        const request: EvidenceRequest = {
            caseDocumentId: caseDocument.id,
            documentName: caseDocument.remoteName,
            documentStatusId: caseDocument.statusId
        };
        return super.postData<CaseRequirementResponse>(this.baseUrl + `account/${accountId}/CaseRequirements/${caseRequirementId}/attachment/reviewed`, request);
    }

    summarizeEvidence(accountId:string, caseId: string, caseDocumentId: string, requirementId: string) {
        const request: SummarizeEvidenceRequest = {
            caseDocumentId: caseDocumentId,
            requirementId: requirementId,
            caseId: caseId
        };
        return super.putData<boolean>(this.baseUrl + `account/${accountId}/CaseRequirements/summarize/case/${caseId}`, request);
    }

    //#endregion
    //#region SaaR

    viewSection(accountId: string, caseRequirementId: string): Observable<Requirement> {
        const url = `${this.baseUrl}account/${accountId}/CaseRequirements/${caseRequirementId}/ViewSection`;
        return this.PatchHttpCall(url, {});
    }

    //#endregion
    //#region Evidence

    uploadFile(accountId: string, caseRequirementId: string, file: FormData): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', 'Bearer ' + this.appService.getAuthToken());

        return this.httpClient.post(this.baseUrl + `account/${accountId}/CaseRequirements/${caseRequirementId}/attachment`, file, {
            headers: headers
        });
    }

    downloadDocument(accountId: string, caseRequirementId: string): Observable<string> {
        const response = this.httpClient.get(this.baseUrl + `account/${accountId}/CaseRequirements/${caseRequirementId}/attachment/uri`, { responseType: 'text' });
        return response;
    }

    //#endregion
}
