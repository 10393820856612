<div class="py-2 px-4 bg-light border-bottom documents-header">
  <span class="float-left property-box-title">List of Calls</span>
</div>
<div class="documents-body p-4">
  <audio controls controlsList="nodownload" style="width:100%;" #audioControl oncontextmenu="return false;">
    Your browser does not support the audio element.
  </audio>
  <p-table [value]="caseCallLogs" selectionMode="single" styleClass="summary-component"
    *ngIf="caseCallLogs.length; else noCaseCallLogsPlaceholder">
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 3.3em" id="id"></th>
        <th id="contactId">Contact Id</th>
        <th id="masterId">Master Id</th>
        <th id="agentId">Agent Id</th>
        <th id="dnis">DNIS</th>
        <th id="ani">ANI</th>
        <th id="startTime">Start Time</th>
        <th id="endTime">End Time</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-caseCallLog>
      <tr styleClass="ptable-tr">
        <td class="ptable-td-play">
          <button type="button" class="btn btn-link btn-sm custom" (click)="getAudioUrl(caseCallLog.id)"
            *ngIf="caseCallLog.recordFileName">
            <em class="fa fa-play"></em>
          </button>
        </td>
        <td class="ptable-td">{{ caseCallLog.callId }}</td>
        <td class="ptable-td">{{ caseCallLog.masterId }}</td>
        <td class="ptable-td text-truncate">{{ caseCallLog.agentId }}</td>
        <td class="ptable-td">{{ caseCallLog.dnis }}</td>
        <td class="ptable-td">{{ caseCallLog.ani }}</td>
        <td class="ptable-td">{{ caseCallLog.startTime | date: 'MM/dd/yyyy HH:mm:ss': timeZone }}</td>
        <td class="ptable-td">{{ caseCallLog.endTime | date: 'MM/dd/yyyy HH:mm:ss': timeZone }}</td>
      </tr>
    </ng-template>
  </p-table>
  <ng-template #noCaseCallLogsPlaceholder>
    <div class="p-4 text-center text-muted">
      <img src="./assets/images/no-data-placeholder.png" alt="" class="pb-3" />
      <h6>No Call Logs Added Yet</h6>
    </div>
  </ng-template>
</div>
