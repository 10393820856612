import { Injectable } from "@angular/core";

@Injectable()
export class PrimorisService {

    private paymentPrefs: any;


    setPaymentPrefs(paymentPrefs) {
        this.paymentPrefs =paymentPrefs;
    }

    getPaymentPrefs() {
        return this.paymentPrefs;
    }
}
