import { CaseIntegrationQueueEntry } from '@Models';
import { UWNotesAddFormProps } from '@Underwriting/components/notes';
import {
    CaseRequirement,
    CaseRequirementResponse,
    NoteResponse,
    RequirementResponse,
    UWIntegrationRequirement,
    UWRequirementTableData,
} from '@Underwriting/models';
import { CaseRequirementStatuses } from '@Underwriting/types';
import { TimeUtils, Utils } from '@Utils';
import { CaseDocumentStatus, IntegrationEnum, NoteTypes, QueueStatusEnum } from 'app/enums';

export const CaseRequirementStatusNames = new Map([
    [CaseRequirementStatuses.Unknown.toString(), "Unknown"],
    [CaseRequirementStatuses.NotOrdered.toString(), "Not Ordered"],
    [CaseRequirementStatuses.Ordered.toString(), "Ordered"],
    [CaseRequirementStatuses.Received.toString(), "Received"],
    [CaseRequirementStatuses.Waived.toString(), "Waived"],
    [CaseRequirementStatuses.Completed.toString(), "Completed"],
    [CaseRequirementStatuses.Failed.toString(), "Failed"],
    [CaseRequirementStatuses.Pending.toString(), "Pending"],
    [CaseRequirementStatuses.Cancelled.toString(), "Cancelled"],
]);

export const createBaseUWNotesAddFormProps = () => {
    return {
        dropShadow: true,
        noteType: NoteTypes.Requirement
    };
};

export const mapIntegrationToUWNotesAddFormProps = (integration: UWIntegrationRequirement) => {
    const addFormProps: UWNotesAddFormProps = {
        ...createBaseUWNotesAddFormProps(),
        caseRequirementName: integration.name,
        integrationId: integration.integrationId,
        integrationActionId: integration.integrationActionId,

    };
    return addFormProps;
};

export const mapCaseRequirementToUWNotesAddFormProps = (caseRequirement: CaseRequirement) => {
    const addFormProps: UWNotesAddFormProps = {
        ...createBaseUWNotesAddFormProps(),
        caseRequirementId: caseRequirement.id
    };
    return addFormProps;
};

export const getCssClassForStatus = (statusDisplay: string) => {
    const formatedStatus = `${statusDisplay}`.toLowerCase();

    // Make sure to return a class for every status. Not doing so will fall back to integration's value.
    switch (formatedStatus) {
        case 'not ordered': return 'not-ordered';
        case 'ordered': return 'ordered';
        case 'reviewed': return 'reviewed';
        case 'completed': return 'completed';
        case 'failed': return 'failed';
        case 'documents needed': return 'documents-needed';
        case 'waived': return 'waived';
        case 'pending': return 'pending';
        case 'cancelled': return 'cancelled';
        default: return null;
    }
};

export const getCaseRequirementStatus = (caseRequirement: CaseRequirement, integration: UWIntegrationRequirement) => {
    // Case Requirement (CR) is expected to be the source of truth but if the integration status is reporting 
    // as "Failed" and the CR is "Not Ordered", we want to make sure we display "Failed". While unlikely, it
    // could be possible that the CR did not receive the proper status update to "Failed". (ie. network error, etc)
    if (integration?.status?.toLowerCase() != 'failed') return caseRequirement.status;
    if (caseRequirement.status !== CaseRequirementStatuses.NotOrdered) return caseRequirement.status;
    // We might want to added "CaseRequirementStatuses.Ordered" in the scenario that a "reorder" fails.

    return CaseRequirementStatuses.Failed;
};

export const mapIntegrationStatusToCaseRequirementStatus = (integrationStatus: string) => {
    switch (integrationStatus.toLowerCase()) {
        case 'not started': return CaseRequirementStatuses.NotOrdered;
        case 'received': return CaseRequirementStatuses.Received;
        case 'documents needed':
        case 'failed': return CaseRequirementStatuses.Failed;
        default: return CaseRequirementStatuses.NotOrdered;
    }
};

export const formatCaseRequirementName = (name: string, subtitle: string) => {
    if (Utils.isNullOrUndefined(subtitle) || Utils.isNullOrWhitespace(subtitle))
        return name;

    return `${name}: ${subtitle}`;
};

export const caseRequirementHasIntegration = (caseRequirement: CaseRequirement) => {
    return !Utils.isNullOrEmptyGuid(caseRequirement?.integration?.id) && !Utils.isNullOrEmptyGuid(caseRequirement?.integration?.actionId);
};

export const caseRequirementHasSection = (caseRequirement: CaseRequirement) => {
    return !Utils.isNullOrEmptyGuid(caseRequirement?.section?.id);
};

export const caseRequirementHasAssociatedIntegration = (caseRequirement: CaseRequirement, caseIntegrationQueueEntries: CaseIntegrationQueueEntry[]) => {
    if (caseRequirement.attachmentCaseDocumentIds.length <=0)
        return false;

    let result = false;
    caseRequirement.attachmentCaseDocumentIds.forEach(caseDocumentId => {
        const ciqEntry = caseIntegrationQueueEntries.find(x => x.integrationRequestId === caseDocumentId);

        if (ciqEntry)
            result = true;
    });

    return result;
};

export const caseRequirementAssociatedIntegrationComplete = (caseRequirement: CaseRequirement, caseIntegrationQueueEntries: CaseIntegrationQueueEntry[]) => {
    if (caseRequirement.attachmentCaseDocumentIds.length <=0)
        return false;

    let result = false;
    caseRequirement.attachmentCaseDocumentIds.forEach(caseDocumentId => {
        const ciqEntry = caseIntegrationQueueEntries.find(x => x.integrationRequestId === caseDocumentId);

        if (ciqEntry === undefined || ciqEntry.status === QueueStatusEnum.Completed)
            result = true;
    });

    return result;
};

export const getIntegrationReceivedDateFromIntegration = (integration: UWIntegrationRequirement) => {
    if ((integration.integrationId === IntegrationEnum.Irix) || (integration.integrationId === IntegrationEnum.IntelliScript))
        return null;
    else
        return integration.lastUpdated;
};

export const mapIntegrationToTableData = (integration: UWIntegrationRequirement, notes: NoteResponse[], dayFormat = 'M/D/YYYY') => {
    const mLastUpdated = TimeUtils.convertToMomentCst(integration.lastUpdated);
    const mLastReceived = TimeUtils.convertToMomentCst(getIntegrationReceivedDateFromIntegration(integration));
    const filteredNotes = notes.filter(n =>
        n.caseRequirementNote?.integrationId == integration?.integrationId
        && n.caseRequirementNote?.integrationActionId == integration?.integrationActionId
    );

    const status = mapIntegrationStatusToCaseRequirementStatus(integration.status);
    const statusDisplay = CaseRequirementStatusNames.get(status);

    const rowData: UWRequirementTableData = {
        caseRequirementId: null,
        hasIntegration: true,
        integrationId: integration.id,
        integrationActionId: integration.integrationActionId,
        hasSection: false,
        section: null,
        name: integration.name,
        displayName: integration.name,
        dateOrdered: TimeUtils.formatDay(mLastUpdated, dayFormat),
        timeOfDayOrdered: TimeUtils.formatTime(mLastUpdated),
        dateFollowUp: '',
        timeOfDayFollowUp: '',
        isFollowUpTomorrow: false, // n/a
        isFollowUpToday: false,    // n/a
        isFollowUpPast: false,     // n/a
        dateReceived: TimeUtils.formatDay(mLastReceived, dayFormat),
        timeOfDayReceived: TimeUtils.formatTime(mLastReceived),
        status,
        statusDisplay,
        statusClassName: getCssClassForStatus(statusDisplay),
        hasDocument: false,
        notes: filteredNotes,
        uwNotesAddForm: mapIntegrationToUWNotesAddFormProps(integration),
        // Props that only come from integration data
        canRun: integration.canRun,
        isRunning: (integration.status?.toLowerCase() === 'running'),
        isPending: false,
        retryMessage: integration.retryMessage
    };

    return rowData;
};

export const mapCaseRequirementToTableData = (caseRequirement: CaseRequirementResponse, integration: UWIntegrationRequirement, notes: NoteResponse[], dayFormat = 'M/D/YYYY', caseIntegrationQueueEntries: CaseIntegrationQueueEntry[] | null) => {
    const status = getCaseRequirementStatus(caseRequirement, integration);
    let statusDisplay = CaseRequirementStatusNames.get(status);
    let isSummarizing = false;
    let isSummarized = false;
    let isSummaryFailed = false;
    if (caseRequirement.evidence.find(x => x.statusId == CaseDocumentStatus.Summarizing) != undefined)
    {
        isSummarizing = true;
    }
    else if (caseRequirement.evidence.find(x => x.statusId == CaseDocumentStatus.SummaryFailed) != undefined)
    {
        isSummaryFailed = true;
    }
    else if (caseRequirement.evidence.find(x => x.statusId == CaseDocumentStatus.Summarized) != undefined)
    {
        isSummarized = true;
    }

    const mLastOrdered = TimeUtils.convertToMomentCst(caseRequirement.lastOrderedOn);
    const mFollowUp = TimeUtils.convertToMomentCst(caseRequirement.followUp);
    const mReceived = TimeUtils.convertToMomentCst(caseRequirement.lastReceivedOn);
    const filteredNotes = notes.filter(n => n.caseRequirementNote?.caseRequirementId == caseRequirement.id);
    const hasIntegration = caseRequirementHasIntegration(caseRequirement);
    const hasSection = caseRequirementHasSection(caseRequirement);

    let statusClassName = getCssClassForStatus(statusDisplay) || getCssClassForStatus(integration?.status);
    if ((statusClassName) && (isSummarizing || isSummarized || isSummaryFailed)) {
        statusClassName = statusClassName.concat(' status-display');
    }

    const rowData: UWRequirementTableData = {
        caseRequirementId: caseRequirement.id,
        hasIntegration,
        integrationId: caseRequirement.integration?.id,
        integrationActionId: caseRequirement.integration?.actionId,
        hasSection,
        section: caseRequirement.section,
        name: caseRequirement.name,
        subtitle: caseRequirement.subtitle,
        displayName: formatCaseRequirementName(caseRequirement.name, caseRequirement.subtitle),
        dateOrdered: TimeUtils.formatDay(mLastOrdered, dayFormat),
        timeOfDayOrdered: TimeUtils.formatTime(mLastOrdered),
        dateFollowUp: TimeUtils.formatDay(mFollowUp, dayFormat),
        timeOfDayFollowUp: TimeUtils.formatTime(mFollowUp),
        isFollowUpTomorrow: TimeUtils.isTomorrow(mFollowUp),
        isFollowUpToday: TimeUtils.isToday(mFollowUp),
        isFollowUpPast: TimeUtils.isPast(mFollowUp),
        dateReceived: TimeUtils.formatDay(mReceived, dayFormat),
        timeOfDayReceived: TimeUtils.formatTime(mReceived),
        status,
        statusDisplay: statusDisplay,
        statusClassName: statusClassName,
        hasDocument: Boolean(caseRequirement.attachmentCaseDocumentIds?.length > 0),
        notes: filteredNotes,
        uwNotesAddForm: mapCaseRequirementToUWNotesAddFormProps(caseRequirement),
        // Props that only come from integration data
        canRun: integration?.canRun, // Formally - always set this to `true` for manual
        isRunning: (integration?.status?.toLowerCase() === 'running'),
        isPending: (caseRequirement.status === CaseRequirementStatuses.Pending),
        retryMessage: integration?.retryMessage || '',
        hasAssociatedIntegration: caseRequirementHasAssociatedIntegration(caseRequirement, caseIntegrationQueueEntries),
        isSummarizing : isSummarizing,
        isSummarized: isSummarized,
        isSummaryFailed: isSummaryFailed,
        hasSummaryStatus: (isSummarizing || isSummarized || isSummaryFailed)
    };

    if (rowData.hasAssociatedIntegration) {
        rowData.associatedIntegrationComplete = caseRequirementAssociatedIntegrationComplete(caseRequirement, caseIntegrationQueueEntries);
    }

    return rowData;
};

export const mapToTableData = (caseRequirement: CaseRequirementResponse | null, integration: UWIntegrationRequirement | null, notes: NoteResponse[], dayFormat = 'M/D/YYYY', caseIntegrationQueueEntries: CaseIntegrationQueueEntry[] | null) => {
    if (!caseRequirement && !integration)
        return null;

    if (!caseRequirement)
        return mapIntegrationToTableData(integration, notes, dayFormat);

    return mapCaseRequirementToTableData(caseRequirement, integration, notes, dayFormat, caseIntegrationQueueEntries);
};

export const mapRequirementsToTableData = (integrations: UWIntegrationRequirement[], caseRequirements: CaseRequirementResponse[], requirements: RequirementResponse[], notes: NoteResponse[], caseIntegrationQueueEntries: CaseIntegrationQueueEntry[] | null, dayFormat = 'M/D/YYYY') => {
    const tableData: UWRequirementTableData[] = [];

    // we'll be splicing out the CRs with integrations from this list
    let caseRequirementsClone = [...caseRequirements];

    const caseRequirementIdsToIgnore: string[] = [];
    requirements.forEach(requirement => {
        const matchingCaseRequirement = caseRequirements.find(cr => cr.requirementId == '00000000-0000-0000-0000-000000000001' 
                                                                 && cr.integration?.id == requirement.integrationId 
                                                                 && cr.integration?.actionId == requirement.integrationActionId);
        
        if (matchingCaseRequirement)
            caseRequirementIdsToIgnore.push(matchingCaseRequirement.id);

    });

    caseRequirementsClone = caseRequirementsClone.filter(cr => !caseRequirementIdsToIgnore.includes(cr.id));

    // Map the integrations - match them with their Case Requirement it exists
    integrations.forEach(integration => {
        const matchingRequirement = requirements.find(r => r.integrationId == integration.integrationId 
                                                        && r.integrationActionId == integration.integrationActionId)

        const index = caseRequirementsClone.findIndex(y => y.integration?.id == integration.integrationId && y.integration?.actionId == integration.integrationActionId);

        if (index < 0 && !matchingRequirement) {
            // no matching - map the core integration data UNLESS there is a config-level requirement for the same integration/post-action
            tableData.push(mapToTableData(null, integration, notes, dayFormat, caseIntegrationQueueEntries));
        }
        else if (index >= 0) {
            // match - splice out the case requirement (so we don't re-display it) and map the data
            const caseRequirement = caseRequirementsClone.splice(index, 1)[0];
            tableData.push(mapToTableData(caseRequirement, integration, notes, dayFormat, caseIntegrationQueueEntries));
        }
    });

    // Map the remaining Case Requirements
    caseRequirementsClone.forEach(caseRequirement => tableData.push(mapToTableData(caseRequirement, null, notes, dayFormat, caseIntegrationQueueEntries)));

    // The mappers could return null.  Make sure to remove null values.
    const filteredTableData = tableData.filter(x => x !== null);

    return filteredTableData;
};
