import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Alert, AlertSeverity } from '../models';

import { AppService } from 'app/app.service';
import { MonitoringService } from 'app/monitor.service';
import { throwError } from 'rxjs';
import { NotificationService, NotificationSeverity } from '../services/notification.service';
import { Utils } from '@Utils';

@Injectable()
export class NotificationInterceptor implements HttpInterceptor {
    constructor(private notificationService: NotificationService,
        private monitoringService: MonitoringService,
        private appService: AppService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            map((event: HttpEvent<any>) => {
                if (this.monitoringService.getRuleExecutionLogs().length > 0 && req.url.indexOf("executionlogs/rules") <= 0) {
                    const log = this.monitoringService.getRuleExecutionLogs()[0];
                    if (log.caseId) {
                        this.appService.flushRuleExecutionLogs(log.caseId, log.clientId).subscribe();
                    }
                }

                if (event instanceof HttpResponse) {
                    this.monitoringService.generateNewRequestId();
                }

                if (event instanceof HttpResponse && event.body && event.body.alerts && event.body.alerts.length > 0) {
                    const alerts = <Array<Alert>>event.body.alerts;

                    alerts.forEach(alert => {
                        let severity: NotificationSeverity = NotificationSeverity.Info;
                        let action = Utils.noop;

                        switch (alert.level) {
                            case AlertSeverity.Information:
                                severity = NotificationSeverity.Info;
                                action = undefined;
                                break;
                            case AlertSeverity.Warning:
                                severity = NotificationSeverity.Warning;
                                action = undefined;
                                break;
                            case AlertSeverity.Error:
                                severity = NotificationSeverity.Warning;
                                break;
                            case AlertSeverity.Critical:
                                severity = NotificationSeverity.Critical;
                                break;
                        }
                        this.notificationService.showNotification({
                            severity: severity,
                            message: alert.message,
                            log: alert.level === AlertSeverity.Error || alert.level === AlertSeverity.Critical,
                            action: action
                        });
                    });
                }
                return event;
            }),
            catchError((err: any, _caught) => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status === 403) {
                        this.notificationService.showNotification({
                            severity: NotificationSeverity.Error,
                            message: err.error
                        });
                        console.error('err.error =', err.error, ';');
                    }
                    return throwError(() => err);
                }
            }));
    }
}
