import { animate, style, transition, trigger } from '@angular/animations';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { v4 as uuidv4 } from 'uuid';

import { Account, AccountEvent, DocumentExternal, FaxEvent, Tag } from '../../../models';
import { DocumentsService, MapKeyService, NotificationService, TagService } from '../../../services';


@Component({
    selector: 'document-wizard-document-add',
    templateUrl: './document-add.component.html',
    styleUrls: ['./document-add.component.scss'],
    animations: [
        trigger('flyRightToLeft', [
            transition(':enter', [
                style({ transform: 'translateX(-100%)' }),
                animate(250),
                animate('10ms', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                animate(200, style({ transform: 'translateX(-100%)' })),
                animate('10ms', style({ opacity: 0 }))
            ])
        ])
    ],
})

export class DocumentWizardDocumentAdd implements OnInit {
    public addDocsFormGroup: UntypedFormGroup;
    combinedDocumentForm: UntypedFormGroup;
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];

    accountInfo: Account;
    event: AccountEvent;
    mapKeys: any;
  @Input() nextButtonText: string;
  @Input() canCancel: boolean;
  @Input() documents: DocumentExternal[];
  @Input() tags: Tag[] = [];
  @Input() selectedDocuments: any[] = [];
  @Input() selectedTags: Tag[] = [];
  @Input() selectedItems: any[] = [];
  @Input() isFaxEvent: boolean;
  template: FaxEvent;

  constructor(
    private _fb: UntypedFormBuilder,
    public notificationService: NotificationService,
    public mapKeyService: MapKeyService,
    public documentService: DocumentsService,
    public tagService: TagService,
    public documentAddDialogRef: MatDialogRef<DocumentWizardDocumentAdd>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }


  ngOnInit() {
      this.event = this.data.event;
      this.accountInfo = this.data.accountInfo;
      this.mapKeys = this.mapKeyService.nonConstantMapKeys;

      this.addDocsFormGroup = this._fb.group({
          documents: []
      });

      this.combinedDocumentForm = this._fb.group({
          parentDocumentId: new uuidv4(),
          documents: [],
          tags: []
      });

      if (!this.tags){
          this.tags = [];
      }
  }

  addTag(event: MatChipInputEvent): void {
      const input = event.input;
      const value = event.value;

      if ((value || '').trim()) {
          const newTag: Tag = { accountId: this.accountInfo.id, name: value.trim(), count: 0 };
          this.tags.push(newTag);
          this.combinedDocumentForm.get('tags').setValue(this.tags);
      }

      if (input) {
          input.value = '';
      }
  }

  toggleItem(item: any, itemList): void {
      const index = itemList.indexOf(item);
      const selectedItemsIndex = this.selectedItems.indexOf(item);

      if (index >= 0) {
          itemList.splice(index, 1);
          item.checked = false;
      } else {
          item.checked = true;
          itemList.push(item);
      }

      if (selectedItemsIndex >= 0) {
          item.checked = false;
          this.selectedItems.splice(selectedItemsIndex, 1);
      } else {
          item.checked = true;
          this.selectedItems.push(item);
      }
  }

  drop(event: CdkDragDrop<any[]>) {
      moveItemInArray(this.selectedItems, event.previousIndex, event.currentIndex);
  }

  remove(item: any): void {
      const selectedItemsIndex = this.selectedItems.indexOf(item);
      const selectedDocumentsIndex = this.selectedDocuments.indexOf(item);
      const selectedTagsIndex = this.selectedTags.indexOf(item);

      if (selectedItemsIndex >= 0) {
          this.selectedItems.splice(selectedItemsIndex, 1);
      }

      if (selectedDocumentsIndex >= 0) {
          this.selectedDocuments.splice(selectedDocumentsIndex, 1);
      }

      if (selectedTagsIndex >= 0) {
          this.selectedTags.splice(selectedTagsIndex, 1);
      }
  }

  addToEvent() {
      this.addDocsFormGroup.get('documents').setValue(this.selectedDocuments);
  }
}
