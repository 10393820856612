import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import * as _ from 'lodash';

import { AppService } from '../../app.service';
import { IConfigService } from '../../config/iconfigservice';
import { ReportDownloadService } from '../reportdownload.service';

@Injectable()
export class ImportExportService {

    publicApiBaseUrl: string;

    constructor(private appService: AppService, private configService: IConfigService, private httpClient: HttpClient, private reportDownloadService: ReportDownloadService) {
        this.publicApiBaseUrl = this.configService.getConfiguration().publicApiUrl;
    }

    exportConfig(clientId, accountId) {
        this.appService.display(true);
        const publicApiBaseUrl = this.configService.getConfiguration().publicApiUrl;
        const url = publicApiBaseUrl + `client/${clientId}/config/ExportConfig/${accountId}`;

        const httpPost = this.httpClient.get(url, { headers: this.appService.getHeaders(), responseType: 'blob', observe: 'response' });

        httpPost.subscribe({
            next: (response: HttpResponse<Blob>) => {
                this.appService.display(false);
                this.reportDownloadService.saveToFileSystem(response, 'json');
            },
            error: (error) => {
                this.appService.display(false);
                this.appService.showResponseErrorMsg(error);
            }
        });
    }

    importConfig(clientId, fileData) {
        this.appService.display(true);
        const publicApiBaseUrl = this.configService.getConfiguration().publicApiUrl;
        const url = publicApiBaseUrl + `client/${clientId}/config/ImportConfig`;

        this.httpClient.post(url, fileData, {
            headers: this.appService.getUploadHttpHeaders()
        }).subscribe({
            next: (response) => {
                let data: any;
                if (response['_body']) {
                    data = JSON.parse(response['_body']);
                } else {
                    data = response;
                }
                if (data === true) {
                    fileData = undefined;
                    this.appService.showMsg('success', data.message);
                } else {
                    this.appService.showMsg('error', data.message);
                }
            },
            error: (error) => {
                this.appService.showResponseErrorMsg(error);
                this.appService.display(false);
            }
        });
    }
}
