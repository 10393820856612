import { ExpandableTextComponent } from '@AccountConfig/account-mapkeys/used-in/expandable-text/expandable-text.component';
import { CaseSummarySidebarComponent, MessageBoxComponent, WorkbenchDecisionConfigComponent, WorkbenchNotesConfigComponent, WorkbenchRequirementsConfigComponent, WorkbenchSettingsComponent, } from '@AccountSettings';
import { AccountEnvelopeSignerComponent } from '@AccountSettings/account-envelopes/account-envelope-signer.component';
import { AccountEnvelopesComponent } from '@AccountSettings/account-envelopes/account-envelopes.component';
import { AccountSettingsV2Component } from '@AccountSettings/account-settings-v2.component';
import { AccountSignersComponent } from '@AccountSettings/account-signers/account-signers.component';
import { ApiMappingsComponent } from '@AccountSettings/api-mappings/api-mappings.component';
import { CaseTransferSettingsComponent } from '@AccountSettings/case-transfer/case-transfer.component';
import { DocumentSigningComponent } from '@AccountSettings/document-signing/document-signing.component';
import { GeneralSettingsComponent } from '@AccountSettings/general/general-settings.component';
import { InterviewSettingsComponent } from '@AccountSettings/interview-settings/interview-settings.component';
import { MyCasesSettingsComponent } from '@AccountSettings/my-cases/my-cases-settings.component';
import { PurlSettingsComponent } from '@AccountSettings/purl-settings/purl-settings.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AttachDocumentDialogComponent, CaseSummaryDocumentAttachmentsComponent } from '@CaseSummary';
import { CaseSummaryBillingComponent } from '@CaseSummary/billing/case-summary-billing.component';
import { CaseSummaryCallLogsComponent } from '@CaseSummary/call-logs/case-summary-call-logs.component';
import { CaseSummaryComponent } from '@CaseSummary/case-summary.component';
import { CaseSummaryTestComponent } from '@CaseSummary/case-test/case-summary-test.component';
import { CaseSummaryDocumentComponent } from '@CaseSummary/documents/case-summary-documents.component';
import { CaseSummaryMessagesDialogComponent } from '@CaseSummary/messages/case-summary-messages.component';
import { AccountMapkeySelectorComponent, DeleteColumnMapkeyDialog, EditCnbwColumnsComponent, EditQueuesComponent, QueueRulesComponent, ViewQueueMessageComponent, ViewQueuesComponent, } from '@CNBW';
import { EditActionDialogComponent } from '@Components/action-new/dialog/edit-action-dialog.component';
import { MagnumModule } from '@Components/third-party-section/magnum/magnum.module';
import { InterviewManagerService, InterviewSectionDialog, InterviewSectionListItem, InterviewValidationService, } from '@Interview';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { PipesModule } from '@Pipes/pipes.module';
import { SecondsToTimePipe } from '@Pipes/secondstotime.pipe';
import { FeatureManagerService, FileUploadService } from '@Services';
import { InterviewSectionActionService } from '@Services/case/interview-section-action.service';
import { SbliOneIncPaymentService } from '@Services/case/sbli-one-inc-payment.service';
import { MrsSnackbarComponent } from '@Shared/components/mrs-snackbar/mrs-snackbar.component';
import { UnderwritingModule } from '@Underwriting';
import { MaxLengthDirective } from 'app/directives/input-max-length.directive';
import { SbliOneIncPaymentIntegrationHandler, } from 'app/interview/handlers/oneinc/sbli-oneincpayment-integration-handler.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { QuillModule } from 'ngx-quill';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { EditorModule } from 'primeng/editor';
import { FileUploadModule } from 'primeng/fileupload';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { TreeModule } from 'primeng/tree';

import { AccountBillingComponent, AccountBillingEventUsageDialogComponent, } from './account/account-config/account-billing/account-billing.component';
import { AccountConfigComponent } from './account/account-config/account-config.component';
import { AccountDocumentsComponent } from './account/account-config/account-documents/account-documents.component';
import { AttachDocumentsComponent } from '@AccountConfig/account-events/actions/event-action-email/attach-documents/attach-documents.component';
import { CombinedDocumentsComponent, } from './account/account-config/account-documents/combined-documents/combined-documents.component';
import { EmbeddedDocumentsSettingsComponent, } from './account/account-config/account-documents/embedded-documents-settings/embedded-documents-settings.component';
import { EventActionAccordComponent, } from './account/account-config/account-events/actions/event-action-accord/event-action-accord.component';
import { EventDisplayMessageActionComponent, } from './account/account-config/account-events/actions/event-action-display-message/event-action-display-message.component';
import { EventEmailActionComponent, } from './account/account-config/account-events/actions/event-action-email/event-action-email.component';
import { EventActionFaxComponent, } from './account/account-config/account-events/actions/event-action-fax/event-action-fax.component';
import { EventActionGTLSubmissionComponent, } from './account/account-config/account-events/actions/event-action-gtl-submission/event-action-gtl-submission.component';
import { EventActionArcusTransmissionComponent, } from './account/account-config/account-events/actions/event-action-arcus-transmission/event-action-arcus-transmission.component';
import { EventIntegrationActionComponent, } from './account/account-config/account-events/actions/event-action-integration/event-action-integration.component';
import { EventSMSActionComponent, } from './account/account-config/account-events/actions/event-action-sms/event-action-sms.component';
import { EventActionWebhookComponent, } from './account/account-config/account-events/actions/event-action-webhook/event-action-webhook.component';
import { EventsComponent } from './account/account-config/account-events/events.component';
import { AccountIntegrationComponent, } from './account/account-config/account-integrations/account-integration-view.component';
import { AccountIntegrationsComponent, } from './account/account-config/account-integrations/account-integrations-list.component';
import { RequestComponent } from './account/account-config/account-integrations/request/request.component';
import { AccountInterviewComponent } from './account/account-config/account-interview/account-interview.component';
import { AccountInterviewService } from './account/account-config/account-interview/account-interview.service';
import { InterviewNavigationQuestionComponent, } from './account/account-config/account-interview/interview-navigation/interview-navigation-question/interview-navigation-question.component';
import { InterviewNavigationReflexiveChoiceComponent, } from './account/account-config/account-interview/interview-navigation/interview-navigation-reflexive-choice/interview-navigation-reflexive-choice.component';
import { InterviewNavigationComponent, } from './account/account-config/account-interview/interview-navigation/interview-navigation.component';
import { InterviewPreviewComponent, } from './account/account-config/account-interview/interview-preview/interview-preview.component';
import { SectionApiSettingsComponent, } from './account/account-config/account-interview/interview-section-api-settings/interview-section-api-settings.component';
import { InterviewSectionPropertiesComponent, } from './account/account-config/account-interview/interview-section-properties/interview-section-properties.component';
import { PrefillAnswerComponent } from './account/account-config/account-interview/prefill-answer/prefill-answer.component';
import { AccountMapKeyComponent } from './account/account-config/account-mapkeys/account-mapkey-view.component';
import { AccountMapKeysComponent } from './account/account-config/account-mapkeys/account-mapkeys-list.component';
import { ConstantComponent } from './account/account-config/account-mapkeys/constant/constant.component';
import { DynamicListComponent } from './account/account-config/account-mapkeys/dynamic-list/dynamic-list.component';
import { ExpressionBuilderComponent, } from './account/account-config/account-mapkeys/expressions/expression-builder.component';
import { AccountMapkeyVmkHelpDialog, } from './account/account-config/account-mapkeys/help/account-mapkey-vmk-help-dialog.component';
import { TestVirtualComponent } from './account/account-config/account-mapkeys/test/test-virtual.component';
import { UsedInComponent } from './account/account-config/account-mapkeys/used-in/used-in.component';
import { AccountMIBComponent } from './account/account-config/account-mib/account-mib.component';
import { AccountRulesComponent } from './account/account-config/account-rules/account-rules.component';
import { AccountRxComponent } from './account/account-config/account-rx/account-rx.component';
import { AccountSummaryComponent } from './account/account-config/account-summary/account-summary.component';
import { AccountUnderwritingComponent } from './account/account-config/account-underwriting/account-underwriting.component';
import { UWLabelComponent } from './account/account-config/account-underwriting/uw-label/uw-label.component';
import { UWTagComponent } from './account/account-config/account-underwriting/uw-tag/uw-tag.component';
import { ActiveAccountsComponent } from './active-accounts/active-accounts.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppService } from './app.service';
import { AuthLegacyService } from './auth/auth-legacy.service';
import { Auth0LoginComponent, SignInUpgradeComponent } from './auth/auth0-login/auth0-login.component';
import { UsersComponent } from './auth/users/users.component';
import { AuthorizationModule } from './authorization/authorization.module';
import { CallManagerComponent } from './call-manager/call-manager.component';
import { ClientDetailsComponent } from './clients/client-details/client-details.component';
import { ClientsComponent } from './clients/clients.component';
import { ActionV2Component } from './components/action-new/action-v2/action-v2.component';
import { AuraComponent } from './components/aura/aura.component';
import { BrainTreeComponent } from './components/braintree/braintree.component';
import { CallDialogComponent } from './components/call-dialog/call-dialog.component';
import { CaseAssignmentAndRestartComponent } from './components/case-assignment/case-assignment-and-restart.component';
import { CaseAssignmentComponent } from './components/case-assignment/case-assignment.modal.component';
import { CaseEventsComponent } from './components/case-events/case-events.component';
import { CaseMetricsV2Component } from './components/case-metrics-v2/case-metrics-v2.component';
import { CaseMetricService } from './components/case-metrics-v2/services/case-metrics.service';
import { CaseMetricsComponent } from './components/case-metrics/case-metrics.component';
import { ConditionComponent } from './components/condition/condition.component';
import { ConditionSetComponent } from './components/conditionset/conditionset.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { ContactsComponent } from './components/contacts/contacts.component';
import { DisplayLabelValueComponent } from './components/display-label-value/display-label-value.component';
import { DocumentWizardDocumentAdd } from './components/document-wizard/add-documents/document-add.component';
import { DocumentWizardDocumentArrange } from './components/document-wizard/arrange-documents/document-arrange.component';
import { ErrorNotificationComponent } from './components/error/error-notification.component';
import { FieldDetailsComponent } from './components/field-details/field-details.component';
import { FollowupDateConfigComponent } from './components/followup-date-config/followup-date-config.component';
import { ActionButtonComponent } from './components/form-fields/action-button/action-button.component';
import { AutoSuggestComponent } from './components/form-fields/auto-suggest/auto-suggest.component';
import { BaseFormFieldServices, } from './components/form-fields/base-interview-form-field/base-interview-form-field.component';
import { CustomDropdownComponent } from './components/form-fields/custom-dropdown/custom-dropdown.component';
import { DateAnswerComponent } from './components/form-fields/date-answer/date-answer.component';
import { DisplayMessageComponent } from './components/form-fields/display-message/display-message.component';
import { DynamicActionFormControlComponent, } from './components/form-fields/dynamic-action-form-control/dynamic-action-form-control.component';
import { DynamicFormComponent } from './components/form-fields/dynamic-form/dynamic-form.component';
import { EFTDateComponent } from './components/form-fields/eft-date/eft-date.component';
import { EmailAnswerComponent } from './components/form-fields/email-answer/email-answer.component';
import { HealthAnswerComponent } from './components/form-fields/health-answer/health-answer.component';
import { HeightAnswerComponent } from './components/form-fields/height-answer/height-answer.component';
import { MultiChoiceMultiAnswerV2Component, } from './components/form-fields/multi-choice-multi-answer-v2/multi-choice-multi-answer-v2.component';
import { MultiChoiceMultiAnswerComponent, } from './components/form-fields/multi-choice-multi-answer/multi-choice-multi-answer.component';
import { MultiChoiceSingleAnswerComponent, } from './components/form-fields/multi-choice-single-answer/multi-choice-single-answer.component';
import { MultiLineAnswerComponent } from './components/form-fields/multi-line-answer/multi-line-answer.component';
import { NumericAnswerComponent } from './components/form-fields/numeric-answer/numeric-answer.component';
import { PhoneNumberAnswerComponent } from './components/form-fields/phone-number-answer/phone-number-answer.component';
import { RepeatBlockComponent } from './components/form-fields/repeat-block/repeat-block.component';
import { RoutingNumberAnswerComponent, } from './components/form-fields/routing-number-answer/routing-number-answer.component';
import { ShortAnswerComponent } from './components/form-fields/short-answer/short-answer.component';
import { SSNAnswerComponent } from './components/form-fields/ssn-answer/ssn-answer.component';
import { AttachmentUploadingDialogComponent, } from './components/form-fields/upload-file-button/attachment-uploading-dialog.component';
import { UploadFileActionButtonComponent } from './components/form-fields/upload-file-button/upload-file-button.component';
import { HTMLEditorModal } from './components/html-editor-modal/html-editor-modal';
import { IntegrationMappingComponent } from './components/integration-mapping/integration-mapping.component';
import { MapKeysDropdownComponent } from './components/map-keys-dropdown/map-keys-dropdown.component';
import { MapKeysModalComponent } from './components/map-keys-dropdown/map-keys-modal.component';
import { MenuItemComponent } from './components/mapkey-menu/mapkey-menu-item.component';
import { MultiSelectDropdownListComponent, } from './components/multi-select-dropdown-list/multi-select-dropdown-list.component';
import { NoteComponent } from './components/note/note.component';
import { NotesListComponent } from './components/notes-list/notes-list.component';
import { ObjectTagsComponent } from './components/object-tags/object-tags.component';
import { PortalOneComponent } from './components/portal-one/portal-one.component';
import { PrimorisModule } from './components/primoris-embedded/primoris.module';
import { RichTextEditorComponent } from './components/rich-text-editor/rich-text-editor.component';
import { RuleComponent } from './components/rule/rule.component';
import { EmbeddedSignatureDialogComponent, } from './components/signature-envelope-dialog/embedded-signature-dialog.component';
import { RemoteSignerComponent } from './components/signature-envelope-dialog/remote-signer.component';
import { SignatureCallbackComponent } from './components/signature-envelope-dialog/signature-callback.component';
import { SignatureEnvelopeDialogComponent, } from './components/signature-envelope-dialog/signature-envelope-dialog-component';


import { StripeComponent } from './components/stripe/stripe.component';
import { SubRuleComponent } from './components/subrule/subrule.component';
import { CepQuestionnaireComponent } from './components/third-party-section/cep-questionnaire/cep-questionnaire.component';
import { CepQuestionnaireService } from './components/third-party-section/cep-questionnaire/cep-questionnaire.service';
import { ThirdPartySection } from './components/third-party-section/third-party-section.component';
import { ThirdPartySectionDirective } from './components/third-party-section/third-party-section.directive';
import { ThirdPartySectionService } from './components/third-party-section/third-party-section.service';
import { TrustCommerceCallbackComponent } from './components/trustcommerce/trustcommerce-callback.component';
import { TrustCommerceComponent } from './components/trustcommerce/trustcommerce.component';
import { AudioPlayer, ImgViewer, JsonViewer, PdfViewer, PlainTextViewer, TiffViewer, ViewDocumentDialog, } from './components/view-document-dialog';
import { ConfigService } from './config/config.service';
import { IConfigService } from './config/iconfigservice';
import { DashboardComponent } from './dashboard/dashboard.component';
import * as Directives from './directives';
import { DynamicActionFormControlDirective } from './directives/dynamic-action-form-control.directive';
import { DynamicFieldDirective } from './directives/dynamic-field.directive';
import { InputChangeDirective } from './directives/input-change.directive';
import { EndOfCallComponent } from './endofcall/endofcall.component';
import { MonitoringErrorHandler } from './error.handler';
import * as Interceptors from './interceptors';
import { InterviewService } from './interview.service';
import { ActionDialogComponent } from './interview/action-dialog.component';
import { ApplicantEndOfInterviewComponent } from './interview/end-of-interview/applicant-end-of-interview.component';
import { ApplicantInterviewCloseComponent } from './interview/end-of-interview/applicant-interview-close.component';
import { EndOfInterviewHandler } from './interview/end-of-interview/end-of-interview-handler.component';
import { InterviewCloseComponent } from './interview/end-of-interview/interview-close.component';
import { InterviewTerminateComponent } from './interview/end-of-interview/interview-terminate.component';
import { AuraIntegrationResponseHandler } from './interview/handlers/aura/aura-integration-response-handler.component';
import { BraintreeIntegrationResponseHandler, } from './interview/handlers/braintree/braintree-integration-response-handler.component';
import { PrimorisIntegrationResponseHandler, } from './interview/handlers/primoris/primoris-integration-response-handler.component';
import { StripeIntegrationResponseHandler } from './interview/handlers/stripe/stripe-integration-response-handler.component';
import { TrustCommerceIntegrationResponseHandler, } from './interview/handlers/trustcommerce/trustcommerce-integration-response-handler.component';
import { InterviewComponent } from './interview/interview.component';
import PdfViewerDialog from './interview/pdf-viewer-dialog.component';
import { PurlVerificationComponent } from './interview/purl/purl-verification/purl-verification.component';
import { PurlComponent } from './interview/purl/purl.component';
import { EditUsersComponent } from './manage-users/edit-users/edit-users.component';
import { ManageUsersPageComponent } from './manage-users/manage-users-page.component';
import { ManageUsersTableComponent } from './manage-users/manage-users-table.component';
import { ManagementQueuesSwitcherComponent } from './management-queues/management-queues-switcher.component';
import { ManagementQueuesComponent } from './management-queues/management-queues.component';
import { NotesDialogComponent } from './management-queues/notes-dialog.component';
import { MonitoringService } from './monitor.service';
import { MyCasesComponent } from './my-cases/my-cases.component';
import { NavigationComponent } from './navigation/navigation.component';
import { SortingDirective } from './new-business-workbench/directives/sorting.directive';
import { CanActivateCnbw } from './new-business-workbench/guards/canActivateCnbw.guard';
import { CanNavigateAwayWithConfirmGuard, CanNavigateAwayWithConfirmGuardAccountConfig, } from './new-business-workbench/guards/canNavigateAway.guard';
import { NewBusinessWorkbenchStateService } from './new-business-workbench/services/new-business-workbench-state.service';
import { NewBusinessWorkbenchService } from './new-business-workbench/services/new-business-workbench.service';
import { NotFoundComponent } from './not-found.component';
import { AccountRuleSelectorTable, AuditCasesTableComponent, AuditCasesTableStatsComponent, AuditCreatePageComponent, AuditGroupTableComponent, AuditPageComponent, AuditViewPageComponent, ViewDocumentPage, AuditPercentLabelComponent, AuditEditPageComponent, AuditSaveDialog } from './pages';
import { BillingReportComponent } from './reports/billing-report/billing-report.component';
import { CallDetailReportComponent } from './reports/call-detail-report/call-detail-report.component';
import { CaseDetailReportComponent } from './reports/case-detail-report/case-detail-report.component';
import { ReportsComponent } from './reports/reports.component';
import { ClientAccountDropdownComponent } from './reports/shared/client-account-dropdown.component';
import { TransmissionReportComponent } from './reports/transmission-report/transmission-report.component';
import { UserAccessReportComponent } from './reports/user-access-report/user-access-report.component';
import * as Services from './services';
import * as Guards from './services/guards';
import * as Resolvers from './services/resolvers';
import * as UtilityServices from './services/utility';
import { MrsTreeviewComponent } from './shared/components/mrs-treeview/mrs-treeview.component';
import { SharedModule } from './shared/shared.module';
import { VersioningComponent } from './versioning/versioning.component';
import { SignatureLoadingComponent } from '@Components/signature-envelope-dialog/v2/signature-loading.component';
import { RemoteSignerV2Component } from '@Components/signature-envelope-dialog/v2/remote-signer/remote-signer-v2.component';
import { SignatureEnvelopeDialogV2Component } from "@Components/signature-envelope-dialog/v2/signature-envelope-dialog/signature-envelope-dialog-v2.component";
import { EmbeddedSignatureDialogV2Component } from "@Components/signature-envelope-dialog/v2/embedded-signature-dialog/embedded-signature-dialog-v2.component";
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';

// import { PdfViewerModule } from 'ng2-pdf-viewer';
export function ConfigLoader(configService: IConfigService) {
    // Note: this factory need to return a function (that return a promise)

    return () => {
        return configService.load().then();
    };
}


@NgModule({
    declarations: [
        CaseAssignmentAndRestartComponent,
        AccountInterviewComponent,
        AccountUnderwritingComponent,
        AccountDocumentsComponent,
        AccountIntegrationsComponent,
        AccountIntegrationComponent,
        AccountMIBComponent,
        AccountRxComponent,
        AccountBillingComponent,
        AccountSettingsV2Component,
        AccountSignersComponent,
        AccountEnvelopesComponent,
        AccountEnvelopeSignerComponent,
        AccountConfigComponent,
        AccountSummaryComponent,
        AccountRulesComponent,
        AccountMapKeysComponent,
        AccountMapKeyComponent,
        AppComponent,
        AttachDocumentsComponent,
        AttachDocumentDialogComponent,
        AccountMapkeyVmkHelpDialog,
        Auth0LoginComponent,
        ActiveAccountsComponent,
        ActionDialogComponent,
        ApplicantInterviewCloseComponent,
        ApplicantEndOfInterviewComponent,
        ApiMappingsComponent,
        AccountBillingEventUsageDialogComponent,
        ApplicantEndOfInterviewComponent,
        BillingReportComponent,
        BrainTreeComponent,
        CallDetailReportComponent,
        ClientAccountDropdownComponent,
        CaseAssignmentComponent,
        CaseMetricsComponent,
        CaseMetricsV2Component,
        CaseTransferSettingsComponent,
        CaseSummaryComponent,
        CaseSummaryBillingComponent,
        CaseSummaryCallLogsComponent,
        CaseSummaryDocumentComponent,
        CaseSummaryDocumentAttachmentsComponent,
        CaseSummaryMessagesDialogComponent,
        CaseSummarySidebarComponent,
        CaseSummaryTestComponent,
        CaseEventsComponent,
        CepQuestionnaireComponent,
        ClientsComponent,
        ClientDetailsComponent,
        CombinedDocumentsComponent,
        ConditionComponent,
        ConditionSetComponent,
        ConfirmationDialogComponent,
        ContactsComponent,
        ConstantComponent,
        CallDialogComponent,
        CallManagerComponent,
        DashboardComponent,
        DocumentSigningComponent,
        DisplayLabelValueComponent,
        DynamicActionFormControlDirective,
        DynamicListComponent,
        DocumentWizardDocumentAdd,
        DocumentWizardDocumentArrange,
        Directives.MaskValueDirective,
        Directives.MaskDateDirective,
        ExpressionBuilderComponent,
        EndOfCallComponent,
        EventsComponent,
        EventEmailActionComponent,
        EventActionFaxComponent,
        EventDisplayMessageActionComponent,
        EventIntegrationActionComponent,
        EventSMSActionComponent,
        EventActionWebhookComponent,
        EventActionAccordComponent,
        EventActionGTLSubmissionComponent,
        EventActionArcusTransmissionComponent,
        EmbeddedDocumentsSettingsComponent,
        EmbeddedSignatureDialogComponent,
        EmbeddedSignatureDialogV2Component,
        EventActionAccordComponent,
        EventActionWebhookComponent,
        EditUsersComponent,
        ErrorNotificationComponent,
        FieldDetailsComponent,
        FollowupDateConfigComponent,
        GeneralSettingsComponent,
        HTMLEditorModal,
        InputChangeDirective,
        InterviewSettingsComponent,
        InterviewNavigationComponent,
        InterviewNavigationQuestionComponent,
        InterviewNavigationReflexiveChoiceComponent,
        InterviewPreviewComponent,
        InterviewSectionPropertiesComponent,
        InterviewComponent,
        InterviewCloseComponent,
        InterviewTerminateComponent,
        IntegrationMappingComponent,
        MapKeysDropdownComponent,
        MapKeysModalComponent,
        MrsTreeviewComponent,
        ManageUsersPageComponent,
        ManageUsersTableComponent,
        MenuItemComponent,
        MenuItemComponent,
        MyCasesComponent,
        ManagementQueuesComponent,
        ManagementQueuesSwitcherComponent,
        MaxLengthDirective,
        MessageBoxComponent,
        MyCasesSettingsComponent,
        MultiSelectDropdownListComponent,
        NavigationComponent,
        NotFoundComponent,
        NotesDialogComponent,
        NoteComponent,
        NotesListComponent,
        ObjectTagsComponent,
        PurlComponent,
        PurlVerificationComponent,
        PurlSettingsComponent,
        PortalOneComponent,
        CaseDetailReportComponent,
        QueueRulesComponent,
        RichTextEditorComponent,
        RequestComponent,
        RuleComponent,
        ReportsComponent,
        RemoteSignerComponent,
        RemoteSignerV2Component,
        SortingDirective,
        SignInUpgradeComponent,
        SignatureEnvelopeDialogComponent,
        SignatureEnvelopeDialogV2Component,
        SignatureCallbackComponent,
        SignatureLoadingComponent,
        StripeComponent,
        SectionApiSettingsComponent,
        SubRuleComponent,
        TrustCommerceCallbackComponent,
        TrustCommerceComponent,
        ThirdPartySection,
        ThirdPartySectionDirective,
        TransmissionReportComponent,
        TestVirtualComponent,
        UserAccessReportComponent,
        UsedInComponent,
        UsersComponent,
        UWLabelComponent,
        UWTagComponent,
        VersioningComponent,
        ViewDocumentDialog,
        WorkbenchDecisionConfigComponent,
        WorkbenchNotesConfigComponent,
        WorkbenchRequirementsConfigComponent,
        WorkbenchSettingsComponent,
        AudioPlayer,
        ImgViewer,
        JsonViewer,
        PdfViewer,
        PlainTextViewer,
        TiffViewer,
        ExpandableTextComponent,
        PdfViewerDialog,
        MrsSnackbarComponent,
        InterviewSectionDialog,
        InterviewSectionListItem,
        ActionDialogComponent,
        EditActionDialogComponent,

        // Audit
        AuditPageComponent,
        AuditCreatePageComponent,
        AuditViewPageComponent,
        AuditCasesTableComponent,
        AuditGroupTableComponent,
        AuditCasesTableStatsComponent,
        AuditPercentLabelComponent,
        AccountRuleSelectorTable,
        AuditEditPageComponent,

        // View Document
        ViewDocumentPage,

        // CNBW
        AccountMapkeySelectorComponent,
        EditCnbwColumnsComponent,
        EditQueuesComponent,
        QueueRulesComponent,
        ViewQueueMessageComponent,
        ViewQueuesComponent,
        DeleteColumnMapkeyDialog,

        // Fields
        AuraComponent,
        ActionButtonComponent,
        AttachmentUploadingDialogComponent,
        AutoSuggestComponent,
        CustomDropdownComponent,
        DateAnswerComponent,
        DisplayMessageComponent,
        DynamicActionFormControlComponent,
        EFTDateComponent,
        EmailAnswerComponent,
        HealthAnswerComponent,
        HeightAnswerComponent,
        MultiChoiceMultiAnswerComponent,
        MultiChoiceMultiAnswerV2Component,
        MultiChoiceSingleAnswerComponent,
        MultiLineAnswerComponent,
        NumericAnswerComponent,
        PhoneNumberAnswerComponent,
        RepeatBlockComponent,
        RoutingNumberAnswerComponent,
        ShortAnswerComponent,
        SSNAnswerComponent,
        UploadFileActionButtonComponent,

        // Dynamic Form
        DynamicFormComponent,
        DynamicFieldDirective,
        ActionV2Component,
        PrefillAnswerComponent,
        AuditSaveDialog
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        ConfirmDialogModule,
        DialogModule,
        TreeModule,
        NgxDocViewerModule,
        ContextMenuModule,
        EditorModule,
        TableModule,
        FileUploadModule,
        AutoCompleteModule,
        CalendarModule,
        ProgressSpinnerModule,
        NgxExtendedPdfViewerModule,
        ScrollToModule.forRoot(),
        QuillModule.forRoot(),
        AngularEditorModule,
        MatIconModule,
        PipesModule,
        SharedModule,
        AuthorizationModule.forRoot(),
        PrimorisModule.forRoot(),
        DragDropModule,
        AppRoutingModule,
        UnderwritingModule,
        MatDialogModule,
        MagnumModule,
        MatTableModule,
        MatSortModule
    ],
    entryComponents: [
        MultiChoiceSingleAnswerComponent,
        MultiChoiceMultiAnswerComponent,
        MultiChoiceMultiAnswerV2Component,
        ConfirmationDialogComponent,
        ActionDialogComponent,
        InterviewCloseComponent,
        ApplicantInterviewCloseComponent,
        ApplicantEndOfInterviewComponent,
        NotesDialogComponent,
        CaseAssignmentComponent,
        EventEmailActionComponent,
        EventActionFaxComponent,
        EventDisplayMessageActionComponent,
        EventIntegrationActionComponent,
        EventSMSActionComponent,
        EventActionWebhookComponent,
        EventActionGTLSubmissionComponent,
        EventActionArcusTransmissionComponent,
        EventActionAccordComponent,
        CombinedDocumentsComponent,
        EmbeddedDocumentsSettingsComponent,
        AccountBillingEventUsageDialogComponent,
        AttachDocumentDialogComponent,
        CaseSummaryMessagesDialogComponent,
        AccountEnvelopeSignerComponent,
        MyCasesSettingsComponent,
        ApiMappingsComponent,
        InterviewSettingsComponent,
        SignatureEnvelopeDialogComponent,
        SignatureEnvelopeDialogV2Component,
        EmbeddedSignatureDialogComponent,
        EmbeddedSignatureDialogV2Component,
        SignatureLoadingComponent,
        ErrorNotificationComponent,
        RemoteSignerComponent,
        RemoteSignerV2Component,
        AccountMapKeyComponent,
        TestVirtualComponent,
        MapKeysModalComponent,
        CallDialogComponent,
        BrainTreeComponent,
        PortalOneComponent,
        TrustCommerceComponent,
        StripeComponent,
        CepQuestionnaireComponent,
        EditActionDialogComponent,

        // Field (TODO: Remove when we have form-field.module working again)
        AuraComponent,
        ActionButtonComponent,
        AttachmentUploadingDialogComponent,
        AutoSuggestComponent,
        CustomDropdownComponent,
        DateAnswerComponent,
        DisplayMessageComponent,
        DynamicActionFormControlComponent,
        EFTDateComponent,
        EmailAnswerComponent,
        HealthAnswerComponent,
        HeightAnswerComponent,
        MultiChoiceMultiAnswerComponent,
        MultiChoiceMultiAnswerV2Component,
        MultiChoiceSingleAnswerComponent,
        MultiLineAnswerComponent,
        NumericAnswerComponent,
        PhoneNumberAnswerComponent,
        RepeatBlockComponent,
        RoutingNumberAnswerComponent,
        ShortAnswerComponent,
        SSNAnswerComponent,
        UploadFileActionButtonComponent,
        // Dynamic Form
        DynamicFormComponent,
    ],
    providers: [
        { provide: IConfigService, useClass: ConfigService },
        { provide: APP_INITIALIZER, useFactory: ConfigLoader, deps: [IConfigService], multi: true },
        AppService,
        AuthLegacyService,
        InterviewService,
        InterviewSectionActionService,
        AccountInterviewService,
        Services.ReportDownloadService,
        MonitoringService,
        DatePipe,
        BraintreeIntegrationResponseHandler,
        SbliOneIncPaymentIntegrationHandler,
        AuraIntegrationResponseHandler,
        TrustCommerceIntegrationResponseHandler,
        StripeIntegrationResponseHandler,
        PrimorisIntegrationResponseHandler,
        EndOfInterviewHandler,
        BaseFormFieldServices, // form-field
        Services.AccountDataService,
        Services.ClientDataService,
        Services.DashboardService,
        Services.ManagementQueueService,
        Services.CaseSummaryService,
        Services.ConfirmationDialogService,
        Services.EnvironmentService,
        Services.IdleService,
        Services.InterviewClientDefaultsService,
        Services.RoutingService,
        Services.WhiteLabelService,
        Services.NotificationService,
        Services.RulesService,
        Services.SignalRService,
        Services.LookupService,
        Services.FeatureToggleService,
        Services.IntegrationService,
        Services.AccountEventsService,
        Services.AccountSettingsService,
        Services.SectionApiSettingsService,
        Services.SectionService,
        Services.DocumentsService,
        Services.ApiMappingsService,
        Services.CaseSignatureService,
        Services.SignerService,
        Services.ImportExportService,
        Services.CaseImportExportService,
        Services.CaseNotificationsService,
        Services.MapKeyService,
        Services.CaseMapKeyService,
        Services.CaseDynamicListMapkeyService,
        Services.ObservableService,
        Services.AccountService,
        Services.TagService,
        Services.CallManagerService,
        Services.CaseAttachmentService,
        Services.SystemEventService,
        Services.GlobalService,
        Services.ClientService,
        Services.ConstantMapKeyService,
        Services.SystemEventService,
        Services.CaseAssigneeService,
        Services.BraintreeService,
        Services.LocalDataService,
        Services.IpAddressService,
        Services.TrustCommerceService,
        Services.MagnumService,
        Services.StripeService,
        Services.QuestionService,
        Services.UserAdminService,
        Services.CaseDocumentsDataService,
        Services.AccountSettingsDataService,
        Services.SectionDataService,
        Services.InterviewDataService,
        Services.MapkeysDataService,
        InterviewManagerService,
        InterviewValidationService,
        SbliOneIncPaymentService,
        NewBusinessWorkbenchService,
        NewBusinessWorkbenchStateService,
        FileUploadService,
        CaseMetricService,
        CepQuestionnaireService,
        ThirdPartySectionService,
        UtilityServices.UtilityService,
        FeatureManagerService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: Interceptors.CookieInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: Interceptors.NotificationInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: Interceptors.LocalTimeInterceptor,
            multi: true
        },
        // {
        //   provide: HTTP_INTERCEPTORS,
        //   useClass: Interceptors.CacheInterceptor,
        //   multi: true
        // },
        Resolvers.AccountResolverService,
        Resolvers.AccountsResolverService,
        Resolvers.ActiveAccountResolverService,
        Resolvers.ClientResolverService,
        Resolvers.ClientsResolverService,
        Resolvers.WhiteLabelThemeResolver,
        Resolvers.InterviewResolverService,
        {
            provide: ErrorHandler,
            useClass: MonitoringErrorHandler
        },
        Guards.FeatureToggleGuard,
        Guards.RedirectGuard,
        CanNavigateAwayWithConfirmGuard,
        CanNavigateAwayWithConfirmGuardAccountConfig,
        CanActivateCnbw,
        SecondsToTimePipe,
        CurrencyPipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule {

    constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
        const appPath = 'assets/mdi.svg';
        matIconRegistry.addSvgIconSet(
            domSanitizer.bypassSecurityTrustResourceUrl(appPath)
        );
    }

}
