<div class="flex flex-row flex-fill">
    <span class="label-content flex-fill">
        <div [className]="uwLabelName ? 'hasADecision spacing' : 'incomplete spacing'">
            {{uwLabelName ? uwLabelName : "UW Incomplete"}}
            <span *ngIf="patchDecision.underWritingNote" class="dateStyle">
                {{patchDecision.underWritingNote.lastModifiedDate | date:'short'}}
            </span>
        </div>
        <div class="flex flex-column w-100">
            <div class="w-100">
                <mat-form-field class="w-40">
                    <mat-label>Select UW Decision</mat-label>
                    <mat-select [(ngModel)]='patchDecision.uwdecisionId' ngDefaultControl>
                        <mat-option *ngFor="let label of labels" [value]="label.id">
                            {{ label.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-error class="error"
                    *ngIf="patchDecision.uwdecisionId === '00000000-0000-0000-0000-000000000000' && submitted">
                    <small>Required</small>
                </mat-error>
            </div>
            <div class="w-100">
                <mat-form-field class="w-60 textarea-formfield">
                    <mat-label>Reason for decision</mat-label>
                    <textarea matInput [(ngModel)]='patchDecision.underWritingNote.noteContent' rows="2"></textarea>
                </mat-form-field>
                <mat-error class="error" *ngIf="patchDecision.underWritingNote.noteContent === '' && submitted">
                    <small class="errorspacing">Required</small>
                </mat-error>
            </div>
        </div>
    </span>
    <span class="align-middle m-1 flex-end">
        <mrs-btn-tertiary buttonLabel="Cancel" buttonId="cancelUpdateDecisionBtn"
            (onClick)="handleOnCancel()"></mrs-btn-tertiary>
        <mrs-btn-primary buttonLabel="Save Decision" buttonId="saveDecisionBtn"
            (onClick)="handleOnSave()"></mrs-btn-primary>
    </span>
</div>