<section id="audit-edit-page" class="ag-page" [formGroup]="auditForm">
    <div class="page-header">
        <span class="title">Edit Audit > {{client?.name }}: {{ this.auditForm.controls.name.value }}</span>
        <div class="title-buttons">
            <mrs-btn-tertiary class="back-btn" buttonLabel="Cancel" (onClick)="goBack()"></mrs-btn-tertiary>
            <mrs-btn-primary buttonLabel="Save Changes" (onClick)="onSubmit()"></mrs-btn-primary>
        </div>
    </div>

    <!-- Page Sub Header -->
    <div class="page-subheader">
        <div>
            {{ startDateDisplay }} - {{ endDateDisplay }}
        </div>
    </div>

    <ng-container *ngIf="!auditLoaded">
        <mat-spinner mode="indeterminate" diameter="30"></mat-spinner>
    </ng-container>

    <div class="page-content" *ngIf="clientsLoaded">
        <!-- General Settings -->
        <div class="page-section">
            <div class="section-header">
                <h3 class="title">General Settings</h3>
            </div>

            <div class="section-content">
                <!-- Name -->
                <div class="input name-input">
                    <mat-form-field class="form-field">
                        <mat-label>Name</mat-label>
                        <input matInput formControlName="name">
                    </mat-form-field>
                </div>

                <!-- Audit Goal Percentage-->
                <div class="input audit-date-type-picker">
                    <mat-form-field class="form-field">
                        <mat-label>Set Target %</mat-label>
                        <input matInput formControlName="auditGoalPercentage">
                    </mat-form-field>
                </div>
            </div>
        </div>
        <!-- Accounts -->
        <div class="page-section">
        <div class="section-header">
            <h3 class="title">Accounts</h3>
        </div>
        <div class="section-subheader">
            <p>Only accounts and versions active during selected Date Range</p>
        </div>

        <div class="section-content">
            <div *ngIf="activeAccountsForSelectedClient == null && !loadingSelectedClientAccounts" class="empty-state-message">
                Select a Client & Date Range to view Accounts
            </div>
            <div *ngIf="activeAccountsForSelectedClient == null && loadingSelectedClientAccounts" class="empty-state-message">
                <mat-spinner mode="indeterminate" diameter="30"></mat-spinner>
            </div>

            <div *ngIf="activeAccountsForSelectedClient != null">
                <account-rule-selector-table [accounts]="activeAccountsForSelectedClient" [selectedAccountRules]="audit.accountRules" (onAuditRuleChange)="handleRuleChange($event)"></account-rule-selector-table>
            </div>
        </div>

        </div>

        <!-- Preview -->
        <div class="page-section">
            <div class="section-header">
                <h3 class="title">Preview</h3>
            </div>

            <div class="section-subheader">
                <div class="input">
                    <mrs-btn-outline buttonLabel="Preview" isSubmitButton="true" [disabled]="(auditForm.controls.accountRules.value.length === 0)" (onClick)="onPreview()"></mrs-btn-outline>
                </div>
                <div class="input" *ngIf="casesLoaded">
                    Total Cases: {{ auditCases.length }}
                </div>
            </div>

            <div class="section-content">
                <div *ngIf="!casesLoaded" class="empty-state-message">
                    Enter required info to preview
                </div>
                <div *ngIf="casesLoaded" class="results">
                    <div *ngIf="isAuditGroupingEnabled">
                        <audit-group-table [auditCasesGroups]="auditCasesGroups" hideActions=true [auditGroupByType]="selectedAuditGroupByType"></audit-group-table>
                    </div>
                    <div *ngIf="!isAuditGroupingEnabled">
                        <audit-cases-table [auditCases]="auditCases"></audit-cases-table>
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>