import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'case-summary-messages-dialog',
    templateUrl: 'case-summary-messages.component.html',
    styleUrls: ['./case-summary-messages.component.scss']
})
export class CaseSummaryMessagesDialogComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<CaseSummaryMessagesDialogComponent>,
        private sanitizer: DomSanitizer,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    messages: SafeHtml[] = [];

    ngOnInit() {
        this.data.displayMessages.forEach(element => {
            this.messages.push(this.formatText(element));
        });
    }

    cancel(): void {
        this.dialogRef.close(false);
    }

    private formatText(text: string): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(text);
    }
}
