import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { forkJoin } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import * as $ from 'jquery';
import { RoutesEnum } from '../services';
import { UWResponse } from '@Models';
import { UsersDTO } from '@DTOs';

@Component({
    selector: 'app-role-builder',
    host: { 'class': 'content' },
    templateUrl: './role-builder.component.html',
    styleUrls: ['./role-builder.component.scss']
})
export class RoleBuilderComponent implements OnInit {

    private sub: any;
    form: UntypedFormGroup;
    public role: any; // = {};
    rolesList: any = [];
    showForm = false;
    authPermissions: any = [];
    authPermissionTypes: any = [];
    userPermissions: any = [];
    showPermissions = false;
    itemSelected = false;
    accessType: any = [];
    features: any;
    selectedItem: any;
    defualtAccessType: any = '00000000-0000-0000-0000-000000000001';
    entities: any = [];
    showDelete = false;
    showErrorMessage = false;
    errorMessage: any;
    selectedRole: any;
    submitAttempt = false;
    windowHeight = 860;
    showDeleteRoleDialog = false;

    // rolesData:any = [];

    constructor(
        private appService: AppService,
        public dialog: MatDialog
    ) {

        this.form = new UntypedFormGroup({
            roleName: new UntypedFormControl('', [Validators.required])
        });
    }

    toggleClick() { $('#sidenav').toggleClass('hidenav'); }

    ngOnInit() {

        this.checkACL('R', RoutesEnum.dashboard);
        const defualtAccessType = '00000000-0000-0000-0000-000000000001';
        this.getData();
    }

    getData() {
        this.getAuthPermissions();
        this.getAuthPermissionTypes();
        this.getRolesList();
    }

    getAuthPermissions() {
        this.appService.getData('AuthPermissions').subscribe(
            data => {
                if (data.status === 'success') {
                    this.authPermissions = data.data;
                } else {
                    this.authPermissions = [];
                }
            }
        );
    }

    getAuthPermissionTypes() {
        this.appService.getData('AuthPermissionTypes').subscribe(
            data => {
                if (data.status === 'success') {
                    this.authPermissionTypes = data.data;
                } else {
                    this.authPermissionTypes = [];
                }
            }
        );
    }

    getRolesList() {
        this.appService.getData('UserRoles').subscribe(
            data => {
                if (data.status === 'success') {
                    this.rolesList = data.data;
                } else {
                    this.rolesList = [];
                }
            }
        );
    }

    addRole() {

        this.form.reset();

        this.role = {
            roleName: '',
            userRolePermissions: []
        };

        this.defualtAccessType = '00000000-0000-0000-0000-000000000001';

        this.authPermissions.forEach(permission => {
            this.role.userRolePermissions.push({
                authPermissionsId: permission.id,
                authPermissionsTypeId: this.defualtAccessType
            });
        });

        this.showPermissions = true;
    }

    editRole(event, data) {
        if (this.checkACL('W')) {
            this.role = data;
            this.form.patchValue(data);
            this.selectedItem = this.role;
            this.loadSelectedData();
        }
    }

    loadSelectedData() {
        const defualtAccessType = '00000000-0000-0000-0000-000000000001';
        this.showPermissions = true;
        this.itemSelected = true;

        this.entities = [
            { ID: '00000000-0000-0000-0000-000000000001', AuthPermission: 'Billing', AccessType: defualtAccessType },
            { ID: '00000000-0000-0000-0000-000000000002', AuthPermission: 'Case Management', AccessType: defualtAccessType },
            { ID: '00000000-0000-0000-0000-000000000003', AuthPermission: 'MIB Calls', AccessType: defualtAccessType }
        ];

        const userRoles = {
            ID: '',
            RoleName: 'TestRole8',
            userRolePermissions: [{
                authPermissionsId: '00000000-0000-0000-0000-000000000001',
                authPermissionsTypeId: '00000000-0000-0000-0000-000000000001'
            }, {
                authPermissionsId: '00000000-0000-0000-0000-000000000002',
                authPermissionsTypeId: '00000000-0000-0000-0000-000000000001'
            }, {
                authPermissionsId: '00000000-0000-0000-0000-000000000003',
                authPermissionsTypeId: '00000000-0000-0000-0000-000000000001'
            }]
        };

        this.entities.forEach(e => {
            e.AccessType = _.find(userRoles.userRolePermissions, ['authPermissionsId', e.ID]).authPermissionsTypeId;
        });
    }

    saveRole() {

        this.submitAttempt = true;

        if (this.form.valid) {

            let isEdit = false;

            if (this.role.id) {
                isEdit = true;
            }

            this.role.roleName = this.form.get('roleName').value;

            this.appService.postData('UserRoles', this.role, isEdit).subscribe(
                data => {
                    if (data.status === 'success') {
                        this.getConfigData();
                        this.submitAttempt = false;
                        this.selectedRole = undefined;
                        this.getData();
                        this.showPermissions = false;

                        this.appService.showMsg('success', 'Saved successfully ...');

                    } else {
                        this.appService.showMsg('error', data.message);
                    }
                }
            );
        }
    }

    deleteItem(templateRef: any) {
        if (this.selectedRole) {
            this.closeForm();
            this.showDelete = true;
            this.showDeleteDialog(templateRef);
            this.selectedRole = undefined;
        } else {
            this.appService.showMsg('warning', 'Please select a record to delete');
        }
    }

    showDeleteDialog(templateRef: any) {
        this.dialog.open(templateRef,
            {
                width: '300px',
                maxHeight: '90%',
                data: {
                }
            });
    }

    closeDialog() {
        this.dialog.closeAll();
    }

    deleteRole() {
        this.showDeleteRoleDialog = false;

        this.appService.deleteData('UserRoles/' + this.role.id).subscribe(
            data => {
                if (data.status === 'success') {
                    this.showPermissions = false;
                    this.closeDialog();
                    this.getData();
                    this.appService.showMsg('success', 'Deleted Successfully');
                } else {
                    this.appService.showMsg('error', data.message);
                }
            }
        );
    }

    closeForm() {
        this.submitAttempt = false;
        this.showPermissions = false;
        this.selectedRole = undefined;
    }

    checkACL(permissionType, redirect?) {
        return this.appService.checkACL('Roles', permissionType, redirect);
    }

    getAccessTypeValue(authPermissionsId) {
        return _.find(this.authPermissionTypes, ['id', _.find(this.role.userRolePermissions, ['authPermissionsId', authPermissionsId]).authPermissionsTypeId]).authPermissionType;
    }

    getAuthPermissionLabel(id) {
        return _.find(this.authPermissions, ['id', id]).authPermission;
    }

    getAuthPermissionTypeLabel(id) {
        return _.find(this.authPermissionTypes, ['id', id]).authPermissionType;
    }

    getRolePermissionIndex(authPermissionsId) {
        const permissionIndex = _.findIndex(this.role.userRolePermissions, ['authPermissionsId', authPermissionsId]);
        return permissionIndex;
    }

    getConfigData() {

        this.appService.getData<UWResponse<UsersDTO>>('Auth/getAuthenticatedData').subscribe(
            authResp => {
                if (authResp.status === 'success') {

                    this.appService.setUserdetails(authResp.data);

                    forkJoin([
                        this.appService.getData('AuthPermissions'),
                        this.appService.getData('AuthPermissionTypes')
                    ]).subscribe(authData => {
                        this.authPermissions = authData[0].data;
                        this.authPermissionTypes = authData[1].data;

                        const access = [];

                        authResp.data.userRolePermissions.forEach(permission => {

                            const accessName = _.find(this.authPermissions, ['id', permission.authPermissionsId]).authPermission;
                            const permissionName = _.find(this.authPermissionTypes, ['id', permission.authPermissionsTypeId]).authPermissionType;

                            const ac = {};
                            ac[accessName] = permissionName;
                            access.push(ac);

                        });

                        this.appService.setACL(access);
                    });
                }
            }
        );
    }
}
