<div class="page-heading" id="subheading">
    <i aria-hidden="true" class="page-heading-icon fa fa-clone"></i>
    <span class="page-heading-title">End of Call Utility</span>
</div>

<div id="innercontent" class="custom-scrollbar">
    <div class="container-fluid container-background">
        <div class="container mat-card my-4 p-0" *ngIf="isContactFound && callDetails">
            <div class="row">
                <div class="col-5 border-right">
                    <div class="p-3">
                        <form [formGroup]="callEditForm" (ngSubmit)="updateCallDetailInfo()" novalidate autocomplete="off">
                            <div class="panel-title">Action #1: Only select when it is not interview related.</div>
                            <div class="panel-sub-title">If your call was not an order, please let us know what happend.</div>
                            <div class="mt-2">
                                <mat-form-field class="w-100">                                    
                                    <mat-label>Dismiss Reason</mat-label>
                                    <mat-select formControlName='dismissReason' (selectionChange)="dismissReasonChange($event)">
                                        <mat-option *ngFor="let type of dismissReasons" [value]="type.id">
                                            {{ type.name }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="border-top pt-3 panel-title">Action #2: Select for all orders associated with this call.</div>
                            <div class="panel-sub-title">This call is currently associated with OrderID: </div>
                            <div class="panel-sub-title">If your calls needs to be associated to multiple orders, please add them below</div>
                            <div class="mt-3 w-100">
                                <mat-form-field class="w-100 chip-input" appearance="fill">
                                    <mat-label>Case Numbers</mat-label>
                                    <mat-chip-grid #chipList>
                                        <mat-chip-row
                                            *ngFor="let caseNumber of callEditForm?.get('caseNumbers')?.value"
                                            [selectable]="true"
                                            [removable]="true"
                                            (removed)="removeCaseNumber(caseNumber)">
                                            {{caseNumber}}
                                            <button matChipRemove>
                                                <mat-icon>cancel</mat-icon>
                                            </button>
                                        </mat-chip-row>                                        
                                        <input
                                            #caseNumberInput
                                            formControlName="caseNumber"
                                            [matAutocomplete]="caseNumberAutocomplete"
                                            [matChipInputFor]="chipList"
                                            [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
                                            [matChipInputAddOnBlur]="addOnBlur"
                                            (matChipInputTokenEnd)="addCaseNumber($event)">
                                    </mat-chip-grid>
                                    <mat-autocomplete (optionSelected)="caseNumberSelected($event)" #caseNumberAutocomplete="matAutocomplete" autoActiveFirstOption>
                                        <mat-option *ngFor="let caseNumber of filteredCaseNumbers" [value]="caseNumber">
                                            {{ caseNumber }}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </div>
                            <div class="mt-3">
                                <button type="submit" mat-raised-button color="primary" class="mr-1">
                                    <i aria-hidden="true" class="fa fa-cloud"></i> Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="col-7">
                    <div class="row pl-3">
                        <div class="col-12 px-0">
                            <div class="border-bottom py-2 mb-3">
                                Call Detail Information
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <display-lv [label]="'Agent Name'" [value]="callDetails?.agentId"></display-lv>
                            <display-lv [label]="'Skill Name'" [value]="callDetails?.skill"></display-lv>
                            <display-lv [label]="'Order ID'" [value]="callDetails?.externalId"></display-lv>
                            <display-lv [label]="'Contact ID'" [value]="callDetails?.callId"></display-lv>
                            <display-lv [label]="'Master ID'" [value]="callDetails?.masterId"></display-lv>
                        </div>
                        <div class="col-6">
                            <display-lv [label]="'DNIS'" [value]="callDetails?.dnis"></display-lv>
                            <display-lv [label]="'ANI'" [value]="callDetails?.ani"></display-lv>
                            <display-lv [label]="'Call Started'" [value]="callDetails?.startTime | date: 'MM/dd/yyyy HH:mm:ss': timeZone"></display-lv>
                            <display-lv [label]="'Call Ended'" [value]="callDetails?.endTime | date: 'MM/dd/yyyy HH:mm:ss': timeZone"></display-lv>
                            <display-lv [label]="'Call Status'" [value]="callDetails?.callStatus"></display-lv>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container mat-card my-4 p-0" *ngIf="!isContactFound">
        <div class="row">
            <div class="col-12 p-4 text-center text-muted ">
                <img src="./assets/images/no-data-placeholder.png " alt=" " class="pb-3 " />
                <h6>No Data Found</h6>
            </div>
        </div>
    </div>
</div>
