<div class="question-container" [formGroup]="group" [ngClass]="{active: isSelected, interviewQuestion: interviewMode}">
    <div [ngClass]="isConfigInvalid()?'invalid-question':''">
        <div class="question-header" *ngIf="!interviewMode" (click)="selectQuestion()">
            <button type="button" class="btn btn-link float-left" (click)="expanded = !expanded">
                <i aria-hidden="true" class="fa"
                    [ngClass]="{'fa-angle-right': !expanded, 'fa-angle-down': expanded}"></i>
            </button>
            <span class="question-mapkey">
                {{config.mapKeyName}}
            </span>
            <span class="question-mapkey float-right pr-2">
                {{config.answerTypeName}}
            </span>
        </div>
        <div class="question-body" *ngIf="expanded && config?.DisplayType">
            <div class="question-text rendered-quill-content ql-editor" [innerHTML]="questionText"></div>
            <div class="help-text rendered-quill-content ql-editor" [innerHTML]="helpText"
                *ngIf="config.helpText?.length"></div>
            <div class="question-form" [ngSwitch]="config?.DisplayType" *ngIf="showList">

                <auto-suggest *ngSwitchCase="'select'" [list]="constantList" [placeholder]="config.Placeholder"
                    field="displayName" dataKey="value" [formControlName]="config.id" (focusin)="onFocus()"
                    (onBlurred)="onBlur()" [disableSort]="disableSort"
                    [showRequiredError]="group.get(config.id)?.hasError('required') && (group.get(config.id)?.touched || group.get(config.id)?.dirty)"
                    [disabled]="isReadOnly()" [interviewMode]="interviewMode" [multiple]="true"
                    [allowNewValues]="config?.AllowNewValues"
                    (newValueChanges)="updatedListValue($event)"></auto-suggest>

                <div class="w-100" *ngSwitchCase="'checkbox'" [attr.data-test-input-type]="inputTypeCheckbox">
                    <div *ngFor="let listValue of constantList; let i = index">
                        <mat-checkbox [checked]="listValue.checked" (change)="onCheckboxChange(listValue,$event)"
                            [disabled]="isReadOnly()"
                            [attr.data-test-value]="listValue.value">{{listValue.displayName}}</mat-checkbox>
                    </div>
                </div>
                <mat-error
                    *ngIf="group.get(config.id)?.hasError('validationError')">{{group.get(config.id)?.errors.validationError}}</mat-error>


                <dynamic-form *ngIf="indentReflexiveQuestions && reflexiveQuestions?.length" [expandAllQuestions]="true"
                    interviewMode="reflexive" [parentId]="reflexiveFormId" [parentIndex]="formIndex"
                    [constantLists]="constantLists" [config]="reflexiveQuestions"></dynamic-form>
            </div>
            <app-field-details [question]="config" [form]="group" [readOnlyMode]="isReadOnly()"></app-field-details>
        </div>
    </div>
</div>
<dynamic-form *ngIf="!indentReflexiveQuestions && reflexiveQuestions?.length" [expandAllQuestions]="true"
    interviewMode="reflexive" [parentId]="reflexiveFormId" [removeIndent]="true" [parentIndex]="formIndex"
    [constantLists]="constantLists" [config]="reflexiveQuestions"></dynamic-form>