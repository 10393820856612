
export enum AuditViewStatusFilters {
    All = 'All',
    Selected = 'Selected',
    Completed = 'Completed',
}

export enum AuditNoCasesMessage {
    Default = 'No Cases',
    Selected = 'No Selected Cases',
    Completed = 'No Completed Cases',
}

// Values must match AuditDateTypes in UWPipeline.Core.Models.
export enum AuditDateTypes {
    Unknown = 0,
    CreationDate = 1,
    CompletionDate = 2
}

// Values must match AuditGroupBy in UWPipeline.Core.Models.
export enum AuditGroupByTypes {
    None = 0,
    AssignedTo = 1,
    CreatedBy = 2,
    CompletedBy = 3
}

export enum AuditPermissions {
    Audit = 'Audit',
    AuditRefresh = 'AuditRefresh',
    AuditEdit = 'AuditEdit'
}

export enum AuditGoalStates {
    Disabled = 0,
    Below = 1,
    Above = 2
}