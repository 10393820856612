<div class="note-component">
  <div class="note-card d-flex flex-column" [ngClass]="{active: expanded}">
    <form novalidate [formGroup]="noteForm" (ngSubmit)="saveNote()" class="d-flex flex-column">

      <div class="note-card-header d-flex flex-row">
        <button type="button" class="btn btn-link expand-trigger" (click)="expanded = !expanded">
          <i aria-hidden="true" class="fa" [ngClass]="{'fa-angle-right': !expanded, 'fa-angle-down': expanded}"></i>
        </button>

        <div *ngIf="!expanded" class="note-card-header-content d-flex flex-row flex-fill"
             (click)="expanded = !expanded">
          <span class="note-type">{{getNoteTypeLabel(note.noteTypeId)}}</span>
          <span class="flex-fill note-content">{{note.noteContent|removeHTMLTags}}</span>
          <span class="generated-by">
          <i aria-hidden="true" class="fa"
             [ngClass]="{'fa-server': note.isSystemGenerated, 'fa-user-circle-o': !note.isSystemGenerated}"></i>
            {{note.userName}}
        </span>
          <span class="last-updated">{{note.creationDate | date: 'MM/dd/yyyy HH:mm:ss': timeZone}}</span>
        </div>

        <div *ngIf="expanded && !note.isSystemGenerated" class="note-card-header-actions flex-fill pr-4">
          <button *ngIf="isEditView && noteForm?.get('id')?.value && checkACL('F')" type="button"
                  class="btn btn-link btn-sm custom float-right" (click)="deleteNote()">
            <i aria-hidden="true" class="far fa-trash-alt"></i> Delete
          </button>
          <button type="button" *ngIf="!isEditView && checkACL('W')" class="btn btn-link btn-sm custom float-right"
                  (click)="editNote()">
            <i aria-hidden="true" class="fa fa-edit"></i> Edit
          </button>
          <button *ngIf="isEditView && !noteForm?.get('id')?.value && checkACL('W')" type="button"
                  class="btn btn-link btn-sm custom float-right" (click)="deleteNote()">
            <i aria-hidden="true" class="fa fa-times"></i> Cancel
          </button>
          <button type="button" *ngIf="isEditView && noteForm?.get('id')?.value"
                  class="btn btn-link btn-sm custom float-right" (click)="isEditView = !isEditView">
            <i aria-hidden="true" class="fa fa-times"></i> Close
          </button>
          <button type="submit" *ngIf="isEditView && checkACL('W')" class="btn btn-link btn-sm custom float-right">
            <i aria-hidden="true" class="fa fa-check"></i> Save
          </button>
        </div>
      </div>

      <div class="note-card-body d-flex flex-column px-4 pt-3 pb-1" *ngIf="expanded">

        <div *ngIf="!isEditView" class="note-card-body-preview d-flex flex-row">

          <div class="preview-block">
            <div class="label-title">Is Client Viewable</div>
            <div class="label-content">
              <i aria-hidden="true" class="fa"
                 [ngClass]="{'fa-check-circle': note.isClientViewable, 'fa-times-circle': !note.isClientViewable}"></i>
            </div>
          </div>

          <div class="preview-block">
            <div class="label-title">Note Related To</div>
            <div class="label-content">{{getRelationLabel(note.relatedTo)}}</div>
          </div>

          <div class="preview-block">
            <div class="label-title">Note Type</div>
            <div class="label-content">{{getNoteTypeLabel(note.noteTypeId)}}</div>
          </div>

          <div class="preview-block">
            <div class="label-title">Followup on</div>
            <div class="label-content" *ngIf="!note.isSystemGenerated">{{note.followUpDateDisplay}}</div>
          </div>

          <div class="preview-block">
            <div class="label-title">Added by</div>
            <div class="label-content">
              <i aria-hidden="true" class="fa"
                 [ngClass]="{'fa-server': note.isSystemGenerated, 'fa-user-circle-o': !note.isSystemGenerated}"></i>
              {{note.userName}}
            </div>
          </div>

          <div class="preview-block">
            <div class="label-title">Last Updated</div>
            <div class="label-content">{{note.creationDate | date: 'MM/dd/yyyy HH:mm:ss': timeZone}}</div>
          </div>

          <div class="preview-block w-100">
            <div class="label-title">Notes</div>
            <div class="label-content" [innerHTML]="note.noteContent"></div>
          </div>

          <div class="preview-block w-100" *ngIf="note.noteTypeReasonId">
            <div class="label-title">Reason</div>
            <div class="label-content">{{getNoteTypeReason(note.noteTypeReasonId)?.description}}</div>
          </div>
        </div>

        <ng-template [ngIf]="isEditView">
          <input type="hidden" formControlName="id"/>
          <input type="hidden" formControlName="caseDetailsId"/>

          <div class="row">
            <div class="col-3">
              <mat-slide-toggle class="d-inline-block mt-2" formControlName='isClientViewable'> &nbsp; Is Client
                Viewable
              </mat-slide-toggle>
            </div>
            <div class="col-3">
              <mat-form-field class="w-100">
                <mat-label>Note Related to</mat-label>
                <mat-select formControlName="relatedTo">
                  <mat-option *ngFor="let rel of noteRelations" [value]="rel.key">
                    {{rel.value}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="noteForm.get('relatedTo').hasError('required')">required</mat-error>
              </mat-form-field>
            </div>
            <div class="col-3">
              <mat-form-field class="w-100">
                <mat-label>Note Type</mat-label>
                <mat-select formControlName="noteTypeId" (selectionChange)="noteTypeChanged()">
                  <mat-option *ngFor="let noteType of activeNoteTypes" [value]="noteType.id">
                    {{noteType.description}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="noteForm.get('noteTypeId').hasError('required')">required</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row" >
            <div class="col-3" [hidden]="!isFollowupDate">
              <mat-form-field class="w-100">
                <mat-label>Followup</mat-label>
                <input matInput [matDatepicker]="picker"
                       [matDatepickerFilter]="dateFilter"
                       formControlName="followUpDate">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-error *ngIf="noteForm.get('followUpDate').hasError('required')">required</mat-error>
              </mat-form-field>
            </div>
            <div class="col-3" *ngIf="activeNoteTypeReasons?.length > 0">
              <mat-form-field class="w-100">
                <mat-label>Reason</mat-label>
                <mat-select formControlName="noteTypeReasonId"
                            (selectionChange)="updateValidations()">
                  <mat-option>--</mat-option>
                  <mat-option *ngFor="let noteTypeReason of activeNoteTypeReasons" [value]="noteTypeReason.id">
                    {{noteTypeReason.description}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-12 note-text-area">
              <angular-editor id="angularEditor" *ngIf="toggleHtmlEditorFeature" [config]="htmlEditorConfig" matInput
                              placeholder="Notes"
                              formControlName="noteContent"></angular-editor>
              <div *ngIf="!toggleHtmlEditorFeature">
                <mat-form-field class="w-100 textarea-formfield">
                  <mat-label>Notes</mat-label>
                  <textarea id="textAreaEditor" matInput matTextareaAutosize matAutosizeMinRows="2"
                            matAutosizeMaxRows="5"
                            formControlName="noteContent"></textarea>
                  <mat-error *ngIf="noteForm.get('noteContent').hasError('required')">required</mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </form>
  </div>
</div>
