<h1 mat-dialog-title>Configure Arcus to Zinia Transmission
</h1>
<div mat-dialog-content>
    <form [formGroup]="webhookForm" novalidate autocomplete="off" class="addpadding">
        <div class="bold-label-18 headerLabel">Credentials</div>
        <div>
            <div formGroupName="oAuth2Request" novalidate autocomplete="off">
                <mat-form-field class="w-100">
                    <mat-label>Grant Type</mat-label>
                    <mat-select id="grantType" formControlName="grantType">
                        <mat-option></mat-option>
                        <mat-option value="client_credentials">Client Credentials</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="w-100">
                    <mat-label>Access Token URL</mat-label>
                    <input id="tokenEndpoint" matInput formControlName='tokenEndpoint'>
                </mat-form-field>
                <mat-form-field class="w-100">
                    <mat-label>Client ID</mat-label>
                    <input id="clientId" matInput formControlName='clientId'>
                </mat-form-field>
                <auto-suggest *ngIf="secretKeys" [list]="secretKeys" placeholder="Password" field="name" dataKey="id"
                    formControlName="clientSecret">
                </auto-suggest>
                <mat-form-field class="w-100">
                    <mat-label>Audience</mat-label>
                    <input id="audience" matInput formControlName='audience'>
                </mat-form-field>
            </div>
        </div>
        <div class="bold-label-18 headerLabel">Annuity Application</div>
        <mat-form-field class="w-100">
            <mat-label>Application Url</mat-label>
            <input id="contentUrl" matInput formControlName='contentUrl'>
            <mat-error *ngIf="webhookForm.get('contentUrl').hasError('required')">required</mat-error>
        </mat-form-field>
        <mat-form-field class="w-100 textarea-formfield">
            <mat-label>Content</mat-label>
            <textarea id="applicationContent" matInput formControlName='applicationContent' rows="10"></textarea>
            <mat-error *ngIf="formSubmitted && webhookForm.get('applicationContent').hasError('required')">
                Content
                <strong>required</strong>
            </mat-error>
        </mat-form-field>
        <div class="bold-label-18 headerLabel">Annuity Application Attachments</div>
        <mat-form-field class="w-100">
            <mat-label>Application Attachments Url</mat-label>
            <input id="attachmentsUrl" matInput formControlName='attachmentsUrl'>
            <mat-error *ngIf="webhookForm.get('attachmentsUrl').hasError('required')">required</mat-error>
        </mat-form-field>
        <mat-form-field class="w-100 textarea-formfield">
            <mat-label>Content</mat-label>
            <textarea id="applicationDocumentsContent" matInput formControlName='applicationDocumentsContent'
                rows="10"></textarea>
            <mat-error *ngIf="formSubmitted && webhookForm.get('applicationDocumentsContent').hasError('required')">
                Content
                <strong>required</strong>
            </mat-error>
        </mat-form-field>
        <div class="flex flex-row">
            <mat-slide-toggle id="toggleCondition" formControlName="regenerateDocuments">
                Regenerate Documents</mat-slide-toggle>
        </div>
        <map-keys-dropdown class="wider-input" [displayList]="mapkeys" showRequiredError="true" showArrow="true"
            ngDefaultControl placeholder="Status Mapkey" [allowedTypes]="[mapKeyTypeEnum.NonVirtual]"
            [formControl]="webhookForm.get('statusMapkey')" formControlName="statusMapkey">
        </map-keys-dropdown>
    </form>
</div>
<div style="padding-right:20px">
    <mat-dialog-actions align="end">
        <mrs-btn-tertiary buttonLabel="Cancel" buttonId="cancelButton" mat-dialog-close>Cancel</mrs-btn-tertiary>
        <mrs-btn-primary buttonId="continueButton" buttonLabel="Continue" (onClick)="saveAccountMessage()">Continue
        </mrs-btn-primary>
    </mat-dialog-actions>
</div>