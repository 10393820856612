import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AccountSettings, CaseStatus, FeatureToggle } from '@Enums';
import {
    InterviewSectionDialog,
    InterviewSectionDialogAction,
    InterviewSectionDialogData,
    InterviewSectionDialogResponse,
} from '@Interview';
import { AccountSettingsService, FeatureManagerService } from '@Services';
import { UWBaseProps, UWDecisionNote, UWDecisionPatch, UWLabel } from '@Underwriting/models';
import { UWDecisionServices } from '@Underwriting/services';
import { Utils } from 'app/utils';
import { SubSink } from 'subsink';

@Component({
    selector: 'uw-decision',
    templateUrl: './uw-decision.component.html',
    styleUrls: ['./uw-decision.component.scss']
})
export default class UWDecisionComponent implements OnChanges, OnInit, OnDestroy {
    @Input() showViewDecision: boolean;
    @Input() uwLabelName: string;
    @Input() uwDecisionNote: UWDecisionNote;
    @Input() baseProps: UWBaseProps;
    @Input() labels: UWLabel[];

    @Output() onShowDecision = new EventEmitter<void>();
    @Output() onHideDecision = new EventEmitter<void>();
    @Output() onSaveDecision = new EventEmitter<UWDecisionPatch>();
    @Output() onSectionSaved = new EventEmitter<void>();

    subs = new SubSink();

    hideUpdateDecisionButtonDisabled = false;
    updateDecisionToggleEnabled = false;
    isDecisionButtonVisible = false;
    decisionSection = '';

    constructor(
        public dialog: MatDialog,
        protected underwritingService: UWDecisionServices,
        protected featureManagerService: FeatureManagerService,
        protected accountSettingsService: AccountSettingsService,
    ) { }

    //#region Helpers

    IsCaseStatusLocked = () => this.baseProps?.case?.statusId === CaseStatus.Cancelled || this.baseProps?.case?.statusId === CaseStatus.Done;

    updateDecisionButtonVisibility(): void {
        if (this.hideUpdateDecisionButtonDisabled) this.isDecisionButtonVisible = true;
        else if (this.IsCaseStatusLocked()) this.isDecisionButtonVisible = false;
        else if (this.baseProps?.readonly) this.isDecisionButtonVisible = false;
        else this.isDecisionButtonVisible = true;
    }

    //#endregion
    //#region Subscriptions

    subscribeToFeatureToggles() {
        this.subs.add(this.accountSettingsService.getAccountSetting(this.baseProps.account, AccountSettings.UWDecisionSection).subscribe(accountSetting => {
            if (!accountSetting) return;
            // Empty Guids should set the value to empty string
            if (accountSetting.value === Utils.emptyGuid) this.decisionSection = '';
            else this.decisionSection = accountSetting.value;
        }));

        this.subs.add(this.featureManagerService.isEnabled(FeatureToggle.CaseUnderwritersUpdateDecisionClient, this.baseProps.client.id).subscribe(isEnabled => {
            this.updateDecisionToggleEnabled = isEnabled;
            this.updateDecisionButtonVisibility();
        }));

        this.subs.add(this.featureManagerService.isEnabled(FeatureToggle.UWWorkbenchUpdateDecisionMultiUserClient, this.baseProps.client.id).subscribe(isEnabled => {
            this.hideUpdateDecisionButtonDisabled = isEnabled;
            this.updateDecisionButtonVisibility();
        }));
    }

    //#endregion
    //#region LifeCycle

    ngOnInit() {
        this.subscribeToFeatureToggles();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.baseProps) this.updateDecisionButtonVisibility();
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    //#endregion
    //#region Handlers

    handleOpenDecisionSection() {
        const sectionModal = this.dialog.open<InterviewSectionDialog, InterviewSectionDialogData, InterviewSectionDialogResponse>(InterviewSectionDialog, {
            width: '100%',
            minWidth: Utils.isDesktop() ? '55vw' : '90%',
            maxWidth: '55vw',
            maxHeight: '90vh',
            data: {
                title: 'Underwriting Decision',
                client: this.baseProps.client,
                account: this.baseProps.account,
                case: this.baseProps.case,
                sectionId: this.decisionSection,
                saveButtonLabel: 'Save Decision',
            },
            disableClose: true,
        });

        sectionModal.afterClosed().subscribe(response => {
            if (response.action == InterviewSectionDialogAction.Saved) {
                this.onSectionSaved.emit();
            }
        });
    }

    handleShowDecision() {
        this.onShowDecision.emit();
    }

    handleHideDecision() {
        this.onHideDecision.emit();
    }

    handleSaveDecision(uwDecision: UWDecisionPatch) {
        this.onSaveDecision.emit(uwDecision);
    }

    //#endregion
}
