import { Component, Input, OnChanges } from '@angular/core';
import { AppService } from 'app/app.service';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import * as _ from 'lodash';

import { Account, AccountIntegrationMapping } from '../../../models';
import { IntegrationMappingType } from '@Enums';
import { IntegrationService, NotificationSeverity } from '@Services';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'account-integration-view',
    host: {'class': 'd-flex flex-row flex-fill'},
    templateUrl: './account-integration-view.component.html',
    styleUrls: ['./account-integrations.component.scss']
})
export class AccountIntegrationComponent implements OnChanges {
    @Input() accountInfo: Account;
    @Input() rules;
    @Input() integration;
    @Input() secretKeys;

  integrationForm: UntypedFormGroup;
  metadata: any;
  showView = true;
  accountIntegration: any;
  updatedAccountIntegration: any;
  selectedTabIndex: any;
  integrationEnabled = false;
  addMapping = false;

    constructor(
        public appService: AppService,
        public _fb: UntypedFormBuilder,
        public integrationService: IntegrationService,
        public dialog: MatDialog
    ) {
        this.updatedAccountIntegration = null;
        this.integrationForm = this._fb.group({
            accountIntegrations: this._fb.array([])
        });
    }

    ngOnChanges() {
        this.showView = true;
        this.getIntegrationMetadata();
    }

    setupForm() {
        this.integrationForm = this._fb.group({
            accountIntegrations: this._fb.array([])
        });

        if (!this.metadata || !this.metadata.environments) {
            this.getIntegrationMetadata();
            return;
        }

        const control = <UntypedFormArray>this.integrationForm.get('accountIntegrations');
        this.metadata.environments.forEach(environment => {
            let accountIntegration = this.integration.accountIntegrations.find(x => x.environmentId === environment.id);

            if (!accountIntegration) {
                accountIntegration = this.integration.accountIntegrations.find(x => x.requestUrl === environment.url);
            }

            control.push(this.createFormGroup(accountIntegration, environment));
        });

        if (!this.integration.accountIntegrations || this.integration.accountIntegrations.length === 0) {
            this.showView = false;
        }
    }

    createFormGroup(accountIntegration, environment) {
        if (accountIntegration) {
            return this._fb.group({
                id: accountIntegration.id,
                accountId: [this.accountInfo.id],
                clientId: [this.accountInfo.clientId],
                integrationId: [this.integration.id],
                username: [accountIntegration.username],
                password: [accountIntegration.password],
                timeoutSeconds: [accountIntegration.timeoutSeconds],
                expirationHours: [accountIntegration.expirationHours],
                maxAttempts: [accountIntegration.maxAttempts],
                environmentId: [environment.id],
                isActive: [accountIntegration.isActive]
            });
        } else {
            return this._fb.group({
                accountId: [this.accountInfo.id],
                clientId: [this.accountInfo.clientId],
                integrationId: [this.integration.id],
                username: [''],
                password: [''],
                timeoutSeconds: ['600'],
                expirationHours: ['1'],
                maxAttempts: ['5'],
                environmentId: [environment.id],
                isActive: ['false']
            });
        }
    }

    getIntegrationMetadata() {
        if (this.integration.version == 2 && this.integration.id) {
            this.integrationService.getIntegrationMetadata(this.integration.id).subscribe(
                data => {
                    this.metadata = data;
                    this.getActiveAccountIntegration();
                    this.setupForm();
                });
        }
    }

    checkACL(permissionType, redirect?) {
        return this.appService.checkACL('Accounts', permissionType, redirect);
    }

    getActiveAccountIntegration() {
        const activeIntegration = this.integration.accountIntegrations.find(x => x.isActive);
        if (!activeIntegration) {
            this.selectedTabIndex = 0;
            this.accountIntegration = this.integration.accountIntegrations.find(x => x.environmentId == 1);
            this.integrationEnabled = false;
            return;
        } else {
            if (activeIntegration.environmentId !== 0) {
                this.selectedTabIndex = (activeIntegration.environmentId - 1);
            } else {
                const env = this.metadata.environments.find(x => x.url === activeIntegration.requestUrl);
                this.selectedTabIndex = (env.id - 1);
            }
            this.integrationEnabled = true;
        }

        this.accountIntegration = activeIntegration;
    }

    isEnvironmentActive(environmentId) {
        const accountIntegration = this.integration.accountIntegrations.find(x => x.environmentId === environmentId);
        if (accountIntegration && accountIntegration.isActive) {
            return true;
        }

        return false;
    }

    getAccountIntegration(index) {
        const environment = this.metadata.environments[index];

        this.accountIntegration = this.integration.accountIntegrations.find(x => x.environmentId === environment.id);

        if (!this.accountIntegration) {
            this.accountIntegration = this.integration.accountIntegrations.find(x => x.requestUrl === environment.url && x.environmentId === 0);
        }
    }

    getSecretKeyLabel(id) {
        if (id && this.secretKeys && this.secretKeys.length) {
            const secretKey = _.find(this.secretKeys, ['id', id]);
            if (secretKey && secretKey.name) {
                return secretKey.name;
            } else {
                return '';
            }
        } else {
            return '';
        }
    }

    activateEnvironment(accountIntegration) {
        this.integrationService.activateAccountIntegration(this.accountInfo, accountIntegration.integrationId, accountIntegration.id).subscribe(response => {
            this.appService.showMsg(NotificationSeverity.Success, 'Environment activated successfully.');
            this.integration.accountIntegrations = response;
            this.getActiveAccountIntegration();
            this.setupForm();
        });
    }

    edit() {
        this.showView = false;
    }

    cancel() {
        this.showView = true;
        this.updatedAccountIntegration = null;
    }

    toggleIntegration($event) {
        this.integrationService.toggleIntegration(this.accountInfo, this.integration.id, $event.checked).subscribe(response => {
            if ($event.checked) {
                this.appService.showMsg(NotificationSeverity.Success, 'Integration activated successfully.');
            } else {
                this.appService.showMsg(NotificationSeverity.Success, 'Integration deactivated successfully.');
            }

            this.integration.accountIntegrations = response;
            this.getActiveAccountIntegration();
            this.setupForm();
        });
    }

    saveForm() {
        if (this.integrationForm.valid) {
            const accountIntegrations = this.integrationForm.value.accountIntegrations;

            accountIntegrations.forEach(ai => {
                this.buildIntegrationMapping(ai);

                if (ai.id && ai.id !== null && ai.accountIntegrationMappings.length === 0) {
                    const accountIntegration = this.integration.accountIntegrations.find(x => x.id === ai.id);
                    ai.accountIntegrationMappings = accountIntegration.accountIntegrationMappings;
                }
            });

            this.integrationService.saveAccountIntegration(this.accountInfo, accountIntegrations).subscribe(response => {
                this.appService.showMsg(NotificationSeverity.Success, 'Integration saved successfully.');
                this.showView = true;
                this.integration.accountIntegrations = response;
                this.getActiveAccountIntegration();
                this.setupForm();
            });
        } else {
            this.appService.showMsg(NotificationSeverity.Error, 'Check the environment tabs for any errors.');
        }
        this.updatedAccountIntegration = null;
    }

    buildIntegrationMapping(formVal) {
        const mappings: AccountIntegrationMapping[] = [];
        if (formVal.response) {
            const responseMapping: AccountIntegrationMapping = {
                accountIntegrationid: formVal.id,
                mapKeyId: formVal.response,
                mappingtypeId: IntegrationMappingType.Response,
                metadataId: 0
            };
            mappings.push(responseMapping);
        }

        if (formVal.inputParams) {
            formVal.inputParams.forEach(element => {
                const inputMapping: AccountIntegrationMapping = {
                    accountIntegrationid: formVal.id,
                    mapKeyId: element.mapKey_Id,
                    mappingtypeId: IntegrationMappingType.Input,
                    metadataId: element.metadata_Id
                };

                mappings.push(inputMapping);
            });
        }

        if (formVal.outputParams) {
            formVal.outputParams.forEach(element => {
                const outputMapping: AccountIntegrationMapping = {
                    accountIntegrationid: formVal.id,
                    mapKeyId: element.mapKey_Id,
                    mappingtypeId: IntegrationMappingType.Output,
                    metadataId: element.metadata_Id
                };

                mappings.push(outputMapping);
            });
        }

        formVal.accountIntegrationMappings = mappings;
    }

    addIntegrationMapping(templateRef: any, formGroup, accountIntegration) {
        this.dialog.open(templateRef,
            {
                width: '800px',
                maxHeight: '90%',
                data: {
                    integrationForm: formGroup,
                    selectedIntegration: this.integration,
                    accountIntegration: this.updatedAccountIntegration || this.accountIntegration
                }
            });
    }

    closeMapping() {
        if (this.integrationForm.valid) {
            const accountIntegrations = this.integrationForm.value.accountIntegrations;

            accountIntegrations.forEach(ai => {
                this.buildIntegrationMapping(ai);

                if (ai.id && ai.id !== null && ai.accountIntegrationMappings.length === 0) {
                    const accountIntegration = this.integration.accountIntegrations.find(x => x.id === ai.id);
                    ai.accountIntegrationMapping = accountIntegration.accountIntegrationMapping;
                }
            });

            const currentIntegration = accountIntegrations.find(x => x.environmentId === this.accountIntegration.environmentId);
            const currentUpdatedIntegrationMappings = currentIntegration.accountIntegrationMappings;
            this.updatedAccountIntegration = JSON.parse(JSON.stringify(this.accountIntegration));

            this.updatedAccountIntegration.accountIntegrationMappings.forEach(aim => {
                const updatedAIM = currentUpdatedIntegrationMappings.find(x => x.metadataId === aim.metadataId && x.mappingtypeId === aim.mappingTypeId);
                aim.mapKeyId = updatedAIM.mapKeyId;
            });
        }
        this.dialog.closeAll();
    }

    showHmacCreds(environment: any) {
        return `${environment?.parameters?.authType}`.toLowerCase() != 'oauth2';
    }
}
