import { Component, OnInit, Input} from '@angular/core';
import { AppService } from 'app/app.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { RoutesEnum } from 'app/services';
import { Account, MapkeyConfig, LookupItem, Output } from 'app/models';
import { MapKeyType, MapKeyExpressionType } from 'app/enums';

@Component({
    selector: 'expression-builder',
    host: { 'class': 'd-flex flex-row flex-fill' },
    templateUrl: './expression-builder.component.html',
    styleUrls: ['./expression-builder.component.scss']
})

export class ExpressionBuilderComponent implements OnInit {
    @Input() accountInfo: Account;
    @Input() mapKeyForm: UntypedFormGroup = new UntypedFormGroup({});
    @Input() caseMapKeys: MapkeyConfig[] = [];
    @Input() rules: any;

    metadata: any = [];
    responseMapKeys: any = [];
    integrationRules: any = [];
    outputProperties: Output[] = [];
    mapKey: MapkeyConfig;
    mapKeyExpressionTypes: LookupItem[] = [
        { id: MapKeyExpressionType.MatchedRuleResult,
            name: 'Matched Rule Result'},
        { id: MapKeyExpressionType.RuleResult,
            name: 'Rule Result'}
    ];

    matchedRuleResultType = MapKeyExpressionType.MatchedRuleResult;
    ruleResultType = MapKeyExpressionType.RuleResult;

    constructor(
        private appService: AppService    ) {
        this.checkACL('R', RoutesEnum.dashboard);
    }

    ngOnInit() {
        this.getIntegrationResponseMapKeys();

        this.mapKey = this.mapKeyForm.value;
        this.mapKeyForm.addControl('expressionId', new UntypedFormControl(''));

        if (this.mapKey && this.mapKey.id !== '' && this.mapKey.virtualExpression != null) {
            if (this.mapKey.virtualExpression.startsWith('MatchedRuleResult')) {
                this.setupMatchedRuleResultForm(false);
            } else {
                this.setupRuleResultForm(false);
            }
        }
    }

    setupMatchedRuleResultForm(isNew: boolean) {
        this.mapKeyForm.addControl('responseMapKeyId', new UntypedFormControl('', Validators.required));
        this.mapKeyForm.addControl('ruleId', new UntypedFormControl('', Validators.required));
        this.mapKeyForm.addControl('propertyId', new UntypedFormControl('', Validators.required));
        this.mapKeyForm.addControl('ruleResultsDelimiter', new UntypedFormControl(''));        
        this.mapKeyForm.addControl('allValues', new UntypedFormControl(''));
        this.mapKeyForm.controls['expressionId'].setValue(MapKeyExpressionType.MatchedRuleResult);

        if (isNew) {
            this.mapKeyForm.controls['propertyId'].setValue('');
            this.mapKeyForm.controls['responseMapKeyId'].setValue('');
            this.mapKeyForm.controls['ruleId'].setValue('');
            this.mapKeyForm.controls['ruleResultsDelimiter'].setValue('');
            this.mapKeyForm.controls['allValues'].setValue('');
        } else {
            this.parseExpressionBuilderMatchedRuleResult(this.mapKey);
            this.mapKeyForm.controls['propertyId'].setValue(this.mapKey.propertyId);
            this.mapKeyForm.controls['responseMapKeyId'].setValue(this.mapKey.responseMapKeyId);
            this.mapKeyForm.controls['ruleId'].setValue(this.mapKey.ruleId);
            this.mapKeyForm.controls['ruleResultsDelimiter'].setValue(this.mapKey.delimiter);
            this.mapKeyForm.controls['allValues'].setValue(this.mapKey.allValues);
        }

        if (this.mapKey.responseMapKeyId && this.mapKey.responseMapKeyId != null) {
            this.getRules(this.mapKey.responseMapKeyId);
        }

        if (this.mapKey.ruleId && this.mapKey.ruleId != null) {
            this.getMatchedProperties(this.mapKey.ruleId);
        }
    }

    setupRuleResultForm(isNew: boolean) {
        this.mapKeyForm.addControl('ruleId', new UntypedFormControl('', Validators.required));
        this.mapKeyForm.controls['expressionId'].setValue(MapKeyExpressionType.RuleResult);

        //In case "Mated Rule Result" was selected previously
        this.mapKeyForm.removeControl('propertyId');
        this.mapKeyForm.removeControl('responseMapKeyId');
        this.mapKeyForm.removeControl('ruleResultsDelimiter');
        this.mapKeyForm.removeControl('allValues');
        if (isNew) {
            this.mapKeyForm.controls['ruleId'].setValue('');
        } else {
            this.parseExpressionBuilderRuleResult(this.mapKey);
            this.mapKeyForm.controls['ruleId'].setValue(this.mapKey.ruleId);
        }
    }

    parseExpressionBuilderRuleResult(mapKey) {
        let virtualExpression = mapKey.virtualExpression;
        virtualExpression = virtualExpression.replace('RuleResult(', '');
        virtualExpression = virtualExpression.replace(')', '');
        virtualExpression = virtualExpression.replace(/'/g, '');
        mapKey.ruleId = virtualExpression;
    }

    parseExpressionBuilderMatchedRuleResult(mapKey) {
        let virtualExpression = mapKey.virtualExpression;
        virtualExpression = virtualExpression.replace('MatchedRuleResult(', '');
        virtualExpression = virtualExpression.replace(')', '');

        const props = virtualExpression.match(/('.*?'|[^',\s]+)(?=\s*,|\s*$)/g);

        const responseMapKey = props[0];
        mapKey.responseMapKeyId = responseMapKey.substring(1, responseMapKey.length - 1);

        const rule = props[1];
        mapKey.ruleId = rule.substring(1, rule.length - 1);

        const property = props[2];
        mapKey.propertyId = parseInt(property.substring(1, property.length - 1));

        const d = props[3];
        mapKey.delimiter = d.substring(1, d.length - 1);
        
        if (props.length > 4){
            const allValues = props[4];
            const allValueString = allValues.substring(1, allValues.length - 1).toLowerCase();
            mapKey.allValues = allValueString === 'true' ? true : false;
        }
        mapKey.expressionId = MapKeyExpressionType.MatchedRuleResult;
    }

    getIntegrationResponseMapKeys() {
        this.appService.getData(`MapKeys/${this.accountInfo.id}/NonConstantMapKeys`).subscribe(result => {
            if (result.status === 'success') {
                this.caseMapKeys = result.data;

                this.caseMapKeys.forEach(element => {
                    if (element.mapKeyTypeId === MapKeyType.Integration) {
                        this.responseMapKeys.push({ listMapKeysId: element.id, name: element.name, listMapKeyName: element.entityHierarchy });
                    }
                });
            }
        });
    }

    expressionChange(option: MatSelectChange) {
        if (option.value === MapKeyExpressionType.MatchedRuleResult) {
            this.setupMatchedRuleResultForm(true);
        } else if (option.value === MapKeyExpressionType.RuleResult) {
            this.setupRuleResultForm(true);
        }
    }

    responseMapKeyChange(option: MatSelectChange) {
        this.outputProperties = [];
        this.getRules(option.value);
    }

    getRules(integrationResponseId) {
        this.appService.getData(`Rules/${this.accountInfo.id}/matchedresults/${integrationResponseId}`).subscribe(result => {
            if (result.status === 'success') {
                this.integrationRules = result.data;
            }
        });
    }

    ruleChange(option: MatSelectChange) {
        this.getMatchedProperties(option.value);
    }

    getMatchedProperties(ruleId) {
        this.appService.getData(`Rules/${this.accountInfo.id}/matchedresultsproperties/${ruleId}`).subscribe(result => {
            if (result.status === 'success') {
                this.outputProperties = result.data;
            }
        });
    }

    checkACL(permissionType, redirect?) {
        return this.appService.checkACL('Accounts', permissionType, redirect);
    }
}
