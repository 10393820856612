import { DocType } from "@Enums";
import { CaseDocumentsDTO } from "@Models/domain/dto";


export function getFilenameExtension(filename: string) {
    const parts = filename.split('.');
    if (parts.length == 1) return ''; // no extension

    const extension = (filename.split('.').pop())?.toLowerCase() || '';
    return extension;
}

export function isAllowedDocumentExtensions(filenameOrExtension: string) {
    const allowedExtensions = { 'pdf': true, 'tiff': true, 'tif': true, };

    // This allows us to accept the filename (eg. `test.jpg`) or the extension (eg. `jpg` or even `.jpg`)
    const ext = getFilenameExtension(filenameOrExtension) || filenameOrExtension.toLowerCase();

    return Boolean(allowedExtensions[ext]);
}

export function isAllowedEvidenceExtensions(filenameOrExtension: string) {
    const allowedExtensions = { 'pdf': true, 'tiff': true, 'tif': true, 'png': true, 'jpg': true, 'jpeg': true };
    const ext = getFilenameExtension(filenameOrExtension) || filenameOrExtension.toLowerCase();
    return Boolean(allowedExtensions[ext]);
}

export function getFileTypeFromExtension(filename: string) {
    const jsonExts = ['json'];
    const pdfExts = ['pdf'];
    const tifExts = ['tif', 'tiff'];
    const xmlExts = ['xml'];
    const htmlExts = ['htm', 'html'];
    const xsltExts = ['xslt'];
    const xsdExts = ['xsd'];
    const textExts = ['txt'];
    const imgExts = ['gif', 'jpg', 'jpeg', 'png'];
    const audioExts = ['wav', 'mp3'];

    const extension = getFilenameExtension(filename);

    if (jsonExts.includes(extension)) return DocType.JSON;
    if (pdfExts.includes(extension)) return DocType.PDF;
    if (tifExts.includes(extension)) return DocType.TIFF;
    if (xmlExts.includes(extension)) return DocType.XML;
    if (htmlExts.includes(extension)) return DocType.HTML;
    if (xsltExts.includes(extension)) return DocType.XSLT;
    if (xsdExts.includes(extension)) return DocType.XSD;
    if (textExts.includes(extension)) return DocType.TEXT;
    if (imgExts.includes(extension)) return DocType.Image;
    if (audioExts.includes(extension)) return DocType.Audio;

    return DocType.Unknown;
}

export function validFileType(fileType: string) {
    const isDefined = Object.values(DocType).includes(fileType as DocType);

    return isDefined && fileType != DocType.Unknown;
}

export function isTiff(caseDocument: CaseDocumentsDTO) {
    if (caseDocument.generateTo === DocType.TIFF) return true;
    if (caseDocument.doctypeId === DocType.TIFF) return true;

    const fileTypeExt = getFileTypeFromExtension(caseDocument.remoteName);
    return fileTypeExt === DocType.TIFF;
}
