import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { QuestionsDTO } from '@DTOs';
import { AnswerTypes } from '@Enums';
import { Output as MetadataOutput } from '@Models';
import { IntegrationService, MapKeyService } from '@Services';


export interface ConstantKeyVirtualExpression {
    integrationResponseMapkey: string;
    displayNameFormat: string;
    valueFormat: string;
}

export interface PrefillSelector {
    value: string;
    display: string;
}

export interface PrefillObjectPropertySettings {
    fromQuestion: string;
    populateWith: string;
}

@Component({
    selector: 'prefill-answer',
    templateUrl: './prefill-answer.component.html',
    styleUrls: ['./prefill-answer.component.scss']
})
export class PrefillAnswerComponent implements OnInit, OnChanges {
    @Input() questions: QuestionsDTO[] = [];
    @Input() fromQuestion = '';
    @Input() populateWith = '';
    @Input() excludeQuestions: string[] = [];
    @Output() fromQuestionSelected = new EventEmitter<string>();
    @Output() prefillSelected = new EventEmitter<string>();

    prefillFormGroup: UntypedFormGroup;
    prefillOptions: PrefillSelector[] = [];
    filteredQuestions: QuestionsDTO[];

    constructor(
        private _fb: UntypedFormBuilder,
        private mapkeyService: MapKeyService,
        private integrationService: IntegrationService) {
        this.prefillFormGroup = this._fb.group({
            fromQuestion: [this.fromQuestion],
            populateWith: [this.populateWith, [Validators.required]]
        });
        this.prefillFormGroup.get('populateWith').disable();
        this.prefillFormGroup.get('populateWith').valueChanges.subscribe(value => {
            this.prefillSelected.emit(value);
        });
        this.prefillFormGroup.get('fromQuestion').valueChanges.subscribe(value => {
            if (value && value !== '') {
                this.prefillFormGroup.get('populateWith').enable();
                this.getPrefillValues(value);
            } else {
                this.prefillFormGroup.get('populateWith').setValue('');
                this.prefillFormGroup.get('populateWith').disable();
            }
            this.fromQuestionSelected.emit(value);
        });
    }

    ngOnInit() {
        this.filterQuestions();
        //If the questionFrom is set then we update the prefillFormGroup with the questionFrom value
        if (this.fromQuestion) {
            this.prefillFormGroup.get('fromQuestion').setValue(this.fromQuestion);
            this.fromQuestionChanged(new MatSelectChange(null, this.fromQuestion));
            //If the populateFrom is set then we update the prefillFormGroup with the populateFrom value
            if (this.populateWith) this.prefillFormGroup.get('populateWith').setValue(this.populateWith);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.fromQuestion && changes.fromQuestion.currentValue !== changes.fromQuestion.previousValue)
            this.prefillFormGroup.get('fromQuestion').setValue(changes.fromQuestion.currentValue);
        if (changes.populateWith && changes.populateWith.currentValue !== changes.populateWith.previousValue)
            this.prefillFormGroup.get('populateWith').setValue(changes.populateWith.currentValue);
        if (Object.values(changes).every(change => !change.isFirstChange()))
            this.filterQuestions();
    }

    filterQuestions() {
        this.filteredQuestions = this.questions.filter(question =>
            this.mapkeyService.dynamicListMapkeys.find(dynamicListMapkey =>
                question.multiChoiceAnswers &&
                question.answerTypeId === AnswerTypes.MultiChoiceSingleAnswer &&
                question.multiChoiceAnswers.find(multiChoiceAnswer => multiChoiceAnswer.constantKey === dynamicListMapkey.id)
            )
        ).filter(question => !this.excludeQuestions.includes(question.id));
        if (this.filteredQuestions && this.filteredQuestions.length === 0) {
            this.prefillFormGroup.get('fromQuestion')?.setValue('');
            this.prefillFormGroup.disable();
        } else {
            this.prefillFormGroup.get('fromQuestion')?.enable();
        }
    }

    fromQuestionChanged($event) {
        if ($event.value === null || $event.value == '') {
            if ($event.value === null)
                this.prefillFormGroup.disable();
            this.prefillFormGroup.get('fromQuestion').setValue('');
            return;//Don't do anything when the user selects the empty option
        }
        this.getPrefillValues($event.value);
    }

    getPrefillValues(questionId: string) {
        const selectedQuestion = this.filteredQuestions?.find(question => question.id === questionId);
        if (!selectedQuestion) return;
        const multiChoiceAnswer = selectedQuestion.multiChoiceAnswers[0];
        this.fromQuestionSelected.emit(multiChoiceAnswer.questionId);

        const dynamicListMapkey = this.mapkeyService.dynamicListMapkeys.find(d => d.id === multiChoiceAnswer.constantKey);
        const integrationResponseMapkey = JSON.parse(dynamicListMapkey.virtualExpression) as ConstantKeyVirtualExpression;
        const firstDynamicReplacement = integrationResponseMapkey.displayNameFormat.split('{{')[1].split('}}')[0];
        const integrationId = this.mapkeyService.mapKeys.find(m => m.id === integrationResponseMapkey.integrationResponseMapkey).integrationId;
        this.integrationService.getIntegrationMetadata(integrationId).subscribe((metadata) => {

            this.prefillOptions = this.getUniquePrefillSelectors(metadata.output, metadata.output.find(x => x.property === firstDynamicReplacement).parentPath).sort((a, b) => a.display.localeCompare(b.display));
            if (this.prefillOptions.length === 0) this.prefillFormGroup.get('populateWith').disable();
        });

    }
    getUniquePrefillSelectors(outputs: MetadataOutput[], parentPathFilter: string): PrefillSelector[] {
        const prefillSelectorsSet = new Set<PrefillSelector>();

        for (const output of outputs.filter(output => output.parentPath === parentPathFilter)) {
            if (output.selector) {
                prefillSelectorsSet.add({
                    value: output.id.toString(),
                    display: output.property
                });
            }
        }

        return Array.from(prefillSelectorsSet);
    }

    isFormValid(): boolean {
        this.prefillFormGroup.markAllAsTouched();
        return this.prefillFormGroup.valid || this.prefillFormGroup.disabled;
    }
}
