import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { AppService } from '@App';
import { AccountSettingsDTO, SectionDTO } from '@DTOs';
import { AccountSettings } from '@Enums';
import { Account, Client, UWResponse } from '@Models';
import { AccountSettingsDataService, SectionDataService } from '@Services';
import { TimeUtils, Utils } from '@Utils';
import { catchError, map, of } from 'rxjs';
import { SubSink } from 'subsink';

@Component({
    selector: 'workbench-decision-config',
    templateUrl: './workbench-decision-config.component.html',
    styleUrls: ['workbench-decision-config.component.scss'],
})
export default class WorkbenchDecisionConfigComponent implements OnInit, OnDestroy {
    @Input() client: Client;
    @Input() account: Account;

    decisionForm: UntypedFormGroup;

    initialSectionId = '';
    noSection: SectionDTO = { id: '00000000-0000-0000-0000-000000000000', name: 'None', header: '', display: true, description: '', restartScript: '', isMasked: false, referenceId: '', objectProperties: [], questions: [], sectionActions: [], sectionReflexives: [] };
    sections: SectionDTO[] = [];
    sectionsLoaded = false;

    accountSettings: AccountSettingsDTO[] = [];
    accountSettingsLoaded = false;
    lastUpdated: Date;

    errorMessage = '';

    subs = new SubSink();

    protected get AccountSettings() { return AccountSettings; } // Make enums available in template

    constructor(
        private formBuilder: UntypedFormBuilder,
        private appService: AppService,
        private sectionDataService: SectionDataService,
        private accountSettingsDataService: AccountSettingsDataService,
    ) {
    }

    //#region Helpers

    isValid() {
        if (!this.decisionForm.valid)
            return false;

        const selectedSection = this.decisionForm.get(AccountSettings.UWDecisionSection)?.value;

        if (Utils.isNullOrWhitespace(selectedSection?.id))
            return false;

        return true;
    }

    getAccountSetting() {
        const uwDecisionSectionSetting = this.accountSettings?.filter(x => x.name === AccountSettings.UWDecisionSection);

        if (!uwDecisionSectionSetting?.length) {
            return null;
        }

        if (uwDecisionSectionSetting?.length > 1) {
            console.error(`Multiple account settings found. [${AccountSettings.UWDecisionSection}]`);
            return null;
        }

        const accountSetting = uwDecisionSectionSetting[0];
        return accountSetting;
    }

    setDecisionForm() {
        const uwDecisionSectionSetting = this.getAccountSetting();
        const initialSection = this.sections.find(x => x.id === uwDecisionSectionSetting?.value);

        this.decisionForm.get(AccountSettings.UWDecisionSection).setValue(initialSection ?? this.noSection);
        this.initialSectionId = initialSection?.id ?? this.noSection.id;

        this.lastUpdated = TimeUtils.convertToDateCst(uwDecisionSectionSetting?.lastModifiedDate ?? uwDecisionSectionSetting?.creationDate);
    }

    //#endregion
    //#region Subscriptions

    subscribeToSections() {
        this.subs.add(this.sectionDataService.loadSectionsForWorkflow(this.account.workflowId).subscribe(sections => {
            this.sections = [this.noSection, ...sections];
            this.setDecisionForm();
            this.sectionsLoaded = true;
        }));
    }

    subscribeToAccountSettings() {
        this.subs.add(this.accountSettingsDataService.loadAccountSettings(this.account.id).subscribe(accountSettings => {
            this.accountSettings = accountSettings;
            this.setDecisionForm();
            this.accountSettingsLoaded = true;
        }));
    }

    //#endregion
    //#region Lifecycle

    ngOnInit() {
        this.decisionForm = this.formBuilder.group({ [AccountSettings.UWDecisionSection]: null, });
        this.subscribeToSections();
        this.subscribeToAccountSettings();
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    //#endregion
    //#region Handlers

    handleChange(change: MatSelectChange) {
        if (this.initialSectionId === change.value.id) return;
        this.save().subscribe(); // "Auto-Save"
    }

    save() {
        if (!this.isValid())
            return of(false);

        const existingAccountSetting = this.accountSettings.find(x => x.name === AccountSettings.UWDecisionSection);
        const selectedSection = this.decisionForm.get(AccountSettings.UWDecisionSection)?.value;

        const updateAccountSetting: AccountSettingsDTO = {
            'id': existingAccountSetting?.id ?? Utils.emptyGuid,
            'accountId': this.account.id,
            'name': AccountSettings.UWDecisionSection,
            'value': selectedSection.id,
        };

        const saveSettings$ = this.appService.postData<UWResponse<AccountSettingsDTO[]>>('AccountSettings', [updateAccountSetting]).pipe(
            map(_response => {
                // We don't use the response. We'll reload our observable so all subscribers will update.
                this.accountSettingsDataService.reloadAccountSettings(this.account.id).subscribe();
                this.initialSectionId = selectedSection.id;

                return true;
            }),
            catchError((err: Error) => {
                this.errorMessage = err.message;
                return of(false);
            }),
        );

        return saveSettings$;
    }

    //#endregion
}