import { AccountMinimalEnhanced, AccountRule, AccountRuleChange, AccountRuleForm, AuditCaseEnhanced, AuditCasesGroup, AuditEnhanced, AuditGroupByTypes, AuditSaveDialog, AuditUtils, AuditViewStatusFilters, EditAuditRequest } from "@Audit";
import { Client } from "@Models";
import { AuditService, ClientContextService, NotificationService, NotificationSeverity } from "@Services";
import { TimeUtils, Utils } from "@Utils";
import { Component, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";
import { forkJoin, switchMap, take } from 'rxjs';
import { MatDialog } from "@angular/material/dialog";

export type EditAuditForm = {
    name: FormControl<string>;
    auditGoalPercentage: FormControl<number>;
    accountRules: FormArray<FormGroup<AccountRuleForm>>;
};

@Component({
    selector: 'audit-edit-page',
    templateUrl: './audit-edit-page.component.html',
    styleUrls: ['./audit-edit-page.component.scss']
})
export default class AuditEditPageComponent implements OnInit {

    error_nameRequired = 'An audit name is required.';
    error_invalidForm = 'Audit configuration is invalid. Check your values.';
    error_invalidGoal = 'Valid values are [0-100].';

    auditForm: FormGroup<EditAuditForm> = this._fb.group({
        name: ['', Validators.required],
        auditGoalPercentage: [0, Validators.pattern(/^(?:100|[1-9]?\d)$/)],
        accountRules: this._fb.array<FormGroup<AccountRuleForm>>([]),
    });

    clients: Client[] = [];
    client: Client = null;
    clientsLoaded = false;

    audit: AuditEnhanced;
    auditCases: AuditCaseEnhanced[] = [];
    auditCasesGroups: AuditCasesGroup[] = [];
    auditLoaded = false;

    startDateDisplay = '';
    endDateDisplay = '';

    // Account Data
    loadingSelectedClientAccounts = false;
    activeAccountsForSelectedClient: AccountMinimalEnhanced[] = null; // The active accounts for the `selectedClient` & time range
    
    // Preview
    loadingCases = false;
    casesLoaded = false;
    isAuditGroupingEnabled = false;
    selectedAuditGroupByType = AuditGroupByTypes.None;

    constructor(
        private _fb: FormBuilder,
        private _route: ActivatedRoute,
        private _clientContextService: ClientContextService,
        private _auditService: AuditService,
        private _notificationService: NotificationService,
        private _location: Location,
        private _dialog: MatDialog
    ) { }

    //#region Helpers

    createAuditEditRequest(): EditAuditRequest {

        const name = this.auditForm.controls.name.value;
        const accountRules = this.auditForm.controls.accountRules.value as AccountRule[];
        const auditGoal = (this.auditForm.controls.auditGoalPercentage.value) ? this.auditForm.controls.auditGoalPercentage.value : 0;

        const request: EditAuditRequest = {
            id: this.audit.id,
            name,
            accountRules,
            goal: auditGoal
        };

        return request;
    }

    clearResults() {
        this.auditCases = [];
        this.auditCasesGroups = [];
        this.casesLoaded = false;
    }

    getActiveAccounts(clientId: string, auditId: string) {
        this.loadingSelectedClientAccounts = true;

        this._auditService.getActiveAccountsFromLastAuditEdit(clientId, auditId).subscribe(activeAccounts => {
            this.loadingSelectedClientAccounts = false;
            this.activeAccountsForSelectedClient = activeAccounts.map((account) => {
                return {...account, activationDateDisplay:TimeUtils.formatDay(account.activationDate, 'M/D/YYYY') };
            });
        });
    }

    //#endregion

    //#region Lifecycle

    async ngOnInit() {
        await this._clientContextService.ready;

        forkJoin({
            clients: this._clientContextService.clients$.pipe(take(1)),
            params: this._route.params.pipe(take(1)),
        }).pipe(
            switchMap(({ clients, params }) => {
                const clientCode = params['clientCode'];
                const auditId = params['auditId'];

                this.clients = clients;
                this.client = this.clients.find(x => x.code === clientCode);
                this.clientsLoaded = true;

                return forkJoin({
                    audit: this._auditService.getAudit(this.client.id, auditId)
                });
            }),
        ).subscribe(({ audit }) => {
            this.audit = {
                ...audit,
                startDate: TimeUtils.convertToDateCst(audit.startDateUtc),
                endDate: TimeUtils.convertToDateCst(audit.endDateUtc),
                lastRefreshedOnDay: TimeUtils.formatDay(audit.lastRefreshedOn, 'M/D/YYYY'),
                lastRefreshedOnTime: TimeUtils.formatTime(audit.lastRefreshedOn),
            };
            
            this.auditForm.controls.name.setValue(this.audit.name);
            this.auditForm.controls.auditGoalPercentage.setValue(this.audit.goal);

            this.startDateDisplay = TimeUtils.formatDay(this.audit.startDate);
            this.endDateDisplay = TimeUtils.formatDay(this.audit.endDate);

            this.isAuditGroupingEnabled = this.audit.groupBy !== AuditGroupByTypes.None;
            this.selectedAuditGroupByType = this.audit.groupBy;

            this.getActiveAccounts(this.audit.clientId, this.audit.id);

            this.auditLoaded = true;
        });

    }

    //#endregion
    
    //#region Handlers

    onPreview() {
        this.loadingCases = true;

        const request = this.createAuditEditRequest();
        const client = this.client;
        
        this._auditService.getAuditEditPreview(request, this.client.id).subscribe(auditPreview => {
            this.auditCases = auditPreview.auditCases.map((auditCase): AuditCaseEnhanced => {
                const account = this.activeAccountsForSelectedClient.find(x => x.id === auditCase.accountId);
                return { ...auditCase, client, account };
            });

            if (this.isAuditGroupingEnabled)
                this.auditCasesGroups = AuditUtils.filterSortAndGroupAuditCases(this.auditCases, this.audit.groupBy, AuditViewStatusFilters.All);

            this.loadingCases = false;
            this.casesLoaded = true;
        });
    }

    onSubmit() {
        if (this.auditForm.controls.name.invalid) {
            this._notificationService.showNotification({ severity: NotificationSeverity.Error, message: this.error_nameRequired });
            return;
        }
        else if (this.auditForm.controls.auditGoalPercentage.invalid) {
            this._notificationService.showNotification({ severity: NotificationSeverity.Error, message: this.error_invalidGoal});
            return;
        }
        else if (this.auditForm.invalid) {
            this._notificationService.showNotification({ severity: NotificationSeverity.Error, message: this.error_invalidForm });
            return;
        }

        const dialogRef = this._dialog.open(AuditSaveDialog, { 
            data: {
                title: 'Save Changes?',
                content: 'Rules on existing accounts or versions cannot be modified once saved.',
                confirmButtonLabel: 'Yes, Save Changes'
            }
        });

        console.log(dialogRef);

        dialogRef.afterClosed().subscribe(shouldSaveAudit => {
            if (shouldSaveAudit) {
                this.loadingCases = true;
                const client = this.client;
                const auditEditRequest = this.createAuditEditRequest();
                const hideWaiting = false;
                const forceRefresh = true;
        
                this._auditService.editAudit(auditEditRequest, this.client.id).pipe(
                    switchMap(_audit => this._auditService.getAudits(client.id, hideWaiting, forceRefresh)), // Not sure why i'm busting the cache here
                ).subscribe(_audits => {
                    this.loadingCases = false;
                    this.casesLoaded = true;
        
                    this.goBack();
                });
            }
        });
    }

    goBack() {
        this._location.back();
    }

    handleRuleChange(change: AccountRuleChange) {
        const index = this.auditForm.controls.accountRules.controls.findIndex(formGroup =>
            formGroup.controls.accountId.value === change.accountId
        );

        // Update the existing form item
        if (index !== -1) {
            // Remove the item if it's empty
            if (change.ruleId === Utils.emptyGuid) {
                this.auditForm.controls.accountRules.removeAt(index);
            }
            else {
                const accountRuleGroup = this.auditForm.controls.accountRules.at(index);
                accountRuleGroup.controls.ruleId.setValue(change.ruleId);
            }
        }
        // Create the form item
        else {
            const formGroup: FormGroup<AccountRuleForm> = this._fb.group({ accountId: change.accountId, ruleId: change.ruleId });
            this.auditForm.controls.accountRules.push(formGroup);
        }

        this.clearResults();
    }

    //#endregion

}