<div [formGroup]="mapKeyForm" style="width: 100%">
  <div class="flex-fill bg-white d-flex flex-column">
    <mat-form-field class="full-width pb-3">
      <mat-select placeholder="Expression" formControlName='expressionId' (selectionChange)="expressionChange($event)">
        <mat-option *ngFor="let expression of mapKeyExpressionTypes" [value]="expression.id">
          {{ expression.name }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="mapKeyForm.get('parentId').hasError('required')">
        Expression type
        <strong>required</strong>
      </mat-error>
    </mat-form-field>
    <mat-form-field class="full-width">
      <mat-label>Name</mat-label>
      <input autofocus matInput formControlName='name'>
      <mat-error *ngIf="mapKeyForm.get('name').hasError('required')">
        Name
        <strong>required</strong>
      </mat-error>
    </mat-form-field>
    <div *ngIf="mapKeyForm.get('expressionId').value == matchedRuleResultType" class="flex-fill bg-white d-flex flex-column">      
      <mat-form-field class="full-width pb-3">
        <mat-label>Response Mapkey</mat-label>
        <mat-select formControlName='responseMapKeyId'
          (selectionChange)="responseMapKeyChange($event)">
          <mat-option *ngFor="let responseMapKey of responseMapKeys" [value]="responseMapKey.listMapKeysId">
            {{ responseMapKey.listMapKeyName }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="mapKeyForm.get('responseMapKeyId').hasError('required')">
          Response Mapkey 
          <strong>required</strong>
        </mat-error>
      </mat-form-field>
      <mat-form-field class="full-width pb-3">
        <mat-label>Rule</mat-label>
        <mat-select formControlName='ruleId' (selectionChange)="ruleChange($event)">
          <mat-option *ngFor="let rule of integrationRules" [value]="rule.id">
            {{ rule.name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="mapKeyForm.get('ruleId').hasError('required')">
          Rule 
          <strong>required</strong>
        </mat-error>
      </mat-form-field>
      <mat-form-field class="full-width pb-3">
        <mat-label>Property</mat-label>
        <mat-select formControlName='propertyId'>
          <mat-option *ngFor="let outputProperty of outputProperties" [value]="outputProperty.id">
            {{ outputProperty.property }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="mapKeyForm.get('propertyId').hasError('required')">
          Property 
          <strong>required</strong>
        </mat-error>
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label>Delimiter</mat-label>
        <input autofocus formControlName='ruleResultsDelimiter' matInput>
      </mat-form-field>
      <div class="flex flex-row">
        <div class="flex" style="flex-direction: column; width: 200px">
          <div class="sub-heading" style="margin-bottom: 0 !important">All values</div>
          <div>Select all values if true otherwise distinct values.</div>
        </div>
        <mat-slide-toggle class="sub-heading d-inline-block" formControlName="allValues">
        </mat-slide-toggle>
      </div>
    </div>
    <div *ngIf="mapKeyForm.get('expressionId').value == ruleResultType" class="flex-fill bg-white d-flex flex-column">
      <mat-form-field class="full-width pb-3">
        <mat-label>Rule</mat-label>
        <mat-select formControlName='ruleId'>
          <mat-option *ngFor="let rule of rules" [value]="rule.id">
            {{ rule.name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="mapKeyForm.get('ruleId').hasError('required')">
          Rule 
          <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  
</div>
