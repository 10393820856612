
export function groupBy<T = any, R = any>(list: T[], predicate: (arg: T) => R): Map<R, T[]> {
    const map = new Map();

    list.forEach((item) => {
        const key = predicate(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}
