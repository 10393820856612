import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AppService } from '../../app.service';
import { IConfigService } from '../../config/iconfigservice';
import { BaseService } from '../base.service';

@Injectable()
export class SectionService extends BaseService {

    publicApiBaseUrl: string;

    constructor(public appService: AppService, public configService: IConfigService, public httpClient: HttpClient) {
        super(appService, configService, httpClient);
        this.publicApiBaseUrl = this.configService.getConfiguration().publicApiUrl;
    }

    exportSection(sectionId: string, clientId: string, accountCode: string, versionNumber: number) {
        const url = `${this.publicApiBaseUrl}client/${clientId}/account/${accountCode}/${versionNumber}/section/${sectionId}/export`;

        return super.getData<any>(url, clientId, false);
    }

    importSection(clientId: string, accountCode: string, versionNumber: number, sectionData: any) {
        const url = `${this.publicApiBaseUrl}client/${clientId}/account/${accountCode}/${versionNumber}/section/import`;

        return super.postData(url, sectionData, clientId);
    }

    getThirdPartyIntegrations(clientId: string, accountCode: string) {
        let api = this.configService.getConfiguration().apiUrl;
        const url = `${api}Accounts/${accountCode}/ActiveIntegrations`;

        return super.getData<any>(url, clientId, false);
    }

}
