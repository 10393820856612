import { NotesDTO } from "@DTOs";
import { AccountSettings, FeatureToggle, NoteType } from "@Enums";
import { AccountDataService, AccountSettingsService, FeatureManagerService, RulesService } from "@Services";
import { Injectable } from '@angular/core';
import { AppService } from "app/app.service";
import { IConfigService } from "app/config/iconfigservice";
import { InterviewService } from "app/interview.service";
import { ConstantListGroup } from 'app/models/config/constant-mapkey-value';
import { shareReplay, Subject } from "rxjs";


@Injectable({
    providedIn: 'root'
})
export class FilterableListService {

    //Create observable that will be used to notify subscribers when the filterable list has been updated
    private filterableListUpdated = new Subject<any>();
    public filterableListUpdated$ = this.filterableListUpdated.asObservable();
    public accountData$;
    public accountSettingData$;
    private updateQueue = [];
    private isProcessing = false;

    constructor(
        private rulesService: RulesService,
        private interviewService: InterviewService,
        public configService: IConfigService,
        public appService: AppService,
        public featureManagerService: FeatureManagerService,
        public accountSettingsService: AccountSettingsService,
        public accountDataService: AccountDataService) {
    }

    private shouldUpdateFilterableList(constantListDictionary: ConstantListGroup,interviewMode: string){
        if (this.interviewService.isReadOnly && interviewMode != null && interviewMode) {
            const allDefaultLists = {};
            for (const key of Object.keys(constantListDictionary)) {
                allDefaultLists[key] = constantListDictionary[key].filter(x => x.ruleId == null);
            }
            this.filterableListUpdated.next(allDefaultLists);
            return false;
        }

        return true;
    }

    private getInterviewRuleFacts(){
        let interviewRuleFacts = this.interviewService.ruleFacts;

        //If the interviewService does not have any rule facts and the first question is answered then the first question was answered and the interview
        //was saved so the ruleFacts were not loaded into the interviewService, yet.  So we need to create a ruleFact for the first question
        const interviewData = this.interviewService.getInterviewData();
        if (interviewRuleFacts.length == 0 && interviewData.sections[0].questions[0].answerValue) {
            const firstQuestionData = interviewData.sections[0].questions[0];
            //TODO: Need to confirm this; this feels so hacky
            interviewRuleFacts = [
                {
                    name: firstQuestionData.mapKeyName,
                    value: (parseInt(firstQuestionData.answerValue) ? parseInt(firstQuestionData.answerValue) : firstQuestionData.answerValue),
                    questionId: firstQuestionData.id,
                }
            ];
        }

        return interviewRuleFacts;
    }
    private async getRuleResults(distinctRuleIds, interviewRuleFacts) : Promise<any>{
        const simpleRules = await this.rulesService.getSimpleRulesByAccount(this.interviewService.account.id);
        const interviewData = this.interviewService.getInterviewData();
        const ruleResults = [];
        for (const ruleId of distinctRuleIds.filter(x => x && simpleRules[x])) {
            const ruleResult = await this.rulesService.execute(interviewData.caseDetails?.id, simpleRules[ruleId], interviewRuleFacts, interviewData.clientId);
            ruleResults.push({ruleid: ruleId, result: ruleResult, ruleName: simpleRules[ruleId].ruleName});
        }
        const complexRules = distinctRuleIds.filter(x=> x && !simpleRules[x]);
        if (interviewData?.caseDetails?.id && complexRules.length > 0) {
            await this.rulesService.executeMultipleRulesWithSection(interviewData.caseDetails.id, complexRules, this.interviewService.getActiveSection()).then(x => {
                ruleResults.push(...x.map(rule => ({...rule, ruleName: rule.name})));
            });
        }

        return ruleResults;
    }
    
    /**
     * Append the filteredList with the list of Constant Mapkeys that are associated with the rule that was true.
     * Once appended, we sort the list by the order of the default list
     * Finally we return the name of the rule that was true
     * If the rule is not true then nothing happens and null is returned
     * 
     * 
     * @param {string} key The Constant Mapkey Id that we are checking
     * @param {TrueRules} ruleResult The rule that is being used to filter the list
     * @param {ConstantListGroup} filteredList The list of Filtered Constant Mapkeys; so far
     * @param {ConstantListGroup} constantListDictionary The ENTIRE list of Constant Mapkeys
     * @returns {string || null} The name of the rule that was true or null if the rule was false
    */
    private setFilteredList(key, ruleResult, filteredList, constantListDictionary){
        if (ruleResult.result) {
            //TODO: This feels redundant since we are setting this in line 113
            filteredList[key] = constantListDictionary[key].filter(mapkey => mapkey.ruleId === ruleResult.ruleid);

            let filteredResults = [];
            filteredResults = constantListDictionary[key].filter(mapkey => mapkey.ruleId === ruleResult.ruleid);
            let defaultList = [];
            defaultList = constantListDictionary[key].filter(x => x.ruleId == null);
            filteredResults.forEach(item => {
                const matchedElement = defaultList.find(x => x.value === item.value);
                if (matchedElement) {
                    item.order = matchedElement.order;
                }
            });
            filteredList[key] = filteredResults.sort((a, b) => a.order - b.order);

            return ruleResult.ruleName;
        }

        return null;
    }

    /**
     * Update filteredList to use the default list and add a case note if all the following are true:
     * 1. Is the list missing from the filteredList?
     * 2. Is there more than one rule that is true?
     * 
     * @param {string} key The Constant Mapkey Id that we are checking
     * @param {ConstantListGroup} filteredList The list of Filtered Constant Mapkeys; so far
     * @param {ConstantListGroup} constantListDictionary The ENTIRE list of Constant Mapkeys
     * @param {string[]} ruleNamesThatWereTrueState The list of rule names that are true up until this point
     */
    private setFilteredListToDefaultList(key, filteredList, constantListDictionary, keysWithTrueRules, ruleNames){
        const multipleVariantsFix = this.featureManagerService.getByName(FeatureToggle.CaseFilterableListsMultipleVariants).enabled;

        if (!filteredList[key] || filteredList[key].length === 0 || 
            (multipleVariantsFix && keysWithTrueRules.filter(x => x === key).length != 1) 
                || (!multipleVariantsFix && keysWithTrueRules.length != 1)) {
            const interviewData = this.interviewService.getInterviewData();

            filteredList[key] = constantListDictionary[key].filter(mapkey => !mapkey.ruleId);
            
            if (keysWithTrueRules.length > 1) {
                //Find the active section
                const activeSection = interviewData.sections.find(section => section.id === this.interviewService.getInterviewData().activeSectionId) ?? interviewData.sections[0];
                const question = interviewData.sections.find(section => section.id === activeSection.id).questions.find(question => question.multiChoiceAnswers.some(answer => answer.constantKey === key));
                const constantListName = filteredList[key][0].listName;
                //Find the questions in the active section that
                //get the interviewData.sections.questions that have the same mapkeyId as the filteredList[key][0].mapkeyId
                this.addNoteToCase(ruleNames, activeSection.name, question?.questionTitle === '' ? question?.mapKeyName : question?.questionTitle, constantListName);
            }
        }
    }
    /**
     * Adds the arguments to the queue and then processes the queue
     * @param {ConstantListGroup} constantListDictionary Dictionary of constant lists
     * @param {string} interviewMode Current mode of the interview, used as a flag to stop the function from doing uncessary work
     *
     */
    public async updateFilterableListAsync(constantListDictionary: ConstantListGroup, interviewMode: string) {
        if(this.featureManagerService.getByName(FeatureToggle.CaseFilterableListsMultipleVariants).enabled) {
            // Add the arguments to the queue
            this.updateQueue.push({constantListDictionary, interviewMode});

            // Process the queue
            this.processQueue();
        }
        else
        {
            // Do it the old way
            await this.updateFilterableListAsyncInternal(constantListDictionary, interviewMode);
        }
    }

    private processQueue() {
        // If a call is being processed, do nothing
        if (this.isProcessing) {
            return;
        }

        // If the queue is empty, do nothing
        if (this.updateQueue.length === 0) {
            return;
        }

        // Get the next call from the queue
        const { constantListDictionary, interviewMode } = this.updateQueue.shift();

        // Set the flag to indicate that a call is being processed
        this.isProcessing = true;

        // Execute the logic
        this.updateFilterableListAsyncInternal(constantListDictionary, interviewMode)
            .finally(() => {
                // When the call is done, reset the flag and process the next call in the queue
                this.isProcessing = false;
                this.processQueue();
            });
    }

    /**
     * Goes through the dictionary of constant lists collecting all the regular constant lists and filtering down the Variant lists
     * Once the all the lists have been collected, we emit the filtered list to the observable
     * @param {ConstantListGroup} constantListDictionary Dictionary of constant lists
     * @param {string} interviewMode Current mode of the interview, used as a flag to stop the function from doing uncessary work
     * 
     */
    private async updateFilterableListAsyncInternal(constantListDictionary: ConstantListGroup, interviewMode: string) {
        const multipleVariantsFix = this.featureManagerService.getByName(FeatureToggle.CaseFilterableListsMultipleVariants).enabled;

        if (!this.shouldUpdateFilterableList(constantListDictionary, interviewMode)){
            return;
        }

        const filteredList = {};
        const keysWithTrueRules: string[] = [];
        const ruleNamesTrueState = {};
        const ruleIds = this.collectRuleIds(constantListDictionary);

        //At this point we have all the ruleIds that are associated with the dictionary of constant lists
        const distinctRuleIds = Array.from(new Set(ruleIds));
        const interviewRuleFacts = this.getInterviewRuleFacts();
        //Get all the rule results for the rules we just filtered out
        await this.getRuleResults(distinctRuleIds, interviewRuleFacts).then(ruleResults => {
            ruleResults.forEach(ruleResult => {
                const keys = Object.keys(constantListDictionary).filter(x => constantListDictionary[x].find(mapkey => mapkey.ruleId === ruleResult.ruleid));
    
                keys.forEach(key => { 
                    const ruleName = this.setFilteredList(key, ruleResult, filteredList, constantListDictionary);
    
                    if (ruleName !== null && multipleVariantsFix){
                        keysWithTrueRules.push(key);
                    }else if (ruleName !== null){
                        keysWithTrueRules.push(ruleName);
                    }
        
                    if (!ruleNamesTrueState[key] || ruleNamesTrueState[key] === null){
                        ruleNamesTrueState[key] = [];
                    }

                    if (ruleName !== null)
                        ruleNamesTrueState[key].push(ruleName);

                    this.setFilteredListToDefaultList(key, filteredList, constantListDictionary, keysWithTrueRules, ruleNamesTrueState[key]);                
                });            
            });

            const keys = Object.keys(constantListDictionary).filter(x => constantListDictionary[x].find(mapkey => !mapkey.ruleId ));

            keys.forEach(key => {
                this.setFilteredListToDefaultList(key, filteredList, constantListDictionary, keysWithTrueRules, ruleNamesTrueState[key]);   
            });
        });
                
        //Emit the filtered list to the observable
        if (Object.keys(filteredList).length > 0)
            this.filterableListUpdated.next(filteredList);
    }

    private collectRuleIds(constantListDictionary: ConstantListGroup): string[] {
        const ruleIds = [];
        for (const key of Object.keys(constantListDictionary)) {
            const listRuleIds = [...new Set<string>(constantListDictionary[key].map(mapkey => { if (mapkey.ruleId) return mapkey.ruleId; }))];
            if (!listRuleIds.some(ruleId => constantListDictionary[key].some(mapkey => mapkey.ruleId === ruleId))) {
                continue;
            }
            ruleIds.push(...listRuleIds.filter(x => x));
        }
        return ruleIds;
    }

    public filteredListOrConfigOrContantListHasRuleId(filteredList, constantList, config): boolean {
        const constantListHasRuleId = constantList?.some(x => x.ruleId);
        const constantKeyNavigationId = config['multiChoiceAnswers'][0].constantKeyNavigation?.id;
        const filteredListAndConfigAreCorrect = Object.keys(filteredList).length != 0 && constantKeyNavigationId;
        return (constantListHasRuleId || (filteredListAndConfigAreCorrect && filteredList && filteredList[constantKeyNavigationId] && filteredList[constantKeyNavigationId].some(x => x.ruleId)));
    }

    private addNoteToCase(ruleNames: string[], sectionName: string, questionText: string, constantListName: string) {
        const caseDetails = this.interviewService.getInterviewData().caseDetails;
        if (caseDetails) {
            if (!this.accountData$){
                this.accountData$ = this.accountDataService.getAccount(caseDetails.accountId).pipe(shareReplay({bufferSize: 1, refCount: false })); 
            }

            this.accountData$.subscribe(data => {
                const account = data;

                if (!this.accountSettingData$){
                    this.accountSettingData$ = this.accountSettingsService.getAccountSetting(account, AccountSettings.AddNoteForVariants).pipe(shareReplay({bufferSize: 1, refCount: false })); 
                }

                this.accountSettingData$.subscribe(result =>{
                    const addNoteForVariant = result?.value === 'true';
                
                    if (addNoteForVariant){
                        const caseNote: NotesDTO = {
                            caseDetailsId: caseDetails.id,
                            isClientViewable: false,
                            noteTypeId: NoteType.SystemLog,
                            relatedTo: "Case",
                            userName: "",
                            noteContent: `
                            A default list was shown because multiple variant rules were true.<br/>
                            Section: ${sectionName}<br/>
                            Question: ${questionText}<br/>
                            List: ${constantListName}<br/>
                            Rules<br/>
                            <ul>
                                <li>${ruleNames.join("</li><li>")}</li>
                            </ul>`,
                            id: "0",
                            isSystemGenerated: true,
                            noteType: {
                                id: NoteType.SystemLog,
                                name: "System Log",
                                description: "System Log",
                                isActive: true
                            }
        
                        };
                        this.appService.postData('notes', caseNote).subscribe();
                    }
                });
            });
        }
    }

}
