enum CaseStatus {
    PendingInitialReview = '00000000-0000-0000-0000-000000000001',
    Cancelled = '00000000-0000-0000-0000-000000000002',
    Done = '00000000-0000-0000-0000-000000000003',
    OnHold = '00000000-0000-0000-0000-000000000004',
    Error = '00000000-0000-0000-0000-000000000005',
    PendingSubmission = '00000000-0000-0000-0000-000000000006',
    New = '00000000-0000-0000-0000-000000000007',
    Submitted = '00000000-0000-0000-0000-000000000008',
    InProgress = '00000000-0000-0000-0000-000000000009',
    Inactive = '00000000-0000-0000-0000-000000000010',
    PendingSignature = '00000000-0000-0000-0000-000000000011',
    Processing = '00000000-0000-0000-0000-000000000012',
    Purged = '00000000-0000-0000-0000-000000000013',
    Deidentified = '00000000-0000-0000-0000-000000000014',
}

export default CaseStatus;
