<h1 mat-dialog-title *ngIf="!applyingSignature">
    <span>Sign Documents</span>
</h1>
<div mat-dialog-content class="flex">
    <ng-container *ngIf="envelope">
        <div *ngFor="let signer of envelope?.signers; let i = index;">
            <div class="signer flex align-items-center" *ngIf="!signer.signerInfo?.remoteSigner">
                <div class="flex-1 flex-row">
                    <div class="flex flex-1 flex-row" [ngClass]="{
                        'signer-completed':signer.signerStatus === 'Signed',
                        'icon-padding': signer?.signerStatus !== 'Signed' && !(signer.signerInfo && this.currentSigner?.signerInfo?.accountSignerId === signer.signerInfo?.accountSignerId && this.loadingEnvelope)}">

                        <mat-spinner diameter="24" style="margin-right: .5rem" 
                            *ngIf="signer.signerInfo && this.currentSigner?.signerInfo?.accountSignerId === signer.signerInfo?.accountSignerId && this.loadingEnvelope"></mat-spinner>
                        <mat-icon *ngIf="signer.signerStatus === 'Signed'" style="margin-right: .5rem" svgIcon="check-circle" class="status-check-mark"></mat-icon>
                        {{ signer.name }}
                    </div>
                </div>
                <div class="signer--actions">
                    <mrs-btn-primary class="material-default" mat-raised-button
                        *ngIf="!envelopeStatus?.complete && signer.signerStatus === 'Sent' && !loadingEnvelope"
                        (click)="sign(signer)"
                        [buttonLabel]="'Sign Documents'"></mrs-btn-primary>

                    <mrs-btn-primary class="material-default" mat-raised-button
                        *ngIf="signer.signerStatus === 'Pending'"
                        [disabled]="true"
                        [buttonLabel]="'Sign Documents'"></mrs-btn-primary>

                    <mrs-btn-tertiary
                        class="material-default"
                        [textColor]="DarkBlueGray"
                        mat-button
                        [disabled]="true"
                        *ngIf="(signer.signerInfo && this.currentSigner?.signerInfo?.accountSignerId === signer.signerInfo?.accountSignerId && this.applyingSignature) || (signer.signerStatus === 'Processing')"
                        [buttonLabel]="'Processing'"></mrs-btn-tertiary>

                    <mrs-btn-tertiary
                        class="material-default"
                        [textColor]="DarkBlueGray"
                        mat-button
                        [disabled]="true"
                        *ngIf="signer.signerInfo && this.currentSigner?.signerInfo?.accountSignerId === signer.signerInfo?.accountSignerId && this.applyingSignature  && applyingSignatureTakingTooLong"
                        [buttonLabel]="'Finalizing'"></mrs-btn-tertiary>

                    <mrs-btn-tertiary
                        [textColor]="UIGreen"
                        class="material-default"
                        mat-button
                        [disabled]="true"
                        *ngIf="signer.signerStatus === 'Signed'"
                        [buttonLabel]="'Signed'">
                    </mrs-btn-tertiary>

                    <mrs-btn-tertiary
                        class="material-default"
                        mat-button
                        [disabled]="true"
                        [textColor]="DarkBlueGray"
                        *ngIf="signer.signerStatus === 'Declined' || signer.signerStatus === 'Failed'"
                        [buttonLabel]="signer.signerStatus === 'Declined' ? 'declined to sign' : 'failed'">
                    </mrs-btn-tertiary>
                </div>
            </div>
            <div *ngIf="signer.signerInfo?.remoteSigner">
                <remote-signer-v2 [context]="context" [signer]="signer" 
                    [signerStatus]="signer.status"
                    [envelopeStatus]="envelopeStatus" [signalRConnection]="signalrConnection"></remote-signer-v2>
            </div>
        </div>
    </ng-container>
</div>
<div mat-dialog-actions align="end">    
    <mrs-btn-tertiary class="done-button" mat-raised-button color="primary" *ngIf="applyingSignature && applyingSignatureTakingTooLong" (click)="ok()" [disabled]="loadingEnvelope" [textColor]="DarkBlueGray" buttonLabel="Close"></mrs-btn-tertiary>
    <mrs-btn-tertiary class="material-default done-button text-danger" *ngIf="!envelopeStatus?.complete && !applyingSignature" mat-button [textColor]="UIBlue" (click)="ok()" buttonLabel="Save & Close"></mrs-btn-tertiary>
    <mrs-btn-tertiary class="done-button" mat-raised-button color="primary" *ngIf="envelopeStatus?.complete && envelopeStatus?.signed" (click)="ok()" [disabled]="loadingEnvelope" [textColor]="DarkBlueGray" buttonLabel="Close"></mrs-btn-tertiary>
    <mrs-btn-tertiary class="done-button" mat-raised-button color="primary" *ngIf="envelopeStatus?.complete && !envelopeStatus?.signed" (click)="ok()" [disabled]="loadingEnvelope" [textColor]="UIBlue" buttonLabel="Close"></mrs-btn-tertiary>
    <mrs-btn-tertiary class="material-default" mat-raised-button *ngIf="envelopeStatus?.signed" [textColor]="UIBlue" (click)="viewSignedDocuments()" [disabled]="loadingEnvelope || loadingEmbeddedUrl" buttonLabel="View Documents"></mrs-btn-tertiary>
</div>

