<div class="case-manager-container">
    <div class="main-page-heading">
        Call Manager
    </div>

<div class="custom-scrollbar">
    <div class="flex-fill">
        <div class="container-fluid" style="padding-left: 0 !important">
            <form [formGroup]="callsForm" novalidate autocomplete="off">
                <div class="flex flex-row mt-2">
                    <mat-form-field style="width:250px;">
                        <mat-label>Client Name</mat-label>
                        <mat-select formControlName='clientId'
                            (selectionChange)="enableImport($event.value)">
                            <mat-option *ngFor="let client of clients" [value]="client.id">
                                {{client.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="callsForm.get('clientId').hasError('required')">
                            required
                        </mat-error>
                    </mat-form-field>

                    <h6 class="ml-4 mb-0 mt-4">Calls Between:</h6>

                    <div class="ml-2 mt-3" style="min-width:205px;">
                        <p-calendar showTime="true" placeholder="Start Date Time" (onSelect)="getStartRange()"
                            formControlName="startDate" showIcon="true" hourFormat="24"></p-calendar>
                        <div>
                            <mat-error
                                *ngIf="callsForm.get('startDate')?.hasError('required') && (callsForm.get('startDate')?.touched || callsForm.get('startDate')?.dirty)">
                                required</mat-error>
                        </div>
                    </div>

                    <div class="ml-2 mt-3" style="min-width:205px;">
                        <p-calendar showTime="true" placeholder="End Date Time" [minDate]="minDateValue"
                            formControlName="endDate" showIcon="true" hourFormat="24"></p-calendar>
                        <div>
                            <mat-error
                                *ngIf="callsForm.get('endDate')?.hasError('required') && (callsForm.get('endDate')?.touched || callsForm.get('endDate')?.dirty) ">
                                required</mat-error>
                        </div>
                    </div>
                    <div class="flex flex-row mr-5" style="justify-content: end; margin-left: auto;">

                        <mrs-btn-tertiary class="float-right ml-3 mt-3" buttonLabel="Import" buttonId="importButton"
                            (onClick)="showImportDialog()" iconName="upload" *ngIf="showImport" textColor="#2C6EED">
                        </mrs-btn-tertiary>
                        <mrs-btn-icon class="float-right mt-3" buttonLabel="Search" buttonId="searchButton"
                            (onClick)="getCalls()" iconName="search"></mrs-btn-icon>
                    </div>
                </div>
            </form>


            <div class="d-flex flex-row flex-nowrap overflow-auto">
                <manage-users-table [data]="reportData" [displayedColumns]="displayedColumns" 
                    [searchableFields]="searchableFields" (tableEvent)="tableAction($event)"
                    initialSort="startTime">
                </manage-users-table>
            </div>
        </div>
    </div>
</div>
</div>
<p-dialog header="Edit Call Manager" [closeOnEscape]="false" [closable]="false" [(visible)]="showEditCall" modal="true"
    appendTo="body" [resizable]="false" width="600">
    <div style="min-width: 800px">

    </div>
    <form [formGroup]="callEditForm" (ngSubmit)="saveCall()" novalidate autocomplete="off">
        <input type="hidden" formControlName="dismissReason">
        <input type="hidden" formControlName="platform">
        <display-lv [label]="'Contact ID'" [value]="selectedCall?.callId" [isUpperCase]="'true'"></display-lv>
        <display-lv [label]="'DNIS'" [value]="selectedCall?.dnis" [isUpperCase]="'true'"></display-lv>
        <display-lv [label]="'SKILL'" [value]="selectedCall?.skill" [isUpperCase]="'true'"></display-lv>
        <div class="label-title">ORDER </div>
        <div class="label-content">
            <div class="mb-2 w-100">
                <auto-suggest [list]="caseIds" placeholder="Order Ids" field="id" dataKey="id"
                    formControlName="caseNumbers" [multiple]="true"></auto-suggest>
            </div>
        </div>

        <display-lv [label]="'Dismiss Reason'" [value]="selectedCall?.dismissReason" [isUpperCase]="'true'">
        </display-lv>

    </form>
    <p-footer>
        <button type="submit" mat-raised-button color="primary" class="mr-1" (click)="saveCall()">
            <i aria-hidden="true" class="fa fa-cloud"></i> Save
        </button>
        <button type="button" mat-button color="basic" class="mr-1" (click)="showEditCall = false">
            <i aria-hidden="true" class="fa fa-times"></i> Cancel
        </button>
    </p-footer>
</p-dialog>

<ng-template #importCallDialog let-data>
    <h1 mat-dialog-title>Import Call</h1>
    <form [formGroup]="importForm" (ngSubmit)="importContact()" novalidate autocomplete="off">
        <div mat-dialog-content class="flex py-2">
            <mat-form-field class="w-100">
                <mat-label>Contact Id</mat-label>
                <input type="text" matInput formControlName="contactId">
                <mat-error *ngIf="importForm.get('contactId').hasError('required')">required</mat-error>
            </mat-form-field>
        </div>
        <div mat-dialog-actions align="end">
            <button type="button" mat-button (click)="cancelImport()" class="mr-1 material-default">
                CANCEL
            </button>
            <button type="submit" mat-raised-button color="primary" class="material-default">
                IMPORT
            </button>
        </div>
    </form>
</ng-template>