import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'mrs-btn-outline',
    templateUrl: './mrs-btn-outline.component.html',
    styleUrls: ['./mrs-btn-outline.component.scss']
})
export class MrsBtnOutlineComponent {
  @Input() buttonLabel: string;
  @Input() buttonId: string;
  @Input() isSubmitButton = false;
  @Input() disabled = false;
  @Input() iconName: string;
  @Output() onClick = new EventEmitter<Event>();

}
