import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IConfigService } from '@Config';
import { MapKeysDTO } from '@DTOs';
import { UWResponse } from '@Models';
import { BaseService } from '@Services';
import { MapkeyUtils } from '@Utils';
import { AppService } from 'app/index';
import { catchError, map, of, take, tap } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export default class MapkeysDataService extends BaseService {
    private _mapkeyCache = new Map<string, MapKeysDTO[]>();
    private memoryThreshold = 10;

    constructor(
        public configService: IConfigService,
        public httpClient: HttpClient,
        public appService: AppService
    ) {
        super(appService, configService, httpClient);
    }


    /**
     * Clear the cache if we've got over 10 accounts loaded. This is a low-brow attempt to prevent rules from growing too large.
     * The threshold number was an educated guess on how many accounts a user interacts with vs how much data we should cache.
     * 
     * TECH DEBT: A smarter approach would be to see if we can compare clientid's and if the client has
     * changed since the last use, we clear the cache. Developers can also manually manage by using `clearCache()`.
     * Another idea is to store a "last cached" date and clear caches over a date threshold. Due to time constraints, we're
     * just using a simple max keys limit.
     * 
     * Current one of the bigger use case scenarios is Wellabe's CNBW where we load 6 accounts.
     */
    memorySaver() {
        if (this._mapkeyCache.keys.length > this.memoryThreshold) this.clearCache();
    }

    clearCache() {
        this._mapkeyCache.clear();
    }

    /**
     * 
     * @param accountId 
     * @param hideWaiting 
     * @param clientId ClientId is required if this is called from a route without the client route parameter. (eg. Case Manager)
     * @returns 
     */
    loadMapkeys(accountId: string, clientId?: string, hideWaiting = false) {
        if (this._mapkeyCache.has(accountId)) {
            return of(this._mapkeyCache.get(accountId));
        }

        const url = `${this.baseApiUrl}MapKeys/${accountId}/MapKeys`; // [UWPipeline.Application] MapKeysController.cs
        return super.getData<UWResponse<MapKeysDTO[]>>(url, clientId, hideWaiting).pipe(
            take(1), // Close the observable after one value so we don't have to unsubscribe.
            map(response => {
                return response.data;
            }),
            tap(mapkeys => {
                this.memorySaver();
                mapkeys.sort(MapkeyUtils.sortByEntityHierarchy);
                this._mapkeyCache.set(accountId, mapkeys);
            }),
            catchError(err => {
                console.error(`Error loading Mapkeys for account ${accountId}.`, err);
                return of([] as MapKeysDTO[]);
            }),
        );
    }

}
