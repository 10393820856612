import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NoteType } from 'app/enums';
import { SubSink } from 'subsink';

@Component({
    selector: 'app-followup-date-config',
    templateUrl: './followup-date-config.component.html',
    styleUrls: ['./followup-date-config.component.scss']
})
export class FollowupDateConfigComponent implements OnInit, OnDestroy {
  @Input() config: any;
  @Input() formGroupRef: UntypedFormGroup;

  private subs = new SubSink();
  private readonly noteTypeActionParam = '00000000-0000-0000-0000-000000000053';
  private readonly validNoteTypesForAutoNotifications = [
      NoteType.UpdateStatus,
      NoteType.PendingInformation,
      NoteType.Interpreter
  ];
  private readonly defaultValue = 2;

  public show: boolean = false;

  ngOnInit(): void {
      let hasValidValue = this.validNoteTypesForAutoNotifications.includes(this.formGroupRef.get(this.noteTypeActionParam).value);

      this.show = this.formGroupRef.valid && hasValidValue;

      this.formGroupRef.get(this.config.id).patchValue(this.getValueOrDefault());

      this.subs.sink = this.formGroupRef.get(this.noteTypeActionParam).valueChanges
          .subscribe(value => {
              this.show = this.validNoteTypesForAutoNotifications.includes(value);
          });

  }

  private getValueOrDefault(): number {
      let value = this.formGroupRef.get(this.config.id)?.value;
      if (value && value != '') {
          return  +value;
      }

      return this.defaultValue;

  }

  ngOnDestroy(): void {
      this.subs.unsubscribe();
  }
}
