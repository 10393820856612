import { Injectable, OnDestroy } from '@angular/core';

import { CacheContent, Client } from '@Models';
import { CacheUtils, TimeUtils } from '@Utils';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { Observable, filter, firstValueFrom, map, shareReplay, take, tap } from 'rxjs';
import { SubSink } from 'subsink';
import { ClientService } from './global';


export const StorageKey_clientContextCache = 'clientContextCache';
export const StorageTimeout_clientContextCache = TimeUtils.hoursToMilliseconds(12);

/**
 * Service to manage the "Client Contxt".
 * See Wiki: https://dev.azure.com/mrsreps/MRS/_wiki/wikis/UW%20Pipeline.wiki/1475/Angular-Client-Context-State-Service
 */
@Injectable({
    providedIn: 'root'
})
export class ClientContextService implements OnDestroy {

    clients$: Observable<Client[]>;
    private _clients: Client[] = [];
    private _currentClient: Client = null;
    private _clientsLoaded = false;

    get clients(): Client[] {
        return this._clients;
    }
    get client(): Client {
        return this._currentClient;
    }
    get clientsLoaded(): boolean {
        return this._clientsLoaded;
    }

    ready: Promise<Client[]>;

    subs = new SubSink();

    constructor(
        private _clientService: ClientService,
        private _router: Router,
        private _activatedRoute: ActivatedRoute
    ) {
        this.clients$ = this._clientService.getClientsAndAccounts().pipe(
            take(1),
            shareReplay(1),
            tap(clients => {
                this._clients = clients;
                // TECH DEBT: IDEA: Add local storage caching for loading the last client used
                // But what does it mean to store the client and users deep link into other client specific URLs?
                this._clientsLoaded = true;
                // In the case of browser refresh or first load, see if we have a last used client.
                this.setClientContextFromStorage();
            }),
        );

        // DEV NOTE: Readiness Pattern (https://stackoverflow.com/a/45070748/253564)
        this.ready = firstValueFrom(this.clients$);

        // When a route is activated/changed/listen for events
        this._router.events.pipe(
            // Filter for NavigationEnd events to know when a route change is done
            filter(event => event instanceof NavigationEnd),
            // Map to the root of the current route to access params
            map(() => this._activatedRoute.root)
        ).subscribe(_route => {
            const route = this.getLastRouteNode(this._activatedRoute.root);

            // Get the client route parameter
            const clientCodeOrId = this.getClientCodeOrId(route.snapshot.params);
            if (!clientCodeOrId) return;

            if (!this.clientCodeMatchesCurrentContext(clientCodeOrId)) {
                // Do something when the context doesn't match the URL
                this.setClient(route.snapshot.params.clientCode, true);
            }
        });
    }

    //#region Helpers

    /**
     * Look for the client code or id route params.  This should always be `:clientCode` which could be
     * the code (123) or the id ({guid}). Also check for `:clientId` as a safety check incase someone 
     * doesn't realize to follow the param name pattern.
     * @param params 
     * @returns 
     */
    getClientCodeOrId(params: Params): string {
        if (params.clientCode) return params.clientCode;
        if (params.clientId) return params.clientId;
        return '';
    }

    /**
     * Return the client object from the clients list that matches the client.code or client.id provided.
     * @param clientCodeOrId The client.id or client.code to find.
     * @returns The matched client object.
     */
    findClient(clientCodeOrId: string) {
        const client = this._clients?.find(x => x.code === clientCodeOrId || x.id === clientCodeOrId);
        return client;
    }

    /**
     * Check to see if the route param matches hte current client context. Since the value could be
     * the code (123) or the id ({guid}), this will compare against both and pass if any of them match.
     * @param clientCodeOrId The client.id or client.code to compare
     * @returns true if it matched the current client context. Otherwise, false.
     */
    clientCodeMatchesCurrentContext(clientCodeOrId: string,) {
        const matches =
            (clientCodeOrId == this._currentClient.code) ||
            (clientCodeOrId == this._currentClient.id);

        return matches;
    }

    /**
     * We need to traverse the route tree until the end, so we have the correct params.
     * @param route The ActivatedRoute to traver
     * @returns 
     */
    getLastRouteNode(route: ActivatedRoute): ActivatedRoute {
        while (route.firstChild) {
            route = route.firstChild;
        }
        return route;
    }

    //#endregion
    //#region Lifecycle

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    //#endregion

    /**
     * Restore the client context from local or session storage. This happens when a user reloads a page or 
     * enters the URL into the browser (direct link, bookmark, etc).
     * 
     * If the URL has a client code that doesn't match the storage client, we'll defer to the route client
     * but set it as "session" storage.
     * @param forceRefresh 
     * @returns 
     */
    setClientContextFromStorage(forceRefresh = false) {
        // Don't set the current context if we're forcing a refresh.
        if (forceRefresh) return;

        const local_clientContextCache = localStorage.getItem(StorageKey_clientContextCache);
        const local_cached: CacheContent<string> = local_clientContextCache ? JSON.parse(local_clientContextCache) : null;

        const session_clientContextCache = sessionStorage.getItem(StorageKey_clientContextCache);
        const session_cached: CacheContent<string> = session_clientContextCache ? JSON.parse(session_clientContextCache) : null;

        let storage_cached = session_cached;
        if (CacheUtils.isExpired(storage_cached)) storage_cached = local_cached;
        if (CacheUtils.isExpired(storage_cached)) return;

        // We need to make sure storage/route clients are the same
        const route = this.getLastRouteNode(this._activatedRoute.root);
        const route_clientCodeOrId = this.getClientCodeOrId(route.snapshot.params);

        // There's no route - set client from storage
        if (!route_clientCodeOrId) {
            this.setClient(storage_cached.value);
            return;
        }

        const route_client = this.findClient(route_clientCodeOrId);
        const active_client = this.findClient(storage_cached.value);

        // There's no clientin the route or storage. Don't set anything
        if (!route_client || !active_client) return;

        // We should use the cache but if the cache and route don't match, we want to use
        // the route (so the site "just works") but cache it as session storage.
        if (route_client.code !== active_client.code) {
            this.setClient(route_client, true);
            return;
        }

        // The route/local client are the same, so it doesn't matter which one we use
        this.setClient(active_client);
    }

    setClient(clientIdOrCode: string | Client, forSession = false) {
        const client = typeof clientIdOrCode === 'string'
            ? this.findClient(clientIdOrCode)
            : clientIdOrCode;

        this._currentClient = client;

        const cached: CacheContent<string> = { expiry: 0, value: this._currentClient.id };

        // We'll always set session. If we're setting "local" (which is more persistant) then we should
        // make sure we override whatever was in session.
        sessionStorage.setItem(StorageKey_clientContextCache, JSON.stringify(cached));

        // If this cache was only targeting session storage, don't set local storage.
        if (!forSession) {
            localStorage.setItem(StorageKey_clientContextCache, JSON.stringify(cached));
        }
    }
}
