import { AuditCaseEnhanced, AuditCaseTableItem } from '@Audit';
import { MrsColor } from '@Enums';
import { CaseSummaryRoute, CaseSummaryService } from '@Services';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';



@Component({
    selector: 'audit-cases-table',
    templateUrl: './audit-cases-table.component.html',
    styleUrls: ['./audit-cases-table.component.scss']
})
export class AuditCasesTableComponent implements OnChanges {
    @Input() auditCases: AuditCaseEnhanced[] = [];
    @Input() noCasesMessage = 'No Cases';
    @Input() stickyHeaders = false;
    @Output() onCompleteAuditCases = new EventEmitter<AuditCaseEnhanced>;

    auditCaseItems: AuditCaseTableItem[] = [];

    // Table Data
    displayedColumns = [ // The columns and order to show in the template
        'auditStatus',
        'caseNumberDisplay',
        'caseCompletedDate',
        'auditSelected',
        'auditCompleted',
        'action'
    ];

    public get MrsColor() { return MrsColor; }

    constructor(
        private _caseSummaryService: CaseSummaryService,
    ) { }

    //#region Helpers

    updateCases() {
        const auditCaseItems = this.auditCases?.map((auditCase): AuditCaseTableItem => {
            const auditCaseItem: AuditCaseTableItem = { ...auditCase };

            if (auditCase.completedOn) {
                auditCaseItem.auditStatus = 'Completed';
                auditCaseItem.isCompleted = true;
            }
            else if (auditCase.selectedOn) {
                auditCaseItem.auditStatus = 'Selected';
                auditCaseItem.isSelected = true;
            }

            return auditCaseItem;
        });

        this.auditCaseItems = auditCaseItems ?? [];
    }

    getStatusClasses(item: AuditCaseTableItem) {
        return {
            'isSelected': item.isSelected,
            'isCompleted': item.isCompleted
        };
    }

    //#endregion
    //#region Lifecycle

    ngOnChanges(changes: SimpleChanges) {
        if (changes.auditCases || changes.interval) {
            this.updateCases();
        }
    }

    //#endregion
    //#region Handlers

    onOpenCase(auditCase: AuditCaseEnhanced, newTab = true) {
        const routeInfo: CaseSummaryRoute = {
            clientCode: auditCase.client.code,
            accountCode: auditCase.account.code,
            accountVersionNumber: auditCase.account.versionNumber,
            caseId: auditCase.caseId,
        };
        this._caseSummaryService.routeToCase(routeInfo, newTab);
    }

    onCompleteCaseAudit(auditCase: AuditCaseEnhanced) {
        this.onCompleteAuditCases.emit(auditCase);
    }

    //#endregion
}
