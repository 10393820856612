export enum MrsColor {
  MidGray = '#26333B',
  UIBlue = '#2C6EED',
  UIRed = '#900606',
  PrimaryBackground_50 = 'rgba(239, 239, 239, 0.5)',
  DisabledTableColumn = '#E7E7E7',
  BrandRedAccent = '#A62923',
  UIGreen = '#1D8F0B',
  DarkBlueGray = '#26333B',
  Gray = "#6A6A6A"
}
