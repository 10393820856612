<div class="uw-card">

    <form *ngIf="showForm" class="row" [formGroup]="labelForm" (ngSubmit)="saveLabel()" novalidate autocomplete="off">
        <input type="hidden" formControlName="id">
        <input type="hidden" formControlName="workFlowId">
        <div class="col-12 text-right">
            <button type="submit" class="btn btn-link btn-sm custom">
                <i aria-hidden="true" class="fa fa-check"></i> Save
            </button>
            <button type="button" *ngIf="label.id" class="btn btn-link btn-sm custom" (click)="cancel()">
                <i aria-hidden="true" class="fa fa-times"></i> Close
            </button>
            <button type="button" *ngIf="!label.id" class="btn btn-link btn-sm custom" (click)="onDelete.emit()">
                <i aria-hidden="true" class="fa fa-trash"></i> Cancel
            </button>
        </div>
        <div class="col-4">
            <mat-form-field class="w-100">
                <mat-label>UW Label Name</mat-label>
                <input autofocus matInput formControlName="name">
                <mat-error *ngIf="labelForm.get('name').hasError('required')">
                    <strong>required</strong>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-8">
            <auto-suggest [list]="rules" placeholder="Rule" field="name" dataKey="id" formControlName="ruleId"></auto-suggest>
        </div>
    </form>

    <div class="row" *ngIf="!showForm">
        <div class="col-12 text-right">
            <button type="submit" *ngIf="checkACL('W')" class="btn btn-link btn-sm custom" (click)="editLabel()">
                <i aria-hidden="true" class="fa fa-edit"></i> Edit
            </button>
            <button *ngIf="checkACL('F')" class="btn btn-link btn-sm custom" (click)="onDelete.emit()">
                <i aria-hidden="true" class="fa fa-trash"></i> Delete
            </button>
            <button class="btn btn-link btn-sm custom custom" *ngIf="(index > 0) && checkACL('W')" (click)="moveUp.emit()">
                <i aria-hidden="true" class="fa fa-arrow-up"></i>
            </button>
            <button class="btn btn-link btn-sm custom" *ngIf="(index < (labelsLength - 1)) && checkACL('W')" (click)="moveDown.emit()">
                <i aria-hidden="true" class="fa fa-arrow-down"></i>
            </button>
        </div>
        <div class="col-4">
            <div class="label-title">UW Label Name</div>
            <div class="label-content">{{label?.name}}</div>
        </div>
        <div class="col-8">
            <div class="label-title"> Rule </div>
            <div class="label-content">{{getRuleName(label?.ruleId)}}</div>
        </div>
    </div>
  <div class="row text-left" *ngIf="showForm && purlEnabled">
    <button type="button" class="btn btn-link btn-sm custom col-4" (click)="openHTMLEditor(label)">
      Customize Applicant Messaging
    </button>
  </div>

</div>
