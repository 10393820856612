import { AppService } from '@App/index';
import { InterviewService } from '@App/interview.service';
import { SignatureEnvelopeRecipient, SignatureEnvelope, SigningUrlDelivery } from '@Models';
import { SystemEventService, SignerService, NotificationSeverity, SignalRService } from '@Services';
import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';
import { SignatureEnvelopeDialogParams } from '../../signature-envelope-dialog-component';
import { DocusignRemoteSignerStatusEnum, MrsColor } from '@Enums';


@Component({
    selector: 'remote-signer-v2',
    templateUrl: 'remote-signer-v2.component.html',
    styleUrls: ['remote-signer-v2.component.scss']
})
export class RemoteSignerV2Component implements OnInit {
    @Input() context: SignatureEnvelopeDialogParams;

    private _signer: SignatureEnvelopeRecipient;
    @Input()
    get signer(): SignatureEnvelopeRecipient {
        return this._signer;
    }
    set signer(val: SignatureEnvelopeRecipient) {
        this._signer = val;
    }

    private _signerStatus: string;
    @Input()
    get signerStatus(): string {
        return this._signerStatus;
    }
    set signerStatus(val: string) {
        this._signerStatus = val;

        if (val === 'completed') {
            this._signer.signerStatus = 'Signed';
            this.customStatus = 'Signed';
            this.usePadding = false;
        }
    }

    @Input() envelopeStatus: any;
    @Input() signalRConnection: any;
    envelope: SignatureEnvelope;
    deliveryMethod: string;
    embeddedSignatureView: any;
    email = '';
    emailInstructions = '';
    phoneNumber = '';
    phoneNumberInstructions = '';
    url = '';
    private _customStatus = '';
    get customStatus(): string {
        return this._customStatus;
    }
    set customStatus(val: string) {
        this._customStatus = val;
        this.usePadding = false;
        this.showSpinner = true;
        if (val !== "Applying Signature" && val !== 'Finalizing') {
            setTimeout(() => {
                this.showSpinner = false;
                this.usePadding = true;
            }, 500);
        }
    }

    showSpinner = false;
    usePadding = true;

    UIGreen = MrsColor.UIGreen;
    DarkBlueGray = MrsColor.DarkBlueGray;

    constructor(
        private appService: AppService,
        private interviewService: InterviewService,
        private systemEventService: SystemEventService,
        private signerService: SignerService) {
    }

    ngOnInit() {
        this.envelope = this.context.envelope;
        this.getAccountSigner();

        this.signalRConnection.on('docusignRemoteSignerStatusNotification', (event) => {
            if ((this.context.caseId === event.caseDetailId || this.context.caseId == event.caseId) && event.accountSignerId === this.signer.recipientId){
                switch (event.docusignStatus) {                    
                    case DocusignRemoteSignerStatusEnum.DocumentOpened: {
                        this.customStatus = "Document Opened";
                        this.usePadding = true;
                        break;
                    }              
                    case DocusignRemoteSignerStatusEnum.ApplyingSignature: {
                        this.customStatus = "Applying Signature";
                        this.usePadding = false;
                        this.showSpinner = true;

                        setTimeout(() => {
                            if (this.customStatus === "Applying Signature")
                                this.customStatus = "Finalizing";
                        }, 10000);
                        break;
                    }  
                    case DocusignRemoteSignerStatusEnum.DeclinedToSign: {
                        this.customStatus = "Declined to Sign";
                        this.usePadding = true;
                        break;
                    }  
                    case DocusignRemoteSignerStatusEnum.SavedAndClosed: {
                        this.customStatus = "Saved & Closed";
                        this.usePadding = true;
                        break;
                    } 
                    case DocusignRemoteSignerStatusEnum.Signed: {
                        this.customStatus = "Signed";
                        this.usePadding = false;
                        break;
                    } 
                }
            }
        });
    }

    getAccountSigner() {
        const caseDetails = this.interviewService.getInterviewData().caseDetails;
        this.signerService.getAccountSigner(caseDetails.accountId, this.signer.signerInfo.accountSignerId).subscribe(response => {
            const emailMapKey = this.interviewService.ruleFacts.find(x => x.name === response.emailMapKey);
            if (emailMapKey) {
                this.email = emailMapKey.value;
                this.emailInstructions = `Email instructions to ${this.email}`;
            }

            const phoneMapKey = this.interviewService.ruleFacts.find(x => x.name === response.phoneMapKey);
            if (phoneMapKey) {
                this.phoneNumber = phoneMapKey.value;
                this.phoneNumberInstructions = `Text instructions to ${this.phoneNumber}`;
            }

            const url = caseDetails.mapkeys.find(x => x.name === response.urlMapKey);
            if (url) {
                this.url = url.value;
            }
        });
    }
    sendCode(deliveryMethod) {
        const signingUrlDelivery: SigningUrlDelivery = {
            accessCode: `${this.signer.signerInfo.accessCode}`,
            deliveryMethod: deliveryMethod,
            accountSignerId: this.signer.signerInfo.accountSignerId,
            envelopeId: this.envelope.envelopeId,
            signer: this.signer
        };
        
        if (deliveryMethod === 'email') {                    
            this.appService.showMsg(NotificationSeverity.Success, `Instructions sent to ${this.email}`);
            this.customStatus = 'Email Sent';
        }

        if (deliveryMethod === 'sms') {
            this.appService.showMsg(NotificationSeverity.Success, `Instructions sent to ${this.phoneNumber}`);
            this.customStatus = 'Text Sent';
        }

        this.systemEventService.deliverSigningUrl(this.interviewService.account.id, this.interviewService.getInterviewData().caseDetails.id, signingUrlDelivery).subscribe((response) => {
            if (response) {
                //do nothing
            } else {
                this.appService.showMsg(NotificationSeverity.Error, 'Unexpected error occurred. Try again.');
            }
        });
    }
}
