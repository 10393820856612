import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from '@App';
import { IConfigService } from '@App/config/iconfigservice';
import { AccountSigner, Envelope, EnvelopeDocument, EnvelopeSigner } from '@Models';
import { BaseService } from '@Services/base.service';

@Injectable()
export class SignerService extends BaseService {

    publicApiBaseUrl: string;

    constructor(public appService: AppService, public configService: IConfigService, public httpClient: HttpClient) {
        super(appService, configService, httpClient);
        this.publicApiBaseUrl = this.configService.getConfiguration().publicApiUrl;
    }

    getAccountSignerMappingProperties(accountId: any) {
        const clientId = this.appService.getClientId('');
        const url = `${this.publicApiBaseUrl}client/${clientId}/config/account/${accountId}/signingprovider/mapping-properties`;

        return super.getData<any>(url, clientId);
    }

    getAccountSigners(accountId: any, filterAvailable: boolean = false) {
        const clientId = this.appService.getClientId('');
        const url = `${this.publicApiBaseUrl}client/${clientId}/config/account/${accountId}/accountsigners?filterAvailabe=${filterAvailable}`;

        return super.getData<any>(url, clientId);
    }


    getAccountSigner(accountId: any, accountSignerId: string) {
        const clientId = this.appService.getClientId('');
        const url = `${this.publicApiBaseUrl}client/${clientId}/config/account/${accountId}/accountsigner/${accountSignerId}`;

        return super.getData<any>(url, clientId);
    }

    getSignerInstructions(accountId: any, message: any, caseId: string) {
        const clientId = this.appService.getClientId('');

        return super.postData<string>(`${this.publicApiBaseUrl}client/${clientId}/account/${accountId}/case/${caseId}/tokenize`, { message: message }, clientId);
    }

    deleteAccountSigner(accountSigner: AccountSigner) {
        const clientId = this.appService.getClientId('');
        const url = `${this.publicApiBaseUrl}client/${clientId}/config/account/${accountSigner.accountId}/accountSigner/${accountSigner.id}`;

        return super.deleteData(url, clientId);
    }

    addAccountSigner(accountSigner: AccountSigner) {
        const clientId = this.appService.getClientId('');
        const url = `${this.publicApiBaseUrl}client/${clientId}/config/account/${accountSigner.accountId}/accountSigner`;

        return super.postData(url, accountSigner, clientId);
    }

    editAccountSigner(accountSigner: AccountSigner) {
        const clientId = this.appService.getClientId('');
        const url = `${this.publicApiBaseUrl}client/${clientId}/config/account/${accountSigner.accountId}/accountSigner`;

        return super.postData(url, accountSigner, clientId);
    }

    editAccountSigners(accountSigners: AccountSigner[], accountId) {
        const clientId = this.appService.getClientId('');
        const url = `${this.publicApiBaseUrl}client/${clientId}/config/account/${accountId}/accountSigners`;

        return super.putData(url, accountSigners, clientId);
    }

    addEnvelope(envelope: Envelope, accountId) {
        const clientId = this.appService.getClientId('');
        const url = `${this.publicApiBaseUrl}client/${clientId}/config/account/${accountId}/envelope`;

        return super.postData(url, envelope, clientId);
    }

    editEnvelope(envelope: Envelope, accountId) {
        const clientId = this.appService.getClientId('');
        const url = `${this.publicApiBaseUrl}client/${clientId}/config/account/${accountId}/envelope`;

        return super.putData(url, envelope, clientId);
    }

    getEnvelopes(accountId: any) {
        const clientId = this.appService.getClientId('');
        const url = `${this.publicApiBaseUrl}client/${clientId}/config/account/${accountId}/envelope`;

        return super.getData<any>(url, clientId);
    }

    getEnvelopeDocumentSigners(accountId: any, envelopeId: any, envelopeSignerId: any) {
        const clientId = this.appService.getClientId('');
        const url = `${this.publicApiBaseUrl}client/${clientId}/config/account/${accountId}/envelope/${envelopeId}/documentsigners/${envelopeSignerId}`;

        return super.getData<any>(url, clientId);
    }


    addEnvelopeSigner(envelopeSigner: EnvelopeSigner, accountId) {
        const clientId = this.appService.getClientId('');
        const url = `${this.publicApiBaseUrl}client/${clientId}/config/account/${accountId}/envelopesigner`;

        return super.postData<EnvelopeSigner>(url, envelopeSigner, clientId);
    }

    editEnvelopeSigners(envelopeSigners: EnvelopeSigner[], accountId) {
        const clientId = this.appService.getClientId('');
        const url = `${this.publicApiBaseUrl}client/${clientId}/config/account/${accountId}/envelopesigners`;

        return super.putData<EnvelopeSigner[]>(url, envelopeSigners, clientId);
    }

    getEnvelopeDocuments(accountId: any, envelopeId: any) {
        const clientId = this.appService.getClientId('');
        const url = `${this.publicApiBaseUrl}client/${clientId}/config/account/${accountId}/envelope/${envelopeId}/envelopedocuments`;

        return super.getData<EnvelopeDocument[]>(url, clientId);
    }

    getEnvelopeSigners(accountId: string, envelopeId: string) {
        const clientId = this.appService.getClientId('');
        const url = `${this.publicApiBaseUrl}client/${clientId}/config/account/${accountId}/envelope/${envelopeId}/envelopesigners`;

        return super.getData<EnvelopeSigner[]>(url, clientId);
    }

    saveEnvelopeDocumentSigner(accountId: any, envelopeId: any, envelopeDocumentSigner: any, isNew: boolean) {
        const clientId = this.appService.getClientId('');
        const url = `${this.publicApiBaseUrl}client/${clientId}/config/account/${accountId}/envelope/${envelopeId}/documentsigner`;
        if (isNew) {
            return super.postData(url, envelopeDocumentSigner, clientId);
        } else {
            return super.putData(url, envelopeDocumentSigner, clientId);
        }
    }

    deleteEnvelope(accountId: any, envelopeId: any) {
        const clientId = this.appService.getClientId('');
        const url = `${this.publicApiBaseUrl}client/${clientId}/config/account/${accountId}/envelope/${envelopeId}`;

        return super.deleteData(url, clientId);
    }

    deleteEnvelopeSigner(accountId: any, envelopeId: any, envelopeSignerId: any) {
        const clientId = this.appService.getClientId('');
        const url = `${this.publicApiBaseUrl}client/${clientId}/config/account/${accountId}/envelope/${envelopeId}/signer/${envelopeSignerId}`;

        return super.deleteData(url, clientId);
    }

    deleteEnvelopeDocument(accountId: any, envelopeId: any, envelopeSignerId: any, envelopeDocumentId: any) {
        const clientId = this.appService.getClientId('');
        const url = `${this.publicApiBaseUrl}client/${clientId}/config/account/${accountId}/envelope/${envelopeId}/signer/${envelopeSignerId}/document/${envelopeDocumentId}`;

        return super.deleteData(url, clientId);
    }

    getSignatureProviderSummary(accountId: any, caseId: any) {
        const clientId = this.appService.getClientId('');
        const url = `${this.publicApiBaseUrl}client/${clientId}/case/${caseId}/account/${accountId}/signatureProviderSummary`;

        return super.getData<any>(url, clientId);
    }
}
