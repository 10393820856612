import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { FeatureManagerService } from '../global/feature-manager/feature-manager.service';

@Injectable()
export class FeatureToggleGuard implements CanActivate {
    constructor(private featureManagerService: FeatureManagerService) { }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
        return new Promise<boolean>(resolve => {
            this.featureManagerService.loadFeatureFlags();
            this.featureManagerService.featureFlags$.subscribe(featureFlags => {
                if (featureFlags)
                    resolve(true);
            });
        });
    }
}
