  <div class="mx-auto w-100">
  <form [formGroup]="interviewForm" (ngSubmit)="saveGeneralSettings()" novalidate autocomplete="off">
    <div class="page-borders">
      <div class="flex flex-row main-page-heading ">
        <div>Application</div>
        <div class="flex-1"></div>
        <div style="justify-content: flex-end; padding-bottom: 5px;">
          <mrs-btn-primary class="float-right"  buttonLabel="Save" buttonId="saveButton"
            type="submit">
          </mrs-btn-primary>
        </div>
      </div>
    </div>
    <div class="m-5">
      <div class="page-sub-header">Disable Application</div>
      <mat-card appearance="outlined">
        <mat-card-content style="margin-left: 5px">
          <auto-suggest [list]="rules" placeholder="Disable application on success of rule" field="name" dataKey="id"
            formControlName="disableInterviewRuleId">
          </auto-suggest>
          <mat-radio-group formControlName="interviewReadOnlyOnDisable"
            [disabled]="interviewForm.get('disableInterviewRuleId').value == ''"
            style="display: flex; flex-direction: column;">
            <mat-radio-button [value]="false" class="mt-2">Hide Case Details</mat-radio-button>
            <mat-radio-button [value]="true" class="mt-2">Read only view of Case Details</mat-radio-button>
          </mat-radio-group>
        </mat-card-content>
      </mat-card>
      <div class="page-sub-header mt-4">Sections & Questions</div>
      <mat-card appearance="outlined">
        <mat-card-content style="margin-left: 5px">
          <div class="flex flex-row space-between">
            <div class="flex flex-row" style=" margin-right:5rem">
              <div class="flex" style="flex-direction: column; width: 200px">
                <div class="sub-heading" style="margin-top:0; margin-bottom: 0 !important">Share Mapkeys</div>
                <div>If a Mapkey is used in later sections, it will be pre-populated.  If the value is changed, a note will be added for an audit warning, but the case will not be in error.</div>
              </div>
              <mat-slide-toggle class="sub-heading d-inline-block" style="margin-top:0" formControlName="shareMapKeysAcrossSections">
              </mat-slide-toggle>
            </div>
            
            <div class="flex flex-row" *ngIf="usePrefillQuestions">
              <div class="flex" style="flex-direction: column; width: 200px">
                <div class="sub-heading" style="margin-top:0; margin-bottom: 0 !important">Pre-Fill Answers</div>
                <div>Multiple choice single answer question types, set up to use a dynamic list, can be used to pre-fill answers in other questions.</div>
              </div>
              <mat-slide-toggle id="prefillToggle" class="sub-heading d-inline-block" style="margin-top:0" formControlName="prefillQuestions">
              </mat-slide-toggle>
            </div>            
            <div class="flex flex-row" *ngIf="useAddNoteForVariants">
              <div class="flex" style="flex-direction: column; width: 200px">
                <div class="sub-heading" style="margin-top:0; margin-bottom: 0 !important">Add note for variants</div>
                <div>Add system log note for Variant Lists where multiple rules are true and default list is used.</div>
              </div>
              <mat-slide-toggle id="addNoteForVariants" class="sub-heading d-inline-block" style="margin-top:0" formControlName="addNoteForVariants">
              </mat-slide-toggle>
            </div>
          </div>
        </mat-card-content>
      </mat-card>

      <div *ngIf="runtime2Enabled || useFilteredVmks || useVmkPerformance">
        <div class="page-sub-header mt-4">Beta</div>
        <mat-card appearance="outlined">
          <mat-card-content style="margin-left: 5px;">
            <div class="flex flex-row" *ngIf="runtime2Enabled">
              <mat-slide-toggle class="d-inline-block" formControlName="runtime2Enabled" labelPosition="after">
                Enable Runtime 2.0 during the application
              </mat-slide-toggle>
              <mat-icon style="margin-bottom: 4px;" class="mat-icon-gray"
                title="When this is enabled the runtime will use version 2 and should only be used by testers at this time.">
                information
              </mat-icon>
            </div>
            <div class="flex flex-row" *ngIf="useFilteredVmks">
              <mat-slide-toggle class="d-inline-block" formControlName="interviewFilteredVmks" labelPosition="after">
                Filter VMKs to evaluate during section processing
              </mat-slide-toggle>
              <mat-icon style="margin-bottom: 4px;" class="mat-icon-gray"
                title="When this is enabled the runtime will only process vmks relevant to the
                current section.">
                information
              </mat-icon>
            </div>
            <div class="flex flex-row mt-2" *ngIf="useVmkPerformance">
              <mat-slide-toggle class="d-inline-block" formControlName="vmkPerformanceUpdates" labelPosition="after">
                Enable enhanced VMK performance during the application
              </mat-slide-toggle>
              <mat-icon style="margin-bottom: 4px;" class="mat-icon-gray"
                title="When this is enabled the runtime will use the enhanced logic for vmk evaluation to increase performance.">
                information
              </mat-icon>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </form>
</div>
