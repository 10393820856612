
  <mat-form-field id="filterCasesFormField" class="filter-cases full-width mt-2" floatLabel="never" appearance="fill">
    <input matInput placeholder="Search for client or account" name="filterValue" (input)="filterChanged($event.target.value)">
    <mat-icon class="case-summary-search-icon" aria-hidden="false" aria-label="Case search icon" matSuffix>
      search
    </mat-icon>
  </mat-form-field>
  <div [ngStyle] = "{
    'height': treeViewHeight,
    'overflow-y': 'auto'
  }">
  <div class="ml-1 all-selection flex flex-row" style="padding-bottom: 10px">
    <mat-icon svgIcon="unfold-less-horizontal" (click)="toggleExpansion()" *ngIf="expanded" style="margin-left: 3px"></mat-icon>
    <mat-icon svgIcon="unfold-more-horizontal" (click)="toggleExpansion()" *ngIf="!expanded" style="margin-left: 3px"></mat-icon>
    <mat-checkbox (click)="selectAll()" ([ngModel])="all" style="margin-left: 4px"> All </mat-checkbox>
  </div>
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" *ngIf="data">
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
      <button mat-icon-button disabled></button>
      <mat-checkbox class="checklist-leaf-node" [checked]="checklistSelection.isSelected(node)"
        (change)="checklistSelection.toggle(node); selectionChange();">{{node.name}}</mat-checkbox>
    </mat-tree-node>
    <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
      <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.filename">
        <mat-icon class="mat-icon-rtl-mirror">
          {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
        </mat-icon>
      </button>
      <mat-checkbox [checked]="descendantsAllSelected(node)" [indeterminate]="descendantsPartiallySelected(node)"
        (change)="todoItemSelectionToggle(node)">{{node.name}}</mat-checkbox>
    </mat-tree-node>
  </mat-tree>
</div>
