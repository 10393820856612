<div class="flex flex-row flex-fill">
    <span class="flex-fill">
        <div [className]="uwLabelName ? 'hasADecision spacing' : 'incomplete spacing'">
            {{ uwLabelName ? uwLabelName : 'UW Incomplete' }}
            <span *ngIf="uwDecisionNote" class="dateStyle">
                {{ uwDecisionNote.lastModifiedDate | date:'short' }}
            </span>
        </div>
        <div *ngIf="uwDecisionNote" class="noteContent">{{ uwDecisionNote.noteContent }}</div>
    </span>
    <span class="m-1 flex-end">
        <mrs-btn-outline *ngIf="isDecisionButtonVisible" buttonLabel="Update Decision" buttonId="editDecisionBtn" (onClick)="buttonClick.emit()"></mrs-btn-outline>
    </span>
</div>