import { Component, OnInit, Input } from '@angular/core';
import { AppService } from '../../../app.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import * as _ from 'lodash';
import { HttpClient } from '@angular/common/http';
import { Account } from '../../../models';
import { ConfirmationDialogService } from '@Services';

@Component({
    selector: 'account-rx',
    host: {'class': 'd-flex flex-row flex-fill'},
    templateUrl: './account-rx.component.html',
    styleUrls: ['./account-rx.component.scss']
})
export class AccountRxComponent implements OnInit {

    readonly DrugLabelMaxLength = 125;
    readonly DrugClassMaxLength = 125;
    readonly TreatedConditionMaxLength = 200;
    readonly InterviewerDirectiveMaxLength = 200;

    @Input() accountInfo: Account;

    rxRisks: any = [];
    rxForm: UntypedFormGroup;

    showForm = false;
    showContent = false;
    selectedRx: any = {};
    importFile: any;
    drugRiskTypes: any = [];
    fileData: any;

    constructor(
        private appService: AppService,
        private _fb: UntypedFormBuilder,
        private confirmationService: ConfirmationDialogService,
        private httpClient: HttpClient
    ) {
    }

    ngOnInit() {
        const numberType = /^\d+$/;
        this.rxForm = this._fb.group({
            id: '',
            drugLabel: ['', Validators.compose([Validators.required, Validators.maxLength(this.DrugLabelMaxLength)])],
            drugClass: ['', Validators.compose([Validators.maxLength(this.DrugClassMaxLength)])],
            drugRiskDateBoundaryStart: ['', Validators.compose([Validators.pattern(numberType)])],
            drugRiskDateBoundaryEnd: ['', Validators.compose([Validators.pattern(numberType)])],
            treatedCondition: ['', Validators.compose([Validators.maxLength(this.TreatedConditionMaxLength)])],
            drugRiskTypeId: ['', Validators.required],
            interviewerDirective: ['', Validators.compose([Validators.maxLength(this.InterviewerDirectiveMaxLength)])],
            accountId: [this.accountInfo.id, Validators.required],
        });

        this.drugRiskTypes = this.appService.getConfigData('drugRiskType');
        this.getRxRisks();
    }

    checkACL(permissionType, redirect?) {
        return this.appService.checkACL('Accounts', permissionType, redirect);
    }

    changelabelOrder(from, to) {
        this.rxRisks.splice(to, 0, this.rxRisks.splice(from, 1)[0]);
    }

    getRxRisks() {
        this.appService.getData(`RxRiskMap/${this.accountInfo.id}`).subscribe(
            data => {
                if (data.status === 'success') {
                    this.rxRisks = data.data;
                }
            }
        );
    }

    addRxRisk() {
        this.showForm = true;
        this.selectedRx = {};
        this.rxForm.patchValue({
            id: '',
            drugLabel: '',
            drugClass: '',
            drugRiskDateBoundaryStart: '',
            drugRiskDateBoundaryEnd: '',
            treatedCondition: '',
            drugRiskTypeId: '',
            interviewerDirective: '',
            accountId: this.accountInfo.id,
        });
    }

    editRxRisk() {
        this.rxForm.patchValue(this.selectedRx);
        this.showForm = true;
    }

    getRxInfo(label) {
        this.showForm = false;
        this.selectedRx = label;
    }

    cancel() {
        this.showForm = false;
        this.selectedRx = {};
    }

    saveRxRisk() {
        if (this.rxForm.valid) {
            let isEdit = false;
            let url = 'RxRiskMap';
            if (this.selectedRx.id) {
                isEdit = true;
                url = `RxRiskMap/${this.selectedRx.id}`;
            }
            this.rxForm.value.drugRiskDateBoundaryStart = parseInt(this.rxForm.value.drugRiskDateBoundaryStart);
            this.rxForm.value.drugRiskDateBoundaryEnd = parseInt(this.rxForm.value.drugRiskDateBoundaryEnd);

            this.appService.postData(url, this.rxForm.value, isEdit, false)
                .subscribe(
                    data => {
                        if (data.status === 'success') {
                            this.showForm = false;
                            this.selectedRx = Object.assign({}, data.data);
                            this.getRxRisks();
                            this.appService.showMsg('success', 'Saved successfully ...');
                        } else {
                            this.appService.showMsg('error', data.message);
                        }
                    }
                );
        }
    }


    deleteRxRisk(id) {
        this.confirmationService.confirm({
            message: 'Are you sure that you want to delete this RX Risk?',
            key: 'rxRiskConfirm',
            accept: () => {
                this.appService.deleteData(`RxRiskMap/${id}`).subscribe(
                    data => {
                        if (data.status === 'success') {
                            this.showForm = false;
                            this.showContent = false;
                            this.selectedRx = null;
                            this.getRxRisks();
                            this.appService.showMsg('success', data.message);
                        }
                    }
                );
            },
            showCancel: true
        });

    }

    getDrugRiskType(drugRiskTypeId) {
        if (drugRiskTypeId && this.drugRiskTypes && this.drugRiskTypes.length) {
            return _.find(this.drugRiskTypes, ['id', drugRiskTypeId]).code;
        } else {
            return '';
        }
    }

    onClick(event) {
        event.target.value = null;
    }

    selectFile(event) {
        const files = event.target.files;
        if (!files) {
            return;
        } else {
            const extension = files[0].name.match(/\.([^\.]+)$/)[1];
            if (extension.toLowerCase() === 'xlsx') {
                const formData: FormData = new FormData();
                for (let i = 0; i < files.length; i++) {
                    formData.append(i.toString(), files[i], files[i].name);
                }
                this.fileData = formData;
                this.importSavedXLSXFile();
            } else {
                this.appService.showMsg('error', 'Please upload the xlsx document type only', false);
            }
        }
    }

    importSavedXLSXFile() {
        this.appService.display(true);
        const url = `${this.appService.getAPIBaseURL()}RxRiskMap/ImportData/${this.accountInfo.id}`;
        this.httpClient.post(url, this.fileData, {
            headers: this.appService.getUploadHttpHeaders()
        }).subscribe({
            next: (response) => {
                let data: any;
                if (response['_body']) {
                    data = JSON.parse(response['_body']);
                } else {
                    data = response;
                }

                if (data.status === 'success') {
                    this.getRxRisks();
                    this.fileData = undefined;
                    this.appService.showMsg('success', data.message);
                } else {
                    this.appService.showMsg('error', data.message);
                }
            },
            error: (error) => {
                this.appService.showResponseErrorMsg(error);
                this.appService.display(false);
            }
        });
    }
}
