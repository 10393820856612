import {Directive, HostListener, Input} from '@angular/core';

@Directive({
    selector: '[appMaxLength]'
})
export class MaxLengthDirective {
  @Input() appMaxLength;

  @HostListener('keydown', ['$event']) onKeydown(event) {
      const value = event.target.value;
      const maxLength = parseInt(this.appMaxLength);
      const keycode = event.button || event.keyCode;
      const allowedKeycodes = [8, 13, 16, 35, 36, 46, 37, 38, 39, 40];
      const keyCodeIndex = allowedKeycodes.indexOf(keycode);
      if ((value.length > maxLength - 1) && (keyCodeIndex === -1)) {
          event.preventDefault();
          event.stopPropagation();
      }
  }
}
