import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IConfigService } from '@Config';
import { CaseBraintreePayload } from '@Models';
import { BaseService, ObservableService } from '@Services';
import { AppService } from 'app';

@Injectable()
export class BraintreeService extends BaseService {
    publicApiBaseUrl: string;

    constructor(
        public appService: AppService,
        public httpClient: HttpClient,
        public configService: IConfigService,
        public observableService: ObservableService
    ) {
        super(appService, configService, httpClient);
        this.publicApiBaseUrl = this.configService.getConfiguration().publicApiUrl;
    }

    async submitPayment(payload: CaseBraintreePayload) {
        return await super.postDataAsync(`${this.publicApiBaseUrl}client/${payload.clientId}/account/${payload.accountId}/case/${payload.caseId}/integration/${payload.caseIntegrationQueueId}/braintree/vaultpayment`, payload);
    }
}
