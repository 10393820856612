import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { AppService } from 'app/app.service';
import { ClientService, NotificationSeverity, UserAdminService } from 'app/services';
import { SidenavService } from 'app/shared/services/sidenav.service';
import { takeUntil } from 'rxjs/operators';
import { TreeItemFlatNode, TreeItemNode } from 'app/shared/components/mrs-treeview/mrs-treeview.component';
import { Subject } from 'rxjs';
import { SubSink } from 'subsink';
import * as _ from 'lodash';

@Component({
    selector: 'app-manage-edit-page',
    templateUrl: './edit-users.component.html',
    styleUrls: ['./edit-users.component.scss']
})
export class EditUsersComponent implements OnInit, OnDestroy {
    @ViewChild(MatStepper) stepper: MatStepper;
    @Input() selectedUser: any;
    @Input() accounts: any[];
    @Output() reloadUsers = new EventEmitter<boolean>();

    treeControl = new NestedTreeControl<any>(node => node.children);
    dataSource = new MatTreeNestedDataSource<any>();
    userForm: UntypedFormGroup;
    roles: any;
    title: any;
    accountTree: any;
    filterValue: string;
    treeData: TreeItemNode[];
    enterpriseUser = false;
    showForm = false;
    selectedClientsAndAccounts: any[];
    fieldTextType: boolean;
    private _destroy$ = new Subject();
    private subs = new SubSink();

    constructor(private _fb: UntypedFormBuilder,
                private userAdminService: UserAdminService,
                private sideNavService: SidenavService,
                private appService: AppService,
                public clientService: ClientService) {

    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    ngOnInit(): void {
        this.userForm = this._fb.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            email: ['', Validators.required],
            userRoleId: ['', Validators.required],
            sso: [''],
            password: ['', Validators.required],
            agentId: [''],
            statusId: ''
        });

        this.getRoles();
        this.setupForm();
        this.getAccounts();

        this.dataSource.data = this.accounts;
    }

    getAccounts() {
        this.subs.add(this.clientService.getClientAndParentAccounts()
            .pipe(takeUntil(this._destroy$))
            .subscribe(async result => {
                this.treeData = [];

                this.treeData = result.map(function (element) {
                    const node = {
                        children: element.accounts,
                        level: '0.1',
                        parentId: null,
                        id: element.id,
                        name: element.name,
                        expandable: true
                    };

                    node.children.forEach(account => {
                        account.level = '0.1.1';
                        account.parentId = element.id;
                    });

                    node.children = _.sortBy(node.children, ['name']);

                    return node;
                });
            }));
    }

    setupForm() {
        if (this.selectedUser && this.selectedUser !== null) {
            this.title = 'Edit User';

            this.enterpriseUser = this.selectedUser.connectionType === 'Enterprise';

            this.userForm.patchValue({
                firstName: this.selectedUser.firstName,
                lastName: this.selectedUser.lastName,
                email: this.selectedUser.email,
                userRoleId: this.selectedUser.userRoleId,
                agentId: this.selectedUser.agentId,
                statusId: this.selectedUser.statusId
            });

            this.setupFields();

            this.showForm = true;

            this.userForm.get('password').setValidators([]);
        } else {
            this.title = 'Add User';
            this.enterpriseUser = false;
            this.setupFields();
            this.showForm = true;
        }

        this.stepper?.reset();
    }

    setupFields() {
        if (this.enterpriseUser) {
            this.userForm.get('firstName').disable();
            this.userForm.get('lastName').disable();
            this.userForm.get('email').disable();
            this.userForm.get('userRoleId').disable();
        } else {
            this.userForm.get('firstName').enable();
            this.userForm.get('lastName').enable();
            this.userForm.get('email').enable();
            this.userForm.get('userRoleId').enable();
        }
    }

    getRoles() {
        this.subs.add(this.appService.getData('UserRoles').subscribe(
            data => {
                if (data.status === 'success') {
                    this.roles = data.data.sort((a, b) => a.roleName.localeCompare(b.roleName));
                } else {
                    this.roles = [];
                }
            }));
    }

    cancel() {
        this.sideNavService.close();
    }

    saveUser() {
        if (this.userForm.valid) {
            const user = {
                id: this.selectedUser?.id,
                firstName: this.userForm.get('firstName').value,
                lastName: this.userForm.get('lastName').value,
                email: this.userForm.get('email').value,
                userRoleId: this.userForm.get('userRoleId').value,
                password: this.userForm.get('password').value,
                agentId: this.userForm.get('agentId').value,
                connectionType: '',
                clients: this.selectedClientsAndAccounts,
                role: '',
                organization: '',
                statusId: this.userForm.get('statusId').value
            };

            user.role = this.roles.find(x => x.id === user.userRoleId)?.roleName;

            if (this.enterpriseUser) {
                user.connectionType = 'Enterprise';
            } else {
                user.connectionType = 'Database';
            }

            if (user.clients.length > 1) {
                user.organization = 'MRS';
            }

            if (this.selectedUser && this.selectedUser !== null) {
                user.id = this.selectedUser.id;
                this.subs.add(this.userAdminService.editUser(user).subscribe(() => {
                    this.stepper.reset();
                    this.sideNavService.close();

                    this.appService.showMsg(NotificationSeverity.Success, `Changes to ${user.firstName} ${user.lastName} have been saved.`);
                    this.reloadUsers.emit(true);
                }));
            } else {
                this.subs.add(this.userAdminService.createUser(user).subscribe(() => {
                    this.stepper.reset();
                    this.sideNavService.close();

                    this.appService.showMsg(NotificationSeverity.Success, `User ${user.firstName} ${user.lastName} has been added succesfully.`);
                    this.reloadUsers.emit(true);
                }));
            }


        } else {
            this.appService.showMsg(NotificationSeverity.Error, 'Fix the form errors');
        }
    }

    updateSelectedClients(selectionModel: TreeItemFlatNode[]) {
        this.selectedClientsAndAccounts = [];

        selectionModel?.forEach(item => {
            if (item.level === 1) {
                let client = this.selectedClientsAndAccounts.find(x => x.id === item.parentId);

                if (!client || client == null) {
                    client = {
                        id: item.parentId,
                        accounts: []
                    };
                    this.selectedClientsAndAccounts.push(client);
                }

                client.accounts.push({id: item.id});
            }

        });
    }

    toggleFieldTextType() {
        this.fieldTextType = !this.fieldTextType;
    }

    connectionTypeChange(val) {
        this.enterpriseUser = val.checked;

        if (this.enterpriseUser) {
            this.userForm.get('password').disable();
            this.userForm.get('password').setValidators([]);
        } else {
            this.userForm.get('password').enable();
            this.userForm.get('password').setValidators([Validators.required]);
        }
    }
}


