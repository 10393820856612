import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { lastValueFrom } from 'rxjs';
import { IConfigService } from '../../config/iconfigservice';
import { Client, WhiteLabelTheme } from '../../models';
import { AccountDataService } from '../account-data.service';
import { ClientDataService } from '../client-data.service';

@Injectable()
export class WhiteLabelService {
    constructor(
    private clientDataService: ClientDataService,
    private configService: IConfigService,
    private accountDataService: AccountDataService) { }

    _domain: string = '.uwpipeline.com';
    _whitelabelStyleBlock: HTMLStyleElement;


    public getCurrentTheme(themeOverride?: string): Promise<WhiteLabelTheme> {
        const theme = this.resolveTheme(themeOverride);
        const environmentalizedTheme = this.environmentalizeThemeResources(theme);
        return Promise.resolve(environmentalizedTheme);
    }

    public generateStyleTag(theme: WhiteLabelTheme): HTMLStyleElement {
        const style = document.createElement('style');
        style.type = 'text/css';

        // Need the !importants below because these are all housed in component-specific stylesheets.  We could do better overrides
        // if we moved them out to the root level, but not sure its worth the trade off.
        let clientStyle = `
      #header {
          background: ${theme.header.backgroundColor} !important;
      }

      .logo-container {
          background-image: url(${theme.resources.logoPrimaryUrl}) !important;
          height: ${theme.header.logoHeight} !important;
          width: ${theme.header.logoWidth} !important;
          flex: 0 0 ${theme.header.logoWidth} !important;
      }

      .login-container .logo {
          background-image: url(${theme.resources.logoLoginUrl}) !important;
          max-width: ${theme.login.logoWidth} !important;
          height: ${theme.login.logoHeight} !important;
      }

      .login-container-background {
        background-color: ${theme.login.containerBackground} !important;
      }

      .modal-dark-background {
        background-color: ${theme.header.modalBackgroundColor};
        color: #fff;
      }
      .modal-dark-background mat-dialog-container {
          background-color: ${theme.header.modalBackgroundColor};
      }
    `;

        if (theme.header.headerTextColor) {
            clientStyle += `
        .toggle-menu {
          color: ${theme.header.headerTextColor} !important;
        }

        #header .text-white {
          color: ${theme.header.headerTextColor} !important;
        }
      `;
        }

        if (theme.login.background) {
            clientStyle += `
        .body{
        background: ${theme.login.background} !important;
      }
        `;
        }

        style.appendChild(document.createTextNode(clientStyle));



        return style;
    }

    public applyTheme(theme: WhiteLabelTheme): void {
        document.title = theme.title;

        const head = document.getElementsByTagName('head')[0];

        // favicon
        const favicon: any = document.querySelector('link[rel*="icon"]') || document.createElement('link');
        favicon.type = 'image/x-icon';
        favicon.rel = 'shortcut icon';
        favicon.href = theme.resources.faviconUrl;
        head.appendChild(favicon);

        // style block
        if (this._whitelabelStyleBlock) {
            head.removeChild(this._whitelabelStyleBlock);
        }

        this._whitelabelStyleBlock = this.generateStyleTag(theme);
        head.appendChild(this._whitelabelStyleBlock);
    }

    private environmentalizeThemeResources(theme: WhiteLabelTheme): WhiteLabelTheme {
        const rootUrl = this.configService.getWhiteLabelConfiguration().whiteLabelResourceUrl;
        if (theme.resources) {
            for (const key in theme.resources) {
                theme.resources[key] = `${rootUrl}/${theme.resources[key]}`;
            }
        }

        return theme;
    }

    public resolveTheme(themeOverride?: string): WhiteLabelTheme {
        const hostname = themeOverride || window.location.hostname;

        let subdomain = hostname.split('.')[0];

        const subdomainHyphen = subdomain.indexOf('-');

        if (subdomainHyphen > 0) { // dev-royalneighbors --> royalneighbors what about localhost:4200/login?debug.theme=vibrant-docusign?
            subdomain = subdomain.slice(subdomainHyphen + 1);
        }

        const targetTheme = this.configService.getWhiteLabelConfiguration().subdomainThemes.find(t => t.subdomain === subdomain) || this.configService.getWhiteLabelConfiguration().themeDefaults;

        return _.defaultsDeep({}, targetTheme, this.configService.getWhiteLabelConfiguration().themeDefaults);
    }

    async isWhiteLabelValidForUser(themeOverride?: string) {
        const currentTheme = this.resolveTheme(themeOverride);

        let result = false;

        if (currentTheme.code !== '') {
            const clients = await lastValueFrom(this.clientDataService.getClients(true));
            const target = clients.find(client => client.code == currentTheme.code);

            if (target && target != null) {

                result = await this.ValidateAccount(currentTheme, target);
            }

        } else {
            result = true;
        }

        return result;
    }

    async ValidateAccount(currentTheme: WhiteLabelTheme, client: Client) {
        if (!currentTheme.accountCode || currentTheme.accountCode == '') {
            return true;
        }

        const accounts = await lastValueFrom(this.accountDataService.getAccounts());
        const target = accounts.find(account => account.code == currentTheme.accountCode);

        if (target && target != null) {
            return true;
        }

        return false;
    }
}
