<div class="mx-auto page-borders" *ngIf="showForm" style="width: 100%;">
  <form [formGroup]="ftpSettingsForm" (ngSubmit)="saveSettings()" novalidate autocomplete="off">
    <mat-card appearance="outlined">

      <mat-card-header>
        <mat-card-title>Case Transfer Settings</mat-card-title>
      </mat-card-header>
      <input type="hidden" formControlName='id'>
      <input type="hidden" formControlName='accountId'>
      <input type="hidden" formControlName='name' value='Case Transmit'>
      <mat-card-content>
        <div class="sub-heading">On/Off Settings</div>
        <div class="mt-3">
          <mat-slide-toggle class="d-inline-block" formControlName="transmissionEnabled" labelPosition="before">
            Case Package Transmit</mat-slide-toggle>
        </div>
        <auto-suggest [list]="rules" placeholder="Case Package Transmit Rule" field="name" dataKey="id"
          formControlName="transmissionRule">
        </auto-suggest>
        <div [hidden]="!ftpSettingsForm.get('transmissionEnabled').value" style="width: 500px">
          <div class="sub-heading">Schedule/Timing Settings</div>
          <mat-form-field class="w-100 mt-3">
            <mat-label>Transmission Schedule</mat-label>
            <mat-select formControlName='transmissionSchedule'>
              <mat-option>Default</mat-option>
              <mat-option value="Custom">Custom</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="w-100 mt-3">
            <mat-label>Case Transmit Timeout (minutes)</mat-label>
            <input matInput type="number" formControlName="caseTransmitTimeout" />
            <mat-error
              *ngIf="(ftpSettingsForm.get('caseTransmitTimeout').hasError('min') || ftpSettingsForm.get('caseTransmitTimeout').hasError('max')) && !ftpSettingsForm.get('caseTransmitTimeout').hasError('required')">
              Please enter a value between 5 and 1440 minutes (24 hours)
            </mat-error>
            <mat-error *ngIf="ftpSettingsForm.get('caseTransmitTimeout').hasError('required')">
              Case Transmit Timeout is <strong>required</strong>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="w-100">
            <mat-label>Wait time (in seconds)</mat-label>
            <input type="number" matInput formControlName="waitTime">
            <mat-error *ngIf="ftpSettingsForm.get('waitTime').hasError('required')">required</mat-error>
          </mat-form-field>
          <div class="sub-heading">Package Contents</div>
          <div>
            <mat-slide-toggle class="d-inline-block" formControlName="zipCasePackage" labelPosition="before"
              (change)="zipCasePackageChanged($event.checked)">Zip Case Package</mat-slide-toggle>
          </div>
          <div class="mt-3">
            <mat-slide-toggle class="d-inline-block" formControlName="excludeCallsFromCaseTransfer"
              (change)="onExcludeCallsToggle($event)" labelPosition="before">Exclude Calls from Case Transfer
            </mat-slide-toggle>
          </div>
          <div class="mt-3" *ngIf="!ftpSettingsForm.get('excludeCallsFromCaseTransfer').value">
            <mat-slide-toggle class="d-inline-block" formControlName="callRecordingsRequired" labelPosition="before">
              Require Call Recording(s) for Case Transfer</mat-slide-toggle>
          </div>
          <div class="mt-3" *ngIf="!ftpSettingsForm.get('excludeCallsFromCaseTransfer').value">
            <mat-slide-toggle class="d-inline-block" formControlName="associatedCallsRequired" labelPosition="before">
              Require Associated Call(s) for Case Transfer</mat-slide-toggle>
          </div>
          <mat-form-field class="w-100 mt-3 custom-tool-tip">
            <div class="flex">
              <mat-label>Case Package Filename</mat-label>
              <input matInput formControlName="casePackageFilename" />
              <i aria-hidden="true" class="fas fa-info-circle" [matTooltip]="toolTipText"
                matTooltipClass="multiline-tooltip"></i>
            </div>
            <mat-error *ngIf="ftpSettingsForm.get('casePackageFilename').hasError('required')">
              Case Package Filename is <strong>required</strong>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="w-100 mt-3 custom-tool-tip"
            *ngIf="!ftpSettingsForm.get('excludeCallsFromCaseTransfer').value">
            <div class="flex">
              <mat-label>Recording Filename</mat-label>
              <input matInput formControlName="recordingFilename" autocomplete="off">
              <i aria-hidden="true" class="fas fa-info-circle" [matTooltip]="recordingToolTipText"
                matTooltipClass="multiline-tooltip"></i>
            </div>
            <mat-error *ngIf="ftpSettingsForm.get('recordingFilename').hasError('required')">
              Recording Filename is <strong>required</strong>
            </mat-error>
          </mat-form-field>
          <div class="sub-heading">Connection Settings</div>
          <mat-form-field class="w-100">
            <mat-label>Protocol</mat-label>
            <mat-select formControlName='protocol'>
              <mat-option value="FTP">FTP</mat-option>
              <mat-option value="SFTP">SFTP</mat-option>
            </mat-select>
            <mat-error *ngIf="ftpSettingsForm.get('protocol').hasError('required')">required</mat-error>
          </mat-form-field>
          <mat-form-field class="w-100">
            <mat-label>Server Name</mat-label>
            <input matInput formControlName="serverName">
            <mat-error *ngIf="ftpSettingsForm.get('serverName').hasError('required')">required</mat-error>
          </mat-form-field>
          <mat-form-field class="w-100">
            <mat-label>Port Number</mat-label>
            <input matInput formControlName="portNumber">
            <mat-error *ngIf="ftpSettingsForm.get('portNumber').hasError('required')">required</mat-error>
          </mat-form-field>
          <mat-form-field class="w-100">
            <mat-label>Authentication Mode</mat-label>
            <mat-select formControlName="authenticationMode" #AuthenticationMode
              (selectionChange)="changeAuthenticationMode($event)">
              <mat-option *ngFor="let mode of authenticationModes" [value]="mode.id">{{mode.name}}</mat-option>
            </mat-select>
            <mat-error *ngIf="ftpSettingsForm.get('authenticationMode').hasError('required')">required</mat-error>
          </mat-form-field>
          <mat-form-field class="w-100">
            <mat-label>Username</mat-label>
            <input matInput formControlName="userName">
            <mat-error *ngIf="ftpSettingsForm.get('userName').hasError('required')">required</mat-error>
          </mat-form-field>
          <auto-suggest [list]="secretKeys" placeholder="Password" field="name" dataKey="id" formControlName="secretUri"
            [showRequiredError]="ftpSettingsForm.get('secretUri').hasError('required')">
          </auto-suggest>
          <auto-suggest [list]="secretKeys" placeholder="Passphrase URI" field="name" dataKey="id"
            formControlName="passphraseUri" *ngIf="ftpSettingsForm.get('authenticationMode').value == 2"
            [showRequiredError]="ftpSettingsForm.get('passphraseUri').hasError('required')">
          </auto-suggest>
          <mat-form-field class="w-100">
            <mat-label>Wait time (in seconds)</mat-label>
            <input type="number" matInput formControlName="waitTime">
            <mat-error *ngIf="ftpSettingsForm.get('waitTime').hasError('required')">required</mat-error>
          </mat-form-field>
          <mat-form-field class="w-100">
            <mat-label>Remote File Path</mat-label>
            <input matInput formControlName="remoteFilePath">
            <mat-error *ngIf="ftpSettingsForm.get('remoteFilePath').hasError('required')">required</mat-error>
          </mat-form-field>
          <auto-suggest [list]="secretKeys" placeholder="PGP Encryption" field="name" dataKey="id"
            formControlName="pgpEncryption">
          </auto-suggest>
        </div>
        <div class="pt-4 flex" style="justify-content: flex-end;">
          <button type="button" mat-button (click)='cancel()' class="mr-1 material-default">
            CANCEL
          </button>
          <button type="submit" mat-flat-button color="primary">
            SAVE
          </button>
        </div>
      </mat-card-content>
    </mat-card>
  </form>
</div>

<div class="mx-auto mt-3" *ngIf="!showForm" style="width: 100%;">
  <mat-card appearance="outlined">
    <mat-card-header>
      <mat-card-title-group>
        <mat-card-title>
          <h2>Case Transfer Settings</h2>
        </mat-card-title>
        <button mat-flat-button color="primary" class="float-right" (click)="editSetting()" *ngIf="checkACL('W')">
          <i aria-hidden="true" class="fa fa-edit pr-1"></i>Edit
        </button>
      </mat-card-title-group>
    </mat-card-header>
    <mat-card-content style="margin-left: 5px">
      <div class="sub-heading">On/Off Settings</div>
      <div class="mt-3">
        <mat-slide-toggle class="d-inline-block" disabled="true" [checked]="params?.transmissionEnabled" color="primary"
          labelPosition="before">
          Case Package Transmit</mat-slide-toggle>
      </div>
      <div class="mt-3">
        <mat-card-content>
          <div class="label-title">Case Package Transmit Rule</div>
          <div class="label-content">{{params?.transmissionRuleName}}</div>
        </mat-card-content>
      </div>
      <div [hidden]="!params?.transmissionEnabled">
        <div class="sub-heading">Schedule/Timing Settings</div>
        <mat-card-content>
          <div class="label-title">Transmission Schedule</div>
          <div class="label-content">{{(params?.transmissionSchedule || 'Default')}}</div>
        </mat-card-content>
        <mat-card-content>
          <div class="label-title">Case Transmit Timeout</div>
          <div class="label-content">{{params?.caseTransmitTimeout}}</div>
        </mat-card-content>
        <mat-card-content>
          <div class="label-title">Wait Time (in minutes)</div>
          <div class="label-content">{{params?.waitTime}}</div>
        </mat-card-content>
        <div class="sub-heading">Package Contents</div>
        <mat-card-content>
          <div class="label-title">Zip Case Package</div>
          <div class="label-content">{{params?.zipCasePackage || 'False'}}</div>
        </mat-card-content>
        <mat-card-content>
          <div class="label-title">Exclude Calls from Case Transfer</div>
          <div class="label-content">{{(params?.excludeCallsFromCaseTransfer ? 'True' : 'False')}}
          </div>
        </mat-card-content>
        <mat-card-content *ngIf="!params?.excludeCallsFromCaseTransfer">
          <div class="label-title">Call Recording(s) during Case Transfer</div>
          <div class="label-content">{{(params?.callRecordingsRequired ? 'Required' : 'Optional')}}
          </div>
        </mat-card-content>
        <mat-card-content *ngIf="!params?.excludeCallsFromCaseTransfer">
          <div class="label-title">Associate Call(s) for Case Transfer</div>
          <div class="label-content">{{(params?.associatedCallsRequired ? 'Required' : 'Optional')}}
          </div>
        </mat-card-content>
        <mat-card-content>
          <div class="label-title">Case Package Filename</div>
          <div class="label-content">{{params?.casePackageFilename}}</div>
        </mat-card-content>
        <mat-card-content *ngIf="!params?.excludeCallsFromCaseTransfer">
          <div class="label-title">Recording Filename</div>
          <div class="label-content">{{params?.recordingFilename}}</div>
        </mat-card-content>

        <div class="sub-heading">Connection Settings</div>
        <div class="label-title">Protocol</div>
        <div class="label-content">{{params?.protocol}}</div>
        <div class="label-title">Server Name</div>
        <div class="label-content">{{params?.serverName}}</div>
        <div class="label-title">Port Number</div>
        <div class="label-content">{{params?.portNumber}}</div>
        <div class="label-title">Authentication Mode</div>
        <div class="label-content">{{getAuthenticationName(params?.authenticationMode)}}</div>
        <div class="label-title">Username</div>
        <div class="label-content">{{params?.userName}}</div>
        <div class="label-title">Password</div>
        <div class="label-content">{{getSecretKeyLabel(params?.secretUri)}}</div>
        <ng-template [ngIf]="params?.authenticationMode == 2">
          <div class="label-title">Passphrase URI</div>
          <div class="label-content">{{getSecretKeyLabel(params?.passphraseUri)}}</div>
        </ng-template>
        <div class="label-title">Remote File Path</div>
        <div class="label-content">{{params?.remoteFilePath}}</div>
        <div class="label-title">PGP Encryption</div>
        <div class="label-content">{{getSecretKeyLabel(params?.pgpEncryption)}}</div>
      </div>
    </mat-card-content>
  </mat-card>
</div>