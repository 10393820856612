<div class="question-container" [formGroup]="group" [ngClass]="{active: isSelected, interviewQuestion: interviewMode}">
  <div [ngClass]="group.get(config.id)?.hasError('validationError')  && !services.interviewService.isInterviewLocked? 'invalid-question' : ''">
    <div class="question-header" *ngIf="!interviewMode" (click)="selectQuestion()">
      <button type="button" class="btn btn-link float-left" (click)="expanded = !expanded">
        <i class="fa" [ngClass]="{'fa-angle-right': !expanded, 'fa-angle-down': expanded}"></i>
      </button>
      <span class="question-mapkey">
                {{config.mapKeyName}}
            </span>
      <span class="question-mapkey float-right pr-2">
                {{config.answerTypeName}}
            </span>
        </div>
        <div class="question-body" *ngIf="expanded">
            <div class="question-text rendered-quill-content ql-editor" [innerHTML]="questionText"></div>
            <div class="help-text rendered-quill-content ql-editor" [innerHTML]="helpText" *ngIf="config.helpText?.length"></div>
            <div class="question-form">
                <button type="button" [attr.data-test-input-type]="inputType" [disabled]="isReadOnly() || services.interviewService.isInterviewLocked" mat-raised-button color="primary" (click)="onClickActionButton()">{{config?.ButtonText}}</button>
                <mat-error *ngIf="group.get(config.id)?.hasError('validationError') && !services.interviewService.isInterviewLocked">{{group.get(config.id)?.errors.validationError}}</mat-error>
            </div>
            <app-field-details [question]="config" [form]="group" [readOnlyMode]="isReadOnly()"></app-field-details>
        </div>
    </div>
</div>
