import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { AccountStatusEnum, FeatureToggle } from "app/enums";
import { ClientService, FeatureManagerService } from "app/services";
import { Observable, Subject } from "rxjs";
import { map, startWith, takeUntil, tap } from "rxjs/operators";
import * as _ from 'lodash';
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ClientAccount } from "app/reports/shared/client-account.interface";
import { Account } from "@Models";

@Component({
    selector: 'client-account-dropdown',
    styleUrls: ['./client-account-dropdown.component.scss'],
    templateUrl: './client-account-dropdown.component.html'
})
export class ClientAccountDropdownComponent implements OnInit, OnDestroy {

    @Input() formField;
    @Input() formGroup;
    @Input()
    set clientAccountSplitOverride(value: boolean){
        this._clientAccountSplitOverride = value;
        this.newDropdownToggle = this.featureManagerService.getByName(FeatureToggle.GlobalOrderDetailReportUpdates).enabled && value;
    }
    get clientAccountSplitOverride(): boolean {
        return this._clientAccountSplitOverride;
    }

    clients: ClientAccount[];
    clientControl: UntypedFormControl = new UntypedFormControl('', [Validators.required]);
    newDropdownToggle = false;
    filteredClients: Observable<any[]>;
    filteredAccounts: Observable<any>;
    private _destroy$ = new Subject();
    private _clientAccountSplitOverride: boolean;


    constructor(
        private clientService: ClientService,
        private featureManagerService: FeatureManagerService
    ) {
        if (!this.formGroup) this.formGroup = new UntypedFormGroup({});

    }

    ngOnInit(): void {
        this.newDropdownToggle = this.featureManagerService.getByName(FeatureToggle.GlobalOrderDetailReportUpdates).enabled && this.clientAccountSplitOverride;
        this.getAccounts();
    }

    getAccounts() {
        this.clientService.getClientsAndAccounts(false, true)
            .pipe(takeUntil(this._destroy$))
            .subscribe(async result => {
                this.clients = result;
                this.getFilteredClients(this.clients);
                this.clients.forEach(client => {
                    client.activeAndDraftAccounts = [];
                    client.accounts.forEach(account => {
                        client.activeAndDraftAccounts.push(...account.versions.filter(function (o) {
                            return o.statusId !== AccountStatusEnum.Inactive;
                        }));
                    });

                    client.activeAndDraftAccounts = _.sortBy(client.activeAndDraftAccounts, ['name']);
                });
            });
    }

    getFilteredClients(clientData: ClientAccount[]) {
        if (this.newDropdownToggle) {
            this.filteredClients = this.clientControl.valueChanges.pipe(
                startWith(''),
                tap((value) => {
                    if (value) {
                        this.formGroup.get(this.formField).enable();
                    } else {
                        this.formGroup.get(this.formField).disable();
                    }
                    this.formGroup.get(this.formField).setValue('');
                    this.getFilteredAccounts(value);
                }),
                map(value => (typeof value === 'string' ? value : value.name)),
                map(name => (name ? this._clientFilter(name) : clientData.slice()))
            );
        }
    }

    getFilteredAccounts(selectedClient: ClientAccount) {
        const client: ClientAccount = this.clients.find(x => x === selectedClient);
        if (client) {
            this.filteredAccounts = this.formGroup.get(this.formField).valueChanges.pipe(
                startWith(''),
                map(value => (typeof value === 'string' ? value : (value as Account).name)),
                map((name:string) => (name ? this._accountFilter(name, client) : client.accounts.filter(a => a.versions.some(v => v.statusId !== AccountStatusEnum.Inactive)).slice()))
            );
        }
    }

    ngOnDestroy() {
        this._destroy$.next(1);
        this._destroy$.unsubscribe();
    }

    private _clientFilter(name: string): ClientAccount[] {
        const filterValue = name.toLowerCase();

        return this.clients.filter(option => option.name.toLowerCase().includes(filterValue) || option.code.toLowerCase().includes(filterValue));
    }

    private _accountFilter(name: string, client:ClientAccount): Account[] {
        const filterValue = name.toLowerCase();
        return client.accounts.filter( a => a.name.toLowerCase().includes(filterValue) || a.code.toLowerCase().includes(filterValue));
    }

    clientDisplayFn(item: ClientAccount): string {
        return item && item.name ? item.name : '';
    }

    accountDisplayFn(item: Account): string {
        return item && item.name ? item.name : '';
    }
}
