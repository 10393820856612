import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { InterviewService } from '../../interview.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { CaseNotificationsService, FeatureToggleService } from '../../services';
import { InterviewTerminateComponent } from './interview-terminate.component';
import { FeatureToggle } from 'app/enums';

@Component({
    selector: 'interview-close',
    templateUrl: 'interview-close.component.html',
    styleUrls: ['../interview.component.scss']
})
export class InterviewCloseComponent implements OnInit {

    interviewTerminateForm: any;
    isTerminate = false;
    accountInfo: any;
    clickSource: any;
    exitText = 'Interview';
    discardText = 'Discard Interview';

    @ViewChild('discardInterview') discardInterviewDialog: TemplateRef<any>;
    constructor(
        public dialogRef: MatDialogRef<InterviewCloseComponent>,
        public interviewService: InterviewService,
        public caseNotificationsService: CaseNotificationsService,
        private featureToggleService: FeatureToggleService,
        public dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit() {
        const isCaseCreated = this.interviewService.getInterviewData().caseDetails;
        if (!isCaseCreated) {
            this.dialogRef.close(this.data.clickSource);
        }

        this.accountInfo = this.data.accountInfo;
        if (!this.accountInfo) {
            this.accountInfo = this.interviewService.account;
        }

        this.clickSource = this.data.clickSource;
        this.interviewService.clickSource = this.clickSource;
        this.interviewTerminateForm = new UntypedFormGroup({
            terminateMessage: new UntypedFormControl('', [Validators.required])
        });
        
        if (this.data.isTerminate) {
            this.isTerminate = true;
            this.terminate();
        }

        this.featureToggleService.getFeatureFlag(FeatureToggle.GlobalTextInterviewToApplication).subscribe(result => {
            if (result){
                this.exitText = 'Application';
                this.discardText = 'Discard Application';
            }
        });
    }

    close() {
        this.dialogRef.close();
    }

    terminate() {
        this.interviewTerminateForm.patchValue({
            terminateMessage: '',
        });

        this.isTerminate = true;

        this.dialog.open(InterviewTerminateComponent,
            {
                width: '520px',
                disableClose: true,
                data: this.data
            });

        this.close();
    }

    submitTerminateReason() {
        if (this.interviewTerminateForm.valid) {
            this.interviewService.autoSaveInterview(this.accountInfo.id, 'TerminateInterview', this.interviewTerminateForm.value.terminateMessage, this.clickSource);
            this.interviewService.flushInterviewMetrics();
            this.dialogRef.close(this.clickSource);
        }
    }

    interviewOnHold() {
        this.interviewService.autoSaveInterview(this.accountInfo.id, 'InterviewOnHold', '', this.clickSource);
        this.interviewService.flushInterviewMetrics();
        this.dialogRef.close(this.clickSource);
    }

    cancelTerminate() {
        this.close();
        this.interviewService.clearCancelActionButton();
    }
}
