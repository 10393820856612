import { Injectable } from '@angular/core';
import { AppService } from 'app/app.service';
import * as _ from 'lodash';
import { Subject } from 'rxjs';

@Injectable()
export class AccountInterviewService {
    private onMoveQuestionSource = new Subject();
    onMoveQuestion = this.onMoveQuestionSource.asObservable();
    private onSectionSelectSource = new Subject<any>();

    onSectionSelect = this.onSectionSelectSource.asObservable();
    private onQuestionSelectSource = new Subject<any>();
    onQuestionSelect = this.onQuestionSelectSource.asObservable();
    private onPreviewQuestionSelectSource = new Subject<any>();
    onPreviewQuestionSelect = this.onPreviewQuestionSelectSource.asObservable();
    private onReflexiveChoiceSelectSource = new Subject<any>();
    onReflexiveChoiceSelect = this.onReflexiveChoiceSelectSource.asObservable();
    private onGetReflexiveQuestionsSource = new Subject<any>();
    onGetReflexiveQuestions = this.onGetReflexiveQuestionsSource.asObservable();
    private onGetRepeatBlockQuestionsSource = new Subject<any>();
    onGetRepeatBlockQuestions = this.onGetRepeatBlockQuestionsSource.asObservable();
    private onDeleteSectionSource = new Subject<any>();
    onDeleteSection = this.onDeleteSectionSource.asObservable();
    private onDeleteQuestionSource = new Subject<any>();
    onDeleteQuestion = this.onDeleteQuestionSource.asObservable();
    private onChangeSectionOrderSource = new Subject<any>();
    onChangeSectionOrder = this.onChangeSectionOrderSource.asObservable();
    private onChangeQuestionOrderSource = new Subject<any>();
    onChangeQuestionOrder = this.onChangeQuestionOrderSource.asObservable();
    private answerTypes: any[];

    constructor(private appService: AppService) {

        this.answerTypes = appService.getConfigData('answerTypes');
    }

    selectSection(section) {
        this.onSectionSelectSource.next(section);
    }

    selectQuestion(data) {
        this.onQuestionSelectSource.next(data);
    }

    selectPreviewQuestion(data) {
        this.onPreviewQuestionSelectSource.next(data);
    }

    selectReflexiveChoice(data) {
        this.onReflexiveChoiceSelectSource.next(data);
    }

    getReflexiveQuestions(data) {
        this.getReflexiveQuestionsData(data);
    }

    getRepeatBlockQuestions(data) {
        this.onGetRepeatBlockQuestionsSource.next(data);
    }

    deleteSection(data) {
        this.onDeleteSectionSource.next(data);
    }

    deleteQuestion(data) {
        this.onDeleteQuestionSource.next(data);
    }

    changeSectionOrder(data) {
        this.onChangeSectionOrderSource.next(data);
    }

    changeQuestionOrder(data) {
        this.onChangeQuestionOrderSource.next(data);
    }

    moveQuestionToSection(question: any, toSectionId: any) {
        return this.appService.updateData(`Questions/${question.id}/${toSectionId}`, question, undefined, true).subscribe(
            {
                next: (data) => {
                    this.onMoveQuestionSource.next({success: true, data: data});
                },
                error: (data) => this.onMoveQuestionSource.next({success: false, data: data})
            });
    }

    getReflexiveQuestionsData(question) {
        this.appService.getData(`Questions/${question.id}`, null).subscribe(
            data => {
                if (data.status === 'success') {
                    // filter answer choices with no reflexive questions
                    const reflexiveChildren = data.data.multiChoiceAnswers[0].constantKeyNavigation.constantMapKeyValues.filter(choice => choice.reflexiveQuestions.length);

                    reflexiveChildren.forEach(choice => {
                        choice.reflexiveQuestions.forEach((reflexiveQuestion) => {
                            this.restructureQuestion(reflexiveQuestion);
                            const objectProperties = data.data.reflexiveQuestions.find(reflexive => reflexive.refQuestionId === reflexiveQuestion.id)?.objectProperties;
                            if (objectProperties) {
                                reflexiveQuestion['objectProperties'] = objectProperties;
                            }
                        });
                    });

                    question['reflexiveChildren'] = _.sortBy(reflexiveChildren, function (o) {
                        return o.order;
                    });
                    question['isReflexiveQuestionsLoaded'] = true;
                    this.onGetReflexiveQuestionsSource.next(data);
                }
            }
        );
    }

    restructureQuestion(question) {
        if (question.propertyValues) {
            question.propertyValues.forEach(property => {
                question[property.name] = property.value;
            });
        }

        question.mapKeyName = (question.questionTitle.length ? question.questionTitle : question.mapKeyName);

        question['reflexiveQuestionsSource'] = JSON.parse(JSON.stringify(question.reflexiveQuestions));
        question['removedReflexiveQuestions'] = [];

        if (question.refQuestionId) { // reflexive question
            question.id = question.refQuestionId;
        }

        question['answerTypeName'] = this.getAnswerTypeName(question.answerType);
    }

    getAnswerTypeName(answerTypeCode) {
        const answerType = _.find(this.answerTypes, ['code', answerTypeCode]);

        if (answerType) {
            return answerType.name;
        } else {
            return answerTypeCode;
        }
    }
}
