import { FeatureToggle } from "@Enums";
import { CurrencyPipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { TableColumnDefinition } from 'app/shared/components/mrs-table/models/table-models';
import { AppService } from '../../app.service';
import { Account, AccountsByClient } from '../../models';
import { FeatureManagerService, ReportDownloadService, RoutesEnum, RoutingService } from '../../services';
import moment = require('moment');

@Component({
    selector: 'billing-report',
    host: { 'class': 'd-flex flex-row flex-fill custom-scrollbar' },
    templateUrl: './billing-report.component.html',
    styleUrls: ['./billing-report.component.scss']
})
export class BillingReportComponent implements OnInit {

    public accounts: any = [];
    public caseStatus: any;
    public orderForm: UntypedFormGroup;
    public filteredData: any = [];
    public selectedRecord: any;
    public clients: AccountsByClient;
    public activeAccount: Account;

    noDataPlaceholder: boolean;
    timeZone: string;
    tableConfig: any = [];
    displayedColumns: TableColumnDefinition[];
    reportData: any;
    searchableFields: [];
    clientAccountOverride = false;

    @Input() scrollWidth;
    @Input() scrollHeight;

    @ViewChild('allSelected') private allSelected: MatOption;

    constructor(
        private appService: AppService,
        private reportDownloadService: ReportDownloadService,
        private _fb: UntypedFormBuilder,
        private routingService: RoutingService,
        private currencyPipe: CurrencyPipe,
        private featureManagerService: FeatureManagerService
    ) {
        this.clientAccountOverride = this.featureManagerService.getByName(FeatureToggle.GlobalReportsBillingReportClientAccountSplit).enabled;
    }

    ngOnInit() {
        this.timeZone = this.appService.getTimeZone();
        this.appService.getAllConfigData().then((data) => {
            const configData = data;
            this.caseStatus = configData.caseStatus;
        });
        this.orderForm = this._fb.group({
            account: ['', Validators.required],
            startDate: ['', Validators.required],
            endDate: ['', Validators.required],
            orderStateIds: ['', Validators.required]
        });
    }

    getSelectedAccount() {
        return this.orderForm.get('account').value;
    }

    getReport() {
        if (this.orderForm.valid) {
            this.getTableConfig();
            this.filteredData = [];
            const params = this.getParams();
            const url = `Reports/GetBillingReport`;
            this.scrollWidth = 1440;

            this.appService.postData(url, params, false, false, params.account.clientId)
                .subscribe(
                    response => {
                        if (response.status === 'success') {
                            this.activeAccount = this.getSelectedAccount();
                            const billingEvents = response.data.billingEvents;
                            billingEvents.forEach(billingEvent => {
                                this.scrollWidth += 100;
                                this.displayedColumns.push(
                                    {
                                        def: this.camelize(billingEvent.name),
                                        header: billingEvent.name,
                                        sortFields: this.camelize(billingEvent.name),
                                        columnStyles:
                                        {
                                            'width': '100px',
                                        },
                                        rowDefs: [
                                            {
                                                className: '',
                                                dataName: this.camelize(billingEvent.name),
                                            }
                                        ]
                                    }
                                );
                            });

                            this.filteredData = response.data.billingReports;

                            this.reportData = (response.data.billingReports || []).map(c => (Object.assign({}, c, {
                            }, c.customEvents.reduce((dict, next) => {
                                dict[this.camelize(next.name)] = this.currencyPipe.transform(next.value);
                                return dict;
                            }, {}))));

                            this.getTableConfig();
                            this.reportData = this.reportData.map((data) => ({
                                ...data,
                                received: moment(data.received).utc().local().format('MM/DD/yyyy'),
                                completed: moment(data.completed).utc().local().format('MM/DD/yyyy'),
                                totalCaseFees: this.currencyPipe.transform(data.totalCaseFees),
                                completedInterviewFees: this.currencyPipe.transform(data.completedInterviewFees),
                                caseNonBillable: data.caseNonBillable === true ? 'true' : 'false',
                                caseUrl: this.getCaseUrl(data)
                            }));
                        }
                        this.noDataPlaceholder = !this.filteredData.length;
                    }
                );
        }
    }

    getCaseUrl(data) {
        const clientCode = this.activeAccount.code.split('-');

        return this.routingService.createUrl(RoutesEnum.caseSummary, {
            clientCode: clientCode[0],
            accountCode: this.activeAccount.code,
            versionNumber: this.activeAccount.versionNumber,
            caseId: data.case_Id
        });
    }

    downloadReport(fileType) {
        const params = this.getParams();

        let url = '';
        if (fileType === 'csv') {
            url = 'Reports/DownloadCSV/GetBillingReport';
        } else if (fileType === 'xls') {
            url = 'Reports/DownloadExcel/GetBillingReport';
        }

        fileType = 'application/' + fileType;

        this.reportDownloadService.downloadReport(fileType, url, params, this.getSelectedAccount().clientId);
    }

    getTableConfig() {
        this.displayedColumns = [
            {
                def: 'actions',
                header: 'Actions',
                columnStyles:
                {
                    'width': '50px'
                },
                isAction: true,
                actions: [{
                    name: 'Mark Billable',
                    actionType: 'markBillable',
                    textColor: "#2C6EED",
                    permission: true,
                    conditions: [
                        {
                            showProperty: 'caseNonBillable',
                            showValue: 'true'
                        }
                    ]
                },
                {
                    name: 'Mark Non-Billable',
                    actionType: 'markNonBillable',
                    textColor: "#2C6EED",
                    permission: true,
                    conditions: [
                        {
                            showProperty: 'caseNonBillable',
                            showValue: 'false'
                        }
                    ]
                }]
            },
            {
                def: 'caseBilled',
                header: 'Case Billed',
                sortFields: ['caseBilled'],
                columnStyles:
                {
                    'width': '150px',
                },
                rowDefs: [
                    {
                        isIcon: true,
                        className: '',
                        iconNameConditionTrue: 'check_circle',
                        iconNameConditionFalse: 'close',
                        dataName: 'caseBilled'
                    }
                ]
            },
            {
                def: 'accountVersion',
                header: 'Account Version',
                sortFields: ['accountVersion'],
                columnStyles:
                {
                    'width': '100px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'accountVersion',
                    }
                ]
            }, {
                def: 'caseNumber',
                header: 'Case #',
                sortFields: ['caseNumber'],
                columnStyles:
                {
                    'width': '150px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'caseNumber',
                        isLink: true,
                        urlField: 'caseUrl'
                    }
                ]
            },
            {
                def: 'applicant',
                header: 'Applicant Name',
                sortFields: ['applicant'],
                columnStyles:
                {
                    'width': '150px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'applicant',
                    }
                ]
            },
            {
                def: 'casestatus',
                header: 'Status',
                sortFields: ['casestatus'],
                columnStyles:
                {
                    'width': '100px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'casestatus',
                    }
                ]
            },
            {
                def: 'uwDecisionLabelName',
                header: 'Decision Label',
                sortFields: ['uwDecisionLabelName'],
                columnStyles:
                {
                    'width': '120px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'uwDecisionLabelName',
                    }
                ]
            },
            {
                def: 'policyNumber',
                header: 'Policy',
                sortFields: ['policyNumber'],
                columnStyles:
                {
                    'width': '150px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'policyNumber',
                    }
                ]
            },
            {
                def: 'received',
                header: 'Received',
                sortFields: ['received'],
                columnStyles:
                {
                    'width': '100px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'received',
                    }
                ]
            },
            {
                def: 'completed',
                header: 'Completed',
                sortFields: ['completed'],
                columnStyles:
                {
                    'width': '200px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'completed',
                    }
                ]
            },
            {
                def: 'completedInterviewFees',
                header: 'Rollup Fees',
                sortFields: ['completedInterviewFees'],
                columnStyles:
                {
                    'width': '120px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'completedInterviewFees',
                    }
                ]
            },
            {
                def: 'totalCaseFees',
                header: 'Total Case Fees',
                sortFields: ['totalCaseFees'],
                columnStyles:
                {
                    'width': '100px',
                },
                rowDefs: [
                    {
                        className: '',
                        dataName: 'totalCaseFees',
                    }
                ]
            }
        ];

    }

    getParams() {
        const params = JSON.parse(JSON.stringify(this.orderForm.value));
        params.accountId = params.account.id;
        params.startDate = new Date(params.startDate);
        const end = new Date(params.endDate);
        end.setHours(23, 59, 59, 999);
        params.endDate = end;

        const idx = params.orderStateIds.indexOf("ALL");
        if (idx > -1) {
            params.orderStateIds.splice(idx, 1);
        }
        return params;
    }

    changeToBilled() {
        const url = `Reports/CaseAsBilled`;
        this.appService.postData(url, this.filteredData, false, false, this.getSelectedAccount().clientId).subscribe(
            data => {
                if (data.status === 'success') {
                    this.appService.showMsg('success', data.message);
                    this.getReport();
                }
            },
            error => this.appService.showMsg('error', error)
        );
    }

    changeBillable(caseId, option) {
        this.appService.postData(`Interview/${caseId}/CaseNonBillable/${option}`, {}, true, false, this.getSelectedAccount().clientId).subscribe(
            {
                next: (data) => {

                    let caseNonBillable: boolean;

                    if (data.status === 'success') {
                        caseNonBillable = option;
                        this.appService.showMsg('success', data.message);
                        this.getReport();
                    } else {
                        caseNonBillable = !option;
                        this.appService.showMsg('error', data.message);
                    }
                },
                error: () => {
                    this.filteredData.forEach(item => {
                        if (item.case_Id === caseId) {
                            item.caseNonBillable = !option;
                        }
                    });
                }
            });
    }

    tableAction(event) {
        if (event.action?.actionType === 'markBillable') {
            this.changeBillable(event.record?.case_Id, 'false');
        }

        if (event.action?.actionType === 'markNonBillable') {
            this.changeBillable(event.record?.case_Id, 'true');
        }
    }

    checkACL(permissionType, redirect?) {
        return this.appService.checkACL('Billing', permissionType, redirect);
    }

    camelize(str) {
        return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
            if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
            return index === 0 ? match.toLowerCase() : match.toUpperCase();
        });
    }

    togglePerOne(all) {
        if (this.allSelected.selected) {
            this.allSelected.deselect();
            return false;
        }
        if (this.orderForm.controls.orderStateIds.value.length == this.caseStatus.length)
            this.allSelected.select();

    }
    toggleAllSelection() {
        if (this.allSelected.selected) {
            this.orderForm.controls.orderStateIds
                .patchValue([...this.caseStatus.map(item => item.id), "ALL"]);
        } else {
            this.orderForm.controls.orderStateIds.patchValue([]);
        }
    }
}
