import { FormControl } from '@angular/forms';

export function removeError(control: FormControl, errorKey: string): void {
    if (!control.errors) return;

    const errors = control.errors;
    delete errors[errorKey];

    Object.keys(errors).length == 0
        ? control.setErrors(null)
        : control.setErrors(errors);
}
