<div id="config-workbench-requirements-section">
    <div class="sub-section-header">
        <h1 class="title">Requirements</h1>
        <span class="actions" *ngIf="dataLoaded">
            Last Updated: <span *ngIf="lastUpdated; else never">{{ lastUpdated | date: 'M/d/yy, h:mm a' }}</span>
            <ng-template #never>Never</ng-template>
        </span>
    </div>

    <message-box class="message-box" [title]="warning_title" [message]="warning_message"></message-box>

    <mat-card appearance="outlined">
        <mat-card-content class="workbench-requirements-mat-card-content">
            <!-- Loading -->
            <ng-container *ngIf="!dataLoaded || !featureflagsLoaded">
                <mat-spinner mode="indeterminate" diameter="30"></mat-spinner>
            </ng-container>

            <!-- No Requirements -->
            <div *ngIf="dataLoaded && featureflagsLoaded && requirements.length === 0" class="no-requirements">
                <p class="message">No Requirements Added</p>
            </div>

            <!-- Requirements List -->
            <ng-container *ngIf="dataLoaded && featureflagsLoaded && requirements.length !== 0">
                <mat-table class="workbench-requirements-table" [dataSource]="dataSource" cdkDropList (cdkDropListDropped)="handleSortChange($event)" cdkDropListData="dataSource">
                    <!-- Drag&Drop Column -->
                    <ng-container matColumnDef="sortOrder">
                        <mat-header-cell *matHeaderCellDef />
                        <mat-cell *matCellDef="let requirement">
                            <mat-icon class="example-drag-cursor">drag_indicator</mat-icon>
                        </mat-cell>
                    </ng-container>
                    <!-- Name Column -->
                    <ng-container matColumnDef="name">
                        <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                        <mat-cell *matCellDef="let requirement"> {{requirement.name}} </mat-cell>
                    </ng-container>
                    <!-- Section Column -->
                    <ng-container matColumnDef="sectionId">
                        <mat-header-cell *matHeaderCellDef> Section </mat-header-cell>
                        <mat-cell *matCellDef="let requirement">
                            <mat-form-field class="w-100">
                                <mat-select panelClass="auto-width-panel" [(value)]="requirement.sectionId" (selectionChange)="handleSectionChange($event, requirement.id)">
                                    <mat-option *ngFor="let section of sections" [value]="section.id" [disabled]="section.isDisabled && requirement.sectionId != section.id">{{ section.name }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </mat-cell>
                    </ng-container>
                    <!-- Type Column -->
                    <ng-container matColumnDef="typeId">
                        <mat-header-cell *matHeaderCellDef> Type </mat-header-cell>
                        <mat-cell *matCellDef="let requirement">
                            <mat-form-field class="w-100">
                                <mat-select [(value)]="requirement.selectedRequirementTypeId" (selectionChange)="handleTypeChange($event, requirement.id)">
                                    <mat-option [value]="noSection.id">Manual</mat-option>
                                    <mat-optgroup *ngIf="showIntegrationsInTypeDropdown" label="Integration" style="font-size: 16px !important; font-weight: 400 !important;">
                                        <mat-option *ngFor="let postAction of integrationPostActions" [value]="postAction.actionid" [disabled]="postAction.isDisabled && requirement.integrationActionId != postAction.actionid">{{ postAction.name }}</mat-option>
                                    </mat-optgroup>
                                    <mat-optgroup label="Application Workflow Section" style="font-size: 16px !important; font-weight: 400 !important;">
                                        <mat-option *ngFor="let section of sections" [value]="section.id" [disabled]="section.isDisabled && requirement.sectionId != section.id">{{ section.name }}</mat-option>
                                    </mat-optgroup>
                                </mat-select>
                            </mat-form-field>
                        </mat-cell>
                    </ng-container>
                    <!-- Rule Column -->
                    <ng-container matColumnDef="ruleId">
                        <mat-header-cell *matHeaderCellDef> Rule </mat-header-cell>
                        <mat-cell *matCellDef="let requirement">
                            <mat-form-field class="w-100">
                                <mat-select [value]="requirement.ruleId" (selectionChange)="handleRuleChange($event, requirement.id)">
                                    <mat-option *ngFor="let rule of rules" [value]="rule.id" [disabled]="rule.isDisabled && requirement.ruleId != rule.id">{{ rule.name }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </mat-cell>
                    </ng-container>
                    <!-- Summarize / EvidenceProcessor Column -->
                    <ng-container matColumnDef="integrationId">
                        <mat-header-cell *matHeaderCellDef> Summarize With </mat-header-cell>
                        <mat-cell *matCellDef="let requirement">
                            <mat-form-field class="w-100">
                                <mat-select [value]="requirement.selectedEvidenceProcessorId" (selectionChange)="handleSummarizeChange($event, requirement.id)">                            
                                    <mat-select-trigger>{{requirement.formattedIntegrationName}}</mat-select-trigger>
                                    <mat-option [value]="noEvidenceProcessor.id">None</mat-option>
                                    <mat-optgroup label="Integration" style="font-size: 16px !important; font-weight: 400 !important;">
                                        <mat-option *ngFor="let processor of evidenceProcessors" [value]="processor.id" >{{ processor.name }}</mat-option>
                                    </mat-optgroup>
                                </mat-select>
                            </mat-form-field>
                        </mat-cell>
                    </ng-container>
                    <!-- Include in Case Package -->
                    <ng-container matColumnDef="includeInCasePackage">
                        <mat-header-cell *matHeaderCellDef><label>Include In Case Package</label></mat-header-cell>
                        <mat-cell *matCellDef="let requirement">
                            <mat-slide-toggle [matTooltip]="tip_includeOnCasePackage" [checked]="requirement.includeInCasePackage" (change)="handleIncludeInCasePackage($event, requirement.id)"></mat-slide-toggle>
                        </mat-cell>
                    </ng-container>
                    <!-- Auto Summarize Toggle -->
                    <ng-container matColumnDef="autoSummarizeEnabled">
                        <mat-header-cell *matHeaderCellDef><label>Automatically Summarize</label></mat-header-cell>
                        <mat-cell *matCellDef="let requirement">
                            <mat-slide-toggle [matTooltip]="tip_autosummarize" [disabled]="requirement?.evidenceProcessor === undefined || requirement?.evidenceProcessor?.processorId === noEvidenceProcessor.id" [checked]="requirement.autoSummarizeEnabled" (change)="handleAutomaticallySummarizeToggle($event, requirement.id)"/>
                        </mat-cell>
                    </ng-container>
                    <!-- Delete Column -->
                    <ng-container matColumnDef="delete">
                        <mat-header-cell *matHeaderCellDef></mat-header-cell>
                        <mat-cell *matCellDef="let requirement">
                            <span class="delete-button column">
                                <button (click)="handleDeleteRequirement(requirement)"><mat-icon>delete</mat-icon></button>
                            </span>
                        </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: columnsToDisplay;" cdkDrag [cdkDragData]="row"></mat-row>
                </mat-table>
            </ng-container>
            <!--The button needs to show up even when there are no requirements.-->
            <br />
            <div>
                <mrs-btn-tertiary test-id="add_requirement_button" buttonLabel="Add Requirement" iconName="add" textColor="#2C6EED" (onClick)="handleOpenAddRequirement()"></mrs-btn-tertiary>
            </div>
        </mat-card-content>
    </mat-card>
</div>


<!-- Add Requirement Modal-->
<ng-template #addRequirement>
    <h1 mat-dialog-title>Add requirement</h1>

    <div mat-dialog-content>
        <!-- Add Requirement Form -->
        <form class="add-requirement">
            <!-- Name -->
            <div>
                <h3>Name</h3>
                <mat-form-field class="w-100">
                    <mat-label>Name</mat-label>
                    <input matInput [formControl]="newRequirementNameControl">
                    <mat-error *ngIf="newRequirementNameControl.errors?.required">Name is required</mat-error>
                    <mat-error *ngIf="newRequirementNameControl.errors?.duplicate">Name is a duplicate</mat-error>
                </mat-form-field>
            </div>

            
            <!-- Section -->
            <div *ngIf="!addUsingNewViewAndAssocation">
                <div *ngIf="!hideSectionConfig">
                    <h3>Section</h3>
                    <mat-form-field class="section-select">
                        <mat-select [formControl]="newRequirementSectionControl" panelClass="auto-width-panel">
                            <mat-option *ngFor="let section of sections" [value]="section.id" [disabled]="section.isDisabled">{{ section.name }}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="newRequirementSectionControl.errors?.duplicateSection">A section can only be associated to one requirement</mat-error>
                    </mat-form-field>
                </div>
            </div>

            <!-- Type-->
            <div *ngIf="addUsingNewViewAndAssocation">
                <div *ngIf="!hideSectionConfig">
                    <h3>Type</h3>
                    <mat-form-field class="section-select">
                        <mat-select [formControl]="newRequirementSectionControl" panelClass="auto-width-panel">
                            <mat-option [value]="noSection.id">Manual</mat-option>
                            <mat-optgroup *ngIf="showIntegrationsInTypeDropdown" label="Integration" style="font-size: 16px !important; font-weight: 400 !important;">
                                <mat-option *ngFor="let postAction of integrationPostActions" [value]="postAction.actionid" [disabled]="postAction.isDisabled">{{ postAction.name }}</mat-option>
                            </mat-optgroup>
                            <mat-optgroup label="Application Workflow Section" style="font-size: 16px !important; font-weight: 400 !important;">
                                <mat-option *ngFor="let section of sections" [value]="section.id" [disabled]="section.isDisabled">{{ section.name }}</mat-option>
                            </mat-optgroup>
                        </mat-select>
                        <mat-error *ngIf="newRequirementSectionControl.errors?.duplicateSection">A section can only be associated to one requirement</mat-error>
                    </mat-form-field>
                </div>
            </div>
            
            <!-- Rule -->
            <div *ngIf="!hideRuleConfig">
                <h3>Rule</h3>
                <div>When true, requirement is added to the Workbench</div>
                <mat-form-field class="rule-select w-100">
                    <mat-select [formControl]="newRequirementRuleControl" panelClass="auto-width-panel">
                        <mat-option *ngFor="let rule of rules" [value]="rule.id" [disabled]="rule.isDisabled">{{ rule.name }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div *ngIf="addUsingNewViewAndAssocation">
                <!-- Summarize With (formerly Association) -->
                <div *ngIf="!hideRuleConfig">
                    <h3>Summarize With</h3>
                    <div>Use requirement output as an input to another process</div>
                    <mat-form-field class="rule-select w-100">
                        <mat-select [formControl]="newRequirementAssociationControl" panelClass="auto-width-panel" (selectionChange)="handleAddRequirementIntegrationChange($event)">   
                            <mat-select-trigger>{{newRequirementFormattedIntegrationName}}</mat-select-trigger>
                            <mat-option [value]="noEvidenceProcessor.id">None</mat-option>                       
                            <mat-optgroup label="Integration" style="font-size: 16px !important; font-weight: 400 !important;">
                                <mat-option *ngFor="let processor of evidenceProcessors" [value]="processor.id" >{{ processor.name }}</mat-option>
                            </mat-optgroup>
                        </mat-select>
                    </mat-form-field>
                    <div *ngIf="showAutoSummarizeControl">
                        <mat-slide-toggle [matTooltip]="tip_autosummarize" [formControl]="newRequirementAutoSummarizeControl">Automatically summarize</mat-slide-toggle>
                    </div>
                    <br />
                    <div *ngIf="showIncludeInPackage">
                        <mat-slide-toggle [matTooltip]="tip_includeOnCasePackage" [formControl]="newRequirementIncludeInCasePackageControl"><h3>Include in Case Package</h3></mat-slide-toggle>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <div mat-dialog-actions class="add-requirement-actions">
        <mrs-btn-tertiary buttonLabel="Cancel" (click)="handleCancelAddRequirement()"></mrs-btn-tertiary>
        <mrs-btn-tertiary buttonLabel="Add" textColor="#2C6EED" [showSpinner]="addingRequirement" (onClick)="handleAddRequirement()"></mrs-btn-tertiary>
    </div>
</ng-template>