import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UWBaseProps, UWRequirementActionEvent, UWRequirementTableData } from '@Underwriting/models';
import { UWRequirementTableActions, UWRequirementTableProps } from '../uw-requirements-expand-table';
import { SubSink } from 'subsink';
import { FeatureManagerService } from 'app/services';
import { FeatureToggle } from 'app/enums';


@Component({
    selector: 'uw-requirements-actions-waived',
    templateUrl: './uw-requirements-actions-waived.component.html',
    styleUrls: ['./uw-requirements-actions-waived.component.scss'],
})
export default class UWRequirementsActionsWaivedComponent {
    @Input() baseProps: UWBaseProps;
    @Input() caseRequirement: UWRequirementTableData;
    @Input() props: UWRequirementTableProps;
    @Output() onMenuAction = new EventEmitter<UWRequirementActionEvent>();

    // Toggles
    allowFollowupOnWaivedRequirements = false;

    subs = new SubSink();

    constructor(
        private featureManagerService: FeatureManagerService,
    ) { }

    //#region Helpers

    public get UWRequirementTableActions() {
        return UWRequirementTableActions;
    }

    //#endregion
    //#region Handlers

    handleMenuAction(columnAction: UWRequirementTableActions, caseRequirement: UWRequirementTableData) {
        const actionEvent: UWRequirementActionEvent = {
            action: columnAction,
            data: caseRequirement
        };
        this.handleMenuActionEvent(actionEvent);
    }

    handleMenuActionEvent(event: UWRequirementActionEvent) {
        this.onMenuAction.emit(event);
    }

    updateAllowFollowupOnWaivedRequirements() {
        const noIntegrationOrLabel = !this.caseRequirement.hasIntegration || !this.props.showRequirementLabel;
        this.allowFollowupOnWaivedRequirements = noIntegrationOrLabel && this.allowFollowupOnWaivedRequirements;
    }
    //#endregion
    //#region Subscriptions

    subscribeToFeatureToggles() {

        this.subs.add(this.featureManagerService.isEnabled(FeatureToggle.UWWorkbenchRequirementFollowUpDatePreservedInEndState, this.baseProps.client.id)
            .subscribe({
                next: isEnabled => {
                    this.allowFollowupOnWaivedRequirements = isEnabled;
                    this.updateAllowFollowupOnWaivedRequirements();
                }
            }));
    }

    //#endregion
    //#region LifeCycle

    async ngOnInit() {
        this.subscribeToFeatureToggles();
    }

    async ngOnDestory() {
        this.subs.unsubscribe();
    }

    //#endregion    
}
