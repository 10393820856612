import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AppService } from "../../app.service";
import * as $ from 'jquery';
import { UntypedFormGroup, UntypedFormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import * as _ from "lodash";
import { RoutesEnum } from '../../services';

@Component({
    selector: 'contacts',
    host: { 'class': 'content' },
    templateUrl: './contacts.component.html',
    styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {

    @Input() type: any;
    @Input() data: any;

    editContactForm: boolean = false;
    editAccountForm: boolean = false;

    contactForm: UntypedFormGroup;
    addContactForm: UntypedFormGroup;
    contactTypeCombo: any;
    showDetails: boolean;

    @Output('onContactSaved') contactSaved: EventEmitter<any> = new EventEmitter<any>();
    @Output('onCancel') clearContact: EventEmitter<any> = new EventEmitter<any>();
    @Output() delete: EventEmitter<any> = new EventEmitter<any>();

    constructor(private appService: AppService) {

        let phoneRegex = /^(1[- .]?)?(\([2-9]\d{2}\)[. -]?|[2-9]\d{2}[. -]?)[2-9]\d{2}[- .]?\d{4}$/;

        this.contactForm = new UntypedFormGroup({
            id: new UntypedFormControl(''),
            clientId: new UntypedFormControl(''),
            firstName: new UntypedFormControl('', [Validators.required]),
            lastName: new UntypedFormControl('', [Validators.required]),
            phone: new UntypedFormControl('', Validators.compose([Validators.required, Validators.pattern(phoneRegex)])),
            email: new UntypedFormControl('', Validators.compose([Validators.required, Validators.pattern("[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})")])),
            isPrimary: new UntypedFormControl(),
        });

        this.addContactForm = new UntypedFormGroup({
            contactsId: new UntypedFormControl({ value: '', disabled: true }),
            contactTypeId: new UntypedFormControl('', [Validators.required]),
        });
    }
    ngOnInit() {
        this.checkACL("R", RoutesEnum.dashboard);
        this.contactTypeCombo = this.appService.combos['ContactTypes'];
        if (!this.data.id) {
            this.contactForm.patchValue(this.data);
            this.editContactForm = true;
        } else {
            this.showDetails = true;
            this.editContactForm = false;
        }
    }
    editContact(data) {
        if (data.accountId) {
            this.editAccountForm = true;
            this.addContactForm.controls["contactsId"].setValue(data.firstName + data.lastName);
            this.addContactForm.controls["contactTypeId"].setValue(data.contactTypeId);
        } else {
            this.editContactForm = true;
            this.contactForm.patchValue(this.data);
        }
        this.showDetails = false;
    }

    cancel() {
        if (!this.data.id) {
            this.clearContact.emit();
        } else {
            this.editAccountForm = false;
            this.editContactForm = false;
            this.showDetails = true;
        }
    }

    saveContact() {
        let isEdit = true;
        if (!this.data.id) {
            delete this.contactForm.value.id;
            isEdit = false;
        }

        if (this.contactForm.valid) {
            this.appService.postData("Contacts", this.contactForm.value, isEdit).subscribe(
                data => {
                    if (data.status == "success") {
                        this.data = Object.assign({}, data.data);
                        this.contactSaved.emit();
                        this.editContactForm = false;
                        this.showDetails = true;
                        this.appService.showMsg("success", "Saved successfully ...");
                    } else {
                        this.appService.showMsg("error", data.message);
                    }
                }
            );
        }
    }

    deleteContact(contact) {
        this.delete.emit(contact);
    }

    updateAccountContact() {
        let params = {
            id: this.data.accountContactId,
            accountId: this.data.accountId,
            contactsId: this.data.id,
            contactTypeId: this.addContactForm.value.contactTypeId
        };
        let url = 'Accounts/' + this.data.accountContactId + '/AccountContacts';
        console.log(url, params);

        if (this.addContactForm.valid) {
            this.appService.postData(url, params, this.editAccountForm, false).subscribe({
                next: (data) => {
                    if (data.status == "success") {
                        this.editAccountForm = false;
                        this.showDetails = true;
                        this.addContactForm.reset();

                        let contactTypeInfo = _.find(this.contactTypeCombo, ['id', data.data['contactTypeId']]);
                        this.data['contactTypeId'] = data.data.contactTypeId;
                        this.data['contactTypeValue'] = contactTypeInfo.name;
                        this.appService.showMsg("success", "Saved successfully ...");
                    } else {
                        this.appService.showMsg("error", data.message);
                    }
                },
                error: () => {
                    this.addContactForm.reset();
                }
            });
        }
    }
    checkACL(permissionType, redirect = undefined) {
        return this.appService.checkACL("Clients", permissionType, redirect);
    }
}