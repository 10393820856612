<div>
  <div class="question-container" [formGroup]="group" style="display: none;">
      <input type="hidden" [formControlName]="config.id" />
  </div>
  <span id="sectionNav">
    <div class="section">
      <!-- <div> where AURA DEUI will run its interview -->
      <div class="content" id="deuiContent"></div>
    </div>
  </span>
</div>
