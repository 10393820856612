import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { AuthLegacyService } from '../../auth/auth-legacy.service';
import { RoutingService, RoutesEnum, ClientDataService } from '../';
import { AppService } from '../../app.service';
import { WhiteLabelService } from '../utility';
import { NotificationSeverity } from '../notification.service';

@Injectable()
export class ValidThemeGuard implements CanActivateChild {
    constructor(
        private appService: AppService,
        public authService: AuthLegacyService,
        public whiteLabelService: WhiteLabelService) {}

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return new Promise(async (resolve) => {
            const clientCode = route.params.clientCode;
            const urlParams = new URLSearchParams(window.location.search);
            const themeOverride = urlParams.get('debug.theme');
            const isValidThemeForUser = await this.whiteLabelService.isWhiteLabelValidForUser(themeOverride);

            if (isValidThemeForUser){
                resolve(true);
            } else {
                this.appService.showMsg(NotificationSeverity.Error, 'Your logged in user does not have the appropriate permissions to this client. Please login using the correct user.');
                this.authService.logout();
                resolve(false);
            }
        });
    }
}
