import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IConfigService } from '@Config';
import { Account, CaseAttachment, CaseDocumentAttachment, CaseMapkey } from '@Models';
import { BaseService } from '@Services';
import { IdleService } from '@Services/utility';
import { AppService, MonitoringService } from 'app';
import { lastValueFrom, Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class FileUploadService extends BaseService {
    ERROR_EmptyFileSize = 'Files must be have a size larger than zero.';

    baseUrl: string;
    publicApiBaseUrl: string;

    uploadProgress = 0;
    stopUploading = false;

    private chunkSizeBytes = 1000000; // 1MB

    constructor(
        public appService: AppService,
        public httpClient: HttpClient,
        public configService: IConfigService,
        public idleService: IdleService,
        public monitoringService: MonitoringService,
    ) {
        super(appService, configService, httpClient);

        this.baseUrl = this.appService.getAPIBaseURL();
        this.publicApiBaseUrl = this.configService.getConfiguration().publicApiUrl;
    }

    //#region Helpers

    private handleError(error: HttpErrorResponse) {
        return throwError(() => new Error(error.message));
    }

    private postChunk<T>(url: string, fileData: any) {

        const result = this.httpClient.post(url, fileData, {
            headers: this.appService.getUploadHttpHeaders(),
            reportProgress: true
        }).pipe(catchError(this.handleError)) as Observable<T>;

        return result;
    }

    private commitInterviewAttachmentChunk(clientId, accountId, caseId, interviewAttachment: CaseDocumentAttachment) {
        const url = `${this.publicApiBaseUrl}client/${clientId}/account/${accountId}/case/${caseId}/upload/interview/commit`;
        return super.postData<CaseMapkey[]>(url, interviewAttachment);
    }

    commitCaseAttachmentChunk(clientId, accountId, caseId, caseAttachment: CaseAttachment) {
        const url = `${this.publicApiBaseUrl}client/${clientId}/account/${accountId}/case/${caseId}/upload/attachment/commit`;
        return super.postData<string>(url, caseAttachment);
    }

    private async uploadChunk(url: string, file: File, attachment: CaseDocumentAttachment | CaseAttachment, metricMessage = 'CaseAttachment Upload') {
        this.uploadProgress = 0;
        this.stopUploading = false;

        const metricEvent = this.monitoringService.beginMetric(metricMessage);

        for (let offset = 0; offset < file.size; offset += this.chunkSizeBytes) {
            if (this.stopUploading) return false;

            attachment.fileName = file.name;
            const chunk = file.slice(offset, offset + this.chunkSizeBytes);

            const formData: FormData = new FormData();
            formData.append('0', chunk, file.name);
            formData.append('data', JSON.stringify(attachment));
            formData.append("chunkIndex", offset.toString());

            const apiResponse = await lastValueFrom(this.postChunk<string>(url, formData));
            attachment.fileId = apiResponse;

            this.uploadProgress = Math.round(100 * offset / file.size);
            this.idleService.resetIdleTimer();
        }

        this.monitoringService.endMetric(metricEvent);
        this.monitoringService.flushMetrics();

        return true;
    }

    //#endregion

    public async sendInterviewAttachmentInChunks(clientId: string, accountId: string, caseId: string, file: File, questionId: string) {
        const interviewAttachment: CaseDocumentAttachment = { 'questionId': questionId, 'caseId': caseId, 'fileName': file.name, 'fileId': null };

        const url = `${this.publicApiBaseUrl}client/${clientId}/account/${accountId}/case/${caseId}/upload/interview/chunk`;
        const commit = await this.uploadChunk(url, file, interviewAttachment, 'CaseAttachment Upload (Interview)');

        if (commit) return this.commitInterviewAttachmentChunk(clientId, accountId, caseId, interviewAttachment);
        return of([]);
    }

    public async sendCaseAttachmentInChunks(clientId: string, accountId: string, caseId: string, caseAttachment: CaseAttachment, file: File) {
        // Upload a file in "chunks" by breaking the file in "chunkSize" pieces and POST each chunk, one at a time, to the API.
        // The API will store the chunks via a fileId (the first chunk will return a fileId we'll save and use for the 2nd+ chunk).
        // When all chunks are upload, we'll need to call the API to "commit" the chunks, which combines/stores it as the full file.
        // Forgetting to "commit" will prevent the file from being created.

        if (file.size == 0)
            return throwError(() => new Error(this.ERROR_EmptyFileSize));

        const url = `${this.publicApiBaseUrl}client/${clientId}/account/${accountId}/case/${caseId}/upload/attachment/chunk`;
        const commit = await this.uploadChunk(url, file, caseAttachment);

        if (commit) return this.commitCaseAttachmentChunk(clientId, accountId, caseId, caseAttachment);
        return of(null);
    }

    public uploadCaseAttachment(clientId: string, account: Account, caseId: string, caseAttachment: CaseAttachment) {
        const url = `${this.publicApiBaseUrl}client/${clientId}/account/${account.id}/${account.versionNumber}/case/${caseId}/attachment`;

        return super.postData(url, caseAttachment, clientId);
    }

    public cancelUpload() {
        this.stopUploading = true;
    }
}
