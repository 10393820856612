import { Injectable } from '@angular/core';
import { ManagementQueue, ManagementQueueAccount, ManagementQueueSettings } from 'app/models';
import { NotificationService, NotificationSeverity, UtilityService } from 'app/services';
import { Subject } from 'rxjs';
import { ManagementQueueUpdates } from '../models/ManagementQueueConfigModel.interface.tx';
import { NewBusinessWorkbenchService } from './new-business-workbench.service';

@Injectable()
export class NewBusinessWorkbenchStateService {
    clientId: any;

    private updatedQueueMessages: string[] = [];
    private settings: ManagementQueueSettings;
    private nbwQueueUpdates: ManagementQueueUpdates;
    private currentQueueAccounts: ManagementQueueAccount[];

    private queueAccountsUpdatedSub = new Subject<ManagementQueueAccount[]>();
    queuesAccountsUpdated$ = this.queueAccountsUpdatedSub.asObservable();

    private queuesUpdatedSub = new Subject<ManagementQueueUpdates>();
    queuesUpdated$ = this.queuesUpdatedSub.asObservable();

    private queuesRemoved = new Subject<string[]>();
    queuesRemovedObservable = this.queuesRemoved.asObservable();

    constructor(
        public notificationService: NotificationService,
        public newBusinessWorkbenchService: NewBusinessWorkbenchService,
        public utilityService: UtilityService
    ) {
        this.newBusinessWorkbenchService.queuesUpdated$.subscribe(updatedQueues => {
            this.updateSavedState(updatedQueues);
        });

        this.newBusinessWorkbenchService.queuesDeleted$.subscribe(deletedQueues => {
            this.removeQueues(deletedQueues);
        });
    }

    //#region Helpers

    private compareQueueData(updatedQueueIds: any, queues: ManagementQueue[]) {
        const updatedQueues = updatedQueueIds.map(id => queues.find(y => y.id == id));

        updatedQueues.forEach(updatedQueue => {
            const existingQueue = this.settings.queues.find(x => x.id == updatedQueue?.id);

            if (existingQueue && existingQueue.name !== updatedQueue.name) {
                this.updatedQueueMessages.push(`'${existingQueue.name}' has been renamed '${updatedQueue.name}'.`);
                this.nbwQueueUpdates.queuesUpdated = true;
            }

            // ***Jon-holding on to this until we implement changing columns on the ui
            // const existingColumns = existingQueue?.managementQueueColumnMapKeys.sort((a, b) => {
            //     if (a.order < b.order) return -1;
            //     if (a.order < b.order) return 1;
            //     return 0;
            // });
            // const updatedColumns = updatedQueue?.managementQueueColumnMapKeys.sort((a, b) => {
            //     if (a.order < b.order) return -1;
            //     if (a.order < b.order) return 1;
            //     return 0;
            // });
            // const columnDifferences = existingColumns?.filter(item1 =>
            //     !updatedColumns.some(item2 => (item2.order === item1.order && item2.mapKeyName === item1.mapKeyName)));
            // if (columnDifferences && columnDifferences.length > 0) {
            //     existingQueue = updatedQueue;
            //     // queueModified = true;
            // }
        }, this);
    }

    private addRemoveQueues(existingQueues: ManagementQueue[], queues: ManagementQueue[]) {
        const existingQueueIds = existingQueues.map(function (a) { return a.id; });
        const currentQueueIds = queues.map(function (a) { return a.id; });

        const difference = existingQueueIds
            .filter(x => !currentQueueIds.includes(x))
            .concat(currentQueueIds.filter(x => !existingQueueIds.includes(x)));


        if (difference && difference.length > 0) {
            difference.forEach(queueId => {
                const currentQueue = queues.find(x => x.id === queueId);

                //remove any queues that permissions have been changed on and I can no longer see it
                if (!currentQueue) {
                    const removedQueueName = this.removeSavedQueue(queueId);
                    if (removedQueueName) {
                        this.nbwQueueUpdates.queuesRemoved = true;
                        this.updatedQueueMessages.push(`'${removedQueueName}' is no longer viewable.`);
                    }
                }

                const existingQueue = existingQueues.find(x => x.id === queueId);

                // add new queues that I now have permissions to see
                if (!existingQueue && currentQueue) {
                    currentQueue.isNewQueue = true;
                    this.nbwQueueUpdates.queuesUpdated = true;
                }
            }, this);
        }

        // retain any new queues that user hasn't navigated to yet
        const existingNewQueues = existingQueues.filter(x => x.isNewQueue);
        existingNewQueues.forEach(queue => {
            const currentQueue = queues.find(x => x.id === queue.id);
            currentQueue.isNewQueue = true;
        });
    }

    private removeSavedQueue(deletedQueueId: any) {
        const idx = this.getSavedSettings()?.queues?.findIndex(x => x.id == deletedQueueId);
        let removedQueueName: string = undefined;

        if (idx > -1) {
            removedQueueName = this.getSavedSettings().queues[idx].name;
            this.getSavedSettings().queues.splice(idx, 1);
        }

        if (this.getSavedSettings().selectedQueue?.id === deletedQueueId) {
            this.getSavedSettings().selectedQueue = undefined;
        }

        return removedQueueName;
    }

    //#endregion

    updateSavedState(updatedQueueIds) {
        this.nbwQueueUpdates = {};
        this.updatedQueueMessages = [];

        if (updatedQueueIds && updatedQueueIds.length > 0) {
            this.newBusinessWorkbenchService.getFilteredWorkbenchQueues(this.clientId).subscribe(queues => {
                const existingQueues = this.settings.queues;
                this.nbwQueueUpdates.queues = queues;
                this.addRemoveQueues(existingQueues, queues);

                //updated queues that have a different name or columns
                this.compareQueueData(updatedQueueIds, queues);

                if (this.settings.selectedQueue == null) {
                    this.nbwQueueUpdates.queuesRemoved = true;
                    this.clearSettings();
                }

                if (this.nbwQueueUpdates.queuesRemoved || this.nbwQueueUpdates.queuesUpdated) {
                    this.queuesUpdatedSub.next(this.nbwQueueUpdates);
                }
            });
        }
    }


    buildMessage() {
        const queueMessages = [];

        if (this.updatedQueueMessages && this.updatedQueueMessages.length > 0) {
            queueMessages.push({
                title: 'Queues have been updated:',
                items: this.updatedQueueMessages
            });
        }

        return queueMessages;
    }

    showQueuesMessage() {
        const queueMessages = this.buildMessage();
        if (queueMessages && queueMessages.length > 0) {
            this.notificationService.showNotification({
                severity: NotificationSeverity.Warning,
                messages: this.updatedQueueMessages
            });

            this.updatedQueueMessages = [];
            this.nbwQueueUpdates = null;
            return true;
        }

        return false;
    }

    removeQueues(deletedQueues) {
        const queueUpdateMessage: ManagementQueueUpdates = {};
        this.updatedQueueMessages = [];

        deletedQueues.forEach(deletedQueueId => {
            const removedQueueName = this.removeSavedQueue(deletedQueueId);
            if (removedQueueName) {
                this.updatedQueueMessages.push(`'${removedQueueName}' is no longer viewable.`);
                queueUpdateMessage.queuesRemoved = true;
            }
        });

        queueUpdateMessage.queues = this.settings.queues;

        if (queueUpdateMessage.queuesRemoved) {
            this.queuesUpdatedSub.next(queueUpdateMessage);
        }
    }


    getNbwQueueUpdates() {
        return this.nbwQueueUpdates;
    }

    saveSettings(data: ManagementQueueSettings) {
        this.settings = data;
    }

    getSavedSettings() {
        if (!this.settings) {
            this.settings = {};
        }
        return this.settings;
    }

    clearSettings() {
        this.settings = null;
    }

    updateQueueAccounts(managementQueueAccounts: ManagementQueueAccount[]) {
        this.queueAccountsUpdatedSub.next(managementQueueAccounts);
    }

    getCurrentQueueAccounts() {
        return this.currentQueueAccounts;
    }

    setCurrentQueueAccounts(managementQueueAccounts: ManagementQueueAccount[]) {
        this.currentQueueAccounts = managementQueueAccounts;
    }
}
