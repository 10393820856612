import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { CaseDetailsDTO } from '@Models/domain/dto';
import { AppService } from '../../app.service';


@Component({
    selector: 'case-summary-call-logs',
    host: { 'class': 'content' },
    templateUrl: './case-summary-call-logs.component.html',
    styleUrls: ['./../case-summary.component.scss']
})

export class CaseSummaryCallLogsComponent implements OnInit {
  @ViewChild('audioControl') audioControlRef: ElementRef;

  @Input() case: CaseDetailsDTO;
  @Input() selectedCaseId: string;

  caseCallLogs: any = [];
  timeZone: string;

  constructor(private appService: AppService,) {}

  ngOnInit(): void {
      this.getCaseCallLogs();
  }

  getCaseCallLogs() {
      this.appService.getData(`CallLog/externalid/?externalid=${this.selectedCaseId}`).subscribe(
          data => {
              this.caseCallLogs = data.data;
          }
      );
  }


  getAudioUrl(id) {

      this.appService.getData(`calllog/${id}/playbackurl`).subscribe(
          data => {
              this.audioControlRef.nativeElement.src = data.data;
              this.audioControlRef.nativeElement.load();
              this.audioControlRef.nativeElement.play();
          }
      );

  }
}
