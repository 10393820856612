import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from '@App';
import { CaseAssignee } from '@Models';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { IConfigService } from '../../config/iconfigservice';
import { BaseService } from '../base.service';
import { ObservableService } from '../observable.service';

@Injectable()
export class CaseAssigneeService extends BaseService {
    publicApiBaseUrl: string;

    constructor(
        public appService: AppService,
        public httpClient: HttpClient,
        public configService: IConfigService,
        public observableService: ObservableService
    ) {
        super(appService, configService, httpClient);
        this.publicApiBaseUrl = this.configService.getConfiguration().publicApiUrl;
    }

    setCaseUserAssignee(caseAssignees: CaseAssignee[]) {
        //Want the end component to handle how errors are displayed
        let response: any;
        let url = `${this.publicApiBaseUrl}global/assignees`;
        this.appService.display(true);
        response = this.httpClient.post(url, caseAssignees, {
            headers: this.appService.getHeaders()
        }).pipe(map((res: Response) => {
            this.appService.display(false);
            if (res) {
                return res;
            }
        }),
        catchError((error: any) => {
            this.appService.display(false);
            return throwError(() => new Error(error.error));
        }));

        return response;
    }


    setAssigneeToCase(caseAssignee: CaseAssignee) {
        const url = `${this.publicApiBaseUrl}global/assignee`;
        this.appService.display(true);
        const response = this.httpClient.post(url, caseAssignee, {
            headers: this.appService.getHeaders()
        }).pipe(map((res: Response) => {
            this.appService.display(false);
            if (res) {
                return res;
            }
        }),
        catchError((error: any) => {
            this.appService.display(false);
            return throwError(() => new Error(error.error));
        }));

        return response;
    }
    
    removeCaseUserAssignee(caseAssignees: CaseAssignee[]) {
        return super.patchData(`${this.publicApiBaseUrl}global/assignees`, caseAssignees);
    }
}
