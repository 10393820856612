<h1 style="justify-content: center;display: flex;margin-top: 24px;">Exit {{exitText}}?</h1>
<div mat-dialog-content>
  <div class="content" style="padding-top: 12px">
    <div class="dialog-options">
      <div class="mb-4 flex flex-column">
        <div style="align-self: center; ">
          <mrs-btn-primary buttonLabel="Save & Exit" (onClick)="interviewOnHold()"></mrs-btn-primary>
        </div>
        <div style="align-self: center; margin-top: 15px">
          <mrs-btn-tertiary textColor="#2C6EED" [buttonLabel]="discardText" buttonId="terminateButton"
            (onClick)="terminate()"></mrs-btn-tertiary>
        </div>
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions align="center" style="margin-bottom: 16px;">
  <mrs-btn-tertiary buttonLabel="Cancel" buttonId="cancelButton" (onClick)="close()"></mrs-btn-tertiary>
</div>