import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { InterviewService } from '../../../interview.service';

@Component({
    selector: 'auto-suggest',
    templateUrl: './auto-suggest.component.html',
    styleUrls: ['./auto-suggest.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: AutoSuggestComponent,
        // useExisting: forwardRef(() => AutoSuggestComponent),
        multi: true
    }]
})
export class AutoSuggestComponent implements OnInit, ControlValueAccessor {

    @Input() placeholder;
    @Input() interviewMode;
    @Input() multiple;
    @Input() field;
    @Input() dataKey;
    @Input() showRequiredError;
    @Input() 
    set disabled(val:boolean){
        this._disabled = val;
        
        this.updateFormDisabledState();
    }

    get disabled(){
        return this._disabled;
    }
    @Input() allowNewValues;
    @Input() disableSort;
    @Input()
    set list(list) {
        if (list && list.length && this.field && (this.disableSort === undefined || !this.disableSort)) {
            list = _.sortBy(list, [obj => obj[this.field].toLowerCase()], [this.field]);
        }
        this._list = list;
        this.filteredList = list;
    }

    autoSuggestForm: UntypedFormGroup;

    _list: any = [];
    _disabled: boolean;
    filteredList: any = [];
    showLabel = false;
    value: any;
    modalValue: any;
    inputTypeDropdown = "autoSuggest";

    // private disabled: boolean;
    private onChange: Function;
    private onTouched: Function;

    @Output() focusin: EventEmitter<any>;
    @Output() valueChanges: EventEmitter<any>;
    @Output() newValueChanges: EventEmitter<any>;
    @Output() onBlurred: EventEmitter<any>;

    constructor(private _fb: UntypedFormBuilder,
        public interviewService: InterviewService) {
        this.onChange = (_: any) => { };
        this.onTouched = () => { };

        this.focusin = new EventEmitter<any>();
        this.valueChanges = new EventEmitter<any>();
        this.newValueChanges = new EventEmitter<any>();
        this.onBlurred = new EventEmitter<any>();
    }

    ngOnInit() {
        this.autoSuggestForm = this._fb.group({
            modalValue: []
        });

        this.updateFormDisabledState();
    }

    updateFormDisabledState(){
        if (this.autoSuggestForm){
            if (this.disabled){
                this.autoSuggestForm.disable();
            }else{
                this.autoSuggestForm.enable();
            }
        }
    }

    writeValue(value: any): void {
        if (this.multiple) {
            if (!value || !value.length) {
                this.value = [];
            } else {
                this.value = value;
            }

            this.modalValue = [];
            this.value.forEach(element => {

                if (!_.find(this._list, [this.dataKey, element])) {
                    const newItem = {};
                    newItem[this.dataKey] = element;
                    newItem[this.field] = element;
                    this._list = _.concat(this._list, newItem);
                }

                this.modalValue.push(_.find(this._list, [this.dataKey, element]));

            });
        } else {
            this.value = value;
            this.modalValue = _.find(this._list, [this.dataKey, value]);
        }

        this.autoSuggestForm.patchValue({
            modalValue: this.modalValue
        });
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    // setDisabledState(isDisabled: boolean): void {
    //     this.disabled = isDisabled;
    // }

    filterList(event) {
        const query = event.query;
        if (this._list) {
            this.filteredList = this._list.filter(item => {
                return (item[this.field].toLowerCase().includes(query.toLowerCase()));
            });
        }
    }

    onSelect(value) {
        if (this.multiple) {
            this.value = [];
            if (!this.modalValue.includes(value)) {
                this.modalValue.push(value);
            }
            this.updateValue();
        } else {
            this.value = value[this.dataKey];
        }

        this.onChange(this.value);
        this.onTouched();
        this.newValueChanges.emit(this.value);
    }

    onUnSelect(value) {
        if (this.multiple) {
            this.modalValue = this.modalValue.filter(item => item[this.dataKey] !== value[this.dataKey]);
            this.value = [];
            this.updateValue();
        } else {
            this.value = '';
            this.modalValue = {};
        }

        this.onChange(this.value);
        this.onTouched();
        this.onBlurred.emit();
        this.newValueChanges.emit(this.value);
    }

    onClear() {
        if (this.multiple) {
            this.value = [];
            this.updateValue();
        } else {
            this.value = '';
            this.modalValue = {};
        }
        this.onChange(this.value);
        this.onTouched();
        this.onBlurred.emit();
    }
    onBlur(event) {
        this.showLabel = false;
        // During development of PBI 32819 circ 9/2023
        // The following code was commented out
        // because when users would click away the
        // selected value for the component would be erased.
        //if (this.multiple) {
        //    event.target.value = '';
        //}
        
    }

    getSelectedTitle() {
        if (this.multiple) {
            return (typeof this.value === 'object' ? this.value.join(', ') : '');
        } else {
            return (this.modalValue ? this.modalValue[this.field] : '');
        }
    }

    onKeyUp(event) {
        if (event.key === ',') {
            event.currentTarget.value = event.currentTarget.value.slice(0, event.currentTarget.value.length - 1);
            event.preventDefault ? event.preventDefault() : event.returnValue = false;
            return false;
        }
        if (this.allowNewValues === 'false' || !this.multiple) { return; }
        if (event.key === ';') {
            let newItemValue = event.currentTarget.value.trim();
            if (newItemValue.length > 1) {

                newItemValue = newItemValue.substring(0, newItemValue.length - 1);
                const isInList = this._list.filter(item => item[this.field].toLowerCase() === newItemValue.toLowerCase());

                const newItem = {};
                newItem[this.dataKey] = newItemValue;
                newItem[this.field] = newItemValue;

                if (!isInList.length) {

                    this._list = _.concat(this._list, newItem);
                    this.modalValue = _.concat(this.modalValue, newItem);
                    this.updateValue();
                    this.onChange(this.value);
                    this.onTouched();
                    this.onBlurred.emit();
                    event.currentTarget.value = '';
                } else {

                    const isInSelectedList = this.modalValue.filter(item => item[this.field].toLowerCase() === newItemValue.toLowerCase());

                    if (!isInSelectedList || !isInSelectedList.length) {
                        this.modalValue = _.concat(this.modalValue, isInList);
                        this.updateValue();
                        this.onChange(this.value);
                        this.onTouched();
                        this.onBlurred.emit();
                        event.currentTarget.value = '';
                    } else {
                        event.currentTarget.value = newItemValue;
                    }
                }

                this.newValueChanges.emit(this.value);
            }
        }
    }

    onDropdownClick(event) {
        // event.originalEvent.preventDefault();
        // event.originalEvent.stopImmediatePropagation();
        // return false;
    }

    updateValue() {
        this.value = [];
        this.modalValue.forEach(element => {
            this.value.push(element[this.dataKey]);
        });

        this.autoSuggestForm.patchValue({
            modalValue: this.modalValue
        });
    }

}
