import { AppService } from './../../app.service';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { RoutingService, RoutesEnum, AccountDataService, AccountService } from '../';
import { Account } from '../../models';
import { AccountStatusEnum } from '../../../app/enums';

@Injectable()
export class InterviewResolverService implements Resolve<any> {
    constructor(
        private accountDataService: AccountDataService,
        public appService: AppService,
        public accountService: AccountService,
        public routingService: RoutingService) {
    }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<Account> {
        return new Promise(async (resolve, reject) => {
            const accountCode = route.params.accountCode;
            const versionNumber = route.params.versionNumber;
            const caseIdParam = route.params.caseId;
            const target = await this.accountService.getTargetAccount(accountCode, versionNumber, true, caseIdParam);

            if (target === null) {
                this.appService.showMsg('error', 'Cannot find the requested account.');
                this.routingService.navigateToDefault();
                reject();
            }

            if (versionNumber !== target.versionNumber.toString()){
                var clientCode = route.params.clientCode;
                this.routingService.navigateToUrl(`client/${clientCode}/account/${accountCode}/${target.versionNumber}/interview`);
            }

            resolve(target);
        });
    }
}
