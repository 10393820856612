import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AppService } from 'app/app.service';

import { Account } from '../../models';

@Component({
    selector: 'rule',
    templateUrl: './rule.component.html',
    styleUrls: ['./rule.component.scss']
})
export class RuleComponent implements OnInit {

    @Input() accountInfo: Account;
    @Input() mapKeys;
    @Input() nonListNonVirtualMapKeys;
    @Input() variableMapKeys;
    @Input() nonVirtualMapKeys;
    @Input() testResult;
    @Input() isTestExecuted;
    expanded: boolean;
    isSubRule: boolean;
    isEditView: boolean;
    @Output() deleteSubRuleEvent = new EventEmitter<any>();
    @Output() newRuleAdded = new EventEmitter<any>();
    @Output() onTestClick = new EventEmitter<any>();
    @Output() clearTestResults = new EventEmitter<any>();
    @Output() ruleCanceled = new EventEmitter<any>();
    @Input() comboData;
    @Input() rules;
    collapseChildren: boolean;
    formSubmitted: boolean;
    ruleForm: UntypedFormGroup;
    initialSavedRule: any;
    showInsertSubRule: boolean;
    subRuleSelectForm: UntypedFormGroup;
    subRuleList: any = [];
    testResultData: any;

    constructor(private _fb: UntypedFormBuilder, private appService: AppService,
        public dialog: MatDialog) {

        // let conditionSet = <FormArray> this._fb.array([]);

        this.showInsertSubRule = false;

    }

    // @Input() config;
    private _config;

    get config() {
        return this._config;
    }

    @Input()
    set config(ruleConfig) {

        this._config = ruleConfig;

        if (ruleConfig && ruleConfig.id) {


            this.ruleForm.reset();

            this.ruleForm.patchValue({
                id: ruleConfig.id,
                name: ruleConfig.name,
                filterId: ruleConfig.filterId,
                AccountId: ruleConfig.accountId,
                clientId: ruleConfig.clientId
            });

            const conditionSets = this.ruleForm.get('conditionSets') as UntypedFormArray;

            while (conditionSets.length) {
                conditionSets.removeAt(0);
            }

            ruleConfig.conditionSets.forEach(conditionSet => {

                if (conditionSet.targetRuleId) {
                    conditionSets.push(this._fb.group({
                        type: 'Rule',
                        targetRuleId: conditionSet.targetRuleId,
                        name: '',
                        filterId: '',
                        AccountId: '',
                        conditionSets: this._fb.array([])
                    }));


                } else {

                    const conditions = this._fb.array([]);
                    conditionSet.conditionSet.conditions.forEach(condition => {

                        conditions.push(this._fb.group({
                            operand: [condition.operand, Validators.required],
                            operatorId: [condition.operatorId, Validators.required],
                            operandTypeId: [condition.operandTypeId, Validators.required],
                            valueoperand: condition.valueOperand,
                            mapkeyOperand: condition.mapkeyOperand,
                            jsonPropertyId: condition.jsonPropertyId,
                            jsonResponse: ['']
                        }));

                    });

                    conditionSets.push(this._fb.group({
                        type: 'ConditionSet',
                        filterId: conditionSet.conditionSet.filterId,
                        conditionSet: conditionSet,
                        conditions: conditions
                    }));

                }

            });


            this.expanded = true;
            this.isEditView = false;
            this.collapseChildren = false;

        } else {

            if (this.ruleForm) {

                this.ruleForm.reset();

                this.ruleForm.patchValue({
                    AccountId: this.accountInfo.id,
                    clientId: this.accountInfo.clientId
                });

                const conditionSets = this.ruleForm.get('conditionSets') as UntypedFormArray;
                while (conditionSets.length) {
                    conditionSets.removeAt(0);
                }
                this.isEditView = true;
            }

        }

    }

    @Input()
    set collapseMe(value) {
        this.expanded = value;
    }

    ngOnInit() {
        // debugger;
        this.ruleForm = this._fb.group({
            id: '',
            name: ['', Validators.required],
            filterId: ['', Validators.required],
            AccountId: [this.accountInfo.id, Validators.required],
            clientId: [this.accountInfo.clientId, Validators.required],
            conditionSets: this._fb.array([]),
            jsonTestResponse: ['']
        });

        this.subRuleSelectForm = this._fb.group({
            targetRuleId: ['', Validators.required]
        });

        this.formSubmitted = false;

        if (this.config && this.config.type) { // subrule
            this.expanded = false;
            this.isSubRule = true;
            this.isEditView = false;
            this.collapseChildren = false;
        } else { // rule
            this.expanded = true;
            this.isSubRule = false;
            this.isEditView = true;
            this.collapseChildren = true;
        }

    }

    viewRule() {
        this.isEditView = false;
        this.newRuleAdded.emit(this.ruleForm.get('id').value);
    }

    editRule() {
        this.clearTestResults.emit();
        this.isEditView = true;
    }

    testRule() {
        this.onTestClick.emit(this.initialSavedRule);
    }

    saveRule() {

        this.formSubmitted = true;

        if (this.ruleForm.valid) {

            const requestData = JSON.parse(JSON.stringify(this.ruleForm.value));

            requestData['conditionSets'].forEach((cs) => {

                if (cs.type !== 'Rule') {
                    cs['conditionSet'] = JSON.parse(JSON.stringify(cs));
                }

            });


            this.appService.postData('Rules', requestData, (this.ruleForm.get('id').value ? true : false)).subscribe(
                data => {

                    if (data.status === 'success') {

                        if (!this.ruleForm.get('id').value) {
                            this.ruleForm.controls['id'].patchValue(data.data.id);
                        }

                        this.initialSavedRule = data.data;
                        this.isEditView = false;
                        this.newRuleAdded.emit(data.data.id);

                        this.appService.showMsg('success', 'Rule saved successfully ...');
                    } else {
                        this.appService.showMsg('error', data.message);
                    }
                }
            );


        } else {
            this.appService.showMsg('error', 'Invalid Form. Please fill all details', false);
        }
    }

    cancelRule() {
        this.config = undefined;
        this.ruleCanceled.emit();
    }

    addConditionSet() {
        const items = this.ruleForm.get('conditionSets') as UntypedFormArray;

        items.push(this._fb.group({
            type: 'ConditionSet',
            order: items.length,
            filterId: [{value: '', disabled: this.isSubRule}, Validators.required],
            conditions: this._fb.array([])
        }));
    }

    insertSubRule() {

        if (this.subRuleSelectForm.valid) {
            this.dialog.closeAll();
            this.showInsertSubRule = false;

            const items = this.ruleForm.get('conditionSets') as UntypedFormArray;

            items.push(this._fb.group({
                type: 'Rule',
                targetRuleId: [this.subRuleSelectForm.get('targetRuleId').value, Validators.required],
                name: '',
                filterId: '',
                AccountId: '',
                conditionSets: this._fb.array([])
            }));
        }
    }

    deleteSubRule() {
        this.deleteSubRuleEvent.emit();
    }

    deleteConditionSet(conditionSetIndex) {

        const conditionSets = this.ruleForm.get('conditionSets') as UntypedFormArray;
        conditionSets.removeAt(conditionSetIndex);

    }

    filterPossibleSubRules() {

        const currentRuleID = this.ruleForm.get('id').value;

        if (currentRuleID) {

            return this.rules.filter(r => (r.id !== currentRuleID));

        } else {
            return this.rules;
        }
    }

    showSubRuleForm() {
        this.subRuleList = this.filterPossibleSubRules();
        this.showInsertSubRule = true;
    }

    checkACL(permissionType, redirect?) {
        return this.appService.checkACL('Accounts', permissionType, redirect);
    }

    getConditionSet(i) {
        return this._config.conditionSets && this._config.conditionSets[i] || null;
    }

    getConditionSetTestResult(i) {
        if (this.testResult && this.testResult.conditionSetResults) {
            // Find the condition set
            const cs = this.getConditionSet(i);
            if (cs) {
                if (cs.targetRuleId) {
                    return this.testResult.conditionSetResults.find(tr => tr.ruleId === cs.targetRuleId);
                } else {
                    return this.testResult.conditionSetResults.find(tr => tr.conditionSetId === cs.conditionSetId);
                }
            }
        }
        return null;
    }
}
