<div class="question-container" [formGroup]="group" [ngClass]="{active: isSelected, interviewQuestion: interviewMode}">
  <div [ngClass]="isConfigInvalid()?'invalid-question':''">
    <div class="question-header" *ngIf="!interviewMode" (click)="selectQuestion()">
      <button type="button" class="btn btn-link float-left" (click)="expanded = !expanded">
        <i aria-hidden="true" class="fa" [ngClass]="{'fa-angle-right': !expanded, 'fa-angle-down': expanded}"></i>
      </button>
      <span class="question-mapkey">
        {{config.mapKeyName}}
      </span>
      <span class="question-mapkey float-right pr-2">
        {{config.answerTypeName}}
      </span>
    </div>
    <div class="question-body" *ngIf="expanded">
      <div class="question-text rendered-quill-content ql-editor" [innerHTML]="questionText"></div>
      <div class="help-text rendered-quill-content ql-editor" [innerHTML]="helpText"
        *ngIf="config.helpText?.length"></div>
      <div class="question-form" data-test-input-type="height">
        <!-- <input type="hidden" matInput [formControlName]="config.id"> -->
        <mat-form-field class="w-40 mr-5">
          <mat-label>Feet</mat-label>
          <input type="number" [formControlName]="'feet'+this.config.id" (focus)="onFocus()" matInput
            (blur)="onBlur()" [readonly]="isReadOnly()" data-test-input-part="feet">

        </mat-form-field>
        <mat-form-field class="w-40">
          <mat-label>Inches</mat-label>
          <input type="number" [formControlName]="'inch'+this.config.id" (focus)="onFocus()"  matInput
            (blur)="onBlur()" [readonly]="isReadOnly()" data-test-input-part="inches">
          <mat-error *ngIf="group.get('inch'+this.config.id)?.hasError('min')">Minimum value is 0 inches when feet
            value is present</mat-error>
          <mat-error *ngIf="group.get('inch'+this.config.id)?.hasError('max')">Maximum value is 12 inches when feet
            value is present</mat-error>
        </mat-form-field>
        <div *ngIf="!heightFeatureToggle">
          <mat-error *ngIf="group.get(config.id)?.hasError('required')">required</mat-error>
          <mat-error *ngIf="group.get(config.id)?.hasError('min')">Minimum total value is {{config?.MinInches}} inches</mat-error>
          <mat-error *ngIf="group.get(config.id)?.hasError('max')">Maximum total value is {{config?.MaxInches}} inches</mat-error>
          <mat-error *ngIf="group.get(config.id)?.hasError('validationError')">{{group.get(config.id)?.errors.validationError}}</mat-error>
        </div>
        <div *ngIf="heightFeatureToggle">
          <mat-error *ngIf="group.get(config.id)?.hasError('required')">required</mat-error>
          <mat-error *ngIf="(!config.isRequired && (group.get(config.id)?.value !== 0 && group.get(config.id)?.value !== ' ')) && (group.get(config.id)?.hasError('min')) || (config.isRequired && group.get(config.id)?.hasError('min'))">Minimum total value is {{config?.MinInches}} inches</mat-error>
          <mat-error *ngIf="(!config.isRequired && (group.get(config.id)?.value !== 0 && group.get(config.id)?.value !== ' ')) && (group.get(config.id)?.hasError('max')) || (config.isRequired && group.get(config.id)?.hasError('max'))">Maximum total value is {{config?.MaxInches}} inches</mat-error>
          <mat-error *ngIf="group.get(config.id)?.hasError('validationError')">{{group.get(config.id)?.errors.validationError}}</mat-error>
        </div>        
      </div>
      <app-field-details [question]="config" [form]="group" [readOnlyMode]="isReadOnly()"></app-field-details>
    </div>
  </div>
</div>
