<div [formGroup]="formGroup">
    <div *ngIf="!newDropdownToggle" class="dropdown-container">
        <mat-form-field class="w-100 full-item">
            <mat-label>Client-Account Name</mat-label>
            <mat-select [formControlName]="formField">
                <mat-optgroup *ngFor="let client of clients" [label]="client.name">
                    <mat-option *ngFor="let account of client.activeAndDraftAccounts" [value]="account">
                        {{account.name}}-v{{account.versionNumber}}
                    </mat-option>
                </mat-optgroup>
            </mat-select>
            <mat-error *ngIf="formGroup.get('account').hasError('required')">
                required
            </mat-error>
        </mat-form-field>
    </div>
    <div *ngIf="newDropdownToggle" class="dropdown-container">
        <mat-form-field class="mr-4 half-item">
            <mat-label>Client</mat-label>
            <input type="text"
                placeholder="Client"
                aria-label="Client"
                matInput
                [formControl]="clientControl"
                [matAutocomplete]="auto">
            <mat-icon matSuffix svgIcon="menu-down" style="color:#26333B"></mat-icon>
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="clientDisplayFn" [optionHeight]="24">
                <mat-option *ngFor="let client of filteredClients | async" [value]="client">
                    <span class="client-name">{{client.name}}</span>
                    <br/>
                    <span class="client-code">{{client.code}}</span>
                </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="clientControl.hasError('required')">
                required
            </mat-error>
        </mat-form-field>
        <mat-form-field class="half-item">
            <mat-label>Account</mat-label>
            <input type="text"
                placeholder="Account"
                aria-label="Account"
                matInput
                [formControlName]="formField"
                [matAutocomplete]="auto2">
            <mat-icon matSuffix svgIcon="menu-down" style="color:#26333B"></mat-icon>
            <mat-autocomplete autoActiveFirstOption #auto2="matAutocomplete" [displayWith]="accountDisplayFn">
                <mat-option *ngFor="let account of filteredAccounts | async" [value]="account">
                    <span class="account-name">{{account.name}}</span>
                    <br/>
                    <span class="account-code">{{account.code}}</span>
                </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="formGroup.get(formField).hasError('required')">
                required
            </mat-error>
        </mat-form-field>
    </div>
</div>
