import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, UntypedFormGroup, FormArray, UntypedFormControl, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { AppService } from '../../app.service';
import { IntegrationService, MapKeyService } from '../../services';
import { MapKeyType } from '../../enums';

@Component({
    selector: 'condition',
    templateUrl: './condition.component.html',
    styleUrls: ['./condition.component.scss']
})
export class ConditionComponent implements OnInit {

    @Input('config') conditionForm: UntypedFormGroup;
    @Input() isSubRule;
    @Input() isEditView;
    @Input() mapKeys;
    @Input() nonListNonVirtualMapKeys;
    @Input() variableMapKeys;
    @Input() nonVirtualMapKeys;
    @Input() comboData;
    @Input() rules;
    @Input() testResult;
    @Input() isTestExecuted;

    @Output() deleteCondition = new EventEmitter<any>();

    conditionalOperators: any[];
    operatorTypes: any[];
    outputProperties: any[];
    _formSubmitted: boolean;
    showResult: boolean = false;
    testResultData: any = {};
    showTestRuleForm: boolean = false;
    metadata: any = {};
    isJsonResponse: boolean = false;
    mapKeyTypeEnum: any = MapKeyType;

    constructor(private appService: AppService, private mapKeyService: MapKeyService, private intergrationService: IntegrationService) { }

    ngOnInit() {
        this.mapKeys = this.mapKeyService.mapKeys;
        this.nonListNonVirtualMapKeys = this.mapKeyService.nonListNonVirtualMapKeys;
        this.variableMapKeys = this.mapKeyService.variableMapKeys;
        this.nonVirtualMapKeys = this.mapKeyService.nonVirtualMapKeys;

        this.isJsonResponseType(this.conditionForm.get('operand').value);
        this.conditionalOperators = this.comboData.operators;
        this.operatorTypes = this.comboData.operandType;

        this.conditionForm.get('operand').valueChanges.subscribe(val => {
            this.isJsonResponseType(val);
        });

        this.conditionForm.get('operandTypeId').valueChanges
            .subscribe(operandTypeId => {
                if (operandTypeId === '00000000-0000-0000-0000-000000000001') {
                    this.conditionForm.get('mapkeyOperand').clearValidators();
                } else {
                    this.conditionForm.get('mapkeyOperand').setValidators([Validators.required]);
                }
                this.conditionForm.get('valueoperand').updateValueAndValidity();
                this.conditionForm.get('mapkeyOperand').updateValueAndValidity();
                this.conditionForm.updateValueAndValidity();
            });

    }

    @Input()
    set formSubmitted(value) {
        this._formSubmitted = value;

        if (this.conditionForm.controls.operandTypeId.value === '00000000-0000-0000-0000-000000000001') {
        }
        if (value) {
            this.conditionForm.markAsTouched();
            this.conditionForm.controls.operand.markAsTouched();
            this.conditionForm.controls.operatorId.markAsTouched();
            this.conditionForm.controls.operandTypeId.markAsTouched();
            this.conditionForm.controls.valueoperand.markAsTouched();
        } else {
            this.conditionForm.markAsUntouched();
            this.conditionForm.controls.operand.markAsUntouched();
            this.conditionForm.controls.operatorId.markAsUntouched();
            this.conditionForm.controls.operandTypeId.markAsUntouched();
            this.conditionForm.controls.valueoperand.markAsUntouched();
        }

    }

    get formSubmitted() {
        return this._formSubmitted;
    }

    delete() {
        this.deleteCondition.emit();
    }

    getMapKeyLabel(id) {
        const label = _.find(this.mapKeys, ['id', id]);
        return (label ? label.entityHierarchy : '');
    }

    getOperatorLabel(id) {
        const label = _.find(this.comboData.operators, ['id', id]);
        return (label ? label.name : '');
    }

    getOperandTypeLabel(id) {
        const label = _.find(this.comboData.operandType, ['id', id]);
        return (label ? label.value : '');
    }

    getJsonPropertyLabel(id) {
        const label = _.find(this.metadata.output, ['id', id]);
        return (label ? label.property : '');
    }

    checkACL(permissionType, redirect?) {
        return this.appService.checkACL('Accounts', permissionType, redirect);
    }

    public noWhitespaceValidator(control: UntypedFormControl) {
        const isWhitespace = (control.value || '').trim().length === 0;
        const isValid = !isWhitespace;
        return isValid ? null : { 'whitespace': true };
    }

    filterList(event, sourceList, filteredList) {
        filteredList = sourceList.filter(item => {
            return item[''];
        });
    }

    isJsonResponseType(operand) {
        const selectedMapKey = this.variableMapKeys.find(o => {
            return operand == o.id;
        });
        if (selectedMapKey && selectedMapKey.isJson) {
            this.getIntegrationMetadata(selectedMapKey.integrationId);
            this.isJsonResponse = true;
        } else {
            this.isJsonResponse = false;
            this.conditionForm.get('jsonPropertyId').setValue(undefined);
        }
    }

    getIntegrationMetadata(integrationId) {
        this.intergrationService.getIntegrationMetadata(integrationId).subscribe(
            data => {
                this.metadata = data;
                this.outputProperties = this.metadata.output.filter(o => {
                    if (o.isArray) {
                        return o;
                    }
                });
            }
        );
    }
}
