﻿<div>
    <div class="sub-section-header">
        <h1 class="title">Underwriting Decision</h1>
        <span class="actions">
            Last Updated: <span *ngIf="lastUpdated; else never">{{ lastUpdated | date: 'M/d/yy, h:mm a' }}</span>
            <ng-template #never>Never</ng-template>
        </span>
    </div>

    <form [formGroup]="decisionForm" novalidate autocomplete="off">
        <mat-card appearance="outlined">
            <mat-card-header>Enable Section Workflow</mat-card-header>
            <mat-card-subtitle>Selecting 'None' will use default decision workflow.</mat-card-subtitle>

            <mat-card-content *ngIf="!sectionsLoaded || !accountSettingsLoaded">
                <mat-spinner mode="indeterminate" diameter="30"></mat-spinner>
            </mat-card-content>

            <mat-card-content *ngIf="sectionsLoaded && accountSettingsLoaded">
                <mat-form-field class="section-select">
                    <mat-select [formControlName]="AccountSettings.UWDecisionSection" (selectionChange)="handleChange($event)" panelClass="auto-width-panel">
                        <mat-option *ngFor="let section of sections" [value]="section">{{ section.name }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </mat-card-content>

            <mat-card-content *ngIf="errorMessage">
                {{ errorMessage }}
            </mat-card-content>
        </mat-card>
    </form>
</div>