import { Component, Input } from '@angular/core';

import * as _ from "lodash";

@Component({
    selector: 'interview-preview',
    // host: { 'class': 'd-flex flex-row flex-fill' },
    templateUrl: './interview-preview.component.html',
    styleUrls: ['./interview-preview.component.scss']
})
export class InterviewPreviewComponent  {

    @Input() selectedSection;
    @Input() previewQuestions;
    @Input() selectedQuestion;
    @Input() expandAllQuestions;
    @Input() constantLists;
}
