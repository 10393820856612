import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { AppService } from '@App';
import { AccountSettingsDTO } from '@DTOs';
import { UWResponse } from '@Models';
import { BaseService } from '@Services';
import { IConfigService } from 'app/config/iconfigservice';
import { first, ReplaySubject } from 'rxjs';
import { SubSink } from 'subsink';


@Injectable({
    providedIn: 'root'
})
export default class AccountSettingsDataService extends BaseService implements OnDestroy {
    forceReload = false;

    loadedAccountSettings = '';
    accountSettingsSub = new ReplaySubject<AccountSettingsDTO[]>(1);
    accountSettings$ = this.accountSettingsSub.asObservable();

    subs = new SubSink();

    constructor(
        public configService: IConfigService,
        public httpClient: HttpClient,
        public appService: AppService
    ) {
        super(appService, configService, httpClient);
    }

    //#region Helpers

    private isAccountSettingsLoaded = (accountId: string) => accountId == this.loadedAccountSettings;

    //#endregion
    //#region Lifecycle

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    //#endregion

    public forceOnNextLoad() {
        this.forceReload = true;
    }

    public reloadAccountSettings(accountId: string, hideWaiting?: boolean) {
        this.forceReload = false;
        const url = new URL(`./AccountSettings/${accountId}`, this.baseApiUrl);

        super.getData<UWResponse<AccountSettingsDTO[]>>(url.toString(), null, hideWaiting)
            .pipe(first())
            .subscribe(response => {
                this.accountSettingsSub.next(response.data);
                this.loadedAccountSettings = accountId;
            });

        return this.accountSettings$;
    }

    public loadAccountSettings(accountId: string) {
        if (this.forceReload || !this.isAccountSettingsLoaded(accountId)) return this.reloadAccountSettings(accountId);
        return this.accountSettings$;
    }
}
