import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import * as $ from 'jquery';
import { SubSink } from 'subsink';
import { AppService } from '../app.service';
import { AuthLegacyService } from '../auth/auth-legacy.service';
import { IConfigService } from '../config/iconfigservice';
import { FeatureToggle, InterviewCloseEnum, RolesEnum } from '../enums';
import { InterviewCloseComponent } from '../interview/end-of-interview/interview-close.component';
import { CaseNotificationsService, Environment, EnvironmentService, FeatureToggleService, IdleService, NotificationService, RoutesEnum, RoutingService, UtilityService } from '../services';
import { FeatureManagerService } from '../services/global/feature-manager/feature-manager.service';
import { Regex } from '@Utils';

type MenuItem = {
    title: string;
    icon: string;
    route: RoutesEnum;
    ACL: string;
    aclPermissionType?: string; // TECH DEBT: This does not appear to be used. `checkAcl()` doesn't use it.
    disabled: boolean;
    hasAccess: boolean;
    children: MenuItem[]; // TECH DEBT: This does not appear to be used. No menu item uses children anymore.
}

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit, OnDestroy {
    @Input() showSideNav = false;
    navOption = 'dashboard';
    closeDialogRef: MatDialogRef<InterviewCloseComponent>;
    userId: string;
    username: string;
    userRole: string;
    isApplicant = false;
    environment: Environment;
    acl: any = [];
    themeList = [];
    activeMenu: any = '';
    activeSubMenu: any = '';
    fullAccessNavItems = ['System Data'];
    displayEnvironmentName = false;
    startText = 'Start Interview';

    menu: MenuItem[] = [
        { title: 'Dashboard', icon: 'fa-tachometer-alt', route: RoutesEnum.dashboard, ACL: 'Dashboard', aclPermissionType: 'V', disabled: false, hasAccess: false, children: [] },
        { title: 'Clients', icon: 'fa-users', route: RoutesEnum.clients, ACL: 'ManageClients', aclPermissionType: 'V', disabled: false, hasAccess: false, children: [] },
        { title: 'My Cases', icon: 'fa-list', route: RoutesEnum.myCases, ACL: 'MyCases', aclPermissionType: 'V', disabled: false, hasAccess: false, children: [] },
        { title: 'Case Manager', icon: 'fa-tasks', route: RoutesEnum.caseMananger, ACL: 'CaseManager', aclPermissionType: 'V', disabled: false, hasAccess: false, children: [] },
        { title: 'Audit', icon: 'fa-shield', route: RoutesEnum.audit, ACL: 'Audit', aclPermissionType: 'V', disabled: false, hasAccess: false, children: [] },
        { title: 'Roles', icon: 'fa-address-card', route: RoutesEnum.roles, ACL: 'ManageRoles', aclPermissionType: 'V', disabled: false, hasAccess: false, children: [] },
        { title: 'Users', icon: 'fa-user-circle', route: RoutesEnum.userManagement, ACL: 'Users', aclPermissionType: 'V', disabled: false, hasAccess: false, children: [] },
        { title: this.startText, icon: 'fa-file-alt', route: RoutesEnum.accounts, ACL: 'Interview', disabled: false, hasAccess: false, children: [] },
        { title: 'Reports', icon: 'fa fa-file-invoice', route: RoutesEnum.reports, ACL: 'Reports', disabled: false, hasAccess: false, children: [] },
        { title: 'Call Manager', icon: 'fa fa-phone', route: RoutesEnum.callManager, ACL: 'CallManager', disabled: false, hasAccess: false, children: [] },
        { title: 'System', icon: 'fa fa-desktop', route: RoutesEnum.system, ACL: 'System Data', disabled: false, hasAccess: false, children: [] }
    ];

    private subs = new SubSink();

    constructor(
        private router: Router,
        private appService: AppService,
        public authService: AuthLegacyService,
        public configService: IConfigService,
        private routingService: RoutingService,
        private idleService: IdleService,
        private environmentService: EnvironmentService,
        private caseNotificationsService: CaseNotificationsService,
        private featureToggleService: FeatureToggleService,
        public dialog: MatDialog,
        private authorizationService: AuthService,
        private featureManagerService: FeatureManagerService,
        private utilityService: UtilityService
    ) {
    }

    //#region Helpers

    updateMenuAccess(menuItem: MenuItem) {
        if (menuItem.ACL === '') {
            menuItem.hasAccess = true;
            return;
        }

        const access = this.fullAccessNavItems.some(x => x === menuItem.ACL) ? 'F' : 'R';
        menuItem.hasAccess = this.appService.checkACL(menuItem.ACL, access);
    }

    updateSideNav(route: string): void {
        if (route === 'dashboard' || route === 'metrics') {
            this.showSideNav = false;
        } else {
            this.showSideNav = true;
        }

        this.menu.forEach(menuItem => {
            if (menuItem.route === route) {
                this.activeMenu = menuItem.title;
                this.activeSubMenu = '';
            } else {
                menuItem.children.forEach(subMenuItem => {
                    if (subMenuItem.route === route) {
                        this.activeMenu = menuItem.title;
                        this.activeSubMenu = subMenuItem.title;
                    }
                });
            }
        });
    }

    handleLogout(): void {
        this.utilityService.handleLogout();
    }

    //#endregion
    //#region Subscriptions

    subscribeFeatureFlags() {
        this.featureToggleService.getFeatureFlag(FeatureToggle.GlobalTextInterviewToApplication).subscribe(result => {
            if (result) {
                this.startText = 'Start Application';

                const menuItem = this.menu.find(x => x.ACL === 'Interview');
                menuItem.title = this.startText;
            }
        });

        this.featureManagerService.isEnabled(FeatureToggle.DevelopmentFeatureAuditUser, this.userId).subscribe(isEnabled => {
            const auditMenuItem = this.menu.find(x => x.route == RoutesEnum.audit);
            if (auditMenuItem) {
                // If the feature is disabled, we want it to override any other access values.
                if (!isEnabled) auditMenuItem.hasAccess = isEnabled;
                // Merge with existing access (role permissions could be disabled so keep it that way)
                else auditMenuItem.hasAccess = auditMenuItem.hasAccess && isEnabled;
            }
        });

        this.displayEnvironmentName = this.featureManagerService.getByName(FeatureToggle.GlobalDisplayEnvironment).enabled;
    }

    //#endregion
    //#region Lifecycle

    ngOnInit() {
        this.menu.forEach(menuItem => this.updateMenuAccess(menuItem));

        this.subscribeFeatureFlags();

        this.userId = this.appService.getUserId();
        this.username = this.appService.getUsername();
        this.userRole = this.appService.getUserRoleName();

        this.isApplicant = this.userRole == RolesEnum.Applicant;
        this.subs.add(this.environmentService.getEnvironment().subscribe(env => this.environment = env));
        this.subs.add(this.router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                const route = val.url.split('/')[2];
                this.updateSideNav(route);
            }
        }));


        this.idleService.startIdleTimer();
    }

    ngOnDestroy() {
        this.idleService.stopIdleTimer();
        this.subs.unsubscribe();
    }

    //#endregion
    //#region Handlers

    signOut() {
        const inInterview = this.router.url.match(Regex.InterviewRoute);
        const onCaseSummary = this.router.url.match(Regex.CaseSummaryRoute);
        if (inInterview) {
            this.closeDialogRef = this.dialog.open(InterviewCloseComponent, {
                width: '300px',
                data: { clickSource: InterviewCloseEnum.SignOut },
                disableClose: false
            });

            this.closeDialogRef.afterClosed().subscribe(data => {
                if (data === InterviewCloseEnum.SignOut) {
                    this.caseNotificationsService.removeUserFromCase();
                    this.handleLogout();
                }
            });
        } else if (onCaseSummary) {
            this.caseNotificationsService.removeUserFromCase();
            this.handleLogout();
        } else {
            this.handleLogout();
        }
    }

    selectMenu(event, item) {
        event.stopPropagation();
        if (!item.disabled) {
            if (item.route) {
                this.routingService.navigateToRoute(item.route);
            }
        }
    }

    toggleClick() {
        $('#sidenav').toggleClass('hidenav');

        setTimeout(() => {
            this.appService.getSideMenuInfo($('#sidenav').hasClass('hidenav'));
        }, 10);
    }

    resetTimeCounter() {
        this.idleService.resetIdleTimer();
    }

    //#endregion
    //#region TECH DEBT: Unused Methods (safe to delete?)

    // These methods don't appear to be used in the template(view) or this component. Verify they're not used anywhere and remove.

    gotoDashboard() {
        this.routingService.navigateToRoute(RoutesEnum.dashboard);
    }

    toggleMenu(itemTitle, event) {
        if (this.activeMenu === itemTitle) {
            this.activeMenu = '';
            event.stopPropagation();
        }
    }

    //#endregion
}
