<div class="page-heading" id="subheading">
    <span class="page-heading-title">My Cases</span>
</div>

<div id="innercontent" class="custom-scrollbar">
    <div class="container-fluid container-background">
        <div class="mat-elevation-z8 my-4 mx-2">
            <div class="flex flex-align-center bg-white py-2 px-4">
                <mat-form-field class="filter-cases" floatLabel="never">
                    <input matInput placeholder="Filter Cases..." name="filterValue" (keyup)="updateFilteredCases()" [(ngModel)]="filterValue">
                </mat-form-field>
            </div>
            <!-- Inline styles on purpose... seems more straightforward than referencing these 1-1 things from the stylesheet somewhere -->
            <table mat-table [dataSource]="caseDataSource" matSort matSortActive="caseCreated" matSortDirection="desc" style="width:100%;">
                <ng-container matColumnDef="clientName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:200px;">Client</th>
                    <td mat-cell *matCellDef="let element"> {{element.clientName}} </td>
                </ng-container>
                <ng-container matColumnDef="accountName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:200px;">Account</th>
                    <td mat-cell *matCellDef="let element"> {{element.accountName}} </td>
                </ng-container>
                <ng-container matColumnDef="caseNumber">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:140px;">Case Number</th>
                    <td mat-cell *matCellDef="let element"> {{element.caseNumber}} </td>
                </ng-container>
                <ng-container matColumnDef="policyNumber">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:140px;">Policy Number</th>
                    <td mat-cell *matCellDef="let element"> {{element.policyNumber}} </td>
                </ng-container>
                <ng-container matColumnDef="applicant">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Applicant</th>
                    <td mat-cell *matCellDef="let element"> {{element.Case_Applicant_FirstName}} {{element.Case_Applicant_LastName}} </td>
                </ng-container>
                <ng-container matColumnDef="statusName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:175px;">Status</th>
                    <td mat-cell *matCellDef="let element"> {{element.statusName}} </td>
                </ng-container>
                <ng-container matColumnDef="caseCreated">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:90px;">Created On</th>
                    <td mat-cell *matCellDef="let element"> {{element.caseCreatedDateFormatted}} </td>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef style="width:75px"></th>
                    <td mat-cell class="text-center" *matCellDef="let element" (click)="stopPropagation($event)">
                        <button mat-icon-button (click)="restartInterview(element)"
                         *ngIf="element.canResume && !isAgentBehavior">
                            <mat-icon svgIcon="file-document-edit-outline" matTooltip="Restart Case" aria-label="Restart Case">                                
                            </mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                <tr mat-row *matRowDef="let row; columns: columnsToDisplay;" (click)="viewCaseDetails(row)" class="link-row"></tr>
            </table>
            <div *ngIf="caseDataSource.filteredData == null || caseDataSource.filteredData.length == 0" class="bg-white">
                <div class="p-4 text-center text-muted ">
                    <img src="./assets/images/no-data-placeholder.png " alt=" " class="pb-3 " />
                    <h6>No Cases Found</h6>
                </div>
            </div>
            <mat-paginator
                [pageSize]="10"
                [pageSizeOptions]="[5, 10, 25, 100]">
            </mat-paginator>
        </div>
    </div>
</div>
