import {AppService} from "../../app.service";
import {Component, ElementRef, Inject, OnInit, ViewChild} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {NotificationService} from "../../services";

export interface CallDialogData {
  caseCallId: string,
  clientId: string,
  title: string
}

@Component({
    templateUrl: './call-dialog-component.html'
})

export class CallDialogComponent implements OnInit{
  @ViewChild('audioControl') audioControlRef: ElementRef;
  modalTitle:string;
  constructor(
    private appService: AppService,
    private dialogRef: MatDialogRef<CallDialogComponent>,
    private notificationService: NotificationService,
    @Inject(MAT_DIALOG_DATA) private data: CallDialogData) {

  }

  ngOnInit() {
      if (this.data.caseCallId) {
          this.getCaseCall(this.data.caseCallId, this.data.clientId);
      }

      if(this.data.title != undefined){
          this.modalTitle = this.data.title;
      }
  }

  getCaseCall(callCaseGuid, clientId?: string) {
      this.appService.getData(`calllog/${callCaseGuid}/playbackurl`, clientId).subscribe(audioData => {
          this.loadAndPlayAudio(audioData);
      });

  }

  loadAndPlayAudio(audioData) {
      this.audioControlRef.nativeElement.src = audioData.data;
      this.audioControlRef.nativeElement.load();
      this.audioControlRef.nativeElement.play();
  }

  closeDialog() {
      this.dialogRef.close();
  }
}
