<div id="container" class="mapkey-section-pane">
    <form (ngSubmit)="saveDynamicList()" [formGroup]="dynamicListForm" autocomplete="off">
        <div id="details">
            <span class="d-inline-flex"><mat-icon (click)="showDetails = !showDetails">{{showDetails ? 'expand_less' :
                'expand_more'}}</mat-icon>
                <h2>Details</h2>
            </span>
            <mat-card appearance="outlined" *ngIf="!isEditView" [ngStyle]="{'display':showDetails?'':'none'}" id="view">
                <mat-card-content>
                    <div class="label-title-bold">Name</div>
                    <div class="label-content">{{dynamicListForm?.get('name')?.value}} </div>
                    <div class="label-title-bold">Type</div>
                    <div class="label-content">Dynamic List</div>
                    <div class="label-title-bold">Folder Location</div>
                    <div class="label-content">{{parentPathDisplay}}</div>
                </mat-card-content>
            </mat-card>
            <mat-card appearance="outlined" *ngIf="isEditView" [ngStyle]="{'display':showDetails?'':'none'}" id="edit">
                <mat-card-content>
                    <mat-form-field class="full-width pb-3">
                        <mat-label>Name</mat-label>
                        <input formControlName='name' matInput>
                        <mat-error *ngIf="dynamicListForm.get('name').hasError('required')">
                            <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="full-width pb-3">
                        <mat-label>Type</mat-label>
                        <input [disabled]="true" matInput value="Dynamic List">
                    </mat-form-field>
                </mat-card-content>
            </mat-card>
        </div>
        <div *ngIf="(isEditView || !(dynamicListForm.controls['id'].value == null  || dynamicListForm.controls['id'].value == ''))"
             id="constantList">
            <span class="d-inline-flex"><mat-icon (click)="showList = !showList">{{showList ? 'expand_less' :
                'expand_more'}}</mat-icon>
                <h2>List</h2>
            </span>
            <div *ngIf="showList">
                <mat-card appearance="outlined" *ngIf="!isEditView">
                    <mat-card-content>
                        <div>
                            <div class="label-title-bold">Source</div>
                            <div class="label-content">
                                {{getIntegrationResponseMapkeyName(dynamicListForm.controls['integrationResponseMapkey']?.value
                                ?? '')}}</div>
                        </div>
                        <div class="scrollable-list">
                            <div class="d-flex sticky-header" id="heading">
                                <div class="col-6 label-title-bold" style="padding-left: 0">Value</div>
                                <div class="col-6 label-title-bold">Display Name</div>
                            </div>
                            <div id="list">
                                <div class="display-list d-flex">
                                    <div class="col-6" style="padding-left: 0">
                                        {{dynamicListForm.controls['valueFormat'].value}}</div>
                                    <div class="col-6">{{dynamicListForm.controls['displayNameFormat'].value}}</div>
                                </div>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
                <mat-card appearance="outlined" *ngIf="isEditView">
                    <mat-card-content>
                        <mat-form-field style="width: 100%">
                            <mat-label>Source</mat-label>
                            <mat-select (selectionChange)="getIntegrationMetadata()"
                                formControlName='integrationResponseMapkey'>
                                <mat-option *ngFor="let integrationResponseMapkey of integrationResponseMapkeys"
                                            [value]="integrationResponseMapkey.id"
                                            matTooltip="{{integrationResponseMapkey.entityHierarchy}}"
                                            matTooltipPosition="left">
                                    {{integrationResponseMapkey.entityHierarchy}}
                                </mat-option>
                            </mat-select>
                            <mat-error
                                *ngIf="dynamicListForm.controls['integrationResponseMapkey'].errors?.['required']"><strong>required</strong></mat-error>
                        </mat-form-field>
                        <div>
                            <div class="display-list d-flex ">
                                <div class="w-50" style="padding-right: 10px;">Value</div>
                                <div class="w-50" style="padding-left: 10px;">Display Name</div>
                            </div>

                            <div class="display-list d-flex ">
                                <div class="w-50 textarea-formfield" style="padding-left: 0; position: relative;padding-right: 10px;">
                                    <textarea (itemSelected)="filteredMentionItems = mentionItems"
                                              (searchTerm)="onSearch($event)" [mentionConfig]="mentionConfig"
                                              [placeholder]="expressionPlaceHolderText" cdkAutosizeMinRows="4"
                                              cdkTextareaAutosize formControlName='valueFormat' class="w-100"></textarea>
                                    <mat-error
                                        *ngIf="dynamicListForm.controls['valueFormat'].errors?.['required']"><strong>At
                                        least one data element is required</strong></mat-error>
                                    <mat-error
                                        *ngIf="dynamicListForm.controls['valueFormat'].errors?.['hasComma']">Cannot
                                        contain commas
                                    </mat-error>
                                    <mat-error
                                        *ngIf="dynamicListForm.controls['valueFormat'].errors?.['missingExpression']">At
                                        least one data element is required
                                    </mat-error>
                                    <mat-error
                                        *ngIf="dynamicListForm.controls['valueFormat'].errors?.['unmatchedToken']">{{unmatchedTokenErrorMessage}}</mat-error>
                                    <mat-error
                                        *ngIf="dynamicListForm.controls['valueFormat'].errors?.['invalidExpression']">{{dynamicListForm.controls['valueFormat'].errors?.['invalidExpression']}}
                                        is not a valid data element
                                    </mat-error>
                                </div>
                                <div class="w-50 textarea-formfield" style="padding-left: 10px; position: relative;">
                                    <textarea (itemSelected)="filteredMentionItems = mentionItems"
                                              (searchTerm)="onSearch($event)" [mentionConfig]="mentionConfig"
                                              [placeholder]="expressionPlaceHolderText" cdkAutosizeMinRows="4"
                                              cdkTextareaAutosize formControlName='displayNameFormat' class="w-100"></textarea>
                                    <mat-error
                                        *ngIf="dynamicListForm.controls['displayNameFormat'].errors?.['required']"><strong>At
                                        least one data element is required</strong></mat-error>
                                    <mat-error
                                        *ngIf="dynamicListForm.controls['displayNameFormat'].errors?.['missingExpression']">At
                                        least one data element is required
                                    </mat-error>
                                    <mat-error
                                        *ngIf="dynamicListForm.controls['displayNameFormat'].errors?.['unmatchedToken']">{{unmatchedTokenErrorMessage}}</mat-error>
                                    <mat-error
                                        *ngIf="dynamicListForm.controls['displayNameFormat'].errors?.['invalidExpression']">{{dynamicListForm.controls['displayNameFormat'].errors?.['invalidExpression']}}
                                        is not a valid data element
                                    </mat-error>
                                </div>
                            </div>
                        </div>
                        <a href="https://mrshelpdesk.zendesk.com/hc/en-us/articles/11985703647511"
                           rel="noopener noreferrer" target="_blank">How do I configure a dynamic list?</a>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </form>
</div>
