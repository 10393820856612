import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppService } from '../../../../../app.service';
import { AccountEvent, AccountMessage, HMACRequest } from '../../../../../models';
import { AccountMessageTemplate } from '../../../models/account-message-template.interface';

@Component({
    selector: 'app-event-action-accord',
    templateUrl: './event-action-accord.component.html',
    styleUrls: ['./event-action-accord.component.scss']
})
export class EventActionAccordComponent implements OnInit {

    public accordForm: UntypedFormGroup;
    headersForm: UntypedFormArray;
    formSubmitted: boolean = false;
    event: AccountEvent;
    template: AccountMessageTemplate;
    messageId: any;
    hmacRequest: HMACRequest;
    secretKeys: any;

    constructor(private _fb: UntypedFormBuilder,
    public appService: AppService,
    public dialogRef: MatDialogRef<EventActionAccordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit() {
        this.event = this.data.event;
        this.accordForm = this._fb.group({
            url: ['', Validators.required],
            userName: [''],
            secret: [''],
            hmacRequest: this._fb.group({ userName: [''], secret: [''], }),
            oAuth2Request: this._fb.group(
                {
                    grantType: [''],
                    tokenEndpoint: [''],
                    clientId: [''],
                    clientSecret: [''],
                    scope: [''],
                }),
            authType: [''],
            content: ['', Validators.required],
            retryAttempts: ['5'],
            expirationHours: ['1'],
            errorCaseOnFailure: [false],
            headers: this._fb.array([])
        });

        this.setupForm();
        this.getSecretKeys();
    }

    setupForm() {
        if (this.event.accountMessage && this.event.accountMessage.template) {
            this.template = JSON.parse(this.event.accountMessage.template);

            const userName = this.template?.hmacRequest?.userName || this.template?.userName;
            const secret = this.template?.hmacRequest?.secret || this.template?.secret;

            this.accordForm.patchValue({
                url: this.template.url,
                hmacRequest: {
                    userName,
                    secret,
                },
                oAuth2Request: {
                    grantType: this.template?.oAuth2Request?.grantType,
                    tokenEndpoint: this.template?.oAuth2Request?.tokenEndpoint,
                    clientId: this.template?.oAuth2Request?.clientId,
                    clientSecret: this.template?.oAuth2Request?.clientSecret,
                    scope: this.template?.oAuth2Request?.scope
                },
                authType: this.template.authType,
                content: this.template.content,
                retryAttempts: this.template.retryAttempts,
                expirationHours: this.template.expirationHours,
                errorCaseOnFailure: this.template.errorCaseOnFailure,
                headers: this.template.headers
            });
            const existingHeaders = this.template?.headers;
            existingHeaders?.forEach((header: { key: string, value: string }) => this.addHeader(header.key, header.value));
        }
    }

    get headers() {
        return this.accordForm.get('headers') as UntypedFormArray;
    }
    set headers(val: UntypedFormArray) {
        this.headers = val;
    }

    addHeader(key?: string, value?: string) {
        this.headers.push(this._fb.group({
            key: key ?? '',
            value: value ?? ''
        }));
    }

    getFormControl(formControlName, index) {
        const formArray = this.accordForm.get(formControlName) as UntypedFormArray;
        return formArray.at(index).get('mapKey_Id' + index);
    }

    saveAccountMessage() {
        const message = this.accordForm.value;
        this.formSubmitted = true;

        if (this.accordForm.valid) {
            if (this.event.accountMessage) {
                this.event.accountMessage.template = JSON.stringify(message);
            } else {
                const accountMessage: AccountMessage = {
                    id: this.messageId,
                    template: JSON.stringify(message)
                };
                this.event.accountMessage = accountMessage;
            }

            this.dialogRef.close();
        } else {
            this.accordForm.markAsTouched();
            this.accordForm.controls.content.markAsTouched();
        }
    }

    getSecretKeys() {
        this.appService.getData(`ConfigData/GetSecretKeys`).subscribe(
            data => {
                if (data.status === 'success') {
                    this.secretKeys = data.data.sort((a,b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0));
                } else {
                    this.secretKeys = [];
                }        
            }
        );
    }

    removeHeader(index) {
        this.headers.removeAt(index);
    }
}
