import { FeatureManagerService, NotificationService } from "@Services";
import { animate, style, transition, trigger } from '@angular/animations';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, forwardRef, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatMenuTrigger } from "@angular/material/menu";
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions } from "@angular/material/tooltip";
import { AppService } from 'app/app.service';
import { FeatureToggle } from "app/enums";
import { AccountInterviewComponent } from '../../account-interview.component';
import { AccountInterviewService } from '../../account-interview.service';
import { PrefillObjectPropertySettings } from "@AccountConfig/account-interview/prefill-answer/prefill-answer.component";
import { QuestionsDTO } from "@DTOs";


const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
    showDelay: 0,
    hideDelay: 0,
    touchendHideDelay: 1500,
    disableTooltipInteractivity: true
};

@Component({
    selector: 'interview-navigation-question',
    // host: { 'class': 'content' },
    templateUrl: './interview-navigation-question.component.html',
    styleUrls: ['./interview-navigation-question.component.scss'],
    animations: [
        trigger('showHide', [
            transition(":enter", [
                style({ opacity: 0 }), //apply default styles before animation starts
                animate(
                    "20ms ease-in",
                    style({ opacity: 1 })
                )
            ]),
            transition(":leave", [
                style({ opacity: 1 }), //apply default styles before animation starts
                animate(
                    "20ms ease-in",
                    style({ opacity: 0 })
                )
            ])
        ])
    ],
    providers: [
        { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults }
    ],
})
export class InterviewNavigationQuestionComponent implements OnDestroy, OnInit {
    @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
    @Input() currentQuestion;
    @Input() questions;
    @Input() section;
    @Input() sections;
    @Input() isReflexiveChildren;
    @Input() selectedQuestion;
    @Input() selectedReflexiveChoice;
    @Input() isRepeatBlockChildren;
    @Input() repeatBlockParent;
    @Input() selectedSection;
    @Input() nestedLevel: number;
    _filterReflexiveQuestionsToQuesions: string[];
    @Input() set filterReflexiveQuestionsToQuesionIds(val: string[]) {
        this._filterReflexiveQuestionsToQuesions = val;
    }
    get filterReflexiveQuestionsToQuesionIds() {
        if (this._filterReflexiveQuestionsToQuesions && this._filterReflexiveQuestionsToQuesions.length) {

            const filteredQuestions = this.questions.filter(x => this._filterReflexiveQuestionsToQuesions.includes(x.id));
            return filteredQuestions;
        }
        return this.questions;
    }

    filteredReflexiveChilden(reflexiveChildren) {
        if (this._filterReflexiveQuestionsToQuesions && this._filterReflexiveQuestionsToQuesions.length) {
            return reflexiveChildren.filter(reflexiveChild =>
                reflexiveChild.reflexiveQuestions.some(x => this._filterReflexiveQuestionsToQuesions.includes(x.id)));
        }
        return reflexiveChildren;
    }

    @Input() disableDragAndDrop = false;
    isReflexiveQuestionsLoaded: boolean;
    question: any;
    questionIndex: any;
    subscriptions: any = [];
    isDragging = false;
    showHide = 'collapse';
    disabledSections: string[] = [];
    _parentQuestions: any = [];
    moveToSectionEnabled = false;

    @Input()
    set parentQuestions(value) {
        if (value && value.length) {
            this._parentQuestions = [...value, this.currentQuestion.id];
        }
        if (this.currentQuestion) {
            this.currentQuestion['parentQuestions'] = value;
        }
    }

    get parentQuestions() {
        return this._parentQuestions;
    }

    @ViewChildren(forwardRef(() => InterviewNavigationQuestionComponent)) interviewNavigationQuestionChildren: QueryList<InterviewNavigationQuestionComponent>;

    constructor(
        private appService: AppService,
        private interviewService: AccountInterviewService,
        private interviewComponent: AccountInterviewComponent,
        private featureManagerService: FeatureManagerService,
        private notificationService: NotificationService) {
    }

    ngOnInit() {
        this.subscriptions.push(
            this.interviewService.onPreviewQuestionSelect.subscribe(data => {
                let targetQuestion = (this.questions || []).find(x => x.id === data.question.id);
                let actualSection = this.section;
                if (this._filterReflexiveQuestionsToQuesions
                    && this._filterReflexiveQuestionsToQuesions.length
                    && data.question.sectionsId === this.section.id) {
                    const updateActualSection = this.sections.find(currentSection => currentSection.id === data.question.sectionsId);
                    if (updateActualSection) {
                        actualSection = updateActualSection;
                    }
                    if (!targetQuestion && actualSection && !this.isRepeatBlockChildren) {
                        targetQuestion = (actualSection.questions || []).find(x => x.id === data.question.id);
                    }
                }
                if (targetQuestion != null) {
                    this.interviewService.selectQuestion({
                        'section': actualSection,
                        'question': targetQuestion,
                        'isReflexiveChildren': this.isReflexiveChildren,
                        'isRepeatBlockChildren': this.isRepeatBlockChildren,
                        'repeatBlockParent': this.repeatBlockParent
                    });
                }

            })
        );
        this.moveToSectionEnabled = this.featureManagerService.getByName(FeatureToggle.ConfigInterviewMoveQuestionBetweenSections).enabled;
        this.subscriptions.push(this.interviewService.onMoveQuestion.subscribe({
            next: (result: any) => {
                if (result.success) {
                    const question = result.data.data;
                    const questionTitle = question.questionTitle ? question.questionTitle : question.mapKeyName;
                    if (this.sections && this.sections.length > 0) {
                        const section = this.sections.find(x => x.id == question.sectionsId);
                        section.expanded = true;
                        this.notificationService.showNotification({ message: `${questionTitle} has been moved to the bottom of ${section.name}` });
                        this.selectQuestion(section, question, false);
                    }
                }
            }
        }));
        if (this._filterReflexiveQuestionsToQuesions && this._filterReflexiveQuestionsToQuesions.length) {
            const repeat = this.questions.filter(q => q.answerType === 'RepeatBlock');
            repeat.forEach(r => {
                r.expanded = true;
                this.getRepeatBlockQuestions(r);
            });
            const reflexive = this.questions.filter(q => q.isReflexive);
            reflexive.forEach(r => {
                r.expanded = true;
                this.getReflexiveQuestions(r);
            });
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => {
            subscription.unsubscribe();
        });
    }

    getReflexiveQuestions(question) {
        if (question.isReflexiveQuestionsLoaded) {
            return;
        }
        this.interviewService.getReflexiveQuestions(question);
    }

    getRepeatBlockQuestions(question) {
        if (question.isRepeatBlockQuestionsLoaded) {
            return;
        }
        this.interviewService.getRepeatBlockQuestions(question);
    }

    selectQuestion(section, question, isReflexiveChildren) {
        let actualSection = section;
        if (this._filterReflexiveQuestionsToQuesions && this._filterReflexiveQuestionsToQuesions.length) {
            const updateActualSection = this.sections.find(currentSection => currentSection.id === section.id);
            if (updateActualSection) {
                actualSection = updateActualSection;
            }
        }
        this.interviewService.selectQuestion({
            'section': actualSection,
            'question': question,
            'isReflexiveChildren': isReflexiveChildren,
            'isRepeatBlockChildren': this.isRepeatBlockChildren,
            'repeatBlockParent': this.repeatBlockParent
        });
    }

    deleteQuestion(questionId) {
        this.interviewService.deleteQuestion(questionId);
    }

    checkACL(permissionType, redirect?) {
        return this.appService.checkACL('Accounts', permissionType, redirect);
    }

    changeQuestionOrder(section, from, to) {
        this.interviewService.changeQuestionOrder({ section: section, from: from, to: to });
    }

    hideQuestion(question) {
        let hideInLeftPanel = false;
        if (question.propertyValues) {
            question.propertyValues.forEach(element => {
                if (element.ansTypePropertiesId === '00000000-0000-0000-0000-000000000038') {
                    hideInLeftPanel = true;
                }
            });
        }
        return hideInLeftPanel;
    }

    drop(section, event: CdkDragDrop<string[]>) {
        const actualPreviousIndex = event.item.data.questionIndex;
        const change = event.previousIndex - event.currentIndex;
        moveItemInArray(section.questions, actualPreviousIndex, actualPreviousIndex - change);
        let hasOrderChanged = false;
        section.questions.forEach((question, index) => {
            if (question.order !== (index + 1)) {
                hasOrderChanged = true;
            }

            question.order = (index + 1);
        });
        if (hasOrderChanged) {
            this.interviewService.changeQuestionOrder({ section: section, from: 0, to: 0 });
        }
    }

    moveQuestionToSection(question, newSection) {
        question.sectionsId = newSection.id;

        this.section.questions = this.section.questions.filter(x => x.id !== question.id);
        newSection.questions.push(question);
        question.order = newSection.questions.length + 1;

        this.interviewService.moveQuestionToSection(question, newSection.id);
    }

    questionId(question) {
        return this.section.id + "_" + question.id;
    }

    openMoveToSectionMenu(question) {
        this.disabledSections = this.getSectionMenuData(question).filter(x => x !== question.sectionsId);
    }

    private getAllSectionsThatContainQuestionMapkeys(question, questionsToCheck) {
        const sectionFromSections = this.sections.find(x => x.id === question.sectionsId);
        const reflexiveQuestionsFromSection = sectionFromSections.sectionReflexives.filter(x => questionsToCheck.includes(x.id));
        const reflexiveQuestionMapkeys = reflexiveQuestionsFromSection.map(x => x.mapkeysId).filter(x => x);

        //Get Child reflexives
        questionsToCheck.forEach(question => {
            this.getReflexiveMapkeysFromSectionReflexivesByQuestionId(question, sectionFromSections.sectionReflexives).forEach(x => reflexiveQuestionMapkeys.push(x));
        });
        //Return all sections that have the same mapkeysId as mapkeyId
        const sections = this.sections.filter(x =>
            x.sectionReflexives?.some(reflexive => reflexive && (reflexive.mapkeysId === question.mapkeysId || reflexiveQuestionMapkeys.includes(reflexive.mapkeysId))) ||
            x.questions?.some(filteredQuestion => filteredQuestion && (filteredQuestion.mapkeysId === question.mapkeysId || reflexiveQuestionMapkeys.includes(filteredQuestion.mapkeysId)))
        );

        return (sections || []).map(x => x.id);
    }

    private getSectionMenuData(question) {
        //If mapkeyId is null then all sections are allowed
        if (!question.mapkeysId) return [];

        //If question has prefillProperties and questionFrom is not empty then no sections are allowed
        if(this.questionIsUsedInPrefill(question)) return this.sections.map(x => x.id);

        const reflexiveQuestions = ([...question.reflexiveQuestions, ...question.repeatBlockQuestions]).map(questions => questions.refQuestionId ?? questions.repeatedQuestionId);
        if ((reflexiveQuestions && reflexiveQuestions.length > 0)) {
            return this.getAllSectionsThatContainQuestionMapkeys(question, reflexiveQuestions);
        }
        //Return all sections that have the same mapkeysId as mapkeyId
        const sections = this.sections.filter(x =>
            x.sectionReflexives?.some(reflexive => reflexive.mapkeysId === question.mapkeysId) ||
            x.questions?.some(filteredQuestion => filteredQuestion.mapkeysId === question.mapkeysId)
        );

        return (sections || []).map(x => x.id);
    }

    getToolTip(question) {

        const mapkey = this.interviewComponent.mapKeys.find(x => x.id === question.mapkeysId);
        if (mapkey) {
            if (question.answerType === 'MultiChoiceSingleAnswer' || question.answerType === 'MultiChoiceMultiAnswer' || question.answerType === 'MultiChoiceMultiAnswerV2' || question.answerType === 'RepeatBlock') {
                return "Section already contains a Mapkey within '" + question.mapKeyName + "' or one of its sub-questions";
            }
            return "Section already contains this Mapkey: " + mapkey.entityHierarchy;
        }
    }

    questionIsUsedInPrefill(question){
        const prefillPropertiesOfAllQuestions : PrefillObjectPropertySettings[] = this.section.questions?.filter(q => q.objectProperties?.some(ob => ob.key === 'prefillProperties')).map(q => JSON.parse(q.objectProperties.find(ob => ob.key === 'prefillProperties').value));
        return prefillPropertiesOfAllQuestions?.some(p => p.fromQuestion === question.id);
    }

    getQuestionsUsedInPrefill(question) : QuestionsDTO[]{
        const questionsUsed : QuestionsDTO[] = [];
        this.section.questions.forEach(q =>{
            const thing = q.objectProperties.find(op => op.key === 'prefillProperties');
            if(!thing) return;
            const prefillProperties : PrefillObjectPropertySettings = JSON.parse(thing.value);
            if(!prefillProperties) return;
            if(prefillProperties.fromQuestion === question.id)
                questionsUsed.push(q);
        });
        return questionsUsed;
    }

    getReflexiveMapkeysFromSectionReflexivesByQuestionId(questionId, sectionReflexives) {
        const reflexiveQuestion = sectionReflexives.filter(x => x.parentQuestionId === questionId);
        const mapkeyIds: string[] = [];
        reflexiveQuestion.forEach(question => {
            const result = this.getReflexiveMapkeysFromSectionReflexivesByQuestionId(question.id, sectionReflexives);
            result.forEach(x => mapkeyIds.push(x));
            if (question.mapkeysId) mapkeyIds.push(question.mapkeysId);
        });
        return mapkeyIds;
    }

    CollapseReflexiveChildren(reflexiveQuestion) {
        if (reflexiveQuestion.reflexiveChildren) {
            reflexiveQuestion.reflexiveChildren.forEach((child) => {
                child.reflexiveQuestions.forEach((reflexiveQuestion) => {
                    this.CollapseReflexiveChildren(reflexiveQuestion);
                });
                child.expanded = false;
            });
        }
        reflexiveQuestion.expanded = false;
    }

    collapseAllChildren() {
        this.interviewNavigationQuestionChildren.forEach((child) => {
            child.collapseAllChildren();
        });
        this.questions.forEach((question) => {
            if (question.isReflexive) {
                if (question.reflexiveChildren) {
                    question.reflexiveChildren.forEach((child) => {
                        child.reflexiveQuestions.forEach((reflexiveQuestion) => {
                            this.CollapseReflexiveChildren(reflexiveQuestion);
                        });
                        child.expanded = false;
                    });
                }
            }
            if (question.answerType == 'RepeatBlock') {
                if (question.repeatBlockChildren) {
                    question.repeatBlockChildren.forEach((child) => {
                        child.expanded = false;
                    });
                }
            }

            question.expanded = false;
        });
    }
}

