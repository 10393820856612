enum NoteType {
    JournalEntry = '00000000-0000-0000-0000-000000000001',
    UpdateStatus = '00000000-0000-0000-0000-000000000002',
    CaseReferral = '00000000-0000-0000-0000-000000000006',
    PendingInformation = '00000000-0000-0000-0000-000000000011',
    Contact = '00000000-0000-0000-0000-000000000012',
    Audit = '00000000-0000-0000-0000-000000000013',
    StatusChange = '00000000-0000-0000-0000-000000000014',
    Interpreter = '00000000-0000-0000-0000-000000000015',
    SystemLog = '00000000-0000-0000-0000-000000000016',
    Error = '00000000-0000-0000-0000-000000000017',
    Underwriting = '00000000-0000-0000-0000-000000000018'
}

export { NoteType };
