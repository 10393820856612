import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { BaseInterviewFormFieldComponent, BaseFormFieldServices } from '../base-interview-form-field/base-interview-form-field.component';

@Component({
    selector: 'routing-number-answer',
    templateUrl: './routing-number-answer.component.html',
    styleUrls: ['./routing-number-answer.component.scss']
})
export class RoutingNumberAnswerComponent extends BaseInterviewFormFieldComponent implements OnInit {
    public mask = [/[0-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

    constructor(public services: BaseFormFieldServices) {

        super(services);
    }

    ngOnInit() {
        super.initialize({
            inputType: 'text',
            additionalValidators: [
                Validators.pattern(/^((0[0-9])|(1[0-2])|(2[1-9])|(3[0-2])|(6[1-9])|(7[0-2])|80)([0-9]{7})$/)
            ]
        });
    }
}
