<div class="question-container" [formGroup]="group" [ngClass]="{active: isSelected, interviewQuestion: interviewMode}">
  <div [ngClass]="group.get(config.id)?.hasError('validationError') ? 'invalid-question' : ''">
    <div class="question-header" *ngIf="!interviewMode" (click)="selectQuestion()">
      <button type="button" class="btn btn-link float-left" (click)="expanded = !expanded">
        <i aria-hidden="true" class="fa" [ngClass]="{'fa-angle-right': !expanded, 'fa-angle-down': expanded}"></i>
      </button>
      <span class="question-mapkey">{{ config.mapKeyName }}</span>
      <span class="question-mapkey float-right pr-2">{{ config.answerTypeName }}</span>
    </div>
    <div class="question-body" *ngIf="expanded && !useV2">
      <div class="question-text rendered-quill-content ql-editor" [innerHTML]="questionText"></div>
      <div class="help-text rendered-quill-content ql-editor" [innerHTML]="helpText" *ngIf="config.helpText?.length">
      </div>
      <div class="question-form">
        <input #fileInput hidden="true" type="file" (change)="selectFile($event)" [accept]="config.AllowedFileTypes" />
        <button mat-flat-button color="primary" (click)="fileInput.click()" [disabled]="isReadOnly() || services.interviewService.isInterviewLocked">{{config.ButtonText}}</button>
        <mat-error *ngIf="group.get(config.id)?.hasError('validationError')">
          {{ group.get(config.id)?.errors.validationError }}
        </mat-error>
      </div>
      <div style="position: relative; margin-top: 25px;" *ngIf="fileUploadProgress !== 0">
        <mat-progress-bar mode="determinate" [value]="fileUploadProgress" class="upload-progress-bar"></mat-progress-bar>
        <span style="position: absolute; top: 0.5em;" [ngStyle]="{'left': fileUploadProgress + '%'}" *ngIf="fileUploadProgress !== 100">
          {{ fileUploadProgress }}%
        </span>
        <span style="position: absolute; top: 0.3em; left: 40%; color: white" *ngIf="fileUploadProgress === 100 && fileUploadComplete">
          File upload complete
        </span>
        <span style="position: absolute; top: 0.3em; left: 40%; color: white" *ngIf="fileUploadProgress === 100 && !fileUploadComplete">
          File upload processing
        </span>
      </div>
      <app-field-details [question]="config" [form]="group" [readOnlyMode]="isReadOnly()"></app-field-details>
    </div>
    <div class="question-body" *ngIf="expanded && useV2">
      <div class="question-text rendered-quill-content ql-editor" [innerHTML]="questionText"></div>
      <div class="help-text rendered-quill-content ql-editor" [innerHTML]="helpText" *ngIf="config.helpText?.length">
      </div>
      <div class="question-form">
        <input #fileInput hidden="true" type="file" (change)="selectFileV2($event)" [accept]="config.AllowedFileTypes" />
        <button mat-flat-button color="primary" (click)="fileInput.click()" [disabled]="isReadOnly() || services.interviewService.isInterviewLocked">{{config.ButtonText}}</button>
        <mat-error *ngIf="group.get(config.id)?.hasError('validationError')">{{ group.get(config.id)?.errors.validationError }}</mat-error>
      </div>
      <div style="position: relative; margin-top: 25px;" *ngIf="fileUploadProgress !== 0">
        <mat-progress-bar mode="determinate" [value]="fileUploadService.uploadProgress" class="upload-progress-bar"></mat-progress-bar>
        <span style="position: absolute; top: 0.5em;" [ngStyle]="{'left': fileUploadService.uploadProgress + '%'}" *ngIf="!fileUploadComplete">
          {{ fileUploadService.uploadProgress }}%
        </span>
        <span style="position: absolute; top: 0.3em; left: 40%; color: white" *ngIf="fileUploadComplete">File upload complete</span>
        <span style="position: absolute; top: 0.3em; left: 40%; color: white" *ngIf="!fileUploadComplete">File upload processing</span>
      </div>
      <app-field-details [question]="config" [form]="group" [readOnlyMode]="isReadOnly()"></app-field-details>
    </div>
  </div>
</div>
