import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UWRequirementActionEvent, UWRequirementTableData } from '@Underwriting/models';

import { UWRequirementTableActions, UWRequirementTableProps } from '../uw-requirements-expand-table';


@Component({
    selector: 'uw-requirements-actions-ordered',
    templateUrl: './uw-requirements-actions-ordered.component.html',
    styleUrls: ['./uw-requirements-actions-ordered.component.scss'],
})
export default class UWRequirementsActionsOrderedComponent {
    @Input() caseRequirement: UWRequirementTableData;
    @Input() props: UWRequirementTableProps;
    @Output() onMenuAction = new EventEmitter<UWRequirementActionEvent>();

    public get UWRequirementTableActions() {
        return UWRequirementTableActions;
    }

    handleMenuAction(columnAction: UWRequirementTableActions, caseRequirement: UWRequirementTableData) {
        const actionEvent: UWRequirementActionEvent = {
            action: columnAction,
            data: caseRequirement
        };
        this.onMenuAction.emit(actionEvent);
    }

    handleMenuActionEvent(event: UWRequirementActionEvent) {
        this.onMenuAction.emit(event);
    }    
}
