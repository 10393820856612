import { Component } from '@angular/core';
import {  RoutingService } from './services';

@Component({
    selector: 'app-not-found',
    host: { 'class': 'content' },
    templateUrl: './not-found.component.html',
    styleUrls: []
})
export class NotFoundComponent {

    constructor(public routingService: RoutingService
    ) {
    }

    goHome(){
        this.routingService.navigateToDefault();
    }
}
