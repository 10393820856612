<section id="audit-page" class="ag-page" [formGroup]="auditForm">
    <div class="page-header">
        <div class="title">Create Audit</div>
        <div class="title-buttons">
            <mrs-btn-tertiary class="back-btn" buttonLabel="Cancel" (onClick)="goToAuditPage()"></mrs-btn-tertiary>
            <mrs-btn-primary buttonLabel="Save Audit" (onClick)="onSubmit()"></mrs-btn-primary>
        </div>
    </div>

    <ng-container *ngIf="!clientsLoaded">
        <mat-spinner mode="indeterminate" diameter="30"></mat-spinner>
    </ng-container>

    <div class="page-content" *ngIf="clientsLoaded">
        <!-- General Settings -->
        <div class="page-section">
            <div class="section-header">
                <h3 class="title">General Settings</h3>
            </div>

            <div class="section-content">
                <!-- Name -->
                <div class="input name-input">
                    <mat-form-field class="form-field">
                        <mat-label>Name</mat-label>
                        <input matInput formControlName="name">
                    </mat-form-field>
                </div>

                <!-- Client -->
                <div class="input client-picker">
                    <mat-form-field class="form-field">
                        <mat-label>Client</mat-label>
                        <mat-select panelClass="auto-width-panel" formControlName="clientId">
                            <mat-option *ngFor="let client of clients" [value]="client.id">{{ client.name }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <!-- Date Range -->
                <div class="input date-picker">
                    <mat-form-field class="form-field">
                        <mat-label>Audit Range</mat-label>
                        <mat-date-range-input [rangePicker]="rangePicker" [dateFilter]="dateRangeFilter">
                            <input matStartDate placeholder="Start date" formControlName="startDate">
                            <input matEndDate placeholder="End date" formControlName="endDate">
                        </mat-date-range-input>
                        <mat-hint>MM/DD/YYYY - MM/DD/YYYY</mat-hint>

                        <mat-error *ngIf="auditForm.controls.startDate.hasError('required') && !auditForm.controls.endDate.hasError('required')">start date is required</mat-error>
                        <mat-error *ngIf="!auditForm.controls.startDate.hasError('required') && auditForm.controls.endDate.hasError('required')">end date is required</mat-error>
                        <mat-error *ngIf="auditForm.controls.startDate.hasError('required') && auditForm.controls.endDate.hasError('required')">start and end date are required</mat-error>
                        <mat-error *ngIf="auditForm.controls.startDate.hasError('dateRange') || auditForm.controls.endDate.hasError('dateRange')">date range must be a year or less</mat-error>

                        <mat-datepicker-toggle matIconSuffix [for]="rangePicker"></mat-datepicker-toggle>
                        <mat-date-range-picker #rangePicker></mat-date-range-picker>
                    </mat-form-field>
                </div>

                <!-- Audit Date Type Picker-->
                <div class="input audit-date-type-picker">
                    <mat-form-field class="form-field">
                        <mat-label>Select Case by</mat-label>
                        <mat-select panelClass="auto-width-panel" formControlName="auditDateType" (selectionChange)="onAuditDateChange($event)">
                            <mat-option *ngFor="let dateType of auditDateTypes" [value]="dateType">{{ dateType.displayName }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <!-- Audit Group By Picker-->
                <div class="input audit-date-type-picker">
                    <mat-form-field class="form-field">
                        <mat-label>Group Cases by</mat-label>
                        <mat-select panelClass="auto-width-panel" formControlName="groupBy" (selectionChange)="onGroupByChange($event)">
                            <mat-option *ngFor="let groupByType of auditGroupByTypes" [value]="groupByType">{{ groupByType.displayName }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <!-- Audit Goal Percentage-->
                <div class="input audit-date-type-picker">
                    <mat-form-field class="form-field">
                        <mat-label>Set Target %</mat-label>
                        <input matInput formControlName="auditGoalPercentage">
                    </mat-form-field>
                </div>

            </div>
        </div>

        <!-- Accounts -->
        <div class="page-section">
            <div class="section-header">
                <h3 class="title">Accounts</h3>
            </div>
            <div class="section-subheader">
                <p>Only accounts and versions active during selected Date Range</p>
            </div>

            <div class="section-content">
                <div *ngIf="activeAccountsForSelectedClient == null && !loadingSelectedClientAccounts" class="empty-state-message">
                    Select a Client & Date Range to view Accounts
                </div>
                <div *ngIf="activeAccountsForSelectedClient == null && loadingSelectedClientAccounts" class="empty-state-message">
                    <mat-spinner mode="indeterminate" diameter="30"></mat-spinner>
                </div>

                <div *ngIf="activeAccountsForSelectedClient != null">
                    <account-rule-selector-table [accounts]="activeAccountsForSelectedClient" (onAuditRuleChange)="handleRuleChange($event)" [initialSort]="defaultSort"></account-rule-selector-table>
                </div>
            </div>
        </div>

        <!-- Preview -->
        <div class="page-section">
            <div class="section-header">
                <h3 class="title">Preview</h3>
            </div>

            <div class="section-subheader">
                <div class="input">
                    <mrs-btn-outline buttonLabel="Preview" isSubmitButton="true" [disabled]="(auditForm.controls.accountRules.value.length === 0) || (auditForm.controls.auditDateType.value === null) || (auditForm.controls.groupBy.value === null)" (onClick)="onPreview()"></mrs-btn-outline>
                </div>
                <div class="input" *ngIf="casesLoaded">
                    Total Cases: {{ auditCases.length }}
                </div>
            </div>

            <div class="section-content">
                <div *ngIf="!casesLoaded" class="empty-state-message">
                    Enter required info to preview
                </div>
                <div *ngIf="casesLoaded" class="results">
                    <div *ngIf="isAuditGroupingEnabled">
                        <audit-group-table [auditCasesGroups]="auditCasesGroups" [hideActions]="true" [auditGroupByType]="selectedAuditGroupByType"></audit-group-table>
                    </div>
                    <div *ngIf="!isAuditGroupingEnabled">
                        <audit-cases-table [auditCases]="auditCases"></audit-cases-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>