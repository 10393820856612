<section id="attachment-file-manager-dialog">
  <h2 mat-dialog-title>{{ dialogTitle }}</h2>
  <p mat-dialog-content class="details">Add requirement documents.</p>

    <form>
        <!-- No Documents -->
        <mat-dialog-content *ngIf="attachments.length == 0">
            <p class="no-documents">No documents are currently attached.</p>
        </mat-dialog-content>

        <!-- List Documents -->
        <mat-dialog-content class="file-list-content" *ngIf="attachments.length > 0">
            <ul class="file-list">
                <li *ngFor="let attachment of attachments"
                    (click)="handlePreview(attachment)"
                    [ngClass]="{ 'disabled': attachment.isTiffFile && !directDownloadIsEnabled}"
                    [matTooltipDisabled]="!attachment.isTiffFile || directDownloadIsEnabled"
                    [matTooltip]="msg_TiffPreviewNotSupported">
                    <div class="details">
                        <div>{{ attachment.name }}</div>
                        <div>{{ attachment.uploadedOnDay }} at {{ attachment.uploadedOnTime }}<b style="display: none;" class="todo"> by {{ attachment.uploadedBy }}</b></div>
                    </div>
                    <div class="download" *ngIf="attachment.isTiffFile && !directDownloadIsEnabled">
                        <button mat-icon-button color="primary" (click)="handleDownload(attachment)">
                            <mat-icon aria-hidden="false" aria-label="download icon">download</mat-icon>
                        </button>
                    </div>
                </li>
            </ul>
        </mat-dialog-content>

        <!-- Add Document Button & Progress Bar -->
        <mat-dialog-content class="add-button-row">
            <ng-container *ngIf="uploading">
                <div class="fileUploadProgress">
                    <span class="percent">{{ fileUploadService.uploadProgress }}% uploaded</span>
                    <mat-progress-bar mode="determinate" [value]="fileUploadService.uploadProgress"></mat-progress-bar>
                </div>
            </ng-container>
            <div class="button-row" [ngClass]="{ 'has-documents': uploading || attachments?.length > 0 || false }">
                <button mat-flat-button color="primary" (click)="fileInput.click()" [disabled]="uploading || data.readonly"><i aria-hidden="true" class="fa fa-plus"></i>&nbsp;Add Document</button>
                <p class="uploading-message" *ngIf="uploading">Closing this page during the upload will cause the items to be lost.</p>
            </div>
        </mat-dialog-content>

        <mat-dialog-actions align="end">
            <mrs-btn-tertiary buttonLabel="Close" buttonId="cancelButton" (click)="handleCancel()">Close</mrs-btn-tertiary>
        </mat-dialog-actions>

        <!-- https://stackoverflow.com/questions/4109276/how-to-detect-input-type-file-change-for-the-same-file -->
        <!-- https://karthiktechblog.com/angular/how-to-reset-the-selected-the-file-with-input-tag-file-type-in-angular-9/ -->
        <!-- Hidden File Input - We'll programatically trigger this to select the file -->
        <input #fileInput hidden="true" type="file" id="fileInput" (change)="handleUploadAttachment($event)" />
    </form>
</section>