import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from '@App';
import { lastValueFrom } from 'rxjs';

import { IConfigService } from '../../config/iconfigservice';
import { BaseService } from '../base.service';
import { ObservableService } from '../observable.service';

@Injectable()
export class CaseDynamicListMapkeyService extends BaseService {
    baseUrl: string;
    publicApiBaseUrl: string;

    constructor(
        public appService: AppService,
        public httpClient: HttpClient,
        public configService: IConfigService,
        public observableService: ObservableService
    ) {
        super(appService, configService, httpClient);

        this.baseUrl = this.appService.getAPIBaseURL();
        this.publicApiBaseUrl = this.configService.getConfiguration().publicApiUrl;
    }

    async getDynamicMapKeys(accountId: string, caseId: string, sectionId: string) {
        const clientId = this.appService.getClientId('');
        return lastValueFrom(super.getData(`${this.publicApiBaseUrl}client/${clientId}/account/${accountId}/case/${caseId}/section/${sectionId}/mapkeys/dynamicList`, undefined, true));
    }

    // TECH DEBT: ^JB I can't find this API endpoint. I only see an API that takes no individual mapkeyid (see: getDynamicMapKeys (plural))
    async getDynamicMapKey(accountId: string, caseId: string, mapkeyId: string) {
        const clientId = this.appService.getClientId('');
        return lastValueFrom(super.getData(`${this.publicApiBaseUrl}client/${clientId}/account/${accountId}/case/${caseId}/mapkeys/dynamicList/${mapkeyId}`, undefined, true));
    }
}
