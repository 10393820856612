<dynamic-form *ngIf="previewQuestions?.length; else noQuestionsPlaceholder"
[expandAllQuestions]="expandAllQuestions" [selectedQuestion]="selectedQuestion"
[constantLists]="constantLists"
[config]="previewQuestions">
</dynamic-form>


<ng-template #noQuestionsPlaceholder>
<div class="p-4 text-center text-muted">
    <img src="./assets/images/no-data-placeholder.png" alt="" class="pb-3"/>
    <h6>No Questions Found</h6>
</div>
</ng-template>
