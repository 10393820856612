import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import {MrsSearchFieldComponent} from '@Shared/components/mrs-search-field/mrs-search-field.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatIconModule,
        MatInputModule,
        MatFormFieldModule,
    ],
    exports: [MrsSearchFieldComponent],
    declarations: [MrsSearchFieldComponent]
})
export class MrsSearchFieldModule {
}
