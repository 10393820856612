import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'mrs-btn-icon',
    templateUrl: './mrs-btn-icon.component.html',
    styleUrls: ['./mrs-btn-icon.component.scss']
})
export class MrsBtnIconComponent {

  @Input() buttonLabel: string;
  @Input() buttonId: string;
  @Input() iconName = '';
  @Input() isSubmitButton = false;
  @Input() disabled = false;
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onClick = new EventEmitter<Event>();

}
