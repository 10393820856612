import { Location } from '@angular/common';
import { Injectable, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../app.service';


export enum RoutesEnum {
    audit = './audit',
    dashboard = '/dashboard',
    myCases = '/mycases',
    caseMananger = '/casemanager',
    caseManangerView = '/casemanager/view',
    caseManangerEdit = '/casemanager/edit',
    clients = '/clients',
    client = '/client/:clientCode',
    clientDetails = '/client/:clientCode/details',
    accountCreation = '/client/:clientCode/account/new',
    accountDetails = '/client/:clientCode/account/:accountCode/:versionNumber',
    interview = '/client/:clientCode/account/:accountCode/:versionNumber/interview',
    interviewRestart = '/client/:clientCode/account/:accountCode/:versionNumber/interview/:caseId',
    caseSummary = '/client/:clientCode/account/:accountCode/:versionNumber/:caseId/summary',
    roles = '/roles',
    users = '/users',
    accounts = '/accounts',
    reports = '/reports',
    callManager = '/callmanager',
    endOfCall = '/navigation/endofcall/:id/:tenant',
    system = '/system',
    login = '/login',
    loginV2 = '/authorize',
    default = '|default|',
    endOfInterview = '/end-of-application',
    purlInterviewStart = '/purl/client/:clientCode/account/:accountCode/:versionNumber/interview/:caseId',
    userManagement = '/manage-users',
    home = '',
    notFound = '/404'
}

@Injectable()
export class RoutingService {

    constructor(
        private appService: AppService,
        private location: Location,
        private router: Router,
        private zone: NgZone) { }

    public navigateToDefault() {
        const defaultRoute = this.getDefaultRoute();
        this.router.navigate([defaultRoute]);
    }

    public navigateToNotFound() {
        this.router.navigate([RoutesEnum.notFound], { skipLocationChange: true });
    }
    public getDefaultRoute(): RoutesEnum {
        return this.appService.checkACL('Dashboard', 'V') ?
            RoutesEnum.dashboard
            : RoutesEnum.myCases;
    }

    public navigateToRoute(route: RoutesEnum, params?: object, queryParams?: object) {
        console.log(`navigate to route: ${route}`, queryParams);
        this.router.navigateByUrl(
            this.router.createUrlTree([this.createUrl(route, params)], {
                queryParams,
                queryParamsHandling: 'merge'
            })
        );
    }

    public navigateToRouteInNewTab(route: RoutesEnum, params?: object, queryParams?: object) {
        const url = this.router.serializeUrl(
            this.router.createUrlTree([this.createUrl(route, params)], {
                queryParams,
                queryParamsHandling: 'merge'
            }));

        window.open(url, '_blank');
    }

    public setLocationWithoutRouting(route: RoutesEnum, params?: object) {
        console.log(`setting url to: ${route}`);
        this.location.go(this.createUrl(route, params));
    }

    public navigateToUrl(url: string) {
        this.zone.run(() => {
            console.log(`navigating straight to url: ${url}`);
            this.router.navigateByUrl(url);
        });
    }

    public async navigateToAsync(url: string, route?: ActivatedRoute) {
        if (window.location.href.toLowerCase().includes(url.toLowerCase())) {
            // browser refresh
            return true;
        }
        return this.zone.run(() => {
            return this.router.navigate([url], { relativeTo: route });
        });
    }

    public createUrl(route: RoutesEnum, params?: object) {
        let routePath: string = route;
        if (route === RoutesEnum.default) {
            routePath = this.getDefaultRoute();
        } else {
            if (params) {
                for (const key in params) {
                    // e.g.
                    //      routePath = '/:clientId/account/:accountId
                    //      params = {
                    //          clientId: 103,
                    //          accountId: 0008
                    //      }
                    //
                    //      result: /103/account/0008
                    //

                    routePath = routePath.replace(`:${key}`, params[key]);
                }
            }
        }

        return routePath;
    }

    public url() { return this.router.url; }
}
