<div class="flex flex-row">
  <div class="flex flex-column mt-3">
    <div *ngFor="let message of data">
      <div class="message-header">
        {{message.title}}
      </div>
      <ul>
        <li class="message-text" *ngFor="let item of message.items">{{item}}</li>
      </ul>
    </div>
  </div>
  <div class="dismiss">
    <button mat-icon-button (click)="snackBarRef.dismiss()">
      Close
    </button>
  </div>
</div>
