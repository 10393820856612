<div class="mx-auto mt-3" style="width: 100%">
  <div class="flex flex-row" style="justify-content: end;">
    <mrs-btn-icon (onClick)="addAccountSigner()" iconName="add" buttonLabel="Add" buttonId="Add"
      *ngIf="selectedSigner == null"></mrs-btn-icon>
  </div>
  <mat-card appearance="outlined" *ngFor="let signer of signers" class="mt-3"
    [ngClass]="{'signer-card': selectedSigner != signer}">
    <mat-card-content>
      <div class="flex flex-row" (click)="selectSigner(signer)">
        <span>{{ signer.label }}</span>
        <div class="flex-1"></div>
        <div style="justify-content: flex-end">
          <i aria-hidden="true" class="fa fa-trash" (click)="deleteAccountSigner(signer)" *ngIf="signer.id != null"></i>
        </div>
      </div>
      <div *ngIf="selectedSigner === signer">
        <form class="px-5 py-3" [formGroup]="signerForm" (ngSubmit)="saveAccountSigner()" novalidate autocomplete="off">
          <mat-form-field class="w-100">
            <mat-label>Label</mat-label>
            <input matInput formControlName='label'>
            <mat-error *ngIf="formSubmitted && signerForm.get('label').hasError('required')">
              Label
              <strong>required</strong>
            </mat-error>
          </mat-form-field>
          <map-keys-dropdown [showRequiredError]="formSubmitted && signerForm.get('emailMapKeyId').hasError('required')"
            [displayList]="inputMapKeys" placeholder="Email Mapkey" formControlName="emailMapKeyId"
            [formControl]="signerForm.get('emailMapKeyId')"></map-keys-dropdown>
          <map-keys-dropdown [showRequiredError]="formSubmitted && signerForm.get('nameMapKeyId').hasError('required')"
            [displayList]="inputMapKeys" placeholder="Name Mapkey" formControlName="nameMapKeyId"
            [formControl]="signerForm.get('nameMapKeyId')"></map-keys-dropdown>
          <map-keys-dropdown [displayList]="inputMapKeys" placeholder="Phone Number Mapkey"
            formControlName="phoneMapKeyId" [formControl]="signerForm.get('phoneMapKeyId')"></map-keys-dropdown>
          <map-keys-dropdown [displayList]="inputMapKeys" placeholder="Delivery Method Mapkey"
            formControlName="deliveryMethodMapKeyId" [formControl]="signerForm.get('deliveryMethodMapKeyId')">
          </map-keys-dropdown>
          <map-keys-dropdown [displayList]="inputMapKeys" placeholder="Signing Portal URL Mapkey"
            formControlName="urlMapKeyId" [formControl]="signerForm.get('urlMapKeyId')"></map-keys-dropdown>
          <map-keys-dropdown [displayList]="inputMapKeys" placeholder="SSN Mapkey" formControlName="ssnMapKeyId"
            [formControl]="signerForm.get('ssnMapKeyId')"></map-keys-dropdown>
          <map-keys-dropdown [displayList]="inputMapKeys" placeholder="Access Code Mapkey"
            formControlName="accessCodeMapKeyId" [formControl]="signerForm.get('accessCodeMapKeyId')">
          </map-keys-dropdown>

          <br />
          <br />
          <app-rich-text-editor #richTextEditor [htmlContent]="signer.remoteEmbeddedInstructions"
            [title]="configEditorTitle" [maxLength]="maxLength">
          </app-rich-text-editor>
          <br />
          <h6 class="mt-4">Signature Output Mappings</h6>
          <div formArrayName="accountSignerMappings">
            <div *ngFor="let mapping of signerForm.controls.accountSignerMappings.controls; let i = index;">
              <ng-container [formGroupName]="i">
                <map-keys-dropdown [displayList]="outputMapKeys" placeholder="{{ mapping.get('name').value }} MapKey"
                  formControlName="mapKeyId"
                  [formControl]="signerForm.get('accountSignerMappings').at(i).get('mapKeyId')"></map-keys-dropdown>
              </ng-container>
            </div>
          </div>
          <div class="pt-4 flex" style="justify-content: flex-end;">
            <button type="button" mat-button (click)="cancelSigner(signer)" class="mr-1 material-default">
              CANCEL
            </button>
            <button type="submit" [disabled]="richTextEditor.textEditorFormGroup.get('htmlEditorControl').invalid"
              mat-flat-button color="primary">
              SAVE
            </button>
          </div>
        </form>
      </div>
    </mat-card-content>
  </mat-card>
</div>