import { Injectable } from '@angular/core';
import * as _ from 'lodash';

import { AppService } from 'app/app.service';
import { QueueStatusEnum } from 'app/enums';
import { NotificationSeverity } from 'app/services';
import { MatDialog } from '@angular/material/dialog';
import { BrainTreeComponent } from 'app/components/braintree/braintree.component';
import { InterviewService } from 'app/interview.service';

@Injectable()
export class BraintreeIntegrationResponseHandler{

    constructor(
        public appService: AppService,
        public interviewService: InterviewService,
        public dialog: MatDialog
    ) {}

    handleResponse(clientId: string, accountId: string,integrationResponse, integrationData){
        if (integrationResponse.status === QueueStatusEnum.Completed) {
            const braintreeAuth = _.get(integrationData, 'details.clientProperties.authToken');

            if (braintreeAuth != null) {
                this.showBraintreeDialog(clientId, accountId, integrationData.caseDetailId, integrationData.caseIntegrationQueueId, braintreeAuth);
            }else{
                this.appService.showMsg(NotificationSeverity.Error, 'Authentication token was not setup or provided');
            }
        }else if (integrationResponse.status === QueueStatusEnum.Failed) {
            this.appService.showMsg(NotificationSeverity.Error, integrationResponse.errorMessage);
        }
    }

    showBraintreeDialog(clientId: string, accountId: string, caseId: string, caseIntegrationQueueId: string, brainTreeAuth: string) {
        this.dialog.open(BrainTreeComponent, {
            width: '60%',
            disableClose: true,
            data: {
                clientId: clientId,
                caseId: caseId,
                caseIntegrationQueueId: caseIntegrationQueueId,
                brainTreeAuth,
                accountId: accountId
            }
        });
    }
}
