import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Params } from '@angular/router';
import { FeatureToggle as FeatureToggleType } from 'app/enums';
import { IActivateMeRequest, ICaseUserActivity, IDeactivateMeRequest } from 'app/shared/models/ICaseUserActivity';
import { AppService } from '../../app.service';
import { AuthLegacyService } from '../../auth/auth-legacy.service';
import { IConfigService } from '../../config/iconfigservice';
import { FeatureManagerService } from '../global/feature-manager/feature-manager.service';
import { SignalRService } from '../signalr.service';

@Injectable()
export class CaseNotificationsService {

    publicApiBaseUrl: string;
    params: Params;

    constructor(
        private appService: AppService,
        private configService: IConfigService,
        private httpClient: HttpClient,
        private signalRService: SignalRService,
        private authService: AuthLegacyService,
        private featureManagerService: FeatureManagerService
    ) {
        this.publicApiBaseUrl = this.configService.getConfiguration().publicApiUrl;
    }

    //#region Helper

    private putWithAuth<T>(url: string, clientId: string, payload: any) {
        const isAuthenticated = this.authService.isAuthenticated();

        if (isAuthenticated || this.featureManagerService.getByName(FeatureToggleType.GlobalUseAuthenticationV2)?.enabled) {
            const result = this.httpClient.put(url, payload, { headers: this.appService.getHeaders(clientId) });
            return result as Observable<T>;
        }
    }

    //#endregion

    addUserToCase(clientId, caseId) {
        const url = this.appService.getAPIBaseURL() + 'CasePresence/case/' + caseId + '/AddUserToCase/';

        return this.httpClient.put(url, null, {
            headers: this.appService.getHeaders(clientId)
        });
    }

    sendCaseHeartbeat(clientId, caseId) {
        const url = this.appService.getAPIBaseURL() + 'CasePresence/case/' + caseId + '/HeartBeat/';
        const isAuthenticated = this.authService.isAuthenticated();
        if (isAuthenticated || this.featureManagerService.getByName(FeatureToggleType.GlobalUseAuthenticationV2)?.enabled) {
            return this.httpClient.put(url, null, {
                headers: this.appService.getHeaders(clientId)
            });
        }
    }

    removeUserFromCase() {
        const isAuthenticated = this.authService.isAuthenticated();
        if (this.params && this.params != null && isAuthenticated) {
            const sessionId = this.signalRService.getSessionId();
            const clientCode = this.params['clientCode'];
            const caseId = this.params['caseId'];

            const httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('id_token')}`,
                    'X-Tenant-Guid': clientCode,
                    'X-Session-Id': sessionId
                })
            };

            const url = this.appService.getAPIBaseURL() + 'CasePresence/case/' + caseId + '/RemoveUserOnCase/';

            this.httpClient.put(url, null, httpOptions).subscribe();
            // TECH DEBT: DEV NOTE: There are some issues with removing case presence and the browser closing too quickly before the API call is resolved.
            // This often results in a lot of App Insights errors about the client connect was closed.  I believe this is mostly tied to closing the browser
            // tab or full browser window but we might consider turning the above subscribe, which is non-blocking, into a promise/await.  That might help
            // eliviate some of these timing issues.  The below code is an example of the promise conversion.  We will have to make this method async
            // and update all callers to also be async and follow those changes down. But this might improve this issue.
            // await firstValueFrom(this.httpClient.put(url, null, httpOptions));
        }
    }

    getUsersOnCase(clientId: string, caseId: string) {
        const url = this.appService.getAPIBaseURL() + 'CasePresence/case/' + caseId + '/GetUsersOnCase/';

        return this.httpClient.get(url, { headers: this.appService.getHeaders(clientId) }).pipe(
            // TECH DEBT: DEV NOTE: I've seen this logic a lot. I'd like to understand the intent to who wrote this.
            // All the map() method is doing is checking if the result is falsy (mostly likely to see if it's null or undefined instead of false or 0)
            // and if it's not null/undefined, return the object.  Except, there's no return value if it IS null/undefined which
            // makes me think that it will default to `return undefined`.  So, why are we going through the effort to check if we got a value
            // if we're still just mapping returning it to a value?  The only _real_ benefit is it coalesces null/undefined into simply undefined
            // which I don't think our code makes any attempt to differentiate.
            map((resData) => {
                const res: any = resData;
                if (res) {
                    return res;
                }
            }),
            catchError((error: any) => {
                this.appService.showResponseErrorMsg(error);
                return throwError(() => new Error(error.message));
            })
        );
    }

    //#region CaseUserActivity

    activateUserOnCase(clientId: string, caseDisplayNumberOrId: string, location: string) {
        const url = `${this.publicApiBaseUrl}client/${clientId}/CaseUserActivity`;
        const request: IActivateMeRequest = { caseDisplayNumberOrId, location };
        const result = this.putWithAuth<ICaseUserActivity[]>(url, clientId, request);
        return result;
    }

    deactivateUserOnCase(clientId: string, caseDisplayNumberOrId: string) {
        const url = `${this.publicApiBaseUrl}client/${clientId}/CaseUserActivity/deactivate`;
        const request: IDeactivateMeRequest = { caseDisplayNumberOrId };
        const result = this.putWithAuth<void>(url, clientId, request);
        return result;
    }

    //#endregion 
}
