import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppService } from '../../../../../app.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AccountEvent, AccountMessage } from '../../../../../models';
import { AccountEventsService } from '../../../../../services';
import { QuillEditorComponent } from 'ngx-quill';

@Component({
    selector: 'account-event-action',
    templateUrl: './event-action-display-message.component.html'
})
export class EventDisplayMessageActionComponent implements OnInit {

    public messageForm: UntypedFormGroup;
    template: any;
    messageId: any;
    formSubmitted: boolean = false;
    event: AccountEvent;
    mapKeys: any;

  @ViewChild('content', {
      static: true
  }) content: QuillEditorComponent;

  constructor(
    private appService: AppService,
    private accountEventsService: AccountEventsService,
    private _fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<EventDisplayMessageActionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
      this.messageForm = this._fb.group({
          mapKeyId: ['', Validators.required],
          content: ['', Validators.required]
      });

      this.event = this.data.event;
      this.mapKeys = this.data.mapKeys;

      if (this.event.accountMessage) {
          this.template = JSON.parse(this.event.accountMessage.template);
          this.messageForm.patchValue({
              mapKeyId: this.template['mapKeyId'],
              content: this.template['content']
          });
      } else if (this.event.configuration) {
          const configuration = JSON.parse(this.event.configuration);
          this.messageId = configuration['messageId'];

          this.accountEventsService.getAccountEventMessage(this.event.accountId, this.messageId).subscribe(res => {
              this.template = JSON.parse(res.template);

              this.messageForm.patchValue({
                  mapKeyId: this.template['mapKeyId'],
                  content: this.template['content']
              });
          });
      }
  }

  saveAccountMessage() {
      const message = this.messageForm.value;
      this.formSubmitted = true;

      if (this.messageForm.valid) {
          if (this.event.accountMessage) {
              this.event.accountMessage.template = JSON.stringify(message);
          } else {
              const accountMessage: AccountMessage = {
                  id: this.messageId,
                  template: JSON.stringify(message)
              };
              this.event.accountMessage = accountMessage;
          }

          this.dialogRef.close();
      } else {
          this.messageForm.markAsTouched();
          this.messageForm.controls.content.markAsTouched();
      }
  }

}
