import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { MapKeyService } from '../../../services';
import { MapKeyType } from '../../../enums/global/mapkey-type.enum';

@Component({
    selector: 'dynamic-action-form-control',
    templateUrl: './dynamic-action-form-control.component.html',
    styleUrls: ['./dynamic-action-form-control.component.scss']
})
export class DynamicActionFormControlComponent implements OnInit {
    config;
    group: UntypedFormGroup;
    mapKeys: any[];
    virtualMapKeys: any[];
    mapKeyTypeEnum: any = MapKeyType;

    constructor(
        private mapkeyService: MapKeyService
    ) {
    }

    ngOnInit() {
        this.mapKeys = this.mapkeyService.nonListNonVirtualMapKeys;
        this.virtualMapKeys = _.sortBy(this.mapkeyService.virtualMapKeys, ['entityHierarchy']);
    }

}
