import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CaseStatus } from '@Enums';
import {
    CaseDetails,
    CaseDocument,
    CaseMetric,
    CustomEventLogGroup,
    DocumentHeader,
    GenerateDocumentStatus,
    PdfResult
} from '@Models';
import { BehaviorSubject, lastValueFrom, Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppService } from '../app.service';
import { IConfigService } from '../config/iconfigservice';
import { BaseService } from './base.service';
import { RoutesEnum, RoutingService } from './routing.service';
import CaseIntegrationQueueEntry from '@Models/case/case-integration-queue';


export type CaseSummaryRoute = {
    clientCode: string;
    accountCode: string;
    accountVersionNumber: string | number;
    caseId: string;
}

@Injectable()
export class CaseSummaryService extends BaseService {
    baseUrl: string;
    publicApiBaseUrl: string;

    _documentPollingInterval: any;
    updatedData = new BehaviorSubject<any>([]);
    updatedInterview = this.updatedData.asObservable();

    constructor(
        public appService: AppService,
        public httpClient: HttpClient,
        public configService: IConfigService,
        public routingService: RoutingService
    ) {
        super(appService, configService, httpClient);

        this.baseUrl = this.appService.getAPIBaseURL();
        this.publicApiBaseUrl = this.configService.getConfiguration().publicApiUrl;
    }

    createSummaryDocument(caseDetails: CaseDetails): Observable<GenerateDocumentStatus> {
        return this.appService.updateData('CaseDocuments/CreateSummaryDocument', caseDetails);
    }

    // I don't think this belongs in here as a "Case Summary Service", but I feel like we are
    // going to start getting TOO granular here?
    //  - Do we go CaseService / ConfigService / etc. until we outgrow it?
    //  - Document service (with the above?  just rename this?  another question so I can get this to wip?)
    //  - Let's chat
    getCaseDocumentFromQueue(documentQueueId: string): Observable<PdfResult> {
        if (documentQueueId == null || (typeof documentQueueId !== 'string')) {
            return throwError(() => new Error('Invalid document queue id.'));
        }

        let headers = new HttpHeaders();
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Authorization', 'Bearer ' + this.appService.getAuthToken());
        headers = headers.append('X-Tenant-Guid', this.appService.getClientId(null));

        const url = `${this.appService.getAPIBaseURL()}CaseDocuments/${documentQueueId}/DownloadFileFromQueue`;
        const httpGet = this.httpClient.get(url, {
            headers: headers,
            responseType: 'blob',
            observe: 'response'
        }).pipe(
            map(response => {
                const file = new Blob([response.body], { type: 'application/pdf' });
                const objectUrl = URL.createObjectURL(file);

                return {
                    file,
                    objectUrl
                };
            })
        );

        return httpGet;
    }

    getDocumentsPendingSignature(caseId: string): Promise<CaseDocument[]> {
        return lastValueFrom(this.appService.getData(`CaseDocuments/${caseId}/PendingSignature`)
            .pipe(
                map(x => x.data)
            ));
    }

    getDocumentsRequiringSignature(caseId: string): Promise<DocumentHeader[]> {
        return lastValueFrom(this.appService.getData(`CaseDocuments/${caseId}/RequiringSignature`)
            .pipe(
                map(x => x.data)
            ));
    }

    getCaseMetrics(caseId: string): Promise<CaseMetric[]> {
        return lastValueFrom(this.appService.getData(`metrics/${caseId}`));
    }

    //getCaseEvents(caseId: string): Promise<CustomEventLogGroup[]> {
    getCaseEvents(caseId: string) {
        const clientId = this.appService.getClientId('');
        return lastValueFrom(super.getData<CustomEventLogGroup[]>(`${this.publicApiBaseUrl}client/${clientId}/case/${caseId}/events`));
    }

    setCaseTest(caseId: string, accountCode: string) {
        const clientId = this.appService.getClientId('');
        return super.putData(`${this.publicApiBaseUrl}client/${clientId}/account/${accountCode}/case/${caseId}/test`);
    }

    getCaseTest(caseId: string, accountCode: string) {
        const clientId = this.appService.getClientId('');
        return super.getData<any>(`${this.publicApiBaseUrl}client/${clientId}/account/${accountCode}/case/${caseId}/test`);
    }

    getCaseMessages(caseId: string, accountCode: string) {
        const clientId = this.appService.getClientId('');
        return super.getData<any>(`${this.publicApiBaseUrl}client/${clientId}/account/${accountCode}/case/${caseId}/messages`);
    }

    getCaseDocumentUri(clientId: string, accountId: string, caseId: string, caseDocumentId: string, viewed = false) {
        const url = `${this.publicApiBaseUrl}client/${clientId}/account/${accountId}/case/${caseId}/document/${caseDocumentId}/uri${viewed ? "?viewed=true" : ""}`;
        return super.getData<any>(url);
    }

    downloadAttachedDocument(clientId: string, accountId: string, caseId: string, caseDocumentId: string, viewed = false) {
        const url = `${this.publicApiBaseUrl}client/${clientId}/account/${accountId}/case/${caseId}/attachment/${caseDocumentId}/uri${viewed ? "?viewed=true" : ""}`;
        return super.getData<string>(url);
    }

    getTiffToDisplayAsPDF(clientId: string, accountId: string, caseId: string, caseDocumentId: string) {
        const url = `${this.publicApiBaseUrl}client/${clientId}/account/${accountId}/case/${caseId}/document/${caseDocumentId}/displaytifftopdf`;
        return super.getData<any>(url);
    }

    routeToCase(record: CaseSummaryRoute, newTab = false) {
        const params = {
            clientCode: record.clientCode,
            accountCode: record.accountCode,
            versionNumber: record.accountVersionNumber,
            caseId: record.caseId
        };

        if (newTab) {
            this.routingService.navigateToRouteInNewTab(RoutesEnum.caseSummary, params);
        } else {
            this.routingService.navigateToRoute(RoutesEnum.caseSummary, params);
        }
    }

    restartInterview(record) {
        this.routingService.navigateToRoute(RoutesEnum.interviewRestart, {
            clientCode: record.clientCode,
            accountCode: record.accountCode,
            versionNumber: record.accountVersionNumber,
            caseId: record.caseId
        });
    }

    restartInterviewInNewTab(record) {
        this.routingService.navigateToRouteInNewTab(RoutesEnum.interviewRestart, {
            clientCode: record.clientCode,
            accountCode: record.accountCode,
            versionNumber: record.accountVersionNumber,
            caseId: record.caseId
        });
    }

    getMillimanQueueId(caseId: string) {
        const clientId = this.appService.getClientId('');
        return super.getData<any>(`${this.publicApiBaseUrl}client/${clientId}/case/${caseId}/document/milliman`);
    }

    requeueReport(caseId: string, caseIntegrationQueueId: string): Observable<any> {
        const clientId = this.appService.getClientId('');
        return super.putData(`${this.publicApiBaseUrl}client/${clientId}/case/${caseId}/document/queue/${caseIntegrationQueueId}`);
    }

    clearSectionJsonData(caseId: string, accountCode: string, sectionId: string): Observable<any> {
        const clientId = this.appService.getClientId('');
        return super.patchData(`${this.publicApiBaseUrl}client/${clientId}/account/${accountCode}/case/${caseId}/section/${sectionId}`);

    }

    validateCaseNavigation(clientCode, caseId: string): Observable<boolean> {
        return super.getData(`${this.publicApiBaseUrl}client/${clientCode}/case/${caseId}/validate`);
    }

    isCaseProcessing(statusId: string, creationDate: string) {
        const OneHourAgo = (date) => {
            const hour = 1000 * 60 * 60;
            const hourago = Date.now() - hour;

            return date < hourago;
        };

        return statusId === CaseStatus.Processing && !OneHourAgo(new Date(creationDate).getTime());
    }

    getCaseIntegrationQueueEntries(caseId: string, accountId: string) {
        const clientId = this.appService.getClientId('');
        return super.getData<CaseIntegrationQueueEntry[]>(`${this.publicApiBaseUrl}client/${clientId}/account/${accountId}/case/${caseId}/caseintegrationqueueentries`);
    }
}
