import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    SimpleChanges,
    ViewChildren,
} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AccountMapKeyChange, AccountMapKeyValidChange, DeleteColumnMapkeyDialog } from '@CNBW';
import { AccountExternal, ManagementQueue, ManagementQueueColumnMapKey } from '@Models';
import { MapkeysDataService } from '@Services';
import { Utils } from '@Utils';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs';
import { SubSink } from 'subsink';


export enum ColumnDefinitions {
    Unknown = '',
    Mapkey = 'mapkey',
    Selection = 'selection',
    Actions = 'actions',
    Note = 'note',
}

export type ColumnItem = {
    id: string;
    name: string;
    order: number;
};

export type ColumnItemForm = {
    id: FormControl<string>;
    managementQueueId: FormControl<string>;
    mapKeyName: FormControl<string>;
    displayName: FormControl<string>;
    order: FormControl<number>;
    columnDefinition: FormControl<string>;
    // Front-end use only
    columnDefinitionType: FormControl<ColumnDefinitions>;
    isSpecialtyType: FormControl<boolean>; // Non-mapkey column
};

export type ColumnsForm = {
    items: FormArray<FormGroup<ColumnItemForm>>
}

/**
 * The ColumnType is the model we use in the UI while the ColumnDefinition is 
 * the value stored in the DB and used in the `mat-table` of `view-queues.component`
 */
export type ColumnType = {
    name: string;
    value: string;
    isSpecialty: boolean;
    disabled: boolean;
}

export const ColumnTypesDefault: ColumnType[] = [
    { name: 'Mapkey', value: 'mapkey', isSpecialty: false, disabled: false, },
    { name: 'Selection', value: 'selection', isSpecialty: true, disabled: false, },
    { name: 'Case Presence', value: 'actions', isSpecialty: true, disabled: false, },
    { name: 'Note', value: 'note', isSpecialty: true, disabled: false, },
];

export const ColumnDefinitionsEnumLookup = new Map<string, ColumnDefinitions>([
    ['mapkey', ColumnDefinitions.Mapkey],
    ['selection', ColumnDefinitions.Selection],
    ['actions', ColumnDefinitions.Actions],
    ['note', ColumnDefinitions.Note],
]);

export type ManagementQueueColumnMapkeysChange = {
    managementQueueColumnMapKeys: ManagementQueueColumnMapKey[];
    hasErrors: boolean;
};

@Component({
    selector: 'edit-cnbw-columns',
    host: {},
    templateUrl: 'edit-cnbw-columns.component.html',
    styleUrls: ['edit-cnbw-columns.component.scss']
})
export default class EditCnbwColumnsComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
    @Input() managementQueue: ManagementQueue;
    @Input() columnMapKeys: ManagementQueueColumnMapKey[];
    @Input() accounts: AccountExternal[];
    @Output() onChange = new EventEmitter<ManagementQueueColumnMapkeysChange>();

    // DEV NOTE: Using ViewChildren vs ViewChild. See: https://stackoverflow.com/a/39759051/253564
    @ViewChildren("tabsWrap") tabsWrapRef: QueryList<ElementRef>;
    @ViewChildren("tabs") tabsRef: QueryList<ElementRef>;

    columnTypes: ColumnType[] = []; // Drop-down values in template
    activeColumnItem: FormGroup<ColumnItemForm>;
    columnsForm: FormGroup<ColumnsForm>;
    get columnItems() {
        // Shorthand / Alias helper to just get the FormArray items
        return this.columnsForm.controls.items;
    }

    // Keep track if all accounts are valid
    accountMapKeyErrorMap = new Map<string, boolean>();

    ColumnDefinitionsEnum = ColumnDefinitions; // binding for template use of enum

    // Scrolling
    tabsObserver: IntersectionObserver;
    disableScrollRight = true;
    disableScrollLeft = true;
    tabsTranslateX = 0;
    tabsTransform = '';

    subs = new SubSink();

    constructor(
        private fb: FormBuilder,
        private mapKeysDataService: MapkeysDataService,
        private cdr: ChangeDetectorRef,
        private dialog: MatDialog
    ) { }

    //#region Helpers

    initalizeForm() {
        this.columnsForm = this.fb.group({ items: this.fb.array<FormGroup<ColumnItemForm>>([]) });
    }

    setActiveColumnItem(index: number) {
        if (this.columnItems.length == 0) {
            this.activeColumnItem = null;
            return;
        }

        if (index < 0 || index >= this.columnItems.length) {
            console.error(`Could not set active column because the column index (${index}) was not valid. (max index: ${this.columnItems.length - 1})`);
            return;
        }

        this.activeColumnItem = this.columnItems.at(index);
    }

    addColumnItem(item: FormGroup<ColumnItemForm>) {
        // Listen for Column Name Changes
        this.subs.add(item.controls.displayName.valueChanges.pipe(
            debounceTime(500), // 0.5 second
            distinctUntilChanged(),
            tap(newDisplayName => {
                const newDisplayName_lower = newDisplayName.toLowerCase();
                const nameExists = this.columnItems.controls.some(x =>
                    x.controls.displayName.value.toLowerCase() == newDisplayName_lower
                    && x.controls.order.value != item.controls.order.value
                );

                if (nameExists)
                    item.controls.displayName.setErrors({ 'name-exists': true });

                this.emitColumnMapkeysChanged();
            })
        ).subscribe());

        this.columnItems.push(item);
    }
    removeColumnItem = (item: FormGroup<ColumnItemForm>) => this.columnItems.removeAt(item.controls.order.value);
    findIndexColumnItem = (columnMapKeyId: string) => this.columnItems.controls.findIndex(columnItem => columnItem.controls.id.value == columnMapKeyId);

    /**
     * This builds the dataset that's used in the "Column Type" dropdown input. It uses the default values and checks to see if 
     * any of the "special" types are being used already so we can disable the drop-down input.
     */
    buildColumnTypes() {
        const columnTypes = Utils.clone<ColumnType[]>(ColumnTypesDefault);

        this.columnItems.controls.forEach(columnItem => {
            if (columnItem.controls.isSpecialtyType.value) {
                const columnType = columnTypes.find(x => x.value == columnItem.controls.columnDefinitionType.value);

                if (columnType) columnType.disabled = true;
            }
        });

        this.columnTypes = columnTypes;
    }

    buildColumnItems() {
        this.columnMapKeys.sort((a, b) => a.order - b.order);
        this.columnMapKeys.forEach((columnMapkey, index) => {
            const columnDefinitionEnum = ColumnDefinitionsEnumLookup.get(columnMapkey.columnDefinition) ?? ColumnDefinitions.Mapkey;

            // DEV NOTE: If the mapkey is set, use that value. Due to using `mat-table`, the `columnDefinition` is the driving
            // value for that component and it must be unique and match the "data source" property name to map up. The refactor
            // idea was to treat "columnDefinition" as a "type" and set Mapkey columns with the literal value "mapkey". However,
            // This didn't work due to `mat-table`. In other words, it's by design that the `columnDefinition` should match the
            // Mapkey value (at least until we can refactor these concepts);
            const columnDefinitionValue = columnMapkey.mapKeyName ?? columnMapkey.columnDefinition;

            // Listen for Display Name changes so we can emit updates
            const displayName = this.fb.control(columnMapkey.displayName, [Validators.maxLength(20)]);

            const columnItemForm = this.fb.group<ColumnItemForm>({
                id: this.fb.control(columnMapkey.id, Validators.required),
                managementQueueId: this.fb.control(columnMapkey.managementQueueId, Validators.required),
                mapKeyName: this.fb.control(columnMapkey.mapKeyName),
                displayName,

                // DEV NOTEP: We're going to use the index from the sorted columnMapKeys because it's possible for human error (direct DB inserts)
                // or bugs (eg. not resetting the order after an add/delete) to cause the order value and the index value to no-longer line-up. 
                // (eg. the first time in the list has an order = 1 but index = 0). This can cause odd bugs, so instead, we want to use the index after
                // we sort and this should "self-heal" the order values if there are gaps or off-by-one issues, if anything changes and the queue is saved.
                // order: this.fb.control(columnMapkey.order),
                order: this.fb.control(index),

                columnDefinition: this.fb.control(columnDefinitionValue, Validators.required),
                columnDefinitionType: this.fb.control(columnDefinitionEnum, Validators.required),
                isSpecialtyType: this.fb.control(columnMapkey.mapKeyName == null),
            });

            this.addColumnItem(columnItemForm);
        });

        this.buildColumnTypes();
    }

    rebuildColumnItems() {
        // Keep the currently active column, so we can re-select it
        const activeColumnId = this.activeColumnItem?.controls.id.value;

        this.columnItems.clear();
        this.activeColumnItem = null;
        this.buildColumnItems();

        // Reset the active column
        const index = this.findIndexColumnItem(activeColumnId);

        this.setActiveColumnItem(index < 1 ? 0 : index);
    }

    updateColumnItemOrderToTheirIndex = () => this.columnItems.controls.forEach((item, index) => item.controls.order.setValue(index));


    emitColumnMapkeysChanged() {
        const columnMapKeys = this.columnItems.value.map(x => ({ ...x }) as ManagementQueueColumnMapKey);
        const hasErrors = this.columnItems.invalid;

        this.onChange.emit({ managementQueueColumnMapKeys: columnMapKeys, hasErrors });
    }

    //#endregion
    //#region Scrolling

    /**
     * Get the current translateX of the given HTML element, including CSS transforms.
     * See: https://developer.mozilla.org/en-US/docs/Web/API/DOMMatrix
     * (I'll be honest, this idea came from ChatGPT.)
     * @param element The HTML element to get the transform translateX value for.
     * @returns current tranlate X transform of the given HTML element.
     */
    getTranslateX(element: HTMLElement) {
        const transform = window.getComputedStyle(element).getPropertyValue('transform');
        const matrix = new DOMMatrix(transform);
        return matrix.m41;
    }

    getScrollOffsets() {
        const parentWidth = this.tabsWrapRef?.first?.nativeElement.offsetWidth || 0;
        const childWidth = this.tabsRef?.first?.nativeElement.offsetWidth || 0;
        const maxTranslateX = parentWidth - childWidth;

        return { maxTranslateX, parentWidth, childWidth };
    }

    /**
     * Scrol the tabs row to towards the first (positive increment) or last (negative increment) tab, if the tabs do not fit the parent container/viewport.
     * @param increment Positive or negative number of pixels to move the tabs row
     */
    scroll(increment: number) {
        const currentTranslateX = this.getTranslateX(this.tabsRef?.first?.nativeElement);
        const { maxTranslateX } = this.getScrollOffsets();

        const newTranslateX = increment < 0
            ? Math.max(currentTranslateX + increment, maxTranslateX) // don't scroll past the end of the child container
            : Math.min(currentTranslateX + increment, 0); // don't scroll past the start of the child container

        const startReached = newTranslateX === 0;
        const endReached = newTranslateX === maxTranslateX;

        this.disableScrollRight = endReached;
        this.disableScrollLeft = startReached;

        this.tabsTranslateX = newTranslateX;
        this.tabsTransform = `translateX(${newTranslateX}px)`;
    }

    scrollToEnd() {
        const { maxTranslateX } = this.getScrollOffsets();
        this.scroll(maxTranslateX);
    }

    scrollToStart() {
        const { maxTranslateX } = this.getScrollOffsets();
        this.scroll(-maxTranslateX);
    }

    /**
     * When the intersection threshold is crossed (If the threshold is 1: when the `intersectionRatio` becomes 1 or anything less than 1), we
     * want to check the scroll offsets to determine if we should enable/disable the scroll buttons.
     * @param _entries 
     */
    tabsIntersectionCallback(_entries: IntersectionObserverEntry[]) {
        const { maxTranslateX } = this.getScrollOffsets();

        this.disableScrollRight = maxTranslateX >= 0;
        this.disableScrollLeft = this.tabsTranslateX == 0;
    }

    /**
     * We'll use IntersectionObserver to determine if the tabs (.column-mapKey-tabs) is overflowing (being hidden by) it's parent container (.column-mapKey-tabs-wrap).
     * This will fire the first loaded and any time the threshold is reached by the browser resizing or elements being added/removed. The trigger threshold is when
     * the inner tabs element becomes fully visable (1) or stops being fully visable (anything less than 1);
     * See: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
     */
    observeTabsIntersection() {
        if (!this.tabsWrapRef?.first?.nativeElement || !this.tabsRef?.first?.nativeElement) {
            console.log('  HERE ');
            return;
        }

        // To be safe, we'll make sure we stop watching any existing observations before re-adding them.
        this.disconnectTabsIntersection();

        const options = {
            root: this.tabsWrapRef?.first?.nativeElement,
            threshold: 1 // Between 0-1. 1 = all inner pixels must be visable
        };

        this.tabsObserver = new IntersectionObserver(this.tabsIntersectionCallback.bind(this), options);
        this.tabsObserver.observe(this.tabsRef?.first.nativeElement);
    }

    disconnectTabsIntersection() {
        this.tabsObserver?.disconnect();
    }

    //#endregion
    //#region Lifecycle

    ngOnInit() {
        this.initalizeForm();
        this.buildColumnItems();
        this.setActiveColumnItem(0);

        // Set each account to having no errors. This will update when `handleMapKeyValidChange` start notifying 
        this.accounts.forEach(account => this.accountMapKeyErrorMap.set(account.id, false));
    }

    ngAfterViewInit() {
        // If our scroll elements are rendered on view init, setup scrolling.
        if (this.tabsWrapRef?.first?.nativeElement && this.tabsRef?.first?.nativeElement) {
            this.observeTabsIntersection();
        }

        //  Listen for scroll elements to be removed or added so we can re-init scrolling.
        this.tabsWrapRef.changes.subscribe((tabsWrapChange: QueryList<ElementRef>) => {
            // DEV NOTE: This will check to see if the viewChild ref is removed/added.  Because tabs can be hidden by *ngIf if 
            // the `columnItems` is empty, this would remove the component from the view.  This could happen when changing queue tabs
            // or deleting all the columns. When the component is removed/added the IntersectionObserver will also be removed.

            if (!tabsWrapChange?.first?.nativeElement) this.disconnectTabsIntersection(); // scroll element was removed
            else this.observeTabsIntersection(); // Scroll element has reappeared
        });

        this.tabsRef.changes.subscribe((tabsChange: QueryList<ElementRef>) => {
            // DEV NOTE: This will check to see if the viewChild ref is removed/added because tabs can be hidden by *ngIf if. 
            // This could happen when changing queue tabs which contain a different list of column tabs.

            if (!tabsChange?.first?.nativeElement) this.disconnectTabsIntersection(); // Scroll Element was added
            else this.observeTabsIntersection(); // Scroll Element was removed
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.columnMapKeys && !changes.columnMapKeys.firstChange) this.rebuildColumnItems();
    }

    ngOnDestroy() {
        // DEV NOTE: when we're done editing queues, make sure we clean our cache or it'll keep growing because the service is a singleton.
        this.mapKeysDataService.clearCache();
        this.disconnectTabsIntersection();
    }

    //#endregion
    //#region Handlers

    handleScrollLeft = () => this.scroll(240);
    handleScrollRight = () => this.scroll(-240);

    handleColumnTabDropped(dropEvent: CdkDragDrop<any>) {
        const { items } = this.columnsForm.controls;
        moveItemInArray(items.controls, dropEvent.previousIndex, dropEvent.currentIndex); // Sort the UI items
        items.controls.forEach((item, index) => item.patchValue({ order: index })); // Update 'order' property we need to save

        this.emitColumnMapkeysChanged();
    }

    handleSelectColumnTab(columnItem: FormGroup<ColumnItemForm>) {
        this.setActiveColumnItem(columnItem.controls.order.value);
    }

    handleColumnTypeChange(columnTypeValue: string) {
        const columnDefinitionEnum = ColumnDefinitionsEnumLookup.get(columnTypeValue);
        const columnType = ColumnTypesDefault.find(x => x.value == columnTypeValue);

        // The columnDefinition needs to be the Mapkey name if it's a Mapkey type.
        // Leave empty for "add a mapkey" error validation to trigger.
        const columnDefinition = columnType.isSpecialty ? columnType.value : null;

        this.activeColumnItem.controls.columnDefinition.setValue(columnDefinition);
        this.activeColumnItem.controls.columnDefinitionType.setValue(columnDefinitionEnum);
        if (columnType.isSpecialty) this.activeColumnItem.controls.mapKeyName.setValue(null);

        // We need to rebuild our column type list, so we enable/disable any that might have changed
        this.buildColumnTypes();

        if (this.activeColumnItem.controls.columnDefinition.hasError('required')) {
            this.activeColumnItem.controls.columnDefinitionType.setErrors({ 'required': true });
        }

        this.emitColumnMapkeysChanged();
    }

    handleMapKeyChange(mapKeyChange: AccountMapKeyChange) {
        this.activeColumnItem.controls.mapKeyName.setValue(mapKeyChange.mapKeyEntityHierarchy);
        this.activeColumnItem.controls.columnDefinition.setValue(mapKeyChange.mapKeyEntityHierarchy);

        // We need to make sure we clear the "Type" error if the columnDefinition error was removed. We set this in `handleColumnTypeChange`
        const columnDefinitionIsRequired = this.activeColumnItem.controls.columnDefinition.hasError('required');

        if (!columnDefinitionIsRequired)
            this.activeColumnItem.controls.columnDefinitionType.setErrors(null);

        this.emitColumnMapkeysChanged();
    }

    handleMapKeyValidChange(mapKeyValidChange: AccountMapKeyValidChange) {
        if (this.accountMapKeyErrorMap.has(mapKeyValidChange.account.id))
            this.accountMapKeyErrorMap.set(mapKeyValidChange.account.id, mapKeyValidChange.error);

        let allAccountMapKeysInvalid = true;
        this.accountMapKeyErrorMap.forEach((hasError, _key) => { allAccountMapKeysInvalid &&= hasError; });

        if (allAccountMapKeysInvalid) {
            // The current mapkey value doesn't exist on any account. Treat it like it's not set.
            this.activeColumnItem.controls.columnDefinitionType.setErrors({ 'required': true });
        } else {
            this.activeColumnItem.controls.columnDefinitionType.setErrors(null);
        }
        this.activeColumnItem.controls.columnDefinitionType.markAsTouched();


        this.emitColumnMapkeysChanged();
    }

    handleAddColumnItem() {
        const newColumnItemForm = this.fb.group<ColumnItemForm>({
            id: this.fb.control(Utils.emptyGuid, Validators.required),
            managementQueueId: this.fb.control(this.managementQueue.id, Validators.required),
            mapKeyName: this.fb.control(''),
            displayName: this.fb.control('New'),
            order: this.fb.control(this.columnItems.length),
            columnDefinition: this.fb.control('', Validators.required),
            // columnDefinition: this.fb.control(''),
            columnDefinitionType: this.fb.control(null, Validators.required),
            isSpecialtyType: this.fb.control(false),
        });

        this.addColumnItem(newColumnItemForm);
        this.setActiveColumnItem(newColumnItemForm.controls.order.value);
        // New items show an error on the UI tab because the type is not set but required, but because the field
        // hasn't been "touched" to trigger the error message. We'll manually touch it so it's clear to the user.
        this.activeColumnItem.controls.columnDefinitionType.markAllAsTouched();
        // Adding an item will require Angular to add the item to the DOM, which will change the tabs width.
        // We need to force Angular to rerender we so can scroll to the "new" end of the tabs. This is because
        // our scrolling is based on the DOM element width.
        // TECH DEBT: Could we use `ViewChildren.changes` like we do with `Tabs`/`TabsWrap` so we don't have to
        // for change detection? I'm not sure how bad for performance forcing change detection is. Perhaps,
        // instead, we could set a flag and when a change is detected in the list, scrollToEnd()?
        this.cdr.detectChanges();
        this.scrollToEnd(); // New columns are added to the end, make sure we scroll in case it's "off screen".
        this.emitColumnMapkeysChanged();
    }

    handleDeleteColumnItem(columnItem: FormGroup<ColumnItemForm>) {
        const dialogRef = this.dialog.open(DeleteColumnMapkeyDialog);
        dialogRef.afterClosed().subscribe(shouldDelete => {
            if (!shouldDelete) return;

            const deleteIndex = columnItem.controls.order.value;

            this.removeColumnItem(columnItem);
            // deleting an item can cause the order to be out of sync (any tabs to the left will shift by one), reset it.
            this.updateColumnItemOrderToTheirIndex();

            // We need to set the new active column because we just deleted the currently active column
            this.setActiveColumnItem(deleteIndex - 1);

            this.emitColumnMapkeysChanged();
        });

    }

    //#endregion
}
