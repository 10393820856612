<section class="view-document-dialog" [ngClass]="{ 'fullscreen': fullscreen, 'hideClose': hideClose }">
    <div class="title-bar" mat-dialog-title>
        <div class="title">
            <strong>{{ title }}</strong>
            <span *ngIf="caseRequirementName" class="requirement-name">for {{ caseRequirementName }}</span>
        </div>
        <mrs-btn-primary matTooltip="Document is already reviewed"
            [matTooltipDisabled]="!hasBeenReviewed && !disableMarkReviewed" *ngIf="showMarkReviewed"
            class="mark-reviewed-btn" [disabled]="hasBeenReviewed || disableMarkReviewed" buttonId="markReviewed"
            buttonLabel="Mark as Reviewed" (onClick)="handleMarkReviewed()"></mrs-btn-primary>
    </div>

    <mat-dialog-content class="content">
        <json-viewer *ngIf="isJsonFile" [src]="src"></json-viewer>
        <plain-text-viewer *ngIf="isTextFile" [src]="src"></plain-text-viewer>
        <img-viewer *ngIf="isImageFile" [src]="src"></img-viewer>
        <audio-player *ngIf="isAudioFile" [src]="src"></audio-player>
        <pdf-viewer *ngIf="isPdfFile" [src]="src" [zoomLevel]="zoomLevel"></pdf-viewer>
        <tiff-viewer *ngIf="isTiffFile" [src]="src" [props]="data"></tiff-viewer>
    </mat-dialog-content>

    <mat-dialog-actions *ngIf="!hideActions" class="actions">
        <mrs-btn-tertiary *ngIf="!hideClose" buttonLabel="Close" buttonId="cancelButton"
            mat-dialog-close></mrs-btn-tertiary>
    </mat-dialog-actions>
</section>