<div class="left-panel w-25 bg-white d-flex flex-column">
    <div class="left-panel-header flex flex-column">
        <div class="left-panel-title">Integrations</div>
        <!-- <button *ngIf="checkACL('W')" type="button" class="btn btn-link btn-sm custom float-right" (click)="addAccountIntegration()">
                <i aria-hidden="true" class="fa fa-plus-circle"></i> Add UW Label
            </button> -->
        <div>
            <mrs-search-field [placeholder]="'Search Integrations'" (searchTextChange)="filterValue = $event; searchIntegrations()" [searchText]="filterValue"></mrs-search-field>
        </div>
    </div>
    <div class="left-panel-body flex-fill overflow-y-auto custom-scrollbar">
        <div class="list-container">
            <div class="list-item" style="cursor: pointer" (click)="getAccountIntegrationDetail(integration)" matRipple *ngFor="let integration of filteredIntegrations; let i = index"
                [ngClass]="{'active': integration.id == this.selectedIntegration?.id}">
                <div class="list-item-title">
                    <i aria-hidden="true" [ngClass]="getIntegrationIconClass(integration)"></i>
                    {{integration.name}}
                </div>
                <div class="list-item-actions mr-3">
                    <!-- <div *ngIf="checkACL('W') && integration.accountIntegrations" (click)="deleteAccountIntegration(integration.accountIntegrations.id, integration)">
                      <mat-icon >delete</mat-icon>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="p-4 text-center text-muted" *ngIf="accountIntegrations?.length == 0">
            <img src="./assets/images/no-data-placeholder.png" alt="" class="pb-3"/>
            <h6>No Integrations Found</h6>
        </div>
    </div>
</div>

<div class="right-panel flex-fill d-flex flex-column">
    <!-- <div class="left-panel-header">
          <span class="left-panel-title">{{selectedIntegration.name}} Details</span>
      </div> -->
    <div class="d-flex flex-row flex-fill">
        <account-integration-view *ngIf="selectedIntegration.version === 2"
            [rules]="rules" [accountInfo]="accountInfo" [integration]="selectedIntegration"
            [secretKeys]="secretKeys">
        </account-integration-view>
        <div class="details-panel flex-fill " style="padding: 16px" *ngIf="selectedIntegration.version != 2">
            <ng-template [ngIf]="!selectedIntegration?.id">
                <div class="text-center" style="padding-top: 96px">
                    <h2>No Integration Selected</h2>
                    <div>To get started, select an integration from the list</div>
                </div>
            </ng-template>
            <div class="" *ngIf="showForm">
                <div class="">
                    <form class="" [formGroup]="integrationForm" (ngSubmit)="saveAccountIntegration()" novalidate autocomplete="off">
                        <div>
                            <mat-card-title><h2>{{selectedIntegration.name}} Details</h2></mat-card-title>
                            <input type="hidden" formControlName='id'>
                            <input type="hidden" formControlName='accountId'>
                            <input type="hidden" formControlName='clientId'>
                            <input type="hidden" formControlName='integrationId'>
                            <mat-form-field class="w-100" *ngIf="selectedIntegration.version == 2 && metadata">
                                <mat-select placeholder="Environment" formControlName="environment">
                                    <mat-option *ngFor="let env of metadata.environments" [value]="env.name">{{env.name}}</mat-option>
                                </mat-select>
                                <mat-error *ngIf="integrationForm.get('environment').hasError('required')">required</mat-error>
                            </mat-form-field>
                            <mat-form-field class="w-100">
                                <mat-label>Request Url</mat-label>
                                <input #autoFocus type="text" matInput formControlName='requestUrl'>
                                <mat-error *ngIf="integrationForm.get('requestUrl').hasError('required')">required</mat-error>
                            </mat-form-field>
                            <mat-form-field class="w-100">
                                <mat-label>Username</mat-label>
                                <input matInput formControlName='username'>
                                <mat-error *ngIf="integrationForm.get('username').hasError('required')">required</mat-error>
                            </mat-form-field>
                            <auto-suggest *ngIf="secretKeys" [list]="secretKeys" placeholder="Password" field="name" dataKey="id"
                                formControlName="password" [showRequiredError]="integrationForm.get('password').hasError('required')">
                            </auto-suggest>
                            <mat-form-field class="w-100" *ngIf="selectedIntegration.version == 2">
                                <mat-label>Timeout (seconds)</mat-label>
                                <input type="number" matInput formControlName="timeoutSeconds" min="0" max="600">
                            </mat-form-field>
                            <mat-form-field class="w-100" *ngIf="selectedIntegration.version == 2">
                                <mat-label>Expiration (hours)</mat-label>
                                <input type="number" matInput formControlName="expirationHours" min="0" max="168">
                                <mat-error *ngIf="integrationForm.get('expirationHours').hasError('required')">required</mat-error>
                            </mat-form-field>
                            <mat-form-field class="w-100">
                                <mat-label>Max No. of Attempts</mat-label>
                                <input type="number" matInput formControlName='maxAttempts'>
                                <mat-error *ngIf="integrationForm.get('maxAttempts').hasError('required')">required</mat-error>
                            </mat-form-field>
                            <mat-slide-toggle class="d-inline-block" formControlName="isActive"> &nbsp; Is Integration Active for
                                Account
                            </mat-slide-toggle>

                            <div class="col-12 pt-5 text-center">
                                <button type="submit" mat-raised-button color="primary" class="mr-1">
                                    <i aria-hidden="true" class="fa fa-cloud"></i> Save
                                </button>
                                <button mat-button color="basic" class="mr-1" (click)="cancel()">
                                    <i aria-hidden="true" class="fa fa-times"></i> Cancel
                                </button>
                                <button mat-button color="basic" type="button" class="mr-1"
                                    *ngIf="selectedAccountIntegration && selectedAccountIntegration.id !== null"
                                    (click)="addIntegrationMapping(addMappingDialog)">
                                    <i aria-hidden="true" class="fa fa-times"></i> Add Mapping
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div class="" *ngIf="!showForm && selectedIntegration?.id">
                <div>
                    <mat-card-title>
                        {{selectedIntegration.name}} Details
                        <button mat-button color="basic" class="float-right" (click)="editAccountIntegration()" *ngIf="checkACL('W')">
                            <i aria-hidden="true" class="fa fa-edit pr-1"></i>Edit
                        </button>
                    </mat-card-title>
                    <mat-card-content>
                        <div *ngIf="selectedIntegration.version == 2">
                            <div class="label-title">Environment</div>
                            <div class="label-content">{{selectedIntegration.environment}}</div>
                        </div>
                        <div class="label-title">Request URL</div>
                        <div class="label-content">{{selectedAccountIntegration?.requestUrl}}</div>

                        <div class="label-title">Username</div>
                        <div class="label-content">{{selectedAccountIntegration?.username}}</div>
                        <div class="label-title">Password</div>
                        <div class="label-content">{{getSecretKeyLabel(selectedAccountIntegration?.password)}}</div>
                        <div *ngIf="selectedIntegration.version == 2">
                            <div class="label-title">Timeout (seconds)</div>
                            <div class="label-content">{{selectedAccountIntegration?.timeoutSeconds}}</div>
                            <div class="label-title">Expiration (hours)</div>
                            <div class="label-content">{{selectedAccountIntegration?.expirationHours}}</div>
                        </div>
                        <div class="label-title">Max No. of Attempts</div>
                        <div class="label-content">{{selectedAccountIntegration?.maxAttempts}}</div>

                        <div class="label-title">Is Integration Active for Account</div>
                        <div class="label-content">
                            <div *ngIf="selectedAccountIntegration?.isActive">
                                <i aria-hidden="true" class="fa fa-check-circle text-success"></i> Active
                            </div>
                            <div *ngIf="!selectedAccountIntegration?.isActive">
                                <i aria-hidden="true" class="fa fa-times text-danger"></i> Inactive
                            </div>
                        </div>

                    </mat-card-content>
                </div>
            </div>
        </div>

        <div class="flex-fill d-flex flex-column" *ngIf="selectedAccountIntegration?.id  && selectedIntegration?.version == 1">

            <div class="py-2 px-4 bg-white border-bottom">
                <span class="float-left property-box-title">{{selectedIntegration.name}} Requests</span>
                <button type="button" class="btn btn-link btn-sm float-right custom" (click)="expandAllRequests = !expandAllRequests">
                    <i aria-hidden="true" class="fas" [ngClass]="{'fa-angle-double-down': !expandAllRequests, 'fa-angle-double-up': expandAllRequests}"></i>
                    {{(expandAllRequests ? 'Collapse All' : 'Expand All')}}
                </button>
                <!-- <button *ngIf="checkACL('W')" type="button" class="btn btn-link btn-sm float-right custom" (click)="addRequest()">
                            <i aria-hidden="true" class="fa fa-plus"></i> Add Request
                        </button> -->
            </div>

            <div class="flex-fill d-flex flex-column overflow-y-auto p-4 custom-scrollbar" *ngIf="selectedAccountIntegration?.integrationRequests?.length; else noRequestsPlaceholder">
                <request *ngFor="let request of selectedAccountIntegration?.integrationRequests; let i = index"
                    [expanded]="expandAllRequests" [request]="request" (onUpdateRequests)="getIntegrationRequests()"></request>
            </div>

            <ng-template #noRequestsPlaceholder>
                <div class="p-4 text-center text-muted">
                    <img src="./assets/images/no-data-placeholder.png" alt="" class="pb-3"/>
                    <h6>No Requests Found</h6>
                </div>
            </ng-template>

        </div>


    </div>
</div>
<ng-template #addMappingDialog let-data>
    <h2 mat-dialog-title>Integration Mapping</h2>
    <div mat-dialog-content>
        <integration-mapping [group]="data.integrationForm" [integration]="data.selectedIntegration"></integration-mapping>
    </div>
    <div mat-dialog-actions>
        <button type="button" class="btn btn-link btn-sm custom float-right"
            (click)="closeMapping()">
            <i aria-hidden="true" class="fa fa-times"></i> Close
        </button>
    </div>

</ng-template>
