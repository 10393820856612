import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { RoutingService, RoutesEnum, ClientDataService } from '../';
import { AppService } from '../../app.service';

@Injectable()
export class ClientGuardService implements CanActivate {
    constructor(
        private appService: AppService,
        private clientDataService: ClientDataService,
        private routingService: RoutingService) {}

    canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
        return new Promise(async (resolve) => {
            const clientCode = route.params.clientCode;
            const clients = await lastValueFrom(this.clientDataService.getClients(true));
            const targetClient = clients.find(c => c.code == clientCode);

            if (targetClient == null) { // Either the client doesn't exist, or the user doesn't have access.
                this.appService.showMsg('error', 'Cannot find the requested client.');
                this.routingService.navigateToDefault();
                resolve(false);
                return;
            }

            // FYI: even though accountId/caseId may be in the URL, we don't have access to them here, since
            // this is executing at the level of the "Client" root URL.

            resolve(true);
        });
    }
}
