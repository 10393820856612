import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MrsDropDownPrimaryComponent } from './index';

@NgModule({
    imports: [
        CommonModule,
        MatOptionModule,
        MatSelectModule,
        ReactiveFormsModule,
        MatFormFieldModule
    ],
    exports: [
        MatIconModule, MrsDropDownPrimaryComponent],
    declarations: [MrsDropDownPrimaryComponent]
})
export class MrsDropDownModule {
}
