import {Pipe, PipeTransform, Injectable} from '@angular/core';

@Injectable()
@Pipe({
    name: 'removeHTMLTags'
})

export class RemoveHtmlTagsPipe implements PipeTransform {
    transform(replaceText: string): any {
        if (replaceText == null || replaceText === '') {
            return '';
        }
        return String(replaceText).replace(/<[^>]+>/gm, '');
    }
}
