import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from '@App';
import { IConfigService } from '@Config';
import { CaseMapkey } from '@Models';
import { BaseService, ObservableService } from '@Services';
import { lastValueFrom } from 'rxjs';

@Injectable()
export class CaseMapKeyService extends BaseService {
    baseUrl: string;
    publicApiBaseUrl: string;

    constructor(
        public appService: AppService,
        public httpClient: HttpClient,
        public configService: IConfigService,
        public observableService: ObservableService
    ) {
        super(appService, configService, httpClient);

        this.baseUrl = this.appService.getAPIBaseURL();
        this.publicApiBaseUrl = this.configService.getConfiguration().publicApiUrl;
    }

    getVirtualMapKeys(accountId: string, caseId: string) {
        const clientId = this.appService.getClientId('');
        return lastValueFrom(super.getData<CaseMapkey[]>(`${this.publicApiBaseUrl}client/${clientId}/account/${accountId}/case/${caseId}/mapkeys/virtual`, undefined, true));
    }

    async updateAndMergeVirtualMapKeys(accountId: string, caseId: string) {
        const updatedMapkeys = await this.getVirtualMapKeys(accountId, caseId);

        if (updatedMapkeys != null && updatedMapkeys.length > 0) {
            this.observableService.triggerMergeMapKeys.next(updatedMapkeys);
        }
    }
}
