import { Component, Inject, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppService } from '../../../../app.service';


@Component({
    selector: 'test-virtual',
    templateUrl: 'test-virtual.component.html',
})
export class TestVirtualComponent implements OnInit {
  @Input() virtualExpression: any;
  @Output() onCloseTestVirtual = new EventEmitter<boolean>();

  testMapkeys = [];
  showResult: boolean = false;
  testResult: any;
  isModal: boolean = false;
  closeText: string = 'CLOSE';

  constructor(
    public dialogRef: MatDialogRef<TestVirtualComponent>,
    public appService: AppService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      if (data && data.isModal) {
          this.virtualExpression = data.virtualExpression;
          this.isModal = true;
      }
  }

  ngOnInit(): void {
      this.setupData();
  }

  closeModal() {
      if (this.isModal) {
          this.dialogRef.close();
      } else {
          this.onCloseTestVirtual.emit(true);
      }
  }

  setupData() {
      if (this.isModal){
          this.closeText = '< BACK';
      }


      this.testMapkeys = [];
      this.showResult = false;
      const expr = this.virtualExpression;
      const matches = expr.match(/@[a-zA-Z_0-9]+/ig);

      if (matches) {
          matches.forEach(function (m) {
              const alreadyExists = this.testMapkeys.some(e => e.mapKeyName === m);

              if (!alreadyExists) {
                  this.testMapkeys.push({ mapKeyName: m, value: '', isNull: false });
              }
          }.bind(this));
      }
  }

  runTest() {
      this.testMapkeys.forEach(mk => {
          if (mk.isNull) {
              mk.value = null;
          }
      });

      const newRule: any = {
          ruleExpression: this.virtualExpression,
          ruleVariables: this.testMapkeys
      };

      this.appService.postData('MapKeys/VirtualMapKeyTest', newRule).subscribe(
          data => {
              this.showResult = true;
              if (data.status === 'success') {
                  this.testResult = data.data;
              } else {
                  this.testResult = data.message;
              }
          }
      );
  }
}
