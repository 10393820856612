import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
    CaseRequirement,
    CaseRequirementResponse,
    Requirement,
    RequirementResponse,
    UWBaseProps,
} from '@Underwriting/models';
import { UWRequirementsDataService, UWWorkbenchService } from '@Underwriting/services';
import { CaseRequirementStatuses } from '@Underwriting/types';
import { SubSink } from 'subsink';

export type RequirementDropdown = RequirementResponse & {
    disabled: boolean;
    tooltip?: string;
};


@Component({
    selector: 'uw-requirements-add-form',
    templateUrl: './uw-requirements-add-form.component.html',
    styleUrls: ['./uw-requirements-add-form.component.scss']
})
export default class UWRequirementsAddForm implements OnChanges {
    @Input() baseProps: UWBaseProps;
    @Input() caseRequirements: CaseRequirementResponse[];

    characterLimit = 32;
    addCaseRequirementForm: FormGroup;

    tooltip_RuleInUse = 'Limit one';

    // States
    disableAddButton = true;
    showSubtitleField = false; // Show the subtitle text field only the user has picked a Requirement from the dropdown
    hasRequirements = false;

    requirementsResponse: RequirementResponse[] = [];
    requirements: RequirementDropdown[] = [];
    requirementsLoaded = false;

    subs = new SubSink();

    constructor(
        private underWriterWorkbenchService: UWWorkbenchService,
        private uwRequirementsDataService: UWRequirementsDataService,
    ) {
        this.addCaseRequirementForm = new FormBuilder().group({
            requirement: ['', Validators.required],
            subtitle: ['']
        });
    }

    //#region Helpers

    isRequirementAlreadyAdded(requirementDropdownId: string) {
        const requirementAlreadyAdded = this.caseRequirements.find(cr => cr.requirementId == requirementDropdownId);
        if (requirementAlreadyAdded) {
            return true;
        }
        return false;
    }

    updateRequirements() {
        this.requirements = this.requirementsResponse.map(r => {
            const requirementDropdown: RequirementDropdown = { ...r, disabled: false };
            requirementDropdown.disabled = this.isRequirementAlreadyAdded(requirementDropdown.id);
            return requirementDropdown;
        });
    }

    updateHasRequirements() {
        this.hasRequirements = this.requirements.length > 0;
    }

    updateDisableAddButton() {
        this.disableAddButton = !this.hasRequirements || !this.showSubtitleField || this.baseProps.readonly;
    }

    clearForm() {
        this.showSubtitleField = false;
        this.addCaseRequirementForm.reset();
        this.addCaseRequirementForm.get('requirement').setErrors(null);
        this.addCaseRequirementForm.get('subtitle').setValue('');
    }

    //#endregion
    //#region Subscriptions

    subscribeToRequirements() {
        const { client, account } = this.baseProps;

        this.subs.add(this.uwRequirementsDataService.loadRequirements(client.code, account.id).subscribe({
            next: requirementResponses => {
                this.requirementsResponse = requirementResponses;
                this.requirementsLoaded = true;
                this.updateRequirements();
                this.updateHasRequirements();
            }
        }));
    }

    //#endregion
    //#region Lifecycle

    ngOnInit() {
        this.subscribeToRequirements();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.caseRequirements) this.updateRequirements();
        this.updateDisableAddButton();
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    //#endregion
    //#region Handlers

    requirementSelected() {
        const requirementSelected = this.addCaseRequirementForm.get('requirement').value;
        this.showSubtitleField = Boolean(requirementSelected);
        this.updateDisableAddButton();
    }

    handleAddCaseRequirement() {
        if (this.addCaseRequirementForm.get('requirement').hasError('required'))
            return;

        const selectedRequirement: Requirement = this.addCaseRequirementForm.get('requirement').value;
        const addedSubtitle: string = this.addCaseRequirementForm.get('subtitle').value;

        const match = this.caseRequirements.find(x => (x.integration) && x.integration.id == selectedRequirement.integrationId && x.integration.actionId == selectedRequirement.integrationActionId);
        if (match) {
            this.underWriterWorkbenchService
                .updateCaseRequirementMatch(match.id, selectedRequirement.id, selectedRequirement.name, addedSubtitle, this.baseProps.account.id)
                .subscribe(() => {
                    this.clearForm();
                });
        }
        else {
            const addCaseRequirement: CaseRequirement = {
                id: undefined,
                requirementId: selectedRequirement.id,
                name: selectedRequirement.name,
                subtitle: addedSubtitle,
                status: CaseRequirementStatuses.NotOrdered.toString()
            };

            this.underWriterWorkbenchService
                .addCaseRequirement(this.baseProps.account.id, addCaseRequirement, this.baseProps.case.id)
                .subscribe(() => {
                    this.clearForm();
                });
        }

    }

    //#endregion
}