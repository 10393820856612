import { Injectable, NgZone } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot } from "@angular/router";
import { FeatureManagerService, FeatureToggleResponse } from '../global/feature-manager/feature-manager.service';
import { RoutesEnum, RoutingService } from "../routing.service";
import { FeatureToggle } from 'app/enums';

@Injectable()
export class FeatureFlagGuard implements CanActivate {
    constructor(
    private featureManagerService: FeatureManagerService,
    private routingService: RoutingService,
    private zone: NgZone
    ) {}
    canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
        return new Promise(async (resolve) => {

            const requiredFeatureFlag: string = route.data[
                "requiredFeatureFlag"
            ] as string;

            const isEnabled: boolean = route.data[
                "isEnabled"
            ] as boolean;

            const featureFlagRedirect: RoutesEnum = (route.data["featureFlagRedirect"] as RoutesEnum) || RoutesEnum.dashboard;

            const featureFlag = this.featureManagerService.getByName(requiredFeatureFlag);

            if (featureFlag.enabled === isEnabled) {
                resolve(true);
            }else{
                this.zone.run(() => {
                    this.routingService.navigateToRoute(featureFlagRedirect);
                });
            }
        });
    }
}
