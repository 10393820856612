import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { AppService } from '../../../../app.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { Account } from '../../../../../app/models';
import { ActivatedRoute } from '@angular/router';
import { FeatureManagerService } from 'app/services/global/feature-manager/feature-manager.service';
import { FeatureToggle } from 'app/enums';

@Component({
    selector: 'interview-settings',
    host: {'class': 'd-flex flex-row flex-fill'},
    templateUrl: './interview-settings.component.html',
    styleUrls: ['../account-settings-v2.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class InterviewSettingsComponent implements OnInit {
    @Input() accountInfo: Account;
    @Input() rules;

    account: Account;
    interviewForm: UntypedFormGroup;
    settings: any = [];
    selectedSetting: any = {};
    runtime2Enabled = false;
    useFilteredVmks = false;
    useVmkPerformance = false;
    usePrefillQuestions = false;
    useAddNoteForVariants = false;

    constructor(
        private appService: AppService,
        private featureManagerService: FeatureManagerService,
        private _fb: UntypedFormBuilder,
        private route: ActivatedRoute
    ) {
    }

    ngOnInit() {
        this.interviewForm = this._fb.group({
            disableInterviewRuleId: '',
            interviewReadOnlyOnDisable: false,
            shareMapKeysAcrossSections: false,
            runtime2Enabled: false,
            interviewFilteredVmks: false,
            vmkPerformanceUpdates: false,
            prefillQuestions: false,
            addNoteForVariants: false
        });
        this.route.data.subscribe(data => {
            this.account = data.account;
        });
        this.getSettings();

        const runtime20Feature$ = this.featureManagerService.isEnabled(FeatureToggle.CaseRuntime20, this.account.clientId);
        runtime20Feature$.subscribe(result => {
            this.runtime2Enabled = result;
        });

        const useFilteredVmks$ = this.featureManagerService.isEnabled(FeatureToggle.ConfigUseFilteredVmks, this.account.clientId);
        useFilteredVmks$.subscribe(result => {
            this.useFilteredVmks = result;
        });

        const useVmkPerformance$ = this.featureManagerService.isEnabled(FeatureToggle.CaseRuntimeProcessVmksPerformanceUpdates, this.account.clientId);
        useVmkPerformance$.subscribe(result => {
            this.useVmkPerformance = result;
        });

        const prefillQuestion$ = this.featureManagerService.isEnabled(FeatureToggle.ConfigInterviewPrefillQuestions, this.account.clientId);
        prefillQuestion$.subscribe(result => {
            this.usePrefillQuestions = result;
        });

        const addNoteForVariant$ = this.featureManagerService.isEnabled(FeatureToggle.ConfigInterviewAddNoteForVariants, this.account.clientId);
        addNoteForVariant$.subscribe(result => {
            this.useAddNoteForVariants = result;
        });
    }

    getSettings() {
        // GeneralSettings
        this.appService.getData(`AccountSettings/${this.accountInfo.id}`).subscribe(
            data => {
                if (data.status === 'success') {
                    const interviewSettings = _.find([{
                        code: 'InterviewSettings',
                        name: 'Interview'
                    }], ['code', 'InterviewSettings']);
                    interviewSettings['params'] = {};
                    interviewSettings['settings'] = [];
                    if (data.data && data.data.length) {
                        const params = {};
                        data.data.forEach(item => {
                            params[item.name] = item.value;
                            if (this.interviewForm.get(item.name) != null) {
                                if (item.name === 'shareMapKeysAcrossSections' || item.name === 'interviewReadOnlyOnDisable' || item.name === 'runtime2Enabled' || item.name === 'interviewFilteredVmks' || item.name === 'prefillQuestions' || item.name === 'addNoteForVariants' || item.name === 'vmkPerformanceUpdates') {
                                    params[item.name] = (item.value === 'true' ? true : false);
                                } else {
                                    params[item.name] = item.value;
                                }

                                this.interviewForm["controls"][item.name].setValue(params[item.name]);
                            }
                        });

                        interviewSettings['params'] = params;
                        interviewSettings['settings'] = data.data;
                        this.selectedSetting = interviewSettings;
                    }
                }
            }
        );
    }

    saveGeneralSettings() {
        if (this.interviewForm.valid) {
            const params = [];
            Object.keys(this.interviewForm.value).forEach(key => {
                const newValue = this.interviewForm.get(key).value;
                const item = {
                    accountId: this.accountInfo.id,
                    name: key,
                    value: newValue != null ? newValue.toString() : null
                };

                if (this.selectedSetting.settings !== undefined) {
                    const setting = this.selectedSetting.settings.find(s => s.name === key);
                    if (setting) {
                        item['id'] = setting.id;
                    }
                }
                params.push(item);
            }, this);

            this.appService.postData('AccountSettings', params)
                .subscribe(
                    data => {
                        if (data.status === 'success') {
                            this.selectedSetting['params'] = [];
                            this.selectedSetting['settings'] = [];

                            if (data.data && data.data.length) {
                                const updatedParams = {};
                                data.data.forEach(item => {
                                    updatedParams[item.name] = item.value;
                                });
                                this.selectedSetting['params'] = updatedParams;
                                this.selectedSetting['settings'] = data.data;
                            }
                            this.appService.showMsg('success', 'Saved successfully ...');
                        } else {
                            this.appService.showMsg('error', data.message);
                        }
                    }
                );
        }
    }
}
