export enum MrsColor {
  MidGray = '#6A6A6A',
  UIBlue = '#2C6EED',
  UIRed = '#900606',
  DisabledTableColumn = '#E7E7E7',
  BrandRedAccent = '#A62923',
  UIRedDisabled = '#FFDCDC',
  UIBlueDisabled = '#C4D4F5',
  LightGray = '#C4C4C4',
  DarkBlueGray = '#26333B'
}
