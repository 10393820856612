import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot } from "@angular/router";
import { AppService } from "app/app.service";
import { AccountDataService, FeatureToggleService, RoutesEnum, RoutingService } from "app/services";
import { FeatureManagerService } from "app/services/global/feature-manager/feature-manager.service";

@Injectable()
export class CanActivateCnbw implements CanActivate {
    constructor(
        private accountDataService: AccountDataService,
        private routingService: RoutingService,
        private featureManagerService: FeatureManagerService,
        private featureToggleService: FeatureToggleService,
        private appService: AppService
    ) { }
    canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
        this.appService.showSecondaryLoadingMask(true);

        return new Promise(async (resolve) => {
            this.accountDataService.getAccounts(true).subscribe(accounts => {
                const uniqueClientIds = [...new Set<string>(accounts.map(item => item.clientId))];
                const hasSingleClient = uniqueClientIds.length == 1;
                const cnbwEnabled = this.featureManagerService.getByName('Global.CaseManager.V2')?.enabled;
                const useCNBWPage = hasSingleClient && cnbwEnabled;

                // CNBW is disabled globally
                if (!useCNBWPage) {
                    this.appService.showSecondaryLoadingMask(false);
                    this.routingService.navigateToRoute(RoutesEnum.caseManangerView);
                }

                // Use CNBW if the Client has access
                this.featureToggleService.getFeatureFlag('Global.CaseManager.V2.Client', uniqueClientIds[0]).subscribe(clientHasAccessToCNBW => {
                    if (clientHasAccessToCNBW) {
                        this.appService.showSecondaryLoadingMask(false);
                        resolve(true);
                    }
                    else {
                        this.appService.showSecondaryLoadingMask(false);
                        this.routingService.navigateToRoute(RoutesEnum.caseManangerView);
                    }
                });
            });
        });
    }
}
