import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { BaseInterviewFormFieldComponent, BaseFormFieldServices } from '../base-interview-form-field/base-interview-form-field.component';

@Component({
    selector: 'multi-line-answer',
    templateUrl: './multi-line-answer.component.html',
    styleUrls: ['./multi-line-answer.component.scss']
})
export class MultiLineAnswerComponent extends BaseInterviewFormFieldComponent implements OnInit, OnDestroy {
    lengthRemaining: number = 0;
    maxLength: number = 0;

    constructor(public services: BaseFormFieldServices) {

        super(services);
    }

    ngOnInit() {
        if (this.config.MaxLength) {
            // Remaining character helpers
            this.lengthRemaining = this.config.MaxLength;
            this.maxLength = this.config.MaxLength;
        }

        if (this.isLiveMode()) {
            this.subscriptions.push(this.group.get(this.config.id).valueChanges.subscribe(
                data => {
                    if (data.length) {
                        this.lengthRemaining = this.maxLength - data.length;
                    }
                }
            ));
        }

        super.initialize({ inputType: 'text' });
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }
}
