import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class IpAddressService {
    captureClientIpAddress(): Promise<any> {
        return fetch("https://api.ipify.org?format=json");
    }
}
