import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Inject, Input, Output, EventEmitter } from '@angular/core';
import { TagService } from '../../services';
import { Tag } from '../../models';
import * as _ from 'lodash';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { UntypedFormControl, UntypedFormGroup, AbstractControl } from '@angular/forms';

@Component({
    selector: 'object-tags',
    templateUrl: 'object-tags.component.html',
})
export class ObjectTagsComponent implements OnInit {

    @Input() account: any;
    @Input() tags: string[];
    @Output() tagsChange = new EventEmitter<string[]>();

    @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
    @ViewChild('auto') matAutocomplete: MatAutocomplete;

    tagFormGroup: UntypedFormGroup;
    tagInputCtrl: AbstractControl;

    constructor(
        private tagService: TagService
    ) { }

    separatorKeysCodes: number[] = [ENTER, COMMA];

    tagSuggestions: string[] = [];
    filteredTagSuggestions: Observable<string[]>;

    async ngOnInit() {
        this.tagFormGroup = new UntypedFormGroup({
            tagInputCtrl: new UntypedFormControl([''])
        });

        this.tagInputCtrl = this.tagFormGroup.get('tagInputCtrl');

        this.tags = _.sortBy(this.tags || []);

        const accountTags = await this.tagService.getTagsByAccount(this.account);
        this.tagSuggestions = _.sortBy((accountTags || []).map(x => x.name));

        this.filteredTagSuggestions = this.tagInputCtrl.valueChanges.pipe(
            startWith(null),
            map((tag: string | null) => tag ? this._filterTags(tag) : this.tagSuggestions.filter(x => this.canAddTag(x)).slice()));
    }

    tagAdd(event: MatChipInputEvent): void {
        if (!this.matAutocomplete.isOpen) {
            const input = event.input;
            const value = event.value;

            if (this.canAddTag(value)) {
                this.tags.push(value.trim());
                this.tagsChange.emit(this.tags);
            }

            if (input) {
                input.value = '';
            }

            this.tagInputCtrl.setValue(null);
        }
    }

    tagRemove(tag: string): void {
        this.tags = this.tags.filter(t => t.toLowerCase() !== tag.toLowerCase());
        this.tagsChange.emit(this.tags);
        this.tagInputCtrl.setValue(null);
    }

    tagSelected(event: MatAutocompleteSelectedEvent): void {
        this.tags.push(event.option.viewValue);
        this.tagsChange.emit(this.tags);

        this.tagInput.nativeElement.value = '';
        this.tagInputCtrl.setValue(null);
    }

    canAddTag(tag: string): boolean {
        const tagLower = (tag || '').toLowerCase();
        return tagLower !== '' && this.tags.find(x => x && x.toLowerCase() === tagLower) == null;
    }

    private _filterTags(value: string): string[] {
        const filterValue = (value || '').toLowerCase();
        const matches = filterValue === '' ?
            this.tagSuggestions
            : this.tagSuggestions.filter(tag => tag.toLowerCase().indexOf(filterValue) === 0);

        return matches.filter(tag => this.canAddTag(tag));
    }
}
