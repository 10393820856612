<div class="flex-fill d-flex flex-column">

    <div class="py-2 px-4 bg-light border-bottom">
        <div class="flex flex-row">
            <span class="float-left property-box-title" style="flex: 1">Notes</span>
            <button *ngIf="checkACL('W')" type="button" class="btn btn-link btn-sm float-right custom" (click)="addNote()">
                <i aria-hidden="true" class="fa fa-plus"></i> Add Note
            </button>
            <button type="button" class="btn btn-link btn-sm float-right custom" (click)="getNotes()">
                <i aria-hidden="true" class="fa fa-sync-alt"></i> Refresh
            </button>
        </div>

        <div class="flex flex-row">
            <mat-form-field style="width: 50%">
                <mat-select [(ngModel)]="selectedNoteTypes" multiple (ngModelChange)="filterNotes()">
                    <mat-select-trigger>
                        <span *ngFor="let selectedNoteType of selectedNoteTypes; last as isLast" class="selected-note">
                            {{selectedNoteType.name }} <span *ngIf="!isLast">,</span>
                        </span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let noteType of activeNoteTypes" [value]="noteType">{{noteType.name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <div class="flex-fill d-flex flex-column overflow-y-auto" *ngIf="notes.length; else noNotesPlaceholder">
        <note
            *ngFor="let note of filteredNotes; let i = index"
            [note]="note"
            [noteTypes]="noteTypes"
            [noteTypeReasons]="noteTypeReasons"
            [noteRelations]="noteRelations"
            [noteFollowupInDays]="noteFollowupInDays"
            (onUpdateNotes)="getNotes()"></note>
    </div>

    <ng-template #noNotesPlaceholder>
        <div class="p-4 text-center text-muted">
            <img src="./assets/images/no-data-placeholder.png" alt="" class="pb-3" />
            <h6>No Notes Found</h6>
        </div>
    </ng-template>

</div>