import { Injectable } from '@angular/core';
import * as _ from 'lodash';

import { AuthLegacyService } from '../../auth/auth-legacy.service';
import { IConfigService } from '../../config/iconfigservice';
import { ConfirmationDialogService } from './confirmation-dialog.service';
import { MatDialogRef } from '@angular/material/dialog';
import { CaseNotificationsService } from '../case/case-notifications.service';
import { Subject } from 'rxjs';
import { FeatureToggleService } from '../global/feature-toggle.service';

@Injectable()
export class IdleService {
    constructor(private authService: AuthLegacyService,
        private configService: IConfigService,
        private confirmationDialogService: ConfirmationDialogService,
        private caseNotificationService: CaseNotificationsService,
        private featureToggleService: FeatureToggleService) {

        const config = this.configService.getConfiguration();
        this._idleWarning = +config.sessionTimeOut;
        this._idleTimeout = +config.logoutTimeOutOnSessionTimeOut;
    }

    private LOCALSTORAGE_LAST_ACTIVITY: string = 'uwp.last-activity';

    private _triggerSignoutAutoSave = new Subject<any>();
    private _isIdle: boolean = false;
    private _idleTimer: any;
    private _idleTimeout: number;
    private _idleWarning: number;
    private _warningDialog: MatDialogRef<any>;
    public loggingOut: boolean = false;
    public isInterviewActive: boolean = false;

    triggeredSignoutAutoSave = this._triggerSignoutAutoSave.asObservable();

    public startIdleTimer(): void {
        this.resetIdleTimer();
        this.setLastActivity();

        this._idleTimer = setInterval(() => {
            this.runIdleTimer();
        }, 10000);
    }

    public stopIdleTimer(): void {
        clearInterval(this._idleTimer);
        this.closeWarningDialog();
    }

    public resetIdleTimer(): void {
        if (this._warningDialog == null) {
            this._isIdle = false;
            this.loggingOut = false;
        }
    }

    private closeWarningDialog() {
        if (this._warningDialog != null) {
            this._warningDialog.close();
        }
    }

    private runIdleTimer(): void {
        if (!this._isIdle && this._warningDialog == null) {
            this.setLastActivity();
            this._isIdle = true; // We aren't really idle, but we _will_ be if no user activity flips this to false within the next interval
            return;
        }

        const idleSeconds = this.getIdleSeconds();

        // User is not considered idle.  They may have triggered activity in another window, so
        // if our warning dialog is up, close it.
        if (idleSeconds < this._idleWarning) {
            this.closeWarningDialog();
            return;
        }

        // User hit the idle threshold, pop the warning dialog.
        if (this._warningDialog == null) {
            console.warn(`Session has been idle for ${this._idleWarning} seconds.  This session will be logged out in ${this._idleTimeout} seconds.`);
            this.showTimeoutDialog();
        }

        // User has timed out, log them out.
        if (idleSeconds >= (this._idleWarning + this._idleTimeout)) {
            console.warn('Session has timed out, logging out.');
            this.stopIdleTimer();
            this.caseNotificationService.removeUserFromCase();
            this.loggingOut = true;
            if (this.isInterviewActive){
                this._triggerSignoutAutoSave.next(true);
            } else {
                this.authService.logout();
            }

            // Tell the user what happened
            this.confirmationDialogService.open({
                title: 'Session Timeout',
                message: `You have been signed out due to inactivity.`
            });
        }
    }

    private showTimeoutDialog(): void {
        this._warningDialog = this.confirmationDialogService.open({
            title: 'Session Timeout',
            message: `Due to inactivity, you will be logged out in ${this._idleTimeout} seconds.`,
            okLabel: 'Continue Session',
            onClose: () => {
                this._warningDialog = null;
                this.resetIdleTimer();
            }
        });
    }

    private setLastActivity(): void {
        window.localStorage.setItem(this.LOCALSTORAGE_LAST_ACTIVITY, new Date().toString());
    }

    private getIdleSeconds(): number {
        const lastActivityStr = window.localStorage.getItem(this.LOCALSTORAGE_LAST_ACTIVITY);
        const lastActivity = lastActivityStr ? new Date(lastActivityStr) : new Date();
        const idleSeconds = Math.floor((new Date().getTime() - lastActivity.getTime()) / 1000);

        return idleSeconds;
    }
}
