import { Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { CaseDocumentStatus, CaseDocumentTypes, CaseStatus, SignatureTypeEnum } from '@Enums';
import { Account, Client, SignatureProviderSummary } from '@Models';
import { CaseDetailsDTO, CaseDocumentsDTO } from '@Models/domain/dto';
import {
    CaseDocumentsDataService,
    CaseSummaryService,
    ConfirmationDialogService,
    NotificationService,
    NotificationSeverity,
    ReportDownloadService,
    SignerService,
} from '@Services';
import { FileUtils, Utils } from '@Utils';
import { MonitoringService } from 'app/monitor.service';
import { Observable } from 'rxjs';
import { SubSink } from 'subsink';

import { AppService } from '@App';
import { ViewDocumentDialog, ViewDocumentDialogData } from 'app/components/view-document-dialog';
import { ConfirmationDialogMessageMode } from '../../components/confirmation-dialog/confirmation-dialog.component';

@Component({
    selector: 'case-summary-document',
    host: { 'class': 'content' },
    templateUrl: './case-summary-documents.component.html',
    styleUrls: ['./case-summary-documents.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CaseSummaryDocumentComponent implements OnInit {
    @ViewChild('audioControl') audioControlRef: ElementRef;
    @Input() case: CaseDetailsDTO;
    @Input() selectedCaseId;
    @Input() events: Observable<any>;

    constructor(
        private route: ActivatedRoute,
        private appService: AppService,
        private reportDownloadService: ReportDownloadService,
        private confirmationService: ConfirmationDialogService,
        private caseSummaryService: CaseSummaryService,
        private signerService: SignerService,
        private notificationService: NotificationService,
        private monitorService: MonitoringService,
        private sanitizer: DomSanitizer,
        public dialog: MatDialog,
        private caseDocumentsDataService: CaseDocumentsDataService
    ) {
    }

    client: Client;
    account: Account;
    selectedDocs: any = []; viewer = 'google';
    selectedDocument: any;
    isRegenerating: boolean;
    caseDocuments: CaseDocumentsDTO[] = [];
    canViewDetails = false;
    canRegenerateDocs = false;
    signatureProviderSummary: SignatureProviderSummary;
    documentHeader: string;
    pdfResult: any;
    dataToDiplsay: any;
    textToDiplsay: any;
    audioData: any;
    imageBlobUrl: any;
    selectedType = 'pdf';
    showPdf = false;
    page = 1;
    fileType = 'any';
    tiffPageCount: any;

    isLoaded = false;
    documentConfig = {
        interval: null,
        pollRateMs: 3000,
        elapsedMs: 0,
        timeoutMs: 120000
    };

    isRequestingSignatures = false;
    showRequestSignatures = false;

    signatureTypeEnum: any = SignatureTypeEnum;
    transmitEnabled = false;
    hasMillimanReport = false;
    caseIntegrationQueueId: string;

    private subs = new SubSink();

    protected toolTipText = "Preview does not support TIFF file types.";

    //#region Helpers

    protected documentIsTIFF = (caseDocument: CaseDocumentsDTO) => FileUtils.isTiff(caseDocument);
    protected isCaseDone = () => this.case && this.case.statusId === CaseStatus.Done;

    private subscribeCaseDocuments() {
        this.subs.add(this.caseDocumentsDataService.loadDocuments(this.selectedCaseId).subscribe({
            next: response => {
                this.caseDocuments = response;
                this.selectedDocs = [];
                this.showRequestSignatures = this.caseDocuments.find(x => x.statusId === CaseDocumentStatus.PendingSignature || x.statusId === CaseDocumentStatus.RequestSignature) != null;
            }
        }));
    }

    //#endregion
    //#region Lifecycle

    ngOnInit() {
        this.route.data.subscribe(data => {
            this.account = data.account;
            this.client = data.client;
        });
        this.canViewDetails = this.appService.checkACL('CaseSummary', 'W');
        this.subscribeCaseDocuments();
        this.getMillimanQueueId();

        this.isRegenerating = this.appService.getIsRegenerating();
        this.signerService.getSignatureProviderSummary(this.account.id, this.selectedCaseId).subscribe(res => {
            this.signatureProviderSummary = res;
        });

        this.getCaseTransmitEnabled();

        this.events.subscribe((result) => {
            this.refreshDocuments();

            if (result) {
                this.notificationService.showNotification({
                    severity: NotificationSeverity.Success,
                    message: "Document generation complete."
                });
            } else {
                this.notificationService.showNotification({
                    severity: NotificationSeverity.Error,
                    message: "Document generation failed.  Please see system log notes for errors."
                });
            }
        });
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    //#endregion
    //#region Handlers

    // `case-summary.component` will call this when saving a status
    public refreshDocuments() {
        this.getMillimanQueueId();
        this.appService.getData(`CaseDocuments/${this.selectedCaseId}/CheckRegeneration`).subscribe(
            data => {
                if (!data.data.isRegenerate) {
                    this.caseDocumentsDataService.reloadDocuments(this.selectedCaseId);
                    this.isRegenerating = false;
                    this.appService.setIsRegenerating(this.isRegenerating);
                }
            }
        );
    }

    //#endregion

    getCaseTransmitEnabled() {
        this.appService.getData(`AccountSettings/${this.account.id}`).subscribe(
            response => {
                if (response.status === 'success') {
                    if (response.data && response.data.length) {
                        const transmissionEnabledSetting = response.data.find(x => x.name === 'transmissionEnabled');

                        if (transmissionEnabledSetting && transmissionEnabledSetting.value.toLowerCase() === 'true') {
                            this.transmitEnabled = true;
                        }
                    }
                }
            });
    }

    caseRetransmit() {
        this.confirmationService.open({
            message: `Are you sure that you want to retransmit this case?`,
            showCancel: true,
            onOk: () => {
                this.appService.postData(`CaseDocuments/${this.selectedCaseId}/CaseReTransmit`, false, false).subscribe(
                    data => {
                        this.appService.showMsg('success', data.message);
                        // this.getupdatedCaseDetails();
                    }
                );
            }
        });

    }

    async regenerateDocuments() {
        let message = 'Do you want to regenerate all documents?';
        let messageMode = ConfirmationDialogMessageMode.Text;

        const documentsToSign = await this.caseSummaryService.getDocumentsRequiringSignature(this.selectedCaseId);

        if (documentsToSign.length > 0 && this.signatureProviderSummary.signatureType === SignatureTypeEnum.Email) {
            const documentNames = documentsToSign.map(x => `<li>${x.name}</li>`).join('');
            message = `
                <p>The following documents will require agent signatures and/or acknowledgement prior to being marked done:</p>
                <ul>${documentNames}</ul>
                <p>All documents will be regenerated.</p>`;
            messageMode = ConfirmationDialogMessageMode.Html;
        }

        if (this.selectedDocs.length) {
            this.regenerate();
        } else {
            this.confirmationService.open({
                title: 'Regenerate Documents?',
                okLabel: 'Accept',
                message,
                messageMode,
                showCancel: true,
                onOk: () => {
                    this.regenerate();
                }
            });
        }
    }

    regenerate() {
        this.isRegenerating = true;
        this.appService.setIsRegenerating(this.isRegenerating);
        this.appService.postData(`CaseDocuments/${this.selectedCaseId}/ReGeneration`, this.selectedDocs, false).subscribe(
            data => {
                this.checkForRegenerating(data.data);
            }
        );
    }

    requestSignatures() {
        const documentsToSign = this.caseDocuments.filter(x => x.statusId === CaseDocumentStatus.RequestSignature
            || x.statusId === CaseDocumentStatus.PendingSignature);
        const documentNames = documentsToSign.map(x => `<li>${x.remoteName}</li>`).join('');
        const message = `<p>The following documents require agent signatures and/or acknowledgement:</p>
            <ul>${documentNames}</ul>
            <p>Signature requests will be sent for these documents.</p>`;

        this.confirmationService.open({
            title: 'Request Signatures?',
            okLabel: 'Accept',
            message: message,
            messageMode: ConfirmationDialogMessageMode.Html,
            showCancel: true,
            onOk: () => {
                this.isRequestingSignatures = true;
                this.appService.setIsRegenerating(this.isRegenerating);
                this.appService.postData(`CaseDocuments/${this.selectedCaseId}/SignPendingDocuments`, {}, false).subscribe(
                    () => {
                        this.isRequestingSignatures = false;
                    }
                );
            }
        });
    }

    checkForRegenerating(data) {
        // for handling multiple docs with archived and active docs
        if (data.isRegenerate) {
            if (data.messages.archivedDocumentMessage) {
                this.appService.showMsg('error', data.result.messages.archivedDocumentMessage);
            }
        } else if (!data.isRegenerate) {
            // for handling archived docs
            // this.loadCaseDocuments();
            this.caseDocumentsDataService.reloadDocuments(this.selectedCaseId);
            if (data.messages.archivedDocumentMessage) {
                this.appService.showMsg('error', data.result.messages.archivedDocumentMessage);
            }
            this.isRegenerating = false;
            this.appService.setIsRegenerating(this.isRegenerating);
        }
    }

    downloadDocument(document) {
        this.selectedDocument = document;
        const url = `CaseDocuments/${document.id}/Download`;
        const fileType = `application/${document.doctypeName}`;
        this.reportDownloadService.downloadReport(fileType, url);
    }

    hasViewPermission(feature) {
        return this.appService.hasViewPermission(feature);
    }

    getMillimanQueueId() {
        this.caseSummaryService.getMillimanQueueId(this.selectedCaseId).subscribe(result => {
            if (result && result !== null) {
                this.hasMillimanReport = true;
                this.caseIntegrationQueueId = result;
            }
        });
    }

    requeueMillimanReport() {
        this.caseSummaryService.requeueReport(this.selectedCaseId, this.caseIntegrationQueueId).subscribe(result => {
            if (result?.success) {
                this.refreshDocuments();
                this.getMillimanQueueId();
                this.notificationService.showNotification({
                    severity: NotificationSeverity.Success,
                    message: `The report has been retrieved successfully.`,
                    log: false
                });
            } else {
                this.notificationService.showNotification({
                    severity: NotificationSeverity.Error,
                    message: `The report is not available from Milliman, please reach out to intelliscriptsupport@milliman.com with the following Transaction ID and request that it be re-imaged: ${result?.trackingId}.`,
                    log: true
                });
            }
        });
    }

    viewDocument(caseDocument: CaseDocumentsDTO) {
        this.dialog.open<ViewDocumentDialog, ViewDocumentDialogData, ViewDocumentDialogData>(ViewDocumentDialog, {
            height: '100%',
            minWidth: Utils.isDesktop() ? '55vw' : '90%',
            maxWidth: null,
            maxHeight: '90vh',
            data: {
                client: this.client,
                account: this.account,
                caseId: this.selectedCaseId,
                caseDocument,
                caseDocumentType: CaseDocumentTypes.Config
            }
        });
    }
}

