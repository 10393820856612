import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppService } from '../app.service';
import { NoteType } from '../enums';

@Component({
    selector: 'notes-dialog',
    templateUrl: 'notes-dialog.component.html',
})
export class NotesDialogComponent implements OnInit {

    displayNotes: any = false;
    noteTypes: any = [];
    noteRelations: any = [];
    noteFollowupInDays: any = [];
    notes: any = [];

    constructor(
        private appService: AppService,
        public dialogRef: MatDialogRef<NotesDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit() {
        this.noteTypes = this.appService.getConfigData('noteTypes');

        this.noteRelations = [{
            key: 'Case', value: 'Case'
        }];
        this.getNotes();
        this.noteFollowupInDays = this.getNextNDates();
    }

    getNotes() {
        this.appService.getData(`Notes/${this.data.caseId}`, this.data.clientId).subscribe(
            results => {
                this.notes = results.data.filter(x => x.noteTypeId !== NoteType.SystemLog);
                this.displayNotes = true;
            });
    }

    getNextNDates() {
        const nextNDates = [];
        let noOfDays = 0;
        let index = 1;

        while (noOfDays < 10) {
            const nextNthDate = this.getNextNthDate(index);

            if (nextNthDate) {
                nextNDates.push({
                    key: nextNthDate,
                    value: `${nextNthDate} (${index} day${(index === 1 ? '' : 's')})`
                });
                noOfDays += 1;
            }

            index += 1;
        }

        return nextNDates;
    }

    getNextNthDate(n) {
        const currentDate = new Date();
        const nextDate = new Date(currentDate);

        nextDate.setDate(nextDate.getDate() + n);

        const dd = nextDate.getDate();
        const mm = nextDate.getMonth() + 1;
        const y = nextDate.getFullYear();

        if (nextDate.getDay() !== 0 && nextDate.getDay() !== 6) {
            return mm + '/' + dd + '/' + y;
        } else {
            return false;
        }
    }
}
