export enum DocusignStatusEnum {
    GeneratingDocuments = 0,
    PackagingDocuments = 1,
    PreparingForSignature = 2,
    GenerationComplete = 3,
    DocumentGenerationComplete = 4,
    GeneratingDocumentsComplete = 5,
    DocumentGenerationFailed = 6,
}

export enum DocusignRemoteSignerStatusEnum {
    DocumentOpened = 0,
    SavedAndClosed = 1,
    DeclinedToSign = 2,
    ApplyingSignature = 3,
    Signed = 4
    }