<div class="mrs-table-container">
    <div class="mrs-table-inner-container">
      <div class="flex flex-align-center">
        <mat-form-field id="filterCasesFormField" class="filter-cases" floatLabel="never" appearance="fill" *ngIf="searchableFields && searchableFields.length > 0">
          <input matInput placeholder="Search" name="filterValue" (keyup)="updateFilteredCases()"
            [(ngModel)]="filterValue">
          <mat-icon class="case-summary-search-icon" aria-hidden="false" aria-label="Case search icon" matSuffix>
            search
          </mat-icon>
        </mat-form-field>
        <div class="ml-2" style="width: 22rem" *ngIf="showClientFilter">
          <app-multi-select-dropdown-list class="multi-select-dropdown-list"
                                          [listTitle]="'Filter by Client'"
                                          [completeList]="clients"
                                          (selectionChanged)="filterData($event)">
          </app-multi-select-dropdown-list>
        </div>
  
        <form class="ml-2" [formGroup]="showInactiveUsersFormGroup" ngNativeValidate>
          <div *ngIf="(hasInactiveUsers$ | async) === true">
            <mat-slide-toggle formControlName="hasInactiveUsersCtrl">Show inactive users</mat-slide-toggle>
          </div>
        </form>
  
        <button mat-button color="primary" class="material-default flex-end" (click)="tableAction(addRecordAction, null)"
          style="margin-top: 15px; margin-left: auto" *ngIf="addRecordAction && addRecordAction.permission">
          <mat-icon>add</mat-icon><span style="margin-top: 2px">{{addRecordAction.name}}</span>
        </button>
      </div>
      <div style="max-height: 60vh; overflow-y: scroll;">
        <table mat-table aria-label="Elements" [dataSource]="mrsDataSource" matSort [matSortActive]="initialSort"
          matSortDirection="asc" style="width:100%; overflow:hidden;">
          <div *ngFor="let column of displayedColumns">
            <div *ngIf="!column.isAction ||  (column.isAction && showActionsColumn)">
              <ng-container [matColumnDef]="column.def">
                <th mat-header-cell *matHeaderCellDef mat-sort-header [ngStyle]="column.columnStyles">{{column.header}}
                </th>
                <div *ngIf="!column.isAction">
                  <td mat-cell *matCellDef="let row" style="cursor: default">
                    <div *ngFor="let rowDef of column.rowDefs">
                      <div [ngClass]="rowDef.className">{{row[rowDef.dataName]}}</div>
                    </div>
                  </td>
                </div>
                <div *ngIf="column.isAction && showActionsColumn" > 
                  <td mat-cell *matCellDef="let row" style="cursor: default">
                    <mat-icon svgIcon="dots-vertical" style="margin-left: 10px; cursor: pointer" [matMenuTriggerFor]="menu">
                      <mat-menu #menu="matMenu">
                        <div *ngFor="let action of column.actions">
                          <button mat-menu-item (click)="tableAction(action, row)"
                            *ngIf="action.permission && row[action.actionType]">
                            <mat-icon *ngIf="action.icon" [svgIcon]="action.icon"></mat-icon>
                            {{action.name}}</button>
                        </div>
                      </mat-menu>
                    </mat-icon>
                  </td>
                </div>
              </ng-container>
            </div>
          </div>
          <tr mat-header-row *matHeaderRowDef="displayedColumnsDef; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsDef;" class="row-height"></tr>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" [attr.colspan]="displayedColumns.length">
              <div class="p-4 text-center text-muted ">
                <img src="./assets/images/no-data-placeholder.png " alt=" " class="pb-3 " />
                <h6>No Calls Found</h6>
            </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="flex mat-paginator-sticky mat-paginator-container" style="margin-left: auto">
      <div style="flex: 1"></div>
      <mat-paginator #paginator [length]="mrsDataSource?.data?.length" [pageIndex]="0" [pageSize]="10" (page)="setStyles()" appearance="fill"
        [pageSizeOptions]="[10, 25, 50, 100]">
      </mat-paginator>
    </div>
  </div>
  