import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppService } from 'app/app.service';
import { IConfigService } from 'app/config/iconfigservice';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SbliOneIncPaymentService {

    publicApiBaseUrl: string;

    constructor(
    public appService: AppService,
    public httpClient: HttpClient,
    public configService: IConfigService,
    ) {
        this.publicApiBaseUrl = this.configService.getConfiguration().publicApiUrl;
    }

    public saveMapkeyData(payInfo ): Observable<any> {
        return this.httpClient.post( this.publicApiBaseUrl+'client/'+payInfo.clientId+'/account/'+payInfo.accountId+'/case/'+payInfo.caseId+'/integration/'+payInfo.caseIntegrationQueueId+'/sbli/savemapkeydata', payInfo );
    }
}
