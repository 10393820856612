<div class="account-mapkey-row">
    <!-- Left Column -->
    <div class="account-name">
        <sup>Account</sup>
        {{ account.name }}
    </div>

    <!-- Right Column -->
    <div class="account-mapkeys">
        <ng-container *ngIf="!mapKeysLoaded">
            <mat-spinner mode="indeterminate" diameter="30"></mat-spinner>
        </ng-container>

        <mat-form-field class="mapkey-select" *ngIf="mapKeysLoaded" floatLabel='never' [ngClass]="{ 'mapkey-notfound': mapKeyFilterInput.hasError('is-not-found')}">
            <mat-label class="label">Mapkey</mat-label>

            <input #input
                type="text"
                aria-label="mapKey"
                matInput
                [formControl]="mapKeyFilterInput"
                [matAutocomplete]="auto"
                (input)="handleMapKeyInput(input.value)">

            <span class="menu-down-suffix" matSuffix><mat-icon svgIcon="menu-down"></mat-icon></span>

            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="handleMapKeySelected($event)">
                <mat-option *ngFor="let filteredMapKey of filteredMapKeysSub | async" [value]="filteredMapKey.entityHierarchy">{{ filteredMapKey.entityHierarchy }}</mat-option>
            </mat-autocomplete>

            <mat-error *ngIf="mapKeyFilterInput.hasError('is-not-found')">Mapkey missing; value will be blank</mat-error>
        </mat-form-field>
    </div>
</div>