import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@auth0/auth0-angular';
import { Auth0Config } from 'app/config/config-model';
import * as auth0 from 'auth0-js';
import jwtDecode from 'jwt-decode';
import * as _ from 'lodash';
import { forkJoin, of, timer } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { AppService } from '../app.service';
import { IConfigService } from '../config/iconfigservice';
import { enumKeys, FeatureToggle, StorageKeysEnum } from '../enums';
import { MonitoringService } from '../monitor.service';
import { FeatureManagerService } from '../services/global/feature-manager/feature-manager.service';
import { NotificationService, NotificationSeverity } from '../services/notification.service';
import { RoutesEnum, RoutingService } from '../services/routing.service';

@Injectable()
export class AuthLegacyService implements OnDestroy {

    private subs = new SubSink();
    private auth0;
    private refreshSubscription: any;
    private loadAuthenticatedDataPromise: Promise<boolean>;
    private _tokenName: string = 'access_token';
    isAuthenticatedV2: boolean;
    private _ssoState: string = '17B7FA32-F2A8-4E8A-A867-7F4EAE441A6B';
    private _defaultState: string = 'BBE09444-17B4-43B6-B58D-56E3B574696A';
    private _defaultNonce: string = 'C9EA9FC1-C90E-40C0-9F51-00AE4BBBCFFC';
    useAuthenticationV2: boolean;

    constructor(
    private configService: IConfigService,
    private appService: AppService,
    private monitoringService: MonitoringService,
    private routingService: RoutingService,
    private notificationService: NotificationService,
    private matDialog: MatDialog,
    private authService: AuthService,
    private featureManagerService: FeatureManagerService,
    private zone: NgZone) {
    }

    setAuth0Config(){
        if (this.auth0) {
            return;
        }

        this.useAuthenticationV2 = this.featureManagerService.getByName(FeatureToggle.GlobalUseAuthenticationV2).enabled;

        if (this.useAuthenticationV2) {
            const auth0V2Config = this.configService.getConfiguration().auth0V2Config;

            const auth0Config: Auth0Config = {
                clientID: auth0V2Config.clientId,
                domain: auth0V2Config.domain,
                responseType: 'token id_token',
                audience: auth0V2Config.audience,
                redirectUri: auth0V2Config.redirectUri,
                scope: auth0V2Config.scope
            };

            this.auth0 = new auth0.WebAuth(auth0Config);
        }else{
            this.auth0 = new auth0.WebAuth(this.configService.getConfiguration().auth0Config);
        }
    }

    public login(): void {
        this.setAuth0Config();
        this.auth0.authorize();
    }

    public async handleAuthentication(): Promise<void> {
        return new Promise<void>((resolve) => {
            if (window.location.hash == null || window.location.hash.indexOf(this._tokenName) === -1) {
                // no auth0 hash on the URL, just proceed.
                resolve();
                return;
            }

            const parsedIdToken: any = jwtDecode(this.getQueryVariable('id_token', true));
            const options: any = {};

            if (parsedIdToken && parsedIdToken['sub'] !== typeof('undefined') && parsedIdToken['sub'].startsWith('samlp')) {
                options.__enableIdPInitiatedLogin = true;
            }

            const isUrlLogin = window.location.hash.indexOf(`state=${this._defaultState}`) >= 0;
            if (isUrlLogin) {
                options.state = this._defaultState;
                options.nonce = this._defaultNonce;
            }

            const isSsoLogin = window.location.hash.indexOf(`state=${this._ssoState}`) >= 0;
            if (isSsoLogin) {
                options.state = this._ssoState;
                options.nonce = undefined;
            }

            // Found a hash, parse it and set the security context before proceeding.
            this.setAuth0Config();
            this.auth0.parseHash(options, (err, authResult) => {
                if (authResult && authResult.accessToken && authResult.idToken) {
                    this.setSession(authResult);
                } else if (err) {
                    const jsonError = JSON.stringify(err);
                    const error = new Error(jsonError);
                    this.monitoringService.logError(error);

                    if (err.errorDescription && (err.errorDescription.includes('Issued At (iat) claim error') && err.errorDescription.includes('is before issued at time'))) {
                        this.notificationService.showNotification({
                            severity: NotificationSeverity.Error,
                            message: 'Your computer\'s system clock appears out of sync. Due to security concerns, we\'re unable to sign you in. Please manually synchronize your system clock or contact your System Administrator.'
                        });
                    }
                    console.log(err);
                }
                resolve();
            });
        });
    }

    setSessionFromAuthResult(authResult: any): void {
        this.setSession({
            accessToken: authResult.access_token,
            idToken: authResult.id_token,
            expiresIn: authResult.expires_in
        });
    }

    private setSession(authResult): void {
    // Set the time that the Access Token will expire at
        const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
        localStorage.setItem('access_token', authResult.accessToken);
        localStorage.setItem('id_token', authResult.idToken);
        localStorage.setItem('expires_at', expiresAt);
        localStorage.setItem('expiresIn', authResult.expiresIn);

        this.scheduleRenewal();
    }

    public async logout(executeRedirect: boolean = true, redirectUrl: string = '/authorize?action=logout') {
    // Remove tokens and expiry time from localStorage
        localStorage.removeItem('access_token');
        localStorage.removeItem('id_token');
        localStorage.removeItem('expires_at');
        localStorage.removeItem('customAttributes');

        const legacy = JSON.parse(localStorage.getItem('legacy'));
        localStorage.removeItem('legacy');
        this.unscheduleRenewal();

        for (const value of enumKeys(StorageKeysEnum)) {
            localStorage.removeItem(StorageKeysEnum[value]);
        }

        // Go to login
        if (executeRedirect){
            if (this.matDialog){
                this.matDialog.closeAll();
            }

            // this is needed to revoke the cached token from the new auth0 tenant, otherwise the access_token is retrieved silently and you're redirected back to the dashboard.
            // will also redirect to legacy login page

            if (!this.useAuthenticationV2) {
                this.useAuthenticationV2 = this.featureManagerService.getByName(FeatureToggle.GlobalUseAuthenticationV2).enabled;
            }

            if (this.useAuthenticationV2 && (legacy === false || !legacy)) {
                this.authService.logout(
                    {logoutParams: {
                        returnTo: `${window.location.origin + redirectUrl}`
                    }});
            } else {
                this.zone.run(() => {
                    this.routingService.navigateToRoute(RoutesEnum.login);
                });
            }
        }
    }

    public isAuthenticated(): boolean {
        const token = localStorage.getItem('id_token');
        if (token == null) {

            return false;
        }

        // Check whether the current time is past the
        // Access Token's expiry time
        const expiresAt = JSON.parse(localStorage.getItem('expires_at') || '{}');
        return new Date().getTime() < expiresAt;
    }

    public renewToken() {
        this.setAuth0Config();
        this.auth0.checkSession({}, (err, result) => {
            if (err) {
                console.log(`Could not get a new token (${err.error}: ${err.error_description}).`);
                // TODO: probably need to force a login
            } else {
                console.log(`Successfully renewed auth!`);
                this.setSession(result);
            }
        });
    }

    public scheduleRenewal() {

        if (!this.isAuthenticated()) { return; }
        this.unscheduleRenewal();

        const expiresAt = JSON.parse(window.localStorage.getItem('expires_at'));

        console.debug('Scheduling renewal of auth token at ' + (new Date(expiresAt)).toString());

        const source = of(expiresAt).pipe(mergeMap(
            expiresAt2 => {

                const now = Date.now();

                // Use the delay in a timer to run the refresh at the proper time
                // Subtract 1 minute so this gets renewed prior to expiration
                return timer(Math.max(1, expiresAt2 - now - 60000));
            }));

        // Once the delay time from above is
        // reached, get a new JWT and schedule
        // additional refreshes
        this.refreshSubscription = source.subscribe(() => {
            this.renewToken();
        });
        this.subs.add(this.refreshSubscription);
    }

    public unscheduleRenewal() {
        if (!this.refreshSubscription) { return; }
        this.refreshSubscription.unsubscribe();
    }

    private unauthorizedAccess() {
        this.appService.showMsg('error', 'Unauthorized access!', false);
        this.logout();
    }

    public async loadAuthenticatedDataAsync() {
        return await this.loadAuthenticatedData();
    }

    public loadAuthenticatedData(): Promise<boolean> {
        this.loadAuthenticatedDataPromise = this.loadAuthenticatedDataPromise || new Promise<void>((resolve, reject) => {
            this.subs.add(this.appService.getData('Auth/getAuthenticatedData').subscribe(
                (authResp) => {
                    if (authResp.status !== 'success') {
                        reject();
                        return;
                    }

                    this.appService.setUserdetails(authResp.data);
                    this.monitoringService.setAuthenticatedUserId(authResp.data.userName);
                    this.appService.getAllConfigData();

                    forkJoin({
                        authpermissions: this.appService.getData('AuthPermissions'),
                        authTypes: this.appService.getData('AuthPermissionTypes')
                    }).subscribe(
                        async (authData) => {
                            const authPermissions = authData.authpermissions.data;
                            const authPermissionTypes = authData.authTypes.data;

                            const access = [];

                            authResp.data.userRolePermissions.forEach(permission => {

                                const accessName = _.find(authPermissions, ['id', permission.authPermissionsId]).authPermission;
                                const permissionName = _.find(authPermissionTypes, ['id', permission.authPermissionsTypeId]).authPermissionType;

                                const ac = {};
                                ac[accessName] = permissionName;
                                access.push(ac);

                            });

                            this.appService.setACL(access);
                            await this.appService.getAllConfigData().then(() => {
                                this.appService.getCombos();
                            });

                            resolve();
                        },
                        (err) => reject());
                },
                (error) => reject()));

        }).then(
            () => true,
            () => {
                this.unauthorizedAccess();
                return false;
            });

        return this.loadAuthenticatedDataPromise;
    }

    private getQueryVariable(variable, fromHash) {
        var query = fromHash ? window.location.hash.substring(1) : window.location.search.substring(1);
        var vars = query.split('&');
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split('=');
            if (decodeURIComponent(pair[0]) == variable) {
                return decodeURIComponent(pair[1]);
            }
        }
        console.log('Query variable %s not found', variable);
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}


