import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {  lastValueFrom, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { AppService } from '../app.service';
import {  SignatureEnvelope, SignatureEnvelopeRecipient } from '../models';
import { IConfigService } from '../config/iconfigservice';
import { SignatureEnvelopeEmbeddedView } from '../models/case/signature-envelope-embedded-view';

@Injectable()
export class CaseSignatureService {
    baseUrl: string;

    constructor(
        private appService: AppService,
        private configService: IConfigService,
        private httpClient: HttpClient
    ) {
        this.baseUrl = this.configService.getConfiguration().publicApiUrl;
    }

    getSignatureEnvelope(accountId: string, caseId: string, envelopeId: string, caseIntegrationQueueId: string): Promise<SignatureEnvelope> {
        const clientId = this.appService.getClientId('');

        return this.request('get', `${this.baseUrl}client/${clientId}/account/${accountId}/case/${caseId}/envelope/${envelopeId}/${caseIntegrationQueueId}/status`);
    }

    putSignatureEnvelope(accountId: string, caseId: string, envelopeId: string, envelope: SignatureEnvelope) {
        const clientId = this.appService.getClientId('');

        this.request('put', `${this.baseUrl}client/${clientId}/account/${accountId}/case/${caseId}/envelope/${envelopeId}`, envelope);
    }

    getSignatureEnvelopeEmbeddedView(caseId: string, envelopeId: string, recipient: SignatureEnvelopeRecipient): Promise<SignatureEnvelopeEmbeddedView> {
        const clientId = this.appService.getClientId('');

        return this.request('post', `${this.baseUrl}client/${clientId}/case/${caseId}/envelope/${envelopeId}/signature/embedded`, recipient);
    }

    private request(method: string, url: string, body?: any): Promise<any> {
        return lastValueFrom(this.httpClient.request(method, url, {
            body: body,
            headers: this.appService.getHeaders()
        }).pipe(map((resData) => {
            this.appService.display(false);
            return resData;
        }),
        catchError((error: any) => {
            this.appService.display(false);
            this.appService.showResponseErrorMsg(error);
            return throwError(() => new Error(error.message));
        })));
    }
}
