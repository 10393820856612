<div class="question-container"
    [ngClass]="{active: isSelected, interviewQuestion: interviewMode}"
    *ngIf="!interviewMode || config.DisplayType == 'Inline'">
    <div class="question-header" *ngIf="!interviewMode" (click)="selectQuestion()">
        <button type="button" class="btn btn-link float-left" (click)="expanded = !expanded">
            <i aria-hidden="true" class="fa" [ngClass]="{'fa-angle-right': !expanded, 'fa-angle-down': expanded}"></i>
        </button>
        <span class="question-mapkey">
            {{config.mapKeyName}}
        </span>
        <span class="question-mapkey float-right pr-2">
            {{config.answerType}}
        </span>
    </div>
    <div class="question-body" *ngIf="expanded" [ngClass]="{
        'message-info': config.MessageType == 'Information',
        'message-warning': config.MessageType == 'Warning',
        'message-danger': config.MessageType == 'Error',
        'message-success': config.MessageType == 'Success'
        }">
        <div class="question-text rendered-quill-content ql-editor" [innerHTML]="questionText"></div>
        <div class="help-text rendered-quill-content ql-editor" [innerHTML]="helpText" *ngIf="config.helpText?.length"></div>
        <app-field-details [question]="config" [form]="group" [readOnlyMode]="isReadOnly()"></app-field-details>
    </div>
</div>
