import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { ConfirmationDialogService, RoutingService } from 'app/services';
import { Observable } from 'rxjs';
import { EditQueuesComponent } from '../edit-queues/edit-queues.component';
import { AccountConfigComponent } from "@AccountConfig/account-config.component";

export interface CanNavigateAwayWithConfirm {
  canDeactivate: (nextStateUrl) => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class CanNavigateAwayWithConfirmGuard implements CanDeactivate<EditQueuesComponent> {

    constructor(public confirmationService: ConfirmationDialogService,
    public routingService: RoutingService){

    }

    canDeactivate(component: EditQueuesComponent, routerSnapshot: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot) {
        return component?.canDeactivate ? component?.canDeactivate(component, routerSnapshot, currentState, nextState) : true;
    }
}
@Injectable()
export class CanNavigateAwayWithConfirmGuardAccountConfig implements CanDeactivate<AccountConfigComponent> {

    constructor(public confirmationService: ConfirmationDialogService,
        public routingService: RoutingService){

    }

    canDeactivate(component: AccountConfigComponent, routerSnapshot: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot):boolean {
        return component?.canDeactivate ? component?.canDeactivate(component, routerSnapshot, currentState, nextState) : true;
    }
}
