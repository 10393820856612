import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AppService } from '../../app.service';
import { IConfigService } from '../../config/iconfigservice';
import { BaseService } from '../base.service';
import { ObservableService } from '../observable.service';

@Injectable()
export class TrustCommerceService extends BaseService {
    publicApiBaseUrl: string;

    constructor(
        public appService: AppService,
        public httpClient: HttpClient,
        public configService: IConfigService,
        public observableService: ObservableService
    ) {
        super(appService, configService, httpClient);
        this.publicApiBaseUrl = this.configService.getConfiguration().publicApiUrl;
    }

    submitCallback(caseIntegrationQueueId:string, payload: any) {
        const clientId = this.appService.getClientId('');
        const url = `${this.publicApiBaseUrl}client/${clientId}/integration/${caseIntegrationQueueId}/trustcommerce/processresponse`;

        return super.postData(url, payload);
    }
}
