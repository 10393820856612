import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { FeatureToggle, IntegrationEnum } from '@Enums';
import { Account, Client } from '@Models';
import { FeatureManagerService, NotificationService, NotificationSeverity, RuleDictionary, SectionDataService } from '@Services';
import {
    CreateRequirementRequest,
    PatchRequirementEvidenceProcessorRequest,
    PatchRequirementIntegrationRequest,
    PatchRequirementRuleRequest,
    PatchRequirementSectionRequest,
    PatchRequirementsSortOrderRequest,
    RequirementResponse,
} from '@Underwriting/models';
import { UWRequirementsDataService, UWWorkbenchService } from '@Underwriting/services';
import { TimeUtils, Utils } from '@Utils';
import { forkJoin } from 'rxjs';
import { SubSink } from 'subsink';

import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import PatchRequirementIncludeInCasePackageRequest from '@CaseSummary/underwriting/models/uww-patch-requirement-include-in-case-package-request';
import RulesDataService from '@Services/config/rules.data.service';
import RequirementItem from './requirement-item';
import RequirementItemType from './requirement-item-type.enum';
import PatchRequirementAutomaticallySummarizeRequest from '@CaseSummary/underwriting/models/uww-patch-requirement-automatically-summarize-request';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import { AppService } from '@App/index';
import { UwwActionDTO } from '@DTOs';

export type SectionItem = {
    id: string;
    name: string;
    isDisabled: boolean;
};
export type RuleItem = {
    id: string;
    name: string;
    isDisabled: boolean;
};
export type IntegrationItem = {
    integrationId: string;
    name: string;
    actionid: string;
    isDisabled: boolean;
};

export type EvidenceProcessorItem = {
    id: string;
    name: string;
}

@Component({
    selector: 'workbench-requirements-config',
    templateUrl: './workbench-requirements-config.component.html',
    styleUrls: ['workbench-requirements-config.component.scss'],
})
export default class WorkbenchRequirementsConfigComponent implements OnInit, OnDestroy {
    @Input() client: Client;
    @Input() account: Account;
    @Output() onChange = new EventEmitter<void>();

    @ViewChild('addRequirement') addRequirementDialog: TemplateRef<Element>;
    @ViewChild('table', {static: true}) table: MatTable<RequirementItem>;

    warning_title = 'You cannot change a requirement name once saved';
    warning_message = 'Delete and add a new one to change it. Cases using a deleted requirement will keep using that requirement.';
    tip_includeOnCasePackage = 'Include in Case Package';
    tip_autosummarize = 'Automatically Summarize';

    dataLoaded = false; // All necessary data has been loaded
    featureflagsLoaded = false;
    dataSource = new MatTableDataSource<any>();

    //Association
    evidenceProcessors: EvidenceProcessorItem[] = [{id: IntegrationEnum.DigitalOwl, name: 'Digital Owl '}];    
    noEvidenceProcessor: EvidenceProcessorItem = { id: Utils.emptyGuid, name: 'None'};
    selectedEvidenceProcessorId: string = this.noEvidenceProcessor.id;

    // Requirements
    lastUpdated: Date;
    requirementsLoaded = false;
    requirements: RequirementItem[] = [];
    newRequirementNameControl: FormControl;
    newRequirementSectionControl: FormControl;
    newRequirementRuleControl: FormControl;
    newRequirementAssociationControl: FormControl;
    newRequirementAutoSummarizeControl: FormControl;
    newRequirementIncludeInCasePackageControl: FormControl;
    showAutoSummarizeControl = false;
    newRequirementFormattedIntegrationName: string = this.noEvidenceProcessor.name;
    requirementNamesLowerCase: string[] = [];
    addingRequirement = false;
    addUsingNewViewAndAssocation = false;

    // Sections & Integrations = RequirementType 
    showIncludeInPackage = false;
    hideSectionConfig = false;
    sectionsLoaded = false;
    noSection: SectionItem = { id: '00000000-0000-0000-0000-000000000000', name: 'None', isDisabled: false };
    sections: SectionItem[] = [];
    
    //Integrations
    integrationPostActions: IntegrationItem[] = [];
    integrationPostActionsLoaded = false;
    showIntegrationsInTypeDropdown = false;

    // Rules
    rulesLoaded = false;
    hideRuleConfig = false;
    rulesDictionary: RuleDictionary = null;
    noRule: RuleItem = { id: '00000000-0000-0000-0000-000000000000', name: 'None', isDisabled: false };
    rules: RuleItem[] = [];

    columnsToDisplay: string[] = [];

    subs = new SubSink();
    protected get RequirementItemType() { return RequirementItemType; } // Make enums available in template


    addRequirementDialogRef: MatDialogRef<Element, any> = null;

    constructor(
        private appService: AppService,
        private uwRequirementsDataService: UWRequirementsDataService,
        private uwWorkbenchService: UWWorkbenchService,
        private sectionDataService: SectionDataService,
        private rulesDataService: RulesDataService,
        private featureManagerService: FeatureManagerService,
        private notificationService: NotificationService,
        public dialog: MatDialog,
    ) {
    }

    //#region Helpers

    updateData_section(requirement: RequirementItem): RequirementItem {
        if (requirement.sectionId == null) return requirement;
        if (requirement.sectionId == this.noSection.id) return requirement;

        const section = this.sections.find(s => s.id === requirement.sectionId);
        if (section) {
            section.isDisabled = true;
        }
    }

    updateData_integrationPostAction(requirement: RequirementItem): RequirementItem {
        if (requirement.integrationActionId == null) return requirement;
        if (requirement.integrationActionId == Utils.emptyGuid) return requirement;

        const integrationPostAction = this.integrationPostActions.find(x => x.integrationId === requirement.integrationId && x.actionid == requirement.integrationActionId);
        if (integrationPostAction) {
            integrationPostAction.isDisabled = true;
        }
    }

    updateDataLoaded() {
        if (!this.requirementsLoaded) return;
        if (!this.sectionsLoaded) return;
        if (!this.rulesLoaded) return;
        if (!this.integrationPostActionsLoaded) return;

        // Enable all Sections because we won't know which Requirements had sections removed, only which section currently have sections.
        this.sections.forEach(section => { section.isDisabled = false; });

        this.integrationPostActions.forEach(x => { x.isDisabled = false; });

        // Disable the Sections that are currently on Requirements
        this.requirements.forEach(requirement => {
            this.updateData_section(requirement);
            this.updateData_integrationPostAction(requirement);
        });

        const sortedRequirements = [...this.requirements].sort((a, b) => b.updatedOn.getTime() - a.updatedOn.getTime());
        this.lastUpdated = sortedRequirements[0]?.updatedOn;
        this.dataLoaded = true;
    }

    mapRequirementResponseToItem(existingRequirements: RequirementResponse[]) {
        const items = existingRequirements.map(x => {
            const requirementTypeId = x.sectionId ?? x.integrationActionId;

            const requirementIntegrationMatch = this.evidenceProcessors.find(y => y.id == x.evidenceProcessor?.processorId);
            const requirementItem: RequirementItem = {
                ...x,
                id: x.id,
                name: x.name,
                sortOrder: x.sortOrder,
                type: RequirementItemType.Existing,
                errorMessage: '',
                updatedOn: TimeUtils.convertToDateCst(x.audit?.updatedOn ?? x.audit?.createdOn),
                sectionId: x.sectionId ?? this.noSection.id,
                ruleId: x.ruleId ?? this.noRule.id,
                integrationId: x.integrationId ?? Utils.emptyGuid,
                formattedIntegrationName: !requirementIntegrationMatch ? this.noEvidenceProcessor.name : 'Integration: ' + requirementIntegrationMatch.name,
                selectedEvidenceProcessorId: x.evidenceProcessor?.processorId ?? this.noEvidenceProcessor.id,
                selectedRequirementTypeId: requirementTypeId ?? Utils.emptyGuid
            };
            return requirementItem;
        });

        return items;
    }

    createNewRequirement(name: string, sortOrder: number, sectionId?: string, ruleId?: string, integrationId?: string, integrationActionId?: string, autoSummarizeEnabled?: boolean, associationId?: string, includeInCasePackage?: boolean) {
        let evidenceProcesser = null;
        if (associationId && associationId != this.noEvidenceProcessor.id) {
            evidenceProcesser = {
                processorType: 'summarize',
                processorId: associationId
            };
        }

        const requirementItem: RequirementItem = {
            id: Utils.emptyGuid,
            name,
            sortOrder,
            type: RequirementItemType.Added,
            errorMessage: '',
            sectionId,
            ruleId,
            integrationId: integrationId,
            integrationActionId: integrationActionId,
            autoSummarizeEnabled: autoSummarizeEnabled,
            includeInCasePackage: includeInCasePackage,
            evidenceProcessor: evidenceProcesser,
            selectedEvidenceProcessorId: evidenceProcesser?.processorId ?? this.noEvidenceProcessor.id
        };
        return requirementItem;
    }

    updateSortOrder() {
        this.requirements.forEach((requirement, index) => requirement.sortOrder = index);
    }

    resetAddRequirementForm() {
        this.newRequirementNameControl.setValue('');
        this.newRequirementNameControl.markAsUntouched();
        this.newRequirementSectionControl.setValue(this.noSection.id);
        this.newRequirementSectionControl.markAsUntouched();
        this.newRequirementRuleControl.setValue(this.noSection.id);
        this.newRequirementRuleControl.markAsUntouched();
        this.newRequirementAssociationControl.setValue(this.noEvidenceProcessor.id);
        this.newRequirementAssociationControl.markAsUntouched();
        this.newRequirementFormattedIntegrationName = this.noEvidenceProcessor.name;
        this.newRequirementAutoSummarizeControl.setValue(false);
        this.showAutoSummarizeControl = false;
        this.newRequirementIncludeInCasePackageControl.setValue(false);
    }

    nameAlreadyExists(name: string) {
        return this.requirementNamesLowerCase.includes(name.toLowerCase());
    }

    closeAddRequirementDialog() {
        this.addRequirementDialogRef?.close();
        this.resetAddRequirementForm();
    }

    //#endregion
    //#region Subscriptions and loaders

    subscribeRules() {
        this.rulesDataService.load(this.account.id).subscribe(rules => {
            const ruleItems: RuleItem[] = rules.map((rule): RuleItem => ({ id: rule.id, name: rule.name, isDisabled: false }));
            ruleItems.unshift(this.noRule); // add no rule default to front of array

            this.rules = ruleItems;
            this.rulesLoaded = true;
            this.updateDataLoaded();
        });
    }

    subscribeToRequirements() {
        this.subs.add(this.uwRequirementsDataService.loadRequirements(this.client.code, this.account.id).subscribe(requirementResponses => {
            this.requirements = this.mapRequirementResponseToItem(requirementResponses);
            this.requirementNamesLowerCase = this.requirements.map(x => x.name.toLowerCase());
            this.requirementsLoaded = true;
            this.dataSource.data = this.requirements;
            this.updateDataLoaded();
        }));
    }

    subscribeToSections() {
        this.subs.add(this.sectionDataService.loadSectionsForWorkflow(this.account.workflowId).subscribe(sections => {
            const sectionSelectItems = sections.map(section => ({
                id: section.id,
                name: section.name,
                isDisabled: false
            }));
            
            if (this.addUsingNewViewAndAssocation) {
                this.sections = [...sectionSelectItems];
            }else{
                this.sections = [this.noSection, ...sectionSelectItems];
            }

            this.sectionsLoaded = true;
            this.updateDataLoaded();
        }));
    }

    setColumnsToDisplay() {
        this.columnsToDisplay.push('sortOrder');
        this.columnsToDisplay.push('name');
        if (this.showIncludeInPackage && !this.addUsingNewViewAndAssocation) {
            this.columnsToDisplay.push('includeInCasePackage');
        }
        if (!this.hideSectionConfig && this.addUsingNewViewAndAssocation) {
            this.columnsToDisplay.push('typeId');
        }
        else if (!this.hideSectionConfig) {
            this.columnsToDisplay.push('sectionId');
        }
        if (!this.hideRuleConfig) {
            this.columnsToDisplay.push('ruleId');
        }
        if (this.addUsingNewViewAndAssocation) {
            this.columnsToDisplay.push('integrationId');
            this.columnsToDisplay.push('autoSummarizeEnabled');
        }
        if (this.showIncludeInPackage && this.addUsingNewViewAndAssocation) {
            this.columnsToDisplay.push('includeInCasePackage');
        }
        this.columnsToDisplay.push('delete');
    }

    subscribeToFeatureToggleThenSections() {
        forkJoin({
            hideSaarConfig: this.featureManagerService.isEnabled(FeatureToggle.UWWorkbenchRequirementsHideSaaRConfigClient, this.client.id),
            hideRuleConfig: this.featureManagerService.isEnabled(FeatureToggle.UWWorkbenchRequirementsHideRuleConfigClient, this.client.id),
            addUsingNewViewAndAssocation: this.featureManagerService.isEnabled(FeatureToggle.UWWorkbenchRequirementsEnableSummarizeDocumentsClient, this.client.id),
            showIncludeInPackage: this.featureManagerService.isEnabled(FeatureToggle.ConfigSettingsWorkbenchIncludeInCasePackageClient, this.client.id),
            showIntegrationsInTypeDropdown: this.featureManagerService.isEnabled(FeatureToggle.UWWorkbenchEnableConfigurationOfIntegrationsAsRequirements, this.client.id)
        }).subscribe(({ hideSaarConfig, hideRuleConfig, addUsingNewViewAndAssocation, showIncludeInPackage, showIntegrationsInTypeDropdown}) => {
            this.hideSectionConfig = hideSaarConfig;
            this.hideRuleConfig = hideRuleConfig;
            this.showIncludeInPackage = showIncludeInPackage;
            this.addUsingNewViewAndAssocation = addUsingNewViewAndAssocation;
            this.showIntegrationsInTypeDropdown = showIntegrationsInTypeDropdown;
            this.featureflagsLoaded = true;
            this.subscribeToSections();
            this.setColumnsToDisplay();
        });
    }

    subscribeToAccountActions() {
        forkJoin({
            globalActions: this.appService.getData(`Actions/client/${this.client.id}`),
            accountActions: this.appService.getData(`Actions/account/${this.account.id}`),
            accountSettings: this.appService.getData(`AccountSettings/${this.account.id}`),
        }).subscribe({
            next: (action) => {
                const globalActions = action.globalActions.data;
                const accountActions: UwwActionDTO[] = action.accountActions.data;
                const suppressedIntegrationsAccountSetting = action.accountSettings.data?.find(x => x.name == "uwwCaseRequirementExcludedIntegrations");

                accountActions?.forEach(accountAction => {
                    const globalAction = globalActions.find(globalAction => globalAction.id == accountAction.globalActionId);
                    if (globalAction.integration_Id || accountAction.v1IntegrationId) { // make sure we have an integration id
                        const globalActionName = globalAction?.name;
                        const actionName = (accountAction.actionName) ?? globalActionName; 

                        const integrationId = globalAction.integration_Id ?? accountAction.v1IntegrationId;
                        if ((!suppressedIntegrationsAccountSetting) || !suppressedIntegrationsAccountSetting.value.includes(integrationId)) // finally, filter based on account setting
                            this.integrationPostActions.push({integrationId: integrationId, name: actionName, actionid: accountAction.actionId, isDisabled: false });
                    }
                });

                this.integrationPostActions.sort((a, b) => this.sortIntegrationPostActions(a, b));

                this.integrationPostActionsLoaded = true;
            }
        });
    }    

    sortIntegrationPostActions(a: IntegrationItem, b: IntegrationItem) {
        if (a.name != b.name)
            return a.name.localeCompare(b.name);

        return a.actionid.localeCompare(b.actionid);
    }
    //#endregion
    //#region Lifecycle

    async ngOnInit() {
        this.newRequirementNameControl = new FormControl('', { validators: [Validators.required] });
        this.newRequirementSectionControl = new FormControl(this.noSection.id);
        this.newRequirementRuleControl = new FormControl(this.noRule.id);
        this.newRequirementAssociationControl = new FormControl(this.noEvidenceProcessor.id);
        this.newRequirementAutoSummarizeControl = new FormControl(false);
        this.newRequirementIncludeInCasePackageControl = new FormControl(false);

        this.uwWorkbenchService.setClientInfo(this.client);
        this.subscribeRules();
        this.subscribeToRequirements();
        this.subscribeToFeatureToggleThenSections();
        this.subscribeToAccountActions();
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    //#endregion
    //#region Handlers

    handleOpenAddRequirement() {
        this.addRequirementDialogRef = this.dialog.open(this.addRequirementDialog, {
            width: '650px',
            disableClose: true
        });
    }

    handleCancelAddRequirement() {
        this.closeAddRequirementDialog();
    }

    handleAddRequirementIntegrationChange(change: MatSelectChange) {
        const selectedIntegrationId = change.value;
        if (selectedIntegrationId == this.noEvidenceProcessor.id)
        {
            this.newRequirementFormattedIntegrationName = this.noEvidenceProcessor.name;
            this.showAutoSummarizeControl = false;
        }
        else 
        {
            const matchingIntegration = this.evidenceProcessors.find(x => x.id == selectedIntegrationId);
            this.newRequirementFormattedIntegrationName = 'Integration: ' + matchingIntegration.name;
            this.showAutoSummarizeControl = true;
        }
    }

    handleAddRequirement() {

        this.newRequirementNameControl.markAsTouched();
        if (this.nameAlreadyExists(this.newRequirementNameControl.value)) {
            this.newRequirementNameControl.setErrors({ duplicate: this.newRequirementNameControl.value });
            return;
        }
        if (this.newRequirementNameControl.invalid)
            return;

        this.newRequirementSectionControl.markAsTouched();
        if (this.newRequirementSectionControl.invalid)
            return;


        this.newRequirementRuleControl.markAsTouched();
        if (this.newRequirementRuleControl.invalid)
            return;

        this.newRequirementAssociationControl.markAsTouched();
        if (this.newRequirementAssociationControl.invalid)
            return;

        const name = this.newRequirementNameControl.value;
        const rule = this.newRequirementRuleControl.value;
        const typeId = this.newRequirementSectionControl.value;
        let sectionId = null;
        let integrationId = null;
        let integrationActionId = null;
        const matchingSection = this.sections.find(x => x.id == typeId);
        const matchingIntegrationPostAction = this.integrationPostActions.find(x => x.actionid == typeId);
        if (matchingSection)
            sectionId = matchingSection.id;
        if (matchingIntegrationPostAction) {
            integrationId = matchingIntegrationPostAction.integrationId;
            integrationActionId = matchingIntegrationPostAction.actionid;
        }
        const associationId = this.newRequirementAssociationControl.value;
        const autoSummarizeEnabled = this.newRequirementAutoSummarizeControl.value;
        const includeInCasePackage = this.newRequirementIncludeInCasePackageControl.value;

        // Has the section already been selected, Bug-32238
        const selectedSection = this.sections.find(x => x.id === sectionId);
        if (selectedSection?.isDisabled) {
            this.newRequirementSectionControl.setErrors({ duplicateSection: selectedSection.name });
            return;
        }

        this.addingRequirement = true;
        const sortOrder = this.requirements.length;
        const newRequirementItem = this.createNewRequirement(name, sortOrder, sectionId, rule, integrationId, integrationActionId, autoSummarizeEnabled, associationId, includeInCasePackage);

        const createRequirement: CreateRequirementRequest = { ...newRequirementItem, accountId: this.account.id };
        this.uwWorkbenchService.createRequirement(createRequirement).subscribe(_response => {
            this.addingRequirement = false;
            // DEV NOTE: We need to reload requirements because we don't have SignalR on the Config page
            this.uwRequirementsDataService.reloadRequirements(this.client.code, this.account.id);
            this.closeAddRequirementDialog();
            this.notificationService.showNotification({
                severity: NotificationSeverity.Success,
                message: "Requirement added"
            });
        });
    }

    handleDeleteRequirement(requirementToDelete: RequirementItem) {
        this.uwWorkbenchService.deleteRequirement(requirementToDelete.id).subscribe(_response => {
            this.uwRequirementsDataService.reloadRequirements(this.client.code, this.account.id);
        });
    }

    handleSortChange(dropEvent: CdkDragDrop<string>) {
        const previousIndex = this.dataSource.data.findIndex(d => d === dropEvent.item.data);

        moveItemInArray(this.dataSource.data, previousIndex, dropEvent.currentIndex);
        // DEV NOTE: The d&d tool re-ordered the `this.requirements` array.
        // Now we need to make sure we update their sortOrder property to match their array index.
        this.updateSortOrder();

        const patchSortOrderRequest: PatchRequirementsSortOrderRequest = {
            accountId: this.account.id,
            requirementsOrder: this.requirements.map(r => ({ requirementId: r.id, sortOrder: r.sortOrder }))
        };

        this.uwWorkbenchService.patchSortOrder(patchSortOrderRequest).subscribe(_response => {
            this.uwRequirementsDataService.reloadRequirements(this.client.code, this.account.id);
        });
    }

    handleSectionChange(change: MatSelectChange, requirementId: string) {
        const selectedSectionId = change.value;

        this.patchRequirementSection(requirementId, selectedSectionId);
    }

    handleRuleChange(change: MatSelectChange, requirementId: string) {
        const selectedRuleId = change.value;

        const request: PatchRequirementRuleRequest = {
            accountId: this.account.id,
            requirementId: requirementId,
            ruleId: (selectedRuleId === this.noRule.id) ? null : selectedRuleId
        };

        this.uwWorkbenchService.patchRequirementRule(request).subscribe(_ => {
            this.uwRequirementsDataService.reloadRequirements(this.client.code, this.account.id);
        });
    }

    handleSummarizeChange(change: MatSelectChange, requirementId: string) {
        const selectedSummaryId = change.value;

        const request: PatchRequirementEvidenceProcessorRequest = {
            accountId: this.account.id,
            requirementId: requirementId,
            evidenceProcessor: (selectedSummaryId === this.noEvidenceProcessor.id) ? null : {
                processorType: 'summarize',
                processorId: selectedSummaryId
            }
        };

        this.uwWorkbenchService.patchRequirementEvidenceProcessor(request).subscribe(_ => {
            this.uwRequirementsDataService.reloadRequirements(this.client.code, this.account.id);
        });
        
    }

    handleIncludeInCasePackage(change: MatSlideToggleChange, requirementId: string) {
        const request: PatchRequirementIncludeInCasePackageRequest = {
            requirementId: requirementId,
            includeInCasePackage: change.checked,
        };
        this.uwWorkbenchService.patchRequirementIncludeInCasePackage(request).subscribe(_ => {
            this.uwRequirementsDataService.reloadRequirements(this.client.code, this.account.id);
        });
    }

    handleAutomaticallySummarizeToggle(change: MatSlideToggleChange, requirementId: string) {
        const request: PatchRequirementAutomaticallySummarizeRequest = {
            requirementId: requirementId,
            autoSummarizeEnabled: change.checked,
        };
        this.uwWorkbenchService.patchRequirementAutoSummarizedEnabled(request).subscribe(_ => {
            this.uwRequirementsDataService.reloadRequirements(this.client.code, this.account.id);
        });
    }

    async handleTypeChange(change: MatSelectChange, requirementId: string) {
        const selectedTypeId = change.value;

        if (this.sections.find(section => section.id === selectedTypeId)) {
            // we have a section
            await this.patchRequirementSection(requirementId, selectedTypeId).then(
                async _ => await this.patchRequirementIntegration(requirementId, Utils.emptyGuid)
            )
        } else if (this.integrationPostActions.find(x => x.actionid === selectedTypeId)) {
            // we have an integration
            await this.patchRequirementIntegration(requirementId, selectedTypeId).then(
                async _ => await this.patchRequirementSection(requirementId, Utils.emptyGuid)
            )
        } else {
            await this.patchRequirementSection(requirementId, Utils.emptyGuid).then(
                async _ => await this.patchRequirementIntegration(requirementId, Utils.emptyGuid)
            );
        }
    }

    async patchRequirementSection(requirementId: string, selectedSectionId: string, deleteIntegration: boolean = false) {
        const request: PatchRequirementSectionRequest = {
            accountId: this.account.id,
            requirementId: requirementId,
            sectionId: (selectedSectionId === this.noSection.id) ? null : selectedSectionId
        };

        await this.uwWorkbenchService.patchRequirementSection(request).then(_ => {
            this.uwRequirementsDataService.reloadRequirements(this.client.code, this.account.id);
        });
    }

    async patchRequirementIntegration(requirementId: string, selectedPostActionId: string) {
        const selectedPostAction = this.integrationPostActions.find(x => x.actionid == selectedPostActionId);
        const request: PatchRequirementIntegrationRequest = {
            accountId: this.account.id,
            requirementId: requirementId,
            integrationId: (selectedPostActionId === Utils.emptyGuid || selectedPostAction.integrationId === Utils.emptyGuid) ? null : selectedPostAction.integrationId,
            actionId: (selectedPostActionId === Utils.emptyGuid) ? null : selectedPostActionId
        };

        await this.uwWorkbenchService.patchRequirementIntegration(request).then(_ => {
            this.uwRequirementsDataService.reloadRequirements(this.client.code, this.account.id);
        });
    }
    //#endregion
}