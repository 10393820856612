import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    templateUrl: './pdf-viewer-dialog.component.html',
    styleUrls: ['./pdf-viewer-dialog.component.scss']
})
export default class PdfViewerDialog implements OnInit{

    pdfResult: any;
    constructor(
        public dialogRef: MatDialogRef<PdfViewerDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }

    ngOnInit(){        
        this.pdfResult = this.data;
    }

    getZoom() {
        return this.isDesktop() ? "80%" : "auto";
    }

    isDesktop() {
        return window.innerWidth > 1650;
    }
}