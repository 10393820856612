import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ThirdPartySectionService {
    private _interviewRestarted = false;
    get interviewRestarted(): boolean {
        return this._interviewRestarted;
    }
    set interviewRestarted(value: boolean) {
        this._interviewRestarted = value;
    }

    private _thirdPartySectionComplete = true;

    private _navigationFromInterviewSubject = new Subject<NavigationFromInterviewData>();
    navigationFromInterviewObservable = this._navigationFromInterviewSubject.asObservable();

    private _navigationFromThirdPartySectionSubject = new Subject<any>();
    navigationFromThirdPartySectionObservable = this._navigationFromThirdPartySectionSubject.asObservable();

    isthirdPartySectionComplete() {
        return this._thirdPartySectionComplete;
    }

    thirdPartySectionNavigation(direction: ThirdPartyNavigationDirection) {
        this._navigationFromThirdPartySectionSubject.next({ direction });
    }

    moveToNext() {
        this._navigationFromInterviewSubject.next({ userClickedNext: true });
    }

    moveToPrevious() {
        this._navigationFromInterviewSubject.next({ userClickedPrevious: true });
    }
}

export interface NavigationFromInterviewData {
    userClickedNext?: boolean;
    userClickedPrevious?: boolean;
}

export enum ThirdPartyNavigationDirection {
    Previous,
    Next
}
