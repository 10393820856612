<div class="flex-fill overflow-y-auto custom-scrollbar">
  <form [formGroup]="integrationForm" (ngSubmit)="saveForm()" novalidate autocomplete="off">
    <div class="m-5">
      <div class="flex flex-row">
        <div class="page-header">{{integration.name}}</div>
        <div class="flex-1"></div>
        <div *ngIf="checkACL('W')">
          <button mat-flat-button color="primary" class="float-right" (click)="edit()" *ngIf="showView">
            <i aria-hidden="true" class="fa fa-edit pr-1"></i>EDIT
          </button>
          <button type="submit" mat-flat-button color="primary" class="float-right mr-1" *ngIf="!showView">
            SAVE CHANGES
          </button>
          <button mat-button color="basic" class="float-right mr-1 material-default" (click)="cancel()"
            *ngIf="!showView">
            CANCEL
          </button>
        </div>
      </div>
      <div>
        <mat-slide-toggle (change)="toggleIntegration($event)" [checked]="integrationEnabled"
          [disabled]="integration.accountIntegrations.length === 0">Activate Integration
        </mat-slide-toggle>
      </div>
    </div>

    <div class="m-5" *ngIf="metadata">
      <div class="flex flex-row">
        <div class="page-sub-header">
          Environment
        </div>
      </div>
      <mat-tab-group (selectedTabChange)="getAccountIntegration($event.index)" [(selectedIndex)]="selectedTabIndex" class="inner-mat-tab mat-tab-no-border">
        <mat-tab *ngFor="let environment of metadata.environments; let i = index"
          [label]="environment.name.toUpperCase()" formArrayName="accountIntegrations">
          <ng-template mat-tab-label>
            <i *ngIf="isEnvironmentActive(environment.id)" class="fa fa-check-circle text-success mr-1"></i>
            {{environment.name.toUpperCase()}}
          </ng-template>
            <div class="flex flex-column" style="width:100%">
            <mat-card appearance="outlined" >
              <mat-card-content [formGroupName]="i">
                <div *ngIf="showView">
                  <div class="flex flex-row inactive-integration-text mb-1">
                    <button mat-stroked-button color="primary" type="button"
                    (click)="activateEnvironment(accountIntegration)"
                    *ngIf="accountIntegration && !accountIntegration.isActive">
                    SET {{environment.name.toUpperCase()}} AS ACTIVE
                    </button>
                    <button mat-stroked-button color="primary" type="button"
                    (click)="activateEnvironment(accountIntegration)"
                    *ngIf="accountIntegration && accountIntegration.isActive" disabled=true>
                    {{environment.name.toUpperCase()}} IS ACTIVE
                    </button>
                  </div>
                  <div *ngIf="environment.isMock">
                    <div >
                      <div style="display:flex; justify-content: center;">
                        <img src="./assets/images/mock-integration.png" height="118px" width="122px"/>
                      </div>
                      <div style="display:flex; justify-content: center;" class="m-3 font-weight-bold"> The mock environment cannot be customized </div>
                    </div>
                  </div>
                  <div class="Rtable Rtable--2cols mt-4" *ngIf="!environment.isMock">
                    <div class="Rtable-cell full-width section-header">Credentials</div>
                    <div class="Rtable-cell" *ngIf="showHmacCreds(environment)">
                      <div class="label-title">Username</div>
                      <div class="label-content">{{accountIntegration?.username}}</div>
                    </div>
                    <div class="Rtable-cell" *ngIf="showHmacCreds(environment)">
                      <div class="label-title">Password</div>
                      <div class="label-content">{{getSecretKeyLabel(accountIntegration?.password)}}</div>
                    </div>
                    <div class="Rtable-cell full-width">
                      <div class="label-title">Request Url</div>
                      <div class="label-content">{{environment.url}}</div>
                    </div>
                    <div class="Rtable-cell full-width section-header">Retry Attempts</div>
                    <div class="Rtable-cell">
                      <div class="label-title">Max # of Attempts</div>
                      <div class="label-content">{{accountIntegration?.maxAttempts}}</div>
                    </div>
                    <div class="Rtable-cell">
                      <div class="label-title">Expiration (Hours)</div>
                      <div class="label-content">{{accountIntegration?.expirationHours}}</div>
                    </div>
                  </div>
                </div>
                <div *ngIf="!showView && !environment.isMock">
                  <div class="flex flex-row section-header" *ngIf="showHmacCreds(environment)">
                    Credentials
                  </div>
                  <div class="flex flex-column" style="width: 75%" *ngIf="showHmacCreds(environment)">
                    <mat-form-field>
                      <mat-label>Username</mat-label>
                      <input matInput formControlName="username"/>
                    </mat-form-field>
                    <auto-suggest *ngIf="secretKeys" [list]="secretKeys" placeholder="Password" field="name" dataKey="id"
                    formControlName="password">
                    </auto-suggest>
                  </div>
                  <div class="flex flex-row section-header">
                    Retry Attempts
                  </div>
                  <div class="flex flex-row">
                    <mat-form-field class="mr-5">
                      <mat-label>Max # of Attempts</mat-label>
                      <input type="number" matInput formControlName="maxAttempts" required />
                    </mat-form-field>
                    <mat-form-field>
                      <mat-label>Expiration (Hours)</mat-label>
                      <input type="number" matInput formControlName="expirationHours" required />
                    </mat-form-field>
                  </div>
                </div>
                <div *ngIf="!showView && environment.isMock">
                  <div style="display:flex; justify-content: center;" class="mt-5">
                    <img src="./assets/images/mock-integration.png" height="118px" width="122px"/>
                  </div>
                  <div style="display:flex; justify-content: center;" class="m-3 font-weight-bold"> The mock environment cannot be customized </div>
                </div>

              </mat-card-content>
            </mat-card>

          <div *ngIf="metadata?.hasAccountMappings && !showView && !environment.isMock && accountIntegration?.id !== null"
            class="flex flex-column mt-5" style="width: 20%">
            <button mat-button color="basic" class="material-default"
             (click)="$event.preventDefault();addIntegrationMapping(addMappingDialog, integrationForm.get('accountIntegrations.' + i), accountIntegration)">
              ADD MAPPING
            </button>
          </div>
        </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </form>
</div>
<ng-template #addMappingDialog let-data>
  <h2 mat-dialog-title>Integration Mapping</h2>
    <div mat-dialog-content>
      <integration-mapping [group]="data.integrationForm" [integration]="data.selectedIntegration" [accountIntegration]="data.accountIntegration"></integration-mapping>
    </div>
    <div mat-dialog-actions>
        <button type="button" class="btn btn-link btn-sm custom float-right"
        (click)="closeMapping()">
        <i aria-hidden="true" class="fa fa-times"></i> Close
      </button>
    </div>

</ng-template>

